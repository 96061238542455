<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width70:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 70:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitleText" [showCloseButton]="true" [(visible)]="isShowPopup"
	titleTemplate="title" (onHidden)="doHiddenPopup($event)" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'title'">
		<strong class="purple">{{ (invoiceDetails.apInvoiceNumber) ? (invoiceDetails.apInvoiceNumber + ' - ' +
			invoiceDetails.subusername) : invoiceDetails.subusername}}
		</strong> | Commission Calculation
	</div>

	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">

			<div id="exportContainer" class="right">
				<dx-button class="secondaryBtn" text="Export Data" icon="xlsxfile"
					(onClick)="onExporting($event)"></dx-button>
			</div>

			<dx-data-grid id="mainDataGrid" [dataSource]="mainDataSource" [remoteOperations]="true" [showBorders]="true">

				<dxi-column dataField="name"
					caption="Settlement Period : {{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} To {{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}"></dxi-column>
				<dxo-summary>
					<dxi-total-item column="name" alignment="right"
						displayFormat=" Total Commission : {{invoiceDetails.settlementValue}}">
						<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-value-format>
					</dxi-total-item>
				</dxo-summary>

				<dxo-master-detail [enabled]="true" template="masterDetail"></dxo-master-detail>
				<div *dxTemplate="let masterDetailItem of 'masterDetail'">
					<div
						*ngIf="checkMasterDetails(masterDetailItem,'invoices') && invoicesSource && invoicesSource.length > 0">
						<dx-data-grid #invoicesDataGrid id="invoicesDataGrid" [dataSource]="invoicesSource"
							keyExpr="invoiceCode" [showBorders]="true">
							<dxi-column dataField="fiscal_sequence" [groupIndex]="1" [allowSorting]="true" sortOrder="asc"
								[autoExpandGroup]="false" caption="Period"
								[calculateCellValue]="fullNameColumn_calculateCellValue">
							</dxi-column>
							<!-- <dxi-column dataField="invoiceDate" sortOrder="asc" dataType="date"></dxi-column> -->
							<dxi-column dataField="invoiceCode" caption="Invoice Code"></dxi-column>
							<dxi-column dataField="clientName" caption="Client Name"></dxi-column>
							<dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku" caption="SKU"></dxi-column>
							<dxi-column dataField="itemname" caption="Item"></dxi-column>
							<dxi-column dataField="description" caption="Description"></dxi-column>
							<dxi-column dataField="linetotal" [caption]="'Line Total ('+globalCurrencySymbol+')'"></dxi-column>
							<dxi-column dataField="commissionType" caption="Commission Type"></dxi-column>
							<dxi-column dataField="condition" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" caption="Criterion"></dxi-column>
							<dxi-column dataField="commissionRate" caption="Commission Rate (%)" alignment="center">
							</dxi-column>
							<dxi-column dataField="commissionAmount" [caption]="'Commission Amount ('+globalCurrencySymbol+')'"
								[width]="180">
							</dxi-column>

							<dxo-summary>
								<dxi-group-item column="invoiceCode" summaryType="count" displayFormat="invoice {0}">
								</dxi-group-item>
								<dxi-group-item column="commissionAmount" summaryType="sum" displayFormat="Total: {0}"
									[showInGroupFooter]="true">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-group-item>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Sub Total: {0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
							</dxo-summary>

							<!-- <dxi-sort-by-group-summary-info summaryItem="count"></dxi-sort-by-group-summary-info> -->
							<dxo-export [enabled]="false"></dxo-export>
							<dxo-paging [enabled]="false"></dxo-paging>
							<dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,'extraCommissionBasedRules') && extraCommissionBasedRulesSource && extraCommissionBasedRulesSource.length > 0">
						<dx-data-grid #extraCommissionBasedRulesDataGrid id="extraCommissionBasedRulesDataGrid"
							[dataSource]="extraCommissionBasedRulesSource" keyExpr="criterion" [showBorders]="true">
							<dxi-column dataField="condition" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" caption="Criterion"></dxi-column>
							<dxi-column dataField="commissionType" caption="Commission Type"></dxi-column>
							<dxi-column dataField="commissionRate" alignment="center" caption="Commission Rate (%)">
							</dxi-column>
							<dxi-column dataField="commissionAmount" caption="Commission Amount ($)" [width]="180">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Sub Total: {0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
							</dxo-summary>
							<dxo-export [enabled]="false"></dxo-export>
							<dxo-paging [enabled]="false"></dxo-paging>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,'arcreditmemos') && arcreditmemosSource && arcreditmemosSource.length >0">
						<dx-data-grid #arcreditmemosDataGrid id="arcreditmemosDataGrid" [dataSource]="arcreditmemosSource"
							keyExpr="clientCode" [showBorders]="true">
							<dxi-column dataField="invoiceCode" caption="Invoice Code"></dxi-column>
							<dxi-column dataField="clientName" caption="Client Name"></dxi-column>
							<dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku" caption="Item"></dxi-column>
							<dxi-column dataField="itemname" caption="Item"></dxi-column>
							<dxi-column dataField="description" caption="Description"></dxi-column>
							<dxi-column dataField="linetotal" [caption]="'Line Total ('+globalCurrencySymbol+')'"></dxi-column>
							<dxi-column dataField="commissionType" caption="Commission Type"></dxi-column>
							<dxi-column dataField="condition" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" caption="Criterion"></dxi-column>
							<dxi-column dataField="commissionRate" alignment="center" caption="Commission Rate  (%)">
							</dxi-column>
							<dxi-column dataField="commissionAmount" [caption]="'Commission Amount ('+globalCurrencySymbol+')'"
								[width]="180">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat=" Sub Total: {0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
							</dxo-summary>
							<dxo-export [enabled]="false"></dxo-export>
							<dxo-paging [enabled]="false"></dxo-paging>
						</dx-data-grid>
					</div>
				</div>
				<dxo-export [enabled]="false"></dxo-export>
			</dx-data-grid>
		</dx-scroll-view>
	</div>
</dx-popup>