<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitle" [showCloseButton]="true" [(visible)]="isShowPopup"
	(onHidden)="doHiddenPopup($event)" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div *ngIf="isShowDatagrid">
				<dx-data-grid #pastInvoicesListDataGridRef id="gridContainer" [dataSource]="pastInvoicesList"
					[showBorders]="true" [columnAutoWidth]="true" keyExpr="creditMemoGuid" class="hideFilterPanelInx"
					[height]="450">

					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
					</dxo-pager>
					​
					<dxi-column caption="Invoice" dataField="invoiceCode" [allowFiltering]="true"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateInvoiceCode">
						<div *dxTemplate="let element of 'cellTemplateInvoiceCode'">
							{{element.data.invoiceCode || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="Invoice Date" dataField="invoiceDate" dataType="date" format="yyyy-MM-dd"
						[allowFiltering]="true" [allowHeaderFiltering]="false" cellTemplate="cellTemplateInvoiceDate">
						<div *dxTemplate="let element of 'cellTemplateInvoiceDate'">
							{{element.data.invoiceDate || '' | customDateFormatPipe:'custom':'YYYY-MM-DD' }}
						</div>
					</dxi-column>
					<dxi-column caption="Invoice Total" dataField="linetotal" [allowFiltering]="false">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplateLinetotal'">
							{{element.data.linetotal || 0 | currency:globalCurrency}}
						</div>
					</dxi-column>
					<dxi-column caption="Rebate Amount" dataField="commissionAmount" [allowEditing]="false"
						[allowFiltering]="false">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplateCommissionAmount'">
							{{element.data.commissionAmount || 0 | currency:globalCurrency}}
						</div>
					</dxi-column>
				</dx-data-grid>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>