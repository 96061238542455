import { Component, OnInit, OnDestroy, ElementRef, HostListener } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { SalesListingOpenFromEnum } from '@app/constants-enums/enums';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CustomNumberDecimalSuffixPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';

@Component({
	selector: 'app-sales-invoice-total-tiles',
	templateUrl: './sales-invoice-total-tiles.component.html',
	styleUrls: ['./sales-invoice-total-tiles.component.css']
})

export class SalesInvoiceTotalTilesComponent implements OnInit, OnDestroy {
	public globalCurrency: string;
	public salesTotalForTheMonth: number;
	public currentMonthName: string;
	public resourcesLoaded: boolean = false;
	private getInvoiceSbsn: Subscription;
	public isShowInvoicePopover: boolean;
	public isUserSettingsExisting: boolean;
	public isShowFullValue: boolean = false;
	public getLastCharacterFromSortValue: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService,
		private _SalesDataSharingService: SalesDataSharingService,
		private _ElementRef: ElementRef) {

	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this._ElementRef.nativeElement.contains(event.target)) {
			// click on inside 
		} else {
			this.isShowFullValue = false; // click on outside 
		}
	}

	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.currentMonthName = this._DashboardService.getCurrentMonthName();
		this.currentMonthName = '';
		this.getSalesInvoiceTotal();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_INVOICE_TILES_EVENT', response => {
			if (response) {
				this.getSalesInvoiceTotal();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getInvoiceSbsn) {
			this.getInvoiceSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_INVOICE_TILES_EVENT');
	}

	private getSalesInvoiceTotal() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.INVOICES_TILE);
		if (this.getInvoiceSbsn) {
			this.getInvoiceSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getInvoiceSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this.resourcesLoaded = false;
			if (response.flag) {
				if (response.data.tiles) {
					this.salesTotalForTheMonth = response.data.tiles.sales_total_for_the_month || 0;
					this.currentMonthName = response.data.tiles.month_name || '';
					const getStringValue = new CustomNumberDecimalSuffixPipe().transform(this.salesTotalForTheMonth, 2).toString();
					this.getLastCharacterFromSortValue = '';
					if (/^[a-zA-Z]+$/.test(getStringValue.substr(getStringValue.length - 1))) {
						this.getLastCharacterFromSortValue = getStringValue.substr(getStringValue.length - 1);
					}
				}
			}
			this.getCustomer360Config();
		}, error => {
			this.resourcesLoaded = false;
		});
	}

	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
		this._SalesDataSharingService.setSalesListingOpenFrom(SalesListingOpenFromEnum.TILE_INVOICE);
	}

	public doOpenInvoicePopover(e) {
		this.getCustomer360Config();
		this.isShowInvoicePopover = true;
	}
	public doCloseInvoicePopover(e) {
		this.isShowInvoicePopover = false;
	}

	// Method used to get Customer360 Configuration
	private getCustomer360Config() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		this.isUserSettingsExisting = false;
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === 'invoice_tile');
				if (configIndex !== -1) {
					const configObject = dashboardSettings.configs[configIndex];
					let isTileSettingSaved = false;
					if (configObject && configObject.show_data_from) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_status_to_consider) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_date_to_use) {
						isTileSettingSaved = true;
					}
					this.isUserSettingsExisting = isTileSettingSaved;
				}
			}
		}
	}

	public doRefreshTile(e) {
		this.isShowInvoicePopover = false;
		this.getSalesInvoiceTotal();
	}

	public doClickToShowFullValue(event) {
		if (this.salesTotalForTheMonth <= 0) {
			return;
		}
		if (this.salesTotalForTheMonth > 0 && this.salesTotalForTheMonth < 1000) {
			return;
		}
		event.stopPropagation();
		this.isShowFullValue = true;
	}
}


