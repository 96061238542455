import { MSG_ERROR_MESSAGE, ServerEntity } from '@app/constants-enums/constants';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Subscription, Observable, Subject } from 'rxjs';
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from "devextreme/excel_exporter";
import { EndUsersChargebackItemTabModel, ChargeDocumentsTabModel, EndUsersChargebackContractModelUI } from "@app/models/end-users-chargeback-contract.model";
import { UploadChargebackEnduserClaimsDialogComponent } from '@app/custom-components/upload-chargeback-enduser-claims-dialog/upload-chargeback-enduser-claims-dialog.component';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-eucc-chargeback-documents-tab',
	templateUrl: './eucc-chargeback-documents-tab.component.html',
	styleUrls: ['./eucc-chargeback-documents-tab.component.css']
})
export class EuccChargebackDocumentsTabComponent implements OnInit, OnDestroy, OnChanges {
	@Input() itemTabDataList: any = [];
	@Input() chargeBackDocumentsTabDatalist: any[] = [];
	@ViewChild("chargeDocumentsTabDataGridContainer", {}) chargeDocumentsTabDataGridContainer: DxDataGridComponent;
	@Output() saveChargeDocumentsTabDataEvent = new EventEmitter<string>();

	private getUomLstSbsn: Subscription;
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public chargeDocumentsTabModel = new ChargeDocumentsTabModel();
	public editChargebackGuid: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public documentFileData: any;
	public claimDocumentUploadFile: any;
	public productSearchItemType: string;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public chargeDocumentsTabDataSource: any;
	public viewDocumentDataGrid: boolean = false;
	public isUploadExcelCsvFileItmGrpTab: boolean = false;
	public successedListOfCsvRows: any = [];
	public ignoredListOfCsvRows: any = [];
	public noOfUnmatchedClaim: any;
	public noOfMatchedClaim: any;
	public noOfTotaClaim: any;
	public listOfStatuses: any[] = this._EndUsersChargebackContractService.doGetListofApprovalStatus();
	public listOfHeaderFilterStatuses: any[] = this._EndUsersChargebackContractService.doGetListofApprovalStatusForHeaderFilter();
	// Matrial design object
	public pageOptions: PageOptions = new PageOptions();
	public guid = new Guid();
	private dataOutShortPayReqSubscription: Subscription;
	@Input() endUsersChargebackContractModelUI = new EndUsersChargebackContractModelUI();
	loadUploadEndUserDxDialogSubject: Subject<any> = new Subject();
	loadViewChargebackDocDxDialogSubject: Subject<any> = new Subject();
	public decimalPointForCurrency: number;
	public isShowClaimsItemListPopup: boolean = false;
	public uploadClaimsItemListProps: any;
	public isShowUploadChargebackEnduserClaimsPopup: boolean = false;
	public uploadChargebackEndUserClaimsProps: any;
	constructor(
		private _Router?: Router,
		private _ToastrService?: ToastrService,
		private _PermissionsService?: NgxPermissionsService,
		private _LoginService?: LoginService,
		private _LoaderService?: LoaderService,
		private _RestApiService?: RestApiService,
		private _ConfirmationDialogService?: ConfirmationDialogService,
		private _DataService?: DataService,
		private _EndUsersChargebackContractService?: EndUsersChargebackContractService,
		private _FileUploadService?: FileUploadService,
		private _GlobalStateService?: GlobalStateService,
		public _AppCommonSrvc?: AppCommonSrvc,
		private _CustomCurrencyPipe?: CustomCurrencyPipe,
		public _BillbackService?: BillbackService,
	) {
	}

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.editChargebackGuid = this._EndUsersChargebackContractService.getEditEndUsersChargebackGuid();
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}

		// product_search logic type (item/price)
		this.productSearchItemType = this._BillbackService.getProductSearchType();

		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.chargeBackDocumentsTabDatalist) {
			const currentValue = changes.chargeBackDocumentsTabDatalist.currentValue;
			if (currentValue) {
			}
		}
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataOutShortPayReqSubscription) {
			this.dataOutShortPayReqSubscription.unsubscribe();
		}
	}

	preInit() {

		this.editChargebackGuid = this._EndUsersChargebackContractService.getEditEndUsersChargebackGuid();
		// if (this.chargeBackDocumentsTabDatalist && this.chargeBackDocumentsTabDatalist.length > 0) {
		// 	this.chargeDocumentsTabDataSource = this.chargeBackDocumentsTabDatalist;
		// }

		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.getChargeBackDocumentTabData();
	}


	getChargeBackDocumentTabData() {
		if (this.editChargebackGuid) {
			this._LoaderService.show();
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('contractguid', this.editChargebackGuid);
			formData.append('method', 'getChargebackDocuments');
			formData.append('contracttype', 'enduserschargebackcontracts');
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
				next: (response) => {
					this.viewDocumentDataGrid = true;
					if (response.flag && response.data) {
						this.chargeBackDocumentsTabDatalist = JSON.parse(JSON.stringify(response.data)) || [];
						this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
				}, error: (error) => {
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			});
		} else {
			this.chargeBackDocumentsTabDatalist = [];
			this.chargeDocumentsTabDataSource = [];
		}
	}

	addChargebackDocumentsAgreementTabDetails() {

		if (this.checkValidation()) {
			// this.getAgreementTabListData();
			if (this.itemTabDataList.length > 0) {
				this.uploadClaimsItemListProps = {
					productList: JSON.parse(JSON.stringify(this.itemTabDataList)),
					buttonText: {
						cancel: 'Ok'
					}
				}
				this.isShowClaimsItemListPopup = false;
				setTimeout(() => {
					this.isShowClaimsItemListPopup = true;
				}, 50);
			}
		}

	}

	// Clear input field changeback document tab data
	clearChargebackDocumentsAgreementTabDetails() {
		this.chargeDocumentsTabModel = new ChargeDocumentsTabModel();
		this.documentFileData = '';
	}

	// View chargeback document dialog
	viewChargeBackDocumentDetails(chargeBackDocumentDetails) {

		const dialogData = {
			claimId: chargeBackDocumentDetails.ixcode,
			callFrom: 'en-users',
			itemTabDataList: this.itemTabDataList,
			endUsersChargebackContractModelUI: this.endUsersChargebackContractModelUI,
			chargeBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
			billBaBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
		}
		this.loadViewChargebackDocDxDialogSubject.next(dialogData);

	}

	downloadChargeBackDocument(chargeBackDocumentDetails) {
		const documentURL = chargeBackDocumentDetails.documentURL;
		const documentNewURLArr = documentURL.split('-');
		if (documentNewURLArr && documentNewURLArr.length > 0) {
			const docId = documentNewURLArr[0];
			const docDate = documentNewURLArr[1];
			const docCode = documentNewURLArr[2];
			const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackDoc';
			window.location.href = finalUrl;
		}
	}

	downloadBillBackDocument(billBackDocumentDetails) {
		const documentURL = billBackDocumentDetails.documentURL;
		const documentNewURLArr = documentURL.split('-');
		if (documentNewURLArr && documentNewURLArr.length > 0) {
			const docId = documentNewURLArr[0];
			const docDate = documentNewURLArr[1];
			const docCode = documentNewURLArr[2];
			const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=billbackDoc';
			window.location.href = finalUrl;
		}

	}

	public doOpenFilterBuilder() {
		this.chargeDocumentsTabDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.chargeDocumentsTabDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'EndUsers_Chargeback_Documents.xlsx';
		worksheet = workbook.addWorksheet('Documents');

		exportDataGrid({
			worksheet: worksheet,
			component: this.chargeDocumentsTabDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'documentTotal') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doOnRowUpdatedChargeDocumentsTab(event) {
		if (this.editChargebackGuid) {
			this.saveChargeDocumentsTabDataEvent.emit('save');
		}
	}

	public doDeleteChargeDocumentsTab(rowData) {
		this._ConfirmationDialogService.confirmDialog().afterClosed().subscribe(isDelete => {
			if (isDelete) {
				// const rowIndex = this.chargeDocumentsTabDataSource.findIndex(ce => ce.guid === rowData.guid);
				// if (rowIndex !== -1) {
				// 	this.chargeDocumentsTabDataSource.splice(rowIndex, 1);
				// 	this.chargeBackDocumentsTabDatalist.splice(rowIndex, 1);
				// 	if (this.editChargebackGuid) {
				this.doCallDeleteChargebackDocument(rowData);
				// 		}
				// 	}
			}
		});
	}

	public doCallDeleteChargebackDocument(objItem) {
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('action', 'delete');
		formdata.append(ServerEntity.CHARGEBACKDOC, JSON.stringify([objItem.guid]));
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formdata).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					let msg = 'Recorded Removed successfully';
					this._ToastrService.success(response.message || msg, 'Success', { closeButton: true, tapToDismiss: true });
					if (response && response.data && response.data['enduserschargebackcontracts'] && response.data['enduserschargebackcontracts'].length == 1) {
						const itemTabDataList = response.data['enduserschargebackcontracts'][0]['chargebackItems'] || [];
						this.itemTabDataList = itemTabDataList;
						this._EndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
						this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
					}
					this.getChargeBackDocumentTabData();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doOnSavedChargeDocumentsTab() {
		if (this.editChargebackGuid) {
			this.saveChargeDocumentsTabDataEvent.emit('save');
		}
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	// Upload document
	uploadDocument(files: File[]) {
		if (files.length === 1) {
			// file type verify
			const fileType = files[0].type;
			if (fileType !== '.doc' && fileType !== '.docx' && fileType !== 'application/msword' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				&& fileType !== '.csv' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && fileType !== 'application/vnd.ms-excel' && fileType !== 'application/pdf') {
				this._ToastrService.info('File extension not valid', 'Info!', { closeButton: true, tapToDismiss: true });
				this.chargeDocumentsTabModel.documentURL = '';
				return false;
			}
			// file size convert KB
			const fileSize = Math.round(files[0].size / 5120);
			if (fileSize > 5120) {
				this._ToastrService.info('File size should not greater then 5 MB', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
			this.documentFileData = files[0];
			this.chargeDocumentsTabModel.documentURL = files[0].name;
			// this._FileUploadService.upload(files);

		}
	}
	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	saveClaimedItems(claimedItems) {
		this._LoaderService.show();

		const item = {
			guid: this.guid.newGuid(),
			documentURL: this.chargeDocumentsTabModel.documentURL,
			documentReferenceNumber: this.chargeDocumentsTabModel.documentReferenceNumber,
			documentDate: '',
			claimedUserNote: this.chargeDocumentsTabModel.claimedUserNote || '',
			claimedStatus: '',
			claimedItems: claimedItems,
		}
		const arrChargebackDocumentsTabList = [];
		arrChargebackDocumentsTabList.push(item);
		// const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.billBackChargeBackRequest.guid + '&method=claimChargeback' + '&chargebackDocs=' + encodeURIComponent(JSON.stringify(arrChargebackDocumentsTabList))
		// this.dataInReqSubscription = this._RestApiService.doDataOutReq((reqFormData)).subscribe(

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.editChargebackGuid);
		formData.append('documentUploadFile', this.documentFileData);
		formData.append('claimDocumentUploadFile', this.claimDocumentUploadFile);
		formData.append('chargebackDocs', (JSON.stringify(arrChargebackDocumentsTabList)));
		formData.append('method', 'claimChargeback');
		formData.append('contracttype', 'enduserschargebackcontracts');

		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this.itemTabDataList = response.data.itemSold;
					this.chargeBackDocumentsTabDatalist = response.data.chargebackDocuments;
					this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
					if (this.editChargebackGuid) {
						this._EndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
						this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
					}
					this.clearChargebackDocumentsAgreementTabDetails();
					this._ToastrService.success('Document Added Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	public doAddNewClaim() {
		const dialogConfig = {
			itemTabDataList: this.itemTabDataList,
			endUsersChargebackContractModelUI: this.endUsersChargebackContractModelUI,
			callFrom: 'end-users',
		};
		this.loadUploadEndUserDxDialogSubject.next(dialogConfig);
	}

	public doModifyClaim(result) {
		const dialogConfig = {
			itemTabDataList: this.itemTabDataList,
			endUsersChargebackContractModelUI: this.endUsersChargebackContractModelUI,
			callFrom: 'end-users',

		};
		this.loadUploadEndUserDxDialogSubject.next(dialogConfig);
	}

	public doHideUploadEndUserDxDialog(result) {
		if (result) {
			this.chargeBackDocumentsTabDatalist = this._EndUsersChargebackContractService.getChargebackDocumentTabDataList();
			this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
			this.saveChargeDocumentsTabDataEvent.emit('uploaditem');
		}
	}

	public doHideViewClaimDxDialog(result) {
		if (result) {
			this.chargeBackDocumentsTabDatalist = this._EndUsersChargebackContractService.getChargebackDocumentTabDataList();
			this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
			this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
		}
	}

	doViewImportClaimItemsExcel() {
		this.uploadChargebackEndUserClaimsProps = {
			billbackType: 'enduserschargeback',
			actionFor: 'showIgnoreItem',
			successedListOfCsvRows: this.successedListOfCsvRows,
			ignoredListOfCsvRows: this.ignoredListOfCsvRows,

		};
		this.isShowUploadChargebackEnduserClaimsPopup = false;
		setTimeout(() => {
			this.isShowUploadChargebackEnduserClaimsPopup = true;
		}, 50);
	}
	checkValidation() {
		let valDoc: boolean = true;
		if (!this.editChargebackGuid) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (this.itemTabDataList.length < 0) {
			this._ToastrService.info('Please add at least one product.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.documentFileData) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.chargeDocumentsTabModel.documentURL || !this.chargeDocumentsTabModel.documentURL.trim()) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.chargeDocumentsTabModel.documentReferenceNumber || !this.chargeDocumentsTabModel.documentReferenceNumber.trim()) {
			this._ToastrService.info('Document Reference No. Required .', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		}
		return valDoc;
	}

	public doCloseClaimsItemListPopup(result) {
		this.isShowClaimsItemListPopup = false;
		if (result) {
			this.saveClaimedItems(result);
		}
	}

	public doCloseUploadChargebackEnduserClaimsPopup(result) {
		this.isShowUploadChargebackEnduserClaimsPopup = false;
	}

}

