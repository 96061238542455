<div class="rightBar">
	<div class="rightBlock">
		<!--<div class="titleRow">
			<h1>{{editBillbackGuid ? 'Update' : 'Add New'}} Billback & Chargeback Agreement</h1>
		</div>-->
		<div class="contentRow cf">
			<div class="borderBox cf padB0">
				<div class="formControls editAgreeDetail">

					<div class="row ">
						<div class="cols12 ">
							<div class="feildCol radioGroup feildColAuto">
								<label>BillBack Type:</label>
								<dx-radio-group [items]="billbackTypeRadio" [value]="billbackContractModelUI.billBackType"
									(onValueChanged)="doChangeBillBackType($event)" [disabled]="isDisablePermission"
									layout="horizontal" displayExpr="text" valueExpr="value">
								</dx-radio-group>
							</div>
						</div>
						<div class="cols12">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="billbackContractModelUI.contractName" maxLength="500">
								</dx-text-box>
								<div class="inputField">
									<label for="contractName">Contract Name</label>
								</div>
							</div>
						</div>
						<div class="cols8">
							<div class="dx-field feildCol" [ngClass]="{inputDisable: isDisablePermission}">
								<div class="dx-field-label">Select Supplier</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-drop-down-box [(value)]="supplierGridBoxValue" valueExpr="code"
										[displayExpr]="displayExprSupplierName" placeholder="Select Supplier Name..."
										[(opened)]="isSupplierDropDownBoxOpened" [showClearButton]="true"
										[dataSource]="supplierDataSource" (onOptionChanged)="onSupplierSelectionChanged($event)"
										[disabled]="isDisablePermission">
										<div *dxTemplate="let data of 'content'">
											<dx-data-grid #supplierGrid [dataSource]="supplierDataSource" keyExpr="code"
												[columns]="supplierGridBoxColumns" [showBorders]="true"
												[(selectedRowKeys)]="supplierGridBoxValue" height="100%">
												<dxo-selection mode="single"></dxo-selection>
												<dxo-filter-row [visible]="true"></dxo-filter-row>
												<dxo-scrolling mode="virtual"></dxo-scrolling>
												<dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
											</dx-data-grid>
										</div>
									</dx-drop-down-box>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="datePicker" [ngClass]="{inputDisable: isDisablePermission}">
								<dx-date-box [(value)]="billbackContractModelUI.startDate" [disabled]="isDisablePermission"
									type="date" displayFormat="yyyy-MM-dd">
								</dx-date-box>
								<div class="inputField inputFieldFilterLbl">
									<label for="inputFieldFilterLbl">Start Date</label>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="datePicker">
								<dx-date-box [(value)]="billbackContractModelUI.endDate" type="date" displayFormat="yyyy-MM-dd">
								</dx-date-box>
								<div class="inputField inputFieldFilterLbl">
									<label for="inputFieldFilterLbl">End Date</label>
								</div>
							</div>
						</div>
						<div class="cols12 colsP0 padT10">
							<div class="cols3"
								*ngIf="(billbackContractModelUI.lastUpdatedDate !== null && billbackContractModelUI.lastUpdatedDate !== '' && billbackContractModelUI.lastUpdatedDate !== undefined)">
								<div class="remarkBx fullNote ecaRemark">
									<strong>Last Updated Date :-</strong>
									{{ (billbackContractModelUI.lastUpdatedDate !== null &&
									billbackContractModelUI.lastUpdatedDate !== '' && billbackContractModelUI.lastUpdatedDate !==
									undefined) ? (billbackContractModelUI.lastUpdatedDate | toDateObj | date: 'yyyy-MM-dd') : ""
									}}
								</div>
							</div>
							<div [ngClass]="billbackContractModelUI.lastUpdatedDate !==null &&
								billbackContractModelUI.lastUpdatedDate !=='' && billbackContractModelUI.lastUpdatedDate
								!==undefined ? 'cols9' : 'cols12'">
								<div class="multiBtnsRow">
									<div class="btnsGroup txtRight">
										<dx-button [disabled]="!editBillbackGuid" class="secondaryBtn"
											[ngClass]="{'grayButton': !editBillbackGuid}" text="Update Bought & Sold Quantity"
											type="normal" stylingMode="contained" (onClick)="calculateBoughtQuantityClick()"
											[useSubmitBehavior]="false">
										</dx-button>
										<dx-button *ngxPermissionsOnly="isCurrentTabPermission" [disabled]="!editBillbackGuid"
											class="secondaryBtn mrgL10" [ngClass]="{'grayButton': !editBillbackGuid}"
											text="Generate Billback Document" icon="docfile" type="normal" stylingMode="contained"
											(onClick)="generateBillbackDocumentClick()" [useSubmitBehavior]="false">
										</dx-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="borderBox cf">
				<div class="tabbing cf padT10">
					<ul>
						<li *ngFor="let item of tabItemsList" (click)="doClickTab(item.key)"
							[ngClass]="{active: selectedTabVal == item.key}">
							<a>{{item.displayVal}}</a>
						</li>
					</ul>
					<div class="absBtnTop">
						<dx-button *ngIf="editBillbackGuid" class="secondaryBtn button" text="Download Billback Price List"
							icon="download" type="normal" stylingMode="contained" (onClick)="downloadPriceList()"
							[useSubmitBehavior]="false">
						</dx-button>
					</div>
				</div>
				<app-bc-items-tab *ngIf="selectedTabVal === tabItemsList[0].key"
					[billbackContractModelUI]="billbackContractModelUI" [itemTabDataList]="itemTabDataList"
					[itemTabDataSource]="itemTabDataSource"
					(saveBillbackItemTabDataEvent)="doSaveBillbackItemTabData($event)">
				</app-bc-items-tab>
				<app-bc-billback-documents-tab *ngIf="selectedTabVal === tabItemsList[1].key"
					[billBackDocumentsTabDatalist]="billBackDocumentsTabDatalist">
				</app-bc-billback-documents-tab>
				<div *ngIf="editBillbackGuid" class="alignLeft mrgR20 mrgT10">
					<dx-button class="secondaryBtn" (onClick)="doExportToExcel($event)" text="Export To Excel"
						icon="exportxlsx" id="buttonDownload" type="normal" stylingMode="contained"
						[useSubmitBehavior]="false">
					</dx-button>
				</div>
				<div class="alignRight mrgT10" *ngxPermissionsOnly="isCurrentTabPermission">
					<dx-button class="primaryBtn" text="Submit" icon="save" type="normal" stylingMode="contained"
						(onClick)="doSaveBillbackContract()" [useSubmitBehavior]="false">
					</dx-button>
				</div>
			</div>
		</div>
	</div>
</div>
<app-bc-billed-items-list-dialog *ngIf="isShowBilledItemsListPopup" [billedItemsProps]="billedItemsProps"
	(closebilledItemsPopup)="doClosebilledItemsPopup($event)"></app-bc-billed-items-list-dialog>