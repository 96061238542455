<div class="numberBlock hoverClr" (click)="tilesClick('INVOICES')">
	<span class="contractIcon">
		<span class="icon-bill_pay icon-set1"></span>
	</span>
	<div class="blockText">
		<strong>
			<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
			<span *ngIf="!resourcesLoaded">${{salesTotalForTheMonth || 0 | customNumberDecimalSuffixPipe:2}}</span>
		</strong>
		<span class="blockDate">{{currentMonthName || ''}}</span>
		<!-- <span class="blockDate"
			*ngIf="settlementPeriodStart && settlementPeriodEnd && settlementPeriodStart !== '' && settlementPeriodEnd !==''">{{ settlementPeriodStart | date: 'yyyy-MM-dd' }}
			to
			{{ settlementPeriodEnd | date: 'yyyy-MM-dd' }}
		</span> -->
		Invoice Total
	</div>
</div>