<div class="row equalHight">
	<div class="cols12">
		<div class="chartAreaBox quarterlyReportBox cf padL0 padR0">
			<div class="chartArea">
				<div class="row mrgB20">
					<div class="cols12">
						<div class="cf"></div>
						<div class="dx-field txtcenter">
							<h2 class="chartTitlesmall">{{currentMonthName}} Sales Goals</h2>
						</div>
					</div>
				</div>

				<div *ngIf="isShowChart" class="posRelative">
					<!-- <dx-select-box #seasons id="seasons" [dataSource]="dataSource" [width]="150" displayExpr="name"
						[(value)]="value">
					</dx-select-box> -->
					<dx-circular-gauge #saleGoalChart id="gauge" [value]="value.mean" [subvalues]="[value.min, value.max]">
						<dxo-size [height]="345"> </dxo-size>
						<dxo-range-container>
							<dxi-range [startValue]="1000000" [endValue]="2000000" color="#FF0000"></dxi-range>
							<dxi-range [startValue]="2000000" [endValue]="3000000" color="#E6E200"></dxi-range>
							<dxi-range [startValue]="3000000" [endValue]="4000000" color="#77DD77"></dxi-range>
						</dxo-range-container>

						<dxo-scale [startValue]="1000000" [endValue]="4000000" [tickInterval]="5">
							<dxo-label [customizeText]="customizeText"></dxo-label>
						</dxo-scale>
						<dxo-tooltip [enabled]="true" [customizeTooltip]="doCustomizeTooltip"></dxo-tooltip>
						<dxo-value-indicator type="rectangleNeedle">
							<dxo-text [customizeText]="doCustomizeTextIndicator"></dxo-text>
						</dxo-value-indicator>
						<!-- <dxo-title text="Temperature in the Greenhouse">
							<dxo-font [size]="28"></dxo-font>
						</dxo-title> -->
					</dx-circular-gauge>


					<app-ix-load-panel *ngIf="isVisibleChartLoader"></app-ix-load-panel>
				</div>
			</div>
		</div>
	</div>