<dx-popup [width]="'1080px'" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false" [title]="pageTitle"
	[showCloseButton]="true" [(visible)]="isShowPopup" [wrapperAttr]="{ class: 'popupToolbarPadding' }"
	(onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
		[useNative]="false">
		<div *dxTemplate="let data of 'content'">
			<div class="tableOut">
				<table class="responsiveTable">
					<tr *ngFor="let pl of productList;let i = index">
						<td *ngIf="isQBOnlineErpUser">{{pl.itemsku}}</td>
						<td>{{pl.itemname}}</td>
						<td>{{pl.description}}</td>
						<td class="width130">
							<select [(ngModel)]="pl.selectedClaimedUom">
								<option *ngFor="let uom of pl.uomList;let menuIndex = index;" value="{{uom.code}}" selected>
									{{uom.name | uppercase}}
								</option>
							</select>
						</td>
						<td>{{pl.end_user_name}}</td>
						<td class="width120">
							<div class="feildColEDQ">
								<input name="" type="text" id="claimed" [(ngModel)]="pl.claimed" (keyup)="sumClaimed('',pl)"
									numbersOnly maxlength="7">
							</div>
						</td>
					</tr>
				</table>
				<div class="popTotal">Total Quantities : <span>{{totalClaimed}}</span></div>
			</div>
		</div>
	</dx-scroll-view>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="claimedItemSubmit()" [text]="'Submit'" class="button primaryBtn" icon="save"></dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>