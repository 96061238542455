import { Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { ChargeTypesTabModel, DistributionChargebackContractModelUI } from '@app/models/distribution-chargeback-contract.model';
import { Guid } from '@app/models/guid';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { DistributionChargebackContractService } from '@app/services/contract-service/billback/distribution-chargeback-contract.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { DecimalPipe } from '@angular/common';
@Component({
	selector: 'app-dcc-chargeback-types-tab',
	templateUrl: './dcc-chargeback-types-tab.component.html',
	styleUrls: ['./dcc-chargeback-types-tab.component.css'],
	providers: [DecimalPipe]
})
export class DccChargebackTypesTabComponent implements OnInit, OnDestroy {

	public guid = new Guid();
	public isDisablePermission: boolean = false;
	public pageOptions: PageOptions = new PageOptions();
	@ViewChild('dataGridContainerOfChargebackTypesTab') dataGridContainerOfChargebackTypesTab: DxDataGridComponent;
	public chargebackTypesDataSource: any;
	public changeBackTypesTabData: any[] = [];
	public chargebackTypesTabDataSource: any;
	@Input() distributionChargebackContractModelUI = new DistributionChargebackContractModelUI();
	public selectedChargebackTypes: any = new ChargeTypesTabModel();
	@Output() saveChargebackTypesTabDataEvent = new EventEmitter<string>();
	public percentage_or_amount_radio_values = [
		{
			key: "percentage",
			value: "Percentage"
		},
		{
			key: "amount",
			value: "Amount"
		}
	];
	public decimalPointForCurrency: number;
	public decimalPointPercentageFormat: string = '1.0-0';
	// Api call request object
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public editDistibutionChargebackGuid: string;
	public isCurrentTabPermission: string;
	public globalCurrencySymbol: string;
	constructor(
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _PermissionsService: NgxPermissionsService,
		private _DecimalPipe: DecimalPipe,
		public _DistributionChargebackContractService: DistributionChargebackContractService
	) {
		this.doChargebackCategorySetCellValue = this.doChargebackCategorySetCellValue.bind(this);
	}

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per)
		}
		if (permsn.indexOf('SALES_DISTRIBUTION_CHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'SALES_DISTRIBUTION_CHARGEBACK_MODIFY';
		}
		this.getChargebackTypesTabData();
		this.editDistibutionChargebackGuid = this._DistributionChargebackContractService.getEditDistributionChargebackGuid();
		this.chargebackTypesTabDataSource = this.distributionChargebackContractModelUI.chargeBackTypesTabData || [];
	}

	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	public getChargebackTypesTabData() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.DISTRIBUTION_CHARGEBACK_TYPES);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this.chargebackTypesDataSource = response.data || [];
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			} else {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	//#region Categories Tab Method
	doChargebackCategorySetCellValue(rowData: any, value: any): void {
		const categoryIndex = this.chargebackTypesDataSource.findIndex(p => p.categoryname === value);
		if (categoryIndex !== -1) {
			rowData.description = this.chargebackTypesDataSource[categoryIndex].description;
			rowData.categoryname = this.chargebackTypesDataSource[categoryIndex].categoryname;
			rowData.is_recoverable = this.chargebackTypesDataSource[categoryIndex].is_recoverable;
			rowData.is_itembased = this.chargebackTypesDataSource[categoryIndex].is_itembased;
			rowData.is_disputable = this.chargebackTypesDataSource[categoryIndex].is_disputable;
			rowData.is_invoicebased = this.chargebackTypesDataSource[categoryIndex].is_invoicebased;
			rowData.categoryId = this.chargebackTypesDataSource[categoryIndex].guid;
		}
	}

	setDefaultValueOfPercentageOrAmount() {
		this.distributionChargebackContractModelUI.chargeBackTypesTabData.forEach(item => {
			if (!item['percentage_or_amount']) {
				item['percentage_or_amount'] = 'percentage';
			}
		});

	}

	onInitNewRowCategoriesAgreement(category) {
		category.data['percentage_or_amount'] = 'percentage';
	}

	addAndUpdateCategoriesAgreementTabDetails(category) {
		if (category.data.is_itembased) {
			category.data.is_invoicebased = true;
		}

		if (!category.data.guid) {
			category.data.guid = this.guid.newGuid();
		}

		if (this._DistributionChargebackContractService.getEditDistributionChargebackGuid()) {
			this.saveChargebackTypesTabDataEvent.emit('save');
		}
		this.distributionChargebackContractModelUI.chargeBackTypesTabData = this.chargebackTypesTabDataSource;
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doCategoriesToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'add',
					text: "Add Chargeback Type",
					onClick: (event: any) => {
						this.addNewRow();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	public addNewRow() {
		this.dataGridContainerOfChargebackTypesTab.instance.addRow();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.dataGridContainerOfChargebackTypesTab.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		const cName = this.distributionChargebackContractModelUI.contractName || '';
		let fileName = '';
		if (cName) {
			fileName = cName + '-';
		}
		fileName = fileName + 'Categories_List.xlsx';
		worksheet = workbook.addWorksheet('Categories List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainerOfChargebackTypesTab.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'is_invoicebased' || gridCell.column.dataField === 'is_itembased' || gridCell.column.dataField === 'is_disputable' || gridCell.column.dataField === 'is_recoverable') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = 'YES';
						} else {
							excelCell.value = 'NO';
						}
						excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
					}
					if (gridCell.column.dataField === 'rate') {
						if (gridCell.data.percentage_or_amount === 'percentage') {
							excelCell.value = (this._DecimalPipe.transform(gridCell.value, this.decimalPointPercentageFormat)) + '%';
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}
	//#endregion
}
