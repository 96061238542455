<dx-popup [width]="500" [height]="495" #dxPopupRef [showTitle]="false" [dragEnabled]="true" [hideOnOutsideClick]="false"
	[showCloseButton]="true" [(visible)]="isShowPopup" (onHidden)="doHiddenPopup($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding popupContentHauto trialModeZindex' }"
	[contentTemplate]="contentTemplateVal">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'trialContent'" class="subscriptionUpg">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" [height]="375">
			<div class="imgUc mrgT10 padB10"><img [src]="_AppCommonSrvc.cdnImgPath + 'trial.png'"
					alt="Subscription Upgrade">
			</div>
			<h3 class="ucTitle">Trial <span class="purple">Mode</span></h3>
			<p class="center mrgB0">You are currently on <span class="purple">Trial Mode.</span>
				<span *ngIf="trial_remaining_days > 0">
					Your trial will expire in <span class="purple">{{trial_remaining_days}} days.</span>
				</span>
				<span *ngIf="trial_remaining_days === 0">
					Your trial expire <span class="purple">Today.</span> Please purchase a
					subscription.
				</span>
				<span *ngIf="trial_remaining_days < 0">
					Your trial has expired. Please purchase a subscription.
				</span>
			</p>
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="center" cssClass="mrgT10" [visible]="is_trial">
		<div *dxTemplate>
			<dx-button class="secondaryBtn button mrgR20" text="Purchase Later" type="normal" stylingMode="text"
				[visible]="called_from === 'CALLED_AS_TIRAL_EXPIRED'" (onClick)="purchaseLater()"
				[useSubmitBehavior]="false">
			</dx-button>
			<dx-button class="secondaryBtn button mrgR20" text="Continue Trial" type="normal" stylingMode="text"
				[visible]="called_from === 'CALLED_AS_TIRAL'" (onClick)="continueTrial()" [useSubmitBehavior]="false">
			</dx-button>
			<dx-button #purchaseOrUpgradeBtnRef class="primaryBtn button" text="Purchase Now"
				[icon]="_AppCommonSrvc.cdnSvgPath +'card-icon-white.svg'" type="normal" stylingMode="contained"
				(onClick)="upgradeSubscription()" [useSubmitBehavior]="false">
			</dx-button>
		</div>
	</dxi-toolbar-item>


	<div *dxTemplate="let data of 'nonTrialContent'" class="subscriptionUpg">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" [height]="375">
			<div class="imgUc mrgT10 padB10">
				<img [src]="_AppCommonSrvc.cdnImgPath + 'subscription-upgrade.jpg'" alt="Subscription Upgrade">
			</div>
			<h3 class="ucTitle">Subscription<span class="purple">Upgrade!</span></h3>
			<p class="center mrgB0">{{subscription.message}}</p>
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="center" cssClass="mrgT10" [visible]="!is_trial">
		<div *dxTemplate>
			<dx-button class="secondaryBtn button mrgR20" text="Cancel" type="normal" stylingMode="text"
				(onClick)="doCancel()" [useSubmitBehavior]="false">
			</dx-button>
			<dx-button #purchaseOrUpgradeBtnRef class="primaryBtn button" text="Upgrade Plan"
				[icon]="_AppCommonSrvc.cdnSvgPath +'card-icon-white.svg'" type="normal" stylingMode="contained"
				(onClick)="upgradeSubscription()" [useSubmitBehavior]="false">
			</dx-button>
		</div>
	</dxi-toolbar-item>

</dx-popup>