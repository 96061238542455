import { DxDropDownBoxComponent, DxDataGridComponent } from 'devextreme-angular';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import { Subscription } from 'rxjs';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PageOptions, GetRecordsReq } from '@app/models/common.model';
import { ToastrService } from 'ngx-toastr';
import { GlobalPopoupHeight80Ratio, GlobalPopoupWidth80Ratio, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { TaskNotificationUI } from '@app/models/businessplan.model';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { Router } from '@angular/router';
@Component({
	selector: 'app-notifications-tab',
	templateUrl: './notifications-tab.component.html',
	styleUrls: ['./notifications-tab.component.css']
})
export class NotificationsTabComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('dxDropDownRefAssignToUser', {}) dxDropDownRefAssignToUser: DxDropDownBoxComponent;
	@ViewChild('dxListOfAssignToDataGrid', {}) dxListOfAssignToDataGrid: DxDataGridComponent;
	public currentTab: string;

	public displayedColumns: string[] = [
		"notificationtitle"
	];
	public dataSource: any = new MatTableDataSource();
	public pageOptions: PageOptions = new PageOptions();
	public activeRow: string;
	public isShowNotificationDetail: boolean = false;
	public notificationDetailData: any;
	public isSearchTextBox: boolean = false;
	public isRecordAvailabled: boolean;
	public view_task_notify: boolean = false;
	public enable_shortpay_notification: boolean = false;
	public searchFilterObj = {
		searchKeyword: '',
	};
	private getRecordsReq: GetRecordsReq = new GetRecordsReq();
	private currentEntity = 'notifications';
	private searchParams: any[] = [];
	private typeParams: string;
	private sort_field = 'created_date';
	private sort_dir = 'desc';
	private viewFields = [];

	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private permsn: any[] = [];
	public isViewNotificationTaskPopUp: boolean;
	public listOfDashboardConfigs: any = [];
	public aSelKeysCompany: any = [];
	public isDropdownOpenedCompany: boolean;
	public listOfTimeInvervals: any[] = [];
	public taskStatuses: any[] = [];
	// public taskNotificationUI: TaskNotificationUI = new TaskNotificationUI();
	public listOfTaskTypes: any[] = [];
	public taskNotificationUI: any;
	public listOfAssignToUsers: any[] = [];
	public popupHeight: number;
	public popupWidth: number;
	private getNotificationsTasksSbsn: Subscription;
	private getPropertyOptionsSubscription: Subscription;
	private getListOfAssignToUserSubscription: Subscription;
	public selectedListOfAssignTo: any[] = [];
	public arrSelectedAssignToList: any[] = [];
	public selectedListOfAssignToTags: any[] = [];
	public selectedAssignToLbl: string
	public clientsList: any;
	public AssignToGridBoxColumns: any[] = [
		{ dataField: 'sub_account_id', caption: 'Id', visible: false },
		{ dataField: 'first_name', caption: 'First Name', visible: true, sortOrder: "asc" },
		{ dataField: 'last_name', caption: 'Last Name', visible: true, sortOrder: "asc" },
	];
	public taskFilterValue: any[] = [];
	public isQBErpUser: boolean;
	private erpType: string;
	public isShowDownloadLogFileBtn: boolean = false;

	// #region for Angular Life Cycle
	constructor(private _LoginService: LoginService,
		private _Router: Router,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _GlobalStateService: GlobalStateService,
		private _IxDxAlertService: IxDxAlertService,
		private _NgxPermissionsService: NgxPermissionsService) {

	}

	ngOnInit() {
		// Get current permissions
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
				this.isQBErpUser = true;
			}
		}
		// End Get current permissions
		this.defaultTabSelections();
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('notification')) {
			this.view_task_notify = this._LoginService.loginUser.account_detail.notification['task_notify'] || false;
		}
		if (this._LoginService.loginUser.account_detail['enable_shortpay_notification']) {
			this.enable_shortpay_notification = this._LoginService.loginUser.account_detail.enable_shortpay_notification;
		}
	}

	ngAfterViewInit() {
	}

	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getNotificationsTasksSbsn) {
			this.getNotificationsTasksSbsn.unsubscribe();
		}
		if (this.getPropertyOptionsSubscription) {
			this.getPropertyOptionsSubscription.unsubscribe();
		}
		if (this.getListOfAssignToUserSubscription) {
			this.getListOfAssignToUserSubscription.unsubscribe();
		}
		// this._AppCommonSrvc.setNotificationTab('');
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		this.setDxPopupWidth();
	}

	private setDxPopupWidth() {
		const innerWidth = window.innerWidth;
		const innerHeight = window.innerHeight;
		this.popupWidth = (innerWidth * GlobalPopoupWidth80Ratio) / 100;
		this.popupHeight = (innerHeight * GlobalPopoupHeight80Ratio) / 100;
	}

	// #endregion

	// Method for Click on Tab
	public clickOnTab(selectedTab: string) {
		if (this.currentTab === selectedTab) {
			return;
		}
		this.currentTab = selectedTab;
		this._AppCommonSrvc.setNotificationTab(this.currentTab);
		switch (selectedTab) {
			case 'errorlog':
				this.clearSearch();
				this.resetPagination();
				this.inItErrorLog();
				this._AppCommonSrvc.setNotificationSubTab('');
				break;
			case 'notificationlog':
				this._Router.navigate(['/notifications/notificationslist']);
				this.clearSearch();
				this.resetPagination();
				this.inItNotificationLog();
				this._AppCommonSrvc.setNotificationSubTab('');
				break;
			case 'notificationtasks':
				this._Router.navigate(['/notifications/taskslist']);
				this.clearSearch();
				this.resetPagination();
				this.inItNotificationTasks();
				this._AppCommonSrvc.setNotificationSubTab('');
				break;
			case 'notificationapprovals':
				this._Router.navigate(['/notifications/approvals/chargebacks']);
				break;
		}
	}

	private defaultTabSelections() {
		let notificationTab = this._AppCommonSrvc.getNotificationTab();
		if (!notificationTab) {
			notificationTab = 'errorlog';
		}
		this.clickOnTab(notificationTab);
		// if (notificationTab === 'notificationtasks') {
		// 	this.currentTab = 'notificationtasks';
		// 	this.inItNotificationTasks();
		// } else {
		// 	this.currentTab = 'errorlog';
		// 	this.inItErrorLog();
		// }
		//this._AppCommonSrvc.setNotificationTab('');
	}

	// #region Filteration and Search functionality
	public onEnterPress(event) {
		if (event.keyCode === 13) {
			this.onSearchClick();
		}
	}

	// Method for Search any keyword
	public onSearchClick() {
		if (this.isSearchTextBox && this.searchFilterObj.searchKeyword === '') {
			this.onSearchCancel();
		} else {
			if (this.searchFilterObj.searchKeyword.trim() !== '' && this.searchFilterObj.searchKeyword !== null && this.searchFilterObj.searchKeyword !== undefined) {
				const nameWithCodeIndex = this.searchParams.findIndex(itemKey => itemKey.hasOwnProperty('name_with_code'));
				if (nameWithCodeIndex !== -1) {
					this.searchParams.splice(nameWithCodeIndex, 1);
				}
				this.searchParams.push({ 'name_with_code': this.searchFilterObj.searchKeyword.trim() });
				this.resetPagination(0);
				this.loadTabData();
				this.isSearchTextBox = true;
			}
		}
	}

	// Event for reset pagination start from zero.
	private resetPagination(pageIndex: number = 0) {
		this.pageOptions.pageIndex = pageIndex;
		if (this.paginator) {
			this.paginator.pageIndex = pageIndex;
			if (this.dataSource) {
				this.dataSource.paginator = this.paginator;
			}
		}
	}

	// For SearchFilter clear searchbox
	public onSearchCancel() {
		this.searchFilterObj.searchKeyword = '';
		this.isSearchTextBox = false;
		const nameWithCodeIndex = this.searchParams.findIndex(itemKey => itemKey.hasOwnProperty('name_with_code'));
		if (nameWithCodeIndex !== -1) {
			this.searchParams.splice(nameWithCodeIndex, 1);
		}
		this.resetPagination(0);
		this.loadTabData();
	}

	private clearSearch() {
		this.searchFilterObj.searchKeyword = '';
		const nameWithCodeIndex = this.searchParams.findIndex(itemKey => itemKey.hasOwnProperty('name_with_code'));
		if (nameWithCodeIndex !== -1) {
			this.searchParams.splice(nameWithCodeIndex, 1);
		}
	}
	// #endregion

	// Method for Remove Item of Error Log List
	public removedNotification(element) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('notifications', JSON.stringify([element.guid]));
				formData.append('type', 'delete');
				if (this.dataInReqSubscription) {
					this.dataInReqSubscription.unsubscribe();
				}
				this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
					next: (response) => {
						if (response && response.flag) {
							this.loadTabData();
							if (element.status === 'unread') {
								setTimeout(() => {
									this.updateNotificationCounter();
								}, 800);
							}
						} else {
							if (response.errorCode !== '1000') {
								this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
						}

					},
					error: (error) => {
						this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				});
			}
		});
	}

	// Method for Mark as Read notification request
	public marksAsReadNotification(element: any) {
		if (element && !element.guid) {
			return;
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('notifications', JSON.stringify([element.guid]));
		formData.append('type', 'mark_as_read');
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					element.status = 'read';
					this.updateNotificationCounter();
				} else {
					if (response.errorCode !== '1000') {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				}
			},
			error: (error) => {
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method for Show Notification Details.
	public notificationDetailClick(element: any) {
		this.isShowDownloadLogFileBtn = false;
		const viewFields = ['guid', 'ixcode', 'code', 'title', 'description', 'method', 'datetime', 'status', 'attachment', 'filedata'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', this.currentEntity);
		formData.append('view_fields', JSON.stringify(viewFields));
		formData.append('search', JSON.stringify([{ guid: element.guid }]));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.isShowNotificationDetail = false;
		this.notificationDetailData = undefined;
		this.activeRow = undefined;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.activeRow = element.guid;
					if (response.data && response.data.length > 0) {
						this.isShowNotificationDetail = true;
						this.notificationDetailData = response.data[0];
						// if (this.notificationDetailData['attachment']) {
						// 	this.notificationDetailData['attachment'] = JSON.parse(JSON.stringify(this.notificationDetailData['attachment']));
						// }
						if (element.status === 'unread') {
							this.marksAsReadNotification(element);
						}
						this.showDownloadLogFileBtn(element);
					}
				} else {
					if (response.errorCode !== '1000') {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method for Load ErrorLog Tab Data
	private inItErrorLog() {
		this.currentEntity = 'notifications';
		this.viewFields = ['guid', 'title', 'datetime', 'method', 'status', 'type', 'subtype'];
		// this.searchParams = [];
		// this.searchParams.push({ 'type': 'error' });
		// this.searchParams.push({ 'subtype': 'functional' });
		this.typeParams = 'error';
		this.loadTabData();
	}

	// Method for Load NotificationsLog Tab Data
	private inItNotificationLog() {
		this.currentEntity = 'notifications';
		this.viewFields = ['guid', 'title', 'datetime', 'method', 'status', 'type', 'subtype'];
		// this.searchParams = [];
		// this.searchParams.push({ 'type': 'notification' });
		this.typeParams = 'notification';
		this.loadTabData();
	}

	// Method for Load Task Tab Data
	private inItNotificationTasks() {
		this.currentEntity = 'notifications';
		this.viewFields = ['guid', 'title', 'datetime', 'method', 'status', 'type', 'subtype', 'attachment'];
		// this.searchParams = [];
		// this.searchParams.push({ 'type': 'task' });
		this.typeParams = 'task';
		this.loadTabData();
		this.fetchListOfTaskTypes();
		this.getListOfAssignToUsers();
		this.loadListOfCompanies();
	}

	// Event for Pagination on Table
	public paginatorEventData(event: any) {
		this.pageOptions.pageIndex = event.pageIndex;
		if (this.pageOptions.pageSize !== event.pageSize) {
			this.pageOptions.pageSize = event.pageSize;
			this.resetPagination();
		}
		this.loadTabData();
	}

	// Set request object to fetch data of selected tabs
	private loadTabData() {
		this.getRecordsReq.usr = this._LoginService.loginUser.user;
		this.getRecordsReq.token = this._LoginService.loginUser.token;
		this.getRecordsReq.entity = this.currentEntity;
		this.getRecordsReq.method = 'getEntityData';
		this.getRecordsReq.itemsperpage = this.pageOptions.pageSize;
		this.getRecordsReq.page = this.pageOptions.pageIndex + 1;
		this.getRecordsReq.search = this.searchParams;
		this.getRecordsReq.sort_field = this.sort_field;
		this.getRecordsReq.sort_dir = this.sort_dir;
		this.getRecordsReq.view_fields = JSON.stringify(this.viewFields);
		if (this.typeParams) {
			this.getRecordsReq['type'] = this.typeParams;
		}
		this.getNotificationsRecords(this.getRecordsReq);
	}

	// Set table grid data
	private getNotificationsRecords(getRecordsReq: GetRecordsReq) {
		const formData = new FormData();
		for (const key in getRecordsReq) {
			if (key === 'search') {
				formData.append(key, JSON.stringify(getRecordsReq[key]));
			} else {
				formData.append(key, getRecordsReq[key]);
			}
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.isRecordAvailabled = false;
		this.isShowNotificationDetail = false;
		this.notificationDetailData = undefined;
		this.activeRow = undefined;
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					if (response.data && response.data.length <= 0) {
						this.isRecordAvailabled = true;
					}
					this.dataSource = new MatTableDataSource(response.data || []);
					this.paginator.length = response.totalRecords;
				} else {
					this.isRecordAvailabled = true;
					this.dataSource = new MatTableDataSource([]);
					this.paginator.length = 0;
					if (response.errorCode !== '1000') {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				}
			},
			error: (error) => {
				this.isRecordAvailabled = true;
				this._LoaderService.hide();
				this.dataSource = new MatTableDataSource([]);
				this.paginator.length = 0;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method for Fetched & Update Notification Counter on Header page.
	private updateNotificationCounter() {
		const fetchedSearchCriteria = {
			isResponse: true,
			ts: new Date().getTime(),
			data: {
				status: 'unread',
				type: ['error', 'notification'],
				// subtype: ['functional']
			}
		};
		this._GlobalStateService.notifyDataChangedDuplicate('fetchedNotificationCounter', fetchedSearchCriteria);
	}

	public convertJsonObject(attachment) {
		if (attachment) {
			return JSON.stringify(attachment);
		} else {
			return attachment;
		}
	}

	// view Notification Task Pop-up
	public doViewNotification(notification_task_guid: string) {
		this.getNotificationTaskDataSourceList(notification_task_guid);
	}

	public doHiddenTaskViewPopop() {
		this.isViewNotificationTaskPopUp = false;
	}

	public getAssignToDisplayExpr() {

	}

	public doChangedTaskStatus(event) {

	}

	getNotificationTaskDataSourceList(notification_task_guid) {
		let searchParams: any[] = [['notification_task_guid', '=', notification_task_guid]];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.TASKS);
		formData.append('is_dropdown', 'true');
		formData.append('filter', JSON.stringify(searchParams));
		if (this.getNotificationsTasksSbsn) {
			this.getNotificationsTasksSbsn.unsubscribe();
		}
		this.getNotificationsTasksSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						//this.taskNotificationUI = new TaskNotificationUI();
						// if (response.data && response.data.length === 1) {
						if (response.data && response.data.length > 0) {
							this.taskNotificationUI = response.data[0] || [];
							this.isViewNotificationTaskPopUp = true;
						}
					}
					else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				}
			},
			error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Fetch list of Task types
	private fetchListOfTaskTypes() {
		let dynamic_groups = [];
		let dynamic_properties = [];
		try {
			dynamic_groups = this._LoginService.loginUser.account_detail.dynamic_groups || [];
			dynamic_properties = this._LoginService.loginUser.account_detail.dynamic_properties || [];
		} catch (error) {
			dynamic_groups = [];
			dynamic_properties = [];
		}

		const dgIndex = dynamic_groups.findIndex(dg => dg.group_name.toString().toLowerCase() === 'task');
		if (dgIndex !== -1) {
			const dgData = dynamic_groups[dgIndex];
			const dpIndex = dynamic_properties.findIndex(dp => dp.group_guid === dgData.guid && dp.property.toString().toLowerCase() === 'tasktype');
			if (dpIndex !== -1) {
				const dpData = dynamic_properties[dpIndex];
				const reqFormData = new FormData();
				reqFormData.append('usr', this._LoginService.loginUser.user);
				reqFormData.append('token', this._LoginService.loginUser.token);
				reqFormData.append('method', 'getEntityData');
				reqFormData.append('entity', 'dynamicPropertyOptions');
				reqFormData.append('search', JSON.stringify([{ property_guid: dpData.guid }]));
				reqFormData.append('sort_dir', 'asc');
				reqFormData.append('sort_field', 'sequence');
				reqFormData.append('view_fields', JSON.stringify(['guid', 'property_guid', 'value', 'label', 'sequence']));
				if (this.getPropertyOptionsSubscription) {
					this.getPropertyOptionsSubscription.unsubscribe();
				}
				this.listOfTaskTypes = [];
				this._LoaderService.show();
				this.getPropertyOptionsSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
					next: (response) => {
						this._LoaderService.hide();
						if (response && response.flag) {
							this.listOfTaskTypes = response.data || [];
							this._GlobalStateService.notifyDataChangedDuplicate('GOT_TASK_TYPE_DATA_EVENT', { isDefault: true });
						} else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
						}
					},
					error: (error) => {
						this._LoaderService.hide();
						this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				});
			}
		}
	}
	// Method used to Filtered AssignTo List from assignto property of Lead, Prospect, Client
	// Method used to Fetched list of AssignTO Users
	private getListOfAssignToUsers() {
		let listOfUserTypes = [];
		try {
			listOfUserTypes = this._LoginService.loginUser.account_detail.userType || [];
		} catch (error) {
			listOfUserTypes = [];
		}
		const selectedListOfUserTypes = listOfUserTypes.filter(userType => userType.can_receive_incentives === true);

		if (selectedListOfUserTypes && selectedListOfUserTypes.length > 0) {
			const listOfRoles = [];
			selectedListOfUserTypes.forEach(userType => {
				listOfRoles.push(userType.user_type);
			});
			const listOfSearches = [];
			listOfSearches.push({ status: 'active' });
			listOfSearches.push({ role: listOfRoles });
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('method', 'getEntityData');
			reqFormData.append('entity', 'subusers');
			reqFormData.append('is_dropdown', 'true');
			reqFormData.append('search', JSON.stringify(listOfSearches));
			const viewFields = ['sub_account_id', 'first_name', 'last_name', 'role', 'status'];
			reqFormData.append('view_fields', JSON.stringify(viewFields));
			if (this.getListOfAssignToUserSubscription) {
				this.getListOfAssignToUserSubscription.unsubscribe();
			}
			this.listOfAssignToUsers = [];
			this.getListOfAssignToUserSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
				next: (response) => {
					if (response) {
						if (response.flag) {
							if (response.data) {
								this.listOfAssignToUsers = response.data || [];
							}
						}
						else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
						}
					}
				},
				error: (error) => {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			});
		}
	}

	public doDisplayValueFormaterAssignToUers(elements) {
		return Array.isArray(elements) ? elements.length + ' User(s) Selected' : '';
	}
	public doChangeDropdownAssignToUers(e: any) {
		if (e.value === null) {
			this.taskNotificationUI.assignto = [];
		}
	}

	private setTagboxAssigTo(selectListOfAssignTo: any[] = []) {
		this.selectedListOfAssignToTags = [];
		for (let i = 0; i < selectListOfAssignTo.length; i++) {
			this.selectedListOfAssignToTags.push(selectListOfAssignTo[i].first_name + ' ' + selectListOfAssignTo[i].last_name);
		}
	}

	public onInitializedAssignTo(e) {
		setTimeout(() => {
			this.dxListOfAssignToDataGrid.selectedRowKeys = this.taskNotificationUI.assignto;
		}, 50);
		setTimeout(() => {
			const selectedRowsData = e.component.getSelectedRowsData() || [];
			this.setTagboxAssigTo(selectedRowsData);
		}, 700);
	}

	public doChangeDatagridAssignTo(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.taskNotificationUI.assignto = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.taskNotificationUI.assignto.push(e.selectedRowsData[i].first_name);
			}
		}
		this.setTagboxAssigTo(e.selectedRowsData);
	}

	private loadListOfCompanies() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CRMDATA,
		};

		this.clientsList = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: function (method, ajaxOptions) {
			},
			onLoaded(result) {
			}
		});
	}

	//#region  Download Error Log File IX-2705
	showDownloadLogFileBtn(element: any) {
		if (element['subtype'] && element['type']) {
			if ((element.type === 'error' || element.type === 'notification') && element.subtype === 'Cron File Process')
				this.isShowDownloadLogFileBtn = true;
		}
	}
	public doClickDownloadLogFile(notificationDetailData: any) {
		if (notificationDetailData && notificationDetailData.hasOwnProperty('filedata')) {
			const fileData = notificationDetailData.filedata || [];
			if (fileData && fileData.hasOwnProperty('fileguid')) {
				const fileguid = fileData.fileguid || '';
				const filename = fileData.filename || '';
				let finalUrl = environment.URL_GET_DOCUMENT + '?usr=' + this._LoginService.loginUser.account_detail.usr
				finalUrl = finalUrl + '&docid=' + fileguid + '&filename=' + filename + '&file=processfileslog';
				window.open(finalUrl, '_blank');
			}
		} else {
			this._ToastrService.info('File Data Not Found !');
		}
	}
	//#endregion
}


