<div class="numberBlock hoverClr">
	<span (click)="doCalculateRoyalty()" id="calculateRoyalty" class="settingIcon dx-icon-convertorder"
		[class]="{'highlight':isCalculationRoyaltyComplete }">
	</span>
	<dx-tooltip target="#calculateRoyalty" showEvent="dxhoverstart" hideEvent="dxhoverend" position="bottom">
		<div *dxTemplate="let data = data of 'content'">
			{{isCalculationRoyaltyComplete ? 'Click to update current settlement period for all contracts' :
			'Calculation in progress'}}
		</div>
	</dx-tooltip>
	<div (click)="tilesClick('ROYALTY')">
		<span class="contractIcon">
			<span class="icon-royalty icon-set1"></span>
		</span>
		<div class="blockText">
			<strong>
				<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
				<span *ngIf="!resourcesLoaded">
					<span *ngIf="!isShowFullValue" (click)="doClickToShowFullValue($event)"
						class="dashboardamount shortamount">
						{{commissionDueForTheMonth || 0 | customFormatCurrencyPipe}}
						<span *ngIf="commissionDueForTheMonth >= 1000" class="dashboardamounttext">Click to see full
							value</span>
					</span>
					<div *ngIf="isShowFullValue" class="fullamount"
						[ngClass]="{'trillionamount ': getLastCharacterFromSortValue ===  'T' ,'quadrillionamount': getLastCharacterFromSortValue === 'Q' || getLastCharacterFromSortValue ==='E'}">
						{{commissionDueForTheMonth || 0 | customCurrencyPipe }}
					</div>
				</span>
			</strong>
			<span class="blockDate">{{currentMonthName || ''}}</span>
			<!-- <span class="blockDate"
			*ngIf="settlementPeriodStart && settlementPeriodEnd && settlementPeriodStart !== '' && settlementPeriodEnd !==''">{{ settlementPeriodStart | date: 'yyyy-MM-dd' }}
			to
			{{ settlementPeriodEnd | date: 'yyyy-MM-dd' }}
		</span> -->
			<span>Open Royalty</span>
		</div>
	</div>
</div>