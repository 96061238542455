import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumbersOnlyDirective, AlphaNumericsOnlyDirective } from "./../directives/numbers-only.directive";
import { DecimalNumbersOnlyDirective } from "./../directives/numbers-only.directive";
import { AutoFocusDirective, DebounceClickDirective } from "./../directives/auto-focus.directive";
@NgModule({
	declarations: [
		NumbersOnlyDirective,
		AutoFocusDirective,
		DecimalNumbersOnlyDirective,
		AlphaNumericsOnlyDirective,
		DebounceClickDirective
	],
	imports: [CommonModule],
	exports: [
		NumbersOnlyDirective,
		AutoFocusDirective,
		DecimalNumbersOnlyDirective,
		AlphaNumericsOnlyDirective,
		DebounceClickDirective
	]
})
export class ResharedModule { }
