<div class="numberBlock hoverClr" (click)="tilesClick('LEADS')">
	<span class="contractIcon">
		<span class="icon-leads_1 icon-set1"></span>
	</span>
	<div class="blockText">
		<strong>
			<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
			<span *ngIf="!resourcesLoaded">{{openLeads || 0 | customNumberSuffixPipe}}</span>
		</strong>
		<span class="blockDate">Active</span>
		Leads
	</div>
</div>