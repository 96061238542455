import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../login.service';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root'
})
export class SuccessInterceptorService implements HttpInterceptor {
	constructor(private _router: Router,
		private activatedRoute: ActivatedRoute, private loginService: LoginService,
		private dialogRef: MatDialog) {

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// if (this.loginService.loginUser && this.loginService.loginUser.token) {
		// 	request = request.clone({
		// 		body: '?token=' + this.loginService.loginUser.token + request.body
		// 	});
		// }
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					if (event.body !== null && event.body !== undefined) {
						if (event.body.flag === false && event.body.errorCode === '1000') {
							if (this.loginService.loginUser !== undefined) {
								this.loginService.loginUser.user = '';
								this.loginService.loginUser.pwd = '';
								this.loginService.loginUser.token = '';
								this.loginService.loginUser.account_detail = null;
								this.loginService.currentsidemenu = '';
							}
							this.dialogRef.closeAll();
							this._router.navigate(['/auth/login'], { replaceUrl: true });
						}
					}
				}
				return event;
			}));
	}
}