import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import * as _ from 'underscore';
import { Component, OnInit, AfterViewInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { ClaimedItemsModel } from "@app/models/billback-chargeback-contract.model";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataService } from '@app/services/data.service';
import { MatDialogRef } from '@angular/material/dialog';
declare function callAccordion(): any;
@Component({
	selector: 'app-eucc-claimed-items-list-dialog',
	templateUrl: './eucc-claimed-items-list-dialog.component.html',
	styleUrls: ['./eucc-claimed-items-list-dialog.component.css']
})
export class EuccClaimedItemsListDialogComponent implements OnInit, AfterViewInit {
	// Api call request object
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;

	public productList: any;
	public conproductList: any;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;

	public totalClaimed: any = 0;
	public uomListData: any = [];
	public claimedItemsModel = new ClaimedItemsModel();
	public pageTitle: string = 'Enter Item Quantities';
	public isShowPopup: boolean = true;
	@Input() uploadClaimsItemListProps: any;
	@Output() closeClaimsItemListPopup = new EventEmitter<any>();
	constructor(
		private _ToastrService: ToastrService,
		private _BillbackService: BillbackService,
		private _ConfirmationDialogService: ConfirmationDialogService,
		private _DataService: DataService,
	) { }

	ngOnInit() {
		this.uomListData = this._DataService.getUOMs();
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		this.productList = [];
		this.conproductList = [];

		this.uploadClaimsItemListProps.productList.forEach(euItems => {
			const itemData = euItems['items'] || [];
			if (itemData && itemData.length > 0) {
				itemData.forEach(item => {
					const itemObj = {
						end_user_id: euItems['end_user_id'] || '',
						end_user_name: euItems['end_user_name'] || '',
						pricelistno: euItems['pricelistno'] || '',
						pricelistname: euItems['pricelistname'] || '',
						itemsku: item['itemsku'] || '',
						itemname: item['itemname'] || '',
						description: item['description'] || '',
						erp_primary_key: item['erp_primary_key'] || '',
						uomList: item['uomList'] || [],
						selectedClaimedUom: item['selectedUom'] || '',
						last_invoice_date: item['last_invoice_date'] || '',
						// discountedPrice: item['chargebackAmount'] || '',
						chargebackAmount: item['chargebackAmount'] || '',
						available: 0,
						billed: 0,
						bought: 0,
						claimed: 0,
						sold: 0
					}
					this.productList.push(itemObj);
				});
			}
		});

	}

	// tslint:disable-next-line:use-life-cycle-interface
	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		callAccordion();
	}

	selectClaimedUomFromList(selectedClaimedUom, cpl) {
		const selectClaimedProductList = this.productList[cpl.erp_primary_key];
		if (selectClaimedProductList && selectClaimedProductList.length > 0) {
			selectClaimedProductList.forEach(element => {
				element.selectedClaimedUom = selectedClaimedUom;
			});
		}
	}
	sumClaimed(cpl, pl) {
		this.totalClaimed = 0;
		pl.claimed = parseInt(pl.claimed.toString()) || 0;
		for (let i = 0; i < this.productList.length; i++) {
			const consTotalClaimed = parseInt(this.totalClaimed) || 0;
			const consClaimed = parseInt(this.productList[i].claimed) || 0;
			this.totalClaimed = (consTotalClaimed + consClaimed) || 0;
		}
	}

	claimedItemSubmit() {
		if (this.totalClaimed > 0) {
			this._ConfirmationDialogService.setDialogConfig({
				popupTitle: 'Confirm',
				popupMessage: 'Please confirm item quantity. You will not be able to change/remove them?',
				popupOkBtnText: 'Add',
				popupCancelBtnText: 'Close',
				popupInfoBoxClass: 'addTopIcon'
			});
			const dialogRef1: MatDialogRef<any> = this._ConfirmationDialogService.confirmDialog();
			dialogRef1.afterClosed().subscribe(dialogResult => {
				if (dialogResult) {
					this.saveClaimedItem();
				}
			});
		} else {
			this._ToastrService.info('Please claimed at least one quantity', 'Saved', { closeButton: true, tapToDismiss: true });
			return false;
		}
	}

	saveClaimedItem() {
		let claimedItems = [];
		claimedItems = this.productList;
		this.productList = [];
		this.conproductList = [];
		this.closeClaimsItemListPopup.emit(claimedItems);
	}

	public doHiddenPopop(e) {
		this.isShowPopup = false;
	}
}


