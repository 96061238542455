import { saveAs } from 'file-saver';
import { BcBillbackDocumentsTabComponent } from './bc-billback-documents-tab/bc-billback-documents-tab.component';
import { AnySoaRecord } from 'dns';
import * as moment from 'moment';
import * as _ from 'underscore';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS, MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { Subscription } from 'rxjs';
import { BillbackContractModelRequest, BillbackContractModelUI } from '@app/models/billback-contract.model';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { BcBilledItemsListDialogComponent } from './bc-billed-items-list-dialog/bc-billed-items-list-dialog.component';
import { BcItemsTabComponent } from './bc-items-tab/bc-items-tab.component';
import { DistributionChargebackContractService } from '@app/services/contract-service/billback/distribution-chargeback-contract.service';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { BillBackChargebackContractModelUI } from '@app/models/billback-chargeback-contract.model';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';

@Component({
	selector: 'app-billback-contract',
	templateUrl: './billback-contract.component.html',
	styleUrls: ['./billback-contract.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
	]
})
export class BillbackContractComponent implements OnInit, OnDestroy {
	@ViewChild("supplierGrid", {}) supplierGrid: DxDataGridComponent;
	public billbackTypeRadio: any[];
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public editBillbackGuid: any;
	public productSearchItemType: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public selectedTabVal: string;
	public tabItemsList: any[] = [];
	public listOfUoms: any[] = [];
	public supplierDataSource: any;
	public supplierGridBoxValue: any;
	public supplierGridBoxColumns: any;
	public isSupplierDropDownBoxOpened: boolean = false;
	public itemTabDataList: any = [];
	public billBackDocumentsTabDatalist: any = [];
	public itemTabDataSource: any;
	public showNotification: any;
	public guid = new Guid();
	public pageOptions: PageOptions = new PageOptions();
	public billbackContractModelUI = new BillBackChargebackContractModelUI();
	public billbackContractModelRequest = new BillbackContractModelRequest();
	public dialogConfig: MatDialogConfig = new MatDialogConfig();
	@ViewChild(BcItemsTabComponent) childBcItemsTabComponent: BcItemsTabComponent;
	@ViewChild(BcBillbackDocumentsTabComponent) childBcBillbackDocumentsTabComponent: BcBillbackDocumentsTabComponent;
	public isShowBilledItemsListPopup: boolean = false;
	public billedItemsProps: any;
	constructor(
		public _MatDialog: MatDialog,
		private _Router: Router,
		private _ToastrService: ToastrService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _DataService: DataService,
		private _BillbackContractService: BillbackContractService,
		private _ChargebackContractService: ChargebackContractService,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService,
		private _DistributionChargebackContractService: DistributionChargebackContractService,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,
		private _GlobalStateService: GlobalStateService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _BillbackService: BillbackService,
		private _ChangeDetectorRef: ChangeDetectorRef,
		private _IxDxAlertService: IxDxAlertService
	) { }

	ngOnInit() {
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		}
		// check Is QB User (no need to view code of QB user)
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}

		this.editBillbackGuid = this._BillbackContractService.getEditBillbackGuid();
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	// Call for take a UOM list from child Item UOM .
	getChildUomListEvent() {
		this._GlobalStateService.unsubscribe('GET_CHILD_UOM_LIST_EVENT');
		this._GlobalStateService.subscribe('GET_CHILD_UOM_LIST_EVENT', response => {
			this.listOfUoms = response.listOfUoms;
		});
	}

	preInit() {

		this.billbackTypeRadio = this._BillbackService.fetchBillbackTypeListRadio();
		this.billbackContractModelUI.billBackType = 'billback';
		this.tabItemsList = this._BillbackContractService.tabItemsList;
		this.supplierDataSource = this._DataService.getVendors();
		if (this.editBillbackGuid) {
			this.editBillbackContract();
		} else {
			this.selectedTabVal = 'items';
		}
		this.supplierGridBoxColumns = [{ dataField: 'name', caption: 'Suppilers' }];
		this.disableBillbackInputFields();
		this.getChildUomListEvent();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	ngAfterContentChecked() {
		// this.itemTabDataGridRef = this.childBcItemsTabComponent.itemTabDataGridRef;
		// this.billbackDocumentsTabDataGridContainer = this.childBcBillbackDocumentsTabComponent.billbackDocumentsTabDataGridContainer;
		this._ChangeDetectorRef.detectChanges();
	}

	public doChangeBillBackType(e) {
		this.billbackContractModelUI.billBackType = e.value;
		this._BillbackService.setBillbackType(this.billbackContractModelUI.billBackType);
		switch (this.billbackContractModelUI.billBackType) {
			case 'billback':
				// this._BillbackContractService.setEditBillbackGuid('');
				// this._Router.navigate(['/contracts/billbackchargeback/billback-contract/']);
				break;
			case 'chargeback':
				this._ChargebackContractService.setEditChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/chargeback-contract/']);
				break;
			case 'enduserschargeback':
				this._EndUsersChargebackContractService.setEditEndUsersChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/end-users-chargeback-contract/']);
				break;
			case 'distributionchargeback':
				this._DistributionChargebackContractService.setEditDistributionChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/distribution-chargeback-contract/']);
				break;
			case 'minmaxenduserschargeback':
				this._MinMaxEndUsersChargebackContractService.setEditMinMaxEndUsersChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/min-max-end-users-chargeback-contract/']);
				break;
		}
	}

	public displayExprSupplierName(item) {
		if (!item) {
			return "";
		}
		const name = item.name;
		return name.toString();
	}

	public onSupplierSelectionChanged(e) {
		if (e.name === "value") {
			if (this.supplierGrid) {
				const selectedSupplierArr = this.supplierGrid.instance.getSelectedRowsData();
				if (selectedSupplierArr && selectedSupplierArr.length > 0) {
					const selectedSupplierObj = selectedSupplierArr[0];
					if (selectedSupplierObj) {
						this.isSupplierDropDownBoxOpened = false;
						this.billbackContractModelUI.selectSupplierId = selectedSupplierObj.code;
						this.billbackContractModelUI.selectSupplier = selectedSupplierObj.name;
					}
				}
			}
			this._ChangeDetectorRef.detectChanges();
		}
	}

	public doClickTab(val) {
		this.selectedTabVal = val;
	}

	public checkValidation(): boolean {
		if (!this.billbackContractModelUI.contractName) {
			this._ToastrService.info('Contract Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.billbackContractModelUI.selectSupplier) {
			this._ToastrService.info('Supplier is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.billbackContractModelUI.startDate) {
			this._ToastrService.info('Start Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
	}

	public doSaveBillbackContract(isProductSave?: boolean) {
		if (this.supplierGrid && !this.editBillbackGuid) {
			let supplierDetails: any = this.supplierGrid.instance.getSelectedRowsData();
			if (supplierDetails && supplierDetails.length > 0) {
				this.billbackContractModelUI.selectSupplierId = supplierDetails[0].code;
				this.billbackContractModelUI.selectSupplier = supplierDetails[0].name;
			}
		}
		if (this.checkValidation()) {
			return false;
		}

		this.billbackContractModelRequest = new BillbackContractModelRequest();
		if (this._BillbackContractService.getEditBillbackGuid()) {
			this.billbackContractModelRequest.guid = this._BillbackContractService.getEditBillbackGuid();
		} else {
			this.billbackContractModelRequest.guid = this.guid.newGuid();
		}
		this.billbackContractModelRequest.ixcode = this.billbackContractModelUI.ixcode;
		this.billbackContractModelRequest.contractName = this.billbackContractModelUI.contractName;
		this.billbackContractModelRequest.selectSupplierId = this.billbackContractModelUI.selectSupplierId;
		this.billbackContractModelRequest.selectSupplier = this.billbackContractModelUI.selectSupplier;
		this.billbackContractModelRequest.startDate = moment(this.billbackContractModelUI.startDate).format('YYYY-MM-DD') || '';
		this.billbackContractModelRequest.endDate = moment(this.billbackContractModelUI.endDate).format('YYYY-MM-DD') || '';
		this.billbackContractModelRequest.billBackType = this.billbackContractModelUI.billBackType;
		this.billbackContractModelRequest.is_active = true;
		this.billbackContractModelRequest.items = this.itemTabDataList || [];
		this.billbackContractModelRequest.billbackDocuments = this.billBackDocumentsTabDatalist || [];
		// if (this.productSearchItemType === 'price') {
		// 	this.billbackContractModelUI.pricelist_modified_date = this.listOfPrices[0].modified_date;
		// 	this.billbackContractModelRequest.pricelist_modified_date = this.billbackContractModelUI.pricelist_modified_date;
		// 	this.billbackContractModelRequest.pricelist_modified_user_action = this.billbackContractModelUI.pricelist_modified_user_action;
		// }
		const arryBillBackContracts = [];
		arryBillBackContracts.push(JSON.parse(JSON.stringify(this.billbackContractModelRequest)));
		this.callSaveBillbackContract(arryBillBackContracts);
	}

	public doSaveBillbackItemTabData(event) {
		// upload item did not get new list for item tab 
		if (event === 'uploaditem') {
			this.itemTabDataList = this._BillbackContractService.getItemTabDataList();
		}
		if (event === 'claimItemBillback') {
			this.showNotification = "No";
			this.itemTabDataList = this._BillbackContractService.getItemTabDataList();
		}
		this.doSaveBillbackContract();
	}

	public callSaveBillbackContract(arryBillbackContract: any[]) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('billbackcontracts', JSON.stringify(arryBillbackContract));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq((formData)).subscribe(
			response => {
				if (response.flag) {
					if (this.showNotification !== 'No') {
						this._ToastrService.success('Save Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}
					const objBillbackContracts = response.data.billbackcontracts;
					let billbackContracts: any;
					if (objBillbackContracts && objBillbackContracts.length > 0) {
						billbackContracts = objBillbackContracts[0];
						if (billbackContracts && billbackContracts.billbackItems) {
							this.itemTabDataList = billbackContracts.billbackItems;
							this.itemTabDataSource = billbackContracts.billbackItems;
						}
						this._BillbackContractService.setEditBillbackGuid(billbackContracts.guid);
						this.editBillbackGuid = billbackContracts.guid;
						this.billbackContractModelRequest.ixcode = billbackContracts.ixcode;
						this.billbackContractModelUI.ixcode = billbackContracts.ixcode;
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
				this.disableBillbackInputFields();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}
	disableBillbackInputFields() {
		if (this.editBillbackGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}

	}
	public editBillbackContract() {
		let searchParams = [{ 'guid': this.editBillbackGuid }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'billbackcontracts');
		formData.append('search', JSON.stringify(searchParams));
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(
			response => {
				if (response.flag) {
					const editBillbackContractDetails = response.data[0];
					this.billbackContractModelUI.guid = editBillbackContractDetails.guid;
					this.billbackContractModelUI.ixcode = editBillbackContractDetails.ixcode;
					this.billbackContractModelUI.contractName = editBillbackContractDetails.contractName;
					this.billbackContractModelUI.selectSupplierId = editBillbackContractDetails.selectSupplierId;
					this.billbackContractModelUI.selectSupplier = editBillbackContractDetails.selectSupplier;
					this.billbackContractModelUI.startDate = moment(editBillbackContractDetails.startDate).format('YYYY-MM-DD') || '';
					this.billbackContractModelUI.endDate = moment(editBillbackContractDetails.endDate).format('YYYY-MM-DD') || '';
					this.billbackContractModelUI.billBackType = editBillbackContractDetails.billBackType;
					this.billbackContractModelUI.is_active = editBillbackContractDetails.is_active;
					this.itemTabDataList = editBillbackContractDetails.items || [];
					this.itemTabDataSource = this.itemTabDataList;
					this.billBackDocumentsTabDatalist = editBillbackContractDetails.billbackDocuments || [];
					this.supplierGridBoxValue = [editBillbackContractDetails.selectSupplierId];
					// this.billbackContractModelUI.pricelist_modified_date = editBillbackContractDetails.pricelist_modified_date;
					// this.billbackContractModelUI.pricelist_modified_user_action = editBillbackContractDetails.pricelist_modified_user_action;
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this.selectedTabVal = 'items';
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this.selectedTabVal = 'items';
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	public calculateBoughtQuantityClick(flag?: boolean) {
		if (!this.editBillbackGuid) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (this.itemTabDataList.length === 0) {
			this._ToastrService.info('Without product item will not update bought and sold quantitie.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		this._LoaderService.show();
		const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.editBillbackGuid + '&method=updateBoughtQuantity'
		this.dataInReqSubscription = this._RestApiService.doDataOutReq((reqFormData)).subscribe(
			response => {
				if (response.flag) {
					this.itemTabDataList = JSON.parse(JSON.stringify(response.data));
					this.itemTabDataSource = this.itemTabDataList;
					if (!flag) {
						this._ToastrService.success('Update Quantities Bought And Sold Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}
					this.selectedTabVal = 'items';
					if (this.childBcBillbackDocumentsTabComponent) {
						this.childBcBillbackDocumentsTabComponent.refreshDataGridComponent();
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	public generateBillbackDocumentClick() {
		if (!this.editBillbackGuid) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (this.itemTabDataList.length === 0) {
			this._ToastrService.info('Without product item will not update bought and sold quantitie.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		let isAvailableQty = this.checkIsAvailableQty();
		if (isAvailableQty) {
			this.showBilledItemsListDialog();
		} else {
			this.climedAllItem();
		}
	}

	// climed item list all item
	climedAllItem() {
		this._LoaderService.show();
		const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.editBillbackGuid + '&method=generateBillbackDocument'
		this.dataInReqSubscription = this._RestApiService.doDataOutReq((reqFormData)).subscribe(
			response => {
				if (response.flag) {
					if (response.data) {
						this.itemTabDataList = JSON.parse(JSON.stringify(response.data.contractItems));
						this.itemTabDataSource = this.itemTabDataList;
						this.billBackDocumentsTabDatalist = JSON.parse(JSON.stringify(response.data.billbackDocuments));
						this.billbackContractModelUI.lastUpdatedDate = moment(response.data.lastUpdatedDate).format('YYYY-MM-DD') || '';
					}
					this._ToastrService.success('Billback document generated successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	//show billed item list dialog 
	showBilledItemsListDialog() {
		this.billedItemsProps = {
			listOfUoms: this.listOfUoms || [],
			itemTabDataList: this.itemTabDataList,
		};
		this.isShowBilledItemsListPopup = false;
		setTimeout(() => {
			this.isShowBilledItemsListPopup = true;
		}, 20);
	}

	// Check is multiple customers and Available Qty is > 2 

	checkIsAvailableQty() {
		if (this.itemTabDataList && this.itemTabDataList.length > 0) {
			let isAvailableQty = false;
			this.itemTabDataList.forEach(elementItem => {
				if (elementItem.customers && elementItem.customers.length > 1) {
					elementItem.customers.forEach(elementCustomer => {
						if (!isAvailableQty && elementCustomer.available > 0) {
							isAvailableQty = true;
						}
					});
				}
			});
			return isAvailableQty;
		}
	}

	// Set Settlement StartDate and EndDate
	compareTwoDates() {
		// check start date and end date validate
		if (new Date(this.billbackContractModelUI.endDate) < new Date(this.billbackContractModelUI.startDate)) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
		}
	}

	//#region Call API Download chargeback/enduser chargeback file
	downloadPriceList() {
		this._LoaderService.show();
		let reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.editBillbackGuid;
		reqFormData = reqFormData + '&method=downloadBillbackContract'
		this.dataInReqSubscription = this._RestApiService.doDataOutReqDownloadFile((reqFormData)).subscribe(
			response => {
				this.downloadFile(response);
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	downloadFile(data: Blob) {
		const blob = new Blob([data], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		const ixcode = this.billbackContractModelUI.ixcode ? this.billbackContractModelUI.ixcode : this.billbackContractModelUI.ixcode;
		link.download = ixcode + '_' + this.editBillbackGuid;
		link.target = '_blank';
		link.dispatchEvent(new MouseEvent('click'));
	}
	//#endregion

	public doExportToExcel(e) {
		const workbook = new ExcelJS.Workbook();
		let workSheet, componentObj;
		if (this.childBcItemsTabComponent && this.childBcItemsTabComponent.itemTabDataGridRef) {
			workSheet = workbook.addWorksheet('Items');
			componentObj = this.childBcItemsTabComponent.itemTabDataGridRef.instance;
		}
		if (this.childBcBillbackDocumentsTabComponent && this.childBcBillbackDocumentsTabComponent.billbackDocumentsTabDataGridContainer) {
			workSheet = workbook.addWorksheet('Billback Documents');
			componentObj = this.childBcBillbackDocumentsTabComponent.billbackDocumentsTabDataGridContainer.instance;
		}
		if (componentObj && componentObj.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		this._BillbackService.exportBillbackChargebackExcel(workbook, this.billbackContractModelUI, workSheet, componentObj);
	}

	public doClosebilledItemsPopup(e) {
		this.isShowBilledItemsListPopup = false;
	}

}
// function saveAs(arg0: Blob, fileName: string) {
// 	throw new Error('Function not implemented.');
// }

