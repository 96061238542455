import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { AccountDetails } from '@app/models/auth';
import { LoginService } from '@app/services/login.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
	selector: 'app-ap-header',
	templateUrl: './ap-header.component.html',
	styleUrls: ['./ap-header.component.css']
})
export class ApHeaderComponent implements OnInit, OnDestroy {
	public first_name: string;
	public last_name: string;
	public role_name: string;
	public customer_no: string;
	private logoutReqSbsn: Subscription;
	public isShowLogoutPopup: boolean;
	public portalType: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _UserIdleService: UserIdleService,
		private _GlobalStateService: GlobalStateService,
		private _RestApiService: RestApiService,
		public _SidemenuService: SidemenuService,
		private _Router: Router,
		private _NgxPermissionsService: NgxPermissionsService) {

	}

	ngOnInit() {
		this.portalType = this._LoginService.loginUser.account_detail.portal_type;
		this._GlobalStateService.subscribe('UserInfoChanged', (response) => {
			if (response) {
				this.getUserInfo();
			}
		});
		this._GlobalStateService.notifyDataChangedDuplicate('UserInfoChanged', new Date().getTime());
	}

	ngOnDestroy(): void {
		if (this.logoutReqSbsn) {
			this.logoutReqSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('UserInfoChanged'); // Dont Removed
	}

	// Method used to get User Information
	private getUserInfo() {
		let accountDetails: AccountDetails;
		try {
			accountDetails = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail));
		} catch (e) { }

		if (Object.keys(accountDetails).length > 0) {
			this.first_name = accountDetails.first_name;
			this.last_name = accountDetails.last_name;
			this.role_name = accountDetails.role_name;
			this.customer_no = accountDetails.usr;
		}
	}

	// Method used to redirect user by passing action
	public doRedirectUser(actionToRedirect: string) {
		this._SidemenuService.setCurrentMenu('dashboard');
		switch (actionToRedirect) {
			// #region for USER MANAGEMENT
			case 'userprofile':
				this._Router.navigate(['adminportal', 'usermanagements', 'userprofile']);
				break;
			default:

			// #endregion
		}
	}

	// Method used to Logout the user
	public doLogout() {
		/*
		this.isShowLogoutPopup = false;
		setTimeout(() => {
			this.isShowLogoutPopup = true;
		}, 300);
		*/
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('logout', '1');
		if (this.logoutReqSbsn) {
			this.logoutReqSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.logoutReqSbsn = this._RestApiService.doLoginReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			this._NgxPermissionsService.flushPermissions();
			this._UserIdleService.stopTimer();
			this._UserIdleService.stopWatching();
			this._LoginService.loginUser = undefined;
			this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
			this._Router.navigate(['/auth/login'], { replaceUrl: true });
		}, error => {
			this._LoaderService.hide();
			console.error(error);
		});
	}

	public doHideLogoutPopup(event: any) {
	}

	public doCancelLogoutPopup(event: any) {
		this.isShowLogoutPopup = false;
	}
}
