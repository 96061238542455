import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { DataService } from '@app/services/data.service';
import { Component, OnInit, AfterViewInit, Inject, ViewChild, OnDestroy, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { environment } from "@environments/environment";
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { DxDataGridComponent } from 'devextreme-angular';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalPopoupHeight90Ratio, GlobalPopoupWidth90Ratio, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { Guid } from '@app/models/guid';
import { MessageTrallComponent } from '@app/custom-components/message-trall/message-trall.component';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { Workbook } from 'exceljs';

@Component({
	selector: 'app-eucc-view-chargeback-documents-dx-popup',
	templateUrl: './eucc-view-chargeback-documents-dx-popup.component.html',
	styleUrls: ['./eucc-view-chargeback-documents-dx-popup.component.css'],
})
export class EuccViewChargebackDocumentsDxPopupComponent implements OnInit, OnDestroy, AfterViewInit {
	public chargeBackDocumentDetails: any;
	public totalClaimed: number;
	public totalClaimedAmount: number;
	public billBackType: string;
	public downloadDocumentURL: string;
	public claimDocumentURL: string;
	public outputDocumentURL: string;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public uomListData: any = [];
	public endUserChargeBackDocumentDataSource: any;
	public successedItemsDataSource: any;
	public ignoredItemsDataSource: any;
	public rejectedItemsDataSource: any;
	public shortPayTaskDataSource: any;
	public verifyCsvDocumentSbsn: Subscription;
	public listOfStatuses: any[] = this._EndUsersChargebackContractService.doGetListofApprovalStatus();
	public listOfApproveStatuses: any[] = this._EndUsersChargebackContractService.doGetListofApprovalStatusForView();
	@ViewChild("rejectedItemDataGridContainer", {}) rejectedItemDataGridContainer: DxDataGridComponent;
	@ViewChild("ignoredItemDataGridContainer", {}) ignoredItemDataGridContainer: DxDataGridComponent;
	@ViewChild("successedItemDataGridContainer", {}) successedItemDataGridContainer: DxDataGridComponent;
	@ViewChild("endUserChargeBacDocumentDataGridContainer", {}) endUserChargeBacDocumentDataGridContainer: DxDataGridComponent;
	@ViewChild("ignoreItemsChargeBackDocumentRef", {}) ignoreItemsChargeBackDocumentRef: DxDataGridComponent;
	public titleText = 'Chargeback Document';
	public isVisiblePopup: boolean = false;
	@Input('loadViewChargebackDocDxDialogSubject') loadViewChargebackDocDxDialogSubject: Subject<any>;
	public dialogData: any;
	public popupWidth: number;
	public popupHeight: number;
	public currentTab: string = 'succeed_items';
	public isShowDatagrid: boolean = false;
	public claimId: string;
	public filterText: string = '';
	public filterTextForIgnoredTab: string = '';
	public filterTextForRejectedTab: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private filterValueChangeSbsnRejected: Observable<any>;
	private filterValueChangeSbsnIgnored: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	private isDisabledClearBtnForRejected: boolean = true;
	private instanceClearBtnForRejected: any = null;
	private isDisabledClearBtnForIgnored: boolean = true;
	private instanceClearBtnForIgnored: any = null;
	private mainSectionStorageKey: string;
	private updateUserSettingSbsn: Subscription;
	private dataOutReqSubscription: Subscription;
	public stateStorageKey: string;
	public ignoredStateStorageKey: string;
	public rejecteStateStorageKey: string;
	private storedFiltersValue: any;
	private guid = new Guid();
	public filterValue: any[] = [];
	loadUploadEndUserDxDialogSubject: Subject<any> = new Subject();
	public itemTabDataList: any;
	public endUsersChargebackContractModelUI: any;
	@Output() loadModifyUploadClaimDialog = new EventEmitter<string>();
	public callFrom: string;
	public isViewMessageTrall: boolean = false;
	public isModifiedClaim: boolean = false;
	@Output() hideViewClaimPopUpDataEvent = new EventEmitter<string>();
	@ViewChild(MessageTrallComponent) childMessageTrallComponent: MessageTrallComponent;
	public decimalPointForCurrency: number;
	public claimItemTabs: any[] = [
		{
			key: 'succeed_items',
			title: 'Claimed Item(s)',
		},
		{
			key: 'ignored_items',
			title: 'Ignored Item(s)',
		},
		{
			key: 'rejected_items',
			title: 'Rejected Item(s)',
		},
	]
	constructor(
		private _LoginService: LoginService,
		private _DataService: DataService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _BillbackService: BillbackService,
		public _LoaderService: LoaderService,
		public _RestApiService: RestApiService,
		public _ToastrService: ToastrService,
		public _CustomCurrencyPipe: CustomCurrencyPipe,
		public _EndUsersChargebackContractService: EndUsersChargebackContractService,
	) {
		this.doLoadViewEndUserChargebackDocument = this.doLoadViewEndUserChargebackDocument.bind(this);
		this.doSaveViewEndUserChargebackDocument = this.doSaveViewEndUserChargebackDocument.bind(this);
		this.doLoadViewRejectedEUCDoc = this.doLoadViewRejectedEUCDoc.bind(this);
		this.doSaveViewRejectedEUCDoc = this.doSaveViewRejectedEUCDoc.bind(this);
		this.doLoadViewIgnoredEUCDoc = this.doLoadViewIgnoredEUCDoc.bind(this);
		this.doSaveViewIgnoredEUCDoc = this.doSaveViewIgnoredEUCDoc.bind(this);
	}
	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		this.setDxPopupWidth();
	}
	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		// get global currency
		setTimeout(() => {
			this.currentTab = 'succeed_items';
		}, 100);
		this.loadViewChargebackDocDxDialogSubject.subscribe(dialogData => {
			this.currentTab = 'succeed_items';
			this.isModifiedClaim = false;
			this.isViewMessageTrall = true;
			this.mainSectionStorageKey = ServerSections.INCENTIVES_END_USERS_CHARGEBACK;
			this.stateStorageKey = ServerSections.EUCC_VIEW_CHARGEBACK_DOCUMENT;
			this.rejecteStateStorageKey = ServerSections.EUCC_VIEW_REJECTED_CHARGEBACK_DOCUMENT;
			this.ignoredStateStorageKey = ServerSections.EUCC_VIEW_IGNORED_CHARGEBACK_DOCUMENT;
			setTimeout(() => {
				if (this.endUserChargeBacDocumentDataGridContainer) {
					this.filterValueChangeSbsn = this.endUserChargeBacDocumentDataGridContainer.filterValueChange.asObservable();
					this.filterValueChangeSbsn.subscribe((result) => {
						this.getFilterText();
					});
				}
				if (this.ignoredItemDataGridContainer) {
					this.filterValueChangeSbsnIgnored = this.ignoredItemDataGridContainer.filterValueChange.asObservable();
					this.filterValueChangeSbsnIgnored.subscribe((result) => {
						this.getIgnoredItemFilterText();
					});
				}
				if (this.rejectedItemDataGridContainer) {
					this.filterValueChangeSbsnRejected = this.rejectedItemDataGridContainer.filterValueChange.asObservable();
					this.filterValueChangeSbsnRejected.subscribe((result) => {
						this.getRejectedItemFilterText();
					});
				}
			}, 60);
			// this.storedFiltersValue = [];
			// this.applyStoredFilter();
			this.uomListData = this._DataService.getUOMs();
			this.dialogData = JSON.parse(JSON.stringify(dialogData));
			this.callFrom = this.dialogData.callFrom;
			this.chargeBackDocumentDetails = this.dialogData.chargeBackDocumentDetails;
			this.itemTabDataList = this.dialogData.itemTabDataList;
			this.endUsersChargebackContractModelUI = this.dialogData.endUsersChargebackContractModelUI;
			this.billBackType = this.dialogData.billBackType;
			this.claimId = this.dialogData.claimId;
			//this.doChangeTab('succeed_items');
			this.endUserChargeBackDocumentDataSource = this.chargeBackDocumentDetails['claimedItems'] || [];
			this.ignoredItemsDataSource = this.chargeBackDocumentDetails['ignoredItems'] || [];
			this.rejectedItemsDataSource = this.chargeBackDocumentDetails['rejectedItems'] || [];
			this.shortPayTaskDataSource = this.chargeBackDocumentDetails.shortPayDetails || [];
			this.totalClaimed = 0;
			this.totalClaimedAmount = 0;
			this.loadComponent();
			this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
			this.isQBErpUser = this._BillbackService.getQbErpUser();
			this.isVisiblePopup = true;

		});
	}

	ngOnDestroy(): void {
		if (this.verifyCsvDocumentSbsn) {
			this.verifyCsvDocumentSbsn.unsubscribe();
		}
		if (this.loadUploadEndUserDxDialogSubject) {
			this.loadUploadEndUserDxDialogSubject.unsubscribe();
		}
		if (this.loadViewChargebackDocDxDialogSubject) {
			this.loadViewChargebackDocDxDialogSubject.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.setDxPopupWidth();
		}, 500);
	}

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.endUserChargeBacDocumentDataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.endUserChargeBacDocumentDataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.endUserChargeBacDocumentDataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.endUserChargeBacDocumentDataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.refreshDataGridComponent();
			// 		}
			// 	}
			// }
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
		}

	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.endUserChargeBacDocumentDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	public refreshDataGridComponent() {
		this.endUserChargeBacDocumentDataGridContainer.instance.refresh();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.endUserChargeBacDocumentDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	private setDxPopupWidth() {
		const innerWidth = window.innerWidth;
		const innerHeight = window.innerHeight;
		this.popupWidth = (innerWidth * GlobalPopoupWidth90Ratio) / 100;
		this.popupHeight = (innerHeight * GlobalPopoupHeight90Ratio) / 100;
	}

	public loadComponent() {
		if (this.dialogData.chargeBackDocumentDetails.documentURL) {
			const documentURL = this.dialogData.chargeBackDocumentDetails.documentURL;
			const documentNewURLArr = documentURL.split('-');
			if (documentNewURLArr.length > 0) {
				const docId = documentNewURLArr[0];
				const docDate = documentNewURLArr[1];
				const docCode = documentNewURLArr[2];
				const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackDoc'
				this.downloadDocumentURL = finalUrl;
			}
		}
		if (this.dialogData.chargeBackDocumentDetails.claimDocumentURL) {
			const documentURL = this.dialogData.chargeBackDocumentDetails.claimDocumentURL;
			const documentNewURLArr = documentURL.split('-');
			if (documentNewURLArr.length > 0) {
				const docId = documentNewURLArr[0];
				const docDate = documentNewURLArr[1];
				const docCode = documentNewURLArr[2];
				const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackClaimDoc'
				this.claimDocumentURL = finalUrl;
			}
		}

		if (this.chargeBackDocumentDetails['csvFileURL']) {
			const documentURL = this.chargeBackDocumentDetails.csvFileURL;
			if (documentURL.length > 0) {
				const docId = documentURL;
				const finalUrl = environment.URL_SERVER + '/getCsvDoc.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&doctype=csv'
				this.outputDocumentURL = finalUrl;
			}
		}
		// else {
		// 	const docId = this.chargeBackDocumentDetails['guid'];
		// 	const finalUrl = environment.URL_SERVER + '/getCsvDoc.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&doctype=csv'
		// 	this.outputDocumentURL = finalUrl;
		// }

		for (let i = 0; i < this.dialogData.chargeBackDocumentDetails.claimedItems.length; i++) {
			const objClaimed = parseFloat(this.dialogData.chargeBackDocumentDetails.claimedItems[i].claimed) || 0;
			const objClaimedAmount = parseFloat(this.dialogData.chargeBackDocumentDetails.claimedItems[i].linetotal) || 0;
			this.totalClaimed = this.totalClaimed + objClaimed;
			this.totalClaimedAmount = this.totalClaimedAmount + objClaimedAmount;
		}
	}

	public doChangeTab(newTab: string) {
		this.currentTab = newTab;
		this.isShowDatagrid = true;
		switch (newTab) {
			case 'ignored_items':
				this.endUserChargeBackDocumentDataSource = this.chargeBackDocumentDetails['ignoredItems'] || [];
				break;
			case 'succeed_items':
				this.endUserChargeBackDocumentDataSource = this.chargeBackDocumentDetails['claimedItems'] || [];
				break;
			case 'rejected_items':
				setTimeout(() => {
					if (this.rejectedItemDataGridContainer) {
						this.filterValueChangeSbsnRejected = this.rejectedItemDataGridContainer.filterValueChange.asObservable();
						this.filterValueChangeSbsnRejected.subscribe((result) => {
							this.getRejectedItemFilterText();
						});
					}
				}, 60);
				this.rejectedItemsDataSource = this.chargeBackDocumentDetails['rejectedItems'] || [];
				break;
			default:
				break;
		}
	}
	doclickRefrenceDocument() {
		window.open(this.downloadDocumentURL);
	}

	doClickClaimedDocument() {
		window.open(this.claimDocumentURL);
	}

	doClickOutputDocument() {
		const formData = new FormData();
		let docId = '';
		if (this.chargeBackDocumentDetails['csvFileURL'] && this.chargeBackDocumentDetails['csvFileURL'].length > 0) {
			docId = this.chargeBackDocumentDetails.csvFileURL;
		} else {
			docId = this.chargeBackDocumentDetails['guid'];
		}
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('docid', docId);
		formData.append('doctype', 'csv');
		formData.append('verify', 'true');
		this._LoaderService.show();
		if (this.verifyCsvDocumentSbsn) {
			this.verifyCsvDocumentSbsn.unsubscribe();
		}
		this.verifyCsvDocumentSbsn = this._RestApiService.doGetCsvDocumentReq(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						window.open(this.outputDocumentURL);
					} else {
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	doClickExportChargebackDocument() {
		const context = this;
		const workbook = new Workbook();
		let fileName = context.chargeBackDocumentDetails.contractName + '_' + context.claimId + '_ChargebackDocument_successful.xlsx';
		const succeedItemsSheet = workbook.addWorksheet('Claimed Item(s)');
		const ignoredItemsSheet = workbook.addWorksheet('Ignored Item(s)');
		const rejectedItemsSheet = workbook.addWorksheet('Rejected Item(s)');
		exportDataGrid({
			worksheet: succeedItemsSheet,
			component: context.endUserChargeBacDocumentDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: ({ gridCell, excelCell }) => {
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			},
		}).then(() => exportDataGrid({
			worksheet: ignoredItemsSheet,
			component: context.ignoredItemDataGridContainer.instance,
			customizeCell: ({ gridCell, excelCell }) => {
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			},
		})).then(() => exportDataGrid({
			worksheet: rejectedItemsSheet,
			component: context.rejectedItemDataGridContainer.instance,
			customizeCell: ({ gridCell, excelCell }) => {
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			},
		})).then(() => {
			this.doSetHeaderBlockOnExportExcel(succeedItemsSheet, context);
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
			});
		});
	}

	public doHiddenPopop() {
		this.isVisiblePopup = false;
		this.isViewMessageTrall = false;
		if (this.isModifiedClaim) {
			this.hideViewClaimPopUpDataEvent.emit('claim_modified');
		}
	}

	public convertToNumber(amountVal) {
		if (this.isNumber(amountVal)) {
			return this._CustomCurrencyPipe.transform((+amountVal || 0));
		} else {
			return amountVal;
		}
	}

	public isNumber(value?: string | number): boolean {
		return ((value != null) &&
			(value !== '') &&
			!isNaN(Number(value.toString())));
	}

	// #region for Datagrid State Storing functionality

	public doLoadViewEndUserChargebackDocument() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveViewEndUserChargebackDocument(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// #endregion

	public doModifyClaim() {
		// this._EndUsersChargebackContractService.setModifiedClaimId(this.chargeBackDocumentDetails.guid);
		const dialogConfig = {
			itemTabDataList: this.itemTabDataList,
			endUsersChargebackContractModelUI: this.endUsersChargebackContractModelUI,
			// callFrom: this.callFrom,
			callFrom: 'modify-claim',
			modifyClaimDetails: this.chargeBackDocumentDetails
		};
		this.loadUploadEndUserDxDialogSubject.next(dialogConfig);

	}

	public doHideUploadEndUserDxDialog(result) {
		if (result) {
			// relace exiting claim details to new one

			// let chargeBackClaimDocumentList: any = this._EndUsersChargebackContractService.getChargebackDocumentTabDataList();
			// if (chargeBackClaimDocumentList && chargeBackClaimDocumentList.length > 0) {
			// 	// const docClaimIndex = chargeBackClaimDocumentList.findIndex(i => i['source_claim_id'] === this.chargeBackDocumentDetails.guid);
			// 	const docClaimIndex = chargeBackClaimDocumentList.findIndex(i => i['guid'] === this.chargeBackDocumentDetails.guid);
			// 	if (docClaimIndex !== -1) {
			// 		console.log('chargeBackClaimDocumentList  > ', chargeBackClaimDocumentList[docClaimIndex]);
			// 		this.chargeBackDocumentDetails = chargeBackClaimDocumentList[docClaimIndex];
			// 		this.shortPayTaskDataSource = this.chargeBackDocumentDetails.shortPayDetails || [];
			// 		this.claimId = this.chargeBackDocumentDetails.ixcode;
			// 		this.chargeBackDocumentDetails.claimedStatus = chargeBackClaimDocumentList[docClaimIndex].claimedStatus;
			// 		this.doChangeTab('succeed_items');
			// 		this.isModifiedClaim = true;
			// 	}
			// }

			//end

			this.isModifiedClaim = true;
			if (this.isModifiedClaim) {
				this.hideViewClaimPopUpDataEvent.emit('claim_modified');
				this.isVisiblePopup = false;
			}
		}
	}

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatuses.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatuses[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	public getListOfApproveStatus(data) {
		this.listOfApproveStatuses.forEach(action => {
			action.label = action.label.toUpperCase();
		});
		this.listOfApproveStatuses.forEach(action => {
			if (action.value === data.claimedStatus) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	public changedApprovalStatusAction(event, approvalGuid) {
		if (this.callFrom === 'notifications') {
			if (this.dialogData['notificationGuid']) {
				approvalGuid = this.dialogData['notificationGuid'];
			}
		}
		this._LoaderService.show();
		const objUpdateData = { 'claimedStatus': event.itemData.value };
		const searchParams = [['type', '=', 'approval']];
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		reqFormData.append('guid', approvalGuid);
		reqFormData.append('updateData', JSON.stringify(objUpdateData));
		reqFormData.append('entity', ServerEntity.NOTIFICATIONS);
		reqFormData.append('filter', JSON.stringify(searchParams));
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataInFormDataReq(reqFormData).subscribe({
			next: (response) => {
				if (response.flag) {
					this.chargeBackDocumentDetails.claimedStatus = event.itemData.value;
					this.childMessageTrallComponent.getAllMessageTrall();
					this._LoaderService.hide();
					this.hideViewClaimPopUpDataEvent.emit('true');
					// this.getApprovalList();
				} else {
					this._LoaderService.hide();
					this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doClickViewClaim(item) {
		this.childMessageTrallComponent.doClickViewClaim(item);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		const context = this;
		if (context.currentTab === 'succeed_items') {
			if (context.endUserChargeBacDocumentDataGridContainer.instance.totalCount() <= 0) {
				this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
				return;
			}
			const workbook = new ExcelJS.Workbook();
			let claimedItemWorksheet;
			let fileName = context.chargeBackDocumentDetails.contractName + '_' + context.claimId + '_ChargebackDocument_successful.xlsx';
			claimedItemWorksheet = workbook.addWorksheet('Claimed Item(s)');
			let ignoredItemsWorkBook: any;
			if (context.ignoreItemsChargeBackDocumentRef && context.ignoreItemsChargeBackDocumentRef.instance && context.ignoreItemsChargeBackDocumentRef.instance.totalCount() > 0) {
				ignoredItemsWorkBook = workbook.addWorksheet('Ignored Item(s)');
			}

			exportDataGrid({
				worksheet: claimedItemWorksheet,
				component: context.endUserChargeBacDocumentDataGridContainer.instance,
				autoFilterEnabled: true,
				keepColumnWidths: true,
				topLeftCell: { row: 8, column: 1 },
				customizeCell: (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
							excelCell.value = '';
							if (gridCell.value) {
								excelCell.value = gridCell.value.toString().toUpperCase();
							}
						}
						if (gridCell.column.dataField === 'is_active') {
							excelCell.value = '';
							let colorName = '';
							excelCell.value = gridCell.value ? 'Active' : 'Inactive';
							colorName = gridCell.value ? '05c1a0' : 'f99300';
							// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
							excelCell.font = { color: { argb: colorName } };
						}
					}
				}
			}).then((cellRange) => {
				// header
				this.doSetHeaderBlockOnExportExcel(claimedItemWorksheet, context);
			}).then(() => {
				workbook.xlsx.writeBuffer().then((buffer) => {
					saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
				});
			});
		}

		if (context.currentTab === 'ignored_items') {
			if (context.endUserChargeBacDocumentDataGridContainer.instance.totalCount() <= 0) {
				this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
				return;
			}
			const workbook = new ExcelJS.Workbook();
			let ignoredItemWorksheet;
			let fileName = context.chargeBackDocumentDetails.contractName + '_' + context.claimId + '_ChargebackDocument_ignored.xlsx';
			ignoredItemWorksheet = workbook.addWorksheet('Ignored Item(s)');
			let ignoredItemsWorkBook: any;
			if (context.ignoreItemsChargeBackDocumentRef && context.ignoreItemsChargeBackDocumentRef.instance && context.ignoreItemsChargeBackDocumentRef.instance.totalCount() > 0) {
				ignoredItemsWorkBook = workbook.addWorksheet('Ignored Item(s)');
			}

			exportDataGrid({
				worksheet: ignoredItemWorksheet,
				component: context.endUserChargeBacDocumentDataGridContainer.instance,
				autoFilterEnabled: true,
				keepColumnWidths: true,
				topLeftCell: { row: 8, column: 1 },
				customizeCell: (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
							excelCell.value = '';
							if (gridCell.value) {
								excelCell.value = gridCell.value.toString().toUpperCase();
							}
						}
						if (gridCell.column.dataField === 'is_active') {
							excelCell.value = '';
							let colorName = '';
							excelCell.value = gridCell.value ? 'Active' : 'Inactive';
							colorName = gridCell.value ? '05c1a0' : 'f99300';
							// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
							excelCell.font = { color: { argb: colorName } };
						}
					}
				}
			}).then((cellRange) => {
				this.doSetHeaderBlockOnExportExcel(ignoredItemWorksheet, context);
			}).then(() => {
				workbook.xlsx.writeBuffer().then((buffer) => {
					saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
				});
			});
		}

	}

	private doSetHeaderBlockOnExportExcel(worksheet, context) {
		const headerRow = worksheet.getRow(2);
		headerRow.height = 30;
		if (context.chargeBackDocumentDetails.contractName) {
			worksheet.mergeCells(2, 1, 2, 12);
		}
		headerRow.getCell(1).value = context.chargeBackDocumentDetails.contractName;
		headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14, 'bold': true };
		headerRow.getCell(1).alignment = { horizontal: 'center', vertical: 'center' };

		const claimIdRow = worksheet.getRow(3);
		claimIdRow.height = 30;
		claimIdRow.getCell(1).value = 'Claim No';
		claimIdRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let claimIdValue = context.claimId;
		claimIdRow.getCell(2).value = claimIdValue;
		claimIdRow.getCell(2).alignment = { horizontal: 'left' };
		claimIdRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		const currentStatus = context.listOfStatuses.find(s => s.value === context.chargeBackDocumentDetails.claimedStatus);
		const statusColor = currentStatus.bgcolor.replace("#", "");
		if (context.currentTab === 'succeed_items' && context.chargeBackDocumentDetails.claimedStatus) {
			if ((context.chargeBackDocumentDetails.claimedStatus === 'inprocess' && context.callFrom !== 'notifications') || context.chargeBackDocumentDetails.claimedStatus !== 'inprocess') {
				claimIdRow.getCell(4).value = 'Claim Status';
				claimIdRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
				claimIdRow.getCell(5).value = currentStatus.finaltext || '';
				claimIdRow.getCell(5).font = { name: 'Segoe UI Light', color: { argb: statusColor }, size: 12, bold: true };
			}
		} else {
			claimIdRow.getCell(4).value = 'Status';
			claimIdRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			claimIdRow.getCell(5).value = currentStatus.finaltext || '';
			claimIdRow.getCell(5).font = { name: 'Segoe UI Light', color: { argb: statusColor }, size: 12, bold: true };
		}

		const docRefNumRow = worksheet.getRow(4);
		docRefNumRow.height = 30;
		docRefNumRow.getCell(1).value = 'Document Reference No.';
		docRefNumRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let dcoRefNumValue = context.chargeBackDocumentDetails.documentReferenceNumber;
		docRefNumRow.getCell(2).value = dcoRefNumValue;
		docRefNumRow.getCell(2).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.chargeBackDocumentDetails.claimDate) {
			docRefNumRow.getCell(4).value = 'Claim Date';
			docRefNumRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			docRefNumRow.getCell(5).value = context.chargeBackDocumentDetails.claimDate || '';
			docRefNumRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		const selectCustomerRow = worksheet.getRow(5);
		selectCustomerRow.height = 30;
		selectCustomerRow.getCell(1).value = 'Customer Code';
		selectCustomerRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let selectCustomerRowValue = context.chargeBackDocumentDetails.selectCustomerId || '';
		selectCustomerRow.getCell(2).value = selectCustomerRowValue;
		selectCustomerRow.getCell(2).alignment = { horizontal: 'left' };
		selectCustomerRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.chargeBackDocumentDetails.documentDate) {
			selectCustomerRow.getCell(4).value = 'Document Date';
			selectCustomerRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			selectCustomerRow.getCell(5).value = context.chargeBackDocumentDetails.documentDate || '';
			selectCustomerRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		const selectCustomerNameRow = worksheet.getRow(6);
		selectCustomerNameRow.height = 30;
		selectCustomerNameRow.getCell(1).value = 'Customer Name';
		selectCustomerNameRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let selectCustomerNameRowValue = context.chargeBackDocumentDetails.selectCustomer || '';
		selectCustomerNameRow.getCell(2).value = selectCustomerNameRowValue;
		selectCustomerNameRow.getCell(2).alignment = { horizontal: 'left' };
		selectCustomerNameRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		// if (context.chargeBackDocumentDetails.selectCustomer) {
		// 	selectCustomerRow.getCell(4).value = 'Customer Name';
		// 	selectCustomerRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
		// 	selectCustomerRow.getCell(5).value = context.chargeBackDocumentDetails.selectCustomer || '';
		// 	selectCustomerRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		// }
		let sourceClaimNoRowValue = context.chargeBackDocumentDetails.claimUse || '';
		if (sourceClaimNoRowValue) {
			const sourceClaimNoRow = worksheet.getRow(7);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'Source Claim No.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = sourceClaimNoRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		let claimedUserNoteRowValue = context.chargeBackDocumentDetails.claimedUserNote || '';
		if (claimedUserNoteRowValue) {
			const sourceClaimNoRow = worksheet.getRow(7);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'User Note.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = claimedUserNoteRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

	}

	//#region Ignored Items

	// Method used to Display Toolbar for Export Data, Filter Data
	public doIgnoredItemsToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateIgnoredFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtnForIgnored,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtnForIgnored = args.component;
					},
					onClick: () => {
						this.ignoredItemDataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilderIgnoredItem();
					}
				}
			},

			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.refreshDataGridComponent();
			// 		}
			// 	}
			// }
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
		}

	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilderIgnoredItem() {
		this.ignoredItemDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	// Method used to Get Same Text from Filter Panel
	private getIgnoredItemFilterText() {
		this.filterTextForIgnoredTab = '';
		this.isDisabledClearBtnForIgnored = true;
		this.ignoredItemDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterTextForIgnoredTab = e.text;
			if (this.filterTextForIgnoredTab) {
				this.isDisabledClearBtnForIgnored = false;
			} else {
				this.isDisabledClearBtnForIgnored = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtnForIgnoredTab();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtnForIgnoredTab() {
		if (this.instanceClearBtnForIgnored !== null) {
			setTimeout(() => {
				this.instanceClearBtnForIgnored.option({ disabled: this.isDisabledClearBtnForIgnored });
			}, 10);
		}
	}

	public doLoadViewIgnoredEUCDoc() {
		this.getIgnoredItemFilterText();
		return this.loadDatagridState(this.ignoredStateStorageKey, this.mainSectionStorageKey);
	}

	public doSaveViewIgnoredEUCDoc(e) {
		const salesListingConfig = this.saveDatagridState(e, this.ignoredStateStorageKey, this.mainSectionStorageKey);
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}
	//#endregion

	//#region Rejected Items

	// Method used to Display Toolbar for Export Data, Filter Data
	public doRejectedItemsToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateRejectedFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtnForRejected,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtnForRejected = args.component;
					},
					onClick: () => {
						this.rejectedItemDataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilderRejectedItem();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcelRejectedItems();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.refreshDataGridComponent();
			// 		}
			// 	}
			// }
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
		}

	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilderRejectedItem() {
		this.rejectedItemDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	// Method used to Get Same Text from Filter Panel
	private getRejectedItemFilterText() {
		this.filterTextForRejectedTab = '';
		this.isDisabledClearBtnForRejected = true;
		this.rejectedItemDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterTextForRejectedTab = e.text;
			if (this.filterTextForRejectedTab) {
				this.isDisabledClearBtnForRejected = false;
			} else {
				this.isDisabledClearBtnForRejected = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtnForRejectedTab();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtnForRejectedTab() {
		if (this.instanceClearBtnForRejected !== null) {
			setTimeout(() => {
				this.instanceClearBtnForRejected.option({ disabled: this.isDisabledClearBtnForRejected });
			}, 10);
		}
	}

	private doExportToExcelRejectedItems() {
		const context = this;
		if (context.rejectedItemDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let claimedItemWorksheet;
		let fileName = context.chargeBackDocumentDetails.contractName + '_' + context.claimId + '_ChargebackDocument_successful.xlsx';
		claimedItemWorksheet = workbook.addWorksheet('Rejected Claimed Item(s)');
		let ignoredItemsWorkBook: any;

		exportDataGrid({
			worksheet: claimedItemWorksheet,
			component: context.rejectedItemDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then((cellRange) => {
			// header
			this.doSetHeaderBlockOnExportExcelRejected(claimedItemWorksheet, context);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	private doSetHeaderBlockOnExportExcelRejected(worksheet, context) {
		const headerRow = worksheet.getRow(2);
		headerRow.height = 30;
		if (context.chargeBackDocumentDetails.contractName) {
			worksheet.mergeCells(2, 1, 2, 12);
		}
		headerRow.getCell(1).value = context.chargeBackDocumentDetails.contractName;
		headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14, 'bold': true };
		headerRow.getCell(1).alignment = { horizontal: 'center', vertical: 'center' };

		const claimIdRow = worksheet.getRow(3);
		claimIdRow.height = 30;
		claimIdRow.getCell(1).value = 'Claim No';
		claimIdRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let claimIdValue = context.claimId;
		claimIdRow.getCell(2).value = claimIdValue;
		claimIdRow.getCell(2).alignment = { horizontal: 'left' };
		claimIdRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		const currentStatus = context.listOfStatuses.find(s => s.value === context.chargeBackDocumentDetails.claimedStatus);
		const statusColor = currentStatus.bgcolor.replace("#", "");
		claimIdRow.getCell(4).value = 'Status';
		claimIdRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
		claimIdRow.getCell(5).value = currentStatus.finaltext || '';
		claimIdRow.getCell(5).font = { name: 'Segoe UI Light', color: { argb: statusColor }, size: 12, bold: true };

		const docRefNumRow = worksheet.getRow(4);
		docRefNumRow.height = 30;
		docRefNumRow.getCell(1).value = 'Document Reference No.';
		docRefNumRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let dcoRefNumValue = context.chargeBackDocumentDetails.documentReferenceNumber;
		docRefNumRow.getCell(2).value = dcoRefNumValue;
		docRefNumRow.getCell(2).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.chargeBackDocumentDetails.documentDate) {
			docRefNumRow.getCell(4).value = 'Document Date';
			docRefNumRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			docRefNumRow.getCell(5).value = context.chargeBackDocumentDetails.documentDate || '';
			docRefNumRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		const selectCustomerRow = worksheet.getRow(5);
		selectCustomerRow.height = 30;
		selectCustomerRow.getCell(1).value = 'Customer Code';
		selectCustomerRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		let selectCustomerRowValue = context.chargeBackDocumentDetails.selectCustomerId || '';
		selectCustomerRow.getCell(2).value = selectCustomerRowValue;
		selectCustomerRow.getCell(2).alignment = { horizontal: 'left' };
		selectCustomerRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		if (context.chargeBackDocumentDetails.selectCustomer) {
			selectCustomerRow.getCell(4).value = 'Customer Name';
			selectCustomerRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			selectCustomerRow.getCell(5).value = context.chargeBackDocumentDetails.selectCustomer || '';
			selectCustomerRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}
		let sourceClaimNoRowValue = context.chargeBackDocumentDetails.claimUse || '';
		if (sourceClaimNoRowValue) {
			const sourceClaimNoRow = worksheet.getRow(6);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'Source Claim No.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = sourceClaimNoRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

		let claimedUserNoteRowValue = context.chargeBackDocumentDetails.claimedUserNote || '';
		if (claimedUserNoteRowValue) {
			const sourceClaimNoRow = worksheet.getRow(7);
			sourceClaimNoRow.height = 30;
			sourceClaimNoRow.getCell(1).value = 'User Note.';
			sourceClaimNoRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			sourceClaimNoRow.getCell(2).value = claimedUserNoteRowValue;
			sourceClaimNoRow.getCell(2).alignment = { horizontal: 'left' };
			sourceClaimNoRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}

	}

	// #region for Datagrid State Storing functionality

	public doLoadViewRejectedEUCDoc() {
		this.getRejectedItemFilterText();
		return this.loadDatagridState(this.rejecteStateStorageKey, this.mainSectionStorageKey);
	}

	public doSaveViewRejectedEUCDoc(e) {
		const salesListingConfig = this.saveDatagridState(e, this.rejecteStateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	// #endregion

	//#endRegion


}

