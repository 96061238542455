<dx-popup [fullScreen]="true" [showTitle]="true" [title]="titleText" [dragEnabled]="true" [hideOnOutsideClick]="true"
	[showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop()"
	[wrapperAttr]="{ class: 'popup-dx-overlay-content dx-popup-content-pad0' }" id="ViewClaimPopUp">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-drawer [openedStateMode]="'shrink'" [position]="'right'" [revealMode]="'slide'" template="template"
			[(opened)]="isOpenAuditTrailDrawer" [closeOnOutsideClick]="false" [openedStateMode]="'overlap'"
			[elementAttr]="{ class: 'priceListDetailsDrawerPadIx' }">
			<div class="cols12">
				<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'"
					[bounceEnabled]="false" [useNative]="false" width="100%" height="100%">
					<div>
						<div class="orderDetail orderPdetails mrgT10">
							<div class="custDetail">
								<!-- <h4 class="graySubHead textWrap">Claim No. :
								{{claimId}}</h4> -->
								<h4 class="graySubHead textWrap" *ngIf="manualAdjustmentClaim?.ixcode">Charegback No.:
									<span class="black">{{manualAdjustmentClaim?.ixcode}}</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="manualAdjustmentClaim?.selectCustomerId">Customer Code:
									<span class="black">{{manualAdjustmentClaim?.selectCustomerId}}</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="manualAdjustmentClaim?.selectCustomer">Customer Name:
									<span class="black">{{manualAdjustmentClaim?.selectCustomer}}</span>
								</h4>
								<h4 class="graySubHead textWrap">
									Reason Code:
									<span class="black">{{manualAdjustmentClaim?.reason_code_label}}
										({{manualAdjustmentClaim?.reason_code_erpcode}})</span>
									<!-- <div class="dx-field feildCol alignLeft">
										<div class="dx-field-label">Reason Code </div>
										<div class="dx-field-value" style="width: 100%;">
											<dx-drop-down-box [(value)]="reasonCodeGridBoxValue" valueExpr="guid"
												[displayExpr]="displayExprReasonCode" placeholder="Select Reason Code..."
												[(opened)]="isReasonCodeDropDownBoxOpened" [showClearButton]="true"
												[deferRendering]="false" [dataSource]="listOfCustomReason"
												(onOptionChanged)="onReasonCodeSelectionChanged($event)"
												[dropDownOptions]="{width:650}">
												<div *dxTemplate="let data of 'content'">
													<dx-data-grid #resonCodeDataGrid [dataSource]="listOfCustomReason" keyExpr="guid"
														[columns]="reasonCodeGridBoxColumns" [hoverStateEnabled]="true" height="100%"
														[(selectedRowKeys)]="reasonCodeGridBoxValue" [allowColumnResizing]="true">
														<dxo-selection mode="single"></dxo-selection>
														<dxo-filter-row [visible]="true"></dxo-filter-row>
														<dxo-scrolling mode="virtual" [scrollByThumb]="true"
															[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
														<dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
													</dx-data-grid>
												</div>
											</dx-drop-down-box>
										</div>
									</div> -->
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="claimPreviewProps?.transaction_date">Transaction
									Date :
									<span class="black">{{claimPreviewProps?.transaction_date| toDateObj | date: 'yyyy-MM-dd'
										}}</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="manualAdjustmentClaim?.source_claim_no">Source Claim
									No.
									:
									<a><span class="highlight"
											(click)="doClickViewClaim(manualAdjustmentClaim)">{{manualAdjustmentClaim?.source_claim_no}}</span></a>
								</h4>
								<h4>
									<dx-button *ngIf="downloadDocumentURL" (onClick)="doclickRefrenceDocument()"
										class="secondaryBtn button" type="normal" stylingMode="contained"
										[useSubmitBehavior]="false" icon="download" text="Reference Document">
									</dx-button>
									<dx-button *ngIf="claimDocumentURL" (onClick)="doClickClaimedDocument()"
										class="secondaryBtn button mrgL10" type="normal" stylingMode="contained"
										[useSubmitBehavior]="false" icon="download" text="Claim Document">
									</dx-button>
									<dx-button *ngIf="manualAdjustmentClaim?.claimedStatus !=='inprocess' && outputDocumentURL"
										(onClick)="doClickOutputDocument()" class="secondaryBtn button mrgL10" type="normal"
										stylingMode="contained" [useSubmitBehavior]="false" icon="download"
										text="Output Document">
									</dx-button>
								</h4>

							</div>
							<div class="orderStatus">
								<ul class="statusBx">
									<li><span class="stLbl">Claim Status : </span>
										<span class="stData stBtn"
											*ngIf="(manualAdjustmentClaim.claimedStatus === 'inprocess' && callFrom !== 'notifications') || manualAdjustmentClaim.claimedStatus !== 'inprocess'"
											[ngClass]="manualAdjustmentClaim.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'classnameBg'">
											{{manualAdjustmentClaim.claimedStatus || '' | filterFromList:listOfStatuses
											:'value':'text'}}</span>
										<span
											*ngIf="(manualAdjustmentClaim.claimedStatus === 'inprocess' && callFrom === 'notifications')">
											<dx-drop-down-button [splitButton]="false"
												[ngClass]="getClassNameForStatusField(manualAdjustmentClaim.claimedStatus)"
												[text]="manualAdjustmentClaim.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'text'"
												stylingMode="contained"
												class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
												[dataSource]="listOfApproveStatuses" [wrapItemText]="true" displayExpr="text"
												keyExpr="value" (onButtonClick)="getListOfApproveStatus(manualAdjustmentClaim)">
											</dx-drop-down-button>
										</span>
									</li>
									<li class="d-flex">
										<span class="stLbl black">Chargeback Period :</span>
										<strong class="stLbl black">{{manualAdjustmentClaim?.chargeback_period| toDateObj | date:
											'MMMM,yyyy'}}</strong>
										<!-- <div class="datePicker inlineBlock">
											<dx-date-box type="date" [(value)]="manualAdjustmentClaim?.chargeback_period || nowDate"
												displayFormat="monthAndYear"
												[min]="minMaxEndUsersChargebackContractModelUI?.startDate || nowDate"
												[max]="minMaxEndUsersChargebackContractModelUI?.endDate || nowDate"
												(onValueChanged)="doFilterDateOnValueChanged($event)"
												[calendarOptions]="{ maxZoomLevel: 'year', minZoomLevel: 'decade' }">
											</dx-date-box>
											<div class="inputField inputFieldFilterLbl">
												<label for="inputFieldFilterLbl">Chargeback Period</label>
											</div>
										</div> -->
									</li>
									<li><span class="stLbl">Cut-Off Date :</span><strong
											class="black">{{manualAdjustmentClaim?.cut_off_date| toDateObj | date: 'yyyy-MM-dd'
											}}</strong>
									</li>
									<!-- <li><span class="stLbl">Transaction Date :</span><strong
											class="black">{{claimPreviewProps?.transaction_date| toDateObj | date: 'yyyy-MM-dd'
											}}</strong>
									</li> -->
								</ul>
							</div>
						</div>
					</div>
					<div class="hrLine"></div>
					<div class="tabbing cf">
						<ul>
							<li (click)="doChangeTab('endusers_items')" [ngClass]="{active: currentTab === 'endusers_items'}">
								<a>End-User(s)/Items</a>
							</li>
						</ul>
					</div>
					<div *ngIf="currentTab === 'endusers_items'">
						<div class="row">
							<!-- <div class="cols6">
								<div class="dx-field feildCol">
									<div class="dx-field-label">End-Users</div>
									<div class="dx-field-value" style="width: 100%;">
										<dx-drop-down-box [(value)]="endUsersGridBoxValue" [displayExpr]="displayExpEnduserName"
											valueExpr="code" placeholder="Select End-User" [(opened)]="isEndUsersDropDownBoxOpened"
											[showClearButton]="true" [deferRendering]="false" [dataSource]="enduserDataSource"
											(onOptionChanged)="onEndUsersSelectionChanged($event)" [remoteOperations]="false">
											<div *dxTemplate="let data of 'content'">
												<dx-data-grid #endUsersDataGrid [dataSource]="enduserDataSource" keyExpr='code'
													[columns]="endUsersGridBoxColumns" [hoverStateEnabled]="true"
													[(selectedRowKeys)]="endUsersGridBoxValue" height="100%">
													<dxo-selection mode="single"></dxo-selection>
													<dxo-filter-row [visible]="true"></dxo-filter-row>
													<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
													<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
												</dx-data-grid>
											</div>
										</dx-drop-down-box>
									</div>
								</div>
							</div> -->
							<div class="cols11 cols11addbtn">
								<div class="dx-field feildCol">
									<div class="dx-field-label">Item</div>
									<div class="dx-field-value" style="width: 100%;">
										<dx-drop-down-box [dataSource]="itemDataSource" [(value)]="lengthSelectedItems"
											valueExpr="itemname" placeholder="Select Item..." [showClearButton]="true"
											[displayExpr]="displayExprItemName" (onValueChanged)="onItemValueChanged($event)">
											<div *dxTemplate="let data of 'content'">
												<dx-tag-box [(value)]="arrSelectedItemList" [grouped]="true">
												</dx-tag-box>
												<dx-data-grid #itemDataGrid [dataSource]="itemDataSource"
													[columns]="itemGridBoxColumns" [hoverStateEnabled]="true"
													[filterRow]="{ visible: true }" [height]="345" keyExpr='itemname'
													[(selectedRowKeys)]="arrSelectedItemList" [remoteOperations]="false"
													[wordWrapEnabled]="true" [showBorders]="true"
													(onSelectionChanged)="onItemSelectionChanged($event)">
													<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
														[useNative]="false"></dxo-scrolling>
													<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
													<dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>
												</dx-data-grid>

											</div>
										</dx-drop-down-box>
									</div>
								</div>
							</div>
							<div class="cols1 cols1addbtn txtRight">
								<dx-button class="secondaryBtn button center" type="normal" stylingMode="contained"
									[useSubmitBehavior]="false" icon="add" (onClick)="addManualAdjustmentItems()" text="Add">
								</dx-button>
							</div>
						</div>
						<div class="mrgT10">
							<dx-data-grid #ignoredClaimItemsRef id="gridContainer" [dataSource]="ignoredClaimItemsDataSource"
								[remoteOperations]="false" keyExpr="guid" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
								[showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
								[allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
								[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true"
								[width]="'100%'" (onSelectionChanged)="doSelectionChangedIgnoredGrd($event)"
								(onEditorPreparing)="doEditorPreparingIgnoredGrd($event)"
								[(selectedRowKeys)]="selectedRowKeysForIgnoreItems"
								[customizeColumns]="ignoredClaimItemGridCustomizeColumns"
								(onEditorPreparing)="doOnEditorPreparingIgnoredClaimItem($event)"
								(onInitialized)="doInitializedIgnoredClaimItemDataGrid($event)"
								(onToolbarPreparing)="doIgnoredItemsToolbarPreparing($event)" [height]="'calc(100vh - 465px)'"
								(onSaved)="doOnSavedIgnoredClaimItemDataGrid()" class="cellColor hoverClr hideFilterPanelInx">
								<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [showScrollbar]="'always'"
									[bounceEnabled]="false" [useNative]="false"></dxo-scrolling>
								<dxo-filter-row [visible]="true"></dxo-filter-row>
								<dxo-filter-panel [visible]="true"></dxo-filter-panel>
								<!-- <dxo-group-panel [visible]="false"></dxo-group-panel> -->
								<dxo-header-filter [visible]="false"></dxo-header-filter>
								<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
								<dxo-column-fixing [enabled]="true"></dxo-column-fixing>
								<dxo-selection mode="multiple"
									*ngIf="manualAdjustmentClaim.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT"></dxo-selection>
								<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadIgnoredItemTab"
									[customSave]="doSaveIgnoredItemTab" [storageKey]="stateStorageIgnoredTabKey">
								</dxo-state-storing>
								<dxo-editing mode="row"
									[allowUpdating]="manualAdjustmentClaim.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT"
									[allowDeleting]="true" [allowAdding]="false" [useIcons]="true">
								</dxo-editing>
								<div *dxTemplate="let data of 'templateIgnoredFilterText'">
									<div (click)="doOpenIgnoredFilterBuilder()" class="highlight mrgR10 pointerMark">
										<span>{{filterTextForIgnored}}</span>
									</div>
								</div>
								<div *dxTemplate="let data of 'templateIgnoredActionBtns'">
									<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
										[showArrowIcon]="false" text="Action"
										class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
										[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
										icon="spindown" (onButtonClick)="doActionsBtnClickForIgnored($event)"
										(onItemClick)="doActionsBtnItemClickForIgnored($event)"
										[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
								</div>
								<!--	<dxi-column caption="End-User Code" dataField="end_user_code"
									cellTemplate="cellTemplateEndUserCode" dataType="string"
									[visible]="!isCustomerChargebackbasis" [showInColumnChooser]="!isCustomerChargebackbasis"
									[allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateEndUserCode'">
										{{element.data.end_user_code || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="End-User" dataField="end_user_name" cellTemplate="cellTemplateEndUser"
									[visible]="!isCustomerChargebackbasis" [showInColumnChooser]="!isCustomerChargebackbasis"
									[allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateEndUser'">
										{{element.data.end_user_name || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="PriceList No." dataField="pricelistno"
									cellTemplate="cellTemplatePriceListNo" dataType="string" [allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplatePriceListNo'">
										{{element.data.pricelistno || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="PriceList Name" dataField="pricelistname"
									cellTemplate="cellTemplatePriceListName" dataType="string" [allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplatePriceListName'">
										{{element.data.pricelistname || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="Item" dataField="itemname" cellTemplate="cellTemplateItemName"
									dataType="string" [allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateItemName'">
										{{element.data.itemname || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="Item Description" dataField="description"
									cellTemplate="cellTemplateDescription" dataType="string" [allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateDescription'">
										{{element.data.description || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="Min" dataField="min" [allowEditing]="false" cellTemplate="cellTemplateMin"
									[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true"
									dataType="string">
									<div *dxTemplate="let element of 'cellTemplateMin'">
										{{element.data.min || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="Max" dataField="max" [allowEditing]="false" cellTemplate="cellTemplateMax"
									[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true"
									dataType="string">
									<div *dxTemplate="let element of 'cellTemplateMax'">
										{{element.data.max || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="UOM (Contract)" dataField="selectedUom"
									cellTemplate="cellTemplateContractUOM" alignment="center" dataType="string"
									[allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateContractUOM'">
										{{element.data.selectedUom | uppercase}}
									</div>
								</dxi-column>
								<dxi-column caption="Total Purchase" dataField="total_bought" alignment="right"
									dataType="number" [allowHeaderFiltering]="false" [allowEditing]="false"
									[allowFiltering]="false" [visible]="isShowMinMaxColumn"
									cellTemplate="cellTemplateTotalPurchase">
									<div *dxTemplate="let element of 'cellTemplateTotalPurchase'">
										{{element.data.total_bought|| 0}}
									</div>
								</dxi-column>
								<dxi-column caption="Other Purchase" dataField="other_bought" [allowEditing]="false"
									cellTemplate="cellTemplateOtherBought" [allowHeaderFiltering]="false"
									[allowFiltering]="false" [allowSorting]="true" dataType="number"
									[visible]="isShowMinMaxColumn" alignment="right">
									<div *dxTemplate="let element of 'cellTemplateOtherBought'">
										{{element.data.other_bought|| 0}}
									</div>
								</dxi-column> -->
								<dxi-column caption="Claimed Qty" dataField="quantity" cellTemplate="cellTemplateClaimed"
									alignment="center" dataType="number" [allowEditing]="true">
									<div *dxTemplate="let element of 'cellTemplateClaimed'">
										{{element.data.quantity || ''}}
									</div>
								</dxi-column>
								<dxi-column caption="Customer Price" dataField="listPrice" alignment="right" dataType="number"
									[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false">
									<dxo-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-format>
									<div *dxTemplate="let element of 'cellTemplateCustomerPrice'">
										{{element.data.listPrice|| 0 | customCurrencyPipe}}
									</div>
								</dxi-column>
								<dxi-column
									[caption]="isCustomerChargebackbasis ? 'Customer Invoice Price' : 'End-user Invoice Price'"
									dataField="final_amount" dataType="number" [allowHeaderFiltering]="false"
									[allowFiltering]="false" [allowEditing]="false">
									<dxo-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-format>
								</dxi-column>

								<dxi-column caption="Chargeback Amount" dataField="chargebackAmount" alignment="right"
									dataType="number" [allowHeaderFiltering]="false" [allowFiltering]="false"
									[allowEditing]="true">
									<dxo-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-format>
									<div *dxTemplate="let element of 'cellTemplateChargebackAmountContract'">
										{{element.data.chargebackAmount|| 0 | customCurrencyPipe}}
									</div>
								</dxi-column>
								<dxi-column caption="End-User Payout Code" dataField="enduser_payout_code"
									[visible]="!isCustomerChargebackbasis" [showInColumnChooser]="!isCustomerChargebackbasis"
									cellTemplate="cellTemplateEndUserPayoutCode" alignment="center" dataType="string"
									[allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateEndUserPayoutCode'">
										{{element.data.enduser_payout_code || ''}}
									</div>
								</dxi-column>
								<dxi-column dataField="taxexempt" caption="Tax Exempt" cellTemplate="cellTemplateTaxExempt"
									dataType="boolean" [allowEditing]="false">
									<dxo-header-filter [dataSource]="listOfTaxExemptHeaderFilter">
									</dxo-header-filter>
									<div *dxTemplate="let element of 'cellTemplateTaxExempt'">
										{{element.data.taxexempt ? 'YES' :'NO'}}
									</div>
								</dxi-column>
								<dxi-column caption="Tax Rate" dataField="taxrate" cellTemplate="cellTemplatetaxrate"
									alignment="center" dataType="number" [allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplatetaxrate'">
										{{element.data.taxrate || 0 | customNumberNoRoundingPipe}} %
									</div>
								</dxi-column>
								<dxi-column caption="Tax Amount" dataField="taxamount" dataType="number" [allowEditing]="false">
									<dxo-format type="currency" [precision]="decimalPointForCurrency"></dxo-format>
								</dxi-column>

								<dxi-column caption="Claimed Amount" dataField="linetotal" dataType="number"
									[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false">
									<dxo-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-format>
									<!-- <div *dxTemplate="let element of 'cellTemplateClaimedAmount'">
										{{element.data.linetotal|| 0 | customCurrencyPipe}}
									</div> -->
								</dxi-column>
								<dxi-column caption="Remark" dataField="remark" cellTemplate="cellTemplateRemark"
									dataType="string" [allowHeaderFiltering]="false" [allowFiltering]="false"
									[allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateRemark'">
										{{element.data.remark || ''}}
									</div>
								</dxi-column>
								<dxi-column dataField="reason_value" [allowEditing]="true" dataType="string" caption="Reason"
									[visible]="true" [showInColumnChooser]="true" cellTemplate="cellTemplateReasonValue">
									<dxo-header-filter [dataSource]="listOfCustomOverrideReason" valueExpr="value"
										displayExpr="label">
									</dxo-header-filter>
									<dxo-lookup [dataSource]="listOfCustomOverrideReason" valueExpr="value" displayExpr="label"
										[allowSelectAll]="false" searchMode="contains">
									</dxo-lookup>
									<div *dxTemplate="let element of 'cellTemplateReasonValue'">
										{{element.data.reason_value || '' | filterFromList:listOfCustomOverrideReason : 'value'
										:
										'label'}}
									</div>
									<dxi-validation-rule type="required"></dxi-validation-rule>
								</dxi-column>
								<dxi-column dataField="reason_description" dataType="string" caption="Reason Description"
									[visible]="true" [showInColumnChooser]="true" [allowEditing]="false">
									<dxi-validation-rule type="required"></dxi-validation-rule>
								</dxi-column>

								<dxi-column type="buttons" caption="Action" [allowFixing]="true"
									[visible]="manualAdjustmentClaim.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT"
									[allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"
									alignment="center" [allowResizing]="true" [minWidth]="120" [width]="120"
									[allowExporting]="false" [allowEditing]="false">
									<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip"></dxi-button>
									<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
								</dxi-column>

								<dxo-summary>
									<dxi-total-item column="quantity" alignment="center" summaryType="sum" displayFormat="{0}">
									</dxi-total-item>
									<dxi-total-item column="amount" alignment="right" summaryType="sum" displayFormat="{0}">
										<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-value-format>
									</dxi-total-item>
									<dxi-total-item column="linetotal" alignment="right" summaryType="sum" displayFormat="{0}">
										<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-value-format>
									</dxi-total-item>
									<dxi-total-item column="taxamount" alignment="right" summaryType="sum" displayFormat="{0}">
										<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-value-format>
									</dxi-total-item>

									<!-- <dxi-group-item column="taxamount" summaryType="sum" displayFormat="{0}"
										[showInGroupFooter]="false" [alignByColumn]="true">
										<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-value-format>
									</dxi-group-item>
									<dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}"
										[showInGroupFooter]="false" [alignByColumn]="true">
									</dxi-group-item>
									<dxi-group-item column="linetotal" summaryType="sum" displayFormat="{0}"
										[showInGroupFooter]="false" [alignByColumn]="true">
										<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-value-format>
									</dxi-group-item> -->
								</dxo-summary>

							</dx-data-grid>
						</div>
					</div>
					<div *ngIf="isShowDropDownButton" class="mrgT20">
						<div class="dx-field cols9 ">
							<div class="dx-field-label"> Remark/Memo</div>
							<div class="dx-field-value">
								<dx-text-area [height]="90" [maxLength]="500" [(value)]="manualAdjustmentClaim.remarkOrMemo"
									name="remarkOrMemo">
								</dx-text-area>
							</div>
						</div>
						<div class="padR0 alignRight">
							<dx-drop-down-button [dropDownOptions]="{ width: 250 }" [splitButton]="true" [showArrowIcon]="true"
								[useSelectMode]="true" [selectedItemKey]="selectedKeyDropDownBtn" [text]="getSubmitBtnText()"
								[items]="listOfStatusesBtns" [wrapItemText]="true" displayExpr="label" keyExpr="value"
								(onButtonClick)="doSaveUpdateClaimList($event)"
								(onItemClick)="doSaveUpdateClaimStatusChanged($event)" class="button dxSubmitBtn submitDDBtn"
								[width]="250">
							</dx-drop-down-button>
							<!-- doSubmitForApproval -->
						</div>
					</div>

				</dx-scroll-view>
			</div>
		</dx-drawer>
	</div>
</dx-popup>