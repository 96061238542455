import { SideMenuForEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { Injectable } from '@angular/core';
import {
	CRMValStatuses,
	CRMTabValEnum,
	CRMTabLblEnum,
	SalesTabsValEnum
} from "@app/constants-enums/enums";
import { Router } from '@angular/router';
import * as moment from "moment";
@Injectable({
	providedIn: 'root'
})
export class ApDashboardService {

	constructor(
		private _LoginService: LoginService,
		private _CrmsService: CrmsService,
		private _SalesDataSharingService: SalesDataSharingService,
		public _SidemenuService: SidemenuService,
		private _Router: Router,
	) { }

	// Method to redirect Appropriate page
	// Method to redirect Appropriate page
	public tilesClick(clickFor: string) {
		if (clickFor === 'TASKS') {
			this._CrmsService.setCrmSelectedtab(CRMTabValEnum.TASKS);
			this._SidemenuService.setCurrentMenu(SideMenuForEnum.TASKS);
			const filterValue = [{ dataType: 'string', dataField: 'status', selectedFilterOperation: 'contains', filterValues: [CRMValStatuses.OPEN] }];
			this._CrmsService.setStoredFilters(filterValue);
			this._Router.navigate(['adminportal/crms/taskslist']);
		} else if (clickFor === 'LEADS') {
			this._SidemenuService.setCurrentMenu(SideMenuForEnum.LEAD);
			const filterValue = [{ dataType: 'string', dataField: 'status', selectedFilterOperation: 'contains', filterValues: [CRMValStatuses.NEWLEAD, CRMValStatuses.INITIALCONTACT, CRMValStatuses.FOLLOWUP] }];
			this._CrmsService.setStoredFilters(filterValue);
			this._Router.navigate(['/adminportal/crms/leadslist']);
		} else if (clickFor === 'PROSPECTS') {
			this._CrmsService.setCrmSelectedtab(CRMTabValEnum.PROSPECT);
			this._SidemenuService.setCurrentMenu(SideMenuForEnum.PROSPECT);
			const filterValue = [{ dataType: 'string', dataField: 'status', selectedFilterOperation: 'contains', filterValues: [CRMValStatuses.DEMOSAMPLES, CRMValStatuses.PROPOSALDEAL, CRMValStatuses.PRICENEGOTIATION] }];
			this._CrmsService.setStoredFilters(filterValue);
			this._Router.navigate(['/adminportal/crms/prospectslist']);
		} else if (clickFor === 'CUSTOMERS') {
			this._CrmsService.setCrmSelectedtab(CRMTabValEnum.CLIENT);
			this._SidemenuService.setCurrentMenu(SideMenuForEnum.CLIENT);
			this._CrmsService.setStoredFilters(undefined);
			this._Router.navigate(['/adminportal/crms/clientslist']);
		}
	}

	// get next month name
	public getCurrentMonthName() {
		let server_date, d;
		const monthNames = ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
		];
		if (this._LoginService.loginUser && this._LoginService.loginUser.server_date) {
			server_date = this._LoginService.loginUser.server_date;
			if (server_date) {
				d = new Date(server_date);
			}
		}
		return monthNames[d.getMonth()] || '';
	}
}
