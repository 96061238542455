import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { SalesTabsValEnum, QuotesInternalTypeValEnum, QuotationForValEnum, ErpTypeValEnum, NameFromServerKeyEnum, Customer360TilesEnum } from "@app/constants-enums/enums";
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from "@app/constants-enums/constants";
import { SalesDataSharingService } from "@app/services/sales-service/sales-data-sharing.service";
import { DataService } from "@app/services/data.service";
import { LoginService } from "@app/services/login.service";
import { LoaderService } from "@app/services/loaderservices/loader.service";
import { RestApiService } from "@app/services/rest-service/rest-api.service";
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { DxDataGridComponent } from "devextreme-angular";
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: "app-sales-invoice-details-original",
	templateUrl: "./sales-invoice-details-original.component.html",
	styleUrls: ["./sales-invoice-details-original.component.css"],
	providers: [CurrencyPipe, DecimalPipe, CustomCurrencyPipe],
})
export class SalesInvoiceDetailsOriginalComponent implements OnInit, OnDestroy {
	public myMath = Math;
	public erpTypeValEnum = ErpTypeValEnum;
	public SalesTabsValEnum = SalesTabsValEnum;
	public QuotesInternalTypeValEnum = QuotesInternalTypeValEnum;
	public QuotationForValEnum = QuotationForValEnum;
	public salesDetails: any;
	public clientsList: any[] = [];
	public uomList: any[] = [];
	private dataOutReqSubscription: Subscription;
	private searchParams: any[] = [];

	public isResponse: boolean = false;
	public erp_type: string = '';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public listOfPrices: any[] = [];
	public isShowPickStatusColumn: boolean = false;
	public shippingTypesDataSource: any = {};
	public isShowDataGrid: boolean = false;
	@ViewChild('itemsListOriginDatagridRef') itemsListOriginDatagridRef: DxDataGridComponent;
	private loaderConfig: any;
	public isShowClassesColumn: boolean;
	public tileDetailsFrom: string;
	public Customer360TilesEnum = Customer360TilesEnum;
	public isShowCostColumn: boolean = false;
	public isEnabledMasterDetails: boolean = false;
	public pageOptions: PageOptions = new PageOptions();
	public isShowOriginalInvoiceDetails: boolean = false;
	public originalInvoiceDetails: any;
	public isShowEligibleField: boolean;
	public isShowEligibleColumn: boolean;
	public listOfSalesPpl: any[];
	public isMultipleSalesReps: boolean;
	public decimalNumberFormat: string = '1.2-2';
	public isEnableAmortisePaymentsFeature: boolean = false;
	public isItemListLevel: boolean = false;
	public isEnableProfitOnDocHeaderFeature: boolean = false;
	public specificListOfProfitOnDocHeader: any[] = [];
	private getFetureCustomFieldsSbsn: Subscription;
	private listOfFeatureCustomFields: any[] = [];

	public decimalForPercentage: number;
	public decimalPercentageFormat: string = '1.2-2';
	public isShowCardcodeColumn: boolean = false;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public canEnableOtherCurrencies: boolean;

	public isEnableDistributionChannels: boolean = false;
	public listOfDistributionChannels: any[] = [];
	private getListOfDistributionChannelsSbsn: Subscription;


	constructor(private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _SalesDataSharingService: SalesDataSharingService,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _DecimalPipe: DecimalPipe,
		private _CurrencyPipe: CurrencyPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		public _AppCommonSrvc: AppCommonSrvc) {
		// pdfMake.vfs = pdfFonts.pdfMake.vfs;
		this.loaderConfig = {
			dx_position: '{of: #mainContent1}'
		};
	}

	ngOnInit() {
		this.isShowDataGrid = true;
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}

		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.canEnableOtherCurrencies = this._LoginService.loginUser.account_detail.enable_other_currencies || false;
		}
		this.listOfSalesPpl = this._DataService.getSalesPpl() || [];
		this.tileDetailsFrom = this._SalesDataSharingService.getSalesTileFrom();
		this.uomList = this._DataService.getUOMs();

		this.decimalForPercentage = this._LoginService.decimalPointForPercentage;
		this.decimalPercentageFormat = this._LoginService.decimalPointPercentageFormat;

		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_distribution_channels')) {
			this.isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels || false;
		}
		this.setIsShowIxcodeOrDocnum();
		this.doCheckUseCustomersFrom();
		this.preInIt();
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}
	}

	displayPhoneNoWithCode(element) {
		let phonenumberstr = "";
		phonenumberstr = element.phone1 || "";
		if (element.dialcode) {
			const getPhoneCodeData = this._DataService.getSplitPhoneCode(
				element.dialcode
			);
			if (getPhoneCodeData && getPhoneCodeData.length > 0) {
				phonenumberstr = getPhoneCodeData[0].code + " " + element.phone1;
			}
		}
		return phonenumberstr;
	}

	// Method used to Show ixode or docnum with synced
	private setIsShowIxcodeOrDocnum() {
		this.isShowClassesColumn = false;
		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			this.isShowClassesColumn = true;
		}
	}

	private preInIt() {
		this.salesDetails = {};
		this.salesDetails.contactpersondata = undefined;
		this.salesDetails.salespersondata = undefined;
		const salesListObj = this._SalesDataSharingService.getSalesQuoOrdInvDetails();
		const selectedInvoiceDetails: any = this._SalesDataSharingService.getOriginSalesInvoiceId();
		this.searchParams = [];
		this.searchParams.push({ 'docnum': selectedInvoiceDetails.source_docnum });
		const invoicesFormData = new FormData();
		invoicesFormData.append('usr', this._LoginService.loginUser.user);
		invoicesFormData.append('token', this._LoginService.loginUser.token);
		invoicesFormData.append('entity', ServerEntity.INVOICES);
		invoicesFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		// invoicesFormData.append('is_dropdown', 'true');
		invoicesFormData.append('search', JSON.stringify(this.searchParams));
		/*
		//  Temporary Commented below code
		if (selectedInvoiceDetails && selectedInvoiceDetails.docdate) {
			// invoicesFormData.append('docdate', selectedInvoiceDetails.docdate);
		}
		*/
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show(this.loaderConfig);

		this.isResponse = false;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(invoicesFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				this.isResponse = true;
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const invoiceDetails = response.data[0];
						this.salesDetails = invoiceDetails;
						this.isShowCostColumn = false;
						this.isEnabledMasterDetails = false;
						if (this.salesDetails.documentlines && this.salesDetails.documentlines.length > 0) {
							this.salesDetails.documentlines.forEach(dl => {
								if (dl.costassignments && dl.costassignments.length > 0) {
									this.isEnabledMasterDetails = true;
								}
								if (dl.costperitem !== 0) {
									this.isShowCostColumn = true;
								}
								if (dl.assigned_date && dl.costassignment_id) {
									this.isShowCostColumn = true;
								}
							});
						}

						this.isShowEligibleField = false;
						if (this.salesDetails.hasOwnProperty('eligible')) {
							this.isShowEligibleField = true;
						}

						this.isShowEligibleColumn = invoiceDetails.documentlines.some(dl => {
							return dl.hasOwnProperty('eligible');
						});

						this.isMultipleSalesReps = false;
						if (this.salesDetails.hasOwnProperty('salesreps')) {
							this.isMultipleSalesReps = true;
						}

						if (this.canEnableOtherCurrencies) {
							setTimeout(() => {
								this.customCurrencyOption.currencyName = invoiceDetails.doccurrency;
							});
						}

						if (this.isEnableDistributionChannels) {
							const filterObj = ['code', 'contains', invoiceDetails.distribution_channels];
							this.doFetchListOfDistributionChannels(filterObj);
						}

						this.searchParams = [];
						if (invoiceDetails.guidclient) {
							this.searchParams.push({ 'guid': invoiceDetails.guidclient });
						} else if (invoiceDetails.cardcode) {
							this.searchParams.push({ 'code': invoiceDetails.cardcode });
						}
						// reqFormData = this.loadSalesDetailsData();
						const clientsFormData = new FormData();
						clientsFormData.append('usr', this._LoginService.loginUser.user);
						clientsFormData.append('token', this._LoginService.loginUser.token);
						clientsFormData.append('entity', ServerEntity.CLIENTS);
						clientsFormData.append('method', ServerMethods.GET_ENTITY_DATA);
						// clientsFormData.append('is_dropdown', 'true');
						clientsFormData.append('search', JSON.stringify(this.searchParams));
						if (this.dataOutReqSubscription) {
							this.dataOutReqSubscription.unsubscribe();
						}
						this._LoaderService.show(this.loaderConfig);
						this.isResponse = false;
						this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(clientsFormData).subscribe({
							next: (response) => {
								this.isResponse = true;
								this._LoaderService.hide();
								if (response.flag) {
									if (response.data && response.data.length > 0) {
										const client = response.data[0];
										// Find Contact Person Details
										if (client.contactemployees && client.contactemployees.length > 0) {
											const contactPplData = client.contactemployees.filter(itemContact => itemContact.internalcode === invoiceDetails.contactpersoncode);
											this.salesDetails.contactpersondata = contactPplData && contactPplData.length > 0 ? contactPplData[0] : undefined;
										}
										// Set Billing/Shipping Address
										if (this.salesDetails.contactpersondata === undefined) {
											this.salesDetails.contactpersondata = {};
										}
										if (client && client.human_readable_account_code) {
											this.salesDetails.human_readable_account_code = client.human_readable_account_code;
										}
										this.setBillingAddress(client);
										this.setShippingAddress(client);
										this.fetchedListOfPrices(client.pricelistnum);
									} else {
										// this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
									}
								} else {
									this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
								}
							}, error: error => {
								this._LoaderService.hide();
								this.isResponse = true;
								// this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
						});
						this.doCheckEnableProfitOnDocFeature();
						this.doCheckEnableAmortisePayments();
					} else {
						this.salesDetails = undefined;
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this.isResponse = true;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}


	// Method To Set billing Address
	private setBillingAddress(client: any) {
		if (this.salesDetails.paytocode && client.address && client.address.billto && client.address.billto.length > 0) {
			const clientAddressData = client.address.billto.filter(itemAddress => itemAddress.addressname === this.salesDetails.paytocode);
			this.salesDetails['contactpersondata']['billtodata'] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.salesDetails.contactpersondata["billtodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Set billing Address
	private setShippingAddress(client: any) {
		if (this.salesDetails.shiptocode && client.address && client.address.shipto && client.address.shipto.length > 0) {
			const clientAddressData = client.address.shipto.filter(itemAddress => itemAddress.addressname === this.salesDetails.shiptocode);
			this.salesDetails['contactpersondata']["shipptodata"] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.salesDetails.contactpersondata["shipptodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Get List of Prices from Clients's pricelistnum
	private fetchedListOfPrices(pricelistnum?: string) {
		this.listOfPrices = [];
		if (!pricelistnum) {
			return;
		}
		const viewFields = ['pricelistno', 'pricelistname'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.PRICELISTS);
		// formdata.append('is_dropdown', '1');
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		// this._LoaderService.show(this.loaderConfig);
		this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: response => {
				// this._LoaderService.hide();
				this.listOfPrices = [];
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data;
					} else {
						// this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				// this._LoaderService.hide();
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doToolbarPreparing(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel();
					}
				}
			}
		);
	}

	private doExportToExcel() {
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Invoice_' + this.salesDetails.ixcode + '.xlsx';
		worksheet = workbook.addWorksheet('Invoice');

		exportDataGrid({
			worksheet: worksheet,
			component: this.itemsListOriginDatagridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'itemname') {
						if (gridCell.data.itemname) {
							excelCell.value = gridCell.data.itemname;
						}
					}
					if (gridCell.column.dataField === 'pickstatus') {
						if (gridCell.data.pickstatus && gridCell.data.pickstatus.toString().toLowerCase() === 'tyes') {
							excelCell.value = 'Yes';
						} else {
							excelCell.value = 'No';
						}
					}
					if (gridCell.column.dataField === 'price') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
					if (gridCell.column.dataField === 'linetotal') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
				}
			}
		}).then((cellRange) => {
			// header
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			if (this.salesDetails.address) {
				worksheet.mergeCells(2, 4, 2, 7);
			}
			headerRow.getCell(1).value = 'Invoice ID:';
			headerRow.getCell(1).font = { 'bold': true };
			let orderIdValue = this.salesDetails.docnum;
			headerRow.getCell(2).value = orderIdValue;
			headerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.salesDetails.address) {
				headerRow.getCell(3).value = 'Billing Address';
				headerRow.getCell(3).font = { 'bold': true };
				headerRow.getCell(4).value = this.salesDetails.address || '';
			}

			// customer
			const customerRow = worksheet.getRow(3);
			if (this.salesDetails.address2) {
				worksheet.mergeCells(3, 4, 3, 7);
			}
			customerRow.height = 30;
			customerRow.getCell(1).value = 'Customer';
			customerRow.getCell(1).font = { 'bold': true };
			let cardCode = '';
			if (!this.isQBErpUser) {
				cardCode = this.salesDetails.cardcode || '';
			}
			customerRow.getCell(2).value = (cardCode ? cardCode + ' - ' : '') + this.salesDetails.cardname;
			customerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.salesDetails.address2) {
				customerRow.getCell(3).value = 'Shipping Address';
				customerRow.getCell(3).font = { 'bold': true };
				customerRow.getCell(4).value = this.salesDetails.address2 || '';
			}

			// DocDate
			const docDateRow = worksheet.getRow(4);
			docDateRow.height = 30;
			docDateRow.getCell(1).value = 'Invoice Date';
			docDateRow.getCell(1).font = { 'bold': true };
			docDateRow.getCell(2).value = this.salesDetails.docdate;
			docDateRow.getCell(2).alignment = { horizontal: 'left' };

			// DocdueDate
			const docdueDateRow = worksheet.getRow(5);
			docdueDateRow.height = 30;
			docdueDateRow.getCell(1).value = 'Invoice Due Date';
			docdueDateRow.getCell(1).font = { 'bold': true };
			docdueDateRow.getCell(2).value = this.salesDetails.docduedate;
			docdueDateRow.getCell(2).alignment = { horizontal: 'left' };

			// Order Status
			const documentStatusRow = worksheet.getRow(6);
			documentStatusRow.height = 30;
			documentStatusRow.getCell(1).value = 'Invoice Status';
			documentStatusRow.getCell(1).font = { 'bold': true };
			let orderStatus = '';
			if (this.salesDetails.documentstatus.toString().toLowerCase() === 'bost_open') {
				orderStatus = 'Open';
			} else {
				orderStatus = 'Closed';
			}
			documentStatusRow.getCell(2).value = orderStatus;
			documentStatusRow.getCell(2).alignment = { horizontal: 'left' };
			let numberOfRows = this.itemsListOriginDatagridRef.instance.getVisibleRows().length;

			// Sub Total
			let rowIncrement = 11;
			let subTotal = 0;
			const subTotalRow = worksheet.getRow(numberOfRows + rowIncrement);
			subTotalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.salesDetails.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.salesDetails.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				subTotalRow.getCell(5).value = 'Sub Total';
				subTotalRow.getCell(5).font = { 'bold': true };
				subTotalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.salesDetails.doctotalsys);
				subTotalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.salesDetails.totaldiscount > 0) {
				// Discount
				let discount = 0;
				rowIncrement = rowIncrement + 1;
				const discountRow = worksheet.getRow(numberOfRows + rowIncrement);
				discountRow.height = 30;
				discountRow.getCell(6).value = 'Discount';
				discountRow.getCell(6).font = { 'bold': true };
				discountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.salesDetails.totaldiscount);
				discountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.salesDetails.totalmiscamount > 0) {
				// Miscellaneous Charge
				let totalmiscamount = 0;
				rowIncrement = rowIncrement + 1;
				const totalmiscamountRow = worksheet.getRow(numberOfRows + rowIncrement);
				totalmiscamountRow.height = 30;
				totalmiscamountRow.getCell(6).value = 'Miscellaneous Charge';
				totalmiscamountRow.getCell(6).font = { 'bold': true };
				totalmiscamountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.salesDetails.totalmiscamount);
				totalmiscamountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.salesDetails.shippingcharges > 0) {
				// Frieght Charge
				let shippingcharges = 0;
				rowIncrement = rowIncrement + 1;
				const shippingchargesRow = worksheet.getRow(numberOfRows + rowIncrement);
				shippingchargesRow.height = 30;
				shippingchargesRow.getCell(6).value = 'Frieght Charge';
				shippingchargesRow.getCell(6).font = { 'bold': true };
				shippingchargesRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.salesDetails.shippingcharges);
				shippingchargesRow.getCell(7).alignment = { horizontal: 'right' };
			}

			// Total
			let total = 0;
			rowIncrement = rowIncrement + 1;
			const totalRow = worksheet.getRow(numberOfRows + rowIncrement);
			totalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.salesDetails.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.salesDetails.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				totalRow.getCell(5).value = 'Total';
				totalRow.getCell(5).font = { 'bold': true };
				totalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.salesDetails.doctotal);
				totalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			// Remark
			const remarkRow = worksheet.getRow(numberOfRows + 13);
			totalRow.height = 30;
			remarkRow.getCell(1).value = 'Remark';
			remarkRow.getCell(1).font = { 'bold': true };
			remarkRow.getCell(2).value = this.salesDetails.comments || '';
			remarkRow.getCell(2).alignment = { horizontal: 'right' };

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	public doRowPreparedDocLine(e: any) {
		if (e.rowType == "data" && (!e.data.costassignments || e.data.costassignments && e.data.costassignments.lenght <= 0)) {
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
			}
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
			}
		}
	}

	public doOriginalInvoiceDetails() {
		const sourceDocNum = this.salesDetails.source_docnum;

		const invoicesFormData = new FormData();
		invoicesFormData.append('usr', this._LoginService.loginUser.user);
		invoicesFormData.append('token', this._LoginService.loginUser.token);
		invoicesFormData.append('entity', ServerEntity.INVOICES);
		invoicesFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		// invoicesFormData.append('is_dropdown', 'true');
		invoicesFormData.append('search', JSON.stringify([{ docnum: sourceDocNum }]));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show(this.loaderConfig);
		this.isResponse = false;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(invoicesFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.isShowOriginalInvoiceDetails = true;
					if (response.data && response.data.length > 0) {
						const invoiceDetails = response.data[0];
						this.originalInvoiceDetails = invoiceDetails;
					}
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}

	// #region for Show/Hide Amortise Payment field
	private doCheckEnableAmortisePayments() {
		this.isEnableAmortisePaymentsFeature = false;
		try {
			this.isEnableAmortisePaymentsFeature = this._LoginService.loginUser.account_detail.enable_amortise_payments;
		} catch (e) {
			this.isEnableAmortisePaymentsFeature = false;
		}
		if (this.isEnableAmortisePaymentsFeature) {
			this.doGetFetureCustomFields();
		}
	}

	private setAmortisePaymentsFeature() {
		this.isItemListLevel = this.listOfFeatureCustomFields.filter(custField => custField.section === 'ITEMLIST').length > 0 ? true : false;

		const documentLines = this.salesDetails.documentlines || [];
		this.listOfFeatureCustomFields.forEach(custField => {
			if (custField.section === 'ITEMLIST' && custField.custom_field_type === 'date' && custField.entity_type === 'invoices') {
				for (let i = 0; i < documentLines.length; i++) {
					if (documentLines[i].hasOwnProperty(custField.ix_custom_field)) {
						let custom_label = custField.ix_custom_field_title;
						if (custField.custom_field_title) {
							custom_label = custField.custom_field_title;
						}
						if (this.itemsListOriginDatagridRef) {
							this.itemsListOriginDatagridRef.instance.columnOption(custField.ix_custom_field, 'caption', custom_label);
						}
					}
				};
			}
		});
	}
	// #endregion

	public doGetFetureCustomFields() {
		let feature_guid = '';
		if (this.isEnableAmortisePaymentsFeature) {
			feature_guid = '7adc7b7ebcdd4dfea92464d6175b8ba0';
		}
		if (this.isEnableProfitOnDocHeaderFeature) {
			feature_guid = 'ffc6f6d2127d41a7b4a3e358c8389912';
		}
		if (this._LoginService.loginUser.account_detail['enable_invoice_custom_fields_feature']) {
			feature_guid = 'bc541207c8644ec3b14fe1ade63460b9';
		}
		const searchCriteria: any = {
			feature_guid: feature_guid
		};
		if (this.isEnableAmortisePaymentsFeature) {
			searchCriteria.entity_type = 'invoices';
		}
		if (!feature_guid) {
			return false;
		}
		this.specificListOfProfitOnDocHeader = [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		formData.append('search', JSON.stringify([searchCriteria]));
		if (this.getFetureCustomFieldsSbsn) {
			this.getFetureCustomFieldsSbsn.unsubscribe();
		}
		this.getFetureCustomFieldsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfFeatureCustomFields = response.data || [];
						if (this.isEnableProfitOnDocHeaderFeature) {
							this.setProfitOnDocHeaderFeature();
						}
						if (this.isEnableAmortisePaymentsFeature) {
							setTimeout(() => {
								this.setAmortisePaymentsFeature();
							}, 250);
						}
					} else {
						// this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// #region for Profit On Document Features
	private setProfitOnDocHeaderFeature() {
		this.specificListOfProfitOnDocHeader = [];
		this.listOfFeatureCustomFields.forEach(custField => {
			if (this.salesDetails && this.salesDetails.hasOwnProperty(custField.ix_custom_field) && this.salesDetails[custField.ix_custom_field]) {
				const newCustFields: any = {
					guid: custField.guid,
					custom_value: null,
					custom_display_value: null,
					custom_label: null
				}
				newCustFields.custom_label = custField.ix_custom_field_title;
				if (custField.custom_field_title) {
					newCustFields.custom_label = custField.custom_field_title;
				}
				newCustFields.custom_value = this.salesDetails[custField.ix_custom_field];
				if (custField.custom_field_output_format === 'percentage') {
					newCustFields.custom_display_value = this._DecimalPipe.transform(this.salesDetails[custField.ix_custom_field] || 0, this.decimalNumberFormat) + '%';
				}
				if (custField.custom_field_output_format === 'amount') {
					newCustFields.custom_display_value = this._CustomCurrencyPipe.transform(this.salesDetails[custField.ix_custom_field] || 0);
				}
				if (custField.custom_field_output_format === 'boolean') {
					newCustFields.custom_display_value = 'No';
					if (this.salesDetails[custField.ix_custom_field]) {
						newCustFields.custom_display_value = 'Yes';
					}
				}
				this.specificListOfProfitOnDocHeader.push(newCustFields);
			}
		});
	}

	private doCheckEnableProfitOnDocFeature() {
		this.isEnableProfitOnDocHeaderFeature = false;
		try {
			this.isEnableProfitOnDocHeaderFeature = this._LoginService.loginUser.account_detail.enable_profit_on_document_header;
		} catch (e) {
			this.isEnableProfitOnDocHeaderFeature = false;
		}
		if (this.isEnableProfitOnDocHeaderFeature) {
			this.doGetFetureCustomFields();
		}
	}

	// #endregion

	private doCheckUseCustomersFrom() {
		let use_customers_from: string;
		try {
			use_customers_from = this._LoginService.loginUser.account_detail.use_customers_from;
		} catch (e) {
			use_customers_from = '';
		}
		this.isShowCardcodeColumn = false;
		if (use_customers_from === 'itemlist') {
			this.isShowCardcodeColumn = true;
			// It is displays Customer Code column in document line items.
		}
	}

	private doFetchListOfDistributionChannels(distributionChannelSearch?: any[]) {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		reqFormData.append('is_dropdown', 'true');
		reqFormData.append('view_fields', JSON.stringify(['guid', 'code', 'description']));
		if (distributionChannelSearch && distributionChannelSearch.length > 0) {
			reqFormData.append('filter', JSON.stringify(distributionChannelSearch));
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}

		this.getListOfDistributionChannelsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: response => {
				this.listOfDistributionChannels = [];
				if (response && response.flag) {
					this.listOfDistributionChannels = response.data || [];
				}
			}, error: error => {
			}
		});
	}
}
