<div class="dx-field feildCol">
	<div class="dx-field-label">Territory</div>
	<div class="dx-field-value" style="width: 100%;">
		<dx-drop-down-box #territoryDropDown [dataSource]="territoryModel.territoryDataSource"
			[(value)]="territoryModel.lengthSelectedTerritory" [displayExpr]="doDisplayExprTerritoriesText"
			valueExpr="guid" placeholder="Select
			Territory..." [showClearButton]="true" (onValueChanged)="onTerritoryValueChanged($event)">
			<div *dxTemplate="let data of 'content'">
				<dx-check-box text="All Territory" (onValueChanged)="checkAllTerritory()"
					[(value)]="territoryModel.chkAllTerritory" class="mrgB10" width="25%">
				</dx-check-box>
				<!-- <dx-text-box *ngIf="territoryModel.territoryGridBoxValue && territoryModel.territoryGridBoxValue.length > 0"
					[readOnly]="true" [hoverStateEnabled]="false" [(value)]="strSelectedTerritoryList"></dx-text-box> -->
				<dx-tag-box [(value)]="territoryModel.arrSelectedTerritoryList" [grouped]="true">
				</dx-tag-box>
				<dx-data-grid #territoryDataGrid [dataSource]="territoryModel.territoryDataSource"
					[columns]="territoryModel.territoryGridBoxColumns" [selection]="{ mode: 'multiple'}"
					[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: pageOptions.pageSize }"
					[filterRow]="{ visible: true }" [height]="325" [allowColumnResizing]="true"
					[allowColumnReordering]="true" [(selectedRowKeys)]="territoryModel.arrSelectedTerritoryList"
					[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
					(onSelectionChanged)="onTerritorySelectionChanged($event)">
					<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
					<dxo-selection [allowSelectAll]="false"></dxo-selection>
				</dx-data-grid>
			</div>
		</dx-drop-down-box>
	</div>
</div>