<div class="rightBar">
	<div class="rightBlock">
		<div class="contentRow cf">
			<div class="borderBox cf padB0">
				<div class="formControls editAgreeDetail">
					<div class="row ">
						<div class="cols12 ">
							<div class="feildCol radioGroup feildColAuto">
								<label>BillBack Type:</label>
								<dx-radio-group [items]="billbackTypeRadio"
									[value]="distributionChargebackContractModelUI.billBackType"
									(onValueChanged)="doChangeBillBackType($event)" [disabled]="isDisablePermission"
									layout="horizontal" displayExpr="text" valueExpr="value">
								</dx-radio-group>
							</div>
						</div>
						<div class="cols12">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="distributionChargebackContractModelUI.contractName"
									maxLength="500">
								</dx-text-box>
								<div class="inputField">
									<label for="contractName">Contract Name</label>
								</div>
							</div>
						</div>
						<div class="cols8">
							<div class="dx-field feildCol" [ngClass]="{inputDisable: isDisablePermission}">
								<div class="dx-field-label">Customer</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-drop-down-box [(value)]="customerGridBoxValue" valueExpr="code"
										[displayExpr]="displayExprCustomerName" placeholder="Select Customer..."
										[(opened)]="isCustomerDropDownBoxOpened" [showClearButton]="true"
										[dataSource]="customerDataSource" (onOptionChanged)="onOptionChangedCustomer($event)"
										[disabled]="isDisablePermission">
										<div *dxTemplate="let data of 'content'">
											<dx-data-grid #customerDataGrid [dataSource]="customerDataSource"
												[columns]="customerGridBoxColumns" [hoverStateEnabled]="true"
												[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
												[(selectedRowKeys)]="customerGridBoxValue" [filterRow]="{ visible: true }"
												[height]="345">
												<dxo-selection mode="single"></dxo-selection>
												<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
													[useNative]="false"></dxo-scrolling>
												<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
											</dx-data-grid>
										</div>
									</dx-drop-down-box>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="datePicker">
								<dx-date-box [(value)]="distributionChargebackContractModelUI.startDate"
									[disabled]="isDisablePermission" type="date" displayFormat="yyyy-MM-dd">
								</dx-date-box>
								<div class="inputField inputFieldFilterLbl">
									<label for="inputFieldFilterLbl">Start Date</label>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="datePicker">
								<dx-date-box [(value)]="distributionChargebackContractModelUI.endDate" type="date"
									displayFormat="yyyy-MM-dd">
								</dx-date-box>
								<div class="inputField inputFieldFilterLbl">
									<label for="inputFieldFilterLbl">End Date</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="borderBox cf">
				<div class="tabbing cf padT10">
					<ul>
						<!-- <li (click)="doClickAgreementTab('chargebackclaims')"
							[ngClass]="{active: currentAgreementTab == 'chargebackclaims'}">
							<a>Chargeback Claims</a>
						</li> -->
						<li (click)="doClickAgreementTab('chargebacktypes')"
							[ngClass]="{active: currentAgreementTab == 'chargebacktypes'}">
							<a>Chargeback Types</a>
						</li>
					</ul>
				</div>

				<!-- <app-dcc-chargeback-claims-tab *ngIf="currentAgreementTab == 'chargebackclaims'"
					[distributionChargebackContractModelUI]="distributionChargebackContractModelUI"
					(saveChargebackClaimsTabDataEvent)="doSaveChargebackClaimsTabData($event)">
				</app-dcc-chargeback-claims-tab> -->

				<app-dcc-chargeback-types-tab *ngIf="currentAgreementTab == 'chargebacktypes'"
					[distributionChargebackContractModelUI]="distributionChargebackContractModelUI"
					(saveChargebackTypesTabDataEvent)="doSaveChargebackTypesTabData($event)">
				</app-dcc-chargeback-types-tab>

				<div class="alignRight mrgT10" *ngxPermissionsOnly="isCurrentTabPermission">
					<dx-button class="primaryBtn" text="Submit" icon="save" type="normal" stylingMode="contained"
						(onClick)="doSaveDistributionChargebackContracts()" [useSubmitBehavior]="false">
					</dx-button>
				</div>
			</div>
		</div>
	</div>
</div>