import { Tasks } from '@app/models/businessplan.model';
import {
	IMG_MARK_AS_PROSPECT_SVG,
	IMG_MARK_AS_CUSTOMER_SVG,
	IMG_CLOSE_SVG,
	IMG_MARK_AS_BUYING_GROUP_SVG,
	IMG_DEAL_SE_SVG,
	IMG_ADD_TASK_SE_SVG,
	IMG_ADD_FILE_SVG,
	IMG_EDIT_SVG,
	IMG_MARK_AS_OPEN_SVG,
	IMG_MARK_AS_CLOSED_SVG,
	IMG_ADD_ORDERS_SVG,
	IMG_ADD_PAYMENTS_SVG,
	IMG_ADD_REBATES_SVG,
	IMG_ADD_CHARGEBACKS_SVG,
	IMG_ICON_COLUMN_CHOOSER,
	IMG_ICON_XLSXFILEPOPUPIX,
	IMG_ICON_REFRESH
} from '@app/constants-enums/constants';
import {
	CRMActionBtnsEnum, ContactDetailTabDataEnum, ContactDetailActionBtnsValEnum, ContactDetailActionBtnsLblEnum, CRMValStatuses,
	CRMLblStatuses, CRMValSources, CRMLblSources, NameFromServerKeyEnum, TaskStatusValEnum, TaskStatusLblEnum, Customer360TimePeriodValEnum, Customer360TimePeriodLblEnum, AppStatesKeyEnum, Customer360TileSettingsValEnum, Customer360TileSettingsLblEnum
} from '@app/constants-enums/enums';
import { CRMTabValEnum, CRMTabLblEnum } from '@app/constants-enums/enums';
import { Injectable, Injector } from '@angular/core';
import { ContactEdit, ContactMove } from '@app/models/businessplan.model';
import * as _ from 'underscore';
import { OptionBtns, TabsData, CommonOptions, TimelinesInsertUpdate } from '@app/models/common.model';
import { DataService } from '../data.service';
import { Guid } from '@app/models/guid';
import * as moment from 'moment';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';

@Injectable({
	providedIn: 'root'
})
export class CrmsService {
	public selectedTabDetails: any;
	public businessPartnerId: any;
	public customerDetailId: any;
	public contactDetailId: any;
	public selectedContactDetailTabDetails: any;
	public customerFrom: any;
	public storedFilters: any;
	private detailTabStoredFilters: any;
	private guid = new Guid();
	private pricelistno: any;
	private crmSelectedTab: string;
	private clientGuid: string;
	private contactEmployeeGuid: string;
	private clientFor: string;
	private entityFor: string;
	private contactDetailsSelectedTab: string;
	private customer360Tile: string;
	private clientDetails: any;
	private clientDetailPageOpenFrom: any;
	private clientDetailByKeyValue: any;
	private _AppCommonSrvc: AppCommonSrvc;

	constructor(private _DataService: DataService, private injector: Injector) {
	}

	setSelectedTabData(val: any) {
		this.selectedTabDetails = val;
	}
	getSelectedTabData() {
		return this.selectedTabDetails;
	}

	setBusinessPartnerId(val: any) {
		this.businessPartnerId = val;
	}
	getBusinessPartnerId() {
		return this.businessPartnerId;
	}

	setContactDetailId(val: any) {
		this.contactDetailId = val;
	}
	getContactDetailId() {
		return this.contactDetailId;
	}
	deactivateAllTabs(tabsList) {
		tabsList.forEach(itemTab => {
			itemTab.isActive = false;
		});
	}

	getCRMTabList() {
		return [
			// {
			// 	tabId: 0,
			// 	tabCode: CRMTabValEnum.DASHBOARD,
			// 	tabName: CRMTabLblEnum.DASHBOARD,
			// 	detailName: 'Dashboard',
			// 	isActive: false
			// },
			{
				tabCode: CRMTabValEnum.LEAD,
				tabName: CRMTabLblEnum.LEAD,
				detailName: 'Lead',
				isActive: false
			}, {
				tabCode: CRMTabValEnum.PROSPECT,
				tabName: CRMTabLblEnum.PROSPECT,
				detailName: 'Prospect',
				isActive: false
			}, {
				tabCode: CRMTabValEnum.CLIENT,
				tabName: CRMTabLblEnum.CLIENT,
				detailName: 'Customer',
				isActive: false
			}, {
				tabCode: CRMTabValEnum.TASKS,
				tabName: CRMTabLblEnum.TASKS,
				detailName: 'Task',
				isActive: false
			}
		];
	}

	getContactDetailTabList(tabCode: string) {
		const tabList: TabsData[] = [];
		switch (tabCode) {
			case NameFromServerKeyEnum.LEAD:
				tabList.push({
					tabId: 1,
					tabCode: ContactDetailTabDataEnum.TASKS,
					tabName: 'Tasks',
					detailName: 'Task',
					isActive: false
				});
				tabList.push({
					tabId: 2,
					tabCode: ContactDetailTabDataEnum.NOTES,
					tabName: 'Notes',
					detailName: 'Note',
					isActive: false
				});
				tabList.push({
					tabId: 0,
					tabCode: ContactDetailTabDataEnum.TIMELINES,
					tabName: 'Timelines',
					detailName: 'Timeline',
					isActive: false
				});

				break;
			case NameFromServerKeyEnum.PROSPECT:
				tabList.push({
					tabId: 2,
					tabCode: ContactDetailTabDataEnum.DEALS,
					tabName: 'Quotes',
					detailName: 'Quote',
					isActive: false
				});
				tabList.push({
					tabId: 1,
					tabCode: ContactDetailTabDataEnum.TASKS,
					tabName: 'Tasks',
					detailName: 'Task',
					isActive: false
				});
				tabList.push({
					tabId: 3,
					tabCode: ContactDetailTabDataEnum.NOTES,
					tabName: 'Notes',
					detailName: 'Note',
					isActive: false
				});
				tabList.push({
					tabId: 0,
					tabCode: ContactDetailTabDataEnum.TIMELINES,
					tabName: 'Timelines',
					detailName: 'Timeline',
					isActive: false
				});
				break;
			case NameFromServerKeyEnum.CLIENT:
				tabList.push({
					tabId: 6,
					tabCode: ContactDetailTabDataEnum.CUSTOMER360,
					tabName: 'Customer 360&#176',
					detailName: 'Customer 360',
					isActive: false
				});
				tabList.push({
					tabId: 7,
					tabCode: ContactDetailTabDataEnum.CONTACTEMPLOYEES,
					tabName: 'Contacts',
					detailName: 'Contacts',
					isActive: false
				});
				tabList.push({
					tabId: 8,
					tabCode: ContactDetailTabDataEnum.ADDRESSES,
					tabName: 'Addresses',
					detailName: 'Addresses',
					isActive: false
				});
				tabList.push({
					tabId: 9,
					tabCode: ContactDetailTabDataEnum.CREDIT_CARDS,
					tabName: 'Credit Cards',
					detailName: 'Credit Cards',
					isActive: false
				});
				tabList.push({
					tabId: 2,
					tabCode: ContactDetailTabDataEnum.DEALS,
					tabName: 'Quotes',
					detailName: 'Quote',
					isActive: false
				});
				tabList.push({
					tabId: 3,
					tabCode: ContactDetailTabDataEnum.ORDERS,
					tabName: 'Orders',
					detailName: 'Order',
					isActive: false
				});
				tabList.push({
					tabId: 6,
					tabCode: ContactDetailTabDataEnum.SHIPMENTS,
					tabName: 'Shipments',
					detailName: 'Shipment',
					isActive: false
				});
				tabList.push({
					tabId: 4,
					tabCode: ContactDetailTabDataEnum.INVOICES,
					tabName: 'Invoices',
					detailName: 'Invoices',
					isActive: false
				});
				tabList.push({
					tabId: 4,
					tabCode: ContactDetailTabDataEnum.AR_CREDIT_MEMO,
					tabName: 'Credit Memos',
					detailName: 'Credit Memos',
					isActive: false
				});
				tabList.push({
					tabId: 5,
					tabCode: ContactDetailTabDataEnum.PAYMENTS,
					tabName: 'Payments',
					detailName: 'Payments',
					isActive: false
				});
				tabList.push({
					tabId: 6,
					tabCode: ContactDetailTabDataEnum.REBATES,
					tabName: 'Rebates',
					detailName: 'Rebates',
					isActive: false
				});
				tabList.push({
					tabId: 7,
					tabCode: ContactDetailTabDataEnum.CHARGEBACKS,
					tabName: 'Chargebacks',
					detailName: 'Chargebacks',
					isActive: false
				});
				tabList.push({
					tabId: 1,
					tabCode: ContactDetailTabDataEnum.TASKS,
					tabName: 'Tasks',
					detailName: 'Task',
					isActive: false
				});
				tabList.push({
					tabId: 9,
					tabCode: ContactDetailTabDataEnum.ROLE,
					tabName: 'Role',
					detailName: 'Role',
					isActive: false
				});
				tabList.push({
					tabId: 5,
					tabCode: ContactDetailTabDataEnum.NOTES,
					tabName: 'Notes',
					detailName: 'Note',
					isActive: false
				});
				tabList.push({
					tabId: 10,
					tabCode: ContactDetailTabDataEnum.CUSTOM_FIELDS,
					tabName: 'Custom Fields',
					detailName: 'Custom Field Details',
					isActive: false
				});
				// tabList.push({
				// 	tabId: 0,
				// 	tabCode: ContactDetailTabDataEnum.TIMELINES,
				// 	tabName: 'Timelines',
				// 	detailName: 'Timeline',
				// 	isActive: false
				// });
				break;
			default:
				break;
		}
		// tabList.push({
		// 	tabId: 3,
		// 	tabCode: ContactDetailTabDataEnum.MAILS,
		// 	tabName: 'Mails',
		// 	detailName: 'Mail',
		// 	isActive: false
		// });
		// tabList.push({
		// 	tabId: 4,
		// 	tabCode: ContactDetailTabDataEnum.DOCUMENTS,
		// 	tabName: 'Documents',
		// 	detailName: 'Documents',
		// 	isActive: false,
		// 	isHidden: false
		// });
		return tabList;
	}

	setSelectedContactDetailTabData(val: any) {
		this.selectedContactDetailTabDetails = val;
	}
	getSelectedContactDetailTabData() {
		return this.selectedContactDetailTabDetails;
	}

	getContactDetailActions(tabCode: any) {
		const contactDetailActionsData: OptionBtns[] = [];
		// contactDetailActionsData.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.PHONECALL,
		// 	btnName: ContactDetailActionBtnsLblEnum.PHONECALL,
		// 	btnImage: IMG_CALL_SE_SVG
		// });
		// contactDetailActionsData.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.SENDEMAIL,
		// 	btnName: ContactDetailActionBtnsLblEnum.SENDEMAIL,
		// 	btnImage: IMG_MAIL_SVG
		// });
		// contactDetailActionsData.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.SITEVISIT,
		// 	btnName: ContactDetailActionBtnsLblEnum.SITEVISIT,
		// 	btnImage: IMG_SITE_VISIT_SVG
		// });
		// contactDetailActionsData.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.APPOINTMENT,
		// 	btnName: ContactDetailActionBtnsLblEnum.APPOINTMENT,
		// 	btnImage: IMG_APPOINTMENT_SE_SVG
		// });
		switch (tabCode) {
			case NameFromServerKeyEnum.LEAD:
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.EDIT,
					btnName: ContactDetailActionBtnsLblEnum.EDIT,
					btnImage: '',
					icon: ' ' + IMG_EDIT_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASPROSPECT,
					btnName: ContactDetailActionBtnsLblEnum.MARKASPROSPECT,
					btnImage: IMG_MARK_AS_PROSPECT_SVG,
					icon: ' ' + IMG_MARK_AS_PROSPECT_SVG,
					visible: true,
				});

				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
					btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
					btnImage: IMG_MARK_AS_CUSTOMER_SVG,
					icon: ' ' + IMG_MARK_AS_CUSTOMER_SVG,
					visible: true,
				});
				/*
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
					btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
					btnImage: IMG_ADD_TASK_SE_SVG,
					icon: ' ' + IMG_ADD_TASK_SE_SVG,
					visible: true,
				});
				*/
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.DELETE,
					btnName: ContactDetailActionBtnsLblEnum.DELETE,
					btnImage: IMG_CLOSE_SVG,
					icon: ' ' + IMG_CLOSE_SVG,
					visible: true,
				});
				// contactDetailActionsData.push({
				// 	btnCode: ContactDetailActionBtnsValEnum.ADD_TO_PIPELINE,
				// 	btnName: ContactDetailActionBtnsLblEnum.ADD_TO_PIPELINE,
				// 	btnImage: IMG_ADD_TO_PIPELINE_SVG
				// });
				break;
			case NameFromServerKeyEnum.PROSPECT:
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.EDIT,
					btnName: ContactDetailActionBtnsLblEnum.EDIT,
					btnImage: '',
					icon: ' ' + IMG_EDIT_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
					btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
					btnImage: IMG_MARK_AS_CUSTOMER_SVG,
					icon: ' ' + IMG_MARK_AS_CUSTOMER_SVG,
					visible: true,
				});
				/*
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
					btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
					btnImage: IMG_ADD_TASK_SE_SVG,
					icon: ' ' + IMG_ADD_TASK_SE_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDDEAL,
					btnName: ContactDetailActionBtnsLblEnum.ADDDEAL,
					btnImage: IMG_DEAL_SE_SVG,
					icon: ' ' + IMG_DEAL_SE_SVG,
					visible: true,
				});
				*/
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.DELETE,
					btnName: ContactDetailActionBtnsLblEnum.DELETE,
					btnImage: IMG_CLOSE_SVG,
					icon: ' ' + IMG_CLOSE_SVG,
					visible: true,
				});
				break;
			case NameFromServerKeyEnum.CLIENT:
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.EDIT,
					btnName: ContactDetailActionBtnsLblEnum.EDIT,
					btnImage: '',
					icon: ' ' + IMG_EDIT_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASBUYINGGROUP,
					btnName: ContactDetailActionBtnsLblEnum.MARKASBUYINGGROUP,
					btnImage: '',
					icon: ' ' + IMG_MARK_AS_BUYING_GROUP_SVG,
					visible: true,
				});
				/*
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
					btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
					btnImage: IMG_ADD_TASK_SE_SVG,
					icon: ' ' + IMG_ADD_TASK_SE_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDDEAL,
					btnName: ContactDetailActionBtnsLblEnum.ADDDEAL,
					btnImage: IMG_DEAL_SE_SVG,
					icon: ' ' + IMG_DEAL_SE_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDORDER,
					btnName: ContactDetailActionBtnsLblEnum.ADDORDER,
					btnImage: IMG_ADD_ORDERS_SVG,
					icon: ' ' + IMG_ADD_ORDERS_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDPAYMENTS,
					btnName: ContactDetailActionBtnsLblEnum.ADDPAYMENTS,
					btnImage: IMG_ADD_PAYMENTS_SVG,
					icon: ' ' + IMG_ADD_PAYMENTS_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDREBATES,
					btnName: ContactDetailActionBtnsLblEnum.ADDREBATES,
					btnImage: IMG_ADD_REBATES_SVG,
					icon: ' ' + IMG_ADD_REBATES_SVG,
					visible: true,
				});
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.ADDCHARGESBACKS,
					btnName: ContactDetailActionBtnsLblEnum.ADDCHARGESBACKS,
					btnImage: IMG_ADD_CHARGEBACKS_SVG,
					icon: ' ' + IMG_ADD_CHARGEBACKS_SVG,
					visible: true,
				});
				// contactDetailActionsData.push({
				// 	btnCode: ContactDetailActionBtnsValEnum.ADDINVOICE,
				// 	btnName: ContactDetailActionBtnsLblEnum.ADDINVOICE,
				// 	btnImage: IMG_CLOSE_SVG
				// });
				*/
				break;
			default:
				break;
		}

		// contactDetailActionsData.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDDOCUMENT,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDDOCUMENT,
		// 	btnImage: IMG_DOC_SVG
		// });
		// contactDetailActionsData.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.EDIT,
		// 	btnName: ContactDetailActionBtnsLblEnum.EDIT,
		// 	btnImage: IMG_EDIT_SVG
		// });
		// contactDetailActionsData.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.DELETE,
		// 	btnName: ContactDetailActionBtnsLblEnum.DELETE,
		// 	btnImage: IMG_CLOSE_SVG
		// });
		return contactDetailActionsData;
	}

	public dataMoveRequest(content: string, from: string, to?: string, action?: string) {
		const contactMove = new ContactMove();
		contactMove.action = 'move';
		if (action) {
			contactMove.action = action;
		}
		contactMove.from = from;
		if (to) {
			contactMove.to = to;
		}
		contactMove.content = [];
		contactMove.content.push(content);

		const contactsReq = [];
		contactsReq.push(contactMove);
		return contactsReq;
	}

	public dataDeleteRequest(code: any, from: string, to?: string, action?: string) {
		const contactMove = new ContactMove();
		contactMove.action = 'delete';
		if (action) {
			contactMove.action = action;
		}
		contactMove.from = from;
		if (to) {
			contactMove.to = to;
		}
		contactMove.ids = [];
		contactMove.ids.push(code);

		const contactsReq = [];
		contactsReq.push(contactMove);
		return contactsReq;
	}

	public dataEditRequest(contentData: any, type: string, action?: string) {
		const contactEdit = new ContactEdit();
		contactEdit.action = 'edit';
		if (action) {
			contactEdit.action = 'add';
		}
		contactEdit.type = type;
		contactEdit.content = [];
		contactEdit.content.push(contentData);

		const contactsReq = [];
		contactsReq.push(contactEdit);
		return contactsReq;
	}

	taskSort(tasks: Tasks[]) {
		const tasksList = _.sortBy(tasks, (taskItem: Tasks) => {
			const dt = new Date(moment(taskItem.enddate + ' ' + taskItem.timeinterval).toDate()).getTime() * -1;
			return dt;
		});
		return tasksList;
	}

	timelineSort(timelines: any[]) {
		const timelinesList = _.sortBy(timelines, (itemTimeline: any) => {
			const dt = new Date(moment(itemTimeline.datetime).toDate()).getTime() * -1;
			return dt;
		});
		return timelinesList;
	}

	getSourcesList() {
		const sources: CommonOptions[] = [];
		for (const itemSource in CRMValSources) {
			if (CRMValSources[itemSource]) {
				const commonOption = new CommonOptions();
				commonOption.id = CRMValSources[itemSource];
				commonOption.name = CRMLblSources[itemSource];
				sources.push(commonOption);
			}
		}
		return sources;
	}

	getCRMStatuses(tabCode: any) {
		const statuses: CommonOptions[] = [];
		let commonOption: CommonOptions = new CommonOptions();
		switch (tabCode) {
			case NameFromServerKeyEnum.LEAD:
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.NEWLEAD;
				commonOption.name = CRMLblStatuses.NEWLEAD;
				statuses.push(commonOption);
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.INITIALCONTACT;
				commonOption.name = CRMLblStatuses.INITIALCONTACT;
				statuses.push(commonOption);
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.FOLLOWUP;
				commonOption.name = CRMLblStatuses.FOLLOWUP;
				statuses.push(commonOption);
				break;
			case NameFromServerKeyEnum.PROSPECT:
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.DEMOSAMPLES;
				commonOption.name = CRMLblStatuses.DEMOSAMPLES;
				statuses.push(commonOption);
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.PROPOSALDEAL;
				commonOption.name = CRMLblStatuses.PROPOSALDEAL;
				statuses.push(commonOption);
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.PRICENEGOTIATION;
				commonOption.name = CRMLblStatuses.PRICENEGOTIATION;
				statuses.push(commonOption);
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.WON;
				commonOption.name = CRMLblStatuses.WON;
				statuses.push(commonOption);
				commonOption = new CommonOptions();
				commonOption.id = CRMValStatuses.LOST;
				commonOption.name = CRMLblStatuses.LOST;
				statuses.push(commonOption);
				break;
		}
		return statuses;
	}

	public generateTimeline(title: string, actiontype: string, actionid?: string, details?: string) {
		const timelinesInsertUpdate = new TimelinesInsertUpdate();
		timelinesInsertUpdate.guid = this.guid.newGuid();
		timelinesInsertUpdate.datetime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		timelinesInsertUpdate.title = title;
		timelinesInsertUpdate.actiontype = actiontype;
		timelinesInsertUpdate.actionid = actionid || undefined;
		timelinesInsertUpdate.details = details || undefined;
		return timelinesInsertUpdate;
	}

	public setCustomerFrom(val: any) {
		this.customerFrom = val;
	}
	public getCustomerFrom() {
		return this.customerFrom;
	}

	public setStoredFilters(val: any) {
		this.storedFilters = val;
	}

	public getStoredFilters() {
		return this.storedFilters || undefined;
	}

	public setDetailTabsStoredFilters(val: any) {
		this.detailTabStoredFilters = val;
	}

	public getDetailTabsStoredFilters() {
		return this.detailTabStoredFilters || undefined;
	}

	public setCrmSelectedtab(val) {
		this.crmSelectedTab = val;
	}

	public getCrmSelectedtab() {
		return this.crmSelectedTab || undefined;
	}

	public setContactDetailsSelectedtab(val) {
		this.contactDetailsSelectedTab = val;
	}

	public getContactDetailsSelectedtab() {
		return this.contactDetailsSelectedTab || undefined;
	}

	public setClientFor(val) {
		this.clientFor = val;
	}

	public getClientFor() {
		return this.clientFor || undefined;
	}

	public setEntityFor(val) {
		this.entityFor = val;
	}

	public getEntityFor() {
		return this.entityFor || undefined;
	}

	public setClientGuid(val: string) {
		this.clientGuid = val;
	}
	public getClientGuid() {
		return this.clientGuid || undefined;
	}

	public setClientDetails(val: any) {
		this.clientDetails = val;
	}
	public getClientDetails() {
		return this.clientDetails || undefined;
	}

	public setContactEmployeeGuid(val: string) {
		this.contactEmployeeGuid = val;
	}
	public getContactEmployeeGuid() {
		return this.contactEmployeeGuid || undefined;
	}

	public getLeadStatuses() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: CRMValStatuses.NEWLEAD,
			btnName: CRMLblStatuses.NEWLEAD,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		actionMenus.push({
			btnCode: CRMValStatuses.INITIALCONTACT,
			btnName: CRMLblStatuses.INITIALCONTACT,
			btnImage: IMG_MARK_AS_PROSPECT_SVG
		});
		actionMenus.push({
			btnCode: CRMValStatuses.FOLLOWUP,
			btnName: CRMLblStatuses.FOLLOWUP,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		actionMenus.push({
			btnCode: CRMValStatuses.CLOSED,
			btnName: CRMLblStatuses.CLOSED,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		return actionMenus;
	}

	public getProspectStatuses() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: CRMValStatuses.DEMOSAMPLES,
			btnName: CRMLblStatuses.DEMOSAMPLES,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		actionMenus.push({
			btnCode: CRMValStatuses.PROPOSALDEAL,
			btnName: CRMLblStatuses.PROPOSALDEAL,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		actionMenus.push({
			btnCode: CRMValStatuses.PRICENEGOTIATION,
			btnName: CRMLblStatuses.PRICENEGOTIATION,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		actionMenus.push({
			btnCode: CRMValStatuses.WON,
			btnName: CRMLblStatuses.WON,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		actionMenus.push({
			btnCode: CRMValStatuses.LOST,
			btnName: CRMLblStatuses.LOST,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG
		});
		return actionMenus;
	}

	public getTaskStatuses() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: TaskStatusValEnum.OPEN,
			btnName: TaskStatusLblEnum.OPEN,
			btnImage: ''
		});
		actionMenus.push({
			btnCode: TaskStatusValEnum.CLOSE,
			btnName: TaskStatusLblEnum.CLOSE,
			btnImage: ''
		});

		return actionMenus;
	}

	public getActionsByLeads() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.EDIT,
			btnName: ContactDetailActionBtnsLblEnum.EDIT,
			btnImage: IMG_EDIT_SVG,
			icon: ' ' + IMG_EDIT_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.MARKASPROSPECT,
			btnName: ContactDetailActionBtnsLblEnum.MARKASPROSPECT,
			btnImage: IMG_MARK_AS_PROSPECT_SVG,
			icon: ' ' + IMG_MARK_AS_PROSPECT_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
			btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG,
			icon: ' ' + IMG_MARK_AS_CUSTOMER_SVG,
			visible: true,
		});
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
		// 	btnImage: IMG_ADD_TASK_SE_SVG,
		// 	icon: ' ' + IMG_ADD_TASK_SE_SVG,
		// 	visible: true,
		// });
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.ADD_TO_PIPELINE,
		// 	btnName: 'Add To Pipeline',
		// 	btnImage: IMG_ADD_TO_PIPELINE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.EDIT,
		// 	btnName: 'Edit',
		// 	btnImage: IMG_EDIT_SVG
		// });
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.DELETE,
			btnName: ContactDetailActionBtnsLblEnum.DELETE,
			btnImage: IMG_CLOSE_SVG,
			icon: ' ' + IMG_CLOSE_SVG,
			visible: true,
		});
		return actionMenus;
	}

	public getActionsByProspects() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.EDIT,
			btnName: ContactDetailActionBtnsLblEnum.EDIT,
			btnImage: IMG_EDIT_SVG,
			icon: ' ' + IMG_EDIT_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
			btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG,
			icon: ' ' + IMG_MARK_AS_CUSTOMER_SVG,
			visible: true,
		});
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
		// 	btnImage: IMG_ADD_TASK_SE_SVG,
		// 	icon: ' ' + IMG_ADD_TASK_SE_SVG,
		// 	visible: true,
		// });
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDDEAL,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDDEAL,
		// 	btnImage: IMG_DEAL_SE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.EDIT,
		// 	btnName: 'Edit',
		// 	btnImage: IMG_EDIT_SVG
		// });
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.DELETE,
			btnName: ContactDetailActionBtnsLblEnum.DELETE,
			btnImage: IMG_CLOSE_SVG,
			icon: ' ' + IMG_CLOSE_SVG,
			visible: true,
		});
		return actionMenus;
	}

	public getActionsByClients() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.EDIT,
			btnName: ContactDetailActionBtnsLblEnum.EDIT,
			btnImage: IMG_EDIT_SVG,
			icon: ' ' + IMG_EDIT_SVG,
			visible: true,
		});
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
		// 	btnImage: IMG_ADD_TASK_SE_SVG,
		// 	icon: ' ' + IMG_ADD_TASK_SE_SVG,
		// 	visible: true,
		// });
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDDEAL,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDDEAL,
		// 	btnImage: IMG_DEAL_SE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDORDER,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDORDER,
		// 	btnImage: IMG_ADD_FILE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.EDIT,
		// 	btnName: 'Edit',
		// 	btnImage: IMG_EDIT_SVG
		// });
		return actionMenus;
	}

	public getActionsByTasks() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
			btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
			btnImage: IMG_ADD_TASK_SE_SVG,
			icon: ' ' + IMG_ADD_TASK_SE_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.EDITTASK,
			btnName: ContactDetailActionBtnsLblEnum.EDITTASK,
			btnImage: IMG_EDIT_SVG,
			icon: ' ' + IMG_EDIT_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.DELETE,
			btnName: ContactDetailActionBtnsLblEnum.DELETE,
			btnImage: IMG_CLOSE_SVG,
			icon: ' ' + IMG_CLOSE_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.OPENTASKSTATUS,
			btnName: ContactDetailActionBtnsLblEnum.OPENTASKSTATUS,
			btnImage: IMG_MARK_AS_OPEN_SVG,
			icon: ' ' + IMG_MARK_AS_OPEN_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.CLOSETASKSTATUS,
			btnName: ContactDetailActionBtnsLblEnum.CLOSETASKSTATUS,
			btnImage: IMG_MARK_AS_CLOSED_SVG,
			icon: ' ' + IMG_MARK_AS_CLOSED_SVG,
			visible: true,
		});
		return actionMenus;
	}

	public getListOfTimeIntervals() {
		let timeIntervals = [];
		timeIntervals = this.setListOfTimeInterval(15);
		return timeIntervals;
	}

	private setListOfTimeInterval(interval?: number) {
		if (!interval) {
			interval = 15;
		}
		const times = []; // time array
		const x = interval; // minutes interval
		let tt = 0; // start time
		const ap = ['AM', 'PM']; // AM-PM

		// loop to increment the time and push results in array
		for (let i = 0; tt < 24 * 60; i++) {
			const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
			const mm = (tt % 60); // getting minutes of the hour in 0-55 format
			const t = (("0" + (hh % 12)).slice(-2)).replace("00", "12") + ':' + ("0" + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
			const timeObj = { id: t, name: t };
			times.push(timeObj); // pushing data in array in [00:00 - 12:00 AM/PM format]
			tt = tt + x;
		}
		const timeIntervals = JSON.parse(JSON.stringify(times));
		timeIntervals.splice(0, 32); // Removed element by specific index 0 to 32
		for (let i = 0; i <= 31; i++) {
			timeIntervals.push(times[i]);
		}
		// Output : 08:00 AM to 07:45:00 PM
		return timeIntervals;
	}

	public getListOfActionsByLeads() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.MARKASPROSPECT,
			btnName: ContactDetailActionBtnsLblEnum.MARKASPROSPECT,
			btnImage: IMG_MARK_AS_PROSPECT_SVG,
			icon: ' ' + IMG_MARK_AS_PROSPECT_SVG,
			visible: true,
			disabled: false
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
			btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG,
			icon: ' ' + IMG_MARK_AS_CUSTOMER_SVG,
			visible: true,
			disabled: false
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
			btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
			btnImage: IMG_ADD_TASK_SE_SVG,
			icon: ' ' + IMG_ADD_TASK_SE_SVG,
			visible: true,
			disabled: false
		});
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.ADD_TO_PIPELINE,
		// 	btnName: 'Add To Pipeline',
		// 	btnImage: IMG_ADD_TO_PIPELINE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.EDIT,
		// 	btnName: 'Edit',
		// 	btnImage: IMG_EDIT_SVG
		// });
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.DELETE,
			btnName: ContactDetailActionBtnsLblEnum.DELETE,
			btnImage: IMG_CLOSE_SVG,
			icon: ' ' + IMG_CLOSE_SVG,
			visible: true,
			disabled: false
		});
		return actionMenus;
	}

	public getListOfActionsByProspects() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
			btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
			btnImage: IMG_MARK_AS_CUSTOMER_SVG,
			icon: ' ' + IMG_MARK_AS_CUSTOMER_SVG,
			visible: true,
			disabled: false,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
			btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
			btnImage: IMG_ADD_TASK_SE_SVG,
			icon: ' ' + IMG_ADD_TASK_SE_SVG,
			visible: true,
			disabled: false,
		});
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDDEAL,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDDEAL,
		// 	btnImage: IMG_DEAL_SE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.EDIT,
		// 	btnName: 'Edit',
		// 	btnImage: IMG_EDIT_SVG
		// });
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.DELETE,
			btnName: ContactDetailActionBtnsLblEnum.DELETE,
			btnImage: IMG_CLOSE_SVG,
			icon: ' ' + IMG_CLOSE_SVG,
			visible: true,
			disabled: false,
		});
		return actionMenus;
	}

	public getListOfActionsByClients() {
		const actionMenus: any[] = [];
		actionMenus.push(
			{
				btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
				btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
				btnImage: IMG_ADD_TASK_SE_SVG,
				icon: ' ' + IMG_ADD_TASK_SE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btnCode: ContactDetailActionBtnsValEnum.MARKASBUYINGGROUP,
				btnName: ContactDetailActionBtnsLblEnum.MARKASBUYINGGROUP,
				btnImage: 'icon-mark_as_buying_group',
				icon: ' ' + 'icon-mark_as_buying_group',
				visible: true,
			}
		);
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDDEAL,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDDEAL,
		// 	btnImage: IMG_DEAL_SE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: ContactDetailActionBtnsValEnum.ADDORDER,
		// 	btnName: ContactDetailActionBtnsLblEnum.ADDORDER,
		// 	btnImage: IMG_ADD_FILE_SVG
		// });
		// actionMenus.push({
		// 	btnCode: CRMActionBtnsEnum.EDIT,
		// 	btnName: 'Edit',
		// 	btnImage: IMG_EDIT_SVG
		// });
		return actionMenus;
	}

	public getListOfActionsByTasks() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
			btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
			btnImage: IMG_ADD_TASK_SE_SVG,
			icon: ' ' + IMG_ADD_TASK_SE_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.EDITTASK,
			btnName: ContactDetailActionBtnsLblEnum.EDITTASK,
			btnImage: IMG_EDIT_SVG,
			icon: ' ' + IMG_EDIT_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.DELETE,
			btnName: ContactDetailActionBtnsLblEnum.DELETE,
			btnImage: IMG_CLOSE_SVG,
			icon: ' ' + IMG_CLOSE_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.OPENTASKSTATUS,
			btnName: ContactDetailActionBtnsLblEnum.OPENTASKSTATUS,
			btnImage: IMG_MARK_AS_OPEN_SVG,
			icon: ' ' + IMG_MARK_AS_OPEN_SVG,
			visible: true,
		});
		actionMenus.push({
			btnCode: ContactDetailActionBtnsValEnum.CLOSETASKSTATUS,
			btnName: ContactDetailActionBtnsLblEnum.CLOSETASKSTATUS,
			btnImage: IMG_MARK_AS_CLOSED_SVG,
			icon: ' ' + IMG_MARK_AS_CLOSED_SVG,
			visible: true,
		});
		return actionMenus;
	}

	public gerListOfActionByContracts() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btn_code: ContactDetailActionBtnsValEnum.ADDCONTACT,
			btn_name: ContactDetailActionBtnsLblEnum.ADDCONTACT,
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		});
		actionMenus.push({
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		});
		actionMenus.push({
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		});

		return actionMenus;
	}

	public gerListOfActionByAddress() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btn_code: ContactDetailActionBtnsValEnum.ADDADDRESS,
			btn_name: ContactDetailActionBtnsLblEnum.ADDADDRESS,
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
		});
		actionMenus.push({
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		});
		actionMenus.push({
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		});
		return actionMenus;
	}
	public gerListOfActionByCustomerRole() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btn_code: ContactDetailActionBtnsValEnum.ADDCUSTOMERROLE,
			btn_name: ContactDetailActionBtnsLblEnum.ADDCUSTOMERROLE,
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
		});
		actionMenus.push({
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		});
		// actionMenus.push({
		// 	btn_code: 'COLUMN_CHOOSER',
		// 	btn_name: 'Column Chooser',
		// 	btn_image: 'column-chooser',
		// 	icon: IMG_ICON_COLUMN_CHOOSER,
		// 	visible: true,
		// 	disabled: false,
		// });
		return actionMenus;
	}

	public setCustomer360TileDetailId(val: any) {
		this.customer360Tile = val;
	}
	public getCustomer360TileDetailId() {
		return this.customer360Tile;
	}

	public setClientDetailPageOpenFrom(value: string) {
		this.clientDetailPageOpenFrom = value;
	}
	public getClientDetailPageOpenFrom() {
		return this.clientDetailPageOpenFrom || '';
	}

	public setClientDetailByKeyValue(val: any) {
		this.clientDetailByKeyValue = val;
	}
	public getClientDetailByKeyValue() {
		return this.clientDetailByKeyValue || undefined;
	}

	public doClearSetterGetter() {
		this.setClientDetailPageOpenFrom('');
		this.setClientDetailByKeyValue('');
	}

	public doClearLocalSavedCustomer360TileDetails(tileType: string) {
		this._AppCommonSrvc = this.injector.get(AppCommonSrvc);
		const customer360DetailsData = JSON.parse(JSON.stringify(this._AppCommonSrvc.getAppState(AppStatesKeyEnum.CUSTOMER_360_DETAILS)));
		if (customer360DetailsData.hasOwnProperty(tileType)) {
			delete customer360DetailsData[tileType];
			this._AppCommonSrvc.setAppState(AppStatesKeyEnum.CUSTOMER_360_DETAILS, customer360DetailsData);
		}
	}

	public getCustomer360ShowDataList() {
		return [
			{ text: Customer360TileSettingsLblEnum.CURRENT_MONTH, value: Customer360TileSettingsValEnum.CURRENT_MONTH },
			{ text: Customer360TileSettingsLblEnum.LAST_MONTH, value: Customer360TileSettingsValEnum.LAST_MONTH },
			{ text: Customer360TileSettingsLblEnum.CUSTOM_RANGE, value: Customer360TileSettingsValEnum.CUSTOM_RANGE }
		];
	}

	public getCustomer360TimePeriodsList() {
		return [
			{
				value: Customer360TileSettingsValEnum.YEAR_TO_DATE,
				text: Customer360TileSettingsLblEnum.YEAR_TO_DATE,
				shortText: Customer360TileSettingsLblEnum.YEAR_TO_DATE_SHORTNAME
			},
			{
				value: Customer360TileSettingsValEnum.YEAR_OVER_YEAR,
				text: Customer360TileSettingsLblEnum.YEAR_OVER_YEAR,
				shortText: Customer360TileSettingsLblEnum.YEAR_OVER_YEAR_SHORTNAME
			},
		];
	}

	public getListOfInvoiceStatusConsider() {
		const listOfStatusConsider = [
			{
				value: Customer360TileSettingsValEnum.STATUS_ALL,
				text: Customer360TileSettingsLblEnum.STATUS_ALL_INVOICE
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_OPEN,
				text: Customer360TileSettingsLblEnum.STATUS_OPEN_INVOICE
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_CLOSED,
				text: Customer360TileSettingsLblEnum.STATUS_CLOSED_INVOICE
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_CANCELLED,
				text: Customer360TileSettingsLblEnum.STATUS_CANCELLED_INVOICE
			}
		];
		return listOfStatusConsider;
	}

	public getlistOfInvoiceDateUses() {
		const listOfDateUses: any[] = [
			{
				value: Customer360TileSettingsValEnum.DATE_DOC_DATE,
				text: Customer360TileSettingsLblEnum.DATE_DOC_DATE
			},
			{
				value: Customer360TileSettingsValEnum.DATE_DUE_DATE,
				text: Customer360TileSettingsLblEnum.DATE_DUE_DATE
			},
			{
				value: Customer360TileSettingsValEnum.DATE_DATE_PAID,
				text: Customer360TileSettingsLblEnum.DATE_DATE_PAID
			}
		]
		return listOfDateUses;
	}

	public getListOfOrderStatusConsider() {
		const listOfStatusConsider = [
			{
				value: Customer360TileSettingsValEnum.STATUS_ALL,
				text: Customer360TileSettingsLblEnum.STATUS_ALL_ORDER
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_OPEN,
				text: Customer360TileSettingsLblEnum.STATUS_OPEN_ORDER
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_CLOSED,
				text: Customer360TileSettingsLblEnum.STATUS_CLOSED_ORDER
			}
		];
		return listOfStatusConsider;
	}

	public getlistOfOrderDateUses() {
		const listOfDateUses: any[] = [
			{
				value: Customer360TileSettingsValEnum.DATE_DOC_DATE,
				text: Customer360TileSettingsLblEnum.DATE_DOC_DATE
			},
			{
				value: Customer360TileSettingsValEnum.DATE_DUE_DATE,
				text: Customer360TileSettingsLblEnum.DATE_DUE_DATE
			}
		]
		return listOfDateUses;
	}

	public getlistOfArCreditmemoDateUses() {
		const listOfDateUses: any[] = [
			{
				value: Customer360TileSettingsValEnum.DATE_DOC_DATE,
				text: Customer360TileSettingsLblEnum.DATE_DOC_DATE
			},
			{
				value: Customer360TileSettingsValEnum.DATE_DUE_DATE,
				text: Customer360TileSettingsLblEnum.DATE_DUE_DATE
			}
		]
		return listOfDateUses;
	}

	public getListOfArCreditmemoStatusConsider() {
		const listOfStatusConsider = [
			{
				value: Customer360TileSettingsValEnum.STATUS_ALL,
				text: Customer360TileSettingsLblEnum.STATUS_ALL_AR_CREDITMEMO
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_DRAFT,
				text: Customer360TileSettingsLblEnum.STATUS_DRAFT_AR_CREDITMEMO
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_OPEN,
				text: Customer360TileSettingsLblEnum.STATUS_OPEN_AR_CREDITMEMO
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_CLOSED,
				text: Customer360TileSettingsLblEnum.STATUS_CLOSED_AR_CREDITMEMO
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_CANCELLED,
				text: Customer360TileSettingsLblEnum.STATUS_CANCELLED_AR_CREDITMEMO
			},
			{
				value: Customer360TileSettingsValEnum.STATUS_CANCELLATION,
				text: Customer360TileSettingsLblEnum.STATUS_CANCELLATION_AR_CREDITMEMO
			},
		];
		return listOfStatusConsider;
	}

	public getListOfArCreditmemoTypeOption() {
		const listOfTypeConsider = [
			{
				value: Customer360TileSettingsValEnum.TYPE_ALL,
				text: Customer360TileSettingsLblEnum.TYPE_ALL
			},
			{
				value: Customer360TileSettingsValEnum.TYPE_SERVICE,
				text: Customer360TileSettingsLblEnum.TYPE_SERVICE
			},
			{
				value: Customer360TileSettingsValEnum.TYPE_INVENTORY,
				text: Customer360TileSettingsLblEnum.TYPE_INVENTORY
			}
		];
		return listOfTypeConsider;
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForApCrm() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
		];
	}
}
