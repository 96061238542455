import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { MinMaxChargebackStatusValEnum, MinMaxChargebackStatusLblEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class MinMaxEndUsersChargebackContractService {

	public tabItemsList: any[] = [
		{ key: 'items', displayVal: 'Items' },
		{ key: 'chargebackdocuments', displayVal: 'Chargeback Documents' }
	];
	public editMinMaxEndUsersChargebackGuid: any;
	public itemTabDataList: any = [];
	public chargebackDocumentTabDataList: any = [];
	public calledClaimedItem: string;
	public modifiedClaimId: string;
	public listOfCharegbackBasis: any[] = [
		{ text: 'End-Users', value: 'endusers' },
		{ text: 'Customers', value: 'customers' },
	];
	public minMaxChargebackClaimApprovalData: any;
	constructor(
		public _LoginService: LoginService,
		public _RestApiService: RestApiService,
		public _ToastrService: ToastrService,
	) { }

	public setEditMinMaxEndUsersChargebackGuid(editMinMaxEndUsersChargebackGuid) {
		this.editMinMaxEndUsersChargebackGuid = editMinMaxEndUsersChargebackGuid;
	}
	public getEditMinMaxEndUsersChargebackGuid() {
		return this.editMinMaxEndUsersChargebackGuid;
	}
	public setItemTabDataList(list) {
		this.itemTabDataList = list;
	}
	public getItemTabDataList() {
		return this.itemTabDataList;
	}
	public setChargebackDocumentTabDataList(list) {
		this.chargebackDocumentTabDataList = list;
	}
	public getChargebackDocumentTabDataList() {
		return this.chargebackDocumentTabDataList;
	}

	//short pay task list
	public getShortPayTaskList(selectCustomerId?: string): Observable<any> {
		let searchParams: any[];
		if (selectCustomerId) {
			searchParams = [['status', '=', 'open'], 'and', ['cardcode', '=', selectCustomerId]];
		} else {
			searchParams = [['status', '=', 'open']];
		}
		let sortParams: any[] = [{ "selector": "created_date", "desc": true }];

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.TASKS);
		formData.append('is_dropdown', 'true');
		formData.append('short_pay_task', 'true');
		formData.append('filter', JSON.stringify(searchParams));
		formData.append('sort', JSON.stringify(sortParams));
		//formData.append('view_fields', JSON.stringify(viewFields));
		return this._RestApiService.doDataOutReqFormData(formData);
	}
	//end

	public getModifiedClaimId() {
		return this.modifiedClaimId;
	}
	public setModifiedClaimId(modifiedClaimId) {
		this.modifiedClaimId = modifiedClaimId;
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add Claim',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public doGetListofClaimApprovalStatus() {
		const listOfStatuses = [
			{
				value: MinMaxChargebackStatusValEnum.DRAFT,
				text: MinMaxChargebackStatusLblEnum.DRAFT,
				label: MinMaxChargebackStatusLblEnum.DRAFT,
				classname: 'defaultClr',
				classnameBg: "orangeBg",
				classnameClrBefore: "orangeClrBefore",
			},
			{
				value: MinMaxChargebackStatusValEnum.PENDING_APPROVAL,
				text: MinMaxChargebackStatusLblEnum.PENDING_APPROVAL,
				label: MinMaxChargebackStatusLblEnum.PENDING_APPROVAL,
				classname: 'orange',
				classnameBg: "orangeBg",
				classnameClrBefore: "orangeClrBefore",
			},
			{
				value: MinMaxChargebackStatusValEnum.APPROVED,
				text: MinMaxChargebackStatusLblEnum.APPROVED,
				label: MinMaxChargebackStatusLblEnum.APPROVED,
				classname: 'green',
				classnameBg: "greenBg",
				classnameClrBefore: "greenClrBefore",
			},
			{
				value: MinMaxChargebackStatusValEnum.REJECTED,
				text: MinMaxChargebackStatusLblEnum.REJECTED,
				label: MinMaxChargebackStatusLblEnum.REJECTED,
				classname: 'red',
				classnameBg: "redBg",
				classnameClrBefore: "redClrBefore",
			}
		];
		return listOfStatuses;
	}

	public setMinMaxChargebackClaimApprovalDetails(approveData) {
		this.minMaxChargebackClaimApprovalData = approveData;
	}
	public getMinMaxChargebackClaimApprovalDetails() {
		return this.minMaxChargebackClaimApprovalData;
	}

}

