import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';

@Component({
	selector: 'app-billback-chargeback-contract-list',
	templateUrl: './billback-chargeback-contract-list.component.html',
	styleUrls: ['./billback-chargeback-contract-list.component.css']
})

export class BillbackChargebackContractListComponent implements OnInit, OnDestroy {

	//#region Local Variable

	public allPermissions: any[] = [];
	public permsn: any = [];
	public billbackChargebackPermission: string = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
	public isColumneVisible: boolean = true;

	//#endregion

	//#region Constructor
	constructor(private _Router: Router,
		private _NgxPermissionsService: NgxPermissionsService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _BillbackContractService: BillbackContractService) {
		// Get current permissions
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
	}
	//#endregion

	//#region Angular Life Cycle Methods

	ngOnInit() {

	}

	ngOnDestroy() {

	}

	//#endregion

}
