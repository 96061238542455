<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width70:'X'" height="80vh"
	[showTitle]="true" [title]="quantityHeaderText" [dragEnabled]="true" [hideOnOutsideClick]="true"
	[showCloseButton]="true" [(visible)]="isShowPopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding popupZIndex' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[elementAttr]="{ class: 'dx-scrollable-content-padR0' }">
			<div class="cols12 padL0 padR0"
				[ngClass]="{'cols6':(listOfSpecificCustomFeatureFields && listOfSpecificCustomFeatureFields.length > 0)}">
				<h4 class="graySubHead textWrap mrgB0" *ngIf="dxItemDescription">Customer :
					<span class="black">{{dxCustomerName}}</span>
				</h4>
				<h4 class="graySubHead textWrap mrgB0" *ngIf="dxItemName">Item :
					<span class="black">{{dxItemName}}</span>
				</h4>
				<h4 class="graySubHead textWrap " *ngIf="dxItemDescription">Description :
					<span class="black mrgR15">{{dxItemDescription}}</span>
				</h4>
			</div>
			<div class="cols6 padL0 padR0 mrgB10">
				<div *ngIf="listOfSpecificCustomFeatureFields && listOfSpecificCustomFeatureFields.length > 0 "
					class="externalFieldBgix">
					<p class="externalFieldTitleix">External Field</p>
					<p *ngFor="let specificField of listOfSpecificCustomFeatureFields;let menuIndex = index;"
						class="externalFieldDescriptionix">
						<span
							class="externalFieldLabelix">{{specificField.custom_label}}:</span>{{specificField.custom_display_value}}
					</p>
				</div>
			</div>

			<div class="cols12 padL0 padR0" *ngIf="dxDataField === 'other_bought'">
				<dx-data-grid #quantityDataGridContainer [dataSource]="listOfAvailableQuantity" [wordWrapEnabled]="true"
					[hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
					[allowColumnResizing]="true" [filterSyncEnabled]="true" [remoteOperations]="false"
					[columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'" class="hideFilterPanelInx"
					(onToolbarPreparing)="doItemsToolbarPreparing($event)" keyExpr="sr_no" height="calc(-200px + 80vh)"
					[elementAttr]="{ class: 'pageNavigationpadB10' }">
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-paging [pageSize]="pageOptions.pageSize" [enabled]="false"></dxo-paging>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="false">
					</dxo-pager>
					<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
					<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadItemTab"
						[customSave]="doSaveItemTab" [storageKey]="stateStorageTabKey">
					</dxo-state-storing>
					<div *dxTemplate="let data of 'templateFilterText'">
						<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
							<span>{{filterTextFor}}</span>
						</div>
					</div>
					<div *dxTemplate="let data of 'templateActionBtns'">
						<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
							[showArrowIcon]="false" text="Action"
							class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
							[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
							icon="spindown" (onButtonClick)="doActionsBtnClickFor($event)"
							(onItemClick)="doActionsBtnItemClickFor($event)"
							[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
					</div>
					<dxi-column dataField="sr_no" [allowHeaderFiltering]="false" caption="Sr. No."
						cellTemplate="cellTemplateSrNo" alignment="center" dataType="string" [visible]="false">
						<div *dxTemplate="let element of 'cellTemplateSrNo'">
							{{element.data.sr_no || ''}}
						</div>
					</dxi-column>

					<dxi-column dataField="type" [allowHeaderFiltering]="false" caption="Doc Type"
						cellTemplate="cellTemplateDocType" dataType="string" [visible]="true">

						<div *dxTemplate="let element of 'cellTemplateDocType'">
							{{element.data.type | filterFromList:listOfDocTypeHeaderFilter : 'value'
							: 'text' }}
						</div>
					</dxi-column>
					<dxi-column dataField="customer" [allowHeaderFiltering]="false" caption="Customer Code"
						cellTemplate="cellTemplateCustomer" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateCustomer'">
							{{element.data.customer || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="customerName" [allowHeaderFiltering]="false" caption="Customer Name"
						cellTemplate="cellTemplateCustomerName" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateCustomerName'">
							{{element.data.customerName || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="docnum" [allowHeaderFiltering]="false" caption="Doc No."
						cellTemplate="cellTemplateDocnum" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateDocnum'">
							<span class="highlight pointerMark">{{element.data.docnum || ''}}</span>
						</div>
					</dxi-column>
					<dxi-column dataField="docdate" [allowHeaderFiltering]="false" caption="Invoice Date"
						cellTemplate="cellTemplateDocdate" dataType="date" format="yyyy-MM-dd">
						<div *dxTemplate="let element of 'cellTemplateDocdate'">
							{{element.data.docdate ||''}}
						</div>
					</dxi-column>
					<dxi-column dataField="quantity" [allowHeaderFiltering]="false" caption="Quantity"
						cellTemplate="cellTemplateQuantity" dataType="number">
						<div *dxTemplate="let element of 'cellTemplateQuantity'">
							{{element.data.quantity || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="price" [allowHeaderFiltering]="false" caption="Price" [visible]="true"
						dataType="number">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplatePrice'">
							{{element.data.price || 0 | customCurrencyPipe}}
						</div>
					</dxi-column>
					<dxo-summary>
						<dxi-total-item column="quantity" alignment="right" summaryType="sum" displayFormat="{0}">
						</dxi-total-item>
						<dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}" [showInGroupFooter]="false"
							[alignByColumn]="true">
						</dxi-group-item>
					</dxo-summary>
				</dx-data-grid>
			</div>

			<div class="cols12 padL0 padR0" *ngIf="dxDataField !== 'other_bought'">
				<dx-data-grid #quantityDataGridContainer [dataSource]="listOfAvailableQuantity" [showBorders]="true"
					[remoteOperations]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
					[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true" [width]="'100%'" class="hideFilterPanelInx"
					keyExpr="sr_no" height="calc(-200px + 80vh)" [elementAttr]="{ class: 'pageNavigationpadB10' }">
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
					<dxo-master-detail [enabled]="true" template="invoiceDetail"></dxo-master-detail>
					<dxi-column dataField="sr_no" [allowHeaderFiltering]="false" caption="Sr. No."
						cellTemplate="cellTemplateSrNo" alignment="center" dataType="string" [visible]="false">
						<div *dxTemplate="let element of 'cellTemplateSrNo'">
							{{element.data.sr_no || ''}}
						</div>
					</dxi-column>

					<dxi-column dataField="quantity" [allowHeaderFiltering]="false" caption="Quantity"
						cellTemplate="cellTemplateQuantity" alignment="left" dataType="number">
						<div *dxTemplate="let element of 'cellTemplateQuantity'">
							{{element.data.quantity || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="price" [allowHeaderFiltering]="false" caption="Price" [visible]="true"
						dataType="number">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplatePrice'">
							{{element.data.price || 0 | customCurrencyPipe}}
						</div>
					</dxi-column>

					<div *dxTemplate="let invDetail of 'invoiceDetail'">
						<dx-data-grid [dataSource]="invDetail.data.invoices" [showBorders]="true" [remoteOperations]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true" [width]="'100%'"
							[elementAttr]="{ class: 'pageNavigationpadB10' }" height="calc(-358px + 80vh)"
							(onCellClick)="doOnInvoiceItemInvoiceCellClick($event)" class="hideFilterPanelInx">
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-filter-panel [visible]="false"></dxo-filter-panel>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxi-column dataField="type" [allowHeaderFiltering]="false" caption="Doc Type"
								cellTemplate="cellTemplateDocType" dataType="string" [visible]="true">

								<div *dxTemplate="let element of 'cellTemplateDocType'">
									{{element.data.type | filterFromList:listOfDocTypeHeaderFilter : 'value'
									: 'text' }}
								</div>
							</dxi-column>
							<dxi-column dataField="customer" [allowHeaderFiltering]="false" caption="Customer Code"
								cellTemplate="cellTemplateCustomer" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateCustomer'">
									{{element.data.customer || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="customerName" [allowHeaderFiltering]="false" caption="Customer Name"
								cellTemplate="cellTemplateCustomerName" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateCustomerName'">
									{{element.data.customerName || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="docnum" [allowHeaderFiltering]="false" caption="Doc No."
								cellTemplate="cellTemplateDocnum" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateDocnum'">
									<span class="highlight pointerMark">{{element.data.docnum || ''}}</span>
								</div>
							</dxi-column>
							<dxi-column dataField="docdate" [allowHeaderFiltering]="false" caption="Invoice Date"
								cellTemplate="cellTemplateDocdate" dataType="date" format="yyyy-MM-dd">
								<div *dxTemplate="let element of 'cellTemplateDocdate'">
									{{element.data.docdate}}
								</div>
							</dxi-column>
							<dxi-column dataField="quantity" [allowHeaderFiltering]="false" caption="Quantity"
								cellTemplate="cellTemplateQuantity" alignment="left" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateQuantity'">
									{{element.data.quantity || ''}}
								</div>
							</dxi-column>
						</dx-data-grid>
					</div>
				</dx-data-grid>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>
<!-- <app-view-invoice-detail-popup *ngIf="isShowInvoiceDetailPopUp" [viewInvoiceDetailProps]="viewInvoiceDetailProps">
</app-view-invoice-detail-popup>  -->
<app-view-end-users-invoice-detail-popup *ngIf="isShowEndusersInvoiceDetailPopUp"
	[viewEndusersInvoiceDetailProps]="viewEndusersInvoiceDetailProps"></app-view-end-users-invoice-detail-popup>
<app-view-craditmemo-detail-popup *ngIf="isShowCreditMemoDetailPopUp"
	[viewCreditMemoDetailProps]="viewCreditMemoDetailProps"></app-view-craditmemo-detail-popup>