<div class="posRelative">
	<h2 class="mrgT10">Approval Trail</h2>
	<dx-button icon="refresh" class="alignRight borderradiusnone" id="button" text="Refresh" type="normal"
		stylingMode="contained" (onClick)="doRefreshMessageTrall()">
	</dx-button>
	<hr />
	<div *ngIf="messageTrallList.length > 0">
		<dx-list class="approvaltimelinelistix">
			<dxi-item class="approvaltimelineitemix" *ngFor="let item of messageTrallList">
				<div class="approvaltimelineinnerboxix">
					<div class="approvaltimelinecalandtxt">
						<div class="approvaltimelinecal">
							<span class="calendarBx mrgT0">
								<span class="cDate">{{ item.comment_date ?
									getDateOperation(item.comment_date,'DATE') : ''}}</span>
								<span class="cMonth">
									{{ item.comment_date ?
									getDateOperation(item.comment_date,'MONTHNAME') : ''}}-{{ item.comment_date ?
									getDateOperation(item.comment_date,'YEAR') : ''}}</span>

							</span>
						</div>
						<div class="approvaltimelinetxt">
							<h3>{{item.username}}</h3>
							<h4 *ngIf="item.source_claim_no">Source Claim No.<a><span class="highlight"
										(click)="doClickViewClaim(item)">{{item.source_claim_no}}</span></a></h4>
							<span class="tlTime">
								<span class="clockIcon"></span>
								{{ item.comment_date ? getDateOperation(item.created_date,'TIME') : ''}}
							</span>
						</div>
					</div>
					<p [ngClass]="{generatedmessageix:item.comment_from === 'system'}">{{item.comment}}</p>
				</div>
			</dxi-item>
		</dx-list>
	</div>
	<div class="approvaltimelinelistix" *ngIf="messageTrallList.length === 0">
		<div class="nocommentavailableix">
			<img [src]="_AppCommonSrvc.cdnSvgPath + 'nocommentavailable.svg'" class="nocommentavailableimgix"
				alt="No Comment Available">
			<p class="nocommentavailabletextix">No Comment Available</p>
		</div>
	</div>
	<app-ix-load-panel *ngIf="isVisibleLoader"></app-ix-load-panel>
	<div class="approvaltimelinetextareaix">
		<form class="inputDisable"
			[ngClass]="{inputDisable: claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'}">
			<textarea name="w3review" rows="3" placeholder="Enter Comment" [(ngModel)]="txtComment"
				[disabled]="claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'"></textarea>
			<dx-button icon="sendix" class="secondaryBtn approvaltimelinetextareaixbtn" id="button" text="Send"
				[ngClass]="{grayButton: claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'}"
				type="normal" stylingMode="contained" (onClick)="doAddMessageTrall()"
				[disabled]="claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'">
			</dx-button>
		</form>
	</div>
</div>
<app-eucc-view-chargeback-documents-dx-popup
	[loadViewChargebackDocDxDialogSubject]="loadViewChargebackDocDxDialogSubject"></app-eucc-view-chargeback-documents-dx-popup>