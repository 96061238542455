import { environment } from '@environments/environment';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_XLSXFILEPOPUPIX, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { CommissionContractModelUI, ExclusionTabModel, ExclusionTierbaseTabModel } from '@app/models/commission-contract.model';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { DataService } from '@app/services/data.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Guid } from '@app/models/guid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyOptions } from '@app/models/common.model';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';

@Component({
	selector: 'app-commission-tierbase-growth-incentive-tab',
	templateUrl: './commission-tierbase-growth-incentive-tab.component.html',
	styleUrls: ['./commission-tierbase-growth-incentive-tab.component.css'],
	providers: [
		CustomCurrencyPipe,
		CustomNumberNoRoundingPipe
	]
})
export class CommissionTierbaseGrowthIncentiveTabComponent implements OnInit, OnDestroy, OnChanges {
	public guid = new Guid();
	@Input() growthIncentiveTabList: any = [];
	@Input() comparison_year?: string;
	@Input() commissionDetailsProps: any;
	@Output() saveCommissionGrowthIncentiveEvent = new EventEmitter<string>();
	@ViewChild("customerDropDown", {}) customerDropDown: DxDropDownBoxComponent;
	@ViewChild("groupDropDown", {}) groupDropDown: DxDropDownBoxComponent;
	@ViewChild("userListDetailssDataGridRef", {}) userListDetailssDataGridRef: DxDataGridComponent;
	@ViewChild("exclusionTabDataGridRef", {}) exclusionTabDataGridRef: DxDataGridComponent;
	@ViewChild("tiersItemDetailssDataGridRef", {}) tiersItemDetailssDataGridRef: DxDataGridComponent;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'Inactive' }];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public productGroupRadioButton: string;
	public erp_type: string;
	public editCommissionGuid: string;
	public getUomLstSbsn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public searchTimeout: any;
	public isCurrentTabPermission: string;
	public isDisablePermission: boolean = false;
	public exclusionsGroupRadioButton: string;
	public GroupsList: any = [];
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public selectedCustomerData: any = [];
	public selectedCustomerGroupData: any = [];
	public selectedProductData: any = [];
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;
	public isAllProductSelectedView: boolean = false;
	public exclusionTabModel = new ExclusionTabModel();
	public dataSourceExclusionTab: any;
	public listOfCommissionBasis: any[] = [];
	public listOfTierBasis: any[] = [];
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public customerOrGroupTab: string = 'customers';
	// public strSelectedItemList: string;
	// public strSelectedCustomerList: string;
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("customerGroupDataGrid", {}) customerGroupDataGrid: DxDataGridComponent;
	@ViewChild("customercustomerGroupDataGrid", {}) customercustomerGroupDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	public chkAllCustomer: boolean = false;
	public chkAllCustomrGroup: boolean = false;
	public chkAllItem: boolean = false;
	public chkMyCustomers: boolean = false;
	public lengthSelectedCustomersAndGroups: any;
	public lengthSelectedCustomers: any;
	public lengthSelectedGroups: any;
	public lengthSelectedItems: any;
	public arrSelectedCustomerList: any = [];
	public arrSelectedItemList: any = [];
	public itemDataSource: any;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public itemGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public itemGridBoxColumns: any;
	public groupDataSource: any;
	public customerGroupGridBoxValue: any[] = [];
	public customerGroupGridBoxColumns: any;
	public arrSelectedCustomerGroupList: any = [];
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	public decimalPointPercentageFormat: string = '1.0-0';
	public globalCurrencySymbol: string;
	public decimalPointForCurrency: number;
	public commissionDetails: any = new CommissionContractModelUI();
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public listOfActions: any[] = [
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		},
	];
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	public updateUserSettingSbsn: Subscription;
	constructor(private _PermissionsService: NgxPermissionsService,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _CommissionService: CommissionService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe) {
		this.doDisplayExprCustomerGroupText = this.doDisplayExprCustomerGroupText.bind(this);
		this.doDisplayExprItemText = this.doDisplayExprItemText.bind(this);
		this.doReorderRows = this.doReorderRows.bind(this);
		// this.doLoadCommExclusionsAgreeState = this.doLoadCommExclusionsAgreeState.bind(this);
		// this.doSaveCommExclusionsAgreeState = this.doSaveCommExclusionsAgreeState.bind(this);
	}

	ngOnInit() {
		this.mainSectionStorageKey = ServerSections.INCENTIVES_COMMISSION_CONTRACT;
		this.stateStorageKey = ServerSections.INCENTIVES_COMMISSION_CONTRACT_GROWTH_INCENTIVE;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		// get current user Erp type
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		this.customerGridBoxColumns = [
			{ dataField: 'code', caption: 'Code' },
			{ dataField: 'name', caption: 'Name' }
		];
		this.itemGridBoxColumns = [
			{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
			{ dataField: 'itemname', caption: 'Item', width: 100 },
			{ dataField: 'description', caption: 'Description' }
		];
		// check Is QB User (no need to view code of QB user)
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
		// this.customerGroupGridBoxColumns = [
		// 	{ dataField: 'guid', caption: 'Guid', visible: false },
		// 	{ dataField: 'group_name', caption: 'Name' },
		// 	{ dataField: 'user_list', caption: 'List', groupIndex: "0" },
		// ];
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
		// Check Edit Mode
		const editCommissionGuid = this._CommissionService.getEditCommissionGuid();

		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}
		if (permsn.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_COMMISSION_MODIFY';
		}
		// End current permissions
		this.editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		this.GroupsList = this._DataService.getGroups();
		this.clearExclusionAgreementTabDetails();
		this.listOfTierBasis = this._CommissionService.getTierBasisForGrowth();
		this.listOfCommissionBasis = this._CommissionService.getCommissionBasis();
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadCustomerDataList();
		this.loadCustomerGroupDataList();
		this.loadItemDataList();
		setTimeout(() => {
			if (this.exclusionTabDataGridRef) {
				this.filterValueChangeSbsn = this.exclusionTabDataGridRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterText();
				});
			}
		}, 120);
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['commissionDetailsProps']) {
			const commissionDetailsProps = _SimpleChanges['commissionDetailsProps'].currentValue;
			this.commissionDetails = commissionDetailsProps;
		}
		if (_SimpleChanges['growthIncentiveTabList']) {
			const growthIncentiveTabListProps = _SimpleChanges['growthIncentiveTabList'].currentValue;
			this.dataSourceExclusionTab = growthIncentiveTabListProps || [];
		}
	}

	ngOnDestroy(): void {
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}
	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// #endregion

	//#region for Customer select box
	loadCustomerDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS
		}
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "name",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams

		});
	}

	public doDisplayExprCustomerGroupText(event: any) {
		let customerGroupTxt = '';
		let listOfDropdownTexts = [];
		if (this.customerOrGroupTab == 'customers') {
			if (this.chkMyCustomers) {
				listOfDropdownTexts.push('My Customer');
			}
			if (this.chkAllCustomer) {
				listOfDropdownTexts.push('All Customer');
			}
			if (this.arrSelectedCustomerList.length > 0) {
				listOfDropdownTexts.push(this.arrSelectedCustomerList.length + ' Customer(s)');
			}
		}
		if (this.customerOrGroupTab === 'groups') {
			listOfDropdownTexts = [];
			if (this.chkAllCustomrGroup) {
				listOfDropdownTexts.push('All Groups');
			}
			if (this.arrSelectedCustomerGroupList.length) {
				listOfDropdownTexts.push(this.arrSelectedCustomerGroupList.length + ' Group(s)');
			}
		}
		customerGroupTxt = listOfDropdownTexts.length > 0 ? listOfDropdownTexts.join(', ') : '';
		return customerGroupTxt;
	}

	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.customerGridBoxValue = [];
			// this.customerDataGrid.instance.refresh();
			this.chkAllCustomer = false;
		}
	}
	checkAllCustomer() {
		if (this.chkAllCustomer) {
			this.chkMyCustomers = false;
		}
		this.setCustomersAndGroupsText();
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }
	}
	onCustomerSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedCustomers = e.selectedRowsData.length + ' Customer(s) Selected';
			this.customerGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomer) {
			this.lengthSelectedCustomers = '';
		}
		this.setCustomersAndGroupsText();
	}

	//#endregion

	//#region for Item|Product select box
	loadItemDataList() {
		// const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=items";
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.itemDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "itemname",
			loadUrl: formdata
		});
	}
	onItemValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedItemList = [];
			this.itemGridBoxValue = [];
			this.itemDataGrid.instance.refresh();
			this.chkAllItem = false;
		}
	}

	public doDisplayExprItemText(event: any) {
		let itemTxt = '';
		let listOfDropdownTexts = [];
		itemTxt = '';
		if (this.chkAllItem) {
			listOfDropdownTexts.push('All Items');
		}
		if (this.arrSelectedItemList.length > 0) {
			listOfDropdownTexts.push(this.arrSelectedItemList.length + ' Item(s)');
		}

		itemTxt = listOfDropdownTexts.length > 0 ? listOfDropdownTexts.join(', ') : '';
		return itemTxt;
	}

	checkAllItem() {
		// if (this.chkAllItem) {
		// 	this.lengthSelectedItems = 'All Items Selected';
		// }
		// 	this.arrSelectedItemList = [];
		// 	this.itemDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedItems = '';
		// }
		this.setItemText();
	}
	onItemSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllItem = false;
			//this.lengthSelectedItems = e.selectedRowsData.length + ' Items Selected';
			this.setItemText();
			this.itemGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].erp_primary_key);
			};
		}
		// if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllItem) {
		// 	this.lengthSelectedItems = '';
		// }
		this.setItemSelctedText();
	}
	setItemSelctedText() {
		let lengthSelectedItems = '';
		this.lengthSelectedItems = '';
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			lengthSelectedItems = this.arrSelectedItemList.length + ' Item(s) Selected';
		}

		if (this.chkAllItem) {
			lengthSelectedItems = 'All Item ' + (lengthSelectedItems ? ', ' : '') + (lengthSelectedItems || '');
		}
		this.lengthSelectedItems = lengthSelectedItems;
	}
	//#endregion

	//#region customer Groups select box

	public clickCustomersOrGroupTab(event, customerOrGroupTab: string) {
		this.customerOrGroupTab = customerOrGroupTab;
		if (this.customerDataGrid) {
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}
		// if (customerOrGroupTab === 'groups') {
		// 	this.groupDropDown.instance.open();
		// 	this.customerDropDown.instance.close();
		// } else {
		// 	this.customerDropDown.instance.open();
		// 	this.groupDropDown.instance.close();
		// }
	}

	public checkMyCustomers() {
		if (this.chkMyCustomers) {
			this.chkAllCustomer = false;
		}
		this.setCustomersAndGroupsText();
	}

	loadCustomerGroupDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=usergroups";
		// formdata = formdata + "&is_dropdown=true";	
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.groupDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "group_name",
			loadUrl: formdata
		});
	}

	checkAllCustomerGroup() {
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }

	}

	onCustomerGroupSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedGroups = e.selectedRowsData.length + ' Group(s) Selected';
			this.customerGroupGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGroupGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomrGroup) {
			this.lengthSelectedGroups = '';
		}
		this.setCustomersAndGroupsText();
	}

	setCustomersAndGroupsText() {
		let lengthSelectedCustomersAndGroups;
		if (this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers;
		}
		if (this.lengthSelectedGroups) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedGroups;
		}
		if (this.lengthSelectedGroups && this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers + ' And ' + this.lengthSelectedGroups;
		}

		if (this.chkAllCustomer) {
			lengthSelectedCustomersAndGroups = 'All Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkMyCustomers) {
			lengthSelectedCustomersAndGroups = 'My Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkAllCustomrGroup) {
			lengthSelectedCustomersAndGroups = 'All Group ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}
		this.lengthSelectedCustomersAndGroups = lengthSelectedCustomersAndGroups;
	}

	setItemText() {
		this.lengthSelectedItems = '';
		const lengthSelectedItems = this.itemDataGrid.instance.getSelectedRowsData().length;
		let txtItemSelection = '';
		if (this.chkAllItem) {
			txtItemSelection = "All Item"
		}
		if (this.chkAllItem && lengthSelectedItems > 0) {
			txtItemSelection = " And All Item";
		}
		this.lengthSelectedItems = lengthSelectedItems + ' Items Selected' + txtItemSelection;
	}


	checkAllGroup() {
		this.setCustomersAndGroupsText();
	}

	// #region for Items And Groups Datagrid Methods
	public getUserListByGroupDetails(element) {
		return element.data.user_detail_list || [];
	}
	//#endregion

	public clickExclusionsGroupRadioButton(tab) {
		this.selectProductSearchTabData = [];
		this.isProductSearchTabAllSelected = false;
		this.exclusionTabModel.productname = '';
		if (tab === 'addgroupexclusion') {
			this.selectProductSearchTabData = JSON.parse(JSON.stringify(this.GroupsList));
		}
		this.selectProductSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	public clearExclusionAgreementTabDetails() {
		this.exclusionsGroupRadioButton = 'addproductexclusion';
		this.arrSelectedCustomerList = [];
		this.arrSelectedCustomerGroupList = [];
		this.lengthSelectedCustomersAndGroups = '';
		this.lengthSelectedCustomers = '';
		this.lengthSelectedGroups = '';
		this.lengthSelectedItems = '';
		this.customerGridBoxValue = [];
		this.chkAllCustomer = false;
		if (this.customerDataGrid) {
			this.customerDataGrid.instance.refresh();
			this.customerDataGrid.instance.clearFilter();
		}
		if (this.customerGroupDataGrid) {
			this.customerGroupDataGrid.instance.refresh();
			this.customerGroupDataGrid.instance.clearFilter();
		}
		if (this.customerDropDown) {
			this.customerDropDown.instance.reset();
		}
		this.arrSelectedItemList = [];
		this.itemGridBoxValue = [];
		this.chkAllItem = false;
		if (this.itemDataGrid) {
			this.itemDataGrid.instance.refresh();
			this.itemDataGrid.instance.clearFilter();
		}
		this.chkAllCustomrGroup = false;
		this.chkMyCustomers = false;
	}

	public doReorderRows = (e: DxDataGridTypes.RowDraggingReorderEvent) => {
		e.promise = this.processReorder(e);
	};

	async processReorder(e) {
		const visibleRows = e.component.getVisibleRows();
		const fromIndex = this.dataSourceExclusionTab.findIndex((item) => item.rownum === e.itemData.rownum);
		const toIndex = this.dataSourceExclusionTab.findIndex((item) => item.rownum === visibleRows[e.toIndex].data.rownum);
		this.dataSourceExclusionTab.splice(fromIndex, 1);
		this.dataSourceExclusionTab.splice(toIndex, 0, e.itemData);
		await this.doSequenceUpdate(e);
		//await e.component.refresh();
	}

	public doSequenceUpdate(event: any) {
		this.dataSourceExclusionTab.forEach((i, index) => {
			i.rownum = index + 1;
		});
		this.doCallSaveCommissionContract();
	}

	public doOnEditorPreparingExclusionTab(event) {
		// if (event && event['row'] && event['row']['data']) {
		// 	return true;
		// } else {
		// 	return false;
		// }
		if (event && event['row']) {
			const itemObject = event.row.data || [];
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (itemObject.commission_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = itemObject.uomlist;
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			if (event.dataField === 'tier_basis') {
				let listOfTierBasis = this.listOfTierBasis;
				event.editorOptions.dataSource = listOfTierBasis;
			}

			if (event.dataField === 'commission_basis') {
				let listOfCommissionBasis = this.listOfCommissionBasis;
				if (itemObject.allproducts) {
					listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}
				event.editorOptions.dataSource = listOfCommissionBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.disabled = true;
				if (itemObject.allproducts) {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.disabled = false;
					}
				} else {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.disabled = false;
					}
				}
			}
		}
	}

	public doOnRowUpdatedExclusionTab(event) {
		if (event.data.commission_basis === 'percentage' || event.data.commission_basis === 'dollar') {
			event.data.selectedUom = '';
		}
		if (event.data.tier_basis === 'notiers') {
			event.data.tiers = [];
		}
		if (event.data.tier_basis !== 'notiers') {
			event.data.rate = null;
		}
	}

	private doCallSaveCommissionContract() {
		setTimeout(() => {
			this.saveCommissionGrowthIncentiveEvent.emit('save');
		}, 350);
	}

	public doOnRowRemovedExclusionTab(event) {
		// console.log('doOnRowRemovedExclusionTab', event);
		this.doCallSaveCommissionContract();
	}

	public doOnonRowPreparedExclusionTab(event) {
		if (event.rowType === "data" && event.data.tier_basis === 'notiers') {
			if (event.rowElement.querySelector(".dx-command-expand")) {
				event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
				event.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
				const isExpanded = event.component.isRowExpanded(event.key);
				setTimeout(() => {
					if (isExpanded) {
						event.component.collapseRow(event.key);
					}
				}, 20);
			}
		}
	}

	public doOnInitNewRowTiersItmTab(event, element) {
		event.data = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null
		};
		const itemObject = element.data;
		event.data.guid = this.guid.newGuid();
		if (itemObject.tiers && itemObject.tiers.length > 0) {
			// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ? parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1 : parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
			if (itemObject.tier_basis === 'volume' || itemObject.tier_basis === 'revenue') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = mintier;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = mintier;
				}
			}
			// event.data.maxtier = '-';
		} else {
			if (itemObject.tier_basis === 'volume') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = 1;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = 0.01;
				}
			}
		}
	}

	public doOnRowInsertedTiersItmTab(event, element) {
		// console.log('doOnRowInsertedTiersItmTab', event);
		// console.log('doOnRowInsertedTiersItmTab element', element);
		// const itemObject = event.row.data;
		// console.log('itemObject', itemObject);
		this.doCallSaveCommissionContract();
	}

	public doOnEditorPreparingTiersItmTab(event, element) {
		// console.log('doOnEditorPreparingTiersItmTab', event);
		// console.log('doOnEditorPreparingTiersItmTab element', element);
	}

	public doOnRowUpdatedTiersItmTab(event, element) {
		// console.log('doOnRowUpdatedTiersItmTab', event);
		// console.log('doOnRowUpdatedTiersItmTab element', element);
	}

	public doOnRowRemovedTiersItmTab(event, element) {
		this.doCallSaveCommissionContract();
	}

	public doOnSavedTiersItmTab(event, element) {
		// console.log('doOnSavedTiersItmTab', event);
		// console.log('doOnSavedTiersItmTab element', element);
		this.doConvertDecimalPointByConfiguration();
		this.doCallSaveCommissionContract();
	}

	public doOnSavedExclusionTab() {
		this.doConvertDecimalPointByConfiguration();
		this.doCallSaveCommissionContract();
	}

	public getTiersByItemDetails(element) {
		return element.data.tiers || [];
	}

	public setCellValueTierBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueCommissionBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueUomColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueRateColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public addExclusionAgreementTabDetails() {
		this.selectedProductData = [];
		this.selectedProductData = this.itemDataGrid.instance.getSelectedRowsData();
		if (this.customerDataGrid) {
			this.selectedCustomerData = [];
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = [];
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}

		// const isExistItemIndex = this.growthIncentiveTabList.findIndex(itemEle => itemEle.clientcode === this.exclusionTabModel.clientcode && itemEle.itemcode === this.exclusionTabModel.clientcode);
		if (this.chkAllItem) {
			this.allProductChecked();
		}
		if (this.chkAllCustomer || this.chkMyCustomers) {
			this.allCustomerChecked();
		}
		if (this.chkAllCustomrGroup) {
			this.allCustomerGroupChecked();
		}

		this.doAddExclusionToListTab();
	}

	public allCustomerGroupChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['group_name'] = 'All Groups';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.erp_primary_key = 'All Groups';
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomergroups = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerGroupData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allCustomerChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textCustomerGroup = this.chkAllCustomer ? 'All Customers' : 'My Customer(s)'
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textCustomerGroup;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.erp_primary_key = textCustomerGroup;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allProductChecked() {
		let itemname = '';
		if (this.exclusionsGroupRadioButton === 'addproductexclusion') {
			itemname = 'All Items';
		}
		if (this.exclusionsGroupRadioButton === 'addgroupexclusion') {
			itemname = 'All Groups';
		}
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = '';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.erp_primary_key = itemname;
		exelusionTierbaseModel.itemname = itemname;
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allproducts = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;

		this.selectedProductData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public checkExclusionListDuplicateRecords(item: any) {
		if (this.growthIncentiveTabList && this.growthIncentiveTabList.length > 0) {
			const indexExcusion = this.growthIncentiveTabList.findIndex(i => i.clientname === item.clientname && i.itemname === item.itemname);
			if (indexExcusion !== -1) {
				return false;
			}
		}
		return true;
	}

	public doAddExclusionToListTab() {
		if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
			if (this.commissionDetails.tiers_type === 'multiple_items') {
				this.selectedCustomerData.forEach(customer => {
					this.selectedProductData.forEach(element => {
						let listOfSpecificUoms = [];
						if (!element.allproducts) {
							const priceListNum = customer.priceListNum || '';
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						// exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = customer.guid;
						exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
						exelusionTierbaseModel.clientname = customer.name;
						exelusionTierbaseModel.allcustomers = customer.allcustomers;
						exelusionTierbaseModel.itemsku = element.itemsku;
						exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.allproducts = element.allproducts;
						exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
						exelusionTierbaseModel.mycustomers = element.mycustomers;
						exelusionTierbaseModel.description = element.description;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						exelusionTierbaseModel.tiers = [];
						//check duplicate validation for exlusion tab list
						if (!this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							return false;
						}
						this.growthIncentiveTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				});
			} else {

				this.selectedCustomerData.forEach(customer => {
					this.selectedProductData.forEach(element => {
						let listOfSpecificUoms = [];
						if (!element.allproducts) {
							const priceListNum = customer.priceListNum || '';
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						// exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = customer.guid;
						exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
						exelusionTierbaseModel.clientname = customer.name;
						exelusionTierbaseModel.allcustomers = customer.allcustomers;
						exelusionTierbaseModel.itemsku = element.itemsku;
						exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.allproducts = element.allproducts;
						exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
						exelusionTierbaseModel.mycustomers = element.mycustomers;
						exelusionTierbaseModel.description = element.description;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						exelusionTierbaseModel.tiers = [];
						//check duplicate validation for exlusion tab list
						if (!this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							return false;
						}
						this.growthIncentiveTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				});
			}
		}

		if (this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0 && this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0) {
			this.selectedCustomerGroupData.forEach(group => {
				this.selectedProductData.forEach(element => {
					let listOfSpecificUoms = [];
					if (!element.allproducts) {
						listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element);
					}
					const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
					const defaultTierBasis = this.listOfTierBasis[0].code;
					const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
					// exelusionTierbaseModel.guid = group.guid + ' - ' + element.erp_primary_key || '';
					exelusionTierbaseModel.guid = this.guid.newGuid();
					exelusionTierbaseModel.clientguid = group.guid;
					exelusionTierbaseModel.clientcode = '';
					exelusionTierbaseModel.clientname = group.group_name;
					exelusionTierbaseModel.allcustomergroups = group.allcustomergroups;
					exelusionTierbaseModel.allcustomers = element.allcustomers;
					exelusionTierbaseModel.itemsku = element.itemsku;
					exelusionTierbaseModel.itemOrGroup = itemOrGroup;
					exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
					exelusionTierbaseModel.itemname = element.itemname;
					exelusionTierbaseModel.allproducts = element.allproducts;
					exelusionTierbaseModel.mycustomers = element.mycustomers;
					exelusionTierbaseModel.description = element.description;
					exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
					exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
					exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
					exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
					exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
					exelusionTierbaseModel.rate = null;
					exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
					exelusionTierbaseModel.tiers = [];
					//check duplicate validation for exlusion tab list
					if (!this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
						return false;
					}
					this.growthIncentiveTabList.unshift(exelusionTierbaseModel);
					// Latest exlusions save on edit commission contract
				});
			})
		}

		this.growthIncentiveTabList.forEach((i, index) => {
			i.rownum = index + 1;
		});
		setTimeout(() => {
			this.dataSourceExclusionTab = this.growthIncentiveTabList;
		}, 50);
		if (this.editCommissionGuid) {
			// this.saveCommissionContract(true);
		}
		this.clearExclusionAgreementTabDetails();
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		// const toolbarItems = e.toolbarOptions.items;
		// toolbarItems.unshift(
		// 	{
		// 		location: 'after',
		// 		template: 'templateFilterText',
		// 	},
		// 	{
		// 		location: 'after',
		// 		widget: 'dxButton',
		// 		options: {
		// 			icon: "cleariconix",
		// 			disabled: this.isDisabledClearBtn,
		// 			text: "Clear",
		// 			onInitialized: (args: any) => {
		// 				this.instanceClearBtn = args.component;
		// 			},
		// 			onClick: () => {
		// 				this.exclusionTabDataGridRef.instance.clearFilter();
		// 			}
		// 		}
		// 	},
		// 	{
		// 		location: 'after',
		// 		widget: 'dxButton',
		// 		options: {
		// 			icon: 'filter',
		// 			text: "Filter",
		// 			onClick: () => {
		// 				this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
		// 			}
		// 		}
		// 	},
		// 	{
		// 		location: 'after',
		// 		template: 'templateActionBtns',
		// 		visible: true,
		// 	},
		// );
		// let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		// if (columnChooserButton) {
		// 	columnChooserButton.options.text = "Column Chooser";
		// 	columnChooserButton.showText = "always";
		// 	columnChooserButton.visible = false;
		// 	columnChooserButton.icon = 'columnChooser';
		// }
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.exclusionTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Commission_Customers_Items_Groups.xlsx';
		worksheet = workbook.addWorksheet('Customers-Items-Groups');

		exportDataGrid({
			worksheet: worksheet,
			component: this.exclusionTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'rate') {
						excelCell.value = '';
						if (gridCell.value) {
							if (gridCell.data && gridCell.data.commission_basis) {
								if (gridCell.data.commission_basis === 'dollar') {
									excelCell.value = this._CommissionService.convertCurrency(gridCell.value || 0, this.customCurrencyOption);
								}
								if (gridCell.data.commission_basis === 'percentage') {
									excelCell.value = +(this._CustomNumberNoRoundingPipe.transform(gridCell.value || 0)) + '%'
								}
							}
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Display Commission basis Name in uppercase
	public getDisplayExprCommissionbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Tier basis Name in uppercase
	public getDisplayExprTierbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	private doConvertDecimalPointByConfiguration() {
		if (this.growthIncentiveTabList && this.growthIncentiveTabList.length > 0) {
			this.growthIncentiveTabList.forEach(grList => {
				if (grList.commission_basis === 'percentage') {
					if (grList.tiers && grList.tiers.length > 0) {
						grList.tiers.forEach(t => {
							if (t.rate !== null) {
								t.rate = +(this._CustomNumberNoRoundingPipe.transform(t.rate));
							}
						});
					}

					if (grList.hasOwnProperty('rate')) {
						if (grList.rate !== null) {
							grList.rate = +(this._CustomNumberNoRoundingPipe.transform(grList.rate));
						}
					}
				}
			});
		}
	}

	public doOpenFilterBuilder() {
		this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}

	public doCustomizeTextTierMinMax(cellInfo: any, parentData: any, which_tier: string) {
		if (which_tier === 'maxtier') {
			if (!cellInfo) {
				return cellInfo;
			} else if (cellInfo === '-') {
				return cellInfo;
			} else {
				if (parentData.data.tier_basis === 'volume') {
					return cellInfo || '0';
				} else if (parentData.data.tier_basis === 'revenue') {
					return this._CommissionService.convertCurrency((+cellInfo || 0));
				} else {
					return cellInfo;
				}
			}
		}
		if (which_tier === 'mintier') {
			if (parentData.data.tier_basis === 'volume') {
				return cellInfo || 0;
			} else if (parentData.data.tier_basis === 'revenue') {
				return this._CommissionService.convertCurrency((+cellInfo || 0));
			} else {
				return cellInfo;
			}
		}
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.exclusionTabDataGridRef.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
	// #region for Datagrid State Storing functionality

	public doLoadCommExclusionsAgreeState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveCommExclusionsAgreeState(e) {
		const commissionListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(commissionListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = commissionListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, commissionListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, commissionListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let commissionListingConfig: any = {};
		try {
			commissionListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			commissionListingConfig = {};
		}

		if (!commissionListingConfig.configs) {
			commissionListingConfig.configs = [];
		}

		const configIndex = commissionListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			commissionListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			commissionListingConfig.configs.push(newConfigObj);
		}
		return commissionListingConfig;
	}

	// #endregion
}
