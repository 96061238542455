import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { Guid } from '@app/models/guid';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { MinMaxChargebackStatusValEnum } from '@app/constants-enums/enums';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-min-max-chargeback-claim-approvals-details',
	templateUrl: './min-max-chargeback-claim-approvals-details.component.html',
	styleUrls: ['./min-max-chargeback-claim-approvals-details.component.css']
})
export class MinMaxChargebackClaimApprovalsDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public minMaxChargebackApprovalDataSource: any;
	public pageOptions: PageOptions = new PageOptions();
	public storedFiltersValue: any;
	private instanceClearBtn: any = null;
	private isDisabledClearBtn: boolean = true;
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	private guid = new Guid();
	public viewFields = ['guid', 'ixcode', 'contractName', 'startDate', 'endDate', 'is_active', 'contractType', 'selectCustomerId'];
	private isShowFilteredText = true;
	private isShowClearBtn = true;
	private isShowFilterBtn = true;
	public selectedKeyDropDownBtn: any;
	public listOfForMinMaxChargebackBtns: any;
	public listOfStatuses: any[] = this._MinMaxEndUsersChargebackContractService.doGetListofClaimApprovalStatus();
	public isShowDropDownButton: Boolean = true;
	public submitReason: string;
	public approvalDetail: any;
	public listOfActions = [
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}];
	public decimalPointForCurrency: number;
	public currentTab: string;
	public dataInReqSubscription: Subscription;
	public dataOutReqSubscription: Subscription;
	private updateUserSettingSbsn: Subscription;
	public approvalTrailDetails: any;
	public loadViewChargebackDocDxDialogSubject: Subject<any> = new Subject();
	public isShowMinMaxChargebackClaimPreviewPopup: boolean = false;
	public minMaxCharebackClaimPreviewProps: any;
	public isOverrideClaimView: boolean = false;
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _IxDxAlertService: IxDxAlertService,
		private _LoaderService: LoaderService,
		private _Router: Router,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,) {
		this.doLoadMinMaxChargebackApprovalState = this.doLoadMinMaxChargebackApprovalState.bind(this);
		this.doSaveMinMaxChargebackApprovalState = this.doSaveMinMaxChargebackApprovalState.bind(this);
	}

	ngOnInit(): void {
		this.currentTab = 'approvals_chargebacks';
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
		this.doDefaultFilter();
		this.applyStoredFilter();
		this.mainSectionStorageKey = ServerSections.NOTIFICATIONS;
		this.stateStorageKey = ServerSections.MIN_MAX_CHARGEBACK_CLAIM_APPROVE_NOTIFICATION_DETAILS;
		this.approvalDetail = this._MinMaxEndUsersChargebackContractService.getMinMaxChargebackClaimApprovalDetails();
		if (this.approvalDetail && this.approvalDetail.hasOwnProperty('type')) {
			if (this.approvalDetail.type === 'override') {
				this.isOverrideClaimView = true;
			}
		}
		this.loadMinMaxChargebackApprovalDataSource();
		this.selectedKeyDropDownBtn = 'approved';
		this.setSubmitMultiPleBtns();
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
	}

	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	private doDefaultFilter() {
		//this.storedFiltersValue = [{ dataType: 'string', dataField: 'is_active', searchMode: 'contains', filterValues: [true] }];
	}
	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	public doOnRowClick(e) {
		const chargeBackDocumentDetails = e;
		// const dialogData = {
		// 	claimId: chargeBackDocumentDetails.ixcode,
		// 	callFrom: 'approval_mm_chargeback_approval_claims',
		// 	itemTabDataList: [],
		// 	minMaxEndUsersChargebackContractModelUI: null,
		// 	chargeBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
		// 	billBaBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
		// };
		// this.loadViewChargebackDocDxDialogSubject.next(dialogData);
		const minMaxEndUsersChargebackContractModelUI = {
			chargebackBasis: e.chargebackBasis,
			guid: e.contractGuid,
			contractName: e.contractName,
		}
		const dialogData = {
			callFrom: 'view',
			chargeback_period: chargeBackDocumentDetails.chargeback_period,
			claimguid: chargeBackDocumentDetails.guid,
			//	selectedEndUserList: selectedEndUserList,
			calledFrom: 'min-max-chargeback-Approval-details',
			approvalDetail: e,
			minMaxEndUsersChargebackContractModelUI: minMaxEndUsersChargebackContractModelUI,
		}
		this.minMaxCharebackClaimPreviewProps = dialogData;
		this.isShowMinMaxChargebackClaimPreviewPopup = false;
		setTimeout(() => {
			this.isShowMinMaxChargebackClaimPreviewPopup = true;
		}, 300);
	}
	public doCellClickDetails(e) {

	}

	public doClickOnTab(activeTab: string) {
		this.approvalTrailDetails = this.approvalDetail;
		this.currentTab = activeTab;
	}

	private loadMinMaxChargebackApprovalDataSource() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.APPROVALCLAIMSLIST,
			approvalID: this.approvalDetail.guid,
			type: 'minmaxenduserschargebackcontracts',
			// view_fields: JSON.stringify(this.viewFields)
		}
		this.minMaxChargebackApprovalDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'before',
				template: 'templateInfoText',
				visible: true
			},
			{
				location: 'after',
				template: 'templateFilterText',
				visible: this.isShowFilteredText
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: this.isShowClearBtn,
				options: {
					icon: "cleariconix",
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: this.isShowFilterBtn,
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
		// const addIndex = this.listOfActions.findIndex(i => i.btn_code
		// 	=== 'ADD');
		// if (addIndex !== -1) {
		// 	this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		// }
	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Min_Max_Chargeback_Approval_Details.xlsx';
		worksheet = workbook.addWorksheet('Min-Max Chargeback Approval Details');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {

				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion
	// #region for Datagrid State Storing functionality

	public doLoadMinMaxChargebackApprovalState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveMinMaxChargebackApprovalState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// #endregion

	//#region Approve/Reject Funcationlity
	private setSubmitMultiPleBtns() {
		this.isShowDropDownButton = false;
		this.listOfForMinMaxChargebackBtns = [
			{
				value: 'approved',
				text: 'Approve',
				label: 'Approve',
				classname: 'defaultClr'
			},
			{
				value: 'rejected',
				text: 'Reject',
				label: 'Reject',
				classname: 'defaultClr'
			},
		];

		this.isHideShowDropDownButton();

	}
	public isHideShowDropDownButton() {
		this.isShowDropDownButton = false;
		if (this.approvalDetail.approval_status === MinMaxChargebackStatusValEnum.DRAFT) {
			this.isShowDropDownButton = false;
		}
		if (this.approvalDetail.approval_status === MinMaxChargebackStatusValEnum.PENDING_APPROVAL) {
			this.isShowDropDownButton = true;
		}
		if (this.approvalDetail.approval_status === MinMaxChargebackStatusValEnum.APPROVED) {
			this.isShowDropDownButton = false;
		}
		if (this.approvalDetail.approval_status === MinMaxChargebackStatusValEnum.REJECTED) {
			this.isShowDropDownButton = false;
		}
	}

	public getSubmitBtnText() {
		return this.listOfForMinMaxChargebackBtns ? this.listOfForMinMaxChargebackBtns[0].text : '';
	}

	public doSaveUpdateClaimList(e) {
		const status = e.selectedItem.value;
		this.callSubmitClaim(status);
	}

	public doSaveUpdateClaimStatusChanged(e) {
		const status = e.itemData.value;
		this.callSubmitClaim(status);
	}

	public callSubmitClaim(status) {
		if (!this.submitReason) {
			this._ToastrService.info('Please add reason!' || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			return;
		}
		let ixCustomDialogOptions: any;
		if (status === 'approved') {
			ixCustomDialogOptions = {
				popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Approve Chargeback Claim(s)',
				message: 'Are you sure you want to approve a Chargeback Claim(s)? <br/>You will not be able to undo the operation.'
			};
		}
		if (status === 'rejected') {
			ixCustomDialogOptions = {
				popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Reject Chargeback Claim(s)',
				message: 'Are you sure you want to reject a Chargeback Claim(s)? <br/>You will not be able to undo the operation.'
			};
		}
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('entity', ServerEntity.APPROVALS);
				formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
				formData.append('guid', this.approvalDetail.guid);
				formData.append('type', 'minmaxenduserschargebackcontracts');
				formData.append('updateData', JSON.stringify({ approval_status: status }));
				if (this.submitReason) {
					formData.append('remark', this.submitReason);
				}
				if (this.dataOutReqSubscription) {
					this.dataOutReqSubscription.unsubscribe();
				}
				this._LoaderService.show();
				this.dataOutReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
					next: response => {
						this._LoaderService.hide();
						if (response && response.flag) {
							this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
						} else {
							this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
						this._Router.navigate(["notifications/approvals/min_max_chargebacks"]);
					}, error: error => {
						this._LoaderService.hide();
						this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				});
			}
		});
	}
	//#endregion

	public doHideViewClaimDxDialog(result) {
		if (result) {
			// this.chargeBackDocumentsTabDatalist = this._MinMaxEndUsersChargebackContractService.getChargebackDocumentTabDataList();
			// this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
			// this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
		}
	}

	public doCloseMinMaxChargebackClaimPopup(e) {

	}

}
