import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { RebatesContractModelRequest, RebatesContractModelUI } from '@app/models/rebates-contract.model';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { LoginService } from '@app/services/login.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { Observable, Subscription } from 'rxjs';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import * as moment from 'moment';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { RebateApprovalStatusValEnum } from '@app/constants-enums/enums';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
@Component({
	selector: 'app-rebate-tierbase-close-settlement-list-tab',
	templateUrl: './rebates-tierbase-close-settlement-list-tab.component.html',
	styleUrls: ['./rebates-tierbase-close-settlement-list-tab.component.css']
})
export class RebatesTierbaseCloseSettlementListTabComponent implements OnInit, OnChanges {

	@Input() rebatesDetails = new RebatesContractModelUI();
	@Input() salesPplList: any = [];
	@Input() isAllCustomerSelectedView: boolean = false;
	@Input() isAllProductSelectedView: boolean = false;
	@Input() customerTabList: any = [];
	@Input() memberTabList: any = [];
	@Input() productOrGroupTabList: any = [];
	@Input() listOfItemsAndGroupsItmGrpTab: any = [];
	@Input() exclusionTabList: any = [];
	@Input() ruleTabList: any[] = [];
	@Input() rebatesRequest = new RebatesContractModelRequest();
	@Input() isCurrentTabPermission: string;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public pageOptions: PageOptions = new PageOptions();
	public closeSettlementDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	private dataOutReqSubscription: Subscription;
	public decimalPointForCurrency: number;
	public globalCurrencySymbol: string;
	public enable_rebate_settlement_approval_workflow: boolean;
	public isShowApprovalStatusColumn: boolean;
	public listOfSettlementStatuses: any[] = [];
	public listOfApprovalStatuses: any[] = [];
	public isShowRebateSettlementApprovalTrailsPopup: boolean;
	public approvalTrailDetailsProps: any;
	private isCellClickApprovalTrail: boolean;
	private editRebatesGuid: string;
	public RebateApprovalStatusValEnum = RebateApprovalStatusValEnum;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public enable_other_currencies: boolean;

	constructor(private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _LoginService: LoginService,
		private _RebateService: RebateService,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _RestApiService: RestApiService) {
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
		this.getCurrencyDisplayValue = this.getCurrencyDisplayValue.bind(this);
	}


	ngOnInit() {
		// get global currency
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		setTimeout(() => {
			this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.rebatesDetails);
			this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
		}, 100);
		this.editRebatesGuid = this._RebateService.getEditRebatesGuid();
		this.doGetSettlementPeriodList();
		this.preInIt();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.rebatesDetails = changes['rebatesDetails'].currentValue;
	}


	// Method used to Init Tab and load data.
	private preInIt() {
		this.listOfSettlementStatuses = this._RebateService.getListOfSettlementStatuses;
		this.listOfApprovalStatuses = this._RebateService.getListOfApprovalStatuses;
		this.doCheckEnabledRebateSettlementApprovalWorkflowFeature();
		this.doHideShowDatagridColumns();
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
	}

	private doGetSettlementPeriodList() {
		this.editRebatesGuid = this._RebateService.getEditRebatesGuid();
		const viewFields = ['ixcode', 'contractName', 'settlementPeriodStart', 'settlementPeriodEnd', 'subusername', 'settlementValue', 'creditMemoCreatedInERP'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLOSESETTLEMENTS,
			contracttype: 'rebatescontracts',
			baseContractCode: this.editRebatesGuid
		};
		this.closeSettlementDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}
	public doCellClickSettlementDetails(e: any) {
		this.isCellClickApprovalTrail = false;
		if (e.column.dataField === "guid") {
			this.isCellClickApprovalTrail = true;
			return;
		}
	}

	public doRowClickSettlementDetails(invoiceDetails) {
		if (this.isCellClickApprovalTrail) {
			return;
		}
		if (invoiceDetails) {
			if (invoiceDetails.rebate_for === 'buyinggroup') {
				this._RebateService.setInvoiceDetails(invoiceDetails);
				// this._Router.navigate(['/contracts/rebates/reportview-buyinggroup']);
				this._Router.navigate(['../rebates/reportview-buyinggroup'], { relativeTo: this._ActivatedRoute });
			} else {
				this._RebateService.setInvoiceDetails(invoiceDetails);
				// this._Router.navigate(['/contracts/rebates/report']);
				this._Router.navigate(['../rebates/report'], { relativeTo: this._ActivatedRoute });
			}
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					icon: "cleariconix",
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		const cName = this.rebatesDetails.contractName || '';
		let fileName = '';
		if (cName) {
			fileName = cName + '-';
		}
		fileName = fileName + 'Settlement_Report.xlsx';
		worksheet = workbook.addWorksheet('Settlement List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'creditMemoCreatedInERP') {
						excelCell.value = '';
						if (gridCell.value) {
							let colorName;
							switch (gridCell.value) {
								case 'Open':
									colorName = '706cce';
									break;
								case 'Processing':
									colorName = 'f99300';
									break;
								case 'Success':
									colorName = '05c1a0';
									break;
								case 'Error':
									colorName = 'ff4545';
									break;
								default:
									break;
							}
							excelCell.font = { color: { argb: colorName } };
							// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colorName.toString() } };
							excelCell.value = gridCell.value;
						}
					}
					if (gridCell.column.dataField === 'errorMessage') {
						if (gridCell.value) {
							excelCell.alignment = { wrapText: true };
							excelCell.value = gridCell.value;
						}
					}

				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	doUpdateSettlementPeriodClick() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
		formData.append('contracttype', 'rebatescontracts');
		if (this.rebatesRequest.guid) {
			formData.append('contractguid', this.rebatesRequest.guid);
		}

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response.flag) {
				this.rebatesDetails.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
				this.rebatesDetails.current_settlement_commission_total = response.data.current_settlement_rebate_total;
				this.doGetSettlementPeriodList();
				// this._ToastrService.success(response.message, 'Saved', { closeButton: true, tapToDismiss: true });
			} else {
				this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			console.error('error', error);
			this._LoaderService.hide();
			this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
		});
	}

	private doCheckEnabledRebateSettlementApprovalWorkflowFeature() {
		let isEnabledRebateSettlementApprovalWorkflow = false;
		try {
			isEnabledRebateSettlementApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_rebate_settlement_approval_workflow;
		} catch (e) {
			isEnabledRebateSettlementApprovalWorkflow = false;
		}
		this.enable_rebate_settlement_approval_workflow = isEnabledRebateSettlementApprovalWorkflow;
	}

	private doHideShowDatagridColumns() {
		this.isShowApprovalStatusColumn = false;
		if (this.enable_rebate_settlement_approval_workflow) {
			this.isShowApprovalStatusColumn = true;
		}
	}

	public doViewApprovalTrails(element: any) {
		this.approvalTrailDetailsProps = {
			guid: this.rebatesDetails.guid,
			settlement_guid: element.guid
		}
		this.doOpenApprovalTrailsPopup();
	}


	private doOpenApprovalTrailsPopup() {
		this.isShowRebateSettlementApprovalTrailsPopup = false;
		setTimeout(() => {
			this.isShowRebateSettlementApprovalTrailsPopup = true;
		}, 100);
	}

	public doCustomizeTotalSummary(e) {
		return this._CustomCurrencyPipe.transform(e.value || 0, this.customCurrencyOption);
	}
	public getCurrencyDisplayValue(rowData: any) {
		if (rowData && rowData.hasOwnProperty('settlementValue')) {
			return this._CustomCurrencyPipe.transform(rowData.settlementValue || 0, this.customCurrencyOption);
		}
	}

}
