<!-- <div class="row" *ngxPermissionsOnly="isCurrentTabPermission">
	<div *ngIf="!isQBOnlineErpUser && minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName"
		class="cols12 ">
		<div class="right secondaryTitle">
			<div class="noteBx mrgT0">
				<span>
					<strong>Customer Price List :</strong>
				</span>
				<span *ngIf="minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName">
					{{minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName || ''}}
				</span>
				<span *ngIf="!minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName">
					<strong> &mdash; </strong>
				</span>
			</div>
		</div>
	</div>
</div> -->

<div class="row">
	<div class="inputContolscf gridSearch cf cols10">
		<div class="dx-field feildCol">
			<div class="dx-field-label">End-Users</div>
			<div class="dx-field-value" style="width: 100%;">
				<dx-drop-down-box [(value)]="endUsersGridBoxValue" [displayExpr]="displayExprEndUserName" valueExpr="guid"
					placeholder="Select End-User" [(opened)]="isEndUsersDropDownBoxOpened" [showClearButton]="true"
					[deferRendering]="false" [dataSource]="endUsersDataSource"
					(onOptionChanged)="onEndUsersSelectionChanged($event)">
					<div *dxTemplate="let data of 'content'">
						<dx-data-grid #endUsersDataGrid [dataSource]="endUsersDataSource" [columns]="endUsersGridBoxColumns"
							[hoverStateEnabled]="true" [(selectedRowKeys)]="endUsersGridBoxValue" height="100%">
							<dxo-selection mode="single"></dxo-selection>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
						</dx-data-grid>
					</div>
				</dx-drop-down-box>
			</div>
		</div>
	</div>

	<!-- <div class="inputContolscf gridSearch cf cols7 cols7addbtn">
		<div class="dx-field feildCol">
			<div class="dx-field-label">Pricelist</div>
			<div class="dx-field-value" style="width: 100%;">
				<dx-drop-down-box #dxDropDownRefPricelistRep [dataSource]="pricelistDataSource"
					[(value)]="priceListGridBoxValue" valueExpr="guid" displayExpr="pricelistname" placeholder="Select
					Pricelist..." [deferRendering]="false" [showClearButton]="true"
					(onOptionChanged)="onOptionChangedPriceList($event)" [(opened)]="isPriceListDropDownBoxOpened">
					<div *dxTemplate="let data of 'content'">
						<dx-data-grid #pricelistDataGrid [dataSource]="pricelistDataSource"
							[columns]="pricelistGridBoxColumns" [hoverStateEnabled]="true"
							[(selectedRowKeys)]="priceListGridBoxValue" height="100%">
							<dxo-selection mode="single"></dxo-selection>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual"> </dxo-scrolling>
							<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
						</dx-data-grid>
					</div>
				</dx-drop-down-box>
			</div>
		</div>
	</div> -->

	<div class="cols1 cols1addbtn txtRight">
		<dx-button class="secondaryBtn button center" type="normal" stylingMode="contained" [useSubmitBehavior]="false"
			icon="add" (onClick)="addProductAgreementTabDetails()" text="Add">
		</dx-button>
	</div>
</div>

<div class="mrgT10">

	<dx-data-grid id="gridContainer" #itemDataGridContainer [dataSource]="itemTabDataSource" [remoteOperations]="false"
		[showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true"
		(onRowRemoved)="doOnRowRemovedItemTab($event)" [height]="450" keyExpr="guid" class="hideFilterPanelInx"
		[height]="'40%'" (onToolbarPreparing)="doToolbarPreparing($event)"
		(onRowExpanding)="doOnRowExpandingItemTab($event)">
		<dxo-selection mode="single"></dxo-selection>
		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-filter-panel [visible]="true"></dxo-filter-panel>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
		<dxo-master-detail [enabled]="true" template="itemDetail" [autoExpandAll]="false"></dxo-master-detail>
		<!-- <dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
		<dxo-pager [visible]="false" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
			[showInfo]="true"></dxo-pager> -->
		<dxo-editing mode="row" [allowUpdating]="!isDisablePermission" [allowDeleting]="!isDisablePermission"
			[useIcons]="true">
		</dxo-editing>

		<div *dxTemplate="let data of 'dateBoxTemplate'">
			<dx-date-box type="date" [value]="minMaxEndUsersChargebackContractModelUI?.month_filter || nowDate"
				displayFormat="monthAndYear" [min]="minMaxEndUsersChargebackContractModelUI?.startDate || nowDate "
				[max]="minMaxEndUsersChargebackContractModelUI?.endDate || nowDate"
				[elementAttr]="{ class: 'dxDateBoxHeight' }" (onValueChanged)="doFilterDateOnValueChanged($event)"
				[calendarOptions]="{ maxZoomLevel: 'year', minZoomLevel: 'decade' }">
			</dx-date-box>
		</div>

		<div *dxTemplate="let data of 'templateFilterText'">
			<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
				<span>{{filterText}}</span>
			</div>
		</div>

		<dxi-column caption="End-User" dataField="end_user_name" [allowEditing]="false"
			cellTemplate="cellTemplateSelectEndUsers" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplateSelectEndUsers'">
				{{element.data.end_user_name || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="PriceList No." dataField="pricelistno" [allowEditing]="false"
			cellTemplate="cellTemplatePriceListNo" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplatePriceListNo'">
				{{element.data.pricelistno || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="PriceList" dataField="pricelistname" [allowEditing]="false"
			cellTemplate="cellTemplatePriceList" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplatePriceList'">
				{{element.data.pricelistname || ''}}
			</div>
		</dxi-column>

		<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
			[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
			[allowExporting]="false">
			<!-- <dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button> -->
			<dxi-button name="delete" cssClass="dx-grid-delete menuTip" [visible]="chkClaimedQty"></dxi-button>
		</dxi-column>

		<div *dxTemplate="let itemDetail of 'itemDetail'">
			<dx-data-grid #itemTabDataGridContainer id="gridContainer" [dataSource]="itemDetail.data.items"
				[remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true"
				(onEditorPreparing)="doOnEditorPreparingItemTab($event)" (onSaved)="doOnSavedItemTab()" keyExpr="guid"
				(onSelectionChanged)="doOnSelectionChangedItemTab($event)" class="hideFilterPanelInx"
				(onCellClick)="doCellClick($event,itemDetail)">

				<dxo-sorting mode="single"></dxo-sorting>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-filter-panel [visible]="true"></dxo-filter-panel>
				<dxo-header-filter [visible]="true"></dxo-header-filter>
				<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
				<dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false"
					[useIcons]="true">
				</dxo-editing>
				<dxi-column caption="SKU" dataField="itemsku" [visible]="!checkAllItem && isQBOnlineErpUser"
					[allowEditing]="false" cellTemplate="cellTemplateSKU" [allowHeaderFiltering]="false"
					[allowFiltering]="true" [allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateSKU'">
						{{element.data.itemsku || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Item" dataField="itemname" [allowEditing]="false" cellTemplate="cellTemplateItemName"
					[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateItemName'">
						{{element.data.itemname || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Description" dataField="description" [allowEditing]="false"
					cellTemplate="cellTemplateDescription" [allowHeaderFiltering]="false" [allowFiltering]="true"
					[allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateDescription'">
						{{element.data.description || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Min" dataField="min" [allowEditing]="false" cellTemplate="cellTemplateMin"
					[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateMin'">
						{{element.data.min || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Max" dataField="max" [allowEditing]="false" cellTemplate="cellTemplateMax"
					[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateMax'">
						{{element.data.max || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="UOM" dataField="selectedUom" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowEditing]="true" cellTemplate="cellTemplateUomCode" dataType="string">
					<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" displayExpr="name">
					</dxo-lookup>
					<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
					<div *dxTemplate="let element of 'cellTemplateUomCode'">
						<span>
							{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' }}
						</span>
					</div>
				</dxi-column>
				<!-- <dxi-column caption="List Price" dataField="listPrice" [allowEditing]="false" [allowHeaderFiltering]="false"
					[allowFiltering]="false" [allowSorting]="true" [visible]="!enable_chargeback_look_back_period"
					dataType="number">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateListPrice'">
						{{element.data.listPrice || 0 | customCurrencyPipe }}
					</div>
				</dxi-column>
				<dxi-column caption="Final Price" dataField="final_amount" [allowEditing]="false"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" dataType="number"
					[visible]="!enable_chargeback_look_back_period">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateFinalAmount'">
						{{element.data.final_amount || 0 | customCurrencyPipe}}
					</div>
				</dxi-column>
				<dxi-column caption="Chargeback Amount" dataField="chargebackAmount" [allowEditing]="false"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" alignment="right"
					dataType="number" [visible]="!enable_chargeback_look_back_period">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateChargebackAmount'">
						{{element.data.chargebackAmount || 0 | customCurrencyPipe }}
					</div>
				</dxi-column> -->
				<!-- <dxi-column caption="Sold" dataField="sold" [allowEditing]="false" cellTemplate="cellTemplateSold"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateSold'">
						<span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.sold) > 0}">{{viewTotalQuantity(element.data.sold)}}</span>
					</div>
				</dxi-column> -->
				<dxi-column caption="Total Purchase" dataField="total_bought" [allowEditing]="false"
					cellTemplate="cellTemplateTotalBought" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateTotalBought'">
						<span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.total_bought) > 0}">{{viewTotalQuantity(element.data.total_bought)}}</span>
					</div>
				</dxi-column>
				<dxi-column caption="Contract Purchase" dataField="bought" [allowEditing]="false"
					cellTemplate="cellTemplateBought" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateBought'">
						<span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.bought) > 0}">{{viewTotalQuantity(element.data.bought)}}</span>
					</div>
				</dxi-column>
				<dxi-column caption="Other Purchase" dataField="other_bought" [allowEditing]="false"
					cellTemplate="cellTemplateOtherBought" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateOtherBought'">
						<span
							[ngClass]="{'highlight pointerMark': viewOtherQuantity(element.data) > 0}">{{viewOtherQuantity(element.data)}}</span>
					</div>
				</dxi-column>
				<dxi-column caption="Available" dataField="available" [allowEditing]="false"
					cellTemplate="cellTemplateAvailable" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateAvailable'">
						{{viewTotalQuantity(element.data.available)}}
						<!-- <span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.available) > 0}">{{viewTotalQuantity(element.data.available)}}</span> -->
					</div>
				</dxi-column>
				<dxi-column caption="Claimed" dataField="claimed" [allowEditing]="false" cellTemplate="cellTemplateClaimed"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateClaimed'">
						<span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.claimed) > 0}">{{viewTotalQuantity(element.data.claimed)}}
						</span>
					</div>
				</dxi-column>

				<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
					[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
					[allowExporting]="false" [visible]="false">
					<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
					<!-- <dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button> -->
				</dxi-column>
				<!-- <dxo-summary>
					<dxi-total-item column="sold" summaryType="sum" displayFormat="{0}"></dxi-total-item>
					<dxi-total-item column="claimed" summaryType="sum" displayFormat="{0}"></dxi-total-item>
					<dxi-total-item column="available" summaryType="sum" displayFormat="{0}"></dxi-total-item>
				</dxo-summary> -->
			</dx-data-grid>
		</div>
		<dxo-summary>
			<dxi-total-item column="end_user_name" summaryType="count"
				displayFormat="Total End-User: {0}"></dxi-total-item>
		</dxo-summary>
	</dx-data-grid>

	<div *ngIf="isUploadExcelCsvFileItmGrpTab" class="noteBx mrgT10 mrgB0">
		<span>
			<b>{{noOfUnmatchedItem || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b> Item(s)
			ignored
			<span class="noteSeprator"></span>
			<b>{{noOfUnmatchedItemUom || 0}}</b> UOM(s) out of <b>{{noOfTotalItem || 0}}</b> UOM(S)
			ignored
			<span>
				<span class="noteSeprator"></span>
				<b>{{noOfmatchedItemPrice || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b>
				item(S)
				Price / Chargeback ignored
			</span>
			<strong *ngIf="listOfCsvRowForItmGrpTab && listOfCsvRowForItmGrpTab.length > 0"
				(click)="doShowCsvItemsListItmGrpTab()" class="pointerMark">Click here to
				view</strong>
		</span>
	</div>
	<!-- <div *ngIf="unMatchedItems.length > 0" class="noteBx mrgT10 mrgB0">
		<span>
			Some items from the End User Price List were not included in the Customer Price List or vice-versa
			<strong (click)="doShowEndUserUnMatchedItems()" class="pointerMark">Click here to
				view</strong>
		</span>
	</div> -->
</div>

<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width70:'X'" height="80vh"
	[showTitle]="true" [title]="quantityHeaderText" [dragEnabled]="true" [hideOnOutsideClick]="true"
	[showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding popupZIndex' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[elementAttr]="{ class: 'dx-scrollable-content-padR0' }">
			<div class="cols12 padL0 padR0"
				[ngClass]="{'cols6':(listOfSpecificCustomFeatureFields && listOfSpecificCustomFeatureFields.length > 0)}">
				<h4 class="graySubHead textWrap mrgB0" *ngIf="dxItemDescription && (quantityDataField !== 'other_bought')">
					Customer :
					<span class="black">{{minMaxEndUsersChargebackContractModelUI.selectCustomer}}</span>
				</h4>
				<h4 class="graySubHead textWrap mrgB0" *ngIf="dxItemName">Item :
					<span class="black">{{dxItemName}}</span>
				</h4>
				<h4 class="graySubHead textWrap " *ngIf="dxItemDescription">Description :
					<span class="black mrgR15">{{dxItemDescription}}</span>
				</h4>
			</div>
			<div class="cols6 padL0 padR0 mrgB10">
				<div *ngIf="listOfSpecificCustomFeatureFields && listOfSpecificCustomFeatureFields.length > 0 "
					class="externalFieldBgix">
					<p class="externalFieldTitleix">External Field</p>
					<p *ngFor="let specificField of listOfSpecificCustomFeatureFields;let menuIndex = index;"
						class="externalFieldDescriptionix">
						<span
							class="externalFieldLabelix">{{specificField.custom_label}}:</span>{{specificField.custom_display_value}}
					</p>
				</div>
			</div>
			<!-- <div class="subTitleRow">
				<h2>{{quantityHeaderText}}</h2>
			</div> -->
			<div class="cols12 padL0 padR0">
				<dx-data-grid #quantityDataGridContainer [dataSource]="listOfAvailableQuantity" [showBorders]="true"
					[remoteOperations]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
					[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true" [width]="'100%'" class="hideFilterPanelInx"
					keyExpr="sr_no" height="calc(-200px + 80vh)" [elementAttr]="{ class: 'pageNavigationpadB10' }">
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
					<dxo-master-detail [enabled]="true" template="invoiceDetail"></dxo-master-detail>
					<dxi-column dataField="sr_no" [allowHeaderFiltering]="false" caption="Sr. No."
						cellTemplate="cellTemplateSrNo" alignment="center" dataType="string" [visible]="false">
						<div *dxTemplate="let element of 'cellTemplateSrNo'">
							{{element.data.sr_no || ''}}
						</div>
					</dxi-column>

					<dxi-column dataField="quantity" [allowHeaderFiltering]="false" caption="Quantity"
						cellTemplate="cellTemplateQuantity" alignment="left" dataType="number">
						<div *dxTemplate="let element of 'cellTemplateQuantity'">
							{{element.data.quantity || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="price" [allowHeaderFiltering]="false" caption="Price" [visible]="true"
						dataType="number">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplatePrice'">
							{{element.data.price || 0 | customCurrencyPipe}}
						</div>
					</dxi-column>
					<!-- <dxi-column dataField="price" [allowHeaderFiltering]="false" caption="Price"
					[visible]="quantityDataField === 'sold'" dataType="number">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplatePrice'">
						{{element.data.price || 0 | customCurrencyPipe}}
					</div>
				</dxi-column>
				<dxi-column dataField="final_amount" [allowHeaderFiltering]="false" caption="End-User Price"
					[visible]="quantityDataField === 'sold'" dataType="number">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateEndUserPrice'">
						{{element.data.final_amount|| 0 | customCurrencyPipe}}
					</div>
				</dxi-column>
				<dxi-column dataField="chargebackAmount" [allowHeaderFiltering]="false" caption="Chargeback"
					dataType="number" [visible]="quantityDataField === 'claimed' || quantityDataField === 'available'">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateChargeback'">
						{{element.data.chargebackAmount|| 0 | customCurrencyPipe}}
					</div>
				</dxi-column> -->
					<div *dxTemplate="let invDetail of 'invoiceDetail'">
						<dx-data-grid [dataSource]="invDetail.data.invoices" [showBorders]="true" [remoteOperations]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true" [width]="'100%'"
							[elementAttr]="{ class: 'pageNavigationpadB10' }" height="calc(-358px + 80vh)"
							(onCellClick)="doOnInvoiceItemInvoiceCellClick($event)" class="hideFilterPanelInx">
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-filter-panel [visible]="false"></dxo-filter-panel>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxi-column dataField="type" [allowHeaderFiltering]="false" caption="Doc Type"
								cellTemplate="cellTemplateDocType" dataType="string" [visible]="true">
								<!-- <dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
								</dxo-header-filter> -->
								<div *dxTemplate="let element of 'cellTemplateDocType'">
									{{element.data.type | filterFromList:listOfDocTypeHeaderFilter : 'value'
									: 'text' }}
								</div>
							</dxi-column>
							<dxi-column dataField="customer" [allowHeaderFiltering]="false" caption="Customer Code"
								cellTemplate="cellTemplateCustomer" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateCustomer'">
									{{element.data.customer || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="customerName" [allowHeaderFiltering]="false" caption="Customer Name"
								cellTemplate="cellTemplateCustomerName" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateCustomerName'">
									{{element.data.customerName || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="docnum" [allowHeaderFiltering]="false" caption="Doc No."
								cellTemplate="cellTemplateDocnum" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateDocnum'">
									<span class="highlight pointerMark">{{element.data.docnum || ''}}</span>
								</div>
							</dxi-column>
							<dxi-column dataField="docdate" [allowHeaderFiltering]="false" caption="Invoice Date"
								cellTemplate="cellTemplateDocdate" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateDocdate'">
									{{element.data.docdate? convertToLocalDate(element.data.docdate) :''}}
								</div>
							</dxi-column>
							<dxi-column dataField="quantity" [allowHeaderFiltering]="false" caption="Quantity"
								cellTemplate="cellTemplateQuantity" alignment="left" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateQuantity'">
									{{element.data.quantity || ''}}
								</div>
							</dxi-column>
							<!-- <dxi-column dataField="eligible" [allowHeaderFiltering]="false" caption="Eligible"
							cellTemplate="cellTemplateEligible"  dataType="string">
							<div *dxTemplate="let element of 'cellTemplateEligible'">
								{{element.data?.eligible ? getEligibleVal(element.data.eligible) :''}}
							</div>
						</dxi-column> -->
							<!-- {{element.data.eligible == '' ? '' :element.data.eligible ? 'Yes' : 'No' || ''}} -->
						</dx-data-grid>
					</div>
				</dx-data-grid>
			</div>
			<div class="row" *ngIf="quantityDataField === 'available'">
				<div class="cols12">
					<div class="mrgT10 alignLeft">
						<span class="icon-toolTip"></span>
						Note : Available quantity does not consider quantity already claimed on other contracts.
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>

<app-mmeucc-item-claim-details-dx-popup
	[minMaxEndUsersChargebackContractModelUI]="minMaxEndUsersChargebackContractModelUI"
	[loadItemClaimDetailsSubject]="loadItemClaimDetailsSubject"></app-mmeucc-item-claim-details-dx-popup>
<!-- 
<app-view-invoice-detail-popup *ngIf="isShowInvoiceDetailPopUp" [viewInvoiceDetailProps]="viewInvoiceDetailProps">
</app-view-invoice-detail-popup> -->
<app-view-end-users-invoice-detail-popup *ngIf="isShowEndusersInvoiceDetailPopUp"
	[viewEndusersInvoiceDetailProps]="viewEndusersInvoiceDetailProps"></app-view-end-users-invoice-detail-popup>
<app-view-craditmemo-detail-popup *ngIf="isShowCreditMemoDetailPopUp"
	[viewCreditMemoDetailProps]="viewCreditMemoDetailProps"></app-view-craditmemo-detail-popup>
<app-mmeucc-upload-items-dialog *ngIf="isShowUploadItemsPopup" [uploadItemsProps]="uploadItemsProps"
	(closeUploadItemsPopup)="doCloseUploadItemsPopup($event)">></app-mmeucc-upload-items-dialog>
<app-mmeucc-view-claim-qty-detail-popup *ngIf="isShowOtherPurchasePopup"
	[otherPurchaseProps]="otherPurchaseProps"></app-mmeucc-view-claim-qty-detail-popup>