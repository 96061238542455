import { Injectable } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BillbackContractService {

	public tabItemsList: any[] = [
		{ key: 'items', displayVal: 'Items' },
		{ key: 'billbackdocuments', displayVal: 'Billback Documents' }
	];
	public editBillbackGuid: any;
	public itemTabDataList: any = [];
	public calledClaimedItem: string;
	private actionFrom: string;
	private dataOutReqSubscription: Subscription;
	constructor(
		public _LoaderService: LoaderService,
		public _LoginService: LoginService,
		public _ToastrService: ToastrService,
		public _RestApiService: RestApiService,
		public _GlobalStateService: GlobalStateService,
	) { }

	public setEditBillbackGuid(editBillbackGuid) {
		this.editBillbackGuid = editBillbackGuid;
	}
	public getEditBillbackGuid() {
		return this.editBillbackGuid;
	}
	public setItemTabDataList(list) {
		this.itemTabDataList = list;
	}
	public getItemTabDataList() {
		return this.itemTabDataList;
	}

	public setActionFrom(actionFrom: string) {
		this.actionFrom = actionFrom;
	}
	public getActionFrom() {
		return this.actionFrom || 'billback_chargeback';
	}

	public getChargebackDocumentLst(editChargebackGuid, contracttype) {
		if (editChargebackGuid) {
			this._LoaderService.show();
			const formData = new FormData();
			const viewFields = ['guid', 'ixcode', 'chargeback_period', 'documentDate', 'documentTotal', 'created_by_name', 'claimedStatus', 'claimtype', 'cut_off_date', 'transaction_date', 'endusersList', 'remark']
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('contractguid', editChargebackGuid);
			formData.append('entity', ServerEntity.CHARGEBACKDOC);
			formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
			formData.append('contracttype', contracttype);
			formData.append('view_fields', JSON.stringify(viewFields));
			if (this.dataOutReqSubscription) {
				this.dataOutReqSubscription.unsubscribe();
			}
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
				next: (response) => {
					if (response.flag && response.data) {
						if (response.data.length > 0) {
							this._GlobalStateService.notifyDataChangedDuplicate('DISABLED_DISTRIBUTION_CHANNEL_' + contracttype, { isDisabled: true });
						} else {
							this._GlobalStateService.notifyDataChangedDuplicate('DISABLED_DISTRIBUTION_CHANNEL_' + contracttype, { isDisabled: false });
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
				}, error: (error) => {
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			});
		} else {
			this._GlobalStateService.notifyDataChangedDuplicate('DISABLED_DISTRIBUTION_CHANNEL_' + contracttype, { isDisabled: false });
		}
	}
}
