import { Pipe, PipeTransform } from '@angular/core';
import { DATETIME_FORMAT_BACKEND, DATE_FORMAT_FRONTEND, ServerSections } from '@app/constants-enums/constants';
import { DateRangeGlobalSettingLblEnum, DateRangeGlobalSettingValEnum } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { LoginService } from '@app/services/login.service';
import * as moment from "moment";

@Pipe({
	name: 'customDateFormatPipe'
})
export class CustomDateFormatPipe implements PipeTransform {

	transform(value: any, args?: any, dateFormat?: string): any {
		if (value) {
			const stillUtc = moment.utc(value).toDate();
			if (args === 'usadatewithdash') {
				value = moment(value).local().format('YYYY-MM-DD');
			} else if (args === 'dateonly') {
				value = moment(value).local().format('DD MMMM, YYYY');
			} else if (args === 'usadateonly') {
				value = moment(value).local().format('YYYY MMMM, DD');
			} else if (args === 'datetimeonly') {
				value = moment(stillUtc).local().format("DD MMMM, YYYY | hh:mm A");
			} else if (args === 'custom') {
				value = moment(value).local().format(dateFormat);
			} else if (args === 'default') {
				value = moment(value).local().format('YYYY-MM-DD HH:mm:ss');
			} else if (args === 'convertFrom24To12') {
				value = moment(value).local().format('YYYY-MM-DD hh:mm:ss A');
			} else if (args === 'convertFrom24To12WoSecond') {
				value = moment(value).local().format('YYYY-MM-DD hh:mm A');
			} else if (args === 'day') {
				value = moment(value).local().format('DD');
			} else if (args === 'month') {
				value = moment(value).local().format('MMM');
			} else if (args === 'sort_year') {
				value = moment(value).local().format('YY');
			} else if (args === 'year') {
				value = moment(value).local().format('YYYY');
			} else if (args === 'only_hours_minutes_with_am_pm') {
				value = moment(value).local().format('hh:mm A');
			} else if (args === 'only_am_pm') {
				value = moment(value).local().format('hh:mm A');
			}
		}
		if (value === 'Invalid date') {
			value = '';
		}
		return value || '';
	}
}

@Pipe({
	name: 'getDateRangeTextPipe'
})
export class GetDateRangeTextPipe implements PipeTransform {
	constructor(private _LoginService: LoginService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _DashboardService: DashboardService) {

	}

	transform(show_data_from: any): any {
		let getDateRangeInString = '';
		const listOfShowDataFrom = this._DashboardService.getListOfDateRangeShowDataFrom();
		let showDataFromText = DateRangeGlobalSettingLblEnum.CURRENT_MONTH;
		const serverDate = this._LoginService.loginUser.server_date;
		getDateRangeInString = moment(serverDate).format('MMM  YYYY');
		const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (dashboardSettings.hasOwnProperty('date_range')) {
			const dateRangeConfig = dashboardSettings.date_range;
			const sdfIndex = listOfShowDataFrom.findIndex(sdf => sdf.value === dateRangeConfig.show_data_from);
			if (sdfIndex !== -1) {
				showDataFromText = listOfShowDataFrom[sdfIndex].text;
			}
			if (dateRangeConfig.show_data_from === DateRangeGlobalSettingValEnum.CURRENT_MONTH) {
				getDateRangeInString = moment(serverDate).format('MMM YYYY');
			}
			if (dateRangeConfig.show_data_from === DateRangeGlobalSettingValEnum.LAST_MONTH) {
				getDateRangeInString = moment(serverDate).subtract(1, 'month').format('MMM YYYY');
			}
			if (dateRangeConfig.show_data_from === DateRangeGlobalSettingValEnum.CALENDAR_YEAR_TO_DATE) {
				if ((moment(serverDate).get('month') + 1) === 1) {
					getDateRangeInString = moment(serverDate).format('MMM YYYY');
				} else {
					getDateRangeInString = moment(serverDate).set('month', 0).startOf('month').format('MMM YYYY') + ' To ' + moment(serverDate).endOf('month').format('MMM YYYY');
				}
			}
			if (dateRangeConfig.show_data_from === DateRangeGlobalSettingValEnum.FISCAL_YEAR_TO_DATE) {
				let fiscalYearStart = 1;
				try {
					fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
				} catch (e) {
					fiscalYearStart = 1;
				}
				if ((moment(serverDate).get('month') + 1) === fiscalYearStart) {
					getDateRangeInString = moment(serverDate).format('MMM YYYY');
				} else {
					if ((moment(serverDate).get('month') + 1) > fiscalYearStart) {
						getDateRangeInString = moment(serverDate).set('month', fiscalYearStart - 1).startOf('month').format('MMM YYYY') + ' To ' + moment(serverDate).endOf('month').format('MMM YYYY');
					}
					if ((moment(serverDate).get('month') + 1) < fiscalYearStart) {
						getDateRangeInString = moment(serverDate).set('month', fiscalYearStart - 1).subtract(1, 'year').startOf('month').format('MMM YYYY') + ' To ' + moment(serverDate).endOf('month').format('MMM YYYY');
					}
				}
			}

			if (dateRangeConfig.show_data_from === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
				getDateRangeInString = moment(dateRangeConfig.custom_start_date).format(DATE_FORMAT_FRONTEND) + ' To ' + moment(dateRangeConfig.custom_end_date).format(DATE_FORMAT_FRONTEND);
			}
		}
		// return showDataFromText + ' (' + getDateRangeInString + ')';
		return getDateRangeInString;
	}
}


@Pipe({
	name: 'toDateObj'
})
export class ToDateObjPipe implements PipeTransform {

	transform(value: any): any {
		if (value) {
			if (value.toString().indexOf(' ') === -1) {
				value = value + ' 00:00:00';
			}
			const temp = value.toString().replace(' ', 'T');
			return new Date(temp);
		} else {
			return null;
		}
	}

}
