import { Injectable } from '@angular/core';
import { LoaderState } from '@app/models/loaderstate.model';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {

	private loaderSubject = new Subject();
	loaderState = this.loaderSubject.asObservable();
	constructor() { }
	show(customChange?: LoaderState) {
		const loaderVisible = {
			dx_visible: true,
			dx_position: { at: 'center' },
			dx_shadingColor: 'transparent',
			dx_showPane: true,
			dx_showIndicator: true,
			dx_shading: true,
			dx_closeOnOutsideClick: false,
		};
		const customLoadPanelProperties = { ...loaderVisible, ...customChange };
		this.loaderSubject.next(customLoadPanelProperties);
		// this.loaderSubject.next({ loaded: true });
	}
	hide() {
		this.loaderSubject.next({ dx_visible: false });
		// this.loaderSubject.next({ loaded: false });
	}

	showDxLoadPanel(customChange?: LoaderState) {
		const loaderVisible = {
			dx_visible: true,
			dx_position: { at: 'center' },
			dx_shadingColor: 'transparent',
			dx_showPane: true,
			dx_showIndicator: true,
			dx_shading: true,
			dx_closeOnOutsideClick: false,
		};
		const customLoadPanelProperties = { ...loaderVisible, ...customChange };
		this.loaderSubject.next(customLoadPanelProperties);
	}

	hideDxLoadPanel() {
		this.loaderSubject.next({ dx_visible: false });
	}
}
