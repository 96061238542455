import { Pipe, PipeTransform } from '@angular/core';
import { LoginService } from '@app/services/login.service';

@Pipe({
	name: 'customNumberSuffixPipe'
})
export class CustomNumberSuffixPipe implements PipeTransform {
	// https://medium.com/front-end-weekly/creating-a-short-number-format-pipe-in-angular-8a2c973c87c2

	transform(value: any, args?: any): any {
		const numberValue = parseFloat(value);
		if (numberValue === null) {
			return null;
		}

		if (numberValue === 0) {
			return "0";
		}

		const fractionSize = 1;
		let abs = Math.abs(numberValue);
		const rounder = Math.pow(10, fractionSize);
		const isNegative = numberValue < 0;
		let key = '';
		const powers = [{ key: "Q", value: Math.pow(10, 15) }, { key: "T", value: Math.pow(10, 12) }, { key: "B", value: Math.pow(10, 9) }, { key: "M", value: Math.pow(10, 6) }, { key: "K", value: 1000 }];

		for (let i = 0; i < powers.length; i++) {
			let reduced = abs / powers[i].value;
			reduced = Math.round(reduced * rounder) / rounder;
			if (reduced >= 1) {
				abs = reduced;
				key = powers[i].key;
				break;
			}
		}
		return (isNegative ? '-' : '') + abs + key;
	}
}

@Pipe({
	name: 'customNumberDecimalSuffixPipe'
})
export class CustomNumberDecimalSuffixPipe implements PipeTransform {
	// https://stackoverflow.com/questions/48583020/display-number-in-million-or-thousand-format-in-angular-4

	transform(value: any, args?: any): any {
		let exp;
		const suffixes = ['K', 'M', 'B', 'T', 'Q', 'E'];
		const number = value;
		const isNegative = number < 0;
		if (Number.isNaN(number)) {
			return null;
		}

		const absNumber = Math.abs(number);
		if (absNumber >= 0 && absNumber < 1000) {
			return (isNegative ? '-' : '') + absNumber.toFixed(2);
		}
		exp = Math.floor(Math.log(absNumber) / Math.log(1000));
		return (isNegative ? '-' : '') + (absNumber / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
	}
}

@Pipe({
	name: 'getToFixedTruncPipe'
})
export class GetToFixedTruncPipe implements PipeTransform {
	/* Input Value: 14.2265 and TruncBy : 8 and isString : true
		Output will be : 14.22650000
	*/
	/* Input Value: 14.2265 and TruncBy : 8 and isString : false
		Output will be : 14.2265
	*/
	transform(value: any, truncBy?: number, isString?: boolean): any {
		let x = value;

		const v = (typeof x === 'string' ? value : value.toString()).split('.');
		if (truncBy <= 0) return v[0];
		let f = v[1] || '';
		if (f.length > truncBy) return `${v[0]}.${f.substr(0, truncBy)}`;
		while (f.length < truncBy) f += '0';
		if (isString) {
			return `${v[0]}.${f}`
		} else {
			Number(v[0] + '.' + f);
		}
	}
}


@Pipe({
	name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

	transform(val: number): string {
		if (val !== undefined && val !== null) {
			// here we just remove the commas from value
			return val.toString().replace(/,/g, "");
		} else {
			return "";
		}
	}
}

@Pipe({
	name: 'customNumberNoRoundingPipe'
})
export class CustomNumberNoRoundingPipe implements PipeTransform {
	private decimalPoint: number;

	constructor(private _LoginService: LoginService) {
		this.decimalPoint = this._LoginService.loginUser.account_detail.app_settings.decimal_point || 0;
	}

	private getMultiplier(digits) {
		let multiplier = 1;
		for (let i = 0; i < digits; i++) {
			multiplier = multiplier * 10;
		}
		return multiplier;
	}

	transform(numberValue: number, customDecimalPlace?: number): any {
		const customDecimalVal = customDecimalPlace >= 0 ? customDecimalPlace : this.decimalPoint;
		return (Math.floor(numberValue * this.getMultiplier(customDecimalVal)) / this.getMultiplier(customDecimalVal)).toFixed(customDecimalVal);	
	}
}
