<div class="numberBlock hoverClr" (click)="tilesClick('TASKS')">
	<span class="contractIcon">
		<span class="icon-tasks_1 icon-set1"></span>
	</span>
	<div class="blockText">
		<strong>
			<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
			<span *ngIf="!resourcesLoaded">{{quantityOfTasks || 0 | customNumberSuffixPipe}}</span>
		</strong>
		<span class="blockDate">Active</span> Tasks
	</div>
</div>