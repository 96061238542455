import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { ProductServiceService } from '@app/services/product-service/product-service.service';
import { environment } from "@environments/environment";
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { CurrencyPipe } from '@angular/common';
import { FilterFromListPipe, GetPriceFromPricelistPipe } from '@app/pipes/filter-from-list.pipe';
import { PAGE_SIZE_OPTIONS, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import DataSource from 'devextreme/data/data_source';
import { PageOptions } from '@app/models/common.model';
import { Observable } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-pricelist-approval-trails',
	templateUrl: './pricelist-approval-trails.component.html',
	styleUrls: ['./pricelist-approval-trails.component.css'],
	providers: [CurrencyPipe, GetPriceFromPricelistPipe]
})
export class PricelistApprovalTrailsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() priceListDetailsIP: any;
	@Input() listOfApproverUsersIP: any;
	@Input() refreshApprovalTrailIP: any;
	private pricelistDetailsObj: any;
	public listOfApprovalTrails: any;
	public listOfApproverUsers: any[] = [];
	public listOfApproverUsersHeaderFilter: any[] = [];
	public listOfPricelistStatuses: any[] = [];
	@ViewChild('pricelistApprovalTrailsDxListRef') pricelistApprovalTrailsDxListRef: DxListComponent;

	public filterValue: any[] = [];
	public storedFiltersValue: any = [];
	public pageOptions: PageOptions = new PageOptions();
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string;
	public listOfActions: any[] = [];


	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _FilterFromListPipe: FilterFromListPipe,
		private _ProductServiceService: ProductServiceService) {
	}

	ngOnInit() {
		const listOfStatuses = this._ProductServiceService.getListOfStatusForPricelist();
		this.listOfPricelistStatuses = listOfStatuses;
		this.listOfActions = this._ProductServiceService.getListOfActionsForAppovalTrailsTab();
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['priceListDetailsIP']) {
			if (_SimpleChanges.priceListDetailsIP.currentValue) {
				this.pricelistDetailsObj = _SimpleChanges.priceListDetailsIP.currentValue;
				this.getApprovalTrails();
			}
		}
		if (_SimpleChanges['refreshApprovalTrailIP']) {
			if (_SimpleChanges.refreshApprovalTrailIP.currentValue) {
				this.doRefreshDataGrid();
			}
		}
		if (_SimpleChanges['listOfApproverUsersIP']) {
			const listOfUsers = _SimpleChanges.listOfApproverUsersIP.currentValue || [];
			this.listOfApproverUsers = listOfUsers;
			this.getListOfApproveUserHeaderFilter();
		}
	}

	ngOnDestroy(): void {

	}


	private getApprovalTrails() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.APPROVALTRAIL,
			trail_for: 'pricelists',
			entity_id: this.pricelistDetailsObj.guid,
			unique_id: this.pricelistDetailsObj.unique_id
		}
		this.listOfApprovalTrails = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	private getListOfApproveUserHeaderFilter() {
		this.listOfApproverUsersHeaderFilter = [];
		this.listOfApproverUsers.map(user => {
			this.listOfApproverUsersHeaderFilter.push({
				value: user.sub_account_id,
				text: user.name
			});
		});
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
				visible: false
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: false,
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: false,
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			}
		);
		let addRowButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (addRowButton) {
			addRowButton.visible = false;
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	// Method used to Refresh DataGrid
	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	private doOpenColumnChooser() {
		this.dataGridContainer.instance.showColumnChooser();
	}

	// Method used to Export Excelsheet for Vendor,Buying Groups,End Users
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Price-List-Approval-Trails.xlsx';
		worksheet = workbook.addWorksheet('Price-List-Approval-Trails');
		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'from_status' || gridCell.column.dataField === 'to_status') {
						excelCell.value = this._FilterFromListPipe.transform(gridCell.value, this.listOfPricelistStatuses, 'value', 'text');
						const colorName = this._FilterFromListPipe.transform(gridCell.value, this.listOfPricelistStatuses, 'value', 'color_code');;
						excelCell.font = { color: { argb: colorName } };
					}
					if (gridCell.column.dataField === 'approvers') {
						if (gridCell.value && gridCell.value.length > 0) {
							excelCell.value = this._FilterFromListPipe.transform(gridCell.value[0], this.listOfApproverUsersHeaderFilter, 'value', 'text');
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.doOpenColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {

	}
}