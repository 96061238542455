import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminportalComponent } from './../adminportal/adminportal.component';
import { ApDashboardComponent } from './../adminportal/ap-dashboard/ap-dashboard.component';
import { AuthGuardService } from '../services/auth-guard.service';


const routes: Routes = [{
	path: '',
	component: AdminportalComponent,
	children: [
		{
			path: 'dashboard',
			component: ApDashboardComponent,
			canActivate: [AuthGuardService],
			data: {
				title: 'Admin Dashboard',
				breadcrumb: [
					{
						label: 'Admin Dashboard',
						url: ''
					}
				]
			}
		},
		{
			path: 'customers',
			loadChildren: () => import('./ap-customers/ap-customers.module').then(m => m.ApCustomersModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'contracts',
			loadChildren: () => import('./ap-contracts/ap-contracts.module').then(m => m.ApContractsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'subscriptionplans',
			loadChildren: () => import('./ap-subscription-plans/ap-subscription-plans.module').then(m => m.ApSubscriptionPlansModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'crms',
			loadChildren: () => import('./ap-crms/ap-crms.module').then(m => m.ApCrmsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'sales',
			loadChildren: () => import('./ap-sales/ap-sales.module').then(m => m.ApSalesModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'usermanagements',
			loadChildren: () => import('./ap-usermanagements/ap-usermanagements.module').then(m => m.ApUsermanagementsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'apps-configs',
			loadChildren: () => import('./ap-application-configurations/ap-application-configurations.module').then(m => m.ApApplicationConfigurationsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: '',
			redirectTo: 'dashboard',
			pathMatch: 'full',
		}
	]
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AdminportalRoutingModule { }
