<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitleText" [showCloseButton]="true" [(visible)]="isShowPopup"
	(onHidden)="doHiddenPopup($event)" [contentTemplate]="currentContentTemplate"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>

	<div *dxTemplate="let data of 'showUploadFile'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="forCsv mrgT10">
				<div class="row">
					<div class="cols12">
						<span class="highlight">
							<span class="mrgR10">Note: </span>
							Column titles should be
							<span *ngIf="contractType === 'individual' || contractType === 'growth_incentive'">
								<span *ngIf="rebateType === 'purchasing'">
									<i>item</i> and <i>uom</i> and <i>rebate</i>.
								</span>
								<span *ngIf="rebateType === 'sales'">
									<span *ngIf="productSearchItemType === 'item'">
										<i>item</i> and <i>uom</i> and <i>rebate</i>.
									</span>
									<span *ngIf="productSearchItemType === 'price'">
										<i>item</i> and <i>uom</i> and <i>price/rebate</i>.
									</span>
								</span>
							</span>
							<span *ngIf="contractType === 'buyinggroup'">
								<i>member</i> and <i>item</i> and <i>uom</i> and <i>rebate</i>.
							</span>
						</span>
					</div>
				</div>
				<div class="row">
					<div class="cols12 colDrag">
						<div class="center">
							<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="doOnFileDrop($event)"
								[showBrowseBtn]="false" accept="csv" [multiple]="false" class="fileUploadInput">
								<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
									<div class="csvUpload">
										<span class="icon-upload"></span>
										<h4>Drag & Drop XLS | XLSX | CSV File Here</h4>
										<span class="orStyle">OR</span>
										<button type="button" (click)="openFileSelector()" class="addFileInput"><span
												class="addIcon"></span>
											Browse Files</button>
										<span class="supprtInst">Supported up to 25 MB</span>
									</div>
								</ng-template>
							</ngx-file-drop>
							<!-- <input type="file" (change)="changeListener($event)" #input
											accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
						</div>

						<div *ngIf="uploadedCsvFilesData">
							<div class="fileImage">
								<span class="icon-csv_file"></span>
							</div>
							<div class="fileDetail">
								<h4>{{uploadedCsvFilesData?.name }}</h4>
								<div class="fileProgress">
									<span></span>
								</div>
								<div class="fileProgText">
									{{ uploadedCsvFilesData?.size }} bytes
									<!-- <span>100% uploaded</span> -->
								</div>
								<span class="sprite closeIcon" (click)="doRemoveUploadFiles()"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>

	<div *dxTemplate="let data of 'showIgnoredItemList'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="tabbing cf mrgT10">
				<ul>
					<li (click)="doChangeTab('ignored_item')" [ngClass]="{active: currentTab == 'ignored_item'}">
						<a>Ignored Item</a>
					</li>
					<li (click)="doChangeTab('incorrect_uoms')" [ngClass]="{active: currentTab == 'incorrect_uoms'}">
						<a>Incorrect UOM(s)</a>
					</li>
					<li (click)="doChangeTab('ignored_price_rebate')"
						[ngClass]="{active: currentTab == 'ignored_price_rebate'}">
						<a>Price / Rebate Ignored</a>
					</li>
				</ul>
			</div>
			<div *ngIf="isShowDatagrid">
				<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="dxDataSource" [showBorders]="true"
					keyExpr="guid" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'" [columnAutoWidth]="true"
					[width]="'100%'">
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false" [showRelevantValues]="false"></dxo-header-filter>
					<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
					</dxo-pager>

					<dxi-column caption="Member" dataField="member" [visible]="contractType === 'buyinggroup'"
						dataType="string" cellTemplate="cellTemplateMember">
						<div *dxTemplate="let element of 'cellTemplateMember'" class="">
							{{element.data.member || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="Item" dataField="item" dataType="string" cellTemplate="cellTemplateItemName">
						<div *dxTemplate="let element of 'cellTemplateItemName'" class="">
							{{element.data.item || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="Description" dataField="description" dataType="string"
						[visible]="currentTab === 'incorrect_uoms' || currentTab === 'ignored_price_rebate'"
						cellTemplate="cellTemplateDescription">
						<div *dxTemplate="let element of 'cellTemplateDescription'" class="">
							{{element.data.description || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="UOM" dataField="uom" [allowFiltering]="false" [allowEditing]="false"
						cellTemplate="cellTemplateUom">
						<div *dxTemplate="let element of 'cellTemplateUom'">
							<span *ngIf="element.data.uom">{{element.data.uom.toString() || '' | uppercase}}</span>
						</div>
					</dxi-column>

					<dxi-column caption="Default UOM" dataField="isItemModifiedUom"
						[visible]="currentTab == 'incorrect_uoms'" [allowFiltering]="true" [allowEditing]="false"
						cellTemplate="cellTemplateItemUomModified">
						<div *dxTemplate="let element of 'cellTemplateItemUomModified'">
							<span *ngIf="element.data.isItemModifiedUom">{{element.data.isItemModifiedUom.toString() || '' |
								uppercase}}</span>
						</div>
					</dxi-column>

					<dxi-column caption="Price"
						[visible]="(contractType === 'individual' || contractType === 'growth_incentive') && productSearchItemType === 'price'"
						dataField="price" dataType="number" [allowFiltering]="true" [allowEditing]="false">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplatePrice'">
							<span *ngIf="element.data.price >= 0">
								{{element.data.price || 0 | customCurrencyPipey}}
							</span>
						</div>
					</dxi-column>

					<dxi-column caption="Rebate" dataField="rebate" dataType="number" [allowFiltering]="true"
						[allowEditing]="false">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplateRebatePrice'">
							<span *ngIf="element.data.rebate >= 0">
								{{element.data.rebate || 0 | customCurrencyPipe}}
							</span>
						</div>
					</dxi-column>

				</dx-data-grid>
			</div>
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="after" [visible]="actionFor === 'showUploadFile'" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button class="primaryBtn button" text="Upload" (onClick)="doUploadCsvFile()" [useSubmitBehavior]="false">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>