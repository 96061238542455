import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { ApDashboardService } from '@app/services/ap-services/ap-dashboard-service/ap-dashboard.service';

@Component({
	selector: 'app-ap-active-customer-tiles',
	templateUrl: './ap-active-customer-tiles.component.html',
	styleUrls: ['./ap-active-customer-tiles.component.css']
})

export class ApActiveCustomerTilesComponent implements OnInit, OnDestroy {
	public activeCustomers: number;
	public resourcesLoaded: boolean = false;
	private getActiveCustomerSbsn: Subscription;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _ApDashboardService: ApDashboardService) {

	}

	ngOnInit() {
		this.getActiveCustomer();
	}

	ngOnDestroy(): void {
		if (this.getActiveCustomerSbsn) {
			this.getActiveCustomerSbsn.unsubscribe();
		}
	}

	// Method used to Get Active Customer
	private getActiveCustomer() {
		this.resourcesLoaded = true;
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getDashboardData');
		formData.append('section', 'activeCustomersTile');
		if (this.getActiveCustomerSbsn) {
			this.getActiveCustomerSbsn.unsubscribe();
		}
		this.getActiveCustomerSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this.resourcesLoaded = false;
			if (response.flag) {
				if (response.data.tiles) {
					this.activeCustomers = response.data.tiles.active_clients || 0;
				}
			}
		}, error => {
			this.resourcesLoaded = false;
		});
	}

	// Method used to Click to redirect on appropriate page
	public tilesClick(clickFor: string) {
		this._ApDashboardService.tilesClick(clickFor);
	}
}
