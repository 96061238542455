import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { MinMaxChargebackStatusValEnum } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Observable, Subject, Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Router } from '@angular/router';
import { ProductServiceService } from '@app/services/product-service/product-service.service';
import { FilterFromListPipe } from '@app/pipes/filter-from-list.pipe';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';

@Component({
	selector: 'app-min-max-chargeback-claim-approvals-listing',
	templateUrl: './min-max-chargeback-claim-approvals-listing.component.html',
	styleUrls: ['./min-max-chargeback-claim-approvals-listing.component.css']
})
export class MinMaxChargebackClaimApprovalsListingComponent implements OnInit {
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public approvalsDataSource: any = {};
	public pageOptions: PageOptions = new PageOptions();
	public guid = new Guid();
	private dataInReqSubscription: Subscription;
	private updateUserSettingSbsn: Subscription;
	public isViewApprovalPopUp: boolean = false;

	private isCellClickDetail: boolean = false;
	@ViewChild("endUserChargeBacDocumentDataGridContainer", {}) endUserChargeBacDocumentDataGridContainer: DxDataGridComponent;
	public filterValue: any[] = [];
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public storedFiltersValue: any;
	public stateStorageKey: string;
	public mainSectionStorageKey: string;
	public listOfStatuses: any[] = this._MinMaxEndUsersChargebackContractService.doGetListofClaimApprovalStatus();
	public listOfWorkflowTypes: any[] = [];
	public listOfWorkflowTypesHeaderFilters: any[] = [];
	private getWorkflowTypeSbsn: Subscription;
	public listOfActions = [
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}
	];
	public decimalPointForCurrency: number;
	constructor(
		public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _Router: Router,
		private _FilterFromListPipe: FilterFromListPipe,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService

	) {
		this.doLoadApproveNotificationState = this.doLoadApproveNotificationState.bind(this);
		this.doSaveApproveNotificationState = this.doSaveApproveNotificationState.bind(this);
	}

	ngOnInit(): void {
		this.preInIt();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.getWorkflowTypeSbsn) {
			this.getWorkflowTypeSbsn.unsubscribe();
		}
	}

	private preInIt() {
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.stateStorageKey = ServerSections.MIN_MAX_CHARGEBACK_CLAIM_APPROVE_NOTIFICATION;
		this.mainSectionStorageKey = ServerSections.NOTIFICATIONS;
		this.fetchListOfWorkflowTypes();
		this.storedFiltersValue = [
			{ dataType: 'string', dataField: 'approval_status', searchMode: '=', filterValues: [MinMaxChargebackStatusValEnum.PENDING_APPROVAL] },
		];
		this.applyStoredFilter();
		this.getApprovalList();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
			this.updateStatesClearBtn();
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.filterText = '';
						this.isDisabledClearBtn = true;
						this.updateStatesClearBtn();
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);

		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {

	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Min_Max_Chargeback_Claim_Approval_List.xlsx';
		worksheet = workbook.addWorksheet('Min-Max Chargeback Claim Approval List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'subtype') {
						excelCell.value = this._FilterFromListPipe.transform(gridCell.value, this.listOfWorkflowTypes, 'value', 'name');
					}
					if (gridCell.column.dataField === 'approval_status') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = this._FilterFromListPipe.transform(gridCell.value, this.listOfStatuses, 'value', 'text');
						colorName = this._FilterFromListPipe.transform(gridCell.value, this.listOfStatuses, 'value', 'color_code');
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						if (sf.filterValues) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
						}
					}
				});
			}, 75);
		}

	}

	// Method used to Fetch list of Task types
	private fetchListOfWorkflowTypes() {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.WORKFLOWTYPES);
		if (this.getWorkflowTypeSbsn) {
			this.getWorkflowTypeSbsn.unsubscribe();
		}
		this.listOfWorkflowTypes = [];
		this.getWorkflowTypeSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					this.listOfWorkflowTypes = response.data || [];
					this.listOfWorkflowTypesHeaderFilters = this.listOfWorkflowTypes.map(wft => {
						return { value: wft.value, text: wft.name };
					});
				}
			},
			error: (error) => {
			}
		});
	}

	private getApprovalList() {
		const viewFields = [];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.APPROVALS,
			type: 'minmaxenduserschargebackcontracts',
			// viewFields: JSON.stringify(viewFields),
		};
		this.approvalsDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		// this.isCellClickDetail = false;
		// if (e.column.dataField === "claimedStatus") {
		// 	this.isCellClickDetail = true;
		// 	return;
		// }
	}

	public doMinMaxChargebackClaimApproverDetails(event: any) {
		const rowData = event.data;
		this._MinMaxEndUsersChargebackContractService.setMinMaxChargebackClaimApprovalDetails(rowData);
		this._Router.navigate(["notifications/approvals/minmaxchargebackapprovaldetails"]);
	}

	//#region load state and save state
	public doLoadApproveNotificationState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				//this.filterValue = newOrExistingConfigClone.configs[configIndex].state_persistent['filterValue'];
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveApproveNotificationState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}
	//#endregion

}

