import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // console.log('ErrorInterceptorService: ', request);
      // if (err.status === 401) {
      // auto logout if 401 response returned from api
      // this.authenticationService.logout();
      //location.reload(true);
      // this.toastr.error("Your session has beed expired.");
      // this.router.navigate(['auth/login']);
      // }
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          console.log('this should print your error!', err.error);
        }
      }
      const error = err.error || err.statusText;
      return throwError(error);
    }))
  }
}
