import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackChargebackService } from "@app/services/contract-service/billback-chargeback/billback-chargeback.service";
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
import { DistributionChargebackContractService } from '@app/services/contract-service/billback/distribution-chargeback-contract.service';
import { Guid } from '@app/models/guid';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';

@Component({
	selector: 'app-billback-chargeback-contract-listing',
	templateUrl: './billback-chargeback-contract-listing.component.html',
	styleUrls: ['./billback-chargeback-contract-listing.component.css']
})

export class BillbackChargebackContractListingComponent implements OnInit, OnDestroy {
	//#region Local Variable
	@Input() actionFrom: string;
	@Input() clientGuid: string;
	@Input() clientDetailsIP: any;
	private clientDetails: any;

	@ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public allPermissions: any[] = [];
	public pageOptions: PageOptions = new PageOptions();
	public dataInReqSubscription: Subscription;
	public dataOutReqSubscription: Subscription;
	public billbackChargebackPermission: string = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
	public storedFiltersValue: any;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'In Active' }];
	private instanceClearBtn: any = null;
	private isDisabledClearBtn: boolean = true;
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	public listContractType: any[] = [
		{ value: 'billbackcontracts', text: 'Billback' },
		{ value: 'chargebackcontracts', text: 'Chargeback' },
		{ value: 'enduserschargebackcontracts', text: 'End-User Chargeback' },
		{ value: 'distributionchargebackcontracts', text: 'Distribution Chargeback' },
		{ value: 'minmaxenduserschargebackcontracts', text: 'Min/Max Chargeback' },
	];
	@ViewChild(DxDataGridComponent, {}) billbackChargebackDataGridContainer: DxDataGridComponent;
	public billbackChargebackContractDataSource: any;
	private guid = new Guid();

	public viewFields = ['guid', 'ixcode', 'contractName', 'startDate', 'endDate', 'is_active', 'contractType', 'selectCustomerId', 'look_back_period'];
	public isShowIsActiveColumn: boolean = true;
	public isShowContractTypeColumn: boolean = true;
	private isShowFilteredText = true;
	private isShowClearBtn = true;
	private isShowFilterBtn = true;
	public listOfStatuses: any[] = this._AppCommonSrvc.doGetBooleanStatusList();
	private isCellClickDetail: boolean = false;
	private updateUserSettingSbsn: Subscription;
	public listOfActions: any[];
	public isShowApproveMultiChargebackClaim: boolean = false;
	public ApproveMultiChargebackClaimProps: any;
	public endUserViewDetailRptProps: any;
	public enable_chargeback_look_back_period: boolean = false;
	public isShowViewDetailsPopUp: boolean = false;
	//#endregion

	//#region Constructor
	constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute,
		public _AppCommonSrvc: AppCommonSrvc,
		private _BillbackChargebackService: BillbackChargebackService,
		private _DistributionChargebackContractService: DistributionChargebackContractService,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _LoaderService: LoaderService,
		private _BillbackContractService: BillbackContractService,
		private _ChargebackContractService: ChargebackContractService,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,
	) {
		this.doLoadBillbackChargebackAgreementsState = this.doLoadBillbackChargebackAgreementsState.bind(this);
		this.doSaveBillbackChargebackState = this.doSaveBillbackChargebackState.bind(this);
	}
	//#endregion

	//#region Angular Life Cycle Methods

	ngOnInit() {
		this.preInIt();
	}


	ngOnChanges(changes: SimpleChanges) {
		if (changes.clientDetailsIP) {
			if (changes.clientDetailsIP.currentValue) {
				this.clientDetails = changes.clientDetailsIP.currentValue;
			}
		}
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	//#endregion

	//#region billback List Methods
	public doChangedBillBackContractStatusAction(data) {
		const billBackGuid = data.guid;
		const is_active = !data.is_active;
		const contractType = data.contractType;
		if (this.allPermissions.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			return false;
		}
		// this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		// formData.append('entity', ServerEntity.BILLBACKCONTRACTS);
		formData.append('entity', contractType);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('guid', billBackGuid);
		formData.append('updateData', JSON.stringify({ 'is_active': is_active }));
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response.flag) {
					this.loadBillbackChargebackContractDataSource();
				} else {
					this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				// this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public addNewBillbackContract() {
		this._BillbackContractService.setActionFrom(this.actionFrom);
		if (this.actionFrom === 'billback_chargeback') {
			this._BillbackContractService.setEditBillbackGuid('');
			this._Router.navigate(['/contracts/billbackchargeback/billback-contract/']);
		}
		if (this.actionFrom === 'crms') {
			this._BillbackContractService.setEditBillbackGuid('');
			this._Router.navigate(['billbackchargeback/chargeback-contract/'], { relativeTo: this._ActivatedRoute });
		}
		/*
		this._Router.navigate(['/contracts/billbackchargeback/billback-contract/']);
		this._BillbackContractService.setEditBillbackGuid('');
		*/
		//this._Router.navigate(['/contracts/billback/']);
		//this._Router.navigate(['/contracts/billback/chargeback-contract/']);	
		// this._Router.navigate(['/contracts/billback/billback-contract/']);
		// this._Router.navigate(['/contracts/billback/end-users-chargeback-contract/']);
		// this._Router.navigate(['/contracts/billback/distribution-chargeback-contract/']);
	}

	public doEditBillbackContract(data) {
		if (this.isCellClickDetail) {
			return;
		}
		if (!data.is_active) {
			return false;
		}
		this._BillbackContractService.setActionFrom(this.actionFrom);
		switch (data.contractType) {
			case 'billbackcontracts':
				this._BillbackContractService.setEditBillbackGuid(data.guid);
				this._Router.navigate(['/contracts//billbackchargeback/billback-contract/']);
				break;
			case 'chargebackcontracts':
				this._ChargebackContractService.setEditChargebackGuid(data.guid);
				if (this.actionFrom === 'crms') {
					this._Router.navigate(['billbackchargeback/chargeback-contract/'], { relativeTo: this._ActivatedRoute });
					// this._Router.navigate(['/contracts/billbackchargeback/chargeback-contract/']);
				}
				if (this.actionFrom === 'billback_chargeback') {
					this._Router.navigate(['/contracts/billbackchargeback/chargeback-contract/']);
				}
				break;
			case 'enduserschargebackcontracts':
				this._EndUsersChargebackContractService.setEditEndUsersChargebackGuid(data.guid);
				this._Router.navigate(['/contracts/billbackchargeback/end-users-chargeback-contract/']);
				break;
			case 'distributionchargebackcontracts':
				this._DistributionChargebackContractService.setEditDistributionChargebackGuid(data.guid)
				this._Router.navigate(['/contracts/billbackchargeback/distribution-chargeback-contract/']);
				break;
			case 'minmaxenduserschargebackcontracts':
				this._MinMaxEndUsersChargebackContractService.setEditMinMaxEndUsersChargebackGuid(data.guid)
				this._Router.navigate(['/contracts/billbackchargeback/min-max-end-users-chargeback-contract/']);
				break;
		}
		// this._Router.navigate(['/contracts/billback/billback-contract/']);
		// this._Router.navigate(['/contracts/billback/end-users-chargeback-contract/']);
		// this._Router.navigate(['/contracts/billback/distribution-chargeback-contract/']);
	}

	private loadBillbackChargebackContractDataSource() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.BILLBACK_CHARGEBACK_CONTRACTS,
			view_fields: JSON.stringify(this.viewFields)
		}
		this.billbackChargebackContractDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}
	//#endregion


	// #region for Operation for Datagrid

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
				visileb: this.isShowFilteredText
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: this.isShowClearBtn,
				options: {
					icon: "cleariconix",
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: this.isShowFilterBtn,
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'ADD':
					this.doAddContract();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
				case 'APPROVE_MULTI_MIN_MAX_CHARGEBACK_CLAIM':
					this.doApproveMultiChargebackClaim();
					break;
				case 'VIEW_DETAILS':
					this.isShowViewDetailsPopUp = false;
					setTimeout(() => {
						this.isShowViewDetailsPopUp = true;
					},);
					break;
			}
		}
	}

	public doApproveMultiChargebackClaim() {
		this.isShowApproveMultiChargebackClaim = true;
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		}
	}

	private doAddContract() {
		this.addNewBillbackContract();
	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Billback_Chargeback_Contract_List.xlsx';
		worksheet = workbook.addWorksheet('Billback_Chargeback_List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'settlementPeriod') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value === 'halfYearly' ? 'Bi-annually' : gridCell.value === 'yearly' ? 'Annually' : gridCell.value.toString().charAt(0).toUpperCase() + gridCell.value.toString().slice(1);
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
					if (gridCell.column.dataField === 'contractType') {
						excelCell.value = gridCell.value === 'billback' ? 'Billback' : gridCell.value === 'chargeback' ? 'Chargeback' : gridCell.value === 'distributionchargeback' ? 'Distribution Chargeback' : 'End-User Chargeback'
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion

	// #region for Init Tab and load data.
	private setAllPermissions() {
		this.allPermissions = [];
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
	}

	// Method used to Init Tab and load data.
	private preInIt() {
		this.setAllPermissions();
		this.listOfActions = this._BillbackChargebackService.getListOfActionsForDatagrid() || [];
		const enable_min_max_claim: boolean = this._LoginService.loginUser.account_detail.hasOwnProperty('enable_min_max_claim') ? this._LoginService.loginUser.account_detail.enable_min_max_claim : false;
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail) {
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_chargeback_look_back_period')) {
				this.enable_chargeback_look_back_period = this._LoginService.loginUser.account_detail.enable_chargeback_look_back_period
			}
			if (this.actionFrom === 'crms' || !enable_min_max_claim) {
				const indexItem = this.listOfActions.findIndex(i => i.btn_code === 'APPROVE_MULTI_MIN_MAX_CHARGEBACK_CLAIM');
				if (indexItem !== -1) {
					this.listOfActions.splice(indexItem, 1);
				}
			}
		}
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);

		if (this.actionFrom === 'billback_chargeback') {
			this.mainSectionStorageKey = ServerSections.INCENTIVES_LISTING_SETTING;
			this.stateStorageKey = ServerSections.INCENTIVES_BILLBACK_CHARGEBACK_LISTING;
			this.doDefaultFilter();
			this.applyStoredFilter();
			this.preInitForBillbackChargeback();
		}
		if (this.actionFrom === 'crms') {
			this.mainSectionStorageKey = ServerSections.CRM_CUSTOMER_DETAILS_LISTING_SETTING;
			this.stateStorageKey = ServerSections.CRM_CUSTOMER_DETAILS_BILLBACK_CHARGEBACK_LISTING;
			this.doFiltersForCrm();
			this.applyStoredFilter();
			this.preInitForCrm();
		}
		this.loadBillbackChargebackContractDataSource();
	}

	private preInitForCrm() {
		this.viewFields = ['guid', 'ixcode', 'contractName', 'startDate', 'endDate', 'is_active', 'contractType', 'selectCustomerId', 'look_back_period'];
		this.isShowContractTypeColumn = false;
		this.isShowFilteredText = false;
		this.isShowClearBtn = false;
		this.isShowFilterBtn = false;
		this.setCommonColumnHideShow();
	}


	private doFiltersForCrm() {
		this.storedFiltersValue = [
			{
				dataType: 'string', dataField: 'is_active', searchMode: 'contains', filterValues: [true]
			},
			{
				dataType: 'string', dataField: 'contractType', searchMode: 'contains', filterValues: ['chargebackcontracts']
			},
			{
				dataType: 'string', dataField: 'selectCustomerId', searchMode: 'contains', filterValues: [this.clientDetails.code]
			}
		];
	}


	private preInitForBillbackChargeback() {
		this.viewFields = ['guid', 'ixcode', 'contractName', 'startDate', 'endDate', 'is_active', 'contractType', 'selectCustomerId', 'look_back_period'];
		this.isShowContractTypeColumn = true;
		this.isShowFilteredText = true;
		this.isShowClearBtn = true;
		this.isShowFilterBtn = true;
		this.setCommonColumnHideShow();
	}


	private doDefaultFilter() {
		this.storedFiltersValue = [{ dataType: 'string', dataField: 'is_active', searchMode: 'contains', filterValues: [true] }];
	}

	private setCommonColumnHideShow() {
		this.isShowIsActiveColumn = true;
		if ((this.allPermissions.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1)) {
			this.isShowIsActiveColumn = false;
		}
	}
	//#endregion

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatuses.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatuses[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		this.isCellClickDetail = false;
		if (e.column.dataField === "is_active") {
			this.isCellClickDetail = true;
			return;
		}
	}

	public getListOfStatus(data) {
		this.listOfStatuses.forEach(action => {
			action.label = action.label.toUpperCase();
		});
		this.listOfStatuses.forEach(action => {
			if (action.value === data.is_active) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	// #region for Datagrid State Storing functionality

	public doLoadBillbackChargebackAgreementsState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveBillbackChargebackState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// #endregion

	public doCloseApproveMultiChargebackClaimPopup() {
		this.isShowApproveMultiChargebackClaim = false;
	}
}
