import { environment } from '@environments/environment';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_XLSXFILEPOPUPIX, PAGE_SIZE_OPTIONS, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { ExclusionTabModel, ExclusionTierbaseTabModel } from '@app/models/royalties-contract.model';
import { RoyaltiesService } from '@app/services/contract-service/royalties/royalties.service';
import { DataService } from '@app/services/data.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Guid } from '@app/models/guid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { PageOptions } from '@app/models/common.model';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';

@Component({
	selector: 'app-royalties-exclusions-tab',
	templateUrl: './royalties-exclusions-tab.component.html',
	styleUrls: ['./royalties-exclusions-tab.component.css'],
	providers: [
		CustomCurrencyPipe,
		DecimalPipe,
		CustomNumberNoRoundingPipe
	]
})
export class RoyaltiesExclusionsTabComponent implements OnInit, OnChanges, OnDestroy {
	public guid = new Guid();
	@Input() exclusionTabList: any = [];
	@Output() saveRoyaltiesExclusionEvent = new EventEmitter<string>();
	@Output() addToDatagridUploadedMatchedItemsExclusionEvent = new EventEmitter<any>();
	@ViewChild("customerDropDown", {}) customerDropDown: DxDropDownBoxComponent;
	@ViewChild("groupDropDown", {}) groupDropDown: DxDropDownBoxComponent;
	@ViewChild("userListDetailssDataGridRef", {}) userListDetailssDataGridRef: DxDataGridComponent;
	@ViewChild("exclusionTabDataGridRef", {}) exclusionTabDataGridRef: DxDataGridComponent;
	@ViewChild("tiersItemDetailssDataGridRef", {}) tiersItemDetailssDataGridRef: DxDataGridComponent;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'Inactive' }];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public productGroupRadioButton: string;
	public erp_type: string;
	public editRoyaltiesGuid: string;
	public getUomLstSbsn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public updateUserSettingSbsn: Subscription;
	public searchTimeout: any;
	public isCurrentTabPermission: string;
	public isDisablePermission: boolean = false;
	public exclusionsGroupRadioButton: string;
	public GroupsList: any = [];
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public selectedCustomerData: any = [];
	public selectedCustomerGroupData: any = [];
	public selectedProductData: any = [];
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;
	public isAllProductSelectedView: boolean = false;
	public exclusionTabModel = new ExclusionTabModel();
	public dataSourceExclusionTab: any;
	public listOfRoyaltiesBasis: any[] = [];
	public listOfTierBasis: any[] = [];
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public customerOrGroupTab: string = 'customers';
	// public strSelectedItemList: string;
	// public strSelectedCustomerList: string;
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("customerGroupDataGrid", {}) customerGroupDataGrid: DxDataGridComponent;
	@ViewChild("customercustomerGroupDataGrid", {}) customercustomerGroupDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	public chkAllCustomer: boolean = false;
	public chkAllCustomrGroup: boolean = false;
	public chkAllItem: boolean = false;
	public chkMyCustomers: boolean = false;
	public lengthSelectedCustomersAndGroups: any;
	public lengthSelectedCustomers: any;
	public lengthSelectedGroups: any;
	public lengthSelectedItems: any;
	public arrSelectedCustomerList: any = [];
	public arrSelectedItemList: any = [];
	public itemDataSource: any;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public itemGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public itemGridBoxColumns: any;
	public groupDataSource: any;
	public customerGroupGridBoxValue: any[] = [];
	public customerGroupGridBoxColumns: any;
	public arrSelectedCustomerGroupList: any = [];

	public isShowUploadExclusionItemsPopup: boolean;
	public uploadFiles: NgxFileDropEntry[] = [];
	public uploadedCsvFilesData: any;
	private headerByItemOrPrice: string[] = ['customer', 'item', 'rate'];
	public popupWidth: number;
	public popupHeight: number;
	public uploadFilePopupWidth: number;
	public uploadFilePopupHeight: number;
	private importCsvRecordsSbsn: Subscription;
	public isUploadedImportFileExclusionTab: boolean;
	public listOfTotalUploadedItems: any[];
	public listOfUnmatchedUploadedItems: any[];
	public isShowIgnoredItemsListExclusionItemsPopup: boolean;
	public filterText: string;
	public pageOptionsDropDownDatagrid: PageOptions = new PageOptions();
	public _PopupHeightWidth = PopupHeightWidth;
	public decimalPointPercentageFormat: string;
	public globalCurrencySymbol: string;
	public listOfActions: any[] = [
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'IMPORT_EXCEL',
			btn_name: 'Import Excel/Csv',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		},
	];
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	private filterValueChangeSbsn: Observable<any>;
	constructor(private _PermissionsService: NgxPermissionsService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RoyaltiesService: RoyaltiesService,
		private _IxDxAlertService: IxDxAlertService,
		private _CurrencyPipe: CurrencyPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _DecimalPipe: DecimalPipe) {
		this.doDisplayExprCustomerGroupText = this.doDisplayExprCustomerGroupText.bind(this);
		this.doDisplayExprItemsText = this.doDisplayExprItemsText.bind(this);
		this.doLoadRoyaltiesExclusionsAgreeState = this.doLoadRoyaltiesExclusionsAgreeState.bind(this);
		this.doSaveRoyaltiesExclusionsAgreeState = this.doSaveRoyaltiesExclusionsAgreeState.bind(this);
	}

	ngOnInit() {
		this.mainSectionStorageKey = ServerSections.INCENTIVES_ROYALTIES_CONTRACT;
		this.stateStorageKey = ServerSections.INCENTIVES_ROYALTIES_CONTRACT_EXCLUSIONS;
		this.pageOptionsDropDownDatagrid.pageSize = PAGE_SIZE_OPTIONS[PAGE_SIZE_OPTIONS.length - 1];
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.popupHeight = heightWidthRatio.innerHeight;
		this.popupWidth = heightWidthRatio.innerWidth;
		// console.log('DATA :::', this.exclusionTabList);
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			this.dataSourceExclusionTab = this.exclusionTabList;
		}

		// get current user Erp type
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		// check Is QB User (no need to view code of QB user)
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
		// this.customerGroupGridBoxColumns = [
		// 	{ dataField: 'guid', caption: 'Guid', visible: false },
		// 	{ dataField: 'group_name', caption: 'Name' },
		// 	{ dataField: 'user_list', caption: 'List', groupIndex: "0" },
		// ];

		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		// Check Edit Mode
		const editRoyaltiesGuid = this._RoyaltiesService.getEditRoyaltiesGuid();

		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}
		if (permsn.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_COMMISSION_MODIFY';
		}
		// End current permissions
		this.editRoyaltiesGuid = this._RoyaltiesService.getEditRoyaltiesGuid();
		this.GroupsList = this._DataService.getGroups();
		this.clearExclusionAgreementTabDetails();
		this.listOfTierBasis = this._RoyaltiesService.getTierBasis();
		this.listOfRoyaltiesBasis = this._RoyaltiesService.getRoyaltiesBasis();
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadCustomerDataList();
		this.loadCustomerGroupDataList();
		this.loadItemDataList();
		setTimeout(() => {
			if (this.exclusionTabDataGridRef) {
				this.filterValueChangeSbsn = this.exclusionTabDataGridRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterText();
				});
			}
		}, 120);
	}

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges['exclusionTabList']) {
			this.dataSourceExclusionTab = [];
			setTimeout(() => {
				this.dataSourceExclusionTab = simpleChanges['exclusionTabList'].currentValue || [];
			}, 300);
		}
	}

	ngOnDestroy() {
		if (this.importCsvRecordsSbsn) {
			this.importCsvRecordsSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}


	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.popupHeight = heightWidthRatio.innerHeight;
		this.popupWidth = heightWidthRatio.innerWidth;
	}

	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: error => {
				this._LoaderService.hide();

			}
		});
	}

	// #endregion

	//#region for Customer select box
	loadCustomerDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=clients";
		// formdata = formdata + "&is_dropdown=true";	
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "name",
			loadUrl: formdata
		});
	}
	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.arrSelectedCustomerGroupList = [];
			this.customerGridBoxValue = [];

			// this.customerDataGrid.instance.refresh();
			this.chkMyCustomers = false;
			this.chkAllCustomer = false;
			this.chkAllCustomrGroup = false;
			this.lengthSelectedCustomers = '';
			this.lengthSelectedCustomersAndGroups = '';
		}
	}
	checkAllCustomer() {
		if (this.chkAllCustomer) {
			this.chkMyCustomers = false;
		}
		this.setCustomersAndGroupsText();
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }
	}
	onCustomerSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedCustomers = e.selectedRowsData.length + ' Customer(s) Selected';
			this.customerGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomer) {
			this.lengthSelectedCustomers = '';
		}
		this.setCustomersAndGroupsText();
	}

	public doDisplayExprCustomerGroupText(event: any) {
		let listOfTexts: any[] = [];
		if (this.customerOrGroupTab == 'customers') {
			listOfTexts = [];
			if (this.chkAllCustomer) {
				listOfTexts.push('All Customers');
			}
			if (this.arrSelectedCustomerList && this.arrSelectedCustomerList.length > 0) {
				listOfTexts.push(this.arrSelectedCustomerList.length + ' Customer(s)');
			}
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}

	//#endregion

	//#region for Item|Product select box
	loadItemDataList() {
		// const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=items";
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.itemDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "itemname",
			loadUrl: formdata
		});
	}
	onItemValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedItemList = [];
			this.itemGridBoxValue = [];
			this.itemDataGrid.instance.refresh();
			this.chkAllItem = false;
			this.lengthSelectedItems = '';
		}
	}
	checkAllItem() {
		// if (this.chkAllItem) {
		// 	this.lengthSelectedItems = 'All Items Selected';
		// 	this.arrSelectedItemList = [];
		// 	this.itemDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedItems = '';
		// }
		// if (this.chkAllItem) {
		this.setItemSelctedText();
	}
	onItemSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllItem = false;
			//this.lengthSelectedItems = e.selectedRowsData.length + ' Items Selected';
			this.itemGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].erp_primary_key);
			};
		}
		// if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllItem) {
		// 	this.lengthSelectedItems = '';
		// }
		this.setItemSelctedText();
	}

	setItemSelctedText() {
		let lengthSelectedItems = '';
		this.lengthSelectedItems = '';
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			lengthSelectedItems = this.arrSelectedItemList.length + 'Item(s) Selected';
		}

		if (this.chkAllItem) {
			lengthSelectedItems = 'All Item ' + (lengthSelectedItems ? ', ' : '') + (lengthSelectedItems || '');
		}
		this.lengthSelectedItems = lengthSelectedItems;
	}

	public doDisplayExprItemsText(event: any) {
		let listOfTexts: any[] = [];
		if (this.chkAllItem) {
			listOfTexts.push('All Items');
		}
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			listOfTexts.push(this.arrSelectedItemList.length + ' Item(s)');
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}
	//#endregion

	//#region customer Groups select box

	public clickCustomersOrGroupTab(event, customerOrGroupTab: string) {
		this.customerOrGroupTab = customerOrGroupTab;
		if (this.customerDataGrid) {
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}
		// if (customerOrGroupTab === 'groups') {
		// 	this.groupDropDown.instance.open();
		// 	this.customerDropDown.instance.close();
		// } else {
		// 	this.customerDropDown.instance.open();
		// 	this.groupDropDown.instance.close();
		// }
	}

	public checkMyCustomers() {
		if (this.chkMyCustomers) {
			this.chkAllCustomer = false;
		}
		this.setCustomersAndGroupsText();
	}

	loadCustomerGroupDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=usergroups";
		// formdata = formdata + "&is_dropdown=true";	
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.groupDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "group_name",
			loadUrl: formdata
		});
	}

	checkAllCustomerGroup() {
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }
	}

	onCustomerGroupSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedGroups = e.selectedRowsData.length + ' Group(s) Selected';
			this.customerGroupGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGroupGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		// if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomrGroup) {
		// 	this.lengthSelectedGroups = '';
		// }
		this.setCustomersAndGroupsText();
	}

	setCustomersAndGroupsText() {
		let lengthSelectedCustomersAndGroups;
		if (this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers;
		}
		if (this.lengthSelectedGroups) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedGroups;
		}
		if (this.lengthSelectedGroups && this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers + ' And ' + this.lengthSelectedGroups;
		}

		if (this.chkAllCustomer) {
			lengthSelectedCustomersAndGroups = 'All Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkMyCustomers) {
			lengthSelectedCustomersAndGroups = 'My Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkAllCustomrGroup) {
			lengthSelectedCustomersAndGroups = 'All Group ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}
		this.lengthSelectedCustomersAndGroups = lengthSelectedCustomersAndGroups;
	}

	checkAllGroup() {
		this.setCustomersAndGroupsText();
	}

	// #region for Items And Groups Datagrid Methods
	public getUserListByGroupDetails(element) {
		return element.data.user_detail_list || [];
	}
	//#endregion

	public clickExclusionsGroupRadioButton(tab) {
		this.selectProductSearchTabData = [];
		this.isProductSearchTabAllSelected = false;
		this.exclusionTabModel.productname = '';
		if (tab === 'addgroupexclusion') {
			this.selectProductSearchTabData = JSON.parse(JSON.stringify(this.GroupsList));
		}
		this.selectProductSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	public clearExclusionAgreementTabDetails() {
		this.exclusionsGroupRadioButton = 'addproductexclusion';
		this.arrSelectedCustomerList = [];
		this.arrSelectedCustomerGroupList = [];
		this.lengthSelectedCustomersAndGroups = '';
		this.lengthSelectedCustomers = '';
		this.lengthSelectedGroups = '';
		this.lengthSelectedItems = '';
		this.customerGridBoxValue = [];
		this.chkAllCustomer = false;
		if (this.customerDataGrid) {
			this.customerDataGrid.instance.refresh();
			this.customerDataGrid.instance.clearFilter();
		}
		if (this.customerGroupDataGrid) {
			this.customerGroupDataGrid.instance.refresh();
			this.customerGroupDataGrid.instance.clearFilter();
		}
		if (this.customerDropDown) {
			this.customerDropDown.instance.reset();
		}
		this.arrSelectedItemList = [];
		this.itemGridBoxValue = [];
		this.chkAllItem = false;
		if (this.itemDataGrid) {
			this.itemDataGrid.instance.refresh();
			this.itemDataGrid.instance.clearFilter();
		}
		this.chkAllCustomrGroup = false;
		this.chkMyCustomers = false;
	}

	public doOnEditorPreparingExclusionTab(event) {
		// if (event && event['row'] && event['row']['data']) {
		// 	return true;
		// } else {
		// 	return false;
		// }
		if (event && event['row']) {
			const itemObject = event.row.data || [];
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (itemObject.commission_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = itemObject.uomlist;
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			if (event.dataField === 'tier_basis') {
				let listOfTierBasis = this.listOfTierBasis;
				event.editorOptions.dataSource = listOfTierBasis;
			}

			if (event.dataField === 'commission_basis') {
				let listOfRoyaltiesBasis = this.listOfRoyaltiesBasis;
				if (itemObject.allproducts) {
					listOfRoyaltiesBasis = this.listOfRoyaltiesBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}
				event.editorOptions.dataSource = listOfRoyaltiesBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.disabled = true;
				if (itemObject.allproducts) {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.disabled = false;
					}
				} else {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.disabled = false;
					}
				}
			}
		}
	}

	public doOnRowUpdatedExclusionTab(event) {
		if (event.data.commission_basis === 'percentage' || event.data.commission_basis === 'dollar') {
			event.data.selectedUom = '';
		}
		if (event.data.tier_basis === 'notiers') {
			event.data.tiers = [];
		}
		if (event.data.tier_basis !== 'notiers') {
			event.data.rate = null;
		}
	}

	public doOnRowRemovedExclusionTab(event) {
		// console.log('doOnRowRemovedExclusionTab', event);
		this.saveRoyaltiesExclusionEvent.emit('save');
	}

	public doOnonRowPreparedExclusionTab(event) {
		if (event.rowType === "data" && event.data.tier_basis === 'notiers') {
			if (event.rowElement.querySelector(".dx-command-expand") && event.rowElement.querySelector(".dx-command-expand").firstChild && event.rowElement.querySelector(".dx-command-expand").firstChild.classList) {
				event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
				event.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
			}
			const isExpanded = event.component.isRowExpanded(event.key);
			setTimeout(() => {
				if (isExpanded) {
					event.component.collapseRow(event.key);
				}
			}, 20);
		}
	}

	public doOnInitNewRowTiersItmTab(event, element) {
		event.data = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null
		};
		const itemObject = element.data;
		event.data.guid = this.guid.newGuid();
		if (itemObject.tiers && itemObject.tiers.length > 0) {
			// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ? parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1 : parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
			if (itemObject.tier_basis === 'volume' || itemObject.tier_basis === 'revenue') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = mintier;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = mintier;
				}
			}
			// event.data.maxtier = '-';
		} else {
			if (itemObject.tier_basis === 'volume') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = 1;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = 0.01;
				}
			}
		}
	}

	public doOnRowInsertedTiersItmTab(event, element) {
		// console.log('doOnRowInsertedTiersItmTab', event);
		// console.log('doOnRowInsertedTiersItmTab element', element);
		// const itemObject = event.row.data;
		// console.log('itemObject', itemObject);
		this.saveRoyaltiesExclusionEvent.emit('save');
	}

	public doOnEditorPreparingTiersItmTab(event, element) {
		// console.log('doOnEditorPreparingTiersItmTab', event);
		// console.log('doOnEditorPreparingTiersItmTab element', element);
	}

	public doOnRowUpdatedTiersItmTab(event, element) {
		// console.log('doOnRowUpdatedTiersItmTab', event);
		// console.log('doOnRowUpdatedTiersItmTab element', element);
	}

	public doOnRowRemovedTiersItmTab(event, element) {
		this.saveRoyaltiesExclusionEvent.emit('save');
		// console.log('doOnRowRemovedTiersItmTab', event);
		// console.log('doOnRowRemovedTiersItmTab element', element);
	}

	public doOnSavedTiersItmTab(event, element) {
		// console.log('doOnSavedTiersItmTab', event);
		// console.log('doOnSavedTiersItmTab element', element);
		this.saveRoyaltiesExclusionEvent.emit('save');
	}

	public doOnSavedExclusionTab() {
		this.saveRoyaltiesExclusionEvent.emit('save');
	}

	public getTiersByItemDetails(element) {
		return element.data.tiers || [];
	}

	public setCellValueTierBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueRoyaltiesBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueUomColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueRateColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public addExclusionAgreementTabDetails() {
		this.selectedProductData = [];
		this.selectedProductData = this.itemDataGrid ? this.itemDataGrid.instance.getSelectedRowsData() : [];
		if (this.customerDataGrid) {
			this.selectedCustomerData = [];
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = [];
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}

		// const isExistItemIndex = this.exclusionTabList.findIndex(itemEle => itemEle.clientcode === this.exclusionTabModel.clientcode && itemEle.itemcode === this.exclusionTabModel.clientcode);
		if (this.chkAllItem) {
			this.allProductChecked();
		}
		if (this.chkAllCustomer || this.chkMyCustomers) {
			this.allCustomerChecked();
		}
		if (this.chkAllCustomrGroup) {
			this.allCustomerGroupChecked();
		}

		this.doAddExclusionToListTab();
	}

	public allCustomerGroupChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['group_name'] = 'All Groups';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.erp_primary_key = 'All Groups';
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomergroups = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerGroupData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allCustomerChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textCustomerGroup = this.chkAllCustomer ? 'All Customers' : 'My Customers'
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textCustomerGroup;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.erp_primary_key = textCustomerGroup;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allProductChecked() {
		let itemname = '';
		if (this.exclusionsGroupRadioButton === 'addproductexclusion') {
			itemname = 'All Items';
		}
		if (this.exclusionsGroupRadioButton === 'addgroupexclusion') {
			itemname = 'All Groups';
		}
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = '';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.erp_primary_key = itemname;
		exelusionTierbaseModel.itemname = itemname;
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allproducts = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;

		this.selectedProductData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public checkExclusionListDuplicateRecords(item: any) {
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			const indexExcusion = this.exclusionTabList.findIndex(i => i.clientname === item.clientname && i.itemname === item.itemname);
			if (indexExcusion !== -1) {
				return false;
			}
		}
		return true;
	}

	public doAddExclusionToListTab() {
		if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
			this.selectedCustomerData.forEach(customer => {
				this.selectedProductData.forEach(element => {
					let listOfSpecificUoms = [];
					if (!element.allproducts) {
						const priceListNum = customer.priceListNum || '';
						listOfSpecificUoms = this._RoyaltiesService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
					}
					const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
					const defaultTierBasis = this.listOfTierBasis[0].code;
					const defaultRoyaltiesBasis = this.listOfRoyaltiesBasis[0].code;
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
					exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
					exelusionTierbaseModel.clientguid = customer.guid;
					exelusionTierbaseModel.clientcode = customer.code;
					exelusionTierbaseModel.clientname = customer.name;
					exelusionTierbaseModel.allcustomers = customer.allcustomers;
					exelusionTierbaseModel.itemsku = element.itemsku;
					exelusionTierbaseModel.itemOrGroup = itemOrGroup;
					exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
					exelusionTierbaseModel.itemname = element.itemname;
					exelusionTierbaseModel.allproducts = element.allproducts;
					exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
					exelusionTierbaseModel.mycustomers = element.mycustomers;
					exelusionTierbaseModel.description = element.description;
					exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
					exelusionTierbaseModel.commission_basis = defaultRoyaltiesBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
					exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
					exelusionTierbaseModel.selectedUom = !element.allproducts && defaultRoyaltiesBasis === 'dollarperuom' ? defaultSelectedUom : '';
					exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
					exelusionTierbaseModel.rate = null;
					exelusionTierbaseModel.royaltiesfinalprice = defaultListPrice;
					exelusionTierbaseModel.tiers = [];
					//check duplicate validation for exlusion tab list
					if (!this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
						return false;
					}
					this.exclusionTabList.unshift(exelusionTierbaseModel);
					// Latest exlusions save on edit royalties contract
				});
			})
		}

		if (this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0 && this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0) {
			this.selectedCustomerGroupData.forEach(group => {
				this.selectedProductData.forEach(element => {
					let listOfSpecificUoms = [];
					if (!element.allproducts) {
						listOfSpecificUoms = this._RoyaltiesService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element);
					}
					const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
					const defaultTierBasis = this.listOfTierBasis[0].code;
					const defaultRoyaltiesBasis = this.listOfRoyaltiesBasis[0].code;
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
					exelusionTierbaseModel.guid = group.guid + ' - ' + element.erp_primary_key || '';
					exelusionTierbaseModel.clientguid = group.guid;
					exelusionTierbaseModel.clientcode = '';
					exelusionTierbaseModel.clientname = group.group_name;
					exelusionTierbaseModel.allcustomergroups = group.allcustomergroups;
					exelusionTierbaseModel.allcustomers = element.allcustomers;
					exelusionTierbaseModel.itemsku = element.itemsku;
					exelusionTierbaseModel.itemOrGroup = itemOrGroup;
					exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
					exelusionTierbaseModel.itemname = element.itemname;
					exelusionTierbaseModel.allproducts = element.allproducts;
					exelusionTierbaseModel.mycustomers = element.mycustomers;
					exelusionTierbaseModel.description = element.description;
					exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
					exelusionTierbaseModel.commission_basis = defaultRoyaltiesBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
					exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
					exelusionTierbaseModel.selectedUom = !element.allproducts && defaultRoyaltiesBasis === 'dollarperuom' ? defaultSelectedUom : '';
					exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
					exelusionTierbaseModel.rate = null;
					exelusionTierbaseModel.royaltiesfinalprice = defaultListPrice;
					exelusionTierbaseModel.tiers = [];
					//check duplicate validation for exlusion tab list
					if (!this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
						return false;
					}
					this.exclusionTabList.unshift(exelusionTierbaseModel);
					// Latest exlusions save on edit royalties contract
				});
			})
		}

		setTimeout(() => {
			this.dataSourceExclusionTab = this.exclusionTabList;
		}, 50);
		if (this.editRoyaltiesGuid) {
			// this.saveRoyaltiesContract(true);
		}
		this.clearExclusionAgreementTabDetails();
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: "cleariconix",
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.exclusionTabDataGridRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'COLUMN_CHOOSER':
					this.exclusionTabDataGridRef.instance.showColumnChooser();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'IMPORT_EXCEL':
					this.doImportToDatagridExclsnTab();
					break;

			}
		}
	}

	public doActionsBtnClick(event: any) {
		// const addIndex = this.listOfActions.findIndex(i => i.btn_code
		// 	=== 'ADD');
		// if (addIndex !== -1) {
		// 	this.listOfActions[addIndex].disabled = !this.isShowAction;
		// }
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.exclusionTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Royalties_Customers_Items_Groups.xlsx';
		worksheet = workbook.addWorksheet('Customers-Items-Groups');

		exportDataGrid({
			worksheet: worksheet,
			component: this.exclusionTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'rate') {
						excelCell.value = '';
						if (gridCell.value) {
							if (gridCell.data && gridCell.data.commission_basis) {
								if (gridCell.data.commission_basis === 'dollar') {
									excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
								}
								if (gridCell.data.commission_basis === 'percentage') {
									excelCell.value = +(this._DecimalPipe.transform(gridCell.value || 0, this.decimalPointPercentageFormat)) + '%'
								}
							}
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		//e.cancel = true;
	}

	// Method used to Display Royalties basis Name in uppercase
	public getDisplayExprRoyaltiesbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Tier basis Name in uppercase
	public getDisplayExprTierbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	public doHideExclusionItemsPopup(event: any) {

	}

	private doImportToDatagridExclsnTab() {
		this.isShowUploadExclusionItemsPopup = true;
	}

	// Method used to upload functionality for Csv,xlsx files
	public doOnFileDrop(event) {
		this.uploadFiles = event;
		for (const droppedFile of event) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					const fileExtension = file.name.split('.')[file.name.split('.').length - 1];
					if (fileExtension === 'csv' || fileExtension === 'xlsx' || fileExtension === 'xls') {
						// Allow 25MB file
						if (file.size > 25000000) {
							this._ToastrService.info('Please upload less than 25 MB size CSV file', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						this.uploadedCsvFilesData = file;
					} else {
						this._ToastrService.info('Please upload CSV file', 'Info', { closeButton: true, tapToDismiss: true });
					}
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
			}
		}
	}

	// Method used to Remove file from list of files
	public doRemoveUploadFiles() {
		this.uploadedCsvFilesData = undefined;
	}

	// Method used to get Json from file and send it to datagrid
	public doUploadCsvFile() {
		if (!this.uploadedCsvFilesData || (this.uploadedCsvFilesData.name && !this.uploadedCsvFilesData.name)) {
			this._ToastrService.info('Choose Csv or Excel File', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		this.getReadCsvFile().then(response => {
			const listOfRows = response;
			if (this.checkFileNotEmpty(listOfRows)) {
				return false;
			}
			this.doImportExclusionItems(listOfRows);
		}).catch(error => {
		});
	}

	private doImportExclusionItems(csvRecords: any[]) {
		const contractData = csvRecords || [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contracttype', 'royaltiescontracts');
		formData.append('method', ServerMethods.IMPORT_CONTRACT_DATA);
		formData.append('contract_data', JSON.stringify(contractData));
		if (this.importCsvRecordsSbsn) {
			this.importCsvRecordsSbsn.unsubscribe();
		}
		this.isUploadedImportFileExclusionTab = false;
		this.filterText = '';
		this.listOfTotalUploadedItems = [];
		this.listOfUnmatchedUploadedItems = [];
		this.importCsvRecordsSbsn = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const listOfMatchedItems = response.data.validDataList || [];
					const listOfUnmatchedItems = response.data.ignoreDataList || [];
					const listOfVisibleRows = this.exclusionTabDataGridRef.instance.getVisibleRows();
					if (listOfVisibleRows && listOfVisibleRows.length > 0) {
						const ixCustomDialogOptions: any = {
							popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
							showTitle: false,
							subtitle: 'Change Contract Items?',
							message: 'Are you sure you want to continue? Your existinq Customers(s) List and Item(s) / Group(s) list will replaced.',
						};
						this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
							if (result) {
								this.addToDatagridUploadedMatchedItemsExclusionEvent.emit({ listOfUploadedMatchedItems: listOfMatchedItems, timestamps: new Date().getTime() });
								this.isUploadedImportFileExclusionTab = true;
								const fileName = this.uploadedCsvFilesData.name;
								this.filterText = fileName;
								this.listOfTotalUploadedItems = csvRecords;
								this.listOfUnmatchedUploadedItems = listOfUnmatchedItems;
								this.isShowUploadExclusionItemsPopup = false;
								this.uploadedCsvFilesData = undefined;
							} else {
								this.isShowUploadExclusionItemsPopup = false;
								this.uploadedCsvFilesData = undefined;
								this.isUploadedImportFileExclusionTab = false;
								this.filterText = '';
								this.listOfTotalUploadedItems = [];
								this.listOfUnmatchedUploadedItems = [];
							}
						});
					} else {
						this.addToDatagridUploadedMatchedItemsExclusionEvent.emit({ listOfUploadedMatchedItems: listOfMatchedItems, timestamps: new Date().getTime() });
						this.isUploadedImportFileExclusionTab = true;
						const fileName = this.uploadedCsvFilesData.name;
						this.filterText = fileName;
						this.listOfTotalUploadedItems = csvRecords;
						this.listOfUnmatchedUploadedItems = listOfUnmatchedItems;
						this.isShowUploadExclusionItemsPopup = false;
						this.uploadedCsvFilesData = undefined;
					}
				}
			}, error: error => {
			}
		});
	}

	public doShowIgnoredItemsExculsionTab() {
		this.isShowIgnoredItemsListExclusionItemsPopup = true;
	}

	public doHideIgnoredItemsListExclusionItemsPopup(event: any) {
		this.isShowIgnoredItemsListExclusionItemsPopup = false;
	}

	// Method used to Read Csv or Xlsx file
	async getReadCsvFile(): Promise<any> {
		return new Promise<string>((resolve, reject) => {
			let csvRowsToJson: any = [];
			if (this.uploadedCsvFilesData && this.uploadedCsvFilesData.name) {
				const file = this.uploadedCsvFilesData;
				const fileReader = new FileReader();
				fileReader.onload = (e) => {
					const arrayBuffer: any = fileReader.result;
					const data = new Uint8Array(arrayBuffer);
					const arr = [];
					for (let i = 0; i !== data.length; ++i) {
						arr[i] = String.fromCharCode(data[i]);
					}
					const bstr = arr.join("");
					const workbook = XLSX.read(bstr, { type: "binary" });
					const firstSheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[firstSheetName];
					let conHeaderByItemOrPrice = this.headerByItemOrPrice;
					const headers = this.getHeaderRow(worksheet);
					if (headers && headers.length === 3) {
						conHeaderByItemOrPrice = headers;
					}
					csvRowsToJson = XLSX.utils.sheet_to_json(worksheet, { header: conHeaderByItemOrPrice, raw: true });
					//const headers = this.getHeaderRow(worksheet);
					if (this.checkValidColums(headers)) {
						reject(csvRowsToJson);
						return false;
					} else {
						csvRowsToJson.splice(0, 1);
						resolve(csvRowsToJson);
					}
				};
				fileReader.readAsArrayBuffer(file);
			} else {
				reject(csvRowsToJson);
			}
		});
	}

	// Method used to get header Csv or Xlsx file
	private getHeaderRow(sheet) {
		const headers = [];
		const range = XLSX.utils.decode_range(sheet['!ref']);
		let C, R = range.s.r; /* start in the first row */
		/* walk every column in the range */
		for (C = range.s.c; C <= range.e.c; ++C) {
			const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
			// console.log("cell",cell)
			let hdr = "UNKNOWN " + C; // <-- replace with your desired default
			if (cell && cell.t) {
				hdr = XLSX.utils.format_cell(cell).trim().toLowerCase();
				headers.push(hdr);
			}
		}
		return headers;
	}

	// Method used to check file is not empty Csv or Xlsx file
	private checkFileNotEmpty(listOfRows): boolean {
		if (listOfRows && listOfRows.length <= 0) {
			this._ToastrService.info('No records available in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		return false;
	}

	// Method used to check columns for rebtae_type Csv or Xlsx file
	private checkValidColums(listOfHeaders: any[]): boolean {
		const headers = listOfHeaders;

		if (headers && headers.length <= 0) {
			this._ToastrService.info('No Header availabled in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}

		if (headers.findIndex(h => h === 'customer') === -1) {
			this._ToastrService.info('customer column is not in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (headers.findIndex(h => h === 'item') === -1) {
			this._ToastrService.info('item column is not in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (headers.findIndex(h => h === 'rate') === -1) {
			this._ToastrService.info('rate column is not in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}

		let isCheckColumnSequence = true;
		for (let i = 0; i < this.headerByItemOrPrice.length; i++) {
			if (isCheckColumnSequence) {
				const headerIndex = headers.findIndex(csvHeader => csvHeader === this.headerByItemOrPrice[i]);
				if (headerIndex !== i) {
					this._ToastrService.info(this.headerByItemOrPrice[i] + ' sequence should be not proper in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
					return true;
				}
			} else if (!isCheckColumnSequence) {
				const headerIndex = headers.findIndex(csvHeader => csvHeader === this.headerByItemOrPrice[i]);
				if (headerIndex !== i) {
					if (headers.findIndex(h => h === 'rate') === 2) {
						return false;
					} else {
						this._ToastrService.info(this.headerByItemOrPrice[i] + ' sequence should be not proper in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
						return true;
					}
				}
			}
		}

		return false;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.exclusionTabDataGridRef.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// #region for Datagrid State Storing functionality

	public doLoadRoyaltiesExclusionsAgreeState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveRoyaltiesExclusionsAgreeState(e) {
		const royaltiesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(royaltiesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = royaltiesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, royaltiesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, royaltiesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let royaltiesListingConfig: any = {};
		try {
			royaltiesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			royaltiesListingConfig = {};
		}

		if (!royaltiesListingConfig.configs) {
			royaltiesListingConfig.configs = [];
		}

		const configIndex = royaltiesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			royaltiesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			royaltiesListingConfig.configs.push(newConfigObj);
		}
		return royaltiesListingConfig;
	}

	// #endregion
}
