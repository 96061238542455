<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="rebatesContractDataSource" [showBorders]="true"
	[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true"
	[filterValue]="filterValue" [dateSerializationFormat]="'yyyy-MM-dd'" [remoteOperations]="true"
	[columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
	(onToolbarPreparing)="doToolbarPreparing($event)" (onRowClick)="editRebatesContract($event.data);"
	class="cellColor hoverClr hideFilterPanelInx" (onCellClick)="doCellClickDetails($event)">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadRebatesState" [customSave]="doSaveRebatesState"
		[storageKey]="stateStorageKey">
	</dxo-state-storing>

	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateUpdateCurrentSettlementBtn'">
		<dx-button class="secondaryBtn toolbarbtnix" type="normal" stylingMode="contained"
			(onClick)="doUpdateCurrentSettlementPeriods()" [useSubmitBehavior]="false">
			<div *dxTemplate="let data of 'content'">
				<dx-load-indicator height="25" width="25" class="button-indicator"
					[elementAttr]="{ class: 'button-loader-indicator-blue' }"
					[visible]="isShowLoaderIndicator"></dx-load-indicator>
				<span class="dx-button-text" [class]="{'mrgL10':isShowLoaderIndicator}">Update Current Settlement
					Period</span>
			</div>
		</dx-button>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="cardcode" caption="Code" dataType="string" [visible]="false" [showInColumnChooser]="false">
	</dxi-column>

	<dxi-column dataField="contractName" caption="Name" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<dxi-column dataField="rebate_for" alignment="center" caption="Contract Type" dataType="string"
		[visible]="isShowRebateForColumn" [showInColumnChooser]="isShowRebateForColumn"
		cellTemplate="cellTemplateRebateFor">
		<dxo-header-filter [dataSource]="listOfRebateFor"> </dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateRebateFor'">
			{{ element.data.rebate_for || '' | filterFromList:listOfRebateFor: 'value':'text'}}
		</div>
	</dxi-column>

	<dxi-column dataField="rebateType" [visible]="isShowRebateTypeColumn" [showInColumnChooser]="isShowRebateTypeColumn"
		alignment="center" caption="Rebate Type" dataType="string" cellTemplate="cellTemplateRebateType">
		<dxo-header-filter [dataSource]="listOfRebateType"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateRebateType'">
			{{ element.data.rebateType || '' | filterFromList:listOfRebateType: 'value':'text'}}
		</div>
	</dxi-column>

	<dxi-column dataField="settlementPeriod" [visible]="isShowSettlementPeriodColumn"
		[showInColumnChooser]="isShowSettlementPeriodColumn" alignment="center" caption="Settlement period"
		dataType="string" cellTemplate="cellTemplateSettlementPeriod">
		<dxo-header-filter [dataSource]="listOfSettlementPeriodHeaderFilter"> </dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateSettlementPeriod'">
			{{ element.data.settlementPeriod || '' | filterFromList:listOfSettlementPeriodHeaderFilter: 'value':'text'}}
		</div>
	</dxi-column>

	<dxi-column dataField="startDate" [visible]="isShowStartDateColumn" [showInColumnChooser]="isShowStartDateColumn"
		alignment="center" caption="Contract Start Date" dataType="date" format="yyyy-MM-dd"
		cellTemplate="cellTemplateStartDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateStartDate'">
			{{ (element.data.startDate !== null && element.data.startDate !== '' && element.data.startDate
			!== undefined && element.data.startDate !== 'Invalid date') ? (element.data.startDate | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="endDate" [visible]="isShowEndDateColumn" [showInColumnChooser]="isShowEndDateColumn"
		alignment="center" caption="Contract End Date" dataType="date" format="yyyy-MM-dd"
		cellTemplate="cellTemplateEndDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateEndDate'">
			{{ (element.data.endDate !== null && element.data.endDate !== '' && element.data.endDate !==
			undefined && element.data.endDate !== 'Invalid date') ? (element.data.endDate | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="settlementPeriodStart" alignment="center" caption="Open Settlement Start Date" dataType="date"
		format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementStartDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateOpenSettlementStartDate'">
			{{ (element.data.settlementPeriodStart !== null && element.data.settlementPeriodStart !== '' &&
			element.data.settlementPeriodStart
			!== undefined && element.data.settlementPeriodStart !== 'Invalid date') ?
			(element.data.settlementPeriodStart | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="settlementPeriodEnd" alignment="center" caption="Open Settlement End Date" dataType="date"
		format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementEndDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateOpenSettlementEndDate'">
			{{ (element.data.settlementPeriodEnd !== null && element.data.settlementPeriodEnd !== '' &&
			element.data.settlementPeriodEnd !==
			undefined && element.data.settlementPeriodEnd !== 'Invalid date') ?
			(element.data.settlementPeriodEnd | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="current_settlement_rebate_total" caption="Settlement Amount" dataType="number"
		[allowHeaderFiltering]="false" [allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column dataField="status" [visible]="isShowStatusColumn" [showInColumnChooser]="isShowStatusColumn"
		[allowFiltering]="false" [allowHeaderFiltering]="true" [allowHeaderFiltering]="true" alignment="center"
		caption="Status" dataType="string" cellTemplate="cellTemplateStatus">
		<dxo-header-filter [dataSource]="listOfStatusHeaderFilter">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateStatus'">
			<span
				*ngIf="element.data.rebate_for === 'individual' || element.data.rebate_for === 'growth_incentive' || element.data.rebate_for === 'distribution' ">
				<span *ngIf="element.data.rebateType === 'sales'">
					<dx-drop-down-button *ngIf="contractLifecycleManagementConfig === ContractLifeCycleValEnum.ALWAYS_OFF"
						[splitButton]="false" [ngClass]="getClassNameForStatusField(element.data.status)"
						[useSelectMode]="false"
						[text]="element.data.status || RebateStatusValEnum.ACTIVE | filterFromList:listOfContractStatuses : 'value' : 'label' | uppercase"
						stylingMode="contained" class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
						[items]="listOfContractStatuses" [wrapItemText]="true" [useSelectMode]="true" displayExpr="label"
						keyExpr="value" [width]="'100%'" (onButtonClick)="getListOfAlwaysOffStatus(element.data)"
						(onItemClick)="changedRebatesContractStatusAction(element.data,element.data.status,element.data.status === RebateStatusValEnum.ACTIVE ? RebateStatusValEnum.INACTIVE : RebateStatusValEnum.ACTIVE)">
					</dx-drop-down-button>
					<dx-drop-down-button
						*ngIf="contractLifecycleManagementConfig === ContractLifeCycleValEnum.ALWAYS_ON || contractLifecycleManagementConfig === ContractLifeCycleValEnum.PER_CONTRACT_BASIS"
						[splitButton]="false" [ngClass]="getClassNameForStatusField(element.data.status)"
						[useSelectMode]="false"
						[text]="element.data.status || RebateStatusValEnum.DRAFT | filterFromList:listOfContractStatuses : 'value' : 'label'  | uppercase"
						stylingMode="contained" class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
						[items]="listOfContractStatuses" [wrapItemText]="true" [useSelectMode]="true" displayExpr="label"
						keyExpr="value" [width]="'100%'" (onButtonClick)="getListOfAlwaysOnStatus(element.data)"
						(onItemClick)="doTaskMenuAction($event,element)">
					</dx-drop-down-button>
				</span>
				<span *ngIf="element.data.rebateType === 'purchasing'">
					<dx-drop-down-button [splitButton]="false" [ngClass]="getClassNameForStatusField(element.data.status)"
						[useSelectMode]="false"
						[text]="element.data.status || RebateStatusValEnum.ACTIVE | filterFromList:listOfContractStatuses : 'value' : 'label' | uppercase"
						stylingMode="contained" class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
						[items]="listOfContractStatuses" [wrapItemText]="true" [useSelectMode]="true" displayExpr="label"
						keyExpr="value" [width]="'100%'" (onButtonClick)="getListOfAlwaysOffStatus(element.data)"
						(onItemClick)="changedRebatesContractStatusAction(element.data,element.data.status,element.data.status === RebateStatusValEnum.ACTIVE ? RebateStatusValEnum.INACTIVE : RebateStatusValEnum.ACTIVE)">
					</dx-drop-down-button>
				</span>
			</span>
			<span *ngIf="element.data.rebate_for === 'buyinggroup'">
				<dx-drop-down-button [splitButton]="false" [ngClass]="getClassNameForStatusField(element.data.status)"
					[useSelectMode]="false"
					[text]="element.data.status || RebateStatusValEnum.ACTIVE | filterFromList:listOfContractStatuses : 'value' : 'label' | uppercase"
					stylingMode="contained" class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
					[items]="listOfContractStatuses" [wrapItemText]="true" [useSelectMode]="true" displayExpr="label"
					keyExpr="value" [width]="'100%'" (onButtonClick)="getListOfAlwaysOffStatus(element.data)"
					(onItemClick)="changedRebatesContractStatusAction(element.data,element.data.status,element.data.status === RebateStatusValEnum.ACTIVE ? RebateStatusValEnum.INACTIVE : RebateStatusValEnum.ACTIVE)">
				</dx-drop-down-button>
			</span>
		</div>
	</dxi-column>

	<dxi-column dataField="guid" caption="Action" [allowHiding]="false" [fixed]="true" fixedPosition="right"
		alignment="center" dataType="string" cellTemplate="cellTemplateAction" [allowExporting]="false"
		[visible]="isShowActionColumn" [showInColumnChooser]="isShowActionColumn" [allowFiltering]="false"
		[allowHeaderFiltering]="false" [allowSorting]="false" [allowResizing]="false" [width]="75">
		<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
			<a id="copyContract_{{element.rowIndex + 1}}" (click)="doCopyContract(element.data);$event.stopPropagation();">
				<span class="icon-copydxtable"></span>
			</a>
			<dx-tooltip target="#copyContract_{{element.rowIndex + 1}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
				position="bottom">
				<div *dxTemplate="let data = data of 'content'">
					Copy
				</div>
			</dx-tooltip>
		</div>
	</dxi-column>

	<dxo-summary>
		<dxi-total-item column="current_settlement_rebate_total" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
			</dxo-value-format>
		</dxi-total-item>
	</dxo-summary>
</dx-data-grid>


<app-upload-rebate-contract-dialog *ngIf="isUploadRebateContractPopup"
	[uploadRebateContractProps]="uploadRebateContractProps"
	(closeUploadRebateContractPopup)="doCloseUploadRebateContractPopup($event)"></app-upload-rebate-contract-dialog>