import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Component, OnInit, Inject, OnDestroy, EventEmitter, Input, Output, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { PageOptions } from '@app/models/common.model';
import { LoginService } from '@app/services/login.service';
import { ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { DATETIME_FORMAT_BACKEND, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
import * as moment from 'moment';

@Component({
	selector: 'app-export-pricelist-terms-conditions-dialog',
	templateUrl: './export-pricelist-terms-conditions-dialog.component.html',
	styleUrls: ['./export-pricelist-terms-conditions-dialog.component.css']
})
export class ExportPricelistTermsConditionsDialogComponent implements OnInit, OnChanges, OnDestroy {
	@Input() exportPricelistTermsProps: any;
	@Output() closeExportPricelistTermConditionsPopup = new EventEmitter<any>();
	public isShowPopup: boolean;
	public popupTitleText: string = 'Export Price List Terms';
	private currentPricelistDetailsObj: any;
	private pricelistDetailsObj: any;

	public pageOptions: PageOptions = new PageOptions();
	public dxDataSourceCustomer: any;
	public isCustomerGridBoxOpened: boolean = false;
	@ViewChild('listOfCientDataGrid') listOfCientDataGrid: DxDataGridComponent;
	public crmDataFilterValue: any[] = [];

	@ViewChild('refEndUsersLstDrpDwn') refEndUsersLstDrpDwn: DxDropDownBoxComponent;
	public dxDataSourceEndUser: any;
	public isEndUserGridBoxOpened: boolean = false;
	@ViewChild('listOfEndUserDataGridRef') listOfEndUserDataGridRef: DxDataGridComponent;
	public endUserDataFilterValue: any[] = [];


	@ViewChild('refBillingAddressLstDrpDwn') refBillingAddressLstDrpDwn: DxDropDownBoxComponent;
	@ViewChild('refListOfBillingAddressDataGrid') refListOfBillingAddressDataGrid: DxDataGridComponent;
	public listOfBillToAddresses: any[] = [];
	public isBillingAddressGridBoxOpened: boolean = false;


	private exportPdfSbsn: Subscription;
	public ServerEntity = ServerEntity;
	public exportPricelistTerms: any = {
		type: ServerEntity.CLIENTS,
		clientCode: [],
		clientDetail: [],
		endUserCode: [],
		endUserDetails: [],
		billingAddressGuid: [],
		billingAddressDetails: [],
		replyDate: null,
	};

	public listOfTypes: any = [
		{
			value: ServerEntity.CLIENTS,
			text: 'Customers'
		},
		{
			value: ServerEntity.ENDUSERS,
			text: 'End-Users'
		}
	];
	public isShowCodeColumn: boolean = false;
	public erp_type: string;
	public isAssignedPriceListToCustomer: boolean;
	public isAssignedPriceListToEndUser: boolean;
	private getAssignedPriceListToCustomerSbsn: Subscription;
	private getAssignedPriceListToEndUserSbsn: Subscription;
	// #region for Angular Life cycle
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService) {
	}

	ngOnInit() {
		this.isShowPopup = true;
		this.preInit();
	}


	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['exportPricelistTermsProps']) {
			const exportPricelistTermsProps = _SimpleChanges['exportPricelistTermsProps'].currentValue;
			this.pricelistDetailsObj = exportPricelistTermsProps.pricelistDetails;
			this.currentPricelistDetailsObj = exportPricelistTermsProps.currentPriceListHistoryObj;
			this.doCheckPriceListAssignedToCustomers();
			this.doCheckPriceListAssignedToEndUsers();
		}
	}

	ngOnDestroy() {
		if (this.exportPdfSbsn) {
			this.exportPdfSbsn.unsubscribe();
		}
		if (this.getAssignedPriceListToCustomerSbsn) {
			this.getAssignedPriceListToCustomerSbsn.unsubscribe();
		}
		if (this.getAssignedPriceListToEndUserSbsn) {
			this.getAssignedPriceListToEndUserSbsn.unsubscribe();
		}
	}

	public doHiddenPopup(event) {
		this.closeExportPricelistTermConditionsPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}
	// #endregion

	private preInit() {
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		this.setColumnForCrmGrid();
	}

	private setColumnForCrmGrid() {
		this.isShowCodeColumn = true;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isShowCodeColumn = true;
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isShowCodeColumn = false;
		} else {
			this.isShowCodeColumn = true;
		}
	}

	public doExportTypeValueChanged(event: any) {
		this.listOfBillToAddresses = [];
		this.exportPricelistTerms.billingAddressGuid = [];
		this.exportPricelistTerms.billingAddressDetails = [];
		this.exportPricelistTerms.clientCode = [];
		this.exportPricelistTerms.clientDetail = [];
		this.exportPricelistTerms.endUserCode = [];
		this.exportPricelistTerms.endUserDetails = [];
	}

	public doExportPriceListTerms() {
		if (this.doCheckValidation()) {
			return;
		}
		let code = '';
		let replyDate = '';
		if (this.exportPricelistTerms.type === ServerEntity.CLIENTS) {
			code = this.exportPricelistTerms.clientCode;
		}
		if (this.exportPricelistTerms.type === ServerEntity.ENDUSERS) {
			code = this.exportPricelistTerms.endUserCode;
		}
		if (this.exportPricelistTerms.replyDate) {
			replyDate = moment(this.exportPricelistTerms.replyDate).format(DATETIME_FORMAT_BACKEND);
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.PRICELIST_TERMS);
		formData.append('method', ServerMethods.DOWNLOAD_PDF);
		formData.append('entity_id', this.currentPricelistDetailsObj.guid);
		formData.append('unique_id', this.currentPricelistDetailsObj.unique_id);
		formData.append('type', this.exportPricelistTerms.type);
		if (code) {
			formData.append('code', code);
		}
		if (this.exportPricelistTerms.billingAddressGuid) {
			formData.append('billing_address_guid', this.exportPricelistTerms.billingAddressGuid);
		}
		if (replyDate) {
			formData.append('reply_date', replyDate);
		}
		this._LoaderService.show();
		if (this.exportPdfSbsn) {
			this.exportPdfSbsn.unsubscribe();
		}
		this.exportPdfSbsn = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					// this.hidePopUpDataEvent.emit(this.dialogData);
					if (response.data.pdfName) {
						const link = document.createElement('a');
						link.href = response.data.pdfLink;
						link.download = response.data.pdfName;
						link.target = '_blank';
						link.dispatchEvent(new MouseEvent('click'));
						this._ToastrService.success('Document Added Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private doCheckValidation() {
		let code = '';
		if (this.exportPricelistTerms.type === ServerEntity.CLIENTS) {
			code = this.exportPricelistTerms.clientCode;
			if (!code || (code && code.length <= 0)) {
				this._ToastrService.info('Customer is required', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
		}
		if (this.exportPricelistTerms.type === ServerEntity.ENDUSERS) {
			code = this.exportPricelistTerms.endUserCode;
			if (!code || (code && code.length <= 0)) {
				this._ToastrService.info('End-User is required', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
		}
		return false;
	}

	// #region for Customer Dropdown

	private doCheckPriceListAssignedToCustomers() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.CLIENTS);
		formData.append('view_fields', JSON.stringify(['guid']));
		formData.append('filter', JSON.stringify(["pricelistnum", "contains", this.currentPricelistDetailsObj.pricelistno]));
		if (this.getAssignedPriceListToCustomerSbsn) {
			this.getAssignedPriceListToCustomerSbsn.unsubscribe();
		}
		this.getAssignedPriceListToCustomerSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					this.isAssignedPriceListToCustomer = response.data && response.data.length > 0;
				}
				this.fetchedListOfCustomers();
			}, error: error => {
				this.fetchedListOfCustomers();
			}
		});
	}

	// Method used to get members who are having Individual Contract in current contract.
	private fetchedListOfCustomers() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS,
			view_fields: JSON.stringify(['guid', 'code', 'name', 'billtodefault', 'address', 'pricelistnum']),
		};
		if (this.isAssignedPriceListToCustomer) {
			loadParams.filter = JSON.stringify([["pricelistnum", "contains", this.currentPricelistDetailsObj.pricelistno]]);
		}

		this.dxDataSourceCustomer = AspNetData.createStore({
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: (method, ajaxOptions) => {
			},
			onLoaded(result) {
			}
		});

	}

	public doDisplayExprCustomerName(company) {
		// if (this.isShowCodeColumn) {
		// return (company && company.code) ? company.code + ' - ' + (company.name ? company.name : '') : '';
		// } else {
		// return (company && company.name) ? company.name : '';
		// }
		let returnStr = '';
		if (company && company.guid) {
			if (company.code) {
				returnStr = company.code + ' - ' + company.name;
			} else {
				returnStr = company.name || '';
			}
		}
		return returnStr;
	}

	public doGridBoxOptionChanged(e) {
		if (e.name === "value") {
			this.isCustomerGridBoxOpened = false;
			this.exportPricelistTerms.clientCode = [];
			// this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to change values event of customer dropdown
	public doChangeCustomers(e: any) {
		if (e.value === null) {
			this.exportPricelistTerms.clientDetail = [];
		}
	}

	// Method used to select event on datagrid of customer selectbox
	public doSelectedChangedCustomers(e: any) {
		this.exportPricelistTerms.clientDetail = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.exportPricelistTerms.clientDetail.push(e.selectedRowsData[i]);
			}
		}
		this.exportPricelistTerms.billingAddressGuid = [];
		this.exportPricelistTerms.billingAddressDetails = [];
		this.setListOfBillingAddress();
	}

	// Method used to Clear filter after closing dropdown.
	public doOpenedDropdownCompany(e) {
		this.listOfCientDataGrid.instance.clearFilter();
	}

	// #endregion

	private setListOfBillingAddress() {
		let listOfAdddress = [];
		if (this.exportPricelistTerms.type === ServerEntity.CLIENTS) {
			const clientDetails = this.exportPricelistTerms.clientDetail.length > 0 ? this.exportPricelistTerms.clientDetail[0] : null;
			listOfAdddress = clientDetails && clientDetails.address ? clientDetails.address.billto || [] : [];
		}
		if (this.exportPricelistTerms.type === ServerEntity.ENDUSERS) {
			const endUserDetails = this.exportPricelistTerms.endUserDetails.length > 0 ? this.exportPricelistTerms.endUserDetails[0] : null;
			listOfAdddress = endUserDetails && endUserDetails.address ? endUserDetails.address.billto || [] : [];
		}
		this.listOfBillToAddresses = listOfAdddress;
	}


	private doCheckPriceListAssignedToEndUsers() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ENDUSERS);
		formData.append('view_fields', JSON.stringify(['guid']));
		formData.append('filter', JSON.stringify(["pricelistnum", "contains", this.currentPricelistDetailsObj.pricelistno]));
		if (this.getAssignedPriceListToEndUserSbsn) {
			this.getAssignedPriceListToEndUserSbsn.unsubscribe();
		}
		this.getAssignedPriceListToEndUserSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					this.isAssignedPriceListToEndUser = response.data && response.data.length > 0;
				}
				this.fetchedListOfEndUsers();
			}, error: error => {
				this.fetchedListOfEndUsers();
			}
		});
	}


	private fetchedListOfEndUsers() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ENDUSERS,
			view_fields: JSON.stringify(['guid', 'code', 'name', 'companyname', 'address', 'billtodefault', 'pricelistnum']),
		};
		if (this.isAssignedPriceListToEndUser) {
			loadParams.filter = JSON.stringify(["pricelistnum", "contains", this.currentPricelistDetailsObj.pricelistno]);
		}
		this.dxDataSourceEndUser = AspNetData.createStore({
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: (method, ajaxOptions) => {
			},
			onLoaded(result) {
			}
		});
	}


	public doDisplayExprEndUser(company: any) {
		let returnStr = '';
		if (company && company.guid) {
			if (company.code) {
				returnStr = company.code + ' - ' + company.companyname;
			} else {
				returnStr = company.companyname || '';
			}
		}

		return returnStr;
	}

	public doEndUserOptionChangedDrpDwn(e) {
		if (e.name === "value") {
			this.isEndUserGridBoxOpened = false;
			this.exportPricelistTerms.endUserCode = [];
			// this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to change values event of customer dropdown
	public doEndUserValueChangedDrpDwn(e: any) {
		if (e.value === null) {
			this.exportPricelistTerms.endUserDetails = [];
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doEndUserOpenedDrpDwn(e) {
		this.listOfEndUserDataGridRef.instance.clearFilter();
	}

	// Method used to select event on datagrid of customer selectbox
	public doEndUserSelectedChangedDrpDwn(e: any) {
		this.exportPricelistTerms.endUserDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.exportPricelistTerms.endUserDetails.push(e.selectedRowsData[i]);
			}
		}
		this.exportPricelistTerms.billingAddressGuid = [];
		this.exportPricelistTerms.billingAddressDetails = [];
		this.setListOfBillingAddress();
	}

	// #region for Billing Address 
	public doDisplayExprBillingAddressName(billToAddress: any) {
		return billToAddress && billToAddress.guid ? billToAddress.addressname : '';
	}

	public doBillingAddressOptionChangedDrpDwn(e) {
		if (e.name === "value") {
			this.isBillingAddressGridBoxOpened = false;
			// this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to change values event of customer dropdown
	public doBillingAddressChangeCustomers(e: any) {
		if (e.value === null) {
			// this.exportPricelistTerms.clientDetail = [];
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doBillingAddressOpenedDrpDwn(e) {
		this.refListOfBillingAddressDataGrid.instance.clearFilter();
	}

	// Method used to select event on datagrid of customer selectbox
	public doBillingAddresonSelectionChanged(e: any) {
		this.exportPricelistTerms.billingAddressDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.exportPricelistTerms.billingAddressDetails.push(e.selectedRowsData[i]);
			}
		}
	}
	//#endregion

}
