<dx-popup [width]="'800px'" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
	[title]="'Price List Mismatch'" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" (onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">

			<dx-data-grid #endUserPriceListMismatchDataGridContainer id="gridContainer"
				[dataSource]="endUserPriceListMismatchDataSource" [remoteOperations]="false" [hoverStateEnabled]="true"
				[showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true" [wordWrapEnabled]="true">
				<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-filter-panel [visible]="false"></dxo-filter-panel>
				<dxo-header-filter [visible]="true"></dxo-header-filter>
				<dxi-column caption="Item" dataField="itemname" cellTemplate="cellTemplateItemName">
					<div *dxTemplate="let element of 'cellTemplateItemName'">
						{{element.data.itemname || ''}}
					</div>
				</dxi-column>
				<!-- <dxi-column caption="Description" dataField="description" cellTemplate="cellTemplateDescription">
					<div *dxTemplate="let element of 'cellTemplateDescription'">
						{{element.data.description || ''}}
					</div>
				</dxi-column> -->
				<dxi-column caption="Note" dataField="message" cellTemplate="cellTemplateMessage">
					<div *dxTemplate="let element of 'cellTemplateMessage'">
						{{element.data.message || ''}}
					</div>
				</dxi-column>
			</dx-data-grid>
		</dx-scroll-view>
	</div>
</dx-popup>