import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_EDIT_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';
import { PriceListTypesLblEnum, PriceListTypesValEnum, PricelistStatusLblEnum, PricelistStatusValEnum } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
@Injectable({
	providedIn: 'root'
})
export class ProductServiceService {
	public productCurrentTab: string = undefined;
	public productDetails: any;
	private priceDetails: any;
	private priceDetailsIdObj: any;
	public pageOptions = new PageOptions();
	public searchFilter: any;
	public storedFilters: any;
	private isEditPricelistDetails: string;

	constructor() { }

	setProductDetails(val: any) {
		this.productDetails = val;
	}
	getProductDetails() {
		return this.productDetails;
	}
	setPriceDetails(val: any) {
		this.priceDetails = val;
	}
	getPriceDetails() {
		return this.priceDetails;
	}
	setPriceDetailsIDs(val: any) {
		this.priceDetailsIdObj = val;
	}
	getPriceDetailsIDs() {
		return this.priceDetailsIdObj;
	}
	setPageOptions(pageOptions: any) {
		this.pageOptions = pageOptions;
	}
	getPageOptions() {
		return this.pageOptions;
	}
	setSearchFilter(searchFilter: any) {
		this.searchFilter = searchFilter;
	}
	getSearchFilter() {
		return this.searchFilter;
	}
	setStoredFilters(val: any) {
		this.storedFilters = val;
	}

	getStoredFilters() {
		return this.storedFilters || undefined;
	}

	setIsEditPricelistDetails(value: string) {
		this.isEditPricelistDetails = value;
	}
	getIsEditPricelistDetails() {
		return this.isEditPricelistDetails;
	}

	getListOfStatusForPricelist() {
		const listOfStatuses = [
			{
				value: PricelistStatusValEnum.DRAFT,
				text: PricelistStatusLblEnum.DRAFT,
				label: PricelistStatusLblEnum.DRAFT,
				classname: 'defaultClr'
			},
			{
				value: PricelistStatusValEnum.PENDING_APPROVAL,
				text: PricelistStatusLblEnum.PENDING_APPROVAL,
				label: PricelistStatusLblEnum.PENDING_APPROVAL,
				classname: 'orange'
			},
			{
				value: PricelistStatusValEnum.APPROVED,
				text: PricelistStatusLblEnum.APPROVED,
				label: PricelistStatusLblEnum.APPROVED,
				classname: 'green'
			}
		];
		return listOfStatuses;
	}

	public getListOfActionsForItems() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForPriceLists() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add Price List',
				btn_image: '',
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EDIT',
				btn_name: 'Update Price List',
				btn_image: '',
				icon: ' ' + IMG_EDIT_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForAddEditPriceLists() {
		return [
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForCustomerTab() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForAppovalTrailsTab() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}


	get getListOfPricelistTypes() {
		return [
			{
				value: PriceListTypesValEnum.STANDARD,
				text: PriceListTypesLblEnum.STANDARD,
			},
			{
				value: PriceListTypesValEnum.GUIDE,
				text: PriceListTypesLblEnum.GUIDE,
			},
			{
				value: PriceListTypesValEnum.NHS,
				text: PriceListTypesLblEnum.NHS,
			},
		]
	}
}
