<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width80:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 70:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="!isEditMode ? 'Add Task' : 'Edit Task' " [showCloseButton]="true"
	[(visible)]="isShowTaskPopup" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">

			<div class="row mrgT10">
				<div class="cols12 colsP0">
					<div class="cols6">
						<div class="dx-field mrgB20" [ngClass]="{'inputDisable':isReadOnlyClient}">
							<div class="dx-field-label">Company</div>
							<div class="dx-field-value">
								<dx-drop-down-box valueExpr="guid" displayExpr="name" [(value)]="aSelKeysCompany"
									placeholder="Select a company..." [deferRendering]="true" [showClearButton]="true"
									[dataSource]="clientsList" [disabled]="isReadOnlyClient" [(opened)]="isDropdownOpenedCompany"
									(onOptionChanged)="doCompanydropdownOptionChanged($event)">
									<div *dxTemplate="let data of 'content'">
										<dx-data-grid [dataSource]="clientsList" [selection]="{ mode: 'single'}"
											[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
											[filterRow]="{ visible: true }" [height]="345" [filterSyncEnabled]="true"
											[filterValue]="taskFilterValue" [remoteOperations]="true" [wordWrapEnabled]="true"
											[showBorders]="true" [(selectedRowKeys)]="aSelKeysCompany"
											(onSelectionChanged)="doSelectionChangedCompany($event)">
											<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
												[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
											<dxi-column dataField="code" dataType="string" [visible]="!isQBErpUser" caption="ID"
												cellTemplate="cellTemplateCode">
												<div *dxTemplate="let element of 'cellTemplateCode'">
													{{element.data.code || ''}}
												</div>
											</dxi-column>
											<dxi-column dataField="name" sortOrder="asc" dataType="string" caption="Company"
												cellTemplate="cellTemplateCompanyName">
												<div *dxTemplate="let element of 'cellTemplateCompanyName'">
													{{element.data.name || ''}}
												</div>
											</dxi-column>
											<dxi-column dataField="crm_type" dataType="string" caption="Type"
												cellTemplate="cellTemplateCrmType">
												<div *dxTemplate="let element of 'cellTemplateCrmType'">
													<span class="highlight">
														<strong *ngIf="element.data.crm_type == 'clients'">Customer</strong>
														<strong *ngIf="element.data.crm_type == 'prospects'">Prospect</strong>
														<strong *ngIf="element.data.crm_type == 'leads'">Lead</strong>
													</span>
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
								</dx-drop-down-box>
							</div>
						</div>
					</div>
					<div class="cols6">
						<div class="dx-field mrgB20">
							<div class="dx-field-label">Contact Person(s)</div>
							<div class="dx-field-value" style="width: 100%;">
								<dx-drop-down-box valueExpr="guid" displayExpr="name"
									[(value)]="taskInsertUpdateUI.contactpersons" placeholder="Select a company..."
									[showClearButton]="true" [dataSource]="contactemployees"
									[disabled]="isAllReadOnlyForClosedTask">
									<div *dxTemplate="let data of 'content'">
										<dx-data-grid [dataSource]="contactemployees" keyExpr="guid"
											[selection]="{ mode: 'multiple'}" [hoverStateEnabled]="true"
											[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }"
											[height]="345" [wordWrapEnabled]="true" [showBorders]="true"
											[(selectedRowKeys)]="taskInsertUpdateUI.contactpersons">
											<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
												[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>

											<dxi-column dataField="name" sortOrder="asc" dataType="string" caption="Name"
												cellTemplate="cellTemplateName">
												<div *dxTemplate="let element of 'cellTemplateName'">
													{{element.data.name || ''}}
												</div>
											</dxi-column>
											<dxi-column dataField="e_mail" dataType="string" caption="Email"
												cellTemplate="cellTemplateEmail">
												<div *dxTemplate="let element of 'cellTemplateEmail'">
													{{element.data.e_mail}}
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
								</dx-drop-down-box>
							</div>
						</div>
					</div>
				</div>

				<div class="cols12">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Task Title</div>
						<div class="dx-field-value">
							<dx-text-box mode="text" [(value)]="taskInsertUpdateUI.title" [showClearButton]="true"
								[maxLength]="100" name="tasktitle" [disabled]="isAllReadOnlyForClosedTask">
							</dx-text-box>
						</div>
					</div>
				</div>
				<div class="cols6">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Task Type</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-select-box [(value)]="taskInsertUpdateUI.tasktype" [dataSource]="listOfTaskTypes"
								displayExpr="label" valueExpr="value" searchMode="contains" searchExpr="selectTaskType"
								[searchTimeout]="200" [minSearchLength]="0" [searchEnabled]="true"
								[disabled]="isAllReadOnlyForClosedTask" name="selectTaskType">
							</dx-select-box>
						</div>
					</div>
				</div>
				<div class="cols6">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Assigned To</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-drop-down-box valueExpr="sub_account_id" [displayExpr]="getAssignToDisplayExpr"
								[(value)]="taskInsertUpdateUI.assignto" placeholder="Select a Assign To..."
								[showClearButton]="true" [dataSource]="listOfAssignToFiltered"
								[disabled]="isAllReadOnlyForClosedTask">
								<div *dxTemplate="let data of 'content'">
									<dx-data-grid [dataSource]="listOfAssignToFiltered" keyExpr="sub_account_id"
										[selection]="{ mode: 'multiple'}" [hoverStateEnabled]="true"
										[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }" [height]="345"
										[wordWrapEnabled]="true" [showBorders]="true"
										[(selectedRowKeys)]="taskInsertUpdateUI.assignto">
										<dxo-scrolling [useNative]="false"></dxo-scrolling>
										<dxi-column dataField="first_name" dataType="string" caption="First Name"
											cellTemplate="cellTemplateFirstName">
											<div *dxTemplate="let element of 'cellTemplateFirstName'">
												{{element.data.first_name || ''}}
											</div>
										</dxi-column>
										<dxi-column dataField="last_name" dataType="string" caption="Last Name"
											cellTemplate="cellTemplateLastName">
											<div *dxTemplate="let element of 'cellTemplateLastName'">
												{{ element.data.last_name ? ' ' + element.data.last_name : ''}}
											</div>
										</dxi-column>
									</dx-data-grid>
								</div>
							</dx-drop-down-box>
						</div>
					</div>
				</div>
				<div class="cols6 datePicker">
					<div class="dx-field feildCol">
						<div class="dx-field-label">Due Date</div>
						<div class="dx-field-value">
							<!-- (onValueChanged)="doSetSettlementStartDate($event)" customItemCreateEvent="change" -->
							<dx-date-box [(value)]="taskInsertUpdateUI.enddate" type="date" displayFormat="yyyy-MM-dd"
								[disabled]="isAllReadOnlyForClosedTask">
							</dx-date-box>
						</div>
					</div>
				</div>
				<div class="cols6 datePicker">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Due Time</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-select-box [(value)]="taskInsertUpdateUI.timeinterval" [dataSource]="listOfTimeInvervals"
								displayExpr="name" valueExpr="id" searchMode="contains" searchExpr="selectTaskTime"
								[searchTimeout]="200" [minSearchLength]="0" [searchEnabled]="true"
								[disabled]="isAllReadOnlyForClosedTask" name="timeinterval">
							</dx-select-box>
						</div>
					</div>
				</div>
				<div class="cols12">
					<div class="dx-field textareaCols mrgB20">
						<div class="dx-field-label">Notes</div>
						<div class="dx-field-value">
							<dx-text-area [(value)]="taskInsertUpdateUI.notes" [disabled]="isAllReadOnlyForClosedTask"
								[height]="90" [maxLength]="500" name="notes">
							</dx-text-area>
						</div>
					</div>
				</div>

				<div class="cols12" [hidden]="!isAllReadOnlyForClosedTask">
					<div class="dx-field mrgB10 addEditTask">
						<div class="dx-field-label taskstatus">Task Status :</div>
						<div class="dx-field-value radioGroup">
							<dx-radio-group [(value)]="taskInsertUpdateUI.status" [dataSource]="taskStatuses"
								(onValueChanged)="doChangedTaskStatus($event)" [disabled]="true" customItemCreateEvent="change"
								displayExpr="name" valueExpr="id" layout="horizontal" name="taskstatus">
							</dx-radio-group>
						</div>
					</div>
				</div>
				<div class=" cols12" [hidden]="isDisplayRemark">
					<div class="dx-field textareaCols mrgB20">
						<div class="dx-field-label">Closed Task Note</div>
						<div class="dx-field-value">
							<dx-text-area [(value)]="taskInsertUpdateUI.closedtasknote" [height]="90" [maxLength]="500"
								name="closedtasknote"></dx-text-area>
						</div>
					</div>
				</div>
				<div *ngIf="taskInsertUpdateUI.attachment" class="left-content cols12">
					<h2 class="mrg0">Attachment:</h2>
					<dx-box direction="row" width="100%" [height]="'auto'">
						<dxi-item [ratio]="0" class="dx-box-dark" [baseSize]="20">
							<dx-box direction="row" width="100%" [height]="'auto'">
								<dxi-item [ratio]="0">
								</dxi-item>
							</dx-box>
						</dxi-item>
						<dxi-item class="dx-box-light" [ratio]="1">
							{{taskInsertUpdateUI.attachment | json}}
						</dxi-item>
					</dx-box>
				</div>
			</div>
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="saveTask()" text="Submit" class="button primaryBtn" icon="save"></dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>