export class LoaderState {
   color = 'primary';
   mode = 'indeterminate';
   value = '50';
   loaded = false;
   dx_visible: boolean = false;
   dx_position?: any; // = { at: 'center' } or { of: '#YOURID' }
   dx_shadingColor?: any;
   dx_showPane?: boolean;
   dx_showIndicator?: boolean;
   dx_shading?: boolean;
   dx_closeOnOutsideClick?: boolean;
   constructor() {
      this.color = 'primary';
      this.mode = 'indeterminate';
      this.value = '50';
      this.loaded = false;
      this.dx_visible = false;
   }
}