import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { Customer360TilesEnum, ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
import { ViewOrderChangeOrdereDetailPopUpModel } from '@app/models/popup.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-view-order-changeorder-detail-popup',
	templateUrl: './view-order-changeorder-detail-popup.component.html',
	styleUrls: ['./view-order-changeorder-detail-popup.component.css'],
	providers: [DecimalPipe, CustomCurrencyPipe]
})

export class ViewOrderChangeorderDetailPopupComponent implements OnInit, OnDestroy {
	public pageTitle: string = '';
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean = true;
	@Input() viewOrderChangeOrderDetailProps: ViewOrderChangeOrdereDetailPopUpModel;
	@Output() viewOrderChangeOrderPopup = new EventEmitter<any>();

	public myMath = Math;
	public erpTypeValEnum = ErpTypeValEnum;
	public orderChangeOrderDetail: any;
	public uomList: any[] = [];
	private dataOutReqSubscription: Subscription;
	private getInvoiceIncentivesSbsn: Subscription;
	private searchParams: any[] = [];
	public globalCurrency: string;
	public isResponse: boolean = false;
	public erp_type: string = '';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public listOfPrices: any[] = [];
	public isShowPickStatusColumn: boolean = false;
	public isShowDataGrid: boolean = false;
	@ViewChild('itemsListOriginDatagridRef') itemsListOriginDatagridRef: DxDataGridComponent;
	private loaderConfig: any;
	public isShowClassesColumn: boolean;
	public Customer360TilesEnum = Customer360TilesEnum;
	public isShowCostColumn: boolean = false;
	public isEnabledMasterDetails: boolean = false;
	public pageOptions: PageOptions = new PageOptions();
	public isShowOriginalOrderDetails: boolean = false;
	public originalOrderDetails: any;
	public isShowEligibleField: boolean;
	public isShowEligibleColumn: boolean;
	public listOfSalesPpl: any[];
	public isMultipleSalesReps: boolean;
	public decimalNumberFormat: string = '1.2-2';

	public isEnableProfitOnDocHeaderFeature: boolean = false;
	public specificListOfProfitOnDocHeader: any[] = [];
	private getFetureCustomFieldsSbsn: Subscription;
	private listOfFeatureCustomFields: any[] = [];
	public isShowDiscountField: boolean = false;
	public isShowPickStatusField: boolean = false;
	public isShowBatchSerialField: boolean = false;
	public erpType: string;
	public isShowShippingTrackDetails: boolean;
	private isShippingTypeMapping: boolean;
	private isShippingType: boolean;
	public listOfShippingTypeMapping: any[] = [];
	public listofShippingTypes: any[] = [];
	public listOfShippingTracks: any[] = [];
	private arrTrackNoSeparator: any[] = [];
	constructor(
		public _Router: Router,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _CurrencyPipe: CurrencyPipe,
		public _AppCommonSrvc: AppCommonSrvc,
		private _DecimalPipe: DecimalPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe
	) { }

	ngOnInit(): void {
		this.setErpType();
		// if (this.viewOrderChangeOrderDetailProps.docType == 'order') {
		this.pageTitle = 'Order Detail'
		this.onInitOrder();
		this.doVisiblePickStatusField();
		this.doVisibleDiscountField();
		// }
	}
	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getInvoiceIncentivesSbsn) {
			this.getInvoiceIncentivesSbsn.unsubscribe();
		}
	}

	doCloseOrderDetailCreditMemoPopup(e) {
		this.isShowPopup = false;
		this.viewOrderChangeOrderPopup.emit({ isSuccess: true });
	}

	onInitOrder() {
		this.isShowDataGrid = true;
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}

		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		this.listOfSalesPpl = this._DataService.getSalesPpl() || [];
		this.uomList = this._DataService.getUOMs();
		this.globalCurrency = this._LoginService.globalCurrency;
		this.setIsShowIxcodeOrDocnum();
		this.setDecimalNumberFormat();
		this.doCallOrderEntity();
	}

	displayPhoneNoWithCode(element) {
		let phonenumberstr = "";
		phonenumberstr = element.phone1 || "";
		if (element.dialcode) {
			const getPhoneCodeData = this._DataService.getSplitPhoneCode(
				element.dialcode
			);
			if (getPhoneCodeData && getPhoneCodeData.length > 0) {
				phonenumberstr = getPhoneCodeData[0].code + " " + element.phone1;
			}
		}
		return phonenumberstr;
	}

	// Method used to Show ixode or docnum with synced
	private setIsShowIxcodeOrDocnum() {
		this.isShowClassesColumn = false;
		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			this.isShowClassesColumn = true;
		}
	}

	private doCallOrderEntity() {
		this.orderChangeOrderDetail = {};
		this.orderChangeOrderDetail.contactpersondata = undefined;
		this.orderChangeOrderDetail.salespersondata = undefined;
		this.searchParams = [];
		this.searchParams.push({ 'docnum': this.viewOrderChangeOrderDetailProps.docNo });
		const ordersFormData = new FormData();
		ordersFormData.append('usr', this._LoginService.loginUser.user);
		ordersFormData.append('token', this._LoginService.loginUser.token);
		ordersFormData.append('entity', ServerEntity.ORDERS);
		ordersFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		ordersFormData.append('search', JSON.stringify(this.searchParams));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show(this.loaderConfig);

		this.isResponse = false;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(ordersFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				this.isResponse = true;
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const orderChangeOrderDetail = response.data[0];
						this.orderChangeOrderDetail = orderChangeOrderDetail;
						this.isShowCostColumn = false;
						this.isEnabledMasterDetails = false;
						this.doCheckEnableProfitOnDocFeature();
						if (this.orderChangeOrderDetail.documentlines && this.orderChangeOrderDetail.documentlines.length > 0) {
							this.orderChangeOrderDetail.documentlines.forEach(dl => {
								if (dl.costassignments && dl.costassignments.length > 0) {
									this.isEnabledMasterDetails = true;
								}
								if (dl.costperitem !== 0) {
									this.isShowCostColumn = true;
								}
								if (dl.assigned_date && dl.costassignment_id) {
									this.isShowCostColumn = true;
								}
							});
						}

						this.isShowBatchSerialField = false;
						this.isShowEligibleField = false;
						if (this.orderChangeOrderDetail.hasOwnProperty('eligible')) {
							this.isShowEligibleField = true;
						}

						this.isShowEligibleColumn = orderChangeOrderDetail.documentlines.some(dl => {
							return dl.hasOwnProperty('eligible');
						});

						this.isMultipleSalesReps = false;
						if (this.orderChangeOrderDetail.hasOwnProperty('salesreps')) {
							this.isMultipleSalesReps = true;
						}

						this.searchParams = [];
						if (orderChangeOrderDetail.guidclient) {
							this.searchParams.push({ 'guid': orderChangeOrderDetail.guidclient });
						} else if (orderChangeOrderDetail.cardcode) {
							this.searchParams.push({ 'code': orderChangeOrderDetail.cardcode });
						}
						// reqFormData = this.loadSalesDetailsData();
						const clientsFormData = new FormData();
						clientsFormData.append('usr', this._LoginService.loginUser.user);
						clientsFormData.append('token', this._LoginService.loginUser.token);
						clientsFormData.append('entity', ServerEntity.CLIENTS);
						clientsFormData.append('method', ServerMethods.GET_ENTITY_DATA);
						// clientsFormData.append('is_dropdown', 'true');
						clientsFormData.append('search', JSON.stringify(this.searchParams));
						if (this.dataOutReqSubscription) {
							this.dataOutReqSubscription.unsubscribe();
						}
						this._LoaderService.show(this.loaderConfig);
						this.isResponse = false;
						this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(clientsFormData).subscribe({
							next: (response) => {
								this.isResponse = true;
								this._LoaderService.hide();
								if (response.flag) {
									if (response.data && response.data.length > 0) {
										const client = response.data[0];
										// Find Contact Person Details
										if (client.contactemployees && client.contactemployees.length > 0) {
											const contactPplData = client.contactemployees.filter(itemContact => itemContact.internalcode === orderChangeOrderDetail.contactpersoncode);
											this.orderChangeOrderDetail.contactpersondata = contactPplData && contactPplData.length > 0 ? contactPplData[0] : undefined;
										}
										// Set Billing/Shipping Address
										if (this.orderChangeOrderDetail.contactpersondata === undefined) {
											this.orderChangeOrderDetail.contactpersondata = {};
										}
										if (client && client.human_readable_account_code) {
											this.orderChangeOrderDetail.human_readable_account_code = client.human_readable_account_code;
										}
										this.setBillingAddress(client);
										this.setShippingAddress(client);
										this.fetchedListOfPrices(client.pricelistnum);
									} else {
										this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
									}
								} else {
									this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
								}
							}, error: error => {
								this._LoaderService.hide();
								this.isResponse = true;
								this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
						});
					} else {
						this.orderChangeOrderDetail = undefined;
					}
					this.getInvoiceIncentiveDetails();
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this.isResponse = true;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private getInvoiceIncentiveDetails() {
		const searchParams = [{ 'docentry': this.orderChangeOrderDetail.docentry }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.INVOICEINCENTIVES);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('search', JSON.stringify(searchParams));
		if (this.getInvoiceIncentivesSbsn) {
			this.getInvoiceIncentivesSbsn.unsubscribe();
		}

		this.getInvoiceIncentivesSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const listOfIncentives = response.data[0].incentives;
						let totalIncentivePaid = 0;
						listOfIncentives.forEach(incentive => {
							totalIncentivePaid += incentive.incentive_paid;
						});
						this.orderChangeOrderDetail.totalIncentivePaid = totalIncentivePaid;
					}
				}
			}, error: (error) => {

			}
		});
	}


	//  Method used to check Erp Type
	private setErpType() {
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erpType = '';
		}

		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}

		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		let tracknoSeparator: any;
		try {
			tracknoSeparator = this._LoginService.loginUser.account_detail.trackno_separator;
		} catch (e) {
			tracknoSeparator = undefined;
		}
		if (tracknoSeparator) {
			try {
				this.arrTrackNoSeparator = tracknoSeparator.split('');
			} catch (e) {
				this.arrTrackNoSeparator = [];
			}
		}
		this.isShowShippingTrackDetails = false;
		if (this.erpType === ErpTypeValEnum.SAPB1_HANA || this.erpType === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
			this.isShowShippingTrackDetails = true;
		}
	}

	private doVisiblePickStatusField() {
		this.isShowPickStatusField = false;
		if (this.erpType === ErpTypeValEnum.SAPB1_HANA || this.erpType === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
			this.isShowPickStatusField = true;
		}
	}

	// Method used to Hide/Show Discount fields
	private doVisibleDiscountField() {
		this.isShowDiscountField = true;
		if (this.isQBErpUser) {
			this.isShowDiscountField = false;
		}
	}


	// Method To Set billing Address
	private setBillingAddress(client: any) {
		if (this.orderChangeOrderDetail.paytocode && client.address && client.address.billto && client.address.billto.length > 0) {
			const clientAddressData = client.address.billto.filter(itemAddress => itemAddress.addressname === this.orderChangeOrderDetail.paytocode);
			this.orderChangeOrderDetail['contactpersondata']['billtodata'] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.orderChangeOrderDetail.contactpersondata["billtodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Set billing Address
	private setShippingAddress(client: any) {
		if (this.orderChangeOrderDetail.shiptocode && client.address && client.address.shipto && client.address.shipto.length > 0) {
			const clientAddressData = client.address.shipto.filter(itemAddress => itemAddress.addressname === this.orderChangeOrderDetail.shiptocode);
			this.orderChangeOrderDetail['contactpersondata']["shipptodata"] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.orderChangeOrderDetail.contactpersondata["shipptodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Get List of Prices from Clients's pricelistnum
	private fetchedListOfPrices(pricelistnum?: string) {
		this.listOfPrices = [];
		if (!pricelistnum) {
			return;
		}
		const viewFields = ['pricelistno', 'pricelistname'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.PRICELISTS);
		// formdata.append('is_dropdown', '1');
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		// this._LoaderService.show(this.loaderConfig);
		this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: response => {
				// this._LoaderService.hide();
				this.listOfPrices = [];
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data;
					} else {
						this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				// this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doToolbarPreparing(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel();
					}
				}
			}
		);
	}

	private doExportToExcel() {
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Order_' + this.orderChangeOrderDetail.ixcode + '.xlsx';
		worksheet = workbook.addWorksheet('Order');

		exportDataGrid({
			worksheet: worksheet,
			component: this.itemsListOriginDatagridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'itemname') {
						if (gridCell.data.itemname) {
							excelCell.value = gridCell.data.itemname;
						}
					}
					if (gridCell.column.dataField === 'pickstatus') {
						if (gridCell.data.pickstatus && gridCell.data.pickstatus.toString().toLowerCase() === 'tyes') {
							excelCell.value = 'Yes';
						} else {
							excelCell.value = 'No';
						}
					}
					if (gridCell.column.dataField === 'price') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
					if (gridCell.column.dataField === 'linetotal') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
				}
			}
		}).then((cellRange) => {
			// header
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			if (this.orderChangeOrderDetail.address) {
				worksheet.mergeCells(2, 4, 2, 7);
			}
			headerRow.getCell(1).value = 'Order ID:';
			headerRow.getCell(1).font = { 'bold': true };
			let orderIdValue = this.orderChangeOrderDetail.docnum;
			headerRow.getCell(2).value = orderIdValue;
			headerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.orderChangeOrderDetail.address) {
				headerRow.getCell(3).value = 'Billing Address';
				headerRow.getCell(3).font = { 'bold': true };
				headerRow.getCell(4).value = this.orderChangeOrderDetail.address || '';
			}

			// customer
			const customerRow = worksheet.getRow(3);
			if (this.orderChangeOrderDetail.address2) {
				worksheet.mergeCells(3, 4, 3, 7);
			}
			customerRow.height = 30;
			customerRow.getCell(1).value = 'Customer';
			customerRow.getCell(1).font = { 'bold': true };
			let cardCode = '';
			if (!this.isQBErpUser) {
				cardCode = this.orderChangeOrderDetail.cardcode || '';
			}
			customerRow.getCell(2).value = (cardCode ? cardCode + ' - ' : '') + this.orderChangeOrderDetail.cardname;
			customerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.orderChangeOrderDetail.address2) {
				customerRow.getCell(3).value = 'Shipping Address';
				customerRow.getCell(3).font = { 'bold': true };
				customerRow.getCell(4).value = this.orderChangeOrderDetail.address2 || '';
			}

			// DocDate
			const docDateRow = worksheet.getRow(4);
			docDateRow.height = 30;
			docDateRow.getCell(1).value = 'Order Date';
			docDateRow.getCell(1).font = { 'bold': true };
			docDateRow.getCell(2).value = this.orderChangeOrderDetail.docdate;
			docDateRow.getCell(2).alignment = { horizontal: 'left' };

			// DocdueDate
			const docdueDateRow = worksheet.getRow(5);
			docdueDateRow.height = 30;
			docdueDateRow.getCell(1).value = 'Order Due Date';
			docdueDateRow.getCell(1).font = { 'bold': true };
			docdueDateRow.getCell(2).value = this.orderChangeOrderDetail.docduedate;
			docdueDateRow.getCell(2).alignment = { horizontal: 'left' };

			// Order Status
			const documentStatusRow = worksheet.getRow(6);
			documentStatusRow.height = 30;
			documentStatusRow.getCell(1).value = 'Order Status';
			documentStatusRow.getCell(1).font = { 'bold': true };
			let orderStatus = '';
			if (this.orderChangeOrderDetail.documentstatus.toString().toLowerCase() === 'bost_open') {
				orderStatus = 'Open';
			} else {
				orderStatus = 'Closed';
			}
			documentStatusRow.getCell(2).value = orderStatus;
			documentStatusRow.getCell(2).alignment = { horizontal: 'left' };
			let numberOfRows = this.itemsListOriginDatagridRef.instance.getVisibleRows().length;

			// Sub Total
			let rowIncrement = 11;
			let subTotal = 0;
			const subTotalRow = worksheet.getRow(numberOfRows + rowIncrement);
			subTotalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				subTotalRow.getCell(5).value = 'Sub Total';
				subTotalRow.getCell(5).font = { 'bold': true };
				subTotalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.doctotalsys);
				subTotalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.orderChangeOrderDetail.totaldiscount > 0) {
				// Discount
				let discount = 0;
				rowIncrement = rowIncrement + 1;
				const discountRow = worksheet.getRow(numberOfRows + rowIncrement);
				discountRow.height = 30;
				discountRow.getCell(6).value = 'Discount';
				discountRow.getCell(6).font = { 'bold': true };
				discountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.totaldiscount);
				discountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.orderChangeOrderDetail.totalmiscamount > 0) {
				// Miscellaneous Charge
				let totalmiscamount = 0;
				rowIncrement = rowIncrement + 1;
				const totalmiscamountRow = worksheet.getRow(numberOfRows + rowIncrement);
				totalmiscamountRow.height = 30;
				totalmiscamountRow.getCell(6).value = 'Miscellaneous Charge';
				totalmiscamountRow.getCell(6).font = { 'bold': true };
				totalmiscamountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.totalmiscamount);
				totalmiscamountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.orderChangeOrderDetail.shippingcharges > 0) {
				// Frieght Charge
				let shippingcharges = 0;
				rowIncrement = rowIncrement + 1;
				const shippingchargesRow = worksheet.getRow(numberOfRows + rowIncrement);
				shippingchargesRow.height = 30;
				shippingchargesRow.getCell(6).value = 'Frieght Charge';
				shippingchargesRow.getCell(6).font = { 'bold': true };
				shippingchargesRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.shippingcharges);
				shippingchargesRow.getCell(7).alignment = { horizontal: 'right' };
			}

			// Total
			let total = 0;
			rowIncrement = rowIncrement + 1;
			const totalRow = worksheet.getRow(numberOfRows + rowIncrement);
			totalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				totalRow.getCell(5).value = 'Total';
				totalRow.getCell(5).font = { 'bold': true };
				totalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail.doctotal);
				totalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			// Remark
			const remarkRow = worksheet.getRow(numberOfRows + 13);
			totalRow.height = 30;
			remarkRow.getCell(1).value = 'Remark';
			remarkRow.getCell(1).font = { 'bold': true };
			remarkRow.getCell(2).value = this.orderChangeOrderDetail.comments || '';
			remarkRow.getCell(2).alignment = { horizontal: 'right' };

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	public doRowPreparedDocLine(e: any) {
		if (e.rowType == "data" && (!e.data.costassignments || e.data.costassignments && e.data.costassignments.lenght <= 0)) {
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
			}
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
			}
		}
	}

	public doOriginalOrderDetails() {
		const sourceDocNum = this.orderChangeOrderDetail.source_docnum;

		const ordersFormData = new FormData();
		ordersFormData.append('usr', this._LoginService.loginUser.user);
		ordersFormData.append('token', this._LoginService.loginUser.token);
		ordersFormData.append('entity', ServerEntity.ORDERS);
		ordersFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		// ordersFormData.append('is_dropdown', 'true');
		ordersFormData.append('search', JSON.stringify([{ docnum: sourceDocNum }]));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show(this.loaderConfig);
		this.isResponse = false;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(ordersFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.isShowOriginalOrderDetails = true;
					if (response.data && response.data.length > 0) {
						const orderChangeOrderDetail = response.data[0];
						this.originalOrderDetails = orderChangeOrderDetail;
					}
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}

	private setDecimalNumberFormat() {
		let decimalPoint = 2;
		try {
			decimalPoint = this._LoginService.loginUser.account_detail.app_settings.decimal_point || 2;
		} catch (e) {
			decimalPoint = 2;
		}
		this.decimalNumberFormat = ('1.' + decimalPoint + '-' + decimalPoint).toString();
	}

	private doCheckEnableProfitOnDocFeature() {
		this.isEnableProfitOnDocHeaderFeature = false;
		try {
			this.isEnableProfitOnDocHeaderFeature = this._LoginService.loginUser.account_detail.enable_profit_on_document_header;
		} catch (e) {
			this.isEnableProfitOnDocHeaderFeature = false;
		}
		if (this.isEnableProfitOnDocHeaderFeature) {
			this.doGetFetureCustomFields();
		}
	}

	public doGetFetureCustomFields() {
		this.listOfFeatureCustomFields = this._AppCommonSrvc.getFeatureCustomFields();
		this.setProfitOnDocHeaderFeature();
	}

	private setProfitOnDocHeaderFeature() {
		this.specificListOfProfitOnDocHeader = [];
		this.listOfFeatureCustomFields.forEach(custField => {
			if (this.orderChangeOrderDetail && this.orderChangeOrderDetail.hasOwnProperty(custField.ix_custom_field)) {
				const newCustFields: any = {
					guid: custField.guid,
					custom_value: null,
					custom_display_value: null,
					custom_label: null
				}
				newCustFields.custom_label = custField.ix_custom_field_title;
				if (custField.custom_field_title) {
					newCustFields.custom_label = custField.custom_field_title;
				}
				newCustFields.custom_value = this.orderChangeOrderDetail[custField.ix_custom_field];
				if (custField.custom_field_output_format === 'percentage') {
					newCustFields.custom_display_value = this._DecimalPipe.transform(this.orderChangeOrderDetail[custField.ix_custom_field] || 0, this.decimalNumberFormat) + '%';
				}
				if (custField.custom_field_output_format === 'amount') {
					newCustFields.custom_display_value = this._CustomCurrencyPipe.transform(this.orderChangeOrderDetail[custField.ix_custom_field] || 0);
				}
				if (custField.custom_field_output_format === 'boolean') {
					newCustFields.custom_display_value = 'No';
					if (this.orderChangeOrderDetail[custField.ix_custom_field]) {
						newCustFields.custom_display_value = 'Yes';
					}
				}
				if (custField.custom_field_output_format === 'text' || custField.custom_field_output_format === 'string') {
					if (this.orderChangeOrderDetail[custField.ix_custom_field]) {
						newCustFields.custom_display_value = this.orderChangeOrderDetail[custField.ix_custom_field];
					}
				}
				if (custField.custom_field_output_format === 'object') {
					newCustFields.custom_display_value = '';
					const customDataObj = this.orderChangeOrderDetail[custField.ix_custom_field];
					if (customDataObj && customDataObj.hasOwnProperty('refName')) {
						newCustFields.custom_display_value = customDataObj.refName;
					}
				}
				newCustFields.display = custField.display;
				if (custField.display) {
					this.specificListOfProfitOnDocHeader.push(newCustFields);
				}
			}
		});
	}
}
