import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '@app/services/login.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root'
})

export class AuthGuardService implements CanActivate, CanActivateChild {

	access_rights_arr: any = [
		"INCENTIVE_VIEW",
		"INCENTIVE_COMMISSION_VIEW",
		"INCENTIVE_COMMISSION_MODIFY",
		"INCENTIVE_BILLBACKCHARGEBACK_VIEW",
		"INCENTIVE_BILLBACKCHARGEBACK_MODIFY",
		"INCENTIVE_ROYALTIES_VIEW",
		"INCENTIVE_ROYALTIES_MODIFY",
		"INCENTIVE_REBATES_VIEW",
		"INCENTIVE_REBATES_MODIFY",
		"CRM_VIEW",
		"CRM_LEAD_VIEW",
		"CRM_LEAD_MODIFY",
		"CRM_CUSTOMER_VIEW",
		"CRM_CUSTOMER_MODIFY",
		"CRM_PROSPECT_VIEW",
		"CRM_PROSPECT_MODIFY",
		"CRM_TASK_VIEW",
		"CRM_TASK_MODIFY",
		"SALES_VIEW",
		"SALES_QUOTATION_VIEW",
		"SALES_QUOTATION_MODIFY",
		"SALES_ORDER_VIEW",
		"SALES_ORDER_MODIFY",
		"SALES_ARINVOIECES_VIEW",
		"SALES_ARCREDITMEMO_VIEW",
		"USERMANAGEMENT_VIEW",
		"USERMANAGEMENT_MODIFY",
		"USERMANAGEMENT_USER_VIEW",
		"USERMANAGEMENT_USER_MODIFY",
		"BUSINESSPARTNERS_VIEW",
		"BUSINESSPARTNERS_MODIFY",
		"BUSINESSPARTNERS_CUSTOMER_VIEW",
		"BUSINESSPARTNERS_VENDORS_VIEW",
		"BUSINESSPARTNERS_BUYINGGROUPS_VIEW",
		"BUSINESSPARTNERS_BUYINGGROUPS_MODIFY",
		"BUSINESSPARTNERS_ENDUSER_VIEW",
		"BUSINESSPARTNERS_ENDUSER_MODIFY",
		"USERMANAGEMENT_ROLE_VIEW",
		"USERMANAGEMENT_ROLE_MODIFY",
		"APP_SETTING_VIEW",
		"EXTERNAL_SYSTEM_CONFIG_VIEW",
		"EXTERNAL_SYSTEM_CONFIG_MODIFY",
		"FISCAL_CALCULATION_OPTIONS_VIEW",
		"FISCAL_CALCULATION_OPTIONS_MODIFY",
		"PRODUCTS_VIEW",
		"PRODUCTS_PRODUCT_LIST_VIEW",
		"PRODUCTS_PRODUCT_LIST_MODIFY",
		"PRODUCTS_PRICE_LIST_VIEW",
		"PRODUCTS_PRICE_LIST_MODIFY",
		"REPORTS_VIEW",
		"REPORTS_YEAR_TO_DATE_VIEW",
		"REPORTS_YEAR_OVER_YEAR_VIEW",
		"REPORTS_PERIOD_TO_DATE_VIEW"
	];
	
	constructor(private _Router: Router,
		private _LoginService: LoginService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _ToastrService: ToastrService) {
	}
	
	canActivate(next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return new Promise((resolve, reject) => {
			if (!this._LoginService.loginUser) {
				this._Router.navigate(['auth/login'], { replaceUrl: true });
				resolve(false);
			} else {

				// user Role rights
				if (this._LoginService.loginUser.account_detail) {
					const currentUserRole = this._LoginService.loginUser.account_detail.role || '';
					if (currentUserRole == 'SUPER_ADMIN') {
						this._NgxPermissionsService.loadPermissions(['SUPER_ADMIN']);
						resolve(true);
					}
					let currentUserPermissions = this._LoginService.loginUser.account_detail.access_rights || [];
					//const currentUserPermissions = this.access_rights_arr || [];
					const routePermission = next.data.permission || null;

					if (routePermission == null || currentUserPermissions.indexOf(routePermission) != -1) {
						this._NgxPermissionsService.loadPermissions(currentUserPermissions);
						resolve(true);
					} else {
						this._ToastrService.info('You will need to provide administrator permission !', 'Permission', { closeButton: true, tapToDismiss: true });
						resolve(false);
					}
				} else {
					this._Router.navigate(['auth/login'], { replaceUrl: true });
					resolve(true);
				}
				// end user Role rights

			}
		});
	}

	/*
	* Authentication check for child routes, In case we want to protect only child route.
	*/
	canActivateChild(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this.canActivate(route, state);
	}

	async clearStorage() {
		this._LoginService.loginUser.user = '';
		this._LoginService.loginUser.pwd = '';
		this._LoginService.loginUser.token = '';
	}
}
