<div class="contentRow cf">
	<!-- <div class="titleRow">
		<h1>Settlement Report List</h1>
	</div> -->
	<div class="borderBox cf padB0">
		<div class="row">
			<div class="cols9 mrgT10">
				<ul class="listText">
					<li>Contract Name:
						<strong class="purple">{{rebatesDetails.contractName || ''}}</strong>
					</li>
					<li
						*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive' || rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'">
						Customer Name:
						<strong class="purple">{{rebatesDetails.selectCustomer || ''}}</strong>
					</li>
					<li
						*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive')  && rebatesDetails.rebateType == 'purchasing'">
						Vendor Name:
						<strong class="purple">{{rebatesDetails.selectVendor || ''}}</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'buyinggroup'"> Buying Group:
						<strong class="purple">{{rebatesDetails.selectBuyingGroup || ''}}</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'individual'"> Type:
						<strong class="purple">Individual
							<span *ngIf="rebatesDetails.rebateType == 'sales'">(Sales Rebate)</span>
							<span *ngIf="rebatesDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
						</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'growth_incentive'"> Type:
						<strong class="purple">Growth Incentive
							<span *ngIf="rebatesDetails.rebateType == 'sales'">(Sales Rebate)</span>
							<span *ngIf="rebatesDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
						</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'distribution'"> Type:
						<strong class="purple">Distribution
							<span *ngIf="rebatesDetails.rebateType == 'sales'">(Sales Rebate)</span>
							<span *ngIf="rebatesDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
						</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'distribution_differential'"> Type:
						<strong class="purple">Distribution Differential
							<span *ngIf="rebatesDetails.rebateType == 'sales'">(Sales Rebate)</span>
							<span *ngIf="rebatesDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
						</strong>
					</li>
				</ul>
			</div>
			<div class="cols3 mrgB20 txtRight">
				<div class="multiBtnsRow mrgT0">
					<div class="btnsGroup">
						<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn"
							text="Update Current Settlement" (onClick)="doUpdateSettlementPeriodClick()"
							[useSubmitBehavior]="false">
						</dx-button>
					</div>
				</div>
			</div>
		</div>
		<div class="cf">
			<div class="tableOut">

				<dx-data-grid #dataGridContainer id="gridContainer" class="cellColor hoverClr hideFilterPanelInx"
					[dataSource]="closeSettlementDataSource" [showBorders]="true" [showColumnLines]="true"
					[showRowLines]="false" [dateSerializationFormat]="'yyyy-MM-dd'" [allowColumnResizing]="true"
					[filterSyncEnabled]="true" [remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'"
					(onToolbarPreparing)="doToolbarPreparing($event)" (onRowClick)="doRowClickSettlementDetails($event.data)"
					(onCellClick)="doCellClickSettlementDetails($event)">
					<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
					<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
					</dxo-pager>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="true"></dxo-filter-panel>
					<dxo-header-filter [visible]="true"></dxo-header-filter>
					<dxo-sorting [mode]="'none'"></dxo-sorting>

					<div *dxTemplate="let data of 'templateFilterText'">
						<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
							<span>{{filterText}}</span>
						</div>
					</div>

					<dxi-column dataField="ixcode" caption="Settlement No." dataType="string">
					</dxi-column>

					<dxi-column dataField="settlementPeriodStart" alignment="center" caption="Start Date" dataType="date"
						format="yyyy-MM-dd" cellTemplate="cellTemplateSettlementPeriodStart">
						<div *dxTemplate="let element of 'cellTemplateSettlementPeriodStart'" class="documentStatusColor"
							[ngClass]="{'purpleClrBefore':!element.data.isSettlementPeriodClosed}">
							{{ element.data.settlementPeriodStart || "" }}
						</div>
					</dxi-column>

					<dxi-column dataField="settlementPeriodEnd" alignment="center" caption="End Date" dataType="date"
						format="yyyy-MM-dd" cellTemplate="cellTemplateSettlementPeriodEnd">
						<div *dxTemplate="let element of 'cellTemplateSettlementPeriodEnd'">
							{{ element.data.settlementPeriodEnd || "" }}
						</div>
					</dxi-column>

					<dxi-column dataField="settlementValue" [caption]="'Rebate Amount ('+globalCurrencySymbol+')'"
						dataType="number" [width]="220" [calculateDisplayValue]="getCurrencyDisplayValue">

					</dxi-column>

					<dxi-column dataField="creditMemoCreatedInERP" caption="Settlement Status" alignment="center"
						cellTemplate="cellTemplateStatus" dataType="string" [allowFiltering]="false"
						[allowHeaderFiltering]="true">
						<dxo-header-filter [dataSource]="listOfSettlementStatuses"></dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateStatus'">
							<!-- <span
								[ngClass]="{'orange':element.data.creditMemoCreatedInERP === 'Processing','purple':element.data.creditMemoCreatedInERP === 'Open','red':element.data.creditMemoCreatedInERP === 'Error','green':element.data.creditMemoCreatedInERP === 'Success'}">{{element.data.creditMemoCreatedInERP}}</span> -->
							<div
								[ngClass]="element.data.creditMemoCreatedInERP || '' | filterFromList:listOfSettlementStatuses : 'value' : 'classname'">
								{{element.data.creditMemoCreatedInERP || '' |
								filterFromList:listOfSettlementStatuses : 'value' : 'text'}}
							</div>
						</div>
					</dxi-column>

					<dxi-column dataField="approval_status" caption="Approval Status" [visible]="isShowApprovalStatusColumn"
						alignment="center" cellTemplate="cellTemplateApprovalStatus" dataType="string"
						[allowFiltering]="false" [allowHeaderFiltering]="true">
						<dxo-header-filter [dataSource]="listOfApprovalStatuses"></dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateApprovalStatus'">
							<div
								[ngClass]="element.data.approval_status || '' | filterFromList:listOfApprovalStatuses : 'value' : 'classname'">
								{{element.data.approval_status || '' |
								filterFromList:listOfApprovalStatuses : 'value' : 'text'}}
							</div>
						</div>
					</dxi-column>

					<dxi-column dataField="errorMessage" caption="Remark" dataType="string" [allowHeaderFiltering]="false"
						[allowSorting]="false">
					</dxi-column>

					<dxi-column dataField="guid" caption="Approval Trails" [visible]="isShowApprovalStatusColumn"
						alignment="center" cellTemplate="cellTemplateApprovalTrails" dataType="string" [width]="'120px'"
						[allowResizing]="false" [allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"
						[allowExporting]="false" [minWidth]="'120px'">
						<div *dxTemplate="let element of 'cellTemplateApprovalTrails'">
							<label *ngIf="element.data.approval_status !== RebateApprovalStatusValEnum.DRAFT"
								(click)="doViewApprovalTrails(element.data)" class="pointerMark"
								id="viewApprovalTrail_{{element.rowIndex + 1}}">
								<span class="viewIcon sprite"></span>
							</label>
							<dx-tooltip target="#viewApprovalTrail_{{element.rowIndex + 1}}" showEvent="dxhoverstart"
								hideEvent="dxhoverend" position="bottom">
								<div *dxTemplate="let data = data of 'content'">
									View Approval Trails
								</div>
							</dx-tooltip>
						</div>
					</dxi-column>


					<!-- <dxi-column caption="Action" alignment="center" dataType="string" cellTemplate="cellTemplateAction"
					[visible]="isColumneVisible" [width]="85" [minWidth]="85"  [allowFiltering]="false" [allowExporting]="false" [allowSorting]="false" [allowResizing]="false">
					<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
						<a id="viewSettlementReport_{{element.rowIndex+1}}"
							(click)="viewRebatesCalculation(element.data)"><span class="viewIcon sprite"></span></a>
							<dx-tooltip target="#viewSettlementReport{{element.rowIndex + 1}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
								position="bottom">
								<div *dxTemplate="let data = data of 'content'">
									View Settlement Report
								</div>
							</dx-tooltip>
					</div>
				</dxi-column> -->

					<dxo-summary>
						<dxi-total-item column="settlementValue" summaryType="sum" displayFormat="{0}"
							[customizeText]="doCustomizeTotalSummary">
							<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format> -->
						</dxi-total-item>
					</dxo-summary>
				</dx-data-grid>
			</div>
		</div>
	</div>
</div>

<app-rebates-settlement-approval-trails-dialog *ngIf="isShowRebateSettlementApprovalTrailsPopup"
	[approvalTrailDetailsProps]="approvalTrailDetailsProps"></app-rebates-settlement-approval-trails-dialog>