<div class="numberBlock hoverClr" (click)="tilesClick('PROSPECTS')">
	<span class="contractIcon">
		<span class="icon-prospects icon-set1"></span>
	</span>
	<div class="blockText">
		<strong>
			<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
			<span *ngIf="!resourcesLoaded">{{openProspects || 0 | customNumberSuffixPipe}}</span>
		</strong>
		<span class="blockDate">Active</span> Prospects
	</div>
</div>