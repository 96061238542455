<!--#region Categories content -->
<div class="">
	<dx-data-grid #dataGridContainerOfChargebackTypesTab id="gridContainer" [dataSource]="chargebackTypesTabDataSource"
		keyExpr="guid" [allowColumnReordering]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
		[allowColumnResizing]="true" [remoteOperations]="true" [columnAutoWidth]="true"
		(onRowInserted)="addAndUpdateCategoriesAgreementTabDetails($event)"
		(onRowUpdated)="addAndUpdateCategoriesAgreementTabDetails($event)"
		(onRowRemoved)="addAndUpdateCategoriesAgreementTabDetails($event)"
		(onInitNewRow)="onInitNewRowCategoriesAgreement($event)"
		(onToolbarPreparing)="doCategoriesToolbarPreparing($event)" [width]="'100%'">
		<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
		<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
			[showInfo]="true">
		</dxo-pager>
		<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false" [useIcons]="true">
		</dxo-editing>
		<dxi-column dataField="guid" [visible]="false"></dxi-column>
		<dxi-column dataField="categoryname" caption="Chargeback Types" [setCellValue]="doChargebackCategorySetCellValue">
			<dxo-lookup [dataSource]="chargebackTypesDataSource" displayExpr="categoryname" valueExpr="categoryname">
			</dxo-lookup>
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-column>
		<dxi-column dataField="description" [allowEditing]="false" caption="Description">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-column>
		<dxi-column dataField="rate" dataType="number" caption="Rate/Amount" [width]="120" alignment="center"
			cellTemplate="cellTemplateRateAmount">
			<dxo-format type="currency" [precision]="decimalPointForCurrency">
			</dxo-format>
			<dxi-validation-rule type="required">
				<!-- </dxi-validation-rule>
							<dxi-validation-rule type="range" min="0" max="100" message="Rate must be between 0 and 100">
							</dxi-validation-rule>
							<dxi-validation-rule type="pattern" pattern="\d+(\.\d{1,2})?" message="Only use digits.">-->
			</dxi-validation-rule>
			<div *dxTemplate="let element of 'cellTemplateRateAmount'">
				<span *ngIf="element.data.percentage_or_amount === 'amount'">
					{{element.data.rate || 0 | customCurrencyPipe }}
				</span>
				<span *ngIf="element.data.percentage_or_amount === 'percentage'">
					{{(element.data.rate || 0 | number:decimalPointPercentageFormat) + '%' }}
				</span>
			</div>
		</dxi-column>
		<dxi-column dataField="percentage_or_amount" dataType="string" [caption]="'% / '+ globalCurrencySymbol"
			[width]="130" alignment="center">
			<dxo-lookup [dataSource]="percentage_or_amount_radio_values" valueExpr="key" displayExpr="value">
			</dxo-lookup>
		</dxi-column>
		<dxi-column dataField="is_invoicebased" [width]="120" caption="Invoice-based" alignment="center"
			dataType="boolean">
		</dxi-column>
		<dxi-column dataField="is_itembased" [width]="120" caption="Item-based" alignment="center" dataType="boolean">
		</dxi-column>
		<dxi-column dataField="is_disputable" [width]="120" caption="Disputable" alignment="center" dataType="boolean">
		</dxi-column>
		<dxi-column dataField="is_recoverable" [width]="120" caption="Recoverable" alignment="center" dataType="boolean">
		</dxi-column>

		<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
			[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
			[allowExporting]="false">
			<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip"></dxi-button>
			<dxi-button name="delete" cssClass="dx-grid-delete dx-icon-trash menuTip"></dxi-button>
		</dxi-column>

	</dx-data-grid>
</div>
<!--#endregion-->