import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import { DxTextBoxComponent } from 'devextreme-angular';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() forogtPasswordProps: any;
	@Output() closeForgotPasswordPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup = false;
	public popupTitleText: string;
	@ViewChild('emailTextBoxRef') emailTextBoxRef: DxTextBoxComponent;
	public emailID: string = '';
	private doDataInReqReqSbsn: Subscription;
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _LoaderService: LoaderService) { }

	ngOnInit() {
		this.emailID = '';
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.popupTitleText = 'Forgot Password';
		this.isShowPopup = true;
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.emailTextBoxRef) {
				this.emailTextBoxRef.instance.focus();
			}
		}, 300);
	}

	ngOnDestroy() {
		if (this.doDataInReqReqSbsn) {
			this.doDataInReqReqSbsn.unsubscribe();
		}
	}

	public doInitializedEmailTextBox(e) {
		e.component.reset();
		setTimeout(() => {
			e.component.focus();
		}, 500);
	}

	public doHiddenPopup(event) {
		this.closeForgotPasswordPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}

	public doSendEmail() {
		if (!this.emailID || (this.emailID && !this.emailID.trim())) {
			this.emailID = this.emailID.toString().trim();
			this._ToastrService.info('Email ID is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		const formData = new FormData();
		formData.append('method', 'generateForgotLink');
		formData.append('user', this.emailID);

		if (this.doDataInReqReqSbsn) {
			this.doDataInReqReqSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.doDataInReqReqSbsn = this._RestApiService.doforgotPasswordReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response.flag) {
				this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
				this.closeForgotPasswordPopup.emit(true);
				this.isShowPopup = false;
			} else {
				this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}
}
