import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'customFileSizeFormat'
})
export class CustomFileSizeFormatPipe implements PipeTransform {

	transform(value: any, decimalsPoint?: number): any {
		const bytes = value || 0;
		if (!+bytes) {
			return '0 Bytes';
		}

		const k = 1024;
		const dm = decimalsPoint < 0 ? 0 : decimalsPoint;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}
