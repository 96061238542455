<div class="sideBar sideBarClosed">
	<!-- <div class="userProfile">
		<div class="userImage">
			<span class="">{{loginUser.account_detail.first_name | slice:0:1 |uppercase}}{{loginUser.account_detail.last_name | slice:0:1 |uppercase}}</span>
		</div>
		<div class="userDetail">
			<h3 *ngIf="loginUser.account_detail">
				{{loginUser.account_detail.first_name}} {{loginUser.account_detail.last_name}}
			</h3>
			<h4 *ngIf="loginUser.account_detail">{{loginUser.account_detail.role_name}}</h4>
	  </div>
	  <span class="icon-toggleDown toggleSub"></span>
		<div class="userDtlToggle toggleIt">
			<a class="editProfile" (click)="sideMenuClick('userprofile')"></a>
			<h3 *ngIf="loginUser.account_detail">
				{{loginUser.account_detail.first_name}} {{loginUser.account_detail.last_name}}
			</h3>
			<h4 *ngIf="loginUser.account_detail">{{loginUser.account_detail.role_name}}</h4>
			<ul class="usrContact">
				<li>
					<a>
						<span class="contIcon mobileIcon"></span> +{{loginUser.account_detail.mobile}}
					</a>
				</li>
				<li>
					<a>
						<span class="contIcon mailIcon"></span> {{loginUser.account_detail.email}}
					</a>
				</li>

				<li>
					<a (click)="logout()">
						<span class="icon-logout-new icon-set1"></span> Logout
					</a>
				</li>

			</ul>
		</div>
	</div> -->

	<ul class="navigation">
		<li (click)="sideMenuClick('dashboard')"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'dashboard'}">
			<a>
				<span class="navallIcon icon-icon-1"></span>
				<span class="menu-title">Dashboard</span>
			</a>
			<ul class="subMenu">
				<li>
					<a>
						Dashboard
					</a>
				</li>
			</ul>
		</li>

		<li *ngxPermissionsOnly="['CRM_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'crms' || _SidemenuService.getCurrentMenu() === 'leads' ||  _SidemenuService.getCurrentMenu() === 'prospects' || _SidemenuService.getCurrentMenu() === 'clients' || _SidemenuService.getCurrentMenu() === 'tasks'}">
			<a>
				<span class="navallIcon icon-icon-2"></span>
				<span class="menu-title">CRM</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li (click)="sideMenuClick('crms')">
					<a>
						CRM
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_LEAD_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'leads' || _SidemenuService.getCurrentMenu() === 'crms'}">
					<a (click)="sideMenuClick('leads')">
						<span class="dashIcon"></span> Leads
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_PROSPECT_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'prospects'}">
					<a (click)="sideMenuClick('prospects')">
						<span class="dashIcon"></span> Prospects
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_CUSTOMER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'clients'}">
					<a (click)="sideMenuClick('clients')">
						<span class="dashIcon"></span> Customers
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_TASK_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'tasks'}">
					<a (click)="sideMenuClick('tasks')">
						<span class="dashIcon"></span> Tasks
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['CUSTOMERS_VIEW']" (click)="sideMenuClick('customers')"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'customers'}">
			<a>
				<span class="navallIcon icon-icon-10"></span>
				<span class="menu-title">Customers</span>
			</a>
			<ul class="subMenu">
				<li>
					<a>
						Customers
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['SUPPORT_REQUEST_VIEW']" (click)="sideMenuClick('support-requests')"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'support-requests'}">
			<a>
				<span class="navallIcon icon-icon-11"></span>
				<span class="menu-title">Support Requests</span>
			</a>
			<ul class="subMenu">
				<li>
					<a>
						Support Requests
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['REFERRAL_PARTNERS_VIEW']" (click)="sideMenuClick('referral-partners')"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'referral-partners'}">
			<a>
				<span class="navallIcon icon-icon-12"></span>
				<span class="menu-title">Referral Partners</span>
			</a>
			<ul class="subMenu">
				<li>
					<a>
						Referral Partners
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['SUBSCRIPTION_PLAN_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'subscription-plans' || _SidemenuService.getCurrentMenu() === 'user-type-creation' || _SidemenuService.getCurrentMenu() === 'module-permissions'}">
			<a>
				<span class="navallIcon icon-icon-13"></span>
				<span class="menu-title">Subscription Plans</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('subscription-plans')">
						<span class="dashIcon"></span> Subscription Plans
					</a>
				</li>
				<li *ngxPermissionsOnly="['SUBSCRIPTION_PLAN_LIST_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'subscription-plans'}">
					<a (click)="sideMenuClick('subscription-plans')">
						<span class="dashIcon"></span> Subscription Plans
					</a>
				</li>
				<li *ngxPermissionsOnly="['SUBSCRIPTION_PLAN_MODULE_PERMISSIONS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'module-permissions'}">
					<a (click)="sideMenuClick('module-permissions')">
						<span class="dashIcon"></span> Module Permissions
					</a>
				</li>
				<li *ngxPermissionsOnly="['SUBSCRIPTION_PLAN_USER_TYPE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'user-type-creation'}">
					<a (click)="sideMenuClick('user-type-creation')">
						<span class="dashIcon"></span> User Type Creation
					</a>
				</li>
			</ul>
		</li>
		<li
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'commission' || _SidemenuService.getCurrentMenu() === 'rebates' || _SidemenuService.getCurrentMenu() === 'royalties' || _SidemenuService.getCurrentMenu() === 'billbacks' || _SidemenuService.getCurrentMenu() === 'incentives' }">
			<a>
				<span class="navallIcon icon-icon-4"></span>
				<span class="menu-title">Incentives</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>

			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('incentives')">
						Incentives
					</a>
				</li>
				<li
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'commission' || _SidemenuService.getCurrentMenu() === 'incentives'}">
					<a (click)="sideMenuClick('commission')">
						<span class="dashIcon"></span> Commission
					</a>
				</li>
				<!-- <li [ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'rebates'}">
							<a (click)="sideMenuClick('rebates')">
								Rebates
							</a>
						</li>
						<li>
							<a (click)="sideMenuClick('royalties')" [ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'royalties'}">
								Royalties
							</a>
						</li>
						<li [ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'billbacks'}">
							<a (click)="sideMenuClick('billbacks')">
								Billbacks & Chargebacks
							</a>
						</li> -->

			</ul>
		</li>
		<li *ngxPermissionsOnly="['SALES_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'sales' || _SidemenuService.getCurrentMenu() === 'quotes' || _SidemenuService.getCurrentMenu() === 'salesorders' || _SidemenuService.getCurrentMenu() === 'invoices' || _SidemenuService.getCurrentMenu() === 'arinvoiceslist' }">
			<a>
				<span class="navallIcon icon-icon-6"></span>
				<span class="menu-title">Sales</span>
			</a>
			<span class="toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('sales')">
						Sales
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_QUOTATION_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'quotes' || _SidemenuService.getCurrentMenu() === 'sales'}">
					<a (click)="sideMenuClick('quotes')">
						<span class="dashIcon"></span> Quotes
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ORDER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'salesorders'}">
					<a (click)="sideMenuClick('salesorders')">
						<span class="dashIcon"></span> Orders
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ARINVOIECES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'invoices'}">
					<a (click)="sideMenuClick('invoices')">
						<span class="dashIcon"></span> Sales Invoices
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ARCREDITMEMO_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'arinvoiceslist'}">
					<a (click)="sideMenuClick('arinvoiceslist')">
						<span class="dashIcon"></span> AR Credit Memos
					</a>
				</li>
			</ul>
		</li>

		<li *ngxPermissionsOnly="['REPORTS_VIEW']" (click)="sideMenuClick('reports')"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'reports'}">
			<a>
				<span class="navallIcon icon-icon-7"></span>
				<span class="menu-title">Reports</span>
			</a>
			<ul class="subMenu">
				<li>
					<a>
						Reports
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['USERMANAGEMENT_USER_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'usersmanagements' || _SidemenuService.getCurrentMenu() === 'usermanagement' || _SidemenuService.getCurrentMenu() === 'rolesmanagement' || _SidemenuService.getCurrentMenu() === 'allusers'  || _SidemenuService.getCurrentMenu() === 'supportusers'}">
			<a>
				<span class="navallIcon icon-icon-8"></span>
				<span class="menu-title">User Management</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('usersmanagements')">
						User Management
					</a>
				</li>
				<li
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'usersmanagements' || _SidemenuService.getCurrentMenu() === 'usermanagement' || _SidemenuService.getCurrentMenu() === 'allusers'  || _SidemenuService.getCurrentMenu() === 'supportusers'}">
					<a (click)="sideMenuClick('usermanagement')">
						<span class="dashIcon"></span> Users
					</a>
				</li>
				<li *ngxPermissionsOnly="['USERMANAGEMENT_ROLE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'rolesmanagement'}">
					<a (click)="sideMenuClick('rolesmanagement')">
						<span class="dashIcon"></span> Roles
					</a>
				</li>
			</ul>
		</li>

		<li *ngxPermissionsOnly="['APP_SETTING_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'apps-configs' || _SidemenuService.getCurrentMenu() === 'externalsystemconfiguration' || _SidemenuService.getCurrentMenu() === 'fiscalcalculationoptions' || _SidemenuService.getCurrentMenu() === 'systemconfigurations' || _SidemenuService.getCurrentMenu() === 'dynamicfields'}">
			<a>
				<span class="navallIcon icon-icon-9"></span>
				<span class="menu-title">Application Settings</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('apps-configs')">
						Application Settings
					</a>
				</li>
				<li *ngxPermissionsOnly="['EXTERNAL_SYSTEM_CONFIG_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'externalsystemconfiguration' || _SidemenuService.getCurrentMenu() === 'apps-configs'}">
					<a (click)="sideMenuClick('externalsystemconfiguration')">
						<span class="dashIcon"></span> External System Configuration
					</a>
				</li>
				<li *ngxPermissionsOnly="['FISCAL_CALCULATION_OPTIONS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'fiscalcalculationoptions'}">
					<a (click)="sideMenuClick('fiscalcalculationoptions')">
						<span class="dashIcon"></span> Fiscal & Calculation Options
					</a>
				</li>
				<li *ngxPermissionsOnly="['SYSTEM_CONFIGURATIONS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'systemconfigurations'}">
					<a (click)="sideMenuClick('systemconfigurations')">
						<span class="dashIcon"></span> System Configuration
					</a>
				</li>
				<li *ngxPermissionsOnly="['DYNAMIC_FIELDS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'dynamicfields'}">
					<a (click)="sideMenuClick('dynamicfields')">
						<span class="dashIcon"></span> Custom Properties
					</a>
				</li>
			</ul>
		</li>

	</ul>
</div>