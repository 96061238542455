<div class="row chargecontent" *ngxPermissionsOnly="isCurrentTabPermission">
	<div class="cols6">
		<div class="feildCol inputDisableFile">
			<div class="fileUpbtn">
				<input type="file" [(ngModel)]="chargeDocumentsTabModel.documentURL"
					accept=".doc,.docx,.csv,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
					(change)="uploadDocument($any($event.target).files)" />
				<span class="fileNamelabel">{{chargeDocumentsTabModel.documentURL}}</span>
				<span class="upldBtn">Browse</span>
			</div>
			<div class="inputField">
				<label for="rate">Document</label>
			</div>
		</div>
	</div>

	<div class="cols6">
		<div class="feildCol">
			<input name="" type="text" id="documentReferenceNumber"
				[(ngModel)]="chargeDocumentsTabModel.documentReferenceNumber">
			<div class="inputField">
				<label for="documentReferenceNumber">Document Reference No.</label>
			</div>
		</div>
	</div>

	<div class="cols10 cols10addbtn">
		<input name="" type="text" id="claimedUserNote" [(ngModel)]="chargeDocumentsTabModel.claimedUserNote">
		<div class="inputField">
			<label for="claimedUserNote">User Note</label>
		</div>
	</div>
	<div class="importexcelbtn">
		<dx-button class="secondaryBtn button center dx-icon-xlsblue" type="normal" stylingMode="contained"
			[useSubmitBehavior]="false" icon="import" (onClick)="doClickImportClaimItemsExcel()" text="Import Excel/CSV">
		</dx-button>
		<!-- <dx-button class="secondaryBtn button center" type="normal" stylingMode="contained" [useSubmitBehavior]="false"
			icon="add" (onClick)="addChargebackDocumentsAgreementTabDetails()" text="Add">
		</dx-button> -->
	</div>

</div>
<!-- *ngIf="viewDocumentDataGrid" -->
<div class="mrgT10">
	<dx-data-grid #chargeDocumentsTabDataGridContainer id="chargeDocumentsTabDataGridContainer"
		[dataSource]="chargeDocumentsTabDataSource" [remoteOperations]="false" [showBorders]="true"
		[allowColumnResizing]="true" [columnAutoWidth]="true" (onToolbarPreparing)="doToolbarPreparing($event)"
		(onRowUpdated)="doOnRowUpdatedChargeDocumentsTab($event)"
		(onRowRemoved)="doOnRowRemovedChargeDocumentsTab($event)" (onSaved)="doOnSavedChargeDocumentsTab()"
		(onRowClick)="viewChargeBackDocumentDetails($event.data);" [height]="450" keyExpr="guid"
		class="hideFilterPanelInx">

		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
		<!-- <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false"></dxo-editing> -->
		<dxi-column caption="Document Name" dataField="documentOriginalName" [allowEditing]="false"
			cellTemplate="cellTemplateDocumentName" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplateDocumentName'">
				{{element.data.documentOriginalName || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="Document Reference No." dataField="documentOriginalName" [allowEditing]="false"
			cellTemplate="cellTemplatedocumentReferenceNumber" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplatedocumentReferenceNumber'">
				{{element.data.documentReferenceNumber || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="Document Date" dataField="documentDate" [allowEditing]="false"
			cellTemplate="cellTemplatedocumentDocumentDate" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplatedocumentDocumentDate'">
				{{element.data.documentDate | toDateObj | date: 'yyyy-MM-dd'}}
			</div>
		</dxi-column>
		<dxi-column caption="Total Claimed Amount" dataField="documentTotal" [allowEditing]="false"
			[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
			<dxo-format type="currency" [precision]="decimalPointForCurrency">
			</dxo-format>
			<div *dxTemplate="let element of 'cellTemplateTotalClaimedAmount'">
				{{element.data.documentTotal || 0 | customCurrencyPipe}}
			</div>
		</dxi-column>
		<dxi-column dataField="created_by_name" caption="Created By" dataType="string" [allowHeaderFiltering]="true"
			cellTemplate="cellTemplateCreatedBy" [allowFiltering]="false">
			<div *dxTemplate="let element of 'cellTemplateCreatedBy'">
				{{element.data.created_by_name}}
			</div>
		</dxi-column>
		<dxi-column caption="Status" dataField="claimedStatus" [allowEditing]="false" cellTemplate="cellTemplateStatus"
			[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplateStatus'" style="text-transform: uppercase;"
				[ngClass]="element.data.claimedStatus === 'approved' ? 'green' : 'red'">
				{{element.data.claimedStatus}}
			</div>
		</dxi-column>
		<dxi-column dataField="guid" caption="Action" [allowEditing]="false" cellTemplate="cellTemplateAction"
			[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false" [allowExporting]="false">
			<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
				<a (click)="viewChargeBackDocumentDetails(element.data);$event.stopPropagation();">
					<span class="icon-view" id="viewDocument_{{element.rowIndex + 1}}"></span>
				</a>
				<a (click)="downloadChargeBackDocument(element.data);$event.stopPropagation();">
					<span class="icon-download" id="downloadAttachedDocument_{{element.rowIndex + 1}}"></span>
				</a>
				<dx-tooltip target="#viewDocument_{{element.rowIndex + 1}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="bottom">
					<div *dxTemplate="let data = data of 'content'">
						View Document
					</div>
				</dx-tooltip>
				<dx-tooltip target="#downloadAttachedDocument_{{element.rowIndex + 1}}" showEvent="dxhoverstart"
					hideEvent="dxhoverend" position="bottom">
					<div *dxTemplate="let data = data of 'content'">
						Download Attached Document
					</div>
				</dx-tooltip>
			</div>
		</dxi-column>
	</dx-data-grid>
	<div *ngIf="isUploadExcelCsvFileItmGrpTab" class="noteBx mrgT10 mrgB0">
		<span>
			<b>{{noOfUnmatchedClaim || 0}}</b> Item(s) out of <b>{{noOfTotaClaim || 0}}</b> Item(s)
			ignored
			<span class="noteSeprator"></span>
			<b>{{ noOfMatchedClaim|| 0}}</b> Item(s) out of <b>{{noOfTotaClaim || 0}}</b> Item(s)
			sucessed
			<strong (click)="doViewImportClaimItemsExcel()" class="pointerMark">Click here to
				view</strong>
		</span>
	</div>
</div>
<app-cc-claimed-items-list-dialog *ngIf="isShowClaimedItemListPopup" [claimedItemsListProps]="claimedItemsListProps"
	(closeClaimedItemListPopup)="doCloseClaimedItemListPopup($event)"></app-cc-claimed-items-list-dialog>
<app-cc-view-chargeback-documents-dialog *ngIf="isShowChargebackDocumentPopup"
	[viewChargebackDocumentProps]="viewChargebackDocumentProps"
	(closeViewChargebackDocumentPopup)="doCloseViewChargebackDocumentPopup($event)"></app-cc-view-chargeback-documents-dialog>
<app-upload-chargeback-enduser-claims-dialog *ngIf="isUploadChargebackEnduserClaimsPopup"
	[uploadChargebackEnduserClaimsProps]="uploadChargebackEnduserClaimsProps"
	(closeUploadChargebackEnduserClaimsPopup)="doCloseUploadChargebackEnduserClaimsPopup($event)"></app-upload-chargeback-enduser-claims-dialog>