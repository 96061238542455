<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false">
	<div id="orderSetting" class="row">

		<div *ngIf="isShowDataFromField">
			<div class="cols12">
				<div class="srTitle">
					<label>Show Data From</label>
				</div>
				<div class="feildCol radioGroup mrgT0 padT0">
					<div class="inlineBlock">
						<div class="radio">
							<dx-radio-group [dataSource]="listOfShowDataFrom" displayExpr="text" valueExpr="value"
								[(value)]="userConfigsUI.showDataFrom" layout="horizontal">
							</dx-radio-group>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="userConfigsUI.showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE" class="cols6">
				<div class="dx-field dateiconset mrgT10 mrgB10">
					<div class="dx-field-label">Start Date</div>
					<div class="dx-field-value">
						<dx-date-box [(value)]="userConfigsUI.startDate" [min]="minDate" type="date"
							displayFormat="yyyy-MM-dd" [showClearButton]="true">
						</dx-date-box>
					</div>
				</div>
			</div>
			<div *ngIf="userConfigsUI.showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE" class="cols6">
				<div class="dx-field dateiconset mrgT10 mrgB10">
					<div class="dx-field-label">End Date</div>
					<div class="dx-field-value">
						<!--(onValueChanged)="doEndDateChanged($event)"
									 valueChangeEvent="change" -->
						<dx-date-box [(value)]="userConfigsUI.endDate" [min]="minDate" type="date" displayFormat="yyyy-MM-dd"
							[showClearButton]="true">
						</dx-date-box>
					</div>
				</div>
			</div>
			<hr class="width100p mrgT10 mrgB10 alignLeft" />
		</div>

		<div class="cols12">
			<div class="srTitle">
				<label>Qualify By</label>
			</div>
			<div class="feildCol radioGroup mrgT0 padT0">
				<div class="inlineBlock">
					<div class="radio">
						<dx-radio-group [dataSource]="listOfDateUses" (onValueChanged)="doChangeDateToUse($event)"
							valueChangeEvent="change" displayExpr="text" valueExpr="value"
							[(value)]="userConfigsUI.which_date_to_use" layout="horizontal">
						</dx-radio-group>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isShowConfimBtn" class="cols12 mrgT10">
			<div class="">
				<dx-button (onClick)="doConfirmedSettings()" class="button dxSubmitBtn" id="button" text="Confirm"
					type="normal" stylingMode="contained" [useSubmitBehavior]="false">
				</dx-button>
			</div>
		</div>
	</div>
</dx-scroll-view>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.0)" [position]="{ of: '#orderSetting' }"
	[(visible)]="isShowLoadPanel" [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false"
	class="zIndexDxLoadPanel">
</dx-load-panel>