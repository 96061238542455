import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { GlobalStateService } from './global-state/global-state.service';
import { NgxPermissionsService } from 'ngx-permissions';
@Injectable({
	providedIn: 'root'
})
export class DataService {
	// public currentsidemenu: string = '';
	public setTimeOutRequestCall: any;
	public contracts: any = [];
	public contacts: any = [];
	public maincontracts: any = [];
	public currentcontracts: any = [];
	public emptyContract: any = [];
	public emptyClient: any = [];
	public items: any = [];
	public territories: any = [];
	public groups: any = [];
	public salesPpl: any = [];
	public clients: any = [];
	public billbackcontracts: any = [];
	public rebatescontracts: any = [];
	public royaltiescontracts: any = [];
	public uoms: any = [];
	public vendors: any = [];
	public quotations: any = [];
	public orders: any = [];
	public invoices: any = [];
	public uomgroups: any = [];
	public pricelists: any = [];
	public specialprices: any = [];
	public countries: any = [];
	public dialcodes: any = [];
	public buyinggroups: any = [];
	public leads: any = [];
	public prospects: any = [];
	public warehouses: any = [];
	public allClientTaskList: any = [];
	public tasks: any = [];
	public vendorsList: any = [];
	public buyingGroupsList: any = [];
	private timezoneLst: any = [];

	constructor(private router: Router,
		private _GlobalStateService: GlobalStateService,
		private _loginService: LoginService,
		private _loaderService: LoaderService,
		private _restApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService) { }

	setContracts(val: any) {
		this.contracts = val;
		this.contracts.contracts = this.contracts.contracts || [];
		this.contracts.rebatescontracts = this.contracts.rebatescontracts || [];
		this.contracts.royaltiescontracts = this.contracts.royaltiescontracts || [];
		this.contracts.billbackcontracts = this.contracts.billbackcontracts || [];
	}

	startSetTimeOutRequestCall() {
	}
	clearSetTimeOutRequestCall(val: any) {
	}

	getContracts() {
		return this.contracts;
	}
	setContacts(val: any) {
		this.contacts = val;
	}

	getContacts() {
		return this.contacts;
	}
	setMaincontracts(val: any) {
		this.maincontracts = val;
	}

	getMaincontracts() {
		return this.maincontracts;
	}
	setCurrentcontracts(val: any) {
		this.currentcontracts = val;
	}

	getCurrentcontracts() {
		return this.currentcontracts;
	}

	setCurrentBillbackContracts(val: any) {
		this.billbackcontracts = val;
	}

	getCurrentBillbackContracts() {
		return this.billbackcontracts;
	}
	setCurrentRebatesContracts(val: any) {
		this.rebatescontracts = val;
	}

	getCurrentRebatesContracts() {
		return this.rebatescontracts;
	}
	setCurrentRoyaltiesContracts(val: any) {
		this.royaltiescontracts = val;
	}

	getCurrentRoyaltiesContracts() {
		return this.royaltiescontracts;
	}
	setEmptyContract(val: any) {
		this.emptyContract = val;
	}

	getEmptyContract() {
		return this.emptyContract;
	}

	setEmptyClient(val: any) {
		this.emptyClient = val;
	}

	getEmptyClient() {
		return this.emptyClient;
	}
	setItems(val: any) {
		this.items = val;
	}

	getItems() {
		return this.items;
	}
	setTerritories(val: any) {
		this.territories = val;
	}

	getTerritories() {
		return this.territories;
	}

	setGroups(val: any) {
		this.groups = val;
	}

	getGroups() {
		return this.groups;
	}
	setSalesPpl(val: any) {
		this.salesPpl = val;
	}

	getSalesPpl() {
		return this.salesPpl || [];
	}
	setClients(val: any) {
		this.clients = val;
	}

	getClients() {
		return this.clients || [];
	}

	setUOMs(val: any) {
		this.uoms = val;
	}

	getUOMs() {
		return this.uoms || [];
	}

	setVendors(val: any) {
		this.vendors = val;
	}

	getVendors() {
		return this.vendors;
	}

	setQuotations(val: any) {
		this.quotations = val;
	}

	getQuotations() {
		return this.quotations || [];
	}

	setOrders(val: any) {
		this.orders = val;
	}

	getOrders() {
		return this.orders || [];
	}

	setInvoices(val: any) {
		this.invoices = val;
	}

	getInvoices() {
		return this.invoices || [];
	}

	setUomGroups(val: any) {
		this.uomgroups = val;
	}

	getUomGroups() {
		return this.uomgroups;
	}

	setPriceLists(val: any) {
		this.pricelists = val;
	}

	getPriceLists() {
		return this.pricelists;
	}

	setSpecialPrices(val: any) {
		this.specialprices = val;
	}

	getSpecialPrices() {
		return this.specialprices;
	}

	setCountries(val: any) {
		this.countries = val;
	}

	getCountries() {
		return this.countries || [];
	}

	setDialCodes(val: any) {
		this.dialcodes = val;
	}

	getDialCodes() {
		return this.dialcodes || [];
	}

	setConcatPhoneCode(phoneId: string): string {
		let dialIdCode = '';
		if (phoneId) {
			const itemDialCodeData = this.dialcodes.filter(itemDialCode => itemDialCode.id === phoneId);
			if (itemDialCodeData && itemDialCodeData.length > 0) {
				dialIdCode = itemDialCodeData[0].id + '#' + itemDialCodeData[0].code;
			}
		}
		return dialIdCode;
	}

	getSplitPhoneCode(phoneCodeStr: string): any[] {
		let itemDialCodeData = [];
		if (phoneCodeStr) {
			const dialcode = phoneCodeStr.split('#');
			if (dialcode && dialcode.length > 0) {
				itemDialCodeData = this.dialcodes.filter(itemDialCode => itemDialCode.id === dialcode[0]);
			}
		}
		return itemDialCodeData;
	}
	// For Display phonenumber or contact number with dialcode(Country code)
	getPhoneNoWithCode(element) {
		let phonenumberstr = "";
		if (element.dialcode) {
			const getPhoneCodeData = this.getSplitPhoneCode(element.dialcode);
			if (getPhoneCodeData && getPhoneCodeData.length > 0) {
				if (element.phone) {
					phonenumberstr = element.phone;
					phonenumberstr = '+' + getPhoneCodeData[0].code + " " + element.phone;
				}
				if (element.contact_number) {
					phonenumberstr = element.contact_number;
					phonenumberstr = '+' + getPhoneCodeData[0].code + " " + element.contact_number;
				}
			}
		} else {
			if (element.phone) {
				phonenumberstr = element.phone;
			}
			if (element.contact_number) {
				phonenumberstr = element.contact_number;
			}
		}
		return phonenumberstr;
	}
	setDefaultDialCodeSelection() {
		let dialCode = '';
		const itemDialCodeData = this.dialcodes.filter(itemDialCode => itemDialCode.name === 'United States');
		if (itemDialCodeData && itemDialCodeData.length > 0) {
			dialCode = itemDialCodeData[0].id;
		}
		return dialCode;
	}

	setDefaultDialIdSelection() {
		let dialCode = '';
		const itemDialCodeData = this.dialcodes.filter(itemDialCode => itemDialCode.name === 'United States');
		if (itemDialCodeData && itemDialCodeData.length > 0) {
			dialCode = itemDialCodeData[0].code;
		}
		return dialCode;
	}

	getDialCodeData(dialCodeId?: string) {
		let dialCode = '';
		const itemDialCodeData = this.dialcodes.filter(itemDialCode => itemDialCode.id === (dialCodeId || 'us'));
		if (itemDialCodeData && itemDialCodeData.length > 0) {
			dialCode = itemDialCodeData[0];
		}
		return dialCode;
	}

	getDialCode(dialCodeParam?: string) {
		let dialCode = '';
		const itemDialCodeData = this.dialcodes.filter(itemDialCode => itemDialCode.code === (dialCodeParam || '1'));
		if (itemDialCodeData && itemDialCodeData.length > 0) {
			dialCode = itemDialCodeData[0].code;
		}
		return dialCode;
	}

	getDialCodeId(dialCodeId?: string) {
		let dialCode = '';
		const itemDialCodeData = this.dialcodes.filter(itemDialCode => itemDialCode.id === (dialCodeId || 'us'));
		if (itemDialCodeData && itemDialCodeData.length > 0) {
			dialCode = itemDialCodeData[0].id;
		}
		return dialCode;
	}

	setBuyingGroups(val: any) {
		this.buyinggroups = val;
	}

	getBuyingGroups() {
		return this.buyinggroups || [];
	}

	setLeads(val: any) {
		this.leads = val;
	}

	getLeads() {
		return this.leads || [];
	}

	setProspects(val: any) {
		this.prospects = val;
	}

	getProspects() {
		return this.prospects || [];
	}
	setWareHouses(val: any) {
		this.warehouses = val;
	}

	getWareHouses() {
		return this.warehouses || [];
	}
	setAllClientTaskList(val: any) {
		this.allClientTaskList = val;
	}

	getAllClientTaskList() {
		return this.allClientTaskList || [];
	}

	setTasks(val: any) {
		this.tasks = val;
	}

	getTasks() {
		return this.tasks;
	}

	setVendorsList(val: any) {
		this.vendorsList = val;
	}

	getVendorsList() {
		return this.vendorsList;
	}

	setBuyingGroupsList(val: any) {
		this.buyingGroupsList = val;
	}

	getBuyingGroupsList() {
		return this.buyingGroupsList;
	}


	logout() {
		const formdata = 'usr=' + this._loginService.loginUser.user + '&' + 'token=' + this._loginService.loginUser.token + '&logout=1';
		this._loaderService.show();
		this._restApiService.doLoginReq(formdata).subscribe(response => {
			this._loaderService.hide();
			this._NgxPermissionsService.flushPermissions();
			this._loginService.loginUser = undefined;
			this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
			this.router.navigate(['/auth/login'], { replaceUrl: true });
		}, error => {
			console.error(error);
		});
	}

	setTimezones(val: any) {
		this.timezoneLst = val;
	}

	getTimezones() {
		return this.timezoneLst || [];
	}
}
