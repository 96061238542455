import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, AfterContentInit } from '@angular/core';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';

@Component({
	selector: 'app-overdue-payment-dialog',
	templateUrl: './overdue-payment-dialog.component.html',
	styleUrls: ['./overdue-payment-dialog.component.css']
})
export class OverduePaymentDialogComponent implements OnInit, AfterViewInit {
	@Output() closeInvoiceOverduePaymentPopup = new EventEmitter<any>();
	@ViewChild('payNowRef', { static: false }) payNowRef: DxButtonComponent;
	@ViewChild('dxPopupRef', { static: false }) dxPopupRef: DxPopupComponent;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc) { }

	ngOnInit() {
		this.isShowPopup = true;
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.dxPopupRef.instance.focus();
			this.dxPopupRef.instance.registerKeyHandler('escape', (e) => {
				e.stopPropagation();
			});
			this.dxPopupRef.instance.registerKeyHandler('enter', (e) => {
				this.doPayNow();
			});
			this.payNowRef.instance.registerKeyHandler('enter', (e) => {
				this.doPayNow();
			});
		});
	}

	public doPayNow() {
		this.closeInvoiceOverduePaymentPopup.emit({
			isClickOnCloseBtn: false,
			isAllowed: true
		});
		this.isShowPopup = false;
	}

	public doHiddenPopup(event?: any) {
		this.closeInvoiceOverduePaymentPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}

}
