import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillbackComponent } from './billback.component';
import { BillbackContractComponent } from './billback-contract/billback-contract.component';
import { ChargebackContractComponent } from './chargeback-contract/chargeback-contract.component';
import { EndUsersChargebackContractComponent } from './end-users-chargeback-contract/end-users-chargeback-contract.component';
import { DistributionChargebackContractComponent } from './distribution-chargeback-contract/distribution-chargeback-contract.component';
import { DccClaimAttachedDocumentsComponent } from './distribution-chargeback-contract/dcc-claim-attached-documents/dcc-claim-attached-documents.component';
import { MinMaxEndUsersChargebackContractComponent } from './min-max-end-users-chargeback-contract/min-max-end-users-chargeback-contract.component';

const routes: Routes = [{
	path: '',
	component: BillbackComponent,

	children: [
		{
			path: 'billback-contract',
			component: BillbackContractComponent,
			data: {
				title: 'Billback & Chargeback Details',
				breadcrumb: [
					{
						label: 'Billback & Chargeback',
						url: '/contracts/billbacklist'
					},
					{
						label: 'Billback & Chargeback Details',
						url: ''
					}
				]
			},
		},
		{
			path: 'chargeback-contract',
			component: ChargebackContractComponent,
			data: {
				title: 'Billback & Chargeback Details',
				breadcrumb: [
					{
						label: 'Billback & Chargeback',
						url: '/contracts/billbacklist'
					},
					{
						label: 'Billback & Chargeback Details',
						url: ''
					}
				]
			},
		},
		{
			path: 'end-users-chargeback-contract',
			component: EndUsersChargebackContractComponent,
			data: {
				title: 'Billback & Chargeback Details',
				breadcrumb: [
					{
						label: 'Billback & Chargeback',
						url: '/contracts/billbacklist'
					},
					{
						label: 'Billback & Chargeback Details',
						url: ''
					}
				]
			},
		},
		{
			path: 'distribution-chargeback-contract',
			component: DistributionChargebackContractComponent,
			data: {
				title: 'Distribution Chargeback Details',
				breadcrumb: [
					{
						label: 'Distribution Chargeback',
						url: '/contracts/billbacklist'
					},
					{
						label: 'Distribution Chargeback Details',
						url: ''
					}
				]
			},
		},
		{
			path: 'distribution-chargeback-claim-attached-document',
			component: DccClaimAttachedDocumentsComponent,
			data: {
				title: 'Claim Attached Document',
				breadcrumb: [
					{
						label: 'Distribution Chargeback',
						url: '/contracts/billbacklist'
					},
					{
						label: 'Distribution Chargeback',
						url: '/contracts/billbackchargeback/distribution-chargeback-contract/'
					},
					{
						label: 'Claim Attached Document',
						url: ''
					}
				]
			},
		},
		{
			path: 'min-max-end-users-chargeback-contract',
			component: MinMaxEndUsersChargebackContractComponent,
			data: {
				title: 'Billback & Chargeback Details',
				breadcrumb: [
					{
						label: 'Billback & Chargeback',
						url: '/contracts/billbacklist'
					},
					{
						label: 'Billback & Chargeback Details',
						url: ''
					}
				]
			},
		},
		{
			path: '',
			component: BillbackContractComponent
		}
	]
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class BillbackRoutingModule { }
