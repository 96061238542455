import * as $ from 'jquery';
import * as _ from 'underscore';
import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RuleTabModel } from '@app/models/commission-contract.model';
import { ToastrService } from 'ngx-toastr';
import { Guid } from '@app/models/guid';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoginService } from '@app/services/login.service';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-royalties-rules-tab',
	templateUrl: './royalties-rules-tab.component.html',
	styleUrls: ['./royalties-rules-tab.component.css'],
	providers: []
})
export class RoyaltiesRulesTabComponent implements OnInit, AfterContentChecked {
	public guid = new Guid();
	@Input() ruleTabList: any[] = [];
	@Input() exclusionTabList: any[] = [];
	@Input() isCurrentTabPermission: string;
	@Output() saveCommissionRulesEvent = new EventEmitter<string>();
	@ViewChild('rulesListRulesTabDataGridRef') rulesListRulesTabDataGridRef: DxDataGridComponent;
	@ViewChild('ruleBonusTypeSelectBoxRef') ruleBonusTypeSelectBoxRef: DxSelectBoxComponent;
	@ViewChild('conditionSelectBoxRef') conditionSelectBoxRef: DxSelectBoxComponent;
	@ViewChild('criterionSelectBoxRef') criterionSelectBoxRef: DxSelectBoxComponent;
	//Rule Customer Drop Down list Object
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	public chkAllCustomer: boolean = false;
	// public isViewAllCustomerChk: boolean = false;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public arrSelectedCustomerList: any = [];
	public lengthSelectedCustomers: any;
	public selectedCustomerData: any = [];
	public editCommissionGuid: string;
	public chkAddOrUpdateRule: string = ''; // use for stop rule object overright on edit time
	public btnRulesAdd: string = 'Add';
	public rulesAddOrEditIndex: string = '-1';
	public ruleTabModel = new RuleTabModel();
	public isViewIERuleRuleRate: boolean = true;
	public isViewBounsAmountCriterion: boolean = true;
	public isViewRateRuleCriterion: boolean = true;
	public isViewBonusRateCriterion: boolean = true;
	public listOfRuleBonusTypes: any[] = [];
	public listOfInclusionExclusionRuleConditions: any[] = [];
	public listOfRateRuleConditions: any[] = [];
	public listOfBonusRateConditions: any[] = [];
	public listOfBonusAmountConditions: any[] = [];
	public listOfInclusionExclusionRuleCriterions: any[] = [];
	public listOfExclusionsForBuyingGroup: any[] = [];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public textRuleBonusType: string;
	public textCondition: string;
	public textCriterion: string;
	public decimalPointPercentageFormat: string;

	constructor(private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _IxDxAlertService: IxDxAlertService,
		private _CommissionService: CommissionService,
		private _ChangeDetectorRef: ChangeDetectorRef) { }

	ngOnInit() {
		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS) {
				this.customerGridBoxColumns = [
					{ dataField: 'code', caption: 'Code', visible: false },
					{ dataField: 'name', caption: 'Name' },
				];
				this.isQBErpUser = true;
			}
			else if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
				this.customerGridBoxColumns = [
					{ dataField: 'code', caption: 'Code' },
					{ dataField: 'name', caption: 'Name' }
				];
			} else {
				this.customerGridBoxColumns = [
					{ dataField: 'code', caption: 'Code' },
					{ dataField: 'name', caption: 'Name' }
				];
			}
		}
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.loadCustomerDataList();
		this.clearRulesAgreementTabDetails();
		this.listOfRuleBonusTypes = this._CommissionService.listOfRuleBonusTypes;
		this.listOfInclusionExclusionRuleConditions = this._CommissionService.listOfInclusionExclusionRuleConditions;
		this.listOfRateRuleConditions = this._CommissionService.listOfRateRuleConditions;
		this.listOfBonusRateConditions = this._CommissionService.listOfBonusRateConditions;
		this.listOfBonusAmountConditions = this._CommissionService.listOfBonusAmountConditions;
		this.listOfInclusionExclusionRuleCriterions = this._CommissionService.listOfInclusionExclusionRuleCriterions;
		this.listOfExclusionsForBuyingGroup = this._CommissionService.listOfExclusionsForBuyingGroup;

	}

	ngAfterContentChecked(): void {
		this._ChangeDetectorRef.detectChanges();
	}

	clearRulesAgreementTabDetails() {
		this.btnRulesAdd = 'Add';
		this.rulesAddOrEditIndex = '-1';
		this.ruleTabModel.ruleBonusType = 'inclusionExclusionRule';
		this.ruleTabModel.condition = 'include';
		this.ruleTabModel.criterion = 'unpaidInvoices';
		this.ruleTabModel.ruleRate = 'N/A';
		this.ruleTabModel.guid = '';
	}

	ruleBonusTypeChange(ruleBonusType) {
		if (this.chkAddOrUpdateRule == '') {
			if (ruleBonusType === 'inclusionExclusionRule') {
				this.ruleTabModel.ruleBonusType = 'inclusionExclusionRule';
				this.ruleTabModel.condition = 'include';
				this.ruleTabModel.criterion = 'unpaidInvoices';
				this.ruleTabModel.ruleRate = 'N/A';
			} else if (ruleBonusType === 'rateRule') {
				this.ruleTabModel.ruleBonusType = 'rateRule';
				this.ruleTabModel.condition = 'breakUnitsOfMeasure';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';
			} else if (ruleBonusType === 'bonusAmount') {
				this.ruleTabModel.ruleBonusType = 'bonusAmount';
				this.ruleTabModel.condition = 'newAccount';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';
			} else if (ruleBonusType === 'bonusRate') {
				this.ruleTabModel.ruleBonusType = 'bonusRate';
				this.ruleTabModel.condition = 'newAccount';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';

			}
			if (ruleBonusType !== 'inclusionExclusionRule') {
				this.conditionChange(this.ruleTabModel.condition);
			}
			if (ruleBonusType === 'inclusionExclusionRule') {
				this.criterionChange(this.ruleTabModel.criterion);
			}
		}
	}

	concatNamePipe() {
		let name = this.ruleBonusTypeSelectBoxRef.displayValue + '|' + this.conditionSelectBoxRef.displayValue;
		const txtCriterion = this.conditionSelectBoxRef ? this.conditionSelectBoxRef.displayValue : this.ruleTabModel.criterion;
		const fullname = name + '|' + txtCriterion;
		return fullname;
	}

	addRuleBonusItem() {
		if (this.ruleTabModel.condition === 'miscellaneousDeductionInventory') {
			this.addRuleBonusItemWithCustomer();
			return;
		}

		let name = this.concatNamePipe();
		const ruleItem = {
			ruleBonusType: this.ruleTabModel.ruleBonusType,
			condition: this.ruleTabModel.condition,
			criterion: $.isNumeric(this.ruleTabModel.criterion) ? parseFloat(this.ruleTabModel.criterion) : this.ruleTabModel.criterion,
			ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
			name: name,
			guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
		};
		let isExistRuleIndex;
		// Check duplicate item in list
		// tslint:disable-next-line:radix
		if (parseInt(this.rulesAddOrEditIndex) === -1) {
			isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === this.ruleTabModel.criterion);
		} else {
			const removedArray = _.reject(this.ruleTabList, item => {
				return item === this.ruleTabList[this.rulesAddOrEditIndex];
			});
			isExistRuleIndex = removedArray.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === this.ruleTabModel.criterion);
		}

		if (isExistRuleIndex !== -1) {
			this._ToastrService.info('Rule already added in list .', 'Duplicate', { closeButton: true, tapToDismiss: true });
			return false;
		}
		// tslint:disable-next-line:radix
		if (parseInt(this.rulesAddOrEditIndex) !== -1) {
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
			this._ToastrService.success('Rule updated successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
		} else {
			this.ruleTabList.push(ruleItem);
			this._ToastrService.success('Rule added successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
		}
		// Latest rule save on edit commission contract
		if (this.editCommissionGuid) {
			this.saveCommissionRulesEvent.emit('save');
		}
		this.clearRulesAgreementTabDetails();
	}

	addRuleBonusItemWithCustomer() {
		this.selectedCustomerData = [];
		if (this.rulesAddOrEditIndex !== '-1') {
			let name = this.concatNamePipe();
			const ruleItem = {
				ruleBonusType: this.ruleTabModel.ruleBonusType,
				condition: this.ruleTabModel.condition,
				criterion: this.ruleTabModel.criterion,
				ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
				name: name,
				guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
			};
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
		}
		else {
			if (this.customerDataGrid) {
				this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData() || [];
				if (this.chkAllCustomer) {
					const custItem = {
						name: 'All Customers',
						code: 'allcustomers'
					}
					this.selectedCustomerData.push(custItem);
				}
				if (this.selectedCustomerData && this.selectedCustomerData.length > 0) {
					for (let i = 0; i < this.selectedCustomerData.length; i++) {
						let name = this.concatNamePipe();
						const ruleItem = {
							ruleBonusType: this.ruleTabModel.ruleBonusType,
							condition: this.ruleTabModel.condition,
							criterion: this.selectedCustomerData[i].code + '@$$@' + this.selectedCustomerData[i].name,
							ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
							name: name,
							guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
						};
						let isExistRuleIndex;
						// Check duplicate item in list
						// tslint:disable-next-line:radix
						if (parseInt(this.rulesAddOrEditIndex) === -1) {
							isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === ruleItem.criterion);
						} else {
							const removedArray = _.reject(this.ruleTabList, item => {
								return item === this.ruleTabList[this.rulesAddOrEditIndex];
							});
							isExistRuleIndex = removedArray.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === ruleItem.criterion);
						}

						if (isExistRuleIndex !== -1) {
							this._ToastrService.info('Rule already added in list .', 'Duplicate', { closeButton: true, tapToDismiss: true });
							return false;
						}
						// tslint:disable-next-line:radix
						if (parseInt(this.rulesAddOrEditIndex) !== -1) {
							this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
							this._ToastrService.success('Rule updated successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
						} else {
							this.ruleTabList.push(ruleItem);
							this._ToastrService.success('Rule added successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
						}
					}
				}
			}
		}
		// Latest rule save on edit commission contract
		if (this.editCommissionGuid) {
			this.saveCommissionRulesEvent.emit('save');
		}
		this.clearRulesAgreementTabDetails();
	}

	conditionChange(condition) {
		// tslint:disable-next-line: max-line-length
		if (condition !== '' && condition !== null && condition !== undefined) {
			if ((condition === 'breakUnitsOfMeasure') || (condition === 'newAccount') || (condition === 'perActiveCustomer') || (condition === 'perActiveAccount')) {
				this.isViewBounsAmountCriterion = true;
				this.isViewRateRuleCriterion = true;
				this.isViewBonusRateCriterion = true;
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.criterion = 'N/A';
				}
			} else {
				this.isViewBounsAmountCriterion = false;
				this.isViewRateRuleCriterion = false;
				this.isViewBonusRateCriterion = false;
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.criterion = '';
				}
			}
		}
		if (this.chkAddOrUpdateRule === 'edit' && this.ruleTabModel.ruleBonusType !== 'inclusionExclusionRule') {
			this.chkAddOrUpdateRule = '';
		}
	}

	criterionChange(criterion) {
		if (criterion !== '' && criterion !== null && criterion !== undefined) {
			if (criterion === 'shippingCosts') {
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.ruleRate = '';
				}
				this.isViewIERuleRuleRate = false;
			} else {
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.ruleRate = 'N/A';
				}
				this.isViewIERuleRuleRate = true;
			}
		}
		if (this.chkAddOrUpdateRule === 'edit' && this.ruleTabModel.ruleBonusType === 'inclusionExclusionRule') {
			this.chkAddOrUpdateRule = '';
		}
	}

	doRemoveRuleBonusListItem(ruleItemIndex) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				// Delete an item from customer Tab list
				this.ruleTabList.splice(ruleItemIndex, 1);
				// Latest rule save on edit commission contract
				if (this.editCommissionGuid) {
					this.saveCommissionRulesEvent.emit('save');
				}

				// this._ToastrService.error('Record deleted successfully. ', 'Deleted', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	doEditRuleBonusListItem(ruleItemIndex, ruleItem) {
		this.btnRulesAdd = 'Update';
		this.chkAddOrUpdateRule = 'edit';
		this.rulesAddOrEditIndex = ruleItemIndex;
		this.ruleTabModel.guid = ruleItem.guid;
		this.ruleTabModel.ruleBonusType = ruleItem.ruleBonusType;
		this.ruleTabModel.condition = ruleItem.condition;
		this.arrSelectedCustomerList = [];
		if (this.ruleTabModel.condition === 'miscellaneousDeductionInventory') {
			this.loadCustomerDataList();
			if (this.arrSelectedCustomerList[0] === 'allcustomers') {
				this.chkAllCustomer = true;
			} else {
				this.lengthSelectedCustomers = ruleItem.criterion.split('@$$@')[1];
				this.customerGridBoxValue = [];
			}
			this.ruleTabModel.criterion = ruleItem.criterion;
			this.ruleTabModel.ruleRate = ruleItem.ruleRate;
			// this.setCustomersText();
		} else {
			this.ruleTabModel.criterion = ruleItem.criterion;
		}
		this.ruleTabModel.ruleRate = ruleItem.ruleRate;

		if (ruleItem.criterion === 'N/A' || ruleItem.condition === 'N/A' || this.ruleTabModel.ruleRate === 'N/A') {
			this.isViewRateRuleCriterion = true;
			this.isViewBonusRateCriterion = true;
			this.isViewBounsAmountCriterion = true;
			this.isViewIERuleRuleRate = true;
		} else {
			this.isViewRateRuleCriterion = false;
			this.isViewBonusRateCriterion = false;
			this.isViewBounsAmountCriterion = false;
			this.isViewIERuleRuleRate = false;
		}
		// this._ChangeDetectorRef.detectChanges();
	}

	public doBuyingGroupPercentageKeyDown(e) {
		const event = e.event;
		const str = event.key || String.fromCharCode(event.which);
		if (/^[.,e]$/.test(str)) {
			event.preventDefault();
		}
	}

	//#region for Customer select box
	loadCustomerDataList() {
		const customeList = _.map(this.exclusionTabList, group => {
			return {
				guid: group['clientguid'] || '',
				code: group['clientcode'] || '',
				name: group['clientname'] || '',
				allcustomers: group['allcustomers'] || '',
			}
		});
		const indexAllCustomer = customeList.findIndex(i => i.allcustomers === true);
		// if (indexAllCustomer !== -1) {
		// 	this.isViewAllCustomerChk = true;
		// }
		const notAllCustomerInList = _.where(customeList, { allcustomers: '' });
		this.customerDataSource = _.uniq(notAllCustomerInList, 'code');
	}

	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.customerGridBoxValue = [];
			// this.customerDataGrid.instance.refresh();
			this.chkAllCustomer = false;
			this.lengthSelectedCustomers = '';
		}
	}

	onCustomerSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedCustomers = e.selectedRowsData.length + ' Customer(s) Selected';
			this.customerGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomer) {
			this.lengthSelectedCustomers = '';
		}
		this.setCustomersText();
	}

	setCustomersText() {
		let lengthSelectedCustomers;
		const selectCustomerList = this.customerDataGrid.instance.getSelectedRowsData() || [];
		this.lengthSelectedCustomers = selectCustomerList.length;
		if (selectCustomerList.length === 0) {
			this.lengthSelectedCustomers = '';
		} else {
			this.lengthSelectedCustomers = selectCustomerList.length + ' Customer(s) Selected';
		}
		if (this.lengthSelectedCustomers) {
			lengthSelectedCustomers = this.lengthSelectedCustomers;
		}

		if (this.chkAllCustomer) {
			lengthSelectedCustomers = 'All Customer ' + (lengthSelectedCustomers ? ', ' : '') + (lengthSelectedCustomers || '');
		}

		this.lengthSelectedCustomers = lengthSelectedCustomers;
	}

	checkAllCustomer() {
		this.lengthSelectedCustomers = '';
		this.setCustomersText();
	}

	//#endregion

}
