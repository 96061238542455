import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApCreditMemoEnum, ErpTypeValEnum } from "@app/constants-enums/enums";
import { LoginService } from "@app/services/login.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { PurchasesService } from "@app/services/purchases-service/purchases.service";
import { RestApiService } from "@app/services/rest-service/rest-api.service";
import { LoaderService } from "@app/services/loaderservices/loader.service";
import { ServerEntity, ServerMethods } from "@app/constants-enums/constants";
import { DxDataGridComponent } from "devextreme-angular";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { CustomCurrencyPipe } from "@app/pipes/custom-currency/custom-currency.pipe";

@Component({
	selector: "app-prchse-ap-credit-memo-details",
	templateUrl: "./prchse-ap-credit-memo-details.component.html",
	styleUrls: ["./prchse-ap-credit-memo-details.component.css"],
})

export class PrchseApCreditMemoDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	public ApCreditMemoEnum = ApCreditMemoEnum;
	private erpType: string;
	private getApCreditMemoDetailsSbsn: Subscription;


	// Matrial design object
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public isShowLoadPanel: boolean = false;
	public apCreditMemoDetails: any;
	public apCreditMemoOpenFrom: string;
	private apCreditMemoGuid: string;
	public isResponse: boolean = false;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public globalCurrencySymbol: string;
	public decimalPointForCurrency: number;
	public isEnableDistributionChannels: boolean = false;
	public listOfDistributionChannels: any[] = [];
	private getListOfDistributionChannelsSbsn: Subscription;

	// #region Angular Life Cycle
	constructor(public router: Router,
		private activatedRoute: ActivatedRoute,
		public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _PurchasesService: PurchasesService,
		private _RestApiService: RestApiService,
		private _CurrencyPipe: CurrencyPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _LoaderService: LoaderService) {

	}

	ngOnInit() {
		this.preInIt();
	}

	ngAfterViewInit() {

	}

	ngOnDestroy() {
		if (this.getApCreditMemoDetailsSbsn) {
			this.getApCreditMemoDetailsSbsn.unsubscribe();
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}
	}
	// #endregion

	// Method used to set Erp Type
	private setErpType() {
		this.erpType = '';
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erpType = '';
		}

		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
	}

	private preInIt() {
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.setErpType();
		this.apCreditMemoGuid = this._PurchasesService.getApCreditMemoGuid();
		this.apCreditMemoOpenFrom = this._PurchasesService.getApCreditMemoDetailsOpenFrom();
		this.loadApCreditMemoData();
	}

	// Set request object to fetch data of selected tabs
	private loadApCreditMemoData() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.APCREDITMEMO);
		formData.append('search', JSON.stringify([{ 'guid': this.apCreditMemoGuid }]));
		if (this.getApCreditMemoDetailsSbsn) {
			this.getApCreditMemoDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.isResponse = false;
		this.getApCreditMemoDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response.flag) {
				if (response.data && response.data.length > 0) {
					this.isResponse = true;
					this.apCreditMemoDetails = response.data[0];
					if (this.isEnableDistributionChannels) {
						const filterObj = ['code', 'contains', this.apCreditMemoDetails.distribution_channels];
						this.doFetchListOfDistributionChannels(filterObj);
					}
				}
			}
		}, error => {
			this._LoaderService.hide();
		});
	}


	//#region Tool bar
	public doToolbarPreparing(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel();
					}
				}
			}
		);
	}

	private doExportToExcel() {
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'ARCreditMemo_' + this.apCreditMemoDetails.ixcode + '.xlsx';
		worksheet = workbook.addWorksheet('APCreditMemo');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {

					if (gridCell.column.dataField === 'price') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
					if (gridCell.column.dataField === 'linetotal') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
				}
			}
		}).then((cellRange) => {
			// header
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			if (this.apCreditMemoDetails.address) {
				worksheet.mergeCells(2, 4, 2, 7);
			}
			headerRow.getCell(1).value = 'Credit Memo ID:';
			headerRow.getCell(1).font = { 'bold': true };
			let orderIdValue = this.apCreditMemoDetails.docnum;
			headerRow.getCell(2).value = orderIdValue;
			headerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.apCreditMemoDetails.address) {
				headerRow.getCell(3).value = 'Billing Address';
				headerRow.getCell(3).font = { 'bold': true };
				headerRow.getCell(4).value = this.apCreditMemoDetails.address || '';
			}

			// customer
			const customerRow = worksheet.getRow(3);
			if (this.apCreditMemoDetails.address2) {
				worksheet.mergeCells(3, 4, 3, 7);
			}
			customerRow.height = 30;
			customerRow.getCell(1).value = 'Customer';
			customerRow.getCell(1).font = { 'bold': true };
			let cardCode = '';
			if (!this.isQBErpUser) {
				cardCode = this.apCreditMemoDetails.cardcode || '';
			}
			customerRow.getCell(2).value = (cardCode ? cardCode + ' - ' : '') + this.apCreditMemoDetails.cardname;
			customerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.apCreditMemoDetails.address2) {
				customerRow.getCell(3).value = 'Shipping Address';
				customerRow.getCell(3).font = { 'bold': true };
				customerRow.getCell(4).value = this.apCreditMemoDetails.address2 || '';
			}

			// DocDate
			const docDateRow = worksheet.getRow(4);
			docDateRow.height = 30;
			docDateRow.getCell(1).value = 'Invoice Date';
			docDateRow.getCell(1).font = { 'bold': true };
			docDateRow.getCell(2).value = this.apCreditMemoDetails.docdate;
			docDateRow.getCell(2).alignment = { horizontal: 'left' };

			// DocdueDate
			const docdueDateRow = worksheet.getRow(5);
			docdueDateRow.height = 30;
			docdueDateRow.getCell(1).value = 'Invoice Due Date';
			docdueDateRow.getCell(1).font = { 'bold': true };
			docdueDateRow.getCell(2).value = this.apCreditMemoDetails.docduedate;
			docdueDateRow.getCell(2).alignment = { horizontal: 'left' };

			let numberOfRows = this.dataGridContainer.instance.getVisibleRows().length;

			// Sub Total
			let rowIncrement = 11;
			let subTotal = 0;
			const subTotalRow = worksheet.getRow(numberOfRows + rowIncrement);
			subTotalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				subTotalRow.getCell(5).value = 'Sub Total';
				subTotalRow.getCell(5).font = { 'bold': true };
				subTotalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.apCreditMemoDetails.doctotalsys);
				subTotalRow.getCell(6).alignment = { horizontal: 'right' };
			} else {
				subTotalRow.getCell(4).value = 'Sub Total';
				subTotalRow.getCell(4).font = { 'bold': true };
				subTotalRow.getCell(5).value = this._CustomCurrencyPipe.transform(this.apCreditMemoDetails.doctotalsys);
				subTotalRow.getCell(5).alignment = { horizontal: 'right' };
			}

			if (this.apCreditMemoDetails.totaldiscount > 0) {
				// Discount
				let discount = 0;
				rowIncrement = rowIncrement + 1;
				const discountRow = worksheet.getRow(numberOfRows + rowIncrement);
				discountRow.height = 30;
				discountRow.getCell(5).value = 'Discount';
				discountRow.getCell(5).font = { 'bold': true };
				discountRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.apCreditMemoDetails.totaldiscount);
				discountRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.apCreditMemoDetails.totalmiscamount > 0) {
				// Miscellaneous Charge
				let totalmiscamount = 0;
				rowIncrement = rowIncrement + 1;
				const totalmiscamountRow = worksheet.getRow(numberOfRows + rowIncrement);
				totalmiscamountRow.height = 30;
				totalmiscamountRow.getCell(5).value = 'Miscellaneous Charge';
				totalmiscamountRow.getCell(5).font = { 'bold': true };
				totalmiscamountRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.apCreditMemoDetails.totalmiscamount);
				totalmiscamountRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.apCreditMemoDetails.shippingcharges > 0) {
				// Frieght Charge
				let shippingcharges = 0;
				rowIncrement = rowIncrement + 1;
				const shippingchargesRow = worksheet.getRow(numberOfRows + rowIncrement);
				shippingchargesRow.height = 30;
				shippingchargesRow.getCell(5).value = 'Frieght Charge';
				shippingchargesRow.getCell(5).font = { 'bold': true };
				shippingchargesRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.apCreditMemoDetails.shippingcharges);
				shippingchargesRow.getCell(6).alignment = { horizontal: 'right' };
			}

			// Total
			let total = 0;
			rowIncrement = rowIncrement + 1;
			const totalRow = worksheet.getRow(numberOfRows + rowIncrement);
			totalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				totalRow.getCell(5).value = 'Total';
				totalRow.getCell(5).font = { 'bold': true };
				totalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.apCreditMemoDetails.doctotal);
				totalRow.getCell(6).alignment = { horizontal: 'right' };
			} else {
				totalRow.getCell(4).value = 'Total';
				totalRow.getCell(4).font = { 'bold': true };
				totalRow.getCell(5).value = this._CustomCurrencyPipe.transform(this.apCreditMemoDetails.doctotal);
				totalRow.getCell(5).alignment = { horizontal: 'right' };
			}

			// Remark
			const remarkRow = worksheet.getRow(numberOfRows + 13);
			totalRow.height = 30;
			remarkRow.getCell(1).value = 'Remark';
			remarkRow.getCell(1).font = { 'bold': true };
			remarkRow.getCell(2).value = this.apCreditMemoDetails.journalmemo || '';
			remarkRow.getCell(2).alignment = { horizontal: 'right' };

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	private doFetchListOfDistributionChannels(distributionChannelSearch?: any[]) {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		reqFormData.append('is_dropdown', 'true');
		reqFormData.append('view_fields', JSON.stringify(['guid', 'code', 'description']));
		if (distributionChannelSearch && distributionChannelSearch.length > 0) {
			reqFormData.append('filter', JSON.stringify(distributionChannelSearch));
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}

		this.getListOfDistributionChannelsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: response => {
				this.listOfDistributionChannels = [];
				if (response && response.flag) {
					this.listOfDistributionChannels = response.data || [];
				}
			}, error: error => {
			}
		});
	}
	//#endregion

}
