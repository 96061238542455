import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserIdleService } from 'angular-user-idle';
import { PageOptions } from '@app/models/common.model';
import { Login } from '@app/models/auth';
import { BusinessPartnerTabValEnum, SalesTabsValEnum, AppsCofigsTabValEnum, CRMTabValEnum, ErpTypeValEnum, ApPurchasesEnum, QuotesInternalTypeValEnum, ArCreditMemoEnum } from '@app/constants-enums/enums';
import { BusinessPlanDataSharingService } from '@app/services/business-plan-service/business-plan-data-sharing.service';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { ProductServiceService } from '@app/services/product-service/product-service.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { UserManagementService } from '@app/services/user-management-service/user-management-service.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CalledFromEnum } from '@app/constants-enums/enums';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import { PurchasesService } from '@app/services/purchases-service/purchases.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
declare function callGeneralJs(): any;
declare function callSidemenu(): any;

@Component({
	selector: 'app-sidemenu',
	templateUrl: './sidemenu.component.html',
	styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit, AfterViewInit, OnDestroy {
	public pageOptions = new PageOptions();
	router: Router;
	loginUser: Login = new Login();
	currentTab: string;
	adminPortal: string;
	roleName: string;
	public calledFromEnum = CalledFromEnum;
	public called_from: string;
	public isQBOnlineErpUser: boolean = false;
	public isEnabledPaymentMenu: boolean = false;
	public isEnabledShipmentMenu: boolean = false;
	public isEnabledTradePromotion: boolean = false;
	public isEnabledCostAccountingMenu: boolean = false;
	private erpType: string;
	private listOfPermissions: any[] = [];
	public isAdminUser: boolean = false;
	constructor(private _router: Router,
		private activatedRoute: ActivatedRoute,
		private loginService: LoginService,
		private _GlobalStateService: GlobalStateService,
		private userIdle: UserIdleService,
		private _loaderService: LoaderService, private _restApiService: RestApiService,
		public _CrmsService: CrmsService,
		public _SidemenuService: SidemenuService,
		public _BusinessPlanDataSharingService: BusinessPlanDataSharingService,
		private _ProductServiceService: ProductServiceService,
		public _SalesDataSharingService: SalesDataSharingService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _UserProfileService: UserProfileService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _UserManagementService: UserManagementService,
		private _ToastrService: ToastrService,
		private _CommissionService: CommissionService,
		private _PurchasesService: PurchasesService) {
		this.router = _router;
	}
	ngOnInit() {
		const permissions = this._PermissionsService.getPermissions();
		this.listOfPermissions = [];
		for (const per in permissions) {
			this.listOfPermissions.push(per);
		}
		this.loginUser = this.loginService.loginUser;
		this.currentTab = 'dashboard';
		this._SidemenuService.setCurrentMenu(this.currentTab);
		this._GlobalStateService.unsubscribe('CALLED_AS_OVERDUE_PAYMENT_TO_PURCHASE');
		this._GlobalStateService.subscribe('CALLED_AS_OVERDUE_PAYMENT_TO_PURCHASE', response => {
			if (response) {
				if (response.isDefault) {
					this.called_from = this._UserProfileService.getCalledFrom();
				}
			}
		});
		this.called_from = this._UserProfileService.getCalledFrom();
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			this.isAdminUser = this._LoginService.loginUser.account_detail.admin;
		} catch (e) {
			this.erpType = '';
		}
		// check Is QB Online User (no need to view code of QB user)

		this.isQBOnlineErpUser = false;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}

		this.isEnabledPaymentMenu = false;
		if (this.erpType === ErpTypeValEnum.SAPB1_HANA) {
			this.isEnabledPaymentMenu = true;
			if (this.listOfPermissions.indexOf('SALES_INCOMING_PAYMENTS_VIEW') === -1) {
				this.isEnabledPaymentMenu = false;
			}
		}
		this.isEnabledCostAccountingMenu = false;
		if (this.erpType === ErpTypeValEnum.NETSUITE) {
			this.isEnabledCostAccountingMenu = true;
			if (this.listOfPermissions.indexOf('PURCHASES_COST_ACCOUNTING_VIEW') === -1) {
				this.isEnabledCostAccountingMenu = false;
			}
		}
		this.isEnabledShipmentMenu = false;
		if (this.erpType === ErpTypeValEnum.SAPB1_HANA || this.erpType === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
			this.isEnabledShipmentMenu = true;
			if (this.listOfPermissions.indexOf('SALES_DELIVERY_VIEW') === -1) {
				this.isEnabledShipmentMenu = false;
			}
		}
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail['trade_promotion_feature']) {
			this.isEnabledTradePromotion = this._LoginService.loginUser.account_detail.trade_promotion_feature;
		}
	}

	ngOnDestroy(): void {
		this._GlobalStateService.unsubscribe('CALLED_AS_OVERDUE_PAYMENT_TO_PURCHASE');
	}

	ngAfterViewInit() {
		callGeneralJs();
	}

	sideMenuClick(sidemenu) {
		this._CrmsService.doClearSetterGetter();
		this._SalesDataSharingService.doClearAllSetterGetter();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}
		// End Get current permissions
		this.loginService.currentsidemenu = sidemenu;
		this.currentTab = sidemenu;
		this._SidemenuService.setCurrentMenu(this.currentTab);
		// use for removing responsive side menu class in general.js
		callSidemenu();
		switch (sidemenu) {
			case 'dashboard':
				this.router.navigate(['/dashboard']);
				break;

			// #region for USER MANAGEMENT
			case 'userprofile':
				this._UserManagementService.setTabSelection('userprofile');
				this.router.navigate(['/usermanagements/userprofile']);
				break;
			case 'currentplan':
				this._UserManagementService.setTabSelection('currentplan');
				this.router.navigate(['/usermanagements/currentplan']);
				break;
			case 'billinghistory':
				this._UserManagementService.setTabSelection('billinghistory');
				this.router.navigate(['/usermanagements/billinghistory']);
				break;
			case 'usersmanagements':
				this._UserManagementService.setCurrentTab('allusers');
				this.router.navigate(['/usermanagements/allusers']);
				break;
			case 'usermanagement':
				this._UserManagementService.setCurrentTab('allusers');
				this.router.navigate(['/usermanagements/allusers']);
				break;
			case 'rolesmanagement':
				this.router.navigate(['/usermanagements/rolemanagement']);
				break;
			case 'usergroups':
				this._UserManagementService.setCurrentTab('usergroups');
				this.router.navigate(['/usermanagements/usergroups']);
				break;
			// #endregion

			// #region for CRMS
			case 'crms':
				if (permsn.indexOf('CRM_LEAD_VIEW') !== -1) {
					this._CrmsService.setSelectedTabData(CRMTabValEnum.LEAD);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['/crms/leads-list']);
				} else if (permsn.indexOf('CRM_PROSPECT_VIEW') !== -1) {
					this._CrmsService.setSelectedTabData(CRMTabValEnum.PROSPECT);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['/crms/prospects-list']);
				} else if (permsn.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
					this._CrmsService.setSelectedTabData(CRMTabValEnum.CLIENT);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['/crms/clients-list']);
				} else if (permsn.indexOf('CRM_TASK_VIEW') !== -1) {
					this._CrmsService.setSelectedTabData(CRMTabValEnum.TASKS);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['/crms/tasks-list']);
				}
				break;
			case 'leads':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.LEAD);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['/crms/leads-list']);
				break;
			case 'prospects':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.PROSPECT);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['/crms/prospects-list']);
				break;
			case 'clients':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.CLIENT);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['/crms/clients-list']);
				break;
			case 'tasks':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.TASKS);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['/crms/tasks-list']);
				break;
			// #endregion

			// #region for PRODUCTS
			case 'products':
				if (permsn.indexOf('PRODUCTS_PRODUCT_LIST_VIEW') !== -1) {
					this._ProductServiceService.productCurrentTab = 'listOfProducts';
					this._ProductServiceService.setPageOptions(this.pageOptions);
					this._ProductServiceService.setSearchFilter(undefined);
					this.router.navigate(['/products/list']);
				} else if (permsn.indexOf('PRODUCTS_PRICE_LIST_VIEW') !== -1) {
					this._ProductServiceService.productCurrentTab = 'priceLists';
					this._ProductServiceService.setPageOptions(this.pageOptions);
					this._ProductServiceService.setSearchFilter(undefined);
					this.router.navigate(['/products/pricelist']);
				}
				break;
			case 'listOfProducts':
				this._ProductServiceService.productCurrentTab = 'listOfProducts';
				this._ProductServiceService.setPageOptions(this.pageOptions);
				this._ProductServiceService.setSearchFilter(undefined);
				this.router.navigate(['/products/list']);
				break;
			case 'priceLists':
				this._ProductServiceService.productCurrentTab = 'priceLists';
				this._ProductServiceService.setPageOptions(this.pageOptions);
				this._ProductServiceService.setSearchFilter(undefined);
				this.router.navigate(['/products/pricelist']);
				break;
			// #endregion

			// #region for INCENTIVE
			case 'incentives':
				if (permsn.indexOf('INCENTIVE_COMMISSION_VIEW') !== -1) {
					this.router.navigate(['/contracts/commissionlist']);
				} else if (permsn.indexOf('INCENTIVE_REBATES_VIEW') !== -1) {
					this.router.navigate(['/contracts/rebateslist']);
				} else if (permsn.indexOf('INCENTIVE_ROYALTIES_VIEW') !== -1) {
					this.router.navigate(['/contracts/royaltieslist']);
				} else if (permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_VIEW') !== -1) {
					this.router.navigate(['/contracts/billbacklist']);
				}
				// else if (permsn.indexOf('INCENTIVE_TRADE_PROMOTION_VIEW') !== -1) {
				// 	this.router.navigate(['/contracts/trade-promotion']);
				// }
				break;
			case 'commission':
				// this.router.navigate(['/contracts/list']);
				this.router.navigate(['/contracts/commissionlist']);
				this._CommissionService.setAgreementMainTab('commission_agreements');
				break;
			case 'rebates':
				this.router.navigate(['/contracts/rebateslist']);
				break;
			case 'royalties':
				this.router.navigate(['/contracts/royaltieslist']);
				break;
			case 'billbacks':
				this.router.navigate(['/contracts/billbacklist']);
				break;
			case 'trade-promotion':
				this.router.navigate(['/contracts/trade-promotion']);
				break;
			// #endregion

			// #region for BUSINESS PARTNERS
			case 'businesspartners':
				if (permsn.indexOf('BUSINESSPARTNERS_CUSTOMER_VIEW') !== -1) {
					// this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.CLIENT);
					// this.router.navigate(['/businesspartners/customers-list']);
					if (permsn.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
						this._CrmsService.setSelectedTabData(CRMTabValEnum.CLIENT);
						this._CrmsService.setStoredFilters(undefined);
						this.router.navigate(['/crms/clients-list']);
					} else {
						this._ToastrService.error('You dont have permission – please contact Administrator!', 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else if (permsn.indexOf('BUSINESSPARTNERS_VENDORS_VIEW') !== -1) {
					this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.VENDOR);
					this.router.navigate(['/businesspartners/vendors-list']);
				} else if (permsn.indexOf('BUSINESSPARTNERS_BUYINGGROUPS_VIEW') !== -1) {
					this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.BUY_GROUP);
					this.router.navigate(['/businesspartners/buyinggroups-list']);
				} else if (permsn.indexOf('BUSINESSPARTNERS_ENDUSER_VIEW') !== -1) {
					this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.END_USERS);
					this.router.navigate(['/businesspartners/end-user-list']);
				}
				break;
			case 'customers':
				// this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.CLIENT);
				// this.router.navigate(['/businesspartners/customers-list']);
				if (permsn.indexOf('BUSINESSPARTNERS_CUSTOMER_VIEW') !== -1) {
					if (permsn.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
						this._CrmsService.setSelectedTabData(CRMTabValEnum.CLIENT);
						this._CrmsService.setStoredFilters(undefined);
						this.router.navigate(['/crms/clients-list']);
					} else {
						this._ToastrService.error('You dont have permission – please contact Administrator!', 'Error', { closeButton: true, tapToDismiss: true });
					}
				}
				break;
			case 'vendors':
				this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.VENDOR);
				this.router.navigate(['/businesspartners/vendors-list']);
				break;
			case 'buyinggroups':
				this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.BUY_GROUP);
				this.router.navigate(['/businesspartners/buyinggroups-list']);
				break;
			case 'endusers':
				this._BusinessPlanDataSharingService.setSelectedTabData(BusinessPartnerTabValEnum.END_USERS);
				this.router.navigate(['/businesspartners/end-user-list']);
				break;
			// #endregion

			// #region for SALES
			case 'sales':
				if (permsn.indexOf('SALES_QUOTATION_VIEW') !== -1) {
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.QUOTATIONS);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['/sales/quotations-list']);
				} else if (permsn.indexOf('SALES_ORDER_VIEW') !== -1) {
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.ORDERS);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['/sales/orders-list']);
				} else if (permsn.indexOf('SALES_ARINVOIECES_VIEW') !== -1) {
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.SALESINVOICES);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['/sales/invoices-list']);
				} else if (permsn.indexOf('SALES_INCOMING_PAYMENTS_VIEW') !== -1) {
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.PAYMENTS);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['/sales/payments-list']);
				} else if (permsn.indexOf('SALES_ARCREDITMEMO_VIEW') !== -1) {
					this._SalesDataSharingService.setArCreditMemoListOpenFrom(ArCreditMemoEnum.AR_CREDIT_MEMOS_LIST_FOR_SALES);
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.AR_CREDIT_MEMO);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['/sales/ar-credit-memos-list']);
				} else if (permsn.indexOf('SALES_DISTRIBUTION_CHARGEBACK_VIEW') !== -1) {
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.CHAGEBACK);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['/sales/chargebacks']);
				} else if (permsn.indexOf('SALES_ENDUSER_INVOICES_VIEW') !== -1) {
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.END_USER_INVOICES);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['/sales/end-user-invoices-list']);
				}
				break;
			case 'quotes':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.QUOTATIONS);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['/sales/quotations-list']);
				break;
			case 'salesorders':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.ORDERS);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['/sales/orders-list']);
				break;
			case 'shipments':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.SHIPMENT);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this._SalesDataSharingService.setQuotesIntenalType(QuotesInternalTypeValEnum.SHIPMENTDETSALES);
				this.router.navigate(['/sales/shipment-list']);
				break;
			case 'salesinvoices':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.SALESINVOICES);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['/sales/invoices-list']);
				break;
			case 'payments':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.PAYMENTS);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['/sales/payments-list']);
				break;
			case 'ar-credit-memos-list':
				this._SalesDataSharingService.setArCreditMemoListOpenFrom(ArCreditMemoEnum.AR_CREDIT_MEMOS_LIST_FOR_SALES);
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.AR_CREDIT_MEMO);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['/sales/ar-credit-memos-list']);
				break;
			case 'chargebacklist':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.CHAGEBACK);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['/sales/chargebacks/distribution-chargebacks-list']);
				break;
			case 'end-user-invoices-list':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.END_USER_INVOICES);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['/sales/end-user-invoices-list']);
				break;
			// #endregion

			// #region for REPORTS
			case 'reports':
				if (permsn.indexOf('REPORTS_YEAR_TO_DATE_VIEW') !== -1) {
					// this._AppCommonSrvc.setSelectedReportTab('year_to_date');
					// this.router.navigate(['/reports/yeartodate']);
				} else if (permsn.indexOf('REPORTS_YEAR_OVER_YEAR_VIEW') !== -1) {
					// this._AppCommonSrvc.setSelectedReportTab('year_to_year');
					// this.router.navigate(['/reports/yeartoyear']);
				} else if (permsn.indexOf('REPORTS_PERIOD_TO_DATE_VIEW') !== -1) {
					// this._AppCommonSrvc.setSelectedReportTab('period_to_date');
					// this.router.navigate(['/reports/periodtodate']);
				} else if (permsn.indexOf('REPORTS_INCENTIVES_VIEW') !== -1) {
					// this._AppCommonSrvc.setSelectedReportTab('period_to_date');
					// this.router.navigate(['/reports/periodtodate']);
				}
				this.router.navigate(['/reports/reportslist']);
				break;
			case 'statementsreportlist':
				this.router.navigate(['/reports/statementsreportlist']);
				break;
			case 'yeartodate':
				// this._AppCommonSrvc.setSelectedReportTab('year_to_date');
				// this.router.navigate(['/reports/yeartodate']);
				this.router.navigate(['/reports/reportslist']);
				break;
			case 'yeartoyear':
				this._AppCommonSrvc.setSelectedReportTab('year_to_year');
				this.router.navigate(['/reports/yeartoyear']);
				break;
			case 'periodtodate':
				this._AppCommonSrvc.setSelectedReportTab('period_to_date');
				this.router.navigate(['/reports/periodtodate']);
				break;
			case 'relateditemsanalytics':
				this._AppCommonSrvc.setSelectedReportTab('reateditemsanalytics');
				this.router.navigate(['/reports/relateditemsanalytics']);
				break;
			case 'potentialrisk':
				this._AppCommonSrvc.setSelectedReportTab('potential_losses');
				this.router.navigate(['/reports/potentialrisk']);
				break;
			case 'incentives_reports':
				this._AppCommonSrvc.setSelectedReportTab('incentives_reports');
				this.router.navigate(['/reports/incentivesreports']);
				break;
			// #endregion

			// #region for APPLICATION CONFIGURATION
			case 'apps-configs':
				if (permsn.indexOf('SYSTEM_CONFIGURATIONS_VIEW') !== -1) {
					this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.SYSTEMCONFIGURATIONS);
					this.router.navigate(['/apps-configs/systemconfigurations']);
				} else if (permsn.indexOf('FISCAL_CALCULATION_OPTIONS_VIEW') !== -1) {
					this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.FISCALCALCULATIONOPTIONS);
					this.router.navigate(['/apps-configs/fiscalcalcoptions']);
				} else if (permsn.indexOf('DYNAMIC_FIELDS_VIEW') !== -1) {
					this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.DYNAMICFIELDS);
					this.router.navigate(['/apps-configs/dynamicfields']);
				} else {
					this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.SYSTEMCONFIGURATIONS);
					this.router.navigate(['/apps-configs/systemconfigurations']);
				}
				break;
			case 'fiscalcalculationoptions':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.FISCALCALCULATIONOPTIONS);
				this.router.navigate(['/apps-configs/fiscalcalcoptions']);
				break;
			case 'systemconfigurations':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.SYSTEMCONFIGURATIONS);
				this.router.navigate(['/apps-configs/systemconfigurations']);
				break;
			case 'dynamicfields':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.DYNAMICFIELDS);
				this.router.navigate(['/apps-configs/dynamicfields']);
				break;
			case 'workflows':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.WORKFLOWS);
				this.router.navigate(['/apps-configs/workflows']);
				break;
			case 'audit_logs':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.AUDIT_LOGS);
				this.router.navigate(['/apps-configs/auditlogs']);
				break;
			case 'approval_trails_logs':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.APPROVAL_TRAILS_LOGS);
				this.router.navigate(['/apps-configs/approvaltrailslogs']);
				break;
			// #endregion

			// #region for Purchases
			case 'purchases':
				if (permsn.indexOf('PURCHASES_APINVOICES_VIEW') !== -1) {
					this._PurchasesService.setTabPurchases(ApPurchasesEnum.AP_INVOICES_TAB);
					this._PurchasesService.setStoredFilters(undefined);
					this.router.navigate(['/purchases/ap-invoices-list']);
				} else if (permsn.indexOf('PURCHASES_APCREDITMEMO_VIEW') !== -1) {
					this._PurchasesService.setTabPurchases(ApPurchasesEnum.AP_CREDIT_MEMOS_TAB);
					this._PurchasesService.setStoredFilters(undefined);
					this.router.navigate(['/purchases/ap-credit-memos-list']);
				}
				break;
			case 'ap-invoices-list':
				if (permsn.indexOf('PURCHASES_APINVOICES_VIEW') !== -1) {
					this._PurchasesService.setPurchasInvoiceType(ApPurchasesEnum.AP_PURCHASE_INVOICE_LIST);
					this._PurchasesService.setTabPurchases(ApPurchasesEnum.AP_INVOICES_TAB);
					this._PurchasesService.setStoredFilters(undefined);
					this.router.navigate(['/purchases/ap-invoices-list']);
				}
				break;
			case 'ap-credit-memos-list':
				if (permsn.indexOf('PURCHASES_APCREDITMEMO_VIEW') !== -1) {
					this._PurchasesService.setTabPurchases(ApPurchasesEnum.AP_CREDIT_MEMOS_TAB);
					this._PurchasesService.setStoredFilters(undefined);
					this.router.navigate(['/purchases/ap-credit-memos-list']);
				}
				break;
			case 'ap-cost-accounting-list':
				if (permsn.indexOf('PURCHASES_COST_ACCOUNTING_VIEW') !== -1) {
					this._PurchasesService.setTabPurchases(ApPurchasesEnum.AP_COST_ACCOUNTING_TAB);
					this._PurchasesService.setStoredFilters(undefined);
					this.router.navigate(['/purchases/ap-cost-accounting-list']);
				}
				break;
			//#endregion
		}
	}

	logout() {
		if (this.loginService.loginUser && this.loginService.loginUser.user) {
			const formdata = 'usr=' + this.loginService.loginUser.user + '&' + 'token=' + this.loginService.loginUser.token + '&logout=1';
			this._loaderService.show();
			this._restApiService.doLoginReq(formdata).subscribe(response => {
				this._loaderService.hide();
				this._PermissionsService.flushPermissions();
				this.userIdle.stopTimer();
				this.userIdle.stopWatching();
				this.loginService.loginUser = undefined;
				this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
				this.router.navigate(['/auth/login'], { replaceUrl: true });
			}, error => {
				this._loaderService.hide();
				console.error(error);
			});
		} else {
			this.router.navigate(['/auth/login']);
		}
	}
	// logout() {
	// 	$.ajax({
	// 		method: 'POST',
	// 		url: 'https://hmswin.webair.com:444/pwdUtil.php',
	// 		data: { 'usr': this.loginService.loginUser.user, 'token': this.loginService.loginUser.token, 'logout': 1 },
	// 		success: (data, status, xhr) => {
	// 			this.loginService.loginUser.user = "";
	// 			this.loginService.loginUser.pwd = "";
	// 			this.loginService.loginUser.token = "";
	// 			this.loginService.currentsidemenu = "";
	// 			this.router.navigate(['/auth/login']);
	// 		}
	// 	});
	// }


}
