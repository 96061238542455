import { DecimalPipe } from '@angular/common';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { DataService } from '@app/services/data.service';
import { Component, OnInit, AfterViewInit, Inject, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ErpTypeValEnum } from "@app/constants-enums/enums";
import { environment } from "@environments/environment";
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-bc-view-billback-documents-dialog',
	templateUrl: './bc-view-billback-documents-dialog.component.html',
	styleUrls: ['./bc-view-billback-documents-dialog.component.css'],
	providers: [DecimalPipe]
})
export class BcViewBillbackDocumentsDialogComponent implements OnInit {
	public billBackDocumentDetails: any;
	public totalClaimed: number;
	public totalClaimedAmount: number;
	public billBackType: string;
	public downloadDocumentUrl: string;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public uomListData: any = [];
	public decimalPointForCurrency: number;
	@ViewChild("documenDataGridContainer", {}) documenDataGridContainer: DxDataGridComponent;
	public documentDataSource: any;
	public isShowPopup: boolean = true;
	@Input() viewBillbackDocumentProps: any;
	@Output() closeViewBillbackDocumentPopup = new EventEmitter<any>();

	constructor(
		private _LoginService: LoginService,
		private _DataService: DataService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _BillbackService: BillbackService,
		private _ToastrService: ToastrService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _DecimalPipe: DecimalPipe,
	) { }

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.uomListData = this._DataService.getUOMs();
		this.billBackDocumentDetails = this.viewBillbackDocumentProps.billBackDocumentDetails;
		this.billBackType = this.viewBillbackDocumentProps.billBackType;
		this.totalClaimed = 0;
		this.totalClaimedAmount = 0;
		this.documentDataSource = this.billBackDocumentDetails.billedItems;
		this.loadComponent();
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
	}

	public loadComponent() {
		if (this.viewBillbackDocumentProps.billBackDocumentDetails.documentURL) {
			const documentURL = this.viewBillbackDocumentProps.billBackDocumentDetails.documentURL;
			const documentNewURLArr = documentURL.split('-');
			if (documentNewURLArr.length > 0) {
				const docId = documentNewURLArr[0];
				const docDate = documentNewURLArr[1];
				const docCode = documentNewURLArr[2];
				const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=billbackDoc'
				this.downloadDocumentUrl = finalUrl;
			}
		}
		for (let i = 0; i < this.viewBillbackDocumentProps.billBackDocumentDetails.billedItems.length; i++) {
			const objClaimed = parseFloat(this.viewBillbackDocumentProps.billBackDocumentDetails.billedItems[i].quantity) || 0;
			const objClaimedAmount = parseFloat(this.viewBillbackDocumentProps.billBackDocumentDetails.billedItems[i].linetotal) || 0;
			this.totalClaimed = this.totalClaimed + objClaimed;
			this.totalClaimedAmount = this.totalClaimedAmount + objClaimedAmount;
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	public doExportToExcel(e) {
		if (this.documenDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let RefNumber = this.billBackDocumentDetails.documentReferenceNumber;
		let fileName = 'Billback_Document_' + RefNumber + '.xlsx';
		worksheet = workbook.addWorksheet('Document-Details');

		exportDataGrid({
			worksheet: worksheet,
			component: this.documenDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'billbackAmount') {
						excelCell.value = '';
						if (gridCell.value) {
							if (gridCell.data && gridCell.data.billbackAmount) {
								excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
							}
						}
					}
					if (gridCell.column.dataField === 'linetotal') {
						excelCell.value = '';
						if (gridCell.value) {
							if (gridCell.data && gridCell.data.linetotal) {
								excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
							}
						}
					}
					if (gridCell.column.dataField === 'quantity') {
						excelCell.value = '';
						if (gridCell.value) {
							if (gridCell.data && gridCell.data.quantity) {
								excelCell.value = this._DecimalPipe.transform(gridCell.value || 0, '1.2-4');
							}
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doHiddenPopop(e) {
		this.isShowPopup = false;
	}
}
