import { DistributionChannelsCallFromViewEnum } from "@app/constants-enums/enums";

export class TerritoryDxDropDownBoxModel {
	public chkAllTerritory: boolean = false;
	public lengthSelectedTerritory: any;
	public arrSelectedTerritoryList: any = [];
	public territoryDataSource: any;
	public territoryGridBoxValue: any[] = [];
	public territoryGridBoxColumns: any[] = [];
	public selectedTerritoryData: any;
	constructor() {
		this.lengthSelectedTerritory = '';
	}
}

export class DistributionChannelsDxDropDownBoxModel {
	public arrSelectedDistributionChannelsList: any = [];
	public distributionChannelsDataSource: any = [];
	public callFromView: string;
	public showSelectionControls: boolean;
	public applyValueMode: string;
	public isDisabledControl: boolean;
	public selectionMode: string; // for dropdown selection mode single OR multiple
	constructor() {
		this.callFromView = DistributionChannelsCallFromViewEnum.DEFAULT;
		this.arrSelectedDistributionChannelsList = [];
		this.showSelectionControls = true;
		this.isDisabledControl = false;
		this.applyValueMode = 'useButtons';
		this.selectionMode = 'multiple';
	}
}
