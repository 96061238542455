export class CommissionContractModelUI {
	public ixcode: string;
	public guid: string;
	public baseContractCode: string;
	public contractName: string;
	public subuserguid: string;
	public subusername: string;
	public startDate: any;
	public endDate: any;
	public invoice_qualification_start_date: any;
	public invoice_qualification_end_date: any;
	public startSettlementPeriodOn: any;
	public startSettlementPeriodWeekOfDayOn: string;
	public contractCalendar: string;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public contract_type: string;
	public comparison_year?: string;
	public calculationOnBasis: string;
	public agreementBy: string;
	public isSettlementPeriodClosed: boolean;
	public is_active: boolean;
	public commissions: CommssionTabModel[] = [];
	public customer_items_groups: ExclusionTabModel[] = [];
	public growth_incentive: GrowthIncentiveTierbaseTabModel[] = [];
	public activities: ActivitiesTierbaseTabModel[] = [];
	public rules: RuleTabModel[] = [];
	public amortized_payments: any[] = [];
	public current_settlement_invoice_total: string;
	public current_settlement_commission_total: string;
	public calculate_commission_by: string;
	public tiers_type?: string;
	public customer_item_match?: string;
	public tier_mode?: string;
	public tier_accrual_period?: string;
	public tiers_include?: string;
	public currency_code?: string;
	// public distribution_channels?: any;
	constructor() {
		this.startSettlementPeriodOn = '1';
		this.contractCalendar = 'fiscal_year';
	}
}

export class CommissionContractModelRequest {
	public ixcode: string;
	public guid: string;
	public baseContractCode: string;
	public contractName: string;
	public subuserguid: string;
	public subusername: string;
	public startDate: any;
	public endDate: any;
	public invoice_qualification_start_date: string;
	public invoice_qualification_end_date: string;
	public startSettlementPeriodOn: any;
	public startSettlementPeriodWeekOfDayOn: string;
	public contractCalendar: string;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public contract_type: string;
	public comparison_year?: string;
	public calculationOnBasis: string;
	public agreementBy: string;
	public isSettlementPeriodClosed: boolean;
	public is_active: boolean;
	public current_settlement_invoice_total: string;
	public current_settlement_commission_total: string;
	public commissions: CommssionTabModel[] = [];
	public customer_items_groups: ExclusionTierbaseTabModel[] = [];
	public growth_incentive: GrowthIncentiveTierbaseTabModel[] = [];
	public activities: ActivitiesTierbaseTabModel[] = [];
	public rules: RuleTabModel[] = [];
	public amortized_payments: any[] = [];
	public calculate_commission_by: string;
	public tiers_type?: string;
	public customer_item_match?: string;
	public tier_mode?: string;
	public tier_accrual_period?: string;
	public tiers_include?: string;
	public currency_code?: string;
	public distribution_channels?: any;
}

export class CommissionContractTemplateModelUI {
	public ixcode: string;
	public guid: string;
	public baseContractCode: string;
	public template_name: string;
	public currency_code?: string;
	// public subuserguid: string;
	// public subusername: string;
	// public startDate: any;
	// public endDate: any;
	public startSettlementPeriodOn: any;
	public contractCalendar: string;
	public startSettlementPeriodWeekOfDayOn: string;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public contract_type: string;
	public comparison_year?: string;
	public calculationOnBasis: string;
	public agreementBy: string;
	public isSettlementPeriodClosed: boolean;
	public is_active: boolean;
	public commissions: CommssionTabModel[] = [];
	public customer_items_groups: ExclusionTabModel[] = [];
	public growth_incentive: GrowthIncentiveTierbaseTabModel[] = [];
	public activities: ActivitiesTierbaseTabModel[] = [];
	public rules: RuleTabModel[] = [];
	public current_settlement_invoice_total: string;
	public current_settlement_commission_total: string;
	public calculate_commission_by: string;
	public tiers_type?: string;
	public customer_item_match?: string;
	public tier_mode?: string;
	public tier_accrual_period?: string;
	public tiers_include?: string;
	constructor() {
		this.startSettlementPeriodOn = '1';
		this.contractCalendar = 'fiscal_year';
	}
}

export class CommissionContractTemplateModelRequest {
	public ixcode: string;
	public guid: string;
	public baseContractCode: string;
	public template_name: string;
	// public subuserguid: string;
	// public subusername: string;
	// public startDate: any;
	// public endDate: any;
	public startSettlementPeriodOn: any;
	public startSettlementPeriodWeekOfDayOn: string;
	public contractCalendar: string;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public contract_type: string;
	public comparison_year?: string;
	public calculationOnBasis: string;
	public agreementBy: string;
	public isSettlementPeriodClosed: boolean;
	public is_active: boolean;
	public current_settlement_invoice_total: string;
	public current_settlement_commission_total: string;
	public commissions: CommssionTabModel[] = [];
	public customer_items_groups: ExclusionTierbaseTabModel[] = [];
	public growth_incentive: GrowthIncentiveTierbaseTabModel[] = [];
	public activities: ActivitiesTierbaseTabModel[] = [];
	public rules: RuleTabModel[] = [];
	public calculate_commission_by: string;
	public tiers_type?: string;
	public customer_item_match?: string;
	public tier_mode?: string;
	public tier_accrual_period?: string;
	public tiers_include?: string;
	public currency_code?: string;

	constructor() {

	}
}

export class CommssionTabModel {
	public numberOfMonths: string;
	public rate: string;
}

export class CustomerTabModel {
	public code: string;
	public name: string;
}

export class ProductAndGroupTabModel {
	public itemcode: string;
	public itemname: string;
	public itemOrGroup: string;
}

export class ExclusionTabModel {
	public clientcode: string;
	public clientname: string;
	public itemOrGroup: string;
	public productcode: string;
	public productname: string;
	public rate: string;
}

export class GrowthIncentiveTierbaseTabModel {
	public guid: string;
	public erp_primary_key: any;
	public clientguid: string;
	public clientcode: string;
	public clientname: string;
	public mycustomers: boolean;
	public allcustomers: boolean;
	public assignedcustomers: boolean;
	public bytransactions: boolean;
	public sales_team_transactions: boolean;
	public allcustomergroups: boolean;
	public itemsku: string;
	public itemOrGroup: any;
	public itemname: string;
	public allproducts: boolean;
	public description: string;
	public tier_basis: string; // Value Could be "volume" | "revenue" | "notiers"
	public commission_basis: any;  // Value could be "doller" | "percentage" | "dollerperuom"
	public uomlist: any;
	public selectedUom: string;
	public listPrice: any;
	public rate: any;
	public commissionfinalprice: any;
	public tiers: any
	constructor() {
		this.allcustomers = false;
		this.assignedcustomers = false;
		this.bytransactions = false;
		this.sales_team_transactions = false;
		this.allcustomergroups = false;
		this.allproducts = false;
	}
}

export class ExclusionTierbaseTabModel {
	public guid: string;
	public erp_primary_key: any;
	public clientguid: string;
	public clientcode: string;
	public clientname: string;
	public mycustomers: boolean;
	public allcustomers: boolean;
	public allcustomergroups: boolean;
	public assignedcustomers: boolean;
	public bytransactions: boolean;
	public sales_team_transactions: boolean;
	public itemsku: string;
	public itemOrGroup: any;
	public itemType: string;  //Invetory,NonInventory,Inventory-Assembly,Other Change etc..
	public itemname: string;
	public allproducts: boolean;
	public classguid: string;  //Use Only ERP type as NETSUITE
	public classid: string;  //Use Only ERP type as NETSUITE
	public classname: string; //Use Only ERP type as NETSUITE
	public allclasses: boolean; //Use Only ERP type as NETSUITE
	public description: string;
	public tier_basis: string; // Value Could be "volume" | "revenue" | "notiers"
	public commission_basis: any;  // Value could be "doller" | "percentage" | "dollerperuom"
	public uomlist: any;
	public selectedUom: string;
	public listPrice: any;
	public rate: any;
	public commissionfinalprice: any;
	public tiers: any
	public territoryguid: string; //territory drop-down-list
	public territoryname: string; //territory drop-down-list
	public allterritories: boolean;//territory drop-down-list
	public customer_age_tiers: any
	public items?: any[];
	public id?: any;
	public name?: any;
	constructor() {
		this.allcustomers = false;
		this.assignedcustomers = false;
		this.bytransactions = false;
		this.sales_team_transactions = false;
		this.allcustomergroups = false;
		this.allproducts = false;
		this.allterritories = false;
		this.allclasses = false;
	}
}

export class ActivitiesTierbaseTabModel {
	public guid: string;
	public activities_guid: string;
	public activities_label: string;
	public activities_value: string;
	public activities_tiers_items: ActivitiesTierItemMasterModel[] = [];
}
export class ActivitiesTierItemMasterModel {
	public guid: string;
	public maximum: string;
	public maxtier: string;
	public rate: string;
}

export class RuleTabModel {
	public guid: string;
	public condition: string;
	public criterion: string;
	public name: string;
	public ruleBonusType: string;
	public ruleRate: string;
}

export class Invoices {
	linenum: string;
	itemsku: string;
	itemdescription: string;
	price: number;
	linetotal: number;
	quantity: number;
	grossprice: number;
	grosstotal: number;
	costperitem: number;
	pickstatus: string;
	linestatus: string;
	taxpercentageperrow: number;
	taxtotal: number;
	uomcode: string;
	discountpercent: number;
	grossprofit: number;
	commissionRate: string;
	commissionType: string;
	condition: string;
	criterion: string;
	itemname: string;
	description: string;
	isRuleApplied: boolean;
	commissionRateIn: string;
	commissionAmount: number;
	invoiceCode: number;
	invoiceDate: string;
	clientCode: string;
	clientName: string;
	year: number;
	month: string;
	monthYear: string;
}

export class ExtraCommissionBasedRules {
	commissionAmount: number;
	commissionRate: number;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
}

export class ARcreditMemos {
	clientCode: string;
	clientName: string;
	commissionAmount: string;
	commissionRate: string;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
	doctype: string;
	description: string;
	invoiceCode: string;
	isRuleApplied: string;
	itemsku: string;
	// itemdescription: string;
	itemname: string;
	linetotal: number;
	price: number;
	quantity: number;
}

export class MiscellaneousDeductionRules {
	commissionAmount: number;
	commissionRate: number;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
	linetotal: string;
}

export class CommissionSettlemntRptModel {
	public ixcode: string;
	public contractGuid: string;
	public contractName: string;
	public salesRep: string;
	public calculate_commission_by: string;
	public currency_code: string;
	public calculationOnBasis: string;
	public settlementPeriodStartDate: any;
	public settlementPeriodEndDate: any;
	public created_date: any;
	public remark: string;
	public apInvoiceCreatedInERP: string;//Open,Processing,Success,Error
	public settlementValue: any;
	public totalCommissionGenerated: any;
	public totalCommissionPayable: any;
	public subusername: string;
	public apInvoiceNumber: string;
	public currencyFormat: any;
	constructor() {

	}
}

export interface ICommissionReportInvoice {
	clientCode: string
	clientName: string
	classid: string
	classname: string
	erp_primary_key: string
	itemname: any
	type: any
	itemsku: any
	description: string
	commission_basis: string
	commissionAmount: number
	linetotal: number
	cashdiscount: number
	miscDedAmount: number
	miscDedAllItemsAmount: number
	miscDedFcAmount: number
	miscDedFpAmount: number
	creditmemototal: number
	finalAmount: number
	rebateCalculation: any[]
	rebate_amount: number
	fiscal_sequence: number
	number: number
	linenum: number
	costperitem: number
	costassignments: any[]
	price: number
	quantity: number
	pickstatus: string
	grossprice: number
	grosstotal: number
	taxpercentageperrow: number
	itemcode: string
	uomcode: string
	uomentry: string
	class: IClass
	account: IAccount
	linestatus: string
	taxtotal: number
	discountpercent: number
	tier_basis: string
	tiers: any[]
	commissionRateIn: string
	grossprofit: number
	invoiceDate: string
	docentry: string
	doctotal: number
	commissionRate: number
	commissionType: string
	exchangerate: number
	doccurrency: string
	contract_selectedUom: string
	condition: string
	criterion: string
	amountpaid: number
	paymentParcent: number
	miscdeductioninventory: number
	miscdeductionallitems: number
	miscdeductionfixedcost: number
	miscdeductionfixedpercentage: number
	discount: number
	costtotal: number
	orgCommissionAmount: number
	invoiceCode: string
	document_type: string
	year: number
	month: string
	monthYear: string
}

export interface IClass {
	id: string
	name: string
}

export interface IAccount {
	id: string
	name: string
}