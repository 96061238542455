import { MSG_ERROR_MESSAGE, ServerAction, ServerEntity, ServerMethods, ServerMode } from '@app/constants-enums/constants';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as _ from 'underscore';
import saveAs from 'file-saver';
import { Subscription, Observable, Subject } from 'rxjs';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from "devextreme/excel_exporter";
import { DecimalPipe } from "@angular/common";
import { MinMaxEndUsersChargebackItemTabModel, MinMaxEndUsersChargebackPriceListTabModel, MinMaxEndUsersPriceListItemModel } from "@app/models/min-max-end-users-chargeback-contract.model";
import { MinMaxEndUsersChargebackContractModelUI } from '@app/models/min-max-end-users-chargeback-contract.model';
import * as moment from 'moment';
import { ViewCreditMemoDetailPopUpModel, ViewEndusersInvoiceDetailPopUpModel, ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
declare const ExcelJS: ExcelJS;

@Component({
	selector: 'app-mmeucc-pricelist-tab',
	templateUrl: './mmeucc-pricelist-tab.component.html',
	styleUrls: ['./mmeucc-pricelist-tab.component.css']
})
export class MmeuccPricelistTabComponent implements OnInit, OnDestroy {
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	@ViewChild("pricelistDataGrid", {}) pricelistDataGrid: DxDataGridComponent;
	@ViewChild("endUsersDataGrid", {}) endUsersDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGridContainer", {}) itemDataGridContainer: DxDataGridComponent;
	@ViewChild("itemTabDataGridContainer", {}) itemTabDataGridContainer: DxDataGridComponent;
	@ViewChild("quantityDataGridContainer", {}) quantityDataGridContainer: DxDataGridComponent;
	@ViewChild('dxDropDownRefPricelistRep', {}) dxDropDownRefPricelistRep: DxDropDownBoxComponent;
	loadItemClaimDetailsSubject: Subject<any> = new Subject();

	public getUomLstSbsn: Subscription;
	public getItemDetailsSbsn: Subscription;
	private getPriceLstSbsn: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;

	public editChargebackGuid: string;
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public isVisiblePopup: boolean = false;
	public isItemClaimDetailsVisiblePopup: boolean = false;
	public itemClaimDetails: boolean = false;

	public pricelistDataSource: any;
	public priceListGridBoxValue: any;
	public pricelistGridBoxColumns: any;
	public arrSelectedPriceList: any = [];
	public lengthSelectedPricelists: any;
	public chkAllPricelist: boolean = false;
	public isPriceListDropDownBoxOpened: boolean = false;
	public endUsersDataSource: any;
	public endUsersGridBoxValue: any;
	public endUsersGridBoxColumns: any;
	public isEndUsersDropDownBoxOpened: boolean = false;
	public listOfCsvRowForItmGrpTab: any[] = [];
	public csvResponse: any;
	public isUploadExcelCsvFileItmGrpTab: boolean = false;
	public noOfUnmatchedItem: number;
	public noOfUnmatchedItemUom: number;
	public noOfmatchedItemPrice: number;
	public noOfTotalItem: number;
	public filterText: string = '';
	public selectedpriceListTabDataList: any[] = [];
	public selecteditemTabDataList: any[] = [];
	public selectedEndUsersDataList: any[] = [];
	public filterValueChangeSbsn: Observable<any>;
	public itemPriceListNo: string;
	public itemPriceListName: string;
	public listOfAvailableQuantity: any[] = [];
	@Input() customerListOfPrices: any[] = [];
	public endUserListOfPrices: any[] = [];
	@Input() itemTabDataList: any = [];
	@Output() saveChargebackItemTabDataEvent = new EventEmitter<string>();
	@Output() editChargebackItemTabDataEvent = new EventEmitter<string>();
	@Input() itemTabDataSource: any;
	public quantityHeaderText: string;
	public quantityDataField: string;
	// Model object
	public guid = new Guid();
	public pageOptions: PageOptions = new PageOptions();
	@Input() minMaxEndUsersChargebackContractModelUI = new MinMaxEndUsersChargebackContractModelUI();
	public unMatchedItems: any[] = [];
	public matchedPriceListItems: any[] = [];
	public enable_chargeback_look_back_period: boolean = false;
	public checkAllItem: boolean = false;
	public isShowInvoiceDetailPopUp: boolean = false;
	public isShowEndusersInvoiceDetailPopUp: boolean = false;
	public isShowCreditMemoDetailPopUp: boolean = false;
	public viewInvoiceDetailProps: ViewInvoiceDetailPopUpModel = new ViewInvoiceDetailPopUpModel();
	public viewEndusersInvoiceDetailProps: ViewEndusersInvoiceDetailPopUpModel = new ViewEndusersInvoiceDetailPopUpModel();
	public viewCreditMemoDetailProps: ViewCreditMemoDetailPopUpModel = new ViewCreditMemoDetailPopUpModel();
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'enduserinvoice', text: 'End-User Invoice' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'order', text: 'Order' }];
	public decimalPointForCurrency: number;
	public nowDate: Date = new Date();
	public minDate: Date = this.minMaxEndUsersChargebackContractModelUI?.startDate || new Date();
	public dxItemName: string = '';
	public dxItemDescription: string = '';
	public listOfSpecificCustomFeatureFields: any[] = [];
	public listOfFeaturesCustomFields: any[] = [];
	public decimalPercentageFormat: string;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public endUserItemsModeLst: any[] = [];
	private listOfExpandedRows: string[] = [];
	public isShowUploadItemsPopup: boolean = false;
	public uploadItemsProps: any;
	public isShowOtherPurchasePopup: boolean = false;
	public otherPurchaseProps: any;
	constructor(
		private _Router?: Router,
		private _ToastrService?: ToastrService,
		private _PermissionsService?: NgxPermissionsService,
		private _LoginService?: LoginService,
		private _LoaderService?: LoaderService,
		private _RestApiService?: RestApiService,
		private _ConfirmationDialogService?: ConfirmationDialogService,
		private _DataService?: DataService,
		private _MinMaxEndUsersChargebackContractService?: MinMaxEndUsersChargebackContractService,
		private _FileUploadService?: FileUploadService,
		private _GlobalStateService?: GlobalStateService,
		public _AppCommonSrvc?: AppCommonSrvc,
		private _CustomCurrencyPipe?: CustomCurrencyPipe,
		public _BillbackService?: BillbackService,
		private _DecimalPipe?: DecimalPipe,
		private _ChangeDetectorRef?: ChangeDetectorRef
	) { }

	ngOnInit() {
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.decimalPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		this.pricelistGridBoxColumns = this._BillbackService.getPriceListGridBoxColumn();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}

		this.editChargebackGuid = this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid();
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.endUsersGridBoxColumns = [
			{ dataField: 'code', caption: 'Code' },
			{ dataField: 'companyname', caption: 'Name' },
			{ dataField: 'pricelistname', caption: 'Pricelist' },
		];
		if (this._LoginService && this._LoginService.loginUser && this._LoginService.loginUser.account_detail) {
			this.enable_chargeback_look_back_period = this._LoginService.loginUser.account_detail.enable_chargeback_look_back_period || false;
		}
		const listOfMasterFeaturesCustomFields = this._AppCommonSrvc.getFeatureCustomFields();
		this.listOfFeaturesCustomFields = listOfMasterFeaturesCustomFields.filter(field => field.display === true && field.external_entity_field === true && field.entity_type === 'items' && field.section === "HEADER");
		this.preInit();
	}

	public preInit() {
		this._GlobalStateService.subscribe('CALLED_COLLAPSE_DATAGRID', response => {
			if (response) {
				this.doCollapseDatagrid();
			}
		});
		this._GlobalStateService.subscribe('CALLED_GET_LIST_PRICE', response => {
			if (response) {
				if (response.isDefault) {
					// console.log('itemTabDataList :: ', this.itemTabDataList);
					// console.log('newItemTabDataList :: ', response.newItemTabDataList);
					if (this.itemTabDataList && this.itemTabDataList.length > 0) {
						// this.itemTabDataSource = this.itemTabDataList;
						// let objEnduserItemlst: any;
						if (response.newItemTabDataList && response.newItemTabDataList.length > 0) {
							this.itemTabDataSource = response.newItemTabDataList;
							// objEnduserItemlst = response.newItemTabDataList;
						} else {
							this.itemTabDataSource = this.itemTabDataList;
							// objEnduserItemlst = this.itemTabDataList;
						}
					}
				}
			}
		});
		this.getListPriceOfItems();
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadEndUsersDataList();
		this.loadPricelistTabDataList();
		// setTimeout(() => {
		// 	this.filterValueChangeSbsn = this.itemTabDataGridContainer.filterValueChange.asObservable();
		// 	this.filterValueChangeSbsn.subscribe((result) => {
		// 		this.getFilterText();
		// 	});
		// }, 60);
	}

	ngAfterContentChecked() {
		this._ChangeDetectorRef.detectChanges();
	}

	ngOnDestroy(): void {

		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('CALLED_GET_LIST_PRICE');
		this._GlobalStateService.unsubscribe('GET_UOM_LIST_FROM_UOM_GROUP_ENTRY');
		this._GlobalStateService.unsubscribe('CALLED_COLLAPSE_DATAGRID');
	}

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		//this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				//	this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		//this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				//	this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	public doCellClick(element, itemDetail) {
		if (element.column.dataField === 'itemsku' || element.column.dataField === 'itemname' || element.column.dataField === 'description' || element.column.dataField === 'selectedUom' || element.column.dataField === 'available'
			|| element.column.dataField === 'listPrice' || element.column.dataField === 'final_amount' || element.column.dataField === 'chargebackAmount' || element.column.dataField === 'min' || element.column.dataField === 'max') {
			return;
		}
		if (element.data[element.column.dataField] && element.data[element.column.dataField].length === 0) {
			return;
		}

		if (element.row && element.row.rowType === 'data') {
			if ((element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0) || (element.column.dataField === 'other_bought')) {
				// if (!element.data.hasOwnProperty('isShowPopover_' + element.rowIndex + '_' + element.columnIndex) || (element.data.hasOwnProperty('isShowPopover_' + element.rowIndex + '_' + element.columnIndex) && !element.data['isShowPopover_' + element.rowIndex + '_' + element.columnIndex])) {
				// 	element.data['isShowPopover_' + element.rowIndex + '_' + element.columnIndex] = true;
				// 	this.getListOfInvoice(element);
				// }
				this.quantityHeaderText = element.column.caption + ' Quantity';
				this.quantityDataField = element.column.dataField;
				if (itemDetail.data.end_user_name) {
					this.quantityHeaderText = this.quantityHeaderText + ' Of ' + itemDetail.data.end_user_name
				}
				if (element.data && element.data.hasOwnProperty('itemname')) {
					this.dxItemName = element.data.itemname;
				}
				if (element.data && element.data.hasOwnProperty('description')) {
					this.dxItemDescription = element.data.description;
				}
				this.getItemDetailsRecords(element.data);
				//this.listOfAvailableQuantity = this.genrateSrNoCol(element.data[element.column.dataField]);
				this.listOfAvailableQuantity = [];
				if (element.column.dataField === 'claimed') {
					// item claim details pop-up open 
					this.isItemClaimDetailsVisiblePopup = true;
					this.itemClaimDetails = element.row['data'] || [];
					const objItemdetails = {
						end_user_code: itemDetail.data.end_user_code,
						itemClaimDetails: this.itemClaimDetails,
					}
					this.loadItemClaimDetailsSubject.next(objItemdetails);
				} else if (element.column.dataField === 'other_bought') {
					let otherQty = this.viewOtherQuantity(element.data);
					if (otherQty == 0) {
						return;
					}
					if (this.editChargebackGuid) {
						const enduserDetails = itemDetail.data;
						const itemDetails = element.row['data'];
						const mode = element.column.dataField;
						this.getEndUserItemsQtyModeLst(enduserDetails, itemDetails, mode).subscribe({
							next: (response) => {
								if (response.flag) {
									this.listOfAvailableQuantity = this.genrateSrNoCol(response.data) || [];
									this.isShowOtherPurchasePopup = false;
									setTimeout(() => {
										this.isShowOtherPurchasePopup = true;
									}, 20);
									this.otherPurchaseProps = {
										dxDataField: element.column.dataField,
										dxItemName: this.dxItemName,
										dxItemDescription: this.dxItemDescription,
										dxCustomerName: this.minMaxEndUsersChargebackContractModelUI.selectCustomer,
										listOfAvailableQuantity: this.listOfAvailableQuantity
									}
								} else {
									this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
								}
								this._LoaderService.hide();
							}, error: (error) => {
								this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
								this._LoaderService.hide();
							}
						});
					}
				}
				else {
					this.doOpenPopup(element);
					if (this.editChargebackGuid) {
						const enduserDetails = itemDetail.data;
						const itemDetails = element.row['data'];
						const mode = element.column.dataField;
						this.getEndUserItemsQtyModeLst(enduserDetails, itemDetails, mode).subscribe({
							next: (response) => {
								if (response.flag) {
									this.listOfAvailableQuantity = this.genrateSrNoCol(response.data) || [];
								} else {
									this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
								}
								this._LoaderService.hide();
							}, error: (error) => {
								this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
								this._LoaderService.hide();
							}
						});
					}
					// this.doOpenPopup(element);
				}
			}
		}
	}

	public genrateSrNoCol(listOfQty) {
		if (listOfQty && listOfQty.length > 0) {
			let i: number = 0;
			listOfQty.forEach(element => {
				element['sr_no'] = i + 1;
				i++;
			});
			// console.log('listOfQty ::', listOfQty);
			return listOfQty;
		}
	}

	public getEligibleVal(eligibleVal) {
		let rtnVal: any = ''
		if (eligibleVal === '') {
			rtnVal = eligibleVal;
		} else {
			if (eligibleVal) {
				rtnVal = 'Yes';
			} else {
				rtnVal = 'No'
			}
		}
		return rtnVal;
	}

	public doOpenPopup(element) {
		this.isVisiblePopup = true;
	}
	public doHiddenPopop(e) {
		this.isVisiblePopup = false;
	}

	convertToLocalDate(dateStr: string) {
		const localDate = moment(dateStr).format("YYYY-MM-DD");
		return localDate;
	}
	//#region end-users select box

	loadEndUsersDataList() {
		const viewFields = ['guid', 'code', 'ixcode', 'companyname', 'name', 'pricelistnum', 'pricelistname', 'taxcode', 'taxexempt'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ENDUSERS,
			view_fields: JSON.stringify(viewFields),
		};

		this.endUsersDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	public onEndUsersSelectionChanged(e) {
		if (e.name === "value") {
			this.isEndUsersDropDownBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	public displayExprEndUserName(enduser) {
		if (!enduser) {
			return "";
		}
		const name = enduser.companyname;
		return name.toString();
	}
	//#endregion end-user select box

	//#region pricelist select box methods
	loadPricelistTabDataList() {
		// const viewFields = ['guid', 'erp_primary_key', 'pricelistno', 'pricelistname', 'description'];
		// let formdata = environment.URL_DATAOUT + "/dataout.php";
		// formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		// formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		// formdata = formdata + "&method=" + ServerMethods.DX_GET_ENTITY_DATA;
		// formdata = formdata + "&entity=" + ServerEntity.PRICELISTS;
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		// // if (this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum) {
		// // 	formdata = formdata + '&pricelistnum=' + this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum;
		// // 	formdata = formdata + '&product_search_from=chargebacks';
		// // }
		// this.pricelistDataSource = AspNetData.createStore({
		// 	loadMode: "processed",
		// 	key: "guid",
		// 	loadUrl: formdata
		// });

		const viewFields = ['guid', 'erp_primary_key', 'pricelistno', 'pricelistname', 'description'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.PRICELISTS,
			view_fields: JSON.stringify(viewFields),
		};

		this.pricelistDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	public onOptionChangedPriceList(e) {
		if (e.name === "value") {
			this.isPriceListDropDownBoxOpened = false;
			// this.getMisMatchedEndUserPricelListItems();
			this._ChangeDetectorRef.detectChanges();
		}

	}

	//#endregion 

	//#region  Item data grid
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.itemTabDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(

			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		visible: !this.isCurrentTabPermission,
			// 		icon: 'xlsxfile',
			// 		text: "Import Excel/Csv",
			// 		onClick: (event: any) => {
			// 			this.doBulkUploadItmGrpTab();
			// 		}
			// 	}
			// },
			{
				location: "after",
				template: "dateBoxTemplate"
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	// Method used to Export Excelsheet for Main Item Grid
	private doExportToExcel(e) {
		if (this.itemDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let workSheet;
		let fileName = '';
		fileName = this.minMaxEndUsersChargebackContractModelUI.contractName + 'EndUser.xlsx';
		workSheet = workbook.addWorksheet('Items');
		const groupIndexesWithSingleItem = [];
		const listOfEndUsersMasterDetailsRows = [];
		exportDataGrid({
			worksheet: workSheet,
			component: this.itemDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 4, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					// if (gridCell.column.dataField === 'listPrice' || gridCell.column.dataField === 'chargebackAmount' || gridCell.column.dataField === 'final_amount') {
					// 	excelCell.value = '';
					// 	if (gridCell.value) {
					// 		excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
					// 	}
					// }
					if (gridCell.column.dataField === 'end_user_name') {
						excelCell.value = gridCell.value;
						listOfEndUsersMasterDetailsRows.push({
							rowIndex: excelCell.fullAddress.row + 1,
							data: gridCell.data
						});
					}
				}
			}
		}).then((cellRange) => {
			workSheet.mergeCells(1, 1, 1, 3);
			const headerRow1 = workSheet.getRow(1);
			const headerRow2 = workSheet.getRow(2);
			const headerRow3 = workSheet.getRow(3);
			headerRow1.getCell(1).value = this.minMaxEndUsersChargebackContractModelUI.contractName;
			headerRow1.getCell(1).font = { name: 'Segoe UI Light', size: 14, bold: true };
			headerRow1.getCell(1).alignment = { horizontal: 'center', vertical: 'center' };
			headerRow1.height = 30;
			// workSheet.mergeCells(1, 1, 1, 3);
			headerRow2.getCell(1).value = 'Customer Code';
			headerRow2.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			headerRow2.getCell(1).alignment = { horizontal: 'left', vertical: 'center' };
			headerRow2.getCell(2).value = this.minMaxEndUsersChargebackContractModelUI.selectCustomerId;
			headerRow2.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
			headerRow2.getCell(2).alignment = { horizontal: 'left', vertical: 'center' };

			headerRow3.getCell(1).value = 'Customer Name';
			headerRow3.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
			headerRow3.getCell(1).alignment = { horizontal: 'left', vertical: 'center' };
			headerRow3.getCell(2).value = this.minMaxEndUsersChargebackContractModelUI.selectCustomer;
			headerRow3.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
			headerRow3.getCell(2).alignment = { horizontal: 'left', vertical: 'center' };
			// workSheet.mergeCells(2, 2, 2, 3);
			groupIndexesWithSingleItem.sort((a, b) => b - a).forEach((rowIndex) => {
				workSheet.getRow(rowIndex + 1).outlineLevel = undefined;
				workSheet.spliceRows(rowIndex, 1);
			});

			// Logic for Invoice Details rows
			const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = workSheet.insertRow(currentIndex, [], "n");
				for (let j = workSheet.rowCount + 1; j > currentIndex; j--) {
					workSheet.getRow(j).outlineLevel = workSheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			let itemColumnsField = [
				"itemname",
				"description",
				"selectedUom",
				// "listPrice",
				// "final_amount",
				// "chargebackAmount",
				// "sold",
				"total_bought",
				"bought",
				"claimed",
				"available"
			];
			let itemColumns = [
				"Item",
				"Description",
				"UOM",
				// "List Price",
				// "Final Price",
				// "Chargeback Amount",
				"Total Bought",
				"Bought",
				// "Sold",
				"Claimed",
				"Available"
			];

			if (this.enable_chargeback_look_back_period) {
				itemColumnsField = [
					"itemname",
					"description",
					"selectedUom",
					// "sold",
					"total_bought",
					"bought",
					"claimed",
					"available"
				];
				itemColumns = [
					"Item",
					"Description",
					"UOM",
					// "Sold",
					"Total Bought",
					"Bought",
					"Claimed",
					"Available"
				];
			}

			for (let i = 0; i < listOfEndUsersMasterDetailsRows.length; i++) {
				let isQBOnlineErpUser = this.isQBOnlineErpUser;
				const columnIndex = cellRange.from.column;
				let row = insertRow(listOfEndUsersMasterDetailsRows[i].rowIndex + i, offset++, 2);
				// row.height = 24;
				// workSheet.mergeCells(row.number, columnIndex, row.number, 7);
				if (listOfEndUsersMasterDetailsRows[i].data.items && listOfEndUsersMasterDetailsRows[i].data.items.length > 0) {
					row = insertRow(listOfEndUsersMasterDetailsRows[i].rowIndex + i, offset++, 2);
					itemColumns.forEach((columnName, currentColumnIndex) => {
						row.keepColumnWidths = true;
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							fill: {
								type: "pattern",
								pattern: "solid",
								fgColor: { argb: "E8F7F9" }
							},
							font: { bold: true },
							// border: {
							// 	bottom: borderStyle,
							// 	left: borderStyle,
							// 	right: borderStyle,
							// 	top: borderStyle
							// }
						});
					});

					let boughtQty = 0;
					let totalBoughtQty = 0;
					let totalSoldQty = 0;
					let totalClaimedQty = 0;
					let totalAvailableQty = 0;
					listOfEndUsersMasterDetailsRows[i].data.items.forEach((itemDetails, itemIndex) => {
						const itemColumnIndexDetail = cellRange.from.column;
						row = insertRow(listOfEndUsersMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						itemColumnsField.forEach((columnName, currentColumnIndex) => {
							let valField = itemDetails[columnName];
							if (columnName === 'sold' || columnName === 'bought' || columnName === 'total_bought' || columnName === 'claimed' || columnName === 'available') {
								if (valField.length > 0) {
									valField = this.viewTotalQuantity(valField);
									if (columnName === 'sold') {
										totalSoldQty = totalSoldQty + valField;
									}
									if (columnName === 'claimed') {
										totalClaimedQty = totalClaimedQty + valField;
									}
									if (columnName === 'available') {
										totalAvailableQty = totalAvailableQty + valField;
									}
									if (columnName === 'bought') {
										boughtQty = boughtQty + valField;
									}
									if (columnName === 'total_bought') {
										totalBoughtQty = totalBoughtQty + valField;
									}
								} else {
									valField = 0;
								}
							}
							if (columnName === "listPrice" || columnName === "final_amount" || columnName === "chargebackAmount") {
								valField = this._CustomCurrencyPipe.transform(valField || 0);
							}
							if (columnName === 'selectedUom') {
								const indexDT = this.listOfUoms.findIndex(i => i.code === valField);
								if (indexDT !== -1) {
									valField = this.listOfUoms[indexDT].name;
								}
							}
							const cellObject = {
								value: valField,
								// fill: {
								// 	type: "pattern",
								// 	pattern: "solid",
								// 	fgColor: { argb: "BEDFE6" }
								// },
								// border: {
								// 	bottom: borderStyle,
								// 	left: borderStyle,
								// 	right: borderStyle,
								// 	top: borderStyle
								// },
								alignment: { horizontal: 'left' }
							};

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
							row.keepColumnWidths = true;
						});
						// row = insertRow(listOfEndUsersMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
					});

					row = insertRow(listOfEndUsersMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;
					row.keepColumnWidths = true;
					if (this.enable_chargeback_look_back_period) {
						workSheet.mergeCells(row.number, columnIndex, row.number, 3);
						rowLastCell = 4;
					} else {
						workSheet.mergeCells(row.number, columnIndex, row.number, 3);
						rowLastCell = 4;
					}
					Object.assign(row.getCell(columnIndex), {
						value: 'Total',
						// fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'right' }
					});

					Object.assign(row.getCell(rowLastCell + 1), {
						value: totalBoughtQty,
						// fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'left' }
					});
					Object.assign(row.getCell(rowLastCell), {
						value: boughtQty,
						// fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'left' }
					});
					Object.assign(row.getCell(rowLastCell + 2), {
						value: totalClaimedQty,
						// fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'left' }
					});
					Object.assign(row.getCell(rowLastCell + 3), {
						value: totalAvailableQty,
						// fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'left' }
					});


				}
				offset--;
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.itemTabDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	public doOnEditorPreparingItemTab(event) {
		if (event && event['row'] && event['row']['data']) {
			const itemObject = event.row.data;
			const listOfRows = this.itemTabDataGridContainer.instance.getVisibleRows();
			const rowIndex = this.itemTabDataGridContainer.instance.getRowIndexByKey(event.row.key);
			let itemDetails = new MinMaxEndUsersChargebackItemTabModel();
			if (rowIndex !== -1) {
				itemDetails = listOfRows[rowIndex].data || [];
			}
			if (event.parentType === "dataRow" && event.dataField === 'selectedUom') {
				event.editorOptions.dataSource = [];
				this._GlobalStateService.unsubscribe('GET_UOM_LIST_FROM_UOM_GROUP_ENTRY');
				this._GlobalStateService.subscribe('GET_UOM_LIST_FROM_UOM_GROUP_ENTRY', response => {
					const newUOMList = response.newUOMList || [];
					if (newUOMList && newUOMList) {
						// console.log('UOM List :: ', newUOMList);
						event.editorOptions.dataSource = JSON.parse(JSON.stringify(newUOMList));
						itemDetails.uomList = JSON.parse(JSON.stringify(newUOMList));
						// if (itemDetails && itemDetails.uomList) {
						// 	const UOMIndex = itemDetails.uomList.findIndex(i => i.code.toString().toLowerCase() === itemDetails.selectedUom.toString().toLowerCase());
						// 	if (UOMIndex !== -1) {
						// 		this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'selectedUom', itemDetails.uomList[UOMIndex].name);
						// 	}
						// }
					}
				});
				if (itemObject && itemObject['uomgroupentry']) {
					this.getUOMListFromUOMGroupEntry(itemObject['uomgroupentry']);
				}
				// else {
				// 	if (itemObject['erp_primary_key']) {
				// 		this.getUOMListWithoutUOMGroupEntry(itemObject['erp_primary_key']);
				// 	}
				// }
				// console.log('UOM GROUP ENTERY :: ', itemObject['uomgroupentry']);
				// console.log('ERP primary Key :: ', itemObject['erp_primary_key']);
				event.editorOptions.onValueChanged = (e: any) => {
					let targetUom = e.value;
					let sourceUom = e.previousValue;
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'selectedUom', targetUom);
					const uomData = itemDetails.uomList.filter(i => i.code.toString().toLowerCase() === targetUom.toString().toLowerCase());
					let uomprice = 0;
					if (uomData && uomData.length > 0) {
						uomprice = uomData[0].price || 0;
					}
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'listPrice', +uomprice);
					const chargeBack = +(this.calculateUomPrice(itemDetails.chargebackAmount, itemDetails.uomgroupentry, sourceUom, targetUom));
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', chargeBack);
					itemDetails.chargebackAmount = chargeBack;

					if (itemDetails.chargebackAmount) {
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', +((parseFloat(uomprice.toString()) - parseFloat(itemDetails.chargebackAmount))));
					} else {
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', +(parseFloat(uomprice.toString())));
					}
					if (targetUom !== sourceUom) {
						this.updateSameUOMForSameItem(itemDetails, targetUom);
					}
				}
			}
			if (event.parentType === "dataRow" && event.dataField === 'chargebackAmount') {
				event.editorOptions.onValueChanged = (e: any) => {
					const chargebackAmount = e.value;
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', chargebackAmount);
					if (chargebackAmount <= itemDetails.listPrice) {
						const val = +(parseFloat((itemDetails.listPrice - chargebackAmount).toFixed(2)));
						if (val > 0) {
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', val);
						} else {
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', 0);
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', itemDetails.listPrice);
						}
					} else {
						this._ToastrService.info('Chargeback cannot be grater then List Price', 'Info!', { closeButton: true, tapToDismiss: true });
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', itemDetails.listPrice);
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', 0);
					}
				}
			}
			if (event.parentType === "dataRow" && event.dataField === 'final_amount') {
				event.editorOptions.onValueChanged = (e: any) => {
					const finalprice = e.value;
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', finalprice);
					if (finalprice <= itemDetails.listPrice) {
						const val = +(parseFloat((itemDetails.listPrice - finalprice).toFixed(2)));
						if (val > 0) {
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', val);
						} else {
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', 0);
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', +itemDetails.listPrice);
						}
					} else {
						this._ToastrService.info('Final price cannot be grater then List Price', 'Info!', { closeButton: true, tapToDismiss: true });
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', 0);
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', itemDetails.listPrice);
					}
				}
			}
		}
	}

	public getUOMListFromUOMGroupEntry(uomgroupentry: string) {
		let UomCollectionList: any[] = [];
		const listOfUoms = this.listOfUoms || [];
		const newUOMList: any[] = [];
		if (this.listOfUomGroups.length > 0 && uomgroupentry) {
			const indexOfUOMGroup = this.listOfUomGroups.findIndex(i => i.absentry.toString().toLowerCase() === uomgroupentry.toString().toLowerCase());
			if (indexOfUOMGroup != -1) {
				const objUOMGroups = this.listOfUomGroups[indexOfUOMGroup];
				if (objUOMGroups && objUOMGroups['uomgroupdefinitioncollection']) {
					UomCollectionList = objUOMGroups['uomgroupdefinitioncollection'] || [];
				}
				if (UomCollectionList && UomCollectionList.length > 0 && listOfUoms && listOfUoms.length > 0) {
					UomCollectionList.forEach(element => {
						if (element && element['alternateuom']) {
							const indexOfUOM = listOfUoms.findIndex(i => i.absentry.toString().toLowerCase() === element['alternateuom'].toString().toLowerCase());
							if (indexOfUOM !== -1) {
								newUOMList.push(listOfUoms[indexOfUOM]);
							}
						}
					});
				}
			}
		}
		this._GlobalStateService.notifyDataChangedDuplicate('GET_UOM_LIST_FROM_UOM_GROUP_ENTRY', { newUOMList: newUOMList });
	}

	public getUOMListWithoutUOMGroupEntry(erp_primary_key: string) {
		// const lstOfItemGuid: any[] = [];
		// lstOfItemGuid.push(erp_primary_key);
		//this.getItemListWithUOMData(lstOfItemGuid);
		this.getItemsForUOMGroupEntry(erp_primary_key);
	}

	private getItemsForUOMGroupEntry(erp_primary_key?: string) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'items');
		formData.append('view_fields', JSON.stringify(["guid", "erp_primary_key", "itemsku", "itemname", "description", "uomgroupentry"]));
		formData.append('search', JSON.stringify([{ erp_primary_key: erp_primary_key }]));
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
						// call from item edting for UOM list without UOM group entry 02102023 
						if (itemDetailsList && itemDetailsList.length > 0) {
							const itemObj = itemDetailsList[0];
							if (itemObj) {
								const uomgroupentry: string = itemObj['uomgroupentry'] || '';
								if (uomgroupentry) {
									this.getUOMListFromUOMGroupEntry(uomgroupentry);
								}
							}
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private calculateUomPrice(price, uomgroupentry, sourceUom, targetUom) {
		let clculatePrice = price;
		let targeteDiscountAmount, baseDiscoutAmount = 0;

		let objSourceAbsEntry, objTargetAbsEntry;
		let sourceAbsEntry, targetAbsEntry = '';

		if (clculatePrice && clculatePrice > 0) {
			objSourceAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === sourceUom.toString().toLowerCase());
			objTargetAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === targetUom.toString().toLowerCase());
			if (objSourceAbsEntry && objSourceAbsEntry.length > 0) {
				sourceAbsEntry = objSourceAbsEntry[0].absentry;
			}
			if (objTargetAbsEntry && objTargetAbsEntry.length > 0) {
				targetAbsEntry = objTargetAbsEntry[0].absentry;
			}
			const objUomGroup = this.listOfUomGroups.filter(item => item.absentry.toString().toLowerCase() === uomgroupentry.toString().toLowerCase());
			if (objUomGroup && objUomGroup.length > 0) {
				const uomGroup = objUomGroup[0];
				if (uomGroup && uomGroup.uomgroupdefinitioncollection && uomGroup.uomgroupdefinitioncollection.length > 0) {
					if (sourceUom !== uomGroup.baseuom) {
						const sourceUomIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase());
						let sourceUomItem: any;
						if (sourceUomIndex !== -1) {
							sourceUomItem = uomGroup.uomgroupdefinitioncollection[sourceUomIndex];
						}
						// const sourceUomItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase())[0];
						if (sourceUomItem) {
							const itemQty = (sourceUomItem.basequantity / sourceUomItem.alternatequantity) || 0;
							baseDiscoutAmount = clculatePrice / itemQty;
						} else {
							baseDiscoutAmount = clculatePrice;
						}
					} else {
						baseDiscoutAmount = clculatePrice;
					}

					if (targetUom !== uomGroup.baseuom) {
						// const targetItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase())[0];
						const targetItemIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase());
						let targetItem: any;
						if (targetItemIndex !== -1) {
							targetItem = uomGroup.uomgroupdefinitioncollection[targetItemIndex];
						}
						const itemQty = (targetItem.basequantity / targetItem.alternatequantity) || 0;
						targeteDiscountAmount = baseDiscoutAmount * itemQty;
					} else {
						targeteDiscountAmount = baseDiscoutAmount;
					}
					clculatePrice = targeteDiscountAmount;
				}
			}
		}
		return clculatePrice;
	}

	public doOnRowRemovedItemTab(event) {
		const endUserRowData = event.data;
		if (this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid()) {
			const listOfUpdatedData = [{
				"end_user_code": endUserRowData.end_user_code,
				"pricelistno": endUserRowData.pricelistno,
			}];
			this.AddUpdateDeleteEnduserRow(ServerAction.DELETE, listOfUpdatedData);
		}
	}

	public doOnSavedItemTab() {
		// if (this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid()) {
		// 	this.saveChargebackItemTabDataEvent.emit('save');
		// }
	}

	//IX-2292 Keep same Uom for same item

	public updateSameUOMForSameItem(itemDetails, targetUom) {
		if (this.itemTabDataSource && this.itemTabDataSource.length > 0) {
			this.itemTabDataSource.forEach(element => {
				let itemList = element['items'] || [];
				if (itemList.length > 0) {
					// const itemIndex = itemList.findIndex(i => i.item_guid === itemDetails.item_guid);
					const itemIndex = itemList.findIndex(i => i.erp_primary_key === itemDetails.erp_primary_key);
					if (itemIndex !== -1) {
						itemList[itemIndex].selectedUom = targetUom;
					}
					element['items'] = itemList;
				}
			});
			this.itemTabDataList = this.itemTabDataSource;
			this._MinMaxEndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
		}
	}
	// end


	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	//#endregion


	public addProductAgreementTabDetails() {
		if (this.endUsersDataGrid) {
			this.selectedEndUsersDataList = this.endUsersDataGrid.instance.getSelectedRowsData();
		}
		if (this.itemDataGrid) {
			this.selecteditemTabDataList = this.itemDataGrid.instance.getSelectedRowsData();
		}
		if (this.pricelistDataGrid) {
			this.selectedpriceListTabDataList = this.pricelistDataGrid.instance.getSelectedRowsData();
		}
		// if (this.selectedpriceListTabDataList.length <= 0) {
		// 	this._ToastrService.info('Pricelist  is required!', 'Info!', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }
		// if (this.selecteditemTabDataList.length <= 0) {
		// 	this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }
		if (this.selectedEndUsersDataList.length <= 0) {
			this._ToastrService.info('End-user is required!', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (this.selectedEndUsersDataList.length >= 0) {
			const pricelistno = this.selectedEndUsersDataList[0]['pricelistnum'];
			const pricelistname = this.selectedEndUsersDataList[0]['pricelistname'];
			if (!pricelistno) {
				this._ToastrService.info('End-user Pricelist No. is required!', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
			if (!pricelistname) {
				this._ToastrService.info('Pricelist Name is required!', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}

		// if (this.selectedpriceListTabDataList.length > 0) {

		if (this.selectedEndUsersDataList.length > 0) {
			this.selecteditemTabDataList = [];
			let endUserPriceListItems: any[] = [];
			let customerPriceListItems: any[] = [];
			this.unMatchedItems = [];
			this.matchedPriceListItems = [];
			// this.itemPriceListNo = this.selectedpriceListTabDataList[0]['pricelistno'];
			// this.itemPriceListName = this.selectedpriceListTabDataList[0]['pricelistname'];
			this.itemPriceListNo = this.selectedEndUsersDataList[0]['pricelistnum'];
			this.itemPriceListName = this.selectedEndUsersDataList[0]['pricelistname'];
			this.fetchedEndUserListOfPrices(this.itemPriceListNo);
			this._GlobalStateService.unsubscribe('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT');
			this._GlobalStateService.subscribe('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', response => {

				if (response.endUserListOfPrices && response.endUserListOfPrices.length > 0) {
					response.endUserListOfPrices.forEach(element => {
						if (element['items'] && element['items'].length > 0) {
							if (response.endUserListOfPrices.length == 1) {
								endUserPriceListItems = element['items'];
							}
						}
					});
				}
				if (this.customerListOfPrices && this.customerListOfPrices.length > 0) {
					this.customerListOfPrices.forEach(element => {
						if (element['items'] && element['items'].length > 0) {
							if (this.customerListOfPrices.length == 1) {
								customerPriceListItems = element['items'];
							}
						}
					});
				}

				this.getMisMatchedEndUserPricelListItems(endUserPriceListItems, customerPriceListItems);
				const itemGuidList = [];
				this.matchedPriceListItems.forEach(element => {
					itemGuidList.push(element.erp_primary_key);
				});
				this.addItemInItemist();
				this.getItemListWithUOMData(itemGuidList)
			});
		}
	}

	public getMisMatchedEndUserPricelListItems(endUserPriceListItems: any, customerPriceListItems: any) {
		if (endUserPriceListItems.length > 0 && customerPriceListItems.length > 0) {
			endUserPriceListItems.forEach(endUserItem => {
				const indexItem = customerPriceListItems.findIndex(i => i.erp_primary_key === endUserItem.erp_primary_key);
				if (indexItem === -1) {
					const itemObj = {
						'itemname': endUserItem.itemname,
						'description': endUserItem.description,
						'message': 'Missing from customer price list'
					}
					this.unMatchedItems.push(itemObj);
				} else {
					this.matchedPriceListItems.push(endUserItem);
				}
			});
		}

		if (endUserPriceListItems && customerPriceListItems.length > 0) {
			customerPriceListItems.forEach(customerItem => {
				const indexItem = endUserPriceListItems.findIndex(i => i.erp_primary_key === customerItem.erp_primary_key);
				if (indexItem === -1) {
					const itemObj = {
						'itemname': customerItem.itemname,
						'description': customerItem.description,
						'message': 'Missing from end-user price list'
					}
					this.unMatchedItems.push(itemObj);
				} else {
					if (this.matchedPriceListItems && this.matchedPriceListItems.length > 0) {
						const mIndex = this.matchedPriceListItems.findIndex(i => i.erp_primary_key === customerItem.erp_primary_key);
						if (mIndex === -1) {
							this.matchedPriceListItems.push(customerItem);
						}
					}
				}
			});
		}
	}

	private fetchedEndUserListOfPrices(pricelistnum: string) {
		this.endUserListOfPrices = [];
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		const viewFields = ['pricelistno', 'pricelistname', 'items', 'modified_date'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', 'getEntityData');
		formdata.append('is_dropdown', '1');
		formdata.append('entity', 'pricelists');
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this.getPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.endUserListOfPrices = response.data || [];
						this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: this.endUserListOfPrices });
					} else {
						this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: [] });
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: [] });
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_END_USER_PRICE_LIST_UOM_DATA_EVENT', { endUserListOfPrices: [] });
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public addItemInItemist() {
		let priceListTabModel = new MinMaxEndUsersChargebackPriceListTabModel();
		if (!this.itemTabDataList) {
			this.itemTabDataList = []
		}
		this._GlobalStateService.unsubscribe('GET_ITEM_LIST_WITH_UOM_DATA_EVENT');
		this._GlobalStateService.subscribe('GET_ITEM_LIST_WITH_UOM_DATA_EVENT', response => {
			let itemIndex: any = -1;
			priceListTabModel.guid = this.guid.newGuid();
			// priceListTabModel.pricelistno = this.itemPriceListNo;
			// priceListTabModel.pricelistname = this.itemPriceListName;
			priceListTabModel.pricelistno = this.itemPriceListNo;
			priceListTabModel.pricelistname = this.itemPriceListName;
			priceListTabModel.end_user_id = this.selectedEndUsersDataList[0].guid;
			priceListTabModel.end_user_name = this.selectedEndUsersDataList[0].companyname;
			priceListTabModel.end_user_code = this.selectedEndUsersDataList[0].code;
			if (this.itemTabDataList && this.itemTabDataList.length > 0) {
				itemIndex = this.itemTabDataList.findIndex(p => p.pricelistno == priceListTabModel.pricelistno && p.end_user_id == priceListTabModel.end_user_id);
			}
			if (itemIndex != -1) {
				this._ToastrService.info('Duplicate pricelist not allowed!.', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
			response.itemDetailsList.forEach(item => {
				let uomlist = this._BillbackService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, this.itemPriceListNo);
				let defaultListPrice = 0;
				let defaultSelectedUom = item['baseuom'] && item['baseuom'].toString() || '';
				let defaultFinalAmount = item.price || 0;
				if (this.customerListOfPrices && this.customerListOfPrices.length > 0) {
					this.customerListOfPrices.forEach(element => {
						if (element['items'] && element['items'].length > 0) {
							const indexPriceList = element['items'].findIndex(i => i.erp_primary_key === item.erp_primary_key);
							if (indexPriceList !== -1) {
								defaultListPrice += element['items'][indexPriceList]['price'] || 0;
							}
						}
					});
				}
				let itemTabModel = new MinMaxEndUsersPriceListItemModel();
				itemTabModel.guid = this.guid.newGuid();
				itemTabModel.erp_primary_key = item.erp_primary_key || '';
				itemTabModel.itemsku = item.itemsku || '';
				itemTabModel.itemname = item.itemname || '';
				itemTabModel.item_guid = item.guid || '';
				itemTabModel.description = item.description || '';
				itemTabModel.uomgroupentry = item.uomgroupentry || '';
				itemTabModel.uomList = uomlist || [];
				itemTabModel.selectedUom = defaultSelectedUom;
				// itemTabModel.discountedPrice = item.discountedPrice || '';
				itemTabModel.is_disbled_input_field = false;
				itemTabModel.listPrice = +defaultListPrice;
				itemTabModel.chargebackAmount = (defaultListPrice - defaultFinalAmount);
				itemTabModel.final_amount = +defaultFinalAmount;
				itemTabModel.bought = '';
				itemTabModel.sold = '';
				itemTabModel.claimed = '';
				itemTabModel.available = '';
				itemTabModel.last_invoice_date = '';

				if (itemIndex !== -1) {
					if (this.itemTabDataList[itemIndex]['items'] && this.itemTabDataList[itemIndex]['items'].length > 0) {
						const iIndex = this.itemTabDataList[itemIndex]['items'].findIndex(p => p.erp_primary_key === item.erp_primary_key);
						if (iIndex != -1) {
							this.itemTabDataList[itemIndex]['items'][iIndex] = itemTabModel;
						} else {
							this.itemTabDataList[itemIndex]['items'].push(JSON.parse(JSON.stringify(itemTabModel)));
						}
					} else {
						this.itemTabDataList[itemIndex]['items'].push(JSON.parse(JSON.stringify(itemTabModel)));
					}
				} else {
					priceListTabModel.items.push(JSON.parse(JSON.stringify(itemTabModel)));
				}
			});
			if (itemIndex === -1) {
				if (this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid()) {
					const newItemTabDataList: any = [];
					newItemTabDataList.unshift(JSON.parse(JSON.stringify(priceListTabModel)));
					this.AddUpdateDeleteEnduserRow(ServerAction.ADD, newItemTabDataList);
				} else {
					this.itemTabDataList.unshift(JSON.parse(JSON.stringify(priceListTabModel)));
					this.saveChargebackItemTabDataEvent.emit('save');
				}
			}

			// this.itemTabDataSource = this.itemTabDataList;
			this.refreshDatagrid();
			this.getListPriceOfItems();
		});
	}

	private refreshDatagrid() {
		//this.arrSelectedPriceList = [];
		this.priceListGridBoxValue = [];
		// this.pricelistDataGrid.instance.clearFilter();
		// this.pricelistDataGrid.instance.refresh();
		// this.dxDropDownRefPricelistRep.instance.reset();
		this.endUsersGridBoxValue = [];
		this.endUsersDataGrid.instance.clearFilter();
		this.endUsersDataGrid.instance.refresh();
		// if (this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid()) {
		// 	this.saveChargebackItemTabDataEvent.emit('save');
		// }
	}

	private getItemListWithUOMData(itemGuidList: any[]) {
		let priceListNum = '';
		try {
			// priceListNum = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum || '';
			priceListNum = this.itemPriceListNo || '';
		} catch (e) {
			priceListNum = '';
		}
		let itemDetailsList: any[];
		// const view_fields = ['erp_primary_key', 'itemname', 'foreignname', 'uomgroupentry', 'itemprices'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'item_details');
		// formData.append('itemGuid', JSON.stringify(itemGuidList));
		formData.append('erp_primary_key', JSON.stringify(itemGuidList));
		formData.append('product_search_from', 'chargeback');
		formData.append('is_dropdown', 'true');
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		// formData.append('view_fields', JSON.stringify(view_fields));
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_LIST_WITH_UOM_DATA_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_LIST_WITH_UOM_DATA_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public viewTotalQuantity(arrObj: any) {
		let totalQty: any = 0;
		if (arrObj && arrObj.length > 0) {
			arrObj.forEach(element => {
				const qty = element['quantity'] || 0;
				totalQty += qty;
			});
		}
		return totalQty;
	}

	public viewOtherQuantity(arrObj: any) {
		let otherQty: any = 0;
		let totalQty: any = this.viewTotalQuantity(arrObj.total_bought) || 0;
		let contractQty: any = this.viewTotalQuantity(arrObj.bought) || 0;
		if (totalQty > 0) {
			if (totalQty > contractQty) {
				otherQty = (totalQty - contractQty) || 0;
			}
		}
		return otherQty;
	}
	// #region for Bulk Upload items to datagrid

	// Method used to click to Open upload dialog
	public doBulkUploadItmGrpTab() {
		if (!this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum) {
			this._ToastrService.info('Customer Name is required', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		}
		this.uploadItemsProps = {
			actionFor: 'showUploadFile',
			isItemExists: this.itemTabDataList.length > 0,
			listOfCsvRows: []
		};
		this.isShowUploadItemsPopup = false;
		setTimeout(() => {
			this.isShowUploadItemsPopup = true;
		}, 20);
	}

	private doMatchExcelItemsWithOrignItems() {
		this._GlobalStateService.unsubscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT', response => {
			const itemDetailsList = response.itemDetailsList;

			const matchedItemsList: any[] = [];
			this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
				if (csvRow.item) {
					const itemIndex = itemDetailsList.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
					if (itemIndex !== -1) {
						const itemEle = itemDetailsList[itemIndex];
						matchedItemsList.push(itemEle);
					}
				}
			});
			const listOfGuids = [];
			matchedItemsList.map(matchedItem => listOfGuids.push(matchedItem.guid));
			this.doAddBulkItemsToItmGrpTabList();
			if (listOfGuids.length > 0) {
				this.fetchedItemDetailsByPricelistnum(listOfGuids);
			}

		});
	}

	// Method used to Add items from excel to Exclusion items list
	private doAddBulkItemsToItmGrpTabList() {
		let priceListTabModel = new MinMaxEndUsersChargebackPriceListTabModel();
		if (!this.itemTabDataList) {
			this.itemTabDataList = []
		}
		this._GlobalStateService.unsubscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT');
		this._GlobalStateService.subscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', response => {
			const listOfItemDetails = response.itemDetailsList;
			this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
				if (csvRow.item) {
					const itemDetailIndex = listOfItemDetails.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
					if (itemDetailIndex !== - 1) {
						const itemEle = listOfItemDetails[itemDetailIndex];
						csvRow.isItemMatched = true;
						csvRow.description = itemEle.description || '';

						const listOfSpecificUoms = this.setlistOfUomBySelProduct(itemEle);
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}

						if (csvRow.uom) {
							const specificUomIndex = listOfSpecificUoms.findIndex(uomEle => uomEle.code.toString().toLowerCase().trim() === csvRow.uom.toString().toLowerCase().trim());
							if (specificUomIndex !== -1) {
								csvRow.isItemUomMatched = 'matched';
								try {
									defaultSelectedUom = listOfSpecificUoms[specificUomIndex].code;
									defaultListPrice = listOfSpecificUoms[specificUomIndex].price || 0;
								} catch (e) {
								}
							} else {
								csvRow.isItemUomMatched = 'unmatched';
								try {
									csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
								} catch (e) {
								}
							}
						} else {
							csvRow.isItemUomMatched = 'unmatched';
							try {
								csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
							} catch (e) {
							}
						}

						let customChargebackfinalprice = 0;
						let customChargebackAmount = 0;

						if (csvRow.hasOwnProperty('chargeback')) {
							csvRow.chargeback = this.convertToNumber(csvRow.chargeback);
							if (+csvRow.chargeback > +defaultListPrice) {
								csvRow.isItemModifiedPrice = true;
								csvRow.isItemModifiedPriceLbl = 0;
								customChargebackAmount = 0;
								customChargebackfinalprice = +defaultListPrice;
							} else {
								customChargebackAmount = +csvRow.chargeback || 0;
								customChargebackfinalprice = +defaultListPrice - customChargebackAmount;
							}
						}

						if (csvRow.hasOwnProperty('price')) {
							csvRow.price = this.convertToNumber(csvRow.price);
							if (+csvRow.price > +defaultListPrice) {
								csvRow.isItemModifiedPrice = true;
								csvRow.isItemModifiedPriceLbl = +defaultListPrice;
								customChargebackfinalprice = +defaultListPrice;
								customChargebackAmount = 0;
							} else {
								customChargebackfinalprice = +csvRow.price || 0;
								customChargebackAmount = +defaultListPrice - +customChargebackfinalprice;
							}
						}

						// if (csvRow.hasOwnProperty('chargeback')) {
						// 	customChargebackAmount = +csvRow.chargeback || 0;
						// }

						let itemIndex: any = -1;
						priceListTabModel.guid = this.guid.newGuid();
						// bleow filed came from Upload CSV file
						priceListTabModel.pricelistno = '';
						priceListTabModel.pricelistname = '';
						priceListTabModel.end_user_id = '';
						priceListTabModel.end_user_name = '';
						if (this.itemTabDataList && this.itemTabDataList.length > 0) {
							itemIndex = this.itemTabDataList.findIndex(p => p.end_user_id == priceListTabModel.end_user_id && p.pricelistno == priceListTabModel.pricelistno);
						}

						let itemTabModel = new MinMaxEndUsersPriceListItemModel();
						itemTabModel.guid = this.guid.newGuid();
						itemTabModel.erp_primary_key = itemEle.erp_primary_key || '';
						itemTabModel.itemsku = itemEle.itemsku || '';
						itemTabModel.itemname = itemEle.itemname || '';
						itemTabModel.item_guid = itemEle.guid || '';
						itemTabModel.description = itemEle.description || '';
						itemTabModel.uomgroupentry = itemEle.uomgroupentry || '';
						itemTabModel.uomList = listOfSpecificUoms || [];
						itemTabModel.selectedUom = defaultSelectedUom;
						// itemTabModel.discountedPrice = itemEle.discountedPrice || '';
						itemTabModel.is_disbled_input_field = false;
						itemTabModel.listPrice = +defaultListPrice;
						itemTabModel.chargebackAmount = customChargebackAmount;
						itemTabModel.final_amount = customChargebackfinalprice;
						itemTabModel.bought = '';
						itemTabModel.sold = '';
						itemTabModel.claimed = '';
						itemTabModel.available = '';
						itemTabModel.last_invoice_date = '';
						if (itemIndex !== -1) {
							if (this.itemTabDataList[itemIndex]['items'] && this.itemTabDataList[itemIndex]['items'].length > 0) {
								const iIndex = this.itemTabDataList[itemIndex]['items'].findIndex(p => p.erp_primary_key === itemEle.erp_primary_key);
								if (iIndex != -1) {
									this.itemTabDataList[itemIndex]['items'][iIndex] = itemTabModel;
								} else {
									this.itemTabDataList[itemIndex]['items'].push(JSON.parse(JSON.stringify(itemTabModel)));
								}
							} else {
								priceListTabModel.items.push(JSON.parse(JSON.stringify(itemTabModel)));
							}
						} else {
							priceListTabModel.items.push(JSON.parse(JSON.stringify(itemTabModel)));
						}

					}
				}
			});
			this.itemTabDataList.unshift(JSON.parse(JSON.stringify(priceListTabModel)));
			this.isUploadExcelCsvFileItmGrpTab = true;
			this.filterText = this.csvResponse.fileName;
			this.checkImportItemSummary();
			if (this.itemTabDataList.length > 0) {
				// this.itemTabDataSource = this.itemTabDataList;
				this.getListPriceOfItems();
				// on upload item not passed data on save method
				this._MinMaxEndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
				this.saveChargebackItemTabDataEvent.emit('uploaditem');
			}
		});
	}
	// Method used to Add items from excel to Exclusion items list
	private doAddBulkItemsToItmGrpTabListForNewPriceList() {
		this._GlobalStateService.unsubscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT_FOR_NEW_PRICE_LIST');
		this._GlobalStateService.subscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT_FOR_NEW_PRICE_LIST', response => {
			const listOfItemDetails = response.itemDetailsList || [];
			const itemTabDataList = response.itemTabDataList || [];
			this.itemTabDataList = [];
			itemTabDataList.forEach(csvRow => {
				if (csvRow.itemname) {
					const itemDetailIndex = listOfItemDetails.findIndex(itemEle => csvRow.itemname.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
					if (itemDetailIndex !== - 1) {
						const itemEle = listOfItemDetails[itemDetailIndex];
						csvRow.isItemMatched = true;
						csvRow.description = itemEle.description || '';

						const listOfSpecificUoms = this.setlistOfUomBySelProduct(itemEle);
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}

						if (csvRow.selectedUom) {
							const specificUomIndex = listOfSpecificUoms.findIndex(uomEle => uomEle.code.toString().toLowerCase().trim() === csvRow.selectedUom.toString().toLowerCase().trim());
							if (specificUomIndex !== -1) {
								csvRow.isItemUomMatched = 'matched';
								try {
									defaultSelectedUom = listOfSpecificUoms[specificUomIndex].code;
									defaultListPrice = listOfSpecificUoms[specificUomIndex].price || 0;
								} catch (e) {
								}
							} else {
								csvRow.isItemUomMatched = 'unmatched';
								try {
									csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
								} catch (e) {
								}
							}
						} else {
							csvRow.isItemUomMatched = 'unmatched';
							try {
								csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
							} catch (e) {
							}
						}
						let customChargebackfinalprice = 0;
						let customChargebackAmount = 0;
						csvRow.chargebackAmount = this.convertToNumber(csvRow.chargebackAmount);
						if (+csvRow.chargebackAmount > +defaultListPrice) {
							csvRow.isItemModifiedPrice = true;
							csvRow.isItemModifiedPriceLbl = 0;
							customChargebackAmount = 0;
							customChargebackfinalprice = +defaultListPrice;
						} else {
							customChargebackAmount = +csvRow.chargebackAmount || 0;
							customChargebackfinalprice = +defaultListPrice - customChargebackAmount;
						}

						csvRow.final_amount = this.convertToNumber(csvRow.final_amount);
						if (+csvRow.final_amount > +defaultListPrice) {
							csvRow.isItemModifiedPrice = true;
							csvRow.isItemModifiedPriceLbl = +defaultListPrice;
							customChargebackfinalprice = +defaultListPrice;
							customChargebackAmount = 0;
						} else {
							customChargebackfinalprice = +csvRow.final_amount || 0;
							customChargebackAmount = +defaultListPrice - +customChargebackfinalprice;
						}

						let itemTabModel = new MinMaxEndUsersChargebackItemTabModel();
						itemTabModel.guid = this.guid.newGuid();
						itemTabModel.erp_primary_key = itemEle.erp_primary_key || '';
						itemTabModel.itemsku = itemEle.itemsku || '';
						itemTabModel.itemname = itemEle.itemname || '';
						itemTabModel.item_guid = itemEle.guid || '';
						itemTabModel.description = itemEle.description || '';
						itemTabModel.uomgroupentry = itemEle.uomgroupentry || '';
						itemTabModel.uomList = listOfSpecificUoms || [];
						itemTabModel.selectedUom = defaultSelectedUom;
						itemTabModel.is_disbled_input_field = false;
						itemTabModel.listPrice = +defaultListPrice;
						itemTabModel.chargebackAmount = customChargebackAmount;
						itemTabModel.final_amount = customChargebackfinalprice;
						itemTabModel.bought = '';
						itemTabModel.sold = '';
						itemTabModel.claimed = '';
						itemTabModel.available = '';
						itemTabModel.last_invoice_date = '';
						this.itemTabDataList.unshift(JSON.parse(JSON.stringify(itemTabModel)));

					}
				}
			});
			this.isUploadExcelCsvFileItmGrpTab = true;
			if (this.csvResponse && this.csvResponse.fileName) {
				this.filterText = this.csvResponse.fileName;
			}
			this.checkImportItemSummary(itemTabDataList);
			if (this.itemTabDataList.length > 0) {
				// this.itemTabDataSource = this.itemTabDataList;
				this.getListPriceOfItems();
				// on upload item not passed data on save method
				this._MinMaxEndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
				this.saveChargebackItemTabDataEvent.emit('uploaditem');
			}
		});
	}

	private fetchedAllItemsItmGrpTab() {
		let priceListNum = '';
		try {
			// priceListNum = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum || '';
			priceListNum = this.itemPriceListNo || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'items');
		formData.append('is_dropdown', 'true');
		formData.append('view_fields', JSON.stringify(["guid", "erp_primary_key", "itemsku", "itemname", "description", "uomgroupentry"]));

		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Fetch specific items details
	private fetchedItemDetailsByPricelistnum(itemGuidList: any[]) {
		let priceListNum = '';
		try {
			// priceListNum = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum || '';
			priceListNum = this.itemPriceListNo || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'item_details');
		formData.append('is_dropdown', 'true');
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to get number of item who unmatched.
	private checkImportItemSummary(itemTabDataList?: any) {
		if (itemTabDataList && itemTabDataList.length > 0) {
			this.noOfTotalItem = this.itemTabDataList.length;
			this.noOfUnmatchedItem = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched === false).length;
			this.noOfUnmatchedItemUom = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
			this.noOfmatchedItemPrice = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
		} else {
			this.noOfTotalItem = this.listOfCsvRowForItmGrpTab.length;
			this.noOfUnmatchedItem = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched === false).length;
			this.noOfUnmatchedItemUom = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
			this.noOfmatchedItemPrice = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
		}
	}

	public doShowCsvItemsListItmGrpTab() {
		this.uploadItemsProps = {
			actionFor: 'showIgnoredItemList',
			listOfCsvRows: [],
			listOfUnmatchedItems: this.listOfCsvRowForItmGrpTab
		};
		this.isShowUploadItemsPopup = false;
		setTimeout(() => {
			this.isShowUploadItemsPopup = true;
		}, 20);
	}

	// #endregion for Bulk Upload items to datagrid
	// #region for Set UOMs
	private setlistOfUomBySelProduct(itemDetails: any) {
		let priceListNum = '';
		try {
			// priceListNum = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum || '';
			priceListNum = this.itemPriceListNo || '';
		} catch (e) {
			priceListNum = '';
		}
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = this.listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = this.listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = this.listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	// Method used to set Default Uom by uomgroupentry from item
	private setDefaultUomByUomGroupEntry(itemDetails: any) {
		const listOfSpecificUOM = [];
		if (itemDetails.uomgroupentry) {
			// const uomGroupLst = this._DataService.getUomGroups() || [];
			const uomGroupLst = this.listOfUomGroups || [];
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						newItemUomPice.absentry = this.listOfUoms[uomIndex].absentry;
						newItemUomPice.code = this.listOfUoms[uomIndex].code;
						newItemUomPice.name = this.listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}
		}
		return listOfSpecificUOM;
	}
	private convertToNumber(str: string) {
		// return +str.replace(/[A-Za-z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
		return +str;
	}


	chkClaimedQty(e) {
		let claimedQty = 0;
		if (e['row'] && e['row']['data'] && e['row']['data'] && e['row']['data']['items']) {
			if (e['row']['data']['items'].length > 0) {
				const itemsList = e['row']['data']['items'] || [];
				itemsList.forEach(element => {
					if (element['claimed'] && element['claimed'].length > 0) {
						element['claimed'].forEach(qObj => {
							claimedQty += +qObj['quantity'];
							if (claimedQty > 0) {
								return false;
							}
						});
					}
				});
			}
		}
		if (claimedQty === 0) {
			return true;
		}
		//return true;
	}

	// task Ix-1947
	getListPriceOfItems(itemTabDataList?: any[]) {
		let newItemTabDataList = [];
		if (itemTabDataList && itemTabDataList.length > 0) {
			newItemTabDataList = itemTabDataList || [];
		} else {
			newItemTabDataList = JSON.parse(JSON.stringify(this.itemTabDataList || []));
		}
		if (!this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName) {
			if (newItemTabDataList && newItemTabDataList.length > 0) {
				// this.itemTabDataList.forEach(element => {
				for (let i = 0; i < newItemTabDataList.length; i++) {
					if (newItemTabDataList[i]['items'] && newItemTabDataList[i]['items'].length > 0) {
						// newItemTabDataList[i]['items'].forEach(itemElement => {
						for (let j = 0; j < newItemTabDataList[i]['items'].length; j++) {
							let listPrice = 0, chargebackAmount = 0;
							let totalClaimedQty = 0, totalSoldQty = 0;;
							if (newItemTabDataList[i]['items'][j]['claimed'] && newItemTabDataList[i]['items'][j]['claimed'].length > 0) {
								newItemTabDataList[i]['items'][j]['claimed'].forEach(elementClaimed => { // quantity,chargebackAmount
									if (elementClaimed['quantity']) {
										totalClaimedQty += +elementClaimed.quantity;
									}
								});
							}
							if (newItemTabDataList[i]['items'][j]['sold'] && newItemTabDataList[i]['items'][j]['sold'].length > 0) {
								newItemTabDataList[i]['items'][j]['sold'].forEach(elementSold => { // quantity,price
									if (elementSold['quantity']) {
										totalSoldQty += +elementSold.quantity;
										if ((listPrice === 0) && (totalSoldQty > totalClaimedQty) || (totalClaimedQty == 0)) {
											listPrice = elementSold['price'];
											chargebackAmount = listPrice == 0 ? 0 : (listPrice - newItemTabDataList[i]['items'][j]['final_amount']) < 0 ? 0 : (listPrice - newItemTabDataList[i]['items'][j]['final_amount']);
										}
									}
								});
							}
							//if (!newItemTabDataList[i]['items'][j]['listPrice'] || newItemTabDataList[i]['items'][j]['listPrice'] == 0) {
							newItemTabDataList[i]['items'][j]['listPrice'] = listPrice;
							newItemTabDataList[i]['items'][j]['chargebackAmount'] = chargebackAmount;
							//}
							// });
						}
					}
					// });
				}
			}
		}

		this._GlobalStateService.notifyDataChangedDuplicate('CALLED_GET_LIST_PRICE', { isDefault: true, newItemTabDataList: newItemTabDataList });
	}

	doOnInvoiceItemInvoiceCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const docType = element.data['type'] || '';
				if (docType && docType == 'invoice') {
					const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
					viewInvoiceDetail.docNo = element.data[element.column.dataField];
					viewInvoiceDetail.docType = docType;
					this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
				}
				if (docType && docType == 'arcreditmemo') {
					const viewCreditMemoDetail = new ViewCreditMemoDetailPopUpModel();
					viewCreditMemoDetail.docNo = element.data[element.column.dataField];
					viewCreditMemoDetail.docType = docType;
					this.doOpenCreditMemoDetailPopup(viewCreditMemoDetail);
				}
				if (docType && docType == 'enduserinvoice') {
					const viewEndusersInvoiceDetail = new ViewEndusersInvoiceDetailPopUpModel();
					viewEndusersInvoiceDetail.docNo = element.data[element.column.dataField];
					viewEndusersInvoiceDetail.docType = docType;
					this.doOpenEndusersInvoiceDetailPopup(viewEndusersInvoiceDetail);
				}
			}
		}
	}
	// Show End-users Invoice Deatil PopUp
	private doOpenEndusersInvoiceDetailPopup(modelData: ViewEndusersInvoiceDetailPopUpModel) {
		this.viewEndusersInvoiceDetailProps.docNo = modelData.docNo;
		this.viewEndusersInvoiceDetailProps.docType = modelData.docType;
		this.isShowEndusersInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowEndusersInvoiceDetailPopUp = true;
		}, 300);
	}
	// Show Invoice Deatil PopUp
	private doOpenInvoiceDetailPopup(modelData: ViewInvoiceDetailPopUpModel) {
		this.viewInvoiceDetailProps.docNo = modelData.docNo;
		this.viewInvoiceDetailProps.docType = modelData.docType;
		this.isShowInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowInvoiceDetailPopUp = true;
		}, 300);
	}

	// Show CreditMemo PopUp
	private doOpenCreditMemoDetailPopup(modelData: ViewCreditMemoDetailPopUpModel) {
		this.viewCreditMemoDetailProps.docNo = modelData.docNo;
		this.viewCreditMemoDetailProps.docType = modelData.docType;
		this.isShowCreditMemoDetailPopUp = false;
		setTimeout(() => {
			this.isShowCreditMemoDetailPopUp = true;
		}, 300);
	}

	public doFilterDateOnValueChanged(e) {
		// const previousValue = e.previousValue;
		// const newValue = e.value;
		if (e && e.value) {
			this.minMaxEndUsersChargebackContractModelUI.month_filter = e.value;
			if (this.minMaxEndUsersChargebackContractModelUI.guid) {
				// this.itemDataGridContainer.instance.collapseAdaptiveDetailRow();
				this.itemDataGridContainer.instance.collapseAll();
				this.saveChargebackItemTabDataEvent.emit('monthFilter');
			}
		}
	}

	private doGetCustomFeatursFields(itemDetails: any) {
		this.listOfSpecificCustomFeatureFields = [];
		this.listOfFeaturesCustomFields.forEach(custField => {
			if (itemDetails && itemDetails.hasOwnProperty(custField.ix_custom_field) && itemDetails[custField.ix_custom_field]) {
				const newCustFields: any = {
					guid: custField.guid,
					custom_value: null,
					custom_display_value: null,
					custom_label: null
				}
				newCustFields.custom_label = custField.ix_custom_field_title;
				if (custField.custom_field_title) {
					newCustFields.custom_label = custField.custom_field_title;
				}
				newCustFields.custom_value = itemDetails[custField.ix_custom_field];
				if (custField.custom_field_output_format === 'percentage') {
					newCustFields.custom_display_value = this._DecimalPipe.transform(itemDetails[custField.ix_custom_field] || 0, this.decimalPercentageFormat) + '%';
				}
				if (custField.custom_field_output_format === 'amount') {
					newCustFields.custom_display_value = this._CustomCurrencyPipe.transform(itemDetails[custField.ix_custom_field] || 0);
				}
				if (custField.custom_field_output_format === 'boolean') {
					newCustFields.custom_display_value = 'No';
					if (itemDetails[custField.ix_custom_field]) {
						newCustFields.custom_display_value = 'Yes';
					}
				}
				if (custField.custom_field_output_format === 'string') {
					newCustFields.custom_display_value = '';
					if (itemDetails[custField.ix_custom_field]) {
						newCustFields.custom_display_value = itemDetails[custField.ix_custom_field];
					}
				}
				this.listOfSpecificCustomFeatureFields.push(newCustFields);
			}
		});
	}

	private getItemDetailsRecords(itemDetails: any) {
		const itemGUID = itemDetails.item_guid || undefined;
		if (!itemGUID) {
			return;
		}
		const listOfCustomViewFieds: any = [];
		const listOfMasterFeaturesCustomFields = this._AppCommonSrvc.getFeatureCustomFields();
		this.listOfFeaturesCustomFields = listOfMasterFeaturesCustomFields.filter(field => field.display === true && field.external_entity_field === true && field.entity_type === 'items' && field.section === "HEADER");
		this.listOfFeaturesCustomFields.forEach(field => {
			if (field.display) {
				listOfCustomViewFieds.push(field.custom_field_id);
			}
		});
		let viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description', 'itembarcodecollection', 'quantityonstock', 'price', 'barcode', 'itemwarehouseinfocollection', 'uomgroupentry', 'itemimages', 'itemcost', 'taxexempt', 'taxcode'];
		viewFields = viewFields.concat(listOfCustomViewFieds);
		const searchFilter = [];
		searchFilter.push({ 'guid': itemGUID });
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEMS);
		formData.append('search', JSON.stringify(searchFilter));
		formData.append('view_fields', JSON.stringify(viewFields));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}

		//this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (results) => {
				if (results) {
					if (results.flag) {
						if (results.data && results.data.length > 0) {
							const dataItem = results.data[0] || [];
							this.doGetCustomFeatursFields(dataItem);
						}
					}
				}
				//this._LoaderService.hide();
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			},
			complete: () => {
			}
		});
	}

	doOnRowExpandingItemTab(event) {
		this.editChargebackGuid = this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid();
		if (event && event.hasOwnProperty('component') && this.editChargebackGuid) {
			const listOfRows = event.component.option('dataSource');
			const rowIndex = listOfRows.findIndex(row => row.guid === event.key);
			let itemDetails: any;
			setTimeout(() => {
				if (event.expanded && this.listOfExpandedRows.findIndex(rowKey => rowKey === event.key) === -1) {
					this.listOfExpandedRows.push(event.key);
				}
			}, 50);
			if (rowIndex !== -1) {
				itemDetails = listOfRows[rowIndex];
				listOfRows[rowIndex]['items'] = [];
				this.getEndUserItemsModeLst(itemDetails).subscribe({
					next: (response) => {
						if (response.flag) {
							// this.endUserItemsModeLst = response.data || [];
							listOfRows[rowIndex]['items'] = response.data || [];
						} else {
							this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
						}
						this._LoaderService.hide();
					}, error: (error) => {
						this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
						this._LoaderService.hide();
					}
				})
				// this.itemDataGridContainer.instance.option('dataSource', listOfRows);
			}
		}
	}

	getEndUserItemsModeLst(itemDetails) {
		if (itemDetails) {
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.GET_ENTITY_DATA);
			formData.append('mode', ServerMode.ITEMS);
			formData.append('entity', ServerEntity.MINMAXENDUSERSCHARGEBACKCONTRACTS_DETAILS);
			formData.append('contractguid', this.editChargebackGuid);
			formData.append('end_user_code', itemDetails.end_user_code);
			formData.append('pricelistno', itemDetails.pricelistno);
			formData.append('is_dropdown', 'true');
			this._LoaderService.show();
			return this._RestApiService.doDataOutReqFormData((formData));
		}
	}

	getEndUserItemsQtyModeLst(enduserDetails, itemDetails, mode) {
		if (itemDetails) {
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.GET_ENTITY_DATA);
			formData.append('mode', mode);
			formData.append('entity', ServerEntity.MINMAXENDUSERSCHARGEBACKCONTRACTS_DETAILS);
			formData.append('contractguid', this.editChargebackGuid);
			formData.append('end_user_code', enduserDetails.end_user_code);
			formData.append('pricelistno', enduserDetails.pricelistno);
			formData.append('erp_primary_key', itemDetails.erp_primary_key);
			formData.append('is_dropdown', 'true');
			this._LoaderService.show();
			return this._RestApiService.doDataOutReqFormData((formData));
		}
	}

	AddUpdateDeleteEnduserRow(action, newItemTabDataList) {
		this.editChargebackGuid = this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.IX_UPDATE_ENTITY_DATA);
		formData.append('entity', ServerEntity.MINMAXENDUSERSCHARGEBACKCONTRACTS);
		formData.append('guid', this.editChargebackGuid);
		formData.append('mode', ServerMode.ENDUSERS);
		formData.append('action', action);
		formData.append('updatedData', JSON.stringify(newItemTabDataList));
		this._LoaderService.show();
		this._RestApiService.doDataInFormDataReq((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this.editChargebackItemTabDataEvent.emit('edit');
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	// AddUpdateDeleteEnduserItemRow(action, enduserDetails, itemDetails) {
	// 	const formData = new FormData();
	// 	formData.append('usr', this._LoginService.loginUser.user);
	// 	formData.append('token', this._LoginService.loginUser.token);
	// 	formData.append('method', ServerMethods.IX_UPDATE_ENTITY_DATA);
	// 	formData.append('entity', ServerEntity.MINMAXENDUSERSCHARGEBACKCONTRACTS);
	// 	formData.append('contractguid', this.editChargebackGuid);
	// 	formData.append('mode', ServerMode.ITEMS);
	// 	formData.append('end_user_code', enduserDetails.end_user_code);
	// 	formData.append('pricelistno', enduserDetails.pricelistno);
	// 	formData.append('erp_primary_key', itemDetails.erp_primary_key);
	// 	formData.append('action', action);
	// 	this._LoaderService.show();
	// 	this._RestApiService.doDataInFormDataReq((formData)).subscribe({
	// 		next: (response) => {
	// 			if (response.flag) {
	// 			} else {
	// 				this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
	// 			}
	// 			this._LoaderService.hide();
	// 		}, error: (error) => {
	// 			this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
	// 			this._LoaderService.hide();
	// 		}
	// 	});
	// }

	private doCollapseDatagrid() {
		if (this.listOfExpandedRows && this.listOfExpandedRows.length > 0) {
			this.listOfExpandedRows.forEach(rowKey => {
				if (this.itemDataGridContainer.instance.isRowExpanded(rowKey)) {
					this.itemDataGridContainer.instance.collapseRow(rowKey);
				}
			});
			this.itemDataGridContainer.instance.refresh();
		}

	}

	public setMonthFilterVal() {
		let monthFilterVal: any = null;
		//if (!this.minMaxEndUsersChargebackContractModelUI?.month_filter) {
		if (this.minMaxEndUsersChargebackContractModelUI.startDate) {
			if ((this.minMaxEndUsersChargebackContractModelUI.startDate < this.nowDate)) {
				monthFilterVal = this.minMaxEndUsersChargebackContractModelUI.startDate;
			} else {
				monthFilterVal = this.nowDate;
			}
		} else {
			monthFilterVal = this.nowDate;
		}
		//}
		return monthFilterVal;
	}

	public doCloseUploadItemsPopup(result) {
		this.isShowUploadItemsPopup = false;
		if (result) {
			this.csvResponse = result;
			if (result.listOfCsvRows && result.listOfCsvRows.length > 0) {
				this.itemTabDataList = [];
				// this.itemTabDataSource = this.itemTabDataList;
				this.getListPriceOfItems();
				const listOfCsvRow = result.listOfCsvRows;
				listOfCsvRow.forEach(rowEle => {
					rowEle.guid = this.guid.newGuid();
					rowEle.description = '';
					rowEle.isItemMatched = false;
					rowEle.isItemUomMatched = 'unmatched';
					rowEle.isItemModifiedUom = '';
					rowEle.isItemModifiedPrice = false;
					rowEle.isItemModifiedPriceLbl = null;
				});
				this.listOfCsvRowForItmGrpTab = listOfCsvRow;
				this.doMatchExcelItemsWithOrignItems();
				this.fetchedAllItemsItmGrpTab();
			}
		}
	}
}
