<!-- <div class="popCnt">
	<div class="popBox cf">
		<div class="popHeader">
			<span (click)="dismiss()" class="closePopup icon-close-popup"></span>
			<h2 class="popTitle">{{popupTitle}}</h2>
		</div>
		<div class="popContent">
			<div class="">
				<p>{{popupMessage}}</p>
			</div>
			<div class="stickyBottom">
				<div class="right">
					<button (click)="doCancelled()" name="cancelbtn" type="button" class="button grayButton"
						tabindex="-1">{{btnCancelText}}</button>
					<button (click)="doOk()" name="submitbtn" type="button" class="button mrgL10"
						tabindex="1">{{btnOkText}}</button>
				</div>
			</div>
		</div>
	</div>
</div> -->

<div class="popCnt popConfirmBox">
	<div class="infoBox" [ngClass]="popupInfoBoxClass ? popupInfoBoxClass : 'deleteTopIcon'">
		<div class="popBox cf">
			<div class="" [ngClass]="popupIconClass ? popupIconClass : 'infoIcon'"></div>
			<span (click)="dismiss()" class="closePopup icon-close-popup"></span>
			<h2 class="infoTitle">{{popupTitle}}</h2>
			<p>{{popupMessage}}</p>
			<div class="fullBtn">
				<input name="" type="button" class="button" (click)="doOk()" value="{{btnOkText}}">
			</div>
			<!--<div class="cancelLink"><a class="cancel-link" (click)="doCancelled()">{{btnCancelText}}</a></div>-->
		</div>
	</div>
</div>