<dx-data-grid #orderDataGridRef id="gridContainer" [dataSource]="ordersDataSource" keyExpr="guid"
	[wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true"
	[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
	[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
	(onCellClick)="doOnOrderCellClick($event)" (onToolbarPreparing)="doOrderToolbarPreparing($event)"
	(onInitialized)="initializedOrderDataGrid($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" columnRenderingMode="virtual" [showScrollbar]="'always'"
		[useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-group-panel [visible]="true"></dxo-group-panel>
	<dxo-header-filter [visible]="false"></dxo-header-filter>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadOrderState" [customSave]="doSaveOrderState"
		[storageKey]="stateStorageKey">
	</dxo-state-storing>
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>
	<dxi-column dataField="clientName" dataType="string" caption="Customer Name" [width]="'auto'"></dxi-column>
	<dxi-column dataField="invoiceCode" dataType="string" caption="Order #" [width]="'auto'"
		cellTemplate="cellTemplateDocNo">
		<div *dxTemplate="let element of 'cellTemplateDocNo'">
			<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
		</div>
	</dxi-column>

	<dxi-column dataField="invoiceDate" dataType="string" [allowSorting]="true" caption="Doc. Date"
    	 [allowHeaderFiltering]="false" [width]="'auto'" cellTemplate="cellTemplateDocDate">
      <div *dxTemplate="let element of 'cellTemplateDocDate'">
         <span class="highlight pointerMark">{{element.data.invoiceDate}}</span>
      </div>
   </dxi-column>
   
	<dxi-column dataField="linetotal" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Gross Revenue" [width]="'auto'" [format]="currencyFormat">
	</dxi-column>

	<dxi-column dataField="grossprofit" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Profit" [width]="'auto'" [format]="currencyFormat">
	</dxi-column>

	<dxi-column dataField="overListRuleRate" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		[customizeText]="doCustomizeTextToPercent" caption="Over List Rate %" [width]="'auto'">
	</dxi-column>
	<dxi-column dataField="overListCommissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Over List Bonus" [width]="'auto'" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="appliedTier" dataType="string" caption="Tier" [width]="'auto'"></dxi-column>
	<dxi-column dataField="commissionRate" dataType="number" [customizeText]="doCustomizeTextToPercent"
		[allowFiltering]="false" [allowSorting]="false" caption="Commission %" [width]="'auto'">
	</dxi-column>

	<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="false"
		caption="Commission" [width]="'auto'" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="salespersonname" dataType="string" [allowHeaderFiltering]="false" caption="Sales Person"
		cellTemplate="cellTemplateSalesPersonName" [width]="'auto'">
		<div *dxTemplate="let element of 'cellTemplateSalesPersonName'">
			{{element.data.salespersonname|| '' }}
		</div>
	</dxi-column>
	<dxo-summary>
		<dxi-total-item column="clientName" displayFormat="Total">
		</dxi-total-item>
		<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="grossprofit" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="over_pricelist_amount" summaryType="sum" displayFormat="{0}"
			[valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="base_profit_amount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="overListCommissionAmount" summaryType="sum" displayFormat="{0}"
			[valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="custom_doctotal" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
	</dxo-summary>

</dx-data-grid>