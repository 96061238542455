import { Component, OnInit, Inject, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { PageOptions } from '@app/models/common.model';
import { LoginService } from '@app/services/login.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { PopupHeightWidth } from '@app/constants-enums/enums';

@Component({
	selector: 'app-upload-rebate-contract-dialog',
	templateUrl: './upload-rebate-contract-dialog.component.html',
	styleUrls: ['./upload-rebate-contract-dialog.component.css']
})

export class UploadRebateContractDialogComponent implements OnInit, OnChanges, OnDestroy {
	@Input() uploadRebateContractProps: any;
	@Output() closeUploadRebateContractPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup = false;
	public popupTitleText: string;

	public uploadFiles: NgxFileDropEntry[] = [];
	private loginUser: any = undefined;
	public uploadedCsvFilesData: any;
	public dxDataSource: any;
	public pageOptions: PageOptions = new PageOptions();
	public isShowOriginalPriceColumn = false;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	private erp_type: string;
	public customPriceCaption: string = 'Price';
	private dataCSVInReqSubscription: Subscription;
	public rebate_contract_file_link: any;

	// #region for Angular Life cycle
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService) {
	}

	ngOnInit() {
		this.popupTitleText = 'Upload Rebate';
		this.isShowPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;

		this.loginUser = this._LoginService.loginUser;
		this.rebate_contract_file_link = this._LoginService.loginUser.account_detail.rebate_contract_file_link || '';
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['uploadRebateContractProps']) {
			const uploadRebateContractProps = _SimpleChanges['uploadRebateContractProps'].currentValue;
		}
	}

	ngOnDestroy() {
		if (this.dataCSVInReqSubscription) {
			this.dataCSVInReqSubscription.unsubscribe();
		}
	}
	// #endregion

	// Method used to upload functionality for Csv,xlsx files
	public doOnFileDrop(event) {
		this.uploadFiles = event;
		for (const droppedFile of event) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					const fileExtension = file.name.split('.')[file.name.split('.').length - 1];
					if (fileExtension !== 'csv') {
						this._ToastrService.info('Please upload CSV file', 'Info', { closeButton: true, tapToDismiss: true });
						return false;
					}
					this.uploadedCsvFilesData = file;
					// if (fileExtension === 'csv') {
					// 	// Allow 25MB file
					// 	if (file.size > 25000000) {
					// 		this._ToastrService.info('Please upload less than 25 MB size CSV file', 'Info', { closeButton: true, tapToDismiss: true });
					// 		return false;
					// 	}
					// 	this.uploadedCsvFilesData = file;
					// } else {
					// 	this._ToastrService.info('Please upload CSV file', 'Info', { closeButton: true, tapToDismiss: true });
					// }
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
			}
		}
	}

	// Method used to Remove file from list of files
	public doRemoveUploadFiles() {
		this.uploadedCsvFilesData = undefined;
	}

	// Method used to get Json from file and send it to datagrid
	public doUploadCsvFile() {
		if (!this.uploadedCsvFilesData || (this.uploadedCsvFilesData.name && !this.uploadedCsvFilesData.name)) {
			this._ToastrService.info('Choose Csv File', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		const formData = new FormData();
		formData.append('usr', this.loginUser.user);
		formData.append('token', this.loginUser.token);
		formData.append('rebatescontracts', this.uploadedCsvFilesData);
		this._LoaderService.show();
		if (this.dataCSVInReqSubscription) {
			this.dataCSVInReqSubscription.unsubscribe();
		}
		// const formData = 'usr=' + this.loginUser.user + '&token=' + this.loginUser.token + '&date='+this.csvFileDetails.date+'&'+this.csvFileDetails.fileType+'='+this.csvFileData+'&mode='+this.csvFileDetails.appendOrReplaceStatus;
		this.dataCSVInReqSubscription = this._RestApiService.doCSVDataInReq((formData)).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					this._ToastrService.success(response.message || 'File uploaded successfully.', 'Success', { closeButton: true, tapToDismiss: true });
					const popupCloseResponse = { isSuccess: true, response: response };
					this.closeUploadRebateContractPopup.emit(popupCloseResponse);
					this.isShowPopup = false;
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Read Csv or Xlss file
	async getReadCsvFile(): Promise<any> {
		return new Promise<string>((resolve, reject) => {
			let csvRowsToJson: any = [];
			if (this.uploadedCsvFilesData && this.uploadedCsvFilesData.name) {
				const file = this.uploadedCsvFilesData;
				const fileReader = new FileReader();
				fileReader.onload = (e) => {
					const arrayBuffer: any = fileReader.result;
					const data = new Uint8Array(arrayBuffer);
					const arr = [];
					for (let i = 0; i !== data.length; ++i) {
						arr[i] = String.fromCharCode(data[i]);
					}
					const bstr = arr.join("");
					const workbook = XLSX.read(bstr, { type: "binary" });
					const firstSheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[firstSheetName];
					csvRowsToJson = XLSX.utils.sheet_to_json(worksheet, {
						header: ['ContractName', 'CustomerCode', 'ContractEmail', 'SettlementPeriod', 'StartDate', 'EndDate', 'ItemCode',
							'RebateType', 'RebateBasis', 'TierMin', 'TierMax', 'Rate', 'ContractCalendar', 'StartSettlementPeriodOn'], raw: true
					});
					csvRowsToJson.splice(0, 1);
					resolve(csvRowsToJson);
				};
				fileReader.readAsArrayBuffer(file);
			} else {
				reject(csvRowsToJson);
			}
		});
	}

	public doDownloadCsvFile() {
		const link = document.createElement('a');
		link.href = this.rebate_contract_file_link;
		link.download = 'Sample_Rebate';
		link.target = '_blank';
		link.dispatchEvent(new MouseEvent('click'));
	}
}
