<div class="rightBar">
	<div class="rightBlock">
		<div class="borderBox">
			<div class="titleRow">
				<div class="row">
					<div class="cols9">
						<h2 *ngIf="invoiceDetails?.ixcode" class="textlabelix mrgB5 d-block"> Settlement No.: <span
								class="sectionTitle">
								{{invoiceDetails?.ixcode}}
							</span></h2>
						<p *ngIf="invoiceDetails?.contractName" class="textlabelix alignLeft mrgB0 mrgR20">Commission Report:
							<span class="black">
								{{invoiceDetails?.contractName}} </span>
						</p>
						<p *ngIf="invoiceDetails?.subusername" class="textlabelix alignLeft mrgB0 mrgR20">Sales Rep: <span
								class="black">
								{{invoiceDetails?.subusername}} </span></p>
						<!-- <div class="remarkBx mrgL0 mrgT10"> -->
						<div class="remarkBx mrgL0 mrgT10" *ngIf="invoiceDetails?.errorMessage">
							<strong>Remarks: </strong>
							<span [innerHtml]="invoiceDetails?.errorMessage"></span>
							<!-- <span [innerHtml]="'A/P Invoice 448352 generated successfully'"></span> -->
						</div>
					</div>
					<div class="cols3">
						<h2 class="alignRight mrgB5">
							<p class="textlabelix alignLeft mrgB0">Status</p>
							<span class="inStatusLabel mrgL10"
								[ngClass]="{'orangeInvoice':invoiceDetails.apInvoiceCreatedInERP === 'Processing','purpleInvoice':invoiceDetails.apInvoiceCreatedInERP === 'Open','redInvoice':invoiceDetails.apInvoiceCreatedInERP === 'Error','greenInvoice':invoiceDetails.apInvoiceCreatedInERP === 'Success'}">{{invoiceDetails?.apInvoiceCreatedInERP}}</span>
						</h2>
						<p *ngIf="invoiceDetails?.created_date" class="textlabelix alignRight mrgB10 width100p txtRight">
							Generated: <span class="black">
								{{invoiceDetails?.created_date | date: 'yyyy-MM-dd'}}
							</span></p>
						<!-- | {{invoiceDetails?.created_date | date:'shortTime'}} </span></p> -->
					</div>
				</div>
			</div>
		</div>
		<div class="borderBox">
			<div id="exportContainer" class="right">
				<!-- <dx-drop-down-button [splitButton]="false" [useSelectMode]="false" text="Export Pdf" stylingMode="outlined"
					class="secondaryBtn actionTextWithIconBtnIx mrgR20" [items]="listOfExportButtons" [wrapItemText]="true"
					displayExpr="btnName" keyExpr="btnCode" (onItemClick)="doExportItemsActions($event)"
					[dropDownOptions]="{ width: 230 }" [visible]="isShowExportPdf">
				</dx-drop-down-button> -->

				<!-- <dx-button class="secondaryBtn mrgR10" text="Close Current Settlement Period"
					[visible]="invoiceDetails.apInvoiceCreatedInERP === 'Open'" (onClick)="closeCurrentSettlementPeriod()"
					[useSubmitBehavior]="false">
				</dx-button>
				<dx-button class="secondaryBtn mrgR10" text="Export Pdf" icon="xlsxfile" (onClick)="doExportJsPdf($event)"
					[visible]="isShowExportPdf">
				</dx-button>
				<dx-button class="secondaryBtn" text="Export Data" icon="xlsxfile" (onClick)="onExporting($event)">
				</dx-button> -->
				<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
					[showArrowIcon]="false" text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx"
					[items]="listOfActions" [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
					keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
					(onItemClick)="doActionsBtnItemClick($event)" [dropDownOptions]="{ width: 270 }">
				</dx-drop-down-button>
			</div>

			<dx-data-grid id="gridContainer" #mainDatagrid [dataSource]="mainDataSource" [remoteOperations]="true"
				[showBorders]="true" [allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'" class="mrgB20">
				<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]=false> </dxo-scrolling>
				<dxi-column dataField="name" dataType="string" caption="Settlement Period : {{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}}
				To
				{{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}" [allowSorting]="false"></dxi-column>
				<dxo-summary>
					<dxi-total-item column="name" alignment="left"
						displayFormat="Total Commission Payable : {{invoiceDetails.settlementValue || 0 | customCurrencyPipe:customCurrencyOption }}  | 
					Total Commission Generated : {{invoiceDetails.totalCommissionGenerated || 0 | customCurrencyPipe:customCurrencyOption }}">
					</dxi-total-item>
					<dxi-total-item column="creditmemototal" summaryType="sum" displayFormat="{0}"
						[customizeText]="doCustomizeTotalSummary">
					</dxi-total-item>
				</dxo-summary>

				<dxo-master-detail [enabled]="true" template="masterDetail" [autoExpandAll]="true"></dxo-master-detail>
				<div *dxTemplate="let masterDetailItem of 'masterDetail'">
					<div *ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.INVOICES)">
						<dx-data-grid #customersInvoiceDataGrid id="customersInvoiceDataGrid"
							[dataSource]="invoicesDataSource" keyExpr="clientCode" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [allowColumnResizing]="true"
							[allowColumnReordering]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							(onCellClick)="doCellClick($event)" [columnAutoWidth]="true" [width]="'100%'"
							[visible]="!enable_profit_on_document_header">
							<dxo-master-detail [enabled]="true" template="itemDetailsTemplate"
								[autoExpandAll]="false"></dxo-master-detail>
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadInvoiceState"
								[customSave]="doSaveInvoiceState" storageKey="invoices-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxi-column dataField="clientName" caption="Customer Name" dataType="string"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="customer_age" caption="Customer Age" [visible]="true" dataType="string"
								[width]="'auto'">
							</dxi-column>
							<dxi-column dataField="linetotal" [allowFiltering]="false" [allowSorting]="false"
								caption="Gross Revenue" dataType="number" [width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="cashdiscount" [allowFiltering]="false" [allowSorting]="false"
								caption="Cash Discount" dataType="number" [width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="creditmemototal" [allowFiltering]="false" [allowSorting]="false"
								caption="Credit Memos (Item Type)" dataType="number" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="serviceArCreditMemoValue" [allowFiltering]="false" [allowSorting]="false"
								caption="Credit Memos (Service Type)" dataType="number" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="miscDedAmount" [allowFiltering]="false" [allowSorting]="false"
								caption="Misc.Deduction" dataType="number" [width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="miscDedFcAmount" [allowFiltering]="false" [allowSorting]="false"
								caption="Misc.Deduction Fixed Cost" dataType="number" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="finalAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Net Revenue" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="rebate_amount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Estimated Rebate Commission" [width]="'auto'">
								<div *dxTemplate="let element of 'cellTemplateExtemeatedCommission'">
									<span [ngClass]="{'highlight pointerMark': (element.data.rebate_amount) > 0}">
										{{element.data.rebate_amount || 0 | customCurrencyPipe:customCurrencyOption }}</span>
								</div>
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Commission" [width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="cashdiscount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="creditmemototal" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="serviceArCreditMemoValue" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="miscDedAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="miscDedFcAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="finalAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="rebate_amount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
							</dxo-summary>
							<div *dxTemplate="let itemsDetails of 'itemDetailsTemplate'">
								<dx-data-grid #invoicesItemDataGrid id="invoicesItemDataGrid"
									[dataSource]="itemsDetails.data.items" keyExpr="erp_primary_key" [showBorders]="true"
									[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
									[filterSyncEnabled]="false" [remoteOperations]="false" [columnAutoWidth]="true"
									(onCellClick)="doOnInvoiceItemCellClick($event)"
									(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [width]="'100%'">
									<dxo-master-detail [enabled]="true" template="invoiceDetailsTemplate"
										[autoExpandAll]="false"></dxo-master-detail>
									<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
									<dxo-pager [visible]="true" [showPageSizeSelector]="true"
										[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
									</dxo-pager>
									<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
									<dxo-state-storing [enabled]="true" type="custom"
										[customLoad]="doLoadInvoiceCustomersItemsState"
										[customSave]="doSaveInvoiceCustomersItemsState"
										storageKey="invoices-items-datagrid-state">
									</dxo-state-storing>
									<dxo-filter-row [visible]="true"></dxo-filter-row>
									<dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku" dataType="string" caption="SKU"
										[width]="'auto'"></dxi-column>
									<dxi-column dataField="classname" dataType="string"
										[visible]="enable_classification_for_commision"
										[showInColumnChooser]="enable_classification_for_commision" caption="Class"
										[width]="'auto'"></dxi-column>
									<dxi-column dataField="itemname" dataType="string"
										[visible]="!enable_classification_for_commision"
										[showInColumnChooser]="!enable_classification_for_commision" caption="Item"
										[width]="'auto'">
									</dxi-column>
									<dxi-column dataField="description" dataType="string"
										[visible]="!enable_classification_for_commision"
										[showInColumnChooser]="!enable_classification_for_commision" caption="Description"
										[width]="'auto'">
									</dxi-column>
									<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false"
										[allowSorting]="false" name="itemGridCommissionAmount" caption="Commission"
										[width]="'auto'" [customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxo-summary>
										<dxi-total-item column="commissionAmount" name="itemCommissionSummaryTotal"
											summaryType="sum" displayFormat="{0}" [customizeText]="doCustomizeTotal">
										</dxi-total-item>
									</dxo-summary>
									<div *dxTemplate="let invoice of 'invoiceDetailsTemplate';index as i">
										<dx-data-grid #invoicesDataGrid id="invoicesDataGrid" [dataSource]="invoice.data.invoices"
											keyExpr="invoiceCode" [showBorders]="true" [showColumnLines]="true"
											[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="false"
											[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true"
											(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)"
											[customizeColumns]="invoiceDataGridCustomizeColumns"
											(onCellClick)="doOnInvoiceItemInvoiceCellClick($event)"
											(onInitialized)="initializedInvoiceDataGrid($event)">
											<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
												[useNative]="false"></dxo-scrolling>
											<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
											<dxo-pager [visible]="true" [showPageSizeSelector]="true"
												[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
											</dxo-pager>
											<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
											<dxo-state-storing [enabled]="true" type="custom"
												[customLoad]="doLoadInvoiceCustomerItemsInvoiceState"
												[customSave]="doSaveInvoiceCustomerItemsInvoiceState"
												storageKey="invoices-items-invoices-datagrid-state">
											</dxo-state-storing>
											<dxo-filter-row [visible]="true"></dxo-filter-row>
											<dxi-column dataField="document_type" dataType="string" [allowSorting]="true"
												caption="Doc. Type" [allowFiltering]="false" [allowHeaderFiltering]="true"
												cellTemplate="cellTemplateDocType" [width]="'auto'">
												<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateDocType'">
													{{element.data.document_type | filterFromList:listOfDocTypeHeaderFilter : 'value'
													: 'text' }}
												</div>
											</dxi-column>

											<dxi-column dataField="itemname" dataType="string" [allowSorting]="true"
												[visible]="enable_classification_for_commision"
												[showInColumnChooser]="enable_classification_for_commision" caption="Items"
												[allowHeaderFiltering]="false" [width]="'auto'">
											</dxi-column>

											<dxi-column dataField="invoiceCode" dataType="string" [allowSorting]="true"
												caption="Doc. No" [allowHeaderFiltering]="false" [width]="'auto'"
												cellTemplate="cellTemplateDocNo">
												<div *dxTemplate="let element of 'cellTemplateDocNo'">
													<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
												</div>
											</dxi-column>
											<dxi-column dataField="branch" dataType="string" [allowSorting]="true"
												[visible]="erp_type === ErpTypeValEnum.EPICOR_PROPHET21"
												[showInColumnChooser]="erp_type === ErpTypeValEnum.EPICOR_PROPHET21"
												caption="Branch" [allowHeaderFiltering]="false" [width]="'auto'">
											</dxi-column>
											<dxi-column dataField="uomcode" dataType="string" caption="Doc. UOM"
												cellTemplate="cellTemplateUom" [allowFiltering]="false"
												[allowHeaderFiltering]="true" [allowSorting]="true" [width]="'auto'">
												<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateUom'">
													{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' |
													uppercase }}
												</div>
											</dxi-column>

											<dxi-column dataField="quantity" caption="Doc. Qty" dataType="number"
												cellTemplate="cellTemplateQuantity" [allowHeaderFiltering]="false" [width]="'auto'">
												<div *dxTemplate="let element of 'cellTemplateQuantity'">
													{{element.data.quantity || '' }}
												</div>
											</dxi-column>

											<dxi-column dataField="contract_selectedUom" dataType="string" caption="Contract UOM"
												[visible]="invoice.data.commission_basis === 'dollarperuom'"
												[showInColumnChooser]="invoice.data.commission_basis === 'dollarperuom'"
												cellTemplate="cellTemplateContractUom" [allowFiltering]="false"
												[allowHeaderFiltering]="true" [allowSorting]="true" [width]="'auto'">
												<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateContractUom'">
													{{element.data.contract_selectedUom || '' | filterFromList:listOfUoms :'code' :
													'name' |
													uppercase }}
												</div>
											</dxi-column>

											<dxi-column dataField="contract_quantity" caption="Contract Qty" dataType="number"
												[visible]="invoice.data.commission_basis === 'dollarperuom'"
												[showInColumnChooser]="invoice.data.commission_basis === 'dollarperuom'"
												cellTemplate="cellTemplateContractQty" [allowHeaderFiltering]="false"
												[width]="'auto'">
												<div *dxTemplate="let element of 'cellTemplateContractQty'">
													{{element.data.contract_quantity || ''}}
												</div>
											</dxi-column>

											<dxi-column dataField="tier_basis" dataType="string" caption="Commission Type"
												cellTemplate="cellTemplateTierBasis" [allowFiltering]="false"
												[allowHeaderFiltering]="true" [allowSorting]="true" [width]="'auto'">
												<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateTierBasis'">
													{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' :
													'label' | uppercase}}
												</div>
											</dxi-column>

											<dxi-column dataField="commission_basis" dataType="string" caption="Commission Basis"
												[width]="'auto'" cellTemplate="cellTemplateCommissionBasis" [allowFiltering]="false"
												[allowHeaderFiltering]="true" [allowSorting]="true">
												<dxo-header-filter [dataSource]="listOfCommissionBasisHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateCommissionBasis'">
													{{element.data.commission_basis || '' | filterFromList:listOfCommissionBasis :
													'code' : 'label' | uppercase}}
												</div>
											</dxi-column>

											<dxi-column dataField="commissionRate" dataType="number" [allowFiltering]="false"
												[allowSorting]="false" [caption]="'Commission Rate (% | '+globalCurrencySymbol+')'"
												alignment="center" [allowHeaderFiltering]="false" [width]="'auto'">
												<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
													<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
													</dxo-format> -->
													<span *ngIf="element.data.commissionRate">
														<span *ngIf="element.data.commissionRateIn === '$'">
															{{element.data.commissionRate || 0 |
															customCurrencyPipe:customCurrencyOption }}
														</span>
														<span *ngIf="element.data.commissionRateIn === '%'">
															{{element.data.commissionRate + element.data.commissionRateIn}}
														</span>
													</span>
												</div>
											</dxi-column>
											<dxi-column dataField="serviceArCreditMemoValue" caption="Credit Memos (Service Type)"
												dataType="number" [allowFiltering]="false" [allowSorting]="false"
												[allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">
											</dxi-column>
											<dxi-column dataField="condition" dataType="string" caption="Condition"
												[width]="'auto'"></dxi-column>

											<dxi-column dataField="criterion" dataType="string" caption="Criterion"
												[width]="'auto'"></dxi-column>

											<dxi-column dataField="linetotal" [caption]="'Line Total ('+globalCurrencySymbol+')'"
												dataType="number" [allowFiltering]="false" [allowSorting]="false" name="linetotal3"
												[width]="'auto'" [allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">

											</dxi-column>

											<!-- 
											<dxi-column dataField="miscdeductioninventory" [allowFiltering]="false"
												[allowSorting]="false" caption="Misc. Deduction (%)" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												cellTemplate="cellTemplateMiscDeduction" [allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateMiscDeduction'">
													{{element.data.miscdeductioninventory || 0  }}%
												</div>
											</dxi-column> -->

											<dxi-column dataField="miscDedAmount" [allowFiltering]="false" [allowSorting]="false"
												caption="Misc. Deduction" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												[width]="'auto'" [allowHeaderFiltering]="false">
												<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format> -->
												<div *dxTemplate="let element of 'cellTemplateMiscDedAmount'">
													{{element.data.miscDedAmount || 0 | customCurrencyPipe:customCurrencyOption }}
													({{element.data.miscdeductioninventory || 0 |
													number:decimalPointPercentageFormat}}%)
												</div>
											</dxi-column>
											<dxi-column dataField="miscDedFcAmount" [allowFiltering]="false" [allowSorting]="false"
												caption="Misc. Deduction Fixed Cost" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												[width]="'auto'" [allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">
											</dxi-column>

											<dxi-column dataField="cashdiscount" [allowFiltering]="false" [allowSorting]="false"
												[caption]="'Cash Discount ('+globalCurrencySymbol+')'" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												[allowHeaderFiltering]="false" [width]="'auto'" [customizeText]="doCustomizeTotal">
											</dxi-column>
											<dxi-column dataField="finalAmount" [allowFiltering]="false" [allowSorting]="false"
												[caption]="'Final Line Total ('+globalCurrencySymbol+')'" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												[width]="'auto'" [allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">
											</dxi-column>
											<dxi-column dataField="costtotal" dataType="number" [allowFiltering]="false"
												[allowSorting]="false"
												[visible]="invoiceDetails.calculationOnBasis === 'grossprofit'"
												[caption]="'Cost ('+globalCurrencySymbol+')'"
												[showInColumnChooser]="invoice.data.commission_basis === 'grossprofit'"
												[width]="'auto'" [customizeText]="doCustomizeTotal">

											</dxi-column>
											<dxi-column dataField="paymentParcent" dataType="number"
												[customizeText]="doCustomizeTextToPercent" [allowFiltering]="false"
												[allowSorting]="false" [width]="'auto'" caption="Payment (%)">
											</dxi-column>
											<dxi-column dataField="grossprofit" dataType="number" [allowFiltering]="false"
												[allowSorting]="false"
												[visible]="invoiceDetails.calculationOnBasis === 'grossprofit'"
												[caption]="'Profit ('+globalCurrencySymbol+')'"
												[showInColumnChooser]="invoice.data.commission_basis === 'grossprofit'"
												[width]="'auto'" [customizeText]="doCustomizeTotal">
											</dxi-column>
											<dxi-column dataField="doccurrency" dataType="string" [allowFiltering]="false"
												[allowSorting]="false" caption="Doc Currency" [width]="'auto'">
											</dxi-column>
											<dxi-column dataField="exchangerate" dataType="number" [allowFiltering]="false"
												[allowSorting]="false" caption="Exchange Rate" [width]="'auto'">
											</dxi-column>
											<dxi-column dataField="orgCommissionAmount" dataType="number" [allowFiltering]="false"
												[allowSorting]="false" caption="Original Commission" [width]="'auto'"
												[calculateCellValue]="doCalculateCellValueOrgCommissionAmount">
											</dxi-column>
											<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false"
												[allowSorting]="false" caption="Commission" [width]="'auto'"
												[customizeText]="doCustomizeTotal">
											</dxi-column>
											<dxo-summary>
												<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="serviceArCreditMemoValue" summaryType="sum"
													displayFormat="{0}" [customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="miscDedAmount" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="miscDedFcAmount" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="cashdiscount" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="finalAmount" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="costtotal" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="grossprofit" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
												<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
													[customizeText]="doCustomizeTotalSummary">
												</dxi-total-item>
											</dxo-summary>

										</dx-data-grid>
									</div>
								</dx-data-grid>
							</div>
						</dx-data-grid>
					</div>

					<div *ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.ORDERS)">
						<dx-data-grid #ordersDataGrid id="ordersDataGrid" [dataSource]="invoicesDataSource"
							keyExpr="clientCode" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [allowColumnReordering]="true" [filterSyncEnabled]="true"
							[remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'"
							(onCellPrepared)="onCellinvoicePrepared($event)" (onCellClick)="doOnOrderCellClick($event)"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)"
							(onInitialized)="initializedOrderDataGrid($event)" [allowColumnResizing]="true"
							[allowColumnReordering]="true" [customizeColumns]="orderDataGridCustomizeColumns"
							[visible]="enable_profit_on_document_header">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadOrderState"
								[customSave]="doSaveOrderState" storageKey="orders-datagrid-state">
							</dxo-state-storing>
							<dxi-column dataField="clientName" dataType="string" caption="Customer Name"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="invoiceCode" dataType="string" [caption]="invoiceCodeActionText"
								[width]="'auto'" cellTemplate="cellTemplateDocNo">
								<div *dxTemplate="let element of 'cellTemplateDocNo'">
									<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
								</div>
							</dxi-column>
							<dxi-column dataField="linetotal" dataType="number" [allowFiltering]="false" [allowSorting]="false"
								caption="Gross Revenue" [width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<!-- <dxi-column dataField="costtotal" [allowFiltering]="false" [allowSorting]="false"
								caption="Total cost" >
								<div *dxTemplate="let element of 'cellTemplateTotalCost'">
									{{element.data.costtotal || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column> -->
							<dxi-column dataField="grossprofit" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Profit" [width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<!-- <dxi-column dataField="over_pricelist_amount" [allowFiltering]="false" [allowSorting]="false"
								caption="Over List Amount" >
								<div *dxTemplate="let element of 'cellTemplateOverList'">
									{{element.data.over_pricelist_amount || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column> -->
							<dxi-column dataField="overListRuleRate" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" [customizeText]="doCustomizeTextToPercent" caption="Over List Rate %"
								[width]="'auto'">
							</dxi-column>
							<dxi-column dataField="overListCommissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Over List Bonus" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="appliedTier" dataType="string" caption="Tier" [width]="'auto'"></dxi-column>
							<dxi-column dataField="commissionRate" dataType="number" [customizeText]="doCustomizeTextToPercent"
								[allowFiltering]="false" [allowSorting]="false" caption="Commission %" [width]="'auto'">
							</dxi-column>
							<!-- <dxi-column dataField="grossprofit" [allowFiltering]="false" [allowSorting]="false"
								caption="Profit" >
								<div *dxTemplate="let element of 'cellTemplateGrossProfit'">
									{{element.data.grossprofit || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column> -->
							<!-- <dxi-column dataField="card_finance_cost" [allowFiltering]="false" [allowSorting]="false"
								caption="Finance Fee" >
								<div *dxTemplate="let element of 'cellTemplateFinance'">
									{{element.data.card_finance_cost || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column>
							<dxi-column dataField="bonus_percentage_1" [allowFiltering]="false" [allowSorting]="false"
								caption="Self-Gen Bonus %" >
								<div *dxTemplate="let element of 'cellTemplateBonusPercentage1'">
									{{element.data.bonus_percentage_1 || 0 }}%
								</div>
							</dxi-column> -->
							<!-- <dxi-column dataField="bonus_percentage_2" [allowFiltering]="false" [allowSorting]="false"
								caption="Bonus % #2 Title" >
								<div *dxTemplate="let element of 'cellTemplateBonusPercentage2'">
									{{element.data.bonus_percentage_2 || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column> -->
							<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Commission" [width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="salespersonname" dataType="string" [allowHeaderFiltering]="false"
								caption="Sales Person" cellTemplate="cellTemplateSalesPersonName" [width]="'auto'">
								<div *dxTemplate="let element of 'cellTemplateSalesPersonName'">
									{{element.data.salespersonname|| '' }}
								</div>
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="clientName" displayFormat="Total">
								</dxi-total-item>
								<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="grossprofit" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="over_pricelist_amount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="base_profit_amount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="overListCommissionAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="custom_doctotal" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>

					<div *ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.CHANGE_ORDERS)">
						<dx-data-grid #changeOrderInvoiceDataGrid id="changeOrderInvoiceDataGrid"
							[dataSource]="changeOrderDataSource" keyExpr="clientCode" [showBorders]="true"
							[showColumnLines]="true" [allowColumnReordering]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							[columnAutoWidth]="true" [width]="'100%'" [customizeColumns]="orderChangeDataGridCustomizeColumns"
							(onInitialized)="initializedChnageOrderDataGrid($event)"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [allowColumnResizing]="true"
							[allowColumnReordering]="true" (onCellClick)="doOnChangeOrderCellClick($event)"
							[visible]="enable_profit_on_document_header">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadChangeOrderState"
								[customSave]="doSaveChangeOrderState" storageKey="change-orders-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxi-column dataField="ixcode" caption="Prev. Settlement No." dataType="string"></dxi-column>
							<dxi-column dataField="clientName" dataType="string" caption="Customer Name"></dxi-column>
							<dxi-column dataField="invoiceCode" dataType="string" [caption]="invoiceCodeActionText"
								cellTemplate="cellTemplateDocNo">
								<div *dxTemplate="let element of 'cellTemplateDocNo'">
									<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
								</div>
							</dxi-column>
							<dxi-column dataField="linetotal" dataType="number" [allowFiltering]="false" [allowSorting]="false"
								caption="Gross Revenue" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<!-- <dxi-column dataField="costtotal" [allowFiltering]="false" [allowSorting]="false"
									caption="Total cost" >
									<div *dxTemplate="let element of 'cellTemplateTotalCost'">
										{{element.data.costtotal || 0 | customCurrencyPipe:customCurrencyOption }}
									</div>
								</dxi-column> -->
							<dxi-column dataField="grossprofit" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Profit" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<!-- <dxi-column dataField="over_pricelist_amount" [allowFiltering]="false" [allowSorting]="false"
									caption="Over List Amount" >
									<div *dxTemplate="let element of 'cellTemplateOverList'">
										{{element.data.over_pricelist_amount || 0 | customCurrencyPipe:customCurrencyOption }}
									</div>
								</dxi-column> -->
							<dxi-column dataField="overListRuleRate" dataType="number"
								[customizeText]="doCustomizeTextToPercent" [allowFiltering]="false" [allowSorting]="false"
								caption="Over List Rate %">
							</dxi-column>
							<dxi-column dataField="overListCommissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Over List Bonus" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="appliedTier" dataType="string" caption="Tier"></dxi-column>
							<dxi-column dataField="commissionRate" dataType="number" [customizeText]="doCustomizeTextToPercent"
								[allowFiltering]="false" [allowSorting]="false" caption="Commission %">
							</dxi-column>
							<!-- <dxi-column dataField="card_finance_cost" [allowFiltering]="false" [allowSorting]="false"
									caption="Finance Fee" >
									<div *dxTemplate="let element of 'cellTemplateFinance'">
										{{element.data.card_finance_cost || 0 | customCurrencyPipe:customCurrencyOption }}
									</div>
								</dxi-column>
								<dxi-column dataField="bonus_percentage_1" [allowFiltering]="false" [allowSorting]="false"
									caption="Self-Gen Bonus %" cellTemplate="cellTemplateBonusPercentage1">
									<div *dxTemplate="let element of 'cellTemplateBonusPercentage1'">
										{{element.data.bonus_percentage_1 || 0 }}%
									</div>
								</dxi-column> -->
							<!-- <dxi-column dataField="bonus_percentage_2" [allowFiltering]="false" [allowSorting]="false"
									caption="Bonus % #2 Title" >
									<div *dxTemplate="let element of 'cellTemplateBonusPercentage2'">
										{{element.data.bonus_percentage_2 || 0 | customCurrencyPipe:customCurrencyOption }}
									</div>
								</dxi-column> -->
							<dxi-column dataField="previousCommissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Previous Commission" [width]="250"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Current Commission" [width]="250"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="commissionAmountDiff" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Commission Variance" [width]="250"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="salespersonname" dataType="string" [allowHeaderFiltering]="false"
								caption="Sales Person" cellTemplate="cellTemplateSalesPersonName">
								<div *dxTemplate="let element of 'cellTemplateSalesPersonName'">
									{{element.data.salespersonname|| '' }}
								</div>
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="grossprofit" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="previousCommissionAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="commissionAmountDiff" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<!-- [customizeText]="doCustomizeTotalSummary" -->
							</dxo-summary>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.MODIFIED_INVOICES)">
						<dx-data-grid #modifiedInvoicesDataGrid id="gridContainer" [dataSource]="modifiedInvoicesDataSource"
							keyExpr="docnum" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							[allowColumnResizing]="true" [allowColumnReordering]="true"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [columnAutoWidth]="true"
							(onCellClick)="doOnModifiedInvoicesCellClick($event)" [width]="'100%'">
							<dxo-master-detail [enabled]="true" template="itemDetailsTemplate"
								[autoExpandAll]="false"></dxo-master-detail>
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadModifiedInvoiceState"
								[customSave]="doSaveModifiedInvoiceState" storageKey="modified-invoices-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxi-column dataField="docnum" dataType="string" caption="Doc Num" [width]="'auto'"
								cellTemplate="cellTemplateDocNo">
								<div *dxTemplate="let element of 'cellTemplateDocNo'">
									<span class="highlight pointerMark">{{element.data.docnum}}</span>
								</div>
							</dxi-column>
							<dxi-column dataField="clientCode" dataType="string" caption="Customer Code"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="clientName" dataType="string" caption="Customer Name"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="previousCommissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Previous Commission" [width]="250"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Current Commission" [width]="250"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="commissionAmountDiff" dataType="number" [allowFiltering]="false"
								[allowSorting]="false" caption="Commission Variance" [width]="250"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmountDiff" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
							</dxo-summary>
							<div *dxTemplate="let invoice of 'itemDetailsTemplate'">
								<dx-data-grid #modifiedInvoicesItemDataGrid id="modifiedInvoicesItemDataGrid"
									[dataSource]="invoice.data.items" keyExpr="erp_primary_key" [showBorders]="true"
									[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
									[filterSyncEnabled]="false" [remoteOperations]="false" [columnAutoWidth]="true"
									(onCellClick)="doOnModifiedInvoiceItemCellClick($event)"
									(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [width]="'100%'">
									<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
									<dxo-pager [visible]="true" [showPageSizeSelector]="true"
										[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
									</dxo-pager>
									<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
									<dxo-state-storing [enabled]="true" type="custom"
										[customLoad]="doLoadInvoiceCustomersItemsState"
										[customSave]="doSaveInvoiceCustomersItemsState"
										storageKey="invoices-items-datagrid-state">
									</dxo-state-storing>
									<dxo-filter-row [visible]="true"></dxo-filter-row>

									<dxi-column dataField="itemname" dataType="string" [allowSorting]="true"
										[visible]="enable_classification_for_commision"
										[showInColumnChooser]="enable_classification_for_commision" caption="Items"
										[allowHeaderFiltering]="false" [width]="'auto'">
									</dxi-column>
									<dxi-column dataField="branch" dataType="string" [allowSorting]="true"
										[visible]="erp_type === ErpTypeValEnum.EPICOR_PROPHET21"
										[showInColumnChooser]="erp_type === ErpTypeValEnum.EPICOR_PROPHET21" caption="Branch"
										[allowHeaderFiltering]="false" [width]="'auto'">
									</dxi-column>
									<dxi-column dataField="uomcode" dataType="string" caption="Doc. UOM"
										cellTemplate="cellTemplateUom" [allowFiltering]="false" [allowHeaderFiltering]="true"
										[allowSorting]="true" [width]="'auto'">
										<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
										</dxo-header-filter>
										<div *dxTemplate="let element of 'cellTemplateUom'">
											{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' |
											uppercase }}
										</div>
									</dxi-column>
									<dxi-column dataField="quantity" caption="Doc. Qty" dataType="number"
										cellTemplate="cellTemplateQuantity" [allowHeaderFiltering]="false" [width]="'auto'">
										<div *dxTemplate="let element of 'cellTemplateQuantity'">
											{{element.data.quantity || '' }}
										</div>
									</dxi-column>
									<dxi-column dataField="contract_selectedUom" dataType="string" caption="Contract UOM"
										[visible]="invoice.data.commission_basis === 'dollarperuom'"
										[showInColumnChooser]="invoice.data.commission_basis === 'dollarperuom'"
										cellTemplate="cellTemplateContractUom" [allowFiltering]="false"
										[allowHeaderFiltering]="true" [allowSorting]="true" [width]="'auto'">
										<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
										</dxo-header-filter>
										<div *dxTemplate="let element of 'cellTemplateContractUom'">
											{{element.data.contract_selectedUom || '' | filterFromList:listOfUoms :'code' :
											'name' |
											uppercase }}
										</div>
									</dxi-column>
									<dxi-column dataField="contract_quantity" caption="Contract Qty" dataType="number"
										[visible]="invoice.data.commission_basis === 'dollarperuom'"
										[showInColumnChooser]="invoice.data.commission_basis === 'dollarperuom'"
										cellTemplate="cellTemplateContractQty" [allowHeaderFiltering]="false" [width]="'auto'">
										<div *dxTemplate="let element of 'cellTemplateContractQty'">
											{{element.data.contract_quantity || ''}}
										</div>
									</dxi-column>
									<dxi-column dataField="tier_basis" dataType="string" caption="Commission Type"
										cellTemplate="cellTemplateTierBasis" [allowFiltering]="false"
										[allowHeaderFiltering]="true" [allowSorting]="true" [width]="'auto'">
										<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter">
										</dxo-header-filter>
										<div *dxTemplate="let element of 'cellTemplateTierBasis'">
											{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' :
											'label' | uppercase}}
										</div>
									</dxi-column>
									<dxi-column dataField="commission_basis" dataType="string" caption="Commission Basis"
										[width]="'auto'" cellTemplate="cellTemplateCommissionBasis" [allowFiltering]="false"
										[allowHeaderFiltering]="true" [allowSorting]="true">
										<dxo-header-filter [dataSource]="listOfCommissionBasisHeaderFilter">
										</dxo-header-filter>
										<div *dxTemplate="let element of 'cellTemplateCommissionBasis'">
											{{element.data.commission_basis || '' | filterFromList:listOfCommissionBasis :
											'code' : 'label' | uppercase}}
										</div>
									</dxi-column>
									<dxi-column dataField="commissionRate" dataType="number" [allowFiltering]="false"
										[allowSorting]="false" [caption]="'Commission Rate (% | '+globalCurrencySymbol+')'"
										alignment="center" [allowHeaderFiltering]="false" [width]="'auto'">
										<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
											<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
											</dxo-format> -->
											<span *ngIf="element.data.commissionRate">
												<span *ngIf="element.data.commissionRateIn === '$'">
													{{element.data.commissionRate || 0 | customCurrencyPipe:customCurrencyOption }}
												</span>
												<span *ngIf="element.data.commissionRateIn === '%'">
													{{element.data.commissionRate + element.data.commissionRateIn}}
												</span>
											</span>
										</div>
									</dxi-column>
									<dxi-column dataField="serviceArCreditMemoValue" caption="Credit Memos (Service Type)"
										dataType="number" [allowFiltering]="false" [allowSorting]="false"
										[allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxi-column dataField="condition" dataType="string" caption="Condition" [width]="'auto'">
									</dxi-column>
									<dxi-column dataField="criterion" dataType="string" caption="Criterion" [width]="'auto'">
									</dxi-column>
									<dxi-column dataField="linetotal" [caption]="'Line Total ('+globalCurrencySymbol+')'"
										dataType="number" [allowFiltering]="false" [allowSorting]="false" name="linetotal3"
										[width]="'auto'" [allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxi-column dataField="miscDedAmount" [allowFiltering]="false" [allowSorting]="false"
										caption="Misc. Deduction" dataType="number"
										[visible]="invoice.data.commission_basis === 'percentage'"
										[showInColumnChooser]="invoice.data.commission_basis === 'percentage'" [width]="'auto'"
										[allowHeaderFiltering]="false">
										<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-format> -->
										<div *dxTemplate="let element of 'cellTemplateMiscDedAmount'">
											{{element.data.miscDedAmount || 0 | customCurrencyPipe:customCurrencyOption }}
											({{element.data.miscdeductioninventory || 0 |
											number:decimalPointPercentageFormat}}%)
										</div>
									</dxi-column>
									<dxi-column dataField="miscDedFcAmount" [allowFiltering]="false" [allowSorting]="false"
										caption="Misc. Deduction Fixed Cost" dataType="number"
										[visible]="invoice.data.commission_basis === 'percentage'"
										[showInColumnChooser]="invoice.data.commission_basis === 'percentage'" [width]="'auto'"
										[allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">
										<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-format> -->
									</dxi-column>
									<dxi-column dataField="cashdiscount" [allowFiltering]="false" [allowSorting]="false"
										[caption]="'Cash Discount ('+globalCurrencySymbol+')'" dataType="number"
										[visible]="invoice.data.commission_basis === 'percentage'"
										[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
										[allowHeaderFiltering]="false" [width]="'auto'" [customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxi-column dataField="finalAmount" [allowFiltering]="false" [allowSorting]="false"
										[caption]="'Final Line Total ('+globalCurrencySymbol+')'" dataType="number"
										[visible]="invoice.data.commission_basis === 'percentage'"
										[showInColumnChooser]="invoice.data.commission_basis === 'percentage'" [width]="'auto'"
										[allowHeaderFiltering]="false" [customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxi-column dataField="costtotal" dataType="number" [allowFiltering]="false"
										[allowSorting]="false" [visible]="invoiceDetails.calculationOnBasis === 'grossprofit'"
										[caption]="'Cost ('+globalCurrencySymbol+')'"
										[showInColumnChooser]="invoice.data.commission_basis === 'grossprofit'" [width]="'auto'"
										[customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxi-column dataField="paymentParcent" dataType="number"
										[customizeText]="doCustomizeTextToPercent" [allowFiltering]="false" [allowSorting]="false"
										[width]="'auto'" caption="Payment (%)">
									</dxi-column>
									<dxi-column dataField="grossprofit" dataType="number" [allowFiltering]="false"
										[allowSorting]="false" [visible]="invoiceDetails.calculationOnBasis === 'grossprofit'"
										[caption]="'Profit ('+globalCurrencySymbol+')'"
										[showInColumnChooser]="invoice.data.commission_basis === 'grossprofit'" [width]="'auto'"
										[customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false"
										[allowSorting]="false" caption="Commission" [width]="'auto'"
										[customizeText]="doCustomizeTotal">
									</dxi-column>
									<dxo-summary>
										<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="serviceArCreditMemoValue" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="miscDedAmount" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="miscDedFcAmount" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="cashdiscount" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="finalAmount" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="costtotal" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="grossprofit" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
										<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
											[customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
									</dxo-summary>
									<dxo-summary>
										<dxi-total-item column="commissionAmount" name="itemCommissionSummaryTotal"
											summaryType="sum" displayFormat="{0}" [customizeText]="doCustomizeTotalSummary">
										</dxi-total-item>
									</dxo-summary>
								</dx-data-grid>
							</div>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES)">
						<dx-data-grid #miscellaneousDeductionRulesDataGrid id="gridContainer"
							[dataSource]="miscellaneousDeductionRulesDataSource" keyExpr="criterion" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [filterSyncEnabled]="true"
							[allowColumnResizing]="true" [allowColumnReordering]="true" [remoteOperations]="true"
							[columnAutoWidth]="true" [width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom"
								[customLoad]="doLoadMiscellaneousDeductionRulesState"
								[customSave]="doSaveMiscellaneousDeductionRulesState"
								storageKey="miscellaneous-deduction-rules-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxi-column dataField="commissionType" dataType="string" caption="Commission Type"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="condition" dataType="string" caption="Condition"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="criterion" dataType="string" caption="Criterion"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="linetotal" dataType="number" caption="Commission Sub Total" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxi-column dataField="commissionRate" dataType="number" [format]="doConvertPercentValue"
								alignment="center" caption="Commission Rate (%)" [width]="'auto'">
							</dxi-column>
							<dxi-column dataField="commissionAmount" caption="Commission Amount" dataType="number"
								[width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES)">
						<dx-data-grid #extraCommissionBasedRulesDataGrid id="gridContainer"
							[dataSource]="extraCommissionBasedRulesDataSource" keyExpr="criterion" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [filterSyncEnabled]="true"
							[allowColumnResizing]="true" [allowColumnReordering]="true" [remoteOperations]="true"
							[columnAutoWidth]="true" [width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom"
								[customLoad]="doLoadExtraCommissionBasedRulesState"
								[customSave]="doSaveExtraCommissionBasedRulesState"
								storageKey="extra-commission-based-rules-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxi-column dataField="commissionType" dataType="string" caption="Commission Type"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="condition" dataType="string" caption="Condition"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="criterion" dataType="string" caption="Criterion"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="commissionRate" dataType="number" [format]="doConvertPercentValue"
								alignment="center" caption="Commission Rate (%)" [width]="'auto'">
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number" caption="Commission Amount"
								[width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS)">
						<dx-data-grid #serviceCreditmemosDataGrid id="gridContainer"
							[dataSource]="serviceCreditmemosDataSource" keyExpr="clientCode" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false"
							[masterDetail]="{ enabled: true, template: 'itemServiceCraditMemoDetailsTemplate','autoExpandAll': false }"
							[allowColumnResizing]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							[allowColumnResizing]="true" [allowColumnReordering]="true"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [columnAutoWidth]="true"
							[width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadServiceCraditMemoState"
								[customSave]="doSaveServiceCraditMemoState" storageKey="service-credit-memos-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="true"></dxo-filter-row>

							<dxi-column dataField="clientName" dataType="string" caption="Customer Name"
								[width]="'auto'"></dxi-column>
							<dxi-column dataField="doctotal" dataType="number" caption="Doc Total" [width]="'auto'"
								[customizeText]="doCustomizeTotal">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="doctotal" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
							</dxo-summary>
							<div *dxTemplate="let itemsDetails of 'itemServiceCraditMemoDetailsTemplate'">
								<dx-data-grid #serviceCraditMemoDataGrid id="serviceCraditMemoDataGrid"
									[dataSource]="itemsDetails.data.creditmemos" keyExpr="docentry" [showBorders]="true"
									[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
									[filterSyncEnabled]="true" [remoteOperations]="true" [columnAutoWidth]="true"
									(onCellClick)="doOnCrMemoItemCellClick($event)" [width]="'100%'">
									<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
									<dxo-pager [visible]="true" [showPageSizeSelector]="true"
										[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
									</dxo-pager>
									<dxo-filter-row [visible]="true"></dxo-filter-row>

									<!-- <dxi-column dataField="doctype" caption="Doc Type">
																			</dxi-column> -->
									<dxi-column dataField="docnum" dataType="string" caption="Doc Num" [width]="'auto'"
										cellTemplate="cellTemplateDocNo">
										<div *dxTemplate="let element of 'cellTemplateDocNo'">
											<span class="highlight pointerMark">{{element.data.docnum}}</span>
										</div>
									</dxi-column>
									<!-- <dxi-column dataField="docentry" caption="Doc Entery">
																			</dxi-column> -->
									<dxi-column dataField="docdate" dataType="date" caption="Doc Date" [width]="'auto'">
									</dxi-column>
									<!-- <dxi-column dataField="cardname" caption="Card Name">
																			</dxi-column> -->
									<dxi-column dataField="doctotal" dataType="number" caption="Doc Total" [width]="'auto'"
										[customizeText]="doCustomizeTotal">
									</dxi-column>
								</dx-data-grid>
							</div>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS)">
						<dx-data-grid #amortizedPaymentsDataGrid id="gridContainer" [dataSource]="amortizedPaymentsDataSource"
							keyExpr="docnum" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							[allowColumnResizing]="true" [allowColumnReordering]="true"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [columnAutoWidth]="true"
							(onCellClick)="doOnAmortizedPaymentCellClick($event)" [width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadAmortizedPaymentsState"
								[customSave]="doSaveAmortizedPaymentsState" storageKey="amortized-payments-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxi-column dataField="settlement_close_date" dataType="date" caption="Payment Start"
								[width]="'auto'">
							</dxi-column>

							<dxi-column dataField="condition" dataType="string" caption="Payment #"
								cellTemplate="cellTemplatePayment" [width]="'auto'">
								<div *dxTemplate="let element of 'cellTemplatePayment'">
									{{element.data.current_payment}} of {{element.data.number_of_payments}}
								</div>
							</dxi-column>
							<dxi-column dataField="docnum" dataType="string" caption="Doc Num" [width]="'auto'"
								cellTemplate="cellTemplateDocNo">
								<div *dxTemplate="let element of 'cellTemplateDocNo'">
									<span class="highlight pointerMark">{{element.data.docnum}}</span>
								</div>
							</dxi-column>

							<dxi-column dataField="billing_start_date" dataType="date" caption="Billing Start Date"
								[width]="'auto'">
							</dxi-column>

							<dxi-column dataField="billing_end_date" dataType="date" caption="Billing End Date"
								[width]="'auto'">
							</dxi-column>

							<dxi-column dataField="total_commission" dataType="number" caption="Total Commission Amount"
								[width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>

							<dxi-column dataField="payment_amount" dataType="number" caption="Amortized Amount"
								[width]="'auto'" [customizeText]="doCustomizeTotal">
							</dxi-column>

							<dxo-summary>
								<dxi-total-item column="total_commission" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
								<dxi-total-item column="payment_amount" summaryType="sum" displayFormat="{0}"
									[customizeText]="doCustomizeTotalSummary">
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>


					<!-- <div *ngIf="checkMasterDetails(masterDetailItem,'service_credit_memos')">
						<dx-data-grid #arcreditmemosDataGrid id="gridContainer" [dataSource]="arcreditmemosDataSource"
							keyExpr="clientCode" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							[columnAutoWidth]="true" [width]="'100%'">
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxi-column dataField="invoiceCode" [groupIndex]="1" [allowSorting]="true"
								[autoExpandGroup]="false" caption="Invoice"></dxi-column>
							<dxi-column dataField="clientName" caption="Customer Name"></dxi-column>
							<dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku" caption="Item"></dxi-column>
							<dxi-column dataField="itemname" caption="Item"></dxi-column>
							<dxi-column dataField="description" caption="Description"></dxi-column>
							<dxi-column dataField="linetotal" caption="Line Total"
								[format]="{style:'currency',currency:'USD',precision:2}">
							</dxi-column>
							<dxi-column dataField="commissionType" caption="Commission Type"></dxi-column>
							<dxi-column dataField="condition" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" caption="Criterion"></dxi-column>
							<dxi-column dataField="commissionRate" alignment="center" caption="Commission Rate (%)">
							</dxi-column>
							<dxi-column dataField="commissionAmount" caption="Commission Amount"
								[format]="{style:'currency',currency:'USD',precision:2}" [width]="180">
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" 
									displayFormat=" Sub Total: {0}">
									<dxo-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-format>
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div> -->

				</div>
				<dxo-export [enabled]="false"></dxo-export>
			</dx-data-grid>
		</div>
	</div>
</div>

<dx-popup width="90%" height="90%" [showTitle]="true" [title]="estimatedRebateHeaderText" [dragEnabled]="true"
	[hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="isVisiblePopup"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" (onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-sortable filter=".dx-tab" [data]="estimatedRebateTabData" itemOrientation="horizontal"
			dragDirection="horizontal" (onDragStart)="doTabDragStart($event)" (onReorder)="doTabDragStop($event)">
			<dx-tab-panel [dataSource]="estimatedRebateTabData" [height]="'inherit'" itemTitleTemplate="tabTitle"
				itemTemplate="tabContent" [deferRendering]="true" [showNavButtons]="false" [repaintChangesOnly]="true"
				[selectedItem]="selectedItem" [selectedIndex]="selectedTabIndex" [animationEnabled]="true"
				[swipeEnabled]="false" (onSelectionChanged)="doTabSelectionChanged($event)" [scrollingEnabled]="true"
				[showNavButtons]="false">
				<div *dxTemplate="let content of 'tabTitle'">
					<span>{{content.text}}</span>
				</div>
				<div *dxTemplate="let tab of 'tabContent'">
					<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'"
						[bounceEnabled]="false" width="100%" height="500">
						<app-rebates-tierbase-calculation-view *ngIf="isviewRebateCalView"
							[isCallFromCommissionContract]="true"
							[settlementPeriodStart]="invoiceDetails.settlementPeriodStart"
							[settlementPeriodEnd]="invoiceDetails.settlementPeriodEnd">
						</app-rebates-tierbase-calculation-view>
					</dx-scroll-view>
				</div>
			</dx-tab-panel>
		</dx-sortable>
	</div>
</dx-popup>

<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width80:'X'" height="680" [showTitle]="true"
	[dragEnabled]="true" [hideOnOutsideClick]="false" title="Export PDF" [showCloseButton]="true"
	[(visible)]="isShowPopupDatagridExport" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'" class="">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<app-incentives-export-pdf *ngIf="isShowPopupDatagridExport" [listOfColumnsProps]="listOfMasterDatagrid"
				(exportPdfEmitter)="doExportPdfEmitting($event)"></app-incentives-export-pdf>
		</dx-scroll-view>
	</div>
</dx-popup>

<app-view-invoice-detail-popup *ngIf="isShowInvoiceDetailPopUp" [viewInvoiceDetailProps]="viewInvoiceDetailProps">
</app-view-invoice-detail-popup>

<app-view-order-changeorder-detail-popup *ngIf="isShowOrderChangeOrderDetailPopUp"
	[viewOrderChangeOrderDetailProps]="viewOrderChangeOrderDetailProps">
</app-view-order-changeorder-detail-popup>

<app-view-craditmemo-detail-popup *ngIf="isShowCreditMemoDetailPopUp"
	[viewCreditMemoDetailProps]="viewCreditMemoDetailProps"></app-view-craditmemo-detail-popup>