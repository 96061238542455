import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CommissionSettlementReportSectionEnum, ContractTypeValEnum } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownButtonComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
@Component({
	selector: 'app-cts-misc-deductrule-rpt-tab',
	templateUrl: './cts-misc-deductrule-rpt-tab.component.html',
	styleUrls: ['./cts-misc-deductrule-rpt-tab.component.css']
})
export class CtsMiscDeductruleRptTabComponent implements OnInit {
	public currencyFormat: any;
	@ViewChild("miscDeductruleDataGridRef", {}) miscDeductruleDataGridRef: DxDataGridComponent;
	@ViewChild("dropDownButtonRef", {}) dropDownButtonRef: DxDropDownButtonComponent;
	@Input() commissionRptDetails: CommissionSettlemntRptModel;
	@Input() grdDataSource?: any; // getting from commission contract report 
	public pageOptions: PageOptions = new PageOptions();
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public CommissionSettlementReportSectionEnum = CommissionSettlementReportSectionEnum;
	private updateUserSettingSbsn: Subscription;
	public miscDeductrulesDataSource: any = [];
	public filterValue: any[] = [];
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string = '';
	public listOfActions: any[] = this._CommissionService.getListOfActionsDatagrid();
	private guid = new Guid();
	private filterValueChangeSbsn: Observable<any>;
	public stateStorageKey = 'miscDeductrules-datagrid-state';
	public globalCurrencySymbol: string;
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];
	public listOfCommissionBasis: any[] = [];
	public listOfCommissionBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'miscDeductrule', text: 'miscDeductrule' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'miscDeductrule', text: 'miscDeductrule' }];
	constructor(
		public _AppCommonSrvc: AppCommonSrvc,
		public _CommissionService: CommissionService,
		public _LoginService: LoginService,
		public _LoaderService: LoaderService,
		public _ToastrService: ToastrService,
		public _RestApiService: RestApiService,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
	) {
		this.doLoadMiscDeductruleState = this.doLoadMiscDeductruleState.bind(this);
		this.doSaveMiscDeductruleState = this.doSaveMiscDeductruleState.bind(this);
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
		this.doConvertPercentValue = this.doConvertPercentValue.bind(this);
	}

	ngOnInit(): void {
		this.currencyFormat = this.commissionRptDetails.currencyFormat;
		if (this.commissionRptDetails.currency_code) {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		} else {
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		}
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
		if (this.grdDataSource) {
			this.miscDeductrulesDataSource = this.grdDataSource;
			this.updateStatesClearBtn();
		} else {
			this.loadMiscDeductrulesDataSource();
		}
		setTimeout(() => {
			if (this.miscDeductruleDataGridRef) {
				this.filterValueChangeSbsn = this.miscDeductruleDataGridRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterTextTab();
				});
			}
		});
	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	public doCustomizeTotalSummary(e) {
		if (e.valueText) {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		}
	}

	doConvertPercentValue(value: any) {
		return this._CustomNumberNoRoundingPipe.transform(+value || 0) + '%';
	}

	doMiscDeductruleToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.miscDeductruleDataGridRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	template: 'templateActionBtns',
			// 	visible: true,
			// },
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			// columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}
	doActionsBtnItemClick(event) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.miscDeductruleDataGridRef.instance.showColumnChooser();
					break;
			}
		}
	}
	doRefreshDataGrid() {
		this.miscDeductruleDataGridRef.instance.refresh();
	}
	doExportToExcel() {

	}
	doActionsBtnClick() {
		// const addIndex = this.listOfActions.findIndex(i => i.btn_code
		// 	=== 'ADD');
		// if (addIndex !== -1) {
		// 	this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		// }
	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.miscDeductruleDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Get Same Text from Filter Panel
	private getFilterTextTab() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		if (this.miscDeductruleDataGridRef) {
			this.miscDeductruleDataGridRef.filterPanel.customizeText = (e: any) => {
				this.filterText = e.text;
				if (this.filterText) {
					this.isDisabledClearBtn = false;
				} else {
					this.isDisabledClearBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}
	// Method used to Enabled/Disabled Clear Button
	public updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
	public doLoadMiscDeductruleState = () => {
		return this._CommissionService.loadStateDataGrid(this.stateStorageKey);
	}
	public doSaveMiscDeductruleState = (e) => {
		this._CommissionService.saveStateDataGrid(e, this.stateStorageKey);
	}
	loadMiscDeductrulesDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.MISCELLANEOUS_DEDUCTION_RULES,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.commissionRptDetails.contractGuid,
		}
		this.miscDeductrulesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onLoaded: (result) => {
				this.updateStatesClearBtn();
			}
		});

	}
}
