import { formatCurrency } from '@angular/common';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Company360DashboardInvoiceLblEnum, Company360DashboardInvoiceValEnum, Company360DashboardOrderLblEnum, Company360DashboardOrderValEnum } from '@app/constants-enums/enums';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-drill-down-bar-chart',
	templateUrl: './drill-down-bar-chart.component.html',
	styleUrls: ['./drill-down-bar-chart.component.css'],
	providers: [CustomCurrencyPipe]
})
export class DrillDownBarChartComponent implements OnInit, OnDestroy {
	@ViewChild(DxSelectBoxComponent) dxselectboxReportType: DxSelectBoxComponent;
	public currentReports: string;
	public isVisibleChartLoader: boolean = false;
	public data: DataItem[];
	dataSource: DataItem[];
	colors: string[];
	totalLevel: number = 3;
	levelCount: number = 0;
	isFirstLevel: boolean;
	currentLevelList: any[] = [];
	// chartViewTypeList: any[] = [{ 'Key': 'orders', 'Value': 'Orders' }, { 'Key': 'invoices', 'Value': 'Invoices' }, { 'Key': 'commission', 'Value': 'Commission' }];
	chartViewTypeList: any[] = [{ 'Key': 'orders', 'Value': 'Orders' }, { 'Key': 'invoices', 'Value': 'Invoices' }];
	private dataOutReqSubscription: Subscription;
	public orderByLbl: string;
	public invoiceByLbl: string;

	constructor(element: ElementRef,
		private _LoginService: LoginService,
		private _restApiService: RestApiService,
		private _GlobalStateService: GlobalStateService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _ToastrService: ToastrService) {
		this.customizeTooltip = this.customizeTooltip.bind(this);
	}

	filterData(name): DataItem[] {
		return this.data.filter(function (item) {
			return item.parentID === name;
		});
	}


	ngOnInit() {
		this.getReportsdata();
		this.colors = this.getColors();
		this.isFirstLevel = true;
		this._GlobalStateService.subscribe('REFRESH_YEAR_TO_DASHBOARD_CHART_EVENT', response => {
			this.getReportsdata();
		});
	}

	ngOnDestroy() {
		this._GlobalStateService.unsubscribe('REFRESH_YEAR_TO_DASHBOARD_CHART_EVENT');
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	getReportsdata() {
		// if (!this.currentReports) {
		// 	this.currentReports = 'orders';
		// }
		// const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&method=dxGetEntityData' + '&entity=drilldownChart' + '&section=' + this.currentReports + ' ';
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.DASHBOARD_SALES_CHART);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.isVisibleChartLoader = true;
		this.dataOutReqSubscription = this._restApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this.isVisibleChartLoader = false;
				// if (response && response.data) {
				// 	this.data = response.data || [];
				// 	this.dataSource = response.data || [];
				// } else {
				// 	this.data = [];
				// }
				// this.dataSource = this.filterData("");
				if (response && response.data) {
					this.dataSource = response.data || [];
				}
				this.getCustomer360Config();
			}, error: error => {
				this.isVisibleChartLoader = false;
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}


	onButtonClick() {
		this.levelCount--;
		this.removeParentId();
		const parentId = this.currentLevelList[this.levelCount - 1] || '';
		this.dataSource = this.filterData(parentId);
	}

	removeParentId() {
		const parentId = this.currentLevelList[this.levelCount];
		this.currentLevelList = _.without(this.currentLevelList, parentId);
	}

	onPointClick(e) {
		// if (this.levelCount !== this.totalLevel) {
		// 	this.currentLevelList.push(e.target.originalArgument);
		// 	this.dataSource = this.filterData(e.target.originalArgument);
		// 	this.levelCount++;
		// }
	}

	public selectChanged(event) {
		this.currentReports = event;
		this.getReportsdata();
	}

	customizeTooltip = (info: any) => {
		return {
			html: "<div> " + this._CustomCurrencyPipe.transform(info.value) + "</div>"
		};
	}

	customizePoint = () => {
		let pointSettings: any;

		pointSettings = {
			color: this.colors[this.levelCount]
		};

		if (!this.isFirstLevel) {
			pointSettings.hoverStyle = {
				hatching: "none"
			};
		}

		return pointSettings;
	}

	getColors(): string[] {
		return colors;
	}

	// Method used to get Customer360 Configuration
	private getCustomer360Config() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		let configIndex = -1;
		this.orderByLbl = Company360DashboardOrderLblEnum.DATE_DOC_DATE;
		this.invoiceByLbl = Company360DashboardInvoiceLblEnum.DATE_DOC_DATE;
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				configIndex = dashboardSettings.configs.findIndex(cg => cg.code === 'order_tile');
				if (configIndex !== -1) {
					const orderConfig = dashboardSettings.configs[configIndex];
					if (orderConfig.which_date_to_use) {
						if (orderConfig.which_date_to_use === Company360DashboardOrderValEnum.DATE_DOC_DATE) {
							this.orderByLbl = Company360DashboardOrderLblEnum.DATE_DOC_DATE;
						}
						if (orderConfig.which_date_to_use === Company360DashboardOrderValEnum.DATE_DUE_DATE) {
							this.orderByLbl = Company360DashboardOrderLblEnum.DATE_DUE_DATE;
						}
					}
				}
				configIndex = dashboardSettings.configs.findIndex(cg => cg.code === 'invoice_tile');
				if (configIndex !== -1) {
					const invoiceConfig = dashboardSettings.configs[configIndex];
					if (invoiceConfig.which_date_to_use) {
						if (invoiceConfig.which_date_to_use === Company360DashboardInvoiceValEnum.DATE_DOC_DATE) {
							this.invoiceByLbl = Company360DashboardInvoiceLblEnum.DATE_DOC_DATE;
						}
						if (invoiceConfig.which_date_to_use === Company360DashboardInvoiceValEnum.DATE_DUE_DATE) {
							this.invoiceByLbl = Company360DashboardInvoiceLblEnum.DATE_DUE_DATE;
						}
						if (invoiceConfig.which_date_to_use === Company360DashboardInvoiceValEnum.DATE_DATE_PAID) {
							this.invoiceByLbl = Company360DashboardInvoiceLblEnum.DATE_DATE_PAID;
						}
					}
				}
			}
		}
	}

	doCustomLegend(e: any) {
		const seriesSplit = e.seriesName ? e.seriesName.split(':') : [];
		if (seriesSplit && seriesSplit.length > 0) {
			return seriesSplit[0] + ': <b>' + seriesSplit[1] + '</b>';
		}
		return '';
	}
}

export class DataItem {
	arg: string;
	val: number;
	parentID: string;
}
let colors: string[] = ["#21b3c6", "#e55253", "#F25022", "#7FBA00", "#00A4EF", "#FFB900", "#737373"];
