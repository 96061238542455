<dx-data-grid #tiersItemDetailssDataGridRef id="tiersItemDetailssDataGridRef" [dataSource]="customer_age_tiers"
	(onInitNewRow)="doOnInitNewRowAgeItmTab($event,tiersData)"
	[masterDetail]="{ enabled: true, template: 'subTiersTemplate','autoExpandAll': false }"
	(onRowInserted)="doOnRowInsertedAgeItmTab($event,tiersData)"
	(onRowUpdated)="doOnRowUpdatedAgeItmTab($event,tiersData)" (onRowRemoved)="doOnRowRemovedAgeItmTab($event,tiersData)"
	(onSaved)="doOnSavedAgeItmTab($event,tiersData)"
	[masterDetail]="{ enabled: true, template: 'subTiersTemplate','autoExpandAll': true }" keyExpr="guid"
	[showBorders]="true" [columnAutoWidth]="true">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-filter-row [visible]="false"></dxo-filter-row>
	<dxo-filter-panel [visible]="false"></dxo-filter-panel>
	<dxo-header-filter [visible]="false"></dxo-header-filter>

	<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
		[allowAdding]="!(tiersData.tier_basis === 'notiers')" [useIcons]="true">
	</dxo-editing>

	<dxi-column caption="Age Minimum" dataField="minage" dataType="number" [allowEditing]="true"
		[editorOptions]="{ format: 'decimal', showClearButton: true }">
	</dxi-column>

	<dxi-column caption="Age Maximum" dataField="maxage" [allowEditing]="true"
		[editorOptions]="{ showClearButton: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-column>

	<!-- <dxi-column
		[caption]="'Rate'+ c.data.commission_basis === 'dollar' ? '('+globalCurrencySymbol+')' : c.data.commission_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')'"
		dataField="rate" dataType="number" [allowEditing]="true"
		[editorOptions]="{ format: 'decimal', showClearButton: true }" cellTemplate="cellTemplateRate">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<div *dxTemplate="let element of 'cellTemplateRate'">
			<span
				*ngIf="(tiersData.data.commission_basis === 'dollar' || tiersData.data.commission_basis === 'dollarperuom')">
				{{element.data.rate || 0 | customCurrencyPipe }}
			</span>
			<span *ngIf="tiersData.data.commission_basis === 'percentage'">
				{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%' }}
			</span>
		</div>
	</dxi-column> -->
	<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
		[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
		[allowExporting]="false">
		<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
		<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
	</dxi-column>
	<div *dxTemplate="let subTiersData of 'subTiersTemplate'; index as i">
		<dx-data-grid #subTiersItemDetailssDataGridRef_{{i}} id="subTiersItemDetailssDataGridRef_{{i}}"
			[dataSource]="subTiersData.data.tiers" (onInitNewRow)="doOnInitNewRowsubTiersItmTab($event,subTiersData)"
			(onRowInserted)="doOnRowInsertedsubTiersItmTab($event,subTiersData)"
			(onRowUpdated)="doOnRowUpdatedsubTiersItmTab($event,subTiersData)"
			(onRowRemoved)="doOnRowRemovedsubTiersItmTab($event,subTiersData)"
			(onSaved)="doOnSavedsubTiersItmTab($event,subTiersData)" keyExpr="guid" [showBorders]="true"
			[columnAutoWidth]="true">
			<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
			<dxo-filter-row [visible]="false"></dxo-filter-row>
			<dxo-filter-panel [visible]="false"></dxo-filter-panel>
			<dxo-header-filter [visible]="false"></dxo-header-filter>

			<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
				[allowAdding]="!(subTiersData.data.tier_basis === 'notiers')" [useIcons]="true">
			</dxo-editing>
			<dxo-master-detail [enabled]="commissionDetails.tiers_type === 'multiple_items'" template="itemsTemplate"
				[autoExpandAll]="false"></dxo-master-detail>
			<dxi-column caption="Tier Minimum" dataField="mintier" dataType="number" [allowEditing]="true"
				[editorOptions]="{ format: 'decimal', showClearButton: true }">
			</dxi-column>

			<dxi-column caption="Tier Maximum" dataField="maxtier" [allowEditing]="true"
				[editorOptions]="{ showClearButton: true }">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-column>

			<dxi-column
				[caption]="'Rate' + subTiersData.data.commission_basis === 'dollar' ? '('+globalCurrencySymbol+')' : subTiersData.data.commission_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')'"
				dataField="rate" [allowEditing]="true" [editorOptions]="{ showClearButton: true }"
				[visible]="commissionDetails.tiers_type === 'single_items'" cellTemplate="cellTemplateRate">

				<dxi-validation-rule type="custom"
					[message]="'Rate '+ (subTiersData.data.commission_basis === 'dollar' ? '('+globalCurrencySymbol+')' : subTiersData.data.commission_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')') +' is required'"
					[validationCallback]="isValidRateCallback">
				</dxi-validation-rule>
				<div *dxTemplate="let element of 'cellTemplateRate'">

					{{setRateValue(tiersData,element)}}
					<!-- <span
						*ngIf="subTiersData.data.commission_basis === 'dollar' || subTiersData.data.commission_basis === 'dollarperuom'">
						{{element.data.rate || 0 | customCurrencyPipe }}
					</span>
					<span *ngIf="subTiersData.data.commission_basis === 'percentage'">
						{{element.data.rate || 0 | customNumberNoRoundingPipe}} %
					</span> -->
				</div>
			</dxi-column>
			<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
				[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
				[allowExporting]="false">
				<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
				<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
			</dxi-column>

			<div *dxTemplate="let item of 'itemsTemplate'">
				<dx-data-grid #itemsOfTiersDataGridRef [dataSource]="getItemsByTiersDetails(item)"
					(onEditorPreparing)="doEditorPreparingItemTierItmTab($event,item,subTiersData)"
					(onRowInserted)="doRowInsertedItemTierItmTab($event,item,subTiersData)"
					(onRowUpdated)="doRowUpdatedItemTierItmTab($event,item,subTiersData)"
					(onRowRemoved)="doRowRemovedItemTierItmTab($event,item,subTiersData)"
					(onSaved)="doOnSavedItemTierItmTab($event,item,subTiersData)" keyExpr="erp_primary_key"
					[showBorders]="true" [allowColumnResizing]="true" [columnAutoWidth]="true">
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="true"></dxo-filter-panel>
					<dxo-header-filter [visible]="true"></dxo-header-filter>
					<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false"
						[useIcons]="true">
					</dxo-editing>

					<dxi-column caption="Classes" [visible]="enable_classification_for_commision" dataField="classname"
						dataType="string" [allowEditing]="false" cellTemplate="cellTemplateClassesName"
						[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateClassesName'">
							{{element.data.classname || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="SKU" dataField="itemsku"
						[visible]="isQBOnlineErpUser && !enable_classification_for_commision" dataType="string"
						[allowEditing]="false" cellTemplate="cellTemplateItemSku" [allowHeaderFiltering]="false"
						[allowFiltering]="true" [allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateItemSku'">
							{{element.data.itemsku || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="Item" dataField="itemname" [visible]="!enable_classification_for_commision"
						dataType="string" [allowEditing]="false" editCellTemplate="itemsListEditor"
						cellTemplate="cellTemplateItemName" [allowHeaderFiltering]="false" [allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateItemName'">
							{{element.data.itemname}}
						</div>
					</dxi-column>

					<dxi-column caption="Description" dataField="description"
						[visible]="!enable_classification_for_commision" dataType="string" [allowEditing]="false"
						[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true"
						cellTemplate="cellTemplateItemDesc">
						<div *dxTemplate="let element of 'cellTemplateItemDesc'">
							{{element.data.description || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="UOM" dataField="selectedUom" [visible]="!enable_classification_for_commision"
						dataType="string" [allowEditing]="subTiersData.data.commission_basis === 'dollarperuom'"
						[setCellValue]="setCellValueUomColumn" cellTemplate="cellTemplateUomCode"
						[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false">
						<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
						</dxo-lookup>
						<div *dxTemplate="let element of 'cellTemplateUomCode'">
							<span *ngIf="subTiersData.data.commission_basis === 'dollarperuom'">
								{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' |
								uppercase}}
							</span>
						</div>
					</dxi-column>

					<dxi-column
						[caption]="'Rate '+(subTiersData.data.commission_basis === 'dollar' ? '('+globalCurrencySymbol+')' : subTiersData.data.commission_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')')"
						dataField="rate" dataType="number" [allowEditing]="true" [allowHeaderFiltering]="false"
						[allowFiltering]="false" [allowSorting]="false"
						[editorOptions]="{ format: 'decimal', showClearButton: true }" cellTemplate="cellTemplateItemRate"
						[width]="100">
						<dxi-validation-rule type="required"></dxi-validation-rule>
						<div *dxTemplate="let element of 'cellTemplateItemRate'">
							<span
								*ngIf="(subTiersData.data.commission_basis === 'dollar' || subTiersData.data.commission_basis === 'dollarperuom')">
								{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
							</span>
							{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
							<span *ngIf="subTiersData.data.commission_basis === 'percentage'">
								{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%'}}
							</span>
						</div>
					</dxi-column>

					<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
						[fixed]="true" fixedPosition="right" [allowSorting]="false" alignment="center" [allowResizing]="false"
						[minWidth]="150" [width]="150" [allowExporting]="false">
						<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
						<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
					</dxi-column>

				</dx-data-grid>
			</div>
		</dx-data-grid>
	</div>
</dx-data-grid>