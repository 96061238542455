<div>
	<span class="highlight noteBx mrgT10 mrgB20">
		<span class="mrgR10"><strong>Note: </strong></span>
		<span>Use [NAME] [BILLINGADDRESS] [SHIPPINGADDRESSTOTAL] [PRICELIST] [STARTDATE] [ENDDATE] [REPLYDATE] and
			[SHIPPINGADDRESSLIST] </span>
	</span>
</div>
<div class="row">
	<div class="cols12">
		<dx-html-editor [(value)]="terms_and_conditions" [height]="'calc(100vh - 430px)'">
			<dxo-table-context-menu [enabled]="true"></dxo-table-context-menu>
			<dxo-table-resizing [enabled]="true"></dxo-table-resizing>
			<dxo-toolbar [multiline]="true">
				<dxi-item name="undo"></dxi-item>
				<dxi-item name="redo"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
					[options]="{ inputAttr: { 'aria-label': 'Font size' } }"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="bold"></dxi-item>
				<dxi-item name="italic"></dxi-item>
				<dxi-item name="strike"></dxi-item>
				<dxi-item name="underline"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="alignLeft"></dxi-item>
				<dxi-item name="alignCenter"></dxi-item>
				<dxi-item name="alignRight"></dxi-item>
				<dxi-item name="alignJustify"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="orderedList"></dxi-item>
				<dxi-item name="bulletList"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="color"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="insertTable"></dxi-item>
				<dxi-item name="deleteTable"></dxi-item>
				<dxi-item name="insertRowAbove"></dxi-item>
				<dxi-item name="insertRowBelow"></dxi-item>
				<dxi-item name="deleteRow"></dxi-item>
				<dxi-item name="insertColumnLeft"></dxi-item>
				<dxi-item name="insertColumnRight"></dxi-item>
				<dxi-item name="deleteColumn"></dxi-item>
				<dxi-item name="separator"></dxi-item>
				<dxi-item name="cellProperties"></dxi-item>
				<dxi-item name="tableProperties"></dxi-item>

			</dxo-toolbar>
			<dxo-media-resizing [enabled]="true"></dxo-media-resizing>
		</dx-html-editor>
	</div>

	<div class="cols12">
		<div class="alignRight mrgT20">
			<dx-button class="primaryBtn" text="Submit" type="normal" stylingMode="contained"
				(onClick)="doSubmitPricelistDetails($event)" [useSubmitBehavior]="false" icon="save"
				[disabled]="!terms_and_conditions || (terms_and_conditions && !terms_and_conditions.trim())"
				[ngClass]="{'greyButton':!terms_and_conditions  || (terms_and_conditions && !terms_and_conditions.trim())}">
			</dx-button>
		</div>
	</div>
</div>