import { DxButtonModule, DxDataGridModule, DxPopupModule, DxTemplateModule, DxTextAreaModule, DxBoxModule, DxPopoverModule, DxTextBoxModule, DxSelectBoxModule, DxDateBoxModule, DxRadioGroupModule, DxDropDownBoxModule, DxTagBoxModule, DxDropDownButtonModule, DxScrollViewModule, DxTooltipModule } from 'devextreme-angular';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';

import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsTabComponent } from './notifications-tab/notifications-tab.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MainPipesModule } from '@app/pipes/main-pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NotificationsTasksPopupComponent } from './notifications-tasks-popup/notifications-tasks-popup.component';
import { NotificationsApprovalsTabComponent } from './notifications-approvals-tab/notifications-approvals-tab.component';
import { CustomComponentsModule } from '@app/custom-components/custom-components.module';
import { ApprovalsTabsComponent } from './approvals-tabs/approvals-tabs.component';
import { PricelistApprovalsListingComponent } from './pricelist-approvals-listing/pricelist-approvals-listing.component';
import { RebateSettlementsApprovalsListingComponent } from './rebate-settlements-approvals-listing/rebate-settlements-approvals-listing.component';
import { MinMaxChargebackClaimApprovalsListingComponent } from './min-max-chargeback-claim-approvals-listing/min-max-chargeback-claim-approvals-listing.component';
import { MinMaxChargebackClaimApprovalsDetailsComponent } from './min-max-chargeback-claim-approvals-details/min-max-chargeback-claim-approvals-details.component';
import { BillbackModule } from '../contracts/billback/billback.module';

@NgModule({
	declarations: [
		NotificationsTabComponent,
		NotificationsComponent,
		NotificationsTasksPopupComponent,
		NotificationsApprovalsTabComponent,
		ApprovalsTabsComponent,
		PricelistApprovalsListingComponent,
		RebateSettlementsApprovalsListingComponent,
		MinMaxChargebackClaimApprovalsListingComponent,
		MinMaxChargebackClaimApprovalsDetailsComponent,
	],
	imports: [
		CommonModule,
		NotificationsRoutingModule,
		MatTableModule,
		MatPaginatorModule,
		MainPipesModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPermissionsModule,
		DxPopupModule,
		DxButtonModule,
		DxTemplateModule,
		DxDataGridModule,
		DxTextAreaModule,
		DxBoxModule,
		DxPopoverModule,
		DxTextBoxModule,
		DxSelectBoxModule,
		DxDateBoxModule,
		DxRadioGroupModule,
		DxDropDownBoxModule,
		DxDropDownButtonModule,
		DxTagBoxModule,
		DxScrollViewModule,
		CustomComponentsModule,
		DxTooltipModule,
		BillbackModule
	],
	providers: [
		DecimalPipe,
		CurrencyPipe
	],
	exports: [
		NotificationsTasksPopupComponent,
		PricelistApprovalsListingComponent
	]
})
export class NotificationsModule { }
