import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/services/login.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { CRMValStatuses, CRMTabValEnum, CRMTabLblEnum, SalesTabsValEnum, Company360DashboardOrderValEnum, Company360DashboardInvoiceValEnum, DateRangeGlobalSettingValEnum, DateRangeGlobalSettingLblEnum, Company360DashboardArCreditmemoValEnum } from "@app/constants-enums/enums";
import { SideMenuForEnum } from '@app/constants-enums/enums';
import * as moment from "moment";
import { ServerSections } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	constructor(
		private _LoginService: LoginService,
		private _CrmsService: CrmsService,
		public _SidemenuService: SidemenuService,
		private _SalesDataSharingService: SalesDataSharingService,
		private _Router: Router,
		private _AppCommonSrvc: AppCommonSrvc,
	) { }

	// Method to redirect Appropriate page
	tilesClick(clickFor: string) {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		let filterValue = [];
		let fromDate: Date;
		let toDate: Date;
		let docDateObj: any;
		let getFiltersObj: any;
		switch (clickFor) {
			case 'TASKS':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.TASKS);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.TASKS);
				filterValue = [{ dataType: 'string', dataField: 'status', searchMode: 'contains', filterValues: [CRMValStatuses.OPEN] }];
				this._CrmsService.setStoredFilters(filterValue);
				this._Router.navigate(['/crms/tasks-list']);
				break;
			case 'LEADS':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.LEAD);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.LEAD);
				filterValue = [{ dataType: 'string', dataField: 'status', searchMode: 'contains', filterValues: [CRMValStatuses.NEWLEAD, CRMValStatuses.INITIALCONTACT, CRMValStatuses.FOLLOWUP] }];
				this._CrmsService.setStoredFilters(filterValue);
				this._Router.navigate(['/crms/leads-list']);
				break;
			case 'PROSPECTS':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.PROSPECT);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.PROSPECT);
				filterValue = [{ dataType: 'string', dataField: 'status', searchMode: 'contains', filterValues: [CRMValStatuses.DEMOSAMPLES, CRMValStatuses.PROPOSALDEAL, CRMValStatuses.PRICENEGOTIATION] }];
				this._CrmsService.setStoredFilters(filterValue);
				this._Router.navigate(['/crms/prospects-list']);
				break;
			case 'CUSTOMERS':
				this._CrmsService.setSelectedTabData(CRMTabValEnum.CLIENT);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.CLIENT);
				this._CrmsService.setStoredFilters(undefined);
				this._Router.navigate(['/crms/clients-list']);
				break;
			case 'QUOTATIONS':
				filterValue = [];
				getFiltersObj = this.getQuoteFiltersForTiles('quote_tile');
				if (getFiltersObj.whichDateToUse === Company360DashboardInvoiceValEnum.DATE_DOC_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docdate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichDateToUse === Company360DashboardInvoiceValEnum.DATE_DUE_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docduedate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}

				this._SalesDataSharingService.setStoredFilters(filterValue);
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.QUOTATIONS);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.QUOTATIONS);
				this._Router.navigate(['/sales/quotations-list']);
				break;
			case 'ORDERS':
				filterValue = [];
				getFiltersObj = this.getOrderFiltersForTiles('order_tile');
				if (getFiltersObj.whichDateToUse === Company360DashboardInvoiceValEnum.DATE_DOC_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docdate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichDateToUse === Company360DashboardInvoiceValEnum.DATE_DUE_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docduedate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}

				if (getFiltersObj.whichStatusToConsider === Company360DashboardOrderValEnum.STATUS_OPEN) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Open'] };
					filterValue.push(docDateObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardOrderValEnum.STATUS_CLOSED) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Close'] };
					filterValue.push(docDateObj);
				}
				this._SalesDataSharingService.setStoredFilters(filterValue);
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.ORDERS);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.ORDERS);
				this._Router.navigate(['/sales/orders-list']);
				break;
			case 'INVOICES':
				filterValue = [];
				getFiltersObj = this.getInvoiceFiltersForTiles('invoice_tile');
				if (getFiltersObj.whichDateToUse === Company360DashboardInvoiceValEnum.DATE_DOC_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docdate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichDateToUse === Company360DashboardInvoiceValEnum.DATE_DUE_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docduedate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichDateToUse === Company360DashboardInvoiceValEnum.DATE_DATE_PAID) {
					const documentStatusObj = { dataType: 'date', dataField: 'doc_close_date', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardInvoiceValEnum.STATUS_OPEN) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Open'] };
					filterValue.push(docDateObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardInvoiceValEnum.STATUS_CLOSED) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Close'] };
					filterValue.push(docDateObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardInvoiceValEnum.STATUS_CANCEL) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Cancel'] };
					filterValue.push(docDateObj);
				}
				this._SalesDataSharingService.setStoredFilters(filterValue);
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.SALESINVOICES);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.SALESINVOICES);
				this._Router.navigate(['/sales/invoices-list']);
				break;
			case 'COMMISSION':
				this._Router.navigate(['/contracts/commissionlist']);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.COMMISSION);
				break;
			case 'REBATE':
				this._Router.navigate(['/contracts/rebateslist']);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.REBATES);
				break;
			case 'ROYALTY':
				this._Router.navigate(['/contracts/royaltieslist']);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.ROYALTIES);
				break;
			case 'AR_CREDITMEMO':
				filterValue = [];
				getFiltersObj = this.getArCreditmemoFiltersForTiles('ar_creditmemo_tile');
				if (getFiltersObj.whichDateToUse === Company360DashboardArCreditmemoValEnum.DATE_DOC_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docdate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichDateToUse === Company360DashboardArCreditmemoValEnum.DATE_DUE_DATE) {
					const documentStatusObj = { dataType: 'date', dataField: 'docduedate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichDateToUse === Company360DashboardArCreditmemoValEnum.DATE_DATE_PAID) {
					const documentStatusObj = { dataType: 'date', dataField: 'doc_close_date', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
					filterValue.push(documentStatusObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_DRAFT) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['draft'] };
					filterValue.push(docDateObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_OPEN) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Open'] };
					filterValue.push(docDateObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_CLOSED) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Close'] };
					filterValue.push(docDateObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_CANCEL) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Cancel'] };
					filterValue.push(docDateObj);
				}
				if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_CANCELLATION) {
					const docDateObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['cancellation'] };
					filterValue.push(docDateObj);
				}

				if (getFiltersObj.whichType === Company360DashboardArCreditmemoValEnum.TYPE_INVENTORY) {
					const documentTypeObj = { dataType: 'string', dataField: 'doctype', selectedFilterOperation: 'equals', filterValues: ['dDocument_Items'] };
					filterValue.push(documentTypeObj);
				}
				if (getFiltersObj.whichType === Company360DashboardArCreditmemoValEnum.TYPE_SERVICE) {
					const documentTypeObj = { dataType: 'string', dataField: 'doctype', selectedFilterOperation: 'equals', filterValues: ['dDocument_Service'] };
					filterValue.push(documentTypeObj);
				}
				this._SalesDataSharingService.setStoredFilters(filterValue);
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.AR_CREDIT_MEMO);
				this._SidemenuService.setCurrentMenu(SideMenuForEnum.AR_CREDIT_MEMOS_LIST);
				this._Router.navigate(['/sales/ar-credit-memos-list']);
				break;
			default:
				break;

		}
	}

	// get next month name
	public getCurrentMonthName() {
		let server_date, d;
		const monthNames = ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"
		];
		if (this._LoginService.loginUser && this._LoginService.loginUser.server_date) {
			server_date = this._LoginService.loginUser.server_date;
			if (server_date) {
				d = new Date(server_date);
			}
		}
		return monthNames[d.getMonth()] || '';
	}

	public getQuoteFiltersForTiles(tileCode: string) {
		const serverDate = this._LoginService.loginUser.server_date;
		let showDataFrom = DateRangeGlobalSettingValEnum.CURRENT_MONTH;
		let whichStatusToConsider = Company360DashboardOrderValEnum.STATUS_ALL;
		let whichDateToUse = Company360DashboardOrderValEnum.DATE_DOC_DATE;
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === tileCode);
				if (configIndex !== -1) {
					const orderConfig = dashboardSettings.configs[configIndex];
					if (orderConfig && orderConfig.show_data_from) {
						showDataFrom = orderConfig.show_data_from;
					}
					if (orderConfig && orderConfig.which_status_to_consider) {
						whichStatusToConsider = orderConfig.which_status_to_consider;
					}
					if (orderConfig && orderConfig.which_status_to_consider) {
						whichStatusToConsider = orderConfig.which_status_to_consider;
					}
					if (orderConfig && orderConfig.which_date_to_use) {
						whichDateToUse = orderConfig.which_date_to_use;
					}
				}
			}
		}

		let fromDate = moment(serverDate).startOf('month');
		let toDate = moment(serverDate).endOf('month');
		let startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
		let endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
		const isGlobalSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (isGlobalSettingsExists) {
			const globalSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
			if (globalSettings.date_range && Object.keys(globalSettings.date_range).length > 0) {
				const dateRangeConfig = globalSettings.date_range;
				if (dateRangeConfig && dateRangeConfig.show_data_from) {
					showDataFrom = dateRangeConfig.show_data_from;
				}

				if (showDataFrom === DateRangeGlobalSettingValEnum.CURRENT_MONTH) {
					fromDate = moment(serverDate).startOf('month');
					toDate = moment(serverDate).endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.LAST_MONTH) {
					fromDate = moment(serverDate).subtract(1, 'months').startOf('month');
					toDate = moment(serverDate).subtract(1, 'months').endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CALENDAR_YEAR_TO_DATE) {
					if ((moment(serverDate).get('month') + 1) === 1) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						fromDate = moment(serverDate).set('month', 0).startOf('month');
						toDate = moment(serverDate).endOf('month');
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.FISCAL_YEAR_TO_DATE) {
					let fiscalYearStart = 1;
					try {
						fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
					} catch (e) {
						fiscalYearStart = 1;
					}
					if ((moment(serverDate).get('month') + 1) === fiscalYearStart) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						if ((moment(serverDate).get('month') + 1) > fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
						if ((moment(serverDate).get('month') + 1) < fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).subtract(1, 'year').startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
					fromDate = moment(dateRangeConfig.custom_start_date);
					toDate = moment(dateRangeConfig.custom_end_date);
				}
				startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
				endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
			}
		}

		return {
			fromDate: startFromDate,
			toDate: endToDate,
			whichStatusToConsider: whichStatusToConsider,
			whichDateToUse: whichDateToUse
		}
	}

	public getOrderFiltersForTiles(tileCode: string) {
		const serverDate = this._LoginService.loginUser.server_date;
		let showDataFrom = DateRangeGlobalSettingValEnum.CURRENT_MONTH;
		let whichStatusToConsider = Company360DashboardOrderValEnum.STATUS_ALL;
		let whichDateToUse = Company360DashboardOrderValEnum.DATE_DOC_DATE;
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === tileCode);
				if (configIndex !== -1) {
					const orderConfig = dashboardSettings.configs[configIndex];
					if (orderConfig && orderConfig.show_data_from) {
						showDataFrom = orderConfig.show_data_from;
					}
					if (orderConfig && orderConfig.which_status_to_consider) {
						whichStatusToConsider = orderConfig.which_status_to_consider;
					}

					if (orderConfig && orderConfig.which_date_to_use) {
						whichDateToUse = orderConfig.which_date_to_use;
					}
				}
			}
		}

		let fromDate = moment(serverDate).startOf('month');
		let toDate = moment(serverDate).endOf('month');
		let startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
		let endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
		const isGlobalSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (isGlobalSettingsExists) {
			const globalSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
			if (globalSettings.date_range && Object.keys(globalSettings.date_range).length > 0) {
				const dateRangeConfig = globalSettings.date_range;
				if (dateRangeConfig && dateRangeConfig.show_data_from) {
					showDataFrom = dateRangeConfig.show_data_from;
				}

				if (showDataFrom === DateRangeGlobalSettingValEnum.CURRENT_MONTH) {
					fromDate = moment(serverDate).startOf('month');
					toDate = moment(serverDate).endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.LAST_MONTH) {
					fromDate = moment(serverDate).subtract(1, 'months').startOf('month');
					toDate = moment(serverDate).subtract(1, 'months').endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CALENDAR_YEAR_TO_DATE) {
					if ((moment(serverDate).get('month') + 1) === 1) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						fromDate = moment(serverDate).set('month', 0).startOf('month');
						toDate = moment(serverDate).endOf('month');
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.FISCAL_YEAR_TO_DATE) {
					let fiscalYearStart = 1;
					try {
						fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
					} catch (e) {
						fiscalYearStart = 1;
					}
					if ((moment(serverDate).get('month') + 1) === fiscalYearStart) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						if ((moment(serverDate).get('month') + 1) > fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
						if ((moment(serverDate).get('month') + 1) < fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).subtract(1, 'year').startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
					fromDate = moment(dateRangeConfig.custom_start_date);
					toDate = moment(dateRangeConfig.custom_end_date);
				}
				startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
				endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
			}
		}

		return {
			fromDate: startFromDate,
			toDate: endToDate,
			whichStatusToConsider: whichStatusToConsider,
			whichDateToUse: whichDateToUse
		}
	}

	public getInvoiceFiltersForTiles(tileCode: string) {
		const serverDate = this._LoginService.loginUser.server_date;
		let showDataFrom = DateRangeGlobalSettingValEnum.CURRENT_MONTH;
		let whichStatusToConsider = Company360DashboardInvoiceValEnum.STATUS_ALL;
		let whichDateToUse = Company360DashboardInvoiceValEnum.DATE_DOC_DATE;
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === tileCode);
				if (configIndex !== -1) {
					const orderConfig = dashboardSettings.configs[configIndex];
					// if (orderConfig && orderConfig.show_data_from) {
					// 	showDataFrom = orderConfig.show_data_from;
					// }
					if (orderConfig && orderConfig.which_status_to_consider) {
						whichStatusToConsider = orderConfig.which_status_to_consider;
					}

					if (orderConfig && orderConfig.which_date_to_use) {
						whichDateToUse = orderConfig.which_date_to_use;
					}
				}
			}
		}

		let fromDate = moment(serverDate).startOf('month');
		let toDate = moment(serverDate).endOf('month');
		let startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
		let endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
		const isGlobalSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (isGlobalSettingsExists) {
			const globalSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
			if (globalSettings.date_range && Object.keys(globalSettings.date_range).length > 0) {
				const dateRangeConfig = globalSettings.date_range;
				if (dateRangeConfig && dateRangeConfig.show_data_from) {
					showDataFrom = dateRangeConfig.show_data_from;
				}

				if (showDataFrom === DateRangeGlobalSettingValEnum.CURRENT_MONTH) {
					fromDate = moment(serverDate).startOf('month');
					toDate = moment(serverDate).endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.LAST_MONTH) {
					fromDate = moment(serverDate).subtract(1, 'months').startOf('month');
					toDate = moment(serverDate).subtract(1, 'months').endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CALENDAR_YEAR_TO_DATE) {
					if ((moment(serverDate).get('month') + 1) === 1) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						fromDate = moment(serverDate).set('month', 0).startOf('month');
						toDate = moment(serverDate).endOf('month');
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.FISCAL_YEAR_TO_DATE) {
					let fiscalYearStart = 1;
					try {
						fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
					} catch (e) {
						fiscalYearStart = 1;
					}
					if ((moment(serverDate).get('month') + 1) === fiscalYearStart) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						if ((moment(serverDate).get('month') + 1) > fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
						if ((moment(serverDate).get('month') + 1) < fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).subtract(1, 'year').startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
					fromDate = moment(dateRangeConfig.custom_start_date);
					toDate = moment(dateRangeConfig.custom_end_date);
				}

				startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
				endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
			}
		}
		return {
			fromDate: startFromDate,
			toDate: endToDate,
			whichStatusToConsider: whichStatusToConsider,
			whichDateToUse: whichDateToUse
		}
	}

	public getArCreditmemoFiltersForTiles(tileCode: string) {
		const serverDate = this._LoginService.loginUser.server_date;
		let showDataFrom = DateRangeGlobalSettingValEnum.CURRENT_MONTH;
		let whichStatusToConsider = Company360DashboardArCreditmemoValEnum.STATUS_ALL;
		let whichDateToUse = Company360DashboardArCreditmemoValEnum.DATE_DOC_DATE;
		let whichType = Company360DashboardArCreditmemoValEnum.TYPE_ALL;
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === tileCode);
				if (configIndex !== -1) {
					const orderConfig = dashboardSettings.configs[configIndex];
					if (orderConfig && orderConfig.show_data_from) {
						showDataFrom = orderConfig.show_data_from;
					}
					if (orderConfig && orderConfig.which_status_to_consider) {
						whichStatusToConsider = orderConfig.which_status_to_consider;
					}

					if (orderConfig && orderConfig.which_date_to_use) {
						whichDateToUse = orderConfig.which_date_to_use;
					}
					if (orderConfig && orderConfig.which_type) {
						whichType = orderConfig.which_type;
					}
				}
			}
		}

		let fromDate = moment(serverDate).startOf('month');
		let toDate = moment(serverDate).endOf('month');
		let startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
		let endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
		const isGlobalSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (isGlobalSettingsExists) {
			const globalSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
			if (globalSettings.date_range && Object.keys(globalSettings.date_range).length > 0) {
				const dateRangeConfig = globalSettings.date_range;
				if (dateRangeConfig && dateRangeConfig.show_data_from) {
					showDataFrom = dateRangeConfig.show_data_from;
				}

				if (showDataFrom === DateRangeGlobalSettingValEnum.CURRENT_MONTH) {
					fromDate = moment(serverDate).startOf('month');
					toDate = moment(serverDate).endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.LAST_MONTH) {
					fromDate = moment(serverDate).subtract(1, 'months').startOf('month');
					toDate = moment(serverDate).subtract(1, 'months').endOf('month');
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CALENDAR_YEAR_TO_DATE) {
					if ((moment(serverDate).get('month') + 1) === 1) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						fromDate = moment(serverDate).set('month', 0).startOf('month');
						toDate = moment(serverDate).endOf('month');
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.FISCAL_YEAR_TO_DATE) {
					let fiscalYearStart = 1;
					try {
						fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
					} catch (e) {
						fiscalYearStart = 1;
					}
					if ((moment(serverDate).get('month') + 1) === fiscalYearStart) {
						fromDate = moment(serverDate).startOf('month');
						toDate = moment(serverDate).endOf('month');
					} else {
						if ((moment(serverDate).get('month') + 1) > fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
						if ((moment(serverDate).get('month') + 1) < fiscalYearStart) {
							fromDate = moment(serverDate).set('month', fiscalYearStart - 1).subtract(1, 'year').startOf('month');
							toDate = moment(serverDate).endOf('month');
						}
					}
				}
				if (showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
					fromDate = moment(dateRangeConfig.custom_start_date);
					toDate = moment(dateRangeConfig.custom_end_date);
				}

				startFromDate = fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
				endToDate = toDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
			}
		}

		return {
			fromDate: startFromDate,
			toDate: endToDate,
			whichStatusToConsider: whichStatusToConsider,
			whichDateToUse: whichDateToUse,
			whichType: whichType
		}
	}

	public getListOfDateRangeShowDataFrom() {
		const listOfShowDataFrom = [
			{
				value: DateRangeGlobalSettingValEnum.CURRENT_MONTH,
				text: DateRangeGlobalSettingLblEnum.CURRENT_MONTH
			},
			/*
			{
				value: DateRangeGlobalSettingValEnum.LAST_MONTH,
				text: DateRangeGlobalSettingLblEnum.LAST_MONTH
			},
			{
				value: DateRangeGlobalSettingValEnum.CALENDAR_YEAR_TO_DATE,
				text: DateRangeGlobalSettingLblEnum.CALENDAR_YEAR_TO_DATE
			},
			*/
			{
				value: DateRangeGlobalSettingValEnum.FISCAL_YEAR_TO_DATE,
				text: DateRangeGlobalSettingLblEnum.FISCAL_YEAR_TO_DATE
			},
			{
				value: DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE,
				text: DateRangeGlobalSettingLblEnum.CUSTOM_DATE_RANGE
			}
		];
		return listOfShowDataFrom;
	}
}
