import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { ProductServiceService } from '@app/services/product-service/product-service.service';
import { environment } from "@environments/environment";
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { PAGE_SIZE_OPTIONS, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { NgxPermissionsService } from 'ngx-permissions';
import { PageOptions } from '@app/models/common.model';
import { BusinessPlanDataSharingService } from '@app/services/business-plan-service/business-plan-data-sharing.service';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';

@Component({
	selector: 'app-pricelist-end-users-listing',
	templateUrl: './pricelist-end-users-listing.component.html',
	styleUrls: ['./pricelist-end-users-listing.component.css'],
	providers: []
})
export class PricelistEndUsersListingComponent implements OnInit, OnChanges, OnDestroy {
	@Input() priceListDetailsIP: any;
	@Input() refreshEndUsersListIP: any;
	private pricelistDetailsObj: any;

	public dxDataSource: any;
	public listOfActions: any[];
	public filterValue: any[] = [];
	public storedFiltersValue: any = [];
	public pageOptions: PageOptions = new PageOptions();
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _BusinessPlanDataSharingService: BusinessPlanDataSharingService,
		private _ProductServiceService: ProductServiceService,
		private _ToastrService: ToastrService) {
	}

	ngOnInit() {

	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['priceListDetailsIP']) {
			if (_SimpleChanges.priceListDetailsIP.currentValue) {
				this.pricelistDetailsObj = _SimpleChanges.priceListDetailsIP.currentValue;
				this.preInIt();
			}
		}
		if (_SimpleChanges['refreshEndUsersListIP']) {
			if (_SimpleChanges.refreshEndUsersListIP.currentValue) {
				this.refreshEndUsersListIP.instance.refresh();
			}
		}
	}

	ngOnDestroy(): void {

	}

	private preInIt() {
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
		this.listOfActions = this._BusinessPlanDataSharingService.getListOfActionsForEndUsers();
		this.storedFiltersValue = [
			{ dataType: 'string', dataField: 'pricelistnum', searchMode: '=', filterValues: [this.pricelistDetailsObj.pricelistno] },
		];
		this.applyStoredFilter();
		this.fetchEndUsersList();
	}


	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						if (sf.filterValues) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
						}
					}
				});
			}, 75);
		}

	}

	private fetchEndUsersList() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			entity: ServerEntity.ENDUSERS,
			method: ServerMethods.DX_GET_ENTITY_DATA,
		};
		this.dxDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public doClearFilterBuilder(event) {
		this.dataGridContainer.instance.clearFilter();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		if (this.dataGridContainer.filterValue && this.dataGridContainer.filterValue.length > 0) {
			this.dataGridContainer.filterPanel.customizeText = (e: any) => {
				this.filterText = e.text;
			};
			this.isDisabledClearBtn = false;
		} else {
			this.filterText = '';
			this.isDisabledClearBtn = true;
		}
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
				visible: false
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: false,
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: false,
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			}
		);
		let addRowButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (addRowButton) {
			addRowButton.visible = false;
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].visible = false;
		}
	}

	// Method used to Refresh DataGrid
	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Vendor,Buying Groups,End Users
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Price-List-End-Users.xlsx';
		worksheet = workbook.addWorksheet('Price-List-End-Users');
		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

}