<div class="borderBox padT20 padB20">
	<div class="row">
		<div class="cols8">
			<h4 class="textandlableix line-height-normal mrgB10"><span class="textlabelix">Commission Report:</span>
				{{commissionRptDetails.contractName}}</h4>
			<p class="contTx mrgB0"><span class="lbl">Sales Rep:</span><span class="black">
					{{commissionRptDetails.salesRep}}</span></p>
			<p class="contTx mrgB0"><span class="lbl">Settlement Period:</span><span class="black">
					{{commissionRptDetails.settlementPeriodStartDate | date: 'yyyy-MM-dd'}} to
					{{commissionRptDetails.settlementPeriodEndDate | date: 'yyyy-MM-dd'}}</span></p>
		</div>
		<div class="cols4 d-flex">
			<div class="flex-grow-1">
				<p class="contTx mrgB10 d-block txtRight mrgR0">
					<span class="lbl">Status</span><span class="inStatusLabel mrgL10"
						[ngClass]="commissionSatusUIClass">{{commissionRptDetails.apInvoiceCreatedInERP}}</span>
				</p>
				<p class="contTx mrgB0 d-block txtRight mrgR0">
					<span class="lbl">Generated:</span><span class="black"> {{commissionRptDetails.created_date | date:
						'yyyy-MM-dd'}}</span>
				</p>
			</div>
			<!-- <button class="mrgL30">Action</button> -->
			<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
				text="Action" class="mrgL30 secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx btnHeightBig"
				[items]="listOfActions" [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
				keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
				(onItemClick)="doActionsBtnItemClick($event)" [dropDownOptions]="{ width: 270 }">
			</dx-drop-down-button>
		</div>
	</div>
	<hr class="hrborderix mrgT10 mrgB15" />
	<div class="d-flex d-flex-center">
		<div class="flex-grow-1">
			<div class="padR30 alignLeft" *ngFor="let item of commissionRptTabs; let  i = $index">
				<span class="labelTextIx d-block line-height-normal">{{item.title}}</span>
				<span class="textlabelix">
					<dx-load-indicator height="25" width="25" class="button-indicator"
						[elementAttr]="{ class: 'button-loader-indicator-blue' }"
						[visible]="isShowLoaderIndicator"></dx-load-indicator>
					<span class="black semiBold" *ngIf="!isShowLoaderIndicator">{{item.value|| 0
						|customCurrencyPipe:customCurrencyOption}}</span>
				</span>
			</div>
		</div>
		<div class="greenboxix">
			<div class="greenboxinnerix padR30">
				<span class="labelTextIx d-block line-height-normal">Total Commission Payable</span>
				<span class="textlabelix black semiBold">{{commissionRptDetails.totalCommissionPayable || 0 |
					customCurrencyPipe:customCurrencyOption }}</span>
			</div>
			<div class="greenboxinnerix">
				<span class="labelTextIx d-block line-height-normal">Total Commission Generated</span>
				<span class="textlabelix black semiBold">{{commissionRptDetails.totalCommissionGenerated || 0 |
					customCurrencyPipe:customCurrencyOption }}</span>
			</div>
		</div>
	</div>
</div>

<div class="borderBox padT10 padB20">
	<dx-tab-panel id="tabPanel" [deferRendering]="false" (onOptionChanged)="doOnTabOptionChanged($event)"
		(onSelectionChanged)="doSelectionChanged($event)" [dataSource]="commissionRptTabs">
		<div *dxTemplate="let tabPanelItem of 'item'">
			<div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.INVOICES" class="pad5">
				<app-cts-invoice-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
					[commissionRptDetails]="commissionRptDetails"></app-cts-invoice-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.ORDERS" class="pad5">
				<app-cts-order-rpt-tab [commissionRptDetails]="commissionRptDetails"
					[viewPopUpModelProps]="viewPopUpModelProps"></app-cts-order-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.CHANGE_ORDERS" class="pad5">
				<app-cts-change-order-rpt-tab [commissionRptDetails]="commissionRptDetails"
					[viewPopUpModelProps]="viewPopUpModelProps"></app-cts-change-order-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.MODIFIED_INVOICES" class="pad5">
				<app-cts-modified-invoice-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
					[commissionRptDetails]="commissionRptDetails"></app-cts-modified-invoice-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS" class="pad5">
				<app-cts-amortized-payment-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
					[commissionRptDetails]="commissionRptDetails"></app-cts-amortized-payment-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES"
				class="pad5">
				<app-cts-extra-comm-basedrule-rpt-tab
					[commissionRptDetails]="commissionRptDetails"></app-cts-extra-comm-basedrule-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS" class="pad5">
				<app-cts-service-credit-memo-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
					[commissionRptDetails]="commissionRptDetails"></app-cts-service-credit-memo-rpt-tab>
			</div>
			<!-- <div *ngIf="tabPanelItem.key === CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES">
				<app-cts-misc-deductrule-rpt-tab
					[commissionRptDetails]="commissionRptDetails"></app-cts-misc-deductrule-rpt-tab>
			</div> -->
		</div>
	</dx-tab-panel>


	<!-- <div class="tabbing cf mrgT0">
		<ul>
			<li *ngFor="let tab of commissionRptTabs" (click)="clickRptTab(tab.key)"
				[ngClass]="{active: currentRptTab == tab.key}">
				<a>{{tab.name}}</a>
			</li>
		</ul>
	</div>
	<div *ngIf="currentRptTab === CommissionSettlementReportSectionEnum.INVOICES">
		<app-cts-invoice-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
			[commissionRptDetails]="commissionRptDetails"></app-cts-invoice-rpt-tab>
	</div>
	<div *ngIf="currentRptTab === CommissionSettlementReportSectionEnum.ORDERS">
		<app-cts-order-rpt-tab [commissionRptDetails]="commissionRptDetails"
			[viewPopUpModelProps]="viewPopUpModelProps"></app-cts-order-rpt-tab>
	</div>
	<div *ngIf="currentRptTab === CommissionSettlementReportSectionEnum.CHANGE_ORDERS">
		<app-cts-change-order-rpt-tab [commissionRptDetails]="commissionRptDetails"
			[viewPopUpModelProps]="viewPopUpModelProps"></app-cts-change-order-rpt-tab>
	</div>
	<div *ngIf=" currentRptTab === CommissionSettlementReportSectionEnum.MODIFIED_INVOICES">
		<app-cts-modified-invoice-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
			[commissionRptDetails]="commissionRptDetails"></app-cts-modified-invoice-rpt-tab>
	</div>
	<div *ngIf="currentRptTab === CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS">
		<app-cts-amortized-payment-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
			[commissionRptDetails]="commissionRptDetails"></app-cts-amortized-payment-rpt-tab>
	</div>
	<div *ngIf=" currentRptTab === CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES">
		<app-cts-extra-comm-basedrule-rpt-tab
			[commissionRptDetails]="commissionRptDetails"></app-cts-extra-comm-basedrule-rpt-tab>
	</div>
	<div *ngIf=" currentRptTab===CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS">
		<app-cts-service-credit-memo-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
		[commissionRptDetails]="commissionRptDetails"></app-cts-service-credit-memo-rpt-tab>
	</div> -->
	<!-- <div *ngIf="currentRptTab === CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES">
		<app-cts-misc-deductrule-rpt-tab [commissionRptDetails]="commissionRptDetails"></app-cts-misc-deductrule-rpt-tab>
	</div> -->
</div>


<!-- <dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width80:'X'" height="680" [showTitle]="true"
	[dragEnabled]="true" [hideOnOutsideClick]="false" title="Export PDF" [showCloseButton]="true"
	[(visible)]="isShowPopupDatagridExport" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'" class="">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<app-incentives-export-pdf *ngIf="isShowPopupDatagridExport" [listOfColumnsProps]="listOfMasterDatagrid"
				(exportPdfEmitter)="doExportPdfEmitting($event)"></app-incentives-export-pdf>
		</dx-scroll-view>
	</div>
</dx-popup> -->

<app-view-invoice-detail-popup
	*ngIf="viewPopUpModelProps.detailView === 'invoice' || viewPopUpModelProps.detailView === 'modification_invoice'"
	[viewInvoiceDetailProps]="viewPopUpModelProps">
</app-view-invoice-detail-popup>

<app-view-order-changeorder-detail-popup
	*ngIf="viewPopUpModelProps.detailView === 'order'|| viewPopUpModelProps.detailView === 'change_order'"
	[viewOrderChangeOrderDetailProps]="viewPopUpModelProps">
</app-view-order-changeorder-detail-popup>

<app-view-craditmemo-detail-popup *ngIf="viewPopUpModelProps.detailView === 'creditmemo'"
	[viewCreditMemoDetailProps]="viewPopUpModelProps"></app-view-craditmemo-detail-popup>