<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false">
	<div id="dateRangeSetting" class="row">
		<div class="cols12">
			<!-- <div class="srTitle">
				<label>Show Data From</label>
			</div> -->
			<div class="feildCol radioGroup mrgT0 padT0">
				<div class="inlineBlock">
					<div class="radio">
						<dx-radio-group [dataSource]="listOfShowDataFrom" (onValueChanged)="doChangeShowDataFrom($event)"
							valueChangeEvent="change" displayExpr="text" valueExpr="value"
							[(value)]="userConfigsUI.show_data_from" layout="vertical">
						</dx-radio-group>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="userConfigsUI.show_data_from === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE" class="cols12">
			<div class="dx-field dateiconset mrgT10 mrgB10">
				<div class="dx-field-label">Start Date</div>
				<div class="dx-field-value">
					<!-- (onValueChanged)="doStartDateChanged($event)"
							valueChangeEvent="change" -->
					<dx-date-box [(value)]="userConfigsUI.startDate" [min]="minDate" displayFormat="yyyy-MM-dd" type="date"
						[showClearButton]="true">
					</dx-date-box>
				</div>
			</div>
		</div>
		<div *ngIf="userConfigsUI.show_data_from === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE" class="cols12">
			<div class="dx-field dateiconset mrgT10 mrgB10">
				<div class="dx-field-label">End Date</div>
				<div class="dx-field-value">
					<!--(onValueChanged)="doEndDateChanged($event)"
							 valueChangeEvent="change" -->
					<dx-date-box [(value)]="userConfigsUI.endDate" [min]="minDate" displayFormat="yyyy-MM-dd" type="date"
						[showClearButton]="true">
					</dx-date-box>
				</div>
			</div>
		</div>
		<div *ngIf="isShowConfimBtn" class="cols12">
			<div class="">
				<dx-button (onClick)="doConfirmedSettings($event)" class="button dxSubmitBtn" id="button" text="Confirm"
					type="normal" stylingMode="contained" [useSubmitBehavior]="false">
				</dx-button>
			</div>
		</div>
	</div>
</dx-scroll-view>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.0)" [position]="{ of: '#dateRangeSetting' }"
	[(visible)]="isShowLoadPanel" [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false"
	class="zIndexDxLoadPanel">
</dx-load-panel>