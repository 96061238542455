<div class="row equalHight">
	<div class="cols12">
		<div class="borderBox chartAreaBox quarterlyReportBox cf">
			<!-- <div class="chartTopRow">
					<div class="subTitleRow">
						<h2>Last 12 Months Report</h2>
					</div>
				</div> -->
			<div class="chartArea">
				<div class="row mrgB20">
					<!-- <div class="cols2 pull-left">
						<dx-button class="button-container" text="Back" icon="chevronleft" [visible]="levelCount !== 0"
							(onClick)="onButtonClick()">
						</dx-button>
					</div> -->
					<div class="cols12">
						<!-- <div class="dx-field alignRight">
								<div class="dx-field-label">Report Type</div>
								<div class="dx-field-value">
									<dx-select-box #dxselectboxReportType [dataSource]="chartViewTypeList" value="orders"
										displayExpr="Value" valueExpr="Key" (valueChange)="selectChanged($event)">
									</dx-select-box>
								</div>
							</div> -->
						<div class="cf"></div>
						<div class="dx-field txtcenter">
							<h2 class="chartTitle">Sales Performance</h2>
						</div>
					</div>
				</div>

				<div class="posRelative">
					<dx-chart id="chart" class="ix-chart-sales-performance" title="" [dataSource]="dataSource">
						<dxi-series valueField="orderTotal" [color]="'#21b3c6'" name="Order By: {{orderByLbl}}"></dxi-series>
						<dxi-series valueField="invoiceTotal" [color]="'#706cce'" name="Invoice By: {{invoiceByLbl}}">
						</dxi-series>
						<dxo-common-series-settings argumentField="monthYear" type="bar" hoverMode="allArgumentPoints"
							selectionMode="allArgumentPoints"></dxo-common-series-settings>
						<!-- <dxo-label [visible]="true"></dxo-label> -->
						<dxo-legend [visible]="true" verticalAlignment="top" horizontalAlignment="center" [font]="{size:16}"
							itemTextPosition="right" [customizeText]="doCustomLegend">
						</dxo-legend>
						<dxo-tooltip title="" [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip">
						</dxo-tooltip>
					</dx-chart>
					<app-ix-load-panel *ngIf="isVisibleChartLoader"></app-ix-load-panel>
				</div>
			</div>
		</div>
	</div>