<dx-popup [width]="addEditPopupWidth | heightwidthRatio : 30:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 42:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitleText" [showCloseButton]="true" (onHidden)="doHiddenPopup($event)"
	[(visible)]="isShowPopup" [wrapperAttr]="{ class: 'popupToolbarPadding anCustomer' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'" class="anCustomer">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<p class="popupDescriptionix">Please enter your Email ID to reset your password. </p>
			<form autocomplete="off">
				<div class="row">
					<!-- 
					<div class="cols12 mrgB10">
						<div class="dx-field">
							<div class="dx-field-label">Email</div>
							<div class="dx-field-value">
								<dx-text-box #emailTextBoxRef mode="email" name="emailID" id="emailID" [(value)]="emailID"
									(onInitialized)="doInitializedEmailTextBox($event)" [maxLength]="100" [showClearButton]="true">
								</dx-text-box>
							</div>
						</div>
					</div> 
					-->
					<div class="cols12">
						<div class="feildCol">
							<input [(ngModel)]="emailID" class="inputLeftBorderCutix" name="emailID" type="text" id="emailID"
								maxlength="100">
							<div class="inputField">
								<label for="emailID">Email</label>
							</div>
						</div>
					</div>
				</div>
			</form>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="doSendEmail()" class="primaryBtn button resetButtonTextix" type="normal"
				stylingMode="contained" [useSubmitBehavior]="false" text="Reset">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>