import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
	selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

	constructor(private _el: ElementRef) { }

	@HostListener('input', ['$event']) onInputChange(event) {
		const initalValue = this._el.nativeElement.value;
		this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
		if (initalValue !== this._el.nativeElement.value) {
			event.stopPropagation();
		}
	}
}

@Directive({
	selector: '[decimalNumbersOnly]'
})
export class DecimalNumbersOnlyDirective {

	constructor(private _el: ElementRef) { }

	@HostListener('input', ['$event']) onInputChange(event) {
		const initalValue = this._el.nativeElement.value;
		this._el.nativeElement.value = initalValue.replace(/[^0-9\.]/g, '');
		if (this._el.nativeElement.value.split('.').length > 2) {
			this._el.nativeElement.value = this._el.nativeElement.value.replace(/\./g, '');
		}
		if (initalValue !== this._el.nativeElement.value) {
			event.stopPropagation();
		}
	}
}

@Directive({
	selector: '[alphaNumericsOnly]'
})
export class AlphaNumericsOnlyDirective {

	constructor(private _el: ElementRef) { }

	@HostListener('input', ['$event']) onInputChange(event) {
		const initalValue = this._el.nativeElement.value;
		this._el.nativeElement.value = initalValue.replace(/[^0-9A-Za-z]/g, '');
		if (initalValue !== this._el.nativeElement.value) {
			event.stopPropagation();
		}
	}
}
