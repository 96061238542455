<div class="rightBar">
	<div class="rightBlock">
		<div class="borderBox cf" height="calc(100vh - 130px);">
			<div *ngIf="invoiceDetails && invoiceDetails.guid" class="row">
				<div class="cols8">
					<ul class="listText">
						<li>Contract Name:
							<strong class="purple">{{invoiceDetails.contractName || ''}}</strong>
						</li>
					</ul>
				</div>
				<div *ngIf="isShowApprovalTrailsBtn" class="cols4">
					<div class="alignRight mrgB10">
						<dx-button text="Approval Trails" type="normal" stylingmode="contained" class="secondaryBtn"
							(onClick)="doViewApprovalTrails($event)">
						</dx-button>
					</div>
				</div>
			</div>

			<dx-data-grid #itemsInvoicesDataSourceRef id="gridContainer" [dataSource]="itemsInvoicesDataSource"
				(onRowClick)="doRedirecToReport($event.data)" [remoteOperations]="true" [showBorders]="true"
				[allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'" [height]="'calc(-450px + 100vh)'">
				<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
				<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>
				<dxo-filter-row [visible]="true"></dxo-filter-row>

				<dxi-column dataField="name" dataType="string" caption="Buying Group / Member"
					[allowHeaderFiltering]="false" cellTemplate="cellTemplateName">
					<div *dxTemplate="let element of 'cellTemplateName'">
						{{ element.data.name || '' }}
					</div>
				</dxi-column>

				<dxi-column dataField="type" dataType="string" caption="Type" [allowHeaderFiltering]="true"
					cellTemplate="cellTemplateType">
					<dxo-header-filter [dataSource]="listOfBuyyinggroupMemberTypeHeaderFilter">
					</dxo-header-filter>
					<div *dxTemplate="let element of 'cellTemplateType'">
						<span *ngIf="element.data.type">
							{{element.data.type | filterFromList : listOfBuyyinggroupMemberTypeHeaderFilter : 'value' :
							'text'}}
						</span>
					</div>
				</dxi-column>

				<dxi-column dataField="split_percentage" caption="Split (%)" dataType="number" alignment="right"
					[allowFiltering]="false" [allowHeaderFiltering]="false" cellTemplate="cellTemplateSplitPercentage">
					<div *dxTemplate="let element of 'cellTemplateSplitPercentage'">
						<span *ngIf="element.data.split_percentage">
							{{element.data.split_percentage | number:decimalPointPercentageFormat}}%
						</span>
					</div>
				</dxi-column>

				<dxi-column dataField="commissionAmount" [caption]="'Rebate Amount ('+globalCurrencySymbol+')'"
					dataType="number" [allowHeaderFiltering]="false" cellTemplate="cellTemplateRebatesAmount">
					<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format> -->
					<div *dxTemplate="let element of 'cellTemplateRebatesAmount'">
						{{element.data.commissionAmount || 0 | customCurrencyPipe:customCurrencyOption }}
					</div>
				</dxi-column>

				<dxi-column dataField="cardcode" caption="Action" cellTemplate="cellTemplateAction" [allowFiltering]="false"
					[allowHeaderFiltering]="false" [allowSorting]="false" alignment="center">
					<div *dxTemplate="let element of 'cellTemplateAction'">
						<a (click)="doRedirecToReport(element.data)" class="highlight">View Report</a>
					</div>
				</dxi-column>

			</dx-data-grid>

			<div class="row" *ngIf="isShowDropDownButton && invoiceDetails && invoiceDetails.guid">
				<div class="cols9" *ngIf="selectedKeyDropDownBtn === RebateApprovalStatusValEnum.REJECTED">
					<div class="dx-field fieldCol mrgT20 mrgB20 remarkBx">
						<div class="dx-field-label">Remark</div>
						<div class="dx-field-value">
							<dx-text-area [height]="60" [maxLength]="256" [(value)]="remark" name="remark">
							</dx-text-area>
						</div>
					</div>
				</div>
				<div *ngIf="enable_rebate_settlement_approval_workflow" class="cols3 alignRight"
					[ngClass]="{'cols12': !selectedKeyDropDownBtn === RebateApprovalStatusValEnum.REJECTED,'cols3':selectedKeyDropDownBtn === RebateApprovalStatusValEnum.REJECTED}">
					<div class="right alignRight mrgT20">
						<dx-drop-down-button [dropDownOptions]="{ width: 250 }" [splitButton]="true"
							[showArrowIcon]="isShowArrowIconDrpDwnBtn" [useSelectMode]="true"
							[selectedItemKey]="selectedKeyDropDownBtn" [text]="getMultipleActionsBtns()"
							[items]="listOfApprovalStatusesBtns" [wrapItemText]="true" displayExpr="label" keyExpr="value"
							(onButtonClick)="doSaveUpdateWorkflowStatus($event)"
							(onItemClick)="doSaveUpdateWorkflowStatusChanged($event)" class="button dxSubmitBtn submitDDBtn"
							[width]="200">
						</dx-drop-down-button>
						<!-- doSubmitForApproval -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-rebates-settlement-approval-trails-dialog *ngIf="isShowRebateSettlementApprovalTrailsPopup"
	[approvalTrailDetailsProps]="approvalTrailDetailsProps"></app-rebates-settlement-approval-trails-dialog>