import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PageOptions } from '@app/models/common.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Observable, Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MinMaxEndUsersChargebackContractModelUI } from '@app/models/min-max-end-users-chargeback-contract.model';
import { LoginService } from '@app/services/login.service';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Guid } from '@app/models/guid';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
declare const ExcelJS: ExcelJS;

@Component({
	selector: 'app-mmeucc-item-claim-details-dx-popup',
	templateUrl: './mmeucc-item-claim-details-dx-popup.component.html',
	styleUrls: ['./mmeucc-item-claim-details-dx-popup.component.css']
})
export class MmeuccItemClaimDetailsDxPopupComponent implements OnInit {
	constructor(
		public _ToastrService: ToastrService,
		public _LoginService: LoginService,
		public _LoaderService: LoaderService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _RestApiService: RestApiService,
	) { }
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public isVisiblePopup: boolean = false;
	public itemDetails: any;
	@Input() minMaxEndUsersChargebackContractModelUI = new MinMaxEndUsersChargebackContractModelUI();
	@Input('loadItemClaimDetailsSubject') loadItemClaimDetailsSubject: Subject<any>;
	public titleText: string = 'Item Claim Details';
	public itemClaimDataSource: any;
	public pageOptions: PageOptions = new PageOptions();
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterValue: any[] = [];
	private guid = new Guid();
	private updateUserSettingSbsn: Subscription;
	public getUomLstSbsn: Subscription;
	public listOfUoms: any[] = [];
	public decimalPointForCurrency: number;
	public end_user_code: string;
	ngOnInit(): void {
		this.fetchListOfUoms();
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		// get global currency
		this.loadItemClaimDetailsSubject.subscribe(objItemdetails => {
			this.end_user_code = objItemdetails.end_user_code || '';
			this.itemDetails = objItemdetails.itemClaimDetails;
			this.getItemDetails();
		});
	}

	ngOnDestroy() {
		if (this.loadItemClaimDetailsSubject) {
			this.loadItemClaimDetailsSubject.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
	}

	public doHiddenPopop(e) {
		this.isVisiblePopup = false;
	}
	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		let addRowButton = toolbarItems.find(x => x.name === "columnChooserButton");
		addRowButton.options.text = "Column Chooser";
		addRowButton.showText = "always";
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'refresh',
					text: "Refresh",
					onClick: (event: any) => {
						this.refreshDataGridComponent();
					}
				}
			}
		);
	}

	private refreshDataGridComponent() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Item_Claim_Details_List.xlsx';
		worksheet = workbook.addWorksheet('Item Claim Details List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'settlementPeriod') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value === 'halfYearly' ? 'Bi-annually' : gridCell.value === 'yearly' ? 'Annually' : gridCell.value.toString().charAt(0).toUpperCase() + gridCell.value.toString().slice(1);
						}
					}

				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	private getItemDetails() {
		this.isVisiblePopup = true;
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ITEM_CLAIM_DETAILS,
			contracttype: 'minmaxenduserschargebackcontracts',
			contractguid: this.minMaxEndUsersChargebackContractModelUI.guid,
			itemkey: this.itemDetails.erp_primary_key,
		};
		if (this.end_user_code) {
			loadParams['enduser'] = this.end_user_code;
		}
		this.itemClaimDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public doLoadClaimItemDetailState = () => {
		let newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.ITEM_CLAIM_DETAILS_SETTING)));
		const itemClaimDetailsConfigClone = newSection;
		if (!itemClaimDetailsConfigClone.configs) {
			itemClaimDetailsConfigClone.configs = [];
		}
		if (itemClaimDetailsConfigClone.configs.length > 0) {
			return itemClaimDetailsConfigClone.configs[0].state_persistent;
		} else {
			return null;
		}
	}

	public doSaveClaimItemDetailState = (e) => {
		let itemClaimDetailsConfig: any = {};
		try {
			itemClaimDetailsConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.ITEM_CLAIM_DETAILS_SETTING)));
		} catch (e) {
			itemClaimDetailsConfig = {};
		}

		if (!itemClaimDetailsConfig.configs) {
			itemClaimDetailsConfig.configs = [];
		}

		if (itemClaimDetailsConfig.configs.length > 0) {
			itemClaimDetailsConfig.configs[0].state_persistent = e;
		} else {
			const statePersistent = {
				guid: this.guid.newGuid(),
				state_persistent: e
			};
			itemClaimDetailsConfig.configs.push(statePersistent);
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.ITEM_CLAIM_DETAILS_SETTING);
		formData.append('user_settings', JSON.stringify(itemClaimDetailsConfig));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[ServerSections.ITEM_CLAIM_DETAILS_SETTING] = itemClaimDetailsConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.ITEM_CLAIM_DETAILS_SETTING, itemClaimDetailsConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.ITEM_CLAIM_DETAILS_SETTING, itemClaimDetailsConfig);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
}


