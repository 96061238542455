import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import { PopupHeightWidth } from "@app/constants-enums/enums";
import { PageOptions } from '@app/models/common.model';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { ToastrService } from 'ngx-toastr';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DxDataGridComponent } from 'devextreme-angular';
declare const ExcelJS: ExcelJS;
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { FilterFromListPipe } from '@app/pipes/filter-from-list.pipe';
import { Subscription } from 'rxjs';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import * as _ from 'underscore';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';

@Component({
	selector: 'app-mmeucc-claim-approval-trail',
	templateUrl: './mmeucc-claim-approval-trail.component.html',
	styleUrls: ['./mmeucc-claim-approval-trail.component.css']
})
export class MmeuccClaimApprovalTrailComponent implements OnInit, OnChanges, OnDestroy {
	@Input() approvalTrailDetails: any;
	@Input() callFrom: string;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public popupTitleText: string;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public filterValue: any[] = [];
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public dxDataSource: any;
	public pageOptions: PageOptions = new PageOptions();
	private approvalTrailDetailsObj: any;
	public listOfActions = [
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}];
	public listOfStatuses: any[] = this._MinMaxEndUsersChargebackContractService.doGetListofClaimApprovalStatus();
	public listOfApproverUsers: any[] = [];
	public listOfApproverUsersHeaderFilter: any[] = [];
	private getListOfUserSbsn: Subscription;

	constructor(private _AppCommonSrvc: AppCommonSrvc,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _FilterFromListPipe: FilterFromListPipe,
		private _ToastrService: ToastrService,
		private _MinMaxEndUsersChargebackContractService?: MinMaxEndUsersChargebackContractService,
	) {
	}

	ngOnInit() {
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.popupTitleText = 'Approval Trails';
		this.getListOfApproverUsers();
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['approvalTrailDetails']) {
			const approvalTrailDetails = _SimpleChanges['approvalTrailDetails'].currentValue;
			this.approvalTrailDetailsObj = approvalTrailDetails;
			this.getApprovalTrails();
		}
	}


	ngOnDestroy(): void {
		if (this.getListOfUserSbsn) {
			this.getListOfUserSbsn.unsubscribe();
		}
	}

	private getApprovalTrails() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.APPROVALTRAIL,
			trail_for: 'minmaxenduserschargebackcontracts',
		}
		if (this.callFrom === 'approvals') {
			loadParams['approval_id'] = this.approvalTrailDetailsObj.guid;
		}
		if (this.callFrom === 'claim') {
			loadParams['entity_id'] = this.approvalTrailDetailsObj.contractGuid;
			loadParams['unique_id'] = this.approvalTrailDetailsObj.guid;
		}
		this.dxDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: (method, ajaxOptions) => {
			},
			onLoaded(result) {
			}
		});
	}


	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			}
		);
	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Min_Max_Charegback_Approval_Trails.xlsx';
		worksheet = workbook.addWorksheet('Min-Max Chargeback Approval Trails');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'recipients') {
						// if (gridCell.data.workflow_type === 'approval_workflow') {
						// 	excelCell.value = this._FilterListFromListWithMultiPropertiesPipe.transform(gridCell.value, this.listOfRecipientsUsers, 'sub_account_id', 'name');
						// }
						// if (gridCell.data.workflow_type !== 'approval_workflow') {
						// 	excelCell.value = this._FilterListFromListWithMultiPropertiesPipe.transform(gridCell.value, this.listOfRecipientsUsers, 'sub_account_id', 'name');
						// }
					}
					if (gridCell.column.dataField === 'from_status' || gridCell.column.dataField === 'to_status') {
						excelCell.value = this._FilterFromListPipe.transform(gridCell.value, this.listOfStatuses, 'value', 'text');
						const colorName = this._FilterFromListPipe.transform(gridCell.value, this.listOfStatuses, 'value', 'color_code');;
						excelCell.font = { color: { argb: colorName } };
					}
					if (gridCell.column.dataField === 'approved_by_guid') {
						excelCell.value = this._FilterFromListPipe.transform(gridCell.value, this.listOfApproverUsers, 'sub_account_id', 'name');
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
	}

	private getListOfApproverUsers() {
		let listOfUserTypes = [];
		try {
			listOfUserTypes = this._LoginService.loginUser.account_detail.userType || [];
		} catch (error) {
			listOfUserTypes = [];
		}
		if (listOfUserTypes && listOfUserTypes.length > 0) {
			const viewFields = ['sub_account_id', 'first_name', 'last_name', 'status'];
			const listOfRoles = _.pluck(listOfUserTypes, 'user_type');
			const listOfSearches = [];
			listOfSearches.push({ status: 'active' });
			listOfSearches.push({ role: listOfRoles });
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
			reqFormData.append('entity', ServerEntity.SUBUSERS);
			reqFormData.append('is_dropdown', 'true');
			reqFormData.append('search', JSON.stringify(listOfSearches));
			// reqFormData.append('view_fields', JSON.stringify(viewFields));
			if (this.getListOfUserSbsn) {
				this.getListOfUserSbsn.unsubscribe();
			}

			this.getListOfUserSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
				next: response => {
					this.listOfApproverUsers = [];
					if (response && response.flag) {
						const listOfUsers = response.data || [];
						listOfUsers.map(user => {
							let strValue = user.first_name ? user.first_name : '';
							strValue += user.first_name && user.last_name ? ' ' + user.last_name : '';
							user.name = strValue;
							this.listOfApproverUsers.push(user);
						});
						this.setListOfApproverHeaderFilter();
					}
				}, error: error => {
				}
			});
		}
	}

	private setListOfApproverHeaderFilter() {
		this.listOfApproverUsersHeaderFilter = [];
		this.listOfApproverUsers.forEach(approver => {
			this.listOfApproverUsersHeaderFilter.push({ value: approver.sub_account_id, text: approver.name });
		})
	}

}

