import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import { DxChartComponent } from 'devextreme-angular';
import { DecimalPipe, formatCurrency } from '@angular/common';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CustomCurrencyPipe, CustomFormatCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-sales-leader-board-chart',
	templateUrl: './sales-leader-board-chart.component.html',
	styleUrls: ['./sales-leader-board-chart.component.css'],
	providers: [CustomCurrencyPipe, CustomFormatCurrencyPipe]
})
export class SalesLeaderBoardChartComponent implements OnInit, OnDestroy {
	@ViewChild('saleLeaderBoardChart') saleLeaderBoardChart: DxChartComponent;
	public isVisibleChartLoader = false;
	pipe: any = new DecimalPipe('en-US');
	public populationData: any[] = [];
	public barDataSource: any[] = [
		{
			"Criteria": "Jing Murphy",
			"Sales": 30370,
			"Units": 20960
		},
		{
			"Criteria": "Mphatso Devan",
			"Sales": 36503,
			"Units": 19818
		},
		{
			"Criteria": "Rudo Adebola",
			"Sales": 55867,
			"Units": 41603
		},
		{
			"Criteria": "Shashi Uttara",
			"Sales": 65149,
			"Units": 27822
		},
		{
			"Criteria": "Chifundo Dayo",
			"Sales": 76671,
			"Units": 29030
		},
		{
			"Criteria": "Wei Jyoti",
			"Sales": 113730,
			"Units": 54386
		},
	];
	private monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	public currentMonthName: string;

	constructor(private _LoginService: LoginService,
		private _GlobalStateService: GlobalStateService,
		private _CustomFormatCurrencyPipe: CustomFormatCurrencyPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe) {
		this.doCustomizeTextLbl = this.doCustomizeTextLbl.bind(this);
		this.customizeTooltip = this.customizeTooltip.bind(this);
	}

	ngOnInit() {
		const serverDate = new Date(this._LoginService.loginUser.server_date);
		this.currentMonthName = this.monthNames[serverDate.getMonth()];
		this._GlobalStateService.subscribe('REFRESH_SALES_LEADER_BOARD_CHART_EVENT', response => {
			this.doRefreshSalesLeaderBoardChart();
		});
	}
	ngOnDestroy() {
		this._GlobalStateService.unsubscribe('REFRESH_SALES_LEADER_BOARD_CHART_EVENT');
	}

	customizeTooltip(barInfo: any) {
		let color = ['#fb7764', '#73d47f', '#fed85e', '#d47683', '#dde392', '#757ab2'];
		// TODO is it better way to make tooltip?
		return {
			// text: '<span style="font-size: 14px; color:#808080">' + barInfo.argument + '</span><br />'
			// 	+ '<span style="color: ' + color[0] + ';">$' + (barInfo.originalValue / 1000000).toFixed(2) + 'M</span>'
			// text: '<span style="font-size: 14px; color:#808080" >' + barInfo.argument + '</span><br />' + '<span>$' + (barInfo.originalValue / 1000000).toFixed(2) + 'M</span>'
			text: '<span style="font-size: 14px; color:#808080" >' + barInfo.argument + '</span><br />'
				+ '<span>' + this._CustomFormatCurrencyPipe.transform(barInfo.originalValue) + '</span>'
		};
	}

	doCustomizeTextLbl(arg: any) {
		return this._CustomCurrencyPipe.transform(arg.value);
	}

	private doRefreshSalesLeaderBoardChart() {
		this.saleLeaderBoardChart.instance.refresh();
	}
}