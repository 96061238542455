<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="pageTitle" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{class: 'twoPopupUpLoaderIx' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="rightBar" id="mainContent1">
				<div class="rightBlock">
					<div *ngIf="isResponse" class="contentRow cf">
						<div *ngIf="!orderChangeOrderDetail" class="row">
							<div class="cols12 center">No Records Found.</div>
						</div>
						<div *ngIf="orderChangeOrderDetail && orderChangeOrderDetail.guid" class="quoteDesign cf">
							<div class="contBtn titleBtnCol">
							</div>

							<div class="subTitleRow" *ngIf="orderChangeOrderDetail.docnum">
								<!-- Order ID -->
								<h2>
									<span class="lbl">Order # </span>
									{{orderChangeOrderDetail.docnum || ''}}
								</h2>
								<span *ngIf="orderChangeOrderDetail.documentstatus =='bost_Open'"
									class="inStatusLabel orangeInvoice mrgL20">Open</span>
								<span *ngIf="orderChangeOrderDetail.documentstatus =='bost_Close'"
									class="inStatusLabel greenInvoice mrgL20">Closed</span>
								<span *ngIf="orderChangeOrderDetail.documentstatus =='bost_Cancel'"
									class="inStatusLabel redInvoice mrgL20">Canceled</span>
								<span *ngIf="orderChangeOrderDetail.documentstatus =='deleted'"
									class="inStatusLabel redInvoice mrg210">Deleted</span>
							</div>
							<div class="orderBox row">
								<div class="orderDetail cols4">
									<!-- <div class="custImg">
										<span class="icon-default-allcompany icon-set1"></span>
									</div> -->

									<div class="custDetail">
										<span class="custId" *ngIf="!isQBErpUser">
											<!-- Customer ID -->
											<span class="lbl">Customer ID #</span>
											<span
												*ngIf="!orderChangeOrderDetail.human_readable_account_code">{{orderChangeOrderDetail.cardcode}}</span>
											<span
												*ngIf="orderChangeOrderDetail.human_readable_account_code">{{orderChangeOrderDetail.human_readable_account_code}}</span>
										</span>

										<h3 class=" custNm">
											<span class="contTx lbl mrgB0 mrgR0">Customer: </span>
											{{orderChangeOrderDetail.cardname}}
										</h3>
										<div *ngIf="orderChangeOrderDetail.salespersoncode && orderChangeOrderDetail.salesreps">
											<!-- <h4 class="graySubHead">Contact Persons</h4> -->
											<div class="contDtl">
												<span class="contTx">
													<span class="lbl">Sales Person: </span>
													<strong *ngIf="!isMultipleSalesReps">
														{{orderChangeOrderDetail.salespersoncode || '' | multifilterFromList:
														listOfSalesPpl:
														'code':'firstname,lastname'}}
													</strong>

													<strong
														*ngIf="isMultipleSalesReps && orderChangeOrderDetail.salesreps && orderChangeOrderDetail.salesreps[0]">
														{{orderChangeOrderDetail.salesreps[0].salesrep || '' | multifilterFromList:
														listOfSalesPpl:
														'code':'firstname,lastname'}}
													</strong>
													<span
														*ngIf="orderChangeOrderDetail.salesreps && orderChangeOrderDetail.salesreps[0] && orderChangeOrderDetail.salesreps.length >= 2"
														id="salesPersonToolTip_{{orderChangeOrderDetail.guid}}"
														class="highlight pointerMark">
														+ {{orderChangeOrderDetail.salesreps.length-1}}
														{{ orderChangeOrderDetail.salesreps.length === 2 ? 'Other' :
														orderChangeOrderDetail.salesreps.length >= 3 ?
														'Others' : ''}}
													</span>
													<span *ngIf="orderChangeOrderDetail.contactpersondata.position">
														{{orderChangeOrderDetail.contactpersondata.position}}
													</span>
													<dx-tooltip target="#salesPersonToolTip_{{orderChangeOrderDetail.guid}}"
														showEvent="dxhoverstart" hideEvent="dxhoverend" position="right">
														<div *dxTemplate="let d = d; of: 'content'">
															<div class="tooltip-wrapper">
																<div class="greenTipout">
																	<ol class="tipList">
																		<span
																			*ngFor="let salesRep of orderChangeOrderDetail.salesreps; let p = index;">
																			<li
																				*ngIf="salesRep.salesrep || '' | multifilterFromList:listOfSalesPpl : 'code' : 'firstname,lastname'">
																				<a>
																					<span>
																						{{salesRep.salesrep || '' |
																						multifilterFromList:listOfSalesPpl
																						:
																						'code' : 'firstname,lastname'}} ({{(salesRep.percent
																						|| 0 |
																						number:decimalNumberFormat) + '%' }})
																					</span>
																				</a>
																			</li>
																		</span>
																	</ol>
																</div>
															</div>
														</div>
													</dx-tooltip>
												</span>
												<span class="contTx icoCall"
													*ngIf="orderChangeOrderDetail.contactpersondata.phone1">
													<span class="icon-call-1"></span>
													<a>
														{{orderChangeOrderDetail.contactpersondata.phone1}}
													</a>
												</span>
												<span class="contTx icoMail"
													*ngIf="orderChangeOrderDetail.contactpersondata.e_mail">
													<span class="icon-mail"></span>
													<a>
														{{orderChangeOrderDetail.contactpersondata.e_mail}}
													</a>
												</span>
												<!-- <span class="contTx">
												<span class="lbl">Shipping Type: </span>[SHIPPING TYPE]
											</span> -->
											</div>
										</div>

										<div class="contDtl"
											*ngIf="orderChangeOrderDetail.docdate || orderChangeOrderDetail.docduedate || orderChangeOrderDetail.modified_date">
											<!-- <span class="contTx icoDate" *ngIf="orderChangeOrderDetail.docdate">
												<span class="lbl">Order Date: </span>
												{{ orderChangeOrderDetail.docdate || '' | customDateFormatPipe:'dateonly'}}
											</span> -->
											<span class="contTx icoDate" *ngIf="orderChangeOrderDetail.modified_date">
												<span class="lbl">Modified Date: </span>
												{{ orderChangeOrderDetail.modified_date || '' | customDateFormatPipe:'dateonly'}}
											</span>
											<span class="contTx icoDate" *ngIf="orderChangeOrderDetail.docdate">
												<span class="lbl">Order Date: </span>
												{{ orderChangeOrderDetail.docdate || '' | customDateFormatPipe:'dateonly'}}
											</span>
											<span class=" contTx icoDate" *ngIf="orderChangeOrderDetail.docduedate">
												<span class=" lbl">Due Date: </span>
												{{ orderChangeOrderDetail.docduedate || '' | customDateFormatPipe:'dateonly'}}
											</span>
										</div>
										<div *ngIf="isShowEligibleField" class="contDtl">
											<span class="contTx icoDate">
												<span class="lbl">Eligible: </span>
												{{ orderChangeOrderDetail.eligible ? 'Yes' : 'No'}}
											</span>
										</div>
										<div class="contDtl"
											*ngIf="orderChangeOrderDetail.billing_start_date || orderChangeOrderDetail.billing_end_date">
											<span class="contTx icoDate" *ngIf="orderChangeOrderDetail.billing_start_date">
												<span class="lbl">Billing Start Date: </span>
												{{ orderChangeOrderDetail.billing_start_date || '' |
												customDateFormatPipe:'dateonly'}}
											</span>
											<span class="contTx icoDate" *ngIf="orderChangeOrderDetail.billing_end_date">
												<span class=" lbl">Billing End Date: </span>
												{{ orderChangeOrderDetail.billing_end_date || '' | customDateFormatPipe:'dateonly'}}
											</span>
										</div>
									</div>
									<div *ngIf="orderChangeOrderDetail.ponumber || orderChangeOrderDetail.shipmethod"
										class="poShipping">
										<div class="row">
											<div *ngIf="orderChangeOrderDetail.ponumber" class="cols6">
												<span>PO Number: </span>{{orderChangeOrderDetail.ponumber}}
											</div>
											<div *ngIf="orderChangeOrderDetail.shipmethod" class="cols6">
												<span>Shipping Method: </span>
												{{orderChangeOrderDetail.shipmethod}}
											</div>
										</div>
									</div>
								</div>

								<div
									*ngIf="orderChangeOrderDetail && (orderChangeOrderDetail.address || orderChangeOrderDetail.address2)"
									class="orderAddress cols6 detailsPage">
									<div *ngIf="orderChangeOrderDetail && orderChangeOrderDetail.address"
										class="cols6 addressBox" [ngClass]="{'singleBlock':!(orderChangeOrderDetail.address2)}">
										<h3 class="custNm">Billing Address</h3>
										<span class="contTx" [innerHTML]="orderChangeOrderDetail.address"></span>
									</div>
									<div *ngIf="orderChangeOrderDetail && orderChangeOrderDetail.address2"
										class="cols6 addressBox" [ngClass]="{'singleBlock':!(orderChangeOrderDetail.address)}">
										<h3 class="custNm">Shipping Address</h3>
										<span class="contTx" [innerHTML]="orderChangeOrderDetail.address2">
										</span>
									</div>
								</div>

								<div class="odStatus cols2">
									<ul *ngIf="orderChangeOrderDetail.totalIncentivePaid !== 0" class="statusBxLnremove">
										<li>
											<span class="stLbl">Incentives:</span>
											<span class="txtRight inlineBlock alignRight"
												[attr.id]="orderChangeOrderDetail.totalIncentivePaid > 0 ? 'incentiveDetails' : null"
												[ngClass]="{'highlight pointerMark':orderChangeOrderDetail.totalIncentivePaid > 0}">
												{{orderChangeOrderDetail.totalIncentivePaid || 0 | customCurrencyPipe}}
											</span>
											<dx-tooltip target="#incentiveDetails" showEvent="dxhoverstart" hideEvent="dxhoverend"
												position="bottom">
												<div *dxTemplate="let data = data of 'content'">
													Incentive Details
												</div>
											</dx-tooltip>
										</li>
									</ul>
									<div
										*ngIf="listOfShippingTracks && listOfShippingTracks.length > 0 && isShowShippingTrackDetails">
										<h3 class="custNm">Shipment Details</h3>
										<dx-data-grid [dataSource]="listOfShippingTracks" [showBorders]="true"
											[showColumnLines]="false" [showRowLines]="true" [showColumnHeaders]="true"
											[columnAutoWidth]="true" [width]="'100%'">
											<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
												[useNative]="false"></dxo-scrolling>
											<dxi-column dataField="shipping_agent_name" dataType="string"
												[allowHeaderFiltering]="false" caption="Carrier">
											</dxi-column>
											<dxi-column dataField="trackno" dataType="string" [allowHeaderFiltering]="false"
												caption="Tracking Numbers" cellTemplate="cellTemplatetrackno">
												<div *dxTemplate="let element of 'cellTemplatetrackno'">
													<a (click)="doOpenTrackUrl(element.data)">
														{{element.data.trackno}}
													</a>
													<!-- <span class="purple">	{{element.data.trackno}}</span> -->
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
								</div>
								<div *ngIf="isEnableProfitOnDocHeaderFeature" class="orderBox EQDetails mrgT10">
									<div *ngFor="let profitOnDocHeader of specificListOfProfitOnDocHeader;let menuIndex = index;"
										class="orderDetailLastrow cols3 mrgB10">
										<span class="lbl">{{profitOnDocHeader.custom_label}}: </span>
										<span [innerHtml]="profitOnDocHeader.custom_display_value" class="black"></span>
									</div>
								</div>
							</div>
						</div>

						<div class=" padB10 mrgB0 cf"
							*ngIf="orderChangeOrderDetail && orderChangeOrderDetail.documentlines && orderChangeOrderDetail.documentlines.length>0">
							<div class="subTitleRow">
								<div class="right secondaryTitle">
									<div *ngIf="!isQBOnlineErpUser" class="noteBx mrg0">
										<span>
											<strong>Customer Price List :</strong>
										</span>
										<span *ngIf="listOfPrices && listOfPrices.length > 0">
											{{listOfPrices[0].pricelistname || ''}}
										</span>
										<span *ngIf="!listOfPrices || (listOfPrices && listOfPrices.length <= 0)">
											<strong> &mdash; </strong>
										</span>
									</div>
								</div>
								<h2>List of Items</h2>
							</div>

							<div *ngIf="isShowDataGrid">
								<div class="mrgB0 mrgT10 cf">
									<dx-data-grid #itemsListOriginDatagridRef [dataSource]="orderChangeOrderDetail.documentlines"
										[wordWrapEnabled]="true" keyExpr="linenum" [showBorders]="true" [showColumnLines]="true"
										[showRowLines]="false" [allowColumnResizing]="true" [columnAutoWidth]="true"
										[width]="'100%'" (onRowPrepared)="doRowPreparedDocLine($event)"
										[masterDetail]="{ enabled:isEnabledMasterDetails, template: 'masterDetail','autoExpandAll': false }"
										class="hoverClr hideFilterPanelInx minHeightDataGrid150">
										<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
											[useNative]="false"></dxo-scrolling>

										<dxi-column dataField="itemsku" dataType="string" caption="SKU"
											[visible]="isQBOnlineErpUser" [allowEditing]="false" [width]="'9%'" [minWidth]="90"
											[allowSorting]="false" cellTemplate="cellTemplateItemSku">
											<div *dxTemplate="let element of 'cellTemplateItemSku'">
												{{element.data.itemsku || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="itemname" dataType="string" caption="Item" [allowSorting]="false"
											cellTemplate="cellTemplateItemName">
											<div *dxTemplate="let element of 'cellTemplateItemName'">
												{{element.data.itemname || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="description" dataType="string" caption="Description"
											[allowEditing]="false" [allowSorting]="false" cellTemplate="cellTemplateDescription"
											[minWidth]="130">
											<div *dxTemplate="let element of 'cellTemplateDescription'">
												{{element.data.description || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="uomcode" dataType="string" caption="UOM"
											cellTemplate="cellTemplateUomCode" [minWidth]="80" [width]="'5%'"
											[allowSorting]="false" [setCellValue]="setCellValueItemUom">
											<dxo-lookup [dataSource]="uomList" [displayExpr]="getDisplayExprUomName"
												valueExpr="code">
											</dxo-lookup>
											<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
											<div *dxTemplate="let element of 'cellTemplateUomCode'">
												{{element.data.uomcode || '' | filterFromList:uomList : 'code' : 'name' |
												titlecase}}
											</div>
										</dxi-column>

										<dxi-column dataField="price" caption="Unit Price" cellTemplate="cellTemplateUnitPrice"
											[minWidth]="90" [width]="'7%'" dataType="number"
											[editorOptions]="{ format: 'decimal', showClearButton: true }" [allowSorting]="false">
											<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
											<div *dxTemplate="let element of 'cellTemplateUnitPrice'">
												{{element.data.price || 0 | customCurrencyPipe}}
											</div>
										</dxi-column>

										<dxi-column dataField="quantity" caption="Quantity" cellTemplate="cellTemplateQuantity"
											[minWidth]="90" [width]="'7%'" dataType="number"
											[editorOptions]="{ format: 'fixedPoint', showClearButton: true }"
											[allowSorting]="false">
											<!-- <dxi-validation-rule type="required"></dxi-validation-rule>
												<dxi-validation-rule type="range" min="1" max="99999"
													message="Quantity must be between 1 and 99999">
												</dxi-validation-rule> -->
											<div *dxTemplate="let element of 'cellTemplateQuantity'">
												{{element.data.quantity || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="discountpercent" [visible]="isShowDiscountField"
											caption="Discount %" [allowEditing]="false" cellTemplate="cellTemplateDiscountPercent"
											dataType="number" [editorOptions]="{ format: 'decimal'}">
											<dxi-validation-rule type="range" min="0" max="99.99"
												message="Discount must be between 0 and 99.99">
											</dxi-validation-rule>
											<div *dxTemplate="let element of 'cellTemplateDiscountPercent'">
												{{element.data.discountpercent || 0 | number:'1.2-2'}}
											</div>
										</dxi-column>

										<dxi-column dataField="discounttotal" [visible]="isShowDiscountField" caption="Discount"
											[allowEditing]="false" cellTemplate="cellTemplateDiscountTotal" dataType="number"
											[editorOptions]="{ format: 'decimal'}">
											<div *dxTemplate="let element of 'cellTemplateDiscountTotal'">
												{{element.data.discounttotal|| 0 | customCurrencyPipe}}
											</div>
										</dxi-column>

										<dxi-column dataField="pickstatus" dataType="string" [visible]="isShowPickStatusField"
											caption="Pick Status" [width]="'9%'" [minWidth]="90" [allowEditing]="false"
											[allowSorting]="false" cellTemplate="cellTemplatePickStatus" alignment="center">
											<div *dxTemplate="let element of 'cellTemplatePickStatus'">
												{{(element.data.pickstatus) ? element.data.pickstatus.toString().toLowerCase() ===
												'tyes' ?
												'Yes' : 'No' : ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="grosstotal" caption="Line Total" [allowEditing]="false"
											[allowSorting]="false" [width]="'10%'" [minWidth]="100"
											cellTemplate="cellTemplateGrossTotal" dataType="number">
											<div *dxTemplate="let element of 'cellTemplateGrossTotal'">
												{{element.data.grosstotal || 0 | customCurrencyPipe}}
											</div>
										</dxi-column>

										<dxi-column dataField="batch_serial_number" dataType="string"
											[visible]="isShowBatchSerialField" caption="Batch/Serial" [allowEditing]="false"
											[allowSorting]="false" [width]="'9%'" [minWidth]="90"
											cellTemplate="cellTemplateBatchSerial" alignment="left">
											<div *dxTemplate="let element of 'cellTemplateBatchSerial'">
												<span *ngIf="element.data.batch_serial_number">
													<dx-button [icon]="doGetIconExpandCollapseItem(element.data)"
														(onClick)="doExpandItem(element.data)" class="transperentBtn"> </dx-button>
													{{element.data.batch_serial_number || ''}}
												</span>
											</div>
										</dxi-column>
										<dxi-column dataField="batch_serial_exp_date" [visible]="isShowBatchSerialField"
											caption="Exp. Date" [width]="'10%'" [minWidth]="100" [allowEditing]="false"
											dataType="string" format="yyyy-MM-dd" [allowSorting]="false"
											cellTemplate="cellTemplateBatchSerialExpDate">
											<div *dxTemplate="let element of 'cellTemplateBatchSerialExpDate'">
												<span *ngIf="element.data.batch_serial_exp_date">
													<dx-button [icon]="doGetIconExpandCollapseItem(element.data)"
														(onClick)="doExpandItem(element.data)" class="transperentBtn"> </dx-button>
													{{element.data.batch_serial_exp_date || ''}}
												</span>
											</div>
										</dxi-column>

										<div *dxTemplate="let documentline of 'masterDetail'">
											<div
												*ngIf="documentline.data.batch_serial_list && documentline.data.batch_serial_list.length > 0">
												<dx-data-grid #batchDataGrid id="batchDataGrid"
													[dataSource]="documentline.data.batch_serial_list || []" keyExpr="number"
													[showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
													[allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'">
													<dxo-scrolling mode="standard" [scrollByThumb]="true" [showScrollbar]="'always'"
														[useNative]="false"></dxo-scrolling>
													<dxi-column dataField="type" dataType="string" caption="Type"></dxi-column>
													<dxi-column dataField="number" dataType="string" caption="Batch/Serial"
														cellTemplate="cellTemplateBatchSerialNumber">
														<div *dxTemplate="let element of  'cellTemplateBatchSerialNumber'">
															{{element.data.number || ''}}
														</div>
													</dxi-column>
													<dxi-column dataField="exp_date" caption="Exp. Date" dataType="date"
														format="yyyy-MM-dd">
													</dxi-column>
												</dx-data-grid>
											</div>
										</div>
									</dx-data-grid>
								</div>
							</div>

							<div class="cols12 paymentBtmRow">
								<div class="row">
									<diV class="totalRowCal withAddBtn noActClm noBatchClm">
										<div class="alignRight mrgT10">
											<div class="cols12">
												<div class="paySubTotal totalWithAct">
													Subtotal :
													<span class="txtRight">
														{{orderChangeOrderDetail.doctotalsys || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div *ngIf="orderChangeOrderDetail.totaldiscount > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Discount :
													<span class="txtRight">
														{{orderChangeOrderDetail.totaldiscount || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div *ngIf="orderChangeOrderDetail.totalmiscamount > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Miscellaneous Charge:
													<span class="txtRight">
														{{orderChangeOrderDetail.totalmiscamount || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div *ngIf="orderChangeOrderDetail.shippingcharges > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Frieght Charge:
													<span class="txtRight">
														{{orderChangeOrderDetail.shippingcharges || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div class="cols12">
												<div class="payFinalTotal totalWithAct">Total :
													<span class="txtRight">
														{{orderChangeOrderDetail.doctotal || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>
										</div>
									</diV>

									<div class="cols12 mrgT10 padL30">
										<div class="dx-field feildCol mrgT10 mrgB20 remarksinputDisable">
											<div class="dx-field-label">Remarks</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-text-area [height]="60" [maxLength]="400"
													[(value)]="orderChangeOrderDetail.comments" [disabled]="true">
												</dx-text-area>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>