<dx-data-grid #dataGridContainer [dataSource]="dxDataSource" [showBorders]="true" [showColumnLines]="true"
	[showRowLines]="false" [allowColumnResizing]="true" [allowColumnReordering]="true" [columnAutoWidth]="true"
	[allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue" [width]="'100%'"
	[height]="'calc(100vh - 360px)'" (onToolbarPreparing)="doToolbarPreparing($event)" class="hideFilterPanelInx">

	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="false"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="none"></dxo-sorting>
	<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>

	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="approval_no" caption="Approval Code" [allowHeaderFiltering]="false"> </dxi-column>

	<dxi-column dataField="workflow_title" caption="Workflow Title" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<dxi-column dataField="level" alignment="center" caption="Workflow Level" dataType="number"
		cellTemplate="cellTemplateLevel" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateLevel'">
			{{element.data.level >= 1 ? 'Level ' + (element.data.level || 0) : ''}}
		</div>
	</dxi-column>
	<dxi-column dataField="modified_date" cellTemplate="cellTemplateModifiedDate" caption="Modified Date"
		dataType="date">
		<div *dxTemplate="let element of 'cellTemplateModifiedDate'">
			{{element.data.modified_date |customDateFormatPipe:'convertFrom24To12'}}
		</div>
	</dxi-column>
	<dxi-column dataField="from_status" caption="Original Status" [allowFiltering]="false" [allowHeaderFiltering]="true"
		cellTemplate="cellTemplateFromStatus">
		<dxo-header-filter [dataSource]="listOfStatuses"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateFromStatus'">
			<span class="font-weight-bold" [ngClass]="element.data.from_status || '' |
			filterFromList:listOfStatuses : 'value' : 'classname'">
				{{ element.data.from_status || "" | filterFromList:listOfStatuses : 'value' : 'text'}}
			</span>
		</div>
	</dxi-column>

	<dxi-column dataField="to_status" caption="Changed Status" [allowFiltering]="false" [allowHeaderFiltering]="true"
		cellTemplate="cellTemplateToStatus">
		<dxo-header-filter [dataSource]="listOfStatuses"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateToStatus'">
			<span class="font-weight-bold" [ngClass]="element.data.to_status || '' |
			filterFromList:listOfStatuses : 'value' : 'classname'"> {{ element.data.to_status || "" |
				filterFromList:listOfStatuses : 'value' : 'text'}}
			</span>
		</div>
	</dxi-column>

	<dxi-column dataField="approved_by_guid" caption="Approved By" [allowFiltering]="false" [allowHeaderFiltering]="true"
		cellTemplate="cellTemplateApprovedBy">
		<dxo-header-filter [dataSource]="listOfApproverUsersHeaderFilter"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateApprovedBy'">
			{{ element.data.approved_by_guid || "" | filterFromList:listOfApproverUsers : 'sub_account_id' :
			'name'}}
		</div>
	</dxi-column>

	<dxi-column dataField="comment" caption="Comment" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>
</dx-data-grid>