import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { DATETIME_FORMAT_BACKEND, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { Subscription } from 'rxjs';
import { Guid } from '@app/models/guid';
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { DateRangeGlobalSettingValEnum } from '@app/constants-enums/enums';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-date-range-global-settings',
	templateUrl: './date-range-global-settings.component.html',
	styleUrls: ['./date-range-global-settings.component.css'],
})

export class DateRangeGlobalSettingsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() openFrom: string;
	@Output() refreshTile: EventEmitter<any> = new EventEmitter<any>();
	public userConfigsUI: any = {
		show_data_from: '',
		startDate: '',
		endDate: '',
	};
	private guid = new Guid();
	public listOfShowDataFrom: any[] = [];
	private updateUserSettingSbsn: Subscription;
	private userSettingConfigCode = 'date_range';
	private actionFrom = 'dashboard';
	public isShowLoadPanel: boolean = false;
	public isDisabledAllFields: boolean = false;
	public DateRangeGlobalSettingValEnum = DateRangeGlobalSettingValEnum;
	public isShowConfimBtn: boolean = true;
	public minDate: any;

	// #region Angular Life cycle
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _DashboardService: DashboardService,
		private _RestApiService: RestApiService) {
	}

	ngOnInit() {
		this.preInit();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.openFrom) {
			this.actionFrom = changes.openFrom.currentValue;
		}
	}

	ngOnDestroy() {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}
	// #endregion

	public doChangeShowDataFrom(e) {
		if (e.event && e.previousValue && e.value) {
		}
	}

	private isCheckValidation() {
		if (this.userConfigsUI.show_data_from === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
			if (!this.userConfigsUI.startDate) {
				this._ToastrService.info('Start Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
			if (!this.userConfigsUI.endDate) {
				this._ToastrService.info('End Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
			const mStart = moment(this.userConfigsUI.startDate);
			const mEnd = moment(this.userConfigsUI.endDate);
			if (!mStart.isSameOrBefore(mEnd)) {
				this._ToastrService.info('End Date should be greater than or equal to Start Date.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
		}
		return false;
		// if (Math.abs(mStart.diff(mEnd, 'days', false)) > 365) {
		// 	this._ToastrService.info('Start date and End Date should be between One Year.', 'Info', { closeButton: true, tapToDismiss: true });
		// 	return true;
		// }
	}

	public doConfirmedSettings(e) {
		if (this.isCheckValidation()) {
			return false;
		}
		this.isShowLoadPanel = true;
		this._AppCommonSrvc.fetchUserSettings().subscribe(response => {
			this.isShowLoadPanel = false;
			if (response) {
				this._AppCommonSrvc.setUserSettings(response.data || []);
				let dateRangeGlobalConfig: any = {};
				try {
					dateRangeGlobalConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS)));
				} catch (e) {
					dateRangeGlobalConfig = {};
				}

				if (!dateRangeGlobalConfig.hasOwnProperty('date_range')) {
					dateRangeGlobalConfig['date_range'] = {};
				}

				if (dateRangeGlobalConfig.date_range && Object.keys(dateRangeGlobalConfig.date_range).length <= 0) {
					dateRangeGlobalConfig.date_range.guid = this.guid.newGuid();
					dateRangeGlobalConfig.date_range.code = this.userSettingConfigCode;
				}

				dateRangeGlobalConfig.date_range.show_data_from = this.userConfigsUI.show_data_from;
				if (this.userConfigsUI.show_data_from === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
					if (this.userConfigsUI.startDate) {
						dateRangeGlobalConfig.date_range.custom_start_date = moment(this.userConfigsUI.startDate).format(DATETIME_FORMAT_BACKEND);
					}
					if (this.userConfigsUI.endDate) {
						dateRangeGlobalConfig.date_range.custom_end_date = moment(this.userConfigsUI.endDate).format(DATETIME_FORMAT_BACKEND);
					}
				} else {
					if (dateRangeGlobalConfig.date_range.hasOwnProperty('custom_start_date')) {
						dateRangeGlobalConfig.date_range.custom_start_date = null;
					}
					if (dateRangeGlobalConfig.date_range.hasOwnProperty('custom_end_date')) {
						dateRangeGlobalConfig.date_range.custom_end_date = null;
					}
				}

				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
				formData.append('section', ServerSections.GLOBAL_SETTINGS);
				formData.append('user_settings', JSON.stringify(dateRangeGlobalConfig));
				if (this.updateUserSettingSbsn) {
					this.updateUserSettingSbsn.unsubscribe();
				}
				this.isShowLoadPanel = true;
				this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe(response => {
					this.isShowLoadPanel = false;
					if (response && response.flag) {
						const userSettings = this._AppCommonSrvc.getUserSettings();
						if (userSettings && userSettings.length <= 0) {
							const newSection = {};
							newSection[ServerSections.GLOBAL_SETTINGS] = dateRangeGlobalConfig;
							this._AppCommonSrvc.setUserSettings([newSection]);
							this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS, dateRangeGlobalConfig);
						} else {
							this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS, dateRangeGlobalConfig);
						}
						this.refreshTile.emit(new Date().getTime());
					}
				}, error => {
					this.isShowLoadPanel = false;
				});
			}
		}, error => {
			this.isShowLoadPanel = false;
		});
	}

	// Method used to get Customer360 Configuration
	private getDateRangeConfig() {
		this.setDefaultSettings();
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (isDashboardSettingsExists) {
			this.setDefaultConfiguration();
		} else {
			this.isShowLoadPanel = true;
			this._AppCommonSrvc.fetchUserSettings().subscribe(response => {
				this.isShowLoadPanel = false;
				if (response) {
					this._AppCommonSrvc.setUserSettings(response.data || []);
					this.setDefaultConfiguration();
				}
			}, error => {
				this.isShowLoadPanel = false;
			});
		}
	}

	private setDefaultConfiguration() {
		const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (dashboardSettings.hasOwnProperty('date_range')) {
			const dateRangeConfig = dashboardSettings.date_range;
			if (dateRangeConfig && dateRangeConfig.show_data_from) {
				this.userConfigsUI.show_data_from = dateRangeConfig.show_data_from;
			}
			if (this.userConfigsUI.show_data_from === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
				if (dateRangeConfig.custom_start_date) {
					this.userConfigsUI.startDate = moment(dateRangeConfig.custom_start_date).toDate();
				}
				if (dateRangeConfig.custom_end_date) {
					this.userConfigsUI.endDate = moment(dateRangeConfig.custom_end_date).toDate();
				}
			}
		}
	}

	private preInit() {
		let minDate = null;
		try {
			minDate = this._LoginService.loginUser.account_detail.app_settings.erp_data_start_date || null;
		} catch (e) {
			minDate = null;
		}
		this.minDate = minDate ? moment(minDate).toDate() : null;
		this.listOfShowDataFrom = this._DashboardService.getListOfDateRangeShowDataFrom();
		this.getDateRangeConfig();
	}

	private setDefaultSettings() {
		this.userConfigsUI.show_data_from = this.listOfShowDataFrom[0].value;
	}

}
