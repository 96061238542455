import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { IxLoadText } from '@app/constants-enums/constants';

@Component({
	selector: 'app-ix-load-panel',
	templateUrl: './ix-load-panel.component.html',
	styleUrls: ['./ix-load-panel.component.css']
})

export class IxLoadPanelComponent implements OnInit, OnDestroy, OnChanges {
	@Input() overlayClass: string;
	@Input() loaderText: string = IxLoadText;
	// #region Angular Life cycle
	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.overlayClass) {
			if (changes.overlayClass.currentValue) {
				this.overlayClass = changes.overlayClass.currentValue;
			}
		}
		if (changes.loaderText) {
			if (changes.loaderText.currentValue) {
				this.loaderText = changes.loaderText.currentValue;
			}
		}
	}

	ngOnDestroy() {
	}
	// #endregion
}
