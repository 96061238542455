import * as _ from 'underscore';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Component, Inject, OnInit, ViewChild, OnDestroy, Output, Input, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '@app/services/login.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-bc-billed-items-list-dialog',
	templateUrl: './bc-billed-items-list-dialog.component.html',
	styleUrls: ['./bc-billed-items-list-dialog.component.css']
})
export class BcBilledItemsListDialogComponent implements OnInit, OnDestroy {
	@ViewChild("itemTabDataGridRef", {}) itemTabDataGridRef: DxDataGridComponent;
	@ViewChild("customersDetailssDataGridRef", {}) customersDetailssDataGridRef: DxDataGridComponent;
	private dataInReqSubscription: Subscription;
	public listOfUoms: any[] = [];
	public itemTabDataSource: any[] = [];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public editBillbackGuid: any;
	public validationMsg: string;
	public numberPattern: any = /^\d+(\d+)?$/;
	public decimalPointForCurrency: number;
	public isShowPopup: boolean = true;
	@Input() billedItemsProps: any;
	@Output() closebilledItemsPopup = new EventEmitter<any>();
	constructor(
		public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private dialogRef: MatDialogRef<BcBilledItemsListDialogComponent>,
		private _LoginService: LoginService,
		private _BillbackService: BillbackService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _BillbackContractService: BillbackContractService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
	) {
		this.checkValidTobillQty = this.checkValidTobillQty.bind(this);
	}

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		const itemTabDataList = this.billedItemsProps.itemTabDataList;
		const filtereItemTabDataList = _.filter(itemTabDataList, function (item: any) { return item.available > 0; });
		this.itemTabDataSource = JSON.parse(JSON.stringify(filtereItemTabDataList || []));
		this.listOfUoms = this.billedItemsProps.listOfUoms;
		this.editBillbackGuid = this._BillbackContractService.getEditBillbackGuid();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}
	public getCustomersByItemDetails(element) {
		return element.data.customers || [];
	}

	public doOnInitNewRowCustomersTab(event) {

	}

	public doOnRowInsertedCustomersTab(event) {

	}

	public doOnEditorPreparingCustomersTab(event) {
		// if (event && event['row'] && event['row']['data']) {
		// 	const rowIndex = this.itemTabDataGridRef.instance.getRowIndexByKey(event.row.key);
		// 	if (event.parentType === "dataRow" && event.dataField === 'claimed_qty') {
		// 		event.editorOptions.onValueChanged = (e: any) => {
		// 			this.customersDetailssDataGridRef.instance.cellValue(rowIndex, 'claimed_qty', e.value);
		// 		}
		// 	}
		// }
	}

	onRowValidating(event, item) {
		console.log('event Data ', event);
		if (event.newData.claimed_qty) {
			const isValid = this.checkValidTobillQty(event, item);
			// event.errorText = result.errorText;
			event.isValid = isValid;
		}
	}

	checkValidTobillQty(event, item) {
		let isValid = true;
		if (event && event['oldData']) {
			const itemObject = item.data;
			const customerObject = event.oldData;
			if (customerObject) {
				let total_claimed_qty = 0;
				if (itemObject.customers && itemObject.customers.length > 0) {
					itemObject.customers.forEach(element => {
						total_claimed_qty += total_claimed_qty + element['claimed_qty'] ? + element['claimed_qty'] : 0;
					});
				}
				const final_available_qty = itemObject.available - total_claimed_qty;
				this.validationMsg = '';
				if (final_available_qty < event.newData.claimed_qty) {
					this.validationMsg = 'Bill qty should not be grater then total available qty of Item ' + itemObject.itemname;;
					isValid = false;
				}
				const customer_available_qty = customerObject.sold - customerObject.billed;
				if (event.newData.claimed_qty > customer_available_qty) {
					this.validationMsg = 'Bill qty should not be grater then available qty for ' + customerObject.customer_name;
					isValid = false;
				}
			}
		}
		return isValid;
	}

	public onEditCanceledCustomersTab(event) {
		this.validationMsg = '';
	}

	public doOnRowRemovedCustomersTab(event) {

	}

	public doOnRowUpdatedCustomersTab(event, customer) {

	}

	public doOnSavedCustomersTab(event, customer) {

	}

	calculateToBillCellValue(rowData) {
		return rowData.claimed_qty ? Math.round(rowData.claimed_qty) : 0;
	}

	claimedItemSubmit() {
		if (this.validationMsg) {
			return false;
		}
		// const claimedItemList = this.itemTabDataGridRef.instance.getVisibleRows();
		const claimedItemList = this.itemTabDataSource;
		if (!claimedItemList && claimedItemList.length === 0) {
			return false;
		}

		// check required at least 1 to bill qty
		let totalValClaimedQty = 0;
		claimedItemList.forEach(element => {
			if (element.customers && element.customers.length > 0) {
				element.customers.forEach(elementCust => {
					totalValClaimedQty = totalValClaimedQty + (elementCust['claimed_qty'] || 0);
				});
			}
		});
		if (totalValClaimedQty === 0) {
			this._ToastrService.info('Required total To bill qty at least 1 !', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		}

		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'generateBillbackDocument');
		formData.append('contractguid', this.editBillbackGuid);
		formData.append('claimed_items', JSON.stringify(claimedItemList));
		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(
			response => {
				if (response.flag) {
					if (response.data) {
					}
					this._ToastrService.success('Billback document generated successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					this.dialogRef.close(true);
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	public doHiddenPopop(e) {
		this.isShowPopup = false;
	}
}
