<dx-popup [width]="800" [height]="500" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
	[title]="popupTitleText" [showCloseButton]="true" [(visible)]="isShowPopup" (onHidden)="doHiddenPopup($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="row">
				<div class="cols6">
					<div class="dx-field mrgB20 labelRadio">
						<div class="dx-field-label">Type</div>
						<div class="dx-field-value">
							<dx-radio-group [(value)]="exportPricelistTerms.type" [dataSource]="listOfTypes" displayExpr="text"
								valueExpr="value" layout="horizontal" name="type"
								(onValueChanged)="doExportTypeValueChanged($event)">
							</dx-radio-group>
						</div>
					</div>
				</div>
				<div class="cols6 mrgT10">
					<div class="dx-field dateiconset">
						<div class="dx-field-label">Reply Date</div>
						<div class="dx-field-value">
							<dx-date-box [(value)]="exportPricelistTerms.replyDate" type="date" displayFormat="yyyy-MM-dd"
								[showClearButton]="true"></dx-date-box>
						</div>
					</div>
				</div>

				<div class="cols12" *ngIf="exportPricelistTerms.type === ServerEntity.CLIENTS">
					<div class="dx-field mrgB20 labelRadio">
						<div class="dx-field-label">Customer</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-drop-down-box #refCustomersLstDrpDwn [dataSource]="dxDataSourceCustomer"
								[(value)]="exportPricelistTerms.clientCode" [(opened)]="isCustomerGridBoxOpened"
								[displayExpr]="doDisplayExprCustomerName" valueExpr="code" [deferRendering]="true"
								placeholder="Select Customer..." [showClearButton]="true"
								(onOptionChanged)="doGridBoxOptionChanged($event)" (onValueChanged)="doChangeCustomers($event)"
								(onOpened)="doOpenedDropdownCompany($event)" class="dx-ix-dropdownbox">
								<div *dxTemplate="let data of 'content'">
									<dx-data-grid #listOfCientDataGrid [dataSource]="dxDataSourceCustomer"
										[hoverStateEnabled]="true" [filterRow]="{ visible: true }" [height]="345"
										[filterSyncEnabled]="true" [filterValue]="crmDataFilterValue"
										[(selectedRowKeys)]="exportPricelistTerms.clientCode" [remoteOperations]="true"
										[wordWrapEnabled]="true" [showBorders]="true"
										(onSelectionChanged)="doSelectedChangedCustomers($event)">
										<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
											[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
										<dxo-selection mode="single" [allowSelectAll]="false"></dxo-selection>
										<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
										<dxi-column dataField="pricelistnum" dataType="string" caption="Price List Name"
											[visible]="false"> </dxi-column>

										<dxi-column [visible]="isShowCodeColumn" dataField="code" dataType="string" caption="Code"
											cellTemplate="cellTemplateCode" [allowHeaderFiltering]="false">
											<div *dxTemplate="let element of 'cellTemplateCode'">
												{{element.data.code || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="name" dataType="string" sortOrder="asc" caption="Company"
											cellTemplate="cellTemplateName" [allowHeaderFiltering]="false">
											<div *dxTemplate="let element of 'cellTemplateName'">
												{{element.data.name || ''}}
											</div>
										</dxi-column>
										<!-- <dxi-column dataField="crm_type" dataType="string" [visible]="false" caption="Type"
											cellTemplate="cellTemplateCrmType" [allowHeaderFiltering]="true"
											[allowFiltering]="false">
											<dxo-header-filter
												[dataSource]="[{value:NameFromServerKeyEnum.CLIENT, text:'Customer'}]">
											</dxo-header-filter>
											<div *dxTemplate="let element of 'cellTemplateCrmType'">
												Customer
											</div>
										</dxi-column> -->
									</dx-data-grid>
								</div>
							</dx-drop-down-box>
						</div>
					</div>
				</div>

				<div class="cols12" *ngIf="exportPricelistTerms.type === ServerEntity.ENDUSERS">
					<div class="dx-field mrgB20 labelRadio">
						<div class="dx-field-label">End-User</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-drop-down-box #refEndUsersLstDrpDwn [dataSource]="dxDataSourceEndUser"
								[(value)]="exportPricelistTerms.endUserCode" [(opened)]="isEndUserGridBoxOpened"
								[displayExpr]="doDisplayExprEndUser" valueExpr="code" [deferRendering]="true"
								placeholder="Select End User..." [showClearButton]="true"
								(onOptionChanged)="doEndUserOptionChangedDrpDwn($event)"
								(onValueChanged)="doEndUserValueChangedDrpDwn($event)"
								(onOpened)="doEndUserOpenedDrpDwn($event)" class="dx-ix-dropdownbox">
								<div *dxTemplate="let data of 'content'">
									<dx-data-grid #listOfEndUserDataGridRef [dataSource]="dxDataSourceEndUser"
										[hoverStateEnabled]="true" [filterRow]="{ visible: true }" [height]="345"
										[filterSyncEnabled]="true" [filterValue]="endUserDataFilterValue"
										[(selectedRowKeys)]="exportPricelistTerms.endUserCode" [remoteOperations]="true"
										[wordWrapEnabled]="true" [showBorders]="true"
										(onSelectionChanged)="doEndUserSelectedChangedDrpDwn($event)">
										<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
											[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
										<dxo-selection mode="single" [allowSelectAll]="false"></dxo-selection>
										<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>

										<dxi-column [visible]="isShowCodeColumn" dataField="code" dataType="string" caption="Code"
											cellTemplate="cellTemplateCode" [allowHeaderFiltering]="false">
											<div *dxTemplate="let element of 'cellTemplateCode'">
												<span class="pointerMark">{{element.data.code}}</span>
											</div>
										</dxi-column>

										<dxi-column dataField="pricelistnum" dataType="string" caption="Price List Name"
											[visible]="false">
										</dxi-column>
										<dxi-column dataField="companyname" dataType="string" sortOrder="asc" caption="Company"
											cellTemplate="cellTemplateCompanyName" [allowHeaderFiltering]="false">
											<div *dxTemplate="let element of 'cellTemplateCompanyName'">
												{{element.data.companyname}}
											</div>
										</dxi-column>

									</dx-data-grid>
								</div>
							</dx-drop-down-box>
						</div>
					</div>
				</div>

				<div class="cols12">
					<div class="dx-field mrgB20 labelRadio">
						<div class="dx-field-label">Billing Address</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-drop-down-box #refBillingAddressLstDrpDwn [dataSource]="listOfBillToAddresses"
								[(value)]="exportPricelistTerms.billingAddressGuid" [(opened)]="isBillingAddressGridBoxOpened"
								[displayExpr]="doDisplayExprBillingAddressName" valueExpr="guid" [deferRendering]="true"
								placeholder="Select Address..." [showClearButton]="true"
								(onOptionChanged)="doBillingAddressOptionChangedDrpDwn($event)"
								(onValueChanged)="doBillingAddressChangeCustomers($event)"
								(onOpened)="doBillingAddressOpenedDrpDwn($event)" class="dx-ix-dropdownbox">
								<div *dxTemplate="let data of 'content'">
									<dx-data-grid #refListOfBillingAddressDataGrid keyExpr="guid"
										[dataSource]="listOfBillToAddresses" [hoverStateEnabled]="true"
										[filterRow]="{ visible: true }" [height]="345"
										[(selectedRowKeys)]="exportPricelistTerms.billingAddressGuid" [remoteOperations]="false"
										[wordWrapEnabled]="true" [showBorders]="true"
										(onSelectionChanged)="doBillingAddresonSelectionChanged($event)">
										<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
											[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
										<dxo-selection mode="single" [allowSelectAll]="false"></dxo-selection>
										<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>

										<dxi-column dataField="addressname" dataType="string" sortOrder="asc"
											caption="Address Name" cellTemplate="cellTemplateAddressName"
											[allowHeaderFiltering]="false">
											<div *dxTemplate="let element of 'cellTemplateAddressName'">
												{{element.data.addressname}}
												<br />
												{{element.data.addressname2 ? element.data.addressname2 : ''}}
												{{element.data.addressname3 ? ', '+element.data.addressname3 : ''}}
												{{element.data.city ? ', '+element.data.city : ''}}
												{{element.data.state ? ', '+element.data.state : ''}}
												{{element.data.zipcode ? ', '+element.data.zipcode : ''}}
												{{element.data.country ? ', '+element.data.country : ''}}
											</div>
										</dxi-column>
									</dx-data-grid>
								</div>
							</dx-drop-down-box>
						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="doExportPriceListTerms()" text="Export PDF" type="normal" stylingMode="contained"
				icon="exportpdfwhiteicon" class="button primaryBtn"></dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>