import { Component, HostListener, Input, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { GlobalPopoupHeight100Ratio, GlobalPopoupHeight20Ratio, GlobalPopoupHeight40Ratio, GlobalPopoupHeight80Ratio, GlobalPopoupHeight90Ratio, GlobalPopoupHeight95Ratio, GlobalPopoupWidth20Ratio, GlobalPopoupWidth30Ratio, GlobalPopoupWidth40Ratio, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxPopupComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, forkJoin, map } from 'rxjs';
import * as _ from 'underscore';

@Component({
	selector: 'app-card-view-close-multiple-settlement',
	templateUrl: './card-view-close-multiple-settlement.component.html',
	styleUrls: ['./card-view-close-multiple-settlement.component.css']
})
export class CardViewCloseMultipleSettlementComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('dxCardViewPopupComponent', {}) dxCardViewPopupComponent: DxPopupComponent;
	@Input() cardViewMultiSettlementProps: any;
	@Output() closeCardViewMultipleSettlementPopup = new EventEmitter<any>();
	public isShowPopup: boolean = true;
	public titleText: string = 'List of Settlement';
	public _PopupHeightWidth = PopupHeightWidth;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public selectedMultiSettlementRowData: any;
	private dataOutReqSubscription: Subscription;
	public offsetY: string = "0 60";
	public successListOfSettlement: any = [];
	public inprocessListOfSettlement: any = [];
	public listOfTabs: any[] = [
		{
			guid: '73b5bb04f3a744a48cde881a5124798e',
			code: 'inprocess',
			title: 'In Process',
		},
		{
			guid: '73b5bb04f3a744a48cde881a51241457e',
			code: 'successful',
			title: 'Successful',
		},
	];
	public minimizeButtonOptions: any;
	public maximizeButtonOptions: any;
	public currentTab: string = 'inprocess';
	public globalCurrency: string;
	constructor(
		private _CommissionService: CommissionService,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _ChangeDetectorRef: ChangeDetectorRef
	) {
		const that: any = this;
		// this.dxCardViewPopupComponent.position = { at: 'right top', my: 'right top', offset: "60 -10" };
		this.minimizeButtonOptions = {
			icon: 'chevronup',
			elementAttr: { class: 'settlement-close-ix' },
			onClick(e) {
				// console.log(e);
				// console.log(that.dxCardViewPopupComponent);
				if (that.dxCardViewPopupComponent.instance.option('height') === '100vh') {
					that.dxCardViewPopupComponent.instance.option('height', 100);
					that.dxCardViewPopupComponent.position = { at: 'right bottom', my: 'right bottom', offset: '10 0' };
					//e.component.option('icon', 'chevrondown');
					e.component.option({
						icon: 'chevrondown',
						elementAttr: { class: 'settlement-open-ix' }
					});
				} else {
					that.dxCardViewPopupComponent.instance.option('height', '100vh');
					that.dxCardViewPopupComponent.position = { at: 'right top', my: 'right top', offset: '0 60' };
					//e.component.option('icon', 'chevronup');
					e.component.option({
						icon: 'chevronup',
						elementAttr: { class: 'settlement-close-ix' }
					});
				}
			},
		};
	}
	ngOnInit(): void {
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.globalCurrency = this._LoginService.globalCurrency;
		this._CommissionService.setIsOpenMultiSettlmentPopUp(true);
		this.selectedMultiSettlementRowData = this.cardViewMultiSettlementProps.selectedMultiSettlementRowData;
		this.selectedMultiSettlementRowData.forEach(element => {
			element['status'] = 'inqueue';
		});
		this.inprocessListOfSettlement = this.selectedMultiSettlementRowData;
		this.doCallMultiSettlementReq();
	}
	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this._CommissionService.getIsOpenMultiSettlmentPopUp()) {
			this._CommissionService.setIsOpenMultiSettlmentPopUp(false);
		}
	}
	ngAfterViewInit() {
	}

	public clickMainTab(currentTab) {
		this.currentTab = currentTab;
		this.getListOfSettlement();
	}
	public getListOfSettlement() {
		this.inprocessListOfSettlement = this.selectedMultiSettlementRowData.filter(item => {
			return (item.status === 'inprocess' || item.status === 'warning' || item.status === 'loading' || item.status === 'inqueue');
		});
		this.successListOfSettlement = _.where(this.selectedMultiSettlementRowData, { status: 'success' });
	}
	async doCallMultiSettlementReq() {
		for (let index = 0; index < this.selectedMultiSettlementRowData.length; index++) {
			let item = this.selectedMultiSettlementRowData[index];
			item['status'] = 'loading';
			await this.syncMultiSettlementCalls(item).then(res => {
				if (this.selectedMultiSettlementRowData.length === index + 1) {
					this.doHiddenPopop();
				}
				// console.log('loop >>> ', res);
				this.clickMainTab('inprocess');
			}).catch(error => {
				this.clickMainTab('inprocess');
				// if (this.selectedMultiSettlementRowData.length === index + 1) {
				// 	this.doHiddenPopop();
				// }
				// console.log('loop error >>> ', error);
			});
		}
	}
	async syncMultiSettlementCalls(item) {
		return new Promise<any>((reject, resolve) => {

			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.CLOSE_CURRENT_SETTELEMENT);
			formData.append('contractguid', item['guid']);
			formData.append('contracttype', 'commissioncontracts');
			if (this.dataOutReqSubscription) {
				this.dataOutReqSubscription.unsubscribe();
			}
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
				next: (response) => {
					if (response) {
						if (response.flag) {
							if (response.data) {
								if (response.data['closesettlement'] && response.data['closesettlement'].length > 0) {
									item['settlementNo'] = response.data['closesettlement'][0]['ixcode'];
								}
							}
							item['status'] = 'success';
						} else {
							item['status'] = 'warning';
							item['warningMessage'] = response.message;
						}
						resolve(response);
					}
				}, error: (error) => {
					reject(false);
					item['status'] = 'error';
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
			});
		});
	}
	public doHiddenPopop(e?: any) {
		this.isShowPopup = false;
		this._CommissionService.setIsOpenMultiSettlmentPopUp(false);
		this.closeCardViewMultipleSettlementPopup.emit();
	}
	public closeCommissionSettlement(contractguid) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.CLOSE_CURRENT_SETTELEMENT);
		formData.append('contractguid', contractguid);
		formData.append('contracttype', 'commissioncontracts');
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

}
