<div id="rebatelist" class="rightBar">
	<div class="rightBlock">
		<div class="contentRow cf">
			<div class="borderBox cf">
				<div class="tableOut">
					<app-rebates-contract-listing [actionFrom]="'rebates'"></app-rebates-contract-listing>
				</div>
			</div>
		</div>
	</div>
</div>