import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';

@Component({
	selector: 'app-open-prospect-tiles',
	templateUrl: './open-prospect-tiles.component.html',
	styleUrls: ['./open-prospect-tiles.component.css']
})
export class OpenProspectTilesComponent implements OnInit, OnDestroy {
	public openProspects: number;
	public resourcesLoaded: boolean = false;
	private getOpenProspectSbsn: Subscription;

	constructor(
		public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService,
	) { }

	ngOnInit() {
		this.getOpenProspect();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_PROSPECT_TILES_EVENT', response => {
			if (response) {
				this.getOpenProspect();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getOpenProspectSbsn) {
			this.getOpenProspectSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_PROSPECT_TILES_EVENT');
	}

	private getOpenProspect() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.OPEN_PROSPECTS_TILE);
		if (this.getOpenProspectSbsn) {
			this.getOpenProspectSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getOpenProspectSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(
			response => {
				this.resourcesLoaded = false;
				if (response.flag) {
					if (response.data.tiles) {
						this.openProspects = response.data.tiles.open_prospects || 0;
					}
				}
			}, error => {
				this.resourcesLoaded = false;
			});
	}

	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
	}
}
