export class ChargebackContractModelUI {
	public ixcode: string;
	public guid: string;
	public percentOrDollar: string;
	public contractName: string;
	public selectCustomer: string;
	public selectCustomerId: string;
	public selectCustomerPriceListNum: string;
	public selectCustomerPriceListName: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public billBackType: string;
	public is_active: boolean;
	public pricelist_modified_date?: string;
	public pricelist_modified_user_action?: string;
	public chargeBackTabData: ChargeBackTabData = new ChargeBackTabData();
}

export class ChargebackContractModelRequest {
	public ixcode: string;
	public guid: string;
	public contractName: string;
	public selectCustomer: string;
	public selectCustomerId: string;
	public selectCustomerPriceListNum: string;
	public selectCustomerPriceListName: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public billBackType: string;
	public is_active: boolean;
	public pricelist_modified_date?: any;
	public pricelist_modified_user_action?: string;
	public chargebacks: ChargeBackItemTabModel[] = [];
	public chargeDocuments: ChargeDocumentsTabModel[] = [];
}

export class ChargeBackTabData {
	public itemTabData: any[] = [];
	public chargeBackDocumentsTabData: any[] = [];
}

export class CustomerTabModel {
	public selectCustomer: string;
	public selectCustomerId: string;
}

export class ChargeBackItemTabModel {
	public guid: string;
	public erp_primary_key: string;
	public itemsku: string;
	public itemname: string;
	public item_guid: string;
	public description?: string;
	public uomList: any;
	public uomgroupentry: any;
	public selectedUom: any;
	public listPrice: any;
	public chargebackAmount: any;
	public discountedPrice: any;
	public final_amount: any;
	public is_disbled_input_field: boolean;
	public bought: any;
	public sold: any;
	public claimed: any;
	public available: any;
	public last_invoice_date: string;
}

export class ChargeDocumentsTabModel {
	public guid: string;
	public contractGuid: string;
	public documentURL: string;
	public documentOriginalName: string;
	public documentReferenceNumber: string;
	public documentDocumentDescription?: string;
	public documentDate: string;
	public claimedStatus: string;
	public claimedStatusReason: string;
	public claimedUserNote: string;
	public claimedItems: ClaimedItemsModel[] = [];
}

export class ClaimedItemsModel {
	public itemCode: string;
	public itemName: string;
	public endUserCode: string;
	public endUserName: string;
	public claimed: number;
}

export class ClaimAttachedDocumentModel {
	public currentClaimElement: any;
	public categoriesList: any = [];
	constructor() {

	}
}

export class ChargeBackPriceListTabModel {
	public guid: string;
	public pricelistname: string;
	public pricelistno: string;
	public items: ChargeBackItemTabModel[] = [];
}
export class ChargebBackItemListTabModel {
	public guid: string;
	public items: ChargeBackItemTabModel[] = [];
}