<div id="wrapper" class="loginScreens">
  <div class="loginContainer verificationScreen cf" *ngIf="!isErrorFlag && isViewHTML && !isEmailAlreadyVerified">
    <div class="loginForm cf">
      <div class="formControls">
        <div class="row center">
          <img [src]="_AppCommonSrvc.cdnImgPath + 'verification-email.png'" alt="Verification Email"
            title="Verification Email" />
        </div>
        <div class="row">
          <div class="cols12">
            <h3>Congratulations!</h3>
            <p>You have successfully verified your email.</p>
          </div>
        </div>
        <div class="cf">
          <input type="button" (click)="login()" class="button payBtn cols12" value="Login Now" />
        </div>
      </div>
    </div>
  </div>

  <div class="loginContainer verificationScreen cf" *ngIf="!isErrorFlag && isViewHTML && isEmailAlreadyVerified">
    <div class="loginForm cf">
      <div class="formControls">
        <div class="row center">

          <img [src]="_AppCommonSrvc.cdnImgPath + 'already-verification-email.png'" alt="Already Verification Email"
            title="Already Verification Email" />

        </div>
        <div class="row">
          <div class="cols12">
            <h3>Email Already Verified</h3>
            <p>Your email address already verified with us.
              <span class="center"><br> Thanks!</span></p>
          </div>
        </div>
        <div class="cf">
          <input type="button" (click)="login()" class="button payBtn cols12" value="Login Now" />
        </div>
      </div>
    </div>
  </div>


  <div class="loginContainer verificationScreen cf" *ngIf="isViewHTML && isErrorFlag">
    <div class="loginForm cf">
      <div class="formControls">
        <div class="row">
          <div class="cols12">
            <h3>Error !</h3>
            <p>{{ErrorFlagMsg}}</p>
          </div>
        </div>
        <div class="cf">
          <input type="button" (click)="login()" class="button payBtn cols12" value="Login Now" />
        </div>
      </div>
    </div>
  </div>
</div>