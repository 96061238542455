<div class="numberBlock hoverClr" id="arCreditmemoPopover">
	<span (click)="doOpenArCreditmemoPopover($event)"
		[class]="isUserSettingsExisting ? 'settingIcon icon-setting-purple' : 'settingIcon icon-setting'"
		title="Setting Icon">
	</span>
	<div (click)="tilesClick('AR_CREDITMEMO')">
		<span class="contractIcon">
			<span class="icon-creditmemos"></span>
		</span>
		<div class="blockText">
			<strong>
				<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
				<span *ngIf="!resourcesLoaded">
					<span *ngIf="!isShowFullValue" (click)="doClickToShowFullValue($event)"
						class="dashboardamount shortamount">
						{{salesTotalForTheMonth || 0 | customFormatCurrencyPipe}}
						<span *ngIf="salesTotalForTheMonth >= 1000" class="dashboardamounttext">Click to see full value</span>
					</span>
					<div *ngIf="isShowFullValue" class="fullamount"
						[ngClass]="{'trillionamount ': getLastCharacterFromSortValue ===  'T' ,'quadrillionamount': getLastCharacterFromSortValue === 'Q' || getLastCharacterFromSortValue ==='E'}">
						{{salesTotalForTheMonth || 0 | customCurrencyPipe }}
					</div>
				</span>
			</strong>
			<span class="blockDate">{{currentMonthName || ''}} Credit Memos</span>
			<!-- <span class="blockDate"
			*ngIf="settlementPeriodStart && settlementPeriodEnd && settlementPeriodStart !== '' && settlementPeriodEnd !==''">{{ settlementPeriodStart | date: 'yyyy-MM-dd' }}
			to
			{{ settlementPeriodEnd | date: 'yyyy-MM-dd' }}
		</span> -->
			Total
		</div>
	</div>
</div>

<dx-popover target="#arCreditmemoPopover" position="bottom" [(visible)]="isShowArCreditmemoPopover"
	(onHidden)="doCloseArCreditmemoPopover($event)" [hideOnOutsideClick]="true" [showCloseButton]="true"
	[showTitle]="true" title="Credit Memos Tile" width="600">
	<div *dxTemplate="let data = model of 'content'">
		<app-tile-ar-creditmemo-settings *ngIf="isShowArCreditmemoPopover" [openFrom]="'ar_creditmemo_tile'"
			(refreshTile)="doRefreshTile($event)">
		</app-tile-ar-creditmemo-settings>
	</div>
</dx-popover>