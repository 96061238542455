import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { LoginService } from '@app/services/login.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownBoxComponent, DxDropDownButtonComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { EndUserDetailReportModel, ViewCreditMemoDetailPopUpModel, ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
import { Guid } from '@app/models/guid';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridPdf } from 'devextreme/pdf_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import DataGrid from "devextreme/ui/data_grid";
import * as moment from 'moment';
import { CustomerTabsModel } from '@app/models/end-users-chargeback-contract.model';
@Component({
	selector: 'app-eucc-view-claim-detail-popup',
	templateUrl: './eucc-view-claim-detail-popup.component.html',
	styleUrls: ['./eucc-view-claim-detail-popup.component.css']
})
export class EuccViewClaimDetailPopupComponent implements OnInit, OnDestroy {
	@Input() endUserViewDetailRptProps: any;
	@ViewChild("endUsersRptDataGridRef", {}) endUsersRptDataGridRef: DxDataGridComponent;
	public endUsersRptsDataSource: any;
	public isVisiblePopup: boolean = true;
	public titleText: string = 'Export Details Report';
	public isShowRptData: boolean = false;
	public pageOptions: PageOptions = new PageOptions();
	public filterValue: any[] = [];
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string = '';
	public listOfActions: any[] = this._EndUsersChargebackContractService.getListOfActionsDatagrid();
	public guid = new Guid();
	@ViewChild('refDrpDwnCustomers') refDrpDwnCustomers: DxDropDownBoxComponent;
	@ViewChild('refDrpDwnDatagridCustomers') refDrpDwnDatagridCustomers: DxDataGridComponent;
	public dxDataSourceCustomers: any;
	public filterValueCustomers: any[] = [];
	public sCustomersSelectedText: string;
	public selectedCustomers: any[] = [];
	public claimDate: any = null;
	private updateUserSettingSbsn: Subscription;
	private getChargebackDocumentListSubscription: Subscription;
	private getUomLstSbsn: Subscription;
	public mainSectionStorageKey = ServerSections.END_USER_DETAIL_REPORT;
	public customersTabs: any[] = [];
	public isShowInvoiceDetailPopUp: boolean = false;
	public isShowCreditMemoDetailPopUp: boolean = false;
	public viewInvoiceDetailProps: ViewInvoiceDetailPopUpModel = new ViewInvoiceDetailPopUpModel();
	public viewCreditMemoDetailProps: ViewCreditMemoDetailPopUpModel = new ViewCreditMemoDetailPopUpModel();
	// public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'claim', text: 'Claimed' }, { value: 'creditmemo', text: 'Credit Memo' }];
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'claim', text: 'Claimed' }];
	public listOfUoms: any = [];
	public listOfStatuses: any[] = this._EndUsersChargebackContractService.doGetListofApprovalStatus();
	public listOfHeaderFilterStatuses: any[] = this._EndUsersChargebackContractService.doGetListofApprovalStatusForHeaderFilter();
	public enable_chargeback_look_back_period: boolean = false;
	public stateStorageKey = 'end-user-chargeback-customer-rpt';
	public chargeBackDocumentDetails: any;
	loadViewChargebackDocDxDialogSubject: Subject<any> = new Subject();
	private filterValueChangeSbsn: Observable<any>;
	private isShowFilterBtn = true;
	public decimalPointForCurrency: number;
	constructor(
		public _LoginService: LoginService,
		public _RestApiService: RestApiService,
		public _LoaderService: LoaderService,
		public _ToastrService: ToastrService,
		private _ChangeDetectorRef: ChangeDetectorRef,
		public _EndUsersChargebackContractService: EndUsersChargebackContractService,
		public _AppCommonSrvc?: AppCommonSrvc,
	) {
		this.doDisplayExprCustomersName = this.doDisplayExprCustomersName.bind(this);
	}

	ngOnInit(): void {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.enable_chargeback_look_back_period = this._LoginService.loginUser.account_detail.enable_chargeback_look_back_period || false;
		}
		this.fetchListOfUoms();
		this.fetchedListOfCustomer();
		// setTimeout(() => {
		// 	this.filterValueChangeSbsn = this.endUsersRptDataGridRef.filterValueChange.asObservable();
		// 	this.filterValueChangeSbsn.subscribe((result) => {
		// 		this.getFilterText();
		// 	});
		// }, 60);
	}
	ngOnDestroy(): void {
		if (this.getChargebackDocumentListSubscription) {
			this.getChargebackDocumentListSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
	}

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				// this._LoaderService.hide();
			}
		});
	}

	doHiddenPopup() {
		this.isVisiblePopup = false;
	}

	// #region for Customer Dropdown

	// private doAutoSelectCustomersDrpDwn(arInvoiceDetailsCode: string) {
	// 	setTimeout(() => {
	// 		this.refDrpDwnCustomers.instance.open();
	// 		setTimeout(() => {
	// 			this.selectedCustomers = [arInvoiceDetailsCode];
	// 		}, 10);
	// 		this.refDrpDwnCustomers.instance.close();
	// 		this.isDisabledCustomersDrpDwn = true;
	// 	}, 50);
	// }


	private fetchedListOfCustomer() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS,
			view_fields: JSON.stringify(['guid', 'code', 'name', 'pricelistnum'])
		};

		this.dxDataSourceCustomers = AspNetData.createStore({
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: (method, ajaxOptions) => {
			},
			onLoaded(result) {
			}
		});
	}


	public doDisplayExprCustomers(company: any) {
		let returnStr = '';
		if (company && company.guid) {
			if (company.code) {
				returnStr = company.code + ' - ' + company.name;
			} else {
				returnStr = company.name || '';
			}
		}
		return returnStr;
	}

	public doDisplayExprCustomersName(event: any) {
		const endUserText = this.selectedCustomers.length > 0 ? this.selectedCustomers.length + ' Customer(s)' : '';
		return endUserText;
	}

	private setTextForCustomersDrpDwn() {
		if (this.selectedCustomers.length <= 0) {
			this.sCustomersSelectedText = '';
			return;
		}

		if (this.selectedCustomers.length >= 0) {
			this.sCustomersSelectedText = this.selectedCustomers.length + ' Customer(s) Selected';
		}

	}

	// Method used to change values event of customer dropdown
	public doValueChangedCustomersDrpDwn(e: any) {
		if (e.value === null) {
			this.sCustomersSelectedText = '';
			this.selectedCustomers = [];
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doClosedCustomersDrpDwn(e) {
		if (this.refDrpDwnDatagridCustomers) {
			this.refDrpDwnDatagridCustomers.instance.clearFilter('row');
		}
	}

	// Method used to select event on datagrid of customer selectbox
	public doSelectedChangedCustomers(e: any) {
		// this.arInvoiceViewDetailReportUI.customerDetails = [];
		// if (e.selectedRowsData && e.selectedRowsData.length > 0) {
		// 	for (let i = 0; i < e.selectedRowsData.length; i++) {
		// 		this.arInvoiceViewDetailReportUI.customerDetails.push(e.selectedRowsData[i]);
		// 	}
		// }
		this.setTextForCustomersDrpDwn();
	}
	// #endregion

	doGenerateReport() {
		this.getChargebackDetailRpt();
	}

	doExportExcel() {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let companyName = '';
		let companyImageBase64 = '';
		let fileName = 'EndUser_Detail_';
		if (this._LoginService.loginUser.account_detail.company_name) {
			companyName = this._LoginService.loginUser.account_detail.company_name;
		}
		if (this._LoginService.loginUser.account_detail.companylogo) {
			companyImageBase64 = this._LoginService.loginUser.account_detail.companylogo_base64;
		}

		fileName = fileName + '_Report.xlsx';
		if (this.customersTabs.length > 0) {
			for (let index = 0; index < this.customersTabs.length; index++) {
				const tabSheet = workbook.addWorksheet(this.customersTabs[index].title);
				const tabComaponentInstance = context.getDataGridInstance(this.customersTabs[index].key);
				let tabSheetObj =
				{
					worksheet: tabSheet,
					component: tabComaponentInstance,
					keepColumnWidths: true,
					autoFilterEnabled: false,
				}

				tabSheetObj['customizeCell'] = (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'claimedStatus') {
							const listOfDocTypeHeaderFilter = this.listOfStatuses;
							const indexDT = listOfDocTypeHeaderFilter.findIndex(i => i.value === gridCell.value);
							excelCell.value = '';
							if (indexDT !== -1) {
								excelCell.value = listOfDocTypeHeaderFilter[indexDT].finaltext;
							}
						}
						if (gridCell.column.dataField === 'type') {
							const listOfDocTypeHeaderFilter = this.listOfDocTypeHeaderFilter;
							const indexDT = listOfDocTypeHeaderFilter.findIndex(i => i.value === gridCell.value);
							excelCell.value = '';
							if (indexDT !== -1) {
								excelCell.value = listOfDocTypeHeaderFilter[indexDT].text;
							}
						}
						if (gridCell.column.dataField === 'selectedUom') {
							const listOfUoms = this.listOfUoms;
							const indexDT = listOfUoms.findIndex(i => i.code.toString().toLowerCase() === gridCell.value.toString().toLowerCase());
							excelCell.value = '';
							if (indexDT !== -1) {
								excelCell.value = listOfUoms[indexDT].name;
							}
						}

					}
				}
				exportDataGrid(tabSheetObj)
					.then(() => {
						if (index !== 0) {
							return exportDataGrid(tabSheetObj);
						}
					})
					//last index export file 
					.then(() => {
						if (index === (this.customersTabs.length - 1)) {
							workbook.xlsx.writeBuffer().then(function (buffer) {
								saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
							})
						}
					}
					)
			}
		} else {
			this._ToastrService.info('Data Not Found !', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
	}

	getDataGridInstance(tabkey) {
		let element = document.getElementById("endUsersRptDataGridRef_" + tabkey);
		let instance = DataGrid.getInstance(element) as DataGrid;
		return instance;
	}

	doOnTabOptionChanged(e) {
		//console.log('EEEE ', e);

	}
	doEndUsersRptToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'before',
				template: 'templateGridText',
			},
			{
				location: 'after',
				template: 'templateFilterText',
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		disabled: this.isDisabledClearBtn,
			// 		text: "Clear",
			// 		icon: "cleariconix",
			// 		onInitialized: (args: any) => {
			// 			this.instanceClearBtn = args.component;
			// 		},
			// 		onClick: () => {
			// 			this.endUsersRptDataGridRef.instance.clearFilter();
			// 		}
			// 	}
			// },
			{
				location: 'after',
				widget: 'dxButton',
				visible: this.isShowFilterBtn,
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	template: 'templateActionBtns',
			// 	visible: true,
			// },
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			// columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}

	}
	initializedEndUsersRptDataGrid(e) {
	}
	doOnCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0) {
				if (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum') {
					const docType = element.data['type'] || '';
					if (docType && docType == 'invoice') {
						const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
						viewInvoiceDetail.docNo = element.data[element.column.dataField];
						viewInvoiceDetail.docType = docType;
						viewInvoiceDetail.docDate = element.data.hasOwnProperty('docdate') ? element.data['docdate'] : '';
						this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
					}
					if (docType && docType == 'arcreditmemo') {
						const viewCreditMemoDetail = new ViewCreditMemoDetailPopUpModel();
						viewCreditMemoDetail.docNo = element.data[element.column.dataField];
						viewCreditMemoDetail.docType = docType;
						this.doOpenCreditMemoDetailPopup(viewCreditMemoDetail);
					}
				}
				if (element.column.dataField === 'claim_no') {
					this.getChargebackDocumentList(element.data);
				}
			}
		}
	}
	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.endUsersRptDataGridRef.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}
	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.endUsersRptDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}
	doActionsBtnItemClick(event) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.endUsersRptDataGridRef.instance.showColumnChooser();
					break;
			}
		}
	}
	doRefreshDataGrid() {
	}
	doExportToExcel() {

	}

	getChargebackDetailRpt() {
		// if (this.selectedCustomers.length === 0 && !this.claimDate) {
		// 	return;
		// }
		this._LoaderService.show();
		const customers: any = [];
		let itemClaimedData: any = [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_CHARGEBACK_DETAILS_REPORT);
		if (this.selectedCustomers.length > 0) {
			//this.customersTabs = this.selectedCustomers;
			formData.append('customers', JSON.stringify(this.selectedCustomers));
		}
		if (this.claimDate && this.enable_chargeback_look_back_period) {
			const claimDate = moment(this.claimDate).format('YYYY-MM-DD') || '';
			formData.append('claim_date', claimDate);
		}
		this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					itemClaimedData = response.data || [];
					if (itemClaimedData.length > 0) {
						this.setCustomerData(itemClaimedData);
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				//this.clearCustomerAndClaimDate();
				this.isShowRptData = true;
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	setCustomerData(itemClaimedData) {
		this.customersTabs = [];
		itemClaimedData.forEach(element => {
			let customerTabsModel = new CustomerTabsModel();
			customerTabsModel.key = element.hasOwnProperty('customer_code') ? element.customer_code : '';
			customerTabsModel.title = element.hasOwnProperty('customer_code') ? element.customer_code : '';
			customerTabsModel.data = element.hasOwnProperty('customer_data') ? element.customer_data : [];
			customerTabsModel.customer_name = element.hasOwnProperty('customer_name') ? element.customer_name : [];
			this.customersTabs.push(customerTabsModel);
		});
	}

	clearCustomerAndClaimDate() {
		this.selectedCustomers = [];
		this.claimDate = null;
	}

	public doLoadEndUsersRptState = () => {
		return this.loadStateDataGrid(this.stateStorageKey);
	}
	public doSaveEndUsersRptState = (e) => {
		this.saveStateDataGrid(e, this.stateStorageKey);
	}

	// Load RPT tab state data
	public loadStateDataGrid(configCode: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(this.mainSectionStorageKey)));
		} catch (e) {
			newSection = null;
		}
		const commissionSettlementReportConfigClone = newSection;
		if (!commissionSettlementReportConfigClone.configs) {
			commissionSettlementReportConfigClone.configs = [];
		}
		const statePersistentIndex = commissionSettlementReportConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (statePersistentIndex !== -1) {
			if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent) {
				return commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	//Save State Data
	public saveStateDataGrid(statePersistent: any, configCode: string) {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		let salesListingConfig: any = {};

		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(this.mainSectionStorageKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING);
		formData.append('user_settings', JSON.stringify(salesListingConfig));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}



	// Show Invoice Deatil PopUp
	private doOpenInvoiceDetailPopup(modelData: ViewInvoiceDetailPopUpModel) {
		this.viewInvoiceDetailProps.docNo = modelData.docNo;
		this.viewInvoiceDetailProps.docType = modelData.docType;
		this.viewInvoiceDetailProps.docDate = modelData.docDate;

		this.isShowInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowInvoiceDetailPopUp = true;
		}, 300);
	}

	// Show CreditMemo PopUp
	private doOpenCreditMemoDetailPopup(modelData: ViewCreditMemoDetailPopUpModel) {
		this.viewCreditMemoDetailProps.docNo = modelData.docNo;
		this.viewCreditMemoDetailProps.docType = modelData.docType;
		this.isShowCreditMemoDetailPopUp = false;
		setTimeout(() => {
			this.isShowCreditMemoDetailPopUp = true;
		}, 300);
	}

	private getChargebackDocumentList(data: any) {
		const reqFormData = new FormData();
		const searchParams = [['guid', '=', data['claim_guid']]];
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.CHARGEBACKDOC);
		reqFormData.append('filter', JSON.stringify(searchParams));
		if (this.getChargebackDocumentListSubscription) {
			this.getChargebackDocumentListSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.getChargebackDocumentListSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					if (response.data && response.data.length > 0) {
						this.chargeBackDocumentDetails = response.data[0];
						this.chargeBackDocumentDetails.claimedStatus = data['claimedStatus'] || '';
						// this.isViewApprovalPopUp = true;
						if (this.chargeBackDocumentDetails && this.chargeBackDocumentDetails.hasOwnProperty('contractGuid') && this.chargeBackDocumentDetails.contractGuid) {
							this._EndUsersChargebackContractService.getEndUserDetail(this.chargeBackDocumentDetails.contractGuid).subscribe({
								next: (response) => {
									this._LoaderService.hide();
									if (response.flag) {
										let responseData = response.data[0];
										if (responseData && !responseData.hasOwnProperty('is_enforce_quantity_sold')) {
											responseData['is_enforce_quantity_sold'] = true;
										}
										const objEndUsersChargebackContract = responseData;
										this.viewChargebackDocPopUp(objEndUsersChargebackContract, this.chargeBackDocumentDetails, data);
									} else {
										this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
									}
								}, error: (error) => {
									this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
									this._LoaderService.hide();
								}
							});
						}

					} else {
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public viewChargebackDocPopUp(objEndUsersChargebackContract, chargeBackDocumentDetails, data) {
		const dialogData = {
			claimId: chargeBackDocumentDetails.ixcode,
			notificationGuid: data.guid,
			callFrom: 'notifications',
			endUsersChargebackContractModelUI: objEndUsersChargebackContract,
			chargeBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
			billBaBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
		}
		this.loadViewChargebackDocDxDialogSubject.next(dialogData);
	}
}
