import { Component, OnInit, Inject, ViewChild, OnDestroy, ChangeDetectorRef, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import { LoginService } from '@app/services/login.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { TaskInsertUpdate, TaskInsertUpdateUI } from '@app/models/businessplan.model';
import { CUSTOM_DATE_FORMATS, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { TaskTypesValEnum, TaskTypesLblEnum, TaskStatusValEnum, TaskStatusLblEnum, NameFromServerKeyEnum, ErpTypeValEnum, CrmClientForEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { CommonOptions, GetRecordsReq } from '@app/models/common.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { Guid } from '@app/models/guid';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";

@Component({
	selector: 'app-ap-add-edit-task-dialog',
	templateUrl: './ap-add-edit-task-dialog.component.html',
	styleUrls: ['./ap-add-edit-task-dialog.component.css'],
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
	],
})

export class ApAddEditTaskDialogComponent implements OnInit, OnDestroy {
	@Input() addeditTaskDetailsProps: any;
	@Output() closeAddEditTaskPopup = new EventEmitter<any>();

	public CrmClientForEnum = CrmClientForEnum;
	public minDate: Date;
	public taskTypes: CommonOptions[] = [];
	public taskTypeId: any = undefined;
	public taskStatuses: CommonOptions[] = [];
	public taskInsertUpdateUI: TaskInsertUpdateUI = new TaskInsertUpdateUI();
	public taskInsertUpdate: TaskInsertUpdate = new TaskInsertUpdate();
	public isEditMode = false;
	public contactemployees: any[] = [];
	public contactEmpployeeGuid: any[] = [];
	public selectedguidcontactperson: any = undefined;

	public isReadOnlyClient: boolean = false;
	public isDisplayRemark: boolean = true;
	public isAllReadOnlyForClosedTask: boolean = false;
	private currentEntity: string = undefined;
	public clientFor: string = 'leads';
	public clientsList: any;
	public searchParams: any[] = [];
	private getRecordsReq: GetRecordsReq = new GetRecordsReq();
	private clientSearchTimeout: any;
	public isQBErpUser: boolean = false;
	private clientDetails: any = {};
	private clientGuid: string;
	private allPermissions: any[] = [];
	private viewFields = ['guid', 'code', 'name', 'crm_type'];
	private updateCrmSubscription: Subscription;
	private fetchCrmListSubscription: Subscription;
	private guid = new Guid();
	public listOfTimeInvervals: any[] = [];
	private erp_type: string;
	public listOfAssignToUsers: any[] = [];
	private getListOfAssignToUserSubscription: Subscription;
	private listOfAssignToClient: string[] = [];
	public listOfAssignToFiltered: any[] = [];
	private getPropertyOptionsSubscription: Subscription;
	public listOfTaskTypes: any[] = [];
	public taskFilterValue: any = [];
	public aSelKeysCompany: any = [];
	public isDropdownOpenedCompany: boolean;
	public isShowPopup: boolean;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	private addeditTaskDetailsObj: any;
	// #region for Angular Life cycle
	constructor(private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _CrmsService: CrmsService,
		private _GlobalStateService: GlobalStateService,
		private _ChangeDetectorRef: ChangeDetectorRef) {
		// Get current permissions
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
	}

	ngOnInit() {
		this.isShowPopup = true;
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		}
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		this.taskStatuses = this.getTaskStatus();
		this.listOfTimeInvervals = this._CrmsService.getListOfTimeIntervals();

		this.loadListOfCompanies();
		this._GlobalStateService.subscribe('GOT_ASSIGNED_TO_DATA_EVENT', response => {
			if (response) {
				if (response.isDefault) {
					this.setAssignToData();
				} else {
					this.setAssignToData(response);
				}
			}
		});
		this._GlobalStateService.subscribe('GOT_TASK_TYPE_DATA_EVENT', response => {
			if (response) {
				if (response.isDefault) {
					this.setTaskType();
				} else {
					this.setTaskType(response);
				}
			}
		});
		this.fetchListOfTaskTypes();
		if (this.isEditMode) {
			// Edit Task Section
			this.editTaskInIt();
		} else {
			// Add Task Section
			this.addTaskInIt();
		}
	}
	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['addeditTaskDetailsProps']) {
			const taskDetails = _SimpleChanges['addeditTaskDetailsProps'].currentValue;
			this.addeditTaskDetailsObj = taskDetails;
			this.isEditMode = taskDetails.isEditMode;
			this.taskTypeId = taskDetails.tasktype;
			this.currentEntity = taskDetails.currentEntity;
			this.clientFor = taskDetails.clientFor;
			if (taskDetails.clientGuid) {
				this.clientGuid = taskDetails.clientGuid;
			}
			if (taskDetails.clientDetails) {
				this.clientDetails = taskDetails.clientDetails;
			}
			if (taskDetails.contactEmpployeeGuid) {
				this.contactEmpployeeGuid = taskDetails.contactEmpployeeGuid;
			}
			this.isAllReadOnlyForClosedTask = taskDetails.isAllReadOnlyForClosedTask || false;
			this.taskInsertUpdateUI.clientfrom = taskDetails.clientFor;
		}
	}
	ngOnDestroy() {
		if (this.fetchCrmListSubscription) {
			this.fetchCrmListSubscription.unsubscribe();
		}
		if (this.updateCrmSubscription) {
			this.updateCrmSubscription.unsubscribe();
		}
		if (this.getListOfAssignToUserSubscription) {
			this.getListOfAssignToUserSubscription.unsubscribe();
		}
		if (this.getPropertyOptionsSubscription) {
			this.getPropertyOptionsSubscription.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('GOT_ASSIGNED_TO_DATA_EVENT');
		this._GlobalStateService.unsubscribe('GOT_TASK_TYPE_DATA_EVENT');
	}
	// #endregion

	// #region for Public Methods

	public doChangedTaskStatus(e) {
		this.isDisplayRemark = true;
		if (this.taskInsertUpdateUI.status === TaskStatusValEnum.CLOSE) {
			this.isDisplayRemark = false;
		}
	}

	// Method to Save/Update Task Object
	public saveupdateTask() {
		if (!this.isEditMode) {
			this.clientDetails = this.taskInsertUpdateUI.clientdetail;
		}
		if (!this.taskInsertUpdateUI.title || (this.taskInsertUpdateUI.title && !this.taskInsertUpdateUI.title.trim())) {
			this.taskInsertUpdateUI.title = '';
			this._ToastrService.info('Task Title is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		} else if (!this.taskInsertUpdateUI.tasktype) {
			this._ToastrService.info('Task Type is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		} else if (!this.taskInsertUpdateUI.enddate) {
			this._ToastrService.info('Due Date is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		//  else if (this.taskInsertUpdateUI.startdate && this.taskInsertUpdateUI.enddate) {
		// 	const mStart = moment(this.taskInsertUpdateUI.startdate);
		// 	const mEnd = moment(this.taskInsertUpdateUI.enddate);
		// 	if (!mStart.isSameOrBefore(mEnd)) {
		// 		this._ToastrService.info('End Date should not be less than Start Date.', 'Info', { closeButton: true, tapToDismiss: true });
		// 		return;
		// 	}
		// }

		if (this.clientDetails.contactemployees && this.clientDetails.contactemployees.length <= 0) {
			this._ToastrService.info('Please add a Contact Person to this Client before creating Tasks', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		if (this.isAllReadOnlyForClosedTask) {
			if (!this.taskInsertUpdateUI.closedtasknote || (this.taskInsertUpdateUI.closedtasknote && !this.taskInsertUpdateUI.closedtasknote.trim())) {
				this.taskInsertUpdateUI.closedtasknote = this.taskInsertUpdateUI.closedtasknote.trim();
				this._ToastrService.info('Closed Task Note is required.', 'Info', { closeButton: true, tapToDismiss: true });
				return;
			}
		}

		const clientDetailsClone = JSON.parse(JSON.stringify(this.clientDetails));
		let timelinesInsertUpdate;
		if (this.isEditMode) {
			this.taskInsertUpdate.title = this.taskInsertUpdateUI.title.trim();
			this.taskInsertUpdate.tasktype = this.taskInsertUpdateUI.tasktype;
			this.taskInsertUpdate.notes = this.taskInsertUpdateUI.notes || '';
			// this.taskInsertUpdate.startdate = this.taskInsertUpdateUI.startdate;
			this.taskInsertUpdate.enddate = this.taskInsertUpdateUI.enddate;
			this.taskInsertUpdate.timeinterval = this.taskInsertUpdateUI.timeinterval;
			this.taskInsertUpdate.status = this.taskInsertUpdateUI.status;
			this.taskInsertUpdate.assignto = this.taskInsertUpdateUI.assignto || '';
			this.taskInsertUpdate.guid = this.taskInsertUpdateUI.guid;
			this.taskInsertUpdate.closedtasknote = undefined;
			if (this.taskInsertUpdateUI.status === TaskStatusValEnum.CLOSE) {
				this.taskInsertUpdate.closedtasknote = this.taskInsertUpdateUI.closedtasknote;
			}
			if (this.taskInsertUpdateUI.startdate) {
				this.taskInsertUpdate.startdate = moment(this.taskInsertUpdateUI.startdate).format('YYYY-MM-DD');
			}
			if (this.taskInsertUpdateUI.enddate) {
				this.taskInsertUpdate.enddate = moment(this.taskInsertUpdateUI.enddate).format('YYYY-MM-DD');
			}
			if (this.clientFor === CrmClientForEnum.LEADS) {
				timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Updated', 'leadtasks', this.taskInsertUpdate.guid, 'Task ' + this.taskInsertUpdate.title.trim() + ' successfully updated.');
			}
			if (this.clientFor === CrmClientForEnum.PROSPECTS) {
				timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Updated', 'prospecttasks', this.taskInsertUpdate.guid, 'Task ' + this.taskInsertUpdate.title + ' successfully updated.');
			}
			if (this.clientFor === CrmClientForEnum.CLIENTS) {
				timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Updated', 'clienttasks', this.taskInsertUpdate.guid, 'Task ' + this.taskInsertUpdate.title + ' successfully updated.');
				clientDetailsClone.issynctoerp = false;
			}

			const ceIndex = clientDetailsClone.contactemployees.findIndex(ce => ce.guid === this.contactEmpployeeGuid);
			if (!clientDetailsClone.contactemployees[ceIndex].timelines) {
				clientDetailsClone.contactemployees[ceIndex].timelines = [];
			}
			const taskIndex = clientDetailsClone.contactemployees[ceIndex].tasks.findIndex(task => task.guid === this.taskInsertUpdate.guid);
			clientDetailsClone.contactemployees[ceIndex].tasks[taskIndex] = this.taskInsertUpdate;
			clientDetailsClone.contactemployees[ceIndex].timelines.push(timelinesInsertUpdate);
			clientDetailsClone.lastactivity = timelinesInsertUpdate;
		} else {
			this.taskInsertUpdate = new TaskInsertUpdate();
			this.taskInsertUpdate.title = this.taskInsertUpdateUI.title.trim();
			this.taskInsertUpdate.tasktype = this.taskInsertUpdateUI.tasktype;
			this.taskInsertUpdate.notes = this.taskInsertUpdateUI.notes || '';
			// this.taskInsertUpdate.startdate = this.taskInsertUpdateUI.startdate;
			this.taskInsertUpdate.enddate = this.taskInsertUpdateUI.enddate;
			this.taskInsertUpdate.timeinterval = this.taskInsertUpdateUI.timeinterval;
			this.taskInsertUpdate.status = this.taskInsertUpdateUI.status;
			this.taskInsertUpdate.assignto = this.taskInsertUpdateUI.assignto || '';
			this.taskInsertUpdate.guid = this.guid.newGuid();
			if (this.taskInsertUpdateUI.startdate) {
				this.taskInsertUpdate.startdate = moment(this.taskInsertUpdateUI.startdate).format('YYYY-MM-DD');
			}
			if (this.taskInsertUpdateUI.enddate) {
				this.taskInsertUpdate.enddate = moment(this.taskInsertUpdateUI.enddate).format('YYYY-MM-DD');
			}
			if (this.clientFor === CrmClientForEnum.LEADS) {
				timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Created', 'leadtasks', this.taskInsertUpdate.guid, 'Task ' + this.taskInsertUpdate.title.trim() + ' successfully created.');
			}
			if (this.clientFor === CrmClientForEnum.PROSPECTS) {
				timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Created', 'prospecttasks', this.taskInsertUpdate.guid, 'Task ' + this.taskInsertUpdate.title.trim() + ' successfully created.');
			}
			if (this.clientFor === CrmClientForEnum.CLIENTS) {
				timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Created', 'clienttasks', this.taskInsertUpdate.guid, 'Task ' + this.taskInsertUpdate.title.trim() + ' successfully created.');
				clientDetailsClone.issynctoerp = false;
			}
			const ceIndex = clientDetailsClone.contactemployees.findIndex(ce => ce.guid === this.taskInsertUpdateUI.guidcontactperson);
			if (!clientDetailsClone.contactemployees[ceIndex].timelines) {
				clientDetailsClone.contactemployees[ceIndex].timelines = [];
			}
			if (!clientDetailsClone.contactemployees[ceIndex].tasks) {
				clientDetailsClone.contactemployees[ceIndex].tasks = [];
			}
			clientDetailsClone.contactemployees[ceIndex].tasks.push(this.taskInsertUpdate);
			clientDetailsClone.contactemployees[ceIndex].timelines.push(timelinesInsertUpdate);
			clientDetailsClone.lastactivity = timelinesInsertUpdate;
		}

		const contacts = [];
		const contentData = [];
		contentData.push(clientDetailsClone);
		contacts.push({
			action: 'edit',
			type: this.currentEntity,
			content: contentData
		});
		if (this.updateCrmSubscription) {
			this.updateCrmSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.updateCrmSubscription = this.updateClientData(contacts).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
					this.closeAddEditTaskPopup.emit({ isSucess: true, clientDetails: clientDetailsClone, response: response });
					this.isShowPopup = false;
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			} else {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	public doSelectionChangedCompany(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			const client = e.selectedRowsData[0];
			this.taskInsertUpdateUI.clientname = client.name;
			this.taskInsertUpdateUI.guidclient = client.guid;
			this.taskInsertUpdateUI.clientdetail = client;
			this.taskInsertUpdateUI.clientfrom = client.crm_type;
			this.currentEntity = client.crm_type;
			if (client.assignto) {
				this.taskInsertUpdateUI.assignto = client.assignto;
			}
			this.listOfAssignToFiltered = [];
			this.listOfAssignToClient = client.assignto || [];
			if (client.contactemployees && client.contactemployees.length > 0) {
				this.contactemployees = client.contactemployees;
				const contactEmployeeIndex = this.contactemployees.findIndex(itemEle => itemEle.name === client.contactperson);
				if (contactEmployeeIndex !== -1) {
					this.taskInsertUpdateUI.guidcontactperson = this.contactemployees[contactEmployeeIndex].guid;
					this.taskInsertUpdateUI.contactpersondetail = JSON.parse(JSON.stringify(this.contactemployees[contactEmployeeIndex]));
				} else if (this.contactemployees && this.contactemployees.length > 0) {
					this.taskInsertUpdateUI.guidcontactperson = this.contactemployees[0].guid;
					this.taskInsertUpdateUI.contactpersondetail = JSON.parse(JSON.stringify(this.contactemployees[0]));
				}
			}
			this.getListOfAssignToUsers();
		}
	}

	// Method used to get Client Details
	private updateClientData(updatedClientData: any): Observable<any> {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contacts', JSON.stringify(updatedClientData));
		return this._RestApiService.doDataInFormDataReq(formData);
	}

	// Method to Fetch Task Status like Open/Close
	private getTaskStatus() {
		const taskStatuses: CommonOptions[] = [];
		for (const taskStatus in TaskStatusValEnum) {
			if (TaskStatusValEnum[taskStatus]) {
				const commonOption = new CommonOptions();
				commonOption.id = TaskStatusValEnum[taskStatus];
				commonOption.name = TaskStatusLblEnum[taskStatus];
				taskStatuses.push(commonOption);
			}
		}
		return taskStatuses;
	}

	public doCompanydropdownOptionChanged(e) {
		if (e.name === 'value') {
			this.isDropdownOpenedCompany = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method to Select Client after searched
	public clientSearchListClick(client: any, id: string) {
		// this.currentEntity = client.crm_type;
		this.fetchCrmDetails(client.guid);
	}

	// #endregion

	// #region for Private Methods


	private loadListOfCompanies() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CRMDATA,
		};

		this.clientsList = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: function (method, ajaxOptions) {
			},
			onLoaded(result) {
			}
		});
	}



	// Set table grid data
	private fetchCrmDetails(clientGuid: string) {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('entity', this.currentEntity);
		reqFormData.append('method', 'getEntityData');
		reqFormData.append('is_dropdown', 'true');
		reqFormData.append('search', JSON.stringify([{ guid: clientGuid }]));
		if (this.fetchCrmListSubscription) {
			this.fetchCrmListSubscription.unsubscribe();
		}
		this.fetchCrmListSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe(response => {
			if (response.flag) {
				if (response.data && response.data.length > 0) {
					this.clientsList = [];
					const clientDetails = response.data[0];
					this.taskInsertUpdateUI.clientname = clientDetails.name;
					this.taskInsertUpdateUI.guidclient = clientDetails.guid;
					this.taskInsertUpdateUI.clientfrom = clientDetails.crm_type;
					if (!this.isEditMode) {
						// if (clientDetails.assignto) {
						// 	this.taskInsertUpdateUI.assignto = clientDetails.assignto;
						// }
					}
					this.clientDetails = JSON.parse(JSON.stringify(clientDetails));
					if (this.clientDetails.contactemployees && this.clientDetails.contactemployees.length > 0) {
						this.contactemployees = this.clientDetails.contactemployees || [];
						const contactEmployeeIndex = this.contactemployees.findIndex(itemEle => itemEle.name === this.clientDetails.contactperson);
						if (contactEmployeeIndex !== -1) {
							this.contactEmpployeeGuid = this.contactemployees[contactEmployeeIndex].guid;
							this.taskInsertUpdateUI.guidcontactperson = this.contactemployees[contactEmployeeIndex].guid;
						} else if (this.contactemployees && this.contactemployees.length > 0) {
							this.contactEmpployeeGuid = this.contactemployees[0].guid;
							this.taskInsertUpdateUI.guidcontactperson = this.contactemployees[0].guid;
						}
					}
					this.listOfAssignToClient = clientDetails.assignto || [];
					this.getListOfAssignToUsers();
				}
			} else {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	// Method To set Data during AddMode
	private addTaskInIt() {
		this.taskInsertUpdateUI.tasktype = this.taskTypeId;
		this.taskInsertUpdateUI.status = TaskStatusValEnum.OPEN;
		this.taskInsertUpdateUI.timeinterval = this.listOfTimeInvervals[0].id;
		this.taskInsertUpdateUI.assignto = '';
		this.isReadOnlyClient = false;

		if (this.clientGuid !== undefined) {
			this.isReadOnlyClient = true;
			const clientDetail = this.clientDetails;
			if (clientDetail !== undefined) {
				this.taskInsertUpdateUI.clientname = clientDetail.name;
				this.taskInsertUpdateUI.guidclient = clientDetail.guid;
				this.taskInsertUpdateUI.clientdetail = clientDetail;
				this.aSelKeysCompany = [];
				this.aSelKeysCompany.push(clientDetail.guid);
				this.contactemployees = clientDetail.contactemployees || [];
				const contactEmployeeIndex = clientDetail.contactemployees.findIndex(itemEle => itemEle.guid === this.contactEmpployeeGuid);
				if (contactEmployeeIndex !== -1) {
					this.contactEmpployeeGuid = clientDetail.contactemployees[contactEmployeeIndex].guid;
					this.taskInsertUpdateUI.guidcontactperson = clientDetail.contactemployees[contactEmployeeIndex].guid;
				}
				this.listOfAssignToClient = clientDetail.assignto || [];
				this.getListOfAssignToUsers();
			}
		}
	}

	// Method To set Data during EditMode
	private editTaskInIt() {
		if (this.isEditMode) {
			this.isReadOnlyClient = true;
			const clientDetails = this.clientDetails;
			this.taskInsertUpdateUI.clientname = clientDetails.name;
			this.taskInsertUpdateUI.guidclient = clientDetails.guid;
			this.aSelKeysCompany = [];
			this.aSelKeysCompany.push(clientDetails.guid);
			this.contactemployees = clientDetails.contactemployees || [];
			const ceIndex = clientDetails.contactemployees.findIndex(itemEle => itemEle.guid === this.contactEmpployeeGuid);
			this.contactEmpployeeGuid = clientDetails.contactemployees[ceIndex].guid;
			this.taskInsertUpdateUI.guidcontactperson = clientDetails.contactemployees[ceIndex].guid;
			this.taskInsertUpdateUI.contactpersondetail = JSON.parse(JSON.stringify(this.contactemployees[ceIndex]));

			const taskIndex = clientDetails.contactemployees[ceIndex].tasks.findIndex(task => task.guid === this.addeditTaskDetailsObj.taskGuid);
			const taskData: any = JSON.parse(JSON.stringify(clientDetails.contactemployees[ceIndex].tasks[taskIndex]));
			this.taskInsertUpdateUI.tasktype = taskData.tasktype;
			this.taskInsertUpdateUI.title = taskData.title || '';
			this.taskInsertUpdateUI.status = taskData.status;
			this.taskInsertUpdateUI.assignto = taskData.assignto || '';
			this.taskInsertUpdateUI.notes = taskData.notes || '';
			this.taskInsertUpdateUI.guid = taskData.guid;
			this.taskInsertUpdateUI.closedtasknote = taskData.closedtasknote || '';

			if (this.isAllReadOnlyForClosedTask) {
				setTimeout(() => {
					this.taskInsertUpdateUI.status = TaskStatusValEnum.CLOSE;
				}, 100);
				this.isDisplayRemark = false;
			}
			// if (taskData.startdate) {
			// 	this.taskInsertUpdateUI.startdate = moment(taskData.startdate).toDate();
			// }
			if (taskData.enddate) {
				this.taskInsertUpdateUI.enddate = moment(taskData.enddate).toDate();
			}
			this.taskInsertUpdateUI.timeinterval = this.listOfTimeInvervals[0].id;
			if (taskData.timeinterval) {
				this.taskInsertUpdateUI.timeinterval = taskData.timeinterval;
			}
			this.listOfAssignToClient = clientDetails.assignto || [];
			this.getListOfAssignToUsers();
		}
	}
	// #endregion

	// #region for Logic related Assigned To

	// Method used to Fetched list of AssignTO Users
	private getListOfAssignToUsers() {
		let listOfUserTypes = [];
		try {
			listOfUserTypes = this._LoginService.loginUser.account_detail.userType || [];
		} catch (error) {
			listOfUserTypes = [];
		}
		const selectedListOfUserTypes = listOfUserTypes.filter(userType => userType.can_receive_incentives === true);

		if (selectedListOfUserTypes && selectedListOfUserTypes.length > 0) {
			const listOfRoles = [];
			selectedListOfUserTypes.forEach(userType => {
				listOfRoles.push(userType.user_type);
			});
			const listOfSearches = [];
			listOfSearches.push({ status: 'active' });
			listOfSearches.push({ role: listOfRoles });
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('method', 'getEntityData');
			reqFormData.append('entity', 'subusers');
			reqFormData.append('is_dropdown', 'true');
			reqFormData.append('search', JSON.stringify(listOfSearches));
			const viewFields = ['sub_account_id', 'first_name', 'last_name', 'role', 'status'];
			reqFormData.append('view_fields', JSON.stringify(viewFields));
			if (this.getListOfAssignToUserSubscription) {
				this.getListOfAssignToUserSubscription.unsubscribe();
			}
			this.listOfAssignToUsers = [];
			this.getListOfAssignToUserSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe(response => {
				if (response && response.flag) {
					this.listOfAssignToUsers = response.data || [];
					this._GlobalStateService.notifyDataChangedDuplicate('GOT_ASSIGNED_TO_DATA_EVENT', { isDefault: true });
				}
			}, error => {
			});
		}
	}

	private setAssignToData(assignTo?: string) {
		this.setListOfAssignToFilterd();
		const sub_account_id = this._LoginService.loginUser.account_detail.sub_account_id;
		let subAccountIdIndex = -1;
		if (this.isEditMode) {
			if (!this.taskInsertUpdateUI.assignto) {
				subAccountIdIndex = this.listOfAssignToFiltered.findIndex(user => user.sub_account_id === sub_account_id);
				if (subAccountIdIndex === -1) {
					subAccountIdIndex = 0;
				}
				if (this.listOfAssignToFiltered && this.listOfAssignToFiltered.length > 0) {
					this.taskInsertUpdateUI.assignto = this.listOfAssignToFiltered[subAccountIdIndex].sub_account_id;
				}
			}
		} else {
			subAccountIdIndex = this.listOfAssignToFiltered.findIndex(user => user.sub_account_id === sub_account_id);
			if (subAccountIdIndex === -1) {
				subAccountIdIndex = 0;
			}
			if (this.listOfAssignToFiltered && this.listOfAssignToFiltered.length > 0) {
				this.taskInsertUpdateUI.assignto = this.listOfAssignToFiltered[subAccountIdIndex].sub_account_id;
			}
		}
	}

	// Method used to Filtered AssignTo List from assignto property of Lead, Prospect, Client
	private setListOfAssignToFilterd() {
		const listOfCustomAssignToData = [];
		this.listOfAssignToClient.forEach(user => {
			const selectedIndex = this.listOfAssignToUsers.findIndex(assignTo => assignTo.sub_account_id === user);
			if (selectedIndex !== -1) {
				listOfCustomAssignToData.push(this.listOfAssignToUsers[selectedIndex]);
			}
		});
		this.listOfAssignToFiltered = listOfCustomAssignToData;
	}

	public getAssignToDisplayExpr(e) {
		if (e) {
			if (e.last_name) {
				return e.first_name + ' ' + e.last_name;
			} else {
				return e.first_name;
			}
		}
		return e || '';
	}

	public getContactEmployeeNameDisplayExpr(e) {
		if (e) {
			if (e.firstname) {
				if (e.lastname) {
					return e.firstname + ' ' + e.lastname;
				} else {
					return e.firstname;
				}
			} else {
				return e.name;
			}
		}
		return e || '';
	}

	// #endregion

	// #region for Fetched List of Task Types

	// Method used to Fetch list of Task types
	private fetchListOfTaskTypes() {
		let dynamic_groups = [];
		let dynamic_properties = [];
		try {
			dynamic_groups = this._LoginService.loginUser.account_detail.dynamic_groups || [];
			dynamic_properties = this._LoginService.loginUser.account_detail.dynamic_properties || [];
		} catch (error) {
			dynamic_groups = [];
			dynamic_properties = [];
		}

		const dgIndex = dynamic_groups.findIndex(dg => dg.group_name.toString().toLowerCase() === 'task');
		if (dgIndex !== -1) {
			const dgData = dynamic_groups[dgIndex];
			const dpIndex = dynamic_properties.findIndex(dp => dp.group_guid === dgData.guid && dp.property.toString().toLowerCase() === 'tasktype');
			if (dpIndex !== -1) {
				const dpData = dynamic_properties[dpIndex];
				const reqFormData = new FormData();
				reqFormData.append('usr', this._LoginService.loginUser.user);
				reqFormData.append('token', this._LoginService.loginUser.token);
				reqFormData.append('method', 'getEntityData');
				reqFormData.append('entity', 'dynamicPropertyOptions');
				reqFormData.append('search', JSON.stringify([{ property_guid: dpData.guid }]));
				reqFormData.append('sort_dir', 'asc');
				reqFormData.append('sort_field', 'sequence');
				reqFormData.append('view_fields', JSON.stringify(['guid', 'property_guid', 'value', 'label', 'sequence']));
				if (this.getPropertyOptionsSubscription) {
					this.getPropertyOptionsSubscription.unsubscribe();
				}
				this.listOfTaskTypes = [];
				this._LoaderService.show();
				this.getPropertyOptionsSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe(response => {
					this._LoaderService.hide();
					if (response && response.flag) {
						this.listOfTaskTypes = response.data || [];
						this._GlobalStateService.notifyDataChangedDuplicate('GOT_TASK_TYPE_DATA_EVENT', { isDefault: true });
					}
				}, error => {
					this._LoaderService.hide();
				});
			}
		}
	}

	// Method used to Set Task Type
	private setTaskType(response?: any) {
		if (this.isEditMode) {
			if (!this.taskInsertUpdateUI.tasktype || (this.taskInsertUpdateUI && !this.taskInsertUpdateUI.tasktype)) {
				try {
					this.taskInsertUpdateUI.tasktype = this.listOfTaskTypes[0].value;
				} catch (error) {
					this.taskInsertUpdateUI.tasktype = '';
				}
			} else {
				const ttIndex = this.listOfTaskTypes.findIndex(tt => tt.value === this.taskInsertUpdateUI.tasktype);
				if (ttIndex === -1) {
					try {
						this.taskInsertUpdateUI.tasktype = this.listOfTaskTypes[0].value;
					} catch (error) {
						this.taskInsertUpdateUI.tasktype = '';
					}
				}
			}
		} else {
			try {
				this.taskInsertUpdateUI.tasktype = this.taskTypeId || this.listOfTaskTypes[0].value;
			} catch (error) {
				this.taskInsertUpdateUI.tasktype = '';
			}
		}
	}
	// #endregion
}
