<div class="contentRow cf">
	<!-- <div class="titleRow">
		<h1>Settlement Report List</h1>
	</div> -->
	<div class="borderBox cf padB0">
		<div class="row contractDetailsText">
			<div class="cols12 mrgT10">
				<ul class="listText">
					<li>Contract Name:
						<strong class="purple">{{rebatesDetails.contractName || ''}}</strong>
					</li>
					<li
						*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive') && rebatesDetails.rebateType === 'sales'">
						Customer Name:
						<strong class="purple">{{rebatesDetails.selectCustomer || ''}}</strong>
					</li>
					<li
						*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive')  && rebatesDetails.rebateType == 'purchasing'">
						Vendor Name:
						<strong class="purple">{{rebatesDetails.selectVendor || ''}}</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'buyinggroup'"> Buying Group:
						<strong class="purple">{{rebatesDetails.selectBuyingGroup || ''}}</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'individual'"> Type:
						<strong class="purple">Individual &nbsp;
							<span *ngIf="rebatesDetails.rebateType == 'sales'">(Sales Rebate)</span>
							<span *ngIf="rebatesDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
						</strong>
					</li>
					<li *ngIf="rebatesDetails.individualOrGroup === 'growth_incentive'"> Type:
						<strong class="purple">Growth Incentive &nbsp;
							<span *ngIf="rebatesDetails.rebateType == 'sales'">(Sales Rebate)</span>
							<span *ngIf="rebatesDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
						</strong>
					</li>
				</ul>
			</div>
			<!-- <div class="cols3 mrgB20 txtRight">
				<div class="multiBtnsRow mrgT0">
					<div class="btnsGroup">
						<button class="button" (click)="doUpdateSettlementPeriodClick()"
							*ngxPermissionsOnly="isCurrentTabPermission">Update
							Current Settlement
						</button>
					</div>
				</div>
			</div> -->
		</div>
		<div class="cf">
			<div class="tableOut">

				<dx-data-grid #invoiceDataGridContainer id="gridContainer" class="cellColor hoverClr hideFilterPanelInx"
					[dataSource]="creditMemoListDataSource" [showBorders]="true" [showColumnLines]="true"
					[showRowLines]="false" [dateSerializationFormat]="'yyyy-MM-dd'" [allowColumnResizing]="true"
					[filterSyncEnabled]="true" [remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'"
					[masterDetail]="{ enabled: true, template: 'itemsDetailsTemplate','autoExpandAll': false }"
					(onToolbarPreparing)="doToolbarPreparing($event)" (onCellClick)="doCellClickCreditMemo($event)">
					<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
					<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
					</dxo-pager>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="true"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
					<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadRebateCreditMemoState"
						[customSave]="doSaveRebateCredutMemoState" storageKey="rebates-cradit-memo-datagrid-state">
					</dxo-state-storing>
					<div *dxTemplate="let data of 'templateFilterText'">
						<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
							<span>{{filterText}}</span>
						</div>
					</div>

					<dxi-column dataField="invoiceCode" caption="Invoice ID" dataType="number"
						cellTemplate="cellTemplateinvoiceCode">
						<div *dxTemplate="let element of 'cellTemplateinvoiceCode'">
							{{ element.data.invoiceCode || '' }}
						</div>
					</dxi-column>

					<dxi-column dataField="invoiceDate" alignment="center" caption="Invoice Date" dataType="date"
						format="yyyy-MM-dd" cellTemplate="cellTemplateInvoiceDate">
						<div *dxTemplate="let element of 'cellTemplateInvoiceDate'">
							{{ (element.data.invoiceDate !== null && element.data.invoiceDate !== '' &&
							element.data.invoiceDate !== undefined && element.data.invoiceDate !== 'Invalid
							date') ? (element.data.invoiceDate | date: 'yyyy-MM-dd') : "" }}
						</div>
					</dxi-column>

					<dxi-column dataField="finalAmount" alignment="right" caption="Invoice Amount" [allowFiltering]="false"
						dataType="number" [calculateDisplayValue]="getCurrencyDisplayValue">
					</dxi-column>

					<dxi-column dataField="commissionAmount" alignment="right" caption="Rebate Amount"
						[allowFiltering]="false" dataType="number" width="220"
						[calculateDisplayValue]="getCurrencyDisplayValue">
					</dxi-column>

					<dxi-column dataField="creditMemoNumber" caption="Credit Memo Number" alignment="center"
						cellTemplate="cellTemplateCreditMemoNumber" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateCreditMemoNumber'">
							<span
								[ngClass]="{'purple':element.data.creditMemoCreatedInERP === '','red':element.data.creditMemoCreatedInERP === false,'green':element.data.creditMemoCreatedInERP === true}"
								class="cursorPointer">
								<span *ngIf="element.data.creditMemoCreatedInERP === ''">Waiting for Sync</span>
								<span *ngIf="element.data.creditMemoCreatedInERP === false">Synced Error</span>
								<span
									*ngIf="element.data.creditMemoCreatedInERP === true">{{element.data.creditMemoNumber}}</span>
							</span>
						</div>
					</dxi-column>

					<dxi-column dataField="errorMessage" caption="Remark" cellTemplate="cellTemplateErrorMessage"
						dataType="string" [allowFiltering]="false">
						<div *dxTemplate="let element of 'cellTemplateErrorMessage'">
							<span *ngIf="element.data.creditMemoCreatedInERP === false">
								{{element.data.errorMessage || ''}}
							</span>
						</div>
					</dxi-column>

					<div *dxTemplate="let item of 'itemsDetailsTemplate'">
						<dx-data-grid #itemsDataGrid id="invoicesDataGrid" [dataSource]="item.data.items" keyExpr="linenum"
							[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[remoteOperations]="false" [columnAutoWidth]="true" [width]="'90%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>

							<dxi-column [visible]="isQBOnlineErpUser" [showInColumnChooser]="isQBOnlineErpUser"
								dataField="itemsku" dataType="string" caption="SKU" [allowFiltering]="true"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemsku">
								<div *dxTemplate="let element of 'cellTemplateItemsku'">
									{{element.data.itemsku || ''}}
								</div>
							</dxi-column>

							<dxi-column dataField="itemname" dataType="string" caption="Item" [allowFiltering]="true"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemname">
								<div *dxTemplate="let element of 'cellTemplateItemname'">
									{{element.data.itemname || ''}}
								</div>
							</dxi-column>

							<dxi-column dataField="description" dataType="string" caption="Description" [allowFiltering]="true"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateDescription">
								<div *dxTemplate="let element of 'cellTemplateDescription'">
									{{element.data.description || ''}}
								</div>
							</dxi-column>

							<dxi-column dataField="quantity" caption="Qty" dataType="number" [allowFiltering]="false"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateQuantity">
								<div *dxTemplate="let element of 'cellTemplateQuantity'">
									{{element.data.quantity || '' }}
								</div>
							</dxi-column>

							<dxi-column dataField="price" dataType="number" [caption]="'Price ('+globalCurrencySymbol+')'"
								[allowFiltering]="false" [allowHeaderFiltering]="false"
								[calculateDisplayValue]="getCurrencyDisplayValue">
							</dxi-column>

							<dxi-column dataField="finalAmount" dataType="number"
								[calculateDisplayValue]="getCurrencyDisplayValue"
								[caption]="'Line Total ('+globalCurrencySymbol+')'" [allowFiltering]="false"
								[allowHeaderFiltering]="false">
							</dxi-column>

							<dxi-column dataField="tier_basis" dataType="string" caption="Rebate Type" [allowFiltering]="false"
								[allowHeaderFiltering]="true" cellTemplate="cellTemplateTierBasis">
								<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter"> </dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateTierBasis'">
									{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label' |
									uppercase}}
								</div>
							</dxi-column>

							<dxi-column dataField="commissionRate" dataType="number"
								[caption]="'Rebate Rate (% | '+ globalCurrencySymbol+')'" [allowFiltering]="false"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateCommissionRate">
								<div *dxTemplate="let element of 'cellTemplateCommissionRate'">
									<span *ngIf="element.data.commissionRate">
										<span *ngIf="element.data.commissionRateIn === '$'">
											{{element.data.commissionRate || 0 | customCurrencyPipe :customCurrencyOption}}
										</span>
										<span *ngIf="element.data.commissionRateIn === '%'">
											{{(element.data.commissionRate || 0 | number:decimalPointForCurrency) +
											element.data.commissionRateIn}}
										</span>
									</span>
								</div>
							</dxi-column>

							<dxi-column dataField="commissionAmount" alignment="right" caption="Rebate Amount"
								dataType="number" [allowFiltering]="false" [allowHeaderFiltering]="false" width="220"
								[calculateDisplayValue]="getCurrencyDisplayValue"></dxi-column>

							<dxo-summary>
								<dxi-total-item column="finalAmount" summaryType="sum" displayFormat="Line Total: {0}"
									[customizeText]="doCustomizeLineTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-total-item>
								<dxi-total-item column="commissionAmount" summaryType="sum"
									displayFormat="Rebate Amount Total: {0}" [customizeText]="doCustomizeRebateTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>

				</dx-data-grid>
			</div>
		</div>
	</div>
</div>