import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminportalRoutingModule } from './adminportal-routing.module';
import { AdminportalComponent } from './adminportal.component';
import { ApDashboardComponent } from './ap-dashboard/ap-dashboard.component';
import { ApHeaderComponent } from './ap-header/ap-header.component';
import { ApSidemenuComponent } from './ap-sidemenu/ap-sidemenu.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainPipesModule } from '@app/pipes/main-pipes.module';
import { ApOpenTaskTilesComponent } from './ap-dashboard/ap-open-task-tiles/ap-open-task-tiles.component';
import { ApOpenLeadsTilesComponent } from './ap-dashboard/ap-open-leads-tiles/ap-open-leads-tiles.component';
import { ApOpenProspectsTilesComponent } from './ap-dashboard/ap-open-prospects-tiles/ap-open-prospects-tiles.component';
import { ApSalesInvoiceTotalTilesComponent } from './ap-dashboard/ap-sales-invoice-total-tiles/ap-sales-invoice-total-tiles.component';
import { ApCommissionTotalTilesComponent } from './ap-dashboard/ap-commission-total-tiles/ap-commission-total-tiles.component';
import { ApQuarterlyReportComponent } from './ap-dashboard/ap-quarterly-report/ap-quarterly-report.component';
import { ApDashboardTaskListComponent } from './ap-dashboard/ap-dashboard-task-list/ap-dashboard-task-list.component';

import { DxChartModule, DxDataGridModule, DxTemplateModule, DxTooltipModule, DxTreeListModule, DxButtonModule, DxContextMenuModule, DxDropDownButtonModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';

import { ApActiveCustomerTilesComponent } from './ap-dashboard/ap-active-customer-tiles/ap-active-customer-tiles.component';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { CustomComponentsModule } from '@app/custom-components/custom-components.module';

@NgModule({
	declarations: [
		AdminportalComponent,
		ApDashboardComponent,
		ApHeaderComponent,
		ApSidemenuComponent,
		ApOpenTaskTilesComponent,
		ApOpenLeadsTilesComponent,
		ApOpenProspectsTilesComponent,
		ApSalesInvoiceTotalTilesComponent,
		ApCommissionTotalTilesComponent,
		ApQuarterlyReportComponent,
		ApDashboardTaskListComponent,
		ApActiveCustomerTilesComponent
	],
	imports: [
		CommonModule,
		CustomComponentsModule,
		AdminportalRoutingModule,
		NgxPermissionsModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MainPipesModule,
		DxDataGridModule,
		DxContextMenuModule,
		DxTemplateModule,
		DxButtonModule,
		DxTooltipModule,
		DxDataGridModule,
		DxTemplateModule,
		DxTooltipModule,
		DxTreeListModule,
		DxChartModule,
		DxPopupModule,
		DxScrollViewModule,
		NgDynamicBreadcrumbModule,
		DxDropDownButtonModule
	]
})
export class AdminportalModule { }
