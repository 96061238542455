import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DATETIME_FORMAT_BACKEND, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { ContractLifeCycleValEnum, DateRangeGlobalSettingLblEnum, DateRangeGlobalSettingValEnum } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import { DxListComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
	//As per discussed with Prasanna for now not show rebate status changes Date-17112021
	public ContractLifeCycleValEnum = ContractLifeCycleValEnum;
	public isShowRebateTiles: boolean = true;
	public isShowDateRangeGlobalSettingPopover = false;
	public isShowDateRangeGlobalSettingComponent = false;
	private listOfShowDataFrom = [];
	public isDateRangeSettingsExisting: boolean = false;
	public called_from: string;
	public dateRangeConfigUI = {
		showDataFrom: ''
	};
	private getDashboarConfigSbsn: Subscription;
	public listOfDashboardConfigs: any = [];
	public selectedDashboardConfigs: any = [];

	public isShowLeadTile: boolean = false;
	public isShowProspectTile: boolean = false;
	public isShowCustomerTile: boolean = false;
	public isShowTaskTile: boolean = false;
	public isShowQuoteTile: boolean = false;
	public isShowOrderTile: boolean = false;
	public isShowInvoiceTile: boolean = false;
	public isShowArCreditMemoTile: boolean = false;
	public isShowCommissionTile: boolean = false;
	public isShowRebateTile: boolean = false;
	public isShowRoyaltyTile: boolean = false;
	private listOfPermissions: any[] = [];
	private updateUserSettingSbsn: Subscription;
	@ViewChild('listOfDashboardConfigRef') listOfDashboardConfigRef: DxListComponent;
	public isShowDashboardConfigSettingPopover: boolean;
	public isShowSalesLeaderBoard: boolean = false;
	private guid = new Guid();
	//End
	constructor(private _GlobalStateService: GlobalStateService,
		private _LoginService: LoginService,
		private _CrmsService: CrmsService,
		private _RestApiService: RestApiService,
		private _SalesDataSharingService: SalesDataSharingService,
		private _DashboardService: DashboardService,
		private _NgxPermissionsService: NgxPermissionsService,
		public _UserProfileService: UserProfileService,
		private _ChangeDetectorRef: ChangeDetectorRef,
		private _ToastrService: ToastrService,
		private _SidemenuService: SidemenuService,
		private _AppCommonSrvc: AppCommonSrvc) {
	}

	ngOnInit() {
		this.preInit();
		this.setPermissionList();
		this._GlobalStateService.unsubscribe('CALLED_ON_DISTRIBUTION_CHANNEL_CHANGE');
		this._GlobalStateService.subscribe('CALLED_ON_DISTRIBUTION_CHANNEL_CHANGE', response => {
			if (response) {
				if (response.isDefault) {
					this._SidemenuService.setCurrentMenu('dashboard');
					this.doRefreshAllOnDashboard();
				}
			}
		});
	}

	preInit() {
		this.setPermissionList();
		this._GlobalStateService.unsubscribe('CALLED_AS_OVERDUE_PAYMENT_TO_PURCHASE');
		this._GlobalStateService.subscribe('CALLED_AS_OVERDUE_PAYMENT_TO_PURCHASE', response => {
			if (response) {
				if (response.isDefault) {
					this.called_from = this._UserProfileService.getCalledFrom();
				}
			}
		});
		this.called_from = this._UserProfileService.getCalledFrom();

		this.getNotificationCounter();
		//As per discussed with Prasanna for now not show rebate status changes Date-17112021
		if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
			this.isShowRebateTiles = false;
		}
		//End
		this._CrmsService.doClearSetterGetter();
		this._SalesDataSharingService.doClearAllSetterGetter();
		this.listOfShowDataFrom = this._DashboardService.getListOfDateRangeShowDataFrom();
		this.getDateRangeConfig();
		this.getDashboardConfig();
		this.isShowSalesLeaderBoard = false;
		try {
			this.isShowSalesLeaderBoard = this._LoginService.loginUser.account_detail.enable_sales_leaderboard;
		} catch (e) {
			this.isShowSalesLeaderBoard = false;
		}
	}

	ngAfterViewInit() {
		// callDashboardQuarterlyReportHighchartsJs(this.child.quarterly_report_highcharts_data);
	}

	ngOnDestroy() {
		if (this.getDashboarConfigSbsn) {
			this.getDashboarConfigSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('CALLED_ON_DISTRIBUTION_CHANNEL_CHANGE');
		this._GlobalStateService.unsubscribe('CALLED_AS_OVERDUE_PAYMENT_TO_PURCHASE');
	}

	private setPermissionList() {
		// Get current permissions
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.listOfPermissions.push(permission);
		}
	}

	// Method for Fetched & Update Notification counter on Header page.
	private getNotificationCounter(searchCriteria?: any) {
		const fetchedSearchCriteria = {
			isResponse: true,
			ts: new Date().getTime(),
			data: {
				status: 'unread',
				type: ['error', 'notification'],
				// subtype: ['functional']
			}
		};
		this._GlobalStateService.notifyDataChangedDuplicate('fetchedNotificationCounter', fetchedSearchCriteria);
	}

	public doOpenDateRangeSetting() {
		this.isShowDateRangeGlobalSettingPopover = true;
		this.isShowDateRangeGlobalSettingComponent = false;
		setTimeout(() => {
			this.isShowDateRangeGlobalSettingComponent = true;
		}, 50);
	}

	public doCloseDateRangeGlobalSettingPopover(e) {
		this.isShowDateRangeGlobalSettingPopover = false;
		this.isShowDateRangeGlobalSettingComponent = false;
	}

	public doRefreshTile(e) {
		this.isShowDateRangeGlobalSettingPopover = false;
		this.isShowDateRangeGlobalSettingComponent = false;
		this.getDateRangeConfig();
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_ORDER_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_INVOICE_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_QUOTE_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_COMMISSION_TOTAL_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_REBATE_TOTAL_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_AR_CREDITMEMO_TILES_EVENT', new Date().getTime());
	}

	// Method used to get Customer360 Configuration
	private getDateRangeConfig() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (isDashboardSettingsExists) {
			this.setDefaultConfiguration();
		} else {
			this._AppCommonSrvc.fetchUserSettings().subscribe(response => {
				if (response) {
					this._AppCommonSrvc.setUserSettings(response.data || []);
					this.setDefaultConfiguration();
				}
			}, error => {
			});
		}
	}


	private setDefaultConfiguration() {
		this.dateRangeConfigUI.showDataFrom = DateRangeGlobalSettingValEnum.CURRENT_MONTH;
		this.isDateRangeSettingsExisting = false;
		const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (dashboardSettings.hasOwnProperty('date_range')) {
			this.isDateRangeSettingsExisting = true;
			const dateRangeConfig = dashboardSettings.date_range;
			if (dateRangeConfig && dateRangeConfig.show_data_from) {
				this.dateRangeConfigUI.showDataFrom = new Date().getTime().toString();
			}
		}
	}

	public doRefreshAllOnDashboard() {
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_LEAD_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_PROSPECT_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_CLIENT_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_TASK_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_QUOTE_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_ORDER_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_INVOICE_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_AR_CREDITMEMO_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_COMMISSION_TOTAL_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_REBATE_TOTAL_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_ROYALTY_TOTAL_TILES_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_YEAR_TO_DASHBOARD_CHART_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_SALES_GOAL_CHART_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_SALES_LEADER_BOARD_CHART_EVENT', new Date().getTime());
		this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_SALES_PERFORMANCE_CHART_EVENT', new Date().getTime());
	}

	private getDashboardConfig() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.IX_DASHBOARD_CONFIG);
		formData.append('is_dropdown', 'true');
		formData.append('view_fields', JSON.stringify(['guid', 'code', 'title', 'is_default']));

		if (this.getDashboarConfigSbsn) {
			this.getDashboarConfigSbsn.unsubscribe();
		}
		this.getDashboarConfigSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			if (response && response.flag) {
				this.listOfDashboardConfigs = response.data || [];
				this.initSavedDashboardConfig();
			}
		}, error => {

		});
	}

	private initSavedDashboardConfig() {
		this.isShowLeadTile = false;
		this.isShowProspectTile = false;
		this.isShowCustomerTile = false;
		this.isShowTaskTile = false;
		this.isShowQuoteTile = false;
		this.isShowOrderTile = false;
		this.isShowInvoiceTile = false;
		this.isShowArCreditMemoTile = false;
		this.isShowCommissionTile = false;
		this.isShowRebateTile = false;
		this.isShowRoyaltyTile = false;

		const selDashboardConfig = [];
		const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);

		if (dashboardSettings && dashboardSettings.hasOwnProperty('configs') && dashboardSettings.configs.length > 0) {
			dashboardSettings.configs.forEach(c => {
				let dashboarConfigIndex = this.listOfDashboardConfigs.findIndex(dc => dc.code === c.code);
				switch (c.code) {
					case 'lead_tile':
						this.isShowLeadTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_LEAD_VIEW') !== -1) {
									this.isShowLeadTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'prospect_tile':
						this.isShowProspectTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_PROSPECT_VIEW') !== -1) {
									this.isShowProspectTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'customer_tile':
						this.isShowCustomerTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
									this.isShowCustomerTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'task_tile':
						this.isShowTaskTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_TASK_VIEW') !== -1) {
									this.isShowTaskTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'quote_tile':
						this.isShowQuoteTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_QUOTATION_VIEW') !== -1) {
									this.isShowQuoteTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'order_tile':
						this.isShowOrderTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_ORDER_VIEW') !== -1) {
									this.isShowOrderTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'invoice_tile':
						this.isShowInvoiceTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_ARINVOIECES_VIEW') !== -1) {
									this.isShowInvoiceTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'ar_creditmemo_tile':
						this.isShowArCreditMemoTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_ARCREDITMEMO_VIEW') !== -1) {
									this.isShowArCreditMemoTile = true;
									selDashboardConfig.push(c.code);
								}
							}
						}
						break;
					case 'commission_tile':
						this.isShowCommissionTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
									if (this.listOfPermissions.indexOf('INCENTIVE_COMMISSION_VIEW') !== -1) {
										this.isShowCommissionTile = true;
										selDashboardConfig.push(c.code);
									}
								}
							}
						}
						break;
					case 'rebate_tile':
						this.isShowRebateTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
									if (this.listOfPermissions.indexOf('INCENTIVE_REBATES_VIEW') !== -1) {
										this.isShowRebateTile = true;
										selDashboardConfig.push(c.code);
									}
								}
							}
						}
						break;
					case 'royalty_tile':
						this.isShowRoyaltyTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
									if (this.listOfPermissions.indexOf('INCENTIVE_ROYALTIES_VIEW') !== -1) {
										this.isShowRoyaltyTile = true;
										selDashboardConfig.push(c.code);
									}
								}
							}
						}
						break;
					default:
						break;
				}
			});
		} else {
			this.listOfDashboardConfigs.forEach(c => {
				if (c.is_default) {
					switch (c.code) {
						case 'lead_tile':
							this.isShowLeadTile = false;
							if (this.listOfPermissions.indexOf('CRM_LEAD_VIEW') !== -1) {
								this.isShowLeadTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'prospect_tile':
							this.isShowProspectTile = false;
							if (this.listOfPermissions.indexOf('CRM_PROSPECT_VIEW') !== -1) {
								this.isShowProspectTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'customer_tile':
							this.isShowCustomerTile = false;
							if (this.listOfPermissions.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
								this.isShowCustomerTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'task_tile':
							this.isShowTaskTile = false;
							if (this.listOfPermissions.indexOf('CRM_TASK_VIEW') !== -1) {
								this.isShowTaskTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'quote_tile':
							this.isShowQuoteTile = false;
							if (this.listOfPermissions.indexOf('SALES_QUOTATION_VIEW') !== -1) {
								this.isShowQuoteTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'order_tile':
							this.isShowOrderTile = false;
							if (this.listOfPermissions.indexOf('SALES_ORDER_VIEW') !== -1) {
								this.isShowOrderTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'invoice_tile':
							this.isShowInvoiceTile = false;
							if (this.listOfPermissions.indexOf('SALES_ARINVOIECES_VIEW') !== -1) {
								this.isShowInvoiceTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'ar_creditmemo_tile':
							this.isShowArCreditMemoTile = false;
							if (this.listOfPermissions.indexOf('SALES_ARCREDITMEMO_VIEW') !== -1) {
								this.isShowArCreditMemoTile = true;
								selDashboardConfig.push(c.code);
							}
							break;
						case 'commission_tile':
							this.isShowCommissionTile = false;
							if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
								if (this.listOfPermissions.indexOf('INCENTIVE_COMMISSION_VIEW') !== -1) {
									this.isShowCommissionTile = true;
									selDashboardConfig.push(c.code);
								}
							}
							break;
						case 'rebate_tile':
							this.isShowRebateTile = false;
							if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
								if (this.listOfPermissions.indexOf('INCENTIVE_REBATES_VIEW') !== -1) {
									this.isShowRebateTile = true;
									selDashboardConfig.push(c.code);
								}
							}
							break;
						case 'royalty_tile':
							this.isShowRoyaltyTile = false;
							if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
								if (this.listOfPermissions.indexOf('INCENTIVE_ROYALTIES_VIEW') !== -1) {
									this.isShowRoyaltyTile = true;
									selDashboardConfig.push(c.code);
								}
							}
							break;
						default:
							break;
					}
				}
			});
		}
		this.selectedDashboardConfigs = selDashboardConfig;
	}

	public dispNoOfSelDashboardConfig(value) {
		return Array.isArray(value) ? value.length + ' Tile(s) Selected' : 'Choose a Tile(s)';
	}

	private doCheckAllSavedDashboardConfigs() {
		this.isShowLeadTile = false;
		this.isShowProspectTile = false;
		this.isShowCustomerTile = false;
		this.isShowTaskTile = false;
		this.isShowQuoteTile = false;
		this.isShowOrderTile = false;
		this.isShowInvoiceTile = false;
		this.isShowArCreditMemoTile = false;
		this.isShowCommissionTile = false;
		this.isShowRebateTile = false;
		this.isShowRoyaltyTile = false;

		const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
		if (dashboardSettings.hasOwnProperty('configs') && dashboardSettings.configs && dashboardSettings.configs.length > 0) {
			dashboardSettings.configs.forEach(c => {
				let dashboarConfigIndex = this.listOfDashboardConfigs.findIndex(dc => dc.code === c.code);
				switch (c.code) {
					case 'lead_tile':
						this.isShowLeadTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_LEAD_VIEW') !== -1) {
									this.isShowLeadTile = true;
								}
							}
						}
						break;
					case 'prospect_tile':
						this.isShowProspectTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_PROSPECT_VIEW') !== -1) {
									this.isShowProspectTile = true;
								}
							}
						}
						break;
					case 'customer_tile':
						this.isShowCustomerTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
									this.isShowCustomerTile = true;
								}
							}
						}
						break;
					case 'task_tile':
						this.isShowTaskTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('CRM_TASK_VIEW') !== -1) {
									this.isShowTaskTile = true;
								}
							}
						}
						break;
					case 'quote_tile':
						this.isShowQuoteTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_QUOTATION_VIEW') !== -1) {
									this.isShowQuoteTile = true;
								}
							}
						}
						break;
					case 'order_tile':
						this.isShowOrderTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_ORDER_VIEW') !== -1) {
									this.isShowOrderTile = true;
								}
							}
						}
						break;
					case 'invoice_tile':
						this.isShowInvoiceTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_ARINVOIECES_VIEW') !== -1) {
									this.isShowInvoiceTile = true;
								}
							}
						}
						break;
					case 'ar_creditmemo_tile':
						this.isShowArCreditMemoTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this.listOfPermissions.indexOf('SALES_ARCREDITMEMO_VIEW') !== -1) {
									this.isShowArCreditMemoTile = true;
								}
							}
						}
						break;
					case 'commission_tile':
						this.isShowCommissionTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
									if (this.listOfPermissions.indexOf('INCENTIVE_COMMISSION_VIEW') !== -1) {
										this.isShowCommissionTile = true;
									}
								}
							}
						}
						break;
					case 'rebate_tile':
						this.isShowRebateTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
									if (this.listOfPermissions.indexOf('INCENTIVE_REBATES_VIEW') !== -1) {
										this.isShowRebateTile = true;
									}
								}
							}
						}
						break;
					case 'royalty_tile':
						this.isShowRoyaltyTile = false;
						if (dashboarConfigIndex !== -1) {
							if (c.is_visible) {
								if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
									if (this.listOfPermissions.indexOf('INCENTIVE_ROYALTIES_VIEW') !== -1) {
										this.isShowRoyaltyTile = true;
									}
								}
							}
						}
						break;
					default:
						break;
				}
			});
		} else {
			this.listOfDashboardConfigs.forEach(c => {
				const isExisits = this.selectedDashboardConfigs.findIndex(dConfig => dConfig === c.code);
				if (c.code === 'lead_tile') {
					this.isShowLeadTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('CRM_LEAD_VIEW') !== -1) {
						this.isShowLeadTile = true;
					}
				}
				if (c.code === 'prospect_tile') {
					this.isShowProspectTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('CRM_PROSPECT_VIEW') !== -1) {
						this.isShowProspectTile = true;
					}
				}
				if (c.code === 'customer_tile') {
					this.isShowCustomerTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
						this.isShowCustomerTile = true;
					}
				}
				if (c.code === 'task_tile') {
					this.isShowTaskTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('CRM_TASK_VIEW') !== -1) {
						this.isShowTaskTile = true;
					}
				}
				if (c.code === 'quote_tile') {
					this.isShowQuoteTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('SALES_QUOTATION_VIEW') !== -1) {
						this.isShowQuoteTile = true;
					}
				}
				if (c.code === 'order_tile') {
					this.isShowOrderTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('SALES_ORDER_VIEW') !== -1) {
						this.isShowOrderTile = true;
					}
				}
				if (c.code === 'invoice_tile') {
					this.isShowInvoiceTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('SALES_ARINVOIECES_VIEW') !== -1) {
						this.isShowInvoiceTile = true;
					}
				}
				if (c.code === 'ar_creditmemo_tile') {
					this.isShowArCreditMemoTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('SALES_ARCREDITMEMO_VIEW') !== -1) {
						this.isShowArCreditMemoTile = true;
					}
				}
				if (c.code === 'commission_tile') {
					this.isShowCommissionTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('INCENTIVE_COMMISSION_VIEW') !== -1) {
						if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
							this.isShowCommissionTile = true;
						}
					}
				}
				if (c.code === 'rebate_tile') {
					this.isShowRebateTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('INCENTIVE_REBATES_VIEW') !== -1) {
						if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
							this.isShowRebateTile = true;
						}
					}
				}
				if (c.code === 'royalty_tile') {
					this.isShowRoyaltyTile = false;
					if (isExisits !== -1 && this.listOfPermissions.indexOf('INCENTIVE_ROYALTIES_VIEW') !== -1) {
						if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_OFF) {
							this.isShowRoyaltyTile = true;
						}
					}
				}
			});
		}
	}

	public doSelectionChangedDashboardConfig(e: any) {
		if (this.listOfDashboardConfigRef.selectedItems.length >= 9) {
			this._ToastrService.info("Maximum 8 tile(s) can be selected.", "Info", { closeButton: true, tapToDismiss: true });
			if (e.addedItems && e.addedItems.length > 0) {
				e.component.unselectItem(e.addedItems[e.addedItems.length - 1]);
				return;
			}
		} else {
			this.doUpdateDashboardSettings();
		}
		this._ChangeDetectorRef.detectChanges();
	}

	private doUpdateDashboardSettings() {
		this.updateUserSettingSbsn = this._AppCommonSrvc.fetchUserSettings().subscribe(response => {
			if (response) {
				this._AppCommonSrvc.setUserSettings(response.data || []);

				let dashboardSettingsConfig: any = {};
				try {
					dashboardSettingsConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS)));
				} catch (e) {
					dashboardSettingsConfig = {};
				}

				if (!dashboardSettingsConfig.hasOwnProperty('configs')) {
					dashboardSettingsConfig['configs'] = [];
				}
				dashboardSettingsConfig.configs.forEach(c => {
					c.is_visible = false;
				});

				this.selectedDashboardConfigs.forEach(c => {
					const dscIndex = dashboardSettingsConfig.configs.findIndex(dc => c === dc.code);
					if (dscIndex === -1) {
						if (c === 'lead_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Active Leads",
								code: "lead_tile",
								is_visible: true,
								type: "tile",
								entity: "leads",
							});
						}
						if (c === 'prospect_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Active Prospects",
								code: "prospect_tile",
								is_visible: true,
								type: "tile",
								entity: "prospects",
							});
						}
						if (c === 'customer_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Active Customers",
								code: "customer_tile",
								is_visible: true,
								type: "tile",
								entity: "clients",
							});
						}
						if (c === 'task_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Active Tasks",
								code: "task_tile",
								is_visible: true,
								type: "tile",
								entity: "tasks",
							});
						}
						if (c === 'quote_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Quotes Total",
								code: "quote_tile",
								is_visible: true,
								type: "tile",
								entity: "quotations",
							});
						}
						if (c === 'order_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Orders Total",
								code: "order_tile",
								is_visible: true,
								type: "tile",
								entity: "orders",
							});
						}
						if (c === 'invoice_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Invoices Total",
								code: "invoice_tile",
								is_visible: true,
								type: "tile",
								entity: "invoices",
							});
						}
						if (c === 'ar_creditmemo_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Credit Memos Total",
								code: "ar_creditmemo_tile",
								is_visible: true,
								type: "tile",
								entity: "arcreditmemo",
							});
						}
						if (c === 'commission_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Commission",
								code: "commission_tile",
								is_visible: true,
								type: "tile",
								entity: "commissioncontracts",
							});
						}
						if (c === 'rebate_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Rebate",
								code: "rebate_tile",
								is_visible: true,
								type: "tile",
								entity: "rebatescontracts",
							});
						}
						if (c === 'royalty_tile') {
							dashboardSettingsConfig.configs.push({
								guid: this.guid.newGuid(),
								title: "Royalty",
								code: "royalty_tile",
								is_visible: true,
								type: "tile",
								entity: "royaltiescontracts",
							});
						}
					} else {
						dashboardSettingsConfig.configs[dscIndex].is_visible = true;
					}
				});
				const dashboardSettingsConfigObj = {
					section: ServerSections.DASHBOARD_SETTINGS,
					section_value: dashboardSettingsConfig
				};

				const userSettings = [];
				const dashboardIndex = userSettings.findIndex(section => section.section === ServerSections.DASHBOARD_SETTINGS);
				if (dashboardIndex === -1) {
					userSettings.push(dashboardSettingsConfigObj);
				}

				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.SAVE_MULTIPLE_USER_SETTINGS);
				formData.append('user_settings', JSON.stringify(userSettings));
				if (this.updateUserSettingSbsn) {
					this.updateUserSettingSbsn.unsubscribe();
				}

				this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe(response => {
					if (response && response.flag) {
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS, dashboardSettingsConfig);
						this.doCheckAllSavedDashboardConfigs();
					}
				}, error => {
				});
			}
		}, error => {
		});
	}

	public doOpenDashboardConfigSetting() {
		this.isShowDashboardConfigSettingPopover = true;
	}

	public doCloseDashboardConfigSettingPopover() {
		this.isShowDashboardConfigSettingPopover = false;
	}

	public onShowing(e) {
		e.component.content().classList.add("ix-dashboardtileContent");
	}
}
