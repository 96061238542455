import { Component, OnInit, OnDestroy, Input, HostListener, ElementRef } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CustomNumberDecimalSuffixPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';

@Component({
	selector: 'app-commssion-total-tiles',
	templateUrl: './commssion-total-tiles.component.html',
	styleUrls: ['./commssion-total-tiles.component.css']
})
export class CommssionTotalTilesComponent implements OnInit, OnDestroy {
	public globalCurrency: string;
	public commissionDueForTheMonth: number;
	public resourcesLoaded: boolean = false;
	public currentMonthName: string;
	private getCommissionTotalSbsn: Subscription;
	public isShowFullValue: boolean = false;
	public getLastCharacterFromSortValue: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService,
		private _ElementRef: ElementRef) {

	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this._ElementRef.nativeElement.contains(event.target)) {
			// click on inside 
		} else {
			this.isShowFullValue = false; // click on outside 
		}
	}


	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.currentMonthName = this._DashboardService.getCurrentMonthName();
		this.getCommissionTotal();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_COMMISSION_TOTAL_TILES_EVENT', response => {
			if (response) {
				this.getCommissionTotal();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getCommissionTotalSbsn) {
			this.getCommissionTotalSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_COMMISSION_TOTAL_TILES_EVENT');
	}

	private getCommissionTotal() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.COMMISSION_TILE);
		if (this.getCommissionTotalSbsn) {
			this.getCommissionTotalSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getCommissionTotalSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this.resourcesLoaded = false;
			if (response.flag) {
				if (response.data.tiles) {
					this.commissionDueForTheMonth = response.data.tiles.commission_due_for_the_month || 0;
					const getStringValue = new CustomNumberDecimalSuffixPipe().transform(this.commissionDueForTheMonth, 2).toString();
					this.getLastCharacterFromSortValue = '';
					if (/^[a-zA-Z]+$/.test(getStringValue.substr(getStringValue.length - 1))) {
						this.getLastCharacterFromSortValue = getStringValue.substr(getStringValue.length - 1);
					}
				}
			}
		}, error => {
			this.resourcesLoaded = false;
			this.commissionDueForTheMonth = 0;
		});
	}

	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
	}

	public doClickToShowFullValue(event) {
		if (this.commissionDueForTheMonth <= 0) {
			return;
		}
		if (this.commissionDueForTheMonth > 0 && this.commissionDueForTheMonth < 1000) {
			return;
		}
		event.stopPropagation();
		this.isShowFullValue = true;
	}
}