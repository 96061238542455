import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UserProfileService {
	public called_form: any;
	public updateFutureOrCurrent: any;
	constructor() { }

	setCalledFrom(val: any) {
		this.called_form = val;
	}
	getCalledFrom() {
		return this.called_form;
	}
	setUpdateFutureOrCurrent(val: any) {
		this.updateFutureOrCurrent = val;
	}
	getUpdateFutureOrCurrent() {
		return this.updateFutureOrCurrent || 'upgrade_current_licences';
	}
}
