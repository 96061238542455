export class UserManagementUI {
	public sub_account_id: string;
	public first_name: string;
	public last_name: string;
	public work_start: any;
	public work_end: any;
	public local_timezone: string;
	public role: string;
	public salesrep: any;
	public email: string;
	public country_code: string;
	public isd_code: string;
	public gl_account_number?: string;
	public gl_account_number_credit?: string;
	public gl_account_number_debit?: string;
	public vendor: any[];
	public selectedVendor?: any[];
	public mobile: string;
	public password: string;
	public current_password: string;
	public new_password: string;
	public confirm_password: string;
	public status: string;
	public confirmpassword: string;
	public salespersoncode?: any[];
	public contract_template?: string[];
	public startDate?: any;
	public endDate?: any;
	public company_name?: string;
	public managedUsers: any[];
	public email_verified?: boolean;
	public admin?: boolean;
	public disable_login?: boolean;
	public crm_data?: string;
	public issetnewpassword?: boolean;
	public authy_user_id?: string;
	public password2?: string;
	public allowed_device_list?: any;
	public notification_type?: any;
	public notification: Notification;
	public notificationTypes: any[];
	public distribution_channels: any[];
	constructor() {
		this.role = "";
		this.salesrep = "";
		this.salespersoncode = [];
		this.vendor = [];
		this.distribution_channels = [];
		this.notification = new Notification();
	}
}
export class Notification {
	public personal_notify: boolean;
	public technical_notify: boolean;
	public billing_notify: boolean;
	public task_notify: boolean;
	public workflow_approval: boolean;
	public notify_by: any[];
	public notification_duration: any;
	public notification_duration_type: string;
	public start_time: any;
	public end_time: any;
	public notify_days: any[];
	public data_notify: boolean;
	constructor() {
		this.personal_notify = false;
		this.technical_notify = false;
		this.billing_notify = false;
		this.task_notify = false;
		this.workflow_approval = false;
		this.notify_by = [];
		this.notification_duration = null;
		this.notification_duration_type = 'minutes';
		this.start_time = null;
		this.end_time = null;
		this.notify_days = [];
		this.data_notify = false;
	}
}

export class UserGroupsUI {
	public group_name: string;
	public user_list: string[];
	public created_date: string;
	public modified_date: string;
	public ixcode: string;
	public guid: string;
	public issynctoerp: boolean;
	public user_detail_list: UserDetailList[];
	public number: number;
	constructor() {
		this.user_list = [];
		this.user_detail_list = [];
	}
}


export class CustomerList {
	public code: string;
	public name: string;
	public guid: string;
}

export class UserDetailList {
	public first_name: string;
	public last_name: string;
	public email: string;
	public role: string;
	public sub_account_id: string;
	public customer_list: CustomerList[];
}
