import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TerritoryDxDropDownBoxComponent } from './territory-dx-drop-down-box/territory-dx-drop-down-box.component';
import { DxButtonModule, DxSelectBoxModule, DxDataGridModule, DxDropDownBoxModule, DxLoadPanelModule, DxCheckBoxModule, DxListModule, DxHtmlEditorModule, DxTextBoxModule, DxTagBoxModule, DxDropDownButtonModule, DxNumberBoxModule, DxLookupModule, DxRadioGroupModule, DxDateBoxModule } from 'devextreme-angular';
import { DistributionChannelsDxDropDownBoxComponent } from './distribution-channels-dx-drop-down-box/distribution-channels-dx-drop-down-box.component';
@NgModule({
	declarations: [TerritoryDxDropDownBoxComponent, DistributionChannelsDxDropDownBoxComponent],
	imports: [
		DxSelectBoxModule,
		DxListModule,
		DxDataGridModule,
		DxButtonModule,
		DxCheckBoxModule,
		DxDropDownBoxModule,
		DxHtmlEditorModule,
		DxTextBoxModule,
		DxTagBoxModule,
		DxDropDownButtonModule,
		DxNumberBoxModule,
		DxLookupModule,
		DxLoadPanelModule,
		DxRadioGroupModule,
		DxTagBoxModule,
		DxDateBoxModule,
		CommonModule
	],
	exports: [
		TerritoryDxDropDownBoxComponent,
		DistributionChannelsDxDropDownBoxComponent
	]
})
export class InputComponentsModule { }
