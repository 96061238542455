<div *ngxPermissionsOnly="isCurrentTabPermission">
	<div class="row proSearch">
		<div class="cols11">
			<div class="dx-field feildCol">
				<div class="dx-field-label">Activities</div>
				<div class="dx-field-value" style="width: 100%;">
					<dx-drop-down-box [dataSource]="activitiesDataSource" [(value)]="lengthSelectedActivities"
						valueExpr="name" placeholder="Select Activities..." [showClearButton]="true"
						(onValueChanged)="onActivitiesValueChanged($event)">
						<div *dxTemplate="let data of 'content'">
							<!-- <dx-check-box text="All Activities" (onValueChanged)="checkAllActivities()"
								[(value)]="chkAllActivities" class="mrgB10">
							</dx-check-box> -->
							<!-- <dx-text-box *ngIf="activitiesGridBoxValue && activitiesGridBoxValue.length > 0"
							[readOnly]="true" [hoverStateEnabled]="false" [(value)]="strSelectedActivitiesList">
						</dx-text-box> -->
							<!-- <div [innerHTML]="content"></div> -->
							<dx-tag-box [(value)]="arrSelectedActivitiesList" [grouped]="true"></dx-tag-box>
							<dx-data-grid #activitiesDataGrid id="gridContainer" [dataSource]="activitiesDataSource"
								[columns]="activitiesGridBoxColumns" [selection]="{ mode: 'multiple' }"
								[hoverStateEnabled]="true" [paging]="{ enabled: false, pageSize: 10 }"
								[filterRow]="{ visible: true }" [height]="345" [(selectedRowKeys)]="arrSelectedActivitiesList"
								[showBorders]="true" [remoteOperations]="true"
								(onSelectionChanged)="onActivitiesSelectionChanged($event)">
								<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
									[useNative]="false"></dxo-scrolling>
								<!-- <dxo-selection [allowSelectAll]="allMode" ></dxo-selection> -->
							</dx-data-grid>
						</div>
					</dx-drop-down-box>
				</div>
			</div>
		</div>
		<div class="cols1 txtRight">
			<a class="button center" (click)="addActivitiesTabDetails()" style="text-transform: capitalize;">Add</a>
		</div>
	</div>
</div>
<div class="cf mrgT10"></div>
<div class="dx-field feildCol">
	<div class="dx-field-value" style="width: 100%;">
		<dx-data-grid #activitiesTabDataGridRef id="gridContainer" [dataSource]="dataSourceActivitiesLstTab"
			[remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true"
			[masterDetail]="{ enabled: true, template: 'tiersTemplate','autoExpandAll': false }"
			(onEditorPreparing)="doOnEditorPreparingActivitiesTab($event)"
			(onRowUpdated)="doOnRowUpdatedActivitiesTab($event)" (onRowRemoved)="doOnRowRemovedActivitiesTab($event)"
			(onSaved)="doOnSavedActivitiesTab($event)" (onRowPrepared)="doOnRowPreparedActivitiesTab($event)"
			(onRowExpanding)="doOnRowExpandingItmGrpTab($event)" keyExpr="guid" class="hideFilterPanelInx" [height]="450">
			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-filter-panel [visible]="false"></dxo-filter-panel>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
			<dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="true" [allowAdding]="false" [useIcons]="true">
			</dxo-editing>
			<dxi-column caption="Activities" dataField="activities_label" [allowEditing]="true"
				[editorOptions]="{ showClearButton: true }" [allowFiltering]="true" [allowHeaderFiltering]="false">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-column>

			<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
				[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
				[allowExporting]="false">

				<!-- <dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip"></dxi-button> -->
				<dxi-button name="delete" cssClass="dx-grid-delete dx-icon-trash menuTip"></dxi-button>

			</dxi-column>
			<div *dxTemplate="let c of 'tiersTemplate'">
				<dx-data-grid #tiersItemDetailssDataGridRef id="tiersItemDetailssDataGridRef_{{c.data.guid}}"
					[dataSource]="getTiersByItemDetails(c)" [remoteOperations]="false"
					(onInitNewRow)="doOnInitNewRowTiersItmGrpTab($event,c)"
					(onRowInserting)="doOnRowInsertingTiersItmGrpTab($event,c)"
					(onEditorPreparing)="doOnEditorPreparingTiersItmGrpTab($event,c)"
					(onRowRemoved)="doOnRowRemovedTiersItmGrpTab($event,c)" (onSaved)="doOnSavedTiersItmGrpTab($event,c)"
					keyExpr="guid" [showBorders]="true" [columnAutoWidth]="true">
					<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
					<dxo-filter-row [visible]="false"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true"
						[useIcons]="true">
					</dxo-editing>
					<dxi-column caption="Tier Minimum" dataField="mintier" dataType="number" [allowEditing]="true"
						[editorOptions]="{ format: 'decimal', showClearButton: true }" [allowFiltering]="true"
						[allowHeaderFiltering]="false">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column caption="Tier Maximum" dataField="maxtier" [allowEditing]="true"
						[editorOptions]="{ showClearButton: true }" [allowFiltering]="true" [allowHeaderFiltering]="false">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column caption="Rate" dataField="rate" dataType="number" [allowEditing]="true"
						[allowFiltering]="true" [allowHeaderFiltering]="false" alignment="right"
						[editorOptions]="{ format: 'decimal', showClearButton: true }">
						<dxo-format type="currency" [precision]="formatter">
						</dxo-format>
					</dxi-column>

					<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
						[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
						[allowExporting]="false">
						<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
						<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</div>
		</dx-data-grid>
	</div>
</div>