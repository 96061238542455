import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';

@Component({
	selector: 'app-message-trall',
	templateUrl: './message-trall.component.html',
	styleUrls: ['./message-trall.component.css']
})
export class MessageTrallComponent implements OnInit, OnDestroy {
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	@Input() claimDetail: any;
	public messageTrallList: any = [];
	public txtComment: string;
	public isVisibleLoader: boolean = false;
	private getChargebackDocumentListSubscription: Subscription;
	loadViewChargebackDocDxDialogSubject: Subject<any> = new Subject();
	constructor(
		public _ToastrService: ToastrService,
		public _LoginService: LoginService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _LoaderService: LoaderService,
		public _EndUsersChargebackContractService: EndUsersChargebackContractService,
		public _RestApiService: RestApiService,
	) { }

	ngOnInit(): void {
		this.getAllMessageTrall();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	public getAllMessageTrall() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.COMMENTTRAIL);
		if (this.claimDetail && this.claimDetail['guid']) {
			formData.append('ref_claim_id', this.claimDetail['guid']);
		}
		// this._LoaderService.show();
		this.isVisibleLoader = true;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this.messageTrallList = response.data || [];
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				// this._LoaderService.hide();
				this.isVisibleLoader = false;
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				// this._LoaderService.hide();
				this.isVisibleLoader = false;
			}
		});
	}

	public doAddMessageTrall() {
		const formData = new FormData();
		let commenttrailArr: any = [];
		if (!this.txtComment) {
			this._ToastrService.info('Please enter comment..!' || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
			return;
		}
		let objcommenttrail: any =
		{
			comment: this.txtComment,
			source_claim_id: '',
		}
		if (this.claimDetail && this.claimDetail['source_claim_id']) {
			objcommenttrail['source_claim_id'] = this.claimDetail['source_claim_id'];
		}
		commenttrailArr.push(objcommenttrail);
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('commenttrail', JSON.stringify(commenttrailArr));
		formData.append('comment_type', 'enduserschargebackcontracts');
		if (this.claimDetail && this.claimDetail['guid']) {
			formData.append('ref_claim_id', this.claimDetail['guid']);
		}
		// this._LoaderService.show();
		this.isVisibleLoader = true;
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this.txtComment = '';
					this.getAllMessageTrall();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this.isVisibleLoader = false;
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this.isVisibleLoader = false;
			}
		});
	}

	public doRefreshMessageTrall() {
		this.getAllMessageTrall();
	}
	// Method used to get Day,Month Name, and Time
	public getDateOperation(dateStr, returnType: string) {
		let returnStr = '';
		if (returnType === 'DATE') {
			returnStr = moment(dateStr).format('DD');
			return returnStr;
		} else if (returnType === 'MONTHNAME') {
			const monthNumber = moment(dateStr).month();
			returnStr = moment().month(monthNumber).format('MMM');
			return returnStr;
		} else if (returnType === 'YEAR') {
			const yearNum = moment(dateStr).year();
			returnStr = moment().year(yearNum).format('YY');
			return returnStr;
		} else if (returnType === 'TIME') {
			returnStr = moment.utc(dateStr).format('h:mm A');
			return returnStr;
		}
	}
	public doClickViewClaim(data) {
		this.getChargebackDocumentList(data);
	}
	private getChargebackDocumentList(data: any) {
		const reqFormData = new FormData();
		const searchParams = [['guid', '=', data['source_claim_id']]];
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.CHARGEBACKDOC);
		reqFormData.append('filter', JSON.stringify(searchParams));
		if (this.getChargebackDocumentListSubscription) {
			this.getChargebackDocumentListSubscription.unsubscribe();
		}
		this.isVisibleLoader = true;
		this.getChargebackDocumentListSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				this.isVisibleLoader = false;
				if (response && response.flag) {
					if (response.data && response.data.length > 0) {
						let chargeBackDocumentDetails = response.data[0];
						// chargeBackDocumentDetails.claimedStatus = data['claimedStatus'] || '';
						if (chargeBackDocumentDetails && chargeBackDocumentDetails.hasOwnProperty('contractGuid') && chargeBackDocumentDetails.contractGuid) {
							this._EndUsersChargebackContractService.getEndUserDetail(chargeBackDocumentDetails.contractGuid).subscribe({
								next: (response) => {
									this._LoaderService.hide();
									if (response.flag) {
										let responseData = response.data[0];
										if (responseData && !responseData.hasOwnProperty('is_enforce_quantity_sold')) {
											responseData['is_enforce_quantity_sold'] = true;
										}
										const objEndUsersChargebackContract = responseData;
										this.viewChargebackDocPopUp(objEndUsersChargebackContract, chargeBackDocumentDetails, data);
									} else {
										this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
									}
								}, error: (error) => {
									this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
									this._LoaderService.hide();
								}
							});
						}


					} else {
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this.isVisibleLoader = false;
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public viewChargebackDocPopUp(objEndUsersChargebackContract, chargeBackDocumentDetails, data) {
		const dialogData = {
			claimId: chargeBackDocumentDetails.ixcode,
			notificationGuid: data.guid,
			endUsersChargebackContractModelUI: objEndUsersChargebackContract,
			callFrom: 'message-trail',
			chargeBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
			billBaBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
		}
		this.loadViewChargebackDocDxDialogSubject.next(dialogData);
	}

}
