import { Component, OnInit, Inject, OnDestroy, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { PageOptions } from '@app/models/common.model';
import { LoginService } from '@app/services/login.service';
import { ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { ServerEntity, ServerMethods } from '@app/constants-enums/constants';

@Component({
	selector: 'app-view-rebate-members-list-dialog',
	templateUrl: './view-rebate-members-list-dialog.component.html',
	styleUrls: ['./view-rebate-members-list-dialog.component.css']
})
export class ViewRebateMembersListDialogComponent implements OnInit, OnChanges, OnDestroy {
	@Input() viewRebateMembersListProps: any;
	@Output() closeViewRebateMembersListPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;

	private erpType: string;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public dxDataSource: any;
	public pageOptions: PageOptions = new PageOptions();
	public isShowDatagrid: boolean;
	public isRemoteOperations: boolean;
	private contractguid: string;
	private contractDetails: any;
	private actionFor: string;
	private newlistOfMembers: any[] = [];
	private storedMemberList: any[] = [];
	private dataOutReqSbsn: Subscription;
	public popupTitle: string = '';

	// #region for Angular Life cycle
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService) {
	}

	ngOnInit() {
		this.isShowPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;

		this.setErpType();
	}


	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['viewRebateMembersListProps']) {
			const viewRebateMembersListProps = _SimpleChanges['viewRebateMembersListProps'].currentValue;

			this.actionFor = viewRebateMembersListProps.actionFor;
			this.contractguid = viewRebateMembersListProps.contractguid;
			this.contractDetails = viewRebateMembersListProps.contractDetails;
			this.storedMemberList = viewRebateMembersListProps.storedMemberList || [];

			if (this.actionFor === 'showMembersNotInBuyinggroup') {
				this.popupTitle = 'List of Members Not In Contracts';
				this.isRemoteOperations = false;
				this.fetchedMembersNotInBuyinggroup();
			}
			if (this.actionFor === 'showMembersWithIndividualContract') {
				this.popupTitle = 'List of Members With Individual Contracts';
				this.isRemoteOperations = true;
				this.fetchedMembersWithIndividualContract();
			}
		}
	}

	ngOnDestroy() {
		if (this.dataOutReqSbsn) {
			this.dataOutReqSbsn.unsubscribe();
		}
	}
	// #endregion

	// Method used to get Erp Type
	private setErpType() {
		// get current user Erp type
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erpType = '';
		}

		this.isQBErpUser = false;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}

		this.isQBOnlineErpUser = false;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
	}

	// Method used to get members who are not in buyinggroup.
	private fetchedMembersNotInBuyinggroup() {
		this.isShowDatagrid = true;
		if (this.contractDetails && this.contractDetails.selectBuyingGroupId) {
			if (this.dataOutReqSbsn) {
				this.dataOutReqSbsn.unsubscribe();
			}

			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.GET_ENTITY_DATA);
			formData.append('entity', ServerEntity.BUYINGGROUPS);
			formData.append('view_fields', JSON.stringify(['code', 'members']));

			const searchArr = [];
			searchArr.push({ 'code': this.contractDetails.selectBuyingGroupId });
			formData.append('search', JSON.stringify(searchArr));

			this.dataOutReqSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
				if (response.flag) {
					try {
						this.newlistOfMembers = response.data[0].members || [];
					} catch (e) {
						this.newlistOfMembers = [];
					}
					const memberNotInBuyingGroupList = [];
					this.newlistOfMembers.forEach(member => {
						const memberIndex = this.storedMemberList.findIndex(sMember => sMember.code === member.code);
						if (memberIndex === -1) {
							memberNotInBuyingGroupList.push(member);
						}
					});
					this.dxDataSource = memberNotInBuyingGroupList;
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			});
		}
	}

	// Method used to get members who are having Individual Contract in current contract.
	private fetchedMembersWithIndividualContract() {
		this.isShowDatagrid = true;
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.BUYING_GROUP_MEMBERS_WITH_INDIVIDUAL_REBATE,
			contractguid: this.contractguid,
			contracttype: 'rebatescontracts'
		};

		this.dxDataSource = AspNetData.createStore({
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: (method, ajaxOptions) => {
			},
			onLoaded(result) {
			}
		});
	}

	public doHiddenPopup(event) {
		this.closeViewRebateMembersListPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}
}
