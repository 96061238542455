import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_VIEW_DETAILS, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';
import { ClaimAttachedDocumentModel } from '@app/models/billback-chargeback-contract.model';

@Injectable({
	providedIn: 'root'
})
export class BillbackChargebackService {

	public editBillBackGuid: string;
	public claimAttachedDocumentModel = new ClaimAttachedDocumentModel();
	public calledFormClaimAttachedDocument: string; //billback or distribution chargeback
	constructor() { }

	getEditBillBackGuid() {
		return this.editBillBackGuid;
	}
	setEditBillBackGuid(val: any) {
		this.editBillBackGuid = val;
	}

	setClaimAttachmentDocumentData(claimData: ClaimAttachedDocumentModel) {
		this.claimAttachedDocumentModel.currentClaimElement = claimData.currentClaimElement;
		this.claimAttachedDocumentModel.categoriesList = claimData.categoriesList;
	}
	getClaimAttachmentDocumentData() {
		return this.claimAttachedDocumentModel;
	}

	setCalledFormClaimAttachedDocument(calledForm: string) {
		this.calledFormClaimAttachedDocument = calledForm;
	}
	getCalledFormClaimAttachedDocument() {
		return this.calledFormClaimAttachedDocument;
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'APPROVE_MULTI_MIN_MAX_CHARGEBACK_CLAIM',
				btn_name: 'Approve Multi Min-Max Chargeback Claim',
				btn_image: 'approve_multi_min_max_chargeback_claim',
				icon: IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'VIEW_DETAILS',
				btn_name: 'View Details',
				btn_image: 'viewDetails',
				icon: IMG_ICON_VIEW_DETAILS,
				visible: true,
				disabled: false,
			},
		];
	}
}
