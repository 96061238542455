import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ErpTypeValEnum, NameFromServerKeyEnum, PricelistStatusLblEnum, PricelistStatusValEnum, PriceListTypesValEnum } from "@app/constants-enums/enums";
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from "@app/services/loaderservices/loader.service";
import { LoginService } from '@app/services/login.service';
import { ProductServiceService } from '@app/services/product-service/product-service.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from "@environments/environment";
import { DxDataGridComponent, DxDropDownButtonComponent, DxListComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, filter, pairwise } from "rxjs";
import { CurrencyPipe } from '@angular/common';
import { GetPriceFromPricelistPipe } from '@app/pipes/filter-from-list.pipe';
import { MSG_ERROR_MESSAGE, PAGE_SIZE_OPTIONS, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { Guid } from '@app/models/guid';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import * as _ from 'underscore';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
declare const ExcelJS: ExcelJS;

@Component({
	selector: 'app-pricelist-details',
	templateUrl: './pricelist-details.component.html',
	styleUrls: ['./pricelist-details.component.css'],
	providers: [CurrencyPipe, GetPriceFromPricelistPipe]
})
export class PricelistDetailsComponent implements OnInit {
	public priceDetails: any;
	public priceUniqueDetails: any;
	displayedColumns: string[] = [];
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;

	public pageOptions = new PageOptions();
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	private allPermissions: any[] = [];

	private dataInReqSubscription: Subscription;
	public currentTab: string;
	public listOfUomGroups: any = [];
	public listOfWarehouses: any = [];
	public listOfUoms: any = [];
	public pricelists: any = [];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public permsn: any = [];
	// check search data or all data in list of product
	public globalCurrency: string;
	public erp_type: string;
	public dxProductDataSource: any;
	public dxCustomerDataSource: any;
	public url: string;
	public entity: string;
	public key: string;
	public queryString: string;
	private loadParams: any;
	public viewFields: string[] = [];
	public currentEntity: string;
	public ExportEntity: string = 'Price List';

	public isAdminUser: boolean = false;
	private instanceEditPriceListBtn: any = null;
	private isDisabledEditPriceListBtn: boolean = false;

	public dxItemsWithDefaultUomDataSource: any;
	public isDefaultItemView: boolean = true;
	public filterText: string;
	private filterValueChangeSbsn: Observable<any>;
	public filterValue = [];
	private getPricelistSbsn: Subscription;
	public isShowStartdate: boolean = false;
	public isShowEnddate: boolean = false;
	public isShowStatus: boolean = false;
	public listOfActions: any[];
	public isShowEditPricelist: boolean;
	public isShowPriceListHistoryBtn: boolean;
	public isOpenPriceListHistoryDrawer: boolean;
	private guid = new Guid();
	public listOfPriceListHistory: any[] = [];
	public currentPriceListFromHistory: any[] = [];
	public currentPriceListHistoryObj: any;
	@ViewChild('listOfPriceListHistoryRef') listOfPriceListHistoryRef: DxListComponent;
	public decimalPointForCurrency: number;
	public isEnableMinMaxClaim: boolean;
	public isShowMinMaxColumn: boolean;
	public isShowPricelistLifecycle: boolean;
	public PricelistStatusValEnum = PricelistStatusValEnum;
	public PricelistStatusLblEnum = PricelistStatusLblEnum;
	private getListOfUserSbsn: Subscription;
	public listOfApproverUsers: any[] = [];
	private sendPriceListForApprovalSbsn: Subscription;
	public isEnablePricelistApprovalWorkflow: boolean;
	public listOfPricelistStatuses: any[] = [];
	public listOfPricelistStatusesBtns: any[] = [];
	public selectedKeyDropDownBtn: any;
	public isShowDropDownButton: boolean;
	public previousPageUrl: string;
	public priceListDetailsIP: any;
	public doRefreshApprovalTrailIP: any;
	@ViewChild('dropDownButtonRef') dropDownButtonRef: DxDropDownButtonComponent;
	public doRefreshEndUsersListIP: any;
	public listOfActionsCustomerTab: any[] = [];
	public listOfPricelistTypes: any[] = [];
	private enable_price_list_terms_feature: boolean;
	public isShowTermsAndConditions: boolean;
	public isShowExportPriceListTermsPopup: boolean;
	public exportPricelistTermsProps: any;
	public isShowDataGrid: boolean;
	public isEnableDistributionChannels: boolean;
	public listOfDistributionChannels: any[] = [];
	private getListOfDistributionChannelsSbsn: Subscription;
	public PriceListTypesValEnum = PriceListTypesValEnum;

	constructor(private _LoaderService: LoaderService,
		private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _LoginService: LoginService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _ProductServiceService: ProductServiceService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _GlobalStateService: GlobalStateService,
		private _IxDxAlertService: IxDxAlertService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _GetPriceFromPricelistPipe: GetPriceFromPricelistPipe) {
	}

	ngOnInit() {
		const currentUrl = this._Router.url;
		if (currentUrl.startsWith('/products/')) {
			this.previousPageUrl = 'PRODUCTS';
		}
		if (currentUrl.startsWith('/notifications/')) {
			this.previousPageUrl = 'NOTIFICATIONS';
		}
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.priceDetails = this._ProductServiceService.getPriceDetails();
		this.priceUniqueDetails = this._ProductServiceService.getPriceDetailsIDs();
		this.listOfActions = this._ProductServiceService.getListOfActionsForPriceLists();
		this.listOfPricelistTypes = this._ProductServiceService.getListOfPricelistTypes;
		this.setErpType();
		this.doCheckPricelistLifecycleManagement();
		this.setPricelistFieldsVisibility();
		this.setAllPermissions();
		this.checkIsAdminUser();
		try {
			this.isEnableMinMaxClaim = this._LoginService.loginUser.account_detail.enable_min_max_claim || false;
		} catch (e) {
			this.isEnableMinMaxClaim = false;
		}
		this.doHideShowMinMaxColumns();
		this.doCheckPriceListTermsFeatures();
		this.doShowTermsAndConditionsTab();
		let isEnabledPricelistLifeCycle = false;
		try {
			isEnabledPricelistLifeCycle = this._LoginService.loginUser.account_detail.enable_pricelist_approval_workflow;
		} catch (e) {
			isEnabledPricelistLifeCycle = false;
		}
		this.isEnablePricelistApprovalWorkflow = isEnabledPricelistLifeCycle;

		let isEnableDistributionChannels = false;
		try {
			isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels;
		} catch (e) {
			isEnableDistributionChannels = false;
		}
		this.isEnableDistributionChannels = isEnableDistributionChannels;
		if (this.isEnableDistributionChannels) {
			this.doFetchListOfDistributionChannels();
		}
		const listOfStatuses = this._ProductServiceService.getListOfStatusForPricelist();
		this.listOfPricelistStatuses = listOfStatuses;
		this.getListOfApproverUsers();
		if (this.currentTab === undefined) {
			this.currentTab = 'listOfProducts';
			this.isShowPriceListHistoryBtn = true;
			this.fetchPricelistDetails();
			// this.inItListOfProductTab();
			setTimeout(() => {
				if (this.dataGridContainer && this.dataGridContainer['filterValueChange']) {
					this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
					this.filterValueChangeSbsn.subscribe((result) => {
						this.getFilterText();
					});
				}
			}, 60);
			// this.inItListOfItemWithDefaultUom();
		} else {
			switch (this.currentTab) {
				case 'listOfProducts':
					this.isShowPriceListHistoryBtn = true;
					this.fetchPricelistDetails();
					setTimeout(() => {
						if (this.dataGridContainer && this.dataGridContainer['filterValueChange']) {
							this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
							this.filterValueChangeSbsn.subscribe((result) => {
								this.getFilterText();
							});
						}
					}, 60);
					// this.inItListOfProductTab();
					// this.inItListOfItemWithDefaultUom();
					break;
				case 'listOfCustomers':
					this.initClientTab();
					break;
				case 'END_USERS_LISTING':
					this.isShowPriceListHistoryBtn = true;
					break;
				case 'approval_trails':
					this.isShowPriceListHistoryBtn = true;
					break;
				case 'terms_conditions':

					break;
				default:
					break;
			}
		}
	}


	// tslint:disable-next-line: use-life-cycle-interface
	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.getPricelistSbsn) {
			this.getPricelistSbsn.unsubscribe();
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('FETCH_LIST_OF_ITEMS_EVENT');
	}

	public clickOnTab(currentTab) {
		if (currentTab === this.currentTab) {
			return false;
		}
		this.currentTab = currentTab;
		this.pageOptions = new PageOptions();

		switch (currentTab) {
			case 'listOfProducts':
				this.doClosePriceListDrawer();
				this.pageOptions.pageSize = PAGE_SIZE_OPTIONS[PAGE_SIZE_OPTIONS.length - 1];
				this.fetchPricelistDetails();
				this.isDisabledClearBtn = true;
				this.filterText = '';
				setTimeout(() => {
					if (this.dataGridContainer && this.dataGridContainer['filterValueChange']) {
						this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
						this.filterValueChangeSbsn.subscribe((result) => {
							this.getFilterText();
						});
					}
				}, 60);
				// this.inItListOfProductTab();
				this.inItListOfItemWithDefaultUom();
				break;
			case 'listOfCustomers':
				this.doClosePriceListDrawer();
				this.initClientTab();
				break;
			case 'END_USERS_LISTING':
				this.doClosePriceListDrawer();
				break;
			case 'approval_trails':
				this.doClosePriceListDrawer();
				break;
			case 'terms_conditions':
				this.doClosePriceListDrawer();
				break;
		}
	}

	// Method used to Fetch Pricelist Details

	private fetchPricelistDetails() {
		const formData = new FormData();
		const viewFields = ['ixcode', 'pricelistno', 'pricelistname', 'startdate', 'enddate', 'min', 'max', 'active', 'status', 'guid', 'items', 'oldpricelists', 'is_approval_workflow', 'approvers'];
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.PRICELISTS);
		// formData.append('view_fields', JSON.stringify(viewFields));
		formData.append('search', JSON.stringify([{ pricelistno: this.priceDetails.pricelistno }]));
		if (this.getPricelistSbsn) {
			this.getPricelistSbsn.unsubscribe();
		}
		this.isShowDataGrid = false;
		this._LoaderService.show();
		this.getPricelistSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						if (response.data && response.data.length > 0) {
							this.priceDetails = response.data[response.data.length - 1];
							this.setListOfPriceListHistory();
							this.setSubmitMultiPleBtns();
						}
					}
				}
				this.isShowDataGrid = true;
			},
			error: (error) => {
				this._LoaderService.hide();
				this.isShowDataGrid = true;
			},
			complete: () => {
				this._LoaderService.hide();
				this.isShowDataGrid = true;
			}
		});
	}

	private inItListOfProductTab() {
		this.viewFields = [];
		this.viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description', 'type', 'guid', 'baseuom', 'itemprices', 'price', 'uom', 'item_full_name', 'min', 'max', 'status'];
		this.loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ITEM_DETAILS,
			pricelistnum: this.priceDetails.pricelistno,
			product_search_from: 'pricelists',
			view_fields: JSON.stringify(this.viewFields)
		}
		if (this.currentPriceListHistoryObj && this.currentPriceListHistoryObj.unique_id) {
			this.loadParams.unique_id = this.currentPriceListHistoryObj.unique_id;
			this.loadParams.startdate = this.currentPriceListHistoryObj.startdate;
			this.loadParams.enddate = this.currentPriceListHistoryObj.enddate;
		}
		this.gettabData();
	}

	private inItListOfItemWithDefaultUom() {
		const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description', 'price', 'guid', 'baseuom', 'uom', 'min', 'max'];
		const loadParams: any = {
			'usr': this._LoginService.loginUser.user,
			'token': this._LoginService.loginUser.token,
			'method': ServerMethods.DX_GET_ENTITY_DATA,
			'entity': ServerEntity.ITEMS,
			'product_search_from': 'pricelists',
			'pricelistnum': this.priceDetails.pricelistno,
			'view_fields': JSON.stringify(viewFields)
		};

		if (this.currentPriceListHistoryObj && this.currentPriceListHistoryObj.unique_id) {
			loadParams.unique_id = this.currentPriceListHistoryObj.unique_id;
			loadParams.startdate = this.currentPriceListHistoryObj.startdate;
			loadParams.enddate = this.currentPriceListHistoryObj.enddate;
		}
		this.dxItemsWithDefaultUomDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onLoaded: (result) => {
				if (result && result.length <= 0) {
					if (this.instanceEditPriceListBtn) {
						this.isDisabledEditPriceListBtn = true;
						this.instanceEditPriceListBtn.option({ disabled: this.isDisabledEditPriceListBtn });
					}
				} else {
					if (this.instanceEditPriceListBtn) {
						this.isDisabledEditPriceListBtn = false;
						this.instanceEditPriceListBtn.option({ disabled: this.isDisabledEditPriceListBtn });
					}
				}
			}
		});
	}

	private initClientTab() {
		this.listOfActionsCustomerTab = this._ProductServiceService.getListOfActionsForCustomerTab();
		this.currentEntity = NameFromServerKeyEnum.CLIENT;
		// this.ExportEntity = 'Price List Customers';
		this.viewFields = [];
		this.filterValue = [];
		this.viewFields = ['guid', 'code', 'name', 'email', 'phone', 'status', 'assignto', 'dialcode', 'contactperson', 'ixcode', 'pricelistnum'];
		this.filterValue = [["pricelistnum", "=", this.priceDetails.pricelistno]];
		this.loadParams = {
			'usr': this._LoginService.loginUser.user,
			'token': this._LoginService.loginUser.token,
			'method': ServerMethods.DX_GET_ENTITY_DATA,
			'entity': this.currentEntity,
			'view_fields': JSON.stringify(this.viewFields)
		};
		this.gettabData();
	}

	private gettabData() {
		this.key = "guid";
		if (this.currentTab === 'listOfProducts') {
			this.dxProductDataSource = AspNetData.createStore({
				key: this.key,
				loadUrl: environment.URL_DATAOUT,
				loadParams: this.loadParams,
				onLoaded: (result) => {
					if (result && result.length <= 0) {
						if (this.instanceEditPriceListBtn) {
							this.isDisabledEditPriceListBtn = true;
							this.instanceEditPriceListBtn.option({ disabled: this.isDisabledEditPriceListBtn });
						}
					} else {
						if (this.instanceEditPriceListBtn) {
							this.isDisabledEditPriceListBtn = false;
							this.instanceEditPriceListBtn.option({ disabled: this.isDisabledEditPriceListBtn });
						}
					}
				}
			});
		} else if (this.currentTab === 'listOfCustomers') {
			this.dxCustomerDataSource = AspNetData.createStore({
				key: this.key,
				loadUrl: environment.URL_DATAOUT,
				loadParams: this.loadParams
			});
		}
	}

	private setListOfPriceListHistory() {
		let listOfPriceListHistory = [];
		try {
			listOfPriceListHistory = JSON.parse(JSON.stringify(this.priceDetails.oldpricelists)) || [];
		} catch (e) {
			listOfPriceListHistory = [];
		}
		const currentPriceList: any = {
			guid: this.priceDetails.guid,
			unique_id: this.priceDetails.unique_id,
			startdate: this.priceDetails.startdate,
			enddate: this.priceDetails.enddate,
			items: this.priceDetails.items,
			pricelist_type: this.priceDetails.pricelist_type
		};
		if (this.isShowPricelistLifecycle) {
			currentPriceList.status = this.priceDetails.status || '';
			currentPriceList.is_approval_workflow = this.priceDetails.is_approval_workflow || false;
			currentPriceList.approvers = this.priceDetails.approvers || [];
		}
		const currentPriceListIndex = listOfPriceListHistory.findIndex(plh => plh.unique_id === currentPriceList.unique_id);
		if (currentPriceListIndex === -1) {
			listOfPriceListHistory.unshift(currentPriceList);
		}
		listOfPriceListHistory.map(pl => {
			if (!pl.hasOwnProperty('guid')) {
				pl.guid = this.guid.newGuid();
			}
			if (!pl.hasOwnProperty('unique_id')) {
				pl.unique_id = this.guid.newGuid();
			}
		});
		this.listOfPriceListHistory = listOfPriceListHistory;
		if (this.previousPageUrl === 'PRODUCTS') {
			const currentPriceListIndex = listOfPriceListHistory.findIndex(plh => plh.unique_id === this.priceUniqueDetails.unique_id);
			if (currentPriceListIndex !== -1) {
				this.currentPriceListHistoryObj = listOfPriceListHistory[currentPriceListIndex];
			} else {
				this.currentPriceListHistoryObj = listOfPriceListHistory[0];
			}
		} else if (this.previousPageUrl === 'NOTIFICATIONS') {
			const pricelistIndex = listOfPriceListHistory.findIndex(plh => plh.unique_id === this.priceUniqueDetails.unique_id);
			if (pricelistIndex !== -1) {
				this.currentPriceListHistoryObj = listOfPriceListHistory[pricelistIndex];
			}
		}
		setTimeout(() => {
			const selectedHistroyIndex = listOfPriceListHistory.findIndex(plh => plh.unique_id === this.priceUniqueDetails.unique_id);
			if (selectedHistroyIndex !== -1) {
				if (!this.listOfPriceListHistoryRef.instance.isItemSelected(selectedHistroyIndex)) {
					this.listOfPriceListHistoryRef.instance.selectItem(selectedHistroyIndex);
				}
			} else {
				if (!this.listOfPriceListHistoryRef.instance.isItemSelected(0)) {
					this.listOfPriceListHistoryRef.instance.selectItem(0);
				}
			}
		}, 50);
	}

	public doSelectionChangedPriceListHistory(event) {
		if (event.addedItems && event.addedItems.length > 0) {
			const selectedPriceListHistoryObj = event.addedItems[0];
			const priceListDetailsObj: any = {
				guid: this.priceDetails.guid || '',
				pricelistno: this.priceDetails.pricelistno,
				unique_id: selectedPriceListHistoryObj.unique_id
			};
			this._ProductServiceService.setPriceDetailsIDs(priceListDetailsObj);
			this.currentPriceListHistoryObj = event.addedItems[0];
			this.currentPriceListHistoryObj.guid = this.priceDetails.guid;
			this.currentPriceListHistoryObj.pricelistno = this.priceDetails.pricelistno;
			this.currentPriceListHistoryObj.pricelistname = this.priceDetails.pricelistname;
			this._ProductServiceService.setPriceDetails(this.priceUniqueDetails);
			this.setSubmitMultiPleBtns();
			if (this.isDefaultItemView) {
				this.inItListOfItemWithDefaultUom();
			} else {
				this.inItListOfProductTab();
			}
		}
	}
	//#region export to excel

	// Method used to Refresh DataGrid
	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
				visible: this.isDefaultItemView
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					visible: this.isDefaultItemView,
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					visible: this.isDefaultItemView,
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				name: 'actionButtons',
				visible: true,
			},
		);
		const actionButtonIndex = toolbarItems.findIndex(toolbarItem => toolbarItem.name === 'actionButtons');
		const searchPanelIndex = toolbarItems.findIndex(toolbarItem => toolbarItem.name === 'searchPanel');
		if (searchPanelIndex !== -1) {
			const searchPanelObject = toolbarItems[searchPanelIndex];
			toolbarItems.splice(searchPanelIndex, 1);
			toolbarItems.splice(actionButtonIndex, 0, searchPanelObject);
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'ADD':
					this.doAddPriceListDetailsOnEditMode();
					break;
				case 'EDIT':
					this.doEditPriceListDetailsOnEditMode();
					break;
				case 'EXPORT':
					if (!this.isDefaultItemView) {
						this.doExportToExcel();
					}
					if (this.isDefaultItemView) {
						this.doItemWithDefaultUomExportToExcel();
					}
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
		const columnChooserIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'COLUMN_CHOOSER');
		if (columnChooserIndex !== -1) {
			this.listOfActions[columnChooserIndex].visible = false;
		}
		const editIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'EDIT');
		if (editIndex !== -1) {
			this.listOfActions[editIndex].visible = this.isShowEditPricelist && this.allPermissions.indexOf("PRODUCTS_PRICE_LIST_MODIFY") !== -1;
			this.listOfActions[editIndex].disabled = this.isDisabledEditPriceListBtn;
		}
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].visible = this.isShowEditPricelist && this.allPermissions.indexOf("PRODUCTS_PRICE_LIST_MODIFY") !== -1;
		}
		if (this.isEnablePricelistApprovalWorkflow) {
			this.doVisibilityForDatagridActionButton();
		}
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		this.toggleProductListColumnVisiblity(true);

		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'PriceList.xlsx';
		worksheet = workbook.addWorksheet('Price List');
		const groupIndexesWithSingleItem = [];

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 4, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "group" && gridCell.column.dataField === "item_full_name") {
					groupIndexesWithSingleItem.push(excelCell.fullAddress.row);
				}
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'price') {
						gridCell.column.width = 100;
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.price);

					}
					if (gridCell.column.dataField === 'uom') {
						gridCell.column.width = 100;
					}
					if (gridCell.column.dataField === 'baseuom') {
						if (gridCell.data.baseuom && gridCell.data.uom) {
							if (gridCell.data.baseuom.toString().toLowerCase() === gridCell.data.uom.toString().toLowerCase()) {
								excelCell.value = 'Default';
							} else {
								excelCell.value = '';
							}
						}
					}
				}
			}
		}).then((cellRange) => {
			// header
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 1, 2, 8);
			headerRow.getCell(1).value = this.priceDetails.pricelistname;
			headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 15 };
			headerRow.getCell(1).alignment = { horizontal: 'left' };

			groupIndexesWithSingleItem.sort((a, b) => b - a).forEach((rowIndex) => {
				worksheet.getRow(rowIndex + 1).outlineLevel = undefined;
				worksheet.spliceRows(rowIndex, 1);
			});
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
				this.toggleProductListColumnVisiblity(false);
			});
		});
	}

	// Method used to Export Excelsheet
	private doItemWithDefaultUomExportToExcel() {

		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'PriceList.xlsx';
		worksheet = workbook.addWorksheet('Price List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 4, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;

				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'price') {
						gridCell.column.width = 100;
						const defaultPrice = this._GetPriceFromPricelistPipe.transform(gridCell.data.price, this.priceDetails.items, gridCell.data.erp_primary_key);
						excelCell.value = this._CustomCurrencyPipe.transform(defaultPrice);
					}

					if (gridCell.column.dataField === 'baseuom') {
						excelCell.value = gridCell.data.baseuom;
					}
				}
			}
		}).then((cellRange) => {
			// header
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 2, 2, 8);
			headerRow.getCell(1).value = 'Pricelist Name:';
			headerRow.getCell(2).value = this.priceDetails.pricelistname;
			headerRow.getCell(2).font = { name: 'Segoe UI Light', size: 15 };
			headerRow.getCell(2).alignment = { horizontal: 'left' };

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	private doAddPriceListDetailsOnEditMode() {
		this._ProductServiceService.setIsEditPricelistDetails('ADD_PRICE_LIST_EDIT_MODE');
		this._Router.navigate(['/products/edit-pricelist-details'], { relativeTo: this._ActivatedRoute });
	}

	private doEditPriceListDetailsOnEditMode() {
		this._ProductServiceService.setIsEditPricelistDetails('EDIT_PRICE_LIST_EDIT_MODE');
		this._Router.navigate(['/products/edit-pricelist-details'], { relativeTo: this._ActivatedRoute });
	}

	// Visible true and false datagrid column
	private toggleProductListColumnVisiblity(isVisible: boolean) {
		if (this.isQBOnlineErpUser) {
			this.dataGridContainer.instance.columnOption('itemsku', 'visible', isVisible);
		}
		this.dataGridContainer.instance.columnOption('itemname', 'visible', isVisible);
		this.dataGridContainer.instance.columnOption('description', 'visible', isVisible);
	}

	// Method used to check Is logged In user Admin user
	private checkIsAdminUser() {
		this.isAdminUser = false;
		try {
			this.isAdminUser = this._LoginService.loginUser.account_detail.admin ? true : false;
		} catch (e) {
			this.isAdminUser = false;
		}
	}

	// Method used to Set All permissions
	private setAllPermissions() {
		this.allPermissions = [];
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
	}

	// Method used to Set Erp Type
	private setErpType() {
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		this.isShowEditPricelist = false;
		// check Is QB Online User (no need to view code of QB user)
		this.isQBOnlineErpUser = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		this.isQBErpUser = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
			this.isShowEditPricelist = true;
		}
		if (this.erp_type === ErpTypeValEnum.SAGE300) {
			this.isShowEditPricelist = true;
		}
		if (this.erp_type === 'CSV') {
			this.isShowEditPricelist = true;
		}
	}

	private setPricelistFieldsVisibility() {
		this.isShowStartdate = false;
		this.isShowEnddate = false;
		this.isShowStatus = false;
		let isEnabledPricelistLifeCycle = false;
		try {
			isEnabledPricelistLifeCycle = this._LoginService.loginUser.account_detail.enable_pricelist_approval_workflow;
		} catch (e) {
			isEnabledPricelistLifeCycle = false;
		}
		if (isEnabledPricelistLifeCycle) {
			this.isShowStartdate = true;
			this.isShowEnddate = true;
			this.isShowStatus = true;
		} else {
			if (this.erp_type === ErpTypeValEnum.SAPB1_HANA) {
				this.isShowStartdate = true;
				this.isShowEnddate = true;
				this.isShowStatus = false;
			} else if (this.erp_type === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
				// check Is SAPB1_HANA_DIAPI User (no need to view code of QB user)
				this.isShowStartdate = true;
				this.isShowEnddate = true;
				this.isShowStatus = false;
			} else if (this.erp_type === ErpTypeValEnum.SAGE300) {
				this.isShowStartdate = true;
				this.isShowEnddate = true;
				this.isShowStatus = false;
			} else {
				this.isShowStartdate = true;
				this.isShowEnddate = true;
				this.isShowStatus = false;
			}
		}
	}

	//#endregion

	public doSwitchChanged(e) {
		if (e.value) {
			this.inItListOfItemWithDefaultUom();
		} else {
			this.inItListOfProductTab();
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	public doClearFilterBuilder(event) {
		this.dataGridContainer.instance.clearFilter();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	public doOpenPriceListDrawer() {
		this.isOpenPriceListHistoryDrawer = false;
		setTimeout(() => {
			this.isShowPriceListHistoryBtn = false;
			this.isOpenPriceListHistoryDrawer = true;
		}, 200);
	}

	public doClosePriceListDrawer() {
		this.isOpenPriceListHistoryDrawer = false;
		this.isShowPriceListHistoryBtn = false;
		if (this.currentTab === "listOfProducts" || this.currentTab === 'approval_trails') {
			setTimeout(() => {
				this.isShowPriceListHistoryBtn = true;
			}, 50);
		}
	}

	private doHideShowMinMaxColumns() {
		this.isShowMinMaxColumn = false;
		if (this.isEnableMinMaxClaim) {
			this.isShowMinMaxColumn = true;
		}
	}


	//#region  for Price List Life cycle

	private doCheckPricelistLifecycleManagement() {
		this.isShowPricelistLifecycle = false;
		let isEnabledPricelistLifeCycle = false;
		try {
			isEnabledPricelistLifeCycle = this._LoginService.loginUser.account_detail.enable_pricelist_approval_workflow;
		} catch (e) {
			isEnabledPricelistLifeCycle = false;
		}
		this.isShowPricelistLifecycle = isEnabledPricelistLifeCycle;
	}

	public doSaveUpdatePriceList(e: any) {
		const status = e.selectedItem.value;
		if (status === PricelistStatusValEnum.DRAFT) {
			this.doSubmitForDraft();
		}
		if (status === PricelistStatusValEnum.PENDING_APPROVAL) {
			this.doSubmitForPendingApproval();
		}
		if (status === PricelistStatusValEnum.APPROVED) {
			this.doSubmitForApproved();
		}
	}

	public doSaveUpdatePricelistStatusChanged(e: any) {
		const status = e.itemData.value;
		if (status === PricelistStatusValEnum.DRAFT) {
			this.doSubmitForDraft();
		}
		if (status === PricelistStatusValEnum.PENDING_APPROVAL) {
			this.doSubmitForPendingApproval();
		}
		if (status === PricelistStatusValEnum.APPROVED) {
			this.doSubmitForApproved();
		}
	}

	private doSubmitForDraft() {
		if (this.priceUniqueDetails.approval_guid) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Submit For Draft',
				message: 'Are you sure you want to submit for draft ? <br/>You will not be able to undo the operation.'
			};
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					const formData = this.doGetFormData(PricelistStatusValEnum.DRAFT);
					if (this.sendPriceListForApprovalSbsn) {
						this.sendPriceListForApprovalSbsn.unsubscribe();
					}
					this._LoaderService.show();
					this.sendPriceListForApprovalSbsn = this._RestApiService.doDataInFormDataReq(formData).subscribe({
						next: response => {
							this._LoaderService.hide();
							this.doRefrshApprovalTrails();
							if (response && response.flag) {
								this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
								this._Router.navigate(["notifications/approvals/pricelist"]);
							} else {
								this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
						}, error: error => {
							this._LoaderService.hide();
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					});
				}
			});
		}
	}

	private doSubmitForPendingApproval() {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Submit For Approval',
			message: 'Are you sure you want to submit for approval? <br/>You will not be able to undo this operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.SEND_PRICELIST_FOR_APPROVAL);
				formData.append('pricelistno', this.priceDetails.pricelistno);
				formData.append('unique_id', this.currentPriceListHistoryObj.unique_id);
				if (this.sendPriceListForApprovalSbsn) {
					this.sendPriceListForApprovalSbsn.unsubscribe();
				}
				this._LoaderService.show();
				this.sendPriceListForApprovalSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: response => {
						this._LoaderService.hide();
						if (response && response.flag) {
							this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
							this.fetchPricelistDetails();
							this.doRefrshApprovalTrails();
						} else {
							this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					}, error: error => {
						this._LoaderService.hide();
						this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				});
			}
		});
	}

	private doSubmitForApproved() {
		if (this.priceUniqueDetails.approval_guid) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Approve Pricelist',
				message: 'Are you sure you want to approve a pricelist? <br/>You will not be able to undo the operation.'
			};
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					const formData = this.doGetFormData(PricelistStatusValEnum.APPROVED);
					if (this.sendPriceListForApprovalSbsn) {
						this.sendPriceListForApprovalSbsn.unsubscribe();
					}
					this._LoaderService.show();
					this.sendPriceListForApprovalSbsn = this._RestApiService.doDataInFormDataReq(formData).subscribe({
						next: response => {
							this._LoaderService.hide();
							if (response && response.flag) {
								this._ToastrService.info(response.message, 'Success', { closeButton: true, tapToDismiss: true });
								this.doRefrshApprovalTrails();
								this._Router.navigate(["notifications/approvals/pricelist"]);
							} else {
								this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
						}, error: error => {
							this._LoaderService.hide();
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					});
				}
			});
		}
	}

	private doGetFormData(approval_status: string) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('entity', ServerEntity.APPROVALS);
		formData.append('type', 'pricelists');
		formData.append('updateData', JSON.stringify({ "approval_status": approval_status }));
		formData.append('guid', this.priceUniqueDetails.approval_guid);
		return formData;
	}

	private setSubmitMultiPleBtns() {
		if (this.isEnablePricelistApprovalWorkflow) {
			const priceListStatus = this.currentPriceListHistoryObj.status || null;
			this.listOfPricelistStatusesBtns = [];
			if (priceListStatus === PricelistStatusValEnum.DRAFT) {
				// const currentStatusIndex = this.listOfPricelistStatuses.findIndex(rs => rs.value === priceListStatus);
				// const draftStatusClone = JSON.parse(JSON.stringify(this.listOfPricelistStatuses[currentStatusIndex]));
				// draftStatusClone.label = 'Submit';
				// this.listOfPricelistStatusesBtns.push(draftStatusClone);
				const signatureReqStatusIndex = this.listOfPricelistStatuses.findIndex(rs => rs.value === PricelistStatusValEnum.PENDING_APPROVAL);
				const signatureReqClone = JSON.parse(JSON.stringify(this.listOfPricelistStatuses[signatureReqStatusIndex]));
				signatureReqClone.label = 'Submit For Approval';
				this.listOfPricelistStatusesBtns.push(signatureReqClone);
			} else if (priceListStatus === PricelistStatusValEnum.PENDING_APPROVAL) {
				const currentStatusIndex = this.listOfPricelistStatuses.findIndex(rs => rs.value === PricelistStatusValEnum.APPROVED);
				const currentStatusClone = JSON.parse(JSON.stringify(this.listOfPricelistStatuses[currentStatusIndex]));
				currentStatusClone.label = 'Approve';
				this.listOfPricelistStatusesBtns.push(currentStatusClone);
				const draftStatusIndex = this.listOfPricelistStatuses.findIndex(rs => rs.value === PricelistStatusValEnum.DRAFT);
				const draftClone = JSON.parse(JSON.stringify(this.listOfPricelistStatuses[draftStatusIndex]));
				draftClone.label = 'Set To Draft';
				this.listOfPricelistStatusesBtns.push(draftClone);
			} else if (priceListStatus === PricelistStatusValEnum.APPROVED) {
				const draftStatusIndex = this.listOfPricelistStatuses.findIndex(rs => rs.value === PricelistStatusValEnum.DRAFT);
				const draftClone = JSON.parse(JSON.stringify(this.listOfPricelistStatuses[draftStatusIndex]));
				draftClone.label = 'Set To Draft';
				this.listOfPricelistStatusesBtns.push(draftClone);
			} else {
				const currentStatusIndex = this.listOfPricelistStatuses.findIndex(rs => rs.value === PricelistStatusValEnum.DRAFT);
				const draftStatusClone = JSON.parse(JSON.stringify(this.listOfPricelistStatuses[currentStatusIndex]));
				draftStatusClone.label = 'Submit';
				this.listOfPricelistStatusesBtns.push(draftStatusClone);
				const signatureReqStatusIndex = this.listOfPricelistStatuses.findIndex(rs => rs.value === PricelistStatusValEnum.PENDING_APPROVAL);
				const signatureReqClone = JSON.parse(JSON.stringify(this.listOfPricelistStatuses[signatureReqStatusIndex]));
				signatureReqClone.label = 'Submit For Approval';
				this.listOfPricelistStatusesBtns.push(signatureReqClone);
			}
			this.selectedKeyDropDownBtn = this.listOfPricelistStatusesBtns[0].value;
		}
		this.isHideShowDropDownButton();
	}

	private isHideShowDropDownButton() {
		this.isShowDropDownButton = false;
		if (this.previousPageUrl === 'PRODUCTS') {
			if (this.currentPriceListHistoryObj) {
				if (this.currentPriceListHistoryObj && this.currentPriceListHistoryObj.status === PricelistStatusValEnum.DRAFT) {
					this.isShowDropDownButton = true;
				}
				if (this.currentPriceListHistoryObj.status === PricelistStatusValEnum.PENDING_APPROVAL) {
					this.isShowDropDownButton = false;
				}
				if (this.currentPriceListHistoryObj.status === PricelistStatusValEnum.APPROVED) {
					this.isShowDropDownButton = false;
				}
			}
		}
		if (this.previousPageUrl === 'NOTIFICATIONS') {
			const addButtonIndex = this.listOfActions.findIndex(i => i.btn_code
				=== 'ADD');
			if (addButtonIndex !== -1) {
				this.listOfActions[addButtonIndex].visible = false;
			}
			const editButtonIndex = this.listOfActions.findIndex(i => i.btn_code
				=== 'EDIT');
			if (editButtonIndex !== -1) {
				this.listOfActions[editButtonIndex].visible = false;
			}

			if (this.currentPriceListHistoryObj) {
				if (this.priceUniqueDetails.approval_status === PricelistStatusValEnum.DRAFT) {
					this.isShowDropDownButton = false;
				}
				if (this.priceUniqueDetails.approval_status === PricelistStatusValEnum.PENDING_APPROVAL) {
					this.isShowDropDownButton = true;
				}
				if (this.priceUniqueDetails.approval_status === PricelistStatusValEnum.APPROVED) {
					this.isShowDropDownButton = false;
				}
			}
		}
	}

	private doRefrshApprovalTrails() {
		if (this.currentTab === 'approval_trails') {
			this.doRefreshApprovalTrailIP = new Date().getTime();
		}
	}

	private doRefreshEndUsersListing() {
		if (this.currentTab === 'END_USERS_LISTING') {
			this.doRefreshEndUsersListIP = new Date().getTime();
		}
	}

	private doVisibilityForDatagridActionButton() {
		if (this.previousPageUrl === 'PRODUCTS') {
			if (this.currentPriceListHistoryObj) {
				if (this.currentPriceListHistoryObj.status === PricelistStatusValEnum.PENDING_APPROVAL || this.currentPriceListHistoryObj.status === PricelistStatusValEnum.APPROVED) {
					const editButtonIndex = this.listOfActions.findIndex(i => i.btn_code
						=== 'EDIT');
					if (editButtonIndex !== -1) {
						this.listOfActions[editButtonIndex].visible = false;
					}
				}
			}
		}
		if (this.previousPageUrl === 'NOTIFICATIONS') {
			const addButtonIndex = this.listOfActions.findIndex(i => i.btn_code
				=== 'ADD');
			if (addButtonIndex !== -1) {
				this.listOfActions[addButtonIndex].visible = false;
			}
			const editButtonIndex = this.listOfActions.findIndex(i => i.btn_code
				=== 'EDIT');
			if (editButtonIndex !== -1) {
				this.listOfActions[editButtonIndex].visible = false;
			}
		}
	}

	public getSubmitBtnText() {
		return this.listOfPricelistStatusesBtns ? this.listOfPricelistStatusesBtns[0].text : '';
	}
	//#endregion



	private getListOfApproverUsers() {
		let listOfUserTypes = [];
		try {
			listOfUserTypes = this._LoginService.loginUser.account_detail.userType || [];
		} catch (error) {
			listOfUserTypes = [];
		}
		if (listOfUserTypes && listOfUserTypes.length > 0) {
			const viewFields = ['sub_account_id', 'first_name', 'last_name', 'status'];
			const listOfRoles = _.pluck(listOfUserTypes, 'user_type');
			const listOfSearches = [];
			listOfSearches.push({ status: 'active' });
			listOfSearches.push({ role: listOfRoles });
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
			reqFormData.append('entity', ServerEntity.SUBUSERS);
			reqFormData.append('is_dropdown', 'true');
			reqFormData.append('search', JSON.stringify(listOfSearches));
			reqFormData.append('sort_dir', 'asc');
			reqFormData.append('sort_field', 'first_name');
			reqFormData.append('view_fields', JSON.stringify(viewFields));
			if (this.getListOfUserSbsn) {
				this.getListOfUserSbsn.unsubscribe();
			}

			this.getListOfUserSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
				next: response => {
					this.listOfApproverUsers = [];
					if (response && response.flag) {
						const listOfUsers = response.data || [];
						listOfUsers.map(user => {
							let strValue = user.first_name ? user.first_name : '';
							strValue += user.first_name && user.last_name ? ' ' + user.last_name : '';
							user.name = strValue;
							this.listOfApproverUsers.push(user);
						});
					}
				}, error: error => {
				}
			});
		}
	}

	private doFetchListOfDistributionChannels() {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		reqFormData.append('is_dropdown', 'true');
		reqFormData.append('view_fields', JSON.stringify(['guid', 'code', 'description']));
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}

		this.getListOfDistributionChannelsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: response => {
				this.listOfDistributionChannels = [];
				if (response && response.flag) {
					this.listOfDistributionChannels = response.data || [];
				}
			}, error: error => {
			}
		});
	}

	// #region for Customer Tabs 

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparingCustomerTab(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
				visible: false
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: false,
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: false,
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			}
		);
		let addRowButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (addRowButton) {
			addRowButton.visible = false;
		}
	}

	public doActionsBtnItemClickCustomerTab(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGridCustomerTab();
					break;
				case 'EXPORT':
					this.doExportToExcelCustomerTab();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClickCustomerTab(event: any) {

	}

	// Method used to Refresh DataGrid
	private doRefreshDataGridCustomerTab() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Vendor,Buying Groups,End Users
	private doExportToExcelCustomerTab() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Price-List-Customers.xlsx';
		worksheet = workbook.addWorksheet('Price-List-Customers');
		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}
	//#endregion


	// #region for Terms and Condition Tab Content

	private doCheckPriceListTermsFeatures() {
		let isEnablePriceListTermsFeature = false;
		try {
			isEnablePriceListTermsFeature = this._LoginService.loginUser.account_detail.enable_price_list_terms_feature || false;
		} catch (e) {
			isEnablePriceListTermsFeature = false;
		}
		this.enable_price_list_terms_feature = isEnablePriceListTermsFeature;
	}

	private doShowTermsAndConditionsTab() {
		this.isShowTermsAndConditions = false;
		if (this.enable_price_list_terms_feature && this.previousPageUrl === 'PRODUCTS') {
			this.isShowTermsAndConditions = true;
		}
	}

	public doRefreshTermsConditions(event) {
		if (event && event.terms) {
			this.priceDetails.terms = event.terms;
		}
	}

	public doOpenExportPriceListTerms() {
		this.exportPricelistTermsProps = {
			pricelistDetails: this.priceDetails,
			currentPriceListHistoryObj: this.currentPriceListHistoryObj,
		};
		this.doOpenExportPricelistTermsPopup();
	}

	private doOpenExportPricelistTermsPopup() {
		this.isShowExportPriceListTermsPopup = false;
		setTimeout(() => {
			this.isShowExportPriceListTermsPopup = true;
		}, 200);
	}

	public doCloseExportPricelistTermsPopup(event) {

	}
	// #endregion
}
