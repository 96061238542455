import { Injectable } from '@angular/core';
import { ClaimAttachedDocumentModel, DistributionChargebackContractModelUI } from '@app/models/distribution-chargeback-contract.model';

@Injectable({
	providedIn: 'root'
})
export class DistributionChargebackContractService {

	public tabItemsList: any[] = [
		{ key: 'items', displayVal: 'Items' },
		{ key: 'chargebackdocuments', displayVal: 'Chargeback Documents' }
	];
	public editDistributionChargebackGuid: any;
	public chargebackTypeTabDataList: any = [];
	public calledFormClaimAttachedDocument: string; //billback or distribution chargeback
	public claimAttachedDocumentModel = new ClaimAttachedDocumentModel();
	public distributionChargebackObject = new DistributionChargebackContractModelUI();
	constructor() { }

	public setEditDistributionChargebackGuid(editDistributionChargebackGuid: any) {
		this.editDistributionChargebackGuid = editDistributionChargebackGuid || '';
	}
	public getEditDistributionChargebackGuid() {
		return this.editDistributionChargebackGuid || '';
	}
	public setChargebackTypeTabDataList(list: any) {
		this.chargebackTypeTabDataList = list;
	}

	setCalledFormClaimAttachedDocument(calledForm: string) {
		this.calledFormClaimAttachedDocument = calledForm || '';
	}
	getCalledFormClaimAttachedDocument() {
		return this.calledFormClaimAttachedDocument;
	}
	setClaimAttachmentDocumentData(claimData: any) {
		this.claimAttachedDocumentModel.currentClaimElement = claimData.currentClaimElement || [];
		this.claimAttachedDocumentModel.categoriesList = claimData.categoriesList || [];
	}
	getClaimAttachmentDocumentData() {
		return this.claimAttachedDocumentModel;
	}
	setDistributionChargebackObject(distributionChargebackObject: any) {
		this.distributionChargebackObject = distributionChargebackObject;
	}
	getDistributionChargebackObject() {
		return this.distributionChargebackObject;
	}
}
