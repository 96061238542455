import { Component, OnInit } from '@angular/core';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';

@Component({
	selector: 'app-commission-tierbase-contract-list-tab',
	templateUrl: './commission-tierbase-contract-list-tab.component.html',
	styleUrls: ['./commission-tierbase-contract-list-tab.component.css']
})
export class CommissionTierbaseContractListTabComponent implements OnInit {

	public currentMainTab: string = 'commission_agreements';
	constructor(
		private _CommissionService: CommissionService,
	) { }

	ngOnInit() {
		if (this._CommissionService.getAgreementMainTab()) {
			this.currentMainTab = this._CommissionService.getAgreementMainTab();
		}
	}

	public clickMainTab(tabName) {
		this.currentMainTab = tabName;
		this._CommissionService.setAgreementMainTab(tabName)
	}

}
