import { Component, OnInit, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter, Input, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoginService } from '@app/services/login.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { TaskInsertUpdate, TaskInsertUpdateUI } from '@app/models/businessplan.model';
import { CUSTOM_DATE_FORMATS, DATETIME_FORMAT_BACKEND, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { TaskTypesValEnum, TaskTypesLblEnum, TaskStatusValEnum, TaskStatusLblEnum, NameFromServerKeyEnum, ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { CommonOptions, GetRecordsReq } from '@app/models/common.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Guid } from '@app/models/guid';

@Component({
	// host: {
	// 	'(document:click)': 'onClick($event)',
	// },
	selector: 'app-task-dialog',
	templateUrl: './task-dialog.component.html',
	styleUrls: ['./task-dialog.component.css'],
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
	],
})

export class TaskDialogComponent implements OnInit, OnChanges, OnDestroy {
	@Input() addeditTaskDetailsProps: any;
	@Output() closeAddEditTaskPopup = new EventEmitter<any>();

	public NameFromServerKeyEnum = NameFromServerKeyEnum;
	public minDate: Date;
	public taskTypes: CommonOptions[] = [];
	public taskTypeId: any = undefined;
	public taskStatuses: CommonOptions[] = [];
	public salesPeoplsList: CommonOptions[] = [];
	public taskInsertUpdateUI: TaskInsertUpdateUI = new TaskInsertUpdateUI();
	public taskInsertUpdate: TaskInsertUpdate = new TaskInsertUpdate();
	public isEditMode = false;
	public contactemployees: any[] = [];
	public selectedguidcontactperson: any = undefined;

	public isReadOnlyClient: boolean = false;
	public isDisplayRemark: boolean = true;
	public isAllReadOnlyForClosedTask: boolean = false;
	private currentEntity: string = undefined;
	public clientfrom: string = 'leads';
	public clientsList: any;
	public searchParams: any[] = [];
	private dataOutReqSubscription: Subscription;
	public isQBErpUser: boolean = false;
	allPermissions: any[] = [];
	public listOfTimeInvervals: any[] = [];
	private erp_type: string;
	public listOfAssignToUsers: any[] = [];
	private getListOfAssignToUserSubscription: Subscription;
	public listOfTaskTypes: any[] = [];
	public getPropertyOptionsSubscription: Subscription;
	private saveupdateTaskSbsn: Subscription;
	private editTaskData: any;
	private listOfAssignToClient: string[] = [];
	public listOfAssignToFiltered: any[] = [];
	public taskFilterValue: any = [];
	public aSelKeysCompany: any = [];
	public isDropdownOpenedCompany: boolean;
	private guid = new Guid();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowTaskPopup = true;

	// #region for Angular Life cycle
	constructor(private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _CrmsService: CrmsService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _GlobalStateService: GlobalStateService,
		private _ChangeDetectorRef: ChangeDetectorRef) {
		// Get current permissions
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
	}

	ngOnInit() {
		this.isShowTaskPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;

		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		this.isQBErpUser = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		this.taskStatuses = this.getTaskStatus();
		this.listOfTimeInvervals = this._CrmsService.getListOfTimeIntervals();

		/*
		this.isEditMode = this.data.isEditMode;
		this.taskTypeId = this.data.tasktype;
		// this.currentEntity = this.data.clientfrom;
		this.currentEntity = ServerEntity.CRMDATA;
		this.clientfrom = this.data.clientfrom;
		this.isAllReadOnlyForClosedTask = this.data.isAllReadOnlyForClosedTask || false;
		this.taskInsertUpdateUI.clientfrom = this.data.clientfrom;
		this.taskInsertUpdate = this.data.taskInsertUpdate || undefined;
		this.loadListOfCompanies();
		*/
		this._GlobalStateService.subscribe('GOT_ASSIGNED_TO_DATA_EVENT', response => {
			if (response) {
				if (response.isDefault) {
					this.setAssignToData();
				} else {
					this.setAssignToData(response);
				}
			}
		});
		this._GlobalStateService.subscribe('GOT_TASK_TYPE_DATA_EVENT', response => {
			if (response) {
				if (response.isDefault) {
					this.setTaskType();
				} else {
					this.setTaskType(response);
				}
			}
		});
		setTimeout(() => {
			this.fetchListOfTaskTypes();
			if (this.isEditMode) {
				// Edit Task Section
				this.editTaskInIt();
			} else {
				// Add Task Section
				this.addTaskInIt();
			}
		}, 300);
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['addeditTaskDetailsProps']) {
			const taskDetails = _SimpleChanges['addeditTaskDetailsProps'].currentValue;
			this.isEditMode = taskDetails.isEditMode;
			this.taskTypeId = taskDetails.tasktype;
			// this.currentEntity = taskDetails.clientfrom;
			this.currentEntity = ServerEntity.CRMDATA;
			this.clientfrom = taskDetails.clientfrom;
			this.isAllReadOnlyForClosedTask = taskDetails.isAllReadOnlyForClosedTask || false;
			this.taskInsertUpdateUI.clientfrom = taskDetails.clientfrom;
			this.taskInsertUpdateUI.contactpersons = taskDetails.contactpersons || [];
			this.taskInsertUpdateUI.attachment = taskDetails.external_data;
			// if (taskDetails.guidclient) {
			// 	this.aSelKeysCompany = [taskDetails.guidclient];
			// }
			this.taskInsertUpdate = taskDetails.taskInsertUpdate || undefined;
			this.loadListOfCompanies();
		}
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.saveupdateTaskSbsn) {
			this.saveupdateTaskSbsn.unsubscribe();
		}
		if (this.getListOfAssignToUserSubscription) {
			this.getListOfAssignToUserSubscription.unsubscribe();
		}
		if (this.getPropertyOptionsSubscription) {
			this.getPropertyOptionsSubscription.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('GOT_ASSIGNED_TO_DATA_EVENT');
		this._GlobalStateService.unsubscribe('GOT_TASK_TYPE_DATA_EVENT');
	}
	// #endregion

	// #region for Public Methods
	// Method to Dismiss Popup Dialog
	dismiss() {
		// this.dialogRef.close();
	}

	public doChangedTaskStatus(e) {
		this.isDisplayRemark = true;
		if (this.taskInsertUpdateUI.status === TaskStatusValEnum.CLOSE) {
			this.isDisplayRemark = false;
		}
	}

	// Method to Save/Update Task Object
	saveTask() {
		if (this.aSelKeysCompany && this.aSelKeysCompany.length <= 0) {
			this.taskInsertUpdateUI.title = '';
			this._ToastrService.info('Company is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (!this.taskInsertUpdateUI.title || (this.taskInsertUpdateUI.title && !this.taskInsertUpdateUI.title.trim())) {
			this.taskInsertUpdateUI.title = '';
			this._ToastrService.info('Task Title is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (!this.taskInsertUpdateUI.tasktype) {
			this._ToastrService.info('Task Type is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (!this.taskInsertUpdateUI.enddate) {
			this._ToastrService.info('Due Date is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		// else if (this.contactemployees && this.contactemployees.length <= 0) {
		// 	this._ToastrService.info('Please add a Contact Person to this Client before creating Tasks', 'Info', { closeButton: true, tapToDismiss: true });
		// 	return;
		// }


		// else if (this.taskInsertUpdateUI.enddate) {
		// 	const serverDate = this._LoginService.loginUser.server_date;
		// 	const mStart = moment(serverDate);
		// 	const mEnd = moment(this.taskInsertUpdateUI.enddate);
		// 	if (!mStart.isSameOrBefore(mEnd)) {
		// 		this._ToastrService.info('End Date should not be less than Current Date.', 'Info', { closeButton: true, tapToDismiss: true });
		// 		return;
		// 	}
		// }

		if (this.isAllReadOnlyForClosedTask) {
			if (!this.taskInsertUpdateUI.closedtasknote || (this.taskInsertUpdateUI.closedtasknote && !this.taskInsertUpdateUI.closedtasknote.trim())) {
				this.taskInsertUpdateUI.closedtasknote = this.taskInsertUpdateUI.closedtasknote.trim();
				this._ToastrService.info('Closed Task Note is required.', 'Info', { closeButton: true, tapToDismiss: true });
				return;
			}
		}
		if (this.isEditMode) {
			this.taskInsertUpdate.title = this.taskInsertUpdateUI.title.trim();
			this.taskInsertUpdate.tasktype = this.taskInsertUpdateUI.tasktype;
			this.taskInsertUpdate.notes = this.taskInsertUpdateUI.notes;
			// this.taskInsertUpdate.startdate = this.taskInsertUpdateUI.startdate;
			this.taskInsertUpdate.enddate = moment(this.taskInsertUpdateUI.enddate).format(DATETIME_FORMAT_BACKEND);
			this.taskInsertUpdate.timeinterval = this.taskInsertUpdateUI.timeinterval;
			this.taskInsertUpdate.status = this.taskInsertUpdateUI.status;
			this.taskInsertUpdate.assignto = this.taskInsertUpdateUI.assignto || [];
			this.taskInsertUpdate.contactpersons = this.taskInsertUpdateUI.contactpersons || [];
			this.taskInsertUpdate.closedtasknote = undefined;
			if (this.taskInsertUpdateUI.status === TaskStatusValEnum.CLOSE) {
				this.taskInsertUpdate.closedtasknote = this.taskInsertUpdateUI.closedtasknote;
			}

			const tasks = [];
			tasks.push(this.taskInsertUpdate);
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('tasks', JSON.stringify(tasks));

			if (this.saveupdateTaskSbsn) {
				this.saveupdateTaskSbsn.unsubscribe();
			}

			this.saveupdateTaskSbsn = this._RestApiService.doDataInFormDataReq(reqFormData).subscribe({
				next: (response) => {
					if (response && response.flag) {
						this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
						this.closeAddEditTaskPopup.emit(true);
						this.isShowTaskPopup = false;
					} else {
						this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				}, error: (error) => {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			});

		} else {
			this.taskInsertUpdate = new TaskInsertUpdate();
			this.taskInsertUpdate.guid = this.guid.newGuid();
			this.taskInsertUpdate.title = this.taskInsertUpdateUI.title.trim();
			this.taskInsertUpdate.tasktype = this.taskInsertUpdateUI.tasktype;
			this.taskInsertUpdate.notes = this.taskInsertUpdateUI.notes;
			this.taskInsertUpdate.contactfrom = this.clientfrom;
			// this.taskInsertUpdate.startdate = this.taskInsertUpdateUI.startdate;
			this.taskInsertUpdate.enddate = moment(this.taskInsertUpdateUI.enddate).format(DATETIME_FORMAT_BACKEND);
			this.taskInsertUpdate.timeinterval = this.taskInsertUpdateUI.timeinterval;
			this.taskInsertUpdate.status = this.taskInsertUpdateUI.status;
			this.taskInsertUpdate.assignto = this.taskInsertUpdateUI.assignto || [];
			this.taskInsertUpdate.guid = this.taskInsertUpdateUI.guid || undefined;
			this.taskInsertUpdate.guidclient = this.taskInsertUpdateUI.guidclient || undefined;
			this.taskInsertUpdate.contactpersons = this.taskInsertUpdateUI.contactpersons || [];


			const tasks = [];
			tasks.push(this.taskInsertUpdate);
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('tasks', JSON.stringify(tasks));

			if (this.saveupdateTaskSbsn) {
				this.saveupdateTaskSbsn.unsubscribe();
			}

			this.saveupdateTaskSbsn = this._RestApiService.doDataInFormDataReq(reqFormData).subscribe({
				next: (response) => {
					if (response && response.flag) {
						this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
						this.closeAddEditTaskPopup.emit(true);
						this.isShowTaskPopup = false;
					} else {
						this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				}, error: (error) => {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			});

		}
		if (this.addeditTaskDetailsProps.clientfrom !== this.taskInsertUpdateUI.clientfrom) {
			this.addeditTaskDetailsProps.clientfrom = this.taskInsertUpdateUI.clientfrom;
		}
		this.addeditTaskDetailsProps.taskInsertUpdate = this.taskInsertUpdate;
		this.addeditTaskDetailsProps.guidcontactperson = this.taskInsertUpdateUI.guidcontactperson;
		this.addeditTaskDetailsProps.guidclient = this.taskInsertUpdateUI.guidclient;
		this.addeditTaskDetailsProps.clientdetail = this.taskInsertUpdateUI.clientdetail;
		// this.dialogRef.close(this.data);
		// this.closeAddEditTaskPopup.emit(this.addeditTaskDetailsProps);
	}

	// Method to Fetch Task Status like Open/Close
	getTaskStatus() {
		const taskStatuses: CommonOptions[] = [];
		for (const taskStatus in TaskStatusValEnum) {
			if (TaskStatusValEnum[taskStatus]) {
				const commonOption = new CommonOptions();
				commonOption.id = TaskStatusValEnum[taskStatus];
				commonOption.name = TaskStatusLblEnum[taskStatus];
				taskStatuses.push(commonOption);
			}
		}
		return taskStatuses;
	}

	public doCompanydropdownOptionChanged(e) {
		if (e.name === 'value') {
			this.isDropdownOpenedCompany = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}
	public doSelectionChangedCompany(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			const client = e.selectedRowsData[0];
			this.taskInsertUpdateUI.clientname = client.name;
			this.taskInsertUpdateUI.guidclient = client.guid;
			this.taskInsertUpdateUI.clientdetail = client;
			this.taskInsertUpdateUI.clientfrom = this.clientfrom = client.crm_type;

			if (client.assignto) {
				this.taskInsertUpdateUI.assignto = [client.assignto];
			}
			this.taskInsertUpdateUI.contactpersons = [];
			this.listOfAssignToFiltered = [];
			this.listOfAssignToClient = client.assignto || [];
			if (client.contactemployees && client.contactemployees.length > 0) {
				this.contactemployees = client.contactemployees;
				const contactEmployeeIndex = this.contactemployees.findIndex(itemEle => itemEle.name === client.contactperson);
				if (contactEmployeeIndex !== -1) {
					const contactEmployeeGuid = this.contactemployees[contactEmployeeIndex].guid;
					// this.taskInsertUpdateUI.guidcontactperson = this.contactemployees[contactEmployeeIndex].guid;
					// this.taskInsertUpdateUI.contactpersondetail = JSON.parse(JSON.stringify(this.contactemployees[contactEmployeeIndex]));
					this.taskInsertUpdateUI.contactpersons = [contactEmployeeGuid];
				} else if (this.contactemployees && this.contactemployees.length > 0) {
					const contactEmployeeGuid = this.contactemployees[0].guid;
					// this.taskInsertUpdateUI.guidcontactperson = this.contactemployees[0].guid;
					// this.taskInsertUpdateUI.contactpersondetail = JSON.parse(JSON.stringify(this.contactemployees[0]));
					this.taskInsertUpdateUI.contactpersons = [contactEmployeeGuid];
				}
			}
			this.getListOfAssignToUsers();
		}
	}

	// #endregion

	// #region for Private Methods

	// Set request object to fetch data of selected tabs

	// this.listOfAssignToFiltered = [];
	private loadListOfCompanies() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: this.currentEntity,
		};

		this.clientsList = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: function (method, ajaxOptions) {
			},
			onLoaded(result) {
			}
		});
	}
	// Set table grid data

	// Method To set Data during AddMode
	private addTaskInIt() {
		this.taskInsertUpdateUI.status = TaskStatusValEnum.OPEN;
		this.taskInsertUpdateUI.timeinterval = this.listOfTimeInvervals[0].id;
		this.taskInsertUpdateUI.assignto = [];
		this.isReadOnlyClient = false;
		if (this.addeditTaskDetailsProps.guidclient !== undefined) {
			this.isReadOnlyClient = true;
			const clientDetail = JSON.parse(JSON.stringify(this.addeditTaskDetailsProps.clientdetail));
			// if (this.clientfrom === NameFromServerKeyEnum.LEAD || this.clientfrom === NameFromServerKeyEnum.PROSPECT) {
			// 	if (clientDetail.assignto) {
			// 		this.taskInsertUpdateUI.assignto = clientDetail.assignto;
			// 	}
			// }
			this.listOfAssignToFiltered = [];
			this.listOfAssignToClient = clientDetail.assignto;
			this.taskInsertUpdateUI.clientname = clientDetail.name;
			this.taskInsertUpdateUI.guidclient = clientDetail.guid;
			this.taskInsertUpdateUI.clientdetail = clientDetail;
			this.aSelKeysCompany = [];
			this.aSelKeysCompany.push(clientDetail.guid);
			this.contactemployees = clientDetail.contactemployees || [];
			const contactEmployeeIndex = clientDetail.contactemployees.findIndex(itemEle => itemEle.guid === this.addeditTaskDetailsProps.guidcontactperson);
			if (contactEmployeeIndex !== -1) {
				const contactEmployeeGuid = clientDetail.contactemployees[contactEmployeeIndex].guid;
				// this.taskInsertUpdateUI.guidcontactperson = clientDetail.contactemployees[contactEmployeeIndex].guid;
				// this.taskInsertUpdateUI.contactpersondetail = JSON.parse(JSON.stringify(this.contactemployees[contactEmployeeIndex]));
				this.taskInsertUpdateUI.contactpersons = [contactEmployeeGuid];
			}
			this.getListOfAssignToUsers();
		}
	}

	// Method To set Data during EditMode
	private editTaskInIt() {
		this.isReadOnlyClient = true;
		this.editTaskData = this.addeditTaskDetailsProps.taskInsertUpdate;
		const taskData = this.addeditTaskDetailsProps.taskInsertUpdate;
		this.taskInsertUpdateUI.tasktype = taskData.tasktype;
		this.taskInsertUpdateUI.title = taskData.title;
		this.taskInsertUpdateUI.status = taskData.status;
		this.taskInsertUpdateUI.assignto = taskData.assignto || '';
		this.taskInsertUpdateUI.notes = taskData.notes;
		this.taskInsertUpdateUI.guid = taskData.guid;
		this.taskInsertUpdateUI.closedtasknote = taskData.closedtasknote;
		if (taskData.status === TaskStatusValEnum.CLOSE) {
			this.isDisplayRemark = false;
		}
		// if (taskData.startdate) {
		// 	this.taskInsertUpdateUI.startdate = moment(taskData.startdate).toDate();
		// }
		if (taskData.enddate) {
			this.taskInsertUpdateUI.enddate = moment(taskData.enddate).toDate();
		}
		this.taskInsertUpdateUI.timeinterval = this.listOfTimeInvervals[0].id;
		if (taskData.timeinterval) {
			this.taskInsertUpdateUI.timeinterval = taskData.timeinterval;
		}
		if (this.addeditTaskDetailsProps.guidclient !== undefined) {
			this.isReadOnlyClient = true;
			let clientDetail = undefined;
			try {
				clientDetail = JSON.parse(JSON.stringify(this.addeditTaskDetailsProps.clientdetail));
			} catch (error) {
				clientDetail = undefined;
			}
			if (clientDetail !== undefined) {
				this.listOfAssignToFiltered = [];
				this.listOfAssignToClient = clientDetail.assignto;
				this.taskInsertUpdateUI.clientname = clientDetail.name;
				this.taskInsertUpdateUI.guidclient = clientDetail.guid;
				this.taskInsertUpdateUI.clientdetail = clientDetail;
				this.aSelKeysCompany = [];
				this.aSelKeysCompany.push(clientDetail.guid);
				this.taskFilterValue = ["guid", "contains", clientDetail.guid];
				this.contactemployees = clientDetail.contactemployees || [];
				const contactEmployeeIndex = clientDetail.contactemployees.findIndex(itemEle => itemEle.guid === this.addeditTaskDetailsProps.guidcontactperson);
				if (contactEmployeeIndex !== -1) {
					this.taskInsertUpdateUI.guidcontactperson = clientDetail.contactemployees[contactEmployeeIndex].guid;
					this.taskInsertUpdateUI.contactpersondetail = JSON.parse(JSON.stringify(this.contactemployees[contactEmployeeIndex]));
				}
			}
			this.getListOfAssignToUsers();
		}
	}

	// #region for Logic related Assigned To

	// Method used to Fetched list of AssignTO Users
	private getListOfAssignToUsers() {
		let listOfUserTypes = [];
		try {
			listOfUserTypes = this._LoginService.loginUser.account_detail.userType || [];
		} catch (error) {
			listOfUserTypes = [];
		}
		//const selectedListOfUserTypes = listOfUserTypes.filter(userType => userType.can_login === true);
		const selectedListOfUserTypes = listOfUserTypes;

		if (selectedListOfUserTypes && selectedListOfUserTypes.length > 0) {
			const listOfRoles = [];
			selectedListOfUserTypes.forEach(userType => {
				listOfRoles.push(userType.user_type);
			});
			const listOfSearches = [];
			listOfSearches.push({ status: 'active' });
			listOfSearches.push({ role: listOfRoles });
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
			reqFormData.append('entity', ServerEntity.SUBUSERS);
			reqFormData.append('is_dropdown', 'true');
			reqFormData.append('search', JSON.stringify(listOfSearches));
			const viewFields = ['sub_account_id', 'first_name', 'last_name', 'role', 'status'];
			reqFormData.append('view_fields', JSON.stringify(viewFields));
			if (this.getListOfAssignToUserSubscription) {
				this.getListOfAssignToUserSubscription.unsubscribe();
			}
			this.listOfAssignToUsers = [];
			this.getListOfAssignToUserSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
				next: (response) => {
					if (response && response.flag) {
						this.listOfAssignToUsers = response.data || [];
						this._GlobalStateService.notifyDataChangedDuplicate('GOT_ASSIGNED_TO_DATA_EVENT', { isDefault: true });
					}
				}, error: (error) => {
				}
			});
		}
	}

	public getAssignToDisplayExpr(e) {
		if (e) {
			if (e.last_name) {
				return e.first_name + ' ' + e.last_name;
			} else {
				return e.first_name;
			}
		}
		return e || '';
	}

	public getContactEmployeeNameDisplayExpr(e) {
		if (e) {
			if (e.firstname) {
				if (e.lastname) {
					return e.firstname + ' ' + e.lastname;
				} else {
					return e.firstname;
				}
			} else {
				return e.name;
			}
		}
		return e || '';
	}

	private setAssignToData(assignTo?: string) {
		this.setListOfAssignToFilterd();
		const sub_account_id = this._LoginService.loginUser.account_detail.sub_account_id;
		let subAccountIdIndex = -1;
		if (this.isEditMode) {
			if (!this.taskInsertUpdateUI.assignto) {
				subAccountIdIndex = this.listOfAssignToFiltered.findIndex(user => user.sub_account_id === sub_account_id);
				if (subAccountIdIndex === -1) {
					subAccountIdIndex = 0;
				}
				if (this.listOfAssignToFiltered && this.listOfAssignToFiltered.length > 0) {
					this.taskInsertUpdateUI.assignto = [this.listOfAssignToFiltered[subAccountIdIndex].sub_account_id];
				}
			}
		} else {
			subAccountIdIndex = this.listOfAssignToFiltered.findIndex(user => user.sub_account_id === sub_account_id);
			if (subAccountIdIndex === -1) {
				subAccountIdIndex = 0;
			}
			if (this.listOfAssignToFiltered && this.listOfAssignToFiltered.length > 0) {
				this.taskInsertUpdateUI.assignto = [this.listOfAssignToFiltered[subAccountIdIndex].sub_account_id];
			}
		}
	}

	// Method used to Filtered AssignTo List from assignto property of Lead, Prospect, Client
	private setListOfAssignToFilterd() {
		const listOfCustomAssignToData = [];
		this.listOfAssignToClient.forEach(user => {
			const selectedIndex = this.listOfAssignToUsers.findIndex(assignTo => assignTo.sub_account_id === user);
			if (selectedIndex !== -1) {
				listOfCustomAssignToData.push(this.listOfAssignToUsers[selectedIndex]);
			}
		});
		// this.listOfAssignToFiltered = listOfCustomAssignToData;
		this.listOfAssignToFiltered = this.listOfAssignToUsers;
	}
	// #endregion

	// #region for Task Types

	// Method used to Fetch list of Task types
	private fetchListOfTaskTypes() {
		let dynamic_groups = [];
		let dynamic_properties = [];
		try {
			dynamic_groups = this._LoginService.loginUser.account_detail.dynamic_groups || [];
			dynamic_properties = this._LoginService.loginUser.account_detail.dynamic_properties || [];
		} catch (error) {
			dynamic_groups = [];
			dynamic_properties = [];
		}

		const dgIndex = dynamic_groups.findIndex(dg => dg.group_name.toString().toLowerCase() === 'task');
		if (dgIndex !== -1) {
			const dgData = dynamic_groups[dgIndex];
			const dpIndex = dynamic_properties.findIndex(dp => dp.group_guid === dgData.guid && dp.property.toString().toLowerCase() === 'tasktype');
			if (dpIndex !== -1) {
				const dpData = dynamic_properties[dpIndex];
				const reqFormData = new FormData();
				reqFormData.append('usr', this._LoginService.loginUser.user);
				reqFormData.append('token', this._LoginService.loginUser.token);
				reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
				reqFormData.append('entity', ServerEntity.DYNAMIC_PROPERTY_OPTIONS);
				reqFormData.append('search', JSON.stringify([{ property_guid: dpData.guid }]));
				reqFormData.append('sort_dir', 'asc');
				reqFormData.append('sort_field', 'sequence');
				reqFormData.append('view_fields', JSON.stringify(['guid', 'property_guid', 'value', 'label', 'sequence']));
				if (this.getPropertyOptionsSubscription) {
					this.getPropertyOptionsSubscription.unsubscribe();
				}
				this.listOfTaskTypes = [];
				this._LoaderService.show();
				this.getPropertyOptionsSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
					next: (response) => {
						this._LoaderService.hide();
						if (response && response.flag) {
							this.listOfTaskTypes = response.data || [];
							this._GlobalStateService.notifyDataChangedDuplicate('GOT_TASK_TYPE_DATA_EVENT', { isDefault: true });
						}
					}, error: (error) => {
						this._LoaderService.hide();
					}
				});
			}
		}
	}

	// Method used to Set Task Type
	private setTaskType(response?: any) {
		if (this.isEditMode) {
			if (!this.editTaskData || (this.editTaskData && !this.editTaskData.tasktype)) {
				try {
					this.taskInsertUpdateUI.tasktype = this.listOfTaskTypes[0].value;
				} catch (error) {
					this.taskInsertUpdateUI.tasktype = '';
				}
			} else {
				const ttIndex = this.listOfTaskTypes.findIndex(tt => tt.value === this.editTaskData.tasktype);
				if (ttIndex === -1) {
					try {
						this.taskInsertUpdateUI.tasktype = this.listOfTaskTypes[0].value;
					} catch (error) {
						this.taskInsertUpdateUI.tasktype = '';
					}
				}
			}
		} else {
			try {
				this.taskInsertUpdateUI.tasktype = this.taskTypeId || this.listOfTaskTypes[0].value;
			} catch (error) {
				this.taskInsertUpdateUI.tasktype = '';
			}
		}
	}
	// #endregion

	// #endregion
}
