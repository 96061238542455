import { ChargeDocumentsTabModel, EndUsersChargebackContractModelUI } from '@app/models/end-users-chargeback-contract.model';
import { Component, Inject, OnInit, ViewChild, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Guid } from '@app/models/guid';
import { Subscription } from 'rxjs';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import * as moment from 'moment';

@Component({
	selector: 'app-upload-chargeback-enduser-claims-dialog',
	templateUrl: './upload-chargeback-enduser-claims-dialog.component.html',
	styleUrls: ['./upload-chargeback-enduser-claims-dialog.component.css']
})
export class UploadChargebackEnduserClaimsDialogComponent implements OnInit, OnDestroy {
	public itemTabDataList: any;
	public chargeBackDocumentsTabDatalist: any;
	public chargeDocumentsTabDataSource: any;
	public uploadFiles: NgxFileDropEntry[] = [];
	public uploadedCsvFilesData: any;
	public actionFor: string = 'showUploadFile';
	public billbackType: string;
	public dxDataSource: any;
	public pageOptions: PageOptions = new PageOptions();
	public headerField: any = ['item', 'uom', 'quantity'];
	public currentTab: string;
	public globalCurrency: string;
	public ignoredListOfCsvRows: any[] = [];
	public successedListOfCsvRows: any[] = [];
	public claimDocumentUploadFile: any;
	public selectedListOfShortPayTask: any[] = [];
	@ViewChild('listOfShortPayTaskDataGrid', {}) listOfShortPayTaskDataGrid: DxDataGridComponent;
	public shortPayTaskDataSource: any;
	public chargeDocumentsTabModel = new ChargeDocumentsTabModel();
	public documentFileData: any;
	public productSearchItemType: string;
	private dataInReqSubscription: Subscription;
	private dataOutShortPayReqSubscription: Subscription;
	public guid = new Guid();
	public editChargebackGuid: any;
	public enable_chargeback_look_back_period: boolean = false;
	public claim_date: any;
	public shortPayTaskList: any[];
	public endUsersChargebackContractModelUI = new EndUsersChargebackContractModelUI();
	public decimalPointForCurrency: number;
	public pageTitle: string;
	public isShowPopup: boolean = true;
	@Input() uploadChargebackEndUserClaimsProps: any;
	@Output() closeUploadChargebackEnduserClaimsPopup = new EventEmitter<any>();
	constructor(
		public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService,
	) { }

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.endUsersChargebackContractModelUI = this.uploadChargebackEndUserClaimsProps.endUsersChargebackContractModelUI;
		// short pay task list
		if (this._LoginService.loginUser.account_detail['enable_shortpay_notification']) {
			this.getShortPayTaskList();
		}
		this.billbackType = this.uploadChargebackEndUserClaimsProps.billbackType;
		this.actionFor = this.uploadChargebackEndUserClaimsProps.actionFor;
		this.itemTabDataList = this.uploadChargebackEndUserClaimsProps.itemTabDataList;
		this.productSearchItemType = this.uploadChargebackEndUserClaimsProps.productSearchItemType;
		this.editChargebackGuid = this.uploadChargebackEndUserClaimsProps.editChargebackGuid;
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.pageTitle = this.actionFor === 'showUploadFile' ? 'Add Claim' : this.actionFor === 'showIgnoreItem' ? 'Ignored Item(s) List' : '';
		if (this.actionFor === 'showIgnoreItem') {
			this.ignoredListOfCsvRows = this.uploadChargebackEndUserClaimsProps.ignoredListOfCsvRows;
			this.successedListOfCsvRows = this.uploadChargebackEndUserClaimsProps.successedListOfCsvRows;
			this.doChangeTab('ignored_claim');
		}

		if (this.billbackType === 'chargeback') {
			this.headerField = ['item', 'uom', 'quantity']
			// this.headerField = ['item', 'uom', 'pricelist', 'quantity']
		}
		if (this.billbackType === 'enduserschargeback') {
			this.headerField = ['item', 'uom', 'end_user', 'quantity']
		}
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.enable_chargeback_look_back_period = this._LoginService.loginUser.account_detail.enable_chargeback_look_back_period || false;
		}
	}

	ngOnDestroy() {
		if (this.dataOutShortPayReqSubscription) {
			this.dataOutShortPayReqSubscription.unsubscribe();
		}
	}

	// #endregion

	//Short Pay task
	getShortPayTaskList() {
		if (this.dataOutShortPayReqSubscription) {
			this.dataOutShortPayReqSubscription.unsubscribe();
		}
		this.dataOutShortPayReqSubscription = this._EndUsersChargebackContractService.getShortPayTaskList(this.endUsersChargebackContractModelUI.selectCustomerId).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						if (response.data && response.data.length > 0) {
							let shortPayTaskLst = [];
							response.data.forEach(element => {
								if (element['external_data']) {
									shortPayTaskLst.push(element['external_data']);
								}
							});
							this.shortPayTaskList = shortPayTaskLst;
							if (this.shortPayTaskList) {
								this.shortPayTaskDataSource = this.shortPayTaskList;
							}
						}
					}
					else {
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				}
			},
			error: (error) => {
				this._ToastrService.error(error, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	//end

	// Method used to set active flag to selected tab
	public doChangeTab(newTab: string) {
		if (this.currentTab === newTab) {
			return;
		}
		this.currentTab = newTab;
		switch (newTab) {
			case 'ignored_claim':
				this.dxDataSource = this.ignoredListOfCsvRows;
				break;
			case 'successed_claim':
				this.dxDataSource = this.successedListOfCsvRows;
				break;
			default:
				break;
		}
	}

	// Method used to upload functionality for Csv,xlsx files
	public doOnFileDrop(event) {
		this.uploadFiles = event;
		for (const droppedFile of event) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					const fileExtension = file.name.split('.')[file.name.split('.').length - 1];
					if (fileExtension === 'csv' || fileExtension === 'xlsx' || fileExtension === 'xls') {
						// Allow 25MB file
						if (file.size > 25000000) {
							this._ToastrService.info('Please upload less than 25 MB size CSV file', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						this.uploadedCsvFilesData = file;

					} else {
						this._ToastrService.info('Please upload CSV file', 'Info', { closeButton: true, tapToDismiss: true });
					}
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
			}
		}
	}

	// Method used to Remove file from list of files
	public doRemoveUploadFiles() {
		this.uploadedCsvFilesData = undefined;
	}

	// Method used to get Json from file and send it to datagrid
	public doUploadCsvFile() {
		if (!this.uploadedCsvFilesData || (this.uploadedCsvFilesData.name && !this.uploadedCsvFilesData.name)) {
			this._ToastrService.info('Choose Csv or Excel File', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		this.getReadCsvFile().then(result => {
			const listOfRows = result;
			if (listOfRows && listOfRows.length <= 0) {
				this._ToastrService.info('No records available in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
			this.uploadChargebackEndUserClaimsProps.listOfCsvRows = listOfRows;
			// this.dialogData.fileName = this.uploadedCsvFilesData.name;
			// this.dialogData.claimDocumentUploadFile = this.uploadedCsvFilesData;
			// this.dialogData.chargeDocumentsTabModel = this.chargeDocumentsTabModel;
			// this.dialogData.documentFileData = this.documentFileData;
			this.submitEndUserClaim();

		}).catch(error => {
			const listOfRows = error;
			if (listOfRows && listOfRows.length <= 0) {
				this._ToastrService.info('No records available in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		});
	}

	public submitEndUserClaim() {
		const itemTabDataList = this.itemTabDataList || [];
		const listOfCsvRows = this.uploadChargebackEndUserClaimsProps.listOfCsvRows || [];
		this.claimDocumentUploadFile = this.uploadedCsvFilesData || [];
		this.chargeDocumentsTabModel = this.chargeDocumentsTabModel; //short pay task changes
		this.documentFileData = this.documentFileData; //short pay task changes

		let claimItemList: any = [];

		if (this.productSearchItemType === 'item') {
			if (listOfCsvRows.length > 0 && itemTabDataList.length > 0) {
				itemTabDataList.forEach(elementTab => {
					if (elementTab['items'] && elementTab['items'].length) {
						elementTab['items'].forEach(element => {
							const filterItemIndex = listOfCsvRows.findIndex(i => i.item.toString().toLowerCase() === element.itemname.toString().toLowerCase() && i.end_user.toString().toLowerCase() == elementTab.end_user_name.toString().toLowerCase());
							element['claimed'] = 0;
							const itemObj = {
								itemsku: element['itemsku'] || '',
								itemname: element['itemname'] || '',
								description: element['description'] || '',
								erp_primary_key: element['erp_primary_key'] || '',
								end_user_id: elementTab['end_user_id'] || '',
								end_user_name: elementTab['end_user_name'] || '',
								uomList: element['uomList'] || [],
								selectedClaimedUom: element['selectedUom'] ? element['selectedUom'].toLowerCase() : '',
								last_invoice_date: element['last_invoice_date'] || '',
								// discountedPrice: item['chargebackAmount'] || '',
								chargebackAmount: element['chargebackAmount'] || '',
								available: 0,
								billed: 0,
								bought: 0,
								claimed: filterItemIndex !== -1 ? listOfCsvRows[filterItemIndex]['quantity'] : 0,
								sold: 0
							}
							// as per IX-2121 task add optional "amount" field.
							if (filterItemIndex !== -1 && listOfCsvRows[filterItemIndex]['amount']) {
								itemObj['amount'] = listOfCsvRows[filterItemIndex]['amount'] || 0;
							}
							if (filterItemIndex !== -1) {
								this.successedListOfCsvRows.push(itemObj);
								claimItemList.push(itemObj);
							} else {
								this.ignoredListOfCsvRows.push(itemObj);
							}
						});
					}
				});

				//if (this.successedListOfCsvRows.length > 0) {
				if (this.checkValidation()) {
					this.saveClaimedItems(claimItemList);
				}
				//}
			}
		}
		if (this.productSearchItemType === 'price') {
			if (listOfCsvRows.length > 0 && itemTabDataList.length > 0) {
				itemTabDataList.forEach(elementEU => {
					const itemDetails = elementEU.items;
					if (itemDetails && itemDetails.length > 0) {
						itemDetails.forEach(element => {
							const filterItemIndex = listOfCsvRows.findIndex(i => i.item.toString() === element.itemname.toString() && i.end_user == elementEU.end_user_name);
							element['claimed'] = 0;
							const itemObj = {
								end_user_id: elementEU['end_user_id'] || '',
								end_user_name: elementEU['end_user_name'] || '',
								pricelistno: elementEU['pricelistno'] || '',
								pricelistname: elementEU['pricelistname'] || '',
								itemsku: element['itemsku'] || '',
								itemname: element['itemname'] || '',
								description: element['description'] || '',
								erp_primary_key: element['erp_primary_key'] || '',
								uomList: element['uomList'] || [],
								selectedClaimedUom: filterItemIndex !== -1 ? (listOfCsvRows[filterItemIndex]['uom'] ? listOfCsvRows[filterItemIndex]['uom'].toLowerCase() : '') : (element['selectedUom'] ? element['selectedUom'].toLowerCase() : ''),
								last_invoice_date: element['last_invoice_date'] || '',
								// discountedPrice: item['chargebackAmount'] || '',
								chargebackAmount: element['chargebackAmount'] || '',
								available: 0,
								billed: 0,
								bought: 0,
								claimed: filterItemIndex !== -1 ? listOfCsvRows[filterItemIndex]['quantity'] : 0,
								sold: 0,
							}
							// as per IX-2121 task add optional "amount" field.
							if (filterItemIndex !== -1 && listOfCsvRows[filterItemIndex]['amount']) {
								itemObj['amount'] = listOfCsvRows[filterItemIndex]['amount'] || 0;
							}
							if (filterItemIndex !== -1) {
								this.successedListOfCsvRows.push(itemObj);
								claimItemList.push(itemObj);
							} else {
								this.ignoredListOfCsvRows.push(itemObj);
							}
						});
					}
				});

				//if (this.successedListOfCsvRows.length > 0) {
				this.saveClaimedItems(claimItemList);
				//}
			}
		}
	}

	public saveClaimedItems(claimedItems) {
		this.uploadChargebackEndUserClaimsProps.ignoredListOfCsvRows = this.ignoredListOfCsvRows;
		this.uploadChargebackEndUserClaimsProps.successedListOfCsvRows = this.successedListOfCsvRows;
		this._LoaderService.show();

		const item = {
			guid: this.guid.newGuid(),
			documentURL: this.chargeDocumentsTabModel.documentURL,
			documentReferenceNumber: this.chargeDocumentsTabModel.documentReferenceNumber,
			documentDate: '',
			claimedUserNote: this.chargeDocumentsTabModel.claimedUserNote || '',
			claimedStatus: '',
			claimedItems: claimedItems,
		}
		const arrChargebackDocumentsTabList = [];
		arrChargebackDocumentsTabList.push(item);
		// const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.billBackChargeBackRequest.guid + '&method=claimChargeback' + '&chargebackDocs=' + encodeURIComponent(JSON.stringify(arrChargebackDocumentsTabList))
		// this.dataInReqSubscription = this._RestApiService.doDataOutReq((reqFormData)).subscribe(
		let shortpayid: string;
		if (this.listOfShortPayTaskDataGrid && this.listOfShortPayTaskDataGrid.selectedRowKeys && this.listOfShortPayTaskDataGrid.selectedRowKeys.length == 1) {
			shortpayid = this.listOfShortPayTaskDataGrid.selectedRowKeys[0];
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.editChargebackGuid);
		formData.append('documentUploadFile', this.documentFileData);
		formData.append('claimDocumentUploadFile', this.claimDocumentUploadFile);
		formData.append('chargebackDocs', (JSON.stringify(arrChargebackDocumentsTabList)));
		formData.append('shortpayid', shortpayid);
		formData.append('method', 'claimChargeback');
		formData.append('contracttype', 'enduserschargebackcontracts');
		// enable chargeback look back period usersetting for IPG customer
		if (this.enable_chargeback_look_back_period) {
			const claimDate = moment(this.claim_date).format('YYYY-MM-DD') || '';
			formData.append('claim_date', claimDate);
		}

		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this.itemTabDataList = response.data.itemSold;
					this.chargeBackDocumentsTabDatalist = response.data.chargebackDocuments;
					this.chargeDocumentsTabDataSource = this.chargeBackDocumentsTabDatalist;
					this.uploadChargebackEndUserClaimsProps.chargeBackDocumentsTabDatalist = this.chargeBackDocumentsTabDatalist;
					if (this.editChargebackGuid) {
						this._EndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
					}
					this.closeUploadChargebackEnduserClaimsPopup.emit(this.uploadChargebackEndUserClaimsProps);
					this._ToastrService.success('Document Added Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			},
			error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	checkValidation() {
		let valDoc: boolean = true;
		if (!this.editChargebackGuid) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (this.itemTabDataList.length < 0) {
			this._ToastrService.info('Please add at least one product.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.documentFileData) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.chargeDocumentsTabModel.documentURL || !this.chargeDocumentsTabModel.documentURL.trim()) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.chargeDocumentsTabModel.documentReferenceNumber || !this.chargeDocumentsTabModel.documentReferenceNumber.trim()) {
			this._ToastrService.info('Document Reference No. Required .', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		}
		return valDoc;
	}


	// Method used to Read Csv or Xlss file
	async getReadCsvFile(): Promise<any> {
		return new Promise<string>((resolve, reject) => {
			let csvRowsToJson: any = [];
			if (this.uploadedCsvFilesData && this.uploadedCsvFilesData.name) {
				const file = this.uploadedCsvFilesData;
				const fileReader = new FileReader();
				fileReader.onload = (e) => {
					const arrayBuffer: any = fileReader.result;
					const data = new Uint8Array(arrayBuffer);
					const arr = [];
					for (let i = 0; i !== data.length; ++i) {
						arr[i] = String.fromCharCode(data[i]);
					}
					const bstr = arr.join("");
					const workbook: any = XLSX.read(bstr, { type: "binary" });
					const firstSheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[firstSheetName];

					this.headerField = this.getHeaderRow(worksheet);
					csvRowsToJson = XLSX.utils.sheet_to_json(worksheet, { header: this.headerField, raw: true });
					csvRowsToJson.splice(0, 1);
					resolve(csvRowsToJson);
				};
				fileReader.readAsArrayBuffer(file);
			} else {
				reject(csvRowsToJson);
			}
		});
	}

	// Method used to get header Csv or Xlsx file
	private getHeaderRow(sheet) {
		const headers = [];
		const range = XLSX.utils.decode_range(sheet['!ref']);
		let C, R = range.s.r; /* start in the first row */
		/* walk every column in the range */
		for (C = range.s.c; C <= range.e.c; ++C) {
			const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
			// console.log("cell",cell)
			let hdr = "UNKNOWN " + C; // <-- replace with your desired default
			if (cell && cell.t) {
				hdr = XLSX.utils.format_cell(cell).trim().toLowerCase();
				headers.push(hdr);
			}
		}
		return headers;
	}


	//short pay task list methods
	public doChangeDatagridShortPayTask(e) {
		//allowd only single selection in short pay data list
		if (e['selectedRowsData'] && e['selectedRowsData'].length > 1) {
			e.component.deselectRows(this.listOfShortPayTaskDataGrid.selectedRowKeys[0]);
		}
	}

	public onInitializedShortPayTask(e) {
		// setTimeout(() => {
		// 	this.listOfShortPayTaskDataGrid.selectedRowKeys = this.selectedListOfShortPayTask;
		// }, 50);
	}

	// Upload document
	uploadDocument(files: File[]) {
		if (files.length === 1) {
			// file type verify
			const fileType = files[0].type;
			if (fileType !== '.doc' && fileType !== '.docx' && fileType !== 'application/msword' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				&& fileType !== '.csv' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && fileType !== 'application/vnd.ms-excel' && fileType !== 'application/pdf') {
				this._ToastrService.info('File extension not valid', 'Info!', { closeButton: true, tapToDismiss: true });
				this.chargeDocumentsTabModel.documentURL = '';
				return false;
			}
			// file size convert KB
			const fileSize = Math.round(files[0].size / 5120);
			if (fileSize > 5120) {
				this._ToastrService.info('File size should not greater then 5 MB', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
			this.documentFileData = files[0];
			this.chargeDocumentsTabModel.documentURL = files[0].name;
			// this._FileUploadService.upload(files);

		}
	}
	//end short pay task list methods

}
