<dx-popup [showTitle]="true" [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[fullScreen]="true" [height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [title]="titleText"
	[dragEnabled]="true" [hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="isShowPopup"
	(onHidden)="doHiddenPopop()" [wrapperAttr]="{ class: 'popupToolbarBottomPaddingRemoveIx' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" width="100%" height="100%">
			<dx-data-grid #closeMultiSettlmenttDataGridContainer [dataSource]="closeMultiSettlementDataSource"
				[remoteOperations]="false" [wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true"
				[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true"
				[filterValue]="filterValue" [remoteOperations]="false" [columnAutoWidth]="true"
				[allowColumnReordering]="true" [width]="'100%'" [height]="'calc(100vh - 140px)'"
				(onEditorPreparing)="onEditorPreparing($event)"
				(onSelectionChanged)="onCloseMultipleSelectionChanged($event)" [(selectedRowKeys)]="selectedRows"
				(onCellPrepared)="doOnCellPreparedCloseMultipalTab($event)"
				(onCellClick)="doOnCellClickCloseMultipalTab($event)" (onToolbarPreparing)="doToolbarPreparing($event)"
				class="cellColor hoverClr hideFilterPanelInx" keyExpr="guid">
				<dxo-selection mode="multiple" [allowSelectAll]="false"> </dxo-selection>
				<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-filter-panel [visible]="true"></dxo-filter-panel>
				<dxo-header-filter [visible]="true"></dxo-header-filter>
				<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
				<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadViewCloseMultipleSettlementDocument"
					[customSave]="doSaveViewCloseMultipleSettlementDocument" [storageKey]="stateStorageKey">
				</dxo-state-storing>
				<!-- <div *dxTemplate="let data of 'templateFilterText'">
					<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
						<span>{{filterText}}</span>
					</div>
				</div> -->
				<dxi-column caption="Contract Name" dataField="contractName" cellTemplate="cellTemplateContractName"
					dataType="string" [allowHeaderFiltering]="false">
					<div *dxTemplate="let element of 'cellTemplateContractName'">
						{{element.data.contractName || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Sales Person" dataField="salesperson_name" cellTemplate="cellTemplateSalesPerson"
					dataType="string" [allowHeaderFiltering]="true">
					<div *dxTemplate="let element of 'cellTemplateSalesPerson'">
						{{element.data.salesperson_name || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="GL Account No." dataField="gl_account_number
				" cellTemplate="cellTemplateGLAccountNo" dataType="string" [allowHeaderFiltering]="false">
					<div *dxTemplate="let element of 'cellTemplateGLAccountNo'">
						{{element.data.gl_account_number|| ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Settlement Period" dataField="settlementPeriod" alignment="center"
					cellTemplate="cellTemplateSettlementPeriod" dataType="string" [allowHeaderFiltering]="false">
					<dxo-header-filter [dataSource]="listOfSettlementPeriodHeaderFilter">
					</dxo-header-filter>
					<div *dxTemplate="let element of 'cellTemplateSettlementPeriod'">
						{{element.data.settlementPeriod || '' |filterFromList:listOfSettlementPeriodHeaderFilter:'value':
						'text'}}
					</div>
				</dxi-column>
				<dxi-column caption="Settlement Start Date" dataField="settlementPeriodStart" alignment="center"
					cellTemplate="cellTemplateSettlementStartDate" dataType="string" [allowHeaderFiltering]="false">
					<div *dxTemplate="let element of 'cellTemplateSettlementStartDate'">
						{{element.data.settlementPeriodStart || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Settlement End Date" dataField="settlementPeriodEnd" alignment="center"
					cellTemplate="cellTemplateSettlementEndDate" dataType="string" [allowHeaderFiltering]="false">
					<div *dxTemplate="let element of 'cellTemplateSettlementEndDate'">
						{{element.data.settlementPeriodEnd || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Settlement Amount" dataField="current_settlement_commission_total" alignment="right"
					dataType="string" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[calculateDisplayValue]="doCurrencyCalculateSettlementAmount">
					<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateSettlementAmount'">
						{{element.data.current_settlement_commission_total || 0 | customCurrencyPipe:customCurrencyOption}}
					</div> -->
				</dxi-column>
				<dxo-toolbar>
					<dxi-item location="before" toolbar="top">
						<dx-check-box [(value)]="selectAllItem" (onValueChanged)="doOnValueChangedSelectAll($event)"
							class="mrgL20">
						</dx-check-box>
						<span for="selectAllItem"> Select All</span>
					</dxi-item>
					<dxi-item location="after" toolbar="top">
						<dx-button (onClick)="columnChooserClick()">
							<i class="dx-icon dx-icon-columnChooser"></i>
							&nbsp;Column Chooser
						</dx-button>
					</dxi-item>
				</dxo-toolbar>
				<!-- <dxi-column caption="InvoiceCreatedInERP" dataField="apInvoiceCreatedInERP" dataType="string"
					[visible]="false" [allowHeaderFiltering]="false">
				</dxi-column> -->
			</dx-data-grid>
			<!-- <div class="row">
				<div class="cols12 mrgT20">
					<dx-button class="primaryBtn width100p txtCenter" text="Submit Close Selected Settlement" icon="save"
						type="normal" stylingMode="contained" [useSubmitBehavior]="true">
					</dx-button>
				</div>
			</div> -->
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="before" cssClass="mrgT10 width100p padL0">
		<div *dxTemplate>
			<div class="icon-container infoTxtIconIx">
				<span class="dx-icon dx-icon-info"></span>
				<span class="demo-info">Contract must have Sales Person and GL Account Number to Close Settlement.</span>
			</div>
		</div>
	</dxi-toolbar-item>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10 width100p padL0">
		<div *dxTemplate>
			<dx-button class="primaryBtn alignCenter width100p" id="button" text="Submit Close Selected Settlement"
				type="normal" stylingMode="contained"
				[disabled]="!selectedRows || (selectedRows && selectedRows.length <= 0)"
				(click)="doSubmitCloseSettlementClaim()">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>