<div id="royaltycontract" class="rightBar">
	<div class="rightBlock">
		<div class="tabbing cf" *ngIf="editRoyaltiesGuid">
			<ul>
				<li (click)="clickMainTab('settlement_period')" [ngClass]="{active: currentMainTab == 'settlement_period'}">
					<a>Settlement Report</a>
				</li>
				<li (click)="clickMainTab('royalties_details')" [ngClass]="{active: currentMainTab == 'royalties_details'}">
					<a>Royalty Details</a>
				</li>
			</ul>
		</div>

		<!--Royalties details content -->
		<div *ngIf="currentMainTab === 'royalties_details'">
			<div class="titleRow">
				<!-- <h1> {{editRoyaltiesGuid ? 'Update' : 'Add New'}} Royalty Agreement</h1> -->
			</div>
			<div class="contentRow cf">
				<div class="borderBox cf padB0">
					<!-- <div class="subTitleRow">
            <h2>Royalties Details</h2>
          </div> -->
					<div class="formControls editAgreeDetail">
						<div class="row ">
							<!-- <div class="cols12 ">
								<div class="feildCol radioGroup">
									<dx-radio-group [items]="percentOrDollarRadio"
										[(value)]="royaltiesContractModelUI.percentOrDollar" [disabled]="isDisablePermission"
										layout="horizontal" displayExpr="text" valueExpr="value">
									</dx-radio-group>
								</div>
							</div> -->
							<div class="cols9">
								<div class="feildCol">
									<dx-text-box mode="text" [(value)]="royaltiesContractModelUI.contractName" maxLength="500"
										[disabled]="isDisablePermission">
									</dx-text-box>
									<div class="inputField">
										<label for="contractName">Contract Name</label>
									</div>
								</div>
							</div>
							<div class="cols3 distributionChannel mrgT10" *ngIf="isEnableDistributionChannels">
								<app-distribution-channels-dx-drop-down-box
									[distributionChannelsModel]="distributionChannelsModel"></app-distribution-channels-dx-drop-down-box>
							</div>
							<!-- <div class="cols3">
								<div class="feildCol">
									<dx-text-box mode="text" [(value)]="royaltiesContractModelUI.royaltiesPercent"
										maxLength="500" [disabled]="isDisablePermission">
									</dx-text-box>
									<div class="inputField">
										<label for="royaltiesPercent">Default Royalty
											{{royaltiesContractModelUI.percentOrDollar === 'percentage' ? '%' : '$'}}</label>
									</div>
								</div>
							</div> -->

							<div class="cols8">
								<div class="dx-field feildCol" [ngClass]="{inputDisable: isDisablePermission}">
									<div class="dx-field-label">Vendor Name</div>
									<div class="dx-field-value" style="width: 100%;">
										<dx-drop-down-box [(value)]="vendorGridBoxValue" valueExpr="code"
											[displayExpr]="displayExprVendorName" placeholder="Select Vendor Name..."
											[(opened)]="isVendorDropDownBoxOpened" [showClearButton]="true"
											[dataSource]="vendorDataSource" (onOptionChanged)="onVendorSelectionChanged($event)"
											[disabled]="isDisablePermission">
											<div *dxTemplate="let data of 'content'">
												<dx-data-grid #vendorGrid [dataSource]="vendorDataSource" keyExpr="code"
													[columns]="vendorGridBoxColumns" [showBorders]="true"
													[(selectedRowKeys)]="vendorGridBoxValue" height="100%">
													<dxo-selection mode="single"></dxo-selection>
													<dxo-filter-row [visible]="true"></dxo-filter-row>
													<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
														[useNative]="false"></dxo-scrolling>
													<dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
												</dx-data-grid>
											</div>
										</dx-drop-down-box>
									</div>
								</div>
							</div>
							<div class="cols2">
								<div class="datePicker">
									<dx-date-box [(value)]="royaltiesContractModelUI.startDate" [disabled]="isDisablePermission"
										type="date" displayFormat="yyyy-MM-dd" (onValueChanged)="setSettlementStartDate()">
									</dx-date-box>
									<div class="inputField inputFieldFilterLbl">
										<label for="inputFieldFilterLbl">Start Date</label>
									</div>
								</div>
							</div>
							<div class="cols2">
								<div class="datePicker">
									<dx-date-box [(value)]="royaltiesContractModelUI.endDate" type="date"
										displayFormat="yyyy-MM-dd" (onValueChanged)="compareTwoDates()">
									</dx-date-box>
									<div class="inputField inputFieldFilterLbl">
										<label for="inputFieldFilterLbl">End Date</label>
									</div>
								</div>
							</div>

							<div *ngIf="royaltiesContractModelUI.settlementPeriod !== 'monthly'" class="cols3">
								<div class="dx-field mrgT20">
									<div class="dx-field-label">Contract Calendar</div>
									<div class="dx-field-value">
										<dx-select-box #contractCalendarSelectBoxRef [dataSource]="listOfContractCalendar"
											[(value)]="royaltiesContractModelUI.contractCalendar" displayExpr="text"
											valueExpr="value" (onValueChanged)="setSettlementStartDate()"
											customItemCreateEvent="blur" placeholder="Contract Calendar">
										</dx-select-box>
									</div>
								</div>
							</div>

							<div class="cols3">
								<div class="dx-field mrgT20">
									<div class="dx-field-label">Start Settlement Period On</div>
									<div class="dx-field-value">
										<dx-number-box [(value)]="royaltiesContractModelUI.startSettlementPeriodOn"
											[disabled]="isDisablePermission" [showClearButton]="true"
											(onValueChanged)="setSettlementStartDate()" maxLength="2" [min]="1" [max]="30">
										</dx-number-box>
									</div>
								</div>
							</div>

							<div class="cols6 ecaCols mrgB10 mrgT10">
								<div class="feildCol radioGroup">
									<label>Settlement Period:</label>
									<span id="{{isDisabledSettlementPeriods ? 'settlementPeriodId': ''}}">
										<dx-radio-group [items]="SettlementPeriodRadio"
											[(value)]="royaltiesContractModelUI.settlementPeriod"
											[disabled]="isDisablePermission || isDisabledSettlementPeriods"
											(onValueChanged)="calculateSettlementPeriod(royaltiesContractModelUI)"
											layout="horizontal" displayExpr="text" valueExpr="value">
										</dx-radio-group>
									</span>
									<dx-tooltip target="#settlementPeriodId" showEvent="dxhoverstart" hideEvent="dxhoverend"
										position="bottom">
										<div *dxTemplate="let data = data of 'content'">
											Settlement Period can't be changed because Amortize Payment feature is enabled.
										</div>
									</dx-tooltip>
								</div>
							</div>

							<div class="cols12 colsP0">
								<div class="cols5"
									*ngIf="royaltiesContractModelUI.settlementPeriodStart && royaltiesContractModelUI.settlementPeriodEnd">
									<div class="remarkBx fullNote ecaRemark mrgT10">
										<strong>Current Settlement
											Period :-</strong>
										<span *ngIf="royaltiesContractModelUI.startDate">
											{{ (royaltiesContractModelUI.settlementPeriodStart !== null &&
											royaltiesContractModelUI.settlementPeriodStart !== '' &&
											royaltiesContractModelUI.settlementPeriodStart !== undefined) ?
											(royaltiesContractModelUI.settlementPeriodStart | date: 'yyyy-MM-dd') : "" }}
											to
											{{ (royaltiesContractModelUI.settlementPeriodEnd !== null &&
											royaltiesContractModelUI.settlementPeriodEnd !== '' &&
											royaltiesContractModelUI.settlementPeriodEnd !== undefined) ?
											(royaltiesContractModelUI.settlementPeriodEnd | date: 'yyyy-MM-dd') : "" }}
										</span>
									</div>
								</div>
								<div
									[ngClass]="(royaltiesContractModelUI.settlementPeriodStart && royaltiesContractModelUI.settlementPeriodEnd) ? 'cols7' : 'cols12'">
									<div class="multiBtnsRow">
										<div class="btnsGroup">
											<dx-button [disabled]="isAddMode" *ngxPermissionsOnly="isCurrentTabPermission"
												class="secondaryBtn" [ngClass]="{'grayButton': isAddMode}" text="Close Current
												Settlement
												Period" type="normal" stylingMode="contained"
												(onClick)="closeCurrentSettlementPeriod(royaltiesContractModelUI)"
												[useSubmitBehavior]="false">
											</dx-button>
										</div>
									</div>
								</div>

								<div class="cols12 colsP0 ucsCols mrgT0" *ngIf="!isAddMode">
									<div class="cols12">
										<hr>
									</div>
									<div class="cols3" *ngxPermissionsOnly="isCurrentTabPermission">
										<dx-button [disabled]="isAddMode" class="secondaryBtn"
											text="Update Current Settlement Period" type="normal" stylingMode="contained"
											(onClick)="UpdateSettlementPeriodClick()" [useSubmitBehavior]="false">
										</dx-button>
									</div>
									<div class="cols9 royaltyDetailsFooterBlock1" *ngxPermissionsOnly="isCurrentTabPermission">
										<div class="ucsData">
											<div class="cols4 mrgT10">
												<label for="currentSettlementInvoiceTotal">Invoice
													Total :
													<strong>
														{{royaltiesContractModelUI.current_settlement_invoice_total || 0 |
														customCurrencyPipe }}
													</strong>
												</label>
											</div>
											<div class="cols4 mrgT10">
												<label for="currentSettlementCreditmemoTotal">
													Credit Memo Total :
													<strong>
														{{royaltiesContractModelUI.current_settlement_creditmemo_total || 0 |
														customCurrencyPipe }}
													</strong>
												</label>
											</div>
											<div class="cols4 mrgT10">
												<label for="currentSettlementCommissionTotal">Royalty Total :
													<strong>
														{{royaltiesContractModelUI.current_settlement_commission_total || 0 |
														customCurrencyPipe }}
													</strong>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>


						</div>
					</div>
				</div>

				<div class="borderBox cf">
					<!-- <div class="row">
            <div class="inputContols cf mrgB20">
              <div class="cols12 radioGroup">
                <div class="radio mrgT0" [ngClass]="{radioDisable: isDisablePermission}">
                  <input name="agreementby" type="radio" value="agreementByCustomers" id="agreementByCustomers"
                    (click)="clickAgreementTab('customers')" [(ngModel)]="royaltiesContractModelUI.agreementBy"
                    [disabled]="isDisablePermission" checked>
                  <label for="agreementByCustomers" class="radio-label">Agreement by Customers</label>
                </div>
              </div>
            </div>
          </div> -->
					<div class="tabbing cf">
						<ul>
							<!-- <li (click)="clickAgreementTab('customers')"
								[ngClass]="{active: currentAgreementTab == 'customers'}">
								<a>Customers</a>
							</li>
							<li (click)="clickAgreementTab('products')"
								[ngClass]="{active: currentAgreementTab == 'products'}">
								<a>Products/Groups</a>
							</li>-->
							<li (click)="clickAgreementTab('exclusions')"
								[ngClass]="{active: currentAgreementTab == 'exclusions'}">
								<a>Customers/Items</a>
							</li>
							<li (click)="clickAgreementTab('rules')" [ngClass]="{active: currentAgreementTab == 'rules'}">
								<a>Rules</a>
							</li>
						</ul>
					</div>

					<!--#region Customer content -->

					<div *ngxPermissionsOnly="isCurrentTabPermission">

						<div class="row suctSearch" *ngIf="currentAgreementTab == 'customers'">

							<div class="inputContolscf gridSearch cf cols12 cols4Add">
								<div class="searchBoxIn">
									<div class="feildCol serchBox">

										<input name="" type="text" [(ngModel)]="customerTabModel.name"
											style="text-transform: capitalize;" (click)="searchSlideDown('tblSearchCustomerTab')"
											(keyup)="doSearchSelectCustomerTabOnChange($event)">
										<button>
											<span class="iconSearch sprite"></span>
										</button>
										<div class="inputField">
											<label for="Select Customers" style="text-transform: capitalize;">Select
												Customers</label>
										</div>
									</div>

									<div class="cf">
										<div class="searchResultSection" id="tblSearchCustomerTab">
											<div class="cf">
												<div class="searchResultBox">
													<div class="serachTable tableOut">
														<table class="responsiveTable fixedHeader">

															<thead>
																<tr>
																	<th class="txtLeft" *ngIf="!isQBErpUser">Code</th>
																	<th>Name</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="txtLeft" [attr.colspan]="isQBErpUser ? 1 : 2"
																		style="text-transform: capitalize;background-color: ghostwhite;">
																		<div class="form-group">
																			<input type="checkbox" id="isCustomerSearchTabAllSelected"
																				[(ngModel)]="isCustomerSearchTabAllSelected"
																				(change)="isCustomerSearchTabAllSelectedChange()">
																			<label for="isCustomerSearchTabAllSelected"
																				style="font-weight: bold;">All
																				Customer</label>
																		</div>
																	</td>
																</tr>
																<tr *ngFor="let ct of selectCustomerSearchTabData ;let i = index">
																	<td class="txtLeft" *ngIf="isQBErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				(change)="selectCustomerSearchTabDataListItem($event,ct)"
																				id="{{ct.code || ct.itemcode}}">
																			<label for="{{ct.code || ct.itemcode}}">{{ct.name ||
																				ct.itemname}}</label>
																		</div>
																	</td>
																	<td class="txtLeft" *ngIf="!isQBErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				(change)="selectCustomerSearchTabDataListItem($event,ct)"
																				id="{{ct.code || ct.itemcode}}">
																			<label for="{{ct.code || ct.itemcode}}">{{ct.code ||
																				ct.itemcode}}</label>
																		</div>
																	</td>
																	<td *ngIf="!isQBErpUser">{{ct.name || ct.itemname}}</td>
																</tr>

															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="button center" (click)="addCustomerAgreementTabDetails()"
									style="text-transform: capitalize;">Add</a>
							</div>

						</div>
					</div>
					<div class="tableOut tableScroll mrgT10 " *ngIf="currentAgreementTab == 'customers'">
						<table class="tableData">
							<tr>
								<th class="center width80">No.</th>
								<th *ngIf="!isAllCustomerSelectedView && !isQBErpUser">
									Code</th>
								<th>Name</th>
								<th class="center width100" *ngxPermissionsOnly="isCurrentTabPermission">
									Action</th>
							</tr>

							<tr *ngFor="let tl of customerTabList;let i = index">
								<td class="center">{{i+1}}</td>
								<td *ngIf="!isAllCustomerSelectedView &&  !isQBErpUser ">
									{{tl.code}}</td>
								<td>{{tl.name}}</td>
								<td class="center actBtn" *ngxPermissionsOnly="isCurrentTabPermission">
									<a (click)="removeCustomerAgreementTabDetails(i);$event.stopPropagation();">
										<span class="icon-deletedxtable"></span>
									</a>
								</td>
							</tr>
						</table>
					</div>

					<!--endregion-->

					<!--#region Product and group content -->

					<div *ngxPermissionsOnly="isCurrentTabPermission">
						<div class="row">
							<div class="cols12 radioGroup " *ngIf="currentAgreementTab == 'products'">
								<div class="feildCol mrgT0 feildColAuto radio">
									<input name="productGroupRadioButton" type="radio" value="addproducts" id="addProducts"
										(click)="clickProductGroupRadioButton('addproduct')" [(ngModel)]="productGroupRadioButton"
										checked>
									<label for="addProducts" class="radio-label">Add Products</label>
									<input name="productGroupRadioButton" type="radio" value="addgroups" id="addGroups"
										(click)="clickProductGroupRadioButton('addgroups')" [(ngModel)]="productGroupRadioButton">
									<label for="addGroups" class="radio-label">Add Groups</label>
								</div>
							</div>
						</div>
						<div class="row proSearch" *ngIf="currentAgreementTab == 'products'">

							<div class="inputContolscf gridSearch cf cols12 cols4Add">
								<div class="searchBoxIn">
									<div class="feildCol serchBox">

										<input name="" type="text" [(ngModel)]="productAndGroupTabModel.itemname"
											style="text-transform: capitalize;" (click)="searchSlideDown('tblSearchProductTab')"
											(keyup)="doSearchSelectProductTabOnChange($event)">
										<button>
											<span class="iconSearch sprite"></span>
										</button>
										<div class="inputField">
											<label for="Select products" style="text-transform: capitalize;">Select
												{{productGroupRadioButton === 'addproducts' ? 'Products' : 'Groups'}}</label>
										</div>
									</div>

									<div class="cf">
										<div class="searchResultSection" id="tblSearchProductTab">
											<div class="cf">
												<div class="searchResultBox">
													<div class="serachTable tableOut">
														<table class="responsiveTable fixedHeader">

															<thead>
																<tr>
																	<th class="txtLeft" *ngIf="isQBOnlineErpUser">SKU</th>
																	<th [ngClass]="{'txtLeft': !isQBOnlineErpUser}">Item</th>
																	<th> Description</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="txtLeft" [attr.colspan]="isQBOnlineErpUser ? 3 : 2"
																		style="text-transform: capitalize;background-color: ghostwhite;">
																		<div class="form-group">
																			<input type="checkbox" id="isProductSearchTabAllSelected"
																				(change)="isProductSearchTabAllSelectedChange($event)"
																				[(ngModel)]="isProductSearchTabAllSelected">
																			<label for="isProductSearchTabAllSelected"
																				style="font-weight: bold;">All
																				{{productGroupRadioButton === 'addproducts' ? 'Products' :
																				'Groups'}}</label>
																		</div>
																	</td>
																</tr>
																<tr *ngFor="let ct of selectProductSearchTabData ;let i = index">

																	<td class="txtLeft" *ngIf="isQBOnlineErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				(change)="selectProductSearchTabDataListItem($event,ct)"
																				id="{{ct.erp_primary_key}}">
																			<label for="{{ct.erp_primary_key}}">{{ct.itemsku}}</label>
																		</div>
																	</td>
																	<td class="txtLeft" *ngIf="!isQBOnlineErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				(change)="selectProductSearchTabDataListItem($event,ct)"
																				id="{{ct.erp_primary_key}}">
																			<label for="{{ct.erp_primary_key}}">{{ct.itemname}}</label>
																		</div>
																	</td>
																	<td *ngIf="isQBOnlineErpUser">{{ct.itemname}}</td>
																	<td>{{ct.description}}</td>
																</tr>

															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="button center" (click)="addProductAgreementTabDetails()"
									style="text-transform: capitalize;">Add</a>
							</div>

						</div>
					</div>
					<div class="tableOut tableScroll mrgT10 " *ngIf="currentAgreementTab == 'products'">
						<table class="tableData MnGroupingTable">
							<tr>
								<th class="center width50">No.</th>
								<th class="width150" *ngIf="isQBOnlineErpUser">SKU</th>
								<th class="width200">Item</th>
								<th> Description</th>
								<th class="width100">Type</th>
								<th class="center width100" *ngxPermissionsOnly="isCurrentTabPermission">
									Action</th>
							</tr>

							<tr *ngFor="let tl of productOrGroupTabList;let i = index">
								<td class="width50">{{i+1}}</td>
								<td class="width150" *ngIf="isQBOnlineErpUser">
									{{ isAllProductSelectedView ? tl.itemname : tl.itemsku}}</td>
								<td class="width200">{{isQBOnlineErpUser && isAllProductSelectedView ? '' : tl.itemname}}</td>
								<td> {{tl.description}}</td>
								<td class="width100 capitalizeText">{{tl.itemOrGroup}}</td>
								<td class="center actBtn" *ngxPermissionsOnly="isCurrentTabPermission">
									<a id="removeProductAgreementTabDetails_{{i+1}}"
										(click)="removeProductAgreementTabDetails(i);$event.stopPropagation();">
										<span class="icon-deletedxtable"></span>
									</a>
									<dx-tooltip target="#removeProductAgreementTabDetails_{{i+ 1}}" showEvent="dxhoverstart"
										hideEvent="dxhoverend" position="bottom">
										<div *dxTemplate="let data = data of 'content'">
											Delete
										</div>
									</dx-tooltip>
								</td>
							</tr>

						</table>
					</div>

					<!--endregion-->

					<!--#region Exclusions content -->

					<!-- <div *ngxPermissionsOnly="isCurrentTabPermission">
						<div class="row" *ngIf="currentAgreementTab == 'exclusions'">
							<div class="cols12 radioGroup ">
								<div class="feildCol mrgT0 feildColAuto radio">
									<input name="exclusionsgroupradiobutton" type="radio" value="addproductexclusion"
										id="addProductExclusion" (click)="clickExclusionsGroupRadioButton('addproductexclusion')"
										[(ngModel)]="exclusionsGroupRadioButton" checked>
									<label for="addProductExclusion" class="radio-label">Exclusion by Products</label>
									<input name="exclusionsgroupradiobutton" type="radio" value="addgroupexclusion"
										id="addGroupExclusion" (click)="clickExclusionsGroupRadioButton('addgroupexclusion')"
										[(ngModel)]="exclusionsGroupRadioButton">
									<label for="addGroupExclusion" class="radio-label">Exclusion by Groups</label>
								</div>
							</div>
						</div>
						<div class="row proSearch" *ngIf="currentAgreementTab == 'exclusions'">

							<div class="inputContolscf gridSearch cf cols4 ">
								<div class="searchBoxIn">
									<div class="feildCol serchBox">

										<input name="" type="text" [(ngModel)]="exclusionTabModel.clientname"
											(click)="selectCustomerSearchTabData.length > 0 ? searchSlideDown('tblSearchCustomerExclusionTab') : ''"
											style="text-transform: capitalize;"
											(keyup)="doSearchSelectCustomerTabOnChange($event)">
										<button>
											<span class="iconSearch sprite"></span>
										</button>
										<div class="inputField">
											<label for="Select Customers" style="text-transform: capitalize;">Select
												Customers</label>
										</div>
									</div>

									<div class="cf">
										<div class="searchResultSection" id="tblSearchCustomerTab">
											<div class="cf">
												<div class="searchResultBox">
													<div class="serachTable tableOut">
														<table class="responsiveTable fixedHeader">

															<thead>
																<tr>
																	<th class="txtLeft">Code</th>
																	<th>Name</th>
																</tr>
															</thead>
															<tbody>
																<tr *ngFor="let ct of selectCustomerSearchTabData ;let i = index"
																	(click)="exclusionsCustomerSearchListClick(ct,'tblSearchCustomerTab')">
																	<td class="txtLeft">
																		<label
																			for="{{ct.code || ct.itemcode}}">{{ct.code || ct.itemcode}}</label>
																	</td>
																	<td>{{ct.name || ct.itemname}}</td>
																</tr>

															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="inputContolscf gridSearch cf cols4 ">
								<div class="searchBoxIn">
									<div class="feildCol serchBox">

										<input name="" type="text" [(ngModel)]="exclusionTabModel.productname"
											style="text-transform: capitalize;" (click)="searchSlideDown('tblSearchProductTab')"
											(keyup)="doSearchSelectProductTabOnChange($event)">
										<button>
											<span class="iconSearch sprite"></span>
										</button>
										<div class="inputField">
											<label for="Select products" style="text-transform: capitalize;">Select
												{{exclusionsGroupRadioButton === 'addproductexclusion' ? 'Products' : 'Groups'}}</label>
										</div>
									</div>

									<div class="cf">
										<div class="searchResultSection" id="tblSearchProductTab">
											<div class="cf">
												<div class="searchResultBox">
													<div class="serachTable tableOut">
														<table class="responsiveTable fixedHeader">

															<thead>
																<tr>
																	<th class="txtLeft" *ngIf="isQBOnlineErpUser">SKU</th>
																	<th [ngClass]="{'txtLeft': !isQBOnlineErpUser}">Item</th>
																	<th> Description</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="txtLeft" [attr.colspan]="isQBOnlineErpUser ? 3 : 2"
																		style="text-transform: capitalize;background-color: ghostwhite;">
																		<div class="form-group">
																			<input type="checkbox" id="isProductSearchTabAllSelected"
																				(change)="isExclusionsProductSearchTabAllSelectedChange($event)"
																				[(ngModel)]="isProductSearchTabAllSelected">
																			<label for="isProductSearchTabAllSelected"
																				style="font-weight: bold;">All
																				{{exclusionsGroupRadioButton === 'addproductexclusion' ? 'Products' : 'Groups'}}</label>
																		</div>
																	</td>
																</tr>
																<tr *ngFor="let ct of selectProductSearchTabData ;let i = index">
																	<td class="txtLeft" *ngIf="isQBOnlineErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				id="{{ct.erp_primary_key}}">
																			<label for="{{ct.erp_primary_key}}">{{ct.itemsku}}</label>
																		</div>
																	</td>
																	<td class="txtLeft" *ngIf="!isQBOnlineErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				id="{{ct.erp_primary_key}}">
																			<label for="{{ct.erp_primary_key}}">{{ct.itemname}}</label>
																		</div>
																	</td>
																	<td *ngIf="isQBOnlineErpUser">{{ct.itemname}}</td>
																	<td>{{ct.description}}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>

							<div class="cols4 cols4Add">
								<div class="feildCol">
									<input name="" type="text" id="rate" [(ngModel)]="exclusionTabModel.rate" decimalNumbersOnly>
									<div class="inputField">
										<label for="rate">Rate $</label>
									</div>
								</div>
								<a class="button center" (click)="addExclusionAgreementTabDetails()"
									style="text-transform: capitalize;">Add</a>
							</div>
						</div>
					</div>
					<div class="tableOut tableScroll mrgT10 " *ngIf="currentAgreementTab == 'exclusions'">
						<table class="tableData MnGroupingTable">
							<tr>
								<th class="center width50">No.</th>
								<th class="width200">Customer Name</th>
								<th class="width100" *ngIf="isQBOnlineErpUser">SKU</th>
								<th>Item</th>
								<th class="width100">Type</th>
								<th class="width100">Rate ($)</th>
								<th class="center width100" *ngxPermissionsOnly="isCurrentTabPermission">
									Action</th>
							</tr>

							<tr *ngFor="let el of exclusionTabList;let i = index">
								<td class="width50">{{i+1}}</td>
								<td class="width200">{{el.clientname}}</td>
								<td class="width150" *ngIf="isQBOnlineErpUser">
									{{el.itemsku}}</td>
								<td>{{el.itemname}} {{el.description && isQBErpUser ? '-' + el.description : ''}}</td>
								<td class="width100 capitalizeText">{{el.itemOrGroup}}</td>
								<td class="center width100">
									<div class="feildCol" [ngClass]="{inputDisable: isDisablePermission}"><input type="text"
											[disabled]="isDisablePermission" value="{{el.rate}}" #rateVar
											(focusout)="editExclusionsListItem(i,rateVar)" decimalNumbersOnly /></div>
								</td>
								<td class="center actBtn" *ngxPermissionsOnly="isCurrentTabPermission">
									<a id="removeExclusionAgreementDetails_{{i}}"
										(click)="removeExclusionAgreementTabDetails(i);$event.stopPropagation();">
										<span class="icon-deletedxtable"></span>
									</a>
								</td>
							</tr>

						</table>
					</div> -->
					<!--endregion-->

					<!--#region Exclusions content -->
					<app-royalties-exclusions-tab (saveRoyaltiesExclusionEvent)="saveRoyaltiesExclusionData($event)"
						(addToDatagridUploadedMatchedItemsExclusionEvent)="doAddUploadedMatchedItemsToDatagridExclusionEvent($event)"
						[exclusionTabList]="exclusionTabList" *ngIf="currentAgreementTab == 'exclusions'">
					</app-royalties-exclusions-tab>
					<!--endregion-->

					<!--#region Rules content -->
					<app-royalties-rules-tab (saveRoyaltiesRulesEvent)="saveRoyaltiesRuleData($event)"
						[ruleTabList]="ruleTabList" [exclusionTabList]="exclusionTabList"
						[isCurrentTabPermission]="isCurrentTabPermission" *ngIf="currentAgreementTab ===
						'rules'">
					</app-royalties-rules-tab>
					<!--endregion-->

					<div class="alignRight" *ngxPermissionsOnly="isCurrentTabPermission">
						<dx-button class="primaryBtn" text="Submit" icon="save" type="normal" stylingMode="contained"
							(onClick)="saveRoyaltiesContract()" [useSubmitBehavior]="false">
						</dx-button>
					</div>
				</div>
			</div>
		</div>
		<!--End Royalties details content -->

		<!--Close settlement list-->
		<div *ngIf="currentMainTab === 'settlement_period'">
			<app-royalties-close-settlement-list-tab [royaltiesContractModelUI]="royaltiesContractModelUI"
				[royaltiesContractModelRequest]="royaltiesContractModelRequest" [salesPplList]="salesPplList"
				[isAllProductSelectedView]="isAllProductSelectedView"
				[isAllCustomerSelectedView]="isAllCustomerSelectedView" [customerTabList]="customerTabList"
				[productOrGroupTabList]="productOrGroupTabList" [exclusionTabList]="exclusionTabList"
				[ruleTabList]="ruleTabList" [isCurrentTabPermission]="isCurrentTabPermission">
			</app-royalties-close-settlement-list-tab>
		</div>
		<!--End close settlement list-->

	</div>
</div>


<app-view-previous-settlement-period-dialog *ngIf="isViewPreviousSettlementPeriodPopup"
	[viewPreviousSettlementPeriodProps]="viewPreviousSettlementPeriodProps"
	(closeViewPreviousSettlementPeriodPopup)="doCloseViewPreviousSettlementPeriodPopup($event)"></app-view-previous-settlement-period-dialog>