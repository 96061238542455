import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as _ from 'underscore';
import saveAs from 'file-saver';
import { Subscription, Observable } from 'rxjs';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from "devextreme/excel_exporter";
import { DecimalPipe } from "@angular/common";
import { ChargeBackItemTabModel } from "@app/models/chargeback-contract.model";
import { ChargebackContractModelUI } from '@app/models/chargeback-contract.model';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
import { BillBackChargebackContractModelRequest, BillBackChargebackContractModelUI } from '@app/models/billback-chargeback-contract.model';
import { BillBackItemTabModel, CustomerMasterDetailsModel } from '@app/models/billback-contract.model';
import { BcUploadItemsDialogComponent } from '../bc-upload-items-dialog/bc-upload-items-dialog.component';
import { BcUploadItemsAndIgnoredItemsDialogComponent } from '../bc-upload-items-and-ignored-items-dialog/bc-upload-items-and-ignored-items-dialog.component';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
declare const ExcelJS: ExcelJS;


@Component({
	selector: 'app-bc-items-tab',
	templateUrl: './bc-items-tab.component.html',
	styleUrls: ['./bc-items-tab.component.css'],
	providers: [DecimalPipe]
})
export class BcItemsTabComponent implements OnInit {
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	@ViewChild("itemTabDataGridRef", {}) itemTabDataGridRef: DxDataGridComponent;
	@ViewChild("customersDetailssDataGridRef", {}) customersDetailssDataGridRef: DxDataGridComponent;
	@Output() saveBillbackItemTabDataEvent = new EventEmitter<string>();
	@Input() itemTabDataList: any = [];
	@Input() itemTabDataSource: any;
	@Input() billbackContractModelUI = new BillBackChargebackContractModelUI();
	@ViewChild(BcUploadItemsAndIgnoredItemsDialogComponent) childBcUploadItemsAndIgnoredItemsDialogComponent: BcUploadItemsAndIgnoredItemsDialogComponent;
	public getUomLstSbsn: Subscription;
	public getItemDetailsSbsn: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;

	public editChargebackGuid: string;
	public productSearchItemType: string;
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;

	public chkAllCustomer: boolean = false;
	public chkAllItem: boolean = false;
	public lengthSelectedCustomers: any;
	public lengthSelectedItems: any;
	public arrSelectedCustomerList: any = [];
	public arrSelectedItemList: any = [];
	public itemDataSource: any;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public itemGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public itemGridBoxColumns: any;
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public selectedCustomerData: any = [];
	public selectedProductData: any = [];
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;

	public dialogConfig: MatDialogConfig = new MatDialogConfig();
	public csvResponse: any;
	public listOfCsvRowForItmGrpTab: any[] = [];
	public listOfCustomerData: any[] = [];
	public isUploadExcelCsvFileItmGrpTab: boolean = false;
	public noOfUnmatchedItem: number;
	public noOfUnmatchedItemUom: number;
	public noOfmatchedItemPrice: number;
	public noOfTotalItem: number;
	public filterText: string = '';
	public selecteditemTabDataList: any[] = [];
	public filterValueChangeSbsn: Observable<any>;
	public isVisibleDeleteForItem: boolean = false;
	public isVisibleDeleteForCustomer: boolean = false;
	public globalCurrencySymbol: string;
	public decimalPointForCurrency: number;
	// Model object
	public guid = new Guid();

	constructor(
		private _PermissionsService: NgxPermissionsService,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _BillbackService: BillbackService,
		private _BillbackContractService: BillbackContractService,
		private _GlobalStateService: GlobalStateService,
		public _MatDialog?: MatDialog
	) { }

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}

		// product_search logic type (item/price)
		this.productSearchItemType = this._BillbackService.getProductSearchType();

		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	ngOnDestroy() {
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
	}

	preInit() {
		if (this.itemTabDataList && this.itemTabDataList.length > 0) {
			this.itemTabDataSource = this.itemTabDataList;
		}
		setTimeout(() => {
			this.filterValueChangeSbsn = this.itemTabDataGridRef.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);

		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		this.customerGridBoxColumns = this._BillbackService.getCustomerGridBoxColumn();
		this.itemGridBoxColumns = this._BillbackService.getItemGridBoxColumn();
		this.editChargebackGuid = this._BillbackContractService.getEditBillbackGuid();
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadCustomerDataList();
		this.loadItemDataList();
	}
	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this.listOfUoms = response.data;
					this._GlobalStateService.notifyDataChangedDuplicate('GET_CHILD_UOM_LIST_EVENT', { listOfUoms: this.listOfUoms });
				}
			}
		}, error => {
			this._LoaderService.hide();

		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this.listOfUomGroups = response.data;
				}
			}
		}, error => {
			this._LoaderService.hide();

		});
	}
	//#region for Customer select box
	loadCustomerDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=clients";
		// formdata = formdata + "&is_dropdown=true";	
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "name",
			loadUrl: formdata
		});
	}
	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.customerGridBoxValue = [];
			this.customerDataGrid.instance.refresh();
			this.chkAllCustomer = false;
		}
	}
	checkAllCustomer() {
		if (this.chkAllCustomer) {
			this.lengthSelectedCustomers = 'All Customers Selected';
			this.arrSelectedCustomerList = [];
			this.customerDataGrid.instance.refresh();
		} else {
			this.lengthSelectedCustomers = '';
		}
	}
	onCustomerSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedCustomers = e.selectedRowsData.length + ' Customers Selected';
			this.customerGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].name);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomer) {
			this.lengthSelectedCustomers = '';
		}
	}

	//#endregion

	//#region for Item|Product select box
	loadItemDataList() {
		// const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=items";
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.itemDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "itemname",
			loadUrl: formdata
		});
	}
	onItemValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedItemList = [];
			this.itemGridBoxValue = [];
			this.itemDataGrid.instance.refresh();
			this.chkAllItem = false;
		}
	}
	checkAllItem() {
		// if (this.chkAllItem) {
		// 	this.lengthSelectedItems = 'All Items Selected';
		// 	this.arrSelectedItemList = [];
		// 	this.itemDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedItems = '';
		// }
	}
	onItemSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllItem = false;
			this.lengthSelectedItems = e.selectedRowsData.length + ' Items Selected';
			this.itemGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].erp_primary_key);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllItem) {
			this.lengthSelectedItems = '';
		}
	}

	//#endregion

	addItemTabDetails() {
		this.selectedCustomerData = [];
		this.selectedProductData = [];
		this.selectedCustomerData = this.customerDataGrid.instance ? this.customerDataGrid.instance.getSelectedRowsData() : [];
		this.selectedProductData = this.itemDataGrid.instance ? this.itemDataGrid.instance.getSelectedRowsData() : [];
		if (this.chkAllCustomer) {
			this.selectedCustomerData.push({ guid: '', code: 'all customer', name: 'All Customer', allcustomers: true })
		}
		this.doAddItemToListTab();
	}

	public doAddItemToListTab() {
		if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
			this.selectedProductData.forEach(element => {
				let itemIndex = this.checkItemDuplicateRecords(element);
				let customers: CustomerMasterDetailsModel[] = [];

				this.selectedCustomerData.forEach(customer => {
					const customerMasterDetailsModel = new CustomerMasterDetailsModel();
					customerMasterDetailsModel.guid = this.guid.newGuid();
					customerMasterDetailsModel.customer_guid = customer.guid;
					customerMasterDetailsModel.customer_code = customer.code;
					customerMasterDetailsModel.customer_name = customer.name;
					customerMasterDetailsModel.allcustomers = customer.allcustomers || false;
					// customerMasterDetailsModel.bought = 0;
					customerMasterDetailsModel.billbackAmount = 0;
					customerMasterDetailsModel.sold = 0;
					customerMasterDetailsModel.billed = 0;
					customerMasterDetailsModel.available = 0;
					if (itemIndex === -1) {
						customers.push(customerMasterDetailsModel);
					} else {
						const custIndex = this.itemTabDataList[itemIndex].customers.findIndex(cust => cust.customer_guid === customer.guid);
						if (custIndex === -1) {
							this.itemTabDataList[itemIndex].customers.unshift(customerMasterDetailsModel);
						}
					}
				});

				if (itemIndex === -1) {
					let listOfSpecificUoms = [];
					listOfSpecificUoms = this._BillbackService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element);
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					const billbackItemTabModel = new BillBackItemTabModel();
					billbackItemTabModel.guid = this.guid.newGuid();
					billbackItemTabModel.item_guid = element.guid;
					billbackItemTabModel.itemsku = element.itemsku;
					billbackItemTabModel.erp_primary_key = element.erp_primary_key;
					billbackItemTabModel.itemname = element.itemname;
					billbackItemTabModel.description = element.description;
					billbackItemTabModel.uomList = listOfSpecificUoms || '';
					billbackItemTabModel.selectedUom = defaultSelectedUom;
					billbackItemTabModel.listPrice = +defaultListPrice.toFixed(2);
					billbackItemTabModel.customers = customers;
					billbackItemTabModel.bought = element.bought || 0;
					billbackItemTabModel.sold = element.sold || 0;
					billbackItemTabModel.billed = element.billed || 0;
					billbackItemTabModel.available = element.available || 0;
					//check duplicate validation for item tab list
					if (!this.checkItemListDuplicateRecords(billbackItemTabModel)) {
						return false;
					}
					this.itemTabDataList.unshift(JSON.parse(JSON.stringify(billbackItemTabModel)));
				}
			})
		}
		this.itemTabDataSource = this.itemTabDataList;
		this.clearItemAgreementTabDetails();
		// Latest item save on edit billback contract
		if (this._BillbackContractService.getEditBillbackGuid()) {
			this.saveBillbackItemTabDataEvent.emit('save');
		}
	}

	public checkItemDuplicateRecords(item: any) {
		return this.itemTabDataList.findIndex(eleItem => eleItem.erp_primary_key === item.erp_primary_key);
	}

	public checkItemListDuplicateRecords(item: any) {
		if (this.itemTabDataList && this.itemTabDataList.length > 0) {
			this.itemTabDataList.forEach(eleItem => {
				if (eleItem.itemname === item.itemname) {
					if (eleItem.customers && eleItem.customers.length > 0) {
						const indexCustomer = eleItem.customers.findIndex(i => i.clientname === item.customer_name);
						if (indexCustomer !== -1) {
							return false;
						}
					}
				}
			});

		}
		return true;
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					visible: !this.isCurrentTabPermission,
					icon: 'xlsxfile',
					text: "Import Excel/Csv",
					onClick: (event: any) => {
						this.doBulkUploadItmGrpTab();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	template: 'templateFilterText',
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		disabled: this.isDisabledClearBtn,
			// 		text: "Clear",
			// 		onInitialized: (args: any) => {
			// 			this.instanceClearBtn = args.component;
			// 		},
			// 		onClick: () => {
			// 			this.itemTabDataGridRef.instance.clearFilter();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'filter',
			// 		text: "Filter",
			// 		onClick: () => {
			// 			this.itemTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.itemTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let workSheet;
		let fileName = '';
		fileName = 'Bilback_Customers_Items_Groups.xlsx';
		workSheet = workbook.addWorksheet('Customers-Items-Groups');
		const groupIndexesWithSingleItem = [];
		const listOfCustomerMasterDetailsRows = [];

		exportDataGrid({
			worksheet: workSheet,
			component: this.itemTabDataGridRef.instance,
			autoFilterEnabled: true,
			// topLeftCell: { row: 5, column: 1 },
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'sold') {
						excelCell.value = gridCell.value;
						listOfCustomerMasterDetailsRows.push({
							rowIndex: excelCell.fullAddress.row + 1,
							data: gridCell.data
						});
						// listOfInvoiceMasterDetailsRows.push({
						// 	rowIndex: excelCell.fullAddress.row + 1,
						// 	data: gridCell.data.items
						// });
					}
				}
			}
		}).then((cellRange) => {

			groupIndexesWithSingleItem.sort((a, b) => b - a).forEach((rowIndex) => {
				workSheet.getRow(rowIndex + 1).outlineLevel = undefined;
				workSheet.spliceRows(rowIndex, 1);
			});

			// Logic for Invoice Details rows
			const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = workSheet.insertRow(currentIndex, [], "n");
				for (let j = workSheet.rowCount + 1; j > currentIndex; j--) {
					workSheet.getRow(j).outlineLevel = workSheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			let itemColumnsField = [
				"customer_name",
				"billbackAmount",
				"sold",
				"billed",
				"available"
			];
			let itemColumns = [
				"Customer Name",
				"Billback Amount(" + this.globalCurrencySymbol + ")",
				"Sold",
				"Billed",
				"Available"
			];
			for (let i = 0; i < listOfCustomerMasterDetailsRows.length; i++) {

				let isQBOnlineErpUser = this.isQBOnlineErpUser;
				const columnIndex = cellRange.from.column;
				let row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
				// row.height = 24;
				// workSheet.mergeCells(row.number, columnIndex, row.number, 7);
				if (listOfCustomerMasterDetailsRows[i].data.customers && listOfCustomerMasterDetailsRows[i].data.customers.length > 0) {
					row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
					itemColumns.forEach((columnName, currentColumnIndex) => {
						row.keepColumnWidths = true;
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							// fill: {
							// 	type: "pattern",
							// 	pattern: "solid",
							// 	fgColor: { argb: "BEDFE6" }
							// },
							font: { bold: true },
							// border: {
							// 	bottom: borderStyle,
							// 	left: borderStyle,
							// 	right: borderStyle,
							// 	top: borderStyle
							// }
						});
					});

					let subTotalLineTotal = 0;
					listOfCustomerMasterDetailsRows[i].data.customers.forEach((itemDetails, itemIndex) => {
						const itemColumnIndexDetail = cellRange.from.column;
						row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						itemColumnsField.forEach((columnName, currentColumnIndex) => {
							const cellObject = {
								value: itemDetails[columnName],
								// fill: {
								// 	type: "pattern",
								// 	pattern: "solid",
								// 	fgColor: { argb: "BEDFE6" }
								// },
								// border: {
								// 	bottom: borderStyle,
								// 	left: borderStyle,
								// 	right: borderStyle,
								// 	top: borderStyle
								// },
								alignment: { horizontal: 'left' }
							};

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
							row.keepColumnWidths = true;
						});
						// row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
					});

					row = insertRow(listOfCustomerMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;
					row.keepColumnWidths = true;
				}
				offset--;
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.itemTabDataGridRef.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}
	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
	public clearItemAgreementTabDetails() {
		this.arrSelectedCustomerList = [];
		this.customerGridBoxValue = [];
		this.chkAllCustomer = false;
		if (this.customerDataGrid) {
			this.customerDataGrid.instance.refresh();
			this.customerDataGrid.instance.clearFilter();
		}
		this.arrSelectedItemList = [];
		this.itemGridBoxValue = [];
		this.chkAllItem = false;
		if (this.itemDataGrid) {
			this.itemDataGrid.instance.refresh();
			this.itemDataGrid.instance.clearFilter();
		}
	}

	public doOnEditorPreparingItemTab(event) {
		if (event && event['row'] && event['row']['data']) {
			const itemObject = event.row.data;
			const listOfRows = this.itemTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.itemTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails: ChargeBackItemTabModel = listOfRows[rowIndex].data;
			if (event.parentType === "dataRow" && event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				event.editorOptions.visible = true;
				uomDataSource = itemObject.uomList;
				event.editorOptions.dataSource = uomDataSource;

				event.editorOptions.onValueChanged = (e: any) => {
					const targetUom = e.value;
					const sourceUom = e.previousValue;
					this.itemTabDataGridRef.instance.cellValue(rowIndex, 'selectedUom', targetUom);
					const uomData = itemDetails.uomList.filter(i => i.code === targetUom);
					let uomprice = 0;
					if (uomData && uomData.length > 0) {
						uomprice = uomData[0].price || 0;
					}
					this.itemTabDataGridRef.instance.cellValue(rowIndex, 'listPrice', +uomprice);
					const chargeBack = +(this.calculateUomPrice(itemDetails.chargebackAmount, itemDetails.uomgroupentry, sourceUom, targetUom));
					this.itemTabDataGridRef.instance.cellValue(rowIndex, 'chargebackAmount', chargeBack);
					itemDetails.chargebackAmount = chargeBack;

					if (itemDetails.chargebackAmount) {
						this.itemTabDataGridRef.instance.cellValue(rowIndex, 'final_amount', +((parseFloat(uomprice.toString()) - parseFloat(itemDetails.chargebackAmount))));
					} else {
						this.itemTabDataGridRef.instance.cellValue(rowIndex, 'final_amount', +(parseFloat(uomprice.toString())));
					}
				}
			}
		}
	}

	public allowDeletingItem(e) {
		return e.row && e.row.data && (e.row.data.billed === 0 || e.row.data.billed === '' || e.row.data.billed === undefined || e.row.data.billed === null) ? true : false;
	}
	public allowDeletingCustomer(e) {
		return e.row && e.row.data && (e.row.data.billed === 0 || e.row.data.billed === '' || e.row.data.billed === undefined || e.row.data.billed === null) ? true : false;
	}


	private calculateUomPrice(price, uomgroupentry, sourceUom, targetUom) {
		let clculatePrice = price;
		let targeteDiscountAmount, baseDiscoutAmount = 0;

		let objSourceAbsEntry, objTargetAbsEntry;
		let sourceAbsEntry, targetAbsEntry = '';

		if (clculatePrice && clculatePrice > 0) {
			objSourceAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === sourceUom.toString().toLowerCase());
			objTargetAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === targetUom.toString().toLowerCase());
			if (objSourceAbsEntry && objSourceAbsEntry.length > 0) {
				sourceAbsEntry = objSourceAbsEntry[0].absentry;
			}
			if (objTargetAbsEntry && objTargetAbsEntry.length > 0) {
				targetAbsEntry = objTargetAbsEntry[0].absentry;
			}
			const objUomGroup = this.listOfUomGroups.filter(item => item.absentry.toString().toLowerCase() === uomgroupentry.toString().toLowerCase());
			if (objUomGroup && objUomGroup.length > 0) {
				const uomGroup = objUomGroup[0];
				if (uomGroup && uomGroup.uomgroupdefinitioncollection && uomGroup.uomgroupdefinitioncollection.length > 0) {
					if (sourceUom !== uomGroup.baseuom) {
						const sourceUomIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase());
						let sourceUomItem: any;
						if (sourceUomIndex !== -1) {
							sourceUomItem = uomGroup.uomgroupdefinitioncollection[sourceUomIndex];
						}
						// const sourceUomItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase())[0];
						if (sourceUomItem) {
							const itemQty = (sourceUomItem.basequantity / sourceUomItem.alternatequantity) || 0;
							baseDiscoutAmount = clculatePrice / itemQty;
						} else {
							baseDiscoutAmount = clculatePrice;
						}
					} else {
						baseDiscoutAmount = clculatePrice;
					}

					if (targetUom !== uomGroup.baseuom) {
						// const targetItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase())[0];
						const targetItemIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase());
						let targetItem: any;
						if (targetItemIndex !== -1) {
							targetItem = uomGroup.uomgroupdefinitioncollection[targetItemIndex];
						}
						const itemQty = (targetItem.basequantity / targetItem.alternatequantity) || 0;
						targeteDiscountAmount = baseDiscoutAmount * itemQty;
					} else {
						targeteDiscountAmount = baseDiscoutAmount;
					}
					clculatePrice = targeteDiscountAmount;
				}
			}
		}
		return clculatePrice;
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	public doOnRowRemovedItemTab(event) {
		this.saveBillbackItemTabDataEvent.emit('save');
	}

	public doOnonRowPreparedItemTab(event) {
		if (event.rowType === "data" && event.data.tier_basis === 'notiers') {
			event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
			event.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
			const isExpanded = event.component.isRowExpanded(event.key);
			setTimeout(() => {
				if (isExpanded) {
					event.component.collapseRow(event.key);
				}
			}, 20);
		}
	}

	public doOnInitNewRowTiersItmTab(event, element) {
		event.data = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null
		};
		const itemObject = element.data;
		event.data.guid = this.guid.newGuid();
		if (itemObject.tiers && itemObject.tiers.length > 0) {
			// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ? parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1 : parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
			if (itemObject.tier_basis === 'volume' || itemObject.tier_basis === 'revenue') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = mintier;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = mintier;
				}
			}
			// event.data.maxtier = '-';
		} else {
			if (itemObject.tier_basis === 'volume') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = 1;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = 0.01;
				}
			}
		}
	}

	public doOnSavedItemTab() {
		this.saveBillbackItemTabDataEvent.emit('save');
	}

	public getCustomersByItemDetails(element) {
		return element.data.customers || [];
	}

	public doOnInitNewRowCustomersTab(element) {

	}

	public doOnRowInsertedCustomersTab(element) {

	}

	public doOnEditorPreparingCustomersTab(element) {

	}

	public doOnRowUpdatedCustomersTab(element) {
		this.saveBillbackItemTabDataEvent.emit('save');
	}
	public doOnRowRemovedCustomersTab(element) {
		this.saveBillbackItemTabDataEvent.emit('save');
	}
	public doOnSavedCustomersTab(element) {

	}
	// #region for Bulk Upload items to datagrid

	// Method used to click to Open upload dialog
	public doBulkUploadItmGrpTab() {
		const dialogData = {
			actionFor: 'showUploadFile',
			isItemExists: this.itemTabDataList.length > 0,
			listOfCsvRows: []
		};
		this.childBcUploadItemsAndIgnoredItemsDialogComponent.doOpenPopup(dialogData);
		// if (!this.billbackContractModelUI.selectSupplier) {
		// 	this._ToastrService.info('Supplier Name is required', 'Info!', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }
		// this.dialogConfig.data = {
		// 	actionFor: 'showUploadFile',
		// 	isItemExists: this.itemTabDataList.length > 0,
		// 	listOfCsvRows: []
		// };
		// this._MatDialog.open(BcUploadItemsDialogComponent, this.dialogConfig).afterClosed().subscribe(result => {
		// 	if (result) {
		// 		this.csvResponse = result;
		// 		if (result.listOfCsvRows && result.listOfCsvRows.length > 0) {
		// 			const listOfCsvRow = result.listOfCsvRows;
		// 			this.listOfCustomerData = result.listOfCustomerData || [];
		// 			if (result.chkAllCustomer) {
		// 				this.listOfCustomerData.unshift({ guid: '', code: 'all customer', name: 'All Customer', allcustomers: true })
		// 			}
		// 			listOfCsvRow.forEach(rowEle => {
		// 				rowEle.guid = this.guid.newGuid();
		// 				rowEle.description = '';
		// 				rowEle.isItemMatched = false;
		// 				rowEle.isItemUomMatched = 'unmatched';
		// 				rowEle.isItemModifiedUom = '';
		// 				rowEle.isItemModifiedPrice = false;
		// 				rowEle.isItemModifiedPriceLbl = null;
		// 			});
		// 			this.listOfCsvRowForItmGrpTab = listOfCsvRow;
		// 			this.doMatchExcelItemsWithOrignItems();
		// 			this.fetchedAllItemsItmGrpTab();
		// 		}
		// 	}
		// });
	}

	public viewUploadDialogData(result: any) {
		if (result) {
			this.csvResponse = result;
			if (result.listOfCsvRows && result.listOfCsvRows.length > 0) {
				const listOfCsvRow = result.listOfCsvRows;
				this.listOfCustomerData = result.listOfCustomerData || [];
				if (result.chkAllCustomer) {
					this.listOfCustomerData.unshift({ guid: '', code: 'all customer', name: 'All Customer', allcustomers: true })
				}
				listOfCsvRow.forEach(rowEle => {
					rowEle.guid = this.guid.newGuid();
					rowEle.description = '';
					rowEle.isItemMatched = false;
					rowEle.isItemUomMatched = 'unmatched';
					rowEle.isItemModifiedUom = '';
					rowEle.isItemModifiedPrice = false;
					rowEle.isItemModifiedPriceLbl = null;
				});
				this.listOfCsvRowForItmGrpTab = listOfCsvRow;
				this.doMatchExcelItemsWithOrignItems();
				this.fetchedAllItemsItmGrpTab();
			}
		}
	}

	private doMatchExcelItemsWithOrignItems() {
		this._GlobalStateService.unsubscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT', response => {
			const itemDetailsList = response.itemDetailsList;
			if (this.productSearchItemType === 'item') {
				this.doAddBulkItemsToItmGrpTabList();
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
			}
			if (this.productSearchItemType === 'price') {
				const matchedItemsList: any[] = [];
				this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
					if (csvRow.item) {
						const itemIndex = itemDetailsList.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
						if (itemIndex !== -1) {
							const itemEle = itemDetailsList[itemIndex];
							matchedItemsList.push(itemEle);
						}
					}
				});
				const listOfGuids = [];
				matchedItemsList.map(matchedItem => listOfGuids.push(matchedItem.guid));
				this.doAddBulkItemsToItmGrpTabList();
				if (listOfGuids.length > 0) {
					this.fetchedItemDetailsByPricelistnum(listOfGuids);
				}
			}
		});
	}

	// Method used to Add items from excel to Exclusion items list
	private doAddBulkItemsToItmGrpTabList() {
		this._GlobalStateService.unsubscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT');
		this._GlobalStateService.subscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', response => {
			const listOfItemDetails = response.itemDetailsList;

			const customersMasterData: any = [];
			this.listOfCustomerData.forEach(customerRow => {
				let customerMasterDetailsModel = new CustomerMasterDetailsModel();
				customerMasterDetailsModel.guid = this.guid.newGuid();
				customerMasterDetailsModel.customer_guid = customerRow.guid;
				customerMasterDetailsModel.customer_code = customerRow.code;
				customerMasterDetailsModel.customer_name = customerRow.name;
				customerMasterDetailsModel.allcustomers = customerRow.allcustomers;
				customerMasterDetailsModel.listPrice = '';
				customerMasterDetailsModel.billbackAmount = '';
				customerMasterDetailsModel.final_amount = '';
				customerMasterDetailsModel.bought = '';
				customerMasterDetailsModel.sold = '';
				customerMasterDetailsModel.billed = '';
				customerMasterDetailsModel.available = '';
				customersMasterData.push(customerMasterDetailsModel);
			});

			this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
				if (csvRow.item) {
					const itemDetailIndex = listOfItemDetails.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
					if (itemDetailIndex !== - 1) {
						const itemEle = listOfItemDetails[itemDetailIndex];
						csvRow.isItemMatched = true;
						csvRow.description = itemEle.description || '';

						const listOfSpecificUoms = this.setlistOfUomBySelProduct(itemEle);
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}

						if (csvRow.uom) {
							const specificUomIndex = listOfSpecificUoms.findIndex(uomEle => uomEle.code.toString().toLowerCase().trim() === csvRow.uom.toString().toLowerCase().trim());
							if (specificUomIndex !== -1) {
								csvRow.isItemUomMatched = 'matched';
								try {
									defaultSelectedUom = listOfSpecificUoms[specificUomIndex].code;
									defaultListPrice = listOfSpecificUoms[specificUomIndex].price || 0;
								} catch (e) {
								}
							} else {
								csvRow.isItemUomMatched = 'unmatched';
								try {
									csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
								} catch (e) {
								}
							}
						} else {
							csvRow.isItemUomMatched = 'unmatched';
							try {
								csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
							} catch (e) {
							}
						}
						let customBillbackfinalprice = 0;
						let customBillbackAmount = 0;
						customBillbackAmount = parseFloat(csvRow.billback) || 0;
						if (customersMasterData && customersMasterData.length > 0) {
							customersMasterData.forEach(element => {
								element.billbackAmount = customBillbackAmount;
							});
						}
						let itemTabModel = new BillBackItemTabModel();
						itemTabModel.guid = this.guid.newGuid();
						itemTabModel.erp_primary_key = itemEle.erp_primary_key || '';
						itemTabModel.itemsku = itemEle.itemsku || '';
						itemTabModel.itemname = itemEle.itemname || '';
						itemTabModel.item_guid = itemEle.guid || '';
						itemTabModel.description = itemEle.description || '';
						itemTabModel.uomgroupentry = itemEle.uomgroupentry || '';
						itemTabModel.uomList = listOfSpecificUoms || [];
						itemTabModel.selectedUom = defaultSelectedUom;
						itemTabModel.customers = JSON.parse(JSON.stringify(customersMasterData));
						itemTabModel.is_disbled_input_field = false;
						itemTabModel.listPrice = +defaultListPrice;
						itemTabModel.billbackAmount = customBillbackAmount;
						itemTabModel.final_amount = customBillbackfinalprice;
						itemTabModel.bought = '';
						itemTabModel.sold = '';
						itemTabModel.billed = '';
						itemTabModel.available = '';
						itemTabModel.last_invoice_date = '';

						//check is exiting item in grid
						if (this.itemTabDataList.length > 0) {
							const exitingIndex = this.itemTabDataList.findIndex(item => item.item_guid === itemTabModel.item_guid);
							if (exitingIndex !== -1) {
								itemTabModel.guid = this.itemTabDataList[exitingIndex].guid;
								let itemCustomersList = this.itemTabDataList[exitingIndex].customers || [];
								let excelItemCustomer = JSON.parse(JSON.stringify(itemTabModel.customers));
								if (itemCustomersList && itemCustomersList.length > 0) {
									//Merge Exting Item customer and excel item customer list
									let customers: any = this.mergeByProperty(itemCustomersList, excelItemCustomer, customBillbackAmount);
									itemTabModel.customers = customers;
								}
								this.itemTabDataList[exitingIndex] = itemTabModel;

								this.itemTabDataSource = this.itemTabDataList;
							} else {
								this.itemTabDataList.unshift(JSON.parse(JSON.stringify(itemTabModel)));
							}
						} else {
							this.itemTabDataList.unshift(JSON.parse(JSON.stringify(itemTabModel)));
						}

					}
				}
			});

			this.isUploadExcelCsvFileItmGrpTab = true;
			if (this.csvResponse && this.csvResponse.fileName) {
				this.filterText = this.csvResponse.fileName;
			}
			this.checkImportItemSummary();
			if (this.itemTabDataList.length > 0) {
				this.itemTabDataSource = this.itemTabDataList;
				// on upload item not passed data on save method
				this._BillbackContractService.setItemTabDataList(this.itemTabDataList);
				this.saveBillbackItemTabDataEvent.emit('uploaditem');
			}
		});
	}

	public mergeByProperty(arr1, arr2, customBillbackAmount) {
		let newCustomerArr: any = [];
		arr2.forEach(element => {
			if (newCustomerArr.length > 1) {
				const isIndex = newCustomerArr.findIndex(item => item.customer_code === element.customer_code);
				if (isIndex === -1) {
					newCustomerArr.push(element);
				} else {
					newCustomerArr[isIndex] = element;
				}
			} else {
				newCustomerArr.push(element);
			}
		});
		arr1.forEach(element => {
			if (newCustomerArr.length > 1) {
				const isIndex = newCustomerArr.findIndex(item => item.customer_code === element.customer_code);
				if (isIndex === -1) {
					newCustomerArr.push(element);
				} else {
					element.billbackAmount = customBillbackAmount;
					newCustomerArr[isIndex] = element;
				}
			} else {
				newCustomerArr.push(element);
			}
		});
		return newCustomerArr;
		// _.each(arr2, (arr2obj) => {
		// 	let arr1obj = _.find(arr1, (arr1obj) => {
		// 		return arr1obj[prop] === arr2obj[prop];
		// 	});
		// 	arr1obj ? _.extend(arr1obj, arr2obj) : arr1.push(arr2obj);
		// });
	}

	private fetchedAllItemsItmGrpTab() {
		let priceListNum = '';
		try {
			priceListNum = this.billbackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'items');
		formData.append('is_dropdown', 'true');
		formData.append('view_fields', JSON.stringify(["guid", "erp_primary_key", "itemsku", "itemname", "description", "uomgroupentry"]));
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					itemDetailsList = response.data || [];
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			} else {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
			this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
		}, error => {
			this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			this._LoaderService.hide();
			// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	// Method used to Fetch specific items details
	private fetchedItemDetailsByPricelistnum(itemGuidList: any[]) {
		let priceListNum = '';
		try {
			priceListNum = this.billbackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'item_details');
		formData.append('is_dropdown', 'true');
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					itemDetailsList = response.data || [];
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			} else {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
			this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
		}, error => {
			this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
			this._LoaderService.hide();
			// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	// Method used to get number of item who unmatched.
	private checkImportItemSummary(itemTabDataList?: any) {
		if (itemTabDataList && itemTabDataList.length > 0) {
			this.noOfTotalItem = this.itemTabDataList.length;
			this.noOfUnmatchedItem = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched === false).length;
			this.noOfUnmatchedItemUom = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
			this.noOfmatchedItemPrice = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
		} else {
			this.noOfTotalItem = this.listOfCsvRowForItmGrpTab.length;
			this.noOfUnmatchedItem = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched === false).length;
			this.noOfUnmatchedItemUom = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
			this.noOfmatchedItemPrice = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
		}
	}

	public doShowCsvItemsListItmGrpTab() {
		// this.dialogConfig.data = {
		// 	actionFor: 'showIgnoredItemList',
		// 	listOfCsvRows: [],
		// 	listOfUnmatchedItems: this.listOfCsvRowForItmGrpTab
		// };
		// this._MatDialog.open(BcUploadItemsDialogComponent, this.dialogConfig).afterClosed().subscribe(result => {
		// 	if (result) {

		// 	}
		// });
		const dialogData = {
			actionFor: 'showIgnoredItemList',
			listOfCsvRows: [],
			listOfUnmatchedItems: this.listOfCsvRowForItmGrpTab
		};
		this.childBcUploadItemsAndIgnoredItemsDialogComponent.doOpenPopup(dialogData);
	}

	// #endregion for Bulk Upload items to datagrid

	// #region for Set UOMs
	private setlistOfUomBySelProduct(itemDetails: any) {
		let priceListNum = '';
		try {
			priceListNum = this.billbackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = this.listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = this.listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = this.listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	// Method used to set Default Uom by uomgroupentry from item
	private setDefaultUomByUomGroupEntry(itemDetails: any) {
		const listOfSpecificUOM = [];
		if (itemDetails.uomgroupentry) {
			// const uomGroupLst = this._DataService.getUomGroups() || [];
			const uomGroupLst = this.listOfUomGroups || [];
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						newItemUomPice.absentry = this.listOfUoms[uomIndex].absentry;
						newItemUomPice.code = this.listOfUoms[uomIndex].code;
						newItemUomPice.name = this.listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}
		}
		return listOfSpecificUOM;
	}
	private convertToNumber(str: string) {
		// return +str.replace(/[A-Za-z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
		return +str;
	}

}
