import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { Component, OnInit, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { CustomNumberDecimalSuffixPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';

@Component({
	selector: 'app-sales-quotes-total-tiles',
	templateUrl: './sales-quotes-total-tiles.component.html',
	styleUrls: ['./sales-quotes-total-tiles.component.css'],
	providers: [CustomNumberDecimalSuffixPipe]
})
export class SalesQuotesTotalTilesComponent implements OnInit, OnDestroy {
	public globalCurrency: string;
	public valueOfSalesQuotesForTheMonth: number;
	public resourcesLoaded: boolean = false;
	public currentMonthName: string;
	private getQuoteSbsn: Subscription;
	public isShowQuotePopover: boolean;
	public isUserSettingsExisting: boolean;
	public isShowFullValue: boolean = false;
	public getLastCharacterFromSortValue: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService,
		private _ElementRef: ElementRef
	) {

	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this._ElementRef.nativeElement.contains(event.target)) {
			// click on inside 
		} else {
			this.isShowFullValue = false; // click on outside 
		}
	}

	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.currentMonthName = this._DashboardService.getCurrentMonthName();
		this.currentMonthName = '';
		this.getSalesQuotesTotal();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_QUOTE_TILES_EVENT', response => {
			if (response) {
				this.getSalesQuotesTotal();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getQuoteSbsn) {
			this.getQuoteSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_QUOTE_TILES_EVENT');
	}

	private getSalesQuotesTotal() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.QUOTATIONS_TILE);
		if (this.getQuoteSbsn) {
			this.getQuoteSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getQuoteSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(
			response => {
				this.resourcesLoaded = false;
				if (response.flag) {
					if (response.data.tiles) {
						this.valueOfSalesQuotesForTheMonth = response.data.tiles.value_of_sales_quotes_for_the_month || 0;
						this.currentMonthName = response.data.tiles.month_name || '';
						const getStringValue = new CustomNumberDecimalSuffixPipe().transform(this.valueOfSalesQuotesForTheMonth, 2).toString();
						this.getLastCharacterFromSortValue = '';
						if (/^[a-zA-Z]+$/.test(getStringValue.substr(getStringValue.length - 1))) {
							this.getLastCharacterFromSortValue = getStringValue.substr(getStringValue.length - 1);
						}
					}
				}
				this.getCustomer360Config();
			}, error => {
				this.resourcesLoaded = false;
			});
	}

	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
	}

	public doOpenQuotePopover(e) {
		this.getCustomer360Config();
		this.isShowQuotePopover = true;
	}
	public doCloseQuotePopover(e) {
		this.isShowQuotePopover = false;
	}

	// Method used to get Customer360 Configuration
	private getCustomer360Config() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		this.isUserSettingsExisting = false;
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === 'quote_tile');
				if (configIndex !== -1) {
					const configObject = dashboardSettings.configs[configIndex];
					let isTileSettingSaved = false;
					if (configObject && configObject.show_data_from) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_status_to_consider) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_date_to_use) {
						isTileSettingSaved = true;
					}
					this.isUserSettingsExisting = isTileSettingSaved;
				}
			}
		}
	}
	public doRefreshTile(e) {
		this.isShowQuotePopover = false;
		this.getSalesQuotesTotal();
	}

	public doClickToShowFullValue(event) {
		if (this.valueOfSalesQuotesForTheMonth <= 0) {
			return;
		}
		if (this.valueOfSalesQuotesForTheMonth > 0 && this.valueOfSalesQuotesForTheMonth < 1000) {
			return;
		}
		event.stopPropagation();
		this.isShowFullValue = true;
	}
}