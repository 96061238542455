import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalPopoupHeight90Ratio, GlobalPopoupWidth90Ratio, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { Customer360TilesEnum, ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-view-invoice-detail-popup',
	templateUrl: './view-invoice-detail-popup.component.html',
	styleUrls: ['./view-invoice-detail-popup.component.css'],
	providers: [CurrencyPipe, DecimalPipe, CustomCurrencyPipe],
})
export class ViewInvoiceDetailPopupComponent implements OnInit, OnDestroy {
	public pageTitle: string = '';
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean = true;
	@Input() viewInvoiceDetailProps: ViewInvoiceDetailPopUpModel;
	@Output() viewInvoiceDetailPopup = new EventEmitter<any>();

	public myMath = Math;
	public erpTypeValEnum = ErpTypeValEnum;
	public invoiceDetails: any;
	public uomList: any[] = [];
	private dataOutReqSubscription: Subscription;
	private getInvoiceIncentivesSbsn: Subscription;
	private searchParams: any[] = [];
	public globalCurrency: string;
	public isResponse: boolean = false;
	public erp_type: string = '';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public listOfPrices: any[] = [];
	public isShowPickStatusColumn: boolean = false;
	public isShowDataGrid: boolean = false;
	@ViewChild('itemsListOriginDatagridRef') itemsListOriginDatagridRef: DxDataGridComponent;
	private loaderConfig: any;
	public isShowClassesColumn: boolean;
	public Customer360TilesEnum = Customer360TilesEnum;
	public isShowCostColumn: boolean = false;
	public isEnabledMasterDetails: boolean = false;
	public pageOptions: PageOptions = new PageOptions();
	public isShowOriginalInvoiceDetails: boolean = false;
	public originalInvoiceDetails: any;
	public isShowEligibleField: boolean;
	public isShowEligibleColumn: boolean;
	public listOfSalesPpl: any[];
	public isMultipleSalesReps: boolean;

	public decimalPointPercentageFormat: string = '1.2-2';
	public decimalPointForCurrency: number;
	public isShowCardcodeColumn: boolean = false;

	public isEnableAmortisePaymentsFeature: boolean = false;
	public isEnableProfitOnDocHeaderFeature: boolean = false;
	public isItemListLevel: boolean = false;
	private listOfFeatureCustomFields: any[] = [];
	public specificListOfProfitOnDocHeader: any[] = [];
	private getFetureCustomFieldsSbsn: Subscription;

	public isExistsPayementInfo: boolean = false;
	public isShowPaymentInfoPopup: boolean = false;
	public listOfPayments: any[] = [];
	public listOfPaymentTypesHeaderFilters: any[] = [];
	public isShowActionCostAssignment: boolean = false;
	public popupWidth: number;
	public popupHeight: number;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public canEnableOtherCurrencies: boolean;

	public isEnableDistributionChannels: boolean = false;
	public listOfDistributionChannels: any[] = [];
	private getListOfDistributionChannelsSbsn: Subscription;

	constructor(private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _CurrencyPipe: CurrencyPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _DecimalPipe: DecimalPipe,
		public _AppCommonSrvc: AppCommonSrvc
	) {
		this.loaderConfig = {
			dx_position: '{of: #mainContent1}'
		};
		this.doCurrencyCalculateDisplayValue = this.doCurrencyCalculateDisplayValue.bind(this);
	}

	ngOnInit(): void {
		if (this.viewInvoiceDetailProps.docType == 'invoice') {
			this.pageTitle = 'Invoice Detail'
			this.onInitInvoice();
		}
	}
	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getInvoiceIncentivesSbsn) {
			this.getInvoiceIncentivesSbsn.unsubscribe();
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}
	}

	doCloseInvoiceDetailCreditMemoPopup(e) {
		this.isShowPopup = false;
		this.viewInvoiceDetailPopup.emit({ isSuccess: true });
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.setDxPopupWidth();
		}, 500);
	}


	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		this.setDxPopupWidth();
	}

	private setDxPopupWidth() {
		const innerWidth = window.innerWidth;
		const innerHeight = window.innerHeight;
		this.popupWidth = (innerWidth * GlobalPopoupWidth90Ratio) / 100;
		this.popupHeight = (innerHeight * GlobalPopoupHeight90Ratio) / 100;
	}

	onInitInvoice() {
		this.isShowDataGrid = true;
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}

		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.canEnableOtherCurrencies = this._LoginService.loginUser.account_detail.enable_other_currencies || false;
		}
		this.listOfSalesPpl = this._DataService.getSalesPpl() || [];
		this.uomList = this._DataService.getUOMs();
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_distribution_channels')) {
			this.isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels || false;
		}
		this.setIsShowIxcodeOrDocnum();
		this.doCheckUseCustomersFrom();
		this.doCallInvoiceEntity();
	}

	displayPhoneNoWithCode(element) {
		let phonenumberstr = "";
		phonenumberstr = element.phone1 || "";
		if (element.dialcode) {
			const getPhoneCodeData = this._DataService.getSplitPhoneCode(
				element.dialcode
			);
			if (getPhoneCodeData && getPhoneCodeData.length > 0) {
				phonenumberstr = getPhoneCodeData[0].code + " " + element.phone1;
			}
		}
		return phonenumberstr;
	}

	// Method used to Show ixode or docnum with synced
	private setIsShowIxcodeOrDocnum() {
		this.isShowClassesColumn = false;
		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			this.isShowClassesColumn = true;
		}
	}

	private doCallInvoiceEntity() {
		this.invoiceDetails = {};
		this.invoiceDetails.contactpersondata = undefined;
		this.invoiceDetails.salespersondata = undefined;
		this.searchParams = [];
		this.searchParams.push({ 'docnum': this.viewInvoiceDetailProps.docNo });
		const invoicesFormData = new FormData();
		invoicesFormData.append('usr', this._LoginService.loginUser.user);
		invoicesFormData.append('token', this._LoginService.loginUser.token);
		invoicesFormData.append('entity', ServerEntity.INVOICES);
		invoicesFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		invoicesFormData.append('search', JSON.stringify(this.searchParams));
		if (this.viewInvoiceDetailProps.docDate) {
			invoicesFormData.append('docdate', this.viewInvoiceDetailProps.docDate);
		}

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();

		this.isResponse = true;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(invoicesFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				this.isResponse = true;
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const invoiceDetails = response.data[0];
						this.invoiceDetails = invoiceDetails;
						this.isShowCostColumn = false;
						this.isEnabledMasterDetails = false;
						if (this.invoiceDetails.documentlines && this.invoiceDetails.documentlines.length > 0) {
							this.invoiceDetails.documentlines.forEach(dl => {
								if (dl.costassignments && dl.costassignments.length > 0) {
									this.isEnabledMasterDetails = true;
								}
								if (dl.costperitem !== 0) {
									this.isShowCostColumn = true;
								}
								if (dl.assigned_date && dl.costassignment_id) {
									this.isShowCostColumn = true;
								}
							});
						}

						this.isShowEligibleField = false;
						if (this.invoiceDetails.hasOwnProperty('eligible')) {
							this.isShowEligibleField = true;
						}

						this.isShowEligibleColumn = invoiceDetails.documentlines.some(dl => {
							return dl.hasOwnProperty('eligible');
						});

						this.isMultipleSalesReps = false;
						if (this.invoiceDetails.hasOwnProperty('salesreps')) {
							this.isMultipleSalesReps = true;
						}

						this.isExistsPayementInfo = false;
						if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
							if (this.invoiceDetails.totalpaidamount && this.invoiceDetails.payments && this.invoiceDetails.payments.length > 0) {
								this.isExistsPayementInfo = true;
							}
						}

						if (this.canEnableOtherCurrencies) {
							setTimeout(() => {
								this.customCurrencyOption.currencyName = invoiceDetails.doccurrency;
							});
						}

						if (this.isEnableDistributionChannels) {
							const filterObj = ['code', 'contains', invoiceDetails.distribution_channels];
							this.doFetchListOfDistributionChannels(filterObj);
						}

						this.getInvoiceIncentiveDetails();
						this.searchParams = [];
						if (invoiceDetails.guidclient) {
							this.searchParams.push({ 'guid': invoiceDetails.guidclient });
						} else if (invoiceDetails.cardcode) {
							this.searchParams.push({ 'code': invoiceDetails.cardcode });
						}
						// reqFormData = this.loadSalesDetailsData();
						const clientsFormData = new FormData();
						clientsFormData.append('usr', this._LoginService.loginUser.user);
						clientsFormData.append('token', this._LoginService.loginUser.token);
						clientsFormData.append('entity', ServerEntity.CLIENTS);
						clientsFormData.append('method', ServerMethods.GET_ENTITY_DATA);
						// clientsFormData.append('is_dropdown', 'true');
						clientsFormData.append('search', JSON.stringify(this.searchParams));
						if (this.dataOutReqSubscription) {
							this.dataOutReqSubscription.unsubscribe();
						}
						this._LoaderService.show();
						this.isResponse = false;
						this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(clientsFormData).subscribe({
							next: (response) => {
								this.isResponse = true;
								this._LoaderService.hide();
								if (response.flag) {
									if (response.data && response.data.length > 0) {
										const client = response.data[0];
										// Find Contact Person Details
										if (client.contactemployees && client.contactemployees.length > 0) {
											const contactPplData = client.contactemployees.filter(itemContact => itemContact.internalcode === invoiceDetails.contactpersoncode);
											this.invoiceDetails.contactpersondata = contactPplData && contactPplData.length > 0 ? contactPplData[0] : undefined;
										}
										// Set Billing/Shipping Address
										if (this.invoiceDetails.contactpersondata === undefined) {
											this.invoiceDetails.contactpersondata = {};
										}
										if (client && client.human_readable_account_code) {
											this.invoiceDetails.human_readable_account_code = client.human_readable_account_code;
										}
										this.setBillingAddress(client);
										this.setShippingAddress(client);
										this.fetchedListOfPrices(client.pricelistnum);
									} else {
										// this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
									}
								} else {
									// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
								}
							}, error: error => {
								this._LoaderService.hide();
								this.isResponse = true;
								// this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
						});
						this.doCheckEnableProfitOnDocFeature();
						this.doCheckEnableAmortisePayments();
					} else {
						this.invoiceDetails = undefined;
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this.isResponse = true;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private getInvoiceIncentiveDetails() {
		const searchParams = [{ 'docentry': this.invoiceDetails.docentry }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.INVOICEINCENTIVES);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('search', JSON.stringify(searchParams));
		if (this.getInvoiceIncentivesSbsn) {
			this.getInvoiceIncentivesSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getInvoiceIncentivesSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					if (response.data && response.data.length > 0) {
						const listOfIncentives = response.data[0].incentives;
						let totalIncentivePaid = 0;
						listOfIncentives.forEach(incentive => {
							totalIncentivePaid += incentive.incentive_paid;
						});
						this.invoiceDetails.totalIncentivePaid = totalIncentivePaid;
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method To Set billing Address
	private setBillingAddress(client: any) {
		if (this.invoiceDetails.paytocode && client.address && client.address.billto && client.address.billto.length > 0) {
			const clientAddressData = client.address.billto.filter(itemAddress => itemAddress.addressname === this.invoiceDetails.paytocode);
			this.invoiceDetails['contactpersondata']['billtodata'] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.invoiceDetails.contactpersondata["billtodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Set billing Address
	private setShippingAddress(client: any) {
		if (this.invoiceDetails.shiptocode && client.address && client.address.shipto && client.address.shipto.length > 0) {
			const clientAddressData = client.address.shipto.filter(itemAddress => itemAddress.addressname === this.invoiceDetails.shiptocode);
			this.invoiceDetails['contactpersondata']["shipptodata"] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.invoiceDetails.contactpersondata["shipptodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Get List of Prices from Clients's pricelistnum
	private fetchedListOfPrices(pricelistnum?: string) {
		this.listOfPrices = [];
		if (!pricelistnum) {
			return;
		}
		const viewFields = ['pricelistno', 'pricelistname'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.PRICELISTS);
		// formdata.append('is_dropdown', '1');
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		// this._LoaderService.show();
		this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: response => {
				// this._LoaderService.hide();
				this.listOfPrices = [];
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data;
					} else {
						this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				// this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doToolbarPreparing(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel();
					}
				}
			}
		);
	}

	private doExportToExcel() {
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Invoice_' + this.invoiceDetails.ixcode + '.xlsx';
		worksheet = workbook.addWorksheet('Invoice');

		exportDataGrid({
			worksheet: worksheet,
			component: this.itemsListOriginDatagridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'itemname') {
						if (gridCell.data.itemname) {
							excelCell.value = gridCell.data.itemname;
						}
					}
					if (gridCell.column.dataField === 'pickstatus') {
						if (gridCell.data.pickstatus && gridCell.data.pickstatus.toString().toLowerCase() === 'tyes') {
							excelCell.value = 'Yes';
						} else {
							excelCell.value = 'No';
						}
					}
					if (gridCell.column.dataField === 'price') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
					if (gridCell.column.dataField === 'linetotal') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
				}
			}
		}).then((cellRange) => {
			// header
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			if (this.invoiceDetails.address) {
				worksheet.mergeCells(2, 4, 2, 7);
			}
			headerRow.getCell(1).value = 'Invoice ID:';
			headerRow.getCell(1).font = { 'bold': true };
			let orderIdValue = this.invoiceDetails.docnum;
			headerRow.getCell(2).value = orderIdValue;
			headerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.invoiceDetails.address) {
				headerRow.getCell(3).value = 'Billing Address';
				headerRow.getCell(3).font = { 'bold': true };
				headerRow.getCell(4).value = this.invoiceDetails.address || '';
			}

			// customer
			const customerRow = worksheet.getRow(3);
			if (this.invoiceDetails.address2) {
				worksheet.mergeCells(3, 4, 3, 7);
			}
			customerRow.height = 30;
			customerRow.getCell(1).value = 'Customer';
			customerRow.getCell(1).font = { 'bold': true };
			let cardCode = '';
			if (!this.isQBErpUser) {
				cardCode = this.invoiceDetails.cardcode || '';
			}
			customerRow.getCell(2).value = (cardCode ? cardCode + ' - ' : '') + this.invoiceDetails.cardname;
			customerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.invoiceDetails.address2) {
				customerRow.getCell(3).value = 'Shipping Address';
				customerRow.getCell(3).font = { 'bold': true };
				customerRow.getCell(4).value = this.invoiceDetails.address2 || '';
			}

			// DocDate
			const docDateRow = worksheet.getRow(4);
			docDateRow.height = 30;
			docDateRow.getCell(1).value = 'Invoice Date';
			docDateRow.getCell(1).font = { 'bold': true };
			docDateRow.getCell(2).value = this.invoiceDetails.docdate;
			docDateRow.getCell(2).alignment = { horizontal: 'left' };

			// DocdueDate
			const docdueDateRow = worksheet.getRow(5);
			docdueDateRow.height = 30;
			docdueDateRow.getCell(1).value = 'Invoice Due Date';
			docdueDateRow.getCell(1).font = { 'bold': true };
			docdueDateRow.getCell(2).value = this.invoiceDetails.docduedate;
			docdueDateRow.getCell(2).alignment = { horizontal: 'left' };

			// Order Status
			const documentStatusRow = worksheet.getRow(6);
			documentStatusRow.height = 30;
			documentStatusRow.getCell(1).value = 'Invoice Status';
			documentStatusRow.getCell(1).font = { 'bold': true };
			let orderStatus = '';
			if (this.invoiceDetails.documentstatus.toString().toLowerCase() === 'bost_open') {
				orderStatus = 'Open';
			} else {
				orderStatus = 'Closed';
			}
			documentStatusRow.getCell(2).value = orderStatus;
			documentStatusRow.getCell(2).alignment = { horizontal: 'left' };
			let numberOfRows = this.itemsListOriginDatagridRef.instance.getVisibleRows().length;

			// Sub Total
			let rowIncrement = 11;
			let subTotal = 0;
			const subTotalRow = worksheet.getRow(numberOfRows + rowIncrement);
			subTotalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				subTotalRow.getCell(5).value = 'Sub Total';
				subTotalRow.getCell(5).font = { 'bold': true };
				subTotalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.doctotalsys);
				subTotalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.invoiceDetails.totaldiscount > 0) {
				// Discount
				let discount = 0;
				rowIncrement = rowIncrement + 1;
				const discountRow = worksheet.getRow(numberOfRows + rowIncrement);
				discountRow.height = 30;
				discountRow.getCell(6).value = 'Discount';
				discountRow.getCell(6).font = { 'bold': true };
				discountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.totaldiscount);
				discountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.invoiceDetails.totalmiscamount > 0) {
				// Miscellaneous Charge
				let totalmiscamount = 0;
				rowIncrement = rowIncrement + 1;
				const totalmiscamountRow = worksheet.getRow(numberOfRows + rowIncrement);
				totalmiscamountRow.height = 30;
				totalmiscamountRow.getCell(6).value = 'Miscellaneous Charge';
				totalmiscamountRow.getCell(6).font = { 'bold': true };
				totalmiscamountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.totalmiscamount);
				totalmiscamountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.invoiceDetails.shippingcharges > 0) {
				// Frieght Charge
				let shippingcharges = 0;
				rowIncrement = rowIncrement + 1;
				const shippingchargesRow = worksheet.getRow(numberOfRows + rowIncrement);
				shippingchargesRow.height = 30;
				shippingchargesRow.getCell(6).value = 'Frieght Charge';
				shippingchargesRow.getCell(6).font = { 'bold': true };
				shippingchargesRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.shippingcharges);
				shippingchargesRow.getCell(7).alignment = { horizontal: 'right' };
			}

			// Total
			let total = 0;
			rowIncrement = rowIncrement + 1;
			const totalRow = worksheet.getRow(numberOfRows + rowIncrement);
			totalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				totalRow.getCell(5).value = 'Total';
				totalRow.getCell(5).font = { 'bold': true };
				totalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.invoiceDetails.doctotal);
				totalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			// Remark
			const remarkRow = worksheet.getRow(numberOfRows + 13);
			totalRow.height = 30;
			remarkRow.getCell(1).value = 'Remark';
			remarkRow.getCell(1).font = { 'bold': true };
			remarkRow.getCell(2).value = this.invoiceDetails.comments || '';
			remarkRow.getCell(2).alignment = { horizontal: 'right' };

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	public doRowPreparedDocLine(e: any) {
		if (e.rowType == "data" && (!e.data.costassignments || e.data.costassignments && e.data.costassignments.lenght <= 0)) {
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
			}
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
			}
		}
	}

	public doOriginalInvoiceDetails() {
		const sourceDocNum = this.invoiceDetails.source_docnum;

		const invoicesFormData = new FormData();
		invoicesFormData.append('usr', this._LoginService.loginUser.user);
		invoicesFormData.append('token', this._LoginService.loginUser.token);
		invoicesFormData.append('entity', ServerEntity.INVOICES);
		invoicesFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		// invoicesFormData.append('is_dropdown', 'true');
		invoicesFormData.append('search', JSON.stringify([{ docnum: sourceDocNum }]));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.isResponse = false;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(invoicesFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.isShowOriginalInvoiceDetails = true;
					if (response.data && response.data.length > 0) {
						const invoiceDetails = response.data[0];
						this.originalInvoiceDetails = invoiceDetails;
					}
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}

	private doCheckUseCustomersFrom() {
		let use_customers_from: string;
		try {
			use_customers_from = this._LoginService.loginUser.account_detail.use_customers_from;
		} catch (e) {
			use_customers_from = '';
		}
		this.isShowCardcodeColumn = false;
		if (use_customers_from === 'itemlist') {
			this.isShowCardcodeColumn = true;
			// It is displays Customer Code column in document line items.
		}
	}

	// #region for Show/Hide Amortise Payment field
	private doCheckEnableAmortisePayments() {
		this.isEnableAmortisePaymentsFeature = false;
		try {
			this.isEnableAmortisePaymentsFeature = this._LoginService.loginUser.account_detail.enable_amortise_payments;
		} catch (e) {
			this.isEnableAmortisePaymentsFeature = false;
		}
		if (this.isEnableAmortisePaymentsFeature) {
			this.doGetFetureCustomFields();
		}
	}

	// Method used to Check Feature Custom Field is on Item Level and sets Column Caption.
	private setAmortisePaymentsFeature() {
		this.isItemListLevel = this.listOfFeatureCustomFields.filter(custField => custField.section === 'ITEMLIST').length > 0 ? true : false;

		const documentLines = this.invoiceDetails.documentlines || [];
		this.listOfFeatureCustomFields.forEach(custField => {
			if (custField.section === 'ITEMLIST' && custField.custom_field_type === 'date' && custField.entity_type === 'invoices') {
				for (let i = 0; i < documentLines.length; i++) {
					if (documentLines[i].hasOwnProperty(custField.ix_custom_field)) {
						let custom_label = custField.ix_custom_field_title;
						if (custField.custom_field_title) {
							custom_label = custField.custom_field_title;
						}
						if (this.itemsListOriginDatagridRef) {
							this.itemsListOriginDatagridRef.instance.columnOption(custField.ix_custom_field, 'caption', custom_label);
						}
					}
				};
			}
		});
	}
	// #endregion


	private doCheckEnableProfitOnDocFeature() {
		this.isEnableProfitOnDocHeaderFeature = false;
		try {
			this.isEnableProfitOnDocHeaderFeature = this._LoginService.loginUser.account_detail.enable_profit_on_document_header;
		} catch (e) {
			this.isEnableProfitOnDocHeaderFeature = false;
		}
		if (this.isEnableProfitOnDocHeaderFeature) {
			this.doGetFetureCustomFields();
		}
	}

	public doGetFetureCustomFields() {
		let feature_guid = '';
		if (this.isEnableAmortisePaymentsFeature) {
			feature_guid = '7adc7b7ebcdd4dfea92464d6175b8ba0';
		}
		if (this.isEnableProfitOnDocHeaderFeature) {
			feature_guid = 'ffc6f6d2127d41a7b4a3e358c8389912';
		}
		if (this._LoginService.loginUser.account_detail['enable_invoice_custom_fields_feature']) {
			feature_guid = 'bc541207c8644ec3b14fe1ade63460b9';
		}
		const searchCriteria: any = {
			feature_guid: feature_guid
		};
		if (this.isEnableAmortisePaymentsFeature) {
			searchCriteria.entity_type = 'invoices';
		}
		if (!feature_guid) {
			return false;
		}

		this.specificListOfProfitOnDocHeader = [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		formData.append('search', JSON.stringify([searchCriteria]));
		if (this.getFetureCustomFieldsSbsn) {
			this.getFetureCustomFieldsSbsn.unsubscribe();
		}
		this.getFetureCustomFieldsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfFeatureCustomFields = response.data || [];
						if (this.isEnableProfitOnDocHeaderFeature) {
							this.setProfitOnDocHeaderFeature();
						}
						if (this.isEnableAmortisePaymentsFeature) {
							setTimeout(() => {
								this.setAmortisePaymentsFeature();
							}, 250);
						}
					} else {
						// this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private setProfitOnDocHeaderFeature() {
		this.specificListOfProfitOnDocHeader = [];
		this.listOfFeatureCustomFields.forEach(custField => {
			if (this.invoiceDetails && this.invoiceDetails.hasOwnProperty(custField.ix_custom_field) && this.invoiceDetails[custField.ix_custom_field]) {
				const newCustFields: any = {
					guid: custField.guid,
					custom_value: null,
					custom_display_value: null,
					custom_label: null
				}
				newCustFields.custom_label = custField.ix_custom_field_title;
				if (custField.custom_field_title) {
					newCustFields.custom_label = custField.custom_field_title;
				}
				newCustFields.custom_value = this.invoiceDetails[custField.ix_custom_field];
				if (custField.custom_field_output_format === 'percentage') {
					newCustFields.custom_display_value = this._DecimalPipe.transform(this.invoiceDetails[custField.ix_custom_field] || 0, this.decimalPointPercentageFormat) + '%';
				}
				if (custField.custom_field_output_format === 'amount') {
					newCustFields.custom_display_value = this._CurrencyPipe.transform(this.invoiceDetails[custField.ix_custom_field] || 0, this.globalCurrency);
				}
				if (custField.custom_field_output_format === 'boolean') {
					newCustFields.custom_display_value = 'No';
					if (this.invoiceDetails[custField.ix_custom_field]) {
						newCustFields.custom_display_value = 'Yes';
					}
				}
				this.specificListOfProfitOnDocHeader.push(newCustFields);
			}
		});
	}

	// #region for Payment Information popup

	public doShowPaymentDetails() {
		if (this.isExistsPayementInfo) {
			this.isShowPaymentInfoPopup = true;
			this.listOfPayments = [];
			const listOfPayment = this.invoiceDetails.payments || [];
			this.listOfPayments = listOfPayment;
			this.getListOfPaymentTypesHeaderFilter();
		}
	}

	public doHidePaymentInfoPopup(event) {
		this.isShowPaymentInfoPopup = false;
	}

	public doCustomizeTotalPaymentSummary(e) {
		if (e.value < 0) {
			return 0;
		} else {
			return e.valueText;
		}
	}

	private getListOfPaymentTypesHeaderFilter() {
		this.listOfPaymentTypesHeaderFilters = [
			{
				value: 'cash',
				text: 'Cash'
			},
			{
				value: 'creditmemo',
				text: 'Credit Memo'
			},
			{
				value: 'check',
				text: 'Check'
			},
			{
				value: 'e-check',
				text: 'E-Check'
			}
		]
	}

	//  #endregion

	public doCurrencyCalculateDisplayValue(rowData: any) {
		if (rowData && rowData.hasOwnProperty('price')) {
			return this._CustomCurrencyPipe.transform(rowData.price || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('linetotal')) {
			return this._CustomCurrencyPipe.transform(rowData.linetotal || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('amount')) {
			return this._CustomCurrencyPipe.transform(rowData.amount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('costperitem')) {
			return this._CustomCurrencyPipe.transform(rowData.costperitem || 0, this.customCurrencyOption);
		}
	}

	private doFetchListOfDistributionChannels(distributionChannelSearch?: any[]) {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		reqFormData.append('is_dropdown', 'true');
		reqFormData.append('view_fields', JSON.stringify(['guid', 'code', 'description']));
		if (distributionChannelSearch && distributionChannelSearch.length > 0) {
			reqFormData.append('filter', JSON.stringify(distributionChannelSearch));
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}

		this.getListOfDistributionChannelsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: response => {
				this.listOfDistributionChannels = [];
				if (response && response.flag) {
					this.listOfDistributionChannels = response.data || [];
				}
			}, error: error => {
			}
		});
	}

}