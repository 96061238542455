import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsTabComponent } from './notifications-tab/notifications-tab.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsApprovalsTabComponent } from './notifications-approvals-tab/notifications-approvals-tab.component';
import { PricelistDetailsComponent } from '../products/pricelist-details/pricelist-details.component';
import { RebatesTierbaseReportviewBuyinggroupComponent } from '../contracts/rebates-tierbase-contract/rebates-tierbase-reportview-buyinggroup/rebates-tierbase-reportview-buyinggroup.component';
import { RebatesTierbaseCalculationViewComponent } from '../contracts/rebates-tierbase-contract/rebates-tierbase-calculation-view/rebates-tierbase-calculation-view.component';
import { MinMaxChargebackClaimApprovalsDetailsComponent } from './min-max-chargeback-claim-approvals-details/min-max-chargeback-claim-approvals-details.component';

const routes: Routes = [
	{
		path: '',
		component: NotificationsComponent,
		children: [
			{
				path: 'errorslist',
				component: NotificationsTabComponent,
				data: {
					title: 'Notifications',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'notificationslist',
				component: NotificationsTabComponent,
				data: {
					title: 'Notifications',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals',
				component: NotificationsTabComponent,
				data: {
					title: 'Notifications',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/chargebacks',
				component: NotificationsTabComponent,
				data: {
					title: 'Chargebacks',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/pricelist',
				component: NotificationsTabComponent,
				data: {
					title: 'Pricelist',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/pricelistdetails',
				component: PricelistDetailsComponent,
				data: {
					title: 'Pricelist',
					breadcrumb: [
						{
							label: 'Approvals',
							url: '/notifications/approvals'
						},
						{
							label: 'Price List',
							url: '/notifications/approvals/pricelist'
						},
						{
							label: 'Price List Details',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/rebates_settlements',
				component: NotificationsTabComponent,
				data: {
					title: 'Rebate_settlements',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/min_max_chargebacks',
				component: NotificationsTabComponent,
				data: {
					title: 'Min-Max Chargebacks',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/minmaxchargebackapprovaldetails',
				component: MinMaxChargebackClaimApprovalsDetailsComponent,
				data: {
					title: 'Min-Max Chargeback Approval',
					breadcrumb: [
						{
							label: 'Approvals',
							url: '/notifications/approvals'
						},
						{
							label: 'Min-Max Chargeback Approval',
							url: '/notifications/approvals/min_max_chargebacks'
						},
						{
							label: 'Min-Max Chargeback Approval Details',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/rebates_settlement_reportview_buyinggroup',
				component: RebatesTierbaseReportviewBuyinggroupComponent,
				data: {
					title: 'Rebate_settlements',
					breadcrumb: [
						{
							label: 'Approvals',
							url: '/notifications/approvals'
						},
						{
							label: 'Rebate Settlement',
							url: '/notifications/approvals/rebates_settlements'
						},
						{
							label: 'Rebate Settlement Report',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvals/report',
				component: RebatesTierbaseCalculationViewComponent,
				data: {
					title: 'Rebate_settlements',
					breadcrumb: [
						{
							label: 'Approvals',
							url: '/notifications/approvals'
						},
						{
							label: 'Rebate Settlement',
							url: '/notifications/approvals/rebates_settlements'
						},
						{
							label: 'Rebate Settlement Report',
							url: ''
						}
					]
				},
			},


			{
				path: 'warningslist',
				component: NotificationsTabComponent,
				data: {
					title: 'Notifications',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'taskslist',
				component: NotificationsTabComponent,
				data: {
					title: 'Notifications',
					breadcrumb: [
						{
							label: 'Notifications',
							url: ''
						}
					]
				},
			},
			{
				path: 'approvalslist',
				component: NotificationsApprovalsTabComponent,
				data: {
					title: 'Approvals',
					breadcrumb: [
						{
							label: 'Approvals',
							url: ''
						}
					]
				},
			}
		]
	},
	{
		path: '',
		redirectTo: 'errorslist',
		pathMatch: 'full',
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class NotificationsRoutingModule { }
