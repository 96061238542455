<div id="notificationlist" class="rightBar">
	<div class="rightBlock">
		<!-- <div class="titleRow">
			<h1>Notifications</h1>
		</div> -->
		<div class="contentRow cf">
			<div class="tabbing">
				<ul>
					<li *ngxPermissionsOnly="['NOTIFICATIONS_ERROR_LOGS_VIEW']" (click)="clickOnTab('errorlog')"
						[ngClass]="{active: currentTab === 'errorlog'}" class="">
						<a>Errors</a>
					</li>
					<li *ngxPermissionsOnly="['NOTIFICATIONS_ERROR_LOGS_VIEW']" (click)="clickOnTab('notificationlog')"
						[ngClass]="{active: currentTab === 'notificationlog'}" class="">
						<a>Notifications</a>
					</li>
					<li *ngIf="view_task_notify && enable_shortpay_notification" (click)="clickOnTab('notificationtasks')"
						[ngClass]="{active: currentTab === 'notificationtasks'}" class="">
						<a>Tasks</a>
					</li>
					<li (click)="clickOnTab('notificationapprovals')"
						[ngClass]="{active: currentTab === 'notificationapprovals'}" class="">
						<a>Approvals</a>
					</li>
				</ul>
			</div>

			<!-- #region for Error, Notifications, Task  -->
			<div
				*ngIf="currentTab === 'errorlog' || currentTab ==='notificationlog' || currentTab === 'notificationtasks'">
				<div class="filterToggleOut aspdFilter">
					<span class="filterTogle">Search</span>
					<div class="filter filterCols searchFilter">
						<div class="row">
							<form autocomplete="off">
								<div class="cols12 inSearchCol">
									<div class="feildCol cancelSearchCol">
										<input type="text" [(ngModel)]="searchFilterObj.searchKeyword"
											(keydown)="onEnterPress($event)" name="searchKeyword" id="searchKeyword"
											placeholder="Search Keywords">
										<span *ngIf="searchFilterObj.searchKeyword !== ''" class="icon-close-input"
											(click)="onSearchCancel()"></span>
									</div>
									<dx-button class="button filterSearch secondaryBtn" text="Search" type="normal"
										stylingMode="contained" icon="search" (onClick)="onSearchClick()"
										[useSubmitBehavior]="false">
									</dx-button>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div *ngxPermissionsOnly="['NOTIFICATIONS_ERROR_LOGS_VIEW']" class="notificationMsgTab">
					<div class="cf"></div>
					<!-- <div class="tabTitles">
					<div class="custDis unreadNtfc">
						<h4>Notification Error Title here</h4>
						<h5><span>Method:</span> Data not cleared sync and try again</h5>
						<span class="contTx icoDate">
							<img class="date-img" src="images/crm/date-gray.png" alt="call Icon" width="17" height="16"> 23
							Jan, 2020
							| 11:26 AM
						</span>
						<div class="center actBtn">
							<a><span class="closeIcon sprite"></span></a>
						</div>
					</div>
					<div class="custDis activeNtfc">
						<h4>Notification Error Title here</h4>
						<h5><span>Method:</span> Data not cleared sync and try again</h5>
						<span class="contTx icoDate">
							<img class="date-img" src="images/crm/date-gray.png" alt="call Icon" width="17" height="16"> 23
							Jan, 2020
							| 11:26 AM
						</span>
						<div class="center actBtn">
							<a><span class="closeIcon sprite"></span></a>
						</div>
					</div>
					<div class="custDis">
						<h4>Notification Error Title here</h4>
						<h5><span>Method:</span> Data not cleared sync and try again</h5>
						<span class="contTx icoDate">
							<img class="date-img" src="images/crm/date-gray.png" alt="call Icon" width="17" height="16"> 23
							Jan, 2020
							| 11:26 AM
						</span>
						<div class="center actBtn">
							<a><span class="closeIcon sprite"></span></a>
						</div>
					</div>
				</div> -->

					<div [hidden]="isRecordAvailabled" class="tableOut tabTitles">
						<table mat-table [dataSource]="dataSource" class="tableData ntfcTable">
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr (click)="notificationDetailClick(row);$event.stopPropagation();" mat-row
								*matRowDef="let row; columns: displayedColumns; let i = index"></tr>
							<ng-container matColumnDef="notificationtitle">
								<th mat-header-cell *matHeaderCellDef>&nbsp;</th>
								<td mat-cell *matCellDef="let element;  let srno = index">
									<div class="custDis"
										[ngClass]="{'activeNtfc':activeRow === element.guid,'unreadNtfc':element.status === 'unread'}">
										<h4 [innerHTML]="element.title"></h4>
										<!-- <h5 *ngIf="element.method"><span>Method:</span>
										{{element.method}}
									</h5> -->
										<span class="contTx icoDate">
											<span class="icon-appointment_date"></span>
											{{element.datetime |customDateFormatPipe:'convertFrom24To12'}}
										</span>
										<div *ngxPermissionsOnly="['NOTIFICATIONS_ERROR_LOGS_MODIFY']" class="center actBtn">
											<a (click)="removedNotification(element);$event.stopPropagation();">
												<span class="icon-deletedxtable"></span>
											</a>
										</div>
									</div>
								</td>
							</ng-container>
						</table>
						<div class="tableEntries cf">
							<mat-paginator #paginator (page)="paginatorEventData($event)" [pageSize]="pageOptions.pageSize"
								[pageSizeOptions]="pageOptions.pageSizeOptions" showFirstLastButtons></mat-paginator>
						</div>
					</div>
					<div class="center" *ngIf="isRecordAvailabled">
						No record(s) available.
					</div>
					<div *ngIf="!isRecordAvailabled" class="tabDetails">
						<div *ngIf="notificationDetailData" class="notificationTabContainer">
							<div class="notiMessageTitle">
								<h4>{{notificationDetailData.title || ''}}</h4>
								<!-- <h5 *ngIf="notificationDetailData.method">
								<span>Method:</span>
								{{notificationDetailData.method}}
							</h5> -->
								<span class="contTx icoDate">
									<span class="icon-appointment_date"></span>
									{{notificationDetailData.datetime | customDateFormatPipe:'convertFrom24To12'}}
									<!-- <span *ngIf="notificationDetailData.code"class="skyblue">
									{{notificationDetailData.code}}
								</span> -->
								</span>
							</div>
							<div class="notificationDetails">
								<p [innerHTML]="notificationDetailData.description">
								</p>
							</div>
							<div class="left-content" *ngIf="currentTab === 'notificationtasks'">
								<!-- <dx-text-area [value]="convertJsonObject(notificationDetailData.attachment)"
								[autoResizeEnabled]="true" [disabled]="true"
								[elementAttr]="{class: 'dx-textarea-attachment' }"></dx-text-area> -->
								<h2 class="mrg0">Attachment:</h2>
								<dx-box direction="row" width="100%" [height]="'auto'">
									<dxi-item [ratio]="0" class="dx-box-dark" [baseSize]="20">
										<dx-box direction="row" width="100%" [height]="'auto'">
											<dxi-item [ratio]="0">
											</dxi-item>
										</dx-box>
									</dxi-item>
									<dxi-item class="dx-box-light" [ratio]="1">
										{{notificationDetailData.attachment | json}}
									</dxi-item>
								</dx-box>
							</div>
						</div>
						<div *ngIf="!isShowNotificationDetail">
							<div class="tableOut">
								<table class="hoverNone noBdr">
									<tr>
										<td class="selectPersonImg center">
											<img [src]="_AppCommonSrvc.cdnImgPath +'no-task-image.png'" alt="" title="" />
											<h4>To view <span class="highlight">notification</span> click on it.</h4>
										</td>
									</tr>
								</table>
							</div>
							<!-- <p class="center"><strong>To view notification click on it.</strong></p> -->
						</div>
						<div class="cols12 mrgT10" *ngIf="currentTab === 'notificationtasks' && notificationDetailData">
							<div class="alignRight">
								<dx-button class="primaryBtn" id="button" text="View Task" type="normal" stylingMode="outlined"
									[useSubmitBehavior]="false" (onClick)="doViewNotification(notificationDetailData.guid)">
								</dx-button>
							</div>
						</div>
						<div class="cols12 mrgT10"
							*ngIf="(currentTab === 'errorlog' || currentTab ==='notificationlog') && notificationDetailData && isShowDownloadLogFileBtn">
							<div class="alignRight">
								<dx-button class="primaryBtn" id="button" text="Download Log File" type="normal"
									stylingMode="outlined" [useSubmitBehavior]="false"
									(onClick)="doClickDownloadLogFile(notificationDetailData)">
								</dx-button>
							</div>
						</div>
					</div>
					<div class="cf"></div>
				</div>
			</div>
			<!-- #endregion -->
		</div>
	</div>
</div>

<app-approvals-tabs *ngIf="currentTab==='notificationapprovals'"></app-approvals-tabs>

<dx-popup [width]=" popupWidth" [height]="popupHeight" [showTitle]="true" title="Task View" [dragEnabled]="true"
	[hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="isViewNotificationTaskPopUp"
	(onHidden)="doHiddenTaskViewPopop()">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<div class="row ">
			<div class="cols12 colsP0">
				<div class="cols6">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Company</div>
						<div class="dx-field-value">
							<dx-drop-down-box valueExpr="guid" displayExpr="name" [(value)]="taskNotificationUI.guidclient"
								placeholder="Select a company..." [deferRendering]="true" [showClearButton]="true"
								[dataSource]="clientsList" [disabled]="true">
								<div *dxTemplate="let data of 'content'">
									<dx-data-grid [dataSource]="clientsList" keyExpr="guid" [selection]="{ mode: 'single'}"
										[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
										[filterRow]="{ visible: true }" [height]="345" [filterSyncEnabled]="true"
										[filterValue]="taskFilterValue" [remoteOperations]="true" [wordWrapEnabled]="true"
										[showBorders]="true" [(selectedRowKeys)]="taskNotificationUI.guidclient">
										<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
										<dxi-column dataField="code" [visible]="!isQBErpUser" caption="ID"
											cellTemplate="cellTemplateCode">
											<div *dxTemplate="let element of 'cellTemplateCode'">
												{{element.data.code || ''}}
											</div>
										</dxi-column>
										<dxi-column dataField="name" caption="Company" cellTemplate="cellTemplateCompanyName">
											<div *dxTemplate="let element of 'cellTemplateCompanyName'">
												{{element.data.name || ''}}
											</div>
										</dxi-column>

									</dx-data-grid>
								</div>
							</dx-drop-down-box>
						</div>
					</div>
				</div>
				<!--<div class="cols6">
				<div class="dx-field mrgB20">
					<div class="dx-field-label">Contact Person</div>
					<div class="dx-field-value" style="width: 100%;">
						<dx-select-box [(value)]="taskNotificationUI.guidcontactperson" [dataSource]="contactemployees"
							[displayExpr]="getContactEmployeeNameDisplayExpr" valueExpr="guid" searchMode="contains"
							searchExpr="selectTaskType" [searchTimeout]="200" [minSearchLength]="0" [searchEnabled]="true"
							[disabled]="isReadOnlyClient" name="taskfor">
						</dx-select-box>
					</div>
				</div>
			</div>
			</div> -->
				<div class="cols6">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Task Title</div>
						<div class="dx-field-value">
							<dx-text-box mode="text" [(value)]="taskNotificationUI.title" [showClearButton]="true"
								[maxLength]="100" name="tasktitle" [disabled]="true">
							</dx-text-box>
						</div>
					</div>
				</div>
				<div class="cols6">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Task Type</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-select-box [(value)]="taskNotificationUI.tasktype" [dataSource]="listOfTaskTypes"
								displayExpr="label" valueExpr="value" searchMode="contains" searchExpr="selectTaskType"
								[searchTimeout]="200" [minSearchLength]="0" [searchEnabled]="true" [disabled]="true"
								name="selectTaskType">
							</dx-select-box>
						</div>
					</div>
				</div>
				<div class="cols6">
					<div class="dx-field mrgB20">
						<div class="dx-field-label">Assign To</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-drop-down-box #dxDropDownRefAssignToUser [dataSource]="listOfAssignToUsers"
								[(value)]="taskNotificationUI.assignto" valueExpr="sub_account_id" [deferRendering]="false"
								placeholder="Select Assign To..." displayExpr="first_name" [showClearButton]="true"
								(onValueChanged)="doChangeDropdownAssignToUers($event)" [disabled]="true"
								class="dx-ix-dropdownbox">
								<div *dxTemplate="let data of 'content'">
									<dx-tag-box [(value)]="selectedListOfAssignToTags" [grouped]="true"></dx-tag-box>
									<dx-data-grid #dxListOfAssignToDataGrid [dataSource]="listOfAssignToUsers"
										keyExpr="sub_account_id" [selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true"
										[height]="345" [(selectedRowKeys)]="taskNotificationUI.assignto" [showBorders]="true"
										(onSelectionChanged)="doChangeDatagridAssignTo($event)"
										(onInitialized)="onInitializedAssignTo($event)">
										<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
										<dxo-selection [allowSelectAll]="false"></dxo-selection>
										<dxi-column dataField="first_name" caption="Name" cellTemplate="cellTemplateAssignTo">
											<div *dxTemplate="let element of 'cellTemplateAssignTo'">
												{{element.data.first_name}} {{element.data.last_name}}
											</div>
										</dxi-column>
										<dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
									</dx-data-grid>
								</div>
							</dx-drop-down-box>
						</div>
					</div>
				</div>

				<div class=" cols12">
					<div class="dx-field textareaCols mrgB20">
						<div class="dx-field-label">Notes</div>
						<div class="dx-field-value">
							<dx-text-area [(value)]="taskNotificationUI.notes" [disabled]="true" [height]="90"
								[maxLength]="500" name="notes">
							</dx-text-area>
						</div>
					</div>
				</div>
				<div class=" cols12">
					<div class="dx-field textareaCols mrgB20">
						<div class="dx-field-label">Attachment</div>
						<div class="dx-field-value">
							<dx-box direction="row" width="100%" [height]="'auto'">
								<dxi-item [ratio]="0" class="dx-box-dark" [baseSize]="20">
									<dx-box direction="row" width="100%" [height]="'auto'">
										<dxi-item [ratio]="0">
										</dxi-item>
									</dx-box>
								</dxi-item>
								<dxi-item class="dx-box-light" [ratio]="1">
									{{taskNotificationUI.external_data | json}}
								</dxi-item>
							</dx-box>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</dx-popup>