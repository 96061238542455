import { SideMenuForEnum } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { CRMTabValEnum, CRMValStatuses, TaskStatusValEnum, NameFromServerKeyEnum, ContactDetailActionBtnsValEnum, TaskTypesValEnum, CrmClientForEnum, ContactDetailTabDataEnum } from '@app/constants-enums/enums';
import { MSG_ERROR_MESSAGE, ServerMethods } from '@app/constants-enums/constants';
import { Router } from '@angular/router';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import { LoaderState } from '@app/models/loaderstate.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';

import { environment } from "@environments/environment";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent } from "devextreme-angular";
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
@Component({
	selector: 'app-ap-dashboard-task-list',
	templateUrl: './ap-dashboard-task-list.component.html',
	styleUrls: ['./ap-dashboard-task-list.component.css']
})
export class ApDashboardTaskListComponent implements OnInit {
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public resourcesLoaded: boolean = false;
	public loaderState = new LoaderState();
	private getTilesSubscription: Subscription;
	public globalCurrency: string;
	public TaskStatusValEnum = TaskStatusValEnum;
	public TaskTypesValEnum = TaskTypesValEnum;
	public ContactDetailActionBtnsValEnum = ContactDetailActionBtnsValEnum;
	public NameFromServerKeyEnum = NameFromServerKeyEnum;
	public listOfTaskStatusHeaderFilter: any[] = [];
	public listOfTaskTypeHeaderFilter: any[] = [];
	public listOfAssignToHeaderFilter: any[] = [];
	public listOfTopFiveTasks: any[] = [];
	public listOfTaskStatuses: any[] = [];
	public listOfTaskActions: any[] = [];
	public listOfTaskTypes: any[] = [];
	private isCellClickDetail: boolean = false;
	public contextMenuPosition = { x: "0px", y: "0px" };

	public getTasksSubscription: Subscription;
	public getClientDetailsSubscription: Subscription;
	public updateCrmSubscription: Subscription;

	public listOfAssignToUsers: any[] = [];
	public getListOfAssignToUserSubscription: Subscription;
	private getPropertyOptionsSubscription: Subscription;
	public pageOptions: PageOptions = new PageOptions();

	public dxDataSource: any;
	public url: string;
	public entity: string;
	public key: string;
	public filterValue: Array<any>;
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public addeditTaskDetailsProps: any;
	public isShowAddEditTaskPopup: boolean;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _Router: Router,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _CrmsService: CrmsService,
		public _SidemenuService: SidemenuService,
		private _IxDxAlertService: IxDxAlertService
	) { }

	ngOnInit() {
		this.preInIt();
	}

	// Method used to Init Tab and load data.
	private preInIt() {

		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);

		this.fetchedListOfTasks();
	}

	private fetchedListOfTaskStatuses() {
		this.listOfTaskStatuses = this._CrmsService.getTaskStatuses();
		const listOfTaskStatuses = this._CrmsService.getTaskStatuses();
		this.setTaskStatusHeaderFilter(listOfTaskStatuses);
	}

	// Method used to Fetch list of Task Types
	private fetchedListOfTaskTypes() {
		let dynamic_groups = [];
		let dynamic_properties = [];
		try {
			dynamic_groups = this._LoginService.loginUser.account_detail.dynamic_groups || [];
			dynamic_properties = this._LoginService.loginUser.account_detail.dynamic_properties || [];
		} catch (error) {
			dynamic_groups = [];
			dynamic_properties = [];
		}
		let dgIndex = -1;
		const currentTab = "tasks";
		if (currentTab === CRMTabValEnum.TASKS) {
			dgIndex = dynamic_groups.findIndex(dg => dg.group_name.toString().toLowerCase() === 'task');
		}
		if (dgIndex !== -1) {
			const dgData = dynamic_groups[dgIndex];
			const dpIndex = dynamic_properties.findIndex(dp => dp.group_guid === dgData.guid && dp.property.toString().toLowerCase() === 'tasktype');
			if (dpIndex !== -1) {
				const dpData = dynamic_properties[dpIndex];
				const reqFormData = new FormData();
				reqFormData.append('usr', this._LoginService.loginUser.user);
				reqFormData.append('token', this._LoginService.loginUser.token);
				reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
				reqFormData.append('entity', 'dynamicPropertyOptions');
				reqFormData.append('search', JSON.stringify([{ property_guid: dpData.guid }]));
				reqFormData.append('sort_dir', 'asc');
				reqFormData.append('sort_field', 'sequence');
				reqFormData.append('view_fields', JSON.stringify(['guid', 'value', 'label', 'bgcolor']));
				if (this.getPropertyOptionsSubscription) {
					this.getPropertyOptionsSubscription.unsubscribe();
				}
				this.listOfTaskTypes = [];
				// this._LoaderService.show();
				this.getPropertyOptionsSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
					next: (response) => {
						// this._LoaderService.hide();
						if (response && response.flag) {
							this.listOfTaskTypes = response.data || [];
							this.setTaskTypeHeaderFilter(response.data);
						}
					},
					error: (error) => {
					}
				});
			}
		}
	}

	// Method used to Set list of AssignTo for Header Filter.
	private setAssignToHeaderFilter(listOfArray: any[] = []) {
		const listOfHeaderFilter: any[] = [];
		listOfArray.forEach(assignTo => {
			listOfHeaderFilter.push({ value: assignTo.sub_account_id, text: assignTo.first_name + ' ' + assignTo.last_name });
		});
		this.listOfAssignToHeaderFilter = listOfHeaderFilter;
	}

	// Method used to Set list of Status(Task) for Header Filter.
	private setTaskStatusHeaderFilter(listOfArray: any[] = []) {
		const listOfHeaderFilter: any[] = [];
		listOfArray.forEach(status => {
			listOfHeaderFilter.push({ value: status.btnCode, text: status.btnName });
		});
		this.listOfTaskStatusHeaderFilter = listOfHeaderFilter;
	}

	// Method used to Set list of Task Type for Header Filter.
	private setTaskTypeHeaderFilter(listOfArray: any[] = []) {
		const listOfHeaderFilter: any[] = [];
		listOfArray.forEach(type => {
			listOfHeaderFilter.push({ value: type.value, text: type.label });
		});
		this.listOfTaskTypeHeaderFilter = listOfHeaderFilter;
	}
	// #region for Tasks related methods

	// Method used to Get List of top 5 task by descending order
	private fetchedListOfTasks() {
		// this.resourcesLoaded = true;
		this.listOfTaskActions = this._CrmsService.getActionsByTasks();
		this.getListOfAssignToUsers();
		this.fetchedListOfTaskStatuses();
		this.fetchedListOfTaskTypes();

		this.filterValue = [];
		const storedFilters = this._CrmsService.getStoredFilters();
		if (storedFilters === undefined) {
			const filterValue = [{ dataType: 'string', dataField: 'status', selectedFilterOperation: 'contains', filterValues: ['open'] }];
			this._CrmsService.setStoredFilters(filterValue);
		}
		this.applyStoredFilter();
		this.getTaskData();
	}

	private getTaskData() {
		const currentTab = "tasks";
		this.key = "guid";
		if (currentTab === CRMTabValEnum.TASKS) {
			this.entity = "tasks";
		}
		this.dxDataSource = AspNetData.createStore({
			key: this.key,
			loadUrl: environment.URL_DATAOUT + "?usr=" + this._LoginService.loginUser.user + "&token=" + this._LoginService.loginUser.token + "&method=dxGetEntityData&entity=" + this.entity,
			onBeforeSend: function (method, ajaxOptions) {
			}
		});
	}

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this._CrmsService.getStoredFilters();
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	// Method used to redirect user to Task Tab of Crm page
	public goToCrmTasks() {
		this._CrmsService.setCrmSelectedtab(CRMTabValEnum.TASKS);
		this._SidemenuService.setCurrentMenu(SideMenuForEnum.TASKS);
		this._CrmsService.setStoredFilters(undefined);
		this._Router.navigate(['adminportal', 'crms', 'taskslist']);
	}
	// Method used to Show Add New Task Dialog
	public addNewTask() {
		this.addeditTaskDetailsProps = {
			isEditMode: false,
			clientFor: CrmClientForEnum.LEADS,
			clientGuid: undefined,
			clientDetails: undefined,
			contactEmployeeGuid: undefined,
			currentEntity: NameFromServerKeyEnum.LEAD
		};
		this.doOpenAddEditTaskPopup();
	}

	// #region for Action Column for Tasks Tab

	// Method used to redirect user to Client Details page with active tab tasks
	public doCustomerDetails(element: any) {
		this._CrmsService.setClientGuid(element.guidclient);
		this._CrmsService.setContactEmployeeGuid(element.guidcontactperson);
		this._CrmsService.setContactDetailsSelectedtab(ContactDetailTabDataEnum.TASKS);
		if (element.contactfrom === NameFromServerKeyEnum.LEAD) {
			this._CrmsService.setClientFor(CrmClientForEnum.LEADS);
			this._CrmsService.setEntityFor(NameFromServerKeyEnum.LEAD);
			this._Router.navigate(['adminportal', 'crms', 'leadslist', 'leaddetails', 'contactdetails']);
		} else if (element.contactfrom === NameFromServerKeyEnum.PROSPECT) {
			this._CrmsService.setClientFor(CrmClientForEnum.PROSPECTS);
			this._CrmsService.setEntityFor(NameFromServerKeyEnum.PROSPECT);
			this._Router.navigate(['adminportal', 'crms', 'prospectslist', 'prospectdetails', 'contactdetails']);
		} else if (element.contactfrom === NameFromServerKeyEnum.CLIENT) {
			this._CrmsService.setEntityFor(NameFromServerKeyEnum.CLIENT);
			this._CrmsService.setClientFor(CrmClientForEnum.CLIENTS);
			this._Router.navigate(['adminportal', 'crms', 'clientslist', 'clientdetails', 'contactdetails']);
		}
	}

	public doTaskActions(e: any, element: any) {
		if (e && e.itemData) {
			switch (e.itemData.btnCode) {
				case ContactDetailActionBtnsValEnum.ADDTASK:
					this.doAddTaskDirectly(element);
					break;
				case ContactDetailActionBtnsValEnum.EDITTASK:
					this.doEditTask(element);
					break;
				case ContactDetailActionBtnsValEnum.DELETE:
					this.doDeleteTask(element);
					break;
				case ContactDetailActionBtnsValEnum.OPENTASKSTATUS:
					this.doMarkAsOpenTask(element);
					break;
				case ContactDetailActionBtnsValEnum.CLOSETASKSTATUS:
					this.doMarkAsCloseTask(element);
					break;
				default:
					break;
			}
		}
	}

	// Method used to Add Task from Task Tab
	private doAddTaskDirectly(element) {
		if (this.getClientDetailsSubscription) {
			this.getClientDetailsSubscription.unsubscribe();
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', element.contactfrom);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('search', JSON.stringify([{ guid: element.guidclient }]));
		this._LoaderService.show();
		this.getClientDetailsSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						const crmList = response.data || [];
						const itemClientIndex = crmList.findIndex(itemCrm => itemCrm.guid === element.guidclient);
						if (itemClientIndex !== -1) {
							const clientDetailsClone = JSON.parse(JSON.stringify(crmList[itemClientIndex]));
							const ceIndex = clientDetailsClone.contactemployees.findIndex(ce => ce.guid === element.guidcontactperson);
							this.addeditTaskDetailsProps = {
								isEditMode: false,
								clientFor: null,
								clientGuid: clientDetailsClone.guid,
								clientDetails: clientDetailsClone,
								contactEmployeeGuid: clientDetailsClone.contactemployees[ceIndex].guid,
								currentEntity: element.contactfrom
							};

							if (element.contactfrom === NameFromServerKeyEnum.LEAD) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.LEADS;
							}
							if (element.contactfrom === NameFromServerKeyEnum.PROSPECT) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.PROSPECTS;
							}
							if (element.contactfrom === NameFromServerKeyEnum.CLIENT) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.CLIENTS;
							}
							this.doOpenAddEditTaskPopup();
						} else {
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Edit Task from Task Tab
	public doEditTask(element) {
		if (this.isCellClickDetail) {
			return;
		}
		if (this.getClientDetailsSubscription) {
			this.getClientDetailsSubscription.unsubscribe();
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', element.contactfrom);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('search', JSON.stringify([{ guid: element.guidclient }]));
		this._LoaderService.show();
		this.getClientDetailsSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						const crmList = response.data || [];
						const itemClientIndex = crmList.findIndex(itemCrm => itemCrm.guid === element.guidclient);
						if (itemClientIndex !== -1) {
							const clientDetailsClone = JSON.parse(JSON.stringify(crmList[itemClientIndex]));
							const ceIndex = clientDetailsClone.contactemployees.findIndex(ce => ce.guid === element.guidcontactperson);
							this.addeditTaskDetailsProps = {
								isEditMode: true,
								clientFor: null,
								clientGuid: clientDetailsClone.guid,
								clientDetails: clientDetailsClone,
								contactEmployeeGuid: clientDetailsClone.contactemployees[ceIndex].guid,
								taskGuid: element.guid,
								currentEntity: element.contactfrom
							};

							if (element.contactfrom === NameFromServerKeyEnum.LEAD) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.LEADS;
							}
							if (element.contactfrom === NameFromServerKeyEnum.PROSPECT) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.PROSPECTS;
							}
							if (element.contactfrom === NameFromServerKeyEnum.CLIENT) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.CLIENTS;
							}
							this.doOpenAddEditTaskPopup();
						} else {
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Delete Task from Task Tab
	private doDeleteTask(element) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				if (this.getClientDetailsSubscription) {
					this.getClientDetailsSubscription.unsubscribe();
				}
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('entity', element.contactfrom);
				formData.append('method', ServerMethods.GET_ENTITY_DATA);
				formData.append('search', JSON.stringify([{ guid: element.guidclient }]));
				this._LoaderService.show();
				this.getClientDetailsSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: (response) => {
						this._LoaderService.hide();
						if (response) {
							if (response.flag) {
								const crmList = response.data || [];
								const itemClientIndex = crmList.findIndex(itemCrm => itemCrm.guid === element.guidclient);
								if (itemClientIndex !== -1) {
									const clientDetailsClone = JSON.parse(JSON.stringify(crmList[itemClientIndex]));
									const ceIndex = clientDetailsClone.contactemployees.findIndex(ce => ce.guid === element.guidcontactperson);
									if (!clientDetailsClone.contactemployees[ceIndex].timelines) {
										clientDetailsClone.contactemployees[ceIndex].timelines = [];
									}
									let timelinesInsertUpdate;
									let currentEntity: string;
									if (element.contactfrom === NameFromServerKeyEnum.LEAD) {
										currentEntity = NameFromServerKeyEnum.LEAD;
										timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Deleted', 'leadtasks', element.guid, 'Task ' + element.title + ' successfully deleted.');
									}

									if (element.contactfrom === NameFromServerKeyEnum.PROSPECT) {
										currentEntity = NameFromServerKeyEnum.PROSPECT;
										timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Deleted', 'prospecttasks', element.guid, 'Task ' + element.title + ' successfully deleted.');
									}

									if (element.contactfrom === NameFromServerKeyEnum.CLIENT) {
										currentEntity = NameFromServerKeyEnum.CLIENT;
										timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Deleted', 'clienttasks', element.guid, 'Task ' + element.title + ' successfully deleted.');
										clientDetailsClone.issynctoerp = false;
									}

									const taskIndex = clientDetailsClone.contactemployees[ceIndex].tasks.findIndex(task => task.guid === element.guid);
									clientDetailsClone.contactemployees[ceIndex].tasks.splice(taskIndex, 1);
									clientDetailsClone.contactemployees[ceIndex].timelines.push(timelinesInsertUpdate);
									clientDetailsClone.lastactivity = timelinesInsertUpdate;

									const contacts = [];
									const contentData = [];
									contentData.push(clientDetailsClone);
									contacts.push({
										action: 'edit',
										type: currentEntity,
										content: contentData
									});
									if (this.updateCrmSubscription) {
										this.updateCrmSubscription.unsubscribe();
									}
									this._LoaderService.show();
									this.updateCrmSubscription = this.updateClientData(contacts).subscribe(response => {
										this._LoaderService.hide();
										if (response) {
											if (response.flag) {
												this._ToastrService.success(response.message, "Success", { closeButton: true, tapToDismiss: true });
												this.fetchedListOfTasks();
											} else {
												this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
											}
										} else {
											this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
										}
									}, error => {
										this._LoaderService.hide();
										this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
									});
								} else {
									this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
								}
							} else {
								this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
							}
						} else {
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					},
					error: (error) => {
						this._LoaderService.hide();
						this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				});
			}
		});
	}

	// Method used to Mark as Open Task
	private doMarkAsOpenTask(element) {
		const latestStatus = TaskStatusValEnum.OPEN;
		if (this.getClientDetailsSubscription) {
			this.getClientDetailsSubscription.unsubscribe();
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', element.contactfrom);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('search', JSON.stringify([{ guid: element.guidclient }]));
		this._LoaderService.show();
		this.getClientDetailsSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						const crmList = response.data || [];
						const itemClientIndex = crmList.findIndex(itemCrm => itemCrm.guid === element.guidclient);
						if (itemClientIndex !== -1) {
							const clientDetailsClone = JSON.parse(JSON.stringify(crmList[itemClientIndex]));
							const ceIndex = clientDetailsClone.contactemployees.findIndex(ce => ce.guid === element.guidcontactperson);
							if (!clientDetailsClone.contactemployees[ceIndex].timelines) {
								clientDetailsClone.contactemployees[ceIndex].timelines = [];
							}
							let timelinesInsertUpdate;
							let currentEntity: string;
							if (element.contactfrom === NameFromServerKeyEnum.LEAD) {
								currentEntity = NameFromServerKeyEnum.LEAD;
								timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Status', 'leadtasks', element.guid, 'Task status ' + latestStatus + ' for ' + element.title + ' successfully updated.');
							}

							if (element.contactfrom === NameFromServerKeyEnum.PROSPECT) {
								currentEntity = NameFromServerKeyEnum.PROSPECT;
								timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Status', 'prospecttasks', 'Task status ' + latestStatus + ' for ' + element.title + ' successfully updated.');
							}

							if (element.contactfrom === NameFromServerKeyEnum.CLIENT) {
								currentEntity = NameFromServerKeyEnum.CLIENT;
								timelinesInsertUpdate = this._CrmsService.generateTimeline('Task Status', 'clienttasks', 'Task status ' + latestStatus + ' for ' + element.title + ' successfully updated.');
								clientDetailsClone.issynctoerp = false;
							}

							const taskIndex = clientDetailsClone.contactemployees[ceIndex].tasks.findIndex(task => task.guid === element.guid);
							clientDetailsClone.contactemployees[ceIndex].tasks[taskIndex].status = latestStatus;
							clientDetailsClone.contactemployees[ceIndex].timelines.push(timelinesInsertUpdate);
							clientDetailsClone.lastactivity = timelinesInsertUpdate;

							const contacts = [];
							const contentData = [];
							contentData.push(clientDetailsClone);
							contacts.push({
								action: 'edit',
								type: currentEntity,
								content: contentData
							});

							if (this.updateCrmSubscription) {
								this.updateCrmSubscription.unsubscribe();
							}
							this._LoaderService.show();
							this.updateCrmSubscription = this.updateClientData(contacts).subscribe(response => {
								this._LoaderService.hide();
								if (response) {
									if (response.flag) {
										this._ToastrService.success(response.message, "Success", { closeButton: true, tapToDismiss: true });
										this.fetchedListOfTasks();
									} else {
										this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
									}
								} else {
									this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
								}
							}, error => {
								this._LoaderService.hide();
								this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							});
						} else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Mark as Close Task
	private doMarkAsCloseTask(element) {
		if (this.getClientDetailsSubscription) {
			this.getClientDetailsSubscription.unsubscribe();
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', element.contactfrom);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('search', JSON.stringify([{ guid: element.guidclient }]));
		this._LoaderService.show();
		this.getClientDetailsSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						const crmList = response.data || [];
						const itemClientIndex = crmList.findIndex(itemCrm => itemCrm.guid === element.guidclient);
						if (itemClientIndex !== -1) {
							const clientDetailsClone = JSON.parse(JSON.stringify(crmList[itemClientIndex]));
							const ceIndex = clientDetailsClone.contactemployees.findIndex(ce => ce.guid === element.guidcontactperson);
							this.addeditTaskDetailsProps = {
								isEditMode: true,
								clientFor: null,
								clientGuid: clientDetailsClone.guid,
								clientDetails: clientDetailsClone,
								contactEmployeeGuid: clientDetailsClone.contactemployees[ceIndex].guid,
								taskGuid: element.guid,
								currentEntity: element.contactfrom,
								isAllReadOnlyForClosedTask: true
							};

							if (element.contactfrom === NameFromServerKeyEnum.LEAD) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.LEADS;
							}
							if (element.contactfrom === NameFromServerKeyEnum.PROSPECT) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.PROSPECTS;
							}
							if (element.contactfrom === NameFromServerKeyEnum.CLIENT) {
								this.addeditTaskDetailsProps.clientFor = CrmClientForEnum.CLIENTS;
							}
							this.doOpenAddEditTaskPopup();
						} else {
							this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to get Client Details
	private updateClientData(updatedClientData: any): Observable<any> {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contacts', JSON.stringify(updatedClientData));
		return this._RestApiService.doDataInFormDataReq(formData);
	}
	// #endregion

	// #region for Fetch AssignTo users
	// Method used to Fetched list of AssignTO Users
	private getListOfAssignToUsers() {
		let listOfUserTypes = [];
		try {
			listOfUserTypes = this._LoginService.loginUser.account_detail.userType || [];
		} catch (error) {
			listOfUserTypes = [];
		}
		const selectedListOfUserTypes = listOfUserTypes.filter(userType => userType.can_receive_incentives === true);

		if (selectedListOfUserTypes && selectedListOfUserTypes.length > 0) {
			const listOfRoles = [];
			selectedListOfUserTypes.forEach(userType => {
				listOfRoles.push(userType.user_type);
			});
			const listOfSearches = [];
			listOfSearches.push({ status: 'active' });
			listOfSearches.push({ role: listOfRoles });
			const reqFormData = new FormData();
			reqFormData.append('usr', this._LoginService.loginUser.user);
			reqFormData.append('token', this._LoginService.loginUser.token);
			reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
			reqFormData.append('entity', 'subusers');
			reqFormData.append('is_dropdown', 'true');
			reqFormData.append('search', JSON.stringify(listOfSearches));
			const viewFields = ['sub_account_id', 'first_name', 'last_name', 'role', 'status'];
			reqFormData.append('view_fields', JSON.stringify(viewFields));
			if (this.getListOfAssignToUserSubscription) {
				this.getListOfAssignToUserSubscription.unsubscribe();
			}
			this.listOfAssignToUsers = [];
			this.getListOfAssignToUserSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
				next: (response) => {
					if (response && response.flag) {
						this.listOfAssignToUsers = response.data || [];
						this.setAssignToHeaderFilter(response.data);
					}
				},
				error: (error) => {
				}
			});
		}
	}
	// #endregion
	// #endregion

	//#region Export excel and filter
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Tasks.xlsx';
		worksheet = workbook.addWorksheet('Tasks');


		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'enddate') {
						excelCell.value = gridCell.data.enddate;
						excelCell.value += '\n ' + gridCell.data.timeinterval;
					}
					if (gridCell.column.dataField === 'tasktype') {
						const taskTypeIndex = this.listOfTaskTypeHeaderFilter.findIndex(assign => assign.value === gridCell.value);
						if (taskTypeIndex !== -1) {
							excelCell.value = this.listOfTaskTypeHeaderFilter[taskTypeIndex].text;
						}
					}
					if (gridCell.column.dataField === 'status') {
						const statusIndex = this.listOfTaskStatusHeaderFilter.findIndex(assign => assign.value === gridCell.value);
						if (statusIndex !== -1) {
							excelCell.value = this.listOfTaskStatusHeaderFilter[statusIndex].text;
						}
					}
					if (gridCell.column.dataField === 'assignto') {
						const assignToIndex = this.listOfAssignToHeaderFilter.findIndex(assign => assign.value === gridCell.value);
						if (assignToIndex !== -1) {
							excelCell.value = this.listOfAssignToHeaderFilter[assignToIndex].text;
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doClearFilterBuilder(event) {
		this.dataGridContainer.instance.clearFilter();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}
	//#endregion

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		this.isCellClickDetail = false;
		if (e.column.dataField === "guid") {
			this.isCellClickDetail = true;
			return;
		}
	}
	public doGetTaskActions(element) {
		this.listOfTaskActions.forEach(t => {
			t.disabled = (element.status === TaskStatusValEnum.OPEN && t.btnCode === ContactDetailActionBtnsValEnum.OPENTASKSTATUS) || (element.status === TaskStatusValEnum.CLOSE && t.btnCode === ContactDetailActionBtnsValEnum.CLOSETASKSTATUS);
		});
	}

	// Method used to Open Task Dialog
	private doOpenAddEditTaskPopup() {
		this.isShowAddEditTaskPopup = false;
		setTimeout(() => {
			this.isShowAddEditTaskPopup = true;
		}, 300);
	}

	public doCloseAddEditTaskPopup(event: any) {
		if (event) {
			this.isShowAddEditTaskPopup = false;
		}
		this.fetchedListOfTasks();
	}
}
