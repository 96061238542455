<dx-popup [width]="500" [height]="500" #dxPopupRef [showTitle]="false" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [showCloseButton]="true" [(visible)]="isShowPopup" (onHidden)="doHiddenPopup($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>

	<div *dxTemplate="let data of 'content'" class="subscriptionUpg">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="imgUc">
				<img [src]="_AppCommonSrvc.cdnImgPath + 'payment_due.png'" alt="Payment Overdue">
			</div>
			<h3 class="ucTitle">Payment<span class="purple">Overdue</span></h3>
			<p class="center mrgB0">
				You have an invoice which is more than <span class="purple">5 days overdue.</span> You must pay it now.
			</p>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="center" cssClass="">
		<div *dxTemplate>
			<dx-button #payNowRef class="primaryBtn button" text="Pay Now" type="normal" stylingMode="contained"
				[focusStateEnabled]="true" [hoverStateEnabled]="true" tabIndex="0" (onClick)="doPayNow()"
				[useSubmitBehavior]="true">
			</dx-button>
		</div>
	</dxi-toolbar-item>

</dx-popup>