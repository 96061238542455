import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';

@Component({
	selector: 'app-active-customer-tiles',
	templateUrl: './active-customer-tiles.component.html',
	styleUrls: ['./active-customer-tiles.component.css']
})

export class ActiveCustomerTilesComponent implements OnInit, OnDestroy {
	public activeCustomers: number;
	public resourcesLoaded: boolean = false;
	private getActiveCustomerSbsn: Subscription;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService
	) {

	}

	ngOnInit() {
		this.getActiveCustomer();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_CLIENT_TILES_EVENT', response => {
			if (response) {
				this.getActiveCustomer();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getActiveCustomerSbsn) {
			this.getActiveCustomerSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_CLIENT_TILES_EVENT');
	}

	// Method used to Get Active Customer
	private getActiveCustomer() {
		this.resourcesLoaded = true;
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.ACTIVE_CUSTOMERS_TILE);
		if (this.getActiveCustomerSbsn) {
			this.getActiveCustomerSbsn.unsubscribe();
		}
		this.getActiveCustomerSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this.resourcesLoaded = false;
			if (response.flag) {
				if (response.data.tiles) {
					this.activeCustomers = response.data.tiles.active_clients || 0;
				}
			}
		}, error => {
			this.resourcesLoaded = false;
		});
	}

	// Method used to Click to redirect on appropriate page
	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
	}
}
