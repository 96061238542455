import { Component, Inject, OnInit, ViewChild, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerMethods } from '@app/constants-enums/constants';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-sales-rep-list-dialog',
	templateUrl: './sales-rep-list-dialog.component.html',
	styleUrls: ['./sales-rep-list-dialog.component.css']
})
export class SalesRepListDialogComponent implements OnInit, OnChanges, OnDestroy {
	@Input() multipleContractDetailsProps: any;
	@Output() closeMultipleContractPopup = new EventEmitter<any>();
	@ViewChild('listOfSalesPersonDataGrid', {}) listOfSalesPersonDataGrid: DxDataGridComponent;
	public salesPersonDataSource: any;
	public salespersonGridBoxColumns: any
	public contractTemplateGuid: string;
	public subUserSetUpReqSubscription: Subscription;
	public startDate: any;
	public endDate: any;
	public userList: any = [];
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowSalesPersonListPopup = true;
	public confirmBtnOption: any;
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _IxDxAlertService: IxDxAlertService,
		private _ToastrService: ToastrService) {
	}

	ngOnInit() {
		this.isShowSalesPersonListPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;

		this.salespersonGridBoxColumns = [
			{ dataField: 'sub_account_id', caption: 'sub_account_id', visible: false },
			{ dataField: 'first_name', caption: 'First Name' },
			{ dataField: 'last_name', caption: 'Last Name' },
			{ dataField: 'role_name', caption: 'Role Name' },
		];
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges.multipleContractDetailsProps) {
			const multipleContractDetails = _SimpleChanges.multipleContractDetailsProps.currentValue;
			this.salesPersonDataSource = multipleContractDetails.salesPersonDataSource || [];
			this.contractTemplateGuid = multipleContractDetails.contractTemplateGuid;
		}
	}

	ngOnDestroy(): void {
		if (this.subUserSetUpReqSubscription) {
			this.subUserSetUpReqSubscription.unsubscribe();
		}
	}

	public doClosePopup() {
		this.isShowSalesPersonListPopup = false;
		this.closeMultipleContractPopup.emit(true);
	}

	public doClickSaveSalesPerson() {

		let selectedSalesPersonList = this.listOfSalesPersonDataGrid ? this.listOfSalesPersonDataGrid.instance.getSelectedRowsData() : [];

		if (selectedSalesPersonList && selectedSalesPersonList.length > 0) {
			this.userList = [];
			selectedSalesPersonList.forEach(item => {
				this.userList.push(item.sub_account_id);
			});
			this.createMultiTemplateContract();
		} else {
			this._ToastrService.info('Choose at least one Sales Person from list', 'Info', { closeButton: true, tapToDismiss: true });
		}
	}

	compareTwoDates() {
		// check start date and end date validate
		if (new Date(this.endDate) < new Date(this.startDate)) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
		}
	}

	public createMultiTemplateContract() {
		const formData = new FormData();
		const startDate = moment(this.startDate).format('YYYY-MM-DD');
		const endDate = moment(this.endDate).format('YYYY-MM-DD');
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.CREATE_CONTRACT_FOR_USERS);
		formData.append('templateGuid', this.contractTemplateGuid);
		formData.append('userList', JSON.stringify(this.userList));
		formData.append('startDate', startDate);
		formData.append('endDate', endDate);

		this._LoaderService.show();
		this.subUserSetUpReqSubscription = this._RestApiService.doSubUserSetUpReqFormData((formData)).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					//this.UpdateSettlementPeriodClick();
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
					this.doClosePopup();
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

}
