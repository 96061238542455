import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ServerMethods, ServerEntity, ServerSections, MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { RoyaltiesService } from '@app/services/contract-service/royalties/royalties.service';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { LoginService } from '@app/services/login.service';
import { Subscription } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ErpTypeValEnum, RoyaltiesSettlementReportSectionEnum } from "@app/constants-enums/enums";
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { ARcreditMemos, ExtraCommissionBasedRules, Invoices, MiscellaneousDeductionRules } from '@app/models/royalties-contract.model';
import { PageOptions } from '@app/models/common.model';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
declare const ExcelJS: ExcelJS;
import { Guid } from '@app/models/guid';
import { ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
@Component({
	selector: 'app-royalties-clculation-view',
	templateUrl: './royalties-clculation-view.component.html',
	styleUrls: ['./royalties-clculation-view.component.css'],
	providers: [
		CurrencyPipe,
		CustomCurrencyPipe,
		CustomNumberNoRoundingPipe
	]
})
export class RoyaltiesClculationViewComponent implements OnInit {

	public invoiceDetails: any;
	@ViewChild('invoicesDataGrid') invoicesDataGrid: DxDataGridComponent;
	@ViewChild('extraRoyaltiesBasedRulesDataGrid') extraRoyaltiesBasedRulesDataGrid: DxDataGridComponent;
	@ViewChild('arcreditmemosDataGrid') arcreditmemosDataGrid: DxDataGridComponent;
	public pageOptions: PageOptions = new PageOptions();
	public apInvoice: any;
	private dataOutReqSubscription: Subscription;
	public invoicesDataSource: {};
	public extraRoyaltiesBasedRulesDataSource: {};
	public arcreditmemosDataSource: {};
	public invoicesSource: Invoices[];
	public extraRoyaltiesBasedRulesSource: ExtraCommissionBasedRules[];
	public arcreditmemosSource: ARcreditMemos[];
	public mainDataSource: any[] = [];
	public settlementPeriodStartDate: any;
	public settlementPeriodEndDate: any;
	public isQBOnlineErpUser: boolean = false;
	public globalCurrency: any;
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'order', text: 'Order' }];
	public enable_classification_for_commision: boolean = false;
	public enable_invoice_custom_fields_feature: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	private erpType: string;
	public RoyaltiesSettlementReportSectionEnum = RoyaltiesSettlementReportSectionEnum;
	private guid = new Guid();
	private getUomLstSbsn: Subscription;
	private updateUserSettingSbsn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];

	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public listOfRoyaltyBasis: any[] = [];
	public listOfRoyaltyBasisHeaderFilter: any[] = [];
	private doDownloadPdfSbsn: Subscription;
	@ViewChild('royaltyReportDetailsDatagridContainer') royaltyReportDetailsDatagridContainer: DxDataGridComponent;
	public decimalPointPercentageFormat: string;
	public isEnableAmortisePaymentsFeature: boolean;
	public amortizedPaymentsDataSource: {};
	@ViewChild('amortizedPaymentsDataGrid') amortizedPaymentsDataGrid: DxDataGridComponent;
	public decimalPointForCurrency: number;
	public globalCurrencySymbol: string;
	public viewInvoiceDetailProps: any;
	public isShowInvoiceDetailPopup: boolean;

	constructor(private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _RoyaltiesService: RoyaltiesService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _DatePipe: DatePipe,
		private _AppCommonSrvc: AppCommonSrvc,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService) {
	}

	ngOnInit() {
		// get global currency
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.invoiceDetails = this._RoyaltiesService.getInvoiceDetails();
		this.settlementPeriodStartDate = this._DatePipe.transform(this.invoiceDetails.settlementPeriodStart, 'yyyy-MM-dd');
		this.settlementPeriodEndDate = this._DatePipe.transform(this.invoiceDetails.settlementPeriodEnd, 'yyyy-MM-dd');
		// check Is QB User (no need to view code of QB user)
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erpType = '';
		}

		this.isQBOnlineErpUser = false;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}

		try {
			this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision || false;
			this.enable_invoice_custom_fields_feature = this._LoginService.loginUser.account_detail.enable_invoice_custom_fields_feature || false;
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;
			this.isEnableAmortisePaymentsFeature = this._LoginService.loginUser.account_detail.enable_amortise_payments;
		} catch (e) {
			this.enable_classification_for_commision = false;
			this.enable_invoice_custom_fields_feature = false;
			this.enable_profit_on_document_header = false;
			this.isEnableAmortisePaymentsFeature = false;
		}

		setTimeout(() => {
			this.setMainDataSource();
			this.setCaptionMainDatagrid();
		}, 100);
		this.fetchListOfUoms();
		this.listOfTierBasis = this._RoyaltiesService.getTierBasis();
		this.listOfTierBasisHeaderFilter = this.getHeaderFilter(this.listOfTierBasis, 'code', 'label');
		this.listOfRoyaltyBasis = this._RoyaltiesService.getRoyaltiesBasis();
		this.listOfRoyaltyBasisHeaderFilter = this.getHeaderFilter(this.listOfRoyaltyBasis, 'code', 'label');
		this.loadInvoicesDataSource();
		this.loadExtraRoyaltiesBasedRulesDataSource();
		// this.loadArcreditmemosSourceDataSource();
		this.loadAmortizedPaymentsDataSource();
	}
	// tslint:disable-next-line:use-life-cycle-interface
	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.doDownloadPdfSbsn) {
			this.doDownloadPdfSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	private setCaptionMainDatagrid() {
		const settlementPeriodStart = this._DatePipe.transform(this.invoiceDetails.settlementPeriodStart, 'yyyy-MM-dd');
		const settlementPeriodEnd = this._DatePipe.transform(this.invoiceDetails.settlementPeriodEnd, 'yyyy-MM-dd');
		let captionStr = '';
		if (this.invoiceDetails.apInvoiceCreatedInERP !== 'Open') {
			captionStr = 'Settlement No. : ' + this.invoiceDetails.ixcode;
			captionStr += ' | Settlement Period : ';
			captionStr += settlementPeriodStart + ' To ' + this.invoiceDetails.settlementPeriodEnd;
		} else {
			captionStr = captionStr + 'Settlement Period : ';
			captionStr += settlementPeriodStart + ' To ' + this.invoiceDetails.settlementPeriodEnd;
		}
		setTimeout(() => {
			this.royaltyReportDetailsDatagridContainer.instance.columnOption('name', 'caption', captionStr);
		}, 350);
	}

	fullNameColumn_calculateCellValue(rowData) {
		const length = rowData.fiscal_sequence.toString().length;
		let fiscal_sequence = rowData.fiscal_sequence;
		if (length === 1) {
			fiscal_sequence = '0' + rowData.fiscal_sequence;
		}
		return fiscal_sequence + '  ' + rowData.monthYear;
	}

	setMainDataSource() {
		let lstMainSection: any = this._RoyaltiesService.getRoyaltiesContractSettlementReportSection();
		this.mainDataSource = lstMainSection.filter(i => i.parent_section_level === 0) || []; // load only base level section

		if (!this.isEnableAmortisePaymentsFeature) {
			const indexAmortisePayment = this.mainDataSource.findIndex(i => i.key === 'amortized_payments');
			this.mainDataSource.splice(indexAmortisePayment, 1);
		}
	}

	checkMasterDetails(objMasterDetails, key) {
		if (objMasterDetails.data) {
			if (objMasterDetails.data.key === key) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public onExporting(e) {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let invoicesSheet, extraRoyaltiesBasedRulesSheet, arCreditMemosSheet, amortizedPaymentsSheet;

		if (context.invoicesDataGrid && context.invoicesDataGrid.instance) {
			invoicesSheet = workbook.addWorksheet('Invoice');
		}
		if (context.extraRoyaltiesBasedRulesDataGrid && context.extraRoyaltiesBasedRulesDataGrid.instance) {
			extraRoyaltiesBasedRulesSheet = workbook.addWorksheet('Additional incentives');
		}
		if (context.arcreditmemosDataGrid && context.arcreditmemosDataGrid.instance) {
			arCreditMemosSheet = workbook.addWorksheet('AR Credit Memo');
		}

		if (context.amortizedPaymentsDataGrid && context.amortizedPaymentsDataGrid.instance && context.isEnableAmortisePaymentsFeature) {
			amortizedPaymentsSheet = workbook.addWorksheet('Amortized Payments');
		}

		let sheetName = '';
		const selectVendor = this.invoiceDetails.selectVendor || '';
		const apInvoiceNumber = this.invoiceDetails.apInvoiceNumber || '';
		let fileName = '';
		if (apInvoiceNumber) {
			fileName = apInvoiceNumber + '_';
		}
		if (selectVendor) {
			fileName = selectVendor + '_';
		}
		fileName = fileName + 'Royalties_Calculation.xlsx';
		if (this.invoiceDetails.apInvoiceNumber) {
			sheetName = this.invoiceDetails.apInvoiceNumber;
			fileName = this.invoiceDetails.apInvoiceNumber + '_' + fileName;
		}
		if (this.invoiceDetails.selectVendor) {
			sheetName = sheetName + '-' + this.invoiceDetails.selectVendor;
		}
		// const worksheet = workbook.addWorksheet(sheetName);
		const listOfItemMasterDetailsRows = [];
		if (context.invoicesDataGrid && context.invoicesDataGrid.instance) {
			exportDataGrid({
				worksheet: invoicesSheet,
				component: context.invoicesDataGrid.instance,
				autoFilterEnabled: false,
				keepColumnWidths: true,
				customizeCell: (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'commissionAmount') {
							gridCell.data.rowIndex = excelCell.fullAddress.row + 1;
							if (gridCell.data['items']) {
								gridCell.data.data = gridCell.data.items;
								for (let i = 0; i < gridCell.data.items.length; i++) {
									gridCell.data.items[i].data = {};
									gridCell.data.items[i].rowIndex = excelCell.fullAddress.row + 1;
									gridCell.data.items[i].data.invoices = gridCell.data.items[i].invoices;
								}
							}
							listOfItemMasterDetailsRows.push({
								rowIndex: excelCell.fullAddress.row + 1,
								data: gridCell.data
							});
							// listOfInvoiceMasterDetailsRows.push({
							// 	rowIndex: excelCell.fullAddress.row + 1,
							// 	data: gridCell.data.items
							// });
							excelCell.value = '';
							if (gridCell.value) {
								excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionAmount);
								// excelCell.value = parseFloat(gridCell.value.toString()).toFixed(2);
							}
						}
					}
				}
			}).then((cellRange) => {
				// Logic for Set headers
				const headerRow = invoicesSheet.getRow(2);
				headerRow.height = 30;
				invoicesSheet.mergeCells(2, 2, 2, 9);
				let secondCellValue = this.invoiceDetails.name;
				headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14 };
				headerRow.getCell(1).alignment = { horizontal: 'left' };
				headerRow.getCell(2).value = secondCellValue;
				headerRow.getCell(2).font = { name: 'Segoe UI Light', size: 14 };
				headerRow.getCell(2).alignment = { horizontal: 'left' };

				invoicesSheet.mergeCells(4, 2, 4, 9);
				const headerRowForSettlementPeriod = invoicesSheet.getRow(4);
				// headerRowForSettlementPeriod.height = 20;
				headerRowForSettlementPeriod.getCell(1).value = 'Settlement Period ';
				headerRowForSettlementPeriod.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
				headerRowForSettlementPeriod.getCell(1).alignment = { horizontal: 'left' };
				headerRowForSettlementPeriod.getCell(2).value = this.invoiceDetails.settlementPeriodStart + ' To ' + this.invoiceDetails.settlementPeriodEnd;
				headerRowForSettlementPeriod.getCell(2).font = { name: 'Segoe UI Light', size: 12 };
				headerRowForSettlementPeriod.getCell(2).alignment = { horizontal: 'left' };


				// Logic for Invoice Details rows
				const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
				let offset = 0;

				const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
					const currentIndex = index + rowOffset;
					const row = invoicesSheet.insertRow(currentIndex, [], "n");
					for (let j = invoicesSheet.rowCount + 1; j > currentIndex; j--) {
						invoicesSheet.getRow(j).outlineLevel = invoicesSheet.getRow(j - 1).outlineLevel;
					}
					row.outlineLevel = outlineLevel;
					return row;
				};

				let itemColumnsField = [
					"document_type",
					"invoiceCode",
					"uomcode",
					"quantity",
					"linetotal"
				];
				let itemColumns = [
					"Doc. Type",
					"Doc. No",
					"Doc. UOM",
					"Doc. Qty",
					"Line Total ($)"
				];
				let isCommissionBasisDollarPerUom = false;
				let isCommissionBasisPercentage = false;
				for (let i = 0; i < listOfItemMasterDetailsRows.length; i++) {

					let isQBOnlineErpUser = this.isQBOnlineErpUser;
					itemColumnsField = [
						"itemname",
						"description",
						"commissionAmount"
					];
					itemColumns = [
						"Item",
						"Description",
						"Royalty"
					];
					if (isQBOnlineErpUser) {
						itemColumnsField = [
							"itemsku",
							"itemname",
							"description",
							"commissionAmount"
						];
						itemColumns = [
							"SKU",
							"Item",
							"Description",
							"Royalty"
						];
					}

					const columnIndex = cellRange.from.column;
					let row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
					// row.height = 24;
					// invoicesSheet.mergeCells(row.number, columnIndex, row.number, 7);
					if (listOfItemMasterDetailsRows[i].data.items && listOfItemMasterDetailsRows[i].data.items.length > 0) {
						row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
						itemColumns.forEach((columnName, currentColumnIndex) => {
							row.keepColumnWidths = true;
							Object.assign(row.getCell(columnIndex + currentColumnIndex), {
								value: columnName,
								fill: {
									type: "pattern",
									pattern: "solid",
									fgColor: { argb: "BEDFE6" }
								},
								font: { bold: true },
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								}
							});
						});

						let subTotalLineTotal = 0;
						listOfItemMasterDetailsRows[i].data.items.forEach((itemDetails, itemIndex) => {
							const itemColumnIndexDetail = cellRange.from.column;
							row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
							row.keepColumnWidths = true;
							itemColumnsField.forEach((columnName, currentColumnIndex) => {
								const cellObject = {
									value: itemDetails[columnName],
									fill: {
										type: "pattern",
										pattern: "solid",
										fgColor: { argb: "BEDFE6" }
									},
									border: {
										bottom: borderStyle,
										left: borderStyle,
										right: borderStyle,
										top: borderStyle
									},
									alignment: { horizontal: 'left' }
								};

								Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
								row.keepColumnWidths = true;
							});
							row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
							row.keepColumnWidths = true;
							//#region invoice code
							if (listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices && listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.length > 0) {
								isCommissionBasisDollarPerUom = false;
								isCommissionBasisPercentage = false;
								if (listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.commission_basis === 'dollarperuom') {
									isCommissionBasisDollarPerUom = true;
								}
								if (listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.commission_basis === 'percentage') {
									isCommissionBasisPercentage = true;
								}
								let invoiceColumnsField = [
									"document_type",
									"invoiceCode",
									"uomcode",
									"quantity",
									"tier_basis",
									"commission_basis",
									"commissionRate",
									"condition",
									"criterion",
									"linetotal",
									"miscDedAmount",
									"miscdeductioninventory",
									"cashdiscount",
									"finalAmount",
									"commissionAmount"
								];
								let invoiceColumns = [
									"Doc. Type",
									"Doc. No",
									"Doc. UOM",
									"Doc. Qty",
									"Royalty Type",
									"Royalty Basis",
									"Royalty Rate (% | $)",
									"Condition",
									"Criterion",
									"Line Total ($)",
									"Misc. Deduction Amount ($)",
									"Misc. Deduction Percentage (%)",
									"Cash Discount($)",
									"Final Line Total ($)",
									"Royalty"
								];
								if (this.invoiceDetails.calculationOnBasis === 'grossprofit') {
									invoiceColumnsField = [
										"document_type",
										"invoiceCode",
										"uomcode",
										"quantity",
										"tier_basis",
										"commission_basis",
										"commissionRate",
										"condition",
										"criterion",
										"linetotal",
										"miscDedAmount",
										"miscdeductioninventory",
										"cashdiscount",
										"finalAmount",
										"costtotal",
										"grossprofit",
										"commissionAmount"
									];
									invoiceColumns = [
										"Doc. Type",
										"Doc. No",
										"Doc. UOM",
										"Doc. Qty",
										"Royalty Type",
										"Royalty Basis",
										"Royalty Rate (% | $)",
										"Condition",
										"Criterion",
										"Line Total ($)",
										"Misc. Deduction Amount ($)",
										"Misc. Deduction Percentage (%)",
										"Cash Discount($)",
										"Final Line Total ($)",
										"Cost ($)",
										"Profit ($)",
										"Royalty"
									];
								}
								if (isCommissionBasisDollarPerUom) {
									invoiceColumnsField = [
										"document_type",
										"invoiceCode",
										"uomcode",
										"quantity",
										'contract_selectedUom',
										'contract_quantity',
										"linetotal"
									];
									invoiceColumns = [
										"Doc. Type",
										"Doc. No",
										"DOc. UOM",
										"Doc. Qty",
										"Contract UOM",
										"Contract Qty",
										"Line Total ($)"
									];
								}
								if (isCommissionBasisPercentage) {
									invoiceColumnsField = [
										"document_type",
										"invoiceCode",
										"uomcode",
										"quantity",
										"linetotal",
										"cashdiscount",
										"miscdeductioninventory",
										"miscDedAmount",
										"finalAmount"
									];
									invoiceColumns = [
										"Doc. Type",
										"Doc. No",
										"Doc. UOM",
										"Doc. Qty",
										"Line Total ($)",
										"Cash Discount ($)",
										"Misc. Deduction (%)",
										"Misc. Deduction Amount ($)",
										"Final Line Total ($)"
									];
								}

								let row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 3);

								invoiceColumns.forEach((columnName, currentColumnIndex) => {
									row.keepColumnWidths = true;
									Object.assign(row.getCell(itemColumnIndexDetail + currentColumnIndex), {
										value: columnName,
										fill: {
											type: "pattern",
											pattern: "solid",
											fgColor: { argb: "BEDFE6" }
										},
										font: { bold: true },
										border: {
											bottom: borderStyle,
											left: borderStyle,
											right: borderStyle,
											top: borderStyle
										}
									});
								});

								let subTotalLineTotal = 0;
								listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.forEach((invoiceDetails, invoiceIndex) => {
									row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 3);
									row.keepColumnWidths = true;
									invoiceColumnsField.forEach((columnName, currentColumnIndex) => {
										let valField = invoiceDetails[columnName];
										if (columnName === 'document_type') {
											const indexDT = this.listOfDocTypeHeaderFilter.findIndex(i => i.value === valField);
											if (indexDT !== -1) {
												valField = this.listOfDocTypeHeaderFilter[indexDT].text;
											}
										}
										if (columnName === 'uomcode') {
											const indexDT = this.listOfUoms.findIndex(i => i.code === valField);
											if (indexDT !== -1) {
												valField = this.listOfUoms[indexDT].name;
											}
										}
										if (columnName === 'tier_basis') {
											const indexTB = this.listOfTierBasis.findIndex(i => i.code === valField);
											if (indexTB !== -1) {
												valField = this.listOfTierBasis[indexTB].label;
											}
										}
										if (columnName === 'commission_basis') {
											const indexCB = this.listOfRoyaltyBasis.findIndex(i => i.code === valField);
											if (indexCB !== -1) {
												valField = this.listOfRoyaltyBasis[indexCB].label;
											}
										}
										if (columnName === 'miscdeductioninventory') {
											valField = valField + '%';
										}

										const cellObject = {
											value: valField,
											fill: {
												type: "pattern",
												pattern: "solid",
												fgColor: { argb: "BEDFE6" }
											},
											border: {
												bottom: borderStyle,
												left: borderStyle,
												right: borderStyle,
												top: borderStyle
											},
											alignment: { horizontal: 'left' }
										};

										Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
										row.keepColumnWidths = true;
									});
								});
								row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
								let rowLastCell: number;
								row.keepColumnWidths = true;
							}
							//#endregion invoice code
						});

					}
					row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;
					row.keepColumnWidths = true;
					offset--;
				}
			}).then(function () {
				if (context.extraRoyaltiesBasedRulesDataGrid && context.extraRoyaltiesBasedRulesDataGrid.instance) {
					return exportDataGrid({
						worksheet: extraRoyaltiesBasedRulesSheet,
						component: context.extraRoyaltiesBasedRulesDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'commissionAmount' || gridCell.column.dataField === 'linetotal') {
									excelCell.value = '';
									if (gridCell.value) {
										excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);
									}
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.amortizedPaymentsDataGrid && context.amortizedPaymentsDataGrid.instance && context.isEnableAmortisePaymentsFeature) {
					return exportDataGrid({
						worksheet: amortizedPaymentsSheet,
						component: context.amortizedPaymentsDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'total_commission' || gridCell.column.dataField === 'payment_amount') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				// https://github.com/exceljs/exceljs#writing-xlsx

				workbook.xlsx.writeBuffer().then(function (buffer) {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
				});
			});
			e.cancel = true;
		} else {
			this._ToastrService.info('Invoice data not found !', 'info', { closeButton: true, tapToDismiss: true });
		}
	}

	private loadInvoicesDataSource() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'invoices',
			contracttype: 'royaltiescontracts'
		}
		this.invoicesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	private loadExtraRoyaltiesBasedRulesDataSource() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'extraCommissionBasedRules',
			contracttype: 'royaltiescontracts'
		};
		this.extraRoyaltiesBasedRulesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	private loadArcreditmemosSourceDataSource() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'arcreditmemosSource',
			contracttype: 'royaltiescontracts'
		};
		this.arcreditmemosDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	private loadAmortizedPaymentsDataSource() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'amortizedPayments',
			contracttype: 'royaltiescontracts'
		};
		this.amortizedPaymentsDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public doLoadInvoiceState = () => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.INVOICES;
		const parentSectionLevelKey = '';
		return this._RoyaltiesService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveInvoiceState = (e) => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.INVOICES;
		const parentSectionLevelKey = '';
		const sLeval = this._RoyaltiesService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadInvoicItemState = () => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.ITEMS;
		const parentSectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.CUSTOMERS;
		return this._RoyaltiesService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveInvoiceItemState = (e) => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.ITEMS;
		const parentSectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.CUSTOMERS;
		const sLeval = this._RoyaltiesService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadInvoicItemInvoiceState = () => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.INVOICES;
		const parentSectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.ITEMS;
		return this._RoyaltiesService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveInvoiceItemInvoiceState = (e) => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.INVOICES;
		const parentSectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.ITEMS;
		const sLeval = this._RoyaltiesService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadExtraRoyaltiesBasedRuleState = () => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES;
		const parentSectionLevelKey = '';
		return this._RoyaltiesService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveExtraRoyaltiesBasedRuleState = (e) => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES;
		const parentSectionLevelKey = '';
		const sLeval = this._RoyaltiesService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadArCreditMemoState = () => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS;
		const parentSectionLevelKey = '';
		return this._RoyaltiesService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveArCreditMemoState = (e) => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS;
		const parentSectionLevelKey = '';
		const sLeval = this._RoyaltiesService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadAmortizedPaymentsState = () => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS;
		const parentSectionLevelKey = '';
		return this._RoyaltiesService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveAmortizedPaymentsState = (e) => {
		const sectionLevelKey = this.RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS;
		const parentSectionLevelKey = '';
		const sLeval = this._RoyaltiesService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	//comman method for all grid object for column chooser button above grid
	public doToolbarColumnChooserBtnPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		let addRowButton = toolbarItems.find(x => x.name === "columnChooserButton");
		addRowButton.options.text = "Column Chooser";
		addRowButton.showText = "always";
	}

	public doInvoiceDetailsCellClick(event: any) {
		if (event.row && event.row.rowType === 'data') {
			if (event.data.hasOwnProperty(event.column.dataField) && event.data[event.column.dataField] !== 0 && (event.column.dataField === 'invoiceCode' || event.column.dataField === 'docnum')) {
				this.viewInvoiceDetailProps = new ViewInvoiceDetailPopUpModel();
				this.viewInvoiceDetailProps.docNo = event.data[event.column.dataField];
				this.viewInvoiceDetailProps.docType = 'invoice';
				this.viewInvoiceDetailProps.docDate = event.data.hasOwnProperty('docdate') ? event.data['docdate'] : '';
				this.doOpenInvoiceDetails();
			}
		}
	}

	private doOpenInvoiceDetails() {
		this.isShowInvoiceDetailPopup = false;
		setTimeout(() => {
			this.isShowInvoiceDetailPopup = true;
		}, 200);
	}

	//Save State Data
	public saveStateData(sLeval, e) {
		let royaltiesSettlementReportConfig: any = {};
		try {
			royaltiesSettlementReportConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.ROYALTIES_SETTLEMENT_REPORT_SETTING)));
		} catch (e) {
			royaltiesSettlementReportConfig = {};
		}

		if (!royaltiesSettlementReportConfig.configs) {
			royaltiesSettlementReportConfig.configs = [];
		}

		const configIndex = royaltiesSettlementReportConfig.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
		if (configIndex !== -1) {
			royaltiesSettlementReportConfig.configs[configIndex].state_persistent = e;
		} else {
			const statePersistent = {
				guid: this.guid.newGuid(),
				section_level: sLeval.section_level,
				parent_section_level: sLeval.parent_section_level,
				state_persistent: e
			};
			royaltiesSettlementReportConfig.configs.push(statePersistent);
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.ROYALTIES_SETTLEMENT_REPORT_SETTING);
		formData.append('user_settings', JSON.stringify(royaltiesSettlementReportConfig));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[ServerSections.ROYALTIES_SETTLEMENT_REPORT_SETTING] = royaltiesSettlementReportConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.ROYALTIES_SETTLEMENT_REPORT_SETTING, royaltiesSettlementReportConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.ROYALTIES_SETTLEMENT_REPORT_SETTING, royaltiesSettlementReportConfig);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to display zero if negative summary value
	public doCustomizeTotalSummary(e) {
		if (e.value < 0) {
			return 'Royalty Total: $0.00';
		} else {
			return e.valueText;
		}
	}

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.UOM);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			if (response && response.flag) {
				this.listOfUoms = response.data;
				this.listOfUomsHeaderFilter = this.getHeaderFilter(response.data, 'code', 'name');
			}
		}, error => {
		});
	}

	private getHeaderFilter(listOfArray: any[], codeField: string, textField: string): any[] {
		const listOfHeaderFilters = [];
		listOfArray.forEach(ele => {
			listOfHeaderFilters.push({ value: ele[codeField], text: ele[textField].toString().toUpperCase() });
		});
		return listOfHeaderFilters;
	}

	public doExporToPDF() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DOWNLOAD_CONTRACT_STATEMENT);
		formData.append('guid', this.invoiceDetails.guid);
		formData.append('contracttype', 'royaltiescontracts');

		if (this.doDownloadPdfSbsn) {
			this.doDownloadPdfSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.doDownloadPdfSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response && response.flag) {
				const fileLink = response.data.file_link || '';
				if (fileLink) {
					const iframe = '<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="' + fileLink + '" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>';

					let win: any = window.open('', '_blank', 'toolbar=no,location=no,menubar=no,resizable=yes,height=720,width=1080,scrollbars=yes,status=yes');
					win.document.write(iframe);
				}
			}
		}, error => {
			this._LoaderService.hide();
		});
	}
}
