import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { RoyaltiesService } from '@app/services/contract-service/royalties/royalties.service';
import { LoginService } from '@app/services/login.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { PageOptions } from '@app/models/common.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { Observable, Subscription } from 'rxjs';
import { RoyaltiesContractModelRequest, RoyaltiesContractModelUI } from '@app/models/royalties-contract.model';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import * as moment from 'moment';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
@Component({
	selector: 'app-royalties-close-settlement-list-tab',
	templateUrl: './royalties-close-settlement-list-tab.component.html',
	styleUrls: ['./royalties-close-settlement-list-tab.component.css']
})
export class RoyaltiesCloseSettlementListTabComponent implements OnInit, OnDestroy {

	@Input() royaltiesContractModelUI = new RoyaltiesContractModelUI();
	@Input() salesPplList: any = [];
	@Input() isAllCustomerSelectedView: boolean = false;
	@Input() isAllProductSelectedView: boolean = false;
	@Input() customerTabList: any = [];
	@Input() productOrGroupTabList: any = [];
	@Input() exclusionTabList: any = [];
	@Input() ruleTabList: any[] = [];
	@Input() royaltiesContractModelRequest = new RoyaltiesContractModelRequest();
	@Input() isCurrentTabPermission: string;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	private dataOutReqSubscription: Subscription;
	public pageOptions: PageOptions = new PageOptions();
	public decimalPointForCurrency: number;
	public closeSettlementDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	private doDownloadPdfSbsn: Subscription;

	constructor(private _Router: Router,
		private _LoginService: LoginService,
		private _RoyaltiesService: RoyaltiesService,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService) {

	}


	ngOnInit() {
		this.doGetSettlementPeriodList();
		this.preInIt();
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.doDownloadPdfSbsn) {
			this.doDownloadPdfSbsn.unsubscribe();
		}
	}


	// Method used to Init Tab and load data.
	private preInIt() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
	}

	doGetSettlementPeriodList() {
		const editRoyaltiesGuid = this._RoyaltiesService.getEditRoyaltiesGuid();
		const viewFields = ['ixcode', 'number', 'contractName', 'settlementPeriodStart', 'settlementPeriodEnd', 'subusername', 'settlementValue', 'apInvoiceCreatedInERP'];

		// queryString = queryString + '&view_fields=' + JSON.stringify(viewFields);
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			baseContractCode: editRoyaltiesGuid,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			contracttype: 'royaltiescontracts',
			entity: 'closesettlements'
		};

		this.closeSettlementDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
		setTimeout(() => {
			this._RoyaltiesService.totalCountOfSettlementReports = this.dataGridContainer.instance.totalCount();
		}, 600);
	}


	viewRoyaltiesCalculation(invoiceDetails) {
		if (invoiceDetails) {
			this._RoyaltiesService.setInvoiceDetails(invoiceDetails);
			this._Router.navigate(['/contracts/royalties/report']);
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		const cName = this.royaltiesContractModelUI.contractName || '';
		let fileName = '';
		if (cName) {
			fileName = cName + '-';
		}
		fileName = fileName + 'Settlement_Report.xlsx';
		worksheet = workbook.addWorksheet('Settlement List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'apInvoiceCreatedInERP') {
						excelCell.value = '';
						if (gridCell.value) {
							let colorName;
							switch (gridCell.value) {
								case 'Open':
									colorName = '706cce';
									break;
								case 'Processing':
									colorName = 'f99300';
									break;
								case 'Success':
									colorName = '05c1a0';
									break;
								case 'Error':
									colorName = 'ff4545';
									break;
								default:
									break;
							}
							excelCell.font = { color: { argb: colorName } };
							// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colorName.toString() } };
							excelCell.value = gridCell.value;
						}
					}
					if (gridCell.column.dataField === 'errorMessage') {
						if (gridCell.value) {
							excelCell.alignment = { wrapText: true };
							excelCell.value = gridCell.value;
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	UpdateSettlementPeriodClick() {
		this._LoaderService.show();
		const arryContracts = [];
		// Get employee name as per id
		// if (this.royaltiesContractModelUI.employeeCode) {
		// 	const emp = this.salesPplList.filter(item => (item.code).toString() === (this.royaltiesContractModelUI.employeeCode).toString())[0];
		// 	this.royaltiesContractModelUI.employeeName = emp !== undefined ? emp.firstname || '' : '';
		// }

		this.royaltiesContractModelRequest.percentOrDollar = this.royaltiesContractModelUI.percentOrDollar;
		this.royaltiesContractModelRequest.contractName = this.royaltiesContractModelUI.contractName;
		this.royaltiesContractModelRequest.selectVendorId = this.royaltiesContractModelUI.selectVendorId;
		this.royaltiesContractModelRequest.selectVendor = this.royaltiesContractModelUI.selectVendor;
		this.royaltiesContractModelRequest.royaltiesPercent = this.royaltiesContractModelUI.royaltiesPercent;
		// this.royaltiesContractModelRequest.employeeCode = this.royaltiesContractModelUI.employeeCode;
		this.royaltiesContractModelRequest.employeeName = this.royaltiesContractModelUI.employeeName;
		this.royaltiesContractModelRequest.startDate = moment(this.royaltiesContractModelUI.startDate).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.endDate = moment(this.royaltiesContractModelUI.endDate).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriodStart = moment(this.royaltiesContractModelUI.settlementPeriodStart).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriodEnd = moment(this.royaltiesContractModelUI.settlementPeriodEnd).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriod = this.royaltiesContractModelUI.settlementPeriod;
		this.royaltiesContractModelRequest.contractCalendar = this.royaltiesContractModelUI.contractCalendar;
		this.royaltiesContractModelRequest.startSettlementPeriodOn = this.royaltiesContractModelUI.startSettlementPeriodOn;
		this.royaltiesContractModelRequest.agreementBy = this.royaltiesContractModelUI.agreementBy;
		this.royaltiesContractModelRequest.allproducts = this.isAllProductSelectedView;
		this.royaltiesContractModelRequest.allclients = this.isAllCustomerSelectedView;
		this.royaltiesContractModelRequest.clients = this.customerTabList;
		this.royaltiesContractModelRequest.items = this.productOrGroupTabList;
		// this.royaltiesContractModelRequest.excludedItems = this.exclusionTabList;
		this.royaltiesContractModelRequest.customer_items_groups = this.exclusionTabList;
		this.royaltiesContractModelRequest.rules = this.ruleTabList;
		this.royaltiesContractModelRequest.is_active = this.royaltiesContractModelUI.is_active;

		if (this.royaltiesContractModelRequest) {
			arryContracts.push(this.royaltiesContractModelRequest);
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
			formData.append('royaltiescontracts', JSON.stringify(arryContracts));
			formData.append('contracttype', 'royaltiescontracts');
			if (this.dataOutReqSubscription) {
				this.dataOutReqSubscription.unsubscribe();
			}
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					this.royaltiesContractModelUI.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
					this.royaltiesContractModelUI.current_settlement_commission_total = response.data.current_settlement_commission_total;
					this.doGetSettlementPeriodList();
					// this._ToastrService.success(response.message, 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			});
		}
	}

	public doExporToPDF(element: any) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DOWNLOAD_CONTRACT_STATEMENT);
		formData.append('guid', element.guid);
		formData.append('contracttype', 'royaltiescontracts');

		if (this.doDownloadPdfSbsn) {
			this.doDownloadPdfSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.doDownloadPdfSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response && response.flag) {
				const fileLink = response.data.file_link || '';
				if (fileLink) {
					const iframe = '<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="' + fileLink + '" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>';

					let win: any = window.open('', '_blank', 'location=no,menubar=no,height=720,width=1080,scrollbars=yes,status=yes');
					win.document.write(iframe);
				}
			}
		}, error => {
			this._LoaderService.hide();
		});
	}
}
