import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { ApDashboardService } from '@app/services/ap-services/ap-dashboard-service/ap-dashboard.service';

@Component({
	selector: 'app-ap-sales-invoice-total-tiles',
	templateUrl: './ap-sales-invoice-total-tiles.component.html',
	styleUrls: ['./ap-sales-invoice-total-tiles.component.css']
})

export class ApSalesInvoiceTotalTilesComponent implements OnInit, OnDestroy {
	public globalCurrency: string;
	private getCounterSbsn: Subscription;
	public salesTotalForTheMonth: number;
	public resourcesLoaded: boolean = false;
	public currentMonthName: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _ApDashboardService: ApDashboardService) {

	}

	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.currentMonthName = this._ApDashboardService.getCurrentMonthName();
		this.getSalesInvoiceTotal();
	}

	ngOnDestroy(): void {
		if (this.getCounterSbsn) {
			this.getCounterSbsn.unsubscribe();
		}
	}

	private getSalesInvoiceTotal() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getDashboardData');
		formData.append('section', 'invoicesTile');
		if (this.getCounterSbsn) {
			this.getCounterSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getCounterSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this.resourcesLoaded = false;
			if (response.flag) {
				if (response.data.tiles) {
					this.salesTotalForTheMonth = response.data.tiles.sales_total_for_the_month || 0;
				}
			}
		}, error => {
			this.resourcesLoaded = false;
		});
	}

	public tilesClick(clickFor: string) {
		this._ApDashboardService.tilesClick(clickFor);
	}
}


