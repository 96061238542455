<div [hidden]="resourcesLoaded">
	<div class="subTitleRow">
		<h2>Open Tasks Listing</h2>
		<a (click)="goToCrmTasks()" class="viewAllBtn">View All
			<span class="rightArrow"></span>
		</a>
	</div>
	<div class="tableOut">
		<dx-data-grid #dataGridContainer class="colorList" id="gridContainer" [dataSource]="dxDataSource"
			[showBorders]="true" [remoteOperations]="true" [filterValue]="filterValue"
			[dateSerializationFormat]="'yyyy-MM-dd'" [showColumnLines]="true" [showRowLines]="false"
			[allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'"
			(onToolbarPreparing)="doToolbarPreparing($event)" [filterSyncEnabled]="true" class="hideFilterPanelInx">

			<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
			<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
				[showInfo]="true">
			</dxo-pager>
			<dxo-export [enabled]="false"></dxo-export>
			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-filter-panel [visible]="true"></dxo-filter-panel>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
			<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
			<div *dxTemplate="let data of 'templateFilterText'">
				<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
					<span>{{filterText}}</span>
				</div>
			</div>

			<div *dxTemplate="let data of 'templateActionBtns'">
				<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
					[showArrowIcon]="false" text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx"
					[items]="listOfActionsCrm" [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
					keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
					(onItemClick)="doActionsBtnItemClick($event)" [dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
			</div>

			<dxi-column [visible]="isShowCodeColumn" [showInColumnChooser]="isShowCodeColumn" dataField="cardcode"
				dataType="string" caption="Code" cellTemplate="cellTemplateCardCode" [allowHeaderFiltering]="false">
				<div *dxTemplate="let element of 'cellTemplateCardCode'">
					<span class="proIcon center">
						<span class="icon-default-allcompany icon-set1"></span>
					</span>
					<span (click)="doRowClickTask(element.data)"
						class="highlight mrgL10 pointerMark">{{element.data.cardcode}}</span>
				</div>
			</dxi-column>

			<dxi-column dataField="clientname" caption="Company" cellTemplate="cellTemplateclientname"
				[allowHeaderFiltering]="false" dataType="string">
				<div *dxTemplate="let element of 'cellTemplateclientname'">
					<span *ngIf="!isShowCodeColumn" class="proIcon center">
						<span class="icon-default-allcompany icon-set1"></span>
					</span>
					<span (click)="doRowClickTask(element.data)"
						class="highlight mrgL10 pointerMark">{{element.data.clientname}}</span>
				</div>
			</dxi-column>

			<dxi-column dataField="tasktype" caption="Task Type" [allowFiltering]="false" [allowHeaderFiltering]="true"
				[allowSorting]="false" cellTemplate="cellTemplatecontactfrom" dataType="string">
				<dxo-header-filter [dataSource]="listOfTaskTypeHeaderFilter"></dxo-header-filter>
				<div *dxTemplate="let element of 'cellTemplatecontactfrom'">
					{{element.data.tasktype || '' | filterFromList:listOfTaskTypes : 'value' : 'label'}}
				</div>
			</dxi-column>

			<dxi-column dataField="title" caption="Task Title" [allowHeaderFiltering]="false" dataType="string">
			</dxi-column>

			<dxi-column dataField="enddate" caption="Due Date" cellTemplate="cellTemplateenddate" dataType="date"
				format="yyyy-MM-dd" sortOrder="desc" [allowHeaderFiltering]="false">
				<div *dxTemplate="let element of 'cellTemplateenddate'">
					{{element.data.enddate || '' | customDateFormatPipe:'dateonly'}}
					<br *ngIf="element.data.timeinterval" />
					{{element.data.timeinterval || ''}}
				</div>
			</dxi-column>

			<dxi-column dataField="assignto" caption="Assigned To" cellTemplate="cellTemplateassignto"
				[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="false" dataType="string">
				<dxo-header-filter [dataSource]="listOfAssignToHeaderFilter"></dxo-header-filter>
				<div *dxTemplate="let element of 'cellTemplateassignto'">
					<span *ngIf="element.data.assignto && element.data.assignto.length > 0">
						{{element.data.assignto[0] || '' | multifilterFromList:listOfAssignToUsers : 'sub_account_id'
						: 'first_name,last_name'}}
						<span
							*ngIf="element.data.assignto[0] && (element.data.assignto.length === 2 || element.data.assignto.length >= 3)"
							id="assigntoToolTip_{{element.data.guid}}">
							+ {{element.data.assignto.length-1}}
							{{ element.data.assignto.length === 2 ? 'Other' : element.data.assignto.length >= 3 ?
							'Others' : ''}}
						</span>
						<dx-tooltip target="#assigntoToolTip_{{element.data.guid}}" showEvent="dxhoverstart"
							hideEvent="dxhoverend" position="right">
							<div *dxTemplate="let d = d; of: 'content'">
								<div class="tooltip-wrapper">
									<div class="greenTipout">
										<ol class="tipList">
											<li *ngFor="let assignto of element.data.assignto; let p = index;">
												<a>
													<span>
														{{assignto || '' | multifilterFromList:listOfAssignToUsers :
														'sub_account_id' : 'first_name,last_name'}}
													</span>
												</a>
											</li>
										</ol>
									</div>
								</div>
							</div>
						</dx-tooltip>
					</span>
				</div>
			</dxi-column>

			<dxi-column dataField="status" caption="Status" cellTemplate="cellTemplatestatus" dataType="string"
				[allowFiltering]="false" [allowHeaderFiltering]="true">
				<dxo-header-filter [dataSource]="listOfTaskStatusHeaderFilter"></dxo-header-filter>
				<div *dxTemplate="let element of 'cellTemplatestatus'">
					<div class="tooltip skyblueToolTip">
						<span class="toolTipTx" id="imgtooltip_{{element.data.guid}}">
							{{element.data.status || '' | filterFromList:listOfTaskStatuses : 'btnCode' : 'btnName'}}
						</span>
					</div>
				</div>
			</dxi-column>

			<dxi-column dataField="guid" caption="Action" [allowHiding]="false" [fixed]="true" fixedPosition="right"
				alignment="center" cellTemplate="cellTemplateGUID" [allowFiltering]="false" [allowHeaderFiltering]="false"
				[allowSorting]="false" [allowResizing]="false" [minWidth]="65" [width]="65" [allowExporting]="false"
				dataType="string">
				<div *dxTemplate="let data of 'cellTemplateGUID'">
					<dx-drop-down-button [splitButton]="false" [showArrowIcon]="true" [useSelectMode]="false" text=""
						stylingMode="contained" class="secondaryBtn actionBtnBorderNone actionBtnIx"
						[items]="listOfTaskActions" [wrapItemText]="true" displayExpr="btnName" keyExpr="btnCode"
						(onButtonClick)="doGetTaskActions(data.data)" (onItemClick)="doTaskActions($event,data.data)"
						[dropDownOptions]="{ width: 230 }">
					</dx-drop-down-button>
				</div>
			</dxi-column>

		</dx-data-grid>

	</div>
	<div class="tableEntries cf" *ngxPermissionsOnly="['CRM_TASK_MODIFY']">
		<div class="titleBtnCol alignRight">
			<dx-button class="secondaryBtn button" text="Add New" icon="add" type="normal" stylingMode="contained"
				(onClick)="addNewTask()" [useSubmitBehavior]="false">
			</dx-button>
		</div>
	</div>
</div>

<app-task-dialog *ngIf="isShowAddEditTaskPopup" [addeditTaskDetailsProps]="addeditTaskDetailsProps"
	(closeAddEditTaskPopup)="doCloseAddEditTaskPopup($event)"></app-task-dialog>