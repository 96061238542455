import { Component, OnInit, Inject, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PageOptions } from '@app/models/common.model';
import { LoginService } from '@app/services/login.service';
import { ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';

@Component({
	selector: 'app-view-rebate-credit-memo-for-past-invoices-list-dialog',
	templateUrl: './view-rebate-credit-memo-for-past-invoices-list-dialog.component.html',
	styleUrls: ['./view-rebate-credit-memo-for-past-invoices-list-dialog.component.css']
})
export class ViewRebateCreditMemoForPastInvoicesListDialogComponent implements OnInit, OnChanges, OnDestroy {
	@Input() viewRebateCreditMemoInvoicesListProps: any;
	@Output() closeViewRebateCreditMemoInvoicesListPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;

	public popupTitle: string = 'Generated Credit Memo For Past Invoices';
	private erpType: string;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public decimalPointForCurrency: number;
	public pageOptions: PageOptions = new PageOptions();
	public isShowDatagrid: boolean;
	private contractguid: string;
	private contractDetails: any;
	public pastInvoicesList: any;

	// #region for Angular Life cycle
	constructor(private _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService) {

	}

	ngOnInit() {
		this.isShowPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;

		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.isShowDatagrid = true;
		this.setErpType();
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['viewRebateCreditMemoInvoicesListProps']) {
			const viewRebateCreditMemoInvoicesListProps = _SimpleChanges['viewRebateCreditMemoInvoicesListProps'].currentValue;
			this.contractguid = viewRebateCreditMemoInvoicesListProps.contractguid;
			this.contractDetails = viewRebateCreditMemoInvoicesListProps.contractDetails;
			this.pastInvoicesList = viewRebateCreditMemoInvoicesListProps.generateCreditMemoOnPastInvoices || [];
		}
	}

	ngOnDestroy() {
	}
	// #endregion

	// Method used to get Erp Type
	private setErpType() {
		// get current user Erp type
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erpType = '';
		}

		this.isQBErpUser = false;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}

		this.isQBOnlineErpUser = false;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
	}

	public doHiddenPopup(event) {
		this.closeViewRebateCreditMemoInvoicesListPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}
}
