import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_REFRESH, IMG_ICON_VERTICALALIGNCENTER } from '@app/constants-enums/constants';
import { SubscriptionPlanStatusesLblEnum, SubscriptionPlanStatusesValEnum } from '@app/constants-enums/enums';

@Injectable({
	providedIn: 'root'
})
export class ApSubscriptionPlansService {
	private selectedTab: string;
	public subscriptionPlans: any[] = [];
	private storedFilter: any;
	private isEditMode: any;
	private subscriptionPlanID: any;
	private selectedSubscriptionPlan: any;
	private isPrivatePlan: any;
	private listOfModulePermissions: any[] = [];
	private listOfUserType: any[] = [];

	constructor() { }

	setTabSelection(val: string) {
		this.selectedTab = val;
	}
	getTabSelection() {
		return this.selectedTab || undefined;
	}

	setStoredFilters(val: any) {
		this.storedFilter = val;
	}

	getStoredFilters() {
		return this.storedFilter || undefined;
	}

	setSubscriptionPlans(val: any) {
		this.subscriptionPlans = val;
	}

	getSubscriptionPlans() {
		return this.subscriptionPlans || undefined;
	}

	setIsEditMode(val: any) {
		this.isEditMode = val;
	}

	getIsEditMode() {
		return this.isEditMode;
	}

	setSubscriptionPlanGUID(val: any) {
		this.subscriptionPlanID = val;
	}

	getSubscriptionPlanGUID() {
		return this.subscriptionPlanID;
	}

	setSelectedSubscriptionPlan(val: any) {
		this.selectedSubscriptionPlan = val;
	}

	getSelectedSubscriptionPlan() {
		return this.selectedSubscriptionPlan;
	}

	setIsPrivatePlan(val: any) {
		this.isPrivatePlan = val;
	}

	getIsPrivatePlan() {
		return this.isPrivatePlan;
	}

	public setModulePermissionsEle(val: any) {
		const eleIndex = this.listOfModulePermissions.findIndex(ele => ele.guid === val.guid);
		if (eleIndex !== 1) {
			this.listOfModulePermissions.splice(eleIndex, 1);
		}
		this.listOfModulePermissions.push(val);
	}

	public getModulePermissionsEle(eleObj?: object, propertyName?: any) {
		if (eleObj && Object.keys(eleObj).length > 0) {
			const eleIndex = this.listOfModulePermissions.findIndex(ele => ele[propertyName] === eleObj[propertyName]);
			if (eleIndex !== -1) {
				return this.listOfModulePermissions[eleIndex];
			} else {
				return {};
			}
		} else {
			return this.listOfModulePermissions && this.listOfModulePermissions.length > 0 ? this.listOfModulePermissions[this.listOfModulePermissions.length - 1] : {};
		}
	}
	public setUserTypeEle(val: any) {
		const eleIndex = this.listOfUserType.findIndex(ele => ele.guid === val.guid);
		if (eleIndex !== 1) {
			this.listOfUserType.splice(eleIndex, 1);
		}
		this.listOfUserType.push(val);
	}

	public getUserTypeEle(eleObj?: object, propertyName?: any) {
		if (eleObj && Object.keys(eleObj).length > 0) {
			const eleIndex = this.listOfUserType.findIndex(ele => ele[propertyName] === eleObj[propertyName]);
			if (eleIndex !== -1) {
				return this.listOfUserType[eleIndex];
			} else {
				return {};
			}
		} else {
			return this.listOfUserType && this.listOfUserType.length > 0 ? this.listOfUserType[this.listOfUserType.length - 1] : {};
		}
	}

	public getListOfSubscriptionType() {
		return [
			{ value: true, text: 'Customised' },
			{ value: false, text: 'Public' }
		];
	}

	public getListOfSubscriptionStatuses() {
		return [
			{ value: SubscriptionPlanStatusesValEnum.ACTIVE, text: SubscriptionPlanStatusesLblEnum.ACTIVE },
			{ value: SubscriptionPlanStatusesValEnum.INACTIVE, text: SubscriptionPlanStatusesLblEnum.INACTIVE }
		];
	}

	public getListOfModuleStatuses() {
		const listOfModuleStatus = [
			{
				value: 'active',
				text: 'Active'
			},
			{
				value: 'inactive',
				text: 'Invactive'
			},
		];
		return listOfModuleStatus;
	}

	public getListOfModuleTypes() {
		const listOfModuleTypes = [
			{
				value: 'public',
				text: 'Public'
			},
			{
				value: 'private',
				text: 'Private'
			},
		];
		return listOfModuleTypes;
	}

	public getListOfActionsForModulePermissons() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}
	public getListOfActionsForSubscriptionPlans() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForUserTypes() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}
}
