import { RestApiCallService } from './../rest-api-call/rest-api-call.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
@Injectable({
	providedIn: 'root'
})
export class RestApiService {
	constructor(public restApiCallService: RestApiCallService) { }

	doLoginReq(formdata: string): Observable<any> {
		return this.restApiCallService.post(environment.URL_LOGIN, formdata);
	}

	doLoginReqFormData(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.URL_LOGIN, formdata);
	}

	doLoginWithSSOReq(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.URL_AUTHORIZATION_SSO, formdata);
	}

	doLogoutReq(formdata: string): Observable<any> {
		return this.restApiCallService.post(environment.URL_LOGIN, formdata);
	}

	doSendSmsOtpReq(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.URL_LOGIN, formdata);
	}
	doDataOutReq(formdata: string): Observable<any> {
		return this.restApiCallService.post(environment.URL_DATAOUT, formdata);
	}
	doDataOutReqDownloadFile(formdata: string): Observable<any> {
		return this.restApiCallService.postFileDownload(environment.URL_DATAOUT, formdata);
	}
	doDataOutReqFormData(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFile(environment.URL_DATAOUT, formdata);
	}

	doDataInReq(formdata: string): Observable<any> {
		return this.restApiCallService.post(environment.URL_DATAIN, formdata);
	}

	doDataInFormDataReq(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.URL_DATAIN, formdata);
	}

	doCSVDataInReq(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFile(environment.URL_CSVDATAIN, formdata);
	}

	doCSVUploadDataInReq(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFileUpload(environment.URL_CSVDATAIN, formdata);
	}
	doUserDataInReq(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFile(environment.URL_User_DATAIN, formdata);
	}

	doUserSetUpReq(formdata: string): Observable<any> {
		return this.restApiCallService.post(environment.URL_USERSETUP, formdata);
	}

	doUserSetUpReqFormData(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.URL_USERSETUP, formdata);
	}

	doSubUserSetUpReq(formdata: string): Observable<any> {
		return this.restApiCallService.post(environment.URL_SUB_USER_SETUP, formdata);
	}
	doSubUserSetUpReqFormData(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.URL_SUB_USER_SETUP, formdata);
	}

	doforgotPasswordReqFormData(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.URL_FORGOT_PASSWORD_SETUP, formdata);
	}

	doDataOutSignUpReq(formdata: string): Observable<any> {
		return this.restApiCallService.post(environment.URL_DATAOUT_SIGNUP, formdata);
	}

	doNameDictionaryReq(): Observable<any> {
		return this.restApiCallService.get(environment.URL_GET_NAME_DICTIONARY);
	}

	doPhoneCodeReq(): Observable<any> {
		return this.restApiCallService.get(environment.URL_GET_PHONE_CODE);
	}

	doGetContryReq(): Observable<any> {
		return this.restApiCallService.get(environment.URL_GET_COUNTRY);
	}

	doGetStateReq(): Observable<any> {
		return this.restApiCallService.get(environment.URL_GET_STATE);
	}

	doGetTimezoneReq(): Observable<any> {
		return this.restApiCallService.get(environment.URL_TIMEZONE_JSON);
	}
	doGetDisposableEmailReq(): Observable<any> {
		return this.restApiCallService.get(environment.URL_GET_DisposableEmail + new Date().valueOf());
	}
	doGetDisposableEmailLocalReq(): Observable<any> {
		return this.restApiCallService.get(environment.URL_GET_DisposableEmail_Local);
	}

	doGetCsvDocumentReq(formdata: FormData): Observable<any> {
		return this.restApiCallService.postFormData(environment.GET_CSV_DOC, formdata);
	}
}
