<div class="rightBar">
	<div class="rightBlock">
		<div class="tabbing cf">
			<ul>
				<li (click)="clickMainTab('commission_agreements')"
					[ngClass]="{active: currentMainTab == 'commission_agreements'}">
					<a>Commission Agreements</a>
				</li>
				<li (click)="clickMainTab('contract_templates')"
					[ngClass]="{active: currentMainTab == 'contract_templates'}">
					<a>Agreement Templates</a>
				</li>
			</ul>
		</div>

		<app-commission-contract-list *ngIf="currentMainTab === 'commission_agreements'"></app-commission-contract-list>
		<app-commission-tierbase-contract-template-list *ngIf="currentMainTab === 'contract_templates'">
		</app-commission-tierbase-contract-template-list>
	</div>
</div>
