import { Injectable } from '@angular/core';
import {
	IMG_MARK_AS_BUYING_GROUP_SVG,
	IMG_MARK_AS_CUSTOMER_SVG,
	IMG_MARK_AS_PROSPECT_SVG,
	IMG_EDIT_SVG,
	IMG_CLOSE_SVG,
	IMG_ADD_TASK_SE_SVG,
	IMG_DEAL_SE_SVG,
	IMG_SITE_VISIT_SVG,
	IMG_CALL_SE_SVG,
	IMG_MAIL_SVG,
	IMG_APPOINTMENT_SE_SVG,
	IMG_DOC_SVG,
	IMG_ICON_XLSXFILEPOPUPIX,
	IMG_ICON_REFRESH,
	IMG_ADD_FILE_SVG,
	IMG_ICON_COLUMN_CHOOSER,
	IMG_ICON_MARK_AS_BUYING_GROUP,
	IMG_ICON_FULL_SCREEN_PURPLE_SVG,
	IMG_ICON_VIEW_DETAILS
} from '@app/constants-enums/constants';
import {
	BusinessPartnerActionBtnsEnum,
	BusinessPartnerTabValEnum,
	ContactDetailTabDataEnum,
	ContactDetailActionBtnsValEnum,
	ContactDetailActionBtnsLblEnum,
	EndUserDetailsTabsValEnum,
	AddressTypesValEnum
} from '@app/constants-enums/enums';
import { ContactMove, ContactEdit, Tasks } from '@app/models/businessplan.model';
import * as _ from 'underscore';
import { OptionBtns } from '@app/models/common.model';

@Injectable({
	providedIn: 'root'
})
export class BusinessPlanDataSharingService {
	public customerDetails: any;
	public selectedTabDetails: any;
	public contactDetails: any;
	public businessPartnerId: any;
	public businessPartnerCode: any;
	public customerDetailId: any;
	public contactDetailId: any;
	private endUsersElements: any[] = [];
	private endUsersInvoiceElements: any[] = [];

	constructor() {

	}

	setSelectedTabData(val: any) {
		this.selectedTabDetails = val;
	}
	getSelectedTabData() {
		return this.selectedTabDetails;
	}

	setBusinessPartnerId(val: any) {
		this.businessPartnerId = val;
	}
	getBusinessPartnerId() {
		return this.businessPartnerId;
	}

	setBusinessPartnerCode(val: any) {
		this.businessPartnerCode = val;
	}
	getBusinessPartnerCode() {
		return this.businessPartnerCode;
	}

	setCustomerDetailId(val: any) {
		this.customerDetailId = val;
	}
	getCustomerDetailId() {
		return this.customerDetailId;
	}

	setContactDetailId(val: any) {
		this.contactDetailId = val;
	}
	getContactDetailId() {
		return this.contactDetailId;
	}

	public dataDeleteRequest(code: string, from: string, to: string) {
		const contactMove = new ContactMove();
		contactMove.action = 'move';
		contactMove.from = from;
		contactMove.to = to;
		contactMove.ids = [];
		contactMove.ids.push(code);

		const contactsReq = [];
		contactsReq.push(contactMove);
		return contactsReq;
	}

	public dataEditRequest(contentData: string, type: string) {
		const contactEdit = new ContactEdit();
		contactEdit.action = 'edit';
		contactEdit.type = type;
		contactEdit.content = [];
		contactEdit.content.push(contentData);

		const contactsReq = [];
		contactsReq.push(contactEdit);
		return contactsReq;
	}

	deactivateAllTabs(tabsList) {
		tabsList.forEach(itemTab => {
			itemTab.isActive = false;
		});
	}

	getBusinessPartnerTabList() {
		return [
			// 	{
			// 	tabId: 0,
			// 	tabCode: BusinessPartnerTabValEnum.LEAD,
			// 	tabName: 'Leads',
			// 	detailName: 'Lead',
			// 	isActive: false
			// }, {
			// 	tabId: 1,
			// 	tabCode: BusinessPartnerTabValEnum.PROSPECT,
			// 	tabName: 'Prospects',
			// 	detailName: 'Prospect',
			// 	isActive: false
			// },
			{
				tabId: 2,
				tabCode: BusinessPartnerTabValEnum.CLIENT,
				tabName: 'Customers',
				detailName: 'Customer',
				isActive: false
			},
			{
				tabId: 3,
				tabCode: BusinessPartnerTabValEnum.VENDOR,
				tabName: 'Vendors',
				detailName: 'Vendor',
				isActive: false
			}, {
				tabId: 4,
				tabCode: BusinessPartnerTabValEnum.BUY_GROUP,
				tabName: 'Buying Groups',
				detailName: 'Buying Group',
				isActive: false
			}
			// , {
			// 	tabId: 5,
			// 	tabCode: BusinessPartnerTabValEnum.TERRITORY,
			// 	tabName: 'Territory',
			// 	detailName: 'Territory',
			// 	isActive: false
			// }
		];
	}

	getActionMenus(tabCode: any) {
		const businessPlanActionsData: any[] = [];
		switch (tabCode) {
			case BusinessPartnerActionBtnsEnum.LEAD:
				businessPlanActionsData.push({
					btnCode: BusinessPartnerActionBtnsEnum.PROSPECT,
					btnName: 'Mark as Prospect',
					btnImage: IMG_MARK_AS_PROSPECT_SVG
				});
				businessPlanActionsData.push({
					btnCode: BusinessPartnerActionBtnsEnum.CUSTOMER,
					btnName: 'Mark as Customer',
					btnImage: IMG_MARK_AS_CUSTOMER_SVG
				});
				break;
			case BusinessPartnerActionBtnsEnum.PROSPECT:
				businessPlanActionsData.push({
					btnCode: BusinessPartnerActionBtnsEnum.CUSTOMER,
					btnName: 'Mark as Customer',
					btnImage: IMG_MARK_AS_CUSTOMER_SVG
				});
				break;
			case BusinessPartnerActionBtnsEnum.VENDOR:
				businessPlanActionsData.push({
					btnCode: BusinessPartnerActionBtnsEnum.BUY_GROUP,
					btnName: 'Mark as BuyingGroups',
					btnImage: IMG_MARK_AS_BUYING_GROUP_SVG
				});
				break;
			default:
				break;
		}
		businessPlanActionsData.push({
			btnCode: BusinessPartnerActionBtnsEnum.EDIT,
			btnName: 'Edit',
			btnImage: IMG_EDIT_SVG
		});
		businessPlanActionsData.push({
			btnCode: BusinessPartnerActionBtnsEnum.DELETE,
			btnName: 'Delete',
			btnImage: IMG_CLOSE_SVG
		});
		return businessPlanActionsData;
	}

	getContactDetailTabList() {
		return [{
			tabId: 0,
			tabCode: ContactDetailTabDataEnum.TIMELINES,
			tabName: 'Timelines',
			detailName: 'Timeline',
			isActive: false
		}, {
			tabId: 1,
			tabCode: ContactDetailTabDataEnum.TASKS,
			tabName: 'Tasks',
			detailName: 'Task',
			isActive: false
		}, {
			tabId: 2,
			tabCode: ContactDetailTabDataEnum.DEALS,
			tabName: 'Deals',
			detailName: 'Deal',
			isActive: false
		}, {
			tabId: 3,
			tabCode: ContactDetailTabDataEnum.MAILS,
			tabName: 'Mails',
			detailName: 'Mail',
			isActive: false
		}, {
			tabId: 4,
			tabCode: ContactDetailTabDataEnum.DOCUMENTS,
			tabName: 'Documents',
			detailName: 'Documents',
			isActive: false
		}];
	}

	getContactDetailActions(tabCode: any) {
		const contactDetailActionsData: OptionBtns[] = [];
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.PHONECALL,
			btnName: ContactDetailActionBtnsLblEnum.PHONECALL,
			btnImage: IMG_CALL_SE_SVG
		});
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.SENDEMAIL,
			btnName: ContactDetailActionBtnsLblEnum.SENDEMAIL,
			btnImage: IMG_MAIL_SVG
		});
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.SITEVISIT,
			btnName: ContactDetailActionBtnsLblEnum.SITEVISIT,
			btnImage: IMG_SITE_VISIT_SVG
		});
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.APPOINTMENT,
			btnName: ContactDetailActionBtnsLblEnum.APPOINTMENT,
			btnImage: IMG_APPOINTMENT_SE_SVG
		});
		switch (tabCode) {
			case BusinessPartnerActionBtnsEnum.LEAD:
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASPROSPECT,
					btnName: ContactDetailActionBtnsLblEnum.MARKASPROSPECT,
					btnImage: IMG_MARK_AS_PROSPECT_SVG
				});

				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
					btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
					btnImage: IMG_MARK_AS_CUSTOMER_SVG
				});
				break;
			case BusinessPartnerActionBtnsEnum.PROSPECT:
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASCUSTOMER,
					btnName: ContactDetailActionBtnsLblEnum.MARKASCUSTOMER,
					btnImage: IMG_MARK_AS_CUSTOMER_SVG
				});
				break;
			case BusinessPartnerActionBtnsEnum.VENDOR:
				contactDetailActionsData.push({
					btnCode: ContactDetailActionBtnsValEnum.MARKASBUYINGGROUP,
					btnName: ContactDetailActionBtnsLblEnum.MARKASBUYINGGROUP,
					btnImage: IMG_MARK_AS_BUYING_GROUP_SVG
				});
				break;
			default:
				break;
		}

		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.ADDTASK,
			btnName: ContactDetailActionBtnsLblEnum.ADDTASK,
			btnImage: IMG_ADD_TASK_SE_SVG
		});
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.ADDDEAL,
			btnName: ContactDetailActionBtnsLblEnum.ADDDEAL,
			btnImage: IMG_DEAL_SE_SVG
		});
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.ADDDOCUMENT,
			btnName: ContactDetailActionBtnsLblEnum.ADDDOCUMENT,
			btnImage: IMG_DOC_SVG
		});
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.EDIT,
			btnName: ContactDetailActionBtnsLblEnum.EDIT,
			btnImage: IMG_EDIT_SVG
		});
		contactDetailActionsData.push({
			btnCode: ContactDetailActionBtnsValEnum.DELETE,
			btnName: ContactDetailActionBtnsLblEnum.DELETE,
			btnImage: IMG_CLOSE_SVG
		});
		return contactDetailActionsData;
	}

	taskSort(tasks: Tasks[]) {
		const tasksList = _.sortBy(tasks, (taskItem: Tasks) => {
			const dt = new Date(taskItem.startdate).getTime();
			return dt;
		});
		return tasksList.reverse();
	}

	public dataMoveRequest(content: string, from: string, to?: string, action?: string) {
		const contactMove = new ContactMove();
		contactMove.from = from;
		if (to) {
			contactMove.to = to;
		}
		contactMove.action = 'move';
		if (action) {
			contactMove.action = action;
		}
		contactMove.content = [];
		contactMove.content.push(content);

		const contactsReq = [];
		contactsReq.push(contactMove);
		return contactsReq;
	}

	public setEndUserElements(val: any) {
		const eleIndex = this.endUsersElements.findIndex(ele => ele.guid === val.guid);
		if (eleIndex !== 1) {
			this.endUsersElements.splice(eleIndex, 1);
		}
		this.endUsersElements.push(val);
	}

	public getEndUserElements(eleObj?: any) {
		if (eleObj && Object.keys(eleObj).length > 0) {
			const eleIndex = this.endUsersElements.findIndex(ele => ele.guid === eleObj.guid);
			if (eleIndex !== -1) {
				return this.endUsersElements[eleIndex];
			} else {
				return {};
			}
		} else {
			return this.endUsersElements && this.endUsersElements.length > 0 ? this.endUsersElements[this.endUsersElements.length - 1] : {};
		}
	}
	public setEndUserInvoiceElements(val: any) {
		const eleIndex = this.endUsersInvoiceElements.findIndex(ele => ele.guid === val.guid);
		if (eleIndex !== 1) {
			this.endUsersInvoiceElements.splice(eleIndex, 1);
		}
		this.endUsersInvoiceElements.push(val);
	}

	public getEndUserInvoiceElements(eleObj?: any) {
		if (eleObj && Object.keys(eleObj).length > 0) {
			const eleIndex = this.endUsersInvoiceElements.findIndex(ele => ele.guid === eleObj.guid);
			if (eleIndex !== -1) {
				return this.endUsersInvoiceElements[eleIndex];
			} else {
				return {};
			}
		} else {
			return this.endUsersInvoiceElements && this.endUsersInvoiceElements.length > 0 ? this.endUsersInvoiceElements[this.endUsersInvoiceElements.length - 1] : {};
		}
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForVendors() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForBuyinggroups() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForEndUsers() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForVendorDetails() {
		return [
			{
				btn_code: 'MARK_AS_BUYINGGROUP',
				btn_name: 'Mark as BuyingGroup',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ICON_MARK_AS_BUYING_GROUP,
				visible: true,
				disabled: false,
			},
		];
	}


	public getListOfActionByAddress() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btn_code: ContactDetailActionBtnsValEnum.ADDADDRESS,
			btn_name: ContactDetailActionBtnsLblEnum.ADDADDRESS,
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
		});
		actionMenus.push({
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		});
		actionMenus.push({
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		});
		return actionMenus;
	}

	public getListOfActionByRole() {
		const actionMenus: any[] = [];
		actionMenus.push({
			btn_code: EndUserDetailsTabsValEnum.ROLE,
			btn_name: 'Add Role',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
		});
		actionMenus.push({
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		});
		// actionMenus.push({
		// 	btn_code: 'COLUMN_CHOOSER',
		// 	btn_name: 'Column Chooser',
		// 	btn_image: 'column-chooser',
		// 	icon: IMG_ICON_COLUMN_CHOOSER,
		// 	visible: true,
		// 	disabled: false,
		// });
		return actionMenus;
	}

	get getListOfActionsForRebatePayoutOptions() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfEndUserAddressTypes() {
		return [
			{ value: AddressTypesValEnum.BILTO, text: 'Billing Address' },
			{ value: AddressTypesValEnum.SHIPTO, text: 'Shipping Address' }
		];
	}

	get getListOfActionsForEndUserInvoices() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'VIEW_DETAILS',
				btn_name: 'View Details',
				btn_image: 'viewDetails',
				icon: IMG_ICON_VIEW_DETAILS,
				visible: true,
				disabled: false,
			},
		];
	}

	get getListOfActionsForEndUserInvoiceViewDetailReport() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfReportTypesForEndUserInvoiceViewDetails() {
		return [
			{
				value: 'detailed_view',
				text: 'Detailed View',
				visible: true,
				disabled: false,
			},
			{
				value: 'summary_view',
				text: 'Summary View',
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfReportViewsForEndUserInvoiceViewDetails() {
		return [
			{
				value: 'datagrid_view',
				text: 'Data Grid',
				visible: true,
				disabled: false,
			},
			{
				value: 'pivotgrid_view',
				text: 'Pivot Grid',
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfInPricelistForEndUserInvoiceViewDetails() {
		return [
			{
				value: false,
				text: 'No',
				visible: true,
				disabled: false,
			},
			{
				value: true,
				text: 'Yes',
				visible: true,
				disabled: false,
			}
		];
	}
}
