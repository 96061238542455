<dx-popup width="400" height="245" [showTitle]="true" [hideOnOutsideClick]="true"
   title="Are you sure you want to Log out of your incentX Account" titleTemplate="title" [showCloseButton]="false"
   [visible]="true" shadingColor="#000000b3" (onShowing)="doPopupShowing($event)">
   <dxo-position at="center" my="center"> </dxo-position>
   <div *dxTemplate="let data of 'title'">
      <p class="logOutPopupTitle">Are you sure you want to Log out of your incentX Account</p>
   </div>
   <div *dxTemplate="let data of 'content'">
      <dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
         [useNative]="false" width="100%" height="100%">

         <div class="logOutPopup">
            <div class="alignLeft width100p mrgB20">
               <div class="logOutPopupLeftContent">
                  <div class="logOutPopupuserImage">
                     <span class="">{{first_name || '' | slice:0:1 |uppercase}}{{last_name ||'' | slice:0:1
                        |uppercase}}</span>
                  </div>
               </div>
               <div class="logOutPopupRightContent">
                  <h3>{{first_name || ''}} {{last_name || ''}}</h3>
                  <h4>{{role_name || ''}}
                     <span class="roleDetails" *ngIf="isAdminUser"> (Admin)</span>
                  </h4>
               </div>
            </div>
            <dx-button (onClick)="doClosePopup()" class="secondaryBtn button" text="Close" type="normal"
               stylingMode="contained" [useSubmitBehavior]="false"></dx-button>
            <dx-button (onClick)="doLogoutPopup()" class="primaryBtn button" text="Log Out" type="normal"
               stylingMode="contained" [useSubmitBehavior]="false"></dx-button>
         </div>
      </dx-scroll-view>
   </div>
</dx-popup>