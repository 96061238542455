import { Component, Input, OnInit, OnDestroy, HostListener, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { GlobalPopoupHeight80Ratio, GlobalPopoupHeight90Ratio, GlobalPopoupWidth80Ratio, GlobalPopoupWidth90Ratio, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { HeaderComponent } from '@app/pages/header/header.component';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import CheckBox from 'devextreme/ui/check_box';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';
import * as moment from 'moment';
@Component({
	selector: 'app-mmeucc-multi-claim-approval-popup',
	templateUrl: './mmeucc-multi-claim-approval-popup.component.html',
	styleUrls: ['./mmeucc-multi-claim-approval-popup.component.css']
})
export class MmeuccMultiClaimApprovalPopupComponent implements OnInit, OnDestroy {
	@Input() ApproveMultiChargebackClaimProps: any;
	@Output() closeApproveMultiChargebackClaimPopup = new EventEmitter<any>();
	@ViewChild("multiClaimApprovalDataGridContainer") multiClaimApprovalDataGridContainer: DxDataGridComponent;
	public isShowPopup: boolean = true;
	public titleText: string = 'Approve Multi Min-Max Chargeback Claim';
	public _PopupHeightWidth = PopupHeightWidth;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public multiClaimApprovalDataSource: any;
	private isDisabledClearBtn: boolean = true;
	private selectAllItem: boolean | null = null;
	private instanceClearBtn: any = null;
	private filterValueChangeSbsn: Observable<any>;
	private mainSectionStorageKey: string;
	private updateUserSettingSbsn: Subscription;
	private getMultiCalimListSubscription: Subscription;
	private sendMultiClaimForApprovalSbsn: Subscription;
	public stateStorageKey: string;
	private storedFiltersValue: any;
	private guid = new Guid();
	public filterValue: any[] = [];
	public filterText: string = '';
	public globalCurrency: string;
	public selectedRows: number[] = [];
	public decimalPointForCurrency: number;
	public chargebackBasisRadio: any[];
	public chargebackBasis: string;
	public chargebackPeriod: string;
	constructor(
		private _LoginService: LoginService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _RestApiService: RestApiService,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _CommissionService: CommissionService,
		private _GlobalStateService: GlobalStateService,
		private _IxDxAlertService: IxDxAlertService,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,
	) {
		this.doLoadViewMultiClaimApprovalSettlementDocument = this.doLoadViewMultiClaimApprovalSettlementDocument.bind(this);
		this.doSaveViewMultiClaimApprovalSettlementDocument = this.doSaveViewMultiClaimApprovalSettlementDocument.bind(this);
	}

	ngOnInit(): void {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.globalCurrency = this._LoginService.globalCurrency;
		this.mainSectionStorageKey = ServerSections.INCENTIVES_BILLBACK_CHARGEBACK_LISTING;
		this.stateStorageKey = ServerSections.MIN_MAX_CHARGEBACK_MULTIPLE_CLAIM_APPROVAL;
		this.chargebackBasisRadio = this._MinMaxEndUsersChargebackContractService.listOfCharegbackBasis;
		this.chargebackBasis = 'endusers';
		this.loadMultiClaimApprovalSettlementDataSource();
	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.getMultiCalimListSubscription) {
			this.getMultiCalimListSubscription.unsubscribe();
		}
		if (this.sendMultiClaimForApprovalSbsn) {
			this.sendMultiClaimForApprovalSbsn.unsubscribe();
		}
	}

	public doHiddenPopop() {
		this.isShowPopup = false;
		this.closeApproveMultiChargebackClaimPopup.emit();
	}
	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.multiClaimApprovalDataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.multiClaimApprovalDataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.multiClaimApprovalDataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	public onEditorPreparing(e) {
		if (e.parentType == 'headerRow' && e.command == 'select') {
			e.editorElement.remove();
		}
	}

	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
		}
		// toolbarItems.unshift(
		// 	{
		// 		location: 'after',
		// 		template: 'templateChargebackBasisRadio',
		// 		visible: true,
		// 	},
		// 	{
		// 		location: 'after',
		// 		template: 'templateChargebackPriodDateBox',
		// 		visible: true,
		// 	}
		// )
	}

	columnChooserClick() {
		this.multiClaimApprovalDataGridContainer.instance.showColumnChooser();
	}

	public doOnValueChangedSelectAll(e) {
		this.selectAllItem = e.value;
		if (e.value) {
			if (this.multiClaimApprovalDataGridContainer) {
				let claimGuidLst: any[] = [];
				if (this.multiClaimApprovalDataSource && this.multiClaimApprovalDataSource.length > 0) {
					this.multiClaimApprovalDataSource.map(item => claimGuidLst.push(item.guid));
				}
				this.selectedRows = claimGuidLst;
			}
		} else {
			if (this.multiClaimApprovalDataSource && this.multiClaimApprovalDataSource.length > 0) {
				if (this.multiClaimApprovalDataSource.length === this.selectedRows.length) {
					this.selectedRows = [];
					this.multiClaimApprovalDataGridContainer.instance.clearSelection();
				}
			}
		}
	}

	public doOnCellPreparedCloseMultipalTab(e) {
		// if (e.rowType === "data" && e.column.command === 'select') {
		// 	var editor = CheckBox.getInstance(e.cellElement.querySelector(".dx-select-checkbox"));
		// 	editor.option("disabled", true);
		// 	e.cellElement.style.pointerEvents = 'none';
		// }
	}

	public onMultiClaimApprovalSelectionChanged(e) {
		if (this.multiClaimApprovalDataSource && this.multiClaimApprovalDataSource.length > 0) {
			if (this.multiClaimApprovalDataSource.length === e.selectedRowsData.length) {
				this.selectAllItem = true;
			} else {
				this.selectAllItem = false;
			}
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.multiClaimApprovalDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	public refreshDataGridComponent() {
		this.multiClaimApprovalDataGridContainer.instance.refresh();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.multiClaimApprovalDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	private loadMultiClaimApprovalSettlementDataSource() {
		const cloneThis = this;
		const reqFormData = new FormData();
		let searchParams: any[] = [['chargebackBasis', '=', this.chargebackBasis], 'and', ['claimedStatus', 'draft']];
		if (this.chargebackPeriod) {
			const chargeback_period = moment(this.chargebackPeriod).format('YYYY-MM-DD') || '';
			searchParams.push('and');
			searchParams.push(['chargeback_period', '=', chargeback_period]);
		}
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.CHARGEBACKDOC);
		reqFormData.append('contracttype', 'minmaxenduserschargebackcontracts');
		reqFormData.append('filter', JSON.stringify(searchParams));

		if (this.getMultiCalimListSubscription) {
			this.getMultiCalimListSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.getMultiCalimListSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					if (response.data && response.data.length > 0) {
						this.multiClaimApprovalDataSource = response.data || [];
						this.selectedRows = [];
					} else {
						this.multiClaimApprovalDataSource = [];
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doSubmitClaimForApproval() {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Send For Approval',
			message: 'Are you sure you want to send for approval? <br/>You will not be able to undo the operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const selectedRowData = this.multiClaimApprovalDataGridContainer.instance.getSelectedRowsData();
				if (selectedRowData && selectedRowData.length > 0) {
					let claimGuidLst: any[] = [];
					selectedRowData.map(item => claimGuidLst.push(item.guid));
					if (claimGuidLst.length > 0) {
						this.doSendMultiCalimForApproval(claimGuidLst);
					}
				} else {
					this._ToastrService.info('Select atleast one settlement', 'Info', { closeButton: true, tapToDismiss: true });
				}
			}
		});
	}

	doSendMultiCalimForApproval(claimGuidLst: any[]) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SEND_MULTI_CLAIM_TO_APPROVAL);
		formData.append('claimids', JSON.stringify(claimGuidLst));
		formData.append('chargebackBasis', this.chargebackBasis);
		if (this.sendMultiClaimForApprovalSbsn) {
			this.sendMultiClaimForApprovalSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.sendMultiClaimForApprovalSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				if (this.multiClaimApprovalDataGridContainer && this.multiClaimApprovalDataGridContainer.instance) {
					// const selectedRow = this.multiClaimApprovalDataGridContainer.instance.getSelectedRowsData();
					// if (selectedRow.length > 0) {
					// 	this.multiClaimApprovalDataGridContainer.instance.deselectRows(selectedRow);
					// }
					this.multiClaimApprovalDataGridContainer.instance.clearSelection();
				}
				this.doHiddenPopop();
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}


	// #region for Datagrid State Storing functionality

	public doLoadViewMultiClaimApprovalSettlementDocument() {
		// this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveViewMultiClaimApprovalSettlementDocument(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// #endregion

	public doChangeChargebackBasis(e) {
		this.chargebackBasis = e.value;
		this.loadMultiClaimApprovalSettlementDataSource();
	}
	public doFilterDateOnValueChanged(e) {
		// const previousValue = e.previousValue;
		// const newValue = e.value;
		if (e) {
			if (e.value) {
				this.chargebackPeriod = e.value;
			} else {
				this.chargebackPeriod = null;
			}
		}
		this.loadMultiClaimApprovalSettlementDataSource();
	}
}