import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
	selector: 'app-close-current-settlement-period-confirm-dialog',
	templateUrl: './close-current-settlement-period-confirm-dialog.component.html',
	styleUrls: ['./close-current-settlement-period-confirm-dialog.component.css']
})
export class CloseCurrentSettlementPeriodConfirmDialogComponent implements OnInit, OnChanges, OnDestroy {
	@Input() currentStlmtPeriodProps: any;
	@Output() closeCurrentStlmtPeriodPopup = new EventEmitter<any>();
	@ViewChild('dxPopupRef', { static: false }) dxPopupRef: DxPopupComponent;
	public isShowPopup: boolean;
	public popupUI: any = {
		showTitle: false,
		title: null,
		subtitle: null,
		message: null,
		popupIconsHtml: null,
		popupIcon: null,
		dialogOpenFrom: null
	};
	public document_date: any;

	constructor() { }

	ngOnInit() {
		this.isShowPopup = true;
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.dxPopupRef.instance.registerKeyHandler('escape', (e) => {
				e.stopPropagation();
			});
		});
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['currentStlmtPeriodProps']) {
			const currentStlmtPeriodProps = _SimpleChanges['currentStlmtPeriodProps'].currentValue;
			this.popupUI = currentStlmtPeriodProps;
		}
	}


	ngOnDestroy(): void {

	}

	public doOkBtn() {
		this.closeCurrentStlmtPeriodPopup.emit({
			isClickOnCloseBtn: false,
			isClickOnOkayBtn: true,
			document_date: this.document_date ? this.document_date : null
		});
		this.isShowPopup = false;
	}

	public doCancelBtn() {
		this.closeCurrentStlmtPeriodPopup.emit({
			isClickOnCloseBtn: true,
			isClickOnOkayBtn: false,
		});
		this.isShowPopup = false;
	}

}
