<!-- [height]="addEditPopupHeight | heightwidthRatio : 70:'Y'" -->
<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width80:'X'" [showTitle]="true"
	[dragEnabled]="true" [hideOnOutsideClick]="false" title="Sales Person List" [showCloseButton]="true"
	[(visible)]="isShowSalesPersonListPopup" class="paymentInfo" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="row mrgT10">
				<div class="cols4">
					<div class="dx-field">
						<div class="dx-field-label">Contract Start Date</div>
						<div class="dx-field-value">
							<dx-date-box [(value)]="startDate" (onValueChanged)="compareTwoDates()" type="date"
								displayFormat="yyyy-MM-dd">
							</dx-date-box>
						</div>
					</div>
				</div>
				<div class="cols4">
					<div class="dx-field">
						<div class="dx-field-label">Contract End Date</div>
						<div class="dx-field-value">
							<dx-date-box [(value)]="endDate" type="date" displayFormat="yyyy-MM-dd"
								(onValueChanged)="compareTwoDates()">
							</dx-date-box>
						</div>
					</div>
				</div>
			</div>
			<div class="mrgT20">
				<dx-data-grid #listOfSalesPersonDataGrid [dataSource]="salesPersonDataSource" keyExpr="sub_account_id"
					[columns]="salespersonGridBoxColumns" [hoverStateEnabled]="true" [columnAutoWidth]="true"
					[width]="'100%'" [showColumnHeaders]="true" [showBorders]="true" [showColumnLines]="true"
					[showRowLines]="false" [allowColumnResizing]="true"
					class="cellColor hoverClr hideFilterPanelInx dxDatagridHeightPopup">
					<dxo-selection mode="multiple"></dxo-selection>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-scrolling mode="virtual" [useNative]="false"></dxo-scrolling>
				</dx-data-grid>
			</div>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button *ngxPermissionsOnly="['INCENTIVE_COMMISSION_MODIFY']" class="primaryBtn button" icon="save"
				text="Confirm" (onClick)="doClickSaveSalesPerson()" [useSubmitBehavior]="false" type="normal"
				stylingMode="contained">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>