import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DATETIME_FORMAT_BACKEND, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { ContractLifeCycleValEnum, DistributionChannelsCallFromViewEnum, ErpTypeValEnum, NameFromServerKeyEnum, RebateApprovalStatusValEnum, RebateStatusValEnum, SettlementPeriodsLblEnum, SettlementPeriodsValEnum } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { CustomerTabModel, MemberTabModel, RebatesContractModelRequest, RebatesContractModelUI, RuleTabModel, ItemsAndGroupsTabModel, ItemsAndGroupsListItmsGrpsTabModel, ExclusionListExclusionTabModel, EndUserShippingAddressInterface } from '@app/models/rebates-contract.model';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import * as _ from 'underscore';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { DxDataGridComponent, DxDropDownBoxComponent, DxRadioGroupComponent } from 'devextreme-angular';
import { environment } from "@environments/environment";
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { AppsConfigsUI, CurrencyOptions, PageOptions } from '@app/models/common.model';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { DistributionChannelsDxDropDownBoxModel } from '@app/models/input-Components.model';

@Component({
	selector: 'app-rebates-tierbase-contract',
	templateUrl: './rebates-tierbase-contract.component.html',
	styleUrls: ['./rebates-tierbase-contract.component.css'],
	providers: [CustomCurrencyPipe]
})
export class RebatesTierbaseContractComponent implements OnInit, OnDestroy, AfterContentChecked {

	//#region Local Variable

	// Comman public object
	public globalCurrency: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public isAddMode: boolean = true;
	public editRebatesGuid: string;
	public currentAgreementTab: string;
	public searchTimeout: any;
	public btnRulesAdd: string = 'Add';
	public rulesAddOrEditIndex: string = '-1';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public isNetsuiteErpUser: boolean = false;
	public enable_classification_for_rebate: boolean = false; // Use For NETSUTE Erp Manage in system config
	public erp_type: string;
	public currentMainTab: string;
	public chkAddOrUpdateRule: string = ''; // use for stop rule object overright on edit time
	public ErpTypeValEnum = ErpTypeValEnum;
	public isUpdateSettlement: boolean = false;
	public isAllSettlmentClosed: boolean = false;
	// Drop down list object
	public salesPplList: any = [];
	public GroupsList: any = [];

	public selectCustomerSearchTabData: any = [];
	public selectMemberSearchTabData: any = [];
	public closecontractsArray: any = [];

	// Agreement tab table list object
	public vendorsList: any = [];
	public customerTabList: any = [];
	public ruleTabList: any[] = [];

	// Agreement tab drop down list select all checkbox object
	public isCustomerSearchTabAllSelected: boolean = false;
	public isMemberSearchTabAllSelected: boolean = false;
	public isAllCustomerSelectedView: boolean = false;
	public isAllMemberSelectedView: boolean = false;
	public isViewIERuleRuleRate: boolean = true;
	public isViewBounsAmountCriterion: boolean = true;
	public isViewRateRuleCriterion: boolean = true;
	public isViewBonusRateCriterion: boolean = true;

	// Api call request object
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;

	// Model object
	public guid = new Guid();
	public rebatesDetails = new RebatesContractModelUI();
	public rebatesRequest = new RebatesContractModelRequest();
	public appsConfigsUI: AppsConfigsUI = new AppsConfigsUI();

	public listOfRebateTypes: any[] = this._RebateService.getlistOfRebateFor;

	public listOfRebateSubTypes: any[] = this._RebateService.getListOfRebateType;

	public listOfSettlementPeriods: any[] = this._RebateService.getListOfSettlementPeriods;

	public listOfPaymentTypes: any[] = this._RebateService.getListOfPaymentTypes;

	public listOfComparisonYear: any[] = [];
	public customerTabModel = new CustomerTabModel();
	public memberTabModel = new MemberTabModel();
	public ruleTabModel = new RuleTabModel();
	public isDisabledFields = false;
	public productSearchItemType: string;

	private getUomLstSbsn: Subscription;
	public listOfUoms: any[] = [];
	private getUomGroupLstSbsn: Subscription;
	private listOfUomGroups: any[] = [];
	private getItemDetailsSbsn: Subscription;


	//#endregion

	//#region for  Item/Group Tab
	public itemsOrGroupsRadioBtn: string;
	public isSelectAllItemsOrGroupsChkboxBtn: boolean;
	public itemsAndGroupsTabModel = new ItemsAndGroupsTabModel();
	public getSearchedItemsLstItmOrGrpTab: any[] = [];
	private getItemsLstSbsn: Subscription;
	public listOfRebateBasis: any[] = [];
	public listOfTierBasis: any[] = [];
	public dataSourceItemsAndGroupsLstItmGrpTab: any[] = [];
	private listOfItemsAndGroupsItmGrpTab: any[] = [];
	@ViewChild("itemsAndGroupsitmGrpTabDataGridRef", {}) itemsAndGroupsitmGrpTabDataGridRef: DxDataGridComponent;
	@ViewChild("tiersItemDetailssDataGridRef", {}) tiersItemDetailssDataGridRef: DxDataGridComponent;
	public filterText: string = '';
	public pageOptions: PageOptions = new PageOptions();
	public listOfCsvRowForItmGrpTab: any[] = [];
	private csvResponse: any;
	public isUploadExcelCsvFileItmGrpTab: boolean = false;
	public noOfUnmatchedItem: number;
	public noOfUnmatchedItemUom: number;
	public noOfmatchedItemPrice: number;
	public noOfTotalItem: number;
	private isImportBtnItmGrpTab: boolean;
	public isShowListPriceColumnItmGrpTab: boolean = false;
	public isShowFinalPriceColumnItmGrpTab: boolean = false;
	public isShowTierListPriceColumnItmGrpTab: boolean = false;
	public isShowTierFinalPriceColumnItmGrpTab: boolean = false;
	private getPriceLstSbsn: Subscription;
	public listOfPrices: any[] = [];

	@ViewChild('refItemsLstItmGrpTabDrpDwn') refItemsLstItmGrpTabDrpDwn: DxDropDownBoxComponent;
	@ViewChild('refItemsLstItmGrpTabDg') refItemsLstItmGrpTabDg: DxDataGridComponent;
	public columnsItemsItmGrpTabDg: any[] = [];
	public itemsLstItmGrpTabDsDrpDwn: any;
	public sItemsLblItmGrpTabDrpDwn: string;
	public aSelKeysItemsItmGrpTabDg: any[] = [];
	public aSelItemsItmGrpTabDg: any[] = [];
	public aItemsLstItmGrpTabDrpDwnTagBox: any[] = [];
	public isCheckedAllItemsItmGrpTabDrpDwnChkBox: boolean = false;

	@ViewChild("refClassesDrpDwn", {}) refClassesDrpDwn: DxDropDownBoxComponent;
	@ViewChild("refclassesDG", {}) refclassesDG: DxDataGridComponent;
	public classesDataSource: any;
	public classesGridBoxColumns: any;
	public chkAllClasses: boolean = false;
	public lengthSelectedClasses: any;
	public arrSelectedClassesList: any = [];
	public classesGridBoxValue: any[] = [];
	public selectedClassesData: any = [];
	//#endregion

	// #region  for Item/Group for BuyingGroup
	@ViewChild("membersListExclsnTabDataGridRef", {}) membersListExclsnTabDataGridRef: DxDataGridComponent;
	public dataSourceMembersLstExclsnTab: any[] = [];
	public listOfSearchedMembersExclsnTab: any[] = [];
	public itemsOrGroupsRadioBtnExclsnTab: string = 'item';
	public listOfMembersItemsExclsnTab: any[] = [];
	public isSelectAllItemsOrGroupsChkboxBtnExclsnTab: boolean;
	public listOfItemsDataSourceColumn: Object;
	private currentItemDetailsExclsnTab: any;

	@ViewChild('refMembersLstExclsnTabDrpDwn') refMembersLstExclsnTabDrpDwn: DxDropDownBoxComponent;
	@ViewChild('refMembersLstExclsnTabDg') refMembersLstExclsnTabDg: DxDataGridComponent;
	public columnsMembersExclsnTabDg: any[] = [];
	public membersLstExclsnTabDsDrpDwn: any;
	public sMembersLblExclsnTabDrpDwn: string;
	private aSelMembersExclsnTabDg: any[] = [];
	public aSelKeysMembersExclsnTabDg: any[] = [];
	public aMembersLstExclsnTabDrpDwnTagBox: any[] = [];
	public isCheckedAllMembersExclsnTabDrpDwnChkBox: boolean = false;

	@ViewChild('refItemsLstExclsnTabDrpDwn') refItemsLstExclsnTabDrpDwn: DxDropDownBoxComponent;
	@ViewChild('refItemsLstExclsnTabDg') refItemsLstExclsnTabDg: DxDataGridComponent;
	public columnsItemsExclsnTabDg: any[] = [];
	public itemsLstExclsnTabDsDrpDwn: any;
	public sItemsLblExclsnTabDrpDwn: string;
	public aSelKeysItemsExclsnTabDg: any[] = [];
	public aSelItemsExclsnTabDg: any[] = [];
	public aItemsLstExclsnTabDrpDwnTagBox: any[] = [];
	public isCheckedAllItemsExclsnTabDrpDwnChkBox: boolean = false;

	@ViewChild("refClassesExclsnTabDrpDwn", {}) refClassesExclsnTabDrpDwn: DxDropDownBoxComponent;
	@ViewChild("refclassesExclsnTabDG", {}) refclassesExclsnTabDG: DxDataGridComponent;
	public classesDataSourceExclsnTab: any;
	public classesGridBoxColumnsExclsnTab: any;
	public chkAllClassesExclsnTab: boolean = false;
	public lengthSelectedClassesExclsnTab: any;
	public arrSelectedClassesListExclsnTab: any = [];
	public classesGridBoxValueExclsnTab: any[] = [];
	public selectedClassesDataExclsnTab: any = [];

	// #endregion

	//#region for Classes select box |  only show while ERP type NETSUITE
	private loadClassesDataList() {
		// const viewFields = ['erp_primary_key', 'classesku', 'classname', 'description'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.IX_CLASSIFICATIONS
		};
		this.classesDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "name",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}
	onClassesValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedClassesList = [];
			this.classesGridBoxValue = [];
			this.refclassesDG.instance.refresh();
			this.chkAllClasses = false;
			this.lengthSelectedClasses = '';
		}
	}

	public doOpenedDropdownClassesValue(event: any) {
		this.refclassesDG.instance.clearFilter();
	}

	public doDisplayExprClassesText(event: any) {
		let listOfText: any[] = [];
		if (this.chkAllClasses) {
			listOfText.push('All Classes');
		}
		if (this.arrSelectedClassesList.length > 0) {
			listOfText.push(this.arrSelectedClassesList.length + ' Classes');
		}
		return listOfText.length > 0 ? listOfText.join(', ') : '';
	}

	checkAllClasses() {
		this.setClassesSelctedText();
	}
	onClassesSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.classesGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.classesGridBoxValue.push(e.selectedRowsData[i].id);
			};
		}
		this.setClassesSelctedText();
	}
	setClassesSelctedText() {
		let lengthSelectedClasses = '';
		this.lengthSelectedClasses = '';
		if (this.arrSelectedClassesList && this.arrSelectedClassesList.length > 0) {
			lengthSelectedClasses = this.arrSelectedClassesList.length + ' Classes Selected';
		}

		if (this.chkAllClasses) {
			lengthSelectedClasses = 'All Classes ' + (lengthSelectedClasses ? ', ' : '') + (lengthSelectedClasses || '');
		}
		this.lengthSelectedClasses = lengthSelectedClasses;
	}
	//#endregion

	// #region for Member Tab
	@ViewChild("membersListMemberTabDataGridRef", {}) membersListMemberTabDataGridRef: DxDataGridComponent;
	public memberTabList: any = [];
	public dataSourceMemberLstMemberTab: any;
	public settlementPeriodsValEnum = SettlementPeriodsValEnum;
	public settlementPeriodsLblEnum = SettlementPeriodsLblEnum;
	public listOfMemberModes: any[] = this._RebateService.getListOfMemberModes;
	// #endregion

	// #region for Rule Tab

	public listOfRuleBonusTypes: any[] = this._RebateService.getListOfRuleBonusTypes;

	public listOfInclusionExclusionRuleConditions: any[] = [
		{ text: 'Include in Calculation', value: 'include' },
		{ text: 'Exclude in Calculation', value: 'exclude' },
	];
	public listOfRateRuleConditions: any[] = [
		{ text: 'Miscellaneous Deduction - Inventory Item', value: 'miscellaneousDeductionInventory' },
	];
	public listOfBonusRateConditions: any[] = [
		{ text: 'New Account', value: 'newAccount' },
		{ text: 'Total Per Settlement Period Greater Than', value: 'totalPerSettlementPeriodGreaterThan' },
		{ text: 'Gross Profit Per Settlement Period Greater Than', value: 'grossProfitPerSettlementPeriod' },
		{ text: 'Number of Unique SKUs Greater Than', value: 'numberOfuniqueSKUsGreaterThan' },
		{ text: 'No. SKUs Shipped In Settlement Period Greater Than', value: 'SKUsShippedInSettlementPeriod' },
	];

	public listOfInclusionExclusionRuleCriterions: any[] = [
		{ text: 'Unpaid Invoices ', value: 'unpaidInvoices' },
		{ text: 'Billbacks / Chargebacks', value: 'billbacksChargebacks' },
		{ text: 'Cash Discounts', value: 'cashDiscounts' },
		{ text: 'Shipping Costs', value: 'shippingCosts' },
	];

	public listOfExclusionsForBuyingGroup: any[] = [
		{ text: 'Exclusion by Items', value: 'item' },
		{ text: 'Exclusion by Groups', value: 'group' },
	];

	public listOfExclusionsForIndividualAndGrowthIncentive: any[] = [
		{ text: 'Items', value: 'itemsRadioBtn' },
		{ text: 'Groups', value: 'groupsRadioBtn' },
	];

	public listOfContractCalendar: any[] = [
		{ text: 'Fiscal Year', value: 'fiscal_year' },
		{ text: 'Calendar Year', value: 'calendar_year' },
		{ text: 'Contract Year', value: 'contract_year' },
	];
	@ViewChild('rulesListRulesTabDataGridRef') rulesListRulesTabDataGridRef: DxDataGridComponent;
	//#endregion 

	public isShowCodeColumn: boolean = false;
	// #region for Customer dropdown 
	@ViewChild('listOfCrmDataGrid') listOfCrmDataGrid: DxDataGridComponent;
	public customersLst: any = [];
	public crmDataFilterValue: any[] = [];
	public isCustomerGridBoxOpened: boolean = false;
	public isHumanReadableAccountCode: boolean = false;
	public sCustomersLbl: string;
	public aSelKeysCustomers: any[] = [];
	public aSelKeysCustomersDetails: any[] = [];
	public NameFromServerKeyEnum = NameFromServerKeyEnum;

	@ViewChild('listOfContactEmployeeDataGrid') listOfContactEmployeeDataGrid: DxDataGridComponent;
	public sContactEmployeesLbl: string;
	public isOpenedContactEmployeeDrpDwn: boolean = false;
	public contactEmployeesLst: any[] = [];
	public aSelKeysContactEmployees: any[] = [];
	public aSelKeysContactEmployeesDetails: any[] = [];
	public isDisabledContactPerson: boolean = false;

	// #engregion

	// #region for Buyinggroup dropdown
	@ViewChild('listOfBuyingGroupDataGrid') listOfBuyingGroupDataGrid: DxDataGridComponent;
	public buyingGroupsLst: any = [];
	public buyingGroupDataFilterValue: any[] = [];
	public isBuyingGroupGridBoxOpened: boolean = false;
	public sBuyingGroupsLbl: string;
	public aSelKeysBuyingGroup: any[] = [];
	public aSelKeysBuyingGroupDetails: any[] = [];
	// #engregion

	// #region for Vendor dropdown
	@ViewChild('listOfVendorDataGrid') listOfVendorDataGrid: DxDataGridComponent;
	public vendorsLst: any = [];
	public vendorDataFilterValue: any[] = [];
	public isVendorGridBoxOpened: boolean = false;
	public sVendorsLbl: string;
	public aSelKeysVendor: any[] = [];
	public aSelKeysVendorsDetails: any[] = [];
	// #engregion

	public RebateStatusValEnum = RebateStatusValEnum;
	public isShowMultipleBtns: boolean = false;
	public isShowArrowIconDrpDwnBtn: boolean = false;
	public isEnabledDocSign: boolean = false;
	public isShowContactEmployeeDrpDwn: boolean = false;
	public listOfRebateStatusesBtn: any[] = [];
	public listOfRebateStatuses = [];

	public isEnabledItemAndGroupDatagrid: boolean = true;
	public isEnabledTiersDatagrid: boolean = true;
	public isEnabledMembersDatagrid: boolean = true;
	public isEnabledRulesDatagrid: boolean = true;
	public isEnabledStartDate = true;
	public isEnabledEndDate = true;
	public isEnabledSettlemenPeriod = true;
	public isEnabledCloseCurrentSettlmentPeriodBtn = true;
	public isEnabledContractName = true;
	public isEnabledTermsAndConditions = true;
	public isEnabledGenerateCreditMemosForExistingInvoicesBtn = true;
	private copyToContractSbsn: Subscription;
	private downloadSignedContractSbsn: Subscription;

	public contractLifecycleManagement: string;
	public isShowContractLifecycleManagementChkBox: boolean = false;
	public isDisabledContractLifeCycleManagementChkbox: boolean = false;
	public actionFrom: string;
	public isDisabledCustomerName = false;
	public isShowBlockSettlementPeriods: boolean = true;
	private closeSettlementReportSbsn: Subscription;
	public isChangeTermsConditionTabTxt: boolean = false;
	private generateAutoPaymentCreditMemoSbsn: Subscription;
	public decimalPointPercentageFormat: string = '1.0-0';
	public viewRebateMembersListProps: any;
	public isShowViewRebateMembersListPopup: boolean;
	public isShowViewRebateCreditMemoForPastInvoicesListPopup: boolean;
	public viewRebateCreditMemoForPastInvoicesListProps: any;
	public isUploadRebateExclusionItemsPopup: boolean;
	public uploadRebateExclusionItemsProps: any;
	public isViewPreviousSettlementPeriodPopup: boolean;
	public viewPreviousSettlementPeriodProps: any;
	public globalCurrencySymbol: string;

	public tier_mode?: string;
	public tier_accrual_period?: string;
	public tiers_include?: string;
	public listOfTierModes: any[] = [
		{ text: 'Absolute', value: 'absolute' },
		{ text: 'Marginal', value: 'marginal' },
	];
	public listOfTierAccrualPeriods: any[] = [
		{ text: 'Weekly', value: 'weekly', visible: true },
		{ text: 'Monthly', value: 'monthly', visible: true },
		{ text: 'Quarterly', value: 'quarterly', visible: true },
		{ text: 'Bi-annually', value: 'halfYearly', visible: true },
		{ text: 'Annually', value: 'yearly', visible: true },
	];
	public listOfTiersInclude: any[] = [
		{ text: 'Only items in tier', value: 'only_items_in_tier' },
		{ text: 'All items in contract', value: 'all_items_in_contract' }
	];
	public listOfTiersType: any[] = [
		{ text: 'Single Item', value: 'single_items' },
		{ text: 'Multiple Item', value: 'multiple_items' },
	];
	public listOfCustomerItemMatch: any[] = [
		{ text: 'Exclusive', value: 'exclusive' },
		{ text: 'Inclusive', value: 'inclusive' },
	];

	public isShowTierAccrualPeriodField: boolean;
	public isShowTierAccrualPeriods: boolean;
	public enable_profit_on_document_header: boolean = false;
	private btnImportInstance: any;
	private isDisabledImportExcelCsv: boolean = false;
	public enable_rebate_settlement_approval_workflow: boolean = false;
	public isDisabledSendCurrentSettlementForApprovalBtn: boolean;
	public isDisabledTierType: boolean;
	public isDisabledCustomerItemMatch: boolean;
	public isDisabledTierInclude: boolean;
	public isDisabledBuyingGroupName: boolean;
	public isDisabledVendorName: boolean;
	public isShowPricelistDrpDwn: boolean;
	public isShowDifferentialPricelistDrpDwn: boolean;
	public isDisabledPricelistDrpDwn: boolean;
	private listOfClosedSettlementReports: any[] = [];

	@ViewChild('refPricelistDrpDwn') refPricelistDrpDwn: DxDataGridComponent;
	public sPricelistLbl: string;
	public listOfPriceLists: any;
	public isPricelistGridBoxOpened: boolean;
	public isShowPricelistCodeColumn: boolean;
	public pricelistFilterValue: any[] = [];
	public aSelKeysPricelistNo: string[] = [];
	private aSelKeysPricelistDetails: any[] = [];

	@ViewChild('refDifferentialPricelistDrpDwn') refDifferentialPricelistDrpDwn: DxDataGridComponent;
	public differentialPricelistFilterValue: any[] = [];
	public aSelKeysDifferentialPricelistNo: string[] = [];
	private aSelKeysDifferentialPricelistDetails: any[] = [];
	private listOfFeaturesCustomFields: any[] = [];
	public listOfInPricelist: any[] = [{ value: true, text: 'Yes' }, { value: false, text: 'No' }];
	public decimalPointForCurrency: number;
	public isDifferentialPricelistGridBoxOpened: boolean;

	@ViewChild("refEndUserDrpDwn", {}) refEndUserDrpDwn: DxDropDownBoxComponent;
	@ViewChild('refEndUserLstItmGrpTabDrpDwn') refEndUserLstItmGrpTabDrpDwn: DxDataGridComponent;
	public columnsForListOfEndUserItemsItmGrpTabDg: any[] = [];
	public listOfEndUserItmGrpTabDsDrpDwn: any;
	public sEndUserLblItmGrpTabDrpDwn: string;
	public aSelKeysEndUserItmGrpTabDg: any[] = [];
	public aSelEndUserDetailsItmGrpTabDg: any[] = [];
	public aEndUserItmGrpTabDrpDwnTagBox: any[] = [];
	public isCheckedAllEndUserItmGrpTabDrpDwnChkBox: boolean = false;
	private mainSectionStorageKey = ServerSections.INCENTIVES_LISTING_SETTING;
	public stateStorageKey = ServerSections.INCENTIVES_REBATES_DETAILS_INDIVIDUAL;
	private updateUserSettingSbsn: Subscription;
	public currentStlmtPeriodProps: any;
	public isViewCloseCurrentSettlementPeriodPopup: boolean;
	private document_date_from_confirm_popup: any;
	public distributionChannelsModel: DistributionChannelsDxDropDownBoxModel = new DistributionChannelsDxDropDownBoxModel();
	public isEnableDistributionChannels: boolean;
	public enable_other_currencies: boolean = false;
	public listOfOtherCurrency: any[] = [];
	public listOfCurrency: any[] = [];
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();

	// #region Angular Life Cycle Methods
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _ToastrService: ToastrService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _DataService: DataService,
		private _RebateService: RebateService,
		private _GlobalStateService: GlobalStateService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
		private _ChangeDetectorRef: ChangeDetectorRef,
		private _IxDxAlertService: IxDxAlertService,
		private _CrmsService: CrmsService) {
		this.doDisplayExprItemsText = this.doDisplayExprItemsText.bind(this);
		this.doDisplayExprItemsItmGrpTabText = this.doDisplayExprItemsItmGrpTabText.bind(this);
		this.doDisplayExprClassesText = this.doDisplayExprClassesText.bind(this);
		this.doDisplayExprMembersText = this.doDisplayExprMembersText.bind(this);
		this.doDisplayExprEndUserItmGrpTabText = this.doDisplayExprEndUserItmGrpTabText.bind(this);
		this.doLoadRebateItemsState = this.doLoadRebateItemsState.bind(this);
		this.doSaveRebateItemsState = this.doSaveRebateItemsState.bind(this);
	}

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.editRebatesGuid = this._RebateService.getEditRebatesGuid();
		this.actionFrom = this._RebateService.getActionFrom();
		this.doInitForRebatesDetailsTab();
		this.getCustomerData();
	}

	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		if (this.getItemsLstSbsn) {
			this.getItemsLstSbsn.unsubscribe();
		}
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		if (this.copyToContractSbsn) {
			this.copyToContractSbsn.unsubscribe();
		}
		if (this.downloadSignedContractSbsn) {
			this.downloadSignedContractSbsn.unsubscribe();
		}
		if (this.closeSettlementReportSbsn) {
			this.closeSettlementReportSbsn.unsubscribe();
		}
		if (this.generateAutoPaymentCreditMemoSbsn) {
			this.generateAutoPaymentCreditMemoSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.unsubscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT');
		this._GlobalStateService.unsubscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.unsubscribe('ADD_BULK_MEMBERSITEMS_TO_ITMGRP_TAB_LIST_EVENT');
	}

	ngAfterContentChecked() {
		this._ChangeDetectorRef.detectChanges();
	}

	//#endregion

	// #region preInitialize method
	doInitForRebatesDetailsTab() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.appsConfigsUI.erpDataStartdate = this._LoginService.loginUser.account_detail.app_settings.erp_data_start_date;
		this.appsConfigsUI.fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start;
		this.enable_classification_for_rebate = this._LoginService.loginUser.account_detail.enable_classification_for_rebate;
		this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;

		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		if (this.enable_other_currencies) {
			if (this._LoginService.loginUser.account_detail.currency_lst.length > 0) {
				this.listOfCurrency = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail.currency_lst));
			}
			if (this._LoginService.loginUser.account_detail.other_currencies_lst.length > 0) {
				this.listOfOtherCurrency = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail.other_currencies_lst));
			}
			this.addPrimaryCurrency();
		}

		this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.rebatesDetails);
		this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;

		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_distribution_channels')) {
			this.isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels || false;
		}
		if (this.isEnableDistributionChannels) {
			this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
			this.distributionChannelsModel.callFromView = DistributionChannelsCallFromViewEnum.ADD_EDIT_REBATE;
			this.distributionChannelsModel.selectionMode = 'single';
			this.distributionChannelsModel.showSelectionControls = false;
			this.distributionChannelsModel.applyValueMode = 'instantly';
		}

		this.setErpType();
		this.setEnabledDocuSignFeatures();
		this.setAllPermissions();
		this.setProductSearch();
		this.doShowColumnPricelistDrpDwn();
		this.listOfFeaturesCustomFields = this._AppCommonSrvc.getFeatureCustomFields();

		this.salesPplList = this._DataService.getSalesPpl();
		this.GroupsList = this._DataService.getGroups();
		this.vendorsList = this._DataService.getVendors();
		this.doCheckEnabledRebateSettlementApprovalWorkflowFeature();

		if (this.enable_classification_for_rebate) {
			this.listOfTierBasis = this._RebateService.getTierBasisForClasses();
			this.listOfRebateBasis = this._RebateService.getRebateBasisForClasses(this.globalCurrencySymbol);
		} else {
			this.listOfTierBasis = this._RebateService.getTierBasis();
			this.listOfRebateBasis = this._RebateService.getRebateBasis(this.globalCurrencySymbol);
		}
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.getDynamicYearsOfErpDataStartDate();
		this.getListOfPricelistDataSource();
		this.doSetListOfEndUserColumnsForDG();
		this.doFetchListOfEndUsersDataSource();
		// Check Edit Mode
		const editRebatesGuid = this._RebateService.getEditRebatesGuid();
		if (editRebatesGuid) {
			this.rebatesRequest.guid = editRebatesGuid;
			this.editRebatesGuid = editRebatesGuid;
			this.editRebatesContractObject();
		} else {
			this.currentMainTab = 'rebates_details';
			this.addRebatesContractObject();
		}
		// Disable rebates inputbox
		// this.disableRebatesInputFields();
		let document_template_docusign_rebate = '';
		try {
			document_template_docusign_rebate = this._LoginService.loginUser.account_detail.app_settings.document_template_docusign_rebate;
		} catch (e) {
			document_template_docusign_rebate = '';
		}
		this.isChangeTermsConditionTabTxt = false;
		if (document_template_docusign_rebate === 'user_template') {
			this.isChangeTermsConditionTabTxt = true;
		}
	}

	// Method used to set Erp Type
	private setErpType() {
		// get current user Erp type
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		// check Is QB User (no need to view code of QB user)
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		if (this.enable_classification_for_rebate) {
			this.isNetsuiteErpUser = true;
			this.classesGridBoxColumns = [
				{ dataField: 'guid', caption: 'Guid', visible: false },
				{ dataField: 'id', caption: 'Id', visible: false },
				{ dataField: 'name', caption: 'Classes' }
			];
		}
	}

	private doCheckEnabledRebateSettlementApprovalWorkflowFeature() {
		let isEnabledRebateSettlementApprovalWorkflow = false;
		try {
			isEnabledRebateSettlementApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_rebate_settlement_approval_workflow;
		} catch (e) {
			isEnabledRebateSettlementApprovalWorkflow = false;
		}
		this.enable_rebate_settlement_approval_workflow = isEnabledRebateSettlementApprovalWorkflow;
	}

	// Method used to check and set Document Sign features Enabled/Disabled
	private setEnabledDocuSignFeatures() {
		try {
			this.isEnabledDocSign = this._LoginService.loginUser.account_detail.is_docusign_enabled || false;
			this.contractLifecycleManagement = this._LoginService.loginUser.account_detail.contract_lifecycle_management;
		} catch (e) {
			this.isEnabledDocSign = false;
			this.contractLifecycleManagement = '';
		}

		try {
			this.listOfRebateStatuses = this._LoginService.loginUser.account_detail.rebate_status_list || [];
		} catch (e) {
			this.listOfRebateStatuses = [];
		}
	}

	// Method used to check and set permissions
	private setAllPermissions() {
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}

		if (permsn.indexOf('INCENTIVE_REBATES_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_REBATES_MODIFY';
		}
	}

	// Method used to check product_search logic type (item/price)
	private setProductSearch() {
		let productSearchList = [];
		try {
			productSearchList = this._LoginService.loginUser.account_detail.app_settings.product_search || [];
		} catch (e) {
			productSearchList = [];
		}
		const productSearchIndex = productSearchList.findIndex(item => item.key === 'rebate');
		this.productSearchItemType = (productSearchIndex !== -1) ? productSearchList[productSearchIndex].type : '';
	}

	// Method used to set dynamic years
	private getDynamicYears(givenYear?: number) {
		const max = new Date().getFullYear();
		const min = max - givenYear || 1;
		const years = [];
		for (let i = max; i >= min; i--) {
			years.push({ text: i.toString(), value: i.toString() });
		}
		return years;
	}
	// #endregion

	// Method used to set dynamic years
	public getDynamicYearsOfErpDataStartDate() {
		if (this.appsConfigsUI && this.appsConfigsUI.erpDataStartdate && this.appsConfigsUI.fiscalYearStart) {
			this.listOfComparisonYear = [];
			let monthList = this._RebateService.getMonth();
			let erpDataStartDateYear: any = parseInt(moment(this.appsConfigsUI.erpDataStartdate).format('YYYY').toString());
			let erpDataStartDateMonth: any = parseInt(moment(this.appsConfigsUI.erpDataStartdate).format('MM').toString());
			let fiscalYearStart: any = this.appsConfigsUI.fiscalYearStart;
			let fiscalYearStartMonthText, fiscalYearEndMonthText;
			const indexMonth = monthList.findIndex(i => i.value === fiscalYearStart.toString());
			if (indexMonth !== -1) {
				fiscalYearStartMonthText = monthList[indexMonth].text;
				fiscalYearEndMonthText = indexMonth === 0 ? monthList[11].text : monthList[indexMonth - 1].text;
			}
			let startYear: number = erpDataStartDateYear;
			let endYear = new Date().getFullYear() - 1;

			if (this.rebatesDetails.startDate) {
				const startDateMonth = moment(this.rebatesDetails.startDate).format('MM');
				const startDateYear = moment(this.rebatesDetails.startDate).format('YYYY');
				endYear = parseInt(startDateYear.toString()) - 1;
			}
			const years = [];
			for (let i = endYear; i >= startYear; i--) {
				const val: any = i.toString() + ' (' + fiscalYearStartMonthText + ' ' + i + ' - ' + fiscalYearEndMonthText + ' ' + (i + 1) + ')'
				years.push({ text: val, value: i.toString() });
			}
			this.listOfComparisonYear = years;
		}
	}
	// #endregion

	// #region rebates Contract Common Methods
	// click main tab
	clickMainTab(tabName) {
		this.currentMainTab = tabName;
		if (this.currentMainTab === 'rebates_details') {
			setTimeout(() => {
				this.doInitItemsAndGroupsTab();
			}, 200);
		}
		if (this.editRebatesGuid) {
			setTimeout(() => {
				if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
					if (this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
						this.checkDifferentListPrice();
					}
				}
			}, 550);
		}
	}

	// Set default value on add mode
	private addRebatesContractObject() {
		this.isAddMode = true;
		this.editRebatesGuid = '';
		this.rebatesRequest.guid = '';
		// this.currentAgreementTab = 'customers';
		this.rebatesDetails.settlementPeriod = SettlementPeriodsValEnum.MONTHLY;
		this.rebatesDetails.individualOrGroup = 'individual';
		this.rebatesDetails.rebateType = 'purchasing';
		this.rebatesDetails.paymentType = 'buyinggroup';
		this.rebatesDetails.member_mode = 'single_member';
		this.itemsOrGroupsRadioBtn = 'itemsRadioBtn';
		this.rebatesDetails.comparison_year = new Date().getFullYear().toString();
		this.isDisabledCustomerName = false;
		this.isDisabledPricelistDrpDwn = false;
		this.isDisabledBuyingGroupName = false;
		this.isDisabledVendorName = false;
		this.rebatesDetails.contractCalendar = 'fiscal_year';
		this.rebatesDetails.startSettlementPeriodOn = '1';

		this.rebatesDetails.tiers_type = 'single_items';
		this.rebatesDetails.customer_item_match = 'exclusive';
		this.rebatesDetails.tier_mode = 'absolute';
		this.setDefaultTiersInclude();
		if (this.actionFrom === 'crms') {
			this.preInitForCrm();
		}
		this.stateStorageKey = ServerSections.INCENTIVES_REBATES_DETAILS_INDIVIDUAL;
		this.changeIndividualOrGroup();
		setTimeout(() => {
			this.fetchedListOfVendors();
			this.setColumnForVendorGrid();
		}, 250);
		this.doCheckStatusBtnForDocSign();
		this.setStatusForRebate();
		this.setSubmitMultiPleBtns();
		if (this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
			this.rebatesDetails.is_contract_lifecycle_management = true;
		}
		if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS) {
			this.rebatesDetails.is_contract_lifecycle_management = false;
		}
	}

	// Set default value on edit mode
	private editRebatesContractObject() {
		// this.currentAgreementTab = 'customers';
		this.itemsOrGroupsRadioBtn = 'itemsRadioBtn';
		this.currentMainTab = 'settlement_period';
		this.editRebatesContract();
	}

	// Method used to Enabled/Disabled Datagrid Crud across all Tabs
	private setEnabledDisabledAll() {
		this.isEnabledContractName = true;
		this.isEnabledItemAndGroupDatagrid = true;
		this.isEnabledTiersDatagrid = true;
		this.isEnabledMembersDatagrid = true;
		this.isEnabledRulesDatagrid = true;
		this.isEnabledStartDate = true;
		this.isEnabledEndDate = true;
		this.isEnabledSettlemenPeriod = true;
		this.isEnabledCloseCurrentSettlmentPeriodBtn = true;
		this.isEnabledTermsAndConditions = true;
		this.isDisabledContactPerson = true;
		this.isDisabledBuyingGroupName = true;
		this.isDisabledVendorName = true;
		this.isDisabledCustomerName = true;
		this.isDisabledPricelistDrpDwn = true;
		this.isDisabledTierType = true;
		this.isDisabledCustomerItemMatch = true;
		this.isDisabledTierInclude = true;
		if (!this.isAddMode) {
			if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
				if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
					if (this.rebatesDetails.rebateType === 'sales') {
						this.isEnabledCloseCurrentSettlmentPeriodBtn = false;
						this.isDisabledContactPerson = true;
						if (this.rebatesDetails.status === RebateStatusValEnum.DRAFT) {
							this.isEnabledCloseCurrentSettlmentPeriodBtn = false;
							this.isDisabledContactPerson = false;
						}
						if (this.rebatesDetails.status === RebateStatusValEnum.SIGNATURE_REQUESTED || this.rebatesDetails.status === RebateStatusValEnum.SIGNED || this.rebatesDetails.status === RebateStatusValEnum.ACTIVE) {
							this.isEnabledContractName = false;
							this.isEnabledStartDate = false;
							this.isEnabledEndDate = false;
							this.isEnabledSettlemenPeriod = false;
							this.isEnabledCloseCurrentSettlmentPeriodBtn = false;
							this.isEnabledItemAndGroupDatagrid = false;
							this.isEnabledTiersDatagrid = false;
							this.isEnabledMembersDatagrid = false;
							this.isEnabledRulesDatagrid = false;
							this.isEnabledTermsAndConditions = false;
							this.isDisabledContactPerson = false;
						}
						if (this.rebatesDetails.status === RebateStatusValEnum.EXPIRED || this.rebatesDetails.status === RebateStatusValEnum.INACTIVE) {
							this.isEnabledContractName = false;
							this.isEnabledStartDate = false;
							this.isEnabledEndDate = false;
							this.isEnabledSettlemenPeriod = false;
							this.isEnabledCloseCurrentSettlmentPeriodBtn = false;
							this.isEnabledItemAndGroupDatagrid = false;
							this.isEnabledTiersDatagrid = false;
							this.isEnabledMembersDatagrid = false;
							this.isEnabledRulesDatagrid = false;
							this.isEnabledTermsAndConditions = false;
							this.isDisabledContactPerson = true;
						}
						if (this.rebatesDetails.status === RebateStatusValEnum.ACTIVE) {
							this.isEnabledCloseCurrentSettlmentPeriodBtn = true;
						}
						if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS) {
							if (!this.rebatesDetails.is_contract_lifecycle_management) {
								this.isEnabledContractName = true;
								this.isEnabledEndDate = true;
								this.isEnabledSettlemenPeriod = true;
								this.isEnabledCloseCurrentSettlmentPeriodBtn = true
								this.isEnabledItemAndGroupDatagrid = true;
								this.isEnabledTiersDatagrid = true;
								this.isEnabledRulesDatagrid = true;
								this.isDisabledContactPerson = false;
							}
						}
					} else {
						this.isEnabledStartDate = false;
					}
				} else {
					this.isEnabledStartDate = false;
				}
			} else {
				this.isEnabledStartDate = false;
			}


			if (this.listOfClosedSettlementReports.length > 1) {
				this.isEnabledSettlemenPeriod = false;
				this.isEnabledStartDate = false;
				this.isDisabledBuyingGroupName = true;
				this.isDisabledVendorName = true;
				this.isDisabledCustomerName = true;
				this.isDisabledPricelistDrpDwn = true;
				this.isDisabledTierType = true;
				this.isDisabledCustomerItemMatch = true;
				this.isDisabledTierInclude = true;
			} else if (this.listOfClosedSettlementReports.length === 1) {
				this.isEnabledSettlemenPeriod = true;
				this.isEnabledStartDate = true;
				this.isDisabledBuyingGroupName = false;
				this.isDisabledVendorName = false;
				this.isDisabledCustomerName = false;
				this.isDisabledPricelistDrpDwn = false;
				this.isDisabledTierType = false;
				this.isDisabledCustomerItemMatch = false;
				this.isDisabledTierInclude = false;
				if (this.enable_rebate_settlement_approval_workflow) {
					if (this.listOfClosedSettlementReports[0].approval_status !== RebateStatusValEnum.DRAFT) {
						this.isEnabledSettlemenPeriod = false;
						this.isEnabledStartDate = false;
						this.isDisabledTierType = true;
						this.isDisabledTierInclude = true;
						this.isDisabledCustomerItemMatch = true;
						this.isDisabledBuyingGroupName = true;
						this.isDisabledVendorName = true;
						this.isDisabledCustomerName = true;
						this.isDisabledPricelistDrpDwn = true;
					}
				}
			} else {
				this.isEnabledStartDate = true;
				this.isEnabledSettlemenPeriod = true;
				this.isDisabledBuyingGroupName = false;
				this.isDisabledVendorName = false;
				this.isDisabledCustomerName = false;
				this.isDisabledPricelistDrpDwn = false;
				this.isDisabledTierType = false;
				this.isDisabledCustomerItemMatch = false;
				this.isDisabledTierInclude = false;
			}
			if (this.enable_rebate_settlement_approval_workflow) {
				const draftIndex = this.listOfClosedSettlementReports.findIndex(sr => sr.approval_status === RebateStatusValEnum.DRAFT);
				this.isDisabledSendCurrentSettlementForApprovalBtn = true;
				this.isEnabledSettlemenPeriod = false;
				if (draftIndex !== -1) {
					this.isEnabledSettlemenPeriod = true;
					this.isDisabledSendCurrentSettlementForApprovalBtn = false;
				}
			}

		} else {
			// if (this.isEnabledDocSign) {
			if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
				this.isDisabledContactPerson = true;
				if (this.rebatesDetails.status === RebateStatusValEnum.DRAFT) {
					this.isEnabledCloseCurrentSettlmentPeriodBtn = false;
					this.isDisabledContactPerson = false;
				}
			}
		}
	}


	// Method used to set Status for Rebate
	private setStatusForRebate() {
		if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
			if (this.rebatesDetails.rebateType === 'sales') {
				if (this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_OFF) {
					this.rebatesDetails.status = RebateStatusValEnum.ACTIVE;
				}
				if (this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
					this.rebatesDetails.status = RebateStatusValEnum.DRAFT;
				}
				if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS) {
					if (this.rebatesDetails.is_contract_lifecycle_management) {
						this.rebatesDetails.status = RebateStatusValEnum.DRAFT;
					} else {
						this.rebatesDetails.status = RebateStatusValEnum.ACTIVE;
					}
				}
			}
			if (this.rebatesDetails.rebateType === 'purchasing') {
				this.rebatesDetails.status = RebateStatusValEnum.ACTIVE;
			}
		}
		if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			this.rebatesDetails.status = RebateStatusValEnum.ACTIVE;
		}
	}

	// Method to add/remove visible class
	public searchSlideDown(id: string) {
		if ($('#' + id + '.showIt').is(':visible') === false) {
			$('#' + id + '').addClass('showIt');
		} else {
			$('#' + id + '').removeClass('showIt');
		}
	}

	// Click agreement tab
	clickAgreementTab(currentAgreementTab) {
		switch (currentAgreementTab) {
			case 'customers':
				this.currentAgreementTab = 'customers';
				this.clearCustomerAgreementTabDetails();
				break;
			case 'members':
				this.currentAgreementTab = 'members';
				this.clearMemberAgreementTabDetails();
				break;
			case 'rules':
				this.currentAgreementTab = 'rules';
				this.clearRulesAgreementTabDetails();
				break;
			case 'itemsOrgroups':
				this.currentAgreementTab = 'itemsOrgroups';
				if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
					this.doInitItemsAndGroupsTab();
				}
				if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
					this.doInitMembersDrpDwn();
					this.doInitItemsDrpDwn();
				}
				break;
			case 'terms_and_conditions':
				this.currentAgreementTab = 'terms_and_conditions';
				break;
			default:
				break;
		}
	}

	// Get agreement tab list data
	getAgreementTabListData() {

	}

	// Set agreement tab list data
	setAgreementTabListData() {

	}

	// Disable input text after save rebates
	disableRebatesInputFields() {
		if (this.editRebatesGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}
	}

	// Change IndividualOrGroup
	public doChangeRebateForRadioBtn(event) {
		const newRebateFor = event.value;
		const currentRebateFor = this.rebatesDetails.individualOrGroup;
		// const newRebateFor = event.target.value;
		if (currentRebateFor === newRebateFor) {
			return false;
		}
		if (!this.editRebatesGuid) {
			if (newRebateFor == 'growth_incentive' || newRebateFor == 'buyinggroup') {
				if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.AUTOPAYMENT) {
					const spMonthlyIndex = this.listOfSettlementPeriods.findIndex(sp => sp.value === SettlementPeriodsValEnum.MONTHLY);
					this.rebatesDetails.settlementPeriod = this.listOfSettlementPeriods[spMonthlyIndex].value;
				}
			}
			const spPerInvoiceIndex = this.listOfSettlementPeriods.findIndex(sp => sp.value === SettlementPeriodsValEnum.AUTOPAYMENT);
			this.listOfSettlementPeriods[spPerInvoiceIndex].visible = false;
			if (newRebateFor == 'individual' || newRebateFor == 'distribution' || newRebateFor == 'distribution_differential') {
				this.listOfSettlementPeriods[spPerInvoiceIndex].visible = true;
			}
			this.isShowBlockSettlementPeriods = true;
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.AUTOPAYMENT && (newRebateFor == 'individual')) {
				this.isShowBlockSettlementPeriods = false;
			}
			let confirmMessage = 'Your existing Item(s) / Group(s) list and Rules will replaced.';
			let isMembersTab: boolean = false;
			let isItemAndGroupTab: boolean = false;
			const isRulesTab = this.ruleTabList && this.ruleTabList.length > 0;
			if (currentRebateFor === 'individual' || currentRebateFor === 'growth_incentive' || currentRebateFor === 'distribution' || currentRebateFor === 'distribution_differential') {
				isItemAndGroupTab = this.listOfItemsAndGroupsItmGrpTab && this.listOfItemsAndGroupsItmGrpTab.length > 0;
			}
			if (currentRebateFor === 'buyinggroup') {
				confirmMessage = 'Your existing Member(s) List and Item(s) / Group(s) list and Rules will replaced.';
				isItemAndGroupTab = this.listOfMembersItemsExclsnTab && this.listOfMembersItemsExclsnTab.length > 0;
				isMembersTab = this.memberTabList && this.memberTabList.length > 0;
			}

			if ((isItemAndGroupTab || isRulesTab || isMembersTab) && !this.enable_classification_for_rebate) {
				const ixCustomDialogOptions: any = {
					popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
					showTitle: false,
					subtitle: 'Change Contract Type?',
					message: 'Are you sure you want to continue? ' + confirmMessage,
				};
				this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
					if (result) {
						setTimeout(() => {
							this.rebatesDetails.individualOrGroup = event.value;
							if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
								this.setColumnForBuyingGroupGrid();
								this.fetchedListOfBuyingGroups();
							}
							this.changeIndividualOrGroup();
							this.clearChildTabsData();

							this.setStatusForRebate();
							this.doCheckStatusBtnForDocSign();
							this.setSubmitMultiPleBtns();
						}, 20);
					} else {
						this.rebatesDetails.individualOrGroup = '';
						setTimeout(() => {
							this.rebatesDetails.individualOrGroup = currentRebateFor;
							if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
								this.setColumnForBuyingGroupGrid();
								this.fetchedListOfBuyingGroups();
							}
							this.changeIndividualOrGroup();
							this.setStatusForRebate();
							this.doCheckStatusBtnForDocSign();
							this.setSubmitMultiPleBtns();
						}, 20);
					}
					this.stateStorageKey = this.rebatesDetails.individualOrGroup;
				});
			} else {
				setTimeout(() => {
					this.rebatesDetails.individualOrGroup = event.value;
					this.stateStorageKey = this.rebatesDetails.individualOrGroup;
					if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
						this.setColumnForBuyingGroupGrid();
						this.fetchedListOfBuyingGroups();
					}
					this.changeIndividualOrGroup();
					this.setStatusForRebate();
					this.doCheckStatusBtnForDocSign();
					this.setSubmitMultiPleBtns();
				}, 20);
			}
		}
		this.doEnabledDisabledRebateType();
		this.doDisabledEnabledImportExcelBtn();
	}

	private changeIndividualOrGroup() {
		if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			this.rebatesDetails.rebateType = 'purchasing';
			// this.currentAgreementTab = 'customers';
			this.currentAgreementTab = 'members';
		} else {
			// this.currentAgreementTab = 'products';
			this.currentAgreementTab = 'itemsOrgroups';
			this.doInitItemsAndGroupsTab();
		}
	}

	// Method used to clear Child Tab table data.
	private clearChildTabsData() {
		this.ruleTabList = [];
		this.listOfItemsAndGroupsItmGrpTab = [];
		setTimeout(() => {
			this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
		}, 50);
		this.listOfMembersItemsExclsnTab = [];
		setTimeout(() => {
			this.dataSourceMembersLstExclsnTab = this.listOfMembersItemsExclsnTab;
		}, 50);
		this.memberTabList = [];
		setTimeout(() => {
			this.dataSourceMemberLstMemberTab = this.memberTabList;
		}, 50);
		this.rebatesDetails.selectCustomerId = '';
		this.rebatesDetails.selectCustomer = '';
		this.rebatesDetails.selectCustomerObj = {};
		this.rebatesDetails.selectVendor = '';
		this.rebatesDetails.selectVendorId = '';
		this.rebatesDetails.selectVendorObj = {};
		this.rebatesDetails.selectBuyingGroup = '';
		this.rebatesDetails.selectBuyingGroupId = '';
		this.rebatesDetails.selectBuyingGroupObj = {};
		this.isUploadExcelCsvFileItmGrpTab = false;
		this.listOfCsvRowForItmGrpTab = [];
		this.filterText = '';
		this.csvResponse = {};
	}

	public doChangeRebateTypeRadioBtn(event) {
		if (this.rebatesDetails.rebateType === 'purchasing') {
			this.setHideShowColumnsItmGrpTab();
			this.fetchedListOfVendors();
			this.setColumnForVendorGrid();
		} else if (this.rebatesDetails.rebateType === 'sales') {
			this.setHideShowColumnsItmGrpTab();
			this.fetchedListOfClients();
			this.setColumnForCrmGrid();
		}
		if (!this.editRebatesGuid) {
			this.rebatesDetails.selectCustomerId = '';
			this.rebatesDetails.selectCustomer = '';
			this.rebatesDetails.selectCustomerObj = {};
			this.rebatesDetails.selectVendor = '';
			this.rebatesDetails.selectVendorId = '';
			this.rebatesDetails.selectVendorObj = {};
			this.rebatesDetails.selectBuyingGroup = '';
			this.rebatesDetails.selectBuyingGroupId = '';
			this.rebatesDetails.selectBuyingGroupObj = {};
			this.currentAgreementTab = 'itemsOrgroups';
		}
		this.setStatusForRebate();
		this.doCheckStatusBtnForDocSign();
		this.setSubmitMultiPleBtns();
	}

	// #region for Select rebates customer search

	// Method used to Fetch client list
	private fetchedListOfClients() {
		const viewFields = ['guid', 'code', 'name', 'crm_type', 'pricelistnum', 'contactemployees', 'contactperson', 'human_readable_account_code', 'start_date', 'end_date'];
		this.crmDataFilterValue = [['crm_type', '=', NameFromServerKeyEnum.CLIENT]];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: NameFromServerKeyEnum.CLIENT,
			view_fields: JSON.stringify(viewFields)
		};

		this.customersLst = AspNetData.createStore({
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}
	// Method used to Fetch client list
	public getCustomerData() {
		this._AppCommonSrvc.fetchCustomerData(NameFromServerKeyEnum.CRMDATA).subscribe(response => {
			if (response) {
				if (response.flag) {
					const customerData = response.data || [];
					const objcustomer = customerData[0];
					if (objcustomer && objcustomer.human_readable_account_code) {
						this.isHumanReadableAccountCode = true;
					}
				}
			}
		}, error => { });
	}

	// Method used to set Dynamic Column for Customer Datagrid
	private setColumnForCrmGrid() {
		this.isShowCodeColumn = true;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isShowCodeColumn = true;
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isShowCodeColumn = false;
		} else {
			this.isShowCodeColumn = true;
		}
	}

	// Method used to close selectbox
	public doGridBoxOptionChanged(e) {
		if (e.name === "value") {
			this.isCustomerGridBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to change values event of customer dropdown
	public doChangeCustomers(e: any) {
		if (e.value === null) {
			this.sCustomersLbl = '';
			this.aSelKeysCustomers = [];
			this.aSelKeysCustomersDetails = [];
			if (!this.editRebatesGuid) {
				this.rebatesDetails.endDate = null;
				this.rebatesDetails.startDate = null;
			}
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doOpenedDropdownCompany(e) {
		this.listOfCrmDataGrid.instance.clearFilter();
	}

	// Method used to select event on datagrid of customer selectbox
	public doSelectedChangedCustomers(e: any) {
		this.aSelKeysCustomersDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.aSelKeysCustomersDetails.push(e.selectedRowsData[i]);
			}
		}
		this.setTextForCustomerDrpDwn();
		this.doSelectCustomer();
	}

	// Method used to set string on item dropdown
	private setTextForCustomerDrpDwn() {
		if (this.aSelKeysCustomersDetails.length >= 0) {
			if (this.isShowCodeColumn) {
				this.sCustomersLbl = this.aSelKeysCustomersDetails[0].code + ' - ' + this.aSelKeysCustomersDetails[0].name;
			} else {
				this.sCustomersLbl = this.aSelKeysCustomersDetails[0].name;
			}
		}
	}

	public doDisplayExprCustomerName(customer) {
		if (this.isShowCodeColumn) {
			return (customer && customer.code) ? customer.code + ' - ' + (customer.name ? customer.name : '') : '';
		} else {
			return (customer && customer.name) ? customer.name : '';
		}
	}

	// Method used to selected and fetch customer details
	private doSelectCustomer() {
		if (this.aSelKeysCustomersDetails && this.aSelKeysCustomersDetails.length > 0) {
			this.rebatesDetails.selectCustomerId = this.aSelKeysCustomersDetails[0].code;
			this.rebatesDetails.selectCustomer = this.aSelKeysCustomersDetails[0].name;
			this.rebatesDetails.selectCustomerObj = this.aSelKeysCustomersDetails[0];
			if (this.aSelKeysCustomersDetails[0]['start_date']) {
				this.rebatesDetails.startDate = moment(this.aSelKeysCustomersDetails[0].start_date).format('YYYY-MM-DD');
			} else {
				if (!this.editRebatesGuid) {
					this.rebatesDetails.startDate = null;
				}
			}
			if (this.aSelKeysCustomersDetails[0]['end_date']) {
				this.rebatesDetails.endDate = moment(this.aSelKeysCustomersDetails[0].end_date).format('YYYY-MM-DD');
			} else {
				if (!this.editRebatesGuid) {
					this.rebatesDetails.endDate = null;
				}
			}
			this.setItemsDataSourceItmGrpTab();
			this.fetchedListOfPrices(this.aSelKeysCustomersDetails[0].pricelistnum);
			this.fetchContactEmployees();
		}
	}

	// Method used to Fetch Contact Employee List
	private fetchContactEmployees() {
		try {
			this.contactEmployeesLst = this.rebatesDetails.selectCustomerObj.contactemployees || [];
		} catch (e) {
			this.contactEmployeesLst = [];
		}
		if (!this.editRebatesGuid) {
			this.aSelKeysContactEmployees = [];
			this.aSelKeysContactEmployeesDetails = [];
			const ceIndex = this.contactEmployeesLst.findIndex(ce => ce.name === this.rebatesDetails.selectCustomerObj.contactperson);
			;
			if (ceIndex !== -1) {
				this.aSelKeysContactEmployees = [this.contactEmployeesLst[ceIndex].guid];
				if (this.aSelKeysContactEmployeesDetails.findIndex(ceDetails => ceDetails.guid === this.contactEmployeesLst[ceIndex].guid) === -1) {
					this.aSelKeysContactEmployeesDetails.push(this.contactEmployeesLst[ceIndex]);
				}
			}
			setTimeout(() => {
				this.setTextForContactEmployeeDrpDwn();
				this.doSelectContactEmployee();
			}, 50);
		}
	}

	// Method used to close selectbox for contact employee
	public doOptionChangedContactEmployeeDrpDwn(e) {
		if (e.name === "value") {
			this.isOpenedContactEmployeeDrpDwn = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to change values event of contact employee dropdown
	public doValChangeContactEmployeeDrpDwn(e: any) {
		if (e.value === null) {
			this.sContactEmployeesLbl = '';
			this.aSelKeysContactEmployees = [];
			this.aSelKeysContactEmployeesDetails = [];
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doOpenedContactEmployeeDrpDwn(e) {
		this.listOfContactEmployeeDataGrid.instance.clearFilter();
	}

	// Method used to select event on datagrid of contact employee selectbox
	public doSelectedChangedContactEmployees(e: any) {
		this.aSelKeysContactEmployeesDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.aSelKeysContactEmployeesDetails.push(e.selectedRowsData[i]);
			}
		}
		this.setTextForContactEmployeeDrpDwn();
		this.doSelectContactEmployee();
	}

	// Method used to set string on item dropdown
	private setTextForContactEmployeeDrpDwn() {
		this.sContactEmployeesLbl = '';
		if (this.aSelKeysContactEmployeesDetails.length > 0) {
			this.sContactEmployeesLbl = this.aSelKeysContactEmployeesDetails[0].name;
		}
	}

	// Method used to set string on item dropdown
	public doDisplayExprContactPersonName(contactPersonDetail: any) {
		return contactPersonDetail ? contactPersonDetail.name : '';
	}

	// Method used to selected and fetch customer details
	private doSelectContactEmployee() {
		this.rebatesDetails.selectContactEmployeeId = '';
		this.rebatesDetails.selectContactEmployee = '';
		this.rebatesDetails.selectContactEmployeeObj = undefined;
		if (this.aSelKeysContactEmployeesDetails && this.aSelKeysContactEmployeesDetails.length > 0) {
			this.rebatesDetails.selectContactEmployeeId = this.aSelKeysContactEmployeesDetails[0].guid;
			this.rebatesDetails.selectContactEmployee = this.aSelKeysContactEmployeesDetails[0].name;
			this.rebatesDetails.selectContactEmployeeObj = this.aSelKeysContactEmployeesDetails[0];
		}
	}

	// #endregion

	// #region for Vendor Dropdown

	// Method used to Fetch list of Vendors
	private fetchedListOfVendors() {
		const viewFields = ['guid', 'code', 'name'];;
		this.vendorDataFilterValue = [];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.VENDORS,
			view_fields: JSON.stringify(viewFields)
		};

		this.vendorsLst = AspNetData.createStore({
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to set Dynamic Column for Customer Datagrid
	private setColumnForVendorGrid() {
		this.isShowCodeColumn = true;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isShowCodeColumn = true;
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isShowCodeColumn = false;
		} else {
			this.isShowCodeColumn = true;
		}
	}

	// Method used to close selectbox
	public doVendorGridBoxOptionChanged(e) {
		if (e.name === "value") {
			this.isVendorGridBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to change values event of item dropdown
	public doChangeVendors(e: any) {
		if (e.value === null) {
			this.sVendorsLbl = '';
			this.aSelKeysVendor = [];
			this.aSelKeysVendorsDetails = [];
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doOpenedDropdownVendor(e) {
		this.listOfVendorDataGrid.instance.clearFilter();
	}

	// Method used to select event on datagrid of Vendor selectbox
	public doSelectedChangedVendors(e: any) {
		this.aSelKeysVendorsDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.aSelKeysVendorsDetails.push(e.selectedRowsData[i]);
			}
		}
		this.setTextForVendorDrpDwn();
		this.doSelectVendor();
	}

	// Method used to set string on item dropdown
	private setTextForVendorDrpDwn() {
		if (this.aSelKeysVendorsDetails.length >= 0) {
			if (this.isShowCodeColumn) {
				this.sVendorsLbl = this.aSelKeysVendorsDetails[0].code + ' - ' + this.aSelKeysVendorsDetails[0].name;
			} else {
				this.sVendorsLbl = this.aSelKeysVendorsDetails[0].name;
			}
		}
	}

	public doDisplayExprVendorName(vendor: any) {
		return vendor ? vendor.name : '';
	}

	// Method used to selected and fetch Vendor details
	private doSelectVendor() {
		if (this.aSelKeysVendorsDetails && this.aSelKeysVendorsDetails.length > 0) {
			this.rebatesDetails.selectVendorId = this.aSelKeysVendorsDetails[0].code;
			this.rebatesDetails.selectVendor = this.aSelKeysVendorsDetails[0].name;
			this.rebatesDetails.selectVendorObj = this.aSelKeysVendorsDetails[0];
		}
	}

	// #endregion

	// #region for Buying group dropdown
	// check buying group text
	public doChangeBuyingGroupText(e) {
		if (e.value) {
			this.rebatesDetails.buyingGroupMember = (100 - parseInt(e.value));
		} else {
			this.rebatesDetails.buyingGroupMember = null;
		}
	}

	public doBuyingGroupPercentageKeyDown(e) {
		const event = e.event;
		const str = event.key || String.fromCharCode(event.which);
		if (/^[.,e]$/.test(str)) {
			event.preventDefault();
		}
	}

	// Method used to Fetched list of Buying groups
	private fetchedListOfBuyingGroups() {
		const viewFields = ['guid', 'code', 'name'];
		this.buyingGroupDataFilterValue = [];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.BUYINGGROUPS,
			view_fields: JSON.stringify(viewFields)
		};

		this.buyingGroupsLst = AspNetData.createStore({
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to set Dynamic Column for Customer Datagrid
	private setColumnForBuyingGroupGrid() {
		this.isShowCodeColumn = true;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isShowCodeColumn = true;
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isShowCodeColumn = false;
		} else {
			this.isShowCodeColumn = true;
		}
	}

	// Method used to close selectbox
	public doBuyingGroupGridBoxOptionChanged(e) {
		if (e.name === "value") {
			this.isBuyingGroupGridBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to change values event of item dropdown
	public doChangeBuyingGroups(e: any) {
		if (e.value === null) {
			this.sBuyingGroupsLbl = '';
			this.aSelKeysBuyingGroup = [];
			this.aSelKeysBuyingGroupDetails = [];
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doOpenedDropdownBuyingGroup(e) {
		this.listOfBuyingGroupDataGrid.instance.clearFilter();
	}

	// Method used to select event on datagrid of customer/prospect selectbox
	public doSelectedChangedBuyingGroups(e: any) {
		this.aSelKeysBuyingGroupDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.aSelKeysBuyingGroupDetails.push(e.selectedRowsData[i]);
			}
		}
		this.setTextForBuyingGroupDrpDwn();
		this.doSelectBuyingGroup();
	}

	// Method used to set string on item dropdown
	private setTextForBuyingGroupDrpDwn() {
		if (this.aSelKeysBuyingGroupDetails.length >= 0) {
			if (this.isShowCodeColumn) {
				this.sBuyingGroupsLbl = this.aSelKeysBuyingGroupDetails[0].code + ' - ' + this.aSelKeysBuyingGroupDetails[0].name;
			} else {
				this.sBuyingGroupsLbl = this.aSelKeysBuyingGroupDetails[0].name;
			}
		}
	}

	public doDisplayExprBuyinggroupName(buyinggroup: any) {
		return buyinggroup ? buyinggroup.name : '';
	}

	// Method used to selected and fetch customer/prospect details
	private doSelectBuyingGroup() {
		if (this.aSelKeysBuyingGroupDetails && this.aSelKeysBuyingGroupDetails.length > 0) {
			this.rebatesDetails.selectBuyingGroupId = this.aSelKeysBuyingGroupDetails[0].code;
			this.rebatesDetails.selectBuyingGroup = this.aSelKeysBuyingGroupDetails[0].name;
			this.rebatesDetails.selectBuyingGroupObj = this.aSelKeysBuyingGroupDetails[0];
			this.getMemberListForBuyingGroup(this.aSelKeysBuyingGroupDetails[0].code);
		}
	}

	//#endregion

	//#endregion

	// #region Settlement StartDate And EndDate Methods

	// Set Settlement StartDate and EndDate
	public doCompareTwoDates(event) {
		this.doSetSettlementStartDate(event);
	}

	// Set Settlement Date
	public doSetSettlementStartDate(event) {
		setTimeout(() => {
			if (!this.rebatesDetails.startSettlementPeriodOn) {
				this.rebatesDetails.startSettlementPeriodOn = "1";
			}
		}, 50);
		// if (this.isAddMode && (event && !event['previousValue'])) {
		// 	this.rebatesDetails.endDate = this.rebatesDetails.startDate;
		// }

		if (this.rebatesDetails['endDate'] && (new Date(this.rebatesDetails.endDate).setHours(0, 0, 0, 0) < new Date(this.rebatesDetails.startDate).setHours(0, 0, 0, 0))) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
			return;
		} else {
			this.getDynamicYearsOfErpDataStartDate();
			this.calculateSettlementPeriod(this.rebatesDetails);
		}
	}

	// Calculate Settlement Period as per start date
	calculateSettlementPeriod(c, isClose?: boolean) {
		this.doShowHideTierAccrualPeriodsDropDown();
		this.isShowBlockSettlementPeriods = true;
		if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.AUTOPAYMENT && (this.rebatesDetails.individualOrGroup == 'individual' || this.rebatesDetails.individualOrGroup == 'buyinggroup')) {
			this.isShowBlockSettlementPeriods = false;
		}
		if (isClose) {
			this._LoaderService.show();
		}
		this.callViewSettlementPeriod(this.rebatesRequest.guid, (response, data) => {
			this.closecontractsArray = data;
			if (isClose) {
				this._LoaderService.hide();
			}
			if (c.startDate) {
				c.startDate = moment(c.startDate).format('YYYY-MM-DD');
				c.endDate = c.endDate ? moment(c.endDate).format('YYYY-MM-DD') : null;
				c.settlementPeriodStart = moment(c.settlementPeriodStart).format('YYYY-MM-DD');
				c.settlementPeriodEnd = moment(c.settlementPeriodEnd).format('YYYY-MM-DD');
				let e, s, d;
				let ssPeriodDay: any = 1; //start settlement period on
				let cc = this.rebatesDetails.contractCalendar; // contract calendar [Fiscal Year,Calendar Year,Contract Year]
				let f: any = 1;// Fiscal Year month as of application configuration
				ssPeriodDay = this.rebatesDetails.startSettlementPeriodOn ? parseInt(this.rebatesDetails.startSettlementPeriodOn.toString()) : 1;

				cc = this.rebatesDetails.contractCalendar;
				if (cc === 'fiscal_year') {
					f = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
				}
				if (!this.closecontractsArray.length) {  // no previous closed settlement periods
					c.settlementPeriodStart = c.startDate;
					s = c.startDate.split('-');
				} else {
					let lastSettlementPeriodEndDate = moment(this.closecontractsArray[this.closecontractsArray.length - 1].settlementPeriodEnd).format('YYYY-MM-DD').split('-');
					if (this.enable_rebate_settlement_approval_workflow) {
						const listOfContractApproved = this.closecontractsArray.filter(cs => cs.approval_status === RebateApprovalStatusValEnum.APPROVED);
						if (listOfContractApproved && listOfContractApproved.length > 0) {
							const maxDateApprovedContract = _.max(listOfContractApproved, (el) => {
								return new Date(el.settlementPeriodEnd);
							});
							if (maxDateApprovedContract && maxDateApprovedContract.guid) {
								lastSettlementPeriodEndDate = moment(maxDateApprovedContract.settlementPeriodEnd).format('YYYY-MM-DD').split('-');
							}
						} else {
							lastSettlementPeriodEndDate = moment(c.startDate).format('YYYY-MM-DD').split('-');
						}
					}

					s = lastSettlementPeriodEndDate;
					d = new Date(s[0], s[1] - 1, s[2]);
					const setStartDateTime = new Date(c.startDate).setHours(0, 0, 0);
					if (new Date(d) <= new Date(setStartDateTime)) {
						c.settlementPeriodStart = c.startDate;
						s = c.settlementPeriodStart.split('-');
					} else {
						d.setDate(d.getDate() + 1);
						c.settlementPeriodStart = moment(d).format('YYYY-MM-DD');
						s = c.settlementPeriodStart.split('-');
						// check all settlment closed
						const endDate = c.endDate ? moment(c.endDate, "YYYY-MM-DD") : null;
						const settlementPeriodStart = moment(c.settlementPeriodStart, "YYYY-MM-DD");
						const diffDays = settlementPeriodStart.diff(endDate, 'days');
						if (diffDays >= 0) {
							this.isAllSettlmentClosed = true;
							return false;
						} else {
							this.isAllSettlmentClosed = false;
						}
						// End check all settlment closed
					}
				}
				if (cc === 'contract_year') {
					f = parseInt(s[1].toString()) || 1;
				}
				const sm = parseInt(s[1].toString()); // start date month
				if (c.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
					// tslint:disable-next-line: max-line-length
					e = new Date(s[0], s[1], 1);  // end date is first day of the next month, and JavaScript months start at 0, month 12 interpreted as Jan of next yr
				} else if (c.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
					d = sm - ((f - 1) % 3); // adjust for fiscal year start
					if (d < 0) {
						d = 0;
						// d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 3) + (d % 3 ? 3 : 0);   // end of current quarter
					d = d + ((f - 1) % 3);    // re-adjust for fiscal year start
					// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
					d = sm - ((f - 1) % 6); // adjust for fiscal year start
					if (d < 0) {
						d = 0;
						// d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 6) + (d % 6 ? 6 : 0);   // end of current quarter
					d = d + ((f - 1) % 6);    // re-adjust for fiscal year start
					// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
					// tslint:disable-next-line: radix
					if (cc === 'fiscal_year') {
						d = sm - ((f - 1) % 12); // adjust for fiscal year start
						if (d < 0) {
							d = 0;
							// d = d + 12;   // months before fiscal year start are as if at end of year
						}
						d = d - (d % 12) + (d % 12 ? 12 : 0);   // end of current quarter
						d = d + ((f - 1) % 12);    // re-adjust for fiscal year start
						// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
						e = new Date(s[0], d, ssPeriodDay);
					} else {
						e = new Date(parseInt(s[0]) + 1, f - 1, ssPeriodDay);
					}
				} else if (c.settlementPeriod === SettlementPeriodsValEnum.CUSTOM) {
					c.settlementPeriodEnd = c.endDate;
				}
				try {
					e.setDate(e.getDate() - 1); // last day of the prev month
					const endDate = c.endDate ? moment(c.endDate, "YYYY-MM-DD") : null;
					const settlementPeriodEnd = moment(e, "YYYY-MM-DD");
					const diffDays = settlementPeriodEnd.diff(endDate, 'days');
					if (diffDays >= 0) {
						c.settlementPeriodEnd = c.endDate;
					} else {
						c.settlementPeriodEnd = moment(e).format('YYYY-MM-DD');
					}
				} catch (e) {
				}

				if (isClose) {
					// c.contractCode = this.guid.newGuid();
					c.startDate = moment(c.startDate).format('YYYY-MM-DD');
					c.endDate = c.endDate ? moment(c.endDate).format('YYYY-MM-DD') : null;
					c.settlementPeriodStart = moment(c.settlementPeriodStart).format('YYYY-MM-DD');
					c.settlementPeriodEnd = moment(c.settlementPeriodEnd).format('YYYY-MM-DD');

					const o = new Object;
					// tslint:disable-next-line: forin
					for (const e in c) {
						o[e] = c[e];
					}

					// o['clients'] = this.customerTabList;
					if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
						o['members'] = this.memberTabList;
						o['items'] = this.listOfMembersItemsExclsnTab;
					}
					if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
						o['items'] = this.listOfItemsAndGroupsItmGrpTab;
					}

					o['rules'] = this.ruleTabList;
					// o['isSettlementPeriodClosed'] = true;
					// o['settelmentCalculatedByBackend'] = '';
					// o['apInvoiceCreatedInERP'] = '';
					// o['errorMessage'] = '';
					// o['settlementValue'] = 0;
					// o['isAPInvoicePaid'] = '';
					// o['apInvoiceNumber'] = '';
					o['startDate'] = moment(o['startDate']).format('YYYY-MM-DD');
					o['endDate'] = o['endDate'] ? moment(o['endDate']).format('YYYY-MM-DD') : null;
					o['settlementPeriodStart'] = moment(o['settlementPeriodStart']).format('YYYY-MM-DD');
					o['settlementPeriodEnd'] = moment(o['settlementPeriodEnd']).format('YYYY-MM-DD');
					// o['baseContractCode'] = c.guid;
					o['guid'] = this.guid.newGuid();

					// this.closecontractsArray.push(o);
					const rebatesRequest = new RebatesContractModelRequest();
					rebatesRequest.guid = this.guid.newGuid();
					rebatesRequest.contractName = c.contractName;
					rebatesRequest.rebate_for = c.individualOrGroup;
					rebatesRequest.rebateType = c.rebateType;
					rebatesRequest.startDate = moment(c.startDate).format('YYYY-MM-DD');
					rebatesRequest.endDate = c.endDate ? moment(c.endDate).format('YYYY-MM-DD') : null;
					rebatesRequest.settlementPeriodStart = moment(c.settlementPeriodStart).format('YYYY-MM-DD');
					rebatesRequest.settlementPeriodEnd = moment(c.settlementPeriodEnd).format('YYYY-MM-DD');
					rebatesRequest.settlementPeriod = c.settlementPeriod;
					rebatesRequest.startSettlementPeriodOn = c.startSettlementPeriodOn;
					rebatesRequest.contractCalendar = c.contractCalendar;
					rebatesRequest.is_active = true;
					rebatesRequest.tiers_type = c.tiers_type || 'single_items';
					rebatesRequest.customer_item_match = c.customer_item_match || 'exclusive';
					rebatesRequest.tier_mode = c.tier_mode || 'absolute';
					rebatesRequest.tier_accrual_period = c.tier_accrual_period;
					rebatesRequest.tiers_include = c.tiers_include || 'only_items_in_tier';
					rebatesRequest.status = c.status;
					rebatesRequest.current_settlement_invoice_total = c.current_settlement_invoice_total;
					rebatesRequest.current_settlement_rebate_total = c.current_settlement_commission_total;
					if (this.enable_other_currencies) {
						rebatesRequest.currency_code = c.currency_code;
					}
					if (this.isEnableDistributionChannels) {
						rebatesRequest.distribution_channels = c.distribution_channels ? c.distribution_channels : '';
					}

					rebatesRequest.isSettlementPeriodClosed = true;
					rebatesRequest.settelmentCalculatedByBackend = '';
					rebatesRequest.errorMessage = '';
					rebatesRequest.settlementValue = 0;
					rebatesRequest.creditMemoCreatedInERP = '';
					rebatesRequest.creditMemoNumber = '';
					rebatesRequest.isCreditMemoPaid = '';
					rebatesRequest.creditMemoPath = '';
					rebatesRequest.baseContractCode = c.guid;
					if (c.individualOrGroup === 'individual' || c.individualOrGroup === 'growth_incentive' || c.individualOrGroup === 'distribution' || c.individualOrGroup === 'distribution_differential') {
						if (c.rebateType === 'sales') {
							rebatesRequest.terms_and_conditions = c.terms_and_conditions || '';
							rebatesRequest.is_contract_lifecycle_management = c.is_contract_lifecycle_management;
							rebatesRequest.contactemployee_guid = c.contactemployee_guid || '';
							rebatesRequest.contactemployee_name = c.contactemployee_name || '';
							if (c.selectCustomerId) {
								rebatesRequest.cardcode = c.selectCustomerId;
								rebatesRequest.name = c.selectCustomer;
								rebatesRequest.cardcode_guid = c.selectCustomerObj.guid;
							}

							rebatesRequest.pricelistno = c.selectPricelistno || '';
							rebatesRequest.pricelistname = c.selectPricelistName || '';

							rebatesRequest.differential_pricelistno = c.selectDifferentialPricelistno || '';
							rebatesRequest.differential_pricelistname = c.selectDifferentialPricelistName || '';

							// if (this.isEnabledDocSign) {
							if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
								if (c.selectContactEmployeeId) {
									rebatesRequest.contactemployee_guid = c.selectContactEmployeeId;
									rebatesRequest.contactemployee_name = c.selectContactEmployee;
								}
								rebatesRequest.terms_and_conditions = c.terms_and_conditions || '';
							}
						}
						if (c.rebateType === 'purchasing') {
							if (c.selectVendorId) {
								rebatesRequest.cardcode = c.selectVendorId;
								rebatesRequest.name = c.selectVendor;
								rebatesRequest.cardcode_guid = c.selectVendorObj.guid;
							}
						}
						this.listOfItemsAndGroupsItmGrpTab.forEach(itm => {
							if (itm.rebate_basis === 'percentage') {
								if (itm.tiers && itm.tiers.length > 0) {
									itm.tiers.forEach(t => {
										if (t.rate !== null) {
											t.rate = +(this._CustomNumberNoRoundingPipe.transform(t.rate));
										}
									});
								}
								if (itm.hasOwnProperty('rate')) {
									if (itm.rate !== null) {
										itm.rate = +(this._CustomNumberNoRoundingPipe.transform(itm.rate));
									}
								}
							}
						});
						if (c.individualOrGroup === 'distribution' || c.individualOrGroup === 'distribution_differential') {
							rebatesRequest.enduser_items_groups = this.listOfItemsAndGroupsItmGrpTab;
						} else {
							rebatesRequest.items = this.listOfItemsAndGroupsItmGrpTab;
						}
					}

					if (c.individualOrGroup === 'buyinggroup') {
						if (c.selectBuyingGroupId) {
							rebatesRequest.cardcode = c.selectBuyingGroupId;
							rebatesRequest.name = c.selectBuyingGroup;
							rebatesRequest.cardcode_guid = c.selectBuyingGroupObj.guid;
						}
						rebatesRequest.rebateType = '';
						rebatesRequest.rebatesPercent = c.rebatesPercent;
						rebatesRequest.member_mode = c.member_mode;
						rebatesRequest.paymentType = c.paymentType;
						rebatesRequest.buyingGroup = c.buyingGroup;
						rebatesRequest.buyingGroupMember = c.buyingGroupMember;
						if (c.paymentType === 'split' || c.paymentType === 'buyinggroup') {
							rebatesRequest.gl_account_number = c.gl_account_number;
						}
						if (c.paymentType === 'buyinggroupmember') {
							rebatesRequest.gl_account_number = c.gl_account_number = '';
						}
						rebatesRequest.members = this.memberTabList;
						this.listOfMembersItemsExclsnTab.forEach(itm => {
							if (itm.rebate_basis === 'percentage') {
								if (itm.tiers.length > 0) {
									itm.tiers.forEach(t => {
										if (t.rate !== null) {
											t.rate = +(this._CustomNumberNoRoundingPipe.transform(t.rate));
										}
									});
								}
								if (itm.hasOwnProperty('rate')) {
									if (itm.rate !== null) {
										itm.rate = +(this._CustomNumberNoRoundingPipe.transform(itm.rate));
									}
								}
							}
						});
						rebatesRequest.items = this.listOfMembersItemsExclsnTab;
					}

					if (c.individualOrGroup === 'growth_incentive') {
						rebatesRequest.comparison_year = c.comparison_year || '';
					}
					rebatesRequest.rules = this.ruleTabList;

					const contractArr = [];
					// contractArr.push(o);
					contractArr.push(rebatesRequest);
					this.callSaveContractCloseSettlement(contractArr);
				} else {
					if (this.editRebatesGuid) {
						this.isUpdateSettlement = true;
						this.saveRebatesContract();
						this.callUpdateSettlementPeriod();
					}
				}
			}
		});
	}

	//#endregion

	// #region close and view current settlement period

	public doCloseCurrentSettlementPeriod(rebatesContractDetails) {
		this.isUpdateSettlement = false;
		if (this.editRebatesGuid !== undefined) {
			if (this.doValidateBeforeCloseSettlement()) {
				return;
			}
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Close Settlement',
				message: 'Are you sure you want to close current settlement period? <br/>You will not be able to undo the operation.'
			};
			/*
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					this.doApplyCloseCurrentSettlementPeriod();
				}
			});
			*/
			ixCustomDialogOptions.dialogOpenFrom = 'REBATE';
			ixCustomDialogOptions.isShowDocumentDate = true;
			this.currentStlmtPeriodProps = ixCustomDialogOptions;
			this.doOpenCloseCurrentSettlementPeriodPopup();
		}
	}

	private doValidateBeforeCloseSettlement() {
		const serverDateSplit = this._LoginService.loginUser.server_date.split('-');
		const d = new Date(+serverDateSplit[0], +serverDateSplit[1] - 1, +serverDateSplit[2]);
		if (!this.rebatesDetails.startDate) {
			this._IxDxAlertService.doShowAlert("Cannot close Settlement Period - please add a Start Date to contract").then(result => {
			});
			return true;
		}
		const end: any[] = this.rebatesDetails.settlementPeriodEnd.split('-');
		const endDate = new Date(end[0], end[1] - 1, end[2]);
		if (d <= endDate) {
			this._IxDxAlertService.doShowAlert("Cannot close Settlement Period until after its end date").then(result => {
			});
			return true;
		}
		return false;
	}

	private doApplyCloseCurrentSettlementPeriod() {
		const isClose = true;
		this.calculateSettlementPeriod(this.rebatesDetails, isClose);
	}

	public viewPreviousSettlementPeriodDialogClick() {
		if (this.editRebatesGuid !== undefined) {
			this.callViewSettlementPeriod(this.editRebatesGuid, (response, data) => {
				const ViewPreviousSettlementPeriodList = data;
				if (ViewPreviousSettlementPeriodList.length !== 0) {
					this.viewPreviousSettlementPeriodProps = {
						contractName: this.rebatesDetails.contractName, salesPersonName: '', ViewPreviousSettlementPeriodList: ViewPreviousSettlementPeriodList
					};
					this.doOpenViewPreviousSettlementPeriod();
				} else {
					return;
				}
			});
		}
	}

	private doOpenViewPreviousSettlementPeriod() {
		this.isViewPreviousSettlementPeriodPopup = false;
		setTimeout(() => {
			this.isViewPreviousSettlementPeriodPopup = true;
		}, 150);
	}

	public doCloseViewPreviousSettlementPeriod() {
		this.isViewPreviousSettlementPeriodPopup = false;
	}

	// Method used to Generate Credit Memo for existing invoices
	public doGenerateCreditMemosForExistingInvoices() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GENERATE_CREDIT_MEMO_ON_PAST_INVOICES);
		formData.append('contractGuid', this.editRebatesGuid);
		formData.append('contracttype', 'rebatescontracts');
		if (this.generateAutoPaymentCreditMemoSbsn) {
			this.generateAutoPaymentCreditMemoSbsn.unsubscribe();
		}

		this._LoaderService.show();
		this.generateAutoPaymentCreditMemoSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
						this.doShowGeneratedPastInvoices(response.data);
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private doShowGeneratedPastInvoices(generateCreditMemoOnPastInvoices: any[]) {
		this.viewRebateCreditMemoForPastInvoicesListProps = {
			generateCreditMemoOnPastInvoices: generateCreditMemoOnPastInvoices || [],
			contractguid: this.editRebatesGuid,
			contractDetails: this.rebatesDetails,
		};
		this.doOpenViewRebateCreditMemoForPastInvoicesListPopup();
	}

	private doOpenViewRebateCreditMemoForPastInvoicesListPopup() {
		this.isShowViewRebateCreditMemoForPastInvoicesListPopup = false;
		setTimeout(() => {
			this.isShowViewRebateCreditMemoForPastInvoicesListPopup = true;
		}, 300);
	}

	public doCloseViewRebateCreditMemoInvoicesListPopup(event: any) {
		this.isShowViewRebateCreditMemoForPastInvoicesListPopup = false;
	}

	public doSendCurrentCurrentSettlementForApproval(event: any) {
		this.isUpdateSettlement = false;
		if (this.editRebatesGuid !== undefined) {
			if (this.doValidateBeforeCloseSettlement()) {
				return;
			}
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Send Settlement For Approval',
				message: 'Are you sure you want to send current settlement for approval? <br/>You will not be able to undo the operation.',
			};
			/*
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					this.doApplyCloseCurrentSettlementPeriod();
				}
			});
			*/
			ixCustomDialogOptions.dialogOpenFrom = 'REBATE';
			ixCustomDialogOptions.isShowDocumentDate = true;
			this.currentStlmtPeriodProps = ixCustomDialogOptions;
			this.doOpenCloseCurrentSettlementPeriodPopup();
		}
	}

	//#endregion

	// #region Customer Tab

	// Customer search select list
	doSearchSelectCustomerTabOnChange(event: any) {
		const view_fields = ['code', 'name', 'pricelistnum'];
		let searchStr: any = event.target.value;
		this.selectCustomerSearchTabData = [];
		if (searchStr && searchStr.trim()) {
			searchStr = searchStr.trim();
			// if (searchStr.length <= 3) {
			// 	if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			// 	this.searchTimeout = setTimeout(() => {
			// 		this._ToastrService.info('Enter more characters to search terms.', 'Info', { closeButton: true, tapToDismiss: true });
			// 		this.selectCustomerSearchTabData = [];
			// 	}, 999);
			// 	return;
			// }
			if (this.dataInReqSubscription) {
				this.dataInReqSubscription.unsubscribe();
			}
			if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			this.searchTimeout = setTimeout(() => {
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.GET_ENTITY_DATA);
				formData.append('is_dropdown', '1');
				formData.append('entity', ServerEntity.CLIENTS);
				formData.append('view_fields', JSON.stringify(view_fields));
				if (searchStr) {
					const encodedSearchStr = encodeURIComponent(searchStr);
					const searchArr = [];
					if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
						searchArr.push({ 'name': encodedSearchStr });
					} else if (this.erp_type === ErpTypeValEnum.SAPB1_HANA || this.erp_type === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
						searchArr.push({ 'name_with_code': encodedSearchStr });
					} else {
						searchArr.push({ 'name_with_code': encodedSearchStr });
					}
					formData.append('search', JSON.stringify(searchArr));
				}
				this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: response => {
						if (response.flag) {
							this.selectCustomerSearchTabData = response.data || [];
							if (this.selectCustomerSearchTabData.length > 0) {
								if ($('#tblSearchCustomerTab.showIt').is(':visible') === false) {
									this.searchSlideDown('tblSearchCustomerTab');
								}
							} else {
								this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
								this.selectCustomerSearchTabData = [];
							}
						} else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
							this.selectCustomerSearchTabData = [];
						}
					}, error: error => {

					}
				});
			}, 750);
		}
	}

	// Select customer searchtab single item checkbox
	selectCustomerSearchTabDataListItem(event, items) {
		if (items.selected) {
			const isExistItemIndex = this.customerTabList.findIndex(itemEle => itemEle.code === items.code);
			if (isExistItemIndex !== -1) {
				setTimeout(() => {
					items.selected = false;
				}, 50);
				this._ToastrService.info("Customer already exists.", "Info", { closeButton: true, tapToDismiss: true });
			}
		}
		if (this.isCustomerSearchTabAllSelected) {
			this.isCustomerSearchTabAllSelected = false;
		}
	}

	// Select customer searchtab all item checkbox
	isCustomerSearchTabAllSelectedChange() {
		this.selectCustomerSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	// Save customer tab data
	addCustomerAgreementTabDetails() {
		const selectedCustomerTabItem = this.selectCustomerSearchTabData.filter(item => item.selected === true);
		const selectedCustomerTabItemLength = selectedCustomerTabItem.length;
		if (selectedCustomerTabItemLength <= 0 && !this.isCustomerSearchTabAllSelected) {
			this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
		} else if (this.isCustomerSearchTabAllSelected) {
			this.customerTabList = [];
			const item = {
				name: 'All Customer',
			};
			this.customerTabList.push(item);
			this.isAllCustomerSelectedView = true;
		} else {
			selectedCustomerTabItem.forEach(element => {
				let inItem = undefined;

				if (this.customerTabList) {
					// Check duplicate item in list
					inItem = this.customerTabList.filter(function (item) {
						return (item.name || item.itemname) === (element.name || element.itemname) && (item.code || item.itemcode) === (element.code || element.itemcode);
					})[0];

					// Check all customer in list
					const checkAllCustomer = this.customerTabList.filter(function (item) {
						return (item.name || item.itemname) === 'All Customer';
					})[0];

					if (checkAllCustomer) {
						this.customerTabList = [];
					}
				}

				if (inItem === undefined) {
					const item = {
						name: element.itemname || element.name,
						code: element.itemcode || element.code,
					};
					this.customerTabList.push(item);
					this._ToastrService.success('Customer(s) added successfully', 'Success', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.warning('Customer(s) already added in list', 'Duplicate', { closeButton: true, tapToDismiss: true });
				}
			});
			this.isAllCustomerSelectedView = false;
		}
		this.setAgreementTabListData();
		this.searchSlideDown('tblSearchCustomerTab');
		this.clearCustomerAgreementTabDetails();
	}

	// Remove customer tab data
	removeCustomerAgreementTabDetails(ItemIndex) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				// Delete an item from customer Tab list
				this.customerTabList.splice(ItemIndex, 1);
				this.setAgreementTabListData();
			}
		});
	}

	// Clear input field customer tab data
	clearCustomerAgreementTabDetails() {
		this.customerTabModel.name = '';
		this.isCustomerSearchTabAllSelected = false;
		this.selectCustomerSearchTabData = [];
	}

	//#endregion

	// #region Member Tab

	// Member search select list
	doSearchSelectMemberTabOnChange(event: any) {
		let searchStr: any = event.target.value;
		this.selectMemberSearchTabData = [];
		if (searchStr && searchStr.trim()) {
			searchStr = searchStr.trim();
			// if (searchStr.length <= 3) {
			// 	if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			// 	this.searchTimeout = setTimeout(() => {
			// 		this._ToastrService.info('Enter more characters to search terms.', 'Info', { closeButton: true, tapToDismiss: true });
			// 		this.selectMemberSearchTabData = [];
			// 	}, 999);
			// 	return;
			// }
			if (this.dataInReqSubscription) {
				this.dataInReqSubscription.unsubscribe();
			}
			if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			this.searchTimeout = setTimeout(() => {
				this.selectMemberSearchTabData = this.getResult('name', searchStr, 'code');
				if (this.selectMemberSearchTabData.length > 0) {
					if ($('#tblSearchMemberTab.showIt').is(':visible') === false) { this.searchSlideDown('tblSearchMemberTab'); }
				}
			}, 750);

			// if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			// this.searchTimeout = setTimeout(() => {
			//    let formdata = 'usr=' + this._LoginService.loginUser.user;
			//    formdata = formdata + '&token=' + this._LoginService.loginUser.token;
			//    formdata = formdata + '&method=getEntityData';
			//    formdata = formdata + '&is_dropdown=1';
			//    formdata = formdata + '&entity=clients';
			//    if (searchStr) {
			//       const searchArr = [];
			//       searchArr.push({ 'name_with_code': searchStr });
			//       formdata = formdata + '&search=' + JSON.stringify(searchArr);
			//    }
			//    this.dataInReqSubscription = this._RestApiService.doDataOutReq(formdata).subscribe(response => {

			//       if (response.flag) {
			//          this.selectMemberSearchTabData = response.data || [];
			//          if (this.selectMemberSearchTabData.length > 0) {
			//             if ($('#tblSearchMemberTab.showIt').is(':visible') === false) {
			//                this.searchSlideDown('tblSearchMemberTab');
			//             }
			//          } else {
			//             this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
			//             this.selectMemberSearchTabData =[];
			//          }
			//       } else {
			//          this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
			//          this.selectMemberSearchTabData =[];
			//       }
			//    });
			// }, 750);
		}
	}

	// data filter on member list
	getResult(keyToFilter, valueStartsWith, keyToFilter2) {
		return _.filter(this.memberTabList, (d) => {
			return (d[keyToFilter].startsWith(valueStartsWith)) || (d[keyToFilter2].startsWith(valueStartsWith));
		}
		);
	}

	getMemberListForBuyingGroup(buyingGroupCode, isReloadMembers?: boolean) {
		if (buyingGroupCode) {
			if (this.dataInReqSubscription) {
				this.dataInReqSubscription.unsubscribe();
			}
			if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			this.searchTimeout = setTimeout(() => {
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.GET_ENTITY_DATA);
				formData.append('entity', ServerEntity.BUYINGGROUPS);
				formData.append('view_fields', JSON.stringify(['members']));
				if (buyingGroupCode) {
					const searchArr = [];
					searchArr.push({ 'code': buyingGroupCode });
					formData.append('search', JSON.stringify(searchArr));
				}
				this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: response => {
						if (response.flag) {
							this.memberTabList = response.data && response.data.length > 0 ? response.data[0].members : [];
							setTimeout(() => {
								this.dataSourceMemberLstMemberTab = this.memberTabList;
							}, 50);
							if (this.editRebatesGuid && isReloadMembers) {
								setTimeout(() => {
									this.saveRebatesContract();
								}, 700);
							}
							this.setMembersDataSourceExlsnTab();
						} else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
							this.selectMemberSearchTabData = [];
						}
					}, error: error => {

					}
				});
			}, 750);
		}
	}

	// Select Member searchtab single item checkbox
	selectMemberSearchTabDataListItem(event, items) {
		if (items.selected) {
			const isExistItemIndex = this.memberTabList.findIndex(itemEle => itemEle.code === items.code);
			if (isExistItemIndex !== -1) {
				setTimeout(() => {
					items.selected = false;
				}, 50);
				this._ToastrService.info("Member already exists.", "Info", { closeButton: true, tapToDismiss: true });
			}
		}
		if (this.isMemberSearchTabAllSelected) {
			this.isMemberSearchTabAllSelected = false;
		}
	}

	// Select Member searchtab all item checkbox
	isMemberSearchTabAllSelectedChange() {
		this.selectMemberSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	// Save Member tab data
	addMemberAgreementTabDetails() {
		const selectedMemberTabItem = this.selectMemberSearchTabData.filter(item => item.selected === true);
		const selectedMemberTabItemLength = selectedMemberTabItem.length;
		if (selectedMemberTabItemLength <= 0 && !this.isMemberSearchTabAllSelected) {
			this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
		} else if (this.isMemberSearchTabAllSelected) {
			this.memberTabList = [];
			const item = {
				name: 'All Member',
			};
			this.memberTabList.push(item);
			this.isAllMemberSelectedView = true;
		} else {
			selectedMemberTabItem.forEach(element => {
				let inItem = undefined;

				if (this.memberTabList) {
					// Check duplicate item in list
					inItem = this.memberTabList.filter(function (item) {
						return (item.name || item.itemname) === (element.name || element.itemname) && (item.code || item.itemcode) === (element.code || element.itemcode);
					})[0];

					// Check all member in list
					const checkAllMember = this.memberTabList.filter(function (item) {
						return (item.name || item.itemname) === 'All Member';
					})[0];

					if (checkAllMember) {
						this.memberTabList = [];
					}
				}

				if (inItem === undefined) {
					const item = {
						name: element.itemname || element.name,
						code: element.itemcode || element.code,
					};
					this.memberTabList.push(item);
					this._ToastrService.success('Member (s) added successfully', 'Success', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.warning('Member (s) already added in list', 'Duplicate', { closeButton: true, tapToDismiss: true });
				}
			});
			this.isAllMemberSelectedView = false;
		}
		this.setAgreementTabListData();
		this.searchSlideDown('tblSearchMemberTab');
		this.clearMemberAgreementTabDetails();
	}

	// Clear input field Member tab data
	clearMemberAgreementTabDetails() {
		this.memberTabModel.name = '';
		this.isMemberSearchTabAllSelected = false;
		this.selectMemberSearchTabData = [];
	}

	// Method used to reload member lis
	public doReloadMembers() {
		this.getMemberListForBuyingGroup(this.rebatesDetails.selectBuyingGroupId, true);
	}

	// Method used to remove meber from current contract
	public doRowRemovedMembersMemberTab(event) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public doSavedMembersMemberTab(event) {

	}

	public doToolbarPreparingMemberTab(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcelMemberTab(event);
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'refresh',
					text: "Reload Members",
					onClick: (event: any) => {
						this.doReloadMembers();
					}
				}
			}
		);
	}

	private doExportToExcelMemberTab(event) {
		if (this.membersListMemberTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Members.xlsx';
		worksheet = workbook.addWorksheet('Members');

		const listOfMasterDetailsRows: any[] = [];
		exportDataGrid({
			worksheet: worksheet,
			component: this.membersListMemberTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 9, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {

				}
			}
		}).then((cellRange) => {
			this.doAddContractDetailsToWorkSheet(worksheet);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	public doShowMembersNotInBuyinggroup() {
		this.viewRebateMembersListProps = {
			actionFor: 'showMembersNotInBuyinggroup',
			contractguid: this.editRebatesGuid,
			contractDetails: this.rebatesDetails,
			storedMemberList: this.memberTabList || []
		};
		this.doOpenViewRebateMembersListPopup();
	}

	public doShowMembersWithIndividualContract() {
		this.viewRebateMembersListProps = {
			actionFor: 'showMembersWithIndividualContract',
			contractguid: this.editRebatesGuid,
			contractDetails: this.rebatesDetails
		};
		this.doOpenViewRebateMembersListPopup();
	}

	private doOpenViewRebateMembersListPopup() {
		this.isShowViewRebateMembersListPopup = false;
		setTimeout(() => {
			this.isShowViewRebateMembersListPopup = true;
		}, 300);
	}

	public doCloseViewRebateMembersListPopup(event: any) {
		this.isShowViewRebateMembersListPopup = false;
	}
	//#endregion

	// #region for Set UOMs
	private setlistOfUomBySelProduct(itemDetails: any) {
		let priceListNum = '';
		try {
			priceListNum = this.rebatesDetails.selectCustomerObj.pricelistnum || '';
		} catch (e) {
			priceListNum = '';
		}
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = this.listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = this.listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = this.listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	// Method used to set Default Uom by uomgroupentry from item
	private setDefaultUomByUomGroupEntry(itemDetails: any) {
		const listOfSpecificUOM = [];
		if (itemDetails.uomgroupentry) {
			// const uomGroupLst = this._DataService.getUomGroups() || [];
			const uomGroupLst = this.listOfUomGroups || [];
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						newItemUomPice.absentry = this.listOfUoms[uomIndex].absentry;
						newItemUomPice.code = this.listOfUoms[uomIndex].code;
						newItemUomPice.name = this.listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}
		}
		return listOfSpecificUOM;
	}

	//#endregion

	// #region Rules Tab

	clearRulesAgreementTabDetails() {
		this.btnRulesAdd = 'Add';
		this.rulesAddOrEditIndex = '-1';
		this.ruleTabModel.ruleBonusType = 'inclusionExclusionRule';
		this.ruleTabModel.condition = 'include';
		this.ruleTabModel.criterion = 'unpaidInvoices';
		this.ruleTabModel.ruleRate = 'N/A';
	}

	ruleBonusTypeChange(ruleBonusType) {
		if (this.chkAddOrUpdateRule == '') {
			if (ruleBonusType === 'inclusionExclusionRule') {
				this.ruleTabModel.ruleBonusType = 'inclusionExclusionRule';
				this.ruleTabModel.condition = 'include';
				this.ruleTabModel.criterion = 'unpaidInvoices';
				this.ruleTabModel.ruleRate = 'N/A';
			} else if (ruleBonusType === 'rateRule') {
				this.ruleTabModel.ruleBonusType = 'rateRule';
				// this.ruleTabModel.condition = 'breakUnitsOfMeasure';
				this.ruleTabModel.condition = 'miscellaneousDeductionInventory';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';
			} else if (ruleBonusType === 'bonusAmount') {
				this.ruleTabModel.ruleBonusType = 'bonusAmount';
				this.ruleTabModel.condition = 'newAccount';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';
			} else if (ruleBonusType === 'bonusRate') {
				this.ruleTabModel.ruleBonusType = 'bonusRate';
				this.ruleTabModel.condition = 'newAccount';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';
			}
			if (ruleBonusType !== 'inclusionExclusionRule') {
				this.conditionChange(this.ruleTabModel.condition);
			}
			if (ruleBonusType === 'inclusionExclusionRule') {
				this.criterionChange(this.ruleTabModel.criterion);
			}
		}
	}

	addRuleBonusItem() {
		const ruleItem = {
			ruleBonusType: this.ruleTabModel.ruleBonusType,
			condition: this.ruleTabModel.condition,
			criterion: $.isNumeric(this.ruleTabModel.criterion) ? parseFloat(this.ruleTabModel.criterion) : this.ruleTabModel.criterion,
			ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
			// name: $('#ruleBonusType option:selected').html() + '|' + $('#ruleCondition option:selected').html() + ($.isNumeric(this.ruleTabModel.criterion) || (this.ruleTabModel.criterion === 'N/A') ? '' : '|' + $('#ruleCriterion option:selected').html()),
			guid: this.ruleTabModel.guid || '',
		};
		let isExistRuleIndex;
		// Check duplicate item in list
		if (parseInt(this.rulesAddOrEditIndex) === -1) {
			isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === this.ruleTabModel.criterion);
		} else {
			const removedArray = _.reject(this.ruleTabList, item => {
				return item === this.ruleTabList[this.rulesAddOrEditIndex];
			});
			isExistRuleIndex = removedArray.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === this.ruleTabModel.criterion);
		}

		if (isExistRuleIndex !== -1) {
			this._ToastrService.info('Rule already added in list .', 'Duplicate', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (parseInt(this.rulesAddOrEditIndex) !== -1) {
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
			this._ToastrService.success('Rule updated successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
		} else {
			ruleItem.guid = this.guid.newGuid();
			this.ruleTabList.push(ruleItem);
			this._ToastrService.success('Rule added successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
		}

		this.clearRulesAgreementTabDetails();
	}

	conditionChange(condition) {
		// tslint:disable-next-line: max-line-length

		if (condition !== '' && condition !== null && condition !== undefined) {
			if ((condition === 'breakUnitsOfMeasure') || (condition === 'newAccount') || (condition === 'perActiveCustomer') || (condition === 'perActiveAccount')) {
				this.isViewBounsAmountCriterion = true;
				this.isViewRateRuleCriterion = true;
				this.isViewBonusRateCriterion = true;
				this.ruleTabModel.criterion = 'N/A';
			} else {
				this.isViewBounsAmountCriterion = false;
				this.isViewRateRuleCriterion = false;
				this.isViewBonusRateCriterion = false;
				this.ruleTabModel.criterion = '';
			}
		}
		if (this.chkAddOrUpdateRule === 'edit' && this.ruleTabModel.ruleBonusType !== 'inclusionExclusionRule') {
			this.chkAddOrUpdateRule = '';
		}
	}

	criterionChange(criterion) {
		if (criterion !== '' && criterion !== null && criterion !== undefined) {
			if (criterion === 'shippingCosts') {
				this.ruleTabModel.ruleRate = '';
				this.isViewIERuleRuleRate = false;
			} else {
				this.ruleTabModel.ruleRate = 'N/A';
				this.isViewIERuleRuleRate = true;
			}
		}
		if (this.chkAddOrUpdateRule === 'edit' && this.ruleTabModel.ruleBonusType === 'inclusionExclusionRule') {
			this.chkAddOrUpdateRule = '';
		}
	}

	public doRemoveRuleBonusListItem(ruleItemIndex) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				// Delete an item from customer Tab list
				this.ruleTabList.splice(ruleItemIndex, 1);
				this.setAgreementTabListData();
				// this._ToastrService.error('Record deleted successfully. ', 'Deleted', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doEditRuleBonusListItem(ruleItemIndex, ruleItem) {
		this.btnRulesAdd = 'Update';
		this.chkAddOrUpdateRule = 'edit';
		this.rulesAddOrEditIndex = ruleItemIndex;
		this.ruleTabModel.ruleBonusType = ruleItem.ruleBonusType;
		this.ruleTabModel.condition = ruleItem.condition;
		this.ruleTabModel.criterion = ruleItem.criterion;
		this.ruleTabModel.ruleRate = ruleItem.ruleRate;
		this.ruleTabModel.guid = ruleItem.guid;

		if (ruleItem.criterion === 'N/A' || ruleItem.condition === 'N/A' || this.ruleTabModel.ruleRate === 'N/A') {
			this.isViewRateRuleCriterion = true;
			this.isViewBonusRateCriterion = true;
			this.isViewBounsAmountCriterion = true;
			this.isViewIERuleRuleRate = true;
		} else {
			this.isViewRateRuleCriterion = false;
			this.isViewBonusRateCriterion = false;
			this.isViewBounsAmountCriterion = false;
			this.isViewIERuleRuleRate = false;
		}
	}

	public doToolbarPreparingRuleTab(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcelRulesTab();
					}
				}
			}
		);
	}

	private doExportToExcelRulesTab() {
		if (this.rulesListRulesTabDataGridRef && this.rulesListRulesTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Rules.xlsx';
		worksheet = workbook.addWorksheet('Rules');

		const listOfMasterDetailsRows: any[] = [];
		exportDataGrid({
			worksheet: worksheet,
			component: this.rulesListRulesTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 9, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'ruleBonusType') {
						excelCell.value = '';
						switch (gridCell.data.ruleBonusType) {
							case 'inclusionExclusionRule':
								excelCell.value = 'Inclusion/Exclusion Rule';
								break;
							case 'rateRule':
								excelCell.value = 'Rate Rule';
								break;
							case 'bonusAmount':
								excelCell.value = 'Bonus Amount';
								break;
							case 'bonusRate':
								excelCell.value = 'Bonus Rate';
								break;
							default:
								excelCell.value = gridCell.data.ruleBonusType;
								break;
						}
					}

					if (gridCell.column.dataField === 'condition') {
						switch (gridCell.data.condition) {
							case 'include':
								excelCell.value = 'Include in Calculation';
								break;
							case 'exclude':
								excelCell.value = 'Exclude in Calculation';
								break;
							case 'breakUnitsOfMeasure':
								excelCell.value = 'Break Units of Measure';
								break;
							case 'itemsLessThan':
								excelCell.value = 'No. Items Less Than';
								break;
							case 'itemsGreaterThan':
								excelCell.value = 'No. Items Greater Than';
								break;
							case 'miscellaneousDeduction':
								excelCell.value = 'Miscellaneous Deduction';
								break;
							case 'miscellaneousDeductionInventory':
								excelCell.value = 'Miscellaneous Deduction - Inventory Item';
								break;
							case 'newAccount':
								excelCell.value = 'New Account';
								break;
							case 'perActiveCustomer':
								excelCell.value = 'Per Active Customer';
								break;
							case 'totalPerSettlementPeriodGreaterThan':
								excelCell.value = 'Total Per Settlement Period Greater Than';
								break;
							case 'newAccountWithMonthlyOrders':
								excelCell.value = 'New Account With Monthly Orders Greater Than';
								break;
							case 'grossProfitPerSettlementPeriod':
								excelCell.value = 'Gross Profit Per Settlement Period Greater Than';
								break;
							case 'SKUsShippedInSettlementPeriod':
								excelCell.value = 'No. SKUs Shipped In Settlement Period Greater Than';
								break;
							default:
								excelCell.value = gridCell.data.condition;
								break;
						}
					}

					if (gridCell.column.dataField === 'criterion') {
						switch (gridCell.data.criterion) {
							case 'billbacksChargebacks':
								excelCell.value = 'Billbacks/Chargebacks';
								break;
							case 'unpaidInvoices':
								excelCell.value = 'Unpaid Invoices';
								break;
							case 'rebates':
								excelCell.value = 'Rebates';
								break;
							case 'cashDiscounts':
								excelCell.value = 'Cash Discounts';
								break;
							case 'shippingCosts':
								excelCell.value = 'Shipping Costs';
								break;
							default:
								excelCell.value = 'gridCell.data.criterion';
								break;
						}
					}

					if (gridCell.column.dataField === 'ruleRate') {
						if (gridCell.data.ruleRate == 'N/A') {
							excelCell.value = gridCell.data.ruleRate;
						} else if (gridCell.data.ruleBonusType == 'bonusAmount') {
							excelCell.value = gridCell.data.ruleRate + ' $';
						} else {
							excelCell.value = gridCell.data.ruleRate + ' %';
						}
					}
				}
			}
		}).then((cellRange) => {
			this.doAddContractDetailsToWorkSheet(worksheet);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}
	//#endregion

	// #region Call API Request

	public saveRebatesContract() {
		if (!this.rebatesDetails.contractName) {
			this._ToastrService.info('Contract Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (!this.rebatesDetails.selectCustomer && (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales') {
			this._ToastrService.info('Customer is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		// if (this.isEnabledDocSign) {
		if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales') {
				/* // Removed validation to check Contact Person only
				if (!this.rebatesDetails.selectContactEmployee) {
					if (this.rebatesDetails.status === RebateStatusValEnum.DRAFT && this.rebatesDetails.is_contract_lifecycle_management) {
						this._ToastrService.info('Contact Person is Required.', 'Info', { closeButton: true, tapToDismiss: true });
						return false;
					}
				}
				
				if (this.rebatesDetails.selectContactEmployee) {
					if (this.rebatesDetails.status === RebateStatusValEnum.DRAFT && this.rebatesDetails.is_contract_lifecycle_management) {
						if (this.rebatesDetails.selectContactEmployeeObj && !this.rebatesDetails.selectContactEmployeeObj.e_mail) {
							this._ToastrService.info('Please select a Contact with a valid email address to digitally sign this contract.', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
					}
				}
				*/
			}
		}
		if (!this.rebatesDetails.selectVendor && (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'purchasing') {
			this._ToastrService.info('Vendor is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.rebatesDetails.selectBuyingGroup && this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			this._ToastrService.info('Buying Group is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (this.enable_other_currencies) {
			if (!this.rebatesDetails.currency_code) {
				this._ToastrService.info('Currency is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}

		if (this.rebatesRequest.guid === undefined || this.rebatesRequest.guid === '' || this.rebatesRequest.guid === null) {
			this.rebatesRequest.guid = this.guid.newGuid();
		}

		this.rebatesRequest.contractName = this.rebatesDetails.contractName;
		this.rebatesRequest.rebate_for = this.rebatesDetails.individualOrGroup;
		this.rebatesRequest.rebateType = this.rebatesDetails.rebateType;
		this.rebatesRequest.startDate = this.rebatesDetails.startDate ? moment(this.rebatesDetails.startDate).format('YYYY-MM-DD') : "";
		this.rebatesRequest.endDate = this.rebatesDetails.endDate ? moment(this.rebatesDetails.endDate).format('YYYY-MM-DD') : "";
		if (this.rebatesDetails.startDate && this.rebatesDetails.endDate) {
			this.rebatesRequest.settlementPeriodStart = this.rebatesDetails.settlementPeriodStart ? moment(this.rebatesDetails.settlementPeriodStart).format('YYYY-MM-DD') : "";
			this.rebatesRequest.settlementPeriodEnd = this.rebatesDetails.settlementPeriodEnd ? moment(this.rebatesDetails.settlementPeriodEnd).format('YYYY-MM-DD') : "";
		} else {
			this.rebatesRequest.settlementPeriodStart = "";
			this.rebatesRequest.settlementPeriodEnd = "";
		}
		this.rebatesRequest.settlementPeriod = this.rebatesDetails.settlementPeriod;
		this.rebatesRequest.startSettlementPeriodOn = this.rebatesDetails.startSettlementPeriodOn;
		this.rebatesRequest.contractCalendar = this.rebatesDetails.contractCalendar;
		this.rebatesRequest.status = this.rebatesDetails.status;

		if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive' || this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') {
			this.listOfItemsAndGroupsItmGrpTab.forEach(itm => {
				if (itm.rebate_basis === 'percentage') {
					if (itm.tiers && itm.tiers.length > 0) {
						itm.tiers.forEach(t => {
							if (t.rate !== null) {
								t.rate = +(this._CustomNumberNoRoundingPipe.transform(t.rate));
							}
						});
					}

					if (itm.hasOwnProperty('rate')) {
						if (itm.rate !== null) {
							itm.rate = +(this._CustomNumberNoRoundingPipe.transform(itm.rate));
						}
					}
				}
			});

			if (this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') {
				this.rebatesRequest.enduser_items_groups = this.listOfItemsAndGroupsItmGrpTab;
			} else {
				this.rebatesRequest.items = this.listOfItemsAndGroupsItmGrpTab;
			}
			if (this.rebatesRequest.rebateType === 'purchasing') {
				if (this.rebatesDetails.selectVendorId) {
					this.rebatesRequest.cardcode = this.rebatesDetails.selectVendorId;
					this.rebatesRequest.name = this.rebatesDetails.selectVendor;
					this.rebatesRequest.cardcode_guid = this.rebatesDetails.selectVendorObj.guid;
				}
			}
			if (this.rebatesRequest.rebateType === 'sales') {

				this.rebatesRequest.cardcode = this.rebatesDetails.selectCustomerId || '';
				this.rebatesRequest.name = this.rebatesDetails.selectCustomer || '';
				this.rebatesRequest.cardcode_guid = this.rebatesDetails.selectCustomerObj.guid || '';

				this.rebatesRequest.pricelistno = this.rebatesDetails.selectPricelistno || '';
				this.rebatesRequest.pricelistname = this.rebatesDetails.selectPricelistName || '';

				this.rebatesRequest.differential_pricelistno = this.rebatesDetails.selectDifferentialPricelistno || '';
				this.rebatesRequest.differential_pricelistname = this.rebatesDetails.selectDifferentialPricelistName || '';

				this.rebatesRequest.contactemployee_guid = this.rebatesDetails.selectContactEmployeeId || '';
				this.rebatesRequest.contactemployee_name = this.rebatesDetails.selectContactEmployee || '';
				this.rebatesRequest.is_contract_lifecycle_management = this.rebatesDetails.is_contract_lifecycle_management;
				this.rebatesRequest.terms_and_conditions = this.rebatesDetails.terms_and_conditions || '';

				if (this.productSearchItemType === 'price') {
					if (!this.editRebatesGuid) {
						if (this.listOfPrices.length > 0) {
							this.rebatesDetails.pricelist_modified_date = this.listOfPrices[0].modified_date;
						}
					}
					this.rebatesRequest.pricelist_modified_date = this.rebatesDetails.pricelist_modified_date;
					this.rebatesRequest.pricelist_modified_user_action = this.rebatesDetails.pricelist_modified_user_action;
				}
			}

			if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
				this.rebatesRequest.comparison_year = this.rebatesDetails.comparison_year;
			}
		}

		if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			if (this.rebatesDetails.selectBuyingGroupId) {
				this.rebatesRequest.cardcode = this.rebatesDetails.selectBuyingGroupId;
				this.rebatesRequest.name = this.rebatesDetails.selectBuyingGroup;
				this.rebatesRequest.cardcode_guid = this.rebatesDetails.selectBuyingGroupObj.guid;
			}
			this.rebatesRequest.rebateType = '';
			this.rebatesRequest.member_mode = this.rebatesDetails.member_mode;
			this.rebatesRequest.paymentType = this.rebatesDetails.paymentType;
			this.rebatesRequest.buyingGroup = this.rebatesDetails.buyingGroup;
			this.rebatesRequest.buyingGroupMember = this.rebatesDetails.buyingGroupMember;
			if (this.rebatesDetails.paymentType === 'split' || this.rebatesDetails.paymentType === 'buyinggroup') {
				this.rebatesRequest.gl_account_number = this.rebatesDetails.gl_account_number;
			}
			if (this.rebatesDetails.paymentType === 'buyinggroupmember') {
				this.rebatesRequest.gl_account_number = this.rebatesDetails.gl_account_number = '';
			}
			this.rebatesRequest.members = JSON.parse(JSON.stringify(this.memberTabList || []));
			this.rebatesRequest.members.forEach(member => {
				delete member['inout_dates'];
			});
			this.listOfMembersItemsExclsnTab.forEach(itm => {
				if (itm.rebate_basis === 'percentage') {
					if (itm.tiers && itm.tiers.length > 0) {
						itm.tiers.forEach(t => {
							if (t.rate !== null) {
								t.rate = +(this._CustomNumberNoRoundingPipe.transform(t.rate));
							}
						});
					}
					if (itm.hasOwnProperty('rate')) {
						if (itm.rate !== null) {
							itm.rate = +(this._CustomNumberNoRoundingPipe.transform(itm.rate));
						}
					}
				}
				if (itm.hasOwnProperty('members') && itm.members && itm.members.lenth > 0) {
					itm.members.forEach(member => {
						delete member['inout_dates'];
					});
				}
			});
			this.rebatesRequest.items = this.listOfMembersItemsExclsnTab;
		}
		this.rebatesRequest.rules = this.ruleTabList;
		this.rebatesRequest.is_active = true;
		this.rebatesRequest.tiers_type = this.rebatesDetails.tiers_type || 'single_items';
		this.rebatesRequest.customer_item_match = this.rebatesDetails.customer_item_match || 'exclusive';
		this.rebatesRequest.tier_mode = this.rebatesDetails.tier_mode || 'absolute';
		this.rebatesRequest.tier_accrual_period = this.rebatesDetails.tier_accrual_period;
		this.rebatesRequest.tiers_include = this.rebatesDetails.tiers_include || 'only_items_in_tier';
		if (this.isEnableDistributionChannels) {
			this.rebatesRequest.distribution_channels = this.distributionChannelsModel.arrSelectedDistributionChannelsList && this.distributionChannelsModel.arrSelectedDistributionChannelsList.length > 0 ? this.distributionChannelsModel.arrSelectedDistributionChannelsList[0] : '';
		}
		if (this.enable_other_currencies) {
			this.rebatesRequest.currency_code = this.rebatesDetails.currency_code || '';
		}

		this.rebatesRequest.tiers_include = this.rebatesDetails.tiers_include || 'only_items_in_tier';
		this.doShowHideTierAccrualPeriodsDropDown();

		const arryRebatesContracts = [];
		arryRebatesContracts.push(this.rebatesRequest);
		this.doCallSaveRebatesContract(arryRebatesContracts);
	}

	private doCallSaveRebatesContract(arryRebatesContracts) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('rebatescontracts', JSON.stringify(arryRebatesContracts));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					if (!this.isUpdateSettlement) {
						this._ToastrService.success(((!this.editRebatesGuid) ? ' Added' : ' Updated') + ' Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}

					this.callUpdateSettlementPeriod();
					this.isUpdateSettlement = false;
					this.rebatesRequest.ixcode = response.data.rebatescontracts[0].ixcode;
					this.rebatesRequest.guid = response.data.rebatescontracts[0].guid;
					this.rebatesDetails.guid = response.data.rebatescontracts[0].guid;
					this.editRebatesGuid = this.rebatesRequest.guid;
					this.isAddMode = false;
					this._RebateService.setEditRebatesGuid(this.editRebatesGuid);
					// Disable rebates inputbox
					// this.disableRebatesInputFields();
					if (this.editRebatesGuid) {
						this.isDisabledFields = true;
					}
					this.setEnabledDisabledAll();
					this.setSubmitMultiPleBtns();
					this.fetchCloseSettlmenteReport();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	async callSaveContractCloseSettlement(arryContracts) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('rebatescontracts', JSON.stringify(arryContracts));
		formData.append('contracttype', 'rebatescontracts');
		if (this.enable_rebate_settlement_approval_workflow) {
			formData.append('send_for_approval', 'true');
		}
		if (this.document_date_from_confirm_popup) {
			const document_date = moment(this.document_date_from_confirm_popup).format(DATETIME_FORMAT_BACKEND)
			formData.append('document_date', document_date);
		}

		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					this.document_date_from_confirm_popup = null;
					// this.currentcontracts.push(arryContracts[0]);
					if (!this.enable_rebate_settlement_approval_workflow) {
						this.calculateSettlementPeriod(this.rebatesDetails, false);
					}
					// console.log('Save and Close settlement contracts, got result');
					this.rebatesDetails.current_settlement_invoice_total = '0';
					this.rebatesDetails.current_settlement_creditmemo_total = '0';
					this.rebatesDetails.current_settlement_sales_total = '0';
					this.rebatesDetails.current_settlement_commission_total = '0';
					if (this.enable_rebate_settlement_approval_workflow) {
						this.fetchCloseSettlmenteReport();
					} else {
						this.setEnabledDisabledAll();
					}
					this.callUpdateSettlementPeriod();
					// this.closecontractsArray = [];
					this._ToastrService.success('Close and save settlement successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					// this._loaderService.hide();
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private editRebatesContract() {
		this.isDisabledFields = false;
		const searchParams = [{ 'guid': this.editRebatesGuid }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('search', JSON.stringify(searchParams));
		// this._LoaderService.show();
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				// this._LoaderService.hide();
				if (response.flag) {
					this.isDisabledFields = true;
					const editRebatesContractDetails = response.data[0];

					this.isAddMode = false;
					this.editRebatesGuid = editRebatesContractDetails.guid;
					this.rebatesDetails.guid = editRebatesContractDetails.guid;
					this.rebatesDetails.contractName = editRebatesContractDetails.contractName;
					this.rebatesDetails.individualOrGroup = editRebatesContractDetails.rebate_for;
					if (this.rebatesDetails.individualOrGroup === 'individual') {
						this.stateStorageKey = ServerSections.INCENTIVES_REBATES_DETAILS_INDIVIDUAL;
					} else if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
						this.stateStorageKey = ServerSections.INCENTIVES_REBATES_DETAILS_GROWTH_INCENTIVE;
					} else if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
						this.stateStorageKey = ServerSections.INCENTIVES_REBATES_DETAILS_BUYINGGROUP;
					} else if (this.rebatesDetails.individualOrGroup === 'distribution') {
						this.stateStorageKey = ServerSections.INCENTIVES_REBATES_DETAILS_DISTRIBUTION;
					} else if (this.rebatesDetails.individualOrGroup === 'distribution_differential') {
						this.stateStorageKey = ServerSections.INCENTIVES_REBATES_DETAILS_DISTRIBUTION_DIFFERENTIAL;
					}
					this.rebatesDetails.rebateType = editRebatesContractDetails.rebateType;
					this.rebatesDetails.status = editRebatesContractDetails.status || '';
					this.doCheckStatusBtnForDocSign();
					editRebatesContractDetails.items.forEach(itm => {
						if (itm.rebate_basis === 'percentage') {
							if (itm.tiers && itm.tiers.length > 0) {
								itm.tiers.forEach(t => {
									if (t.rate !== null) {
										t.rate = +(this._CustomNumberNoRoundingPipe.transform(t.rate));
									}
								});
							}
							if (itm.hasOwnProperty('rate')) {
								if (itm.rate !== null) {
									itm.rate = +(this._CustomNumberNoRoundingPipe.transform(itm.rate));
								}
							}
						}
					});
					if (!this.rebatesDetails.hasOwnProperty('currency_code')) {
						this.rebatesDetails.currency_code = '';
					}
					if (this.enable_other_currencies) {
						this.rebatesDetails.currency_code = editRebatesContractDetails.currency_code;
						this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.rebatesDetails);
						this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
					}
					if (this.enable_classification_for_rebate) {
						this.listOfRebateBasis = this._RebateService.getRebateBasisForClasses(this.globalCurrencySymbol);
					} else {
						this.listOfRebateBasis = this._RebateService.getRebateBasis(this.globalCurrencySymbol);
					}
					if (this.isEnableDistributionChannels) {
						this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
						if (editRebatesContractDetails.distribution_channels) {
							this.distributionChannelsModel.arrSelectedDistributionChannelsList.push(editRebatesContractDetails.distribution_channels);
						}
					}
					if (editRebatesContractDetails.rebate_for === 'individual' || editRebatesContractDetails.rebate_for === 'distribution' || editRebatesContractDetails.rebate_for === 'distribution_differential') {
						if (editRebatesContractDetails.rebateType === 'sales') {
							this.fetchedListOfClients();
							this.setColumnForCrmGrid();
							if (!editRebatesContractDetails.hasOwnProperty('status')) {
								if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
									this.rebatesDetails.status = RebateStatusValEnum.DRAFT;
								} else {
									this.rebatesDetails.status = RebateStatusValEnum.ACTIVE;
								}
							}
							this.rebatesDetails.selectCustomerId = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectCustomer = editRebatesContractDetails.name;
							this.rebatesDetails.selectCustomerObj = {};
							this.rebatesDetails.selectCustomerObj.code = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectCustomerObj.name = editRebatesContractDetails.name;
							this.rebatesDetails.selectCustomerObj.guid = editRebatesContractDetails.cardcode_guid;
							this.rebatesDetails.pricelist_modified_date = editRebatesContractDetails.pricelist_modified_date;
							this.rebatesDetails.pricelist_modified_user_action = editRebatesContractDetails.pricelist_modified_user_action;
							this.rebatesDetails.is_contract_lifecycle_management = editRebatesContractDetails.is_contract_lifecycle_management;
							this.rebatesDetails.terms_and_conditions = editRebatesContractDetails.terms_and_conditions || '';
							this.rebatesDetails.selectContactEmployeeId = editRebatesContractDetails.contactemployee_guid || '';
							this.rebatesDetails.selectContactEmployee = editRebatesContractDetails.contactemployee_name || '';

							this.doEnabledDisabledRebateType();
							this.rebatesDetails.selectPricelistno = editRebatesContractDetails.pricelistno || '';
							this.rebatesDetails.selectPricelistName = editRebatesContractDetails.pricelistname || '';
							this.aSelKeysPricelistNo = editRebatesContractDetails.pricelistno ? [editRebatesContractDetails.pricelistno] : [];
							this.aSelKeysPricelistDetails = editRebatesContractDetails.pricelistno ? [{
								pricelistno: editRebatesContractDetails.pricelistno || '',
								pricelistname: editRebatesContractDetails.pricelistname || ''
							}] : [];
							this.doSelectPricelist();
							this.setTextForPricelistDrpDwn();

							this.rebatesDetails.selectDifferentialPricelistno = editRebatesContractDetails.differential_pricelistno || '';
							this.rebatesDetails.selectDifferentialPricelistName = editRebatesContractDetails.differential_pricelistname || '';
							this.aSelKeysDifferentialPricelistNo = editRebatesContractDetails.differential_pricelistno ? [editRebatesContractDetails.differential_pricelistno] : [];
							this.aSelKeysDifferentialPricelistDetails = editRebatesContractDetails.differential_pricelistno ? [{
								pricelistno: editRebatesContractDetails.differential_pricelistno || '',
								pricelistname: editRebatesContractDetails.differential_pricelistname || ''
							}] : [];
							this.doSelectDifferentialPricelist();
							this.setTextForDifferentialPricelistDrpDwn();


							this.doCheckStatusBtnForDocSign();
							if (editRebatesContractDetails.cardcode) {
								const customerObj = {
									code: editRebatesContractDetails.cardcode,
									name: editRebatesContractDetails.name,
									guid: editRebatesContractDetails.cardcode_guid
								};
								this.aSelKeysCustomers = [customerObj.code];
								this.aSelKeysCustomersDetails = [customerObj];
								this.setTextForCustomerDrpDwn();
								this.doSelectCustomer();
								// if (this.isEnabledDocSign) {
								if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
									this.setSubmitMultiPleBtns();
									if (editRebatesContractDetails.contactemployee_guid) {
										const contactEmployeeObj = {
											name: editRebatesContractDetails.contactemployee_name,
											guid: editRebatesContractDetails.contactemployee_guid
										};
										this.rebatesDetails.selectContactEmployeeObj = contactEmployeeObj;
										this.aSelKeysContactEmployees = [editRebatesContractDetails.contactemployee_guid];
										this.aSelKeysContactEmployeesDetails = [];
										if (this.aSelKeysContactEmployeesDetails.findIndex(ceDetails => ceDetails.guid === this.rebatesDetails.selectContactEmployeeId) === -1) {
											this.aSelKeysContactEmployeesDetails.push(contactEmployeeObj);
										}
										setTimeout(() => {
											this.setTextForContactEmployeeDrpDwn();
											this.doSelectContactEmployee();
										}, 50);
									}
								}
								this.fetchedCustomerDetails(editRebatesContractDetails.cardcode);
								this.setEnabledDisabledAll();
							}
						}
						if (editRebatesContractDetails.rebateType === 'purchasing') {
							this.fetchedListOfVendors();
							this.setColumnForVendorGrid();
							this.rebatesDetails.selectVendorId = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectVendor = editRebatesContractDetails.name;
							this.rebatesDetails.selectVendorObj = {};
							this.rebatesDetails.selectVendorObj.code = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectVendorObj.name = editRebatesContractDetails.name;
							this.rebatesDetails.selectVendorObj.guid = editRebatesContractDetails.cardcode_guid;
							if (editRebatesContractDetails.cardcode) {
								const vendorObj = {
									code: editRebatesContractDetails.cardcode,
									name: editRebatesContractDetails.name,
									guid: editRebatesContractDetails.cardcode_guid
								}
								this.aSelKeysVendor = [vendorObj.code];
								this.aSelKeysVendorsDetails = [vendorObj];
								this.setTextForVendorDrpDwn();
								this.doSelectVendor();
								this.fetchedVendorDetails(editRebatesContractDetails.cardcode);
							}
						}

						if (editRebatesContractDetails.rebate_for === 'distribution' || editRebatesContractDetails.rebate_for === 'distribution_differential') {
							this.rebatesDetails.enduser_items_groups = editRebatesContractDetails.enduser_items_groups;
							this.listOfItemsAndGroupsItmGrpTab = editRebatesContractDetails.enduser_items_groups;
						} else {
							editRebatesContractDetails.items.forEach(item => {
								if (!item.hasOwnProperty('guid')) {
									item.guid = this.guid.newGuid();
								}
							});
							this.rebatesDetails.items = editRebatesContractDetails.items;
							this.listOfItemsAndGroupsItmGrpTab = editRebatesContractDetails.items;
						}
						setTimeout(() => {
							this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
						}, 50);
					}
					if (editRebatesContractDetails.rebate_for === 'growth_incentive') {
						if (editRebatesContractDetails.rebateType === 'sales') {
							this.fetchedListOfClients();
							this.setColumnForCrmGrid();
							if (!editRebatesContractDetails.hasOwnProperty('status')) {
								if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
									this.rebatesDetails.status = RebateStatusValEnum.DRAFT;
								} else {
									this.rebatesDetails.status = RebateStatusValEnum.ACTIVE;
								}
							}
							this.rebatesDetails.selectCustomerId = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectCustomer = editRebatesContractDetails.name;
							this.rebatesDetails.selectCustomerObj = {};
							this.rebatesDetails.selectCustomerObj.code = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectCustomerObj.name = editRebatesContractDetails.name;
							this.rebatesDetails.selectCustomerObj.guid = editRebatesContractDetails.cardcode_guid;
							this.rebatesDetails.pricelist_modified_date = editRebatesContractDetails.pricelist_modified_date;
							this.rebatesDetails.pricelist_modified_user_action = editRebatesContractDetails.pricelist_modified_user_action;
							this.rebatesDetails.is_contract_lifecycle_management = editRebatesContractDetails.is_contract_lifecycle_management;
							this.rebatesDetails.terms_and_conditions = editRebatesContractDetails.terms_and_conditions || '';
							this.rebatesDetails.selectContactEmployeeId = editRebatesContractDetails.contactemployee_guid || '';
							this.rebatesDetails.selectContactEmployee = editRebatesContractDetails.contactemployee_name || '';
							this.doCheckStatusBtnForDocSign();
							if (editRebatesContractDetails.cardcode) {
								const customerObj = {
									code: editRebatesContractDetails.cardcode,
									name: editRebatesContractDetails.name,
									guid: editRebatesContractDetails.cardcode_guid
								}
								this.aSelKeysCustomers = [customerObj.code];
								this.aSelKeysCustomersDetails = [customerObj];
								this.setTextForCustomerDrpDwn();
								this.doSelectCustomer();

								// if (this.isEnabledDocSign) {
								if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
									if (editRebatesContractDetails.contactemployee_guid) {
									}
									this.setSubmitMultiPleBtns();
								}
								this.fetchedCustomerDetails(editRebatesContractDetails.cardcode);
								this.setEnabledDisabledAll();
							}
						}
						if (editRebatesContractDetails.rebateType === 'purchasing') {
							this.fetchedListOfVendors();
							this.setColumnForVendorGrid();
							this.rebatesDetails.selectVendorId = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectVendor = editRebatesContractDetails.name;
							this.rebatesDetails.selectVendorObj = {};
							this.rebatesDetails.selectVendorObj.code = editRebatesContractDetails.cardcode;
							this.rebatesDetails.selectVendorObj.name = editRebatesContractDetails.name;
							this.rebatesDetails.selectVendorObj.guid = editRebatesContractDetails.cardcode_guid;
							if (editRebatesContractDetails.cardcode) {
								const vendorObj = {
									code: editRebatesContractDetails.cardcode,
									name: editRebatesContractDetails.name,
									guid: editRebatesContractDetails.cardcode_guid
								}
								this.aSelKeysVendor = [vendorObj.code];
								this.aSelKeysVendorsDetails = [vendorObj];
								this.setTextForVendorDrpDwn();
								this.doSelectVendor();
								this.fetchedVendorDetails(editRebatesContractDetails.cardcode);
							}
						}
						this.rebatesDetails.comparison_year = editRebatesContractDetails.comparison_year;
						editRebatesContractDetails.items.forEach(item => {
							if (!item.hasOwnProperty('guid')) {
								item.guid = this.guid.newGuid();
							}
						});
						this.rebatesDetails.items = editRebatesContractDetails.items;
						this.listOfItemsAndGroupsItmGrpTab = editRebatesContractDetails.items;
						setTimeout(() => {
							this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
						}, 50);
						if (this.productSearchItemType === 'price') {
							this.checkDifferentListPrice();
						}
					}
					if (editRebatesContractDetails.rebate_for === 'buyinggroup') {
						this.fetchedListOfBuyingGroups();
						this.setColumnForBuyingGroupGrid();
						this.rebatesDetails.selectBuyingGroupId = editRebatesContractDetails.cardcode;
						this.rebatesDetails.selectBuyingGroup = editRebatesContractDetails.name;
						this.rebatesDetails.selectBuyingGroupObj = {};
						this.rebatesDetails.selectBuyingGroupObj.code = editRebatesContractDetails.cardcode;
						this.rebatesDetails.selectBuyingGroupObj.name = editRebatesContractDetails.name;
						this.rebatesDetails.selectBuyingGroupObj.guid = editRebatesContractDetails.cardcode_guid;
						if (editRebatesContractDetails.cardcode) {
							const buyingGroupDetails = {
								code: editRebatesContractDetails.cardcode,
								name: editRebatesContractDetails.name,
								guid: editRebatesContractDetails.cardcode_guid
							};
							this.aSelKeysBuyingGroup = [buyingGroupDetails.code];
							this.aSelKeysBuyingGroupDetails = [buyingGroupDetails];
							this.setTextForBuyingGroupDrpDwn();
							this.doSelectBuyingGroup();
							this.fetchedBuyingGroupDetails(editRebatesContractDetails.cardcode);
						}
						this.rebatesDetails.member_mode = editRebatesContractDetails.member_mode || 'single_member';
						this.rebatesDetails.paymentType = editRebatesContractDetails.paymentType;
						this.rebatesDetails.buyingGroup = editRebatesContractDetails.buyingGroup;
						this.rebatesDetails.buyingGroupMember = editRebatesContractDetails.buyingGroupMember;
						if (editRebatesContractDetails.paymentType === 'split' || editRebatesContractDetails.paymentType === 'buyinggroup') {
							this.rebatesDetails.gl_account_number = editRebatesContractDetails.gl_account_number;
						}
						if (editRebatesContractDetails.paymentType === 'buyinggroupmember') {
							this.rebatesDetails.gl_account_number = '';
						}
						this.memberTabList = editRebatesContractDetails.members;
						this.listOfMembersItemsExclsnTab = editRebatesContractDetails.items;
						setTimeout(() => {
							this.dataSourceMemberLstMemberTab = this.memberTabList;
							this.dataSourceMembersLstExclsnTab = this.listOfMembersItemsExclsnTab;
						}, 50);
					}
					if (editRebatesContractDetails.rules && editRebatesContractDetails.rules.length > 0) {
						editRebatesContractDetails.rules.forEach(r => {
							if (!r.hasOwnProperty('guid')) {
								r.guid = this.guid.newGuid();
							}
						});
					}

					this.ruleTabList = editRebatesContractDetails.rules;
					this.rebatesDetails.rules = editRebatesContractDetails.rules;

					this.rebatesDetails.startDate = editRebatesContractDetails.startDate ? moment(editRebatesContractDetails.startDate).format('YYYY-MM-DD') : null;
					this.rebatesDetails.endDate = editRebatesContractDetails.endDate ? moment(editRebatesContractDetails.endDate).format('YYYY-MM-DD') : null;
					this.rebatesDetails.settlementPeriodStart = editRebatesContractDetails.settlementPeriodStart ? moment(editRebatesContractDetails.settlementPeriodStart).format('YYYY-MM-DD') : null;
					this.rebatesDetails.settlementPeriodEnd = editRebatesContractDetails.settlementPeriodEnd ? moment(editRebatesContractDetails.settlementPeriodEnd).format('YYYY-MM-DD') : null;
					this.rebatesDetails.settlementPeriod = editRebatesContractDetails.settlementPeriod;
					this.rebatesDetails.startSettlementPeriodOn = editRebatesContractDetails.startSettlementPeriodOn;
					this.rebatesDetails.contractCalendar = editRebatesContractDetails.contractCalendar;
					// this.rebatesDetails.agreementBy = editRebatesContractDetails.agreementBy;

					const spPerInvoiceIndex = this.listOfSettlementPeriods.findIndex(sp => sp.value === SettlementPeriodsValEnum.AUTOPAYMENT);
					this.listOfSettlementPeriods[spPerInvoiceIndex].visible = false;
					if (this.rebatesDetails.individualOrGroup === 'individual') {
						this.listOfSettlementPeriods[spPerInvoiceIndex].visible = true;
					}
					this.isShowBlockSettlementPeriods = true;
					if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.AUTOPAYMENT && (this.rebatesDetails.individualOrGroup === 'individual')) {
						this.isShowBlockSettlementPeriods = false;
					}
					if (this.rebatesDetails.individualOrGroup === 'individual') {
						if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS) {
							if (!this.rebatesDetails.is_contract_lifecycle_management) {
								this.isEnabledSettlemenPeriod = true;
							} else {
								if (this.rebatesDetails.status === RebateStatusValEnum.DRAFT) {
									this.isEnabledSettlemenPeriod = true;
								}
								if (this.rebatesDetails.status === RebateStatusValEnum.SIGNATURE_REQUESTED || this.rebatesDetails.status === RebateStatusValEnum.SIGNED || this.rebatesDetails.status === RebateStatusValEnum.ACTIVE) {
									this.isEnabledSettlemenPeriod = false;
								}
								if (this.rebatesDetails.status === RebateStatusValEnum.EXPIRED || this.rebatesDetails.status === RebateStatusValEnum.INACTIVE) {
									this.isEnabledSettlemenPeriod = false;
								}
							}
						} else if (this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
							if (this.rebatesDetails.status === RebateStatusValEnum.DRAFT) {
								this.isEnabledSettlemenPeriod = true;
							}
							if (this.rebatesDetails.status === RebateStatusValEnum.SIGNATURE_REQUESTED || this.rebatesDetails.status === RebateStatusValEnum.SIGNED || this.rebatesDetails.status === RebateStatusValEnum.ACTIVE) {
								this.isEnabledSettlemenPeriod = false;
							}
							if (this.rebatesDetails.status === RebateStatusValEnum.EXPIRED || this.rebatesDetails.status === RebateStatusValEnum.INACTIVE) {
								this.isEnabledSettlemenPeriod = false;
							}
						} else if (this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_OFF) {
							this.isEnabledSettlemenPeriod = true;
							this.fetchCloseSettlmenteReport();
						} else {
							this.isEnabledSettlemenPeriod = false;
						}
					}
					if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
						this.isEnabledSettlemenPeriod = false;
					}

					this.rebatesDetails.current_settlement_invoice_total = editRebatesContractDetails.current_settlement_invoice_total;
					this.rebatesDetails.current_settlement_creditmemo_total = editRebatesContractDetails.current_settlement_creditmemo_total;
					this.rebatesDetails.current_settlement_sales_total = editRebatesContractDetails.current_settlement_sales_total;
					this.rebatesDetails.current_settlement_commission_total = editRebatesContractDetails.current_settlement_rebate_total;
					this.rebatesDetails.is_active = editRebatesContractDetails.is_active;

					// if (this.rebatesDetails.startDate !== '' && this.rebatesDetails.endDate !== '') {
					// 	this.calculateSettlementPeriod(this.rebatesDetails);
					// }
					this.getDynamicYearsOfErpDataStartDate();
					this.setAgreementTabListData();
					this.changeIndividualOrGroup();
					this.setEnabledDisabledAll();

					this.rebatesDetails.tiers_type = editRebatesContractDetails.tiers_type || 'single_items';
					this.rebatesDetails.customer_item_match = editRebatesContractDetails.customer_item_match || 'exclusive';
					this.rebatesDetails.tier_mode = editRebatesContractDetails.tier_mode || 'absolute';
					this.rebatesDetails.tier_accrual_period = editRebatesContractDetails.tier_accrual_period;
					this.rebatesDetails.tiers_include = editRebatesContractDetails.tiers_include || 'only_items_in_tier';
					this.doShowHideTierAccrualPeriodsDropDown();
					// if (this.enable_rebate_settlement_approval_workflow) {
					this.fetchCloseSettlmenteReport();
					// }
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				console.error('error', error);
				// this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private fetchCloseSettlmenteReport() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('baseContractCode', this.editRebatesGuid);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('contracttype', 'rebatescontracts');
		formData.append('entity', ServerEntity.CLOSESETTLEMENTS);
		if (this.closeSettlementReportSbsn) {
			this.closeSettlementReportSbsn.unsubscribe();
		}
		this.closeSettlementReportSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					let closeSettlmentReports: any[] = [];
					try {
						closeSettlmentReports = response.data || [];
					} catch (e) {
						closeSettlmentReports = [];
					}
					this.listOfClosedSettlementReports = closeSettlmentReports;
					this.setEnabledDisabledAll();
					/*
					if (closeSettlmentReports.length > 1) {
						this.isEnabledSettlemenPeriod = false;
					}
					this.isDisabledBuyingGroupName = false;
					this.isEnabledStartDate = false;
					if (closeSettlmentReports.length > 2) {
						this.isDisabledBuyingGroupName = true;
						this.isEnabledStartDate = true;
					}
					if (this.enable_rebate_settlement_approval_workflow) {
						const draftIndex = closeSettlmentReports.findIndex(sr => sr.approval_status === RebateStatusValEnum.DRAFT);
						this.isDisabledSendCurrentSettlementForApprovalBtn = true;
						this.isEnabledSettlemenPeriod = false;
						if (draftIndex !== -1) {
							this.isEnabledSettlemenPeriod = true;
							this.isDisabledSendCurrentSettlementForApprovalBtn = false;
						}
					}
					*/
				}
			}, error: error => {
			}
		});
	}

	callViewSettlementPeriod(contractCode, callbackMethod: any) {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_CLOSE_SETTLEMENTS);
		formData.append('contracttype', 'rebatescontracts');
		formData.append('baseContractCode', contractCode);

		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					callbackMethod(true, response.data);
				} else {
					// this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					callbackMethod(false, []);
				}
			}, error: error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				callbackMethod(false, []);
			}
		});
	}

	public updateSettlementPeriodClick() {
		// this.saveRebatesContract();
		this.callUpdateSettlementPeriod(true);
	}

	public callUpdateSettlementPeriod(isShowLoader?: boolean) {
		if (this.rebatesRequest && this.rebatesRequest.guid) {
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('contracttype', 'rebatescontracts');
			formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
			formData.append('contractguid', this.rebatesRequest.guid);
			if (isShowLoader) {
				this._LoaderService.show();
			}
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
				next: response => {
					if (isShowLoader) {
						this._LoaderService.hide();
					}
					if (this.currentMainTab !== 'rebates_details') {
						this.currentMainTab = 'settlement_period';
					}
					if (response && response.flag) {
						this.rebatesDetails.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
						this.rebatesDetails.current_settlement_creditmemo_total = response.data.current_settlement_creditmemo_total;
						this.rebatesDetails.current_settlement_sales_total = response.data.current_settlement_sales_total;
						this.rebatesDetails.current_settlement_commission_total = response.data.current_settlement_rebate_total;
					} else {
						this._ToastrService.info(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				}, error: error => {
					if (isShowLoader) {
						this._LoaderService.hide();
					}

					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			});
		}
	}

	// Method used to fetched Customer Details
	private fetchedCustomerDetails(code) {
		const view_fields = ['guid', 'code', 'name', 'pricelistnum', 'contactemployees', 'contactperson', 'address'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('is_dropdown', '1');
		formData.append('entity', ServerEntity.CLIENTS);
		formData.append('view_fields', JSON.stringify(view_fields));
		const searchArr = [];
		searchArr.push({ 'code': code });
		formData.append('search', JSON.stringify(searchArr));

		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const clientDetails = response.data[0];

						this.rebatesDetails.selectCustomerId = clientDetails.code;
						this.rebatesDetails.selectCustomer = clientDetails.name;
						this.rebatesDetails.selectCustomerObj = clientDetails;
						this.aSelKeysCustomers = [clientDetails.code];
						this.aSelKeysCustomersDetails = [clientDetails];
						this.setTextForCustomerDrpDwn();
						this.doSelectCustomer();
						this.setItemsDataSourceItmGrpTab();
						this.fetchedListOfPrices(this.rebatesDetails.selectCustomerObj.pricelistnum, true);
						// if (this.isEnabledDocSign) {
						if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
							if (clientDetails.contactemployees && clientDetails.contactemployees.length > 0) {
								if (this.rebatesDetails.selectContactEmployeeId) {
									const ceIndex = clientDetails.contactemployees.findIndex(ce => ce.guid === this.rebatesDetails.selectContactEmployeeId);
									if (ceIndex !== -1) {
										this.rebatesDetails.selectContactEmployeeId = clientDetails.contactemployees[ceIndex].guid;
										this.rebatesDetails.selectContactEmployee = clientDetails.contactemployees[ceIndex].name;
										this.rebatesDetails.selectContactEmployeeObj = clientDetails.contactemployees[ceIndex];
										this.aSelKeysContactEmployees = [clientDetails.contactemployees[ceIndex].guid];
										this.aSelKeysContactEmployeesDetails = [];
										const existingceIndex = this.aSelKeysContactEmployeesDetails.findIndex(ceDetails => ceDetails.guid === this.rebatesDetails.selectContactEmployeeId);
										if (existingceIndex === -1) {
											this.aSelKeysContactEmployeesDetails.push(this.contactEmployeesLst[ceIndex]);
										} else {
											this.aSelKeysContactEmployeesDetails[existingceIndex] = this.contactEmployeesLst[ceIndex];
										}

										setTimeout(() => {
											this.setTextForContactEmployeeDrpDwn();
											this.doSelectContactEmployee();
										}, 50);
									}
								} else {
									// When Old Contract dont get ContactEmployee GUID
									const ceIndex = clientDetails.contactemployees.findIndex(ce => ce.name === clientDetails.contactperson);
									if (ceIndex !== -1) {
										this.rebatesDetails.selectContactEmployeeId = clientDetails.contactemployees[ceIndex].guid;
										this.rebatesDetails.selectContactEmployee = clientDetails.contactemployees[ceIndex].name;
										this.rebatesDetails.selectContactEmployeeObj = clientDetails.contactemployees[ceIndex];
										this.aSelKeysContactEmployees = [clientDetails.contactemployees[ceIndex].guid];
										this.aSelKeysContactEmployeesDetails = [];
										const existingceIndex = this.aSelKeysContactEmployeesDetails.findIndex(ceDetails => ceDetails.guid === this.rebatesDetails.selectContactEmployeeId);
										if (existingceIndex === -1) {
											this.aSelKeysContactEmployeesDetails.push(this.contactEmployeesLst[ceIndex]);
										} else {
											this.aSelKeysContactEmployeesDetails[existingceIndex] = this.contactEmployeesLst[ceIndex];
										}

										setTimeout(() => {
											this.setTextForContactEmployeeDrpDwn();
											this.doSelectContactEmployee();
										}, 50);
									}
								}
							}
						}
					}
				} else {
					// this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {

			}
		});
	}

	// Method used to Fetch particular vendor details
	private fetchedVendorDetails(code) {
		const view_fields = ['guid', 'code', 'name'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.VENDORS);
		formData.append('is_dropdown', '1');
		formData.append('view_fields', JSON.stringify(view_fields));
		formData.append('search', JSON.stringify([{ 'code': code }]));
		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					const vendorDetails = response.data || [];
					if (vendorDetails && vendorDetails.length > 0) {
						this.rebatesDetails.selectVendorId = vendorDetails[0].code;
						this.rebatesDetails.selectVendor = vendorDetails[0].name;
						this.rebatesDetails.selectVendorObj = vendorDetails[0];

						this.aSelKeysVendor = [vendorDetails[0].code];
						this.aSelKeysVendorsDetails = [vendorDetails[0]];
						this.setTextForVendorDrpDwn();
						this.doSelectVendor();
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {

			}
		});
	}

	// Method used to fetch particular buyinggroup details
	private fetchedBuyingGroupDetails(code) {
		const view_fields = ['guid', 'code', 'name'];
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.BUYINGGROUPS);
		formData.append('is_dropdown', '1');
		formData.append('view_fields', JSON.stringify(view_fields));
		formData.append('search', JSON.stringify([{ 'code': code }]));

		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					const buyingGroupDetails = response.data || [];
					if (buyingGroupDetails && buyingGroupDetails.length > 0) {
						this.rebatesDetails.selectBuyingGroupId = buyingGroupDetails[0].code;
						this.rebatesDetails.selectBuyingGroup = buyingGroupDetails[0].name;
						this.rebatesDetails.selectBuyingGroupObj = buyingGroupDetails[0];

						this.aSelKeysBuyingGroup = [buyingGroupDetails[0].code];
						this.aSelKeysBuyingGroupDetails = [buyingGroupDetails[0]];
						this.setTextForBuyingGroupDrpDwn();
						this.doSelectBuyingGroup();
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {

			}
		});
	}

	// Method used to update listPrice if Customer price list updated.
	private checkDifferentListPrice() {
		let pricelist_modified_date;
		try {
			pricelist_modified_date = this.listOfPrices[0].modified_date;
		} catch (e) {
			pricelist_modified_date = null;
		}

		if (new Date(this.rebatesDetails.pricelist_modified_date) <= new Date(pricelist_modified_date)) {
			if (this.rebatesDetails.pricelist_modified_user_action === 'ignored') {
				return false;
			}
			const itemGuidList = [];
			this.listOfItemsAndGroupsItmGrpTab.forEach(item => {
				if (!item.allproducts && item.rebate_basis === 'dollarperuom') {
					itemGuidList.push(item.item_guid);
				}
			});
			if (itemGuidList.length <= 0) {
				return false;
			}
			this.doCompareListPriceWithUomPrice();
			this.fetchedItemDetailsToCompareListPriceByGuid(itemGuidList);
		}
	}

	// Method used to compare listprice with updated customer pricelist
	private doCompareListPriceWithUomPrice() {
		this._GlobalStateService.unsubscribe('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', response => {
			const itemDetailsList = response.itemDetailsList;
			const priceListNum = this.getCustomerPriceListNum();
			let isShowConfirmDialog = false;
			for (let i = 0; i < this.listOfItemsAndGroupsItmGrpTab.length; i++) {
				const item = this.listOfItemsAndGroupsItmGrpTab[i];
				isShowConfirmDialog = false;
				if (!item.allproducts && item.rebate_basis === 'dollarperuom') {
					const itemDetailsIndex = itemDetailsList.findIndex(itmDtl => itmDtl.erp_primary_key === item.erp_primary_key);
					if (itemDetailsIndex !== -1) {
						const itemDetailsOrigin = itemDetailsList[itemDetailsIndex];
						const listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemDetailsOrigin, priceListNum);
						const uomIndex = listOfSpecificUoms.findIndex(uom => uom.code === item.selectedUom);
						if (uomIndex !== -1) {
							const newListPrice = +listOfSpecificUoms[uomIndex].price;
							if (newListPrice !== +item.listPrice) {
								isShowConfirmDialog = true;
								break;
							}
						}
					}
				}
			}
			if (isShowConfirmDialog) {
				let pricelistname = '';
				try {
					pricelistname = this.rebatesDetails.selectCustomerObj.pricelistname || '';
				} catch (e) {
					pricelistname = '';
				}

				const ixCustomDialogOptions: any = {
					popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
					showTitle: false,
					subtitle: 'Price List Update!',
					message: 'Customer price list \'' + pricelistname + '\' has been modified, do you want to update contract price list? ',
				};
				this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
					if (result) {
						for (let i = 0; i < this.listOfItemsAndGroupsItmGrpTab.length; i++) {
							const item = this.listOfItemsAndGroupsItmGrpTab[i];
							if (!item.allproducts && item.rebate_basis === 'dollarperuom') {
								const itemDetailsIndex = itemDetailsList.findIndex(itmDtl => itmDtl.erp_primary_key === item.erp_primary_key);
								const itemDetailsOrigin = itemDetailsList[itemDetailsIndex];
								const listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemDetailsOrigin, priceListNum);
								item.uomlist.forEach(storedUom => {
									const uomIndex = listOfSpecificUoms.findIndex(uom => uom.code.toString().toLowerCase() === storedUom.code.toString().toLowerCase());
									if (uomIndex !== -1) {
										if (+storedUom.price !== +listOfSpecificUoms[uomIndex].price) {
											storedUom.price = +listOfSpecificUoms[uomIndex].price;
										}
									}
								});
								const uomIndex = item.uomlist.findIndex(uom => uom.code.toString().toLowerCase() === item.selectedUom.toString().toLowerCase());
								if (uomIndex !== -1) {
									const newPrice = +item.uomlist[uomIndex].price || 0;
									if (item.tier_basis === 'notiers') {
										if (newPrice !== +item.listPrice) {
											item.listPrice = newPrice;
											item.finalrebateprice = newPrice - (+item.rate || 0);
										}
									}
									if (item.tier_basis === 'volume' || item.tier_basis === 'revenue') {
										item.listPrice = newPrice;
										item.tiers.forEach(tier => {
											if (newPrice !== +tier.listPrice) {
												tier.listPrice = newPrice;
												tier.finalrebateprice = newPrice - (+tier.rate || 0);
											}
										});
									}
								}
							}
						}
						if (this.editRebatesGuid) {
							this.rebatesDetails.pricelist_modified_user_action = 'agreed';
							if (this.listOfPrices.length > 0) {
								this.rebatesDetails.pricelist_modified_date = this.listOfPrices[0].modified_date;
							}
							setTimeout(() => {
								this.saveRebatesContract();
							}, 700);
						}
					} else {
						if (this.editRebatesGuid) {
							this.rebatesDetails.pricelist_modified_user_action = 'ignored';
							setTimeout(() => {
								this.saveRebatesContract();
							}, 700);
						}
					}
				});
			}
		});
	}

	// Method used to fetched item details to compare listprice with updated customer pricelist
	private fetchedItemDetailsToCompareListPriceByGuid(itemGuidList: string[]) {
		let itemDetailsList = [];
		const priceListNum = this.getCustomerPriceListNum();
		const view_fields = ['guid', 'erp_primary_key', 'itemname', 'itemsku', 'description', 'baseuom', 'uomgroupentry', 'itemprices'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEM_DETAILS);
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		formData.append('product_search_from', 'rebate');
		formData.append('view_fields', JSON.stringify(view_fields));
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		// this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}, error: error => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				// this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to return selected customer pricelistnum
	private getCustomerPriceListNum() {
		let priceListNum = '';
		try {
			priceListNum = this.rebatesDetails.selectCustomerObj.pricelistnum || '';
		} catch (e) {
			priceListNum = '';
		}
		return priceListNum;
	}
	//#endregion

	// #region for Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: error => {
				// this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOMGROUPS);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: error => {
				// this._LoaderService.hide();
			}
		});
	}

	private calculateUomPrice(price, uomgroupentry, sourceUom, targetUom) {
		let clculatePrice = price;
		let targeteDiscountAmount, baseDiscoutAmount = 0;

		let objSourceAbsEntry, objTargetAbsEntry;
		let sourceAbsEntry, targetAbsEntry = '';

		if (clculatePrice && clculatePrice > 0) {
			objSourceAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === sourceUom.toString().toLowerCase());
			objTargetAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === targetUom.toString().toLowerCase());
			if (objSourceAbsEntry && objSourceAbsEntry.length > 0) {
				sourceAbsEntry = objSourceAbsEntry[0].absentry;
			}
			if (objTargetAbsEntry && objTargetAbsEntry.length > 0) {
				targetAbsEntry = objTargetAbsEntry[0].absentry;
			}
			const objUomGroup = this.listOfUomGroups.filter(item => item.absentry.toString().toLowerCase() === uomgroupentry.toString().toLowerCase());
			if (objUomGroup && objUomGroup.length > 0) {
				const uomGroup = objUomGroup[0];
				if (uomGroup && uomGroup.uomgroupdefinitioncollection && uomGroup.uomgroupdefinitioncollection.length > 0) {
					if (sourceUom !== uomGroup.baseuom) {
						const sourceUomIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase());
						let sourceUomItem: any;
						if (sourceUomIndex !== -1) {
							sourceUomItem = uomGroup.uomgroupdefinitioncollection[sourceUomIndex];
						}
						// const sourceUomItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase())[0];
						if (sourceUomItem) {
							const itemQty = (sourceUomItem.basequantity / sourceUomItem.alternatequantity) || 0;
							baseDiscoutAmount = clculatePrice / itemQty;
						} else {
							baseDiscoutAmount = clculatePrice;
						}
					} else {
						baseDiscoutAmount = clculatePrice;
					}

					if (targetUom !== uomGroup.baseuom) {
						// const targetItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase())[0];
						const targetItemIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase());
						let targetItem: any;
						if (targetItemIndex !== -1) {
							targetItem = uomGroup.uomgroupdefinitioncollection[targetItemIndex];
						}
						const itemQty = (targetItem.basequantity / targetItem.alternatequantity) || 0;
						targeteDiscountAmount = baseDiscoutAmount * itemQty;
					} else {
						targeteDiscountAmount = baseDiscoutAmount;
					}
					// discountedPrice = (targeteDiscountAmount % 1) === 0 ? targeteDiscountAmount : targeteDiscountAmount.toFixed(4);
					clculatePrice = targeteDiscountAmount;
				}
			}
		}
		return clculatePrice;
	}
	// #endregion

	// #region for Individual and Growth Incentive Items/Groups Tab

	// Method used to preintialize Items/Groups Tab
	private doInitItemsAndGroupsTab() {
		setTimeout(() => {
			this.setHideShowColumnsItmGrpTab();
			if (this.itemsAndGroupsitmGrpTabDataGridRef) {
				if (this.rebatesDetails.individualOrGroup === 'individual') {
					this.itemsAndGroupsitmGrpTabDataGridRef.instance.columnOption('tier_basis', 'caption', 'Rebate Type');
					this.isImportBtnItmGrpTab = true;
				} else if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
					this.itemsAndGroupsitmGrpTabDataGridRef.instance.columnOption('tier_basis', 'caption', 'Rebate Type');
					this.isImportBtnItmGrpTab = true;
				} else if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
					this.itemsAndGroupsitmGrpTabDataGridRef.instance.columnOption('tier_basis', 'caption', 'Growth Basis');
					this.isImportBtnItmGrpTab = false;
				}
				this.itemsAndGroupsitmGrpTabDataGridRef.instance.repaint();
			}
			if (this.enable_classification_for_rebate) {
				this.loadClassesDataList();
			} else {
				this.setColumnForItemsDataGridItmGrpTab();
				this.setItemsDataSourceItmGrpTab();
			}

		}, 300);
	}

	// Radion Button Click on Items Groups
	public doChangeItemsOrGroupsRadioBtn(event) {

	}

	// Method used to set dynamic columns on items datagrid into dropdown
	private setColumnForItemsDataGridItmGrpTab() {
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.columnsItemsItmGrpTabDg = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				this.columnsItemsItmGrpTabDg.push({ dataField: 'price', caption: 'Price' });
			}
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.columnsItemsItmGrpTabDg = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				this.columnsItemsItmGrpTabDg.push({ dataField: 'price', caption: 'Price' });
			}
		} else {
			this.columnsItemsItmGrpTabDg = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				this.columnsItemsItmGrpTabDg.push({ dataField: 'price', caption: 'Price' });
			}
		}
	}

	// Method used to fetched Items List
	private setItemsDataSourceItmGrpTab() {
		const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
		let viewFields = ['guid', 'erp_primary_key', 'itemsku', 'itemname', 'description', 'uomgroupentry', 'price'];
		viewFields = viewFields.concat(featureCustomFieldsObj.listOfFeatureCustomFieldsName);

		let priceListNum = '';
		try {
			priceListNum = this.rebatesDetails.selectCustomerObj.pricelistnum || '';
		} catch (e) {
			priceListNum = '';
		}
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ITEMS,
			product_search_from: 'rebate',
			view_fields: JSON.stringify(viewFields)
		};
		if (priceListNum) {
			loadParams.pricelistnum = priceListNum;
		}
		this.itemsLstItmGrpTabDsDrpDwn = AspNetData.createStore({
			key: 'erp_primary_key',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to Clear filter of Datagrid when open dropdown
	public doOpenedDropdownItemsItmGrpTab(e) {
		this.refItemsLstItmGrpTabDg.instance.clearFilter();
	}

	// Method used to change values event of item dropdown
	public doChangeItemsItmGrpTabDrpdwn(event) {
		if (event.value === null) {
			this.sItemsLblItmGrpTabDrpDwn = '';
			this.isCheckedAllItemsItmGrpTabDrpDwnChkBox = false;
			this.aSelItemsItmGrpTabDg = [];
			this.aSelKeysItemsItmGrpTabDg = [];
			this.aItemsLstItmGrpTabDrpDwnTagBox = [];
		}
	}

	public doDisplayExprItemsItmGrpTabText(event: any) {
		let listOfTexts: any[] = [];
		if (this.isCheckedAllItemsItmGrpTabDrpDwnChkBox) {
			listOfTexts.push('All Items');
		}
		if (this.aSelKeysItemsItmGrpTabDg && this.aSelKeysItemsItmGrpTabDg.length > 0) {
			listOfTexts.push(this.aSelKeysItemsItmGrpTabDg.length + ' Item(s)');
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}

	// Method used to set string on item dropdown
	private setTextForItemItmGrpTabDrpDwn() {
		if (this.aSelItemsItmGrpTabDg.length <= 0 && !this.isCheckedAllItemsItmGrpTabDrpDwnChkBox) {
			this.sItemsLblItmGrpTabDrpDwn = '';
			return;
		}
		if (this.isCheckedAllItemsItmGrpTabDrpDwnChkBox) {
			this.sItemsLblItmGrpTabDrpDwn = 'All Item Selected';
		} else {
			if (this.aSelItemsItmGrpTabDg.length >= 0) {
				this.sItemsLblItmGrpTabDrpDwn = this.aSelItemsItmGrpTabDg.length + ' Item(s) Selected';
			}
		}
	}

	// Method used to change values of Checkbox above item datagrid in dropdown
	public doChangeAllItemsItmGrpTabDrpDwnChkBox(event) {
		this.setTextForItemItmGrpTabDrpDwn();
	}

	// Method used to select item of datagrid into dropdown
	public doSelChangedItemsItmGrpTabDg(event) {
		this.aSelItemsItmGrpTabDg = [];
		if (event.selectedRowsData && event.selectedRowsData.length > 0) {
			for (let i = 0; i < event.selectedRowsData.length; i++) {
				this.aSelItemsItmGrpTabDg.push(event.selectedRowsData[i]);
			}
		}
		this.setTagboxItemsItmGrpTab(event.selectedRowsData);
		this.setTextForItemItmGrpTabDrpDwn();
	}

	// Method used to Set tagbox values to show selected items
	private setTagboxItemsItmGrpTab(selectListOfItems: any[] = []) {
		this.aItemsLstItmGrpTabDrpDwnTagBox = [];
		for (let i = 0; i < selectListOfItems.length; i++) {
			this.aItemsLstItmGrpTabDrpDwnTagBox.push(selectListOfItems[i].itemname);
		}
	}

	// Method used to changed value of items tag box
	public doValueChangeItemsItmGrpTabTagBox(event) {
		const listTags = event.value || [];
		if (event.value.length !== event.previousValue.length) {
			for (let i = 0; i < this.aSelItemsItmGrpTabDg.length; i++) {
				const tagIndex = listTags.findIndex(tag => tag === this.aSelItemsItmGrpTabDg[i].itemname);
				if (tagIndex === -1) {
					const itemKeyIndex = this.aSelKeysItemsItmGrpTabDg.findIndex(itemKey => itemKey === this.aSelItemsItmGrpTabDg[i].erp_primary_key);
					this.aSelKeysItemsItmGrpTabDg.splice(itemKeyIndex, 1);
					this.aSelItemsItmGrpTabDg.splice(i, 1);
				}
			}
			this.setTextForItemItmGrpTabDrpDwn();
		}
	}

	public doAddItemsAndGroupsToListItmGrpTab() {
		// while Erp type as a NETSUITE
		if (this.enable_classification_for_rebate) {
			this.doAddClasesAndGroupsToListItmGrpTab();
			return;
		}
		if (this.checkValidationBeforeAddToDatagridItmGrpTab()) {
			return false;
		}
		const itemDetailsList: any[] = [];
		// const listOfSelectedRows = this.getSearchedItemsLstItmOrGrpTab;
		const listOfSelectedRows = this.aSelItemsItmGrpTabDg;
		const listOfGuids: string[] = [];
		listOfSelectedRows.forEach(rowEle => {
			itemDetailsList.push(rowEle);
			listOfGuids.push(rowEle.guid);
		});
		if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive' || this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') && this.rebatesDetails.rebateType === 'sales') {
			if (this.productSearchItemType === 'item') {
				this.addItemsAndGroupsToDatagridItmGrpTab();
				this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}
			if (this.productSearchItemType === 'price') {
				this.addItemsAndGroupsToDatagridItmGrpTab();
				if (listOfGuids.length > 0) {
					this.getItemDetailsByGuidsItmGrpTab(listOfGuids);
				} else {
					this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				}
			}
		}
		if (((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'purchasing') || this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			this.addItemsAndGroupsToDatagridItmGrpTab();
			this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
		}
	}

	public doAddClasesAndGroupsToListItmGrpTab() {
		this.selectedClassesData = this.refclassesDG.instance.getSelectedRowsData();
		if (this.selectedClassesData.length <= 0 && !this.chkAllClasses) {
			this._ToastrService.info('Classes is required!', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}

		if (this.chkAllClasses) {
			this.allClassesChecked();
		}

		if (this.rebatesDetails.tiers_type === 'multiple_items') {
			const itemEle = this.selectedClassesData[0];

			const itemOrGroup = this.itemsOrGroupsRadioBtn === 'itemsRadioBtn' ? 'item' : 'group';
			let tierIndex = -1;
			let rbIndex = -1;
			if (this.rebatesDetails.individualOrGroup === 'individual') {
				tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
				rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
			}
			if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
				tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
				rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
			}
			if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
				tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
				rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
			}
			if (itemEle.allproducts) {
				rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
			}
			const defaultTierBasis = this.listOfTierBasis[tierIndex].code;
			const defaultRebateBasis = this.listOfRebateBasis[rbIndex].code;
			let defaultSelectedUom = '';
			let defaultListPrice = 0;
			const item = new ItemsAndGroupsListItmsGrpsTabModel();
			item.guid = this.guid.newGuid();
			item.allclasses = itemEle.allclasses;
			item.item_guid = itemEle['id'];
			item.erp_primary_key = itemEle['id'];
			item.classguid = itemEle['guid'];
			item.classid = itemEle['id'];
			item.classname = itemEle['name'];
			item.description = itemEle.description || '';
			item.uomgroupentry = itemEle.uomgroupentry || '';
			item.tier_basis = defaultTierBasis; // Value Could be "volume" | "revenue" | "notiers"
			item.rebate_basis = defaultRebateBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
			item.selectedUom = defaultSelectedUom;
			item.listPrice = +defaultListPrice;
			item.rate = null;
			item.rebatefinalprice = defaultListPrice;
			item.tiers = [];
			item.items = this.selectedClassesData.map(classEle => {
				return {
					allclasses: classEle.allclasses,
					erp_primary_key: classEle['id'],
					classguid: classEle['guid'],
					classid: classEle['id'],
					classname: classEle['name'],
				}
			});
			if (this.doCheckDuplicateItemsForIndividualGrowthIncentive(item)) {
				this.listOfItemsAndGroupsItmGrpTab.push(item);
			}
		} else {
			this.selectedClassesData.forEach(itemEle => {
				const itemOrGroup = this.itemsOrGroupsRadioBtn === 'itemsRadioBtn' ? 'item' : 'group';
				let tierIndex = -1;
				let rbIndex = -1;
				if (this.rebatesDetails.individualOrGroup === 'individual') {
					tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
				}
				if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
					tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
				}
				if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
					tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
				}
				if (itemEle.allproducts) {
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
				}
				const defaultTierBasis = this.listOfTierBasis[tierIndex].code;
				const defaultRebateBasis = this.listOfRebateBasis[rbIndex].code;
				let defaultSelectedUom = '';
				let defaultListPrice = 0;
				const item = new ItemsAndGroupsListItmsGrpsTabModel();
				item.guid = this.guid.newGuid();
				item.allclasses = itemEle.allclasses;
				item.erp_primary_key = itemEle['id'];
				item.classguid = itemEle['guid'];
				item.classid = itemEle['id'];
				item.classname = itemEle['name'];
				item.description = itemEle.description || '';
				item.uomgroupentry = itemEle.uomgroupentry || '';
				item.tier_basis = defaultTierBasis; // Value Could be "volume" | "revenue" | "notiers"
				item.rebate_basis = defaultRebateBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
				item.selectedUom = defaultSelectedUom;
				item.listPrice = +defaultListPrice;
				item.rate = null;
				item.rebatefinalprice = defaultListPrice;
				item.tiers = [];

				if (this.listOfItemsAndGroupsItmGrpTab && this.listOfItemsAndGroupsItmGrpTab.length > 0) {
					const classIndex = this.listOfItemsAndGroupsItmGrpTab.findIndex(i => i.classguid === item.classguid);
					if (classIndex === -1) {
						this.listOfItemsAndGroupsItmGrpTab.push(item);
					} else {
						this.listOfItemsAndGroupsItmGrpTab[classIndex] = item;
					}
				} else {
					this.listOfItemsAndGroupsItmGrpTab.push(item);
				}
			});
		}

		this.clearDataAfterAddedToItemsAndGroupList();
		setTimeout(() => {
			this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
		}, 50);
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public allClassesChecked() {
		let itemname = 'All Classes';
		const itemModel = new ItemsAndGroupsListItmsGrpsTabModel();
		itemModel.guid = 'all_classes';
		itemModel.id = 'all_classes';
		itemModel.name = itemname;
		itemModel.allclasses = true;
		this.selectedClassesData.unshift(JSON.parse(JSON.stringify(itemModel)));
	}

	// Method used to Check Validation before adding rows to datagrid
	private checkValidationBeforeAddToDatagridItmGrpTab(): boolean {
		if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
			if (this.rebatesDetails.rebateType === 'purchasing') {
				const selectedRowsLength = this.getSearchedItemsLstItmOrGrpTab.filter(rowEle => rowEle.selected).length;
				// if (!this.isSelectAllItemsOrGroupsChkboxBtn && selectedRowsLength <= 0) {
				// 	this._ToastrService.info('Item is required!', 'Info', { closeButton: true, tapToDismiss: true });
				// 	return true;
				// }
				if (this.aSelItemsItmGrpTabDg.length <= 0 && !this.isCheckedAllItemsItmGrpTabDrpDwnChkBox) {
					this._ToastrService.info('Item is required!', 'Info', { closeButton: true, tapToDismiss: true });
					return true;
				}
			}
			if (this.rebatesDetails.rebateType === 'sales') {
				if (this.productSearchItemType === 'price') {
					if (!this.rebatesDetails.selectCustomerObj) {
						this._ToastrService.info('Customer Name is required!', 'Info', { closeButton: true, tapToDismiss: true });
						return true;
					}
					if (this.rebatesDetails.selectCustomerObj && !this.rebatesDetails.selectCustomerObj.pricelistnum) {
						this._ToastrService.info('Assign a price list to customer before adding items.', 'Info', { closeButton: true, tapToDismiss: true });
						return true;
					}
				}
				// const selectedRowsLength = this.getSearchedItemsLstItmOrGrpTab.filter(rowEle => rowEle.selected).length;
				// if (!this.isSelectAllItemsOrGroupsChkboxBtn && selectedRowsLength <= 0) {
				// 	this._ToastrService.info('Item is required!', 'Info', { closeButton: true, tapToDismiss: true });
				// 	return true;
				// }
				if (this.aSelItemsItmGrpTabDg.length <= 0 && !this.isCheckedAllItemsItmGrpTabDrpDwnChkBox) {
					this._ToastrService.info('Item is required!', 'Info', { closeButton: true, tapToDismiss: true });
					return true;
				}
				return false;
			}
		}
		return false;
	}

	// Method used to Get Item Details from List of Guids
	private getItemDetailsByGuidsItmGrpTab(itemGuidList: any[]) {
		let itemDetailsList: any[];
		if (itemGuidList.length <= 0) {
			this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
		}
		let priceListNum = '';
		try {
			priceListNum = this.rebatesDetails.selectCustomerObj.pricelistnum || '';
		} catch (e) {
			priceListNum = '';
		}
		const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
		let view_fields = ['guid', 'erp_primary_key', 'itemname', 'itemsku', 'description', 'baseuom', 'uomgroupentry', 'itemprices'];
		view_fields = view_fields.concat(featureCustomFieldsObj.listOfFeatureCustomFieldsName);
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEM_DETAILS);
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		formData.append('product_search_from', 'rebate');
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		formData.append('view_fields', JSON.stringify(view_fields));
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}, error: error => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Add selected row into datagrid.
	private addItemsAndGroupsToDatagridItmGrpTab() {
		this._GlobalStateService.unsubscribe('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GET_ITEM_DETAILS_LIST_ITMGRPTAB_EVENT', response => {
			const featureCustomFieldsForItemObj = this.getFeatureCustomFieldsForItems();
			const featureCustomFieldsForEndUserObj = this.getFeatureCustomFieldsForEndUsers();
			const itemDetails = response.itemDetailsList;
			const priceListNum = this.getCustomerPriceListNum();

			if (this.isCheckedAllItemsItmGrpTabDrpDwnChkBox) {
				let itemname = '';
				if (this.itemsOrGroupsRadioBtn === 'itemsRadioBtn') {
					itemname = 'All Items';
				}
				if (this.itemsOrGroupsRadioBtn === 'groupsRadioBtn') {
					itemname = 'All Groups';
				}
				const selectAllItem = {
					guid: itemname,
					item_guid: itemname,
					erp_primary_key: itemname,
					itemsku: '',
					itemname: itemname,
					description: '',
					uomgroupentry: '',
					allproducts: true,
				};
				itemDetails.unshift(selectAllItem);
			}
			const listOfAllEndUser = [];
			if (this.isCheckedAllEndUserItmGrpTabDrpDwnChkBox) {
				const allEndUserObj = {
					allenduser: true,
					guid: 'all_enduser',
					enduser_guid: 'all_enduser',
					code: 'all_enduser',
					companyname: 'All End-User',
				};
				listOfAllEndUser.push(allEndUserObj);
			}

			if (this.rebatesDetails.tiers_type === 'multiple_items') {
				const itemEle = itemDetails[0];
				let listOfSpecificUoms = [];
				if (!itemEle.allproducts) {
					listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemEle, priceListNum);
				}
				const itemOrGroup = this.itemsOrGroupsRadioBtn === 'itemsRadioBtn' ? 'item' : 'group';
				let tierIndex = -1;
				let rbIndex = -1;
				if (this.rebatesDetails.individualOrGroup === 'individual') {
					tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollarperuom');
				}
				if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
					tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollarperuom');
				}
				if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
					tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'volume');
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
				}
				if (itemEle.allproducts) {
					rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
				}
				const defaultTierBasis = this.listOfTierBasis[tierIndex].code;
				const defaultRebateBasis = this.listOfRebateBasis[rbIndex].code;
				let defaultSelectedUom = '';
				let defaultListPrice = 0;
				if (listOfSpecificUoms.length > 0) {
					defaultSelectedUom = listOfSpecificUoms[0].code || '';
					defaultListPrice = listOfSpecificUoms[0].price || 0;
				}

				const item = new ItemsAndGroupsListItmsGrpsTabModel();
				item.guid = this.guid.newGuid();
				item.item_guid = itemEle.guid || '';
				item.erp_primary_key = itemEle.erp_primary_key;
				item.itemsku = '';
				item.itemname = itemEle.itemname || '';
				item.itemOrGroup = itemOrGroup;
				item.description = '';
				item.uomgroupentry = itemEle.uomgroupentry || '';
				item.allproducts = itemEle.allproducts || false;
				item.tier_basis = defaultTierBasis; // Value Could be "volume" | "revenue" | "notiers"
				item.rebate_basis = defaultRebateBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
				item.uomlist = listOfSpecificUoms;
				item.selectedUom = defaultSelectedUom;
				item.listPrice = +defaultListPrice;
				item.rate = null;
				item.rebatefinalprice = defaultListPrice;
				item.tiers = [];
				item.items = itemDetails.map(item => {
					return item;
				});
				if (this.doCheckDuplicateItemsForIndividualGrowthIncentive(item)) {
					this.listOfItemsAndGroupsItmGrpTab.push(item);
				}
			} else {

				if (this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') {
					const listOfSelectedEndUsers = this.refEndUserLstItmGrpTabDrpDwn.instance.getSelectedRowsData();
					const listOfMergedSelectedEndUsers = [...listOfAllEndUser, ...listOfSelectedEndUsers];
					listOfMergedSelectedEndUsers.forEach(endUser => {
						itemDetails.forEach(itemEle => {
							const itemOrGroup = this.itemsOrGroupsRadioBtn === 'itemsRadioBtn' ? 'item' : 'group';
							let tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
							let rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollarperuom');

							let listOfSpecificUoms = [];
							if (!itemEle.allproducts) {
								listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemEle, priceListNum);
							} else {
								// rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
								listOfSpecificUoms = this.listOfUoms;
							}

							const defaultTierBasis = this.listOfTierBasis[tierIndex].code;
							const defaultRebateBasis = this.listOfRebateBasis[rbIndex].code;

							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}

							const item = new ItemsAndGroupsListItmsGrpsTabModel();
							item.guid = this.guid.newGuid();
							item.allenduser = endUser.allenduser || false;
							item.enduserguid = endUser.enduser_guid;
							item.endusercode = endUser.code || '';
							item.endusername = endUser.companyname || '';
							if (!endUser.allenduser) {
								if (!item.hasOwnProperty('shipto')) {
									item.shipto = <EndUserShippingAddressInterface>{};
								}
								item.shipto.guid = endUser.guid || '';
								item.shipto.addressname = endUser.addressname || '';
								item.shipto.addresstype = endUser.addresstype || '';
								item.shipto.addressname2 = endUser.addressname2 || '';
								item.shipto.addressname3 = endUser.addressname3 || '';
								item.shipto.city = endUser.city || '';
								item.shipto.state = endUser.state || '';
								item.shipto.country = endUser.country || '';
								item.shipto.zipcode = endUser.zipcode || '';
								if (featureCustomFieldsForEndUserObj.listOfFeatureCustomFieldsName && featureCustomFieldsForEndUserObj.listOfFeatureCustomFieldsName.length > 0) {
									featureCustomFieldsForEndUserObj.listOfFeatureCustomFieldsName.forEach(field => {
										if (endUser.hasOwnProperty(field)) {
											item.shipto[field] = endUser[field];
										}
									});
								}
							}
							item.allproducts = itemEle.allproducts || false;
							item.item_guid = itemEle.guid || '';
							item.erp_primary_key = itemEle.erp_primary_key;
							item.itemsku = itemEle.itemsku || '';
							item.itemname = itemEle.itemname || '';
							item.itemOrGroup = itemOrGroup;
							item.description = itemEle.description || '';
							item.uomgroupentry = itemEle.uomgroupentry || '';
							item.allproducts = itemEle.allproducts || false;
							item.tier_basis = defaultTierBasis; // Value Could be "volume" | "revenue" | "notiers"
							item.rebate_basis = defaultRebateBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
							item.uomlist = listOfSpecificUoms;
							item.selectedUom = defaultSelectedUom;
							item.listPrice = +defaultListPrice;
							item.rate = null;
							item.rebatefinalprice = defaultListPrice;
							item.tiers = [];
							item.in_pricelist = true;
							if (featureCustomFieldsForItemObj.listOfFeatureCustomFieldsName && featureCustomFieldsForItemObj.listOfFeatureCustomFieldsName.length > 0) {
								featureCustomFieldsForItemObj.listOfFeatureCustomFieldsName.forEach(field => {
									if (itemEle.hasOwnProperty(field)) {
										item[field] = itemEle[field];
									}
								});
							}
							this.listOfItemsAndGroupsItmGrpTab.push(item);
						});
					});
				} else {
					itemDetails.forEach(itemEle => {
						let listOfSpecificUoms = [];
						if (!itemEle.allproducts) {
							listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemEle, priceListNum);
						}
						const itemOrGroup = this.itemsOrGroupsRadioBtn === 'itemsRadioBtn' ? 'item' : 'group';
						let tierIndex = -1;
						let rbIndex = -1;
						if (this.rebatesDetails.individualOrGroup === 'individual') {
							tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
							rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollarperuom');
						}
						if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
							tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
							rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollarperuom');
						}
						if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
							tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'volume');
							rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
						}
						if (this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') {
							tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === 'notiers');
							rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollarperuom');
						}
						if (itemEle.allproducts) {
							rbIndex = this.listOfRebateBasis.findIndex(rb => rb.code === 'dollar');
						}
						const defaultTierBasis = this.listOfTierBasis[tierIndex].code;
						const defaultRebateBasis = this.listOfRebateBasis[rbIndex].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}

						const item = new ItemsAndGroupsListItmsGrpsTabModel();
						item.guid = this.guid.newGuid();
						item.item_guid = itemEle.guid || '';
						item.erp_primary_key = itemEle.erp_primary_key;
						item.itemsku = itemEle.itemsku || '';
						item.itemname = itemEle.itemname || '';
						item.itemOrGroup = itemOrGroup;
						item.description = itemEle.description || '';
						item.uomgroupentry = itemEle.uomgroupentry || '';
						item.allproducts = itemEle.allproducts || false;
						item.tier_basis = defaultTierBasis; // Value Could be "volume" | "revenue" | "notiers"
						item.rebate_basis = defaultRebateBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						item.uomlist = listOfSpecificUoms;
						item.selectedUom = defaultSelectedUom;
						item.listPrice = +defaultListPrice;
						item.rate = null;
						item.rebatefinalprice = defaultListPrice;
						item.tiers = [];
						this.listOfItemsAndGroupsItmGrpTab.push(item);
					});
				}
			}
			this.clearDataAfterAddedToItemsAndGroupList();
			setTimeout(() => {
				this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
			}, 50);
			if (this.editRebatesGuid) {
				setTimeout(() => {
					this.saveRebatesContract();
				}, 700);
			}
		});
	}

	// Method used to Clear Data after Items added to datagrid
	private clearDataAfterAddedToItemsAndGroupList() {
		this.sItemsLblItmGrpTabDrpDwn = '';
		this.isCheckedAllItemsItmGrpTabDrpDwnChkBox = false;
		this.aItemsLstItmGrpTabDrpDwnTagBox = [];
		this.aSelKeysItemsItmGrpTabDg = [];
		this.aSelItemsItmGrpTabDg = [];
		this.isSelectAllItemsOrGroupsChkboxBtn = false;
		this.lengthSelectedClasses = '';
		this.chkAllClasses = false;
		this.arrSelectedClassesList = [];
		if (this.refclassesDG) {
			this.refclassesDG.instance.refresh();
			this.refclassesDG.instance.clearFilter();
		}
		this.isCheckedAllEndUserItmGrpTabDrpDwnChkBox = false;
		this.aSelKeysEndUserItmGrpTabDg = [];
		this.aSelEndUserDetailsItmGrpTabDg = [];
		this.aEndUserItmGrpTabDrpDwnTagBox = [];
		this.sEndUserLblItmGrpTabDrpDwn = '';
	}

	// #region for Items And Groups Datagrid Methods
	public getTiersByItemDetails(element) {
		return element.data.tiers || [];
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					visible: this.isImportBtnItmGrpTab && !this.enable_classification_for_rebate,
					icon: 'xlsxfile',
					text: "Import Excel/Csv",
					disabled: this.isDisabledImportExcelCsv,
					onInitialized: (args: any) => {
						this.btnImportInstance = args.component;
					},
					onClick: (event: any) => {
						this.doBulkUploadItmGrpTab();
					},
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.options.icon = "columnChooser";
			columnChooserButton.showText = "always";
			columnChooserButton.icon = 'columnChooser';
		}
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.itemsAndGroupsitmGrpTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'ItemAndGroupTab.xlsx';
		worksheet = workbook.addWorksheet('Item And Group');


		const listOfMasterDetailsRows: any[] = [];
		exportDataGrid({
			worksheet: worksheet,
			component: this.itemsAndGroupsitmGrpTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 11, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'itemname') {
						listOfMasterDetailsRows.push({
							rowIndex: excelCell.fullAddress.row + 1,
							data: gridCell.data
						});
					}
					if (gridCell.column.dataField === 'rate') {
						if (gridCell.data.tier_basis === 'notiers') {
							if ((gridCell.data.rebate_basis === 'dollar' || gridCell.data.rebate_basis === 'dollarperuom')) {
								excelCell.value = this._CustomCurrencyPipe.transform(+gridCell.data.rate || 0, this.customCurrencyOption);
							}
							if (gridCell.data.rebate_basis === 'percentage') {
								excelCell.value = this._CustomNumberNoRoundingPipe.transform(+gridCell.data.rate || 0) + '%';
							}
						}
					}
				}
			}
		}).then((cellRange) => {
			const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = worksheet.insertRow(currentIndex, [], "n");
				for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
					worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			let columnsField = [
				'mintier',
				'maxtier',
				'rate'
			];
			let columns = [
				'Tier Minimum',
				'Tier Maximum',
				'Rate'
			];

			let rebate_basis, tier_basis;
			for (let i = 0; i < listOfMasterDetailsRows.length; i++) {
				rebate_basis = listOfMasterDetailsRows[i].data.rebate_basis;
				tier_basis = listOfMasterDetailsRows[i].data.tier_basis;
				let itemColumnsField = [
					'itemsku',
					'itemname',
					'description',
					'rate',
				];
				let itemCaptionColumns = [
					'SKU',
					'Item',
					'Description',
					'Rate'
				];

				if (rebate_basis === 'dollarperuom' && !this.enable_classification_for_rebate) {
					itemColumnsField = [
						'itemsku',
						'itemname',
						'description',
						'selectedUom',
						'rate',
					];
					let itemColumns = [
						'SKU',
						'Item',
						'Description',
						'UOM',
						'Rate'
					];
				}

				if (this.enable_classification_for_rebate) {
					itemColumnsField = [
						'classname',
						'rate',
					];
					itemCaptionColumns = [
						'Classes',
						'Rate'
					];
				}

				if (!this.isQBOnlineErpUser) {
					const itemskuColFieldIndex = itemColumnsField.findIndex(colField => colField === 'itemsku');
					if (itemskuColFieldIndex !== -1) {
						itemColumnsField.splice(itemskuColFieldIndex, 1);
					}
					const itemskuColCaptionIndex = itemCaptionColumns.findIndex(colCaption => colCaption === 'SKU');
					if (itemskuColCaptionIndex !== -1) {
						itemCaptionColumns.splice(itemskuColCaptionIndex, 1);
					}
				}

				if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
					columnsField = [
						'mintier',
						'maxtier',
						'listPrice',
						'rate',
						'rebatefinalprice'
					];
					columns = [
						'Tier Minimum',
						'Tier Maximum',
						'List Price',
						'Rate',
						'Final Price'
					];
				}

				let row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				const columnIndex = cellRange.from.column;
				// row.height = 24;
				// worksheet.mergeCells(row.number, columnIndex, row.number, 7);

				if (listOfMasterDetailsRows[i].data.tiers.length > 0) {
					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);

					columns.forEach((columnName, currentColumnIndex) => {
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							fill: {
								type: "pattern",
								pattern: "solid",
								fgColor: { argb: "BEDFE6" }
							},
							font: { bold: true },
							border: {
								bottom: borderStyle,
								left: borderStyle,
								right: borderStyle,
								top: borderStyle
							}
						});
					});

					let subTotalLineTotal = 0;
					listOfMasterDetailsRows[i].data.tiers.forEach((tierDetails) => {
						const itemColumnIndexDetail = cellRange.from.column;
						row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						columnsField.forEach((columnName, currentColumnIndex) => {
							const cellObject = {
								value: tierDetails[columnName],
								fill: {
									type: "pattern",
									pattern: "solid",
									fgColor: { argb: "BEDFE6" }
								},
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								},
								alignment: { horizontal: 'left' }
							};

							if (columnName === 'mintier') {
								if (!tierDetails[columnName]) {
									cellObject.value = tierDetails[columnName];
								} else if (tierDetails[columnName] === '-') {
									cellObject.value = tierDetails[columnName];
								} else {
									if (tier_basis === 'volume') {
										cellObject.value = tierDetails[columnName] || '0';
									} else if (tier_basis === 'revenue') {
										cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
									} else {
										cellObject.value = tierDetails[columnName];
									}
								}
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'maxtier') {
								if (!tierDetails[columnName]) {
									cellObject.value = tierDetails[columnName];
								} else if (tierDetails[columnName] === '-') {
									cellObject.value = tierDetails[columnName];
								} else {
									if (tier_basis === 'volume') {
										cellObject.value = tierDetails[columnName] || '0';
									} else if (tier_basis === 'revenue') {
										cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
									} else {
										cellObject.value = tierDetails[columnName];
									}
								}
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'rate') {
								if (((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'purchasing') || this.rebatesDetails.individualOrGroup === 'buyinggroup') {
									subTotalLineTotal = subTotalLineTotal + tierDetails[columnName];
								}
								if ((rebate_basis === 'dollar' || rebate_basis === 'dollarperuom')) {
									cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
								}
								if (rebate_basis === 'percentage') {
									cellObject.value = this._CustomNumberNoRoundingPipe.transform(tierDetails[columnName]) + '%';
								}
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'listPrice') {
								cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName]), this.customCurrencyOption;
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'finalrebateprice') {
								if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
									subTotalLineTotal = subTotalLineTotal + tierDetails[columnName];
								}
								cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
						});

						row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						if (this.rebatesDetails.tiers_type === 'multiple_items') {
							row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 3);
							itemCaptionColumns.forEach((columnName, currentColumnIndex) => {
								Object.assign(row.getCell(itemColumnIndexDetail + currentColumnIndex), {
									value: columnName,
									fill: {
										type: "pattern",
										pattern: "solid",
										fgColor: { argb: "BEDFE6" }
									},
									font: { bold: true },
									border: {
										bottom: borderStyle,
										left: borderStyle,
										right: borderStyle,
										top: borderStyle
									}
								});
							});

							tierDetails.items.forEach((itemDetails, itemIndex) => {
								row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 3);
								row.keepColumnWidths = true;
								itemColumnsField.forEach((columnName, currentColumnIndex) => {
									const cellObject = {
										value: itemDetails[columnName],
										fill: {
											type: "pattern",
											pattern: "solid",
											fgColor: { argb: "BEDFE6" }
										},
										border: {
											bottom: borderStyle,
											left: borderStyle,
											right: borderStyle,
											top: borderStyle
										},
										alignment: { horizontal: 'left' }
									};

									if (columnName === 'rate') {
										if ((rebate_basis === 'dollar' || rebate_basis === 'dollarperuom')) {
											cellObject.value = this._CustomCurrencyPipe.transform(+itemDetails[columnName] || 0, this.customCurrencyOption);
										} else if (rebate_basis === 'percentage') {
											cellObject.value = this._CustomNumberNoRoundingPipe.transform(+itemDetails[columnName] || 0) + '%';
										} else {
											cellObject.value = itemDetails[columnName] || '';
										}
										cellObject.alignment.horizontal = 'right';
									}
									Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
									row.keepColumnWidths = true;
								});
							});
							row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
							row.keepColumnWidths = true;
						}
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;
					if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
						worksheet.mergeCells(row.number, columnIndex, row.number, 4);
						rowLastCell = 5;
					} else {
						worksheet.mergeCells(row.number, columnIndex, row.number, 2);
						rowLastCell = 3;
					}
					Object.assign(row.getCell(columnIndex), {
						value: 'Sub Total',
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'right' }
					});

					Object.assign(row.getCell(rowLastCell), {
						value: this._CustomCurrencyPipe.transform(subTotalLineTotal, this.customCurrencyOption),
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				}
				offset--;
			}

			this.doAddContractDetailsToWorkSheet(worksheet);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Commonly used between two different export to excel
	private doAddContractDetailsToWorkSheet(worksheet) {
		const contractTypeIndex = this.listOfRebateTypes.findIndex(rebateType => rebateType.value === this.rebatesDetails.individualOrGroup);
		let contractTypeName = '';
		if (contractTypeIndex !== -1) {
			contractTypeName = this.listOfRebateTypes[contractTypeIndex].text;
		}
		let rebateType = '';
		if (this.rebatesDetails.individualOrGroup == 'individual' || this.rebatesDetails.individualOrGroup == 'growth_incentive') {
			const rebateTypeIndex = this.listOfRebateSubTypes.findIndex(rebateSubType => rebateSubType.value === this.rebatesDetails.rebateType);
			if (rebateTypeIndex !== -1) {
				rebateType = this.listOfRebateSubTypes[rebateTypeIndex].text;
				contractTypeName = contractTypeName + ((rebateType) ? ' (' + rebateType + ')' : '');
			}
		}
		const cotractTypeRow = worksheet.getRow(1);
		// worksheet.mergeCells(3, 4, 3, 7);
		cotractTypeRow.height = 30;
		cotractTypeRow.getCell(1).value = 'Contract Type';
		cotractTypeRow.getCell(1).font = { 'bold': true };
		cotractTypeRow.getCell(2).value = contractTypeName;
		cotractTypeRow.getCell(2).alignment = { horizontal: 'left' };

		const contractNameRow = worksheet.getRow(2);
		contractNameRow.height = 30;
		contractNameRow.getCell(1).value = 'Contract Name';
		contractNameRow.getCell(1).font = { 'bold': true };
		contractNameRow.getCell(2).value = this.rebatesDetails.contractName;
		contractNameRow.getCell(2).alignment = { horizontal: 'left' };

		let contractForLabel = '';
		let contractForValue = '';
		if (this.rebatesDetails.individualOrGroup == 'individual') {
			if (this.rebatesDetails.rebateType === 'purchasing') {
				contractForLabel = 'Vendor Name';
				contractForValue = this.sVendorsLbl;
			}
			if (this.rebatesDetails.rebateType === 'sales') {
				contractForLabel = 'Customer Name';
				contractForValue = this.sCustomersLbl;
			}
		} else if (this.rebatesDetails.individualOrGroup == 'growth_incentive') {
			if (this.rebatesDetails.rebateType === 'purchasing') {
				contractForLabel = 'Vendor Name';
				contractForValue = this.sVendorsLbl;
			}
			if (this.rebatesDetails.rebateType === 'sales') {
				contractForLabel = 'Customer Name';
				contractForValue = this.sCustomersLbl;
			}
		} else if (this.rebatesDetails.individualOrGroup == 'buyinggroup') {
			contractForLabel = 'Buying Group Name';
			contractForValue = this.sBuyingGroupsLbl;
		}

		if (this.rebatesDetails.individualOrGroup == 'growth_incentive') {
			contractNameRow.getCell(5).value = 'Comparison Year';
			contractNameRow.getCell(5).font = { 'bold': true };
			contractNameRow.getCell(6).value = this.rebatesDetails.comparison_year;
			contractNameRow.getCell(6).alignment = { horizontal: 'left' };
		}

		if (this.rebatesDetails.individualOrGroup == 'buyinggroup') {
			let paymentTypeName = '';
			const paymentTypeIndex = this.listOfPaymentTypes.findIndex(pt => pt.value === this.rebatesDetails.paymentType);
			if (paymentTypeIndex !== -1) {
				paymentTypeName = this.listOfPaymentTypes[paymentTypeIndex].text;
			}
			contractNameRow.getCell(5).value = 'Payment Type';
			contractNameRow.getCell(5).font = { 'bold': true };
			contractNameRow.getCell(6).value = paymentTypeName;
			contractNameRow.getCell(6).alignment = { horizontal: 'left' };
		}

		const contractForNameRow = worksheet.getRow(3);
		contractForNameRow.height = 30;
		contractForNameRow.getCell(1).value = contractForLabel;
		contractForNameRow.getCell(1).font = { 'bold': true };
		contractForNameRow.getCell(2).value = contractForValue;
		contractForNameRow.getCell(2).alignment = { horizontal: 'left' };

		const customerItemMatchIndex = this.listOfCustomerItemMatch.findIndex(custItm => custItm.value === this.rebatesDetails.customer_item_match);
		let customerItemMatchLabel = '';
		if (customerItemMatchIndex !== -1) {
			customerItemMatchLabel = this.listOfCustomerItemMatch[customerItemMatchIndex].text;
		}
		contractForNameRow.getCell(5).value = 'Customer/Item Match';
		contractForNameRow.getCell(5).font = { 'bold': true };
		contractForNameRow.getCell(6).value = customerItemMatchLabel;
		contractForNameRow.getCell(6).alignment = { horizontal: 'left' };

		if (this.rebatesDetails.individualOrGroup == 'buyinggroup' && this.rebatesDetails.paymentType == 'split') {
			contractForNameRow.getCell(5).value = 'Buying Group';
			contractForNameRow.getCell(5).font = { 'bold': true };
			contractForNameRow.getCell(6).value = this._CustomNumberNoRoundingPipe.transform(this.rebatesDetails.buyingGroup) + '%';
			contractForNameRow.getCell(6).alignment = { horizontal: 'right' };
		}

		const startDateRow = worksheet.getRow(4);
		startDateRow.height = 30;
		startDateRow.getCell(1).value = 'Start Date';
		startDateRow.getCell(1).font = { 'bold': true };
		startDateRow.getCell(2).value = moment(this.rebatesDetails.startDate).format('YYYY-MM-DD');
		startDateRow.getCell(2).alignment = { horizontal: 'left' };


		const tierTypeIndex = this.listOfTiersType.findIndex(custItm => custItm.value === this.rebatesDetails.tiers_type);
		let tierTypeLabel = '';
		if (tierTypeIndex !== -1) {
			tierTypeLabel = this.listOfTiersType[tierTypeIndex].text;
		}
		startDateRow.getCell(5).value = 'Tiers Type';
		startDateRow.getCell(5).font = { 'bold': true };
		startDateRow.getCell(6).value = tierTypeLabel;
		startDateRow.getCell(6).alignment = { horizontal: 'left' };

		if (this.rebatesDetails.individualOrGroup == 'buyinggroup' && this.rebatesDetails.paymentType == 'split') {
			startDateRow.getCell(5).value = 'Buying Group Member';
			startDateRow.getCell(5).font = { 'bold': true };
			startDateRow.getCell(6).value = this._CustomNumberNoRoundingPipe.transform(this.rebatesDetails.buyingGroupMember) + '%';
			startDateRow.getCell(6).alignment = { horizontal: 'right' };
		}


		const endDateRow = worksheet.getRow(5);
		endDateRow.height = 30;
		endDateRow.getCell(1).value = 'End Date';
		endDateRow.getCell(1).font = { 'bold': true };
		endDateRow.getCell(2).value = moment(this.rebatesDetails.endDate).format('YYYY-MM-DD');
		endDateRow.getCell(2).alignment = { horizontal: 'left' };
		if (this.rebatesDetails.individualOrGroup == 'buyinggroup') {
		}
		let settlementPeriod = '';
		const settlementPeriodIndex = this.listOfSettlementPeriods.findIndex(sp => sp.value === this.rebatesDetails.settlementPeriod);
		if (settlementPeriodIndex !== -1) {
			settlementPeriod = this.listOfSettlementPeriods[settlementPeriodIndex].text;
		}

		const settlementPeriodRow = worksheet.getRow(6);
		settlementPeriodRow.height = 30;
		settlementPeriodRow.getCell(1).value = 'Settlement Period';
		settlementPeriodRow.getCell(1).font = { 'bold': true };
		settlementPeriodRow.getCell(2).value = settlementPeriod;
		settlementPeriodRow.getCell(2).alignment = { horizontal: 'left' };
		if (this.rebatesDetails.settlementPeriodStart && this.rebatesDetails.settlementPeriodEnd) {
			const currentSettlementPeriod = moment(this.rebatesDetails.settlementPeriodStart).format('YYYY-MM-DD') + ' To ' + moment(this.rebatesDetails.settlementPeriodEnd).format('YYYY-MM-DD');
			settlementPeriodRow.getCell(5).value = 'Current Settlement Period';
			settlementPeriodRow.getCell(5).font = { 'bold': true };
			settlementPeriodRow.getCell(6).value = currentSettlementPeriod;
			settlementPeriodRow.getCell(6).alignment = { horizontal: 'left' };
		}

		const invoiceTotalRow = worksheet.getRow(7);
		invoiceTotalRow.height = 30;
		invoiceTotalRow.getCell(1).value = 'Invoice Total';
		invoiceTotalRow.getCell(1).font = { 'bold': true };
		invoiceTotalRow.getCell(2).value = this._CustomCurrencyPipe.transform(+this.rebatesDetails.current_settlement_invoice_total, this.customCurrencyOption);
		invoiceTotalRow.getCell(2).alignment = { horizontal: 'right' };
		invoiceTotalRow.getCell(5).value = 'Rebate Total';
		invoiceTotalRow.getCell(5).font = { 'bold': true };
		invoiceTotalRow.getCell(6).value = this._CustomCurrencyPipe.transform(+this.rebatesDetails.current_settlement_commission_total, this.customCurrencyOption);
		invoiceTotalRow.getCell(6).alignment = { horizontal: 'right' };


		const tierModeIndex = this.listOfTierModes.findIndex(custItm => custItm.value === this.rebatesDetails.tier_mode);
		let tierModeLabel = '';
		if (tierModeIndex !== -1) {
			tierModeLabel = this.listOfTierModes[tierModeIndex].text;
		}

		const tierAccrualPeriodIndex = this.listOfTierAccrualPeriods.findIndex(custItm => custItm.value === this.rebatesDetails.tier_accrual_period);
		let tierAccrualPeriodLabel = '';
		if (tierAccrualPeriodIndex !== -1) {
			tierAccrualPeriodLabel = this.listOfTierAccrualPeriods[tierAccrualPeriodIndex].text;
		}

		const tierIncludeIndex = this.listOfTiersInclude.findIndex(custItm => custItm.value === this.rebatesDetails.tiers_include);
		let tierIncludeLabel = '';
		if (tierIncludeIndex !== -1) {
			tierIncludeLabel = this.listOfTiersInclude[tierIncludeIndex].text;
		}

		const tierModeRow = worksheet.getRow(8);
		tierModeRow.height = 30;
		tierModeRow.getCell(1).value = 'Tier Mode';
		tierModeRow.getCell(1).font = { 'bold': true };
		tierModeRow.getCell(2).value = tierModeLabel;
		tierModeRow.getCell(2).alignment = { horizontal: 'right' };

		if (this.isShowTierAccrualPeriodField) {
			tierModeRow.getCell(4).value = 'Tiers Tier Accrual Period';
			tierModeRow.getCell(4).font = { 'bold': true };
			tierModeRow.getCell(5).value = tierAccrualPeriodLabel;
			tierModeRow.getCell(5).alignment = { horizontal: 'right' };

			tierModeRow.getCell(7).value = 'Tiers Include';
			tierModeRow.getCell(7).font = { 'bold': true };
			tierModeRow.getCell(8).value = tierIncludeLabel;
			tierModeRow.getCell(8).alignment = { horizontal: 'right' };
		} else {
			tierModeRow.getCell(5).value = 'Tiers Include';
			tierModeRow.getCell(5).font = { 'bold': true };
			tierModeRow.getCell(6).value = tierIncludeLabel;
			tierModeRow.getCell(6).alignment = { horizontal: 'right' };
		}

	}

	// Method used to Display Rebate basis Name in uppercase
	public getDisplayExprRebatebasis(item: any) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Tier basis Name in uppercase
	public getDisplayExprTierbasis(item: any) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	public setCellValueTierBasisColumn(rowData: any, value: any, currentRowData: any) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueRebateBasisColumn(rowData: any, value: any, currentRowData: any) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueUomColumn(rowData: any, value: any, currentRowData): void {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueRateColumn(rowData: any, value: any, currentRowData: any) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueFinalPriceColumn(rowData: any, value: any, currentRowData: any) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	public doInitializedItemItmGrpTab(event) {
		setTimeout(() => {
			this.doAddDynamicFeatureCustomColumns(event);
		}, 200);
	}

	// Method used to Hide expand icon when row prepared
	public doOnonRowPreparedItemItmGrpTab(event) {
		if (event.rowType === "data" && event.data.tier_basis === 'notiers') {
			if (event.rowElement.querySelector(".dx-command-expand") && event.rowElement.querySelector(".dx-command-expand").firstChild && event.rowElement.querySelector(".dx-command-expand").firstChild.classList) {
				event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
				event.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
				const isExpanded = event.component.isRowExpanded(event.key);
				setTimeout(() => {
					if (isExpanded) {
						event.component.collapseRow(event.key);
					}
				}, 20);
			}
		}
	}

	public doOnRowExpandingItmGrpTab(event) {
		const listOfRows = event.component.getVisibleRows();
		const rowIndex = listOfRows.findIndex(row => row.data.erp_primary_key === event.key);
		let itemDetails: any;
		if (rowIndex !== -1) {
			itemDetails = listOfRows[rowIndex].data;
			this.setHideShowTiersColumnsItmGrpTab(itemDetails);
		}
	}

	public doOnEditorPreparingItemItmGrpTab(event) {
		if (event.parentType === "dataRow" && event.dataField === 'selectedUom') {
			const itemObject = event.row.data;
			const listOfRows = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails = listOfRows[rowIndex].data;
			event.editorOptions.visible = false;
			let uomDataSource = [];
			event.editorOptions.dataSource = uomDataSource;
			if (!itemObject.allproducts) {
				if (itemObject.rebate_basis === 'dollarperuom') {
					event.editorOptions.visible = true;
					uomDataSource = itemObject.uomlist;
					event.editorOptions.showClearButton = true;
					event.editorOptions.dataSource = uomDataSource;
				}
			}
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				event.editorOptions.onValueChanged = (e: any) => {
					const targetUom = e.value;
					const sourceUom = e.previousValue;
					itemDetails.selectedUom = targetUom;
					if (!itemDetails.allproducts) {
						if (itemDetails.tier_basis === 'notiers') {
							if (itemDetails.rebate_basis === 'dollarperuom') {
								const uomData = itemDetails.uomlist.filter(i => i.code === targetUom);
								let uomprice = 0;
								if (uomData && uomData.length > 0) {
									uomprice = uomData[0].price || 0;
								}
								itemDetails.listPrice = +uomprice;
								itemDetails.rate = +(this.calculateUomPrice(itemDetails.rate, itemDetails.uomgroupentry, sourceUom, targetUom));
								if (itemDetails.rate) {
									itemDetails.rebatefinalprice = +((parseFloat(uomprice.toString()) - parseFloat(itemDetails.rate)).toFixed(2));
								} else {
									itemDetails.rebatefinalprice = +(parseFloat(uomprice.toString()).toFixed(2));
								}
							}
						}

						if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
							if (itemDetails.rebate_basis === 'dollarperuom') {
								if (itemDetails.tiers && itemDetails.tiers.length > 0) {
									const uomData = itemDetails.uomlist.filter(i => i.code === targetUom);
									let uomprice = 0;
									if (uomData && uomData.length > 0) {
										uomprice = uomData[0].price || 0;
									}
									itemDetails.tiers.forEach(tierEle => {
										tierEle.listPrice = +uomprice;
										tierEle.rate = +(this.calculateUomPrice(tierEle.rate, itemDetails.uomgroupentry, sourceUom, targetUom));
										if (tierEle.rate) {
											tierEle.rebatefinalprice = +((parseFloat(uomprice.toString()) - parseFloat(tierEle.rate)).toFixed(2));
										} else {
											tierEle.rebatefinalprice = +(parseFloat(uomprice.toString()).toFixed(2));
										}
									});
								}
							}
						}
					}

				};
			}
			if ((this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') && this.rebatesDetails.rebateType === 'sales') {
				if (itemDetails.allproducts) {
					if (itemDetails.rebate_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						uomDataSource = itemObject.uomlist;
						event.editorOptions.showClearButton = true;
						event.editorOptions.dataSource = uomDataSource;
					}
				}
			}
		}

		if (event.parentType === "dataRow" && event.dataField === 'tier_basis') {
			const itemObject = event.row.data;
			const listOfRows = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails = listOfRows[rowIndex].data;
			let listOfTierBasis = this.listOfTierBasis;
			if (this.rebatesDetails.individualOrGroup === 'individual') {
				listOfTierBasis = this.listOfTierBasis;
			} else if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
				listOfTierBasis = this.listOfTierBasis.filter(rb => rb.code === 'volume' || rb.code === 'revenue');
			}
			event.editorOptions.dataSource = listOfTierBasis;
			this.setHideShowTiersColumnsItmGrpTab(itemObject);
		}

		if (event.parentType === "dataRow" && event.dataField === 'rebate_basis') {
			const itemObject = event.row.data;
			const listOfRows = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails = listOfRows[rowIndex].data;
			let listOfRebateBasis = this.listOfRebateBasis;
			if (itemObject.allproducts) {
				if (this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') {
					listOfRebateBasis = this.listOfRebateBasis;
				} else {
					listOfRebateBasis = this.listOfRebateBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}
			}
			event.editorOptions.dataSource = listOfRebateBasis;
			this.setHideShowTiersColumnsItmGrpTab(itemObject);
		}

		if (event.parentType === "dataRow" && event.dataField === 'listPrice') {
			const itemObject = event.row.data;
			const listOfRows = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails = listOfRows[rowIndex].data;
			event.editorOptions.visible = false;
			event.editorOptions.disabled = true;
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				if (!itemObject.allproducts) {
					if (itemObject.tier_basis === 'notiers') {
						if (itemObject.rebate_basis === 'dollarperuom') {
							event.editorOptions.visible = true;
							event.editorOptions.disabled = true;
						}
					}
				}
			}
		}

		if (event.parentType === "dataRow" && event.dataField === 'rebatefinalprice') {
			const itemObject = event.row.data;
			const listOfRows = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails = JSON.parse(JSON.stringify(listOfRows[rowIndex].data));
			event.editorOptions.visible = false;
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				if (!itemDetails.allproducts) {
					if (itemDetails.tier_basis === 'notiers') {
						if (itemDetails.rebate_basis === 'dollarperuom') {
							event.editorOptions.visible = true;
						}
					}
				}
				event.editorOptions.onValueChanged = (e: any) => {
					const rebatefinalprice = e.value;
					this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rebatefinalprice', +rebatefinalprice);
					if (!itemDetails.allproducts) {
						if (itemDetails.tier_basis === 'notiers') {
							if (itemDetails.rebate_basis === 'dollarperuom') {
								if (rebatefinalprice <= itemDetails.listPrice) {
									const val = +(parseFloat((itemDetails.listPrice - rebatefinalprice).toFixed(2)));
									if (val >= 0) {
										this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rate', val);
									} else {
										this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rate', 0);
										this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rebatefinalprice', +itemDetails.listPrice);
									}
								} else {
									this._ToastrService.info('Final Price cannot be grater then List Price' || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
									this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rate', 0);
									this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rebatefinalprice', +itemDetails.listPrice);
								}
							}
						}
					}
				};
			}
		}

		if (event.parentType === "dataRow" && event.dataField === 'rate') {
			const itemObject = event.row.data;
			const listOfRows = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails = JSON.parse(JSON.stringify(listOfRows[rowIndex].data));
			event.editorOptions.visible = false;
			if (itemObject.allproducts) {
				if (itemObject.tier_basis === 'notiers') {
					event.editorOptions.visible = true;
				}
			} else {
				if (itemObject.tier_basis === 'notiers') {
					event.editorOptions.visible = true;
				}
			}

			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				event.editorOptions.onValueChanged = (e: any) => {
					const currentRate = e.value;
					this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rate', currentRate);
					if (!itemDetails.allproducts) {
						if (itemDetails.tier_basis === 'notiers') {
							if (itemDetails.rebate_basis === 'dollarperuom') {
								if (currentRate <= itemDetails.listPrice) {
									const rebatefinalprice = +(parseFloat((itemDetails.listPrice - currentRate).toFixed(2)));
									if (rebatefinalprice >= 0) {
										this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rebatefinalprice', +rebatefinalprice);
									} else {
										this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rebatefinalprice', 0);
										this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rate', itemDetails.listPrice);
									}
								} else {
									this._ToastrService.info('Rate cannot be grater then List Price' || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
									this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rebatefinalprice', itemDetails.listPrice);
									this.itemsAndGroupsitmGrpTabDataGridRef.instance.cellValue(rowIndex, 'rate', 0);
								}
							}
						}
					}
				};
			}
		}

		if (event.parentType === "dataRow" && (this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential')) {
			const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
			const listOfFeaturesCustomFields = featureCustomFieldsObj.listOfFeatureCustomFields;
			const fieldIndex = listOfFeaturesCustomFields.findIndex(customField => customField.ix_custom_field === event.dataField);
			listOfFeaturesCustomFields.forEach(customField => {
				if (customField.ix_custom_field === event.dataField) {
					let uomDataSource = listOfFeaturesCustomFields[fieldIndex].custom_field_values || [];
					event.editorOptions.showClearButton = true;
					event.editorOptions.dataSource = uomDataSource;
				}
			});
		}
		if (event.parentType === "dataRow" && event.dataField === 'in_pricelist') {
			event.editorOptions.dataSource = this.listOfInPricelist || [];
		}
	}

	public doOnRowUpdatedItemItmGrpTab(event) {
		// const listOfRows = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleRows();
		// const rowIndex = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getRowIndexByKey(event.key);
		// listOfRows[rowIndex].data.listPrice = 25000;
		const itemDetails = event.data;
		if (itemDetails.tier_basis === 'notiers') {
			itemDetails.tiers = [];
		}

		if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
			itemDetails.rate = null;
		}

		if (itemDetails.rebate_basis === 'percentage' || itemDetails.rebate_basis === 'dollar') {
			itemDetails.selectedUom = '';
		}
	}

	public doOnRowRemovedItemItmGrpTab(event) {
		// console.log('doOnRowRemovedItemItmGrpTab', event);
	}

	public doRowValidatingItmGrpTab(event) {
		if (event['newData']) {
			let itemObject = JSON.parse(JSON.stringify(event['oldData']));
			let dataFiledsList = Object.keys(event['newData']);
			dataFiledsList.forEach(element => {
				itemObject[element] = event['newData'][element];
			});
			if (!this.doCheckDuplicateItemsForIndividualGrowthIncentive(itemObject)) {
				const indexOfOldRow = this.dataSourceItemsAndGroupsLstItmGrpTab.findIndex(i => i.guid === event.key);
				if (indexOfOldRow !== -1) {
					dataFiledsList.forEach(element => {
						event['newData'][element] = this.dataSourceItemsAndGroupsLstItmGrpTab[indexOfOldRow][element];
					});
				}
			}
		}
	}

	private doCheckDuplicateItemsForIndividualGrowthIncentive(memberItems: any): boolean {
		let doesNotExists = true;
		if (this.rebatesDetails.tiers_type === 'multiple_items') {
			let countOfEachItem = 0;
			this.dataSourceItemsAndGroupsLstItmGrpTab.forEach(memberEle => {
				if (memberEle.guid !== memberItems.guid && memberEle.erp_primary_key === memberItems.erp_primary_key && memberEle.tier_basis === memberItems.tier_basis && memberEle.rebate_basis === memberItems.rebate_basis) {
					memberItems.items.forEach(memberItem => {
						const existingItemIndex = memberEle.items.findIndex(itemEle => itemEle.erp_primary_key === memberItem.erp_primary_key);
						if (existingItemIndex !== -1) {
							countOfEachItem++;
						}
					});
				}
			});
			if (countOfEachItem > 0) {
				this._ToastrService.info('Duplicate entry: an entry matching this product, already exists.', 'Info', { closeButton: true, tapToDismiss: true });
				doesNotExists = false;
			}
		}
		return doesNotExists;
	}

	public doOnSavedItemItmGrpTab(event) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public setCellValueTiersFinalPriceColumn(rowData, value, currentRowData) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueTiersRateColumn(rowData, value, currentRowData) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value, currentRowData);
	}

	// Method ued to Add new row Tiers datagrid
	public doOnInitNewRowTiersItmGrpTab(event: any, element: any) {
		const itemObject = element.data;
		const tierObject: any = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null,
			listPrice: null,
			rebatefinalprice: null,
		};
		if (!this.enable_classification_for_rebate) {
			if (itemObject.items && itemObject.items.length > 0) {
				tierObject.items = itemObject.items.map((item) => {
					let listOfSpecificUoms = [];
					if (!item.allproducts) {
						const priceListNum = itemObject.pricelistnum || '';
						listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);
					}
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					return {
						allproducts: item.allproducts,
						erp_primary_key: item.erp_primary_key,
						itemType: item.itemType,
						itemname: item.itemname,
						itemsku: item.itemsku,
						description: item.description,
						selectedUom: !item.allproducts && itemObject.rebate_basis === 'dollarperuom' ? defaultSelectedUom : '',
						rate: null,
						listPrice: +defaultListPrice.toFixed(2),
						rebatefinalprice: defaultListPrice,
					}
				});
			}
		}
		if (this.enable_classification_for_rebate) {
			if (itemObject.items && itemObject.items.length > 0) {
				tierObject.items = itemObject.items.map((classEle: any) => {
					let defaultListPrice = 0;
					return {
						allclasses: classEle.allclasses,
						erp_primary_key: classEle.classid,
						classguid: classEle.classguid,
						classid: classEle.classid,
						classname: classEle.classname,
						listPrice: +defaultListPrice.toFixed(2),
						rate: null,
						rebatefinalprice: defaultListPrice,
					}
				});
			}
		}

		const itemDetails = element.data;
		tierObject.guid = this.guid.newGuid();
		if (itemDetails.tiers && itemDetails.tiers.length > 0) {
			// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ? parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1 : parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
			if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
				if (itemDetails.rebate_basis === 'percentage') {
					// event.data.mintier = mintier;
				}
				if (itemDetails.rebate_basis === 'dollar') {
					// event.data.mintier = mintier;
				}
			}
			// event.data.maxtier = '-';
		} else {
			if (itemDetails.tier_basis === 'volume') {
				if (itemDetails.rebate_basis === 'percentage') {
					// event.data.mintier = 1;
				}
				if (itemDetails.rebate_basis === 'dollar') {
					// event.data.mintier = 0.01;
				}
			}
		}

		if (this.productSearchItemType === 'price') {
			if (!itemDetails.allproducts) {
				if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
					if (itemDetails.rebate_basis === 'dollarperuom') {
						const uomIndex = itemDetails.uomlist.findIndex(uom => uom.code === itemDetails.selectedUom);
						if (uomIndex !== -1) {
							tierObject.listPrice = itemDetails.uomlist[uomIndex].price;
						}
						tierObject.rate = 0;
						const val = +(parseFloat((tierObject.listPrice - tierObject.rate).toFixed(2)));
						if (val > 0) {
							tierObject.rebatefinalprice = val;
						} else {
							tierObject.rebatefinalprice = +itemDetails.listPrice;
						}
						tierObject.rate = null;
					}
				}
			}
		}
		event.data = tierObject;
	}

	// Method ued to Add new row Tiers datagrid
	public doOnRowInsertingTiersItmGrpTab(event: any, element: any) {
		const itemDetails = element.data;
		const listOfRows = event.component.getVisibleRows();
		const rowIndex = event.component.getRowIndexByKey(event.data.guid);
		let tierDetails: any;
		if (rowIndex !== -1) {
			tierDetails = listOfRows[rowIndex].data;
		} else {
			tierDetails = event.data;
		}

		const currentRate = event.data.rate;
		if (currentRate && currentRate >= 0) {
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				if (!itemDetails.allproducts) {
					if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
						if (itemDetails.rebate_basis === 'dollarperuom') {
							tierDetails.rate = currentRate;
							const newRebatefinalprice = +(parseFloat((tierDetails.listPrice - currentRate).toFixed(2)));
							if (newRebatefinalprice > 0) {
								tierDetails.rebatefinalprice = +newRebatefinalprice;
							} else {
								this._ToastrService.info('Rebate Amount cannot be grater then List Price' || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
								tierDetails.rebatefinalprice = 0;
								tierDetails.rate = tierDetails.listPrice;
							}
						} else {
							tierDetails.rebatefinalprice = tierDetails.listPrice;
							tierDetails.rate = currentRate;
						}
					}
				}
			}
		}

		const rebatefinalprice = event.data.rebatefinalprice;
		if (rebatefinalprice && rebatefinalprice > 0) {
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				if (!itemDetails.allproducts) {
					if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
						if (itemDetails.rebate_basis === 'dollarperuom') {
							tierDetails.rebatefinalprice = rebatefinalprice;
							const val = +(parseFloat((tierDetails.listPrice - rebatefinalprice).toFixed(2)));
							if (val > 0) {
								tierDetails.rate = val;
							} else {
								this._ToastrService.info('Final Price cannot be grater then List Price' || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
								tierDetails.rate = 0;
								tierDetails.rebatefinalprice = +tierDetails.listPrice;
							}
						} else {
							tierDetails.rebatefinalprice = tierDetails.listPrice;
						}
					}
				}
			}
		}
	}

	// Method ued to Edit preparing for tier datagrid row
	public doOnEditorPreparingTiersItmGrpTab(event: any, element: any) {
		const itemDetails = element.data;
		let rowIndex: number;
		let tierDetails: any = {};
		let listOfRows: any[] = [];

		if (event.parentType === "dataRow" && event.dataField === 'rate') {
			if (!event.row.isNewRow) {
				listOfRows = event.component.getVisibleRows();
				rowIndex = event.component.getRowIndexByKey(event.row.key);
				if (rowIndex !== -1) {
					tierDetails = listOfRows[rowIndex].data;
				}
			}
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				if (!itemDetails.allproducts) {
					if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
						if (itemDetails.rebate_basis === 'dollarperuom') {
							event.editorOptions.onValueChanged = (e: any) => {
								if (event.row.isNewRow) {
									listOfRows = event.component.getVisibleRows();
									rowIndex = event.component.getRowIndexByKey(event.row.key);
									if (rowIndex !== -1) {
										tierDetails = listOfRows[rowIndex].data;
									}
								}
								const currentRate = e.value;
								event.component.cellValue(rowIndex, 'rate', currentRate);
								if (currentRate <= tierDetails.listPrice) {
									const rebatefinalprice = +(parseFloat((tierDetails.listPrice - currentRate).toFixed(2)));
									if (rebatefinalprice >= 0) {
										event.component.cellValue(rowIndex, 'rebatefinalprice', +rebatefinalprice);
									} else {
										event.component.cellValue(rowIndex, 'rebatefinalprice', 0);
										event.component.cellValue(rowIndex, 'rate', tierDetails.listPrice);
									}
								} else {
									this._ToastrService.info('Rate cannot be grater then List Price' || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
									event.component.cellValue(rowIndex, 'rebatefinalprice', 0);
									event.component.cellValue(rowIndex, 'rate', tierDetails.listPrice);
								}
							};
						}
					}
				}
			}
		}

		if (event.parentType === "dataRow" && event.dataField === 'rebatefinalprice') {
			if (!event.row.isNewRow) {
				listOfRows = event.component.getVisibleRows();
				rowIndex = event.component.getRowIndexByKey(event.row.key);
				if (rowIndex !== -1) {
					tierDetails = listOfRows[rowIndex].data;
				}
			}
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
				if (!itemDetails.allproducts) {
					if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
						if (itemDetails.rebate_basis === 'dollarperuom') {
							event.editorOptions.onValueChanged = (e: any) => {
								if (event.row.isNewRow) {
									listOfRows = event.component.getVisibleRows();
									rowIndex = event.component.getRowIndexByKey(event.row.key);
									if (rowIndex !== -1) {
										tierDetails = listOfRows[rowIndex].data;
									}
								}
								const rebatefinalprice = e.value;
								event.component.cellValue(rowIndex, 'rebatefinalprice', rebatefinalprice);
								if (rebatefinalprice <= tierDetails.listPrice) {
									const val = +(parseFloat((tierDetails.listPrice - rebatefinalprice).toFixed(2)));
									if (val >= 0) {
										event.component.cellValue(rowIndex, 'rate', val);
									} else {
										event.component.cellValue(rowIndex, 'rate', 0);
										event.component.cellValue(rowIndex, 'rebatefinalprice', +tierDetails.listPrice);
									}
								} else {
									this._ToastrService.info('Final Price cannot be grater then List Price' || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
									event.component.cellValue(rowIndex, 'rate', 0);
									event.component.cellValue(rowIndex, 'rebatefinalprice', tierDetails.listPrice);
								}
							};
						}
					}
				}
			}
		}
	}

	// Method ued to remove tier from datagrid
	public doOnRowRemovedTiersItmGrpTab(event: any, element: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	// Method ued to save contract
	public doOnSavedTiersItmGrpTab(event: any, element: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	// Method used to make visibility true/false
	private setHideShowColumnsItmGrpTab() {
		this.isShowListPriceColumnItmGrpTab = false;
		this.isShowFinalPriceColumnItmGrpTab = false;
		if (this.productSearchItemType === 'price') {
			if (this.rebatesDetails.individualOrGroup === 'individual') {
				if (this.rebatesDetails.rebateType === 'sales') {
					this.isShowListPriceColumnItmGrpTab = true;
					this.isShowFinalPriceColumnItmGrpTab = true;
					this.setColumnForItemsDataGridItmGrpTab();
				}
			}

			if (this.rebatesDetails.individualOrGroup === 'growth_incentive') {
				if (this.rebatesDetails.rebateType === 'sales') {
					this.isShowListPriceColumnItmGrpTab = true;
					this.isShowFinalPriceColumnItmGrpTab = true;
					this.setColumnForItemsDataGridItmGrpTab();
				}
			}
		}
	}

	// Method used to Make visibility true/false List Price and Final Price
	private setHideShowTiersColumnsItmGrpTab(itemDetails: any) {
		this.isShowTierListPriceColumnItmGrpTab = false;
		this.isShowTierFinalPriceColumnItmGrpTab = false;
		if (this.productSearchItemType === 'price') {
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales') {
				if ((itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') && itemDetails.rebate_basis === 'dollarperuom') {
					this.isShowTierListPriceColumnItmGrpTab = true;
					this.isShowTierFinalPriceColumnItmGrpTab = true;
				}
			}
		}
	}

	// Method used to Fetched Price list
	private fetchedListOfPrices(pricelistnum: string, isCheckListPrice?: boolean) {
		this.listOfPrices = [];
		if (!pricelistnum) {
			return;
		}
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		const viewFields = ['pricelistno', 'pricelistname', 'items', 'modified_date'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('is_dropdown', '1');
		formdata.append('entity', ServerEntity.PRICELISTS);
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this.getPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: response => {
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data || [];
						try {
							this.rebatesDetails.selectCustomerObj.pricelistname = response.data[0].pricelistname;
						} catch (e) {
							this.rebatesDetails.selectCustomerObj.pricelistname = '';
						}
						if (isCheckListPrice) {
							if (this.editRebatesGuid) {
								if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
									if (this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
										this.checkDifferentListPrice();
									}
								}
							}
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// 
	public getItemsByTiersDetails(element) {
		return element.data.items || [];
	}

	public doEditorPreparingItemTierItmTab(event, subParentData: any, parentData: any) {
		if (event && event['row']) {
			const itemObject = event.row.data;
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (parentData.data.rebate_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = [];
						const itemIndex = parentData.data.items.findIndex(item => item.erp_primary_key === itemObject.erp_primary_key);
						if (itemIndex !== -1) {
							const selectedItemObject = parentData.data.items[itemIndex];
							uomDataSource = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, selectedItemObject);
						}
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			// if (event.dataField === 'tier_basis') {
			// 	let listOfTierBasis = this.listOfTierBasis;
			// 	event.editorOptions.dataSource = listOfTierBasis;
			// }

			if (event.dataField === 'rebate_basis') {
				let listOfRebateBasis = this.listOfRebateBasis;
				if (itemObject.allproducts) {
					listOfRebateBasis = this.listOfRebateBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}

				if (itemObject['itemType']) {
					if (itemObject['itemType'] === 'OtherCharge' || itemObject['itemType'] === 'Service') {
						listOfRebateBasis = this.listOfRebateBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
					}
				}
				event.editorOptions.dataSource = listOfRebateBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.visible = true;
				if (itemObject.allproducts) {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				} else {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				}
			}
		}
	}

	public doRowRemovedItemTierItmTab(event, subParentData: any, parentData: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public doOnSavedItemTierItmTab(event, subParentData: any, parentData: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public doRowUpdatedItemTierItmTab(event, subParentData: any, parentData: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}
	// #endregion

	// #region for Bulk Upload items to datagrid

	// Method used to click to Open upload dialog
	public doBulkUploadItmGrpTab() {
		if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
			if (!this.rebatesDetails.selectCustomerObj) {
				this._ToastrService.info('Customer Name is required', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
			if (this.rebatesDetails.selectCustomerObj && !this.rebatesDetails.selectCustomerObj.pricelistnum) {
				this._ToastrService.info('Assign a price list to customer before adding items', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}
		this.uploadRebateExclusionItemsProps = {
			actionFor: 'showUploadFile',
			contractType: this.rebatesDetails.individualOrGroup,
			rebateType: this.rebatesDetails.rebateType,
			isItemExists: this.listOfItemsAndGroupsItmGrpTab.length > 0,
			listOfCsvRows: []
		};
		this.doOpenUploadRebateExclusionItemsPopup();
		// this._MatDialog.open(UploadRebateExclusionItemsDialogComponent, this.dialogConfig).afterClosed().subscribe(result => {
		// 	if (result) {
		// 		this.csvResponse = result;
		// 		if (result.listOfCsvRows && result.listOfCsvRows.length > 0) {
		// 			setTimeout(() => {
		// 				this.listOfItemsAndGroupsItmGrpTab = [];
		// 				this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
		// 			}, 50);
		// 			const listOfCsvRow = result.listOfCsvRows;
		// 			listOfCsvRow.forEach(rowEle => {
		// 				rowEle.guid = this.guid.newGuid();
		// 				rowEle.description = '';
		// 				rowEle.isItemMatched = false;
		// 				rowEle.isItemUomMatched = 'unmatched';
		// 				rowEle.isItemModifiedUom = '';
		// 				rowEle.isItemModifiedPrice = false;
		// 				rowEle.isItemModifiedPriceLbl = null;
		// 			});
		// 			this.listOfCsvRowForItmGrpTab = listOfCsvRow;
		// 			this.doMatchExcelItemsWithOrignItems();
		// 			this.fetchedAllItemsItmGrpTab();
		// 		}
		// 	}
		// });
	}

	private doMatchExcelItemsWithOrignItems() {
		this._GlobalStateService.unsubscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT', response => {
			const itemDetailsList = response.itemDetailsList;
			if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales') {
				if (this.productSearchItemType === 'item') {
					this.doAddBulkItemsToItmGrpTabList();
					this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
				}
				if (this.productSearchItemType === 'price') {
					const matchedItemsList: any[] = [];
					this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
						if (csvRow.item) {
							const itemIndex = itemDetailsList.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
							if (itemIndex !== -1) {
								const itemEle = itemDetailsList[itemIndex];
								matchedItemsList.push(itemEle);
							}
						}
					});
					const listOfGuids = [];
					matchedItemsList.map(matchedItem => listOfGuids.push(matchedItem.guid));
					this.doAddBulkItemsToItmGrpTabList();
					if (listOfGuids.length > 0) {
						this.fetchedItemDetailsByPricelistnum(listOfGuids);
					}
				}
			}
			if (this.rebatesDetails.individualOrGroup === 'buyinggroup' || ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'purchasing')) {
				this.doAddBulkItemsToItmGrpTabList();
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
			}
		});
	}

	// Method used to Add items from excel to Exclusion items list
	private doAddBulkItemsToItmGrpTabList() {
		this._GlobalStateService.unsubscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT');
		this._GlobalStateService.subscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', response => {
			const listOfItemDetails = response.itemDetailsList;
			this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
				if (csvRow.item) {
					const itemDetailIndex = listOfItemDetails.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
					if (itemDetailIndex !== - 1) {
						const itemEle = listOfItemDetails[itemDetailIndex];
						csvRow.isItemMatched = true;
						csvRow.description = itemEle.description || '';

						const listOfSpecificUoms = this.setlistOfUomBySelProduct(itemEle);
						const itemOrGroup = this.itemsOrGroupsRadioBtn === 'itemsRadioBtn' ? 'item' : 'group';
						const tierIndex = this.listOfTierBasis.findIndex(tierEle => tierEle.code === 'notiers');
						const defaultTierBasis = this.listOfTierBasis[tierIndex].code;
						let rebateIndex;
						if (this.enable_classification_for_rebate) {
							rebateIndex = this.listOfRebateBasis.findIndex(rebateEle => rebateEle.code === 'dollar');
						}
						else {
							rebateIndex = this.listOfRebateBasis.findIndex(rebateEle => rebateEle.code === 'dollarperuom');
						}
						const defaultRebateBasis = this.listOfRebateBasis[rebateIndex].code;

						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}

						if (csvRow.uom) {
							const specificUomIndex = listOfSpecificUoms.findIndex(uomEle => uomEle.code.toString().toLowerCase().trim() === csvRow.uom.toString().toLowerCase().trim());
							if (specificUomIndex !== -1) {
								csvRow.isItemUomMatched = 'matched';
								try {
									defaultSelectedUom = listOfSpecificUoms[specificUomIndex].code;
									defaultListPrice = listOfSpecificUoms[specificUomIndex].price || 0;
								} catch (e) {
								}
							} else {
								csvRow.isItemUomMatched = 'unmatched';
								try {
									csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
								} catch (e) {
								}
							}
						} else {
							csvRow.isItemUomMatched = 'unmatched';
							try {
								csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
							} catch (e) {
							}
						}

						let customRebatefinalprice = 0;
						let customRate = 0;
						if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales') {
							if (this.productSearchItemType === 'item') {
								customRate = parseFloat(csvRow.rebate) || null;
							}
							if (this.productSearchItemType === 'price') {
								if (csvRow.hasOwnProperty('rebate')) {
									csvRow.rebate = this.convertToNumber(csvRow.rebate ? csvRow.rebate.toString() : '');
									if (+csvRow.rebate > +defaultListPrice) {
										csvRow.isItemModifiedPrice = true;
										csvRow.isItemModifiedPriceLbl = 0;
										customRate = 0;
										customRebatefinalprice = +defaultListPrice;
									} else {
										customRate = +csvRow.rebate || 0;
										customRebatefinalprice = +defaultListPrice - customRate;
									}
								}

								if (csvRow.hasOwnProperty('price')) {
									csvRow.price = this.convertToNumber(csvRow.price ? csvRow.price.toString() : '');
									if (+csvRow.price > +defaultListPrice) {
										csvRow.isItemModifiedPrice = true;
										csvRow.isItemModifiedPriceLbl = +defaultListPrice;
										customRebatefinalprice = +defaultListPrice;
										customRate = 0;
									} else {
										customRebatefinalprice = +csvRow.price || 0;
										customRate = +defaultListPrice - +customRebatefinalprice;
									}
								}
							}
						}

						if (this.rebatesDetails.individualOrGroup === 'buyinggroup' || ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'purchasing')) {
							if (csvRow.hasOwnProperty('rebate')) {
								customRate = +csvRow.rebate || 0;
							}
						}

						const item = new ItemsAndGroupsListItmsGrpsTabModel();
						item.item_guid = itemEle.guid;
						item.erp_primary_key = itemEle.erp_primary_key;
						item.itemsku = itemEle.itemsku || '';
						item.itemname = itemEle.itemname || '';
						item.itemOrGroup = itemOrGroup;
						item.description = itemEle.description || '';
						item.uomgroupentry = itemEle.uomgroupentry || '';
						item.allproducts = itemEle.allproducts || false;
						item.tier_basis = defaultTierBasis; // Value Could be "volume" | "revenue" | "notiers"
						item.rebate_basis = defaultRebateBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						item.uomlist = listOfSpecificUoms;
						item.selectedUom = defaultSelectedUom;
						item.listPrice = +defaultListPrice;
						item.rate = customRate;
						item.rebatefinalprice = customRebatefinalprice;
						item.tiers = [];
						this.listOfItemsAndGroupsItmGrpTab.push(item);
					}
				}
			});
			this.isUploadExcelCsvFileItmGrpTab = true;
			this.filterText = this.csvResponse.fileName;
			this.checkImportItemSummary();
			if (this.listOfItemsAndGroupsItmGrpTab.length > 0) {
				setTimeout(() => {
					this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
				}, 50);
				if (this.editRebatesGuid && this.listOfItemsAndGroupsItmGrpTab.length > 0) {
					setTimeout(() => {
						this.saveRebatesContract();
					}, 700);
				}
			}
		});
	}

	private fetchedAllItemsItmGrpTab() {
		let priceListNum = '';
		try {
			priceListNum = this.rebatesDetails.selectCustomerObj.pricelistnum || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEMS);
		formData.append('is_dropdown', 'true');
		formData.append('view_fields', JSON.stringify(["guid", "erp_primary_key", "itemsku", "itemname", "description", "uomgroupentry"]));
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}, error: error => {
				this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Fetch specific items details
	private fetchedItemDetailsByPricelistnum(itemGuidList: any[]) {
		let priceListNum = '';
		try {
			priceListNum = this.rebatesDetails.selectCustomerObj.pricelistnum || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEM_DETAILS);
		formData.append('is_dropdown', 'true');
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
			}, error: error => {
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to get number of item who unmatched.
	private checkImportItemSummary() {
		this.noOfTotalItem = this.listOfCsvRowForItmGrpTab.length;
		this.noOfUnmatchedItem = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched === false).length;
		this.noOfUnmatchedItemUom = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
		this.noOfmatchedItemPrice = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
	}

	public doShowCsvItemsListItmGrpTab() {
		this.uploadRebateExclusionItemsProps = {
			actionFor: 'showIgnoredItemList',
			contractType: this.rebatesDetails.individualOrGroup,
			rebateType: this.rebatesDetails.rebateType,
			listOfCsvRows: [],
			listOfUnmatchedItems: this.listOfCsvRowForItmGrpTab
		};
		this.doOpenUploadRebateExclusionItemsPopup();
	}

	// #endregion for Bulk Upload items to datagrid

	// #endregion for Items and Group Tab

	// #region for BuyingGroup Items/Group Tab

	// Method used to select any one Exclusion Type
	public doChangeItemOrGroupRadioBtnExclsnTab(event) {
		const newExclusionTypeValue = event.value;
		if (newExclusionTypeValue !== this.itemsOrGroupsRadioBtnExclsnTab) {
			this.itemsOrGroupsRadioBtnExclsnTab = newExclusionTypeValue;
			if (newExclusionTypeValue === 'item') {

			}
			if (newExclusionTypeValue === 'group') {

			}
		}
	}

	public doAddMembersToListExclsnTabForClass() {
		if (this.isValidMembersExclsnTabForClass()) {
			return false;
		}
		const listOfSelectedMembers = JSON.parse(JSON.stringify(this.aSelMembersExclsnTabDg));
		const listOfSelectedClasses = this.refclassesDG.instance.getSelectedRowsData();

		if (this.isCheckedAllMembersExclsnTabDrpDwnChkBox) {
			const allMembersObject: any = {};
			allMembersObject.code = 'all_members';
			allMembersObject.name = 'All Members';
			allMembersObject.allmembers = true;
			listOfSelectedMembers.unshift(allMembersObject);
		}
		if (this.chkAllClasses) {
			const allClassesObject: any = {};
			allClassesObject.allclasses = true;
			allClassesObject.guid = 'all_classes';
			allClassesObject.id = 'all_classes';
			allClassesObject.name = 'All Classes';
			listOfSelectedClasses.unshift(allClassesObject);
		}

		const tierIndex = this.listOfTierBasis.findIndex(t => t.code === 'notiers');
		let rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollar');
		let rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
		const tierCode = tierIndex !== -1 && this.listOfTierBasis.length > 0 ? this.listOfTierBasis[tierIndex].code : '';
		const priceListNum = '';

		if (this.rebatesDetails.tiers_type === 'multiple_items') {
			listOfSelectedMembers.forEach(member => {
				const classEle = listOfSelectedClasses[0];
				const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
				exclusionObject.member_item_guid = this.guid.newGuid();
				exclusionObject.code = member.code;
				exclusionObject.name = member.name;
				exclusionObject.allmembers = member.allmembers || false;
				exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
				exclusionObject.allclasses = classEle['allclasses'] || false;
				exclusionObject.classguid = classEle['guid'];
				exclusionObject.erp_primary_key = classEle['id'];
				exclusionObject.classid = classEle['id'];
				exclusionObject.classname = classEle['name'];
				exclusionObject.description = classEle.description || '';
				exclusionObject.tier_basis = tierCode;
				exclusionObject.rebate_basis = rebateCode;
				exclusionObject.rate = null;
				exclusionObject.items = listOfSelectedClasses.map(classEle => {
					return {
						allclasses: classEle['allclasses'] || false,
						classguid: classEle['guid'],
						erp_primary_key: classEle['id'],
						classid: classEle['id'],
						classname: classEle['name'],
					};
				});
				if (this.doCheckDuplicateItemsForBuyinggroup(exclusionObject)) {
					this.listOfMembersItemsExclsnTab.push(exclusionObject);
				}
			});
		} else {
			listOfSelectedMembers.forEach(member => {
				listOfSelectedClasses.forEach(classes => {
					const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
					exclusionObject.member_item_guid = this.guid.newGuid();
					exclusionObject.code = member.code;
					exclusionObject.name = member.name;
					exclusionObject.allmembers = member.allmembers || false;
					exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
					exclusionObject.allclasses = classes['allclasses'] || false;
					exclusionObject.classguid = classes['guid'];
					exclusionObject.erp_primary_key = classes['id'];
					exclusionObject.classid = classes['id'];
					exclusionObject.classname = classes['name'];
					exclusionObject.description = classes.description || '';
					exclusionObject.tier_basis = tierCode;
					exclusionObject.rebate_basis = rebateCode;
					exclusionObject.rate = null;
					const isRowExistis = this.listOfMembersItemsExclsnTab.filter(exclsn => exclsn.code === member.code && exclsn.erp_primary_key === classes.erp_primary_key).length <= 0;
					if (isRowExistis) {
						this.listOfMembersItemsExclsnTab.push(exclusionObject);
					}
				});
			});
		}

		this.clearValuesExclsnTab();
		setTimeout(() => {
			this.dataSourceMembersLstExclsnTab = this.listOfMembersItemsExclsnTab;
		}, 50);
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	// Method used to Add Members into Datagrid
	public doAddMembersToListExclsnTab() {
		if (this.enable_classification_for_rebate) {
			this.doAddMembersToListExclsnTabForClass();
			return;
		}
		if (this.isValidMembersExclsnTab()) {
			return false;
		}
		const listOfSelectedMembers = JSON.parse(JSON.stringify(this.aSelMembersExclsnTabDg));
		const listOfSelectedItems = JSON.parse(JSON.stringify(this.aSelItemsExclsnTabDg));

		if (this.isCheckedAllMembersExclsnTabDrpDwnChkBox) {
			const allMembersObject: any = {};
			allMembersObject.code = 'all_members';
			allMembersObject.name = 'All Members';
			allMembersObject.allmembers = true;
			listOfSelectedMembers.unshift(allMembersObject);
		}
		if (this.isCheckedAllItemsExclsnTabDrpDwnChkBox) {
			const allItemsObject: any = {};
			allItemsObject.allproducts = true;
			allItemsObject.guid = 'all_items';
			allItemsObject.erp_primary_key = 'all_items';
			allItemsObject.itemname = 'All Items';
			allItemsObject.itemsku = '';
			allItemsObject.description = '';
			allItemsObject.uomgroupentry = '';
			listOfSelectedItems.unshift(allItemsObject);
		}

		listOfSelectedMembers.forEach(member => {
			if (!member.hasOwnProperty('allmembers')) {
				member.allmembers = false;
			}
		});

		const tierIndex = this.listOfTierBasis.findIndex(t => t.code === 'notiers');
		const tierCode = tierIndex !== -1 && this.listOfTierBasis.length > 0 ? this.listOfTierBasis[tierIndex].code : '';
		let rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollarperuom');
		let rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
		const priceListNum = '';
		let listOfSpecificUoms = [];

		if (this.rebatesDetails.member_mode === 'single_member' && this.rebatesDetails.tiers_type === 'single_items') {
			// console.log('do logic here for Single Member with Single Item');
			listOfSelectedMembers.forEach(member => {
				listOfSelectedItems.forEach(item => {
					listOfSpecificUoms = [];
					listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);

					rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollarperuom');
					rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
					if (item.allproducts) {
						rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollar');
						rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
					}

					const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
					exclusionObject.member_item_guid = this.guid.newGuid();
					exclusionObject.code = member.code;
					exclusionObject.name = member.name;
					exclusionObject.allmembers = member.allmembers || false;
					exclusionObject.allproducts = item.allproducts || false;
					exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
					exclusionObject.erp_primary_key = item.erp_primary_key;
					exclusionObject.item_guid = item.guid || '';
					exclusionObject.itemname = item.itemname;
					exclusionObject.itemsku = item.itemsku || '';
					exclusionObject.description = item.description || '';
					exclusionObject.uomgroupentry = item.uomgroupentry || '';
					exclusionObject.tier_basis = tierCode;
					exclusionObject.rebate_basis = rebateCode;
					exclusionObject.uomlist = listOfSpecificUoms;
					exclusionObject.selectedUom = listOfSpecificUoms.length > 0 ? listOfSpecificUoms[0].code : '';
					exclusionObject.rate = null;

					const isRowExistis = this.listOfMembersItemsExclsnTab.filter(exclsn => exclsn.code === member.code && exclsn.erp_primary_key === item.erp_primary_key).length <= 0;
					if (isRowExistis) {
						this.listOfMembersItemsExclsnTab.push(exclusionObject);
					}
				});
			});
		} else if (this.rebatesDetails.member_mode === 'single_member' && this.rebatesDetails.tiers_type === 'multiple_items') {
			// console.log('do logic here for Single Member with Multiple Items');
			const item = listOfSelectedItems[0];
			listOfSelectedMembers.forEach(member => {
				listOfSpecificUoms = [];
				listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);

				rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollarperuom');
				rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
				if (listOfSelectedItems[0].allproducts) {
					rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollar');
					rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
				}

				const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
				exclusionObject.member_item_guid = this.guid.newGuid();
				exclusionObject.code = member.code;
				exclusionObject.name = member.name;
				exclusionObject.allmembers = member.allmembers || false;
				exclusionObject.allproducts = false;
				exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
				exclusionObject.erp_primary_key = item.erp_primary_key;
				exclusionObject.item_guid = item.guid || '';
				exclusionObject.itemname = item.itemname;
				exclusionObject.itemsku = '';
				exclusionObject.description = '';
				exclusionObject.uomgroupentry = item.uomgroupentry || '';
				exclusionObject.tier_basis = tierCode;
				exclusionObject.rebate_basis = rebateCode;
				exclusionObject.uomlist = listOfSpecificUoms;
				exclusionObject.selectedUom = listOfSpecificUoms.length > 0 ? listOfSpecificUoms[0].code : '';
				exclusionObject.rate = null;
				const listOfItems = [];
				listOfSelectedItems.map(item => {
					listOfItems.push(item);
				});
				exclusionObject.items = listOfItems;
				if (this.doCheckDuplicateItemsForBuyinggroup(exclusionObject)) {
					this.listOfMembersItemsExclsnTab.push(exclusionObject);
				}
			});
		} else if (this.rebatesDetails.member_mode === 'multiple_member' && this.rebatesDetails.tiers_type === 'single_items') {
			// console.log('do logic here for Multiple Members with Single Item');
			let allMemberObj: any;
			const allMemberIndex = listOfSelectedMembers.findIndex(selMember => selMember.allmembers === true);
			if (allMemberIndex !== -1) {
				allMemberObj = listOfSelectedMembers[allMemberIndex];
			}
			let memberListWithoutAllMember = listOfSelectedMembers.filter(selMember => selMember.allmembers === false);
			listOfSelectedItems.forEach(item => {
				listOfSpecificUoms = [];
				listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);

				rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollarperuom');
				rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
				if (item.allproducts) {
					rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollar');
					rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
				}

				if (allMemberObj && allMemberObj.code) {
					const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
					exclusionObject.member_item_guid = this.guid.newGuid();
					exclusionObject.allproducts = item.allproducts || false;
					exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
					exclusionObject.erp_primary_key = item.erp_primary_key;
					exclusionObject.item_guid = item.guid || '';
					exclusionObject.itemname = item.itemname;
					exclusionObject.itemsku = item.itemsku || '';
					exclusionObject.description = item.description || '';
					exclusionObject.uomgroupentry = item.uomgroupentry || '';
					exclusionObject.tier_basis = tierCode;
					exclusionObject.rebate_basis = rebateCode;
					exclusionObject.uomlist = listOfSpecificUoms;
					exclusionObject.selectedUom = listOfSpecificUoms.length > 0 ? listOfSpecificUoms[0].code : '';
					exclusionObject.rate = null;

					exclusionObject.code = allMemberObj.code;
					exclusionObject.name = allMemberObj.name;
					exclusionObject.allmembers = allMemberObj.allmembers || false;
					exclusionObject.members = [];
					if (this.doCheckDuplicateItemsForBuyinggroup(exclusionObject)) {
						this.listOfMembersItemsExclsnTab.push(exclusionObject);
					}
				}
				if (memberListWithoutAllMember && memberListWithoutAllMember.length > 0) {
					const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
					exclusionObject.member_item_guid = this.guid.newGuid();
					exclusionObject.allproducts = item.allproducts || false;
					exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
					exclusionObject.erp_primary_key = item.erp_primary_key;
					exclusionObject.item_guid = item.guid || '';
					exclusionObject.itemname = item.itemname;
					exclusionObject.itemsku = item.itemsku || '';
					exclusionObject.description = item.description || '';
					exclusionObject.uomgroupentry = item.uomgroupentry || '';
					exclusionObject.tier_basis = tierCode;
					exclusionObject.rebate_basis = rebateCode;
					exclusionObject.uomlist = listOfSpecificUoms;
					exclusionObject.selectedUom = listOfSpecificUoms.length > 0 ? listOfSpecificUoms[0].code : '';
					exclusionObject.rate = null;

					exclusionObject.code = memberListWithoutAllMember[0].code;
					exclusionObject.name = memberListWithoutAllMember[0].name;
					exclusionObject.members = memberListWithoutAllMember;
					if (this.doCheckDuplicateItemsForBuyinggroup(exclusionObject)) {
						this.listOfMembersItemsExclsnTab.push(exclusionObject);
					}
				}

			});
		} else if (this.rebatesDetails.member_mode === 'multiple_member' && this.rebatesDetails.tiers_type === 'multiple_items') {
			// console.log('do logic here for Multiple Members with Multiple Items');
			const item = listOfSelectedItems[0];
			listOfSpecificUoms = [];
			if (listOfSelectedItems && listOfSelectedItems.length > 0) {
				for (let i = 0; i < listOfSelectedItems.length; i++) {
					let listOfItemUoms = [];
					const item = listOfSelectedItems[i];
					if (!item.hasOwnProperty('allproducts') || !item.allproducts) {
						const newUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);
						newUoms.forEach(specificUom => {
							const itemUom = listOfSpecificUoms.findIndex(uom => uom.code === specificUom.code);
							if (itemUom === -1) {
								listOfItemUoms.push(specificUom);
							}
						});
					}
					listOfSpecificUoms = [...listOfSpecificUoms, ...listOfItemUoms];
				}
			}

			rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollarperuom');
			rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
			if (listOfSelectedItems[0].allproducts) {
				rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollar');
				rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
			}

			const allMemberIndex = listOfSelectedMembers.findIndex(selMember => selMember.allmembers);
			if (allMemberIndex !== -1) {
				const member = listOfSelectedMembers[allMemberIndex];
				const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
				exclusionObject.member_item_guid = this.guid.newGuid();
				exclusionObject.allproducts = item.allproducts || false;
				exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
				exclusionObject.erp_primary_key = item.erp_primary_key;
				exclusionObject.item_guid = item.guid || '';
				exclusionObject.itemname = item.itemname;
				exclusionObject.itemsku = '';
				exclusionObject.description = '';
				exclusionObject.uomgroupentry = item.uomgroupentry || '';
				exclusionObject.tier_basis = tierCode;
				exclusionObject.rebate_basis = rebateCode;
				exclusionObject.uomlist = listOfSpecificUoms;
				exclusionObject.selectedUom = listOfSpecificUoms.length > 0 ? listOfSpecificUoms[0].code : '';
				exclusionObject.rate = null;
				const listOfItems = listOfSelectedItems.map(item => {
					return item;
				});;

				exclusionObject.items = listOfItems;
				exclusionObject.code = member.code;
				exclusionObject.name = member.name;
				exclusionObject.allmembers = member.allmembers || false;
				exclusionObject.members = [];
				if (this.doCheckDuplicateItemsForBuyinggroup(exclusionObject)) {
					this.listOfMembersItemsExclsnTab.push(exclusionObject);
				}
			}
			const membersIndex = listOfSelectedMembers.findIndex(selMember => !selMember.allmembers);
			if (membersIndex !== -1) {
				const member = listOfSelectedMembers[membersIndex];
				const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
				exclusionObject.member_item_guid = this.guid.newGuid();
				exclusionObject.allproducts = item.allproducts || false;
				exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
				exclusionObject.erp_primary_key = item.erp_primary_key;
				exclusionObject.item_guid = item.guid || '';
				exclusionObject.itemname = item.itemname;
				exclusionObject.itemsku = '';
				exclusionObject.description = '';
				exclusionObject.uomgroupentry = item.uomgroupentry || '';
				exclusionObject.tier_basis = tierCode;
				exclusionObject.rebate_basis = rebateCode;
				exclusionObject.uomlist = listOfSpecificUoms;
				exclusionObject.selectedUom = listOfSpecificUoms.length > 0 ? listOfSpecificUoms[0].code : '';
				exclusionObject.rate = null;
				const listOfItems = [];
				listOfSelectedItems.map(item => {
					listOfItems.push(item);
				});
				exclusionObject.items = listOfItems;
				exclusionObject.code = member.code;
				exclusionObject.name = member.name;
				exclusionObject.allmembers = member.allmembers || false;
				exclusionObject.members = JSON.parse(JSON.stringify(listOfSelectedMembers));
				const allMemberIndex = listOfSelectedMembers.findIndex(member => member.hasOwnProperty('allmembers') && member.allmembers === true);
				if (allMemberIndex !== -1) {
					exclusionObject.members.splice(allMemberIndex, 1);
				}
				if (this.doCheckDuplicateItemsForBuyinggroup(exclusionObject)) {
					this.listOfMembersItemsExclsnTab.push(exclusionObject);
				}
			}
		}
		this.clearValuesExclsnTab();
		setTimeout(() => {
			this.dataSourceMembersLstExclsnTab = this.listOfMembersItemsExclsnTab;
		}, 50);
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	private isValidMembersExclsnTabForClass() {
		if (this.aSelMembersExclsnTabDg.length <= 0 && !this.isCheckedAllMembersExclsnTabDrpDwnChkBox) {
			this._ToastrService.info('Member is required' || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (this.arrSelectedClassesList.length <= 0 && !this.chkAllClasses) {
			this._ToastrService.info('Classes is required' || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		return false;
	}
	private isValidMembersExclsnTab() {
		if (this.aSelMembersExclsnTabDg.length <= 0 && !this.isCheckedAllMembersExclsnTabDrpDwnChkBox) {
			this._ToastrService.info('Member is required' || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (this.aSelItemsExclsnTabDg.length <= 0 && !this.isCheckedAllItemsExclsnTabDrpDwnChkBox) {
			this._ToastrService.info('Item is required' || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		return false;
	}

	private doCheckDuplicateItemsForBuyinggroup(memberItems: any): boolean {
		let doesNotExists = true;
		if (this.rebatesDetails.member_mode === 'single_member' && this.rebatesDetails.tiers_type === 'single_items') {
			for (let i = 0; i < this.dataSourceMembersLstExclsnTab.length; i++) {
				const memberEle = this.dataSourceMembersLstExclsnTab[i];
				if (memberEle.member_item_guid !== memberItems.member_item_guid && memberEle.code === memberItems.code && memberEle.tier_basis === memberItems.tier_basis && memberEle.rebate_basis === memberItems.rebate_basis && memberEle.erp_primary_key === memberItems.erp_primary_key) {
					doesNotExists = false;
					break;
				}
			}
			if (!doesNotExists) {
				this._ToastrService.info('Duplicate entry: an entry matching this member, product, already exists.', 'Info', { closeButton: true, tapToDismiss: true });
				doesNotExists = false;
			}
		}

		if (this.rebatesDetails.member_mode === 'single_member' && this.rebatesDetails.tiers_type === 'multiple_items') {
			// Old Logic
			for (let i = 0; i < this.dataSourceMembersLstExclsnTab.length; i++) {
				const rowEle = this.dataSourceMembersLstExclsnTab[i];
				if (rowEle.member_item_guid !== memberItems.member_item_guid && rowEle.code === memberItems.code && rowEle.tier_basis === memberItems.tier_basis && rowEle.rebate_basis === memberItems.rebate_basis) {
					for (let itemIndex = 0; itemIndex < rowEle.items.length; itemIndex++) {
						const memberItemEle = rowEle.items[itemIndex];
						if (memberItems.items.findIndex(itemEle => itemEle.erp_primary_key === memberItemEle.erp_primary_key) !== -1) {
							doesNotExists = false;
							break;
						}
					}
				}
			}
			if (!doesNotExists) {
				this._ToastrService.info('Duplicate entry: an entry matching this member, product, already exists.', 'Info', { closeButton: true, tapToDismiss: true });
			}
			/*
			let countOfEachItem = 0;
			this.dataSourceMembersLstExclsnTab.forEach(memberEle => {
				if (memberEle.member_item_guid !== memberItems.member_item_guid && memberEle.code === memberItems.code && memberEle.tier_basis === memberItems.tier_basis && memberEle.rebate_basis === memberItems.rebate_basis) {
					memberItems.items.forEach(memberItem => {
						const existingItemIndex = memberEle.items.findIndex(itemEle => itemEle.erp_primary_key === memberItem.erp_primary_key);
						if (existingItemIndex !== -1) {
							countOfEachItem++;
						}
					});
				}
			});
			if (countOfEachItem > 0) {
				this._ToastrService.info('Duplicate entry: an entry matching this member, product, already exists.', 'Info', { closeButton: true, tapToDismiss: true });
				doesNotExists = false;
			}
			*/
		}

		if (this.rebatesDetails.member_mode === 'multiple_member' && this.rebatesDetails.tiers_type === 'single_items') {
			for (let i = 0; i < this.dataSourceMembersLstExclsnTab.length; i++) {
				const rowEle = this.dataSourceMembersLstExclsnTab[i];
				if (rowEle.member_item_guid !== memberItems.member_item_guid && rowEle.tier_basis === memberItems.tier_basis && rowEle.rebate_basis === memberItems.rebate_basis && rowEle.erp_primary_key === memberItems.erp_primary_key) {
					if (!memberItems.allmembers) {
						if (memberItems.members && memberItems.members.length > 0) {
							for (let mitem = 0; mitem < memberItems.members.length; mitem++) {
								const itemOfMemberItem = memberItems.members[mitem];
								const existingItemIndex = rowEle.members.findIndex(memberEle => memberEle.code === itemOfMemberItem.code);
								if (existingItemIndex !== -1) {
									doesNotExists = false;
									break;
								}
							}
						}
					} else {
						if (rowEle.code === memberItems.code) {
							doesNotExists = false;
							break;
						}
					}
				}
			}
			if (!doesNotExists) {
				this._ToastrService.info('Duplicate entry: an entry matching this member, product, already exists.', 'Info', { closeButton: true, tapToDismiss: true });
			}
		}
		if (this.rebatesDetails.member_mode === 'multiple_member' && this.rebatesDetails.tiers_type === 'multiple_items') {
			for (let i = 0; i < this.dataSourceMembersLstExclsnTab.length; i++) {
				const rowEle = this.dataSourceMembersLstExclsnTab[i];
				if (rowEle.member_item_guid !== memberItems.member_item_guid && rowEle.tier_basis === memberItems.tier_basis && rowEle.rebate_basis === memberItems.rebate_basis) {
					if (!memberItems.allmembers) {
						if (memberItems.members && memberItems.members.length > 0) {
							for (let mi = 0; mi < memberItems.members.length; mi++) {
								const memberOfMemberItem = memberItems.members[mi];
								const existingMemberIndex = rowEle.members.findIndex(memberEle => memberEle.code === memberOfMemberItem.code);
								if (existingMemberIndex !== -1) {
									if (memberItems.items && memberItems.items.length > 0) {
										for (let mitem = 0; mitem < memberItems.items.length; mitem++) {
											const itemOfMemberItem = memberItems.items[mitem];
											const existingItemIndex = rowEle.items.findIndex(memberEle => memberEle.erp_primary_key === itemOfMemberItem.erp_primary_key);
											if (existingItemIndex !== -1) {
												doesNotExists = false;
												break;
											}
										}
									}
								}
							}
						}
					} else {
						if (memberItems.items && memberItems.items.length > 0) {
							for (let mitem = 0; mitem < memberItems.items.length; mitem++) {
								const itemOfMemberItem = memberItems.items[mitem];
								const existingItemIndex = rowEle.items.findIndex(memberEle => memberEle.erp_primary_key === itemOfMemberItem.erp_primary_key);
								if (existingItemIndex !== -1) {
									doesNotExists = false;
									break;
								}
							}
						}
					}
				}
			}
			if (!doesNotExists) {
				this._ToastrService.info('Duplicate entry: an entry matching this member, product, already exists.', 'Info', { closeButton: true, tapToDismiss: true });
			}
		}

		return doesNotExists;
	}

	private clearValuesExclsnTab() {
		this.itemsOrGroupsRadioBtnExclsnTab = 'item';

		this.sMembersLblExclsnTabDrpDwn = '';
		this.isCheckedAllMembersExclsnTabDrpDwnChkBox = false;
		this.aMembersLstExclsnTabDrpDwnTagBox = [];
		this.aSelKeysMembersExclsnTabDg = [];
		this.aSelMembersExclsnTabDg = [];

		this.sItemsLblExclsnTabDrpDwn = '';
		this.isCheckedAllItemsExclsnTabDrpDwnChkBox = false;
		this.aItemsLstExclsnTabDrpDwnTagBox = [];
		this.aSelKeysItemsExclsnTabDg = [];
		this.aSelItemsExclsnTabDg = [];

		this.chkAllClasses = false;
		this.lengthSelectedClasses = '';
		this.arrSelectedClassesList = [];
	}

	// #region for Members Dropdown Methods

	// Method used to Intialize Members dropdown events
	private doInitMembersDrpDwn() {
		this.setColumnForMembersDataGridExclsnTab();
		this.setMembersDataSourceExlsnTab();
	}

	// Method used to set dynamic columns on members datagrid into dropdown
	private setColumnForMembersDataGridExclsnTab() {
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.columnsMembersExclsnTabDg = [
				{ dataField: 'code', caption: 'Code', visible: true, dataType: 'string' },
				{ dataField: 'name', caption: 'Name', dataType: 'string' },
				{ dataField: 'buyinggroup_code', caption: 'Buying Group ID', dataType: 'string' },
				{ dataField: 'buyinggroup_name', caption: 'Buying Group', dataType: 'string' }
			];
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.columnsMembersExclsnTabDg = [
				{ dataField: 'code', caption: 'Code', visible: false, dataType: 'string' },
				{ dataField: 'name', caption: 'Name', dataType: 'string' },
				{ dataField: 'buyinggroup_code', caption: 'Buying Group ID', dataType: 'string' },
				{ dataField: 'buyinggroup_name', caption: 'Buying Group', dataType: 'string' }
			];
		} else {
			this.columnsMembersExclsnTabDg = [
				{ dataField: 'code', caption: 'Code', visible: true, dataType: 'string' },
				{ dataField: 'name', caption: 'Name', dataType: 'string' },
				{ dataField: 'buyinggroup_code', caption: 'Buying Group ID', dataType: 'string' },
				{ dataField: 'buyinggroup_name', caption: 'Buying Group', dataType: 'string' }
			];
		}
	}

	// Method used to fetched Members List
	private setMembersDataSourceExlsnTab() {
		setTimeout(() => {
			this.membersLstExclsnTabDsDrpDwn = this.memberTabList;
		}, 100);
	}

	// Method used to clear search of datagrid when dropdown open
	public doOpenMembersExclsnTabDrpdwn() {
		this.refMembersLstExclsnTabDg.instance.clearFilter();
	}

	// Method used to change values event of member dropdown
	public doChangeMembersExclsnTabDrpdwn(event) {
		if (event.value === null) {
			this.sMembersLblExclsnTabDrpDwn = '';
			this.isCheckedAllMembersExclsnTabDrpDwnChkBox = false;
			this.aSelKeysMembersExclsnTabDg = [];
			this.aSelMembersExclsnTabDg = [];
			this.aMembersLstExclsnTabDrpDwnTagBox = [];
		}
	}

	public doOpenedDropdownMembersExclsnTab(event: any) {
		this.refMembersLstExclsnTabDg.instance.clearFilter();
	}

	public doDisplayExprMembersText(event: any) {
		let listOfText: any[] = [];
		if (this.isCheckedAllMembersExclsnTabDrpDwnChkBox) {
			listOfText.push('All Members');
		}
		if (this.aSelKeysMembersExclsnTabDg.length > 0) {
			listOfText.push(this.aSelKeysMembersExclsnTabDg.length + ' Members');
		}
		return listOfText.length > 0 ? listOfText.join(', ') : '';
	}

	// Method used to set string on members dropdown
	private setTextForMembersExclsnTabDrpDwn() {
		if (this.aSelMembersExclsnTabDg.length <= 0 && !this.isCheckedAllMembersExclsnTabDrpDwnChkBox) {
			this.sMembersLblExclsnTabDrpDwn = '';
			return;
		}
		if (this.isCheckedAllMembersExclsnTabDrpDwnChkBox) {
			this.sMembersLblExclsnTabDrpDwn = 'All Members Selected';
		} else {
			if (this.aSelMembersExclsnTabDg.length >= 0) {
				this.sMembersLblExclsnTabDrpDwn = this.aSelMembersExclsnTabDg.length + ' Member(s) Selected';
			}
		}
	}

	// Method used to change values of Checkbox above members datagrid in dropdown
	public doChangeAllMembersExclsnTabDrpDwnChkBox(event) {
		this.setTextForMembersExclsnTabDrpDwn();
	}

	// Method used to select members of datagrid into dropdown
	public doSelChangedMembersExclsnTabDg(event) {
		this.aSelMembersExclsnTabDg = [];
		if (event.selectedRowsData && event.selectedRowsData.length > 0) {
			for (let i = 0; i < event.selectedRowsData.length; i++) {
				this.aSelMembersExclsnTabDg.push(event.selectedRowsData[i]);
			}
		}
		this.setTagboxMembers(event.selectedRowsData);
		this.setTextForMembersExclsnTabDrpDwn();
	}

	// Method used to Set tagbox values to show selected members
	private setTagboxMembers(selectListOfItems: any[] = []) {
		this.aMembersLstExclsnTabDrpDwnTagBox = [];
		for (let i = 0; i < selectListOfItems.length; i++) {
			this.aMembersLstExclsnTabDrpDwnTagBox.push(selectListOfItems[i].name);
		}
	}

	// Method used to changed value of members tag box
	public doValueChangeMembersExclsnTabTagBox(event) {
		const listTags = event.value;
		if (event.value.length !== event.previousValue.length) {
			for (let i = 0; i < this.aSelMembersExclsnTabDg.length; i++) {
				const tagIndex = listTags.findIndex(tag => tag === this.aSelMembersExclsnTabDg[i].name);
				if (tagIndex === -1) {
					const memberKeyIndex = this.aSelKeysMembersExclsnTabDg.findIndex(memberKey => memberKey === this.aSelMembersExclsnTabDg[i].code);
					this.aSelKeysMembersExclsnTabDg.splice(memberKeyIndex, 1);
					this.aSelMembersExclsnTabDg.splice(i, 1);
				}
			}
			this.setTextForMembersExclsnTabDrpDwn();
		}
	}

	// #endregion for Members Dropdown Methods

	// #region for Items Dropdown Methods

	// Method used to Intialize Item dropdown events
	private doInitItemsDrpDwn() {
		this.setColumnForItemsDataGridExclsnTab();
		this.setItemsDataSourceExclsnTab();
	}

	// Method used to set dynamic columns on items datagrid into dropdown
	private setColumnForItemsDataGridExclsnTab() {
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.columnsItemsExclsnTabDg = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.columnsItemsExclsnTabDg = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			this.columnsItemsExclsnTabDg = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
	}

	// Method used to fetched Items List
	private setItemsDataSourceExclsnTab() {
		const viewFields = ['guid', 'erp_primary_key', 'itemsku', 'itemname', 'description', 'uomgroupentry'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: 'dxGetEntityData',
			entity: 'items',
			view_fields: JSON.stringify(viewFields)
		};
		this.itemsLstExclsnTabDsDrpDwn = AspNetData.createStore({
			key: 'erp_primary_key',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to clear  filter of datagrid when dropdown open
	public doOpenItemsExclsnTabDrpdwn(e) {
		this.refItemsLstExclsnTabDg.instance.clearFilter();
	}

	// Method used to change values event of item dropdown
	public doChangeItemsExclsnTabDrpdwn(event) {
		if (event.value === null) {
			this.sItemsLblExclsnTabDrpDwn = '';
			this.isCheckedAllItemsExclsnTabDrpDwnChkBox = false;
			this.aSelItemsExclsnTabDg = [];
			this.aSelKeysItemsExclsnTabDg = [];
			this.aItemsLstExclsnTabDrpDwnTagBox = [];
		}
	}

	public doOpenedDropdownItemsExclsnTabDrpdwn(e) {
		this.refItemsLstExclsnTabDg.instance.clearFilter();
	}

	public doDisplayExprItemsText(event: any) {
		let listOfTexts: any[] = [];
		if (this.isCheckedAllItemsExclsnTabDrpDwnChkBox) {
			listOfTexts.push('All Items');
		}
		if (this.aSelKeysItemsExclsnTabDg && this.aSelKeysItemsExclsnTabDg.length > 0) {
			listOfTexts.push(this.aSelKeysItemsExclsnTabDg.length + ' Item(s)');
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}

	// Method used to set string on item dropdown
	private setTextForItemExclsnTabDrpDwn() {
		if (this.aSelItemsExclsnTabDg.length <= 0 && !this.isCheckedAllItemsExclsnTabDrpDwnChkBox) {
			this.sItemsLblExclsnTabDrpDwn = '';
			return;
		}
		if (this.isCheckedAllItemsExclsnTabDrpDwnChkBox) {
			this.sItemsLblExclsnTabDrpDwn = 'All Item Selected';
		} else {
			if (this.aSelItemsExclsnTabDg.length >= 0) {
				this.sItemsLblExclsnTabDrpDwn = this.aSelItemsExclsnTabDg.length + ' Item(s) Selected';
			}
		}
	}

	// Method used to change values of Checkbox above item datagrid in dropdown
	public doChangeAllItemsExclsnTabDrpDwnChkBox(event) {
		this.setTextForItemExclsnTabDrpDwn();
	}

	// Method used to select item of datagrid into dropdown
	public doSelChangedItemsExclsnTabDg(event) {
		this.aSelItemsExclsnTabDg = [];
		if (event.selectedRowsData && event.selectedRowsData.length > 0) {
			for (let i = 0; i < event.selectedRowsData.length; i++) {
				this.aSelItemsExclsnTabDg.push(event.selectedRowsData[i]);
			}
		}
		this.setTagboxItems(event.selectedRowsData);
		this.setTextForItemExclsnTabDrpDwn();
	}

	// Method used to Set tagbox values to show selected items
	private setTagboxItems(selectListOfItems: any[] = []) {
		this.aItemsLstExclsnTabDrpDwnTagBox = [];
		for (let i = 0; i < selectListOfItems.length; i++) {
			this.aItemsLstExclsnTabDrpDwnTagBox.push(selectListOfItems[i].itemname);
		}
	}

	// Method used to changed value of items tag box
	public doValueChangeItemsExclsnTabTagBox(event) {
		const listTags = event.value || [];
		if (event.value.length !== event.previousValue.length) {
			for (let i = 0; i < this.aSelItemsExclsnTabDg.length; i++) {
				const tagIndex = listTags.findIndex(tag => tag === this.aSelItemsExclsnTabDg[i].itemname);
				if (tagIndex === -1) {
					const itemKeyIndex = this.aSelKeysItemsExclsnTabDg.findIndex(itemKey => itemKey === this.aSelItemsExclsnTabDg[i].erp_primary_key);
					this.aSelKeysItemsExclsnTabDg.splice(itemKeyIndex, 1);
					this.aSelItemsExclsnTabDg.splice(i, 1);
				}
			}
			this.setTextForItemExclsnTabDrpDwn();
		}
	}

	// #endregion for Items Dropdown Methods

	// #region for Exclusion List Datagrid Methods

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparingExclsnTab(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					visible: !this.enable_classification_for_rebate,
					icon: 'xlsxfile',
					text: "Import Excel/Csv",
					onClick: (event: any) => {
						this.doImportToDatagridExclsnTab();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcelExclsnTab(event);
					}
				}
			}
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.options.icon = "columnChooser";
			columnChooserButton.showText = "always";
			columnChooserButton.icon = 'columnChooser';
		}
	}

	private doImportToDatagridExclsnTab() {
		if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			if (!this.rebatesDetails.selectBuyingGroupObj || (this.rebatesDetails.selectBuyingGroupObj && !this.rebatesDetails.selectBuyingGroupObj.code)) {
				this._ToastrService.info('Buyign Group Name is required', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}
		this.uploadRebateExclusionItemsProps = {
			actionFor: 'showUploadFile',
			contractType: this.rebatesDetails.individualOrGroup,
			rebateType: this.rebatesDetails.rebateType,
			isItemExists: this.listOfMembersItemsExclsnTab.length > 0,
			listOfCsvRows: []
		};
		this.doOpenUploadRebateExclusionItemsPopup();
	}

	private matchedMemberItemWithCsvFile() {
		this._GlobalStateService.unsubscribe('ADD_BULK_MEMBERSITEMS_TO_ITMGRP_TAB_LIST_EVENT');
		this._GlobalStateService.subscribe('ADD_BULK_MEMBERSITEMS_TO_ITMGRP_TAB_LIST_EVENT', response => {
			const listOfItemDetails = response.itemDetailsList;
			const tierIndex = this.listOfTierBasis.findIndex(t => t.code === 'notiers');
			const tierCode = tierIndex !== -1 && this.listOfTierBasis.length > 0 ? this.listOfTierBasis[tierIndex].code : '';
			const rebateIndex = this.listOfRebateBasis.findIndex(r => r.code === 'dollarperuom');
			const rebateCode = (rebateIndex !== -1 && this.listOfRebateBasis.length > 0) ? this.listOfRebateBasis[rebateIndex].code : '';
			const listOfMembersItemsExclsnTab = [];
			this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
				csvRow.isMemberMatched = false;
				csvRow.isItemMatched = false;
				csvRow.isItemUomMatched = 'unmatched';
				csvRow.isItemModifiedUom = '';
				csvRow.isItemModifiedPrice = false;
				csvRow.isItemModifiedPriceLbl = null;
				if (csvRow.member && csvRow.item) {
					const memberIndex = this.memberTabList.findIndex(member => member.name.toString().toLowerCase() === csvRow.member.toString().toLowerCase());
					const itemIndex = listOfItemDetails.findIndex(item => item.itemname.toString().toLowerCase() === csvRow.item.toString().toLowerCase());
					if (memberIndex !== -1 && itemIndex !== -1) {
						const member = this.memberTabList[memberIndex];
						const item = listOfItemDetails[itemIndex];
						csvRow.description = item.description || '';
						csvRow.isMemberMatched = true;
						csvRow.isItemMatched = true;

						const listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, '');
						let defaultUom = '';

						if (csvRow.uom) {
							const uomIndex = listOfSpecificUoms.findIndex(uom => uom.code.toString().toLowerCase() === csvRow.uom.toString().toLowerCase());
							if (uomIndex !== -1) {
								defaultUom = listOfSpecificUoms[uomIndex].code;
								csvRow.isItemUomMatched = 'matched';
								csvRow.isItemModifiedUom = '';
							}
						} else {
							defaultUom = listOfSpecificUoms.length > 0 ? listOfSpecificUoms[0].code : '';
							csvRow.isItemUomMatched = 'unmatched';
							csvRow.isItemModifiedUom = defaultUom;
						}

						let rate = null;
						csvRow.rebate = this.convertToNumber(csvRow.rebate ? csvRow.rebate.toString() : '');
						if ($.isNumeric(csvRow.rebate)) {
							rate = +csvRow.rebate;
							csvRow.isItemModifiedPrice = true;
						}

						const exclusionObject: ExclusionListExclusionTabModel = new ExclusionListExclusionTabModel();
						exclusionObject.member_item_guid = this.guid.newGuid();
						exclusionObject.code = member.code;
						exclusionObject.name = member.name;
						exclusionObject.allmembers = false;
						exclusionObject.allproducts = false;
						exclusionObject.itemOrGroup = this.itemsOrGroupsRadioBtnExclsnTab;
						exclusionObject.erp_primary_key = item.erp_primary_key;
						exclusionObject.item_guid = item.guid || '';
						exclusionObject.itemname = item.itemname;
						exclusionObject.itemsku = item.itemsku || '';
						exclusionObject.description = item.description || '';
						exclusionObject.uomgroupentry = item.uomgroupentry || '';
						exclusionObject.tier_basis = tierCode;
						exclusionObject.rebate_basis = rebateCode;
						exclusionObject.uomlist = listOfSpecificUoms;
						exclusionObject.selectedUom = defaultUom;
						exclusionObject.rate = rate;

						const isRowExistis = listOfMembersItemsExclsnTab.filter(exclsn => exclsn.code === member.code && exclsn.erp_primary_key === item.erp_primary_key).length <= 0;
						if (isRowExistis) {
							listOfMembersItemsExclsnTab.push(exclusionObject);
						}
					}
				}
			});

			this.isUploadExcelCsvFileItmGrpTab = true;
			this.filterText = this.csvResponse.fileName;
			this.checkImportMemberItemSummary();
			if (listOfMembersItemsExclsnTab.length > 0) {
				setTimeout(() => {
					this.dataSourceMembersLstExclsnTab = listOfMembersItemsExclsnTab;
				}, 50);
				if (this.editRebatesGuid && listOfMembersItemsExclsnTab.length > 0) {
					setTimeout(() => {
						this.saveRebatesContract();
					}, 700);
				}
			}
		});
	}

	private checkImportMemberItemSummary() {
		this.noOfTotalItem = this.listOfCsvRowForItmGrpTab.length;
		this.noOfUnmatchedItem = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched === false).length;
		this.noOfUnmatchedItemUom = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
		this.noOfmatchedItemPrice = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
	}

	public doShowCsvMembersItemsListExclsionTab() {
		this.uploadRebateExclusionItemsProps = {
			actionFor: 'showIgnoredItemList',
			contractType: this.rebatesDetails.individualOrGroup,
			rebateType: this.rebatesDetails.rebateType,
			listOfCsvRows: [],
			listOfUnmatchedItems: this.listOfCsvRowForItmGrpTab
		};
		this.doOpenUploadRebateExclusionItemsPopup();
	}

	private fetchedItemsExclsnTab() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEMS);
		formData.append('is_dropdown', 'true');
		let itemsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULK_MEMBERSITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemsList });
			}, error: error => {
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULK_MEMBERSITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemsList });
				this._LoaderService.hide();
			}
		});
	}

	// Method used to Export Excelsheet for Exclusion List
	private doExportToExcelExclsnTab(e) {
		if (this.membersListExclsnTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'ItemAndGroupTab.xlsx';
		worksheet = workbook.addWorksheet('Item And Group');

		const listOfMasterDetailsRows: any[] = [];
		exportDataGrid({
			worksheet: worksheet,
			component: this.membersListExclsnTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 11, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'itemname') {
						listOfMasterDetailsRows.push({
							rowIndex: excelCell.fullAddress.row + 1,
							data: gridCell.data
						});
					}
					if (gridCell.column.dataField === 'rate') {
						excelCell.alignment = { horizontal: 'right' };
						if (gridCell.data.tier_basis === 'notiers') {
							if ((gridCell.data.rebate_basis === 'dollar' || gridCell.data.rebate_basis === 'dollarperuom')) {
								excelCell.value = this._CustomCurrencyPipe.transform(+gridCell.data.rate || 0, this.customCurrencyOption);
							}
							if (gridCell.data.rebate_basis === 'percentage') {
								excelCell.value = this._CustomNumberNoRoundingPipe.transform(+gridCell.data.rate || 0) + '%';
							}
						}
					}
				}
			}
		}).then((cellRange) => {
			const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = worksheet.insertRow(currentIndex, [], "n");
				for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
					worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			const columnsField = [
				'mintier',
				'maxtier',
				'rate'
			];
			const columns = [
				'Tier Minimum',
				'Tier Maximum',
				'Rate'
			];

			let rebate_basis, tier_basis;
			for (let i = 0; i < listOfMasterDetailsRows.length; i++) {
				rebate_basis = listOfMasterDetailsRows[i].data.rebate_basis;
				tier_basis = listOfMasterDetailsRows[i].data.tier_basis;
				let itemColumnsField = [
					'itemsku',
					'itemname',
					'description',
					'rate',
				];
				let itemCaptionColumns = [
					'SKU',
					'Item',
					'Description',
					'Rate'
				];

				if (rebate_basis === 'dollarperuom' && !this.enable_classification_for_rebate) {
					itemColumnsField = [
						'itemsku',
						'itemname',
						'description',
						'selectedUom',
						'rate',
					];
					let itemColumns = [
						'SKU',
						'Item',
						'Description',
						'UOM',
						'Rate'
					];
				}

				if (this.enable_classification_for_rebate) {
					itemColumnsField = [
						'classname',
						'rate',
					];
					itemCaptionColumns = [
						'Classes',
						'Rate'
					];
				}

				if (!this.isQBOnlineErpUser) {
					const itemskuColFieldIndex = itemColumnsField.findIndex(colField => colField === 'itemsku');
					if (itemskuColFieldIndex !== -1) {
						itemColumnsField.splice(itemskuColFieldIndex, 1);
					}
					const itemskuColCaptionIndex = itemCaptionColumns.findIndex(colCaption => colCaption === 'SKU');
					if (itemskuColCaptionIndex !== -1) {
						itemCaptionColumns.splice(itemskuColCaptionIndex, 1);
					}
				}
				let row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				const columnIndex = cellRange.from.column;
				// row.height = 24;
				// worksheet.mergeCells(row.number, columnIndex, row.number, 7);

				if (listOfMasterDetailsRows[i].data.tiers.length > 0) {
					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);

					columns.forEach((columnName, currentColumnIndex) => {
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							fill: {
								type: "pattern",
								pattern: "solid",
								fgColor: { argb: "BEDFE6" }
							},
							font: { bold: true },
							border: {
								bottom: borderStyle,
								left: borderStyle,
								right: borderStyle,
								top: borderStyle
							}
						});
					});

					let subTotalLineTotal = 0;
					listOfMasterDetailsRows[i].data.tiers.forEach((tierDetails, tierIndex) => {
						const itemColumnIndexDetail = cellRange.from.column;
						row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						columnsField.forEach((columnName, currentColumnIndex) => {
							const cellObject = {
								value: tierDetails[columnName],
								fill: {
									type: "pattern",
									pattern: "solid",
									fgColor: { argb: "BEDFE6" }
								},
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								},
								alignment: { horizontal: 'left' }
							};

							if (columnName === 'mintier') {
								if (!tierDetails[columnName]) {
									cellObject.value = tierDetails[columnName];
								} else if (tierDetails[columnName] === '-') {
									cellObject.value = tierDetails[columnName];
								} else {
									if (tier_basis === 'volume') {
										cellObject.value = tierDetails[columnName] || '0';
									} else if (tier_basis === 'revenue') {
										cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
									} else {
										cellObject.value = tierDetails[columnName];
									}
								}
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'maxtier') {
								if (!tierDetails[columnName]) {
									cellObject.value = tierDetails[columnName];
								} else if (tierDetails[columnName] === '-') {
									cellObject.value = tierDetails[columnName];
								} else {
									if (tier_basis === 'volume') {
										cellObject.value = tierDetails[columnName] || '0';
									} else if (tier_basis === 'revenue') {
										cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
									} else {
										cellObject.value = tierDetails[columnName];
									}
								}
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'rate') {
								if (((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'purchasing') || this.rebatesDetails.individualOrGroup === 'buyinggroup') {
									subTotalLineTotal = subTotalLineTotal + tierDetails[columnName];
								}
								if ((rebate_basis === 'dollar' || rebate_basis === 'dollarperuom')) {
									cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
								}
								if (rebate_basis === 'percentage') {
									cellObject.value = this._CustomNumberNoRoundingPipe.transform(+tierDetails[columnName] || 0) + '%';
								}
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'listPrice') {
								cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'finalrebateprice') {
								if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales' && this.productSearchItemType === 'price') {
									subTotalLineTotal = subTotalLineTotal + tierDetails[columnName];
								}
								cellObject.value = this._CustomCurrencyPipe.transform(tierDetails[columnName], this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
							row.keepColumnWidths = true;
						});

						row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						if (this.rebatesDetails.tiers_type === 'multiple_items') {
							row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 3);
							itemCaptionColumns.forEach((columnName, currentColumnIndex) => {
								Object.assign(row.getCell(itemColumnIndexDetail + currentColumnIndex), {
									value: columnName,
									fill: {
										type: "pattern",
										pattern: "solid",
										fgColor: { argb: "BEDFE6" }
									},
									font: { bold: true },
									border: {
										bottom: borderStyle,
										left: borderStyle,
										right: borderStyle,
										top: borderStyle
									}
								});
							});

							tierDetails.items.forEach((itemDetails, itemIndex) => {
								row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 3);
								row.keepColumnWidths = true;
								itemColumnsField.forEach((columnName, currentColumnIndex) => {
									const cellObject = {
										value: itemDetails[columnName],
										fill: {
											type: "pattern",
											pattern: "solid",
											fgColor: { argb: "BEDFE6" }
										},
										border: {
											bottom: borderStyle,
											left: borderStyle,
											right: borderStyle,
											top: borderStyle
										},
										alignment: { horizontal: 'left' }
									};

									if (columnName === 'rate') {
										if ((rebate_basis === 'dollar' || rebate_basis === 'dollarperuom')) {
											cellObject.value = this._CustomCurrencyPipe.transform(+itemDetails[columnName] || 0, this.customCurrencyOption);
										} else if (rebate_basis === 'percentage') {
											cellObject.value = this._CustomNumberNoRoundingPipe.transform(+itemDetails[columnName] || 0) + '%';
										} else {
											cellObject.value = itemDetails[columnName] || '';
										}
										cellObject.alignment.horizontal = 'right';
									}
									Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
									row.keepColumnWidths = true;
								});
							});
							row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
							row.keepColumnWidths = true;
						}
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;

					worksheet.mergeCells(row.number, columnIndex, row.number, 2);
					rowLastCell = 3;

					Object.assign(row.getCell(columnIndex), {
						value: 'Sub Total',
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'right' }
					});

					Object.assign(row.getCell(rowLastCell), {
						value: this._CustomCurrencyPipe.transform(subTotalLineTotal, this.customCurrencyOption),
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				}
				offset--;
			}

			this.doAddContractDetailsToWorkSheet(worksheet);

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doEditorPreparingMembersExclsnTab(event) {
		if (event.parentType === "dataRow") {
			const itemObject = event.row.data;
			const listOfRows = this.membersListExclsnTabDataGridRef.instance.getVisibleRows();
			const rowIndex = this.membersListExclsnTabDataGridRef.instance.getRowIndexByKey(event.row.key);
			const itemDetails = listOfRows[rowIndex].data;
			if (event.parentType === "dataRow" && event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (itemObject.rebate_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						uomDataSource = itemObject.uomlist;
						event.editorOptions.dataSource = uomDataSource;
					}
				}
			}

			if (event.parentType === "dataRow" && event.dataField === 'tier_basis') {
				const listOfTierBasis = this.listOfTierBasis;
				event.editorOptions.dataSource = listOfTierBasis;
			}

			if (event.parentType === "dataRow" && event.dataField === 'rebate_basis') {
				let listOfRebateBasis = this.listOfRebateBasis;
				if (itemObject.allproducts) {
					listOfRebateBasis = this.listOfRebateBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}
				event.editorOptions.dataSource = listOfRebateBasis;
			}

			if (event.parentType === "dataRow" && event.dataField === 'rate') {
				// event.editorOptions.disabled = true;
				// if (itemObject.allproducts) {
				// 	if (itemObject.tier_basis === 'notiers') {
				// 		event.editorOptions.disabled = false;
				// 	}
				// } else {
				// 	if (itemObject.tier_basis === 'notiers') {
				// 		event.editorOptions.disabled = false;
				// 	}
				// }
				event.editorOptions.visible = false;
				if (itemObject.allproducts) {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.visible = true;
					}
				} else {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.visible = true;
					}
				}
			}
		}
	}

	public doRowUpdatedMembersExclsnTab(event) {
		const itemDetails = event.data;
		if (itemDetails.tier_basis === 'notiers') {
			itemDetails.tiers = [];
		}

		if (itemDetails.tier_basis === 'volume' || itemDetails.tier_basis === 'revenue') {
			itemDetails.rate = null;
		}

		if (itemDetails.rebate_basis === 'percentage' || itemDetails.rebate_basis === 'dollar') {
			itemDetails.selectedUom = '';
		}
	}

	public doRowRemovedMembersExclsnTab(e) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public doSavedMembersExclsnTab(e) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public doRowPreparedMembersExclsnTab(event: any) {
		if (event.rowType === "data" && event.data.tier_basis === 'notiers') {
			if (event.rowElement.querySelector(".dx-command-expand").firstChild && event.rowElement.querySelector(".dx-command-expand").firstChild.classList && event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove) {
				event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
				event.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
				const isExpanded = event.component.isRowExpanded(event.key);
				setTimeout(() => {
					if (isExpanded) {
						event.component.collapseRow(event.key);
					}
				}, 20);
			}
		}
	}

	public doRowValidatingMembersExclsnTab(event) {
		if (event['newData']) {
			let itemObject = JSON.parse(JSON.stringify(event['oldData']));
			let dataFiledsList = Object.keys(event['newData']);
			dataFiledsList.forEach(element => {
				itemObject[element] = event['newData'][element];
			});
			if (!this.doCheckDuplicateItemsForBuyinggroup(itemObject)) {
				// this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists', 'Info', { closeButton: true, tapToDismiss: true });
				const indexOfOldRow = this.dataSourceMembersLstExclsnTab.findIndex(i => i.member_item_guid === event.key);
				if (indexOfOldRow !== -1) {
					dataFiledsList.forEach(element => {
						event['newData'][element] = this.dataSourceMembersLstExclsnTab[indexOfOldRow][element];
					});
				}
			}
		}
	}

	public setCellValueItemColumnGridExclsnTab(rowData: any, value: any, currentRowData: any) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	// #endregion

	//  #region for Tiers Datagrid Methods

	public doInitNewRowTiersExclsnTab(event: any, element: any) {
		const itemObject = element.data;
		const tierObject: any = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null,
			listPrice: null,
			rebatefinalprice: null,
		};
		if (!this.enable_classification_for_rebate) {
			tierObject.items = itemObject.items.map((item) => {
				let listOfSpecificUoms = [];
				if (!item.allproducts) {
					const priceListNum = itemObject.pricelistnum || '';
					listOfSpecificUoms = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);
				}
				let defaultSelectedUom = '';
				let defaultListPrice = 0;
				if (listOfSpecificUoms.length > 0) {
					defaultSelectedUom = listOfSpecificUoms[0].code || '';
					defaultListPrice = listOfSpecificUoms[0].price || 0;
				}
				return {
					allproducts: item.allproducts,
					erp_primary_key: item.erp_primary_key,
					itemType: item.itemType,
					itemname: item.itemname,
					itemsku: item.itemsku,
					description: item.description,
					selectedUom: !item.allproducts && itemObject.rebate_basis === 'dollarperuom' ? defaultSelectedUom : '',
					rate: null,
					listPrice: +defaultListPrice.toFixed(2),
					rebatefinalprice: defaultListPrice,
				}
			});
		}
		if (this.enable_classification_for_rebate) {
			tierObject.items = itemObject.items.map((item) => {
				let defaultListPrice = 0;
				return {
					allproducts: item.allproducts,
					erp_primary_key: item.erp_primary_key,
					classguid: item.classguid,
					classid: item.classid,
					classname: item.classname,
					rate: null,
					listPrice: +defaultListPrice.toFixed(2),
					rebatefinalprice: defaultListPrice,
				}
			});
		}
		const itemDetails = element.data;
		tierObject.guid = this.guid.newGuid();
		event.data = tierObject;
	}

	public doRowRemovedTiersExclsnTab(e, element) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public doSavedTiersExclsnTab(e, element) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}

	public getItemsByTiersDetailsMembersExclsnTab(element) {
		return element.data.items || [];
	}

	public doEditorPreparingItemTierMembersExclsnTab(event, subParentData: any, parentData: any) {
		if (event && event['row']) {
			const itemObject = event.row.data;
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (parentData.data.rebate_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = [];
						const itemIndex = parentData.data.items.findIndex(item => item.erp_primary_key === itemObject.erp_primary_key);
						if (itemIndex !== -1) {
							const selectedItemObject = parentData.data.items[itemIndex];
							uomDataSource = this._RebateService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, selectedItemObject);
						}
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			// if (event.dataField === 'tier_basis') {
			// 	let listOfTierBasis = this.listOfTierBasis;
			// 	event.editorOptions.dataSource = listOfTierBasis;
			// }

			if (event.dataField === 'rebate_basis') {
				let listOfRebateBasis = this.listOfRebateBasis;
				if (itemObject.allproducts) {
					listOfRebateBasis = this.listOfRebateBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}

				if (itemObject['itemType']) {
					if (itemObject['itemType'] === 'OtherCharge' || itemObject['itemType'] === 'Service') {
						listOfRebateBasis = this.listOfRebateBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
					}
				}
				event.editorOptions.dataSource = listOfRebateBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.visible = true;
				if (itemObject.allproducts) {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				} else {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				}
			}
		}
	}

	public doRowUpdatedItemTierMembersExclsnTab(event, subParentData: any, parentData: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}
	public doRowRemovedItemTierMembersExclsnTab(event, subParentData: any, parentData: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}
	public doOnSavedItemTierMembersExclsnTab(event, subParentData: any, parentData: any) {
		if (this.editRebatesGuid) {
			setTimeout(() => {
				this.saveRebatesContract();
			}, 700);
		}
	}
	// #endregion

	// #endregion

	private convertToNumber(str: string) {
		return +str.replace(/[A-Za-z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
	}

	// #region for Document Sign process for Sales rebate

	// Method used to check Document Sign is enabled.
	private doCheckStatusBtnForDocSign() {
		this.isShowMultipleBtns = false;
		this.isShowContactEmployeeDrpDwn = false;
		this.isShowContractLifecycleManagementChkBox = false;
		this.isDisabledContractLifeCycleManagementChkbox = false;

		if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
			if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
				if (this.rebatesDetails.rebateType === 'sales') {
					this.isShowMultipleBtns = true;
					this.isShowContactEmployeeDrpDwn = true;
					if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS) {
						this.isShowContractLifecycleManagementChkBox = true;

						this.isShowMultipleBtns = false;
						this.isShowContactEmployeeDrpDwn = false;
						if (this.rebatesDetails.is_contract_lifecycle_management) {
							this.isShowMultipleBtns = true;
							this.isShowContactEmployeeDrpDwn = true;
							if (this.rebatesDetails.status === RebateStatusValEnum.EXPIRED || this.rebatesDetails.status === RebateStatusValEnum.INACTIVE) {
								this.isDisabledContractLifeCycleManagementChkbox = true;
							}
						}
					}

				}
			}
		}
	}

	// Method used to Set Buttons Array with different statuses.
	private setSubmitMultiPleBtns() {
		if (this.isShowMultipleBtns) {
			if (!this.editRebatesGuid) {
				this.listOfRebateStatusesBtn = [];
				const draftStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === this.rebatesDetails.status);
				const draftStatusIndexClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[draftStatusIndex]));
				draftStatusIndexClone.label = 'Save As Draft';
				this.listOfRebateStatusesBtn.push(draftStatusIndexClone);
			} else {
				this.listOfRebateStatusesBtn = [];

				if (this.rebatesDetails.status === RebateStatusValEnum.DRAFT) {
					const currentStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === this.rebatesDetails.status);
					const draftStatusClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[currentStatusIndex]));
					draftStatusClone.label = 'Save As Draft';
					this.listOfRebateStatusesBtn.push(draftStatusClone);
					const signatureReqStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.SIGNATURE_REQUESTED);
					const signatureReqClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[signatureReqStatusIndex]));
					signatureReqClone.label = 'Request Signature';
					this.listOfRebateStatusesBtn.push(signatureReqClone);
					// const inactiveStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.INACTIVE);
					// this.listOfRebateStatusesBtn.push(this.listOfRebateStatuses[inactiveStatusIndex]);
				}
				if (this.rebatesDetails.status === RebateStatusValEnum.SIGNATURE_REQUESTED) {
					const currentStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === this.rebatesDetails.status);
					const currentStatusClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[currentStatusIndex]));
					currentStatusClone.label = 'Request Signature';
					this.listOfRebateStatusesBtn.push(currentStatusClone);
					const draftStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.DRAFT);
					const draftClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[draftStatusIndex]));
					draftClone.label = 'Reset To Draft';
					this.listOfRebateStatusesBtn.push(draftClone);
					// const inactiveStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.INACTIVE);
					// this.listOfRebateStatusesBtn.push(this.listOfRebateStatuses[inactiveStatusIndex]);
				}
				if (this.rebatesDetails.status === RebateStatusValEnum.SIGNED) {
					const currentStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === this.rebatesDetails.status);
					const currentStatusClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[currentStatusIndex]));
					currentStatusClone.label = 'Submit';
					this.listOfRebateStatusesBtn.push(currentStatusClone);
					const draftStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.DRAFT);
					const draftClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[draftStatusIndex]));
					draftClone.label = 'Reset To Draft';
					this.listOfRebateStatusesBtn.push(draftClone);
					// const activeStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.ACTIVE);
					// this.listOfRebateStatusesBtn.push(this.listOfRebateStatuses[activeStatusIndex]);
					// const inactiveStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.INACTIVE);
					// this.listOfRebateStatusesBtn.push(this.listOfRebateStatuses[inactiveStatusIndex]);
				}
				if (this.rebatesDetails.status === RebateStatusValEnum.ACTIVE) {
					const currentStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === this.rebatesDetails.status);
					const currentStatusClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[currentStatusIndex]));
					currentStatusClone.label = 'Submit';
					this.listOfRebateStatusesBtn.push(currentStatusClone);
					const draftStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.DRAFT);
					const draftClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[draftStatusIndex]));
					draftClone.label = 'Reset To Draft';
					this.listOfRebateStatusesBtn.push(draftClone);
				}
				if (this.rebatesDetails.status === RebateStatusValEnum.INACTIVE) {
					const currentStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === this.rebatesDetails.status);
					this.listOfRebateStatusesBtn.push(this.listOfRebateStatuses[currentStatusIndex]);
					// const activeStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.ACTIVE);
					// this.listOfRebateStatusesBtn.push(this.listOfRebateStatuses[activeStatusIndex]);
					const draftStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.DRAFT);
					const draftClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[draftStatusIndex]));
					draftClone.label = 'Reset To Draft';
					this.listOfRebateStatusesBtn.push(draftClone);
				}
				if (this.rebatesDetails.status === RebateStatusValEnum.EXPIRED) {
					const currentStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === this.rebatesDetails.status);
					this.listOfRebateStatusesBtn.push(this.listOfRebateStatuses[currentStatusIndex]);
					const draftStatusIndex = this.listOfRebateStatuses.findIndex(rs => rs.value === RebateStatusValEnum.DRAFT);
					const draftClone = JSON.parse(JSON.stringify(this.listOfRebateStatuses[draftStatusIndex]));
					draftClone.label = 'Copy to New Contract';
					this.listOfRebateStatusesBtn.push(draftClone);
				}
			}
		}
	}

	public getSubmitBtnText() {
		const buttonIndex = this.listOfRebateStatusesBtn.findIndex(rs => rs.value === this.rebatesDetails.status);
		if (buttonIndex !== -1) {
			return this.listOfRebateStatusesBtn[buttonIndex].label;
		}
		return '';
	}

	// Method used to Click
	public doSubmitBtnClick(e) {
		switch (e.selectedItem.value) {
			case RebateStatusValEnum.DRAFT:
				this.saveRebatesContract();
				break;
			case RebateStatusValEnum.SIGNATURE_REQUESTED:
				this.saveRebatesContract();
				break;
			case RebateStatusValEnum.SIGNED:
				this.saveRebatesContract();
				break;
			case RebateStatusValEnum.ACTIVE:
				this.saveRebatesContract();
				break;
			default:
				break;
		}
	}
	// Method used to Click
	public doChangeRebateStatus(e) {
		const oldStatus = this.rebatesDetails.status;
		const newStatus = e.itemData.value;
		if (newStatus === oldStatus) {
			return;
		}
		switch (e.itemData.value) {
			case RebateStatusValEnum.DRAFT:
				if (oldStatus === RebateStatusValEnum.SIGNED || oldStatus === RebateStatusValEnum.SIGNATURE_REQUESTED || oldStatus === RebateStatusValEnum.ACTIVE) {
					const ixCustomDialogOptions: any = {
						popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
						showTitle: false,
						subtitle: 'Reset to draft?',
						message: 'This will reset the contract to Draft status and notify the signer the contract has been cancelled. Do you want to proceed ?',
					};
					this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
						if (result) {
							this.doUpdateRebateStatus(RebateStatusValEnum.DRAFT);
						}
					});
				}
				if (oldStatus === RebateStatusValEnum.EXPIRED) {
					this.doCopyToContract();
				}

				break;
			case RebateStatusValEnum.SIGNATURE_REQUESTED:
				if (this.contractLifecycleManagement === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagement === ContractLifeCycleValEnum.ALWAYS_ON) {
					if ((this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') && this.rebatesDetails.rebateType === 'sales') {
						if (!this.rebatesDetails.selectContactEmployee) {
							if (oldStatus === RebateStatusValEnum.DRAFT && this.rebatesDetails.is_contract_lifecycle_management) {
								this._ToastrService.info('Contact Person is Required.', 'Info', { closeButton: true, tapToDismiss: true });
								this.rebatesDetails.status = '';
								setTimeout(() => {
									this.rebatesDetails.status = oldStatus;
								}, 100);
								return false;
							}
						}
						if (this.rebatesDetails.selectContactEmployee) {
							if (oldStatus === RebateStatusValEnum.DRAFT && this.rebatesDetails.is_contract_lifecycle_management) {
								if (this.rebatesDetails.selectContactEmployeeObj && !this.rebatesDetails.selectContactEmployeeObj.e_mail) {
									this._ToastrService.info('Please select a Contact with a valid email address to digitally sign this contract.', 'Info', { closeButton: true, tapToDismiss: true });
									this.rebatesDetails.status = '';
									setTimeout(() => {
										this.rebatesDetails.status = oldStatus;
									}, 100);
									return false;
								}
							}
						}
					}
				}
				this.doUpdateRebateStatus(RebateStatusValEnum.SIGNATURE_REQUESTED);
				break;
			default:
				break;
		}
	}

	// Method used to Update Rebate Status
	private doUpdateRebateStatus(rebateStatus: string) {
		const rebatesDetails = JSON.parse(JSON.stringify(this.rebatesDetails));
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('guid', this.rebatesDetails.guid);
		formData.append('updateData', JSON.stringify({ 'status': rebateStatus }));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.rebatesDetails.status = rebateStatus;
						this._ToastrService.success(response.message || 'Rebate Contract updated Successfully', 'Updated', { closeButton: true, tapToDismiss: true });
						this.callUpdateSettlementPeriod();
						// Disable rebates inputbox
						// this.disableRebatesInputFields();
						this.setSubmitMultiPleBtns();
						this.setEnabledDisabledAll();
					} else {
						this.rebatesDetails.status = '';
						setTimeout(() => {
							this.rebatesDetails.status = rebatesDetails.status;
						}, 50);
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this.rebatesDetails.status = '';
					setTimeout(() => {
						this.rebatesDetails.status = rebatesDetails.status;
					}, 50);
					this._ToastrService.info(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this.rebatesDetails.status = '';
				setTimeout(() => {
					this.rebatesDetails.status = rebatesDetails.status;
				}, 300);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to copy contract and redirect to Rebate contracts list page
	private doCopyToContract() {
		const rebatesDetails = JSON.parse(JSON.stringify(this.rebatesDetails));
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.COPY_CONTRACT);
		formData.append('contracttype', 'rebatescontracts');
		formData.append('contractguid', this.rebatesDetails.guid);
		if (this.copyToContractSbsn) {
			this.copyToContractSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.copyToContractSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this._ToastrService.success(response.message || 'Rebate Contract copied Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
						setTimeout(() => {
							if (this.actionFrom === 'rebates') {
								this._Router.navigate(['/contracts/rebateslist']);
							}
							if (this.actionFrom === 'crms') {
								// this._Router.navigate(['/crms/customerdetails']);
								this._Router.navigate(['customerdetails'], { relativeTo: this._ActivatedRoute });
							}
						}, 500);
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this.rebatesDetails.status = '';
				setTimeout(() => {
					this.rebatesDetails.status = rebatesDetails.status;
				}, 50);
			}, error: error => {
				this._LoaderService.hide();
				this.rebatesDetails.status = '';
				setTimeout(() => {
					this.rebatesDetails.status = rebatesDetails.status;
				}, 50);
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Download Signed Contract
	public doDownloadSignedContract() {
		if (!this.rebatesDetails.guid) {
			return;
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DOWNLOAD_SIGNED_PDF);
		formData.append('rebate_guid', this.rebatesDetails.guid);
		if (this.downloadSignedContractSbsn) {
			this.downloadSignedContractSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.downloadSignedContractSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						if (response.data && response.data.pdfLink) {
							const link = document.createElement('a');
							link.href = response.data.pdfLink;
							link.download = response.data.pdfName;
							link.target = '_blank';
							link.dispatchEvent(new MouseEvent('click'));
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.teisDisabledContactPersonxt || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doChangeContractLifeCycleMgmt(e) {
		this.rebatesDetails.is_contract_lifecycle_management = e.value;
		if (!e.value) {
			this.isShowMultipleBtns = false
			this.isShowContactEmployeeDrpDwn = false;
			this.rebatesDetails.status = RebateStatusValEnum.ACTIVE;
			this.setEnabledDisabledAll();
		} else {
			this.isShowMultipleBtns = true;
			this.isShowContactEmployeeDrpDwn = true;
			this.rebatesDetails.status = RebateStatusValEnum.DRAFT;
			this.doCheckStatusBtnForDocSign();
			this.setSubmitMultiPleBtns();
			this.setEnabledDisabledAll();
		}
	}
	// #endregion

	// #region for Called from CRM
	private preInitForCrm() {
		const clientDetails = this._CrmsService.getClientDetails();
		this.rebatesDetails.rebateType = 'sales';
		this.fetchedListOfClients();
		this.setColumnForCrmGrid();
		if (clientDetails && clientDetails.code) {
			// setTimeout(() => {
			this.aSelKeysCustomers = [];
			this.aSelKeysCustomers = [clientDetails.code];
			// }, 4000);
			const contractTypeIndex = this.listOfRebateTypes.findIndex(rt => rt.value === 'buyinggroup');
			if (contractTypeIndex !== -1) {
				this.listOfRebateTypes[contractTypeIndex].disabled = true;
			}

			const rebateTypeIndex = this.listOfRebateSubTypes.findIndex(rt => rt.value === 'purchasing');
			if (rebateTypeIndex !== -1) {
				this.listOfRebateSubTypes[rebateTypeIndex].disabled = true;
			}
			this.isDisabledCustomerName = true;
			this.aSelKeysCustomersDetails.push(clientDetails);
			this.setTextForCustomerDrpDwn();
			this.doSelectCustomer();
		}
	}
	//#endregion	

	// #region for Upload Rebate For Exclusion Items
	private doOpenUploadRebateExclusionItemsPopup() {
		this.isUploadRebateExclusionItemsPopup = false;
		setTimeout(() => {
			this.isUploadRebateExclusionItemsPopup = true;
		}, 300);
	}

	public doCloseUploadRebateExclusionItemsPopup(event: any) {
		if (event.isClickOnCloseBtn) {
			return;
		}
		this.isUploadRebateExclusionItemsPopup = false;
		if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
			this.csvResponse = event;
			if (event.listOfCsvRows && event.listOfCsvRows.length > 0) {
				const listOfCsvRow = event.listOfCsvRows;
				setTimeout(() => {
					this.listOfItemsAndGroupsItmGrpTab = [];
					this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
				}, 50);
				listOfCsvRow.forEach(rowEle => {
					rowEle.guid = this.guid.newGuid();
					rowEle.description = '';
					rowEle.isItemMatched = false;
					rowEle.isItemUomMatched = 'unmatched';
					rowEle.isItemModifiedUom = '';
					rowEle.isItemModifiedPrice = false;
					rowEle.isItemModifiedPriceLbl = null;
				});
				this.listOfCsvRowForItmGrpTab = listOfCsvRow;
				this.doMatchExcelItemsWithOrignItems();
				this.fetchedAllItemsItmGrpTab();
			}
		}
		if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			this.csvResponse = event;
			if (event.listOfCsvRows && event.listOfCsvRows.length > 0) {
				const listOfCsvRow = event.listOfCsvRows;
				setTimeout(() => {
					this.listOfMembersItemsExclsnTab = [];
					this.dataSourceMembersLstExclsnTab = this.listOfMembersItemsExclsnTab;
				}, 50);
				listOfCsvRow.forEach(rowEle => {
					rowEle.guid = this.guid.newGuid();
					rowEle.description = '';
					rowEle.isMemberMatched = false;
					rowEle.isItemMatched = false;
					rowEle.isItemUomMatched = 'unmatched';
					rowEle.isItemModifiedUom = '';
					rowEle.isItemModifiedPrice = false;
					rowEle.isItemModifiedPriceLbl = null;
				});
				this.listOfCsvRowForItmGrpTab = listOfCsvRow;
				this.matchedMemberItemWithCsvFile();
				this.fetchedItemsExclsnTab();
			}
		}
	}

	public doCustomizeTextTierMinMax(cellInfo: any, parentData: any, which_tier: string) {
		if (which_tier === 'maxtier') {
			if (!cellInfo) {
				return cellInfo;
			} else if (cellInfo === '-') {
				return cellInfo;
			} else {
				if (parentData.data.tier_basis === 'volume') {
					return cellInfo || '0';
				} else if (parentData.data.tier_basis === 'revenue') {
					return this._CustomCurrencyPipe.transform(+cellInfo || 0, this.customCurrencyOption);
				} else {
					return cellInfo;
				}
			}
		}
		if (which_tier === 'mintier') {
			if (parentData.data.tier_basis === 'volume') {
				return cellInfo || 0;
			} else if (parentData.data.tier_basis === 'revenue') {
				return this._CustomCurrencyPipe.transform(+cellInfo || 0, this.customCurrencyOption);
			} else {
				return cellInfo;
			}
		}
	}
	// #endregion

	// #region for Tier Mode
	public doChangeTierMode(event: any) {
		if (event.value) {
			this.doShowHideTierAccrualPeriodsDropDown();
		}
	}

	private doShowHideTierAccrualPeriodsDropDown() {
		this.isShowTierAccrualPeriodField = false;
		if (this.rebatesDetails.tier_mode === 'marginal') {
			this.isShowTierAccrualPeriodField = true;
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.CUSTOM) {
				this.isShowTierAccrualPeriodField = false;
			}
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.AUTOPAYMENT) {
				this.isShowTierAccrualPeriodField = false;
			}
		}
		this.doHideShowTierAccrualPeriodsDropDownOptions();
	}

	private doHideShowTierAccrualPeriodsDropDownOptions() {
		if (this.rebatesDetails.tier_mode === 'marginal') {
			let removedOptions = [];
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.WEEKLY) {
				removedOptions = [];
			}
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
				removedOptions = ['weekly'];
			}
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
				removedOptions = ['weekly', 'monthly'];
			}
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
				removedOptions = ['weekly', 'monthly', 'quarterly'];
			}
			if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
				removedOptions = ['weekly', 'monthly', 'quarterly', 'halfYearly'];
			}

			this.listOfTierAccrualPeriods.forEach(period => {
				const optionIndex = removedOptions.findIndex(opt => opt === period.value);
				if (optionIndex !== -1) {
					period.visible = false;
				} else {
					period.visible = true;
				}
			});
			this.setDefaultTierAccrualPeriod();
		}
	}

	private setDefaultTiersInclude() {
		if (this.isAddMode) {
			if (this.enable_profit_on_document_header) {
				this.rebatesDetails.tiers_include = 'all_items_in_contract';
			} else {
				this.rebatesDetails.tiers_include = 'only_items_in_tier';
			}
		}
	}

	private setDefaultTierAccrualPeriod() {
		if (this.rebatesDetails.settlementPeriod === 'weekly') {
			this.rebatesDetails.tier_accrual_period = 'weekly';
		} else if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
			this.rebatesDetails.tier_accrual_period = 'monthly';
		} else if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
			this.rebatesDetails.tier_accrual_period = 'quarterly';
		} else if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
			this.rebatesDetails.tier_accrual_period = 'halfYearly';
		} else if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
			this.rebatesDetails.tier_accrual_period = 'yearly';
		} else if (this.rebatesDetails.settlementPeriod === SettlementPeriodsValEnum.AUTOPAYMENT) {
			this.rebatesDetails.tier_accrual_period = 'weekly';
		} else {
			this.rebatesDetails.tier_accrual_period = '';
		}
	}

	public doTiersTypeChanged(event: any) {
		const newTiersType = event.value;
		const currentTiersType = this.rebatesDetails.tiers_type;
		// const newRebateFor = event.target.value;
		if (currentTiersType === newTiersType) {
			return false;
		}
		let isShowConfirmationPopup = false;
		let confirmMessage = '';
		if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			if (this.dataSourceMembersLstExclsnTab && this.dataSourceMembersLstExclsnTab.length > 0) {
				confirmMessage = 'Your existing Items list and Rules will replaced.';
				isShowConfirmationPopup = true;
			}
		}
		if (this.rebatesDetails.individualOrGroup === 'individual' || this.rebatesDetails.individualOrGroup === 'growth_incentive') {
			if (this.dataSourceItemsAndGroupsLstItmGrpTab && this.dataSourceItemsAndGroupsLstItmGrpTab.length > 0) {
				confirmMessage = 'Your existing Item(s) / Group(s) list and Rules will replaced.';
				isShowConfirmationPopup = true;
			}
		}

		if (isShowConfirmationPopup) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
				showTitle: false,
				subtitle: 'Change Tiers Type?',
				message: 'Are you sure you want to continue? ' + confirmMessage,
			};
			this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
				this.rebatesDetails.tiers_type = '';
				if (result) {
					this.listOfItemsAndGroupsItmGrpTab = [];
					this.listOfMembersItemsExclsnTab = [];
					setTimeout(() => {
						this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
						this.dataSourceMembersLstExclsnTab = this.listOfMembersItemsExclsnTab;
					}, 50);
					this.rebatesDetails.tiers_type = newTiersType;
				} else {
					setTimeout(() => {
						this.rebatesDetails.tiers_type = currentTiersType;
					}, 20);
				}
			});
		} else {
			this.rebatesDetails.tiers_type = newTiersType;
		}
		this.doDisabledEnabledImportExcelBtn();
	}

	private doDisabledEnabledImportExcelBtn() {
		setTimeout(() => {
			if (this.rebatesDetails.individualOrGroup === 'individual') {
				if (this.rebatesDetails.tiers_type === 'multiple_items') {
					this.isDisabledImportExcelCsv = true;
					this.btnImportInstance.option({
						disabled: true
					});
				} else {
					this.isDisabledImportExcelCsv = false;
					this.btnImportInstance.option({
						disabled: false
					});
				}
			}
		}, 250);
	}
	//#endregion

	// #region for Contract Type: Distribution
	private doEnabledDisabledRebateType() {
		this.currentAgreementTab = '';
		setTimeout(() => {
			const purchaseRebateTypeIndex = this.listOfRebateSubTypes.findIndex(rebateType => rebateType.value === 'purchasing');
			const salesRebateTypeIndex = this.listOfRebateSubTypes.findIndex(rebateType => rebateType.value === 'sales');
			this.listOfRebateSubTypes[purchaseRebateTypeIndex].disabled = false;
			this.isShowPricelistDrpDwn = false;
			this.isShowDifferentialPricelistDrpDwn = false;
			// this.listOfRebateSubTypes[purchaseRebateTypeIndex].visible = true;
			if (this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') {
				this.currentAgreementTab === 'itemsOrgroups';
				this.isShowPricelistDrpDwn = true;
				if (this.rebatesDetails.individualOrGroup === 'distribution_differential') {
					this.isShowDifferentialPricelistDrpDwn = true;
				}
				this.listOfRebateSubTypes[purchaseRebateTypeIndex].disabled = true;
				// this.listOfRebateSubTypes[purchaseRebateTypeIndex].visible = false;
				this.rebatesDetails.rebateType = this.listOfRebateSubTypes[salesRebateTypeIndex].value;
			}
		}, 30);
	}

	private doShowColumnPricelistDrpDwn() {
		this.isShowPricelistCodeColumn = false;
		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			this.isShowPricelistCodeColumn = true;
		}
	}

	public doPriceListOptionChangedDrpDwn(e) {
		if (e.name === "value") {
			this.isPricelistGridBoxOpened = false;
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doPricelistClosedDrpDwn(e) {
		this.refPricelistDrpDwn.instance.clearFilter('row');
	}

	public doDisplayExprPricelistName(pricelistDetails: any) {
		if (this.isShowPricelistCodeColumn) {
			return (pricelistDetails && pricelistDetails.pricelistno) ? pricelistDetails.pricelistno + ' - ' + pricelistDetails.pricelistname : '';
		} else {
			return (pricelistDetails && pricelistDetails.pricelistname) ? pricelistDetails.pricelistname : '';
		}
	}

	// Method used to set string on item dropdown
	private setTextForPricelistDrpDwn() {
		if (this.aSelKeysPricelistDetails.length > 0) {
			if (this.isShowPricelistCodeColumn) {
				this.sPricelistLbl = this.aSelKeysPricelistDetails[0].pricelistno + ' - ' + this.aSelKeysPricelistDetails[0].pricelistname;
			} else {
				this.sPricelistLbl = this.aSelKeysPricelistDetails[0].pricelistname || '';
			}
		}
	}

	public doPicelistSelectedChangedDg(e: any) {
		this.aSelKeysPricelistDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.aSelKeysPricelistDetails.push(e.selectedRowsData[i]);
			}
		}
		this.setTextForPricelistDrpDwn();
		this.doSelectPricelist();
	}

	private doSelectPricelist() {
		if (this.aSelKeysPricelistDetails && this.aSelKeysPricelistDetails.length > 0) {
			this.rebatesDetails.selectPricelistno = this.aSelKeysPricelistDetails[0].pricelistno;
			this.rebatesDetails.selectPricelistName = this.aSelKeysPricelistDetails[0].pricelistname;
			this.rebatesDetails.selectPricelistObj = this.aSelKeysPricelistDetails[0];
		}
	}

	// Method used to fetched Items List
	private getListOfPricelistDataSource() {
		const viewFields = ['unique_id', 'pricelistno', 'pricelistname'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.PRICELISTS,
			view_fields: JSON.stringify(viewFields)
		};
		this.listOfPriceLists = AspNetData.createStore({
			key: 'pricelistno',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to Clear filter after closing dropdown.
	public doDifferentialPricelistClosedDrpDwn(e) {
		this.refDifferentialPricelistDrpDwn.instance.clearFilter('row');
	}

	public doDifferentialPriceListOptionChangedDrpDwn(e) {
		if (e.name === "value") {
			this.isDifferentialPricelistGridBoxOpened = false;
		}
	}

	public doDifferentialPicelistSelectedChangedDg(e: any) {
		this.aSelKeysDifferentialPricelistDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.aSelKeysDifferentialPricelistDetails.push(e.selectedRowsData[i]);
			}
		}
		this.setTextForDifferentialPricelistDrpDwn();
		this.doSelectDifferentialPricelist();
	}

	// Method used to set string on item dropdown
	private setTextForDifferentialPricelistDrpDwn() {
		if (this.aSelKeysDifferentialPricelistDetails.length > 0) {
			if (this.isShowPricelistCodeColumn) {
				this.sPricelistLbl = this.aSelKeysDifferentialPricelistDetails[0].pricelistno + ' - ' + this.aSelKeysDifferentialPricelistDetails[0].pricelistname;
			} else {
				this.sPricelistLbl = this.aSelKeysDifferentialPricelistDetails[0].pricelistname || '';
			}
		}
	}

	private doSelectDifferentialPricelist() {
		if (this.aSelKeysDifferentialPricelistDetails && this.aSelKeysDifferentialPricelistDetails.length > 0) {
			this.rebatesDetails.selectDifferentialPricelistno = this.aSelKeysDifferentialPricelistDetails[0].pricelistno;
			this.rebatesDetails.selectDifferentialPricelistName = this.aSelKeysDifferentialPricelistDetails[0].pricelistname;
			this.rebatesDetails.selectDifferentialPricelistObj = this.aSelKeysDifferentialPricelistDetails[0];
		}
	}

	private getFeatureCustomFieldsForItems() {
		const listOfFeatureCustomFieldsName: string[] = [];
		const listOfFeatureCustomFields: any[] = [];
		const listOfFeaturesCustomFields = this.listOfFeaturesCustomFields.filter(field => field.external_entity_field === true && field.entity_type === ServerEntity.ITEMS && field.section === "HEADER" && field.is_contract_field_use_for_creation === true);
		listOfFeaturesCustomFields.forEach(field => {
			if (field.display) {
				listOfFeatureCustomFieldsName.push(field.ix_custom_field);
				listOfFeatureCustomFields.push(field);
			}
		});
		return {
			listOfFeatureCustomFieldsName: listOfFeatureCustomFieldsName,
			listOfFeatureCustomFields: listOfFeatureCustomFields
		};
	}

	private getFeatureCustomFieldsForEndUsers() {
		const listOfFeatureCustomFieldsName: string[] = [];
		const listOfFeatureCustomFields: any[] = [];
		const listOfFeaturesCustomFields = this.listOfFeaturesCustomFields.filter(field => field.external_entity_field === true && field.entity_type === ServerEntity.ENDUSERS && field.section === "SHIPTOADDRESS");
		listOfFeaturesCustomFields.forEach(field => {
			if (field.display) {
				listOfFeatureCustomFieldsName.push(field.ix_custom_field);
				listOfFeatureCustomFields.push(field);
			}
		});
		return {
			listOfFeatureCustomFieldsName: listOfFeatureCustomFieldsName,
			listOfFeatureCustomFields: listOfFeatureCustomFields
		};
	}

	private doAddDynamicFeatureCustomColumns(event) {
		if (this.rebatesDetails.individualOrGroup === 'distribution' || this.rebatesDetails.individualOrGroup === 'distribution_differential') {
			const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
			const customFieldDataList = [];
			const listOfFeaturesCustomFields = featureCustomFieldsObj.listOfFeatureCustomFields;
			if (listOfFeaturesCustomFields && listOfFeaturesCustomFields.length > 0) {
				for (let i = 0; i < listOfFeaturesCustomFields.length; i++) {
					const customField = listOfFeaturesCustomFields[i];
					const customFieldDataObj = {
						dataField: customField.ix_custom_field,
						caption: customField.custom_field_title,
						dataType: customField.custom_field_output_format,
						visible: customField.display,
						showInColumnChooser: customField.display,
						allowHeaderFiltering: false,
						allowFiltering: false,
						allowEditing: true
						// visibleIndex: tableColLen + i,
					};

					if (customField.custom_field_output_format === 'text' || customField.custom_field_output_format === 'string') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = true;
						customFieldDataObj.dataType = 'string';
						customFieldDataObj['encodeHtml'] = false;
						if (customField.custom_field_values && customField.custom_field_values.length > 0) {
							customFieldDataObj.allowHeaderFiltering = true;
							customFieldDataObj.allowFiltering = false;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key", allowClearing: true };
						}
					}

					if (customField.custom_field_type === 'date') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = true;
						customFieldDataObj['format'] = 'yyyy-MM-dd';
					}
					//if (responseData[i].ix_custom_field === 'over_pricelist_amount' || responseData[i].ix_custom_field === 'custom_doctotal' || responseData[i].ix_custom_field === 'base_profit_amount') {
					if (customField.custom_field_output_format === 'amount') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = false;
						customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
					}
					if (customField.custom_field_output_format === 'percentage') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = false;
						customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
					}
					//}
					if (customField.custom_field_type === 'object') {
						if (customField.custom_field_output_format === 'string') {
							customFieldDataObj.allowHeaderFiltering = customField.custom_field_values && customField.custom_field_values.length > 0 ? true : false;
							customFieldDataObj.allowFiltering = (customField.custom_field_values && customField.custom_field_values.length > 0) ? false : true;
							customFieldDataObj.dataType = 'string';
							customFieldDataObj['encodeHtml'] = false;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key", allowClearing: true };
						}
						if (customField.custom_field_output_format === 'object') {
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = true;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key" };
							customFieldDataObj.dataField = customField.ix_custom_field + '.refName';
						}
						if (customField.custom_field_output_format === 'amount') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = false;
							customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
						}
						if (customField.custom_field_output_format === 'percentage') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = false;
							customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
						}
					}
					customFieldDataList.push(customFieldDataObj);
				}
				const listOfColumns = this.itemsAndGroupsitmGrpTabDataGridRef.instance.getVisibleColumns();
				customFieldDataList.forEach(element => {
					const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
					if (columnIndex === -1) {
						this.itemsAndGroupsitmGrpTabDataGridRef.instance.addColumn(element);
					}
				});
			}
		}
	}

	public doCustomizeTextToPercent(cellInfo) {
		return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
	}

	private doSetListOfEndUserColumnsForDG() {
		this.columnsForListOfEndUserItemsItmGrpTabDg = [
			{ dataField: 'code', caption: 'Code', visible: true },
			{ dataField: 'companyname', caption: 'Company', visible: true },
			{ dataField: 'addressname', caption: 'Shipping Address Name', visible: true }
		];
	}

	private doFetchListOfEndUsersDataSource() {
		// const viewFields = ['guid','code','companyname','pricelistnum','pricelistname','taxexempt','taxcode','addresstype','addressname','addressname2','addressname3'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ENDUSERS_WITH_SHIPPING_DETAILS,
			// view_fields: JSON.stringify(viewFields)
		};

		this.listOfEndUserItmGrpTabDsDrpDwn = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public doDisplayExprEndUserItmGrpTabText(event: any) {
		let listOfTexts: any[] = [];
		if (this.isCheckedAllEndUserItmGrpTabDrpDwnChkBox) {
			listOfTexts.push('All End-User');
		}
		if (this.aSelKeysEndUserItmGrpTabDg && this.aSelKeysEndUserItmGrpTabDg.length > 0) {
			listOfTexts.push(this.aSelKeysEndUserItmGrpTabDg.length + ' End-User(s)');
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}


	// Method used to Clear filter of Datagrid when open dropdown
	public doOpenedDrpdwnEndUserItmGrpTab(e) {
		this.refEndUserLstItmGrpTabDrpDwn.instance.clearFilter('row');
	}

	// Method used to change values event of item dropdown
	public doValueChangedDrpDwnEndUserItmGrpTab(event) {
		if (event.value === null) {
			this.sEndUserLblItmGrpTabDrpDwn = '';
			this.aSelEndUserDetailsItmGrpTabDg = [];
			this.aSelKeysEndUserItmGrpTabDg = [];
			this.aEndUserItmGrpTabDrpDwnTagBox = [];
			this.isCheckedAllEndUserItmGrpTabDrpDwnChkBox = false;
		}
	}

	// Method used to change values of Checkbox above item datagrid in dropdown
	public doChangeAllEndUserItmGrpTabDrpDwnChkBox(event) {
		this.setTextForEndUserItmGrpTabDrpDwn();
	}

	// Method used to set string on item dropdown
	private setTextForEndUserItmGrpTabDrpDwn() {
		this.sEndUserLblItmGrpTabDrpDwn = '';
		if (this.aSelKeysEndUserItmGrpTabDg.length <= 0 && !this.isCheckedAllEndUserItmGrpTabDrpDwnChkBox) {
			this.sEndUserLblItmGrpTabDrpDwn = '';
			return;
		}
		if (this.isCheckedAllEndUserItmGrpTabDrpDwnChkBox) {
			this.sEndUserLblItmGrpTabDrpDwn = this.sEndUserLblItmGrpTabDrpDwn + 'All End-User Selected';
		}
		if (this.aSelKeysEndUserItmGrpTabDg.length >= 0) {
			this.sEndUserLblItmGrpTabDrpDwn = this.sEndUserLblItmGrpTabDrpDwn + this.aSelKeysEndUserItmGrpTabDg.length + ' End-User(s) Selected';
		}

	}

	// Method used to Set tagbox values to show selected items
	private setTagboxEndUserItmGrpTab(selectListOfItems: any[] = []) {
		this.aEndUserItmGrpTabDrpDwnTagBox = [];
		for (let i = 0; i < selectListOfItems.length; i++) {
			this.aEndUserItmGrpTabDrpDwnTagBox.push(selectListOfItems[i].addressname);
		}
	}

	// Method used to changed value of items tag box
	public doValueChangeEndUserItmGrpTabTagBox(event) {
		const listTags = event.value || [];
		if (event.value.length !== event.previousValue.length) {
			for (let i = 0; i < this.aSelEndUserDetailsItmGrpTabDg.length; i++) {
				const tagIndex = listTags.findIndex(tag => tag === this.aSelEndUserDetailsItmGrpTabDg[i].addressname);
				if (tagIndex === -1) {
					const itemKeyIndex = this.aSelKeysEndUserItmGrpTabDg.findIndex(itemKey => itemKey === this.aSelEndUserDetailsItmGrpTabDg[i].code);
					this.aSelKeysEndUserItmGrpTabDg.splice(itemKeyIndex, 1);
					this.aSelEndUserDetailsItmGrpTabDg.splice(i, 1);
				}
			}
			this.setTextForEndUserItmGrpTabDrpDwn();
		}
	}

	// Method used to select item of datagrid into dropdown
	public doSelChangedEndUserItmGrpTabDg(event) {
		this.aSelEndUserDetailsItmGrpTabDg = [];
		if (event.selectedRowsData && event.selectedRowsData.length > 0) {
			for (let i = 0; i < event.selectedRowsData.length; i++) {
				this.aSelEndUserDetailsItmGrpTabDg.push(event.selectedRowsData[i]);
			}
		}
		this.setTagboxEndUserItmGrpTab(event.selectedRowsData);
		this.setTextForEndUserItmGrpTabDrpDwn();
	}

	//#endregion

	// #region for Member Mode

	public doValueChangedMemberModes(event: any) {
		const newMemberMode = event.value;
		const currentMemberMode = this.rebatesDetails.member_mode;
		// const newRebateFor = event.target.value;
		if (currentMemberMode === newMemberMode) {
			return false;
		}
		let isShowConfirmationPopup = false;
		let confirmMessage = '';
		if (this.rebatesDetails.individualOrGroup === 'buyinggroup') {
			if (this.dataSourceMembersLstExclsnTab && this.dataSourceMembersLstExclsnTab.length > 0) {
				confirmMessage = 'Your existing Items list and Rules will replaced.';
				isShowConfirmationPopup = true;
			}
		}

		if (isShowConfirmationPopup) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
				showTitle: false,
				subtitle: 'Change Member Mode?',
				message: 'Are you sure you want to continue? ' + confirmMessage,
			};
			this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
				this.rebatesDetails.member_mode = '';
				if (result) {
					this.listOfItemsAndGroupsItmGrpTab = [];
					this.listOfMembersItemsExclsnTab = [];
					setTimeout(() => {
						this.dataSourceItemsAndGroupsLstItmGrpTab = this.listOfItemsAndGroupsItmGrpTab;
						this.dataSourceMembersLstExclsnTab = this.listOfMembersItemsExclsnTab;
					}, 50);
					this.rebatesDetails.member_mode = newMemberMode;
				} else {
					setTimeout(() => {
						this.rebatesDetails.member_mode = currentMemberMode;
					}, 20);
				}
			});
		} else {
			this.rebatesDetails.member_mode = newMemberMode;
		}
		this.doDisabledEnabledImportExcelBtn();
	}
	//#endregion


	// #region for State Persistent
	public doLoadRebateItemsState() {
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveRebateItemsState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				rebateType: this.rebatesDetails.rebateType || '',
				tiers_type: this.rebatesDetails.tiers_type || '',
				member_mode: this.rebatesDetails.member_mode || '',
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}
	// #endregion

	// #region for Close Current Settlement Confirm Popup

	private doOpenCloseCurrentSettlementPeriodPopup() {
		this.isViewCloseCurrentSettlementPeriodPopup = false;
		setTimeout(() => {
			this.isViewCloseCurrentSettlementPeriodPopup = true;
		}, 150);
	}

	public doCloseCurrentStlmtPeriodPopup(e: any) {
		if (e.isClickOnOkayBtn) {
			this.document_date_from_confirm_popup = e.document_date;
			this.isViewCloseCurrentSettlementPeriodPopup = false;
			this.doApplyCloseCurrentSettlementPeriod();
		} else {
			this.isViewCloseCurrentSettlementPeriodPopup = false;
		}
	}
	// #endregion

	//#region Other Currency

	public addPrimaryCurrency() {
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('currency_code')) {
			let currency_code = this._LoginService.loginUser.account_detail.currency_code
			if (currency_code) {
				if (this.listOfCurrency && this.listOfCurrency.length > 0) {
					const currencyIndex = this.listOfCurrency.findIndex(currency => currency.currency_code === currency_code);
					if (currencyIndex !== -1) {
						if (this._LoginService.loginUser.account_detail.hasOwnProperty('erp_currency_name')) {
							this.listOfCurrency[currencyIndex]['erp_currency_name'] = this._LoginService.loginUser.account_detail.erp_currency_name;
						}
						this.listOfOtherCurrency.push(this.listOfCurrency[currencyIndex]);
					}
				}
			}
		}
	}

	public currencyLookUpContentReady(e) {
		e.component.content().classList.add("ix-currencyContent");
	}
	public displayExprForCurrency(currency: any) {
		return currency ? currency.currency_name + ' - ' + currency.currency_code + ' - ' + currency.currency_symbol : '';
		// return currency ? currency : '';
	}

	public doSelectionChangedForCurrency(e) {
		this.rebatesDetails.currency_code = e.value;
		this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.rebatesDetails);
		this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
		if (this.enable_classification_for_rebate) {
			this.listOfRebateBasis = this._RebateService.getRebateBasisForClasses(this.globalCurrencySymbol);
		} else {
			this.listOfRebateBasis = this._RebateService.getRebateBasis(this.globalCurrencySymbol);
		}
		if (this.editRebatesGuid) {
			this.saveRebatesContract();
			this.currentAgreementTab = 'rules';
			setTimeout(() => {
				this.currentAgreementTab = 'itemsOrgroups';
			});
		}
	}

	//#endregion
}
