import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';

@Component({
	selector: 'app-forgot-password-extended',
	templateUrl: './forgot-password-extended.component.html',
	styleUrls: ['./forgot-password-extended.component.css']
})
export class ForgotPasswordExtendedComponent implements OnInit, OnDestroy {
	private queryStringToken: string = undefined;
	private forgotPasswordReqSubscription: Subscription;
	forgotPasswordForm: any = {
		emailID: '',
		mobile: '',
		verifythrough: 'authyapp',
		authenticationCode: '',
		password: '',
		confirmpassword: '',
		isVerified: true
	};
	public strHasMinLength: string = '';
	public strHasNumber: string = '';
	public strHasUpper: string = '';
	public strHasLower: string = '';
	public strHasNonalphas: string = '';
	// public passwordPolicyRegex = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|<>._^*()%!-])[A-Za-z\d$&+,:;=?@#|<>._^*()%!-].{11,}';
	public passwordPolicyRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#\"\$%\^&\*()_+|=\\]\\[{}~`:\";\'<>?,.\/])(?=.{11,})';
	public txtSymbol = '?.*[-!@#$%()_+|={}~[:;]';
	public passwordshow = ' icon-view-pwd';
	public passwordhide = ' icon-hide-pwd';
	public currentpasswordMode = 'password';
	public newpasswordMode = 'password';
	public confirmpasswordMode = 'password';
	public currentpasswordButton = {
		icon: this.passwordhide,
		type: "normal",
		onClick: () => {
			this.onClickpasswordButtonOption('current');
		}
	};
	public newpasswordButton = {
		icon: this.passwordhide,
		type: "normal",
		onClick: () => {
			this.onClickpasswordButtonOption('new');
		}
	};
	public confirmpasswordButton = {
		icon: this.passwordhide,
		type: "normal",
		onClick: () => {
			this.onClickpasswordButtonOption('confirm');
		}
	};


	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _ActivatedRoute: ActivatedRoute, private _Router: Router,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _IxDxAlertService: IxDxAlertService) {
		this._ActivatedRoute.queryParams.subscribe(params => {
			this.queryStringToken = params.token || undefined;
			this.validateForgotToken();
		});
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		if (this.forgotPasswordReqSubscription) {
			this.forgotPasswordReqSubscription.unsubscribe();
		}
	}
	public onClickpasswordButtonOption(type: string) {
		switch (type) {
			case 'current': {
				if (this.currentpasswordButton.icon == this.passwordshow) {
					this.currentpasswordButton = {
						icon: this.passwordhide,
						type: "normal",
						onClick: () => {
							this.onClickpasswordButtonOption(type);
						}
					}
				}
				else {
					this.currentpasswordButton = {
						icon: this.passwordshow,
						type: "normal",
						onClick: () => {
							this.onClickpasswordButtonOption(type);
						}
					}
				}
				this.currentpasswordMode = this.currentpasswordMode === "text" ? "password" : "text";
				break;
			}
			case 'new': {
				if (this.newpasswordButton.icon == this.passwordshow) {
					this.newpasswordButton = {
						icon: this.passwordhide,
						type: "normal",
						onClick: () => {
							this.onClickpasswordButtonOption(type);
						}
					}
				}
				else {
					this.newpasswordButton = {
						icon: this.passwordshow,
						type: "normal",
						onClick: () => {
							this.onClickpasswordButtonOption(type);
						}
					}
				}
				this.newpasswordMode = this.newpasswordMode === "text" ? "password" : "text";
				break;
			}
			case 'confirm': {
				if (this.confirmpasswordButton.icon == this.passwordshow) {
					this.confirmpasswordButton = {
						icon: this.passwordhide,
						type: "normal",
						onClick: () => {
							this.onClickpasswordButtonOption(type);
						}
					}
				}
				else {
					this.confirmpasswordButton = {
						icon: this.passwordshow,
						type: "normal",
						onClick: () => {
							this.onClickpasswordButtonOption(type);
						}
					}
				}
				this.confirmpasswordMode = this.confirmpasswordMode === "text" ? "password" : "text";
				break;
			}
		}
	}

	public changeVerifyThrough() {
		if (this.forgotPasswordForm.verifythrough === 'smsapp') {
			this.sendOTPViaSMS();
		}
	}

	public verifyEmailAndAuthCode() {
		if (!this.forgotPasswordForm.password || !this.forgotPasswordForm.confirmpassword) {
			this._ToastrService.info('Password and Confirm Password are required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (this.forgotPasswordForm.password && this.forgotPasswordForm.confirmpassword && this.forgotPasswordForm.password !== this.forgotPasswordForm.confirmpassword) {
			this._ToastrService.info('Password and Confirm Password should be same.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		if (!this.forgotPasswordForm.authenticationCode) {
			this._ToastrService.info('Authentication Code is required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const formData = new FormData();
		formData.append('method', 'validateAuthyToken');
		formData.append('token', encodeURIComponent(this.queryStringToken));
		formData.append('password', this.forgotPasswordForm.password);
		formData.append('authentication_code', this.forgotPasswordForm.authenticationCode);

		if (this.forgotPasswordReqSubscription) {
			this.forgotPasswordReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.forgotPasswordReqSubscription = this._RestApiService.doforgotPasswordReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response.flag) {
				const ixCustomDialogOptions: any = {
					popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
					title: '',
					showTitle: false,
					subtitle: 'Password Reset',
					message: 'Your password has been reset successfully.',
					buttons: [
						{
							elementAttr: { class: 'customPrimaryBtn' },
							text: "Login Now",
							type: "normal",
							stylingMode: "contained",
							onClick: (e) => {
								return true;
							}
						}
					]
				};
				this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
					if (result) {
						this._Router.navigate(['/auth/login'], { replaceUrl: true });
					}
				});
			} else {
				this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	public doResendCode() {
		this.sendOTPViaSMS();
	}

	private validateForgotToken() {
		if (this.queryStringToken) {
			const formData = new FormData();
			formData.append('method', 'validateForgotToken');
			formData.append('token', encodeURIComponent(this.queryStringToken));

			if (this.forgotPasswordReqSubscription) {
				this.forgotPasswordReqSubscription.unsubscribe();
			}
			this._LoaderService.show();
			this.forgotPasswordForm.isVerified = false;
			this.forgotPasswordReqSubscription = this._RestApiService.doforgotPasswordReqFormData(formData).subscribe(response => {
				this._LoaderService.hide();
				if (response.flag) {
					if (response.data && response.data.contact_details) {
						this.forgotPasswordForm.isVerified = true;
						this.forgotPasswordForm.emailID = response.data.contact_details.email;
						// this.forgotPasswordForm.mobile = response.data.contact_details.mobile;
					}
				} else {
					this.forgotPasswordForm.isVerified = false;
					const ixCustomDialogOptions: any = {
						popupIconsHtml: '<div class="infoTopIcon"><div class="infoIcon mrgT0"></div></div>',
						title: '',
						showTitle: false,
						subtitle: '',
						message: response.message || '',
						buttons: [
							{
								elementAttr: { class: 'customPrimaryBtn' },
								text: "Ok",
								type: "normal",
								stylingMode: "contained",
								onClick: (e) => {
									return true;
								}
							}
						]
					};
					this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
						if (result) {
							this._Router.navigate(['/auth/login'], { replaceUrl: true });
						}
					});
				}
			}, error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				// this._Router.navigate(['/auth/login'], { replaceUrl: true });
			});
		} else {
			this._Router.navigate(['/auth/login'], { replaceUrl: true });
			this._ToastrService.error('Token was missing!', 'Error', { closeButton: true, tapToDismiss: true });
		}
	}

	private sendOTPViaSMS() {
		if (this.queryStringToken && this.forgotPasswordForm.emailID) {
			const formData = new FormData();
			formData.append('method', 'smsOTP');
			formData.append('token', encodeURIComponent(this.queryStringToken));

			if (this.forgotPasswordReqSubscription) {
				this.forgotPasswordReqSubscription.unsubscribe();
			}
			this.forgotPasswordForm.mobile = '';
			this._LoaderService.show();
			this.forgotPasswordReqSubscription = this._RestApiService.doforgotPasswordReqFormData(formData).subscribe(response => {
				this._LoaderService.hide();
				if (response.flag) {
					this.forgotPasswordForm.mobile = response.data.phone;
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			});
		}
	}

	public doOnValueChangedNewPassword(e) {
		const password = e.value || '';
		// if (password) {
		this.strHasMinLength = this._AppCommonSrvc.chkHasMinLength(password);
		this.strHasNumber = this._AppCommonSrvc.chkHasNumber(password);
		this.strHasUpper = this._AppCommonSrvc.chkHasUpper(password);
		this.strHasLower = this._AppCommonSrvc.chkHasLower(password);
		this.strHasNonalphas = this._AppCommonSrvc.chkHasNonalphas(password);
		// }
	}

	public doOnChangeNewPassword(e) {
		const password = e.event.target.value || '';
		this.strHasMinLength = this._AppCommonSrvc.chkHasMinLength(password, true);
		this.strHasNumber = this._AppCommonSrvc.chkHasNumber(password, true);
		this.strHasUpper = this._AppCommonSrvc.chkHasUpper(password, true);
		this.strHasLower = this._AppCommonSrvc.chkHasLower(password, true);
		this.strHasNonalphas = this._AppCommonSrvc.chkHasNonalphas(password, true);
	}

	public newGeneratePassword() {
		const newGeneratedpassword: any = this._AppCommonSrvc.generatePassword();
		this.forgotPasswordForm.password = newGeneratedpassword;
		this.forgotPasswordForm.confirmpassword = newGeneratedpassword;
	}
}
