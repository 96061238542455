import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { ArCreditMemoEnum, SalesListingOpenFromEnum } from '@app/constants-enums/enums';
import { CustomNumberDecimalSuffixPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-sales-ar-creditmemo-total-tiles',
	templateUrl: './sales-ar-creditmemo-total-tiles.component.html',
	styleUrls: ['./sales-ar-creditmemo-total-tiles.component.css']
})
export class SalesArCreditmemoTotalTilesComponent implements OnInit {

	public globalCurrency: string;
	public salesTotalForTheMonth: number;
	public currentMonthName: string;
	public resourcesLoaded: boolean = false;
	private getArCreditmemoSbsn: Subscription;
	public isShowArCreditmemoPopover: boolean;
	public isUserSettingsExisting: boolean;
	public isShowFullValue: boolean = false;
	public getLastCharacterFromSortValue: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService,
		private _SalesDataSharingService: SalesDataSharingService,
		private _ElementRef: ElementRef) { }


	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this._ElementRef.nativeElement.contains(event.target)) {
			// click on inside 
		} else {
			this.isShowFullValue = false; // click on outside 
		}
	}

	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.currentMonthName = this._DashboardService.getCurrentMonthName();
		this.currentMonthName = '';
		this.getSalesArCreditmemoTotal();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_AR_CREDITMEMO_TILES_EVENT', response => {
			if (response) {
				this.getSalesArCreditmemoTotal();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getArCreditmemoSbsn) {
			this.getArCreditmemoSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_AR_CREDITMEMO_TILES_EVENT');
	}

	private getSalesArCreditmemoTotal() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.AR_CREDITMEMOS_TILE);
		if (this.getArCreditmemoSbsn) {
			this.getArCreditmemoSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getArCreditmemoSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this.resourcesLoaded = false;
			if (response.flag) {
				if (response.data.tiles) {
					this.salesTotalForTheMonth = response.data.tiles.value_of_ar_credit_memo_for_the_month || 0;
					this.currentMonthName = response.data.tiles.month_name || '';
					const getStringValue = new CustomNumberDecimalSuffixPipe().transform(this.salesTotalForTheMonth, 2).toString();
					this.getLastCharacterFromSortValue = '';
					if (/^[a-zA-Z]+$/.test(getStringValue.substr(getStringValue.length - 1))) {
						this.getLastCharacterFromSortValue = getStringValue.substr(getStringValue.length - 1);
					}
				}
			}
			this.getCustomer360Config();
		}, error => {
			this.resourcesLoaded = false;
		});
	}

	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
		this._SalesDataSharingService.setArCreditMemoListOpenFrom(ArCreditMemoEnum.AR_CREDIT_MEMOS_LIST_FOR_SALES);
		this._SalesDataSharingService.setSalesListingOpenFrom(SalesListingOpenFromEnum.TILE_AR_CREDITMEMO);
	}

	public doOpenArCreditmemoPopover(e) {
		this.getCustomer360Config();
		this.isShowArCreditmemoPopover = true;
	}
	public doCloseArCreditmemoPopover(e) {
		this.isShowArCreditmemoPopover = false;
	}

	// Method used to get Customer360 Configuration
	private getCustomer360Config() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		this.isUserSettingsExisting = false;
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === 'ar_creditmemo_tile');
				if (configIndex !== -1) {
					const configObject = dashboardSettings.configs[configIndex];
					let isTileSettingSaved = false;
					if (configObject && configObject.show_data_from) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_status_to_consider) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_date_to_use) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_type) {
						isTileSettingSaved = true;
					}
					this.isUserSettingsExisting = isTileSettingSaved;
				}
			}
		}
	}

	public doRefreshTile(e) {
		this.isShowArCreditmemoPopover = false;
		this.getSalesArCreditmemoTotal();
	}

	public doClickToShowFullValue(event) {
		if (this.salesTotalForTheMonth <= 0) {
			return;
		}
		if (this.salesTotalForTheMonth > 0 && this.salesTotalForTheMonth < 1000) {
			return;
		}
		event.stopPropagation();
		this.isShowFullValue = true;
	}

}
