import { HostListener, Pipe, PipeTransform } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';

@Pipe({
	name: 'filterListFromList'
})
export class FilterListFromListPipe implements PipeTransform {

	transform(value: any[], list?: any[], propertyName?: any, returnPropertyName?: any, withSeperator?: string): any {
		const getParamValue = value || [];
		let getLabel = '';
		const seperator = (!withSeperator || withSeperator && !withSeperator.trim()) ? ', ' : withSeperator;
		getParamValue.forEach((ele, index) => {
			const item = list.filter(i => i.hasOwnProperty(propertyName) && i[propertyName].toString().toLowerCase() === ele.toString().toLowerCase());
			if (item && item.length > 0) {
				getLabel = getLabel + item[0][returnPropertyName];
				if (index < (getParamValue.length - 1)) {
					getLabel = getLabel + seperator;
				}
			}
		});
		return getLabel;
	}

}

@Pipe({
	name: 'multiFilterListFromListWithMultiProperties'
})
export class FilterListFromListWithMultiPropertiesPipe implements PipeTransform {

	transform(value: any[], list?: any[], propertyName?: any, returnPropertyName?: any, withSeperator?: string): any {
		const getParamValue = value || [];
		let getLabel = '';
		const seperator = (!withSeperator || withSeperator && !withSeperator.trim()) ? ', ' : withSeperator;
		getParamValue.forEach((ele, index) => {
			let item = [];
			if (typeof (ele) === 'string') {
				item = list.filter(i => i.hasOwnProperty(propertyName) && i[propertyName].toString().toLowerCase() === ele.toString().toLowerCase());
			}
			if (typeof (ele) === 'object') {
				item = list.filter(i => i.hasOwnProperty(propertyName) && i[propertyName].toString().toLowerCase() === ele[propertyName].toString().toLowerCase());
			}
			if (item && item.length > 0) {
				let multipleProps = [];
				try {
					multipleProps = returnPropertyName.split(',');
				} catch (error) {
					multipleProps = [];
				}
				for (const prop in multipleProps) {
					if (multipleProps.length > 1) {
						getLabel = getLabel + ' ' + item[0][multipleProps[prop]];
					} else {
						getLabel = getLabel + item[0][multipleProps[prop]];
					}
					// if (index < (multipleProps.length - 1)) {
					// 	getLabel = getLabel + seperator;
					// }
				}

				// getLabel = getLabel + item[0][returnPropertyName];
				if (index < (getParamValue.length - 1)) {
					getLabel = getLabel + seperator;
				}
			}
		});
		return getLabel;
	}

}

@Pipe({
	name: 'filterFromList'
})
export class FilterFromListPipe implements PipeTransform {

	transform(value: any, list?: any[], propertyName?: any, returnPropertyName?: any): any {
		const getParamValue = value || '';
		const item = list.filter(i => i.hasOwnProperty(propertyName) && i[propertyName].toString().toLowerCase() === getParamValue.toString().toLowerCase());
		if (item && item.length > 0) {
			return item[0][returnPropertyName];
		} else {
			return '';
		}
	}

}

@Pipe({
	name: 'multifilterFromList'
})
export class MultiFilterFromListPipe implements PipeTransform {

	transform(value: any, list?: any[], propertyName?: any, returnPropertyName?: string): any {
		const getParamValue = value || '';
		const item = list.filter(i => i.hasOwnProperty(propertyName) && i[propertyName].toString().toLowerCase() === getParamValue.toString().toLowerCase());
		let returnValue = '';
		if (item && item.length > 0) {
			const selectedObject = item[0];
			let multipleProps = [];
			try {
				multipleProps = returnPropertyName.split(',');
			} catch (error) {
				multipleProps = [];
			}
			for (const prop in multipleProps) {
				if (multipleProps.length > 1) {
					returnValue = returnValue + ' ' + selectedObject[multipleProps[prop]];
				} else {
					returnValue = selectedObject[multipleProps[prop]];
				}
			}
			return returnValue;
		} else {
			return returnValue;
		}
	}
}

@Pipe({
	name: 'filterFromNestedList'
})
export class FilterFromNestedListPipe implements PipeTransform {
	transform(value: any, listP?: any[], listS?: any[], propertyNameP?: any, propertyNameS?: any, returnPropertyName?: any): any {
		const getParamValue = value || '';
		const itemP = listP.filter(i => i.hasOwnProperty(propertyNameP) && i[propertyNameP].toString().toLowerCase() === getParamValue.toString().toLowerCase());

		if (itemP && itemP.length > 0 && itemP[0][propertyNameS]) {
			const itemS = listS.filter(i => i.hasOwnProperty(propertyNameP) && i[propertyNameP].toString().toLowerCase() === itemP[0][propertyNameS].toString().toLowerCase());
			if (itemS && itemS.length > 0) {
				return itemS[0][returnPropertyName];
			} else {
				return '';
			}
		} else {
			return '';
		}
	}
}


@Pipe({
	name: 'calculateAmountFromList'
})
export class CalculateAmountFromListPipe implements PipeTransform {
	transform(value: any, propertyArray: any, propertyName?: any, propertyForCondition?: any): any {
		const getParamValue = value || {};
		let totalAmount = 0;
		if (getParamValue[propertyArray] && getParamValue[propertyArray].length > 0) {
			getParamValue[propertyArray].forEach(i => {
				if (i[propertyForCondition] === 'it_Invoice') {
					totalAmount = totalAmount + (+i[propertyName] || 0);
				}
			});
			getParamValue.amount = totalAmount;
			return totalAmount;
		} else {
			return 0;
		}
	}
}

@Pipe({
	name: 'getPriceFromPricelist'
})
export class GetPriceFromPricelistPipe implements PipeTransform {
	transform(value: any, items?: any[], erp_primary_key?: string): any {
		if (items && items.length > 0) {
			const itemsIndex = items.findIndex(i => i.erp_primary_key === erp_primary_key);
			if (itemsIndex !== -1) {
				return items[itemsIndex].price;
			} else {
				return 0;
			}
		}
		return 0;

	}
}

@Pipe({
	name: 'relpaceWithStar'
})
export class ReplacedWithStarPipe implements PipeTransform {
	transform(value: any, noOfBeginLetter?: number, noOfEndLetter?: number): any {
		const getParamValue = value || '';
		if (getParamValue) {
			const getStringValue = JSON.parse(JSON.stringify(getParamValue.toString()));
			const newString = getStringValue.substr(noOfBeginLetter, getStringValue.toString().length - noOfEndLetter).replace(/[^*]/g, "*");
			const firstThree = getStringValue.substr(0, noOfBeginLetter);
			const lastThree = getStringValue.substr(getStringValue.toString().length - noOfBeginLetter, getStringValue.toString().length);
			return firstThree + newString + lastThree;
		}
		return getParamValue;
	}
}

@Pipe({
	name: 'heightwidthRatio'
})
export class HeightWidthRatioPipe implements PipeTransform {
	private heightwidthObj: any;

	constructor(private _AppCommonSrvc: AppCommonSrvc) {
		this.heightwidthObj = this._AppCommonSrvc.getPopupHeightWidthRatio();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.heightwidthObj = this._AppCommonSrvc.getPopupHeightWidthRatio();
	}

	transform(value: any, percentage: number, scaleType: string): any {
		const getParamValue = value || '';
		const userPercentage = percentage || null;
		const userScaleType = scaleType || null;
		this.heightwidthObj = this._AppCommonSrvc.getPopupHeightWidthRatio();
		if (userScaleType.toString().toUpperCase() === 'X') {
			return (this.heightwidthObj.innerWidth * userPercentage) / 100;
		} else if (userScaleType.toString().toUpperCase() === 'Y') {
			return (this.heightwidthObj.innerHeight * userPercentage) / 100;
		}
		return getParamValue;
	}
}
