import { DataService } from '@app/services/data.service';
import { Component, OnInit, AfterViewInit, Inject, EventEmitter, Output, Input } from '@angular/core';
import { ErpTypeValEnum } from "@app/constants-enums/enums";
import { environment } from "@environments/environment";
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
@Component({
	selector: 'app-view-charge-documents-dialog',
	templateUrl: './view-charge-documents-dialog.component.html',
	styleUrls: ['./view-charge-documents-dialog.component.css']
})
export class ViewChargeDocumentsDialogComponent implements OnInit {
	public chargeBackDocumentDetails: any;
	public totalClaimed: number;
	public totalClaimedAmount: number;
	public billBackType: string;
	public downloadDocumentUrl: string;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public uomListData: any = [];
	public globalCurrency: string;
	public isShowPopup: boolean = true;
	@Input() chargeDocumentProps: any;
	@Output() closeChargeDocumentPopup = new EventEmitter<any>();
	constructor(
		private _LoginService: LoginService,
		private _DataService: DataService,
		public _AppCommonSrvc: AppCommonSrvc,
	) { }


	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.uomListData = this._DataService.getUOMs();
		this.chargeBackDocumentDetails = this.chargeDocumentProps.chargeBackDocumentDetails;
		this.billBackType = this.chargeDocumentProps.billBackType;
		this.totalClaimed = 0;
		this.totalClaimedAmount = 0;

		this.loadComponent();
		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS) {
				this.isQBErpUser = true;
			}
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
		}
	}

	public loadComponent() {
		if (this.billBackType === 'billback') {
			if (this.chargeDocumentProps.chargeBackDocumentDetails.documentURL) {
				const documentURL = this.chargeDocumentProps.chargeBackDocumentDetails.documentURL;
				const documentNewURLArr = documentURL.split('-');
				if (documentNewURLArr.length > 0) {
					const docId = documentNewURLArr[0];
					const docDate = documentNewURLArr[1];
					const docCode = documentNewURLArr[2];
					const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=billbackDoc'
					this.downloadDocumentUrl = finalUrl;
				}
			}
			for (let i = 0; i < this.chargeDocumentProps.chargeBackDocumentDetails.billedItems.length; i++) {
				const objClaimed = parseFloat(this.chargeDocumentProps.chargeBackDocumentDetails.billedItems[i].quantity) || 0;
				const objClaimedAmount = parseFloat(this.chargeDocumentProps.chargeBackDocumentDetails.billedItems[i].linetotal) || 0;
				this.totalClaimed = this.totalClaimed + objClaimed;
				this.totalClaimedAmount = this.totalClaimedAmount + objClaimedAmount;
			}
		}

		if (this.billBackType === 'chargeback' || this.billBackType === 'enduserschargeback') {
			if (this.chargeDocumentProps.chargeBackDocumentDetails.documentURL) {
				const documentURL = this.chargeDocumentProps.chargeBackDocumentDetails.documentURL;
				const documentNewURLArr = documentURL.split('-');
				if (documentNewURLArr.length > 0) {
					const docId = documentNewURLArr[0];
					const docDate = documentNewURLArr[1];
					const docCode = documentNewURLArr[2];
					const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackDoc'
					this.downloadDocumentUrl = finalUrl;
				}
			}
			for (let i = 0; i < this.chargeDocumentProps.chargeBackDocumentDetails.claimedItems.length; i++) {
				const objClaimed = parseFloat(this.chargeDocumentProps.chargeBackDocumentDetails.claimedItems[i].claimed) || 0;
				const objClaimedAmount = parseFloat(this.chargeDocumentProps.chargeBackDocumentDetails.claimedItems[i].linetotal) || 0;
				this.totalClaimed = this.totalClaimed + objClaimed;
				this.totalClaimedAmount = this.totalClaimedAmount + objClaimedAmount;
			}
		}

		if (this.billBackType === 'viewcraditmemo') {

			if (this.chargeDocumentProps.chargeBackDocumentDetails.documentURL) {
				const documentURL = this.chargeDocumentProps.chargeBackDocumentDetails.documentURL;
				const documentNewURLArr = documentURL.split('-');
				if (documentNewURLArr.length > 0) {
					const docId = documentNewURLArr[0];
					const docDate = documentNewURLArr[1];
					const docCode = documentNewURLArr[2];
					const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode
					this.downloadDocumentUrl = finalUrl;
				}
			}
			for (let i = 0; i < this.chargeDocumentProps.chargeBackDocumentDetails.claimedItems.length; i++) {
				const objClaimed = parseFloat(this.chargeDocumentProps.chargeBackDocumentDetails.claimedItems[i].claimed) || 0;
				const objClaimedAmount = parseFloat(this.chargeDocumentProps.chargeBackDocumentDetails.claimedItems[i].linetotal) || 0;
				this.totalClaimed = this.totalClaimed + objClaimed;
				this.totalClaimedAmount = this.totalClaimedAmount + objClaimedAmount;
			}
		}
	}

	public doHiddenPopop(e) {
		this.isShowPopup = false;
	}

}
