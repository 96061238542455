import { CcUploadItemsDialogComponent } from './../cc-upload-items-dialog/cc-upload-items-dialog.component';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { Subscription, Observable } from 'rxjs';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from "devextreme/excel_exporter";
import { DecimalPipe } from "@angular/common";
import { ChargeBackItemTabModel, ChargebBackItemListTabModel } from "@app/models/chargeback-contract.model";
import { ChargebackContractModelUI } from '@app/models/chargeback-contract.model';
import * as moment from 'moment';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
declare const ExcelJS: ExcelJS;

@Component({
	selector: 'app-cc-items-tab',
	templateUrl: './cc-items-tab.component.html',
	styleUrls: ['./cc-items-tab.component.css'],
	providers: [DecimalPipe]
})
export class CcItemsTabComponent implements OnInit, OnDestroy {
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	@ViewChild("itemTabDataGridContainer", {}) itemTabDataGridContainer: DxDataGridComponent;
	@ViewChild("quantityDataGridContainer", {}) quantityDataGridContainer: DxDataGridComponent;
	public getUomLstSbsn: Subscription;
	public getItemDetailsSbsn: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public listOfAvailableQuantity: any[] = [];

	public editChargebackGuid: string;
	public productSearchItemType: string;
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;

	@Input() chargebackContractModelUI = new ChargebackContractModelUI();
	@Input() itemTabDataList: any = [];
	@Input() itemDataSource: any;
	@Output() saveChargebackItemTabDataEvent = new EventEmitter<string>();
	@Input() itemTabDataSource: any;
	public itemGridBoxValue: any[] = [];
	public itemGridBoxColumns: any;
	public arrSelectedItemList: any = [];
	public lengthSelectedItems: any;
	public chkAllItem: boolean = false;
	public listOfCsvRowForItmGrpTab: any[] = [];
	public csvResponse: any;
	public isUploadExcelCsvFileItmGrpTab: boolean = false;
	public noOfUnmatchedItem: number;
	public noOfUnmatchedItemUom: number;
	public noOfmatchedItemPrice: number;
	public noOfTotalItem: number;
	public filterText: string = '';
	public selecteditemTabDataList: any[] = [];
	public filterValueChangeSbsn: Observable<any>;
	public isVisiblePopup: boolean = false;
	public decimalNumberFormat: string = '1.0-0';
	public quantityHeaderText: string;
	public quantityDataField: string;
	// Model object
	public guid = new Guid();
	public pageOptions: PageOptions = new PageOptions();
	public decimalPointForCurrency: number;
	public isUploadItemsPopup: boolean = false;
	public uploadItemsProps: any;

	constructor(
		private _Router?: Router,
		private _ToastrService?: ToastrService,
		private _PermissionsService?: NgxPermissionsService,
		private _LoginService?: LoginService,
		private _LoaderService?: LoaderService,
		private _RestApiService?: RestApiService,
		private _ConfirmationDialogService?: ConfirmationDialogService,
		private _DataService?: DataService,
		private _ChargebackContractService?: ChargebackContractService,
		private _FileUploadService?: FileUploadService,
		private _GlobalStateService?: GlobalStateService,
		public _AppCommonSrvc?: AppCommonSrvc,
		private _CustomCurrencyPipe?: CustomCurrencyPipe,
		public _BillbackService?: BillbackService,
		private _DecimalPipe?: DecimalPipe
	) { }

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		this.itemGridBoxColumns = this._BillbackService.getItemGridBoxColumn();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}
		// product_search logic type (item/price)
		this.productSearchItemType = this._BillbackService.getProductSearchType();
		this.editChargebackGuid = this._ChargebackContractService.getEditChargebackGuid();
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	public preInit() {
		if (this.itemTabDataList && this.itemTabDataList.length > 0) {
			this.itemTabDataSource = this.itemTabDataList;
		}
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadItemTabDataList();
		setTimeout(() => {
			this.filterValueChangeSbsn = this.itemTabDataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
	}

	ngOnDestroy(): void {

		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('GET_ITEM_LIST_WITH_UOM_DATA_EVENT');
	}

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	public doCellClick(element) {
		if (element.column.dataField === 'itemsku' || element.column.dataField === 'itemname' || element.column.dataField === 'description' || element.column.dataField === 'selectedUom' || element.column.dataField === 'listPrice' || element.column.dataField === 'final_amount' || element.column.dataField === 'chargebackAmount') {
			return;
		}
		if (element.data[element.column.dataField] && element.data[element.column.dataField].length === 0) {
			return;
		}

		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0) {
				// if (!element.data.hasOwnProperty('isShowPopover_' + element.rowIndex + '_' + element.columnIndex) || (element.data.hasOwnProperty('isShowPopover_' + element.rowIndex + '_' + element.columnIndex) && !element.data['isShowPopover_' + element.rowIndex + '_' + element.columnIndex])) {
				// 	element.data['isShowPopover_' + element.rowIndex + '_' + element.columnIndex] = true;
				// 	this.getListOfInvoice(element);
				// }
				this.quantityHeaderText = 'Quantity ' + element.column.caption;
				this.quantityDataField = element.column.dataField;
				// switch (element.column.dataField) {
				// 	case 'sold':
				// 		this.quantityHeaderText ='Sold';
				// 		break;
				// 	case 'claimed':
				// 		this.quantityHeaderText = 'Claimed';
				// 		break;
				// 	case 'available':
				// 		this.quantityHeaderText = 'Available';
				// 		break;
				// 	default:
				// 		break;
				// }
				this.listOfAvailableQuantity = element.data[element.column.dataField];
				this.doOpenPopup(element);
			}
		}
	}

	public viewTotalQuantity(arrObj: any) {
		let totalQty: any = 0;
		if (arrObj && arrObj.length > 0) {
			arrObj.forEach(element => {
				const qty = element['quantity'] || 0;
				totalQty += qty;
			});
		}
		return this._DecimalPipe.transform(totalQty || 0, '1.2-4');
	}

	public doOpenPopup(element) {
		this.isVisiblePopup = true;
	}
	public doHiddenPopop(e) {
		this.isVisiblePopup = false;
	}

	convertToLocalDate(dateStr: string) {
		const localDate = moment(dateStr).format("YYYY-MM-DD");
		return localDate;
	}

	//#region item select box methods
	loadItemTabDataList() {
		// const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=items";
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		if (this.chargebackContractModelUI.selectCustomerPriceListNum) {
			formdata = formdata + '&pricelistnum=' + this.chargebackContractModelUI.selectCustomerPriceListNum;
			formdata = formdata + '&product_search_from=chargebacks';
		}
		// else {
		// 	this._ToastrService.info('No Price List available for this customer', 'Info!', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }

		this.itemDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "itemname",
			loadUrl: formdata
		});
	}

	public checkAllItem() {
		if (this.chkAllItem) {
			this.lengthSelectedItems = 'All Items Selected';
			this.arrSelectedItemList = [];
		} else {
			this.lengthSelectedItems = '';
		}
	}

	public onItemValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedItemList = [];
			this.itemGridBoxValue = [];
			this.itemDataGrid.instance.refresh();
			this.chkAllItem = false;
		}
	}

	public onItemSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.chkAllItem = false;
			this.lengthSelectedItems = e.selectedRowsData.length + ' Items Selected';
			this.itemGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].erp_primary_key);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllItem) {
			this.lengthSelectedItems = '';
		}
	}

	//#endregion 

	//#region  Item data grid
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.itemTabDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		visible: !this.isCurrentTabPermission,
			// 		icon: 'xlsxfile',
			// 		text: "Import Excel/Csv",
			// 		onClick: (event: any) => {
			// 			this.doBulkUploadItmGrpTab();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.itemTabDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Chargeback_Items.xlsx';
		worksheet = workbook.addWorksheet('Items');

		exportDataGrid({
			worksheet: worksheet,
			component: this.itemTabDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'listPrice' || gridCell.column.dataField === 'chargebackAmount' || gridCell.column.dataField === 'final_amount') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.itemTabDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	public doOnEditorPreparingItemTab(event) {
		if (event && event['row'] && event['row']['data']) {
			const itemObject = event.row.data;
			const listOfRows = this.itemTabDataGridContainer.instance.getVisibleRows();
			const rowIndex = this.itemTabDataGridContainer.instance.getRowIndexByKey(event.row.key);
			const itemDetails: ChargeBackItemTabModel = listOfRows[rowIndex].data;
			if (event.parentType === "dataRow" && event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				event.editorOptions.visible = true;
				uomDataSource = itemObject.uomList;
				event.editorOptions.dataSource = uomDataSource;

				event.editorOptions.onValueChanged = (e: any) => {
					const targetUom = e.value;
					const sourceUom = e.previousValue;
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'selectedUom', targetUom);
					const uomData = itemDetails.uomList.filter(i => i.code === targetUom);
					let uomprice = 0;
					if (uomData && uomData.length > 0) {
						uomprice = uomData[0].price || 0;
					}
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'listPrice', +uomprice);
					const chargeBack = +(this.calculateUomPrice(itemDetails.chargebackAmount, itemDetails.uomgroupentry, sourceUom, targetUom));
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', chargeBack);
					itemDetails.chargebackAmount = chargeBack;

					if (itemDetails.chargebackAmount) {
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', +((parseFloat(uomprice.toString()) - parseFloat(itemDetails.chargebackAmount))));
					} else {
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', +(parseFloat(uomprice.toString())));
					}
				}
			}
			if (event.parentType === "dataRow" && event.dataField === 'chargebackAmount') {
				event.editorOptions.onValueChanged = (e: any) => {
					const chargebackAmount = e.value;
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', chargebackAmount);

					if (this.productSearchItemType === 'price') {
						if (chargebackAmount <= itemDetails.listPrice) {
							const val = +(parseFloat((itemDetails.listPrice - chargebackAmount).toFixed(2)));
							if (val > 0) {
								this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', val);
							} else {
								this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', 0);
								this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', itemDetails.listPrice);
							}
						} else {
							this._ToastrService.info('Chargeback cannot be grater then List Price', 'Info!', { closeButton: true, tapToDismiss: true });
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', itemDetails.listPrice);
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', 0);
						}
					} else {
						const val = +(parseFloat((itemDetails.listPrice - chargebackAmount).toFixed(2)));
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', chargebackAmount);
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', val);
					}
				}
			}
			if (event.parentType === "dataRow" && event.dataField === 'final_amount') {
				event.editorOptions.onValueChanged = (e: any) => {
					const finalprice = e.value;
					this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', finalprice);
					if (finalprice <= itemDetails.listPrice) {
						const val = +(parseFloat((itemDetails.listPrice - finalprice).toFixed(2)));
						if (val > 0) {
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', val);
						} else {
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', 0);
							this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', +itemDetails.listPrice);
						}
					} else {
						this._ToastrService.info('Final price cannot be grater then List Price', 'Info!', { closeButton: true, tapToDismiss: true });
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'chargebackAmount', 0);
						this.itemTabDataGridContainer.instance.cellValue(rowIndex, 'final_amount', itemDetails.listPrice);
					}
				}
			}
		}
	}
	private calculateUomPrice(price, uomgroupentry, sourceUom, targetUom) {
		let clculatePrice = price;
		let targeteDiscountAmount, baseDiscoutAmount = 0;

		let objSourceAbsEntry, objTargetAbsEntry;
		let sourceAbsEntry, targetAbsEntry = '';

		if (clculatePrice && clculatePrice > 0) {
			objSourceAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === sourceUom.toString().toLowerCase());
			objTargetAbsEntry = this.listOfUoms.filter(item => item.code.toString().toLowerCase() === targetUom.toString().toLowerCase());
			if (objSourceAbsEntry && objSourceAbsEntry.length > 0) {
				sourceAbsEntry = objSourceAbsEntry[0].absentry;
			}
			if (objTargetAbsEntry && objTargetAbsEntry.length > 0) {
				targetAbsEntry = objTargetAbsEntry[0].absentry;
			}
			const objUomGroup = this.listOfUomGroups.filter(item => item.absentry.toString().toLowerCase() === uomgroupentry.toString().toLowerCase());
			if (objUomGroup && objUomGroup.length > 0) {
				const uomGroup = objUomGroup[0];
				if (uomGroup && uomGroup.uomgroupdefinitioncollection && uomGroup.uomgroupdefinitioncollection.length > 0) {
					if (sourceUom !== uomGroup.baseuom) {
						const sourceUomIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase());
						let sourceUomItem: any;
						if (sourceUomIndex !== -1) {
							sourceUomItem = uomGroup.uomgroupdefinitioncollection[sourceUomIndex];
						}
						// const sourceUomItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === sourceAbsEntry.toString().toLowerCase())[0];
						if (sourceUomItem) {
							const itemQty = (sourceUomItem.basequantity / sourceUomItem.alternatequantity) || 0;
							baseDiscoutAmount = clculatePrice / itemQty;
						} else {
							baseDiscoutAmount = clculatePrice;
						}
					} else {
						baseDiscoutAmount = clculatePrice;
					}

					if (targetUom !== uomGroup.baseuom) {
						// const targetItem = uomGroup.uomgroupdefinitioncollection.filter(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase())[0];
						const targetItemIndex = uomGroup.uomgroupdefinitioncollection.findIndex(u => u.alternateuom.toString().toLowerCase() === targetAbsEntry.toString().toLowerCase());
						let targetItem: any;
						if (targetItemIndex !== -1) {
							targetItem = uomGroup.uomgroupdefinitioncollection[targetItemIndex];
						}
						const itemQty = (targetItem.basequantity / targetItem.alternatequantity) || 0;
						targeteDiscountAmount = baseDiscoutAmount * itemQty;
					} else {
						targeteDiscountAmount = baseDiscoutAmount;
					}
					clculatePrice = targeteDiscountAmount;
				}
			}
		}
		return clculatePrice;
	}

	public doOnRowRemovedItemTab(event) {
		if (this._ChargebackContractService.getEditChargebackGuid()) {
			this.saveChargebackItemTabDataEvent.emit('save');
		}
	}

	public doOnSavedItemTab() {
		if (this._ChargebackContractService.getEditChargebackGuid()) {
			this.saveChargebackItemTabDataEvent.emit('save');
		}
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}
	//#endregion

	public addProductAgreementTabDetails() {
		if (this.itemDataGrid) {
			this.selecteditemTabDataList = this.itemDataGrid.instance.getSelectedRowsData();
		}
		if (this.selecteditemTabDataList.length <= 0 && !this.checkAllItem) {
			this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		}
		const itemGuidList = [];
		this.selecteditemTabDataList.forEach(element => {
			itemGuidList.push(element.guid);
		});
		if (this.productSearchItemType === 'price') {
			this.addItemInItemist();
			this.getItemListWithUOMData(itemGuidList);
		} else {
			this.addItemInItemist();
			this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_LIST_WITH_UOM_DATA_EVENT', { itemDetailsList: this.selecteditemTabDataList });
		}

	}

	public addItemInItemist() {
		let itemListTabModel = new ChargebBackItemListTabModel();
		if (!this.itemTabDataList) {
			this.itemTabDataList = []
		}
		this._GlobalStateService.unsubscribe('GET_ITEM_LIST_WITH_UOM_DATA_EVENT');
		this._GlobalStateService.subscribe('GET_ITEM_LIST_WITH_UOM_DATA_EVENT', response => {
			let itemIndex: any = -1;
			itemListTabModel.guid = this.guid.newGuid();
			if (this.itemTabDataList && this.itemTabDataList.length > 0) {
				itemIndex = 0;
				// itemIndex = this.itemTabDataList.findIndex(p => p.guid == itemListTabModel.guid);
			}
			response.itemDetailsList.forEach(item => {
				let uomlist = this._BillbackService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, this.chargebackContractModelUI.selectCustomerPriceListNum);
				let defaultSelectedUom = '';
				let defaultListPrice = 0;
				if (uomlist.length > 0) {
					defaultSelectedUom = uomlist[0].code || '';
					defaultListPrice = uomlist[0].price || 0;
				}
				let itemTabModel = new ChargeBackItemTabModel();
				itemTabModel.guid = this.guid.newGuid();
				itemTabModel.erp_primary_key = item.erp_primary_key || '';
				itemTabModel.itemsku = item.itemsku || '';
				itemTabModel.itemname = item.itemname || '';
				itemTabModel.item_guid = item.guid || '';
				itemTabModel.description = item.description || '';
				itemTabModel.uomgroupentry = item.uomgroupentry || '';
				itemTabModel.uomList = uomlist || [];
				itemTabModel.selectedUom = defaultSelectedUom;
				// itemTabModel.discountedPrice = item.discountedPrice || '';
				itemTabModel.is_disbled_input_field = false;
				itemTabModel.listPrice = +defaultListPrice;
				itemTabModel.chargebackAmount = '';
				itemTabModel.final_amount = +defaultListPrice;
				itemTabModel.bought = '';
				itemTabModel.sold = '';
				itemTabModel.claimed = '';
				itemTabModel.available = '';
				itemTabModel.last_invoice_date = '';
				if (itemIndex !== -1) {
					if (this.itemTabDataList[itemIndex]['items'] && this.itemTabDataList[itemIndex]['items'].length > 0) {
						const iIndex = this.itemTabDataList[itemIndex]['items'].findIndex(p => p.erp_primary_key === item.erp_primary_key);
						if (iIndex != -1) {
							this.itemTabDataList[itemIndex]['items'][iIndex] = itemTabModel;
						} else {
							this.itemTabDataList[itemIndex]['items'].push(JSON.parse(JSON.stringify(itemTabModel)));
						}
					} else {
						this.itemTabDataList[itemIndex]['items'].push(JSON.parse(JSON.stringify(itemTabModel)));
					}
				} else {
					itemListTabModel.items.push(JSON.parse(JSON.stringify(itemTabModel)));
				}
			});
			if (itemIndex === -1) {
				this.itemTabDataList.unshift(JSON.parse(JSON.stringify(itemListTabModel)));
			}
			this.itemTabDataSource = this.itemTabDataList;
			this.refreshDatagrid();
		});
	}

	private refreshDatagrid() {
		this.arrSelectedItemList = [];
		this.itemGridBoxValue = [];
		this.itemDataGrid.instance.clearFilter();
		this.itemDataGrid.instance.refresh();
		this.chkAllItem = false;
		if (this._ChargebackContractService.getEditChargebackGuid()) {
			this.saveChargebackItemTabDataEvent.emit('save');
		}
	}

	private getItemListWithUOMData(itemGuidList: any[]) {
		let priceListNum = '';
		try {
			priceListNum = this.chargebackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		let itemDetailsList: any[];
		// const view_fields = ['erp_primary_key', 'itemname', 'foreignname', 'uomgroupentry', 'itemprices'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'item_details');
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		formData.append('product_search_from', 'chargeback');
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		// formData.append('view_fields', JSON.stringify(view_fields));
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_LIST_WITH_UOM_DATA_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_ITEM_LIST_WITH_UOM_DATA_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// #region for Bulk Upload items to datagrid

	// Method used to click to Open upload dialog
	public doBulkUploadItmGrpTab() {
		if (!this.chargebackContractModelUI.selectCustomerPriceListNum) {
			this._ToastrService.info('Customer Name is required', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		}
		this.uploadItemsProps = {
			actionFor: 'showUploadFile',
			isItemExists: this.itemTabDataList.length > 0,
			listOfCsvRows: []
		};
		this.isUploadItemsPopup = false;
		setTimeout(() => {
			this.isUploadItemsPopup = true;
		}, 20);
	}

	private doMatchExcelItemsWithOrignItems() {
		this._GlobalStateService.unsubscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GOT_ITEMLIST_ITMGRPTAB_EVENT', response => {
			const itemDetailsList = response.itemDetailsList;
			if (this.productSearchItemType === 'item') {
				this.doAddBulkItemsToItmGrpTabList();
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
			}
			if (this.productSearchItemType === 'price') {
				const matchedItemsList: any[] = [];
				this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
					if (csvRow.item) {
						const itemIndex = itemDetailsList.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
						if (itemIndex !== -1) {
							const itemEle = itemDetailsList[itemIndex];
							matchedItemsList.push(itemEle);
						}
					}
				});
				const listOfGuids = [];
				matchedItemsList.map(matchedItem => listOfGuids.push(matchedItem.guid));
				this.doAddBulkItemsToItmGrpTabList();
				if (listOfGuids.length > 0) {
					this.fetchedItemDetailsByPricelistnum(listOfGuids);
				}
			}
		});
	}

	// Method used to Add items from excel to Exclusion items list
	private doAddBulkItemsToItmGrpTabList() {
		this._GlobalStateService.unsubscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT');
		this._GlobalStateService.subscribe('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', response => {
			const listOfItemDetails = response.itemDetailsList;

			this.listOfCsvRowForItmGrpTab.forEach(csvRow => {
				if (csvRow.item) {
					const itemDetailIndex = listOfItemDetails.findIndex(itemEle => csvRow.item.toString().toLowerCase().trim() === itemEle.itemname.toString().toLowerCase().trim());
					if (itemDetailIndex !== - 1) {
						const itemEle = listOfItemDetails[itemDetailIndex];
						csvRow.isItemMatched = true;
						csvRow.description = itemEle.description || '';

						const listOfSpecificUoms = this.setlistOfUomBySelProduct(itemEle);
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}

						if (csvRow.uom) {
							const specificUomIndex = listOfSpecificUoms.findIndex(uomEle => uomEle.code.toString().toLowerCase().trim() === csvRow.uom.toString().toLowerCase().trim());
							if (specificUomIndex !== -1) {
								csvRow.isItemUomMatched = 'matched';
								try {
									defaultSelectedUom = listOfSpecificUoms[specificUomIndex].code;
									defaultListPrice = listOfSpecificUoms[specificUomIndex].price || 0;
								} catch (e) {
								}
							} else {
								csvRow.isItemUomMatched = 'unmatched';
								try {
									csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
								} catch (e) {
								}
							}
						} else {
							csvRow.isItemUomMatched = 'unmatched';
							try {
								csvRow.isItemModifiedUom = listOfSpecificUoms[0].code;
							} catch (e) {
							}
						}

						let customChargebackfinalprice = 0;
						let customChargebackAmount = 0;
						if (this.productSearchItemType === 'item') {
							customChargebackAmount = parseFloat(csvRow.chargeback) || null;
						}
						if (this.productSearchItemType === 'price') {
							if (csvRow.hasOwnProperty('chargeback')) {
								csvRow.chargeback = this.convertToNumber(csvRow.chargeback);
								if (+csvRow.chargeback > +defaultListPrice) {
									csvRow.isItemModifiedPrice = true;
									csvRow.isItemModifiedPriceLbl = 0;
									customChargebackAmount = 0;
									customChargebackfinalprice = +defaultListPrice;
								} else {
									customChargebackAmount = +csvRow.chargeback || 0;
									customChargebackfinalprice = +defaultListPrice - customChargebackAmount;
								}
							}

							if (csvRow.hasOwnProperty('price')) {
								csvRow.price = this.convertToNumber(csvRow.price);
								if (+csvRow.price > +defaultListPrice) {
									csvRow.isItemModifiedPrice = true;
									csvRow.isItemModifiedPriceLbl = +defaultListPrice;
									customChargebackfinalprice = +defaultListPrice;
									customChargebackAmount = 0;
								} else {
									customChargebackfinalprice = +csvRow.price || 0;
									customChargebackAmount = +defaultListPrice - +customChargebackfinalprice;
								}
							}
						}

						// if (csvRow.hasOwnProperty('chargeback')) {
						// 	customChargebackAmount = +csvRow.chargeback || 0;
						// }

						let itemTabModel = new ChargeBackItemTabModel();
						itemTabModel.guid = this.guid.newGuid();
						itemTabModel.erp_primary_key = itemEle.erp_primary_key || '';
						itemTabModel.itemsku = itemEle.itemsku || '';
						itemTabModel.itemname = itemEle.itemname || '';
						itemTabModel.item_guid = itemEle.guid || '';
						itemTabModel.description = itemEle.description || '';
						itemTabModel.uomgroupentry = itemEle.uomgroupentry || '';
						itemTabModel.uomList = listOfSpecificUoms || [];
						itemTabModel.selectedUom = defaultSelectedUom;
						// itemTabModel.discountedPrice = itemEle.discountedPrice || '';
						itemTabModel.is_disbled_input_field = false;
						itemTabModel.listPrice = +defaultListPrice;
						itemTabModel.chargebackAmount = customChargebackAmount;
						itemTabModel.final_amount = customChargebackfinalprice;
						itemTabModel.bought = '';
						itemTabModel.sold = '';
						itemTabModel.claimed = '';
						itemTabModel.available = '';
						itemTabModel.last_invoice_date = '';
						this.itemTabDataList.unshift(JSON.parse(JSON.stringify(itemTabModel)));

					}
				}
			});
			this.isUploadExcelCsvFileItmGrpTab = true;
			if (this.csvResponse && this.csvResponse.fileName) {
				this.filterText = this.csvResponse.fileName;
			}
			this.checkImportItemSummary();
			if (this.itemTabDataList.length > 0) {
				this.itemTabDataSource = this.itemTabDataList;
				// on upload item not passed data on save method
				this._ChargebackContractService.setItemTabDataList(this.itemTabDataList);
				this.saveChargebackItemTabDataEvent.emit('uploaditem');
			}
		});
	}

	private fetchedAllItemsItmGrpTab() {
		let priceListNum = '';
		try {
			priceListNum = this.chargebackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'items');
		formData.append('is_dropdown', 'true');
		formData.append('view_fields', JSON.stringify(["guid", "erp_primary_key", "itemsku", "itemname", "description", "uomgroupentry"]));
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GOT_ITEMLIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
			}
			// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	// Method used to Fetch specific items details
	private fetchedItemDetailsByPricelistnum(itemGuidList: any[]) {
		let priceListNum = '';
		try {
			priceListNum = this.chargebackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'item_details');
		formData.append('is_dropdown', 'true');
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		let itemDetailsList = [];
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT', { itemDetailsList: itemDetailsList });
				this._LoaderService.hide();
			}
			// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	// Method used to get number of item who unmatched.
	private checkImportItemSummary(itemTabDataList?: any) {
		if (itemTabDataList && itemTabDataList.length > 0) {
			this.noOfTotalItem = this.itemTabDataList.length;
			this.noOfUnmatchedItem = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched === false).length;
			this.noOfUnmatchedItemUom = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
			this.noOfmatchedItemPrice = this.itemTabDataList.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
		} else {
			this.noOfTotalItem = this.listOfCsvRowForItmGrpTab.length;
			this.noOfUnmatchedItem = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched === false).length;
			this.noOfUnmatchedItemUom = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemUomMatched === 'unmatched').length;
			this.noOfmatchedItemPrice = this.listOfCsvRowForItmGrpTab.filter(itemEle => itemEle.isItemMatched && itemEle.isItemModifiedPrice).length;
		}
	}

	public doShowCsvItemsListItmGrpTab() {
		this.uploadItemsProps = {
			actionFor: 'showIgnoredItemList',
			listOfCsvRows: [],
			listOfUnmatchedItems: this.listOfCsvRowForItmGrpTab
		};
		this.isUploadItemsPopup = false;
		setTimeout(() => {
			this.isUploadItemsPopup = true;
		}, 20);
	}

	// #endregion for Bulk Upload items to datagrid
	// #region for Set UOMs
	private setlistOfUomBySelProduct(itemDetails: any) {
		let priceListNum = '';
		try {
			priceListNum = this.chargebackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = this.listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = this.listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = this.listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	// Method used to set Default Uom by uomgroupentry from item
	private setDefaultUomByUomGroupEntry(itemDetails: any) {
		const listOfSpecificUOM = [];
		if (itemDetails.uomgroupentry) {
			// const uomGroupLst = this._DataService.getUomGroups() || [];
			const uomGroupLst = this.listOfUomGroups || [];
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = this.listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						newItemUomPice.absentry = this.listOfUoms[uomIndex].absentry;
						newItemUomPice.code = this.listOfUoms[uomIndex].code;
						newItemUomPice.name = this.listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}
		}
		return listOfSpecificUOM;
	}
	private convertToNumber(str: string) {
		// return +str.replace(/[A-Za-z`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
		return +str;
	}

	public doCloseUploadItemsPopup(result) {
		this.isUploadItemsPopup = false;
		if (result) {
			this.csvResponse = result;
			if (result.listOfCsvRows && result.listOfCsvRows.length > 0) {
				this.itemTabDataList = [];
				this.itemTabDataSource = this.itemTabDataList;
				const listOfCsvRow = result.listOfCsvRows;
				listOfCsvRow.forEach(rowEle => {
					rowEle.guid = this.guid.newGuid();
					rowEle.description = '';
					rowEle.isItemMatched = false;
					rowEle.isItemUomMatched = 'unmatched';
					rowEle.isItemModifiedUom = '';
					rowEle.isItemModifiedPrice = false;
					rowEle.isItemModifiedPriceLbl = null;
				});
				this.listOfCsvRowForItmGrpTab = listOfCsvRow;
				this.doMatchExcelItemsWithOrignItems();
				this.fetchedAllItemsItmGrpTab();
			}
		}
	}
}
