import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import * as _ from 'underscore';
import { Guid } from '@app/models/guid';

@Component({
	selector: 'app-commission-tierbase-contract-template-list',
	templateUrl: './commission-tierbase-contract-template-list.component.html',
	styleUrls: ['./commission-tierbase-contract-template-list.component.css']
})
export class CommissionTierbaseContractTemplateListComponent implements OnInit, OnDestroy {

	//#region Local Variable

	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	private allPermissions: any[] = [];
	public pageOptions: PageOptions = new PageOptions();
	private saveCCTemplateSbsn: Subscription;
	private dataOutSalesPplReqSubscription: Subscription;
	public commissionPermission: string = 'INCENTIVE_COMMISSION_MODIFY';
	public isShowAction: boolean = true;
	private storedFiltersValue: any;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'Inactive' }];

	public filterValue: any[] = [];
	public commissionContractDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public salesPersonDataSource: any;
	public loginUserRoleList: any = [];
	public listOfStatuses: any[] = this._AppCommonSrvc.doGetBooleanStatusList();
	private isCellClickDetail: boolean = false;
	public listOfContractTypes = [
		{ value: 'standard', text: 'Standard' },
		{ value: 'growth_incentive', text: 'Growth Incentive' }
	];
	public listOfCalculationOnBasis: any[] = [
		{ value: 'grossprofit', text: 'Gross Profit' },
		{ value: 'revenue', text: 'Gross Sales' }
	];
	public listOfCalculateCommissionBy = [
		{ value: 'orders', text: 'Orders' },
		{ value: 'invoices', text: 'Invoices' }
	];
	public listOfSettlementPeriods: any[] = this._CommissionService.getListOfSettlementPeriods;
	public multipleContractDetails: any;
	public isShowMultipleContractPopup: boolean;
	private guid = new Guid();
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	private updateUserSettingSbsn: Subscription;
	private dataOutReqSubscription: Subscription;
	private dataInReqSubscription: Subscription;
	public listOfActions: any[];
	//#endregion

	//#region Constructor
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _Router: Router,
		private _CommissionService: CommissionService,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _LoaderService: LoaderService) {

		this.doLoadAgreementTemplateState = this.doLoadAgreementTemplateState.bind(this);
		this.doSaveAgreementTemplateState = this.doSaveAgreementTemplateState.bind(this);
	}
	//#endregion

	//#region Angular Life Cycle Methods

	ngOnInit() {
		this.preInIt();
	}

	ngOnDestroy() {
		if (this.saveCCTemplateSbsn) {
			this.saveCCTemplateSbsn.unsubscribe();
		}
		if (this.dataOutSalesPplReqSubscription) {
			this.dataOutSalesPplReqSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
	}

	// Method used to Init Tab and load data.
	private preInIt() {
		this.getAllPermissions();
		this.mainSectionStorageKey = ServerSections.INCENTIVES_LISTING_SETTING;
		this.stateStorageKey = ServerSections.INCENTIVES_AGREEMENT_TEMPLATES_LISTING;
		this.listOfActions = this._CommissionService.getListOfActionsForAgrementTemplateDatagrid() || [];
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
		this.storedFiltersValue = [{ dataType: 'string', dataField: 'is_active', searchMode: 'contains', filterValues: [true] }];
		this.applyStoredFilter();
		this.loadCommissionContractDataSource();
		this.getLoginUserRoleList();
		setTimeout(() => {
			this.getSalesPplList();
		}, 20);
	}

	private getAllPermissions() {
		// Get current permissions
		this.allPermissions = [];
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
		this.isShowAction = true;
		if ((this.allPermissions.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1)) {
			this.isShowAction = false;
		}
	}
	//#endregion

	//#region commission List Methods

	public addNewCommissionContract() {
		this._Router.navigate(['/contracts/commission-template/']);
		this._CommissionService.setEditCommissionGuid('');
		this._CommissionService.setEditCommissionTemplateGuid('');
	}

	public doEditCommissionContract(data) {
		if (this.isCellClickDetail) {
			return;
		}
		// if (!data.is_active) {
		// 	return false;
		// }
		this._Router.navigate(['/contracts/commission-template/']);
		this._CommissionService.setEditCommissionTemplateGuid(data.guid);
	}

	public createCommissionContract(data) {
		this._Router.navigate(['/contracts/commission/']);
		this._CommissionService.setEditCommissionTemplateGuid(data.guid);
	}

	public doCreateMultipleCommissionContracts(element: any) {
		this.multipleContractDetails = {
			contractTemplateGuid: element.guid,
			salesPersonDataSource: JSON.parse(JSON.stringify(this.salesPersonDataSource)),
		}
		this.doOpenMultipleContractPopup();
	}

	// Method used to Open Task Dialog
	private doOpenMultipleContractPopup() {
		this.isShowMultipleContractPopup = false;
		setTimeout(() => {
			this.isShowMultipleContractPopup = true;
		}, 300);
	}

	public doCloseMultipleContractPopup(event: any) {
		if (event) {
			this.isShowMultipleContractPopup = false;
		}
	}

	//#endregion

	//#region Dev_extreme Source

	// Get current login user usertype role list
	private getLoginUserRoleList() {
		this.loginUserRoleList = [];
		const userTypeList = this._LoginService.loginUser.account_detail.userType || [];
		if (userTypeList && userTypeList.length > 0) {
			const canReceiveIncentives: any = { can_receive_incentives: true };
			this.loginUserRoleList = _.map(_.where(userTypeList, canReceiveIncentives), (utl) => {
				return utl.user_type;
			});
		}
	}

	// Get sales people drop-list as per role
	private getSalesPplList() {
		const searchParams: any = [];
		const rolesArr = this.loginUserRoleList || [];
		searchParams.push({ role: rolesArr });
		searchParams.push({ status: 'active' });
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.SUBUSERS);
		formData.append('search', JSON.stringify(searchParams));
		formData.append('is_dropdown', 'true');
		if (this.dataOutSalesPplReqSubscription) {
			this.dataOutSalesPplReqSubscription.unsubscribe();
		}
		// this._LoaderService.show();
		this.dataOutSalesPplReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response.flag) {
					// this.salesPplList = response.data || [];
					this.salesPersonDataSource = response.data || [];
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				// this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private loadCommissionContractDataSource() {
		const viewFields = ['number', 'guid', 'ixcode', 'template_name', 'settlementPeriod', 'commissionPercent', 'is_active', 'contract_type', 'calculationOnBasis', 'calculate_commission_by'];

		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CCTEMPLATES,
			// view_fields: JSON.stringify(viewFields)
		};
		this.commissionContractDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	//#region commission List Methods
	changedCommissionContractStatusAction(commissionGuid, is_active) {
		const view_fields = ['number', 'guid', 'ixcode', 'template_name', 'is_active', 'settlementPeriod', 'commissionPercent'];
		if (this.allPermissions.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			return false;
		}
		this._LoaderService.show();
		const objUpdateData = { 'is_active': is_active };
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.CCTEMPLATES);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('guid', commissionGuid);
		formData.append('updateData', JSON.stringify(objUpdateData));
		formData.append('view_fields', JSON.stringify(view_fields));
		if (this.saveCCTemplateSbsn) {
			this.saveCCTemplateSbsn.unsubscribe();
		}
		this.saveCCTemplateSbsn = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.loadCommissionContractDataSource();
				} else {
					this._LoaderService.hide();
					this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
						this.isDisabledClearBtn = true;
						this.updateStatesClearBtn();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always"; columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'ADD':
					this.doAddContract();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowAction;
		}
	}

	private doAddContract() {
		this.addNewCommissionContract();
	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Commission_Contract_List.xlsx';
		worksheet = workbook.addWorksheet('Commission List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'settlementPeriod') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value === 'halfYearly' ? 'Bi-annually' : gridCell.value === 'yearly' ? 'Annually' : gridCell.value.toString().charAt(0).toUpperCase() + gridCell.value.toString().slice(1);
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatuses.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatuses[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		this.isCellClickDetail = false;
		if (e.column.dataField === "is_active") {
			this.isCellClickDetail = true;
			return;
		}
	}

	public getListOfStatus(data) {
		this.listOfStatuses.forEach(action => {
			action.label = action.label.toUpperCase();
		});
		this.listOfStatuses.forEach(action => {
			if (action.value === data.is_active) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	// #region for Datagrid State Storing functionality

	public doLoadAgreementTemplateState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveAgreementTemplateState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// #endregion

	public copyCommissionContract(data) {
		const formData = new FormData();
		data['guid'] = this.guid.newGuid();
		data['template_name'] = data['template_name'] + ' - Copy';
		const arryCcTemplates = [];
		arryCcTemplates.push(data);
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('cctemplates', JSON.stringify(arryCcTemplates));
		// this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					// this.loadCommissionContractDataSource();
					this.dataGridContainer.instance.refresh();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				// this._LoaderService.hide();
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				// this._LoaderService.hide();
			}
		});
	}
}
