// All Model for dx-popup property
export class ViewEndusersInvoiceDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
		this.detailView = '';
	}
	docNo: string;
	docType: string;
	detailView: string; //pop-up details view type invoice,Order,craditmemo etc.
}
export class ViewInvoiceDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docDate = '';
		this.docType = '';
		this.detailView = '';
	}
	docNo: string;
	docType: string;
	docDate?: any;
	detailView: string; //pop-up details view type invoice,Order,craditmemo etc.
}
export class ViewOrderChangeOrdereDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
		this.detailView = '';
	}
	docNo: string;
	docType: string;
	detailView: string; //pop-up details view type invoice,Order,craditmemo etc.
}
export class ViewCreditMemoDetailPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
		this.detailView = '';
	}
	docNo: string;
	docType: string;
	detailView: string; //pop-up details view type invoice,Order,craditmemo etc.
}
export class ViewPopUpModel {
	constructor() {
		this.docNo = '';
		this.docType = '';
		this.detailView = '';
	}
	docNo: string;
	docType: string;
	detailView: string; //pop-up details view type invoice,Order,craditmemo etc.
}

export class EndUserDetailReportModel {
	guid: string;
	end_user_id: string;
	end_user_name: string;
	end_user_code: string;
	pricelistno: string;
	pricelistname: string;
	itemname: string;
	description: string;
	selectedUom: string;
	total_sold: number; // sum of quantity
	sold_price: number;  //price
	end_user_price: number //End-user Price (final_amount)
	sold_type: string;
	sold_docnum: string;
	sold_docdate: Date;
	sold_quantity: number;
	total_claimed: number;// sum of quantity
	claim_no: string;
	claimedStatus: string;
	claim_docnum: string;
	claim_docdate: Date;
	selectedClaimedUom: string;
	claimed_qty: number;
	claim_approved_qty: number
	claim_chargebackAmount: number
	total_available: number;// sum of quantity
	available_chargebackAmount: number;
	available_docnum: string;
	available_docdate: Date;
	available_quantity: number;
	available_type: string;
	avlQty: number;
}
