import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IMG_ADD_FILE_SVG, IMG_CLOSE_SVG, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent, DxDropDownButtonComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { RebateStatusValEnum, RebateStatusLblEnum, ContractLifeCycleValEnum, SettlementPeriodsValEnum, SettlementPeriodsLblEnum } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import * as moment from 'moment';

@Component({
	selector: 'app-rebates-contract-listing',
	templateUrl: './rebates-contract-listing.component.html',
	styleUrls: ['./rebates-contract-listing.component.css']
})

export class RebatesContractListingComponent implements OnInit, OnChanges, OnDestroy {

	public SettlementPeriodsValEnum = SettlementPeriodsValEnum;
	public SettlementPeriodsLblEnum = SettlementPeriodsLblEnum;
	//#region Local Variable
	@Input() actionFrom: string;
	@Input() clientGuid: string;
	@Input() clientDetailsIP: any;
	private clientDetails: any;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	private allPermissions: any[] = [];
	public pageOptions: PageOptions = new PageOptions();
	public dataInReqSubscription: Subscription;
	public dataOutReqSubscription: Subscription;
	public isColumneVisible: boolean = true;
	public isDocuSignEnabled: boolean = false;
	// public storedFiltersValue: any = [];
	public listOfStatusHeaderFilter: any[] = [];
	public listOfContractStatuses: any[] = [];
	public listOfSettlementPeriodHeaderFilter: any[] = this._RebateService.getListOfSettlementPeriods;
	public listOfRebateFor: any[] = this._RebateService.getlistOfRebateFor;
	public listOfRebateType: any[] = this._RebateService.getListOfRebateType;
	private isCellClickDetail: boolean = false;
	public rebatesContractDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public RebateStatusValEnum = RebateStatusValEnum;
	public RebateStatusLblEnum = RebateStatusLblEnum;
	public contractLifecycleManagementConfig: string;
	public ContractLifeCycleValEnum = ContractLifeCycleValEnum;

	public filterValue = [];
	private storedFiltersValue = [];
	public isShowStatusColumn: boolean = true;
	public isShowRebateForColumn: boolean = true;
	public isShowRebateTypeColumn: boolean = true;
	public isShowSettlementPeriodColumn: boolean = true;
	public isShowStartDateColumn: boolean = true;
	public isShowEndDateColumn: boolean = true;
	private isShowFilteredText = true;
	private isShowClearBtn = true;
	private isShowFilterBtn = true;
	private viewFields = [];
	private guid = new Guid();
	private updateUserSettingSbsn: Subscription;
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	private isAddRebatePermission: boolean = true;
	@ViewChild('dropDownButtonRef') dropDownButtonRef: DxDropDownButtonComponent;
	public listOfActions: any[];
	public isUploadRebateContractPopup: boolean;
	public uploadRebateContractProps: any;
	public decimalPointForCurrency: number;
	private updateCurrentSettlementPeriodsSbsn: Subscription;
	public isShowLoaderIndicator: boolean;
	public isShowActionColumn: boolean = true;
	private fetchedContractDeailsSbsn: Subscription;
	private doCloseSettlementReportSbsn: Subscription;
	//#endregion

	//#region Constructor
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _RebateService: RebateService,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _LoaderService: LoaderService,
		private _IxDxAlertService: IxDxAlertService) {
		this.doLoadRebatesState = this.doLoadRebatesState.bind(this);
		this.doSaveRebatesState = this.doSaveRebatesState.bind(this);
	}
	//#endregion

	//#region Angular Life Cycle Methods

	ngOnInit() {
		this.preInIt();
	}

	ngOnChanges(changes: SimpleChanges) {
		// if (changes.clientDetailsIP) {
		// }
		if (changes.clientDetailsIP) {
			if (changes.clientDetailsIP.currentValue) {
				this.clientDetails = changes.clientDetailsIP.currentValue;
			}
		}
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.fetchedContractDeailsSbsn) {
			this.fetchedContractDeailsSbsn.unsubscribe();
		}
		if (this.doCloseSettlementReportSbsn) {
			this.doCloseSettlementReportSbsn.unsubscribe();
		}
	}

	//#endregion

	//#region rebates List Methods
	changedRebatesContractStatusAction(data, existingStatus, newstatus) {
		if (existingStatus === RebateStatusValEnum.EXPIRED && newstatus === 'copy_to_new_contract') {
			this.saveRebatesContract(data);
		} else {
			this.doUpdateRebateStatus(data, existingStatus, newstatus);
		}
	}

	//COPY TO NEW CONTRACT
	public saveRebatesContract(data: any) {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.COPY_CONTRACT);
		reqFormData.append('contracttype', 'rebatescontracts');
		reqFormData.append('contractguid', data.guid);

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
					this.loadRebatesContractDataSource();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//Update Status as Action
	public doUpdateRebateStatus(data: any, existingStatus: string, newstatus: string) {
		let chkValidate = this.checkValidation(existingStatus, newstatus);
		if (!chkValidate.isValidate) {
			return;
		}

		if (this.allPermissions.indexOf('INCENTIVE_REBATES_MODIFY') === -1) {
			return false;
		}
		let objUpdateData;
		switch (newstatus) {
			case RebateStatusValEnum.INACTIVE:
				objUpdateData = { 'status': newstatus, 'is_active': false };
				break;
			case RebateStatusValEnum.ACTIVE:
				objUpdateData = { 'status': newstatus, 'is_active': true };
				break;
			default:
				objUpdateData = { 'status': newstatus };
				break;
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('guid', data.guid);
		formData.append('updateData', JSON.stringify(objUpdateData));

		if (chkValidate.isResetDraft) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
				showTitle: false,
				subtitle: 'Reset to draft?',
				message: 'This will reset the contract to Draft status and notify the signer the contract has been cancelled. Do you want to proceed ?',
			};
			this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					this.doAPICallUpdateRebateStatus(formData);
				}
			});
		} else {
			this.doAPICallUpdateRebateStatus(formData);
		}
	}

	// Call data in API for Update rebate status
	public doAPICallUpdateRebateStatus(formData: any) {
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					this._LoaderService.hide();
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
					this.loadRebatesContractDataSource();
				} else {
					this._LoaderService.hide();
					this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Check Validation
	private checkValidation(existingStatus: string, newstatus: string): any {
		let isValidate = true;
		let isResetDraft = false;
		let returnvalue = { isValidate: isValidate, isResetDraft: isResetDraft };
		let lblMessage = "Can't Change Status from " + existingStatus + " to " + newstatus;
		if (existingStatus !== newstatus) {
			switch (existingStatus) {
				case RebateStatusValEnum.DRAFT:
					if (newstatus !== RebateStatusValEnum.SIGNATURE_REQUESTED) {
						isValidate = false;
					}
					break;
				case RebateStatusValEnum.EXPIRED:
					if (newstatus !== 'copy_to_new_contract') {
						isValidate = false;
					}
					break;
				case RebateStatusValEnum.INACTIVE:
					if (newstatus !== RebateStatusValEnum.ACTIVE) {
						isValidate = false;
					}
					break;
				case RebateStatusValEnum.ACTIVE:
					if (newstatus !== RebateStatusValEnum.DRAFT && newstatus !== RebateStatusValEnum.INACTIVE) {
						isValidate = false;
					}
					else if (newstatus === RebateStatusValEnum.DRAFT) {
						isResetDraft = true;
					}
					break;
				case RebateStatusValEnum.SIGNATURE_REQUESTED:
					if (newstatus !== RebateStatusValEnum.DRAFT) {
						isValidate = false;
					}
					else if (newstatus === RebateStatusValEnum.DRAFT) {
						isResetDraft = true;
					}
					break;
				case RebateStatusValEnum.SIGNED:
					if (newstatus !== RebateStatusValEnum.DRAFT) {
						isValidate = false;
					}
					else if (newstatus === RebateStatusValEnum.DRAFT) {
						isResetDraft = true;
					}
					break;
				default:
					break;
			}
		}

		if (!isValidate) {
			this._ToastrService.error(lblMessage || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		}
		returnvalue.isResetDraft = isResetDraft;
		returnvalue.isValidate = isValidate;
		return returnvalue;
	}

	// Method used to Set list of Status for Header Filter.
	private setStatusHeaderFilter(listOfArray: any[] = []) {
		this.listOfStatusHeaderFilter = [];
		const listOfHeaderFilter: any[] = [];
		listOfArray.forEach(status => {
			listOfHeaderFilter.push({ value: status.value, text: status.label, bgcolor: status.bgcolor });
		});
		this.listOfStatusHeaderFilter = listOfHeaderFilter;
	}

	public addNewRebatesContract() {
		this._RebateService.setEditRebatesGuid('');
		if (this.actionFrom === 'rebates') {
			this._RebateService.setActionFrom('rebates');
			this._Router.navigate(['/contracts/rebates/']);
		}
		if (this.actionFrom === 'crms') {
			this._RebateService.setActionFrom('crms');
			this._Router.navigate(['rebates/'], { relativeTo: this._ActivatedRoute });
		}
	}

	public editRebatesContract(data) {
		if (this.isCellClickDetail) {
			return;
		}
		this._RebateService.setEditRebatesGuid(data.guid);
		if (this.actionFrom === 'rebates') {
			this._RebateService.setActionFrom('rebates');
			this._Router.navigate(['../rebates/'], { relativeTo: this._ActivatedRoute });
		}
		if (this.actionFrom === 'crms') {
			this._RebateService.setActionFrom('crms');
			// this._Router.navigate(['/contracts/rebates/'], { relativeTo: this._ActivatedRoute });
			this._Router.navigate(['rebates'], { relativeTo: this._ActivatedRoute });
		}
	}

	//#endregion

	//#region Dev_extreme Source

	private loadRebatesContractDataSource() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.REBATESCONTRACTS,
			view_fields: JSON.stringify(this.viewFields)
		};
		this.rebatesContractDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
				visible: this.isShowFilteredText,
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: this.isShowClearBtn,
				options: {
					icon: 'cleariconix',
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				visible: this.isShowFilterBtn,
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateUpdateCurrentSettlementBtn',
				visible: true,
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);

		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}

	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'ADD':
					this.doAddContract();
					break;
				case 'IMPORT':
					this.doImportToCSV();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isAddRebatePermission;
		}
	}

	private doAddContract() {
		if (this.actionFrom === 'rebates') {
			this._RebateService.setActionFrom('rebates');
			this._RebateService.setEditRebatesGuid('');
			this._Router.navigate(['/contracts/rebates/']);
		} else if (this.actionFrom === 'crms') {
			this._RebateService.setActionFrom('crms');
			this._RebateService.setEditRebatesGuid('');
			this._Router.navigate(['rebates'], { relativeTo: this._ActivatedRoute });
		}
	}

	// Method used to Refresh DataGrid
	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		const fileName = 'Rebates_Contract_List.xlsx';
		worksheet = workbook.addWorksheet('Rebates List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'rebateType') {
						excelCell.value = '';
						let colorName = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value === 'sales' ? 'Sales' : gridCell.value === 'purchasing' ? 'Purchasing' : '';
						}
					}
					if (gridCell.column.dataField === 'settlementPeriod') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value === 'halfYearly' ? 'Bi-annually' : gridCell.value === 'yearly' ? 'Annually' : gridCell.value.toString().charAt(0).toUpperCase() + gridCell.value.toString().slice(1);
						}
					}
					if (gridCell.column.dataField === 'status') {
						excelCell.value = '';
						// let colorName = '21b3c6';
						// this.listOfContractStatuses.filter(a => a.value === gridCell.value)[0].bgcolor;
						switch (gridCell.value) {
							case RebateStatusValEnum.ACTIVE:
								excelCell.value = RebateStatusLblEnum.ACTIVE
								break;
							case RebateStatusValEnum.INACTIVE:
								excelCell.value = RebateStatusLblEnum.INACTIVE
								break;
							case RebateStatusValEnum.DRAFT:
								excelCell.value = RebateStatusLblEnum.DRAFT
								break;
							case RebateStatusValEnum.EXPIRED:
								excelCell.value = RebateStatusLblEnum.EXPIRED
								break;
							case RebateStatusValEnum.SIGNATURE_REQUESTED:
								excelCell.value = RebateStatusLblEnum.SIGNATURE_REQUESTED
								break;
							case RebateStatusValEnum.SIGNED:
								excelCell.value = RebateStatusLblEnum.SIGNED
								break;
							default:
								break;
						}
						// colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		// e.cancel = true;
	}

	public doImportToCSV() {
		this.doOpenUploadRebateContractPopup();
		// this._MatDialog.open(UploadRebateContractDialogComponent).afterClosed().subscribe(result => {
		// 	this.dataGridContainer.instance.cancelEditData();
		// 	if (result) {
		// 		this.doRefreshDataGrid();
		// 	}
		// });
	}

	private doOpenUploadRebateContractPopup() {
		this.isUploadRebateContractPopup = false;
		setTimeout(() => {
			this.isUploadRebateContractPopup = true;
		}, 300);
	}

	public doCloseUploadRebateContractPopup() {
		this.isUploadRebateContractPopup = false;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	//#endregion

	private doCheckEnabledDocuSigned() {
		try {
			this.contractLifecycleManagementConfig = this._LoginService.loginUser.account_detail.contract_lifecycle_management;
		} catch (e) {
			this.contractLifecycleManagementConfig = '';
		}
	}

	private setListOfRebateStatuses() {
		this.listOfContractStatuses = [];
		if (this.contractLifecycleManagementConfig === ContractLifeCycleValEnum.PER_CONTRACT_BASIS || this.contractLifecycleManagementConfig === ContractLifeCycleValEnum.ALWAYS_ON) {
			try {
				this.listOfContractStatuses = this._LoginService.loginUser.account_detail.rebate_status_list || [];
			} catch (e) { }
		} else if (this.contractLifecycleManagementConfig === ContractLifeCycleValEnum.ALWAYS_OFF) {
			let listOfContractStatuses = [];
			this.listOfContractStatuses = [];
			try {
				listOfContractStatuses = this._LoginService.loginUser.account_detail.rebate_status_list || [];
			} catch (e) { }
			const activeIndex = listOfContractStatuses.findIndex(a => a.value === RebateStatusValEnum.ACTIVE);
			if (activeIndex !== -1) {
				this.listOfContractStatuses.push(listOfContractStatuses[activeIndex]);
			}
			const inactiveIndex = listOfContractStatuses.findIndex(a => a.value === RebateStatusValEnum.INACTIVE);
			if (inactiveIndex !== -1) {
				this.listOfContractStatuses.push(listOfContractStatuses[inactiveIndex]);
			}
		}
		this.setStatusHeaderFilter(this.listOfContractStatuses);
	}

	private setDefaultFilters() {
		this.storedFiltersValue = [];
		if (this.contractLifecycleManagementConfig === ContractLifeCycleValEnum.ALWAYS_OFF) {
			const activeStatusIndex = this.storedFiltersValue.findIndex(filter => filter.dataField === 'status');
			if (activeStatusIndex === -1) {
				this.storedFiltersValue.push({ dataType: 'string', dataField: 'status', searchMode: 'equal', filterValues: [RebateStatusValEnum.ACTIVE] });
			} else {
				this.storedFiltersValue[activeStatusIndex] = { dataType: 'string', dataField: 'status', searchMode: 'equal', filterValues: [RebateStatusValEnum.ACTIVE] };
			}
		}
	}

	// #region for Rebate Permissions
	private setAllPermissions() {
		this.allPermissions = [];
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const permission in permissions) {
			this.allPermissions.push(permission);
		}
		this.isAddRebatePermission = true;
		this.isShowActionColumn = true;
		const isAddPermissionExists = this.allPermissions.findIndex(p => p === 'INCENTIVE_REBATES_MODIFY');
		if (isAddPermissionExists === -1) {
			this.isAddRebatePermission = false;
			this.isShowActionColumn = false;
		}
	}
	//#endregion

	// #region for CRM/Rebate
	private preInitForCrm() {
		this.mainSectionStorageKey = ServerSections.CRM_CUSTOMER_DETAILS_LISTING_SETTING;
		this.stateStorageKey = ServerSections.CRM_CUSTOMER_DETAILS_REBATES_LISTING;

		this.viewFields = ['guid', 'ixcode', 'rebate_for', 'rebateType', 'contractName', 'cardcode', 'startDate', 'endDate', 'settlementPeriod', 'status', 'is_contract_lifecycle_management', 'settlementPeriodStart', 'settlementPeriodEnd', 'current_settlement_rebate_total'];
		this.isShowRebateForColumn = false;
		this.isShowRebateTypeColumn = false;
		this.isShowSettlementPeriodColumn = true;
		this.isShowStartDateColumn = true;
		this.isShowEndDateColumn = true;
		this.isShowFilteredText = false;
		this.isShowClearBtn = false;
		this.isShowFilterBtn = false;
		this.setCommonColumnHideShow();
		const buyingGroupIndex = this.listOfRebateFor.findIndex(rf => rf.value === 'buyinggroup');
		this.listOfRebateFor.splice(buyingGroupIndex, 1);
	}

	private doFiltersForCrm() {
		this.storedFiltersValue = [];
		if (this.contractLifecycleManagementConfig === ContractLifeCycleValEnum.ALWAYS_OFF) {
			const activeStatusIndex = this.storedFiltersValue.findIndex(filter => filter.dataField === 'status');
			if (activeStatusIndex === -1) {
				this.storedFiltersValue.push({ dataType: 'string', dataField: 'status', searchMode: 'equal', filterValues: [RebateStatusValEnum.ACTIVE] });
			} else {
				this.storedFiltersValue[activeStatusIndex] = { dataType: 'string', dataField: 'status', searchMode: 'equal', filterValues: [RebateStatusValEnum.ACTIVE] };
			}
		}
		const cardCodeIndex = this.storedFiltersValue.findIndex(filter => filter.dataField === 'cardcode');
		if (cardCodeIndex === -1) {
			this.storedFiltersValue.push({ dataType: 'string', dataField: 'cardcode', searchMode: 'equal', filterValues: [this.clientDetails.code] });
		}

		/*
		const rebateForIndex = this.storedFiltersValue.findIndex(filter => filter.dataField === 'rebate_for');
		if (rebateForIndex === -1) {
			const filterValues = [];
			this.listOfRebateFor.filter(rf => rf.value === 'individual' || rf.value === 'growth_incentive').map(x => filterValues.push(x.value));
			this.storedFiltersValue.push({ dataType: 'string', dataField: 'rebate_for', searchMode: 'equal', filterValues: filterValues });
		}

		const rebateTypeIndex = this.storedFiltersValue.findIndex(filter => filter.dataField === 'rebateType');
		if (rebateTypeIndex === -1) {
			const filterValues = [];
			this.listOfRebateType.filter(rf => rf.value === 'sales').map(x => filterValues.push(x.value));
			this.storedFiltersValue.push({ dataType: 'string', dataField: 'rebateType', searchMode: 'equal', filterValues: filterValues });
		}
		*/
	}

	private preInitForRebate() {
		this.mainSectionStorageKey = ServerSections.INCENTIVES_LISTING_SETTING;
		this.stateStorageKey = ServerSections.INCENTIVES_REBATES_LISTING;
		this.viewFields = ['guid', 'ixcode', 'rebate_for', 'rebateType', 'contractName', 'startDate', 'endDate', 'settlementPeriod', 'status', 'is_contract_lifecycle_management', 'settlementPeriodStart', 'settlementPeriodEnd', 'current_settlement_rebate_total'];
		this.isShowRebateForColumn = true;
		this.isShowRebateTypeColumn = true;
		this.isShowSettlementPeriodColumn = true;
		this.isShowStartDateColumn = true;
		this.isShowEndDateColumn = true;
		this.setCommonColumnHideShow();
	}

	private setCommonColumnHideShow() {
		this.isShowStatusColumn = true;
		if ((this.allPermissions.indexOf('INCENTIVE_REBATES_MODIFY') === -1)) {
			this.isShowStatusColumn = false;
		}
	}

	private preInIt() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.setAllPermissions();
		this.doCheckEnabledDocuSigned();
		this.setListOfRebateStatuses();
		this.listOfActions = this._RebateService.getListOfActionsForDatagrid();
		if (this.actionFrom === 'rebates') {
			this.setDefaultFilters();
			this.applyStoredFilter();
			this.preInitForRebate();
		} else if (this.actionFrom === 'crms') {
			this.doFiltersForCrm();
			this.applyStoredFilter();
			this.preInitForCrm();
		}
		this.loadRebatesContractDataSource();
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
	}
	//#endregion

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		this.isCellClickDetail = false;
		if (e.column.dataField === "status") {
			this.isCellClickDetail = true;
			return;
		}
	}

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatusHeaderFilter.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatusHeaderFilter[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	public getListOfAlwaysOffStatus(data) {
		this.listOfContractStatuses.forEach(action => {
			action.label = action.label.toUpperCase();
		});
		if (data.status === RebateStatusValEnum.ACTIVE) {
			this.listOfContractStatuses.forEach(action => {
				if (action.value === RebateStatusValEnum.INACTIVE) {
					action.visible = true;
				} else {
					action.visible = false;
				}
			});
		} else if (data.status === RebateStatusValEnum.INACTIVE) {
			this.listOfContractStatuses.forEach(action => {
				if (action.value === RebateStatusValEnum.ACTIVE) {
					action.visible = true;
				} else {
					action.visible = false;
				}
			});
		}
	}
	public getListOfAlwaysOnStatus(data) {
		this.listOfContractStatuses.forEach(action => {
			action.visible = false;
			action.label = action.label.toUpperCase();
		});

		if (this.contractLifecycleManagementConfig === ContractLifeCycleValEnum.ALWAYS_ON) {
			if (data.status === RebateStatusValEnum.SIGNATURE_REQUESTED || data.status === RebateStatusValEnum.SIGNED || data.status === RebateStatusValEnum.ACTIVE) {
				this.listOfContractStatuses.forEach(action => {
					if (action.value === RebateStatusValEnum.DRAFT) {
						action.visible = true;
						action.label = 'RESET TO DRAFT';
					}
				});
			}
			if (data.status === RebateStatusValEnum.DRAFT) {
				this.listOfContractStatuses.forEach(action => {
					if (action.value === RebateStatusValEnum.SIGNATURE_REQUESTED) {
						action.visible = true;
						action.label = 'Request Signature';
					}
				});
			}
			if (data.status === RebateStatusValEnum.EXPIRED) {
				this.listOfContractStatuses.push({
					"bgcolor": "#21b3c6",
					"value": "copy_to_new_contract",
					"label": "COPY TO NEW CONTRACT",
					"type": "default",
					"visible": true
				})
			}
		}
		if (this.contractLifecycleManagementConfig === ContractLifeCycleValEnum.PER_CONTRACT_BASIS) {
			if (data.is_contract_lifecycle_management) {
				if (data.status === RebateStatusValEnum.SIGNATURE_REQUESTED || data.status === RebateStatusValEnum.SIGNED || data.status === RebateStatusValEnum.ACTIVE) {
					this.listOfContractStatuses.forEach(action => {
						if (action.value === RebateStatusValEnum.DRAFT) {
							action.visible = true;
							action.label = 'RESET TO DRAFT';
						}
					});
				}
				if (data.status === RebateStatusValEnum.DRAFT) {
					this.listOfContractStatuses.forEach(action => {
						if (action.value === RebateStatusValEnum.SIGNATURE_REQUESTED) {
							action.visible = true;
							action.label = 'Request Signature';
						}
					});
				}
				if (data.status === RebateStatusValEnum.EXPIRED) {
					this.listOfContractStatuses.push({
						"bgcolor": "#21b3c6",
						"value": "copy_to_new_contract",
						"label": "COPY TO NEW CONTRACT",
						"type": "default",
						"visible": true
					})
				}
			}
			if (!data.is_contract_lifecycle_management) {
				if (data.status === RebateStatusValEnum.ACTIVE) {
					this.listOfContractStatuses.forEach(action => {
						if (action.value === RebateStatusValEnum.INACTIVE) {
							action.visible = true;
						} else {
							action.visible = false;
						}
					});
				} else if (data.status === RebateStatusValEnum.INACTIVE) {
					this.listOfContractStatuses.forEach(action => {
						if (action.value === RebateStatusValEnum.ACTIVE) {
							action.visible = true;
						} else {
							action.visible = false;
						}
					});
				}
			}
		}

	}
	public doTaskMenuAction(e: any, element: any) {
		if (e && e.itemData) {
			switch (e.itemData.value) {
				case RebateStatusValEnum.DRAFT:
					this.changedRebatesContractStatusAction(element.data, element.data.status, RebateStatusValEnum.DRAFT)
					break;
				case RebateStatusValEnum.SIGNATURE_REQUESTED:
					this.changedRebatesContractStatusAction(element.data, element.data.status, RebateStatusValEnum.DRAFT)
					break;
				case 'copy_to_new_contract':
					this.changedRebatesContractStatusAction(element.data, element.data.status, 'copy_to_new_contract')
					break;
				case RebateStatusValEnum.ACTIVE:
					this.changedRebatesContractStatusAction(element.data, element.data.status, RebateStatusValEnum.INACTIVE)
					break;
				case RebateStatusValEnum.INACTIVE:
					this.changedRebatesContractStatusAction(element.data, element.data.status, RebateStatusValEnum.ACTIVE)
					break;
				default:
					break;
			}
		}
	}

	public doLoadRebatesState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveRebatesState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	public doUpdateCurrentSettlementPeriods() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_MULTIPLE_OPEN_SETTELEMENT_PERIOD);
		formData.append('contracttype', 'rebatescontracts');
		if (this.updateCurrentSettlementPeriodsSbsn) {
			this.updateCurrentSettlementPeriodsSbsn.unsubscribe();
		}
		this._ToastrService.info('Update Current Settlement Process has been started', 'Info', { closeButton: true, tapToDismiss: true, newestOnTop: true });
		this.isShowLoaderIndicator = true;
		this.updateCurrentSettlementPeriodsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this.isShowLoaderIndicator = false;
				if (response.flag) {
					this._ToastrService.success('Update Current Settlement Process has been completed', 'Success', { closeButton: true, tapToDismiss: true, newestOnTop: true });
					this.doRefreshDataGrid();
				}
			}, error: (error) => {
				this.isShowLoaderIndicator = false;
			}
		});
	}

	public doCopyContract(element: any) {
		console.log('doCopyContract', element);
		const searchParams = [{ 'guid': element.guid }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('search', JSON.stringify(searchParams));
		if (this.fetchedContractDeailsSbsn) {
			this.fetchedContractDeailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.fetchedContractDeailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const rebatesContractDetails = response.data[0];
						rebatesContractDetails.current_settlement_invoice_total || 0;
						rebatesContractDetails.current_settlement_creditmemo_total || 0;
						rebatesContractDetails.current_settlement_sales_total || 0;
						rebatesContractDetails.current_settlement_rebate_total || 0;
						delete rebatesContractDetails.created_date;
						delete rebatesContractDetails.modified_date;
						delete rebatesContractDetails.number;
						this.doSendCopyContract(rebatesContractDetails);
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private doSendCopyContract(rebatesContractDetails) {
		const rebatesContractClone = JSON.parse(JSON.stringify(rebatesContractDetails));
		rebatesContractClone.guid = this.guid.newGuid();
		rebatesContractClone.contractName = rebatesContractClone.contractName + ' - Copy';
		rebatesContractClone.ixcode = 'IXREBCON' + new Date(this._LoginService.loginUser.server_date).getTime();
		const getSettlementPeriodStatAndEndDate = this._RebateService.doGenerateSettlementPeriodStartAndEndDate(rebatesContractClone);
		rebatesContractClone.settlementPeriodStart = getSettlementPeriodStatAndEndDate.settlementPeriodStart ? moment(getSettlementPeriodStatAndEndDate.settlementPeriodStart).format('YYYY-MM-DD') : null;
		rebatesContractClone.settlementPeriodEnd = getSettlementPeriodStatAndEndDate.settlementPeriodEnd ? moment(getSettlementPeriodStatAndEndDate.settlementPeriodEnd).format('YYYY-MM-DD') : null;

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('rebatescontracts', JSON.stringify([rebatesContractClone]));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					this._ToastrService.success('Contract successfuly copied.', 'Success', { closeButton: true, tapToDismiss: true });
					this.doRefreshDataGrid();
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}
}
