<div [hidden]="!resourcesLoaded" class="loaderParent">
	<mat-progress-spinner class="" [color]="loaderState.color" [value]="loaderState.value">
	</mat-progress-spinner>
</div>

<div [hidden]="resourcesLoaded">
	<div class="subTitleRow">
		<h2>Open Tasks Listing</h2>
		<a (click)="goToCrmTasks()" class="viewAllBtn">View All
			<span class="rightArrow"></span>
		</a>
	</div>
	<div class="tableOut">
		<dx-data-grid #dataGridContainer class="colorList" id="gridContainer" [dataSource]="dxDataSource"
			[showBorders]="true" (onRowClick)="doEditTask($event.data)" [remoteOperations]="true"
			[filterValue]="filterValue" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
			[columnAutoWidth]="true" [width]="'100%'" (onToolbarPreparing)="doToolbarPreparing($event)"
			[filterSyncEnabled]="true" class="hideFilterPanelInx" (onCellClick)="doCellClickDetails($event)">

			<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
			<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
				[showInfo]="true">
			</dxo-pager>
			<dxo-export [enabled]="false"></dxo-export>
			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-filter-panel [visible]="true"></dxo-filter-panel>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
			<div *dxTemplate="let data of 'templateFilterText'">
				<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
					<span>{{filterText}}</span>
				</div>
			</div>
			<dxi-column dataField="clientname" caption="Company" cellTemplate="cellTemplateclientname"
				[allowHeaderFiltering]="false" dataType="string">
				<div *dxTemplate="let data of 'cellTemplateclientname'">
					<span class="proIcon center">
						<span class="icon-default-allcompany icon-set1"></span>
					</span>
					<span class="highlight mrgL10 pointerMark">
						{{data.value}}
					</span>
				</div>
			</dxi-column>

			<dxi-column dataField="contactpersonname" caption="Contact Person" [allowHeaderFiltering]="false"
				dataType="string">
			</dxi-column>

			<dxi-column dataField="tasktype" caption="Task Type" [allowFiltering]="false" [allowHeaderFiltering]="true"
				[allowSorting]="false" cellTemplate="cellTemplatecontactfrom" dataType="string">
				<dxo-header-filter [dataSource]="listOfTaskTypeHeaderFilter"></dxo-header-filter>
				<div *dxTemplate="let element of 'cellTemplatecontactfrom'">
					{{element.data.tasktype || '' | filterFromList:listOfTaskTypes : 'value' : 'label'}}
				</div>
			</dxi-column>

			<dxi-column dataField="title" caption="Task Title" [allowHeaderFiltering]="false" dataType="string">
			</dxi-column>

			<dxi-column dataField="enddate" caption="Due Date" cellTemplate="cellTemplateenddate" dataType="date"
				format="yyyy-MM-dd" sortOrder="desc" [allowHeaderFiltering]="false">
				<div *dxTemplate="let element of 'cellTemplateenddate'">
					{{element.data.enddate || '' | customDateFormatPipe:'dateonly'}}
					<br *ngIf="element.data.timeinterval" />
					{{element.data.timeinterval || ''}}
				</div>
			</dxi-column>

			<dxi-column dataField="assignto" caption="Assigned To" cellTemplate="cellTemplateassignto"
				[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="false" dataType="string">
				<dxo-header-filter [dataSource]="listOfAssignToHeaderFilter"></dxo-header-filter>
				<div *dxTemplate="let element of 'cellTemplateassignto'">
					{{element.data.assignto || '' | multifilterFromList:listOfAssignToUsers : 'sub_account_id' :
					'first_name,last_name'}}
				</div>
			</dxi-column>

			<dxi-column dataField="status" caption="Status" cellTemplate="cellTemplatestatus" dataType="string"
				[allowFiltering]="false" [allowHeaderFiltering]="true">
				<dxo-header-filter [dataSource]="listOfTaskStatusHeaderFilter"></dxo-header-filter>
				<div *dxTemplate="let element of 'cellTemplatestatus'">
					<div class="tooltip skyblueToolTip">
						<span class="toolTipTx" id="imgtooltip_{{element.data.guid}}">
							{{element.data.status || '' | filterFromList:listOfTaskStatuses : 'btnCode' : 'btnName'}}
						</span>
					</div>
				</div>
			</dxi-column>

			<dxi-column dataField="guid" caption="Action" alignment="center" cellTemplate="cellTemplateGUID"
				[allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false" [allowResizing]="false"
				[minWidth]="65" [width]="65" [allowExporting]="false" dataType="string">
				<div *dxTemplate="let data of 'cellTemplateGUID'">
					<dx-drop-down-button [splitButton]="false" [showArrowIcon]="true" [useSelectMode]="false" text=""
						stylingMode="contained" class="secondaryBtn actionBtnBorderNone actionBtnIx"
						[items]="listOfTaskActions" [wrapItemText]="true" [useSelectMode]="true" displayExpr="btnName"
						keyExpr="btnCode" (onButtonClick)="doGetTaskActions(data.data)"
						(onItemClick)="doTaskActions($event,data.data)" [dropDownOptions]="{ width: 230 }">
					</dx-drop-down-button>
				</div>
			</dxi-column>

		</dx-data-grid>

	</div>
	<div class="tableEntries cf" *ngxPermissionsOnly="['CRM_TASK_MODIFY']">
		<div class="titleBtnCol alignRight">
			<dx-button class="secondaryBtn button" text="Add New" icon="add" type="normal" stylingMode="contained"
				(onClick)="addNewTask()" [useSubmitBehavior]="false">
			</dx-button>
		</div>
	</div>
</div>

<app-ap-add-edit-task-dialog *ngIf="isShowAddEditTaskPopup" [addeditTaskDetailsProps]="addeditTaskDetailsProps"
	(closeAddEditTaskPopup)="doCloseAddEditTaskPopup($event)"></app-ap-add-edit-task-dialog>