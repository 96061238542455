import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '@app/services/data.service';

@Pipe({
	name: 'showPaymentCardAddressesPipe'
})
export class ShowPaymentCardAddressesPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		const addressObj = value;
		let addressStr = '';
		if (addressObj.billto_address) {
			addressStr = addressObj.billto_address + ',<br/>';
		}
		if (addressObj.billto_city) {
			addressStr = addressStr + addressObj.billto_city + ', ';
		}
		if (addressObj.billto_state) {
			addressStr = addressStr + addressObj.billto_state + ', ';
		}
		if (addressObj.billto_zip) {
			addressStr = addressStr + addressObj.billto_zip + ', ';
		}
		if (addressObj.billto_country) {
			addressStr = addressStr + addressObj.billto_country;
		}
		return addressStr;
	}
}


@Pipe({
	name: 'showCreditCardKnoxAddressesPipe'
})
export class ShowCreditCardKnoxAddressesPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		const addressObj = value;
		let addressStr = '';

		if (addressObj.address1) {
			addressStr = addressObj.address1 + ',<br/>';
			if (addressObj.address2) {
				addressStr = addressObj.address2 + ',<br/>';
			}
		}
		if (addressObj.city) {
			addressStr = addressStr + addressObj.city + ', ';
		}
		if (addressObj.state) {
			addressStr = addressStr + addressObj.state + ', ';
		}
		if (addressObj.zip) {
			addressStr = addressStr + addressObj.zip + ', ';
		}
		if (addressObj.country) {
			addressStr = addressStr + addressObj.country;
		}
		return addressStr;
	}
}


@Pipe({
	name: 'showClientAddressesPipe'
})
export class ShowClientAddressesPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		let addressText: string;
		let isSeperator: Boolean;
		addressText = '';
		const address = value || '';
		if (address) {
			isSeperator = true;
			// if (address.block) {
			// 	addressText = addressText + address.block;
			// 	if (isSeperator) {
			// 		addressText = addressText + ', ';
			// 	}
			// }
			// if (address.streetno) {
			// 	addressText = address.streetno;
			// 	if (isSeperator) {
			// 		addressText = addressText + ', ';
			// 	}
			// }
			// if (address.street) {
			// 	addressText = addressText + address.street;
			// 	if (isSeperator) {
			// 		addressText = addressText + ', ';
			// 	}
			// 	addressText = addressText + '<br/>';
			// }
			if (address.addressname2) {
				addressText = addressText + address.addressname2;
				if (isSeperator) {
					addressText = addressText + ', ';
					addressText = addressText + '<br/>';
				}
			}
			if (address.addressname3) {
				addressText = addressText + address.addressname3;
				if (isSeperator) {
					addressText = addressText + ', ';
					addressText = addressText + '<br/>';
				}
			}
			if (address.city) {
				addressText = addressText + address.city;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
			}
			if (address.state) {
				addressText = addressText + address.state;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
				addressText = addressText + '';
			}
			if (address.zipcode) {
				addressText = addressText + address.zipcode;
				if (isSeperator && address.country) {
					addressText = addressText + ', ';
				}
			}
			if (address.country) {
				addressText = addressText + address.country;
				isSeperator = false;
			}
			return addressText;
		}
	}
}

@Pipe({
	name: 'showClientPhonePipe'
})
export class ShowClientPhonePipe implements PipeTransform {

	constructor(private _DataService: DataService) {

	}

	transform(value: any, propertyName?: any): any {
		let phonenumberstr = value[propertyName] || '';
		if (value['dialcode']) {
			const getPhoneCodeData = this._DataService.getSplitPhoneCode(value['dialcode']);
			if (getPhoneCodeData && getPhoneCodeData.length > 0) {
				phonenumberstr = '+' + getPhoneCodeData[0].code + ' ' + value[propertyName];
			}
		}
		return phonenumberstr;
	}
}


@Pipe({
	name: 'showZohoAddressesPipe'
})
export class ShowZohoAddressesPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		let addressText: string;
		let isSeperator: Boolean;
		addressText = '';
		const address = value || '';
		if (address) {
			isSeperator = true;
			if (address.street) {
				addressText = addressText + address.street;
				if (isSeperator) {
					addressText = addressText + ', ';
					// addressText = addressText + '<br/>';
				}
			}
			if (address.street2) {
				addressText = addressText + address.street2;
				if (isSeperator) {
					addressText = addressText + ', ';
					// addressText = addressText + '<br/>';
				}
			}
			if (address.city) {
				addressText = addressText + address.city;
				if (isSeperator) {
					addressText = addressText + ', ';
					// addressText = addressText + '<br/>';
				}
			}
			if (address.state) {
				addressText = addressText + address.state;
				if (isSeperator) {
					addressText = addressText + ', ';
					// addressText = addressText + '<br/>';
				}
				addressText = addressText + '';
			}
			if (address.zipcode) {
				addressText = addressText + address.zipcode;
				if (isSeperator && address.country) {
					addressText = addressText + ', ';
				}
			}
			if (address.zip) {
				addressText = addressText + address.zip;
				if (isSeperator && address.country) {
					addressText = addressText + ', ';
				}
			}
			if (address.country) {
				addressText = addressText + address.country;
				isSeperator = false;
			}
			return addressText;
		}
	}
}

@Pipe({
	name: 'showUnderscoreToSpacePipe'
})
export class ShowUnderscoreToSpacePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		const paymentGateway = value || '';
		if (paymentGateway) {
			const paymentGatewayStr = paymentGateway.toString().replace(/_/g, ' ');
			return paymentGatewayStr;
		} else {
			return paymentGateway;
		}
	}
}