import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RebateStatusValEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { ToastrService } from 'ngx-toastr';
import { environment } from "@environments/environment";
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { Subscription } from 'rxjs';
import { formatCurrency } from '@angular/common';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Guid } from '@app/models/guid';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-rebate-revenue-bar-chart',
	templateUrl: './rebate-revenue-bar-chart.component.html',
	styleUrls: ['./rebate-revenue-bar-chart.component.css'],
	providers: [CustomCurrencyPipe]
})
export class RebateRevenueBarChartComponent implements OnInit {
	public globalCurrency: string;
	@ViewChild('listOfContractDataGrid') listOfContractDataGrid: DxDataGridComponent;
	public isVisibleChartLoader: boolean = false;
	public isEnabledDocSign: boolean = false;
	public contractName: string;
	public settlementStartDate: string;
	public settlementEndDate: string;
	public sContractsLbl: string;
	public isOpenContractNameDropDownBox: boolean;
	public selectedContractKeys: string[] = [];
	public selectedContractDetails: any[] = [];
	public listOfContractsDataSource: any;
	public contractFilterValue: any[] = [];
	public listOfContractStatuses: any[] = [];
	public listOfStatusHeaderFilter: any[] = [];
	private getContractChartSbsn: Subscription;
	private getListOfContractsSbsn: Subscription;
	public contractsChart: any[] = [];
	public contractsAverageCounter: number = 0;
	public revenueAccruedTotal: any = 0;
	public contractsEstimatedTotal: any = 0;
	public constantLineText: any;
	public pageOptions: PageOptions = new PageOptions();
	public isCheckAllContracts: boolean = true;
	public startDate = new Date();
	public endDate = new Date();
	public minDateValue = new Date();
	private guid = new Guid();
	private updateUserSettingSbsn: Subscription;
	private getContractDetailsSbsn: Subscription;

	constructor(private _LoginService: LoginService,
		private _ChangeDetectorRef: ChangeDetectorRef,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _AppCommonSrvc: AppCommonSrvc) {
		this.doCustomizeTooltipInDollar = this.doCustomizeTooltipInDollar.bind(this);
		this.doCustomizeTextInDollarYAxis = this.doCustomizeTextInDollarYAxis.bind(this);
		this.doCustomizeLabel = this.doCustomizeLabel.bind(this);
		this.doCustomiseTextForCustomiseLabel = this.doCustomiseTextForCustomiseLabel.bind(this);
	}

	ngOnInit() {
		this.setEnabledDocuSignFeatures();
		this.getListOfStatuses();
		this.fetchedListOfContracts();
		this.setMinMaxDate();
		setTimeout(() => {
			this.getUserSettings();
		}, 100);
	}

	ngOnDestroy() {
		if (this.getContractChartSbsn) {
			this.getContractChartSbsn.unsubscribe();
		}
		if (this.getListOfContractsSbsn) {
			this.getListOfContractsSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.getContractDetailsSbsn) {
			this.getContractDetailsSbsn.unsubscribe();
		}
	}

	// Method used to check and set Document Sign features Enabled/Disabled
	private setEnabledDocuSignFeatures() {
		try {
			this.isEnabledDocSign = this._LoginService.loginUser.account_detail.is_docusign_enabled || false;
		} catch (e) {
			this.isEnabledDocSign = false;
		}
	}

	private setMinMaxDate() {
		let erp_data_start_date = new Date();
		try {
			erp_data_start_date = new Date(this._LoginService.loginUser.account_detail.app_settings.erp_data_start_date);
		} catch (e) {
			erp_data_start_date = new Date();
		}
		this.minDateValue = erp_data_start_date;
	}

	// Method used to get all statuses for Rebate Contract
	private getListOfStatuses() {
		this.listOfContractStatuses = [];
		let rebate_status_list = [];
		try {
			rebate_status_list = this._LoginService.loginUser.account_detail.rebate_status_list || [];
		} catch (e) {
			rebate_status_list = [];
		}
		if (this.isEnabledDocSign) {
			this.listOfContractStatuses = rebate_status_list;
		} else {
			const activeIndex = rebate_status_list.findIndex(a => a.value === RebateStatusValEnum.ACTIVE);
			if (activeIndex !== -1) {
				this.listOfContractStatuses.push(rebate_status_list[activeIndex]);
			}
			const inactiveIndex = rebate_status_list.findIndex(a => a.value === RebateStatusValEnum.INACTIVE);
			if (inactiveIndex !== -1) {
				this.listOfContractStatuses.push(rebate_status_list[inactiveIndex]);
			}
		}
		this.setStatusHeaderFilter();
	}

	// Method used to Set list of Status for Header Filter.
	private setStatusHeaderFilter() {
		this.listOfStatusHeaderFilter = [];
		const listOfHeaderFilter: any[] = [];
		this.listOfContractStatuses.forEach(status => {
			listOfHeaderFilter.push({ value: status.value, text: status.label });
		});
		this.listOfStatusHeaderFilter = listOfHeaderFilter;
	}

	// Method used to close Contract Name dropdown box
	public doContractNameOptionChanged(e) {
		if (e.name === "value") {
			this.isOpenContractNameDropDownBox = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	// Method used to Clear filter after closing dropdown.
	public doOpenedDropdownContract(e) {
		// this.listOfContractDataGrid.instance.clearFilter();
		// setTimeout(() => {
		// 	this.contractFilterValue = [['status', '=', RebateStatusValEnum.ACTIVE]];
		// }, 10);
	}

	private resetCounterAndDatagrid() {
		this.sContractsLbl = '';
		this.selectedContractKeys = [];
		this.selectedContractDetails = [];
		this.contractsChart = [];
		this.contractsAverageCounter = 0;
		this.revenueAccruedTotal = this._CustomCurrencyPipe.transform(0);
		this.contractsEstimatedTotal = this._CustomCurrencyPipe.transform(0);
		this.constantLineText = '';
	}

	public doChangeContractValue(e) {
		if (e.value === null) {
			this.resetCounterAndDatagrid();
			setTimeout(() => {
				this.doForAllContracts();
			}, 300);
		}
	}

	private fetchDefaultContract() {
		this.isCheckAllContracts = true;
		this.contractName = 'Revenue Forecast - All Contracts';
		let serverDate = new Date();
		try {
			serverDate = new Date(this._LoginService.loginUser.server_date);
		} catch (e) {
			serverDate = new Date();
		}
		let fiscalYearStart = 1;
		try {
			fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
		} catch (e) {
			fiscalYearStart = 1;
		}
		let startDate = new Date();
		let endDate = new Date();
		if ((new Date(serverDate).getMonth() + 1) >= fiscalYearStart) {
			startDate.setHours(0, 0, 0);
			startDate.setMonth(fiscalYearStart - 1, 1);
			const endMonth = startDate.getMonth() + 11;
			endDate.setMonth(endMonth);
			const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
			endDate.setDate(lastDay.getDate());
			endDate.setHours(23, 59, 59);
		} else {
			startDate = new Date(serverDate);
			startDate.setFullYear(serverDate.getFullYear() - 1);
			startDate.setMonth(fiscalYearStart - 1, 1);
			endDate = new Date(serverDate);
			endDate.setFullYear(serverDate.getFullYear() - 1);
			const endMonth = startDate.getMonth() + 11;
			endDate.setMonth(endMonth);
			const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
			endDate.setDate(lastDay.getDate());
			endDate.setHours(23, 59, 59);
		}
		this.startDate = startDate;
		this.endDate = endDate;
		this.settlementStartDate = startDate.getFullYear() + '-' + ((startDate.getMonth() < 10) ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)) + '-' + ((startDate.getDate() < 10) ? '0' + startDate.getDate() : startDate.getDate());
		this.settlementEndDate = endDate.getFullYear() + '-' + ((endDate.getMonth() < 10) ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)) + '-' + ((endDate.getDate() < 10) ? '0' + endDate.getDate() : endDate.getDate());
		this.setTextForContactDrpDwn();
		this.getContractChartData();
	}

	// Method used to Fetch list of Contracts
	public fetchedListOfContracts() {
		this.contractFilterValue = [['status', '=', RebateStatusValEnum.ACTIVE]];
		const viewFields = ['guid', 'contractName', 'settlementPeriodStart', 'settlementPeriodEnd', 'status'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.REBATESCONTRACTS,
			view_fields: JSON.stringify(viewFields),
		};
		this.listOfContractsDataSource = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onBeforeSend: function (method, ajaxOptions) {
			},
			onLoaded(result) {
			}
		});
	}

	// Method used to change event to check the checkbox for All Contracts.
	public doChangeAllContracts(e) {
		if (e.value) {
			this.listOfContractDataGrid.instance.clearSelection();
			this.doForAllContracts();
		}
	}

	// Method used to select the Contract from dropdown.
	public doSelectedChangedContract(e) {
		this.selectedContractDetails = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.selectedContractDetails.push(e.selectedRowsData[i]);
			}
		}

		this.isCheckAllContracts = false;
		this.setTextForContactDrpDwn();
		this.doShowContractName(e.selectedRowsData[0]);
		this.getContractChartData();
	}

	// Method used to set string on item dropdown
	private setTextForContactDrpDwn() {
		if (this.isCheckAllContracts) {
			this.sContractsLbl = 'All Contracts';
		} else {
			if (this.selectedContractDetails.length > 0) {
				this.sContractsLbl = this.selectedContractDetails[0].contractName;
			}
		}
	}

	// Method used to Show Contract Name and Settlement Start and End Dates.
	private doShowContractName(selectedCotract) {
		this.contractName = '';
		this.settlementStartDate = '';
		this.settlementEndDate = '';
		if (selectedCotract && selectedCotract.guid) {
			this.contractName = 'Revenue Forecast - ' + selectedCotract.contractName;
			this.settlementStartDate = selectedCotract.settlementPeriodStart;
			this.settlementEndDate = selectedCotract.settlementPeriodEnd;
		}
	}

	// Method used to Fetch contract Chart details
	private getContractChartData(isRefresh?: boolean) {
		this.contractsChart = [];
		this.contractsAverageCounter = 0;
		this.revenueAccruedTotal = this._CustomCurrencyPipe.transform(0);
		this.contractsEstimatedTotal = this._CustomCurrencyPipe.transform(0);
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.REBATE_REVENUE_DATA);
		formData.append('contracttype', 'rebatescontracts');
		formData.append('allcontracts', this.isCheckAllContracts.toString());
		if (this.isCheckAllContracts) {
			const startMonthYear = (((this.startDate.getMonth() + 1) < 10) ? '0' + (this.startDate.getMonth() + 1) : (this.startDate.getMonth() + 1)) + '-' + this.startDate.getFullYear();
			const endMonthYear = (((this.endDate.getMonth() + 1) < 10) ? '0' + (this.endDate.getMonth() + 1) : (this.endDate.getMonth() + 1)) + '-' + this.endDate.getFullYear();
			formData.append('startMonthYear', startMonthYear);
			formData.append('endMonthYear', endMonthYear);
		}
		if (!this.isCheckAllContracts) {
			formData.append('contractguid', this.selectedContractKeys[0]);
		}

		if (this.getContractChartSbsn) {
			this.getContractChartSbsn.unsubscribe();
		}
		this.isVisibleChartLoader = true;
		this.getContractChartSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this.isVisibleChartLoader = false;
				if (response) {
					if (response.flag) {
						if (response.data && response.data.length > 0) {
							const revenueForcast = response.data[0];
							this.contractsChart = revenueForcast.chartData || [];
							this.contractsAverageCounter = revenueForcast.average || 0;
							this.revenueAccruedTotal = this._CustomCurrencyPipe.transform(revenueForcast.revenue_accured_till_date || 0);
							this.contractsEstimatedTotal = this._CustomCurrencyPipe.transform(revenueForcast.estimated_revenue_total || 0);
							// this.constantLineText = 'Average: ' + this._CustomCurrencyPipe.transform(revenueForcast.average || 0);
							this.constantLineText = '';
						}
					} else {
						// this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this.isVisibleChartLoader = false;
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
		if (!isRefresh) {
			this.doUpdateUserSettings();
		}
	}

	public doCustomizeTooltipInDollar(info: any) {
		return {
			html: "<div> " + this._CustomCurrencyPipe.transform(info.value) + "</div>"
		};
	}

	public doCustomizeTextInDollarYAxis(e: any) {
		return this._CustomCurrencyPipe.transform(e.value);
	}

	public doCustomizeLabel = (arg: any) => {
		return {
			visible: true,
			customizeText: this.doCustomiseTextForCustomiseLabel
		};
	}

	private doCustomiseTextForCustomiseLabel(e: any) {
		return this._CustomCurrencyPipe.transform(e.valueText);
	}


	public doStartDateChanged(e) {
		const selectedDate = e.value;
		selectedDate.setDate(1);
		selectedDate.setHours(0, 0, 0);
		e.component.option('value', selectedDate);
		if (this.endDate < e.value) {
			this._ToastrService.info('Period From should be less than Period To', 'Info', { closeButton: true, tapToDismiss: true });
			setTimeout(() => {
				e.component.option('value', e.previousValue);
			}, 300);
			return;
		}
		if (e.previousValue) {
			if (this.startDate === selectedDate) {
				return;
			}
			this.startDate = selectedDate;
			this.settlementStartDate = this.startDate.getFullYear() + '-' + ((this.startDate.getMonth() < 10) ? '0' + (this.startDate.getMonth() + 1) : (this.startDate.getMonth() + 1)) + '-' + ((this.startDate.getDate() < 10) ? '0' + this.startDate.getDate() : this.startDate.getDate());
			this.settlementEndDate = this.endDate.getFullYear() + '-' + ((this.endDate.getMonth() < 10) ? '0' + (this.endDate.getMonth() + 1) : (this.endDate.getMonth() + 1)) + '-' + ((this.endDate.getDate() < 10) ? '0' + this.endDate.getDate() : this.endDate.getDate());
			this.getContractChartData();
		}
	}

	public doEndDateChanged(e) {
		const selectedDate = e.value;
		const lastDateOfMonth = new Date(+selectedDate.getFullYear(), (+selectedDate.getMonth() + 1), 0);
		selectedDate.setDate(lastDateOfMonth.getDate());
		selectedDate.setHours(23, 59, 59);
		e.component.option('value', selectedDate);
		if (e.value < this.startDate) {
			this._ToastrService.info('Period To should be greater than Period Start', 'Info', { closeButton: true, tapToDismiss: true });
			setTimeout(() => {
				e.component.option('value', e.previousValue);
			}, 300);
			return;
		}
		if (e.previousValue) {
			if (this.endDate === selectedDate) {
				return;
			}
			this.endDate = selectedDate;
			this.settlementStartDate = this.startDate.getFullYear() + '-' + ((this.startDate.getMonth() < 10) ? '0' + (this.startDate.getMonth() + 1) : (this.startDate.getMonth() + 1)) + '-' + ((this.startDate.getDate() < 10) ? '0' + this.startDate.getDate() : this.startDate.getDate());
			this.settlementEndDate = this.endDate.getFullYear() + '-' + ((this.endDate.getMonth() < 10) ? '0' + (this.endDate.getMonth() + 1) : (this.endDate.getMonth() + 1)) + '-' + ((this.endDate.getDate() < 10) ? '0' + this.endDate.getDate() : this.endDate.getDate());
			this.getContractChartData();
		}
	}

	public doRefreshChart() {
		this.getContractChartData(true);
	}

	// Method used to getUserSetting
	private getUserSettings() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === 'rebate_revenue_forecast');
				if (configIndex !== -1) {
					const rebateForcastConfig = dashboardSettings.configs[configIndex];
					const contractGuid = rebateForcastConfig.contractGuid;
					const startMonthYear = rebateForcastConfig.startMonthYear;
					const endMonthYear = rebateForcastConfig.endMonthYear;
					const startDate = new Date();
					const endDate = new Date();
					const startMonthYearSplit = startMonthYear.split('-');
					startDate.setFullYear(+startMonthYearSplit[1], (+startMonthYearSplit[0]) - 1, 1);
					startDate.setHours(0, 0, 0);
					const endMonthYearSplit = endMonthYear.split('-');
					const lastDateOfMonth = new Date(+endMonthYearSplit[1], (+endMonthYearSplit[0]), 0);
					endDate.setFullYear(+endMonthYearSplit[1], (+endMonthYearSplit[0]) - 1, lastDateOfMonth.getDate());
					endDate.setHours(23, 59, 59);
					this.startDate = startDate;
					this.endDate = endDate;
					if (contractGuid === 'all_contracts') {
						this.doForAllContracts();
					} else {
						this.isCheckAllContracts = false;
						this.sContractsLbl = '';
						this.selectedContractKeys = [contractGuid];
						this.getContractDetailsByGuid(rebateForcastConfig)
					}
				} else {
					this.fetchDefaultContract();
				}
			} else {
				this.fetchDefaultContract();
			}
		} else {
			this.fetchDefaultContract();
		}
	}

	// Method used to updateUserSetting
	private doUpdateUserSettings() {
		const rebateForcast = {
			guid: '',
			code: '',
			startMonthYear: '',
			endMonthYear: '',
			contractGuid: '',
		};

		let dashboardSettingsConfig: any = {};
		let startMonthYear: string, endMonthYear: string, contractGuid: string;
		startMonthYear = ((this.startDate.getMonth() < 10) ? '0' + (this.startDate.getMonth() + 1) : (this.startDate.getMonth() + 1)) + '-' + this.startDate.getFullYear();
		endMonthYear = ((this.endDate.getMonth() < 10) ? '0' + (this.endDate.getMonth() + 1) : (this.endDate.getMonth() + 1)) + '-' + this.endDate.getFullYear();
		contractGuid = this.isCheckAllContracts ? 'all_contracts' : (this.selectedContractKeys[0] || '');
		try {
			dashboardSettingsConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS)));
		} catch (e) {
			dashboardSettingsConfig = {};
		}

		if (!dashboardSettingsConfig.hasOwnProperty('configs')) {
			dashboardSettingsConfig['configs'] = [];
		}
		const configsIndex = dashboardSettingsConfig.configs.findIndex(cg => cg.code === 'rebate_revenue_forecast');
		if (configsIndex === -1) {
			rebateForcast.guid = this.guid.newGuid();
			rebateForcast.code = 'rebate_revenue_forecast';
			rebateForcast.startMonthYear = startMonthYear;
			rebateForcast.endMonthYear = endMonthYear;
			rebateForcast.contractGuid = contractGuid;
			dashboardSettingsConfig.configs.push(rebateForcast);
		} else {
			dashboardSettingsConfig.configs[configsIndex].startMonthYear = startMonthYear;
			dashboardSettingsConfig.configs[configsIndex].endMonthYear = endMonthYear;
			dashboardSettingsConfig.configs[configsIndex].contractGuid = contractGuid;
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.DASHBOARD_SETTINGS);
		formData.append('user_settings', JSON.stringify(dashboardSettingsConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response) {
					if (response.flag) {
						const userSettings = this._AppCommonSrvc.getUserSettings();
						if (userSettings && userSettings.length <= 0) {
							const newSection = {};
							newSection[ServerSections.DASHBOARD_SETTINGS] = dashboardSettingsConfig;
							this._AppCommonSrvc.setUserSettings([newSection]);
							this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS, dashboardSettingsConfig);
						} else {
							this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS, dashboardSettingsConfig);
						}
					}
				}
			},
			error: error => {
			}
		});
	}

	//  Method used to All Contract
	private doForAllContracts() {
		this.isCheckAllContracts = true;
		this.contractName = 'Revenue Forecast - All Contracts';
		this.sContractsLbl = '';
		this.selectedContractKeys = [];
		this.selectedContractDetails = [];
		this.setTextForContactDrpDwn();
		this.settlementStartDate = this.startDate.getFullYear() + '-' + ((this.startDate.getMonth() < 10) ? '0' + (this.startDate.getMonth() + 1) : (this.startDate.getMonth() + 1)) + '-' + ((this.startDate.getDate() < 10) ? '0' + this.startDate.getDate() : this.startDate.getDate());
		this.settlementEndDate = this.endDate.getFullYear() + '-' + ((this.endDate.getMonth() < 10) ? '0' + (this.endDate.getMonth() + 1) : (this.endDate.getMonth() + 1)) + '-' + ((this.endDate.getDate() < 10) ? '0' + this.endDate.getDate() : this.endDate.getDate());
		this.getContractChartData();
	}

	//  Method used to fetch specific contract by passing guid
	private getContractDetailsByGuid(rebateForcastConfig: any) {
		const contractGuid = rebateForcastConfig.contractGuid;
		const viewFields = ['guid', 'contractName', 'settlementPeriodStart', 'settlementPeriodEnd', 'status'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('view_fields', JSON.stringify(viewFields));
		formData.append('search', JSON.stringify([{ status: RebateStatusValEnum.ACTIVE }, { guid: contractGuid }]));
		if (this.getContractDetailsSbsn) {
			this.getContractDetailsSbsn.unsubscribe();
		}
		this.getContractDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response) {
					if (response.flag) {
						if (response.data && response.data.length > 0) {
							const contractDetailsIndex = response.data.findIndex(cd => cd.guid === contractGuid);
							if (contractDetailsIndex !== -1) {
								const contractDetails = response.data[contractDetailsIndex];
								this.isCheckAllContracts = false;
								this.selectedContractKeys = [];
								this.selectedContractKeys.push(contractGuid);
								this.selectedContractDetails = [];
								this.selectedContractDetails.push(contractDetails);
								this.setTextForContactDrpDwn();
								this.doShowContractName(contractDetails);
								this.getContractChartData();
							} else {
								this.doForAllContracts();
							}
						} else {
							this.doForAllContracts();
						}
					}
				}
			},
			error: error => {
			}
		});
	}
}