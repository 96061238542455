import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AddEditPriceListReq } from '@app/models/items.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { ProductServiceService } from '@app/services/product-service/product-service.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
	selector: 'app-pricelist-terms-conditions',
	templateUrl: './pricelist-terms-conditions.component.html',
	styleUrls: ['./pricelist-terms-conditions.component.css'],
	providers: []
})
export class PricelistTermsConditionsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() priceListDetailsIP: any;
	@Input() currentPriceListDetailsIP: any;
	@Output() refreshTermsConditionsIP = new EventEmitter<any>();
	private priceListDetailsObj: any;
	private currentPriceListDetailsObj: any;
	public terms_and_conditions: string;
	private doSavePriceListSbsn: Subscription;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _ProductServiceService: ProductServiceService,
		private _RestApiService: RestApiService,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService) {
	}

	ngOnInit() {

	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['priceListDetailsIP']) {
			if (_SimpleChanges.priceListDetailsIP.currentValue) {
				const priceListDetailsIP = _SimpleChanges.priceListDetailsIP.currentValue;
				this.priceListDetailsObj = priceListDetailsIP;
				this.terms_and_conditions = this.priceListDetailsObj.terms || null;
			}
		}
		if (_SimpleChanges['currentPriceListDetailsIP']) {
			if (_SimpleChanges.currentPriceListDetailsIP.currentValue) {
				const currentPriceListDetailsIP = _SimpleChanges.currentPriceListDetailsIP.currentValue;
				this.currentPriceListDetailsObj = currentPriceListDetailsIP;
			}
		}
	}

	ngOnDestroy(): void {

	}


	public doSubmitPricelistDetails(e?: any) {
		let _AddEditPriceListReq = new AddEditPriceListReq();
		_AddEditPriceListReq = JSON.parse(JSON.stringify(this.priceListDetailsObj));
		_AddEditPriceListReq.terms = this.terms_and_conditions;

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('pricelists', JSON.stringify([_AddEditPriceListReq]));

		if (this.doSavePriceListSbsn) {
			this.doSavePriceListSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.doSavePriceListSbsn = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
						this.refreshTermsConditionsIP.emit({ terms: this.terms_and_conditions });
					}
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}
}