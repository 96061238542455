import { CcPricelistTabComponent } from '@app/pages/contracts/billback/chargeback-contract/cc-pricelist-tab/cc-pricelist-tab.component';
import { CcItemsTabComponent } from './cc-items-tab/cc-items-tab.component';
import * as moment from 'moment';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { ChargebackContractModelRequest, ChargebackContractModelUI } from '@app/models/chargeback-contract.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { Subscription } from 'rxjs';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { CcChargebackDocumentsTabComponent } from './cc-chargeback-documents-tab/cc-chargeback-documents-tab.component';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { DistributionChargebackContractService } from '@app/services/contract-service/billback/distribution-chargeback-contract.service';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';

@Component({
	selector: 'app-chargeback-contract',
	templateUrl: './chargeback-contract.component.html',
	styleUrls: ['./chargeback-contract.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
	]
})
export class ChargebackContractComponent implements OnInit, OnDestroy {

	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	@ViewChild(CcItemsTabComponent) itemTabComponentRef;
	@ViewChild(CcPricelistTabComponent) CcPricelistTabComponentRef;
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public editChargebackGuid: string;
	public productSearchItemType: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public selectedTabVal: string;
	public tabItemsList: any[] = [];

	// Select box list customer
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public isCustomerDropDownBoxOpened: boolean = false;

	// Model object
	public guid = new Guid();
	public pageOptions: PageOptions = new PageOptions();
	public chargebackContractModelUI = new ChargebackContractModelUI();
	public chargebackContractModelRequest = new ChargebackContractModelRequest();
	public itemTabDataList: any = [];
	public itemTabDataSource: any;
	public chargeBackDocumentsTabDatalist: any;
	public selectCustomerPriceList: any;
	public isCustomerPriceListChanged: boolean = false;

	private getCustomerPriceLstSbsn: Subscription;
	private getPriceLstSbsn: Subscription;
	private getItemDetailsSbsn: Subscription;
	private getUomLstSbsn: Subscription;
	private getUomGroupLstSbsn: Subscription;
	public listOfPrices: any[] = [];
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public showNotification: any;
	public selectCustomerDetails: any;
	public billbackTypeRadio: any[];
	@ViewChild(CcItemsTabComponent) childCcItemsTabComponent: CcItemsTabComponent;
	@ViewChild(CcChargebackDocumentsTabComponent) childCcChargebackDocumentsTabComponent: CcChargebackDocumentsTabComponent;
	public actionFrom: string;
	public isDisabledBillbackTypeField = false;
	public isDisabledCustomerField = false;
	public itemDataSource: any;
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _GlobalStateService: GlobalStateService,
		private _Router: Router,
		private _ToastrService: ToastrService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _ConfirmationDialogService: ConfirmationDialogService,
		private _ChargebackContractService: ChargebackContractService,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService,
		private _BillbackContractService: BillbackContractService,
		private _DistributionChargebackContractService: DistributionChargebackContractService,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,
		public _BillbackService: BillbackService,
		private _ChangeDetectorRef: ChangeDetectorRef,
		private _IxDxAlertService: IxDxAlertService,
		private _CrmsService: CrmsService) {

	}

	ngOnInit() {
		this.actionFrom = this._BillbackContractService.getActionFrom();
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		}
		// check Is QB User (no need to view code of QB user)
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		this.customerGridBoxColumns = this._BillbackService.getCustomerGridBoxColumn();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}

		this.editChargebackGuid = this._ChargebackContractService.getEditChargebackGuid();
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		if (this.getCustomerPriceLstSbsn) {
			this.getCustomerPriceLstSbsn.unsubscribe();
		}
	}

	ngAfterContentChecked() {
		this._ChangeDetectorRef.detectChanges();
	}

	preInit() {
		this.billbackTypeRadio = this._BillbackService.fetchBillbackTypeListRadio();
		this.chargebackContractModelUI.billBackType = 'chargeback';
		this.tabItemsList = this._ChargebackContractService.tabItemsList;
		this.productSearchItemType = this._BillbackService.getProductSearchType();
		this.loadCustomerDataList();
		if (this.editChargebackGuid) {
			this.editChargebackContract();
		} else {
			this.selectedTabVal = 'items';
			if (this.actionFrom === 'crms') {
				this.preInitForCrm();
			}
		}
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.disableChargebackInputFields();
	}

	// Set Settlement StartDate and EndDate
	compareTwoDates() {
		// check start date and end date validate
		if (new Date(this.chargebackContractModelUI.endDate) < new Date(this.chargebackContractModelUI.startDate)) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
		}
	}

	disableChargebackInputFields() {
		if (this.editChargebackGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}

	}
	//#region for Customer select box methods
	public loadCustomerDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=clients";
		// formdata = formdata + "&is_dropdown=true";	
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "code",
			loadUrl: formdata
		});
	}

	public onOptionChangedCustomer(e) {
		if (e.name === "value") {
			if (this.customerDataGrid) {
				const selectedCustomerArr = this.customerDataGrid.instance.getSelectedRowsData();
				if (selectedCustomerArr && selectedCustomerArr.length > 0) {
					const selectedCustomerObj = selectedCustomerArr[0];
					if (selectedCustomerObj) {
						this.chargebackContractModelUI.selectCustomerId = selectedCustomerObj.code;
						this.chargebackContractModelUI.selectCustomer = selectedCustomerObj.name;
						this.chargebackContractModelUI.selectCustomerPriceListNum = selectedCustomerObj.pricelistnum;
						if (this.productSearchItemType == 'item') {
							this.itemTabComponentRef.loadItemTabDataList();
						}

						this.fetchedListOfPrices(this.chargebackContractModelUI.selectCustomerPriceListNum);
					}
				}
			}
			this.isCustomerDropDownBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}

	}

	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.customerGridBoxValue = [];
			this.customerDataGrid.instance.refresh();
		}
	}
	public displayExprCustomerName(customer) {
		if (!customer) {
			return "";
		}
		const name = customer.name;

		return name;
	}
	//#endregion

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOMGROUPS);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	public doChangeBillBackType(e) {
		this.chargebackContractModelUI.billBackType = e.value;
		this._BillbackService.setBillbackType(this.chargebackContractModelUI.billBackType);
		switch (this.chargebackContractModelUI.billBackType) {
			case 'billback':
				this._BillbackContractService.setEditBillbackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/billback-contract/']);
				break;
			case 'chargeback':
				// this._ChargebackContractService.setEditChargebackGuid('');
				// this._Router.navigate(['/contracts/billbackchargeback/chargeback-contract/']);
				break;
			case 'enduserschargeback':
				this._EndUsersChargebackContractService.setEditEndUsersChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/end-users-chargeback-contract/']);
				break;
			case 'distributionchargeback':
				this._DistributionChargebackContractService.setEditDistributionChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/distribution-chargeback-contract/']);
				break;
			case 'minmaxenduserschargeback':
				this._MinMaxEndUsersChargebackContractService.setEditMinMaxEndUsersChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/min-max-end-users-chargeback-contract/']);
				break;
		}
	}

	public doClickTab(val) {
		this.selectedTabVal = val;
	}

	public checkValidation(): boolean {
		if (!this.chargebackContractModelUI.contractName) {
			this._ToastrService.info('Contract Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.chargebackContractModelUI.selectCustomer) {
			this._ToastrService.info('Customer is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.chargebackContractModelUI.startDate) {
			this._ToastrService.info('Start Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
	}

	public doSaveChargebackContract(isProductSave?: boolean) {
		if (this.checkValidation()) {
			return false;
		}

		this.chargebackContractModelRequest = new ChargebackContractModelRequest();
		if (this._ChargebackContractService.getEditChargebackGuid()) {
			this.chargebackContractModelRequest.guid = this._ChargebackContractService.getEditChargebackGuid();
		} else {
			this.chargebackContractModelRequest.guid = this.guid.newGuid();
		}
		this.chargebackContractModelRequest.ixcode = this.chargebackContractModelUI.ixcode;
		this.chargebackContractModelRequest.contractName = this.chargebackContractModelUI.contractName;
		this.chargebackContractModelRequest.selectCustomerId = this.chargebackContractModelUI.selectCustomerId;
		this.chargebackContractModelRequest.selectCustomer = this.chargebackContractModelUI.selectCustomer;
		this.chargebackContractModelRequest.selectCustomerPriceListNum = this.chargebackContractModelUI.selectCustomerPriceListNum;
		this.chargebackContractModelRequest.selectCustomerPriceListName = this.chargebackContractModelUI.selectCustomerPriceListName;
		this.chargebackContractModelRequest.startDate = moment(this.chargebackContractModelUI.startDate).format('YYYY-MM-DD') || '';
		this.chargebackContractModelRequest.endDate = moment(this.chargebackContractModelUI.endDate).format('YYYY-MM-DD') || '';
		this.chargebackContractModelRequest.billBackType = this.chargebackContractModelUI.billBackType;
		this.chargebackContractModelRequest.is_active = true;
		this.chargebackContractModelRequest.chargebacks = this.itemTabDataList || [];
		this.chargebackContractModelRequest.chargeDocuments = this.chargeBackDocumentsTabDatalist || [];
		if (this.productSearchItemType === 'price') {
			this.chargebackContractModelUI.pricelist_modified_date = this.listOfPrices && this.listOfPrices[0] ? this.listOfPrices[0].modified_date : '';
			this.chargebackContractModelRequest.pricelist_modified_date = this.chargebackContractModelUI.pricelist_modified_date;
			this.chargebackContractModelRequest.pricelist_modified_user_action = this.chargebackContractModelUI.pricelist_modified_user_action;
		}
		const arryBillBackContracts = [];
		arryBillBackContracts.push(JSON.parse(JSON.stringify(this.chargebackContractModelRequest)));
		this.callSaveChargebackContract(arryBillBackContracts);
		this.clearChargebackTabData();
	}

	public doSaveChargebackItemTabData(event) {
		// upload item did not get new list for item tab 
		if (event === 'uploaditem') {
			this.itemTabDataList = this._ChargebackContractService.getItemTabDataList();
		}
		if (event === 'claimItemChargeback') {
			this.showNotification = "No";
			this.itemTabDataList = this._ChargebackContractService.getItemTabDataList();
		}
		this.doSaveChargebackContract();
	}

	public callSaveChargebackContract(arryChargebackContract: any[]) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('chargebackcontracts', JSON.stringify(arryChargebackContract));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq((formData)).subscribe(
			response => {
				if (response.flag) {
					if (this.showNotification !== 'No') {
						this._ToastrService.success('Save Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}
					const objBillbackContracts = response.data.chargebackcontracts;
					let billbackContracts: any;
					if (objBillbackContracts && objBillbackContracts.length > 0) {
						billbackContracts = objBillbackContracts[0];
						if (billbackContracts && billbackContracts.chargebackItems) {
							this.itemTabDataList = billbackContracts.chargebackItems;
							this.itemTabDataSource = billbackContracts.chargebackItems;
						}
						this._ChargebackContractService.setEditChargebackGuid(billbackContracts.guid);
						this.editChargebackGuid = billbackContracts.guid;
						this.chargebackContractModelRequest.ixcode = billbackContracts.ixcode;
						this.chargebackContractModelUI.guid = billbackContracts.guid;
						this.chargebackContractModelUI.ixcode = billbackContracts.ixcode;
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
				this.disableChargebackInputFields();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	public clearChargebackTabData() {

	}

	public updateQuantitiesSoldClick() {
		if (!this.editChargebackContract) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (this.itemTabDataList.length === 0) {
			this._ToastrService.info('Without product item will not update quantitie sold.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		this._LoaderService.show();
		const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.editChargebackGuid + '&method=updateQuantitiesSold'
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.editChargebackGuid);
		formData.append('method', 'updateQuantitiesSold');
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(
			response => {
				if (response.flag) {
					this.itemTabDataList = JSON.parse(JSON.stringify(response.data));
					this.itemTabDataSource = this.itemTabDataList;
					this._ToastrService.success('Update Quantities Sold Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	public editChargebackContract() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.CHARGEBACKCONTRACTS_DETAILS);
		formData.append('contractguid', this.editChargebackGuid);
		// formData.append('search', JSON.stringify(searchParams));
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					const editChargebackContractDetails = response.data[0];
					this.chargebackContractModelUI.guid = editChargebackContractDetails.guid;
					this.chargebackContractModelUI.ixcode = editChargebackContractDetails.ixcode;
					this.chargebackContractModelUI.contractName = editChargebackContractDetails.contractName;
					this.chargebackContractModelUI.selectCustomerId = editChargebackContractDetails.selectCustomerId;
					this.chargebackContractModelUI.selectCustomer = editChargebackContractDetails.selectCustomer;
					this.chargebackContractModelUI.selectCustomerPriceListNum = editChargebackContractDetails.selectCustomerPriceListNum;
					this.chargebackContractModelUI.selectCustomerPriceListName = editChargebackContractDetails.selectCustomerPriceListName;
					this.chargebackContractModelUI.startDate = moment(editChargebackContractDetails.startDate).format('YYYY-MM-DD') || '';
					this.chargebackContractModelUI.endDate = moment(editChargebackContractDetails.endDate).format('YYYY-MM-DD') || '';
					this.chargebackContractModelUI.billBackType = editChargebackContractDetails.billBackType;
					this.chargebackContractModelUI.is_active = editChargebackContractDetails.is_active;
					this.itemTabDataList = editChargebackContractDetails['chargebacks'] || [];
					this.chargeBackDocumentsTabDatalist = editChargebackContractDetails.chargeDocuments || [];
					this.customerGridBoxValue = [editChargebackContractDetails.selectCustomerId];
					this.chargebackContractModelUI.pricelist_modified_date = editChargebackContractDetails.pricelist_modified_date;
					this.chargebackContractModelUI.pricelist_modified_user_action = editChargebackContractDetails.pricelist_modified_user_action;
					this.itemTabDataSource = this.itemTabDataList;
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				if (this.chargebackContractModelUI.selectCustomerPriceListNum) {
					this.fetchedListOfPrices(this.chargebackContractModelUI.selectCustomerPriceListNum, true);
				}
				this.selectedTabVal = 'items';
				this._LoaderService.hide();
			}, error: (error) => {
				this.selectedTabVal = 'items';
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	// Method used to Fetched Price list
	private fetchedListOfPrices(pricelistnum: string, isCheckListPrice?: boolean) {
		this.listOfPrices = [];
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		const viewFields = ['pricelistno', 'pricelistname', 'items', 'modified_date'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.PRICELISTS);
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		formdata.append('is_dropdown', '1');
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this.getPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data || [];
						try {
							this.chargebackContractModelUI.selectCustomerPriceListName = response.data[0].pricelistname;
						} catch (e) {
							this.chargebackContractModelUI.selectCustomerPriceListName = '';
						}
						if (isCheckListPrice) {
							if (this.editChargebackGuid) {
								if (this.productSearchItemType === 'price') {
									this.checkDifferentCustomerListPrice();
								}
							}
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Fetched New Price list
	private fetchedNewListOfPrices(pricelistnum: string) {
		this.listOfPrices = [];
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		const viewFields = ['pricelistno', 'pricelistname', 'items', 'modified_date'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.PRICELISTS);
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		formdata.append('is_dropdown', '1');
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this.getPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data || [];

					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private checkDifferentCustomerListPrice() {
		this.isCustomerPriceListChanged = false;
		this.selectCustomerPriceList = '';
		this.compareSelectedCustomerPriceList()
		this.getSelectedCustomerPriceListNum();
	}

	// Method used to check customer pricelist number is changed or not
	private compareSelectedCustomerPriceList() {
		this._GlobalStateService.unsubscribe('GET_SELECT_CUSTOMER_PRICE_LIST_NUMBER');
		this._GlobalStateService.subscribe('GET_SELECT_CUSTOMER_PRICE_LIST_NUMBER', response => {
			const selectCustomerDetails = response.selectCustomerDetails;
			if (selectCustomerDetails) {
				this.selectCustomerPriceList = selectCustomerDetails.pricelistnum;
			}
			const priceListNum = this.getCustomerPriceListNum();
			if (priceListNum !== this.selectCustomerPriceList) {
				this.fetchedNewListOfPrices(this.selectCustomerPriceList);
				this.isCustomerPriceListChanged = true;
			}
			this.checkDifferentListPrice();
		});
	}

	// Method used to update listPrice if Customer price list updated.
	private checkDifferentListPrice() {
		let pricelist_modified_date;
		try {
			pricelist_modified_date = this.listOfPrices[0].modified_date;
		} catch (e) {
			pricelist_modified_date = null;
		}

		if (!this.isCustomerPriceListChanged) {
			if (new Date(this.chargebackContractModelUI.pricelist_modified_date) === new Date(pricelist_modified_date)) {
				// if (this.chargebackContractModelUI.pricelist_modified_user_action === 'ignored') {
				// 	return false;
				// }
				return false;
			}
		}
		const itemGuidList = [];
		this.itemTabDataList.forEach(item => {
			itemGuidList.push(item.item_guid);
		});
		if (itemGuidList.length <= 0) {
			return false;
		}
		if (this.productSearchItemType === 'price') {
			this.CcPricelistTabComponentRef.doAddBulkItemsToItmGrpTabListForNewPriceList();
		}
		this.doCompareListPriceWithUomPrice();
		this.fetchedItemDetailsToCompareListPriceByGuid(itemGuidList);
	}

	// Method used to Fetched Price list for selected customer
	private getSelectedCustomerPriceListNum() {

		let selectCustomerDetails = [];
		const searchArr = [];
		searchArr.push({ code: this.chargebackContractModelUI.selectCustomerId });
		const viewFields = ['pricelistnum', 'code', 'name', 'guid'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.CLIENTS);
		formdata.append('is_dropdown', '1');
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		if (this.getCustomerPriceLstSbsn) {
			this.getCustomerPriceLstSbsn.unsubscribe();
		}
		this.getCustomerPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						const customerList = response.data || [];
						if (customerList.length > 0) {
							selectCustomerDetails = customerList[0];
							this._GlobalStateService.notifyDataChangedDuplicate('GET_SELECT_CUSTOMER_PRICE_LIST_NUMBER', { selectCustomerDetails: selectCustomerDetails });
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to compare listprice with updated customer pricelist
	private doCompareListPriceWithUomPrice() {
		this._GlobalStateService.unsubscribe('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', response => {
			const itemDetailsList = response.itemDetailsList;

			const priceListNum = this.getCustomerPriceListNum();
			let isShowConfirmDialog = false;
			for (let i = 0; i < this.itemTabDataList.length; i++) {
				const item = this.itemTabDataList[i];
				isShowConfirmDialog = false;
				const itemDetailsIndex = itemDetailsList.findIndex(itmDtl => itmDtl.erp_primary_key === item.erp_primary_key);
				if (itemDetailsIndex !== -1) {
					const itemDetailsOrigin = itemDetailsList[itemDetailsIndex];
					const listOfSpecificUoms = this._BillbackService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemDetailsOrigin, priceListNum);
					const uomIndex = listOfSpecificUoms.findIndex(uom => uom.code === item.selectedUom);
					if (uomIndex !== -1) {
						const newListPrice = +listOfSpecificUoms[uomIndex].price;
						if (newListPrice !== +item.listPrice) {
							isShowConfirmDialog = true;
							break;
						}
					}
				}
			}

			// once user can change selected customer pricelist
			if (this.isCustomerPriceListChanged) {
				isShowConfirmDialog = true;
			}

			if (isShowConfirmDialog) {
				let pricelistname = '';
				try {
					pricelistname = this.chargebackContractModelUI.selectCustomerPriceListName || '';
				} catch (e) {
					pricelistname = '';
				}
				this._ConfirmationDialogService.setDialogConfig({
					popupTitle: "Price List Update!",
					popupMessage: 'Customer price list \'' + pricelistname + '\' has been modified, do you want to update contract price list? ',
					popupOkBtnText: "Yes",
					popupCancelBtnText: "Cancel",
					popupInfoBoxClass: "confirmationIcon"
				});
				this._ConfirmationDialogService.confirmDialog().afterClosed().subscribe(result => {
					if (result) {
						this.chargebackContractModelUI.selectCustomerPriceListNum = this.selectCustomerPriceList;

						if (this.isCustomerPriceListChanged) {
							if (this.listOfPrices && this.listOfPrices.length > 0) {
								this.chargebackContractModelUI.selectCustomerPriceListName = this.listOfPrices[0].pricelistname;
							}
							this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT_FOR_NEW_PRICE_LIST', { itemDetailsList: itemDetailsList, itemTabDataList: this.itemTabDataList });
						}
						else {
							for (let i = 0; i < this.itemTabDataList.length; i++) {
								const item = this.itemTabDataList[i];
								const itemDetailsIndex = itemDetailsList.findIndex(itmDtl => itmDtl.erp_primary_key === item.erp_primary_key);
								const itemDetailsOrigin = itemDetailsList[itemDetailsIndex];
								const listOfSpecificUoms = this._BillbackService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemDetailsOrigin, priceListNum);
								item.uomList.forEach(storedUom => {
									const uomIndex = listOfSpecificUoms.findIndex(uom => uom.code.toString().toLowerCase() === storedUom.code.toString().toLowerCase());
									if (uomIndex !== -1) {
										if (+storedUom.price !== +listOfSpecificUoms[uomIndex].price) {
											storedUom.price = +listOfSpecificUoms[uomIndex].price;
										}
									}
								});
								const uomIndex = item.uomList.findIndex(uom => uom.code.toString().toLowerCase() === item.selectedUom.toString().toLowerCase());
								if (uomIndex !== -1) {
									const newPrice = +item.uomList[uomIndex].price || 0;
									if (newPrice !== +item.listPrice) {
										item.listPrice = newPrice;
										// check & fixed chargeback basis final price from application config
										if (this._LoginService.loginUser.account_detail.app_settings.chargeback_price_basis !== "final_price") {
											item.final_amount = newPrice - (+item.chargebackAmount || 0);
										} else {
											item.chargebackAmount = newPrice - (+item.final_amount || 0);
										}
									}
								}

							}
						}
						if (this.editChargebackGuid) {
							this.chargebackContractModelUI.pricelist_modified_user_action = 'agreed';
							if (this.listOfPrices.length > 0) {
								this.chargebackContractModelUI.pricelist_modified_date = this.listOfPrices[0].modified_date;
							}
							setTimeout(() => {
								this.doSaveChargebackContract();
							}, 700);
						}
					} else {
						if (this.editChargebackGuid) {
							this.chargebackContractModelUI.pricelist_modified_user_action = 'ignored';
							setTimeout(() => {
								this.doSaveChargebackContract();
							}, 700);
						}
					}
				});
			}
		});
	}

	// Method used to fetched item details to compare listprice with updated customer pricelist
	private fetchedItemDetailsToCompareListPriceByGuid(itemGuidList: string[]) {
		let itemDetailsList = [];
		let priceListNum;
		if (this.selectCustomerPriceList) {
			priceListNum = this.selectCustomerPriceList;
		} else {
			this.getCustomerPriceListNum();
		}
		const view_fields = ['guid', 'erp_primary_key', 'itemname', 'itemsku', 'description', 'baseuom', 'uomgroupentry', 'itemprices'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEM_DETAILS);
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		formData.append('is_dropdown', 'true');
		formData.append('product_search_from', 'chargebacks');
		formData.append('view_fields', JSON.stringify(view_fields));
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		// this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				// this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to return selected customer pricelistnum
	private getCustomerPriceListNum() {
		let priceListNum = '';
		try {
			priceListNum = this.chargebackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		return priceListNum;
	}

	//#region Call API Download chargeback/enduser chargeback file
	downloadPriceList() {
		if (this.editChargebackGuid) {
			this._LoaderService.show();
			let reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.editChargebackGuid;
			reqFormData = reqFormData + '&method=downloadChargebackContract'
			this.dataInReqSubscription = this._RestApiService.doDataOutReqDownloadFile((reqFormData)).subscribe(
				response => {
					this.downloadFile(response);
					this._LoaderService.hide();
				}, error => {
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				});
		}
	}
	downloadFile(data: Blob) {
		const blob = new Blob([data], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		const ixcode = this.chargebackContractModelUI.ixcode ? this.chargebackContractModelUI.ixcode : this.chargebackContractModelRequest.ixcode;
		link.download = ixcode + '_' + this.editChargebackGuid;
		link.target = '_blank';
		link.dispatchEvent(new MouseEvent('click'));
	}

	public doExportToExcel() {
		const workbook = new ExcelJS.Workbook();
		let workSheet, componentObj;
		if (this.childCcItemsTabComponent && this.childCcItemsTabComponent.itemTabDataGridContainer) {
			workSheet = workbook.addWorksheet('Items');
			componentObj = this.childCcItemsTabComponent.itemTabDataGridContainer.instance;
		}
		if (this.childCcChargebackDocumentsTabComponent && this.childCcChargebackDocumentsTabComponent.chargeDocumentsTabDataGridContainer) {
			workSheet = workbook.addWorksheet('Chargeback Documents');
			componentObj = this.childCcChargebackDocumentsTabComponent.chargeDocumentsTabDataGridContainer.instance;
		}
		if (componentObj && componentObj.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		this._BillbackService.exportBillbackChargebackExcel(workbook, this.chargebackContractModelUI, workSheet, componentObj);
	}
	//#endregion

	// #region for Action From Crm
	private preInitForCrm() {
		const clientDetails = this._CrmsService.getClientDetails();
		this.isDisabledBillbackTypeField = true;
		this.isDisabledCustomerField = true;
		if (clientDetails && clientDetails.code) {
			this.customerGridBoxValue = [clientDetails.code];
			this.chargebackContractModelUI.selectCustomerId = clientDetails.code;
			this.chargebackContractModelUI.selectCustomer = clientDetails.name;
			this.chargebackContractModelUI.selectCustomerPriceListNum = clientDetails.pricelistnum || '';
			// this.itemTabComponentRef.loadItemTabDataList();
			this.fetchedListOfPrices(this.chargebackContractModelUI.selectCustomerPriceListNum);
		}
	}
	// #endregion
}
