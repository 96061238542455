import { LoaderService } from './../loaderservices/loader.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout, map, tap } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class RestApiCallService {
	private ajaxTimeout = 60000;

	constructor(public httpClient: HttpClient, private loaderService: LoaderService) {

	}

	get(url: string, params: any = null, isHideLoader: boolean = false, isNotTimeout: boolean = false, t_headers: any = []): Observable<any> {
		if (!isHideLoader) {
			// console.info('Show Loader');
		}
		const o_headers = new HttpHeaders();
		const httpClientReq = this.httpClient.get(url, { headers: o_headers, params: params });
		if (isNotTimeout) {
			httpClientReq.pipe(timeout(this.ajaxTimeout));
		}

		httpClientReq.pipe(map((response: any) => {
			if (!isHideLoader) {
				// console.info('Hide Loader');
			}
			return response;
		}));
		return httpClientReq;
	}

	post(url: string, params: any = null, isHideLoader: boolean = false, isNotTimeout: boolean = false, t_headers: any = []): Observable<any> {
		if (!isHideLoader) {
			// this.loaderService.show();
		}

		let httpHeaders = new HttpHeaders();
		httpHeaders = httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
		httpHeaders = httpHeaders.append('frontend', 'true');
		const httpOption = {
			headers: httpHeaders
		};
		url = url;
		const httpClientReq = this.httpClient.post(url, params, httpOption);
		if (isNotTimeout) {
			httpClientReq.pipe(timeout(this.ajaxTimeout));
		}
		httpClientReq.pipe(map(result => {
			console.log('resultL:', result);
			return result;
		}));
		return httpClientReq;
	}

	postFormData(url: string, params: any = null, isHideLoader: boolean = false, isNotTimeout: boolean = false, t_headers: any = []): Observable<any> {
		if (!isHideLoader) {
			// this.loaderService.show();
		}

		let httpHeaders = new HttpHeaders();
		// httpHeaders = httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
		httpHeaders = httpHeaders.append('frontend', 'true');
		const httpOption = {
			headers: httpHeaders
		};
		url = url;
		const httpClientReq = this.httpClient.post(url, params, httpOption);
		if (isNotTimeout) {
			httpClientReq.pipe(timeout(this.ajaxTimeout));
		}
		httpClientReq.pipe(map(result => {
			console.log('resultL:', result);
			return result;
		}));
		return httpClientReq;
	}

	postAuthorizePayNet(url: string, params: any = null, isHideLoader: boolean = false, isNotTimeout: boolean = false, t_headers: any = []): Observable<any> {
		if (!isHideLoader) {
			// this.loaderService.show();
		}

		let httpHeaders = new HttpHeaders();
		httpHeaders = httpHeaders.append('Content-Type', 'application/json');
		// httpHeaders = httpHeaders.append('Access - Control - Allow - Origin', '*');
		const httpOption = {
			headers: httpHeaders
		};
		url = url;
		const httpClientReq = this.httpClient.post(url, params, httpOption);
		if (isNotTimeout) {
			httpClientReq.pipe(timeout(this.ajaxTimeout));
		}
		httpClientReq.pipe(map(result => {
			console.log('resultL:', result);
			return result;
		}));
		return httpClientReq;
	}

	postFile(url: string, params: any = null, isHideLoader: boolean = false, isNotTimeout: boolean = false, t_headers: any = []): Observable<any> {
		if (!isHideLoader) {
			// this.loaderService.show();
		}

		let httpHeaders = new HttpHeaders();
		//httpHeaders = httpHeaders.append('Content-Type', undefined);
		//httpHeaders = httpHeaders.append('frontend', 'true');
		// reportProgress: true,
		const httpOption: any = {
			headers: httpHeaders,
		};
		url = url;
		const httpClientReq = this.httpClient.post(url, params, httpOption);
		if (isNotTimeout) {
			httpClientReq.pipe(timeout(this.ajaxTimeout));
		}
		httpClientReq.pipe(map(result => {
			return result;
		}));
		return httpClientReq;
	}

	postFileUpload(url: string, params: any = null, isHideLoader: boolean = false, isNotTimeout: boolean = false, t_headers: any = []): Observable<any> {
		if (!isHideLoader) {
			// this.loaderService.show();
		}

		let httpHeaders = new HttpHeaders();
		//httpHeaders = httpHeaders.append('Content-Type', undefined);
		//httpHeaders = httpHeaders.append('frontend', 'true');
		const httpOption: any = {
			headers: httpHeaders,
			reportProgress: true,
			observe: 'events'
		};
		url = url;
		const httpClientReq = this.httpClient.post(url, params, httpOption);
		if (isNotTimeout) {
			httpClientReq.pipe(timeout(this.ajaxTimeout));
		}
		httpClientReq.pipe(map(result => {
			return result;
		}));
		return httpClientReq;
	}

	postFileDownload(url: string, params: any = null, isHideLoader: boolean = false, isNotTimeout: boolean = false, t_headers: any = []): Observable<any> {
		if (!isHideLoader) {
			// this.loaderService.show();
		}

		let httpHeaders = new HttpHeaders();

		//httpHeaders = httpHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
		//httpHeaders = httpHeaders.append('Accept', 'application/pdf');
		//httpHeaders = httpHeaders.append('frontend', 'true');
		// httpHeaders = httpHeaders.append('responseType ', 'json');
		const httpOption = {
			headers: httpHeaders,
		};
		url = url;
		const httpClientReq = this.httpClient.post(url, params, {
			headers: new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded',
			}), responseType: 'blob'
		});
		if (isNotTimeout) {
			httpClientReq.pipe(timeout(this.ajaxTimeout));
		}
		httpClientReq.pipe(map(result => {
			console.log('resultL:', result);
			return result;
		}));
		return httpClientReq;
	}
}
