import { Injectable } from '@angular/core';
import { IMG_EMAIL_DX_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_EXPORT_PDF, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';

@Injectable({
	providedIn: 'root'
})

export class ApSalesService {

	private listOfBillingInvoices: any[] = [];

	constructor() { }

	public setBillingInvoiceEle(val: any) {
		const eleIndex = this.listOfBillingInvoices.findIndex(ele => ele.guid === val.guid);
		if (eleIndex !== 1) {
			this.listOfBillingInvoices.splice(eleIndex, 1);
		}
		this.listOfBillingInvoices.push(val);
	}

	public getBillingInvoiceEle(eleObj?: object, propertyName?: any) {
		if (eleObj && Object.keys(eleObj).length > 0) {
			const eleIndex = this.listOfBillingInvoices.findIndex(ele => ele[propertyName] === eleObj[propertyName]);
			if (eleIndex !== -1) {
				return this.listOfBillingInvoices[eleIndex];
			} else {
				return {};
			}
		} else {
			return this.listOfBillingInvoices && this.listOfBillingInvoices.length > 0 ? this.listOfBillingInvoices[this.listOfBillingInvoices.length - 1] : {};
		}
	}

	public getListOfActionsForSales() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfTransactionTypesForSales() {
		return [
			{
				value: 'new',
				text: 'New',
			},
			{
				value: 'renewal',
				text: 'Renew',
			},
			{
				value: 'reactivate',
				text: 'Re-activate',
			}
		];
	}

	get getListOfStatusesForSales() {
		return [
			// {
			// 	value: 'draft',
			// 	text: 'Draft',
			// 	classname: 'defaultClr',
			// 	color_code: '21b3c6',
			// },
			{
				value: 'unpaid',
				text: 'Unpaid',
				classname: 'red',
				bg_class_name: 'redInvoice',
				color_code: 'ff4545',
			},
			{
				value: 'sent',
				text: 'Outstanding',
				classname: 'purple',
				bg_class_name: 'purpleInvoice',
				color_code: '706cce',
			},
			{
				value: 'paid',
				text: 'Paid',
				classname: 'green',
				bg_class_name: 'greenInvoice',
				color_code: '05c1a0',
			},
			{
				value: 'partiallypaid',
				text: 'Partially Paid',
				classname: 'blue',
				bg_class_name: 'blueInvoice',
				color_code: '21B3C6',
			},
			{
				value: 'overdue',
				text: 'OverDue',
				classname: 'orange',
				bg_class_name: 'orangeInvoice',
				color_code: 'f99300',
			},
			{
				value: 'void',
				text: 'Void',
				classname: 'pink',
				bg_class_name: 'pinkInvoice',
				color_code: 'DB5383',
			},
		];
	}

	get getListOfActionsForSalesInvoiceDetails() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EMAIL',
				btn_name: 'Send Email',
				btn_mage: '',
				icon: IMG_EMAIL_DX_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'DOWNLOAD',
				btn_name: 'Download PDF',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_EXPORT_PDF,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'RAW_DATA',
				btn_name: 'Download Raw Data',
				btn_image: 'column-chooser',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			}
		];
	}

}
