import { Component, OnInit, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { ApinvoiceViewDialogComponent } from '../apinvoice-view-dialog/apinvoice-view-dialog.component';
@Component({
	selector: 'app-view-previous-settlement-period-dialog',
	templateUrl: './view-previous-settlement-period-dialog.component.html',
	styleUrls: ['./view-previous-settlement-period-dialog.component.css']
})
export class ViewPreviousSettlementPeriodDialogComponent implements OnInit, OnChanges {
	@Input() viewPreviousSettlementPeriodProps: any;
	@Output() closeViewPreviousSettlementPeriodPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;
	public globalCurrency: string;
	contractName: string;
	salesPersonName: string;
	public listOfViewPreviousSettlementPeriod: any[];
	public apinvoiceViewDetailsProps: any;
	public isViewApinvoiceViewDetailsPopup: boolean;

	constructor(private _LoginService: LoginService,
		private _AppCommonSrvc: AppCommonSrvc) {

	}

	ngOnInit() {
		this.isShowPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.popupTitleText = 'Settlement Period For Contract';
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['viewPreviousSettlementPeriodProps']) {
			const viewPreviousSettlementPeriodProps = _SimpleChanges['viewPreviousSettlementPeriodProps'].currentValue;

			this.contractName = viewPreviousSettlementPeriodProps.contractName;
			this.salesPersonName = viewPreviousSettlementPeriodProps.salesPersonName;
			this.listOfViewPreviousSettlementPeriod = viewPreviousSettlementPeriodProps.ViewPreviousSettlementPeriodList;
		}
	}

	public doHiddenPopup(event) {
		this.closeViewPreviousSettlementPeriodPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}

	public doOpenApInvoiceLink(vs: any) {
		this.apinvoiceViewDetailsProps = {
			invoiceDetails: vs,
		};
		this.doOpenApinvoiceViewDetailsPopup();
	}

	private doOpenApinvoiceViewDetailsPopup() {
		this.isViewApinvoiceViewDetailsPopup = false;
		setTimeout(() => {
			this.isViewApinvoiceViewDetailsPopup = true;
		}, 150);
	}

	public doCloseApinvoiceViewDetailsPopup(event: any) {
		if (event.isClickOnCloseBtn) {
			this.isViewApinvoiceViewDetailsPopup = false;
		}
	}
}
