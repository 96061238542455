<div class="rightBar">
	<!-- <ul class="topBreadcrumb" *ngIf="CalledFormClaimAttachedDocument === 'billback'">
		<li><a routerLink="/contracts/billbacklist">Billback & Chargeback </a></li>
		<li><a routerLink="/contracts/billback"> Billback & Chargeback Details</a></li>
		<li>
			<a>Documents</a>
		</li>
	</ul> -->
	<!-- <ul class="topBreadcrumb">
		<li>
			<a routerLink="/sales/distribution-chargebacks-list">Chageback</a>
		</li>
		<li>
			<a routerLink="/sales/distribution-chargebacks">Chageback Details</a>
		</li>
		<li>
			<a>Add Chageback</a>
		</li>
	</ul> -->
	<div class="rightBlock">
		<!-- <div class="titleRow">
			<h1>Add Documents Notes</h1>
		</div> -->
		<div class="contentRow cf">
			<div class="borderBox cf padB0" *ngxPermissionsOnly="isCurrentTabPermission">
				<div class="row">
					<div class="cols3">
						<div class="feildCol inputDisableFile">
							<!-- <input name="" type="text" id="rate" [(ngModel)]="chargeDocumentsTabModel.documentURL"> -->
							<div class="fileUpbtn">
								<input type="file" [(ngModel)]="chargeDocumentsTabModel.documentURL"
									accept=".doc,.docx,.csv,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
									(change)="uploadDocument($any($event.target).files)" />
								<span class="fileNamelabel">{{chargeDocumentsTabModel.documentURL}}</span>
								<span class="upldBtn">Browse</span>
							</div>
							<!-- Upload Percent: {{percentDone}}% <br /> -->
							<div class="inputField">
								<label for="rate">Document</label>
							</div>
						</div>
					</div>

					<div class="cols6">
						<div class="feildCol">
							<dx-text-box mode="text" [(value)]="chargeDocumentsTabModel.documentDocumentDescription"
								maxLength="500">
							</dx-text-box>
							<div class="inputField">
								<label for="documentDocumentDescription">Document Description</label>
							</div>
						</div>
					</div>
					<div class="cols3">
						<div class="feildCol">
							<dx-text-box mode="text" [(value)]="chargeDocumentsTabModel.documentReferenceNumber"
								maxLength="500">
							</dx-text-box>
							<div class="inputField">
								<label for="documentReferenceNumber">Document Reference No.</label>
							</div>
						</div>
					</div>

					<div class="cols12">
						<div class="row">
							<div class="cf mrgB10 cols12">
								User Note
							</div>
							<div class="cols12">
								<dx-html-editor [(value)]="chargeDocumentsTabModel.claimedUserNote" [height]="150">
									<dxo-toolbar>
										<dxi-item name="undo"></dxi-item>
										<dxi-item name="redo"></dxi-item>
										<dxi-item name="separator"></dxi-item>
										<dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
										<dxi-item name="separator"></dxi-item>
										<dxi-item name="bold"></dxi-item>
										<dxi-item name="italic"></dxi-item>
										<dxi-item name="strike"></dxi-item>
										<dxi-item name="underline"></dxi-item>
										<dxi-item name="separator"></dxi-item>
										<dxi-item name="alignLeft"></dxi-item>
										<dxi-item name="alignCenter"></dxi-item>
										<dxi-item name="alignRight"></dxi-item>
										<dxi-item name="alignJustify"></dxi-item>
										<dxi-item name="separator"></dxi-item>
										<dxi-item name="orderedList"></dxi-item>
										<dxi-item name="bulletList"></dxi-item>

									</dxo-toolbar>
								</dx-html-editor>
							</div>
						</div>
						<div class="right">
							<a class="button center mrgB10 mrgT10" (click)="addChargebackDocumentsAgreementTabDetails()"
								style="text-transform: capitalize;">{{chargeDocumentsTabModel.guid ? 'Update' : 'Add'}}</a>
						</div>
					</div>

				</div>
			</div>
			<div class="borderBox cf padB0">
				<div class="subTitleRow">
					<h2>List Of Documents</h2>
				</div>
				<div class="mrgB10">
					<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="chargeDocumentsTabDataSource"
						[allowColumnReordering]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
						[allowColumnResizing]="true" [remoteOperations]="true" [columnAutoWidth]="true"
						(onToolbarPreparing)="doToolbarPreparing($event)" [width]="'100%'">
						<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
						<dxo-pager [visible]="true" [showPageSizeSelector]="true"
							[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
						</dxo-pager>

						<dxi-column dataField="documentDate" caption="Date" [width]="100"
							cellTemplate="cellTemplateDocumentData">
							<div *dxTemplate="let element of 'cellTemplateDocumentData'">
								{{ (element.data.documentDate !== null && element.data.documentDate !== '' &&
								element.data.documentDate !== undefined && element.data.documentDate !== 'Invalid date') ?
								(element.data.documentDate | toDateObj | date: 'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>
						<dxi-column dataField="documentURL" [width]="100" caption="Document"></dxi-column>
						<dxi-column dataField="documentDocumentDescription" [width]="350" caption="Document Description">
						</dxi-column>
						<dxi-column dataField="documentReferenceNumber" [width]="100" caption="Reference No."></dxi-column>
						<dxi-column dataField="claimedUserNote" caption="Note" [width]="450"
							cellTemplate="cellTemplateUserNote">
							<div *dxTemplate="let element of 'cellTemplateUserNote'" class="claimNote">
								<div [innerHTML]="element.data.claimedUserNote"></div>
							</div>
						</dxi-column>
						<dxi-column caption="Action" alignment="center" [allowResizing]="false" [width]="80"
							cellTemplate="cellTemplateAction">
							<div *dxTemplate="let element of 'cellTemplateAction'">
								<span class="center txtCenter actBtn">
									<a id="editChargeBackDocumentDetails_{{element.rowIndex + 1 }}"
										(click)="editChargeBackDocumentDetails(element.data);$event.stopPropagation();">
										<span class="iconOne sprite alignRight"></span>
									</a>
								</span>
								<span class="center txtCenter actBtn">
									<a (click)="downloadChargeBackDocument(element.data);$event.stopPropagation();"
										id="downloadDocument_{{element.rowIndex + 1}}">
										<span class="downloadIcon sprite alignRight"></span>
									</a>
								</span>
								<!-- <a (click)="removeChargeBackDocumentDetails(tl);$event.stopPropagation();">
									<span class="icon-deletedxtable alignRight mrgR10" style="width:24px"></span>
								</a> -->

								<dx-tooltip target="#editChargeBackDocumentDetails_{{element.rowIndex + 1 }}"
									showEvent="dxhoverstart" hideEvent="dxhoverend" position="bottom">
									<div *dxTemplate="let data = data of 'content'">
										Edit
									</div>
								</dx-tooltip>
								<dx-tooltip target="#downloadDocument_{{element.rowIndex + 1 }}" showEvent="dxhoverstart"
									hideEvent="dxhoverend" position="bottom">
									<div *dxTemplate="let data = data of 'content'">
										Download Document
									</div>
								</dx-tooltip>
							</div>
						</dxi-column>
					</dx-data-grid>
				</div>
				<div *ngxPermissionsOnly="isCurrentTabPermission">
					<!-- <div class="alignLeft" [ngClass]="{inputDisable: is_disabled_submit_credit_button}">
						<input name="" type="button" class="button" [disabled]="is_disabled_submit_credit_button"
							value="Submit Credit Memo" (click)="submitCreditMemo()">
					</div> -->
					<div class="alignRight mrgB10" *ngIf="!currentClaimElement?.is_disputable">
						<input name="" type="button" class="button" value="Save" (click)="goToBackPage()">
					</div>

					<dx-drop-down-button *ngIf="currentClaimElement?.is_disputable" class="alignRight mrgB10 width200"
						[splitButton]="true" [useSelectMode]="false" [text]="dropTextValue" [items]="listOfStatuses"
						displayExpr="label" keyExpr="guid" (onButtonClick)="onStatusDropButtonClick($event)"
						(onItemClick)="onItemClick($event)">
					</dx-drop-down-button>
				</div>
			</div>
		</div>
	</div>
</div>