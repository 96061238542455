import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { ADMIN_PORTAL, SUPER_ADMIN, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { UserIdleService } from 'angular-user-idle';

import { LoginService } from '@app/services/login.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { Subscription } from 'rxjs';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import { CalledFromEnum } from '@app/constants-enums/enums';
import { Router } from '@angular/router';
import { DataService } from '@app/services/data.service';
import { UserManagementService } from '@app/services/user-management-service/user-management-service.service';
import { ResendVerificationEmailReq } from '@app/models/common.model';
import { environment } from '@environments/environment';

declare function callGeneralJs(): any;
@Component({
	selector: 'app-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.css'],
	providers: [DatePipe]
})
export class PagesComponent implements OnInit, AfterViewInit, OnDestroy {
	myDate: any;
	firstMoveDate: any;
	public is_staging_environment: boolean = false;
	private isAutoLogOutPopUpOpen: boolean = false;
	public setTimeOutRequestCall: any;
	private notificationCountSubscription: Subscription;
	private logInReqSubscription: Subscription;
	private getUserSettingSbsn: Subscription;
	private getFetureCustomFieldsSbsn: Subscription;
	public isShowSubscriptionUpgradePopup: boolean;
	public subscriptionUpgradeProps: any;
	public isShowInvoiceOverduePaymentPopup: boolean;
	public invoiceOverduePaymentProps: any;
	public isRemoveEmailVarifiedSection: boolean;
	constructor(private _LoginService: LoginService,
		private _Router: Router,
		private _GlobalStateService: GlobalStateService,
		private _UserProfileService: UserProfileService,
		private _DataService: DataService,
		private _RestApiService: RestApiService,
		private userIdle: UserIdleService,
		private _UserManagementService: UserManagementService,
		public _AppCommonSrvc: AppCommonSrvc) {
	}

	// @HostListener('window:focus') onFocus() {
	// 	this.myDate = new Date().toLocaleString();
	// 	if (!this.firstMoveDate) {
	// 		this.firstMoveDate = JSON.parse(JSON.stringify(this.myDate));
	// 	}
	// 	else {
	// 		// console.log("first Move DateTime >>>",this.myDate);
	// 		// console.log("Last Move DateTime >>>",this.firstMoveDate);
	// 		const datetime_in: any = new Date(this.myDate);
	// 		const datetime_out: any = new Date(this.firstMoveDate);
	// 		const diffsec: any = (datetime_in - datetime_out);
	// 		// const totalseconds:any =  Math.abs(new Date(datetime_in) - new Date(datetime_out)) / 1000;
	// 		const totalseconds: any = Math.abs(diffsec / 1000);
	// 		// console.log("Diff Secound Time >>>",totalseconds);
	// 		this.isAutoLogOutPopUpOpen = this._AppCommonSrvc.getAutoLogOutPopUpStatus();
	// 		if (totalseconds > MOUSE_MOVE_EXPIRE_RESET_TIME && (!this.isAutoLogOutPopUpOpen)) {
	// 			this.firstMoveDate = undefined;
	// 			setTimeout(() => {
	// 				this.resetBackendExpireTime();
	// 			}, 250);
	// 		}
	// 		else {
	// 			this.firstMoveDate = undefined;
	// 			this.firstMoveDate = JSON.parse(JSON.stringify(this.myDate));
	// 		}
	// 		this.myDate = new Date().toLocaleString();
	// 	}
	// }


	ngOnInit() {
		// this.doCheckToOpenSubscriptionUpgrdePopup();
		// this.doCheckInvoiceOverduePaymentPopup();
		this.setTimeOutRequestCall = setInterval(() => {
			this.resetBackendExpireTime();
		}, 60000);

		let strURL = environment.URL_SERVER.split('//')[1].split('.')[0] || '';
		if (strURL) {
			this.is_staging_environment = strURL === 'staging' ? true : false;
		}

		this._GlobalStateService.subscribe('TOKEN_REFRESHER_INTERVAL_EVENT', response => {
			if (response) {
				clearInterval(this.setTimeOutRequestCall);
				this._GlobalStateService.notifyDataChangedDuplicate('NOTIFICATION_REFRESHER_INTERVAL_EVENT', new Date().getTime());
			}
		});

		this._GlobalStateService.subscribe('fetchedNotificationCounter', response => {
			if (response.isResponse) {
				this.getNotificationCounter(response.data);
			} else {
				this.getNotificationCounter();
			}
		});
		this._GlobalStateService.subscribe('fetchedFetureCustomFields', response => {
			if (response) {
				this.doGetFetureCustomFields();
			}
		});
		this.fetchUserSettings();
		this.doGetFetureCustomFields();
	}

	ngAfterViewInit() {
		// callGeneralJs();
	}

	ngOnDestroy(): void {
		this._GlobalStateService.unsubscribe('TOKEN_REFRESHER_INTERVAL_EVENT');
		clearInterval(this.setTimeOutRequestCall);
		this._GlobalStateService.unsubscribe('fetchedNotificationCounter');
		this._GlobalStateService.unsubscribe('fetchedFetureCustomFields');
		if (this.notificationCountSubscription) {
			this.notificationCountSubscription.unsubscribe();
		}
		if (this.logInReqSubscription) {
			this.logInReqSubscription.unsubscribe();
		}
		if (this.getUserSettingSbsn) {
			this.getUserSettingSbsn.unsubscribe();
		}
		if (this.getFetureCustomFieldsSbsn) {
			this.getFetureCustomFieldsSbsn.unsubscribe();
		}
	}

	resetBackendExpireTime(): void {
		if (this._LoginService.loginUser && this._LoginService.loginUser.user) {
			if (this.logInReqSubscription) {
				this.logInReqSubscription.unsubscribe();
			}
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			// this.loaderService.show();
			this.logInReqSubscription = this._RestApiService.doLoginReqFormData(formData).subscribe({
				next: response => {
					// console.log('ResetBackendExpireTime');
					// this.loaderService.hide();
					this.firstMoveDate = undefined;
					this.userIdle.stopTimer();
					this.userIdle.stopWatching();
					this.userIdle.startWatching();
				}, error: error => {
					// this.loaderService.hide();
					this.userIdle.stopTimer();
					this.userIdle.stopWatching();
					this.userIdle.startWatching();
					//console.warn(error.error.message);
				}
			});
		}
	}

	// Method for Fetched Update Notification Counter on Header page.
	private getNotificationCounter(searchCriteria?: any) {
		const defaultSearchCriteria = {
			status: 'unread'
		};
		let serchCriteriaData = undefined;
		if (searchCriteria) {
			serchCriteriaData = searchCriteria;
		} else {
			serchCriteriaData = defaultSearchCriteria;
		}
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('entity', ServerEntity.NOTIFICATION_HEADER);
		reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		if (serchCriteriaData) {
			reqFormData.append('search', JSON.stringify(serchCriteriaData));
		}
		if (this.notificationCountSubscription) {
			this.notificationCountSubscription.unsubscribe();
		}
		this.notificationCountSubscription = this._RestApiService.doDataOutReqFormData((reqFormData)).subscribe({
			next: response => {
				if (response && response.flag) {
					if (response.data) {
						// Notification Counter functionality
						const unreadNotificationCounter = {
							isResponse: false,
							data: {
								unread_notification_count: response.data.unread_notification_count || 0
							}
						};
						unreadNotificationCounter.isResponse = false;
						if (response.data && +response.data.unread_notification_count >= 0) {
							unreadNotificationCounter.isResponse = true;
						}
						this._GlobalStateService.notifyDataChangedDuplicate('unreadNotificationCounter', unreadNotificationCounter);

						// Synced With ERP functionality
						const syncedWithERPData = {
							isResponse: false,
							data: {
								service_error_found: response.data.service_error_found,
								service_message: response.data.service_message
							}
						};
						if (response.data && response.data.service_message) {
							syncedWithERPData.isResponse = true;
						}
						this._GlobalStateService.notifyDataChangedDuplicate('syncedWithErpMessage', syncedWithERPData);
					}
				}
			}, error: error => {

			}
		});
	}

	// Method used to get the Revenue configurations
	public fetchUserSettings() {
		if (this.getUserSettingSbsn) {
			this.getUserSettingSbsn.unsubscribe();
		}
		this.getUserSettingSbsn = this._AppCommonSrvc.fetchUserSettings().subscribe({
			next: (response) => {
				if (response) {
					this._AppCommonSrvc.setUserSettings(response.data || []);
				}
			}, error: (error) => {
			}
		});
	}

	public doGetFetureCustomFields() {
		if (this.getFetureCustomFieldsSbsn) {
			this.getFetureCustomFieldsSbsn.unsubscribe();
		}
		this.getFetureCustomFieldsSbsn = this._AppCommonSrvc.fetchListOfFetureCustomFields().subscribe({
			next: (response) => {
				this._AppCommonSrvc.setFeatureCustomFields([]);
				if (response && response.flag) {
					this._AppCommonSrvc.setFeatureCustomFields(response.data || []);
				}
			}, error: (error) => {
				this._AppCommonSrvc.setFeatureCustomFields([]);
			}
		});
	}

	private doOpenSubscriptionUpgradePopup() {
		this.isShowSubscriptionUpgradePopup = false;
		setTimeout(() => {
			this.isShowSubscriptionUpgradePopup = true;
		}, 200);
	}

	public doCloseSubscriptionUpgradePopup(event: any) {
		if (event.isClickOnCloseBtn) {
			this.isShowSubscriptionUpgradePopup = false;
			return;
		}
		if (event.isAllowed) {
			if (event.redirectTo) {
				this.isShowSubscriptionUpgradePopup = false;
				this._Router.navigate(['/subscriptions/trial-to-purchase-subscriptions']);
				return;
			}
			if (event.isCancel) {
				const accountDetailObj = this._LoginService.loginUser.account_detail;
				if (!accountDetailObj.is_trial || (accountDetailObj.is_trial && accountDetailObj.trial_remaining_days < 0)) {
					this._DataService.logout();
				}
			}
		}
		this.isShowSubscriptionUpgradePopup = false;
	}

	private doCheckToOpenSubscriptionUpgrdePopup() {
		const accountDetailObj = this._LoginService.loginUser.account_detail;
		if (accountDetailObj.role === SUPER_ADMIN || accountDetailObj.is_trial) {
			const subscription = accountDetailObj.subscription;
			if (subscription || accountDetailObj.is_trial) {
				if (!subscription.tier_requirement_match || accountDetailObj.is_trial) {
					const trial_remaining_days = accountDetailObj.trial_remaining_days;
					if (trial_remaining_days < 0) {
						this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_TIRAL_EXPIRED);
					} else {
						this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_TIRAL);
					}
					this.subscriptionUpgradeProps = {
						subscription: subscription,
						is_trial: accountDetailObj.is_trial,
						message: '',
						buttonText: {
							cancel: 'Ok'
						}
					};
					this.doOpenSubscriptionUpgradePopup();
				}
			}
		}
	}

	private doCheckInvoiceOverduePaymentPopup() {
		const accountDetailObj = this._LoginService.loginUser.account_detail;
		if (accountDetailObj && accountDetailObj.is_invoice_overdue) {
			this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_OVERDUE_PAYMENT);
			this.doOpenInvoiceOverduePaymentPopup();
		}
	}

	private doOpenInvoiceOverduePaymentPopup() {
		this.isShowInvoiceOverduePaymentPopup = false;
		setTimeout(() => {
			this.isShowInvoiceOverduePaymentPopup = true;
		}, 200);
	}

	public doCloseInvoiceOverduePaymentPopup(event: any) {
		if (event.isClickOnCloseBtn) {
			this.isShowInvoiceOverduePaymentPopup = false;
			return;
		}
		if (event.isAllowed) {
			this.isShowInvoiceOverduePaymentPopup = false;
			this._UserManagementService.setTabSelection('billinghistory');
			this._Router.navigate(['/usermanagements/billinghistory']);
		}
	}

	public doRemoveEmailVarifiedSection(email_verified) {
		this.isRemoveEmailVarifiedSection = email_verified;
	}

}
