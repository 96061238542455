import { DistributionChargebackContractModelRequest } from '@app/models/distribution-chargeback-contract.model';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageOptions } from '@app/models/common.model';
import { ServerMethods, ServerEntity, PAGE_SIZE_OPTIONS } from '@app/constants-enums/constants';
import { Guid } from '@app/models/guid';
import { DistributionChargebackContractService } from '@app/services/contract-service/billback/distribution-chargeback-contract.service';
import { Router } from '@angular/router';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS, MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { DistributionChargebackContractModelUI } from '@app/models/distribution-chargeback-contract.model';
import * as moment from 'moment';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';

@Component({
	selector: 'app-distribution-chargeback-contract',
	templateUrl: './distribution-chargeback-contract.component.html',
	styleUrls: ['./distribution-chargeback-contract.component.css'],
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
	]
})
export class DistributionChargebackContractComponent implements OnInit, OnDestroy {

	public billbackTypeRadio: any[];
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public erp_type: string;
	public currentAgreementTab: string;
	public editDistributionChargebackGuid: string;
	public showNotification: string;

	// Select box list customer
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public isCustomerDropDownBoxOpened: boolean = false;
	public changeBackTypesTabData: any[] = [];
	public chargeBackClaimsTabData: any[] = [];

	public guid = new Guid();
	public pageOptions: PageOptions = new PageOptions();
	public distributionChargebackContractModelUI = new DistributionChargebackContractModelUI();
	public distributionChargebackContractModelRequest = new DistributionChargebackContractModelRequest();

	// Api call request object
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;

	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild('dataGridContainerOfChargebackClamisTab') dataGridContainerOfChargebackClamisTab: DxDataGridComponent;
	constructor(
		private _Router: Router,
		private _ToastrService: ToastrService,
		private _PermissionsService: NgxPermissionsService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		public _BillbackService: BillbackService,
		private _BillbackContractService: BillbackContractService,
		private _ChargebackContractService: ChargebackContractService,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,
		private _ChangeDetectorRef: ChangeDetectorRef,
		public _DistributionChargebackContractService: DistributionChargebackContractService
	) {
	}

	ngOnInit() {
		this.editDistributionChargebackGuid = this._DistributionChargebackContractService.getEditDistributionChargebackGuid();
		this.customerGridBoxColumns = this._BillbackService.getCustomerGridBoxColumn();
		this.preInit();
		this.billbackTypeRadio = this._BillbackService.fetchBillbackTypeListRadio();
		this.distributionChargebackContractModelUI.billBackType = 'distributionchargeback';
		this.loadCustomerDataList();
	}

	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	preInit() {
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		}
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per)
		}
		if (permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		if (this.editDistributionChargebackGuid) {
			this.editDistributionChargebackContract();
		} else {
			this.distributionChargebackContractModelUI = new DistributionChargebackContractModelUI();
			this.currentAgreementTab = 'chargebacktypes';
		}
		this.disableDistributionChargebackInputFields();
	}

	// Click agreement tab
	doClickAgreementTab(currentAgreementTab) {
		switch (currentAgreementTab) {
			case 'chargebacktypes':
				this.currentAgreementTab = 'chargebacktypes';
				break;
			case 'chargebackclaims':
				this.currentAgreementTab = 'chargebackclaims';
				break;
		}
	}

	//#region for Customer select box methods
	public loadCustomerDataList() {
		const viewFields = ['guid', 'code', 'name'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS,
			view_fields: JSON.stringify(viewFields)
		};
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "code",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}
	public onOptionChangedCustomer(e) {
		if (e.name === "value") {
			if (this.customerDataGrid) {
				const selectedCustomerArr = this.customerDataGrid.instance.getSelectedRowsData();
				if (selectedCustomerArr && selectedCustomerArr.length > 0) {
					const selectedCustomerObj = selectedCustomerArr[0];
					if (selectedCustomerObj) {
						this.distributionChargebackContractModelUI.selectCustomerId = selectedCustomerObj.code;
						this.distributionChargebackContractModelUI.selectCustomerName = selectedCustomerObj.name;
					}
				}
			}
			this.isCustomerDropDownBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}

	}
	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.customerGridBoxValue = [];
			this.customerDataGrid.instance.refresh();
		}
	}
	public displayExprCustomerName(customer) {
		if (!customer) {
			return "";
		}
		const name = customer.name;

		return name;
	}
	//#endregion

	public doChangeBillBackType(e) {
		this.distributionChargebackContractModelUI.billBackType = e.value;
		this._BillbackService.setBillbackType(this.distributionChargebackContractModelUI.billBackType);
		switch (this.distributionChargebackContractModelUI.billBackType) {
			case 'billback':
				this._BillbackContractService.setEditBillbackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/billback-contract/']);
				break;
			case 'chargeback':
				this._ChargebackContractService.setEditChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/chargeback-contract/']);
				break;
			case 'enduserschargeback':
				this._EndUsersChargebackContractService.setEditEndUsersChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/end-users-chargeback-contract/']);
				break;
			case 'distributionchargeback':
				// this._BillbackService.setBillbackType('distributionchargeback');
				// this._Router.navigate(['/contracts/billbackchargeback/distribution-chargeback-contract/']);
				break;
			case 'minmaxenduserschargeback':
				this._MinMaxEndUsersChargebackContractService.setEditMinMaxEndUsersChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/min-max-end-users-chargeback-contract/']);
				break;
		}
	}

	public checkValidation(): boolean {
		if (!this.distributionChargebackContractModelUI.contractName) {
			this._ToastrService.info('Contract Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.distributionChargebackContractModelUI.selectCustomerName) {
			this._ToastrService.info('Customer is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.distributionChargebackContractModelUI.startDate) {
			this._ToastrService.info('Start Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
	}

	public doSaveDistributionChargebackContracts(isProductSave?: boolean) {
		if (this.customerDataGrid && !this.editDistributionChargebackGuid) {
			let supplierDetails: any = this.customerDataGrid.instance.getSelectedRowsData();
			if (supplierDetails && supplierDetails.length > 0) {
				this.distributionChargebackContractModelUI.selectCustomerId = supplierDetails[0].code;
				this.distributionChargebackContractModelUI.selectCustomerName = supplierDetails[0].name;
			}
		}
		if (this.checkValidation()) {
			return false;
		}

		this.distributionChargebackContractModelRequest = new DistributionChargebackContractModelRequest();
		if (this._DistributionChargebackContractService.getEditDistributionChargebackGuid()) {
			this.distributionChargebackContractModelRequest.guid = this._DistributionChargebackContractService.getEditDistributionChargebackGuid();
		} else {
			this.distributionChargebackContractModelRequest.guid = this.guid.newGuid();
		}

		this.distributionChargebackContractModelRequest.contractName = this.distributionChargebackContractModelUI.contractName;
		this.distributionChargebackContractModelRequest.selectCustomerId = this.distributionChargebackContractModelUI.selectCustomerId;
		this.distributionChargebackContractModelRequest.selectCustomerName = this.distributionChargebackContractModelUI.selectCustomerName;
		this.distributionChargebackContractModelRequest.startDate = moment(this.distributionChargebackContractModelUI.startDate).format('YYYY-MM-DD') || '';
		this.distributionChargebackContractModelRequest.endDate = moment(this.distributionChargebackContractModelUI.endDate).format('YYYY-MM-DD') || '';
		this.distributionChargebackContractModelRequest.billBackType = this.distributionChargebackContractModelUI.billBackType;
		this.distributionChargebackContractModelRequest.is_active = true;
		this.distributionChargebackContractModelRequest.categories = this.distributionChargebackContractModelUI.chargeBackTypesTabData || [];
		this.distributionChargebackContractModelRequest.chargebackClaims = this.distributionChargebackContractModelUI.chargeBackClaimsTabData || [];
		const arryDistributionChargebackContracts = [];
		arryDistributionChargebackContracts.push(JSON.parse(JSON.stringify(this.distributionChargebackContractModelRequest)));
		this.callSaveDistributionChargebackContracts(arryDistributionChargebackContracts);
	}

	public doSaveChargebackTypesTabData(event) {
		// upload item did not get new list for item tab 
		this.doSaveDistributionChargebackContracts();
	}
	public doSaveChargebackClaimsTabData(event) {
		// upload item did not get new list for item tab 

		this.doSaveDistributionChargebackContracts();
	}

	public callSaveDistributionChargebackContracts(arryDistributionChargebackContracts: any[]) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('distributionchargebackcontracts', JSON.stringify(arryDistributionChargebackContracts));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq((formData)).subscribe(
			response => {
				if (response.flag) {
					if (this.showNotification !== 'No') {
						this._ToastrService.success('Save Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}
					const objdistributionChargebackContracts = response.data.distributionChargebackContracts;
					let dDistributionChargebackContracts: any;
					if (objdistributionChargebackContracts && objdistributionChargebackContracts.length > 0) {
						dDistributionChargebackContracts = objdistributionChargebackContracts[0];
						this.changeBackTypesTabData = dDistributionChargebackContracts.changeBackTypesTabData;
						this.chargeBackClaimsTabData = dDistributionChargebackContracts.chargeBackClaimsTabData;
						this._DistributionChargebackContractService.setEditDistributionChargebackGuid(dDistributionChargebackContracts.guid);
						this.editDistributionChargebackGuid = dDistributionChargebackContracts.guid;
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
				this.disableDistributionChargebackInputFields();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	public editDistributionChargebackContract() {
		let searchParams = [{ 'guid': this.editDistributionChargebackGuid }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', 'distributionchargebackcontracts');
		// formData.append('contractguid', this.editDistributionChargebackGuid);
		formData.append('search', JSON.stringify(searchParams));
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(response => {
			if (response.flag) {
				const editChargebackContractDetails = response.data[0];
				this.distributionChargebackContractModelUI.guid = this._DistributionChargebackContractService.editDistributionChargebackGuid;
				this.distributionChargebackContractModelUI.contractName = editChargebackContractDetails.contractName;
				this.distributionChargebackContractModelUI.selectCustomerId = editChargebackContractDetails.selectCustomerId;
				this.distributionChargebackContractModelUI.selectCustomerName = editChargebackContractDetails.selectCustomerName;
				this.distributionChargebackContractModelUI.startDate = moment(editChargebackContractDetails.startDate).format('YYYY-MM-DD') || '';
				this.distributionChargebackContractModelUI.endDate = moment(editChargebackContractDetails.endDate).format('YYYY-MM-DD') || '';
				this.distributionChargebackContractModelUI.billBackType = editChargebackContractDetails.billBackType;
				this.distributionChargebackContractModelUI.is_active = editChargebackContractDetails.is_active;
				this.distributionChargebackContractModelUI.chargeBackTypesTabData = editChargebackContractDetails['categories'] || [];
				this.distributionChargebackContractModelUI.chargeBackClaimsTabData = editChargebackContractDetails['chargebackClaims'] || [];
				this.customerGridBoxValue = [editChargebackContractDetails.selectCustomerId];
				this.currentAgreementTab = 'chargebacktypes';
			} else {
				this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
			}
			this._LoaderService.hide();
		}, error => {
			console.error('error', error);
			this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			this._LoaderService.hide();
		});
	}

	disableDistributionChargebackInputFields() {
		if (this.editDistributionChargebackGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}

	}
}
