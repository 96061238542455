<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="approvalsDataSource" [showBorders]="true"
	[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true"
	[remoteOperations]="{ groupPaging: true }" [dateSerializationFormat]="'yyyy-MM-dd'" [filterValue]="filterValue"
	(onCellClick)="doCellClickDetails($event)" [width]="'100%'" (onToolbarPreparing)="doToolbarPreparing($event)"
	[columnResizingMode]="'nextColumn'" [allowColumnReordering]="true" class="cellColor hoverClr hideFilterPanelInx">

	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>
	<dxo-selection [mode]="is_enable_min_max_claim ? 'multiple' :'single'" [allowSelectAll]="false"> </dxo-selection>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="true" [useIcons]="true"> </dxo-editing>

	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadApproveNotificationState"
		[customSave]="doSaveApproveNotificationState" [storageKey]="stateStorageKey">
	</dxo-state-storing>

	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="claim_id" caption="Claim Id" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateClaimId">
		<div *dxTemplate="let element of 'cellTemplateClaimId'">
			{{ element.data.claim_id}}
		</div>
	</dxi-column>

	<!-- <dxi-column dataField="created_date" caption="Created Date" dataType="date" [allowSorting]="true"
				[allowHeaderFiltering]="false" cellTemplate="cellTemplateCreatedDate" sortOrder="desc"
				[visible]="false">
				<div *dxTemplate="let element of 'cellTemplateCreatedDate'">
					{{ element.data.created_date}}
				</div>
			</dxi-column> -->

	<dxi-column dataField="type" caption="Type" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateType" [visible]="false" [showInColumnChooser]="false">
		<div *dxTemplate="let element of 'cellTemplateType'">
			{{ element.data.type}}
		</div>
	</dxi-column>

	<dxi-column dataField="contract_name" caption="Contract Name" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateContractmName">
		<div *dxTemplate="let element of 'cellTemplateContractmName'">
			{{ element.data.contract_name}}
		</div>
	</dxi-column>

	<dxi-column dataField="selectCustomerId" caption="Customer Code" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<dxi-column dataField="created_by_name" caption="Created By" dataType="string" [allowHeaderFiltering]="true"
		cellTemplate="cellTemplateCreatedBy" [allowFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateCreatedBy'">
			{{element.data.created_by_name}}
		</div>
	</dxi-column>
	<!--Note : https://incentx.atlassian.net/browse/IX-3059?focusedCommentId=13619 as per that comment -->
	<dxi-column dataField="created_date" caption="Document Date" dataType="date" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateDocDate">
		<div *dxTemplate="let element of 'cellTemplateDocDate'">
			{{ (element.data.created_date !== null && element.data.created_date !== '' &&
			element.data.created_date
			!== undefined && element.data.created_date !== 'Invalid date') ? (element.data.created_date | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>
	<dxi-column dataField="claim_date" caption="Claim Date" dataType="date" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateClaimDate">
		<div *dxTemplate="let element of 'cellTemplateClaimDate'">
			{{ (element.data.claim_date !== null && element.data.claim_date !== '' &&
			element.data.claim_date
			!== undefined && element.data.claim_date !== 'Invalid date') ? (element.data.claim_date | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="claimed_items_count" caption="Claimed Item Line(s)" dataType="number" [allowFiltering]="false"
		[allowFiltering]="false" [allowHeaderFiltering]="false" cellTemplate="cellTemplateClaimedItemCount">
		<div *dxTemplate="let element of 'cellTemplateClaimedItemCount'">
			{{element.data.claimed_items_count || 0}}
		</div>
	</dxi-column>

	<dxi-column dataField="claim_amount" caption="Approved Amount" dataType="number" [allowHeaderFiltering]="false"
		[allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column dataField="user_claim_amount_total" caption="Claimed Amount" dataType="number"
		[allowHeaderFiltering]="false" [allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
	</dxi-column>

	<dxi-column dataField="claimedStatus" [allowFiltering]="false" [allowHeaderFiltering]="true" alignment="center"
		caption="Status" dataType="string" cellTemplate="cellTemplateApprovalStatus">
		<dxo-header-filter [dataSource]="listOfHeaderFilterStatuses">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateApprovalStatus'">
			<div *ngIf="element.data.claimedStatus === 'inprocess'">
				<dx-drop-down-button [splitButton]="false"
					[ngClass]="getClassNameForStatusField(element.data.claimedStatus)"
					[text]="element.data.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'text' | uppercase"
					stylingMode="contained" class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
					[dataSource]="listOfStatuses" [wrapItemText]="true" displayExpr="text" keyExpr="value"
					(onButtonClick)="getListOfStatus(element.data)"
					(onItemClick)="changedApprovalStatusAction($event,element.data.guid)" [width]="'100%'">
				</dx-drop-down-button>
			</div>
			<div *ngIf="element.data.claimedStatus !== 'inprocess'">
				<div class="stData stBtn"
					[ngClass]="element.data?.claimedStatus || '' | filterFromList:listOfApproveStatuses : 'value' : 'classnameBg'">
					{{element.data.claimedStatus || '' | filterFromList:listOfApproveStatuses : 'value' :
					'finaltext'}}
				</div>
			</div>
		</div>
	</dxi-column>

	<dxi-column type="buttons" caption="Action" [fixed]="true" fixedPosition="right" [allowFiltering]="false"
		[allowHeaderFiltering]="false" [allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150"
		[width]="150" [allowExporting]="false" cellTemplate="cellTemplateAction">
		<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
			<a (click)="viewApprovalsPopup(element.data)">
				<span class="icon-view icon-view_action-col"></span>
			</a>
			<a name="delete" *ngIf="element.data.claimedStatus === 'inprocess'"
				(click)="deleteApproveListItem(element.data)">
				<span class="icon-deletedxtable"></span></a>
		</div>
	</dxi-column>

</dx-data-grid>
<app-eucc-view-chargeback-documents-dx-popup (hideViewClaimPopUpDataEvent)="doHideViewClaimDxDialog($event)"
	[loadViewChargebackDocDxDialogSubject]="loadViewChargebackDocDxDialogSubject"></app-eucc-view-chargeback-documents-dx-popup>