<div class="numberBlock hoverClr" (click)="tilesClick('CUSTOMERS')">
	<span class="contractIcon">
		<span class="icon-customer icon-set1"></span>
	</span>
	<div class="blockText">
		<strong>
			<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
			<span *ngIf="!resourcesLoaded">
				{{activeCustomers || 0 | customNumberSuffixPipe}}
			</span>
		</strong>
		<span class="blockDate">Active</span>
		Customers
	</div>
</div>