import { Component, Input, OnInit, OnDestroy, HostListener, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { GlobalPopoupHeight80Ratio, GlobalPopoupHeight90Ratio, GlobalPopoupWidth80Ratio, GlobalPopoupWidth90Ratio, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { HeaderComponent } from '@app/pages/header/header.component';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import CheckBox from 'devextreme/ui/check_box';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { CurrencyOptions } from '@app/models/common.model';
@Component({
	selector: 'app-close-multiple-settlement-dx-dialog',
	templateUrl: './close-multiple-settlement-dx-dialog.component.html',
	styleUrls: ['./close-multiple-settlement-dx-dialog.component.css'],
})
export class CloseMultipleSettlementDxDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("closeMultiSettlmenttDataGridContainer") closeMultiSettlmenttDataGridContainer: DxDataGridComponent;
	@Output() closeCardViewMultipleSettlementPopup = new EventEmitter<any>();
	@Input() closeMultipleSettlementProp: any;
	public isShowPopup: boolean = true;
	public titleText: string = 'Close Multiple Settlement';
	public _PopupHeightWidth = PopupHeightWidth;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public closeMultiSettlementDataSource: any;
	public closeMultiSettlementDataList: any;
	private isDisabledClearBtn: boolean = true;
	private selectAllItem: boolean | null = null;
	private instanceClearBtn: any = null;
	private filterValueChangeSbsn: Observable<any>;
	private mainSectionStorageKey: string;
	private updateUserSettingSbsn: Subscription;
	public stateStorageKey: string;
	private storedFiltersValue: any;
	private guid = new Guid();
	public filterValue: any[] = [];
	public filterText: string = '';
	public globalCurrency: string;
	public selectedRows: number[] = [];
	public listOfSettlementPeriodHeaderFilter: any[] = this._CommissionService.getListOfSettlementPeriods
	public updateSettlementPeriodBtnText = 'Update Current Settlement Period';
	public decimalPointForCurrency: number;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	constructor(
		private _LoginService: LoginService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _RestApiService: RestApiService,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _CommissionService: CommissionService,
		private _GlobalStateService: GlobalStateService,
		private _IxDxAlertService: IxDxAlertService,
	) {
		this.doLoadViewCloseMultipleSettlementDocument = this.doLoadViewCloseMultipleSettlementDocument.bind(this);
		this.doSaveViewCloseMultipleSettlementDocument = this.doSaveViewCloseMultipleSettlementDocument.bind(this);
		this.doCurrencyCalculateSettlementAmount = this.doCurrencyCalculateSettlementAmount.bind(this);
	}

	ngOnInit(): void {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.globalCurrency = this._LoginService.globalCurrency;
		this.mainSectionStorageKey = ServerSections.INCENTIVES_COMMISSION_CONTRACT_LIST;
		this.stateStorageKey = ServerSections.CLOSE_MULTIPLE_SETTLEMENT_COMMISSION;
		this.loadCloseMultipleSettlementDataSource();

		// setTimeout(() => {
		// 	this.filterValueChangeSbsn = this.closeMultiSettlmenttDataGridContainer.filterValueChange.asObservable();
		// 	this.filterValueChangeSbsn.subscribe((result) => {
		// 		this.getFilterText();
		// 	});
		// }, 60);
		// setTimeout(() => {
		// 	if (this.closeMultiSettlmenttDataGridContainer && this.closeMultiSettlmenttDataGridContainer.instance) {
		// 		this.closeMultiSettlmenttDataGridContainer.selectedRowKeys = [];
		// 	}
		// }, 200);
	}
	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.closeMultiSettlmenttDataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.closeMultiSettlmenttDataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.closeMultiSettlmenttDataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}

	ngAfterViewInit() {
	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	public doHiddenPopop() {
		this.isShowPopup = false;
		this.closeCardViewMultipleSettlementPopup.emit();
	}

	public onEditorPreparing(e) {
		if (e.parentType == 'headerRow' && e.command == 'select') {
			e.editorElement.remove();
		}
	}

	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;

		toolbarItems.unshift(
			// {
			// 	location: 'after',
			// 	template: 'templateFilterText',
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		disabled: this.isDisabledClearBtn,
			// 		text: "Clear",
			// 		icon: "cleariconix",
			// 		onInitialized: (args: any) => {
			// 			this.instanceClearBtn = args.component;
			// 		},
			// 		onClick: () => {
			// 			this.closeMultiSettlmenttDataGridContainer.instance.clearFilter();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'filter',
			// 		text: "Filter",
			// 		onClick: () => {
			// 			this.doOpenFilterBuilder();
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'refresh',
			// 		text: "Refresh",
			// 		onClick: (event: any) => {
			// 			this.refreshDataGridComponent();
			// 		}
			// 	}
			// },
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
		}
	}

	columnChooserClick() {
		this.closeMultiSettlmenttDataGridContainer.instance.showColumnChooser();
	}

	public doOnValueChangedSelectAll(e) {
		this.selectAllItem = e.value;
		if (e.value) {
			this.selectedRows = this.closeMultiSettlementDataList.filter((item) => item['gl_account_number'] && item['salesperson_name']);
		} else {
			if (this.closeMultiSettlementDataList && this.closeMultiSettlementDataList.length > 0) {
				const validateItemList = this.closeMultiSettlementDataList.filter((item) => item['gl_account_number'] && item['salesperson_name']);
				if (validateItemList && (validateItemList.length === this.selectedRows.length)) {
					this.closeMultiSettlmenttDataGridContainer.instance.clearSelection();
					this.selectedRows = [];
				}
			}
		}
		// this.closeMultiSettlmenttDataGridContainer.instance.selectAll();
	}

	public doOnCellPreparedCloseMultipalTab(e) {
		if (e.rowType === "data" && e.column.command === 'select' && (!e.data['gl_account_number'] || !e.data['salesperson_name'])) {
			var editor = CheckBox.getInstance(e.cellElement.querySelector(".dx-select-checkbox"));
			editor.option("disabled", true);
			e.cellElement.style.pointerEvents = 'none';
		}
	}

	public doOnCellClickCloseMultipalTab(e) {
		if (e.column['command'] && e.column['command'] === 'select') {
			// if (this.closeMultiSettlementDataList && this.closeMultiSettlementDataList.length > 0) {
			// 	const validateItemList = this.closeMultiSettlementDataList.filter((item) => item['gl_account_number'] && item['salesperson_name']);
			// 	if (validateItemList && (validateItemList.length === e.selectedRowsData.length)) {
			// 		this.selectAllItem = true;
			// 	} else {
			// 		this.selectAllItem = false;
			// 	}
			// }
		}
	}

	public onCloseMultipleSelectionChanged(e) {
		// console.log('e.selectedRowsData :: ', e.selectedRowsData);
		let disabledKeys = e.currentSelectedRowKeys;
		if (disabledKeys && disabledKeys.length > 0) {
			const gl_account_number = disabledKeys[0]['gl_account_number'] || '';
			const salesperson_name = disabledKeys[0]['salesperson_name'] || '';
			if (!gl_account_number || !salesperson_name) {
				e.component.deselectRows(disabledKeys);
			}
		}
		// if (this.closeMultiSettlementDataList && this.closeMultiSettlementDataList.length > 0) {
		// 	const validateItemList = this.closeMultiSettlementDataList.filter((item) => item['gl_account_number'] && item['salesperson_name']);
		// 	if (validateItemList && (validateItemList.length === e.selectedRowsData.length)) {
		// 		this.selectAllItem = true;
		// 	} else {
		// 		if (this.selectAllItem) {
		// 			this.selectAllItem = false;
		// 		}
		// 	}
		// }
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.closeMultiSettlmenttDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	public refreshDataGridComponent() {
		this.closeMultiSettlmenttDataGridContainer.instance.refresh();
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.closeMultiSettlmenttDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	private loadCloseMultipleSettlementDataSource() {
		const cloneThis = this;
		const viewFields = ['number', 'guid', 'ixcode', 'contractName', 'startDate', 'endDate', 'is_active', 'settlementPeriod', 'commissionPercent', 'subusername', 'salesperson_name', 'salesperson_first_name', 'salesperson_last_name', 'settlementPeriodStart', 'settlementPeriodEnd', 'current_settlement_commission_total', 'apInvoiceCreatedInERP', 'gl_account_number', 'currency_code'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			contracttype: 'commissioncontracts',
			onlyopen: true,
			entity: ServerEntity.CLOSE_SETTLEMENTS,
			view_fields: JSON.stringify(viewFields)
		};
		this.closeMultiSettlementDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			onLoaded(result) {
				cloneThis.closeMultiSettlementDataList = result;
				cloneThis.selectedRows = [];
			}
		});
	}

	public doSubmitCloseSettlementClaim() {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Close Settlement',
			message: 'Are you sure you want to close current settlement period? <br/>You will not be able to undo the operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const IsOpenMultiSettlmentPopUp = this._CommissionService.getIsOpenMultiSettlmentPopUp();
				if (IsOpenMultiSettlmentPopUp) {
					this._ToastrService.info('The previous process is still in progress. Please try after that complete.', 'Info', { closeButton: true, tapToDismiss: true });
					return;
				}
				const selectedRowData = this.closeMultiSettlmenttDataGridContainer.instance.getSelectedRowsData();
				if (selectedRowData && selectedRowData.length > 0) {
					if (this.closeMultiSettlmenttDataGridContainer && this.closeMultiSettlmenttDataGridContainer.instance) {
						const selectedRow = this.closeMultiSettlmenttDataGridContainer.instance.getSelectedRowsData();
						if (selectedRow.length > 0) {
							this.closeMultiSettlmenttDataGridContainer.instance.deselectRows(selectedRow);
						}
					}
					this.doHiddenPopop();
					this._GlobalStateService.notifyDataChangedDuplicate('VIEW_CLOSED_MULTIPLE_SETTLEMENT_POPUP', { isDefault: true, selectedRowData: selectedRowData });
				} else {
					this._ToastrService.info('Select atleast one settlement', 'Info', { closeButton: true, tapToDismiss: true });
				}
			}
		});
	}

	// #region for Datagrid State Storing functionality

	public doLoadViewCloseMultipleSettlementDocument() {
		// this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveViewCloseMultipleSettlementDocument(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}

	// #endregion
	public doCurrencyCalculateSettlementAmount(rowData: any) {
		if (rowData && rowData.hasOwnProperty('currency_code')) {
			this.customCurrencyOption.currencyCode = rowData.currency_code;
		} else {
			this.customCurrencyOption.currencyCode = '';
		}
		return this._CommissionService.convertCurrency(rowData.current_settlement_commission_total, this.customCurrencyOption);
	}
}
