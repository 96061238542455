import { Injectable } from '@angular/core';

@Injectable({
providedIn: 'root'
})
export class SidemenuService {
	public selectedCurrentMenu: any;

constructor() { }

public setCurrentMenu(val: any) {
		this.selectedCurrentMenu = val;
}
public getCurrentMenu() {
		return this.selectedCurrentMenu;
	}
}
