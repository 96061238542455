import * as $ from 'jquery';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { DistributionChannelsCallFromViewEnum, ErpTypeValEnum, SettlementPeriodsValEnum } from '@app/constants-enums/enums';
import {
	CommissionContractModelRequest, CommissionContractModelUI, ExclusionTabModel,
	RuleTabModel
} from '@app/models/commission-contract.model';
import { Guid } from '@app/models/guid';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { CurrencyOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DistributionChannelsDxDropDownBoxModel } from '@app/models/input-Components.model';
@Component({
	selector: 'app-commission-tierbase-contract',
	templateUrl: './commission-tierbase-contract.component.html',
	styleUrls: ['./commission-tierbase-contract.component.css'],
})
export class CommissionTierbaseContractComponent implements OnInit {

	//#region Local Variable

	// Comman public object
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public isAddMode: boolean = true;
	public editCommissionGuid: string;
	public editCommissionTemplateGuid: string;
	public currentAgreementTab: string;
	public searchTimeout: any;
	public exclusionsGroupRadioButton: string;
	public btnRulesAdd: string = 'Add';
	public rulesAddOrEditIndex: string = '-1';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public loginUserRoleList: any = [];
	public erp_type: string;
	public enable_classification_for_commision: boolean = false;
	public commission_rate_on_invoice: boolean = false;
	public enable_invoice_qualification_feature: boolean = false;
	public enable_commission_on_sales_orders_feature: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	public currentMainTab: string;
	public salesPersonDataSource: any;
	public salesPersonGridBoxValue: any;
	public salespersonGridBoxColumns: any;
	public isSalesPersonDropDownBoxOpened: boolean = false;
	public ErpTypeValEnum = ErpTypeValEnum;
	public isUpdateSettlement: boolean = false;
	public isAllSettlmentClosed: boolean = false;
	public isClosedSettlementReport: boolean = false;
	public isShowLoadPanel: boolean = false;
	// Drop down list object
	public salesPplList: any = [];
	public GroupsList: any = [];
	public selectCustomerList: any = [];
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public closecontractsArray: any = [];
	public radioCommissionTypeDataList: any = [];
	public radioSalesOrderOrInvoiceList: any = [];
	public listOfContractCalendar: any[] = [];

	// Agreement tab table list object
	public growthIncentiveTabList: any = [];
	public exclusionTabList: any = [];
	public activitiesTabList: any = [];
	public ruleTabList: any[] = [];
	public amortized_payments: any[] = [];

	// Agreement tab drop down list select all checkbox object
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;
	public isEndUsersSearchTabAllSelected: boolean = false;
	public isAllCustomerSelectedView: boolean = false;
	public isAllProductSelectedView: boolean = false;
	public isAllEndUsersSelectedView: boolean = false;
	public isViewIERuleRuleRate: boolean = true;
	public isViewBounsAmountCriterion: boolean = true;
	public isViewRateRuleCriterion: boolean = true;
	public isViewBonusRateCriterion: boolean = true;
	public is_use_territory_for_commission_contract: boolean;
	// Api call request object
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private dataOutSalesPplReqSubscription: Subscription;

	// Model object
	public guid = new Guid();
	public commissionDetails = new CommissionContractModelUI();
	public commissionRequest = new CommissionContractModelRequest();

	public exclusionTabModel = new ExclusionTabModel();
	public ruleTabModel = new RuleTabModel();
	public listOfYears: any[] = [];
	public listOfSettlementPeriods: any[] = this._CommissionService.getListOfSettlementPeriods;
	public listOfCalculationOnBasis: any[] = this._CommissionService.getListOfCalculationBasis;
	public isDisabledContractType: boolean = false;
	public isDisabledCalculationOnBasisType: boolean = false;
	public listOfWeekDays: any = [
		{
			text: "Sunday",
			value: "sunday",
			number: 7,
		},
		{
			text: "Monday",
			value: "monday",
			number: 1,
		},
		{
			text: "Tuesday",
			value: "tuesday",
			number: 2,
		},
		{
			text: "Wednesday",
			value: "wednesday",
			number: 3
		},
		{
			text: "Thursday",
			value: "thursday",
			number: 4
		},
		{
			text: "Friday",
			value: "friday",
			number: 5
		},
		{
			text: "Saturday",
			value: "saturday",
			number: 6
		},

	];
	@ViewChild('contractCalendarSelectBoxRef') contractCalendarSelectBoxRef: DxSelectBoxComponent;
	@ViewChild('weekDaysSelectBoxRef') weekDaysSelectBoxRef: DxSelectBoxComponent;
	//#endregion

	public productOrGroupTabList = [];
	public customerTabList = [];
	public isViewPreviousSettlementPeriodPopup: boolean;
	public viewPreviousSettlementPeriodProps: any;

	public listOfTiersType: any[] = this._CommissionService.getListOfTiers;
	public listOfCustomerItemMatch: any[] = this._CommissionService.getListOfCustomerOrItemMatch;
	public listOfTierModes: any[] = this._CommissionService.getListOfTierModes;
	public listOfTierAccrualPeriods: any[] = this._CommissionService.getListOfTierAccrualPeriods;
	public listOfTiersInclude: any[] = this._CommissionService.getListOfTierInclude;
	public isShowTierAccrualPeriodField: boolean;
	public isShowTierAccrualPeriods: boolean;
	public enable_other_currencies: boolean = false;
	public listOfOtherCurrency: any[] = [];
	public listOfCurrency: any[] = [];
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public distributionChannelsModel: DistributionChannelsDxDropDownBoxModel = new DistributionChannelsDxDropDownBoxModel();
	public isEnableDistributionChannels: boolean = this._AppCommonSrvc.chkEnabledDistributionChannel();
	public contractNameUIClass: any;
	//#region Constructor
	constructor(private _ToastrService: ToastrService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _DataService: DataService,
		private _CommissionService: CommissionService,
		private _IxDxAlertService: IxDxAlertService,
		private _AppCommonSrvc: AppCommonSrvc,
		private ref: ChangeDetectorRef) { }

	//#endregion

	//#region Angular Life Cycle Methods
	ngOnInit() {
		this.editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		this.editCommissionTemplateGuid = this._CommissionService.getEditCommissionTemplateGuid();
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
		this.loadDistributionChannel();
		// if (this.editCommissionTemplateGuid) {
		// 	this.editCommissionGuid = this.editCommissionTemplateGuid;
		// }
		this.doInitForCommissionDetailsTab();
		this.listOfYears = this._CommissionService.getListOfYears;
		this.commissionDetails.comparison_year = this.listOfYears[0];
	}

	// tslint:disable-next-line:use-life-cycle-interface
	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	//#endregion

	//#region Commission Contract Comman Methods

	// Init data on commission tab
	doInitForCommissionDetailsTab() {
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			this.is_use_territory_for_commission_contract = this._LoginService.loginUser.account_detail.app_settings.is_use_territory_for_commission_contract || false;
			this.commission_rate_on_invoice = this._LoginService.loginUser.account_detail.commission_rate_on_invoice || false;
			this.enable_invoice_qualification_feature = this._LoginService.loginUser.account_detail.enable_invoice_qualification_feature || false;
			this.enable_commission_on_sales_orders_feature = this._LoginService.loginUser.account_detail.commission_on_sales_orders || false;
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;
		}

		// Check Edit Mode
		// let editCommissionGuid;
		// if (this.editCommissionTemplateGuid) {
		// 	editCommissionGuid = this._CommissionService.getEditCommissionTemplateGuid();
		// } else {
		const editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		//}
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}

		if (permsn.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_COMMISSION_MODIFY';
		}

		this.getLoginUserRoleList();

		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		if (this.enable_other_currencies) {
			if (this._LoginService.loginUser.account_detail.currency_lst.length > 0) {
				this.listOfCurrency = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail.currency_lst));
			}
			if (this._LoginService.loginUser.account_detail.other_currencies_lst.length > 0) {
				this.listOfOtherCurrency = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail.other_currencies_lst));
			}
			this.addPrimaryCurrency();
		}
		setTimeout(() => {
			this.getSalesPplList();
		}, 20);

		this.GroupsList = this._DataService.getGroups();

		if (this.editCommissionGuid || this.editCommissionTemplateGuid) {
			if (this.editCommissionTemplateGuid) {
				this.currentMainTab = 'commission_details';
			} else {
				//this.callUpdateSettlementPeriod(true);
				this.commissionRequest.guid = editCommissionGuid;
				this.editCommissionGuid = editCommissionGuid;
				this.editCommissionContractObject();
			}

		} else {
			this.currentMainTab = 'commission_details';
			this.addCommissionContractObject();
		}
		this.salespersonGridBoxColumns = [
			{ dataField: 'first_name', caption: 'First Name', sortOrder: "asc" },
			{ dataField: 'last_name', caption: 'Last Name' },
			{ dataField: 'role_name', caption: 'Role Name' },
		];
		this.radioCommissionTypeDataList = [
			{ value: 'standard', text: 'Standard' },
			{ value: 'growth_incentive', text: 'Growth Incentive' }
		];
		this.radioSalesOrderOrInvoiceList = [
			{ value: 'orders', text: 'Orders' },
			{ value: 'invoices', text: 'Invoices' }
		];
		this.listOfContractCalendar = this._CommissionService.listOfContractCalendar || [];
		if (this.commission_rate_on_invoice) {
			this.currentAgreementTab = 'rules';
		}
		// Disable commission inputbox
		// this.disableCommissionInputFields();

		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS) {
				this.isQBErpUser = true;
			}
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
		}

		this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision
		this.contractNameUIClass = {
			'cols12': (!this.enable_other_currencies && !this.isEnableDistributionChannels),
			'cols9': (!this.enable_other_currencies && this.isEnableDistributionChannels) || (this.enable_other_currencies && !this.isEnableDistributionChannels),
			'cols6': (this.enable_other_currencies && this.isEnableDistributionChannels),
		}
		this.onHtmlViewSideCondtion();
	}

	// check html side IF condition flag
	onHtmlViewSideCondtion() {
		// disabled condtion for contract type field
		if (this.isDisablePermission || this.commission_rate_on_invoice || this.enable_profit_on_document_header) {
			this.isDisabledContractType = true;
		}
		// disabled condtion for calculation basis type field
		if (this.isDisablePermission || this.enable_profit_on_document_header) {
			this.isDisabledCalculationOnBasisType = true;
		}
	}

	onCommissionTypeValueChanged($event) {
		this.commissionDetails.contract_type = $event.value;
		if ($event.value == 'growth_incentive') {
			this.currentAgreementTab = 'growth-incentive';
		}
		if ($event.value == 'standard') {
			this.currentAgreementTab = 'exclusions';
		}
	}

	// click main tab
	clickMainTab(tabName) {
		this.currentMainTab = tabName;
		if (this.commission_rate_on_invoice) {
			this.currentAgreementTab = 'rules';
		}
		this.checkIsSettlementClosed();
	}

	// Show/hide select box
	hostOnClick(event) {
		if ($(event.target).closest('.showIt').length === 0) {
			if ($(event.target).closest('.serchBox').length > 0) {
				return false;
			}
			$('.showIt').removeClass('showIt');
		}
	}

	// Set default value on add mode
	addCommissionContractObject() {
		this.isAddMode = true;
		this.editCommissionGuid = '';
		this.commissionRequest.guid = '';
		if (this.enable_other_currencies) {
			this.currentAgreementTab = 'rules';
			setTimeout(() => {
				this.currentAgreementTab = 'exclusions';
			});
		} else {
			this.currentAgreementTab = 'exclusions';
		}
		this.commissionDetails.settlementPeriod = 'monthly';
		this.commissionDetails.calculationOnBasis = 'grossprofit';
		this.commissionDetails.contract_type = 'standard';
		this.commissionDetails.calculate_commission_by = 'invoices';
		this.commissionDetails.tiers_type = 'single_items';
		this.commissionDetails.customer_item_match = 'exclusive';
		this.commissionDetails.tier_mode = 'absolute';
		this.setDefaultTiersInclude();
	}

	// Set default value on edit mode
	editCommissionContractObject() {
		this.editCommissionContract();
	}

	// Method to add/remove visible class
	public searchSlideDown(id: string) {
		if ($('#' + id + '.showIt').is(':visible') === false) {
			$('#' + id + '').addClass('showIt');
		} else {
			$('#' + id + '').removeClass('showIt');
		}
	}

	// Click agreement tab
	clickAgreementTab(currentAgreementTab) {
		switch (currentAgreementTab) {
			case 'exclusions':
				this.currentAgreementTab = 'exclusions';
				break;
			case 'activities':
				this.currentAgreementTab = 'activities';
				break;
			case 'growth-incentive':
				this.currentAgreementTab = 'growth-incentive';
				break;
			case 'rules':
				this.currentAgreementTab = 'rules';
				break;
		}
	}

	// Get agreement tab list data
	getAgreementTabListData() {

	}

	// Set agreement tab list data
	setAgreementTabListData() {
		if (this.commissionDetails.contract_type == 'growth_incentive') {
			this.currentAgreementTab = 'growth-incentive';
		} else {
			this.commissionDetails.contract_type = 'standard';
			this.currentAgreementTab = 'exclusions';
		}
	}

	// Disable input text after save commission
	disableCommissionInputFields() {
		if (this.editCommissionGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}
	}

	// Get current login user usertype role list
	getLoginUserRoleList() {
		this.loginUserRoleList = [];
		const userTypeList = this._LoginService.loginUser.account_detail.userType || [];
		if (userTypeList && userTypeList.length > 0) {
			const canReceiveIncentives: any = { can_receive_incentives: true };
			this.loginUserRoleList = _.map(
				_.where(userTypeList, canReceiveIncentives), function (utl) {
					return utl.user_type;
				});
		}
	}

	// Get sales people drop-list as per role
	getSalesPplList() {
		const searchParams: any = [];
		const rolesArr = this.loginUserRoleList || [];
		searchParams.push({ role: rolesArr });
		searchParams.push({ status: 'active' });
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.SUBUSERS);
		formData.append('search', JSON.stringify(searchParams));
		formData.append('is_dropdown', 'true');
		if (this.dataOutSalesPplReqSubscription) {
			this.dataOutSalesPplReqSubscription.unsubscribe();
		}
		//	this._LoaderService.show();
		this.dataOutSalesPplReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				//this._LoaderService.hide();
				if (response.flag) {
					this.salesPplList = response.data || [];
					this.salesPersonDataSource = this.salesPplList;
					if (!this.editCommissionGuid && this.salesPplList && this.salesPplList.length > 0) {
						this.commissionDetails.subuserguid = this.salesPplList[0].sub_account_id;
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				//this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Display Tier basis Name in uppercase
	public displayExprSalesPersonName(item) {
		if (!item) {
			return "";
		}
		const name = item.first_name + ' ' + item.last_name;
		return name.toString();
	}

	public onSalesPersonSelectionChanged(e) {
		if (e.name === "value") {
			this.isSalesPersonDropDownBoxOpened = false;
			this.ref.detectChanges();
		}
	}

	//#endregion

	//#region Settlement StartDate And EndDate Methods

	// Set Settlement StartDate and EndDate
	compareTwoDates() {
		// check start date and end date validate
		// if (new Date(this.commissionDetails.endDate) < new Date(this.commissionDetails.startDate)) {
		// 	this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
		// 	});
		// }

		this.setSettlementStartDate();
	}

	// Set Settlement Date
	setSettlementStartDate() {
		setTimeout(() => {
			if (!this.commissionDetails.startSettlementPeriodOn) {
				this.commissionDetails.startSettlementPeriodOn = "1";
			}
		}, 50);
		// check start date and end date validate
		if (!this.commissionDetails.endDate || !this.commissionDetails.startDate) {
			return;
		}
		if (new Date(this.commissionDetails.endDate) < new Date(this.commissionDetails.startDate)) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
			return;
		} else {
			if (this.commissionDetails.startDate) {
				this.calculateSettlementPeriod(this.commissionDetails);
			}
		}
	}

	// Calculate Settlement Period as per start date
	calculateSettlementPeriod(c, isClose?: boolean) {
		this.callViewSettlementPeriod(this.commissionRequest.guid, (response, data) => {
			this.closecontractsArray = data;
			if (c.startDate) {
				c.startDate = c.startDate ? moment(c.startDate).format('YYYY-MM-DD') : '';
				c.endDate = c.endDate ? moment(c.endDate).format('YYYY-MM-DD') : '';
				c.settlementPeriodStart = c.settlementPeriodStart ? moment(c.settlementPeriodStart).format('YYYY-MM-DD') : '';
				c.settlementPeriodEnd = c.settlementPeriodEnd ? moment(c.settlementPeriodEnd).format('YYYY-MM-DD') : '';
				let e, s, d;
				let ssPeriodDay: any = 1; //start settlement period on
				let cc = this.commissionDetails.contractCalendar; // contract calendar [Fiscal Year,Calendar Year,Contract Year]
				let f: any = 1;// Fiscal Year month as of application configuration
				ssPeriodDay = this.commissionDetails.startSettlementPeriodOn ? parseInt(this.commissionDetails.startSettlementPeriodOn.toString()) : 1;

				cc = this.commissionDetails.contractCalendar;
				if (cc === 'fiscal_year') {
					f = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
				}
				if (!this.closecontractsArray.length) {  // no previous closed settlement periods
					c.settlementPeriodStart = c.startDate;
					s = c.startDate.split('-');
				} else {
					s = moment(this.closecontractsArray[this.closecontractsArray.length - 1].settlementPeriodEnd).format('YYYY-MM-DD').split('-');
					d = new Date(s[0], s[1] - 1, s[2]);
					const dDate = moment(new Date(d)).format('YYYY-MM-DD');
					const cDate = moment(new Date(c.startDate)).format('YYYY-MM-DD');
					if (dDate < cDate) {
						c.settlementPeriodStart = c.startDate;
						s = c.settlementPeriodStart.split('-');
					} else {
						d.setDate(d.getDate() + 1);
						c.settlementPeriodStart = moment(d).format('YYYY-MM-DD');
						s = c.settlementPeriodStart.split('-');
						// check all settlment closed
						const endDate = moment(c.endDate, "YYYY-MM-DD");
						const settlementPeriodStart = moment(c.settlementPeriodStart, "YYYY-MM-DD");
						const diffDays = settlementPeriodStart.diff(endDate, 'days');
						if (diffDays > 0) {
							this.isAllSettlmentClosed = true;
							//return false;
						} else {
							this.isAllSettlmentClosed = false;
						}
						// End check all settlment closed
					}
				}
				if (cc === 'contract_year') {
					f = parseInt(s[1].toString()) || 1;
				}
				//#region New Settlement period calculate IX-1392
				const sm = parseInt(s[1].toString()); // start date month
				if (c.settlementPeriod === 'monthly') {
					e = new Date(s[0], s[1], ssPeriodDay);
				} else if (c.settlementPeriod === 'quarterly') {
					d = sm - ((f - 1) % 3); // adjust for fiscal year start
					if (d < 0) {
						d = 0;
						//d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 3) + (d % 3 ? 3 : 0);   // end of current quarter
					d = d + ((f - 1) % 3);    // re-adjust for fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === 'halfYearly') {
					d = sm - ((f - 1) % 6); // adjust for fiscal year start
					if (d < 0) {
						d = 0;
						//	d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 6) + (d % 6 ? 6 : 0);   // end of current quarter
					d = d + ((f - 1) % 6);    // re-adjust for fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === 'yearly') {
					// tslint:disable-next-line: radix
					if (cc === 'fiscal_year') {
						d = sm - ((f - 1) % 12); // adjust for fiscal year start
						if (d < 0) {
							d = 0;
							// 	d = d + 12;   // months before fiscal year start are as if at end of year
						}
						d = d - (d % 12) + (d % 12 ? 12 : 0);   // end of current quarter
						d = d + ((f - 1) % 12);    // re-adjust for fiscal year start
						e = new Date(s[0], d, ssPeriodDay);
					} else {
						e = new Date(parseInt(s[0]) + 1, f - 1, ssPeriodDay);
					}
				} else if (c.settlementPeriod === 'weekly') {
					if (!this.commissionDetails.startSettlementPeriodWeekOfDayOn) {
						this.commissionDetails.startSettlementPeriodWeekOfDayOn = this.listOfWeekDays[0].value;
					}
					let selectDayOfMonthText: any = moment(this.commissionDetails.settlementPeriodStart).format('dddd');
					const indexCD = this.listOfWeekDays.findIndex(item => item.text == selectDayOfMonthText);
					const indexOfWeekDay = this.listOfWeekDays.findIndex(item => item.value == this.commissionDetails.startSettlementPeriodWeekOfDayOn);
					const diffDay = this.listOfWeekDays[indexOfWeekDay].number - this.listOfWeekDays[indexCD].number;
					let addDay = 0;
					if (diffDay <= 0) {
						addDay = 7 + diffDay;
					} else {
						addDay = diffDay;
					}
					const date = moment(this.commissionDetails.settlementPeriodStart, "YYYY-MM-DD").add((addDay - 1), 'days');
					e = new Date(date.year(), date.month(), date.date());
				} else if (c.settlementPeriod === 'biweekly') {
					if (!this.commissionDetails.startSettlementPeriodWeekOfDayOn) {
						this.commissionDetails.startSettlementPeriodWeekOfDayOn = this.listOfWeekDays[0].value;
					}
					let selectDayOfMonthText: any = moment(this.commissionDetails.settlementPeriodStart).format('dddd');
					const indexCD = this.listOfWeekDays.findIndex(item => item.text == selectDayOfMonthText);
					const indexOfWeekDay = this.listOfWeekDays.findIndex(item => item.value == this.commissionDetails.startSettlementPeriodWeekOfDayOn);
					const diffDay = this.listOfWeekDays[indexOfWeekDay].number - this.listOfWeekDays[indexCD].number;
					let addDay = 0;
					if (diffDay <= 0) {
						addDay = 14 + diffDay;
					} else {
						addDay = diffDay + 7;
					}
					const date = moment(this.commissionDetails.settlementPeriodStart, "YYYY-MM-DD").add((addDay - 1), 'days');
					e = new Date(date.year(), date.month(), date.date());
				}
				else if (c.settlementPeriod === 'custom') {
					c.settlementPeriodEnd = c.endDate;
				}
				//#endregion
				if (c.settlementPeriod !== 'weekly' && c.settlementPeriod !== 'biweekly') {
					e.setDate(e.getDate() - 1); // last day of the prev month
				}
				const endDate = moment(c.endDate, "YYYY-MM-DD");
				const settlementPeriodEnd = moment(e, "YYYY-MM-DD");
				const diffDays = settlementPeriodEnd.diff(endDate, 'days');
				if (diffDays >= 0) {
					c.settlementPeriodEnd = c.endDate;
				} else {
					c.settlementPeriodEnd = moment(e).format('YYYY-MM-DD');
				}

				if (isClose) {
					// c.contractCode = this.guid.newGuid();
					c.startDate = c.startDate ? moment(c.startDate).format('YYYY-MM-DD') : '';
					c.endDate = c.endDate ? moment(c.endDate).format('YYYY-MM-DD') : '';
					c.settlementPeriodStart = c.settlementPeriodStart ? moment(c.settlementPeriodStart).format('YYYY-MM-DD') : '';
					c.settlementPeriodEnd = c.settlementPeriodEnd ? moment(c.settlementPeriodEnd).format('YYYY-MM-DD') : '';

					const o = new Object;
					// tslint:disable-next-line:no-shadowed-variable
					for (const e in c) {
						o[e] = c[e];
					}
					o['customer_items_groups'] = this.exclusionTabList;
					o['growth_incentive'] = this.growthIncentiveTabList;
					o['activities'] = this.activitiesTabList;
					o['rules'] = this.ruleTabList;
					o['amortized_payments'] = this.amortized_payments;
					o['isSettlementPeriodClosed'] = true;
					o['allproducts'] = this.isAllProductSelectedView;
					o['allclients'] = this.isAllCustomerSelectedView;
					o['settelmentCalculatedByBackend'] = '';
					o['apInvoiceCreatedInERP'] = '';
					o['errorMessage'] = '';
					o['settlementValue'] = 0;
					o['isAPInvoicePaid'] = '';
					o['apInvoiceNumber'] = '';
					o['startDate'] = moment(o['startDate']).format('YYYY-MM-DD') || '';
					o['endDate'] = moment(o['endDate']).format('YYYY-MM-DD') || '';
					o['invoice_qualification_start_date'] = o['invoice_qualification_start_date'] ? moment(o['invoice_qualification_start_date']).format('YYYY-MM-DD') || '' : '';
					o['invoice_qualification_end_date'] = o['invoice_qualification_end_date'] ? moment(o['invoice_qualification_end_date']).format('YYYY-MM-DD') || '' : '';
					o['settlementPeriodStart'] = moment(o['settlementPeriodStart']).format('YYYY-MM-DD');
					o['settlementPeriodEnd'] = moment(o['settlementPeriodEnd']).format('YYYY-MM-DD');
					o['baseContractCode'] = c.guid;
					o['guid'] = this.guid.newGuid();

					// this.closecontractsArray.push(o);
					const contractArr = [];
					contractArr.push(o);
					this.callSaveContractCloseSettlement(contractArr);
					this.checkIsSettlementClosed();
				} else {
					if (this.editCommissionGuid) {
						this.isUpdateSettlement = true;
						this.saveCommissionContract();
						//this.callUpdateSettlementPeriod();
					}
				}
			} else {
				this._LoaderService.hide();
			}
		});
	}

	//#endregion

	//#region close current settlement period

	closeCurrentSettlementPeriod(commissionContractDetails) {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Close Settlement',
			message: 'Are you sure you want to close current settlement period? <br/>You will not be able to undo the operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const IsOpenMultiSettlmentPopUp = this._CommissionService.getIsOpenMultiSettlmentPopUp();
				if (IsOpenMultiSettlmentPopUp) {
					this._ToastrService.info('The previous process is still in progress. Please try after that complete.', 'Info', { closeButton: true, tapToDismiss: true });
					return;
				}
				this.isUpdateSettlement = false;
				if (this.editCommissionGuid !== undefined) {
					// const d = new Date();
					const serverDateSplit = this._LoginService.loginUser.server_date.split('-');
					const d = new Date(+serverDateSplit[0], +serverDateSplit[1] - 1, +serverDateSplit[2]);
					if (!commissionContractDetails.startDate) {
						this._IxDxAlertService.doShowAlert("Cannot close Settlement Period - please add a Start Date to contract").then(result => {
						});
						return;
					}
					let end = commissionContractDetails.settlementPeriodEnd.split('-');
					end = new Date(end[0], end[1] - 1, end[2]);
					if (d <= end) {
						this._IxDxAlertService.doShowAlert("Cannot close Settlement Period until after its end date").then(result => {
						});
						return;
					}
					const isClose = true;
					this.calculateSettlementPeriod(commissionContractDetails, isClose);
				}
			}
		});
	}

	//#endregion

	saveCommissionContract(isOtherSave?: boolean) {
		if (this.salesPersonGridBoxValue && this.salesPersonGridBoxValue[0] && !this.commissionDetails.subuserguid) {
			this.isUpdateSettlement = false;
			this.commissionDetails.subuserguid = this.salesPersonGridBoxValue[0];
		}
		if (!this.commissionDetails.contractName) {
			this._ToastrService.info('Contract Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (this.enable_other_currencies) {
			if (!this.commissionDetails.currency_code) {
				this._ToastrService.info('Currency is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}
		if (!this.commissionDetails.subuserguid || !this.salesPersonGridBoxValue) {
			this._ToastrService.info('Sales person is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		if (this.isEnableDistributionChannels) {
			if (this.distributionChannelsModel.arrSelectedDistributionChannelsList.length === 0) {
				this._ToastrService.info('Distribution Channel is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
		}

		if (this.commissionRequest.guid === undefined || this.commissionRequest.guid === '' || this.commissionRequest.guid === null) {
			this.commissionRequest.guid = this.guid.newGuid();
		}
		this.commissionDetails.subuserguid = this.salesPersonGridBoxValue[0];
		// Get sub user name as per id
		if (this.commissionDetails.subuserguid) {
			const emp = this.salesPplList.filter(item => (item.sub_account_id).toString() === (this.commissionDetails.subuserguid).toString())[0];
			this.commissionDetails.subusername = emp !== undefined ? emp.first_name + ' ' + emp.last_name || '' : '';
		}

		this.commissionRequest.contractName = this.commissionDetails.contractName;
		this.commissionRequest.subuserguid = this.commissionDetails.subuserguid;
		this.commissionRequest.subusername = this.commissionDetails.subusername;
		this.commissionRequest.contract_type = this.commissionDetails.contract_type || '';
		this.commissionRequest.comparison_year = this.commissionDetails.comparison_year || '';
		this.commissionRequest.startDate = this.commissionDetails.startDate ? moment(this.commissionDetails.startDate).format('YYYY-MM-DD') : '';
		this.commissionRequest.endDate = this.commissionDetails.endDate ? moment(this.commissionDetails.endDate).format('YYYY-MM-DD') : '';
		this.commissionRequest.invoice_qualification_start_date = this.commissionDetails.invoice_qualification_start_date ? moment(this.commissionDetails.invoice_qualification_start_date).format('YYYY-MM-DD') : '';
		this.commissionRequest.invoice_qualification_end_date = this.commissionDetails.invoice_qualification_end_date ? moment(this.commissionDetails.invoice_qualification_end_date).format('YYYY-MM-DD') : '';
		this.commissionRequest.settlementPeriodStart = this.commissionDetails.settlementPeriodStart ? moment(this.commissionDetails.settlementPeriodStart).format('YYYY-MM-DD') : '';
		this.commissionRequest.settlementPeriodEnd = this.commissionDetails.settlementPeriodEnd ? moment(this.commissionDetails.settlementPeriodEnd).format('YYYY-MM-DD') : '';
		this.commissionRequest.settlementPeriod = this.commissionDetails.settlementPeriod;
		this.commissionRequest.startSettlementPeriodOn = this.commissionDetails.startSettlementPeriodOn;
		this.commissionRequest.startSettlementPeriodWeekOfDayOn = this.commissionDetails.startSettlementPeriodWeekOfDayOn;
		this.commissionRequest.contractCalendar = this.commissionDetails.contractCalendar;
		this.commissionRequest.calculationOnBasis = this.commissionDetails.calculationOnBasis;
		this.commissionRequest.agreementBy = this.commissionDetails.agreementBy;
		this.commissionRequest.customer_items_groups = this.exclusionTabList;
		this.commissionRequest.growth_incentive = this.growthIncentiveTabList;
		this.commissionRequest.activities = this.activitiesTabList;
		this.commissionRequest.rules = this.ruleTabList;
		this.commissionRequest.amortized_payments = this.amortized_payments;
		this.commissionRequest.is_active = true;
		this.commissionRequest.calculate_commission_by = this.commissionDetails.calculate_commission_by;
		this.commissionRequest.tiers_type = this.commissionDetails.tiers_type || 'single_items';
		this.commissionRequest.customer_item_match = this.commissionDetails.customer_item_match || 'exclusive';
		this.commissionRequest.tier_mode = this.commissionDetails.tier_mode || 'absolute';
		this.commissionRequest.tier_accrual_period = this.commissionDetails.tier_accrual_period;
		this.commissionRequest.tiers_include = this.commissionDetails.tiers_include || 'only_items_in_tier';
		if (this.enable_other_currencies) {
			this.commissionRequest.currency_code = this.commissionDetails.currency_code;
		}
		if (this.isEnableDistributionChannels) {
			this.commissionRequest.distribution_channels = this.distributionChannelsModel.arrSelectedDistributionChannelsList.length > 0 ? this.distributionChannelsModel.arrSelectedDistributionChannelsList[0] : '';
		}
		// this.doShowHideTierAccrualPeriodsDropDown();

		const arryCommissionContracts = [];
		arryCommissionContracts.push(this.commissionRequest);
		this.callSaveCommissionContract(arryCommissionContracts, isOtherSave);
	}

	ViewPreviousSettlementPeriodDialogClick() {
		if (this.editCommissionGuid !== undefined) {
			this.callViewSettlementPeriod(this.editCommissionGuid, (response, data) => {
				const ViewPreviousSettlementPeriodList = data;
				if (ViewPreviousSettlementPeriodList.length !== 0) {

					this.viewPreviousSettlementPeriodProps = {
						contractName: this.commissionDetails.contractName, salesPersonName: this.commissionDetails.subusername, ViewPreviousSettlementPeriodList: ViewPreviousSettlementPeriodList
					};

					this.doOpenViewPreviousSettlementPeriod();
				} else {
					this._ToastrService.info('Settled contracts not found.', 'Info', { closeButton: true, tapToDismiss: true });
					return;
				}
			});
		}
	}

	private doOpenViewPreviousSettlementPeriod() {
		this.isViewPreviousSettlementPeriodPopup = false;
		setTimeout(() => {
			this.isViewPreviousSettlementPeriodPopup = true;
		}, 150);
	}

	public doCloseViewPreviousSettlementPeriod() {
		this.isViewPreviousSettlementPeriodPopup = false;
	}

	//#region Call API Request

	callSaveCommissionContract(arryCommissionContracts, isOtherSave?: boolean) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('commissioncontracts', JSON.stringify(arryCommissionContracts));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					if (!isOtherSave && !this.isUpdateSettlement) {
						this._ToastrService.success(((!this.editCommissionGuid) ? ' Added' : ' Updated') + ' Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
					this.isUpdateSettlement = false;
					this.commissionRequest.ixcode = response.data.commissioncontracts[0].ixcode;
					this.commissionRequest.guid = response.data.commissioncontracts[0].guid;
					this.commissionDetails.guid = response.data.commissioncontracts[0].guid;
					this.editCommissionGuid = this.commissionRequest.guid;
					this.isAddMode = false;
					this._CommissionService.setEditCommissionGuid(this.editCommissionGuid);
					this.callUpdateSettlementPeriod();
					// Disable Commission inputbox
					//this.disableCommissionInputFields();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	async callSaveContractCloseSettlement(arryContracts) {
		// tslint:disable-next-line: max-line-length
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('commissioncontracts', JSON.stringify(arryContracts));
		formData.append('contracttype', 'commissioncontracts');
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					// this.currentcontracts.push(arryContracts[0]);
					const commissioncontracts = response.data['commissioncontracts'] || [];
					if (commissioncontracts.length > 0) {
						const indexCon = commissioncontracts.findIndex(i => i.guid === this.editCommissionGuid);
						if (indexCon !== -1) {
							this.amortized_payments = commissioncontracts[indexCon]['amortized_payments'] || [];
						}
					}
					this.calculateSettlementPeriod(this.commissionDetails, false);
					this._CommissionService.setAgreementMainTab('commission_agreements');
					setTimeout(() => {
						this.checkIsSettlementClosed();
					}, 50);
					// this.commissionDetails.current_settlement_invoice_total = '0';
					// this.commissionDetails.current_settlement_commission_total = '0';
					// this.closecontractsArray = [];
					this._ToastrService.success('Close and save settlement successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					// this._loaderService.hide();
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});

	}

	editCommissionContract() {
		let searchParams: any = [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		if (this.editCommissionTemplateGuid) {
			searchParams.push({ 'guid': this.editCommissionTemplateGuid });
			formData.append('entity', ServerEntity.CCTEMPLATES);
		} else {
			searchParams.push({ 'guid': this.editCommissionGuid });
			formData.append('entity', ServerEntity.COMMISSIONCONTRACTS);
		}
		formData.append('search', JSON.stringify(searchParams));
		// this._LoaderService.show();
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response.flag) {
					const editCommissionContractDetails = response.data[0];
					this.isAddMode = false;
					if (!this.editCommissionTemplateGuid) {
						this.editCommissionGuid = editCommissionContractDetails.guid;
						this.commissionDetails.guid = editCommissionContractDetails.guid;
					}
					this.commissionDetails.contractName = editCommissionContractDetails.contractName || '';
					this.commissionDetails.subuserguid = editCommissionContractDetails.subuserguid || '';
					this.commissionDetails.subusername = editCommissionContractDetails.subusername || '';
					this.commissionDetails.contract_type = editCommissionContractDetails.contract_type || '';
					this.commissionDetails.comparison_year = editCommissionContractDetails.comparison_year || '';
					if (!this.editCommissionTemplateGuid) {
						this.commissionDetails.startDate = editCommissionContractDetails['startDate'] && editCommissionContractDetails['startDate'] !== 'Invalid date' ? moment(editCommissionContractDetails.startDate).format('YYYY-MM-DD') || '' : '';
						this.commissionDetails.endDate = editCommissionContractDetails['endDate'] && editCommissionContractDetails['endDate'] !== 'Invalid date' ? moment(editCommissionContractDetails.endDate).format('YYYY-MM-DD') || '' : '';
					}
					this.commissionDetails.invoice_qualification_start_date = editCommissionContractDetails['invoice_qualification_start_date'] && editCommissionContractDetails['invoice_qualification_start_date'] !== 'Invalid date' ? moment(editCommissionContractDetails.invoice_qualification_start_date).format('YYYY-MM-DD') || '' : '';
					this.commissionDetails.invoice_qualification_end_date = editCommissionContractDetails['invoice_qualification_end_date'] && editCommissionContractDetails['invoice_qualification_end_date'] !== 'Invalid date' ? moment(editCommissionContractDetails.invoice_qualification_end_date).format('YYYY-MM-DD') || '' : '';
					this.commissionDetails.settlementPeriodStart = editCommissionContractDetails.settlementPeriodStart ? moment(editCommissionContractDetails.settlementPeriodStart).format('YYYY-MM-DD') || '' : '';
					this.commissionDetails.settlementPeriodEnd = editCommissionContractDetails.settlementPeriodEnd ? moment(editCommissionContractDetails.settlementPeriodEnd).format('YYYY-MM-DD') || '' : '';
					this.commissionDetails.settlementPeriod = editCommissionContractDetails.settlementPeriod;
					this.commissionDetails.startSettlementPeriodOn = editCommissionContractDetails.startSettlementPeriodOn;
					this.commissionDetails.startSettlementPeriodWeekOfDayOn = editCommissionContractDetails.startSettlementPeriodWeekOfDayOn;
					this.commissionDetails.contractCalendar = editCommissionContractDetails.contractCalendar;
					this.commissionDetails.calculationOnBasis = editCommissionContractDetails.calculationOnBasis;
					this.commissionDetails.tiers_type = editCommissionContractDetails.tiers_type || 'single_items';
					this.commissionDetails.customer_item_match = editCommissionContractDetails.customer_item_match || 'exclusive';
					this.commissionDetails.tier_mode = editCommissionContractDetails.tier_mode || 'absolute';
					this.commissionDetails.tier_accrual_period = editCommissionContractDetails.tier_accrual_period;
					this.commissionDetails.tiers_include = editCommissionContractDetails.tiers_include || 'only_items_in_tier';
					if (this.enable_other_currencies) {
						this.commissionDetails.currency_code = editCommissionContractDetails.currency_code;
						this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
					}
					if (this.isEnableDistributionChannels) {
						this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
						if (editCommissionContractDetails.hasOwnProperty('distribution_channels')) {
							this.distributionChannelsModel.arrSelectedDistributionChannelsList.push(editCommissionContractDetails.distribution_channels);
						}
					}
					this.doShowHideTierAccrualPeriodsDropDown();
					this.commissionDetails.current_settlement_invoice_total = editCommissionContractDetails.current_settlement_invoice_total;
					this.commissionDetails.current_settlement_commission_total = editCommissionContractDetails.current_settlement_commission_total;
					this.commissionDetails.is_active = editCommissionContractDetails.is_active;
					this.commissionDetails.customer_items_groups = [];
					this.commissionDetails.growth_incentive = [];
					this.commissionDetails.rules = [];
					this.commissionDetails.amortized_payments = [];
					if (editCommissionContractDetails.customer_items_groups && editCommissionContractDetails.customer_items_groups.length > 0) {
						editCommissionContractDetails.customer_items_groups.forEach((i, index) => {
							i['rownum'] = index + 1;
						});
					}
					if (editCommissionContractDetails.growth_incentive && editCommissionContractDetails.growth_incentive.length > 0) {
						editCommissionContractDetails.growth_incentive.forEach((i, index) => {
							i['rownum'] = index + 1;
						});
					}
					this.exclusionTabList = editCommissionContractDetails.customer_items_groups || [];
					this.growthIncentiveTabList = editCommissionContractDetails.growth_incentive || [];
					this.activitiesTabList = editCommissionContractDetails.activities || [];
					this.ruleTabList = editCommissionContractDetails.rules;
					this.amortized_payments = editCommissionContractDetails['amortized_payments'] || [];
					this.commissionDetails.calculate_commission_by = editCommissionContractDetails.calculate_commission_by || 'invoices';
					this.salesPersonGridBoxValue = [this.commissionDetails.subuserguid];
					if (editCommissionContractDetails.allclients) {
						this.isAllCustomerSelectedView = true;
					}
					if (editCommissionContractDetails.allproducts) {
						this.isAllProductSelectedView = true;
					}

					// Note : https://incentx.atlassian.net/browse/IX-2834 comment for datain rquest call on load 

					// if (this.commissionDetails.startDate !== '' && this.commissionDetails.endDate !== '') {
					// 	this.calculateSettlementPeriod(this.commissionDetails);
					// }
					if (this.currentMainTab !== 'commission_details') {
						this.currentMainTab = 'settlement_period';
					}
					this.setAgreementTabListData();

				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				// this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	callViewSettlementPeriod(contractCode, callbackMethod: any) {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		// tslint:disable-next-line: max-line-length
		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('baseContractCode', contractCode);
		formData.append('method', ServerMethods.GET_CLOSE_SETTLEMENTS);
		formData.append('contracttype', 'commissioncontracts');
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this._LoaderService.hide();
					callbackMethod(true, response.data);
				} else {
					this._LoaderService.hide();
					callbackMethod(false, []);
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				callbackMethod(false, []);
			}
		});
	}

	public updateSettlementPeriodClick() {
		this.saveCommissionContract();
	}

	callUpdateSettlementPeriod() {
		const arryContracts = [];
		// Get sub user name as per id
		if (this.commissionDetails.subuserguid && !this.commissionDetails.subusername) {
			// const emp = this.salesPplList.filter(item => (item.code).toString() === (this.commissionDetails.subuserguid).toString())[0];
			// this.commissionDetails.subusername = emp !== undefined ? emp.firstname || '' : '';
			const emp = this.salesPplList.filter(item => (item.sub_account_id).toString() === (this.commissionDetails.subuserguid).toString())[0];
			this.commissionDetails.subusername = emp !== undefined ? emp.first_name + ' ' + emp.last_name || '' : '';
		}
		this.commissionRequest.contractName = this.commissionDetails.contractName || '';
		this.commissionRequest.contract_type = this.commissionDetails.contract_type || '';
		this.commissionRequest.comparison_year = this.commissionDetails.comparison_year || '';
		this.commissionRequest.subuserguid = this.commissionDetails.subuserguid;
		this.commissionRequest.subusername = this.commissionDetails.subusername;
		this.commissionRequest.startDate = moment(this.commissionDetails.startDate).format('YYYY-MM-DD');
		this.commissionRequest.endDate = moment(this.commissionDetails.endDate).format('YYYY-MM-DD');
		this.commissionRequest.invoice_qualification_start_date = moment(this.commissionDetails.invoice_qualification_start_date).format('YYYY-MM-DD');
		this.commissionRequest.invoice_qualification_end_date = moment(this.commissionDetails.invoice_qualification_end_date).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodStart = moment(this.commissionDetails.settlementPeriodStart).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodEnd = moment(this.commissionDetails.settlementPeriodEnd).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriod = this.commissionDetails.settlementPeriod;
		this.commissionRequest.startSettlementPeriodOn = this.commissionDetails.startSettlementPeriodOn;
		this.commissionRequest.startSettlementPeriodWeekOfDayOn = this.commissionDetails.startSettlementPeriodWeekOfDayOn;
		this.commissionRequest.contractCalendar = this.commissionDetails.contractCalendar;
		this.commissionRequest.calculationOnBasis = this.commissionDetails.calculationOnBasis;
		this.commissionRequest.agreementBy = this.commissionDetails.agreementBy;
		this.commissionRequest.customer_items_groups = this.exclusionTabList;
		this.commissionRequest.growth_incentive = this.growthIncentiveTabList;
		this.commissionRequest.activities = this.activitiesTabList;
		this.commissionRequest.rules = this.ruleTabList;
		this.commissionRequest.amortized_payments = this.amortized_payments;
		this.commissionRequest.is_active = this.commissionDetails.is_active;
		this.commissionRequest.calculate_commission_by = this.commissionDetails.calculate_commission_by;
		if (this.enable_other_currencies) {
			this.commissionRequest.currency_code = this.commissionDetails.currency_code;
		}
		if (this.commissionRequest) {
			arryContracts.push(this.commissionRequest);
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('contractguid', this.commissionDetails.guid);
			formData.append('contracttype', 'commissioncontracts');
			formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
			if (this.dataOutReqSubscription) {
				this.dataOutReqSubscription.unsubscribe();
			}
			// this._LoaderService.show();
			this.isShowLoadPanel = true;
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
				next: (response) => {
					// this._LoaderService.hide();
					this.isShowLoadPanel = false;
					if (this.currentMainTab !== 'commission_details') {
						this.currentMainTab = 'settlement_period';
					}
					if (response.flag) {
						this.commissionDetails.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
						this.commissionDetails.current_settlement_commission_total = response.data.current_settlement_commission_total;
						// this._ToastrService.success(response.message, 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}

				}, error: (error) => {
					// this._LoaderService.hide();
					this.isShowLoadPanel = false;
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
			});
		}
	}

	//#endregion
	// call from child exlusion page

	saveCommissionExclusionData(event) {
		this.saveCommissionContract();
	}

	saveGrowthIncentiveData(event) {
		this.saveCommissionContract();
	}

	saveCommissionActivitiesData(event) {
		this.saveCommissionContract();
	}

	saveCommissionRulesData(event) {
		this.saveCommissionContract();
	}

	public checkIsSettlementClosed() {
		if (this.commissionDetails.startDate !== this.commissionDetails.settlementPeriodStart) {
			this.isClosedSettlementReport = true;
		}
	}

	// #region for Tier Mode
	public doChangeTierMode(event: any) {
		if (event.value) {
			this.doShowHideTierAccrualPeriodsDropDown();
			this.setDefaultTierAccrualPeriod();
		}
	}

	private doShowHideTierAccrualPeriodsDropDown() {
		this.isShowTierAccrualPeriodField = false;
		if (this.commissionDetails.tier_mode === 'marginal') {
			this.isShowTierAccrualPeriodField = true;
			if (this.commissionDetails.settlementPeriod === 'custom') {
				this.isShowTierAccrualPeriodField = false;
			}
		}
		this.doLoadListOfTierAccrualPeriodsOptions();
	}

	private doLoadListOfTierAccrualPeriodsOptions() {
		if (this.commissionDetails.tier_mode === 'marginal') {
			let removedOptions = [];
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.BIWEEKLY) {
				removedOptions = [];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.WEEKLY) {
				removedOptions = [];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY, SettlementPeriodsValEnum.MONTHLY];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY, SettlementPeriodsValEnum.MONTHLY, SettlementPeriodsValEnum.QUARTERLY];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY, SettlementPeriodsValEnum.MONTHLY, SettlementPeriodsValEnum.QUARTERLY, SettlementPeriodsValEnum.HALFYEARLY];
			}

			this.listOfTierAccrualPeriods.forEach(period => {
				const optionIndex = removedOptions.findIndex(opt => opt === period.value);
				if (optionIndex !== -1) {
					period.visible = false;
				} else {
					period.visible = true;
				}
			});
		}
	}

	private setDefaultTiersInclude() {
		if (this.isAddMode) {
			if (this.enable_profit_on_document_header) {
				this.commissionDetails.tiers_include = 'all_items_in_contract';
			} else {
				this.commissionDetails.tiers_include = 'only_items_in_tier';
			}
		}
	}

	private setDefaultTierAccrualPeriod() {
		if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.WEEKLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.WEEKLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.BIWEEKLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.BIWEEKLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.MONTHLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.QUARTERLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.HALFYEARLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.YEARLY;
		} else {
			this.commissionDetails.tier_accrual_period = '';
		}
	}

	public doChangeTierAccrualPeriod(event: any) {
		this.doShowHideTierAccrualPeriodsDropDown();
		if (this.commissionDetails.tier_mode === 'marginal') {
			if (this.isAddMode) {
				this.setDefaultTierAccrualPeriod();
			} else {
				if (!this.isAddMode && event && event.previousValue !== this.commissionDetails.settlementPeriod) {
					this.setDefaultTierAccrualPeriod();
				}
			}
		}
	}

	public doTiersTypeChanged(event: any) {
		const newTiersType = event.value;
		const currentTiersType = this.commissionDetails.tiers_type;
		// const newRebateFor = event.target.value;
		if (currentTiersType === newTiersType) {
			return false;
		}
		let isShowConfirmationPopup = false;
		let confirmMessage = '';

		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			confirmMessage = 'Your existing Item(s) / Group(s) list and Rules will replaced.';
			isShowConfirmationPopup = true;
		}

		if (isShowConfirmationPopup) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
				showTitle: false,
				subtitle: 'Change Tiers Type?',
				message: 'Are you sure you want to continue? ' + confirmMessage,
			};
			this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
				this.commissionDetails.tiers_type = '';
				if (result) {
					setTimeout(() => {
						this.exclusionTabList = [];
					}, 50);
					this.commissionDetails.tiers_type = newTiersType;
				} else {
					setTimeout(() => {
						this.commissionDetails.tiers_type = currentTiersType;
					}, 20);
				}
			});
		} else {
			this.commissionDetails.tiers_type = newTiersType;
		}

	}
	//#endregion

	//#region Other Currency

	public addPrimaryCurrency() {
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('currency_code')) {
			let currency_code = this._LoginService.loginUser.account_detail.currency_code
			if (currency_code) {
				if (this.listOfCurrency && this.listOfCurrency.length > 0) {
					const currencyIndex = this.listOfCurrency.findIndex(currency => currency.currency_code === currency_code);
					if (currencyIndex !== -1) {
						if (this._LoginService.loginUser.account_detail.hasOwnProperty('erp_currency_name')) {
							this.listOfCurrency[currencyIndex]['erp_currency_name'] = this._LoginService.loginUser.account_detail.erp_currency_name;
						}
						this.listOfOtherCurrency.push(this.listOfCurrency[currencyIndex]);
					}
				}
			}
		}
	}

	public currencyLookUpContentReady(e) {
		e.component.content().classList.add("ix-currencyContent");
	}
	public displayExprForCurrency(currency: any) {
		return currency ? currency.currency_name + ' - ' + currency.currency_code + ' - ' + currency.currency_symbol : '';
		// return currency ? currency : '';
	}

	public doSelectionChangedForCurrency(e) {
		this.commissionDetails.currency_code = e.value;
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
		if (this.editCommissionGuid) {
			this.saveCommissionContract();
			this.currentAgreementTab = 'rules';
			setTimeout(() => {
				this.currentAgreementTab = 'exclusions';
			});
		}
	}

	//#endregion
	// Load Distribution channel
	public loadDistributionChannel() {
		if (this.isEnableDistributionChannels) {
			this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
			this.distributionChannelsModel.callFromView = DistributionChannelsCallFromViewEnum.COMMISSION_TIERBASE_CONTRACT;
			this.distributionChannelsModel.showSelectionControls = false;
			this.distributionChannelsModel.applyValueMode = 'instantly';
			this.distributionChannelsModel.selectionMode = 'single';
		}
	}
}

