<div class="row equalHight">
	<div class="cols12">
		<div class="chartAreaBox quarterlyReportBox cf padL0 padR0">
			<div class="chartArea">
				<div class="row mrgB20">
					<div class="cols12">
						<div class="cf"></div>
						<div class="dx-field txtcenter">
							<h2 class="chartTitlesmall">{{currentMonthName}} Leader Board</h2>
						</div>
					</div>
				</div>

				<div class="posRelative">

					<dx-chart #saleLeaderBoardChart [rotated]="true" [dataSource]="barDataSource" [barGroupWidth]="20">
						<dxo-size [height]="345"> </dxo-size>
						<dxo-argument-axis [placeholderSize]="60" [visible]="true">
							<dxo-label [visible]="true"></dxo-label>
							<dxo-tick [visible]="false"></dxo-tick>
						</dxo-argument-axis>
						<dxi-value-axis [valueMarginsEnabled]="false" [color]="'#21b3c6'" [axisDivisionFactor]="80"
							[placeholderSize]="30" [visible]="false">
							<dxo-label [indentFromAxis]="5">
								<dxo-format type="currency millions"></dxo-format>
								<dxo-font [opacity]="0.6"></dxo-font>
							</dxo-label>
							<dxo-tick [visible]="false"></dxo-tick>
						</dxi-value-axis>
						<dxo-common-series-settings argumentField="Criteria" valueField="Sales" ignoreEmptyPoints="true"
							[color]="'#21b3c6'" type="bar">
							<dxo-label [visible]="true" [customizeText]="doCustomizeTextLbl"> </dxo-label>
							<!-- [format]="{type: 'currency',precision: '2'}" -->
							<dxo-hover-style>
								<dxo-hatching [opacity]="0"></dxo-hatching>
							</dxo-hover-style>
						</dxo-common-series-settings>
						<dxo-series-template nameField="Criteria"></dxo-series-template>
						<dxo-legend [visible]="false"></dxo-legend>
						<dxo-tooltip [enabled]="true" color="#fff" [paddingTopBottom]="8"
							[customizeTooltip]="customizeTooltip">
							<dxo-font [opacity]="1" size="22"></dxo-font>
							<dxo-shadow color="#000" [opacity]="0.15" [blur]="0" [offsetX]="3" [offsetY]="3"></dxo-shadow>
						</dxo-tooltip>
					</dx-chart>
					<app-ix-load-panel *ngIf="isVisibleChartLoader"></app-ix-load-panel>
				</div>
			</div>
		</div>
	</div>