<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="listOfApprovalTrails" [showBorders]="false"
   [showColumnLines]="true" [showRowLines]="false" [dateSerializationFormat]="'yyyy-MM-dd'" [allowColumnResizing]="true"
   [columnResizingMode]="'nextColumn'" [columnAutoWidth]="true" [width]="'100%'"
   (onToolbarPreparing)="doToolbarPreparing($event)" class="hideFilterPanelInx" [height]="'calc(100vh - 430px)'">
   <dxo-filter-row [visible]="true"></dxo-filter-row>
   <dxo-filter-panel [visible]="true"></dxo-filter-panel>
   <dxo-header-filter [visible]="true" [showRelevantValues]="true">
   </dxo-header-filter>
   <dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
   <dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
   <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
      [showInfo]="true">
   </dxo-pager>
   <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
   <!-- <dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadProductsState"
      [customSave]="doSaveProductsState" [storageKey]="stateStorageKey">
   </dxo-state-storing> -->
   <div *dxTemplate="let data of 'templateFilterText'">
      <div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
         <span>{{filterText}}</span>
      </div>
   </div>
   <div *dxTemplate="let data of 'templateActionBtns'">
      <dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
         text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
         [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
         (onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
         [dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
   </div>

   <dxi-column dataField="pricelistname" caption="Price List Name " dataType="string" [allowHeaderFiltering]="false">
   </dxi-column>
   <dxi-column dataField="workflow_title" caption="Workflow Title " dataType="string" [allowHeaderFiltering]="false">
   </dxi-column>
   <dxi-column dataField="approvers" caption="Approver" dataType="object" cellTemplate="cellTemplateApprover"
      [allowFiltering]="false" [allowHeaderFiltering]="true">
      <dxo-header-filter [dataSource]="listOfApproverUsersHeaderFilter"></dxo-header-filter>
      <div *dxTemplate="let element of 'cellTemplateApprover'">
         {{element.data.approvers && element.data.approvers.length > 0 ? (element.data.approvers[0] || '' |
         filterFromList:listOfApproverUsersHeaderFilter :'value': 'text') : ''}}
      </div>
   </dxi-column>

   <dxi-column dataField="startdate" caption="Start Date" [allowHeaderFiltering]="false" dataType="date"
      format="yyyy-MM-dd" cellTemplate="cellTemplateStartdate" [allowResizing]="false">
      <div *dxTemplate="let element of 'cellTemplateStartdate'">
         {{element.data.startdate || ''}}
      </div>
   </dxi-column>

   <dxi-column dataField="enddate" caption="End Date" [allowHeaderFiltering]="false" dataType="date" format="yyyy-MM-dd"
      cellTemplate="cellTemplateEnddate" [allowResizing]="true">
      <div *dxTemplate="let element of 'cellTemplateEnddate'">
         {{element.data.enddate || ''}}
      </div>
   </dxi-column>

   <dxi-column dataField="modified_date" caption="Modified Date" [allowHeaderFiltering]="false" dataType="date"
      format="yyyy-MM-dd" cellTemplate="cellTemplateModifiyDate" [allowResizing]="true">
      <div *dxTemplate="let element of 'cellTemplateModifiyDate'">
         {{ element.data.modified_date || '' | customDateFormatPipe:'usadatewithdash'}}
      </div>
   </dxi-column>

   <dxi-column dataField="from_status" caption="Original Status" [allowFiltering]="false" [allowHeaderFiltering]="true"
      dataType="string" cellTemplate="cellTemplateOriginalStatus" [allowResizing]="false">
      <dxo-header-filter [dataSource]="listOfPricelistStatuses"></dxo-header-filter>
      <div *dxTemplate="let element of 'cellTemplateOriginalStatus'">
         <div
            [ngClass]="element.data.from_status || '' | filterFromList:listOfPricelistStatuses : 'value' : 'classname'">
            {{element.data.from_status || '' | filterFromList:listOfPricelistStatuses : 'value' : 'text'}}
         </div>
      </div>
   </dxi-column>
   <dxi-column dataField="to_status" caption="Changed Status" [allowFiltering]="false" [allowHeaderFiltering]="true"
      dataType="string" cellTemplate="cellTemplateChangedStatus" [allowResizing]="false">
      <dxo-header-filter [dataSource]="listOfPricelistStatuses"></dxo-header-filter>
      <div *dxTemplate="let element of 'cellTemplateChangedStatus'">
         <div [ngClass]="element.data.to_status || '' | filterFromList:listOfPricelistStatuses : 'value' : 'classname'">
            {{element.data.to_status || '' | filterFromList:listOfPricelistStatuses : 'value' : 'text'}}
         </div>
      </div>
   </dxi-column>
   <dxi-column dataField="comment" caption="Comment " dataType="string" [allowHeaderFiltering]="false">
   </dxi-column>

</dx-data-grid>