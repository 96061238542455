<dx-popup [showTitle]="true" [width]="'1080px'" [title]="titleText" [dragEnabled]="true" [hideOnOutsideClick]="true"
	[showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			width="100%" height="100%">
			<div class="tableOut">
				<table class="responsiveTable">
					<tr *ngFor="let pl of productList;let i = index">
						<td *ngIf="isQBOnlineErpUser">{{pl.itemsku}}</td>
						<td>{{pl.itemname}}</td>
						<td>{{pl.description}}</td>
						<td class="width130">
							<select [(ngModel)]="pl.selectedClaimedUom">
								<option *ngFor="let uom of pl.uomList;let menuIndex = index;" value="{{uom.code}}" selected>
									{{uom.name | uppercase}}
								</option>
							</select>
						</td>
						<td>{{pl.end_user_name}}</td>
						<td class="width120">
							<div class="feildColEDQ">
								<input name="" type="text" id="claimed" [(ngModel)]="pl.claimed" (keyup)="sumClaimed('',pl)"
									numbersOnly maxlength="7">
							</div>
						</td>
					</tr>
				</table>
				<div class="popTotal">Total Quantities : <span>{{totalClaimed}}</span></div>
			</div>
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button class="primaryBtn alignRight" id="button" text="Submit" type="normal" stylingMode="contained"
				(click)="claimedItemSubmit()">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>