export class BussinessPlanResponse {
	public leads: any;
	public prospects: any;
	public clients: any;
	public vendors: any;
	public buyingGroups: any;
}


export class Tasks {
	public guid?: string;
	public tasktype?: string;
	public title?: string;
	public notes?: string;
	public startdate?: any;
	public enddate?: any;
	public status?: string;
	public assignto?: string;
	public closedtasknote?: string;
	public timeinterval: string;
}

export class TaskInsertUpdate {
	public guid: string;
	public tasktype: string;
	public title: string;
	public notes: string;
	public startdate?: any;
	public enddate: any;
	public status: string;
	public closedtasknote?: any;
	public timeinterval: string;
	public assignto: any[];
	public contactpersons: any[];
	public contactfrom: string;
	public guidclient: string;
	public external_data: any;
	public feature_guid: string;
	public notification_task_guid: string;
	public created_date: string;
	public modified_date: string;
	public ixcode: string;
	public issynctoerp: boolean;

	constructor() {
	}
}

export class TaskInsertUpdateUI {
	public clientfrom: string;
	public clientname: string;
	public guidclient: string;
	public clientdetail: string;
	public guidcontactperson: string;
	public contactpersondetail: string;
	public contactpersons: any[];
	public guid: string;
	public tasktype: string;
	public title: string;
	public notes: string;
	public startdate?: any;
	public enddate: any;
	public status: string;
	public assignto?: any;
	public closedtasknote?: any;
	public timeinterval: string;
	public attachment?: any;
	constructor() {
		this.guid = null;
		this.tasktype = null;
		this.title = null;
		// this.startdate = null;
		this.enddate = null;
		this.status = null;
		this.timeinterval = null;
	}
}

export class ContactMove {
	public action: string;
	public content?: any;
	public ids?: any;
	public from: string;
	public to: string;
	constructor() {
		this.action = '';
		this.from = '';
		this.to = '';
	}
}

export class ContactEdit {
	public action: string;
	public type: string;
	public content: string[];
	constructor() {
		this.action = '';
		this.type = '';
		this.content = null;
	}
}

export class Notes {
	public guid?: any;
	public description?: string;
	public createdby?: any;
	public datetime?: string;
	public createdat?: string;
	public updatedat?: string;
}

export class EndUsers {
	public guid: string;
	public code: string;
	public companyname: string;
	public address?: any;
	public email?: string;
	public phone?: string;
	public dialcodephone?: string;
	public pricelistnum?: string;
	public billtodefault?: string;
	public shiptodefault?: string;
	public taxexempt?: boolean;
	public taxcode?: string;
	public distribution_channels?: string;
}

export class EndUsersAddress {
	public guid: string;
	public addressname: string;
	public addresstype?: string;
	public addressname2?: string;
	public addressname3?: string;
	public country?: string;
	public state?: string;
	public city?: string;
	public zipcode?: string;
	public taxcode?: string;
	public taxexempt?: boolean;
}

export class NoteInsertUpdate {
	public guid?: any;
	public description?: string;
	public createdby?: any;
	public datetime?: string;
	constructor() {
		this.description = null;
		this.createdby = null;
		this.datetime = null;
	}
}


export class TaskNotificationUI {
	public clientfrom: string;
	public clientname: string;
	public guidclient: string;
	public clientdetail: string;
	public guidcontactperson: string;
	public contactpersondetail: string;
	public guid: string;
	public tasktype: string;
	public title: string;
	public notes: string;
	public startdate?: any;
	public enddate: any;
	public status: string;
	public assignto?: any;
	public closedtasknote?: any;
	public timeinterval: string;
	constructor() {
		this.guid = null;
		this.tasktype = null;
		this.title = null;
		// this.startdate = null;
		this.enddate = null;
		this.status = null;
		this.timeinterval = null;
	}


}

export interface EndUserInvoiceViewDetailsIO {
	ts: number;
	endUserInvoiceViewDetailReport: EndUserInvoiceViewDetailReportUI;
	isShowDatagrid?: boolean;
	isGenerateReport?: boolean;
}
export class EndUserInvoiceViewDetailReportUI {
	public endusers?: string[];
	public endUsersDetails?: any[];
	public startdate?: string;
	public enddate?: string;
	public combine_months?: boolean;
	public startdate_and_enddate?: [Date, Date];
	public pricelistnum?: string[];
	public pricelistnumDetails?: any[];
	public endUsersPricelist?: string[];
	public endUsersPricelistDetails?: any[];
	public report_type?: string;
	public report_view?: string;

	constructor() { }
}

export class EndUserInvoiceViewDetailReportRequest {
	public endusers?: string[];
	public startdate?: string;
	public enddate?: string;
	public combine_months?: boolean;
	public pricelistnum?: string[];
	public endusers_pricelist?: string[];
	public report_type?: string;

	constructor() { }

}