import { Component, OnInit, OnDestroy, Inject, AfterViewInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MSG_ERROR_MESSAGE, ServerMethods } from '@app/constants-enums/constants';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoginService } from '@app/services/login.service';
import { Subscription } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { ErpTypeValEnum, PopupHeightWidth } from "@app/constants-enums/enums";
declare const ExcelJS: ExcelJS;

import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { ARcreditMemos, ExtraCommissionBasedRules, Invoices, MiscellaneousDeductionRules } from '@app/models/commission-contract.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';

declare function callAccordion(): any;
@Component({
	selector: 'app-apinvoice-view-dialog',
	templateUrl: './apinvoice-view-dialog.component.html',
	styleUrls: ['./apinvoice-view-dialog.component.css']
})
export class ApinvoiceViewDialogComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() apinvoiceViewDetailsProps: any;
	@Output() closeApinvoiceViewDetailsPopup = new EventEmitter<any>();
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;

	@ViewChild('invoicesDataGrid') invoicesDataGrid: DxDataGridComponent;
	@ViewChild('extraCommissionBasedRulesDataGrid') extraCommissionBasedRulesDataGrid: DxDataGridComponent;
	@ViewChild('arcreditmemosDataGrid') arcreditmemosDataGrid: DxDataGridComponent;
	public invoiceDetails: any;
	public apInvoice: any;
	private dataOutReqSubscription: Subscription;
	public invoicesSource: Invoices[];
	public extraCommissionBasedRulesSource: ExtraCommissionBasedRules[];
	public arcreditmemosSource: ARcreditMemos[];
	public mainDataSource: any[] = [];
	public settlementPeriodStartDate: any;
	public settlementPeriodEndDate: any;
	public isQBOnlineErpUser: boolean = false;

	constructor(private datePipe: DatePipe,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _restApiService: RestApiService) {
	}

	ngOnInit() {
		this.isShowPopup = true;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.popupTitleText = 'Settlement Period For Contract';

		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
		}
		this.getApInvoiceHtml();
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['apinvoiceViewDetailsProps']) {
			const apinvoiceViewDetailsProps = _SimpleChanges['apinvoiceViewDetailsProps'].currentValue;

			this.invoiceDetails = apinvoiceViewDetailsProps.invoiceDetails;
			this.settlementPeriodStartDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodStart, 'yyyy-MM-dd');
			this.settlementPeriodEndDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodEnd, 'yyyy-MM-dd');
		}
	}

	ngAfterViewInit() {
		// callAccordion();
	}

	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	doHiddenPopup(event) {
		this.closeApinvoiceViewDetailsPopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}

	fullNameColumn_calculateCellValue(rowData) {
		const length = rowData.fiscal_sequence.toString().length;
		let fiscal_sequence = rowData.fiscal_sequence;
		if (length === 1) {
			fiscal_sequence = '0' + rowData.fiscal_sequence;
		}
		return fiscal_sequence + '  ' + rowData.monthYear
	}
	setMainDataSource() {
		this.mainDataSource = [];
		if (this.invoicesSource && this.invoicesSource.length > 0) {
			this.mainDataSource.push({ "key": "invoices", "name": "Invoice Details" });
		}

		if (this.extraCommissionBasedRulesSource && this.extraCommissionBasedRulesSource.length > 0) {
			this.mainDataSource.push({ "key": "extraCommissionBasedRules", "name": "Extra Commission Based Rules" });
		}

		if (this.arcreditmemosSource && this.arcreditmemosSource.length > 0) {
			this.mainDataSource.push({ "key": "arcreditmemos", "name": "AR Cardit Memos" });
		}
	}

	checkMasterDetails(objMasterDetails, key) {
		// console.log('calll >>> ', key);
		if (objMasterDetails.data) {
			if (objMasterDetails.data.key === key) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	onExporting(e) {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let invoicesSheet, extraCommissionBasedRulesSheet, arCreditMemosSheet;

		if (context.invoicesDataGrid && context.invoicesDataGrid.instance) {
			invoicesSheet = workbook.addWorksheet('Invoices');
		}
		if (context.extraCommissionBasedRulesDataGrid && context.extraCommissionBasedRulesDataGrid.instance) {
			extraCommissionBasedRulesSheet = workbook.addWorksheet('Extra Commission Based Rules');
		}
		if (context.arcreditmemosDataGrid && context.arcreditmemosDataGrid.instance) {
			arCreditMemosSheet = workbook.addWorksheet('AR Cradit Memos');
		}

		let sheetName = '';
		let fileName = 'Commission_Calculation.xlsx';
		if (this.invoiceDetails.apInvoiceNumber) {
			sheetName = this.invoiceDetails.apInvoiceNumber;
			fileName = this.invoiceDetails.apInvoiceNumber + '_' + fileName;
		}
		if (this.invoiceDetails.subusername) {
			sheetName = sheetName + '-' + this.invoiceDetails.subusername;
		}
		// const worksheet = workbook.addWorksheet(sheetName);

		if (context.invoicesDataGrid && context.invoicesDataGrid.instance) {
			exportDataGrid({
				worksheet: invoicesSheet,
				component: context.invoicesDataGrid.instance,
				autoFilterEnabled: false
			}).then(function (dataGridRange) {
				if (context.extraCommissionBasedRulesDataGrid && context.extraCommissionBasedRulesDataGrid.instance) {
					// header
					// const headerRow = invoicesSheet.getRow(1);
					// headerRow.height = 20;
					// invoicesSheet.mergeCells(1, 1, 2, 10);

					// headerRow.getCell(1).value = 'Settlement Period : ' + context.settlementPeriodStartDate + ' To ' + context.settlementPeriodEndDate;
					// headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 20 };
					// headerRow.getCell(1).alignment = { horizontal: 'center' };

					// footer
					const footerRowIndex = dataGridRange.to.row + 2;
					const footerRow = invoicesSheet.getRow(footerRowIndex);
					invoicesSheet.mergeCells(footerRowIndex, 1, footerRowIndex, 10);

					footerRow.getCell(1).value = 'Total Commission : $' + context.invoiceDetails.settlementValue;
					footerRow.getCell(1).font = { 'bold': true, name: 'Segoe UI Light', size: 16 };
					footerRow.getCell(1).alignment = { horizontal: 'right' };

					return exportDataGrid({
						worksheet: extraCommissionBasedRulesSheet,
						component: context.extraCommissionBasedRulesDataGrid.instance,
						autoFilterEnabled: true
					});
				}
			}).then(function () {
				if (context.arcreditmemosDataGrid && context.arcreditmemosDataGrid.instance) {
					return exportDataGrid({
						worksheet: arCreditMemosSheet,
						component: context.arcreditmemosDataGrid.instance,
						autoFilterEnabled: true
					});
				}
			}).then(function () {
				// https://github.com/exceljs/exceljs#writing-xlsx

				workbook.xlsx.writeBuffer().then(function (buffer) {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
				});
			});
			e.cancel = true;
		} else {
			this._ToastrService.info('Invoice data not found !', 'info', { closeButton: true, tapToDismiss: true })
		}
	}

	getApInvoiceHtml() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('apInvoicePath', this.invoiceDetails.apInvoicePath);
		formData.append('methods', ServerMethods.GET_AP_INVOICE_HTML);
		this.dataOutReqSubscription = this._restApiService.doDataOutReqFormData((formData)).subscribe({
			next: response => {
				if (response && response.data) {
					const data = response.data;
					this.invoicesSource = data.invoices || [];
					this.extraCommissionBasedRulesSource = data.extraCommissionBasedRules || [];
					this.arcreditmemosSource = data.arcreditmemos || [];
				} else {
					this.apInvoice = '<h2>Data not found !</h2>';
				}
				this.setMainDataSource();
			}, error: error => {
				console.error('error', error);
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//HTML API invoice
	// getApInvoiceHtml() {
	// 	const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&apInvoicePath=' + this.apInvoicePath + '&method=getApInvoiceHtml';
	// 	this.dataOutReqSubscription = this._restApiService.doDataOutReq((reqFormData)).subscribe(
	// 		response => {
	// 			if (response.data !== undefined) {
	// 				this.apInvoice = response.data;
	// 				setTimeout(() => {
	// 					callAccordion();
	// 				}, 1000);
	// 			} else {
	// 				this.apInvoice = '<h2>Data not found !</h2>';
	// 			}
	// 		}, error => {
	// 			console.error('error', error);
	// 			this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
	// 		});
	// }
}

