<div class="rightBar">
	<div class="rightBlock" *ngIf="isResponse">
		<div id="mainContent" class="contentRow cf">
			<div class="borderBox quoteDesign cf">
				<div class="orderBox">
					<div class="subTitleRow" *ngIf="apCreditMemoDetails && apCreditMemoDetails.docnum">
						<!-- AP Credit Memo ID -->
						<h2>
							<span class="lbl">AP Credit Memo ID# </span>
							{{apCreditMemoDetails.docnum || ''}}
						</h2>
					</div>
					<div class="orderBox row">
						<div class="orderDetail cols4">
							<span class="custId" *ngIf="!isQBErpUser">
								Customer ID
								{{apCreditMemoDetails?.cardcode}}
							</span>
							<h3 class="custNm">
								<span class="contTx lbl mrgB0 mrgR0">Customer: </span>
								{{apCreditMemoDetails?.cardname}}
							</h3>

							<div class="contDtl">
								<span class="contTx icoDate" *ngIf="apCreditMemoDetails?.docdate">
									<span class="lbl">Credit Memo
										Date:
									</span>
									{{ apCreditMemoDetails?.docdate || '' | customDateFormatPipe:'dateonly'}}
								</span>
								<span class="contTx icoDate" *ngIf="apCreditMemoDetails?.docduedate">
									<span class="lbl">Due Date: </span>
									{{ apCreditMemoDetails?.docduedate || '' | customDateFormatPipe:'dateonly'}}
								</span>
							</div>

							<div *ngIf="isEnableDistributionChannels" class="contDtl">
								<span class="contTx icoDate">
									<span class="lbl">Distribution Channel: </span>
									{{apInvoiceDetails && apInvoiceDetails.distribution_channels ?
									(apInvoiceDetails.distribution_channels || '' | filterFromList:listOfDistributionChannels :
									'code' : 'code') : ''}}
									{{apInvoiceDetails && apInvoiceDetails.distribution_channels ? ' - '
									+(apInvoiceDetails.distribution_channels || '' | filterFromList:listOfDistr`ibutionChannels :
									'code' : 'description') : ''}}
								</span>
							</div>

							<div *ngIf="apCreditMemoDetails.ponumber || apCreditMemoDetails.shipmethod" class="poShipping">
								<div class="row">
									<div *ngIf="apCreditMemoDetails.ponumber" class="cols6">
										<span>PO Number</span>{{apCreditMemoDetails.ponumber}}
									</div>
									<div *ngIf="apCreditMemoDetails.shipmethod" class="cols6">
										<span>Shipping Method</span>
										{{apCreditMemoDetails.shipmethod}}
									</div>
								</div>
							</div>
						</div>

						<div class="orderAddress cols6 padL0"
							*ngIf="apCreditMemoDetails && (apCreditMemoDetails.address || apCreditMemoDetails.address2)">
							<div *ngIf="apCreditMemoDetails && apCreditMemoDetails.address" class="cols6 addressBox"
								[ngClass]="{'singleBlock':!(apCreditMemoDetails.address2)}">
								<h3 class="custNm">Billing Address</h3>
								<span class="contTx" [innerHTML]="apCreditMemoDetails.address"></span>
							</div>
							<div *ngIf="apCreditMemoDetails && apCreditMemoDetails.address2" class="cols6 addressBox"
								[ngClass]="{'singleBlock':!(apCreditMemoDetails.address)}">
								<h3 class="custNm">Shipping Address</h3>
								<span class="contTx" [innerHTML]="apCreditMemoDetails.address2">
								</span>
							</div>
						</div>

						<div class="orderStatus odStatus cols2 noBdr mrgT0">
							<ul class="statusBxLnremove">
								<li>
									<span class="stLbl">Status:</span>
									<span class="inStatusLabel orangeInvoice"
										*ngIf="apCreditMemoDetails?.documentstatus =='bost_Open'">Open</span>
									<span class="inStatusLabel greenInvoice"
										*ngIf="apCreditMemoDetails?.documentstatus =='bost_Close'">Closed</span>
									<span class="inStatusLabel redInvoice"
										*ngIf="apCreditMemoDetails?.documentstatus =='bost_Cancel'">Canceled</span>
									<span class="inStatusLabel redInvoice"
										*ngIf="apCreditMemoDetails?.documentstatus =='deleted'">Deleted</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="borderBox padB20 mrgB0 cf">
				<div class="subTitleRow">
					<h2>Document Listing</h2>
				</div>
				<div *ngIf="apCreditMemoDetails.documentlines && apCreditMemoDetails.documentlines.length > 0">
					<div class="mrgB0 cf">
						<dx-data-grid #dataGridContainer id="gridContainer"
							[dataSource]="apCreditMemoDetails.documentlines || []" keyExpr="linenum" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[columnAutoWidth]="true" (onToolbarPreparing)="doToolbarPreparing($event)" [width]="'100%'"
							class="hoverClr hideFilterPanelInx">
							<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxi-column dataField="itemname" caption="Item" dataType="string" cellTemplate="cellTemplateItem">
								<div *dxTemplate="let element of 'cellTemplateItem'">
									{{element.data.itemname || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="description" caption="Description" dataType="string"
								cellTemplate="cellTemplateDescription">
								<div *dxTemplate="let element of 'cellTemplateDescription'">
									{{element.data.description || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="price" [caption]="'Rate '+ globalCurrencySymbol" dataType="number">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateRate'">
									{{element.data.price || 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<dxi-column dataField="quantity" caption="Quantity" dataType="number"
								cellTemplate="cellTemplateQuantity">
								<div *dxTemplate="let element of 'cellTemplateQuantity'">
									{{element.data.quantity}}
								</div>
							</dxi-column>
							<dxi-column dataField="linetotal" caption="Total" dataType="number">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateTotal'">
									{{element.data.linetotal || 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<!-- <dxi-column dataField="" caption="View Document" cellTemplate="cellTemplateViewDoc"
                        *ngIf="isShowViewDocuments">
                        <div *dxTemplate="let element of 'cellTemplateViewDoc'">
                           <a *ngIf="apCreditMemoDetails.chargebackDocGuid" class="centeredIcon viewIconBtn"
                              (click)="viewChargeBackDocumentDetails()"><span class="sprite viewIcon "></span></a>
                        </div>
                     </dxi-column> -->
						</dx-data-grid>
					</div>
				</div>
				<div class="cols12 paymentBtmRow">
					<div class="row">
						<diV class="totalRowCal withAddBtn noActClm noBatchClm">
							<div class="alignRight mrgT10">
								<div class="cols12">
									<div class="paySubTotal totalWithAct">
										Subtotal :
										<span class="txtRight">
											{{apCreditMemoDetails.doctotalsys || 0 | customCurrencyPipe}}
										</span>
									</div>
								</div>
								<div *ngIf="apCreditMemoDetails.totaldiscount && apCreditMemoDetails.totaldiscount > 0"
									class="cols12">
									<div class="paySubTotal totalWithAct">
										Discount :
										<span class="txtRight">
											{{apCreditMemoDetails.totaldiscount || 0 | customCurrencyPipe }}
										</span>
									</div>
								</div>
								<div *ngIf="apCreditMemoDetails?.totalmiscamount && apCreditMemoDetails.totalmiscamount > 0"
									class="cols12">
									<div class="paySubTotal totalWithAct">
										Miscellaneous Charge:
										<span class="txtRight">
											{{apCreditMemoDetails.totalmiscamount || 0 | customCurrencyPipe }}
										</span>
									</div>
								</div>
								<div *ngIf="apCreditMemoDetails.shippingcharges && apCreditMemoDetails.shippingcharges > 0"
									class="cols12">
									<div class="paySubTotal totalWithAct">
										Frieght Charge:
										<span class="txtRight">
											{{apCreditMemoDetails.shippingcharges || 0 |customCurrencyPipe }}
										</span>
									</div>
								</div>
								<div class="cols12">
									<div class="paySubTotal payFinalTotal">
										Total :
										<span class="txtRight">
											{{apCreditMemoDetails.doctotal || 0 |customCurrencyPipe }}
										</span>
									</div>
								</div>
							</div>
						</diV>
						<div class="cols12 mrgT10 padL30">
							<div class="dx-field feildCol mrgT10 mrgB20 remarksinputDisable">
								<div class="dx-field-label">Remarks</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-text-area [height]="60" [maxLength]="400" [(value)]="apCreditMemoDetails.journalmemo"
										[disabled]="true">
									</dx-text-area>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#mainContent' }"
	[(visible)]="isShowLoadPanel" [showIndicator]="true" [showPane]="true" [shading]="false"
	[hideOnOutsideClick]="false">
</dx-load-panel>