import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class InputControlService {
	public territoryGridBoxColumns: any[] = [
		{ dataField: 'guid', caption: 'Guid', visible: false },
		{ dataField: 'territory_name', caption: 'Name' },
	];
	public distributionChannelsGridBoxColumns: any[] = [
		{ dataField: 'guid', caption: 'Guid', visible: false },
		{ dataField: 'code', caption: 'Code', visible: true, sortOrder: "asc" },
		{ dataField: 'description', caption: 'Description', visible: false },
	];
}
