import { Component, OnInit, OnDestroy, Input, ElementRef, HostListener } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { Guid } from '@app/models/guid';
import { Company360DashboardOrderLblEnum, Company360DashboardOrderValEnum, SalesListingOpenFromEnum } from '@app/constants-enums/enums';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CustomNumberDecimalSuffixPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { formatNumber } from 'devextreme/localization';
import { CustomFormatCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-sales-order-total-tiles',
	templateUrl: './sales-order-total-tiles.component.html',
	styleUrls: ['./sales-order-total-tiles.component.css']
})
export class SalesOrderTotalTilesComponent implements OnInit, OnDestroy {
	public globalCurrency: string;
	public valueOfSalesOrdersForTheMonth: number;
	public resourcesLoaded: boolean = false;
	public currentMonthName: string;
	private getOrderSbsn: Subscription;
	public isShowOrderPopover: boolean;
	public isUserSettingsExisting: boolean;
	public isShowFullValue: boolean = false;
	public getLastCharacterFromSortValue: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService,
		private _SalesDataSharingService: SalesDataSharingService,
		private _CustomFormatCurrencyPipe: CustomFormatCurrencyPipe,
		private _ElementRef: ElementRef) {

	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this._ElementRef.nativeElement.contains(event.target)) {
			// click on inside 
		} else {
			this.isShowFullValue = false; // click on outside 
		}
	}

	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.currentMonthName = this._DashboardService.getCurrentMonthName();
		this.currentMonthName = '';
		this.getSalesOrderTotal();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_ORDER_TILES_EVENT', response => {
			if (response) {
				this.getSalesOrderTotal();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getOrderSbsn) {
			this.getOrderSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_ORDER_TILES_EVENT');
	}

	private getSalesOrderTotal() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.ORDERS_TILE);
		if (this.getOrderSbsn) {
			this.getOrderSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getOrderSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(
			response => {
				this.resourcesLoaded = false;
				if (response.flag) {
					if (response.data.tiles) {
						this.valueOfSalesOrdersForTheMonth = response.data.tiles.value_of_sales_orders_for_the_month || 0;
						this.currentMonthName = response.data.tiles.month_name || '';
						const getStringValue = new CustomNumberDecimalSuffixPipe().transform(this.valueOfSalesOrdersForTheMonth, 2).toString();
						this.getLastCharacterFromSortValue = '';
						if (/^[a-zA-Z]+$/.test(getStringValue.substr(getStringValue.length - 1))) {
							this.getLastCharacterFromSortValue = getStringValue.substr(getStringValue.length - 1);
						}
					}
				}
				this.getCustomer360Config();
			}, error => {
				this.resourcesLoaded = false;
			});
	}

	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
		this._SalesDataSharingService.setSalesListingOpenFrom(SalesListingOpenFromEnum.TILE_ORDER);
	}

	public doOpenOrderPopover(e) {
		this.getCustomer360Config();
		this.isShowOrderPopover = true;
	}
	public doCloseOrderPopover(e) {
		this.isShowOrderPopover = false;
	}

	// Method used to get Customer360 Configuration
	private getCustomer360Config() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		this.isUserSettingsExisting = false;
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === 'order_tile');
				if (configIndex !== -1) {
					const configObject = dashboardSettings.configs[configIndex];
					let isTileSettingSaved = false;
					if (configObject && configObject.show_data_from) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_status_to_consider) {
						isTileSettingSaved = true;
					}
					if (configObject && configObject.which_date_to_use) {
						isTileSettingSaved = true;
					}
					this.isUserSettingsExisting = isTileSettingSaved;
				}
			}
		}
	}

	public doRefreshTile(e) {
		this.isShowOrderPopover = false;
		this.getSalesOrderTotal();
	}

	public doClickToShowFullValue(event) {
		if (this.valueOfSalesOrdersForTheMonth <= 0) {
			return;
		}
		if (this.valueOfSalesOrdersForTheMonth > 0 && this.valueOfSalesOrdersForTheMonth < 1000) {
			return;
		}
		event.stopPropagation();
		this.isShowFullValue = true;
	}
}
