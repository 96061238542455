<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitleText" [showCloseButton]="true" [(visible)]="isShowPopup"
	(onHidden)="doHiddenPopup($event)" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<ul class="listText mrgT10">
				<li>Contract Name:
					<strong class="purple">{{contractName}}</strong>
				</li>
				<li> Salesperson Name:<strong class="purple">{{salesPersonName}}</strong></li>
			</ul>

			<dx-data-grid id="gridContainer" [dataSource]="listOfViewPreviousSettlementPeriod" [showBorders]="true"
				[columnAutoWidth]="true" keyExpr="guid" class="hideFilterPanelInx" [height]="450" class="colorList">

				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-filter-panel [visible]="false"></dxo-filter-panel>
				<dxo-header-filter [visible]="false"></dxo-header-filter>
				<dxo-sorting mode="single"></dxo-sorting>


				<dxi-column caption="No" dataType="number" cellTemplate="cellTemplateContractNo">
					<div *dxTemplate="let element of 'cellTemplateContractNo'">
						{{element.rowIndex + 1}}
					</div>
				</dxi-column>

				<dxi-column caption="Name" dataField="contractName" dataType="string"
					cellTemplate="cellTemplateContractName">
					<div *dxTemplate="let element of 'cellTemplateContractName'">
						<span class="highlight">
							{{element.data.contractName || ''}}
						</span>
					</div>
				</dxi-column>

				<dxi-column caption="Start Date" dataField="settlementPeriodStart" dataType="string"
					cellTemplate="cellTemplateSettlementPeriodStart">
					<div *dxTemplate="let element of 'cellTemplateSettlementPeriodStart'">
						{{ (element.data.settlementPeriodStart !== null && element.data.settlementPeriodStart !== '' &&
						element.data.settlementPeriodStart !== undefined) ? (element.data.settlementPeriodStart | date:
						'yyyy-MM-dd') : "" }}
					</div>
				</dxi-column>

				<dxi-column caption="End Date" dataField="settlementPeriodEnd" dataType="string"
					cellTemplate="cellTemplateSettlementPeriodEnd">
					<div *dxTemplate="let element of 'cellTemplateSettlementPeriodEnd'">
						{{ (element.data.settlementPeriodEnd !== null && element.data.settlementPeriodEnd !== '' &&
						element.data.settlementPeriodEnd !== undefined) ? (element.data.settlementPeriodEnd | date:
						'yyyy-MM-dd') : "" }}
					</div>
				</dxi-column>

				<dxi-column caption="Salesperson" dataField="subusername" dataType="string"
					cellTemplate="cellTemplateSubusername">
					<div *dxTemplate="let element of 'cellTemplateSubusername'">
						{{element.data.subusername || ''}}
					</div>
				</dxi-column>

				<dxi-column caption="Commission Amount" dataField="settlementValue" dataType="number"
					cellTemplate="cellTemplateSettlementValue">
					<div *dxTemplate="let element of 'cellTemplateSettlementValue'">
						{{element.data.settlementValue || 0 | customCurrencyPipe}}
					</div>
				</dxi-column>

				<dxi-column caption="Status" dataField="apInvoiceCreatedInERP" dataType="string"
					cellTemplate="cellTemplateApInvoiceCreatedInERP">
					<div *dxTemplate="let element of 'cellTemplateApInvoiceCreatedInERP'">
						<a *ngIf="element.data.apInvoiceCreatedInERP === true" id="view_{{element.rowIndex + 1}}"
							(click)="doOpenApInvoiceLink(element.data)">
							<span class="viewIcon sprite"></span>
						</a>
						<a *ngIf="element.data.apInvoiceCreatedInERP === ''" class="orange">Processing</a>
						<a *ngIf="element.data.apInvoiceCreatedInERP === false" class="red">Error</a>
						<dx-tooltip target="#view_{{element.rowIndex + 1}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
							position="bottom">
							<div *dxTemplate="let data = data of 'content'">
								View Settlement Report
							</div>
						</dx-tooltip>
					</div>
				</dxi-column>

				<dxi-column caption="Remark" dataField="errorMessage" dataType="string"
					cellTemplate="cellTemplateErrorMessage">
					<div *dxTemplate="let element of 'cellTemplateErrorMessage'">
						{{element.data.errorMessage || ''}}
					</div>
				</dxi-column>
			</dx-data-grid>
		</dx-scroll-view>
	</div>

</dx-popup>

<app-apinvoice-view-dialog *ngIf="isViewApinvoiceViewDetailsPopup"
	[apinvoiceViewDetailsProps]="apinvoiceViewDetailsProps"
	(closeApinvoiceViewDetailsPopup)="doCloseApinvoiceViewDetailsPopup($event)"></app-apinvoice-view-dialog>