<div *ngxPermissionsOnly="isCurrentTabPermission">
	<div class="row rulescontent" style="margin-bottom: 15px">
		<div class="cols3">
			<div class="dx-field">
				<div class="dx-field-label">Rule/Bonus Type</div>
				<div class="dx-field-value">
					<dx-select-box #ruleBonusTypeSelectBoxRef [dataSource]="listOfRuleBonusTypes"
						[(value)]="ruleTabModel.ruleBonusType" displayExpr="text" valueExpr="value"
						(onValueChanged)="ruleBonusTypeChange(ruleTabModel.ruleBonusType)" customItemCreateEvent="blur"
						placeholder="Select Rule/Bonus Type">
					</dx-select-box>
				</div>
			</div>
		</div>

		<!--#region inclusionExclusionRule -->
		<div *ngIf="ruleTabModel.ruleBonusType == 'inclusionExclusionRule'">
			<div class="cols3">
				<div class="dx-field">
					<div class="dx-field-label">Condition</div>
					<div class="dx-field-value">
						<dx-select-box #conditionSelectBoxRef [dataSource]="listOfInclusionExclusionRuleConditions"
							(onValueChanged)="doChangeConditionsDrpDwn($event)" [(value)]="ruleTabModel.condition"
							displayExpr="text" valueExpr="value" placeholder="Select Rule/Bonus Type">
						</dx-select-box>
					</div>
				</div>
			</div>
			<div class=" cols3" *ngIf="ruleTabModel.condition !== 'excludeLateInvoice'">
				<div class="dx-field">
					<div class="dx-field-label">Criterion</div>
					<div class="dx-field-value">
						<dx-select-box #criterionSelectBoxRef [dataSource]="listOfInclusionExclusionRuleCriterions"
							[(value)]="ruleTabModel.criterion" (onValueChanged)="criterionChange(ruleTabModel.criterion)"
							customItemCreateEvent="blur" displayExpr="text" valueExpr="value" placeholder="Select Criterion">
						</dx-select-box>
					</div>
				</div>
			</div>
			<div class="cols3" *ngIf="ruleTabModel.condition === 'excludeLateInvoice'">
				<div class="dx-field">
					<div for="Number of Days Past Due Date" class="dx-field-label">
						Number of Days Past Due Date
					</div>
					<div class="dx-field-value">
						<dx-text-box [(value)]="ruleTabModel.criterion" mode="text" [showClearButton]="true">
						</dx-text-box>
					</div>
				</div>
			</div>
			<div class="cols2 cols2updatebtn">
				<div class="dx-field" [ngClass]="{inputDisable: isViewIERuleRuleRate}">
					<div class="dx-field-label">Rate %</div>
					<div class="dx-field-value">
						<dx-text-box [(value)]="ruleTabModel.ruleRate" [disabled]="isViewIERuleRuleRate"
							[showClearButton]="true">
						</dx-text-box>
					</div>
				</div>
			</div>
		</div>
		<!-- #endregion -->

		<!--#region rateRule -->
		<div *ngIf="ruleTabModel.ruleBonusType == 'rateRule'">
			<div class="cols3">
				<div class="dx-field">
					<div class="dx-field-label">Condition</div>
					<div class="dx-field-value">
						<dx-select-box #conditionSelectBoxRef [dataSource]="listOfRateRuleConditions"
							[(value)]="ruleTabModel.condition" (onValueChanged)="conditionChange(ruleTabModel.condition)"
							customItemCreateEvent="blur" displayExpr="text" valueExpr="value" placeholder="Select Condition">
						</dx-select-box>
					</div>
				</div>
			</div>
			<div class="cols3"
				*ngIf="ruleTabModel.condition !== 'miscellaneousDeductionInventory' && ruleTabModel.condition !== 'miscellaneousDeductionFixedCost' && ruleTabModel.condition !== 'miscellaneousDeductionItemPercent' && ruleTabModel.condition !== 'miscellaneousDeductionAllItem'">
				<div class="dx-field" [ngClass]="{inputDisable: isViewBounsAmountCriterion}">
					<div *ngIf="ruleTabModel.condition !=='miscellaneousDeduction'" fo="Criterion" class="dx-field-label">
						Criterion
					</div>
					<div *ngIf="ruleTabModel.condition === 'miscellaneousDeduction'" for="Title/Label"
						class="dx-field-label">
						Title/Label
					</div>
					<div class="dx-field-value">
						<dx-text-box [(value)]="ruleTabModel.criterion" mode="text" [showClearButton]="true"
							[disabled]="isViewRateRuleCriterion">
						</dx-text-box>
					</div>
				</div>
			</div>
			<div class="cols3"
				*ngIf="ruleTabModel.condition === 'miscellaneousDeductionInventory' || ruleTabModel.condition === 'miscellaneousDeductionAllItem'">
				<div class="dx-field feildCol">
					<div class="dx-field-label">Customers</div>
					<div class="dx-field-value" style="width: 100%;">
						<dx-drop-down-box #customerDropDown [dataSource]="customerDataSource"
							[(value)]="lengthSelectedCustomers" [displayExpr]="doDisplayExprCustomerText" valueExpr="code"
							placeholder="Select Customer..." [showClearButton]="true"
							(onValueChanged)="onCustomerValueChanged($event)" [dropDownOptions]="{width:600}"
							class="dx-ix-dropdownbox">
							<div *dxTemplate="let data of 'content'">
								<dx-check-box text="All Customers In Contract" [(value)]="chkAllCustomer"
									(onValueChanged)="checkAllCustomer()" class="mrgB10">
								</dx-check-box>
								<dx-check-box text="By Transaction" (onValueChanged)="checkByTransaction()"
									[(value)]="chkByTransaction" class="mrgB10">
								</dx-check-box>
								<dx-tag-box [value]="arrSelectedCustomerListTagBox" [grouped]="true"
									(onValueChanged)="doValueChangeCustomersTagBox($event)" class="dx-ix removedCloseIcon"
									tagTemplate="tagTemplate">
									<div *dxTemplate="let tagData of 'tagTemplate'" class="dx-tag-content">
										{{tagData}}
										<div class="dx-tag-remove-button"></div>
									</div>
								</dx-tag-box>
								<dx-data-grid #customerDataGrid id="gridContainer" [dataSource]="customerDataSource"
									[columns]="customerGridBoxColumns" [selection]="{ mode: 'multiple' }"
									[remoteOperations]="true" [activeStateEnabled]="true" [hoverStateEnabled]="true"
									[autoNavigateToFocusedRow]="true" [paging]="{ enabled: true, pageSize: 50 }"
									[filterRow]="{ visible: true }" [height]="345" [(selectedRowKeys)]="arrSelectedCustomerList"
									keyExpr="code" [showBorders]="true" [allowColumnResizing]="true"
									[columnResizingMode]="'nextColumn'" [allowColumnReordering]="true"
									(onSelectionChanged)="onCustomerSelectionChanged($event)">
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
						</dx-drop-down-box>
					</div>
				</div>
			</div>
			<div class="cols3"
				*ngIf="ruleTabModel.condition === 'miscellaneousDeductionFixedCost' || (ruleTabModel.condition === 'miscellaneousDeductionItemPercent')">
				<div class="dx-field feildCol">
					<div class="dx-field-label">Items</div>
					<div class="dx-field-value" style="width: 100%;">
						<dx-drop-down-box #itemDropDown [dataSource]="itemDataSource" [(value)]="lengthSelectedItems"
							[displayExpr]="doDisplayExprItemsText" valueExpr="erp_primary_key" placeholder="Select Item(s)..."
							[showClearButton]="true" (onValueChanged)="onItemValueChanged($event)"
							[dropDownOptions]="{width:600}">
							<div *dxTemplate="let data of 'content'">
								<dx-check-box text="All Items" (onValueChanged)="checkAllItem()" [(value)]="chkAllItem"
									class="mrgB10">
								</dx-check-box>
								<!-- <dx-text-box *ngIf="itemGridBoxValue && itemGridBoxValue.length > 0" [readOnly]="true"
								[hoverStateEnabled]="false" [(value)]="strSelectedItemList"></dx-text-box> -->
								<dx-tag-box [value]="arrSelectedItemListTagBox" [grouped]="true"
									(onValueChanged)="doValueChangeItemTagBox($event)" class="dx-ix removedCloseIcon"
									tagTemplate="tagTemplate">
									<div *dxTemplate="let tagData of 'tagTemplate'" class="dx-tag-content">
										{{tagData}}
										<div class="dx-tag-remove-button"></div>
									</div>
								</dx-tag-box>
								<dx-data-grid #itemDataGrid id="gridContainer" [dataSource]="itemDataSource"
									[columns]="itemGridBoxColumns" [selection]="{ mode: 'multiple'}" [activeStateEnabled]="true"
									[hoverStateEnabled]="true" [autoNavigateToFocusedRow]="true"
									[paging]="{ enabled: true, pageSize: 50 }" [filterRow]="{ visible: true }" [height]="325"
									[(selectedRowKeys)]="arrSelectedItemList" [remoteOperations]="true" [wordWrapEnabled]="true"
									[allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
									[allowColumnReordering]="true" [showBorders]="true"
									(onSelectionChanged)="onItemSelectionChanged($event)">
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
						</dx-drop-down-box>
					</div>
				</div>
			</div>
			<!-- <div class="cols3"
				*ngIf="ruleTabModel.condition === 'miscellaneousDeductionItemPercent' && enable_classification_for_commision">
				<div class="dx-field feildCol">
					<div class="dx-field-label">Classes</div>
					<div class="dx-field-value" style="width: 100%;">
						<dx-drop-down-box [dataSource]="classesDataSource" [(value)]="lengthSelectedClasses"
							[displayExpr]="doDisplayExprClassesText" valueExpr="id" placeholder="Select Classes..."
							[showClearButton]="true" (onValueChanged)="onClassesValueChanged($event)"
							[dropDownOptions]="{width:600}">
							<div *dxTemplate="let data of 'content'">
								<dx-check-box text="All Classes" (onValueChanged)="checkAllClasses()" [(value)]="chkAllClasses"
									class="mrgB10" width="25%">
								</dx-check-box>
								<dx-tag-box [value]="arrSelectedClassesListTagBox" [grouped]="true"
									(onValueChanged)="doValueChangeClassesTagBox($event)" class="dx-ix removedCloseIcon"
									tagTemplate="tagTemplate">
									<div *dxTemplate="let tagData of 'tagTemplate'" class="dx-tag-content">
										{{tagData}}
										<div class="dx-tag-remove-button"></div>
									</div>
								</dx-tag-box>
								<dx-data-grid #classesDataGrid [dataSource]="classesDataSource"
									[columns]="classesGridBoxColumns" [selection]="{ mode: 'multiple'}"
									[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 50 }"
									[filterRow]="{ visible: true }" [height]="325" [(selectedRowKeys)]="arrSelectedClassesList"
									[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'" [allowColumnReordering]="true"
									(onSelectionChanged)="onClassesSelectionChanged($event)">
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [useNative]="false"></dxo-scrolling>
									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
						</dx-drop-down-box>
					</div>
				</div>
			</div> -->
			<div class="cols1 cols2updatebtn">
				<div class="dx-field">
					<div class="dx-field-label">Rate
						<span [ngSwitch]="ruleTabModel.condition">
							<span *ngSwitchCase="'miscellaneousDeductionItemPercent'">%</span>
							<span *ngSwitchCase="'miscellaneousDeductionFixedCost'">{{globalCurrencySymbol}}</span>
							<span *ngSwitchDefault>%</span>
						</span>
					</div>
					<div class="dx-field-value">
						<!-- <dx-number-box [(value)]="ruleTabModel.ruleRate" [showClearButton]="true" [showSpinButtons]="true">
						</dx-number-box> -->
						<dx-text-box [(value)]="ruleTabModel.ruleRate" [showClearButton]="true">
						</dx-text-box>
					</div>
				</div>
			</div>
		</div>
		<!-- #endregion -->

		<!--#region bonusAmount -->
		<div *ngIf="ruleTabModel.ruleBonusType == 'bonusAmount'">
			<div class="cols3">
				<div class="dx-field">
					<div class="dx-field-label">Condition</div>
					<div class="dx-field-value">
						<dx-select-box #conditionSelectBoxRef [dataSource]="listOfBonusAmountConditions"
							[(value)]="ruleTabModel.condition" (onValueChanged)="conditionChange(ruleTabModel.condition)"
							customItemCreateEvent="blur" displayExpr="text" valueExpr="value" placeholder="Select Condition">
						</dx-select-box>
					</div>
				</div>
			</div>
			<div class="cols3">
				<div class="dx-field" [ngClass]="{inputDisable: isViewBounsAmountCriterion}">
					<div class="dx-field-label">Criterion {{globalCurrencySymbol}}</div>
					<div class="dx-field-value">
						<dx-text-box [(value)]="ruleTabModel.criterion" mode="text" [showClearButton]="true"
							[disabled]="isViewBounsAmountCriterion">
						</dx-text-box>
					</div>
				</div>
			</div>
			<div class="cols2 cols2updatebtn">
				<div class="dx-field">
					<div class="dx-field-label">Rate {{ruleTabModel.ruleRate != 'N/A' && (ruleTabModel.ruleBonusType ==
						'bonusAmount' || ruleTabModel.condition === 'miscellaneousDeductionFixedCost')
						?globalCurrencySymbol : '%'}}</div>
					<div class="dx-field-value">
						<!-- <dx-number-box [(value)]="ruleTabModel.ruleRate" valueChangeEvent="keyup"
							(onKeyDown)="doBuyingGroupPercentageKeyDown($event)" [min]="0" [max]="99" maxLength="2"
							[showClearButton]="true" [showSpinButtons]="true">
						</dx-number-box> -->
						<dx-text-box [(value)]="ruleTabModel.ruleRate" [showClearButton]="true">
						</dx-text-box>
					</div>
				</div>
			</div>
		</div>
		<!-- #endregion -->

		<!--#region bonusRate -->
		<div *ngIf="ruleTabModel.ruleBonusType == 'bonusRate'">
			<div class="cols3">
				<div class="dx-field">
					<div class="dx-field-label">Condition</div>
					<div class="dx-field-value">
						<dx-select-box #conditionSelectBoxRef [dataSource]="listOfBonusRateConditions"
							[(value)]="ruleTabModel.condition" displayExpr="text" valueExpr="value"
							(onValueChanged)="conditionChange(ruleTabModel.condition)" customItemCreateEvent="blur"
							placeholder="Select Condition">
						</dx-select-box>
					</div>
				</div>
			</div>
			<div class="cols3">
				<div class="dx-field" [ngClass]="{inputDisable: isViewBonusRateCriterion}">
					<div for="Criterion" class="dx-field-label">
						Criterion {{ruleTabModel.condition ==='SKUsShippedInSettlementPeriod' ? '(Units)' :
						globalCurrencySymbol}}
					</div>
					<div class="dx-field-value">
						<dx-text-box [(value)]="ruleTabModel.criterion" mode="text" [showClearButton]="true"
							[disabled]="isViewBonusRateCriterion">
						</dx-text-box>
					</div>
				</div>
			</div>
			<div class="cols2 cols2updatebtn">
				<div class="dx-field">
					<div class="dx-field-label">Rate %</div>
					<div class="dx-field-value">
						<!-- <dx-number-box [(value)]="ruleTabModel.ruleRate" valueChangeEvent="keyup"
							(onKeyDown)="doBuyingGroupPercentageKeyDown($event)" [min]="0" [max]="99" maxLength="2"
							[showClearButton]="true" [showSpinButtons]="true">
						</dx-number-box> -->
						<dx-text-box [(value)]="ruleTabModel.ruleRate" [showClearButton]="true">
						</dx-text-box>
					</div>
				</div>
			</div>

		</div>
		<!-- #endregion -->
		<div class="cols1 txtRight cols1updatebtn">
			<div class="dx-field">
				<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn button center" type="normal"
					stylingMode="contained" [useSubmitBehavior]="false" icon="add" (onClick)="addRuleBonusItem()"
					[text]="btnRulesAdd">
				</dx-button>
			</div>
		</div>

	</div>
</div>
<div class="tableOut mrgT10 tableScroll">
	<div id="rules-message" style="color:red"></div>
	<dx-data-grid #rulesListRulesTabDataGridRef id="gridContainer" [dataSource]="ruleTabList"
		[allowColumnResizing]="true" [remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true"
		keyExpr="guid" class="hideFilterPanelInx" [width]="'100%'" [height]="350">

		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-filter-panel [visible]="false"></dxo-filter-panel>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-scrolling mode="standard" [scrollByThumb]="true" [showScrollbar]="'always'"
			[useNative]="false"></dxo-scrolling>

		<dxi-column caption="Type" dataField="ruleBonusType" dataType="string" [allowEditing]="false"
			[allowHeaderFiltering]="false" cellTemplate="cellTemplateRuleBonusType">
			<div *dxTemplate="let element of 'cellTemplateRuleBonusType'">
				<span [ngSwitch]="element.data.ruleBonusType">
					<span *ngSwitchCase="'inclusionExclusionRule'">Inclusion/Exclusion Rule</span>
					<span *ngSwitchCase="'rateRule'">Rate Rule</span>
					<span *ngSwitchCase="'bonusAmount'">Bonus Amount</span>
					<span *ngSwitchCase="'bonusRate'">Bonus Rate</span>
					<span *ngSwitchDefault>{{element.data.ruleBonusType}}</span>
				</span>
			</div>
		</dxi-column>

		<dxi-column caption="Condition" dataField="condition" dataType="string" [allowEditing]="false"
			[allowHeaderFiltering]="false" cellTemplate="cellTemplateCondition">
			<div *dxTemplate="let element of 'cellTemplateCondition'">
				<span [ngSwitch]="element.data.condition">
					<span *ngSwitchCase="'include'">Include in Calculation</span>
					<span *ngSwitchCase="'exclude'">Exclude in Calculation</span>
					<span *ngSwitchCase="'excludeLateInvoice'">Exclude Late Invoice</span>
					<span *ngSwitchCase="'breakUnitsOfMeasure'">Break Units of Measure</span>
					<span *ngSwitchCase="'itemsLessThan'">No. Items Less Than</span>
					<span *ngSwitchCase="'itemsGreaterThan'">No. Items Greater Than</span>
					<span *ngSwitchCase="'miscellaneousDeduction'">Miscellaneous Deduction</span>
					<span *ngSwitchCase="'miscellaneousDeductionInventory'">Miscellaneous Deduction -
						Inventory Item</span>
					<span *ngSwitchCase="'miscellaneousDeductionAllItem'">Miscellaneous Deduction -
						All Item</span>
					<span *ngSwitchCase="'miscellaneousDeductionFixedCost'">Miscellaneous Deduction - Fixed Cost</span>
					<span *ngSwitchCase="'miscellaneousDeductionItemPercent'">Miscellaneous Deduction - Item Percent</span>
					<span *ngSwitchCase="'newAccount'">New Account</span>
					<span *ngSwitchCase="'perActiveCustomer'">Per Active Customer</span>
					<span *ngSwitchCase="'overListRate'">Over List Rate</span>
					<span *ngSwitchCase="'totalPerSettlementPeriodGreaterThan'">Total Per Settlement Period
						Greater Than</span>
					<span *ngSwitchCase="'newAccountWithMonthlyOrdersGreaterThan'">New Account With Monthly Orders Greater
						Than</span>
					<span *ngSwitchCase="'newAccountWithMonthlyOrders'">New Account With Monthly Orders
						Greater Than</span>
					<span *ngSwitchCase="'grossProfitPerSettlementPeriod'">Gross Profit Per Settlement Period
						Greater Than</span>
					<span *ngSwitchCase="'SKUsShippedInSettlementPeriod'">No. SKUs Shipped In Settlement
						Period Greater Than</span>
					<span *ngSwitchDefault>{{element.data.condition}}</span>
				</span>
			</div>
		</dxi-column>

		<dxi-column caption="Criterion" dataField="criterion" dataType="string" [allowEditing]="false"
			[allowHeaderFiltering]="false" cellTemplate="cellTemplateCriterion">
			<div *dxTemplate="let element of 'cellTemplateCriterion'">
				<!-- <span
					*ngIf="element.data.condition !== 'miscellaneousDeductionInventory' || element.data.condition !== 'miscellaneousDeductionFixedCost'|| element.data.condition !== 'miscellaneousDeductionItemPercent' || element.data.condition !== 'miscellaneousDeductionAllItem' && (element.data.condition !== 'excludeLateInvoice') && (element.data.condition !== 'excludeLateInvoice')">
					{{element.data.criterion || '' | filterFromList :listOfMasterCriterions :'value':'text'}}
				</span>
				<span
					*ngIf="element.data.condition === 'miscellaneousDeductionInventory' || element.data.condition === 'miscellaneousDeductionFixedCost' || element.data.condition === 'miscellaneousDeductionItemPercent' || element.data.condition === 'miscellaneousDeductionAllItem'&& (element.data.condition !== 'excludeLateInvoice')">
					{{element.data.criterion ? element.data.criterion.split('@$$@')[1] : element.data.criterion}}
				</span>
				<span *ngIf="element.data.condition === 'excludeLateInvoice'">
					{{element.data.criterion || '' }}
				</span> -->
				<span>{{setCriterionValForGrid(element.data)}}</span>
			</div>
		</dxi-column>

		<dxi-column caption="Rate" dataField="ruleRate" [allowEditing]="false" [allowHeaderFiltering]="false"
			cellTemplate="cellTemplateRuleRate" dataType="string">
			<div *dxTemplate="let element of 'cellTemplateRuleRate'">
				<!-- {{element.data.ruleRate == 'N/A' ? element.data.ruleRate
				:element.data.ruleBonusType == 'bonusAmount' || element.data.condition === 'miscellaneousDeductionFixedCost'
				?
				globalCurrencySymbol + element.data.ruleRate : element.data.ruleRate + '%'}} -->
				<div *ngIf="element.data.ruleRate == 'N/A';then if_content else else_content">
				</div>
				<ng-template #if_content>{{element.data.ruleRate || ''}}</ng-template>
				<ng-template #else_content>
					<span
						*ngIf="element.data.ruleBonusType === 'bonusAmount' || element.data.condition === 'miscellaneousDeductionFixedCost'">{{element.data.ruleRate
						|| 0 | customCurrencyPipe:customCurrencyOption }}</span>
					<span
						*ngIf="element.data.ruleBonusType !== 'bonusAmount' && element.data.condition !== 'miscellaneousDeductionFixedCost'">{{element.data.ruleRate
						? (element.data.ruleRate || 0 | customNumberNoRoundingPipe) + '%' : ''}}</span>
				</ng-template>
			</div>
		</dxi-column>

		<dxi-column dataField="guid" dataType="string" *ngxPermissionsOnly="isCurrentTabPermission"
			[visible]="isEnabledRulesDatagrid" caption="Action" cellTemplate="cellTemplateRuleAction"
			[allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false" alignment="center"
			[allowResizing]="false" [minWidth]="150" [width]="150" [allowExporting]="false">
			<div *dxTemplate="let element of 'cellTemplateRuleAction'" class="center">
				<span class="center txtCenter actBtncenter actBtn">
					<a (click)="doEditRuleBonusListItem(element.rowIndex,element.data);">
						<span class="icon-editdxtable"></span>
					</a>
					<a (click)="doRemoveRuleBonusListItem(element.rowIndex);">
						<span class="icon-deletedxtable"></span>
					</a>
				</span>
			</div>
		</dxi-column>

	</dx-data-grid>
</div>