import { OnInit, Component } from "@angular/core";

@Component({
	selector: 'app-ap-dashboard',
	templateUrl: './ap-dashboard.component.html',
	styleUrls: ['./ap-dashboard.component.css']
})

export class ApDashboardComponent implements OnInit {

	constructor() {

	}
	ngOnInit(): void {
	}
}
