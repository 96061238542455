import { MSG_CONFIRMED_MESSAGE, CONFIRMED_DELETE_TITLE } from './../../constants-enums/constants';
import { Injectable, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationDialogService {
	confirmedDialogConfig: MatDialogConfig = new MatDialogConfig();
	constructor(public matDialog: MatDialog) {
		this.defaultConfig();
	}

	public setDialogConfig(dialogData?: any, dialogFullConfig?: MatDialogConfig) {
		if (dialogData && Object.keys(dialogData).length > 0) {
			this.confirmedDialogConfig.data = dialogData;
		}
		if (dialogFullConfig && Object.keys(dialogFullConfig).length > 0) {
			this.confirmedDialogConfig = dialogFullConfig;
		}
	}

	public confirmDialog() {
		const dialogRef: MatDialogRef<any> = this.matDialog.open(ConfirmationDialogComponent, this.confirmedDialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.defaultConfig();
		});
		return dialogRef;
	}

	private defaultConfig() {
		this.confirmedDialogConfig.hasBackdrop = true;
		this.confirmedDialogConfig.disableClose = true;
		this.confirmedDialogConfig.data = {
			popupTitle: CONFIRMED_DELETE_TITLE,
			popupMessage: MSG_CONFIRMED_MESSAGE,
			popupOkBtnText: 'Ok',
			popupCancelBtnText: 'Cancel',
			popupInfoBoxClass: ''
		};
	}
}
