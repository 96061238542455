<div id="rebatecontract" class="rightBar">
	<div class="rightBlock">
		<div class="tabbing cf" *ngIf="editRebatesGuid">
			<ul>
				<li (click)="clickMainTab('settlement_period')" [ngClass]="{active: currentMainTab == 'settlement_period'}">
					<a *ngIf="isShowBlockSettlementPeriods">Settlement Report</a>
					<a *ngIf="!isShowBlockSettlementPeriods">Credit Memo Report</a>
				</li>
				<li (click)="clickMainTab('rebates_details')" [ngClass]="{active: currentMainTab == 'rebates_details'}">
					<a>Rebate Details</a>
				</li>
			</ul>
		</div>

		<!--Rebates details content -->
		<div *ngIf="currentMainTab === 'rebates_details'">
			<div class="titleRow">
				<!-- <h1> {{editRebatesGuid ? 'Update' : 'Add New'}} Rebate Agreement</h1> -->
			</div>
			<div class="contentRow cf">
				<div class="borderBox cf padB0">
					<div *ngIf="isShowMultipleBtns" class="rebateStatusTab">
						<div [ngSwitch]="rebatesDetails.status">
							<ul *ngSwitchCase="RebateStatusValEnum.DRAFT">
								<li [ngClass]="{currentStat:rebatesDetails.status===RebateStatusValEnum.DRAFT}">
									<span>Draft</span>
								</li>
								<li class="disabledStat">
									<span>Signature Requested</span>
								</li>
								<li class="disabledStat">
									<span>Signed</span>
								</li>
								<li class="disabledStat">
									<span>Active</span>
								</li>
							</ul>
							<ul *ngSwitchCase="RebateStatusValEnum.SIGNATURE_REQUESTED">
								<li>
									<span>Draft</span>
								</li>
								<li [ngClass]="{currentStat:rebatesDetails.status===RebateStatusValEnum.SIGNATURE_REQUESTED}">
									<span>Signature Requested</span>
								</li>
								<li class="disabledStat">
									<span>Signed</span>
								</li>
								<li class="disabledStat">
									<span>Active</span>
								</li>
							</ul>
							<ul *ngSwitchCase="RebateStatusValEnum.SIGNED">
								<li>
									<span>Draft</span>
								</li>
								<li>
									<span>Signature Requested</span>
								</li>
								<li [ngClass]="{currentStat:rebatesDetails.status===RebateStatusValEnum.SIGNED}" class="">
									<span>Signed</span>
								</li>
								<li class="disabledStat">
									<span>Active</span>
								</li>
							</ul>
							<ul *ngSwitchCase="RebateStatusValEnum.ACTIVE">
								<li>
									<span>Draft</span>
								</li>
								<li>
									<span>Signature Requested</span>
								</li>
								<li>
									<span>Signed</span>
								</li>
								<li [ngClass]="{currentStat:rebatesDetails.status===RebateStatusValEnum.ACTIVE}" class="">
									<span>Active</span>
								</li>
							</ul>
							<ul *ngSwitchCase="RebateStatusValEnum.EXPIRED">
								<li>
									<span>Draft</span>
								</li>
								<li>
									<span>Signature Requested</span>
								</li>
								<li>
									<span>Signed</span>
								</li>
								<li [ngClass]="{currentStat:rebatesDetails.status===RebateStatusValEnum.EXPIRED}" class="">
									<span>Expired</span>
								</li>
							</ul>
							<ul *ngSwitchCase="RebateStatusValEnum.INACTIVE">
								<li [ngClass]="{currentStat:rebatesDetails.status===RebateStatusValEnum.INACTIVE}">
									<span>Inactive</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="formControls editAgreeDetail">
						<div class="row">
							<div [ngClass]="{'cols6':enable_other_currencies,'cols8':!enable_other_currencies}">
								<div class="dx-field mrgT10 mrgB10">
									<div class="dx-field-label">Contract Name</div>
									<div class="dx-field-value">
										<dx-text-box mode="text" [(value)]="rebatesDetails.contractName"
											[disabled]="isDisablePermission || !isEnabledContractName" [showClearButton]="true">
										</dx-text-box>
									</div>
								</div>
							</div>
							<div class="cols2" *ngIf="enable_other_currencies">
								<div class="dx-field mrgT10">
									<div class="dx-field-label">Currency</div>
									<div class="dx-field-value" style="width: 100%;">
										<dx-lookup [items]="listOfOtherCurrency" [displayExpr]="displayExprForCurrency"
											[value]="rebatesDetails.currency_code" valueExpr="currency_code"
											[searchEnabled]="false" [searchPlaceholder]="''" [applyValueMode]="'instantly'"
											[cleanSearchOnOpening]="true" fieldTemplate="field" [showCancelButton]="false"
											itemTemplate="listItem" (onContentReady)="currencyLookUpContentReady($event)"
											(onValueChanged)="doSelectionChangedForCurrency($event)" [wrapItemText]="true"
											[disabled]="isDisablePermission || (!isAddMode && rebatesDetails.currency_code)"
											[elementAttr]="{ 'class': 'currencyDropdownIx' }">
											<dxo-drop-down-options title="Select Currency" [hideOnOutsideClick]="true"
												[height]="310" [maxHeight]="290" [showTitle]="false"></dxo-drop-down-options>
											<div *dxTemplate="let currency of 'field'">
												<div *ngIf="currency" class="custom-field d-flex">
													<span class="currency-flag mrgL10 mrgT3" [ngClass]="currency.icon"></span>
													<div class="flex-grow-1">
														<p class="mrgB0">{{ currency.currency_code}} -
															{{currency.erp_currency_name}}
															<span class="currencySymbol alignRight"
																[innerHtml]="currency.currency_symbol"></span>
														</p>
													</div>
												</div>
											</div>
											<div *dxTemplate="let currency of 'listItem'">
												<div class="custom-item d-flex currencyDropdownCustom-item">
													<span class="currency-flag mrgT10" [ngClass]="currency.icon"></span>
													<div class="flex-grow-1">
														<h5 class="mrgB0" [innerHtml]="currency.currency_code"></h5>
														<p class="mrgB0" [innerHtml]="currency.erp_currency_name"></p>
													</div>
													<span class="currencySymbol mrgT10"
														[innerHTML]="currency.currency_symbol"></span>
												</div>
											</div>
										</dx-lookup>
									</div>
								</div>
							</div>
							<div class="cols2 datePicker">
								<div class="dx-field mrgT10 mrgB10"
									[ngClass]="{'inputDisable':isDisablePermission || !isEnabledStartDate}">
									<div class="dx-field-label">Start Date</div>
									<div class="dx-field-value">
										<dx-date-box [(value)]="rebatesDetails.startDate"
											(onValueChanged)="doSetSettlementStartDate($event)" valueChangeEvent="change"
											type="date" displayFormat="yyyy-MM-dd"
											[disabled]="isDisablePermission || !isEnabledStartDate">
										</dx-date-box>
									</div>
								</div>
							</div>
							<div class="cols2 datePicker">
								<div class="dx-field mrgT10 mrgB10">
									<div class="dx-field-label">End Date</div>
									<div class="dx-field-value">
										<dx-date-box [(value)]="rebatesDetails.endDate"
											(onValueChanged)="doCompareTwoDates($event)" valueChangeEvent="change" type="date"
											displayFormat="yyyy-MM-dd" [disabled]="isDisablePermission|| !isEnabledEndDate">
										</dx-date-box>
									</div>
								</div>
							</div>

							<div class="cols12 colsP0">
								<div class="cols6">
									<div class="dx -field labelRadio mrgT5">
										<div class="dx-field-label">Contract Type:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfRebateTypes" displayExpr="text" valueExpr="value"
												(onValueChanged)="doChangeRebateForRadioBtn($event)" valueChangeEvent="blur"
												[value]="rebatesDetails.individualOrGroup"
												[disabled]="isDisablePermission || isDisabledFields" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
								<div class="cols3"
									*ngIf="rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive' || rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential'">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Rebate Type:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfRebateSubTypes" displayExpr="text"
												valueExpr="value" (onValueChanged)="doChangeRebateTypeRadioBtn($event)"
												valueChangeEvent="blur" [(value)]="rebatesDetails.rebateType"
												[disabled]="isDisablePermission || isDisabledFields" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>

								<div class="cols3" *ngIf="rebatesDetails.individualOrGroup === 'growth_incentive'">
									<div class="dx-field mrgT20">
										<div class="dx-field-label">Comparison Year</div>
										<div class="dx-field-value">
											<dx-select-box [dataSource]="listOfComparisonYear" placeholder="Select Comparison Year"
												displayExpr="text" valueExpr="value" [(value)]="rebatesDetails.comparison_year"
												[disabled]="isDisablePermission" [showClearButton]="true">
											</dx-select-box>
										</div>
									</div>
								</div>

								<div class="cols3"
									*ngIf="rebatesDetails.individualOrGroup === 'buyinggroup' && (rebatesDetails.paymentType === 'split' || rebatesDetails.paymentType === 'buyinggroup')">
									<div class="dx-field mrgT20">
										<div class="dx-field-label">GL Account Number</div>
										<div class="dx-field-value">
											<dx-text-box mode="text" [(value)]="rebatesDetails.gl_account_number"
												[disabled]="isDisablePermission" [showClearButton]="true">
											</dx-text-box>
										</div>
									</div>
								</div>

								<div *ngIf="isShowContractLifecycleManagementChkBox" class="cols2">
									<div class="dx-field dx-lablenone mrgT10">
										<div class="dx-field-label">&nbsp;</div>
										<div class="dx-field-value">
											<dx-check-box [(value)]="rebatesDetails.is_contract_lifecycle_management"
												[disabled]="isDisabledContractLifeCycleManagementChkbox"
												(onValueChanged)="doChangeContractLifeCycleMgmt($event)"
												text="Enabled Contract Lifecycle Management" [width]="'100%'"
												id="is_contract_lifecycle_management">
											</dx-check-box>
										</div>
									</div>
								</div>
							</div>

							<div class="cols12 colsP0">
								<div class="cols3">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Customer/Item Match:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfCustomerItemMatch" displayExpr="text"
												valueExpr="value" valueChangeEvent="blur"
												[(value)]="rebatesDetails.customer_item_match"
												[disabled]="isDisablePermission || isDisabledCustomerItemMatch" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>

								<div class="cols3">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Tier Type:</div>
										<div class="dx-field-value" style="width:100%">
											<dx-radio-group [dataSource]="listOfTiersType" displayExpr="text" valueExpr="value"
												valueChangeEvent="blur" (onValueChanged)="doTiersTypeChanged($event)"
												[value]="rebatesDetails.tiers_type"
												[disabled]="isDisablePermission || isDisabledTierType" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>

								<div class="cols3" *ngIf="rebatesDetails.individualOrGroup==='buyinggroup'">
									<div class=" dx-field labelRadio mrgT5">
										<div class="dx-field-label">&nbsp;</div>
										<div class="dx-field-value" style="width:100%">
											<dx-radio-group [dataSource]="listOfMemberModes" valueChangeEvent="blur"
												(onValueChanged)="doValueChangedMemberModes($event)" displayExpr="text"
												valueExpr="value" [value]="rebatesDetails.member_mode"
												[disabled]="isDisablePermission" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>

								<div class="cols3 distributionChannel mrgT20" *ngIf="isEnableDistributionChannels">
									<app-distribution-channels-dx-drop-down-box
										[distributionChannelsModel]="distributionChannelsModel"></app-distribution-channels-dx-drop-down-box>
								</div>

							</div>

							<div class="cols12 colsP0">
								<div class="cols12"
									*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive' || rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType == 'sales'">
									<div class="padL0 padR0"
										[ngClass]="{'cols12':!isShowContactEmployeeDrpDwn && !isShowPricelistDrpDwn,'cols6':isShowContactEmployeeDrpDwn || isShowPricelistDrpDwn,'cols4':isShowPricelistDrpDwn && isShowDifferentialPricelistDrpDwn}">
										<div class="dx-field mrgT15 mrgB10"
											[ngClass]="{'inputDisable':isDisablePermission || isDisabledCustomerName}">
											<div class="dx-field-label">Customer Name</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-drop-down-box #refCustomersLstDrpDwn [dataSource]="customersLst"
													[disabled]="isDisablePermission || isDisabledCustomerName"
													[(value)]="aSelKeysCustomers" [(opened)]="isCustomerGridBoxOpened"
													[displayExpr]="doDisplayExprCustomerName" valueExpr="code"
													[deferRendering]="true" placeholder="Select Customer..." [showClearButton]="true"
													(onOptionChanged)="doGridBoxOptionChanged($event)"
													(onValueChanged)="doChangeCustomers($event)"
													(onOpened)="doOpenedDropdownCompany($event)" class="dx-ix-dropdownbox">
													<div *dxTemplate="let data of 'content'">
														<dx-data-grid #listOfCrmDataGrid [dataSource]="customersLst"
															[selection]="{ mode: 'single'}" [hoverStateEnabled]="true"
															[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }"
															[height]="345" [filterSyncEnabled]="true"
															[filterValue]="crmDataFilterValue" [(selectedRowKeys)]="aSelKeysCustomers"
															[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
															(onSelectionChanged)="doSelectedChangedCustomers($event)">
															<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																[scrollByThumb]="true" [showScrollbar]="'always'"
																[useNative]="false"></dxo-scrolling>
															<dxo-selection [allowSelectAll]="false"></dxo-selection>

															<dxi-column [visible]="isShowCodeColumn && !isHumanReadableAccountCode"
																dataField="code" dataType="string" caption="Customer ID"
																cellTemplate="cellTemplateCode" [allowHeaderFiltering]="false">
																<div *dxTemplate="let element of 'cellTemplateCode'">
																	<span class="pointerMark">{{element.data.code}}</span>
																</div>
															</dxi-column>
															<dxi-column [visible]="isShowCodeColumn && isHumanReadableAccountCode"
																dataField="human_readable_account_code" dataType="string"
																caption="Customer ID"
																cellTemplate="cellTemplatehumanreadableaccountcode"
																[allowHeaderFiltering]="false">
																<div
																	*dxTemplate="let element of 'cellTemplatehumanreadableaccountcode'">
																	<span
																		class="pointerMark">{{element.data.human_readable_account_code}}</span>
																</div>
															</dxi-column>

															<dxi-column dataField="name" dataType="string" sortOrder="asc"
																caption="Customer Name" cellTemplate="cellTemplateName"
																[allowHeaderFiltering]="false">
																<div *dxTemplate="let element of 'cellTemplateName'">
																	<span class="highlight pointerMark">{{element.data.name}}</span>
																</div>
															</dxi-column>
															<dxi-column dataField="crm_type" dataType="string" [visible]="false"
																caption="Type" cellTemplate="cellTemplateCrmType"
																[allowHeaderFiltering]="true" [allowFiltering]="false">
																<dxo-header-filter
																	[dataSource]="[{value:NameFromServerKeyEnum.CLIENT, text:'Customer'}]">
																</dxo-header-filter>
																<div *dxTemplate="let element of 'cellTemplateCrmType'">
																	Customer
																</div>
															</dxi-column>
														</dx-data-grid>
													</div>
												</dx-drop-down-box>
											</div>
										</div>
									</div>
									<div *ngIf="isShowContactEmployeeDrpDwn" class="cols6">
										<div class="dx-field mrgT15"
											[ngClass]="{'inputDisable':isDisablePermission || isDisabledContactPerson}">
											<div class="dx-field-label">Contact Person</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-drop-down-box #refCustomersLstDrpDwn [dataSource]="contactEmployeesLst"
													[disabled]="isDisablePermission || isDisabledContactPerson"
													[(value)]="aSelKeysContactEmployees" [(opened)]="isOpenedContactEmployeeDrpDwn"
													[displayExpr]="doDisplayExprContactPersonName" valueExpr="guid"
													placeholder="Select Contact Person..." [showClearButton]="true"
													(onOptionChanged)="doOptionChangedContactEmployeeDrpDwn($event)"
													(onValueChanged)="doValChangeContactEmployeeDrpDwn($event)"
													(onOpened)="doOpenedContactEmployeeDrpDwn($event)" class="dx-ix-dropdownbox">
													<div *dxTemplate="let data of 'content'">
														<dx-data-grid #listOfContactEmployeeDataGrid
															[dataSource]="contactEmployeesLst" [selection]="{ mode: 'single'}"
															[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
															[filterRow]="{ visible: true }" [height]="345" keyExpr="guid"
															[(selectedRowKeys)]="aSelKeysContactEmployees" [remoteOperations]="false"
															[wordWrapEnabled]="true" [showBorders]="true"
															(onSelectionChanged)="doSelectedChangedContactEmployees($event)">
															<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																[scrollByThumb]="true" [showScrollbar]="'always'"
																[useNative]="false"></dxo-scrolling>
															<dxo-selection [allowSelectAll]="false"></dxo-selection>

															<dxi-column dataField="name" dataType="string" sortOrder="asc"
																caption="Contact Person" cellTemplate="cellTemplateContactPersonName"
																[allowHeaderFiltering]="false">
																<div *dxTemplate="let element of 'cellTemplateContactPersonName'">
																	<span class="highlight pointerMark">{{element.data.name}}</span>
																</div>
															</dxi-column>
															<dxi-column dataField="e_mail" dataType="string" caption="Email"
																cellTemplate="cellTemplateEmail" [allowHeaderFiltering]="false">
																<div *dxTemplate="let element of 'cellTemplateEmail'">
																	<span class="pointerMark">{{element.data.e_mail || ''}}</span>
																</div>
															</dxi-column>
														</dx-data-grid>
													</div>
												</dx-drop-down-box>
											</div>
										</div>
									</div>

									<div *ngIf="isShowPricelistDrpDwn" class=""
										[ngClass]="{'cols4':isShowPricelistDrpDwn && isShowDifferentialPricelistDrpDwn,'cols6': isShowPricelistDrpDwn && !isShowDifferentialPricelistDrpDwn}">
										<div class="dx-field mrgT15"
											[ngClass]="{'inputDisable':isDisablePermission || isDisabledPricelistDrpDwn}">
											<div class="dx-field-label">Price List</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-drop-down-box [dataSource]="listOfPriceLists"
													[displayExpr]="doDisplayExprPricelistName" [(value)]="aSelKeysPricelistNo"
													[(opened)]="isPricelistGridBoxOpened" valueExpr="pricelistno"
													placeholder="Select Pricelist..." [showClearButton]="true"
													(onOptionChanged)="doPriceListOptionChangedDrpDwn($event)"
													(onClosed)="doPricelistClosedDrpDwn($event)" [deferRendering]="true"
													class="dx-ix-dropdownbox">
													<div *dxTemplate="let data of 'content'">
														<dx-data-grid #refPricelistDrpDwn [dataSource]="listOfPriceLists"
															(onSelectionChanged)="doPicelistSelectedChangedDg($event)"
															[(selectedRowKeys)]="aSelKeysPricelistNo" [hoverStateEnabled]="true"
															[height]="345" [remoteOperations]="true" [filterSyncEnabled]="true"
															[filterValue]="pricelistFilterValue" [wordWrapEnabled]="true"
															[showBorders]="true">
															<dxo-paging [enabled]="true" [pageSize]="100"></dxo-paging>
															<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																[scrollByThumb]="true" [showScrollbar]="'always'"
																[useNative]="false"></dxo-scrolling>
															<dxo-selection mode="single" [allowSelectAll]="false"></dxo-selection>
															<dxo-header-filter [visible]="false"></dxo-header-filter>
															<dxo-filter-row [visible]="true"></dxo-filter-row>

															<dxi-column dataField="pricelistno" dataType="string"
																caption="Price List No." cellTemplate="cellTemplatePricelistno"
																[allowFiltering]="true" [visible]="isShowPricelistCodeColumn">
																<div *dxTemplate="let element of 'cellTemplatePricelistno'">
																	{{element.data.pricelistno || ''}}
																</div>
															</dxi-column>
															<dxi-column dataField="pricelistname" dataType="string" sortOrder="asc"
																caption="Price List Name" cellTemplate="cellTemplateName"
																[allowHeaderFiltering]="false" [allowFiltering]="true">
																<div *dxTemplate="let element of 'cellTemplateName'">
																	<span
																		class="highlight pointerMark">{{element.data.pricelistname}}</span>
																</div>
															</dxi-column>
														</dx-data-grid>
													</div>
												</dx-drop-down-box>
											</div>
										</div>
									</div>

									<div *ngIf="isShowDifferentialPricelistDrpDwn" class="cols4">
										<div class="dx-field mrgT15"
											[ngClass]="{'inputDisable':isDisablePermission || isDisabledPricelistDrpDwn}">
											<div class="dx-field-label">Differential Price List</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-drop-down-box [dataSource]="listOfPriceLists"
													[displayExpr]="doDisplayExprPricelistName"
													[(value)]="aSelKeysDifferentialPricelistNo"
													[(opened)]="isDifferentialPricelistGridBoxOpened" valueExpr="pricelistno"
													placeholder="Select Differential Pricelist..." [showClearButton]="true"
													(onOptionChanged)="doDifferentialPriceListOptionChangedDrpDwn($event)"
													(onClosed)="doDifferentialPricelistClosedDrpDwn($event)" [deferRendering]="true"
													class="dx-ix-dropdownbox">
													<div *dxTemplate="let data of 'content'">
														<dx-data-grid #refDifferentialPricelistDrpDwn [dataSource]="listOfPriceLists"
															(onSelectionChanged)="doDifferentialPicelistSelectedChangedDg($event)"
															[(selectedRowKeys)]="aSelKeysDifferentialPricelistNo"
															[hoverStateEnabled]="true" [height]="345" [remoteOperations]="true"
															[filterSyncEnabled]="true" [filterValue]="differentialPricelistFilterValue"
															[wordWrapEnabled]="true" [showBorders]="true">
															<dxo-paging [enabled]="true" [pageSize]="100"></dxo-paging>
															<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																[scrollByThumb]="true" [showScrollbar]="'always'"
																[useNative]="false"></dxo-scrolling>
															<dxo-selection mode="single" [allowSelectAll]="false"></dxo-selection>
															<dxo-header-filter [visible]="false"></dxo-header-filter>
															<dxo-filter-row [visible]="true"></dxo-filter-row>

															<dxi-column dataField="pricelistno" dataType="string"
																caption="Price List No." cellTemplate="cellTemplatePricelistno"
																[allowFiltering]="true" [visible]="isShowPricelistCodeColumn">
																<div *dxTemplate="let element of 'cellTemplatePricelistno'">
																	{{element.data.pricelistno || ''}}
																</div>
															</dxi-column>
															<dxi-column dataField="pricelistname" dataType="string" sortOrder="asc"
																caption="Price List Name" cellTemplate="cellTemplateName"
																[allowHeaderFiltering]="false" [allowFiltering]="true">
																<div *dxTemplate="let element of 'cellTemplateName'">
																	<span
																		class="highlight pointerMark">{{element.data.pricelistname}}</span>
																</div>
															</dxi-column>
														</dx-data-grid>
													</div>
												</dx-drop-down-box>
											</div>
										</div>
									</div>
								</div>
								<div class="cols8" *ngIf="rebatesDetails.individualOrGroup == 'buyinggroup'">
									<div class="dx-field mrgT15 mrgB10"
										[ngClass]="{'inputDisable':isDisablePermission || isDisabledBuyingGroupName}">
										<div class="dx-field-label">Buying Group Name</div>
										<div class="dx-field-value" style="width: 100%;">
											<dx-drop-down-box #refBuyingGroupsLstDrpDwn [dataSource]="buyingGroupsLst"
												[disabled]="isDisablePermission || isDisabledBuyingGroupName"
												[(value)]="aSelKeysBuyingGroup" [(opened)]="isBuyingGroupGridBoxOpened"
												[displayExpr]="doDisplayExprBuyinggroupName" valueExpr="code"
												[deferRendering]="true" placeholder="Select Buying Group..."
												[showClearButton]="true"
												(onOptionChanged)="doBuyingGroupGridBoxOptionChanged($event)"
												(onValueChanged)="doChangeBuyingGroups($event)"
												(onOpened)="doOpenedDropdownBuyingGroup($event)" class="dx-ix-dropdownbox">
												<div *dxTemplate="let data of 'content'">
													<dx-data-grid #listOfBuyingGroupDataGrid [dataSource]="buyingGroupsLst"
														[selection]="{ mode: 'single'}" [hoverStateEnabled]="true"
														[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }"
														[height]="345" [filterSyncEnabled]="true"
														[filterValue]="buyingGroupDataFilterValue"
														[(selectedRowKeys)]="aSelKeysBuyingGroup" [remoteOperations]="true"
														[wordWrapEnabled]="true" [showBorders]="true"
														(onSelectionChanged)="doSelectedChangedBuyingGroups($event)">
														<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
															[scrollByThumb]="true" [showScrollbar]="'always'"
															[useNative]="false"></dxo-scrolling>
														<dxo-selection [allowSelectAll]="false"></dxo-selection>

														<dxi-column [visible]="isShowCodeColumn" dataField="code" dataType="string"
															caption="Buying Group ID" cellTemplate="cellTemplateCode"
															[allowHeaderFiltering]="false">
															<div *dxTemplate="let element of 'cellTemplateCode'">
																<span class="pointerMark">{{element.data.code}}</span>
															</div>
														</dxi-column>

														<dxi-column dataField="name" dataType="string" sortOrder="asc"
															caption="Buying Group Name" cellTemplate="cellTemplateName"
															[allowHeaderFiltering]="false">
															<div *dxTemplate="let element of 'cellTemplateName'">
																<span class="highlight pointerMark">{{element.data.name}}</span>
															</div>
														</dxi-column>

													</dx-data-grid>
												</div>
											</dx-drop-down-box>
										</div>
									</div>
								</div>
								<div class="cols8"
									*ngIf="(rebatesDetails.individualOrGroup =='individual' || rebatesDetails.individualOrGroup == 'growth_incentive') && rebatesDetails.rebateType == 'purchasing'">
									<div class="dx-field mrgT15 mrgB10"
										[ngClass]="{'inputDisable':isDisablePermission || isDisabledVendorName}">
										<div class="dx-field-label">Vendor Name</div>
										<div class="dx-field-value" style="width: 100%;">
											<dx-drop-down-box #refVendorsLstDrpDwn [dataSource]="vendorsLst"
												[disabled]="isDisablePermission || isDisabledVendorName" [(value)]="aSelKeysVendor"
												[(opened)]="isVendorGridBoxOpened" [displayExpr]="doDisplayExprVendorName"
												valueExpr="code" [deferRendering]="true" placeholder="Select Vendor..."
												[showClearButton]="true" (onOptionChanged)="doVendorGridBoxOptionChanged($event)"
												(onValueChanged)="doChangeVendors($event)"
												(onOpened)="doOpenedDropdownVendor($event)" class="dx-ix-dropdownbox">
												<div *dxTemplate="let data of 'content'">
													<dx-data-grid #listOfVendorDataGrid [dataSource]="vendorsLst"
														[selection]="{ mode: 'single'}" [hoverStateEnabled]="true"
														[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }"
														[height]="345" [filterSyncEnabled]="true"
														[filterValue]="vendorDataFilterValue" [(selectedRowKeys)]="aSelKeysVendor"
														[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
														(onSelectionChanged)="doSelectedChangedVendors($event)">
														<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
															[scrollByThumb]="true" [showScrollbar]="'always'"
															[useNative]="false"></dxo-scrolling>
														<dxo-selection [allowSelectAll]="false"></dxo-selection>

														<dxi-column [visible]="isShowCodeColumn" dataField="code" dataType="string"
															caption="Vendor ID" cellTemplate="cellTemplateCode"
															[allowHeaderFiltering]="false">
															<div *dxTemplate="let element of 'cellTemplateCode'">
																<span class="pointerMark">{{element.data.code}}</span>
															</div>
														</dxi-column>

														<dxi-column dataField="name" dataType="string" sortOrder="asc"
															caption="Vendor Name" cellTemplate="cellTemplateName"
															[allowHeaderFiltering]="false">
															<div *dxTemplate="let element of 'cellTemplateName'">
																<span class="highlight pointerMark">{{element.data.name}}</span>
															</div>
														</dxi-column>

													</dx-data-grid>
												</div>
											</dx-drop-down-box>
										</div>
									</div>
								</div>

							</div>

							<div class="cols12 colsP0">
								<div class="cols3"
									*ngIf="rebatesDetails.settlementPeriod !== settlementPeriodsValEnum.MONTHLY && rebatesDetails.settlementPeriod
								!== settlementPeriodsValEnum.CUSTOM && rebatesDetails.settlementPeriod !== settlementPeriodsValEnum.AUTOPAYMENT">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">Contract Calendar</div>
										<div class="dx-field-value">
											<dx-select-box #contractCalendarSelectBoxRef [dataSource]="listOfContractCalendar"
												[(value)]="rebatesDetails.contractCalendar" displayExpr="text" valueExpr="value"
												(onValueChanged)="doSetSettlementStartDate($event)" [showClearButton]="true"
												customItemCreateEvent="blur" placeholder="Contract Calendar"
												[disabled]="isDisablePermission || !isEnabledSettlemenPeriod">
											</dx-select-box>
										</div>
									</div>
								</div>
								<div class="cols2"
									*ngIf="rebatesDetails.settlementPeriod !== settlementPeriodsValEnum.CUSTOM && rebatesDetails.settlementPeriod !== settlementPeriodsValEnum.AUTOPAYMENT">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label" hint="Start Settlement Period On">Start Settlement Period On
										</div>
										<div class="dx-field-value">
											<dx-number-box [(value)]="rebatesDetails.startSettlementPeriodOn"
												[disabled]="isDisablePermission" [showClearButton]="true"
												(onValueChanged)="doSetSettlementStartDate($event)" maxLength="2" [min]="1"
												[max]="30" [disabled]="isDisablePermission || !isEnabledSettlemenPeriod">
											</dx-number-box>
										</div>
									</div>
								</div>

								<div class="cols7">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Settlement Period:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfSettlementPeriods" displayExpr="text"
												valueExpr="value" (onValueChanged)="calculateSettlementPeriod(rebatesDetails)"
												valueChangeEvent="blur" [(value)]="rebatesDetails.settlementPeriod"
												[disabled]="isDisablePermission || !isEnabledSettlemenPeriod" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
							</div>
							<div class="cols12 colsP0" *ngIf="rebatesDetails.individualOrGroup =='buyinggroup'">
								<div class="cols6">
									<div class="dx-field labelRadio mrgT10">
										<div class="dx-field-label">Payment Type:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfPaymentTypes" displayExpr="text" valueExpr="value"
												(onValueChanged)="calculateSettlementPeriod(rebatesDetails)" valueChangeEvent="blur"
												[(value)]="rebatesDetails.paymentType" [disabled]="isDisablePermission"
												layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>

								<div class="cols3" *ngIf="rebatesDetails.paymentType == 'split'">
									<div class="dx-field mrgT15">
										<div class="dx-field-label ">Buying Group %</div>
										<div class="dx-field-value">
											<dx-number-box [(value)]="rebatesDetails.buyingGroup" valueChangeEvent="keyup"
												(onValueChanged)="doChangeBuyingGroupText($event)"
												(onKeyDown)="doBuyingGroupPercentageKeyDown($event)"
												[disabled]="isDisablePermission" [min]="0" [max]="99" maxLength="2"
												[showClearButton]="true" [showSpinButtons]="true">
											</dx-number-box>
										</div>
									</div>
								</div>
								<div class="cols3" *ngIf="rebatesDetails.paymentType == 'split'">
									<div class="dx-field mrgT15">
										<div class="dx-field-label">Buying Group Member %</div>
										<div class="dx-field-value">
											<dx-number-box [(value)]="rebatesDetails.buyingGroupMember" [disabled]="true" [min]="0"
												[max]="99" maxLength="2" [showClearButton]="true" [showSpinButtons]="true">
											</dx-number-box>
										</div>
									</div>
								</div>
							</div>
							<div class="cols12 colsP0">
								<div class="cols2">
									<div class="dx-field dx-lableset mrgT5">
										<div class="dx-field-label">Tier Mode:</div>
										<div class="dx-field-value" style="width:100%">
											<dx-radio-group [dataSource]="listOfTierModes" displayExpr="text" valueExpr="value"
												[(value)]="rebatesDetails.tier_mode" (onValueChanged)="doChangeTierMode($event)"
												valueChangeEvent="blur" [disabled]="true" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
								<div class="cols2" *ngIf="isShowTierAccrualPeriodField">
									<div class="dx-field mrgT15"
										[ngClass]="{'inputDisable': isDisablePermission || isDisabledFields}">
										<div class="dx-field-label">Tier Accrual Period</div>
										<div class="dx-field-value">
											<dx-select-box #tierAccrualPeriodSelectBoxRef [dataSource]="listOfTierAccrualPeriods"
												[(value)]="rebatesDetails.tier_accrual_period" displayExpr="text" valueExpr="value"
												[showClearButton]="true" placeholder="Tier Accrual Periods"
												[disabled]="isDisablePermission || isDisabledFields">
											</dx-select-box>
										</div>
									</div>
								</div>
								<div class="cols4">
									<div class="dx-field dx-lableset mrgT5">
										<div class="dx-field-label">Tiers Include:</div>
										<div class="dx-field-value" style="width: 100%;">
											<dx-radio-group [dataSource]="listOfTiersInclude" displayExpr="text" valueExpr="value"
												[(value)]="rebatesDetails.tiers_include"
												[disabled]="isDisablePermission || isDisabledTierInclude" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
							</div>

							<div *ngIf="!isShowBlockSettlementPeriods" class="cols12">
								<div class="multiBtnsRow">
									<div class="btnsGroup">
										<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn"
											[ngClass]="{'grayButton': isAddMode || (!isAddMode && !isEnabledGenerateCreditMemosForExistingInvoicesBtn) }"
											id="generateCreditMemosForExistingInvoicesBtn"
											text="Generate Credit Memos For Existing Invoices"
											[disabled]="isAddMode || (!isAddMode && !isEnabledGenerateCreditMemosForExistingInvoicesBtn)"
											(onClick)="doGenerateCreditMemosForExistingInvoices()" type="normal"
											stylingMode="contained" [useSubmitBehavior]="false">
										</dx-button>
									</div>
								</div>
							</div>

							<div
								*ngIf="isShowBlockSettlementPeriods && rebatesDetails.settlementPeriodStart && rebatesDetails.settlementPeriodEnd && !isAllSettlmentClosed"
								class="cols12 colsP0">
								<div class="cols5">
									<div class="remarkBx fullNote ecaRemark">
										<strong>Current Settlement Period :-</strong>
										<span
											*ngIf="rebatesDetails.startDate && rebatesDetails.settlementPeriodStart && rebatesDetails.settlementPeriodStart !=='Invalid date'">
											{{ (rebatesDetails.settlementPeriodStart !== null &&
											rebatesDetails.settlementPeriodStart !== '' && rebatesDetails.settlementPeriodStart
											!==
											undefined) ? (rebatesDetails.settlementPeriodStart | date: 'yyyy-MM-dd') : "" }}
											to
											{{ (rebatesDetails.settlementPeriodEnd !== null &&
											rebatesDetails.settlementPeriodEnd
											!== '' && rebatesDetails.settlementPeriodEnd !== undefined) ?
											(rebatesDetails.settlementPeriodEnd | date: 'yyyy-MM-dd') : "" }}
										</span>
									</div>
								</div>
								<div class="cols7">
									<div class="multiBtnsRow">
										<div *ngIf="!enable_rebate_settlement_approval_workflow" class="btnsGroup">
											<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn"
												[ngClass]="{'grayButton': isAddMode }" id="closeCurrentSettlmentPeriodBtn"
												text="Close Current Settlement Period" [disabled]="isAddMode"
												(onClick)="doCloseCurrentSettlementPeriod(rebatesDetails)" type="normal"
												stylingMode="contained" [visible]="!isAllSettlmentClosed"
												[useSubmitBehavior]="false">
											</dx-button>
										</div>
										<div *ngIf="enable_rebate_settlement_approval_workflow && !isAddMode" class="btnsGroup">
											<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn"
												[ngClass]="{'grayButton': isAddMode }" text="Send Current Settlement For Approval"
												[disabled]="isDisabledSendCurrentSettlementForApprovalBtn"
												(onClick)="doSendCurrentCurrentSettlementForApproval($event)" type="normal"
												stylingMode="contained" [useSubmitBehavior]="false">
											</dx-button>
										</div>
										<div
											*ngIf="isShowMultipleBtns && (rebatesDetails.status===RebateStatusValEnum.SIGNED || rebatesDetails.status===RebateStatusValEnum.ACTIVE)"
											class="btnsGroup">
											<dx-button (onClick)="doDownloadSignedContract()" class="secondaryBtn mrgR10"
												id="downloadSignedContract" text="Download Signed Contract" type="normal"
												stylingMode="contained" [useSubmitBehavior]="false" icon="download">
											</dx-button>
										</div>
									</div>
								</div>

								<div class="cols12 colsP0" *ngIf="!isAddMode">
									<div class="cols12">
										<hr>
									</div>
									<div class="cols3" *ngxPermissionsOnly="isCurrentTabPermission">
										<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn widthauto"
											[ngClass]="{'grayButton': isAddMode}" id="updateCurrentSettlmentPeriodBtn"
											[visible]="!isAllSettlmentClosed" text="Update Current Settlement Period"
											[disabled]="isAddMode" (onClick)="updateSettlementPeriodClick()" type="normal"
											stylingMode="contained" [useSubmitBehavior]="false">
										</dx-button>
									</div>
									<div class="cols9" *ngxPermissionsOnly="isCurrentTabPermission">
										<div class="d-flex">
											<div class="flex-grow-1">
												<div class="ucsData">
													<label class="width100p mrgT0" for="currentSettlementInvoiceTotal">Invoice Total:
														<strong>
															{{rebatesDetails.current_settlement_invoice_total || 0 |
															customCurrencyPipe:customCurrencyOption}}
														</strong>
													</label>
												</div>
											</div>
											<div class="flex-grow-1">
												<div class="ucsData">
													<label class="width100p mrgT0" for="currentSettlementInvoiceTotal">Credit Memo
														Total:
														<strong>
															{{rebatesDetails.current_settlement_creditmemo_total || 0 |
															customCurrencyPipe:customCurrencyOption}}
														</strong>
													</label>
												</div>
											</div>
											<div class="flex-grow-1">
												<div class="ucsData">
													<label class="width100p mrgT0" for="currentSettlementInvoiceTotal">Sales Total:
														<strong>
															{{rebatesDetails.current_settlement_sales_total || 0 |
															customCurrencyPipe:customCurrencyOption}}
														</strong>
													</label>
												</div>
											</div>
											<div class="flex-grow-1">
												<div class="ucsData">
													<label class="width100p mrgT0" for="currentSettlementRebatesTotal">Rebate Total:
														<strong>
															{{rebatesDetails.current_settlement_commission_total || 0 |
															customCurrencyPipe:customCurrencyOption }}
														</strong>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="borderBox cf">
					<div class="tabbing cf">
						<ul>
							<!-- <li (click)="clickAgreementTab('customers')" [ngClass]="{active: currentAgreementTab == 'customers'}"
              *ngIf="rebatesDetails.individualOrGroup =='buyinggroup'">
              <a>Customers</a>
            </li> -->
							<li (click)="clickAgreementTab('members')" [ngClass]="{active: currentAgreementTab == 'members'}"
								*ngIf="rebatesDetails.individualOrGroup =='buyinggroup'">
								<a>Members</a>
							</li>

							<li (click)="clickAgreementTab('itemsOrgroups')"
								[ngClass]="{active: currentAgreementTab == 'itemsOrgroups'}">
								<a
									*ngIf="!enable_classification_for_rebate && !((rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales')">Items</a>
								<a
									*ngIf="!enable_classification_for_rebate && (rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'">End-Users</a>
								<a *ngIf="enable_classification_for_rebate">Classes</a>
							</li>

							<li (click)="clickAgreementTab('rules')" [ngClass]="{active: currentAgreementTab == 'rules'}">
								<a>Rules</a>
							</li>
							<li (click)="clickAgreementTab('terms_and_conditions')"
								[ngClass]="{active: currentAgreementTab == 'terms_and_conditions'}" *ngIf="isShowMultipleBtns">
								<a *ngIf="!isChangeTermsConditionTabTxt">Terms & Conditions</a>
								<a *ngIf="isChangeTermsConditionTabTxt">Contract Text</a>
							</li>
						</ul>
					</div>

					<!--#region Customer content -->

					<div *ngxPermissionsOnly="isCurrentTabPermission">
						<div class="row suctSearch" *ngIf="currentAgreementTab == 'customers'">
							<div class="inputContolscf gridSearch cf cols12 cols4Add">
								<div class="searchBoxIn">
									<div class="feildCol serchBox">
										<input name="" type="text" [(ngModel)]="customerTabModel.name"
											style="text-transform: capitalize;" (click)="searchSlideDown('tblSearchCustomerTab')"
											(keyup)="doSearchSelectCustomerTabOnChange($event)">
										<button>
											<span class="iconSearch sprite"></span>
										</button>
										<div class="inputField">
											<label for="Select Customers" style="text-transform: capitalize;">Select
												Customers</label>
										</div>
									</div>

									<div class="cf">
										<div class="searchResultSection" id="tblSearchCustomerTab">
											<div class="cf">
												<div class="searchResultBox">
													<div class="serachTable tableOut">
														<table class="responsiveTable fixedHeader">

															<thead>
																<tr>
																	<th class="txtLeft" *ngIf="!isQBErpUser">Code</th>
																	<th>Name</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="txtLeft" [attr.colspan]="isQBErpUser ? 1 : 2"
																		style="text-transform: capitalize;background-color: ghostwhite;">
																		<div class="form-group">
																			<input type="checkbox" id="isCustomerSearchTabAllSelected"
																				[(ngModel)]="isCustomerSearchTabAllSelected"
																				(change)="isCustomerSearchTabAllSelectedChange()">
																			<label for="isCustomerSearchTabAllSelected"
																				style="font-weight: bold;">All
																				Customer</label>
																		</div>
																	</td>
																</tr>
																<tr *ngFor="let ct of selectCustomerSearchTabData ;let i = index">
																	<td class="txtLeft" *ngIf="isQBErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				(change)="selectCustomerSearchTabDataListItem($event,ct)"
																				id="{{ct.code || ct.itemcode}}">
																			<label for="{{ct.code || ct.itemcode}}">{{ct.name ||
																				ct.itemname}}</label>
																		</div>
																	</td>
																	<td class="txtLeft" *ngIf="!isQBErpUser">
																		<div class="form-group">
																			<input type="checkbox" [(ngModel)]="ct.selected"
																				(change)="selectCustomerSearchTabDataListItem($event,ct)"
																				id="{{ct.code || ct.itemcode}}">
																			<label for="{{ct.code || ct.itemcode}}">{{ct.code ||
																				ct.itemcode}}</label>
																		</div>
																	</td>
																	<td *ngIf="!isQBErpUser">{{ct.name || ct.itemname}}</td>
																</tr>

															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<a class="button center" (click)="addCustomerAgreementTabDetails()"
									style="text-transform: capitalize;">Add</a>
							</div>

						</div>
					</div>
					<div class="tableOut tableScroll mrgT10 " *ngIf="currentAgreementTab == 'customers'">
						<table class="tableData">
							<tr>
								<th class="center width80">No.</th>
								<th *ngIf="!isAllCustomerSelectedView && !isQBErpUser">Code</th>
								<th>Name</th>
								<th class="center width100" *ngxPermissionsOnly="isCurrentTabPermission">
									Action</th>
							</tr>

							<tr *ngFor="let tl of customerTabList;let i = index">

								<td class="center">{{i+1}}</td>
								<td *ngIf="!isAllCustomerSelectedView && !isQBErpUser">{{tl.code}}</td>
								<td>{{tl.name}}</td>
								<td class="center actBtn" *ngxPermissionsOnly="isCurrentTabPermission">
									<a id="deleteCustomerAgreementDetails_{{i+1}}"
										(click)="removeCustomerAgreementTabDetails(i);$event.stopPropagation();">
										<span class="icon-deletedxtable"></span>
									</a>
									<dx-tooltip target="#deleteCustomerAgreementDetails_{{i+ 1}}" showEvent="dxhoverstart"
										hideEvent="dxhoverend" position="bottom">
										<div *dxTemplate="let data = data of 'content'">
											Delete
										</div>
									</dx-tooltip>
								</td>
							</tr>
						</table>
					</div>

					<!--endregion-->

					<!-- #region Member content -->
					<!-- tableOut tableScroll mrgT10 -->
					<div class="mrgT20 mrgB20" *ngIf="currentAgreementTab == 'members'">
						<div class="row">
							<div *ngIf="rebatesDetails.selectBuyingGroupId" class="cols2 txtLeft mrgB20">
								<a (click)="doShowMembersNotInBuyinggroup()" class="center"
									style="text-transform: capitalize;">List of Members Not In Contracts</a>
							</div>
							<div *ngIf="editRebatesGuid" class="cols3 txtLeft mrgB20">
								<a (click)="doShowMembersWithIndividualContract()" class="center"
									style="text-transform: capitalize;">List of Members With Individual Contracts</a>
							</div>
						</div>

						<dx-data-grid #membersListMemberTabDataGridRef id="gridContainer"
							[dataSource]="dataSourceMemberLstMemberTab" [remoteOperations]="false" [showBorders]="true"
							(onToolbarPreparing)="doToolbarPreparingMemberTab($event)" [columnAutoWidth]="true"
							(onRowRemoved)="doRowRemovedMembersMemberTab($event)" (onSaved)="doSavedMembersMemberTab($event)"
							keyExpr="code" class="hideFilterPanelInx" [height]="450">

							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-filter-panel [visible]="false"></dxo-filter-panel>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxo-scrolling mode="standard" [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-editing mode="row" [allowDeleting]="true" [allowAdding]="false" [useIcons]="true">
							</dxo-editing>
							<dxo-master-detail [enabled]="true" template="inoutDatesDetails"></dxo-master-detail>

							<dxi-column caption="Code" dataType="string" [visible]="!isQBErpUser" dataField="code"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateCode">
								<div *dxTemplate="let element of 'cellTemplateCode'">
									{{element.data.code || ''}}
								</div>
							</dxi-column>

							<dxi-column caption="Member" dataField="name" dataType="string" [allowEditing]="false"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateMemberName">
								<div *dxTemplate="let element of 'cellTemplateMemberName'">
									{{element.data.name || ''}}
								</div>
							</dxi-column>

							<dxi-column dataField="buyinggroup_code" caption="Buying Group ID" dataType="string">
							</dxi-column>
							<dxi-column dataField="buyinggroup_name" caption="Buying Group Name" dataType="string">
							</dxi-column>

							<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
								[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
								[allowExporting]="false">
								<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip"></dxi-button>
								<dxi-button name="delete" cssClass="dx-grid-delete dx-icon-trash menuTip"></dxi-button>
							</dxi-column>

							<div *dxTemplate="let element of 'inoutDatesDetails'">
								<dx-data-grid #inoutDateDetailDataGridContainer [dataSource]="element.data.inout_dates || []"
									[showBorders]="true" keyExpr="guid" [filterSyncEnabled]="true" [showColumnLines]="true"
									[showRowLines]="false" [allowColumnResizing]="false" [columnAutoWidth]="true"
									[width]="'100%'" class="hideFilterPanelInx">
									<dxo-header-filter [visible]="false"></dxo-header-filter>
									<dxo-filter-row [visible]="true"></dxo-filter-row>
									<dxo-filter-panel [visible]="false"></dxo-filter-panel>
									<dxo-sorting mode="single"></dxo-sorting>
									<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxi-column dataField="startdate" caption="Start Date" dataType="date" format="yyyy-MM-dd"
										[allowHeaderFiltering]="false">
									</dxi-column>
									<dxi-column dataField="enddate" caption="End Date" dataType="date" format="yyyy-MM-dd"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateEndDate">
										<div *dxTemplate="let element of 'cellTemplateEndDate'">
											{{element.data.enddate ? element.data.enddate :'-'}}
										</div>
									</dxi-column>
								</dx-data-grid>
							</div>
						</dx-data-grid>
						<div class="cf"></div>
					</div>
					<!--endregion-->

					<!--#region Rules content -->
					<div *ngxPermissionsOnly="isCurrentTabPermission">
						<div *ngIf="currentAgreementTab == 'rules'">
							<div class="row rulescontent" *ngIf="isEnabledRulesDatagrid" style="margin-bottom: 15px">
								<div class="cols3">
									<div class="dx-field">
										<div class="dx-field-label">Rule/Bonus Type</div>
										<div class="dx-field-value">
											<dx-select-box [dataSource]="listOfRuleBonusTypes"
												[(value)]="ruleTabModel.ruleBonusType" displayExpr="text" valueExpr="value"
												(onValueChanged)="ruleBonusTypeChange(ruleTabModel.ruleBonusType)"
												customItemCreateEvent="blur" placeholder="Select Rule/Bonus Type">
											</dx-select-box>
										</div>
									</div>
								</div>

								<!--#region inclusionExclusionRule -->
								<div *ngIf="ruleTabModel.ruleBonusType == 'inclusionExclusionRule'">
									<div class="cols3">
										<div class="dx-field">
											<div class="dx-field-label">Condition</div>
											<div class="dx-field-value">
												<dx-select-box [dataSource]="listOfInclusionExclusionRuleConditions"
													[(value)]="ruleTabModel.condition" displayExpr="text" valueExpr="value"
													placeholder="Select Rule/Bonus Type">
												</dx-select-box>
											</div>
										</div>
									</div>
									<div class=" cols3">
										<div class="dx-field">
											<div class="dx-field-label">Criterion</div>
											<div class="dx-field-value">
												<dx-select-box [dataSource]="listOfInclusionExclusionRuleCriterions"
													[(value)]="ruleTabModel.criterion"
													(onValueChanged)="criterionChange(ruleTabModel.criterion)"
													customItemCreateEvent="blur" displayExpr="text" valueExpr="value"
													placeholder="Select Criterion">
												</dx-select-box>
											</div>
										</div>
									</div>
									<div class="cols2 cols2addbtn">
										<div class="dx-field" [ngClass]="{inputDisable: isViewIERuleRuleRate}">
											<div class="dx-field-label">Rate %</div>
											<div class="dx-field-value">
												<dx-text-box mode="text" [(value)]="ruleTabModel.ruleRate"
													[disabled]="isViewIERuleRuleRate" [showClearButton]="true">
												</dx-text-box>
											</div>
										</div>
									</div>
								</div>
								<!-- #endregion -->

								<!--#region rateRule -->
								<div *ngIf="ruleTabModel.ruleBonusType == 'rateRule'">
									<div class="cols3">
										<div class="dx-field">
											<div class="dx-field-label">Condition</div>
											<div class="dx-field-value">
												<dx-select-box [dataSource]="listOfRateRuleConditions"
													[(value)]="ruleTabModel.condition"
													(onValueChanged)="conditionChange(ruleTabModel.condition)"
													customItemCreateEvent="blur" displayExpr="text" valueExpr="value"
													placeholder="Select Condition">
												</dx-select-box>
											</div>
										</div>
									</div>
									<div class="cols3">
										<div class="dx-field">
											<div *ngIf="ruleTabModel.condition !=='miscellaneousDeduction' &&
												ruleTabModel.condition !=='miscellaneousDeductionInventory'" fo="Criterion" class="dx-field-label">
												Criterion
											</div>
											<div
												*ngIf="ruleTabModel.condition === 'miscellaneousDeduction' || ruleTabModel.condition === 'miscellaneousDeductionInventory'"
												for="Title/Label" class="dx-field-label">
												Title/Label
											</div>
											<div class="dx-field-value">
												<dx-text-box [(value)]="ruleTabModel.criterion" mode="text" [showClearButton]="true"
													[disabled]="isViewRateRuleCriterion">
												</dx-text-box>
											</div>
										</div>
									</div>

									<div class="cols2 cols2addbtn">
										<div class="dx-field">
											<div class="dx-field-label">Rate %</div>
											<div class="dx-field-value">
												<dx-text-box [(value)]="ruleTabModel.ruleRate" [showClearButton]="true">
												</dx-text-box>
											</div>
										</div>
									</div>
								</div>
								<!-- #endregion -->

								<!--#region bonusAmount -->
								<div *ngIf="ruleTabModel.ruleBonusType == 'bonusAmount'">
									<div class="cols3">
										<div class="feildCol">
											<select id="ruleCondition" [(ngModel)]="ruleTabModel.condition"
												(change)="conditionChange(ruleTabModel.condition)">
												<option value="newAccount">New Account</option>
												<option value="perActiveCustomer">Per Active Customer</option>
												<option value="totalPerSettlementPeriodGreaterThan">Total Per Settlement Period
													Greater
													Than
												</option>
												<option value="newAccountWithMonthlyOrders">New Account With Monthly Orders
													Greater
													Than
												</option>
												<!-- <option value="perActiveAccount">Per Active Account</option> -->
											</select>
											<div class="inputField">
												<label for="condition">Condition</label>
											</div>
										</div>
									</div>
									<div class="cols3">
										<div class="dx-field" [ngClass]="{inputDisable: isViewBounsAmountCriterion}">
											<div class="dx-field-label">Criterion $</div>
											<div class="dx-field-value">
												<dx-number-box [(value)]="ruleTabModel.criterion" [showClearButton]="true"
													[showSpinButtons]="true" [disabled]="isViewBounsAmountCriterion">
												</dx-number-box>
											</div>
										</div>
									</div>
									<div class="cols2 cols2addbtn">
										<div class="dx-field">
											<div class="dx-field-label">Rate %</div>
											<div class="dx-field-value">
												<div class="dx-field-value">
													<dx-text-box [(value)]="ruleTabModel.ruleRate" [showClearButton]="true">
													</dx-text-box>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- #endregion -->

								<!--#region bonusRate -->
								<div *ngIf="ruleTabModel.ruleBonusType == 'bonusRate'">
									<div class="cols3">
										<div class="dx-field">
											<div class="dx-field-label">Condition</div>
											<div class="dx-field-value">
												<dx-select-box [dataSource]="listOfBonusRateConditions"
													[(value)]="ruleTabModel.condition" displayExpr="text" valueExpr="value"
													(onValueChanged)="conditionChange(ruleTabModel.condition)"
													customItemCreateEvent="blur" placeholder="Select Condition">
												</dx-select-box>
											</div>
										</div>
									</div>
									<div class="cols3">
										<div class="dx-field" [ngClass]="{inputDisable: isViewBonusRateCriterion}">
											<div for="Criterion" class="dx-field-label">
												Criterion {{ruleTabModel.condition ==='SKUsShippedInSettlementPeriod' ? '(Units)' :
												globalCurrencySymbol}}
											</div>
											<div class="dx-field-value">
												<dx-text-box [(value)]="ruleTabModel.criterion" mode="text" [showClearButton]="true"
													[disabled]="isViewBonusRateCriterion">
												</dx-text-box>
											</div>
										</div>
									</div>
									<div class="cols2 cols2addbtn">
										<div class="dx-field">
											<div class="dx-field-label">Rate %</div>
											<div class="dx-field-value">
												<div class="dx-field-value">
													<dx-text-box [(value)]="ruleTabModel.ruleRate" [showClearButton]="true">
													</dx-text-box>
												</div>
											</div>
										</div>
									</div>

								</div>
								<!-- #endregion -->

								<div class="cols1 txtRight cols1addbtn">
									<div class="dx-field">
										<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn button"
											[text]="btnRulesAdd" (onClick)="addRuleBonusItem()" type="normal" icon="add"
											stylingMode="contained" [useSubmitBehavior]="false">
										</dx-button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="tableOut mrgT10 tableScroll" *ngIf="currentAgreementTab == 'rules'">
						<div id="rules-message" style="color:red"></div>
						<dx-data-grid #rulesListRulesTabDataGridRef id="gridContainer" [dataSource]="ruleTabList"
							(onToolbarPreparing)="doToolbarPreparingRuleTab($event)" [remoteOperations]="false"
							[showBorders]="true" [allowColumnResizing]="true" [columnAutoWidth]="true" keyExpr="guid"
							class="hideFilterPanelInx" [height]="350">

							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxo-filter-panel [visible]="false"></dxo-filter-panel>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<!-- <dxo-sorting mode="single"></dxo-sorting> -->
							<dxo-scrolling mode="standard" [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<!-- <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false" [useIcons]="true">
							</dxo-editing> -->

							<!-- ​<div *dxTemplate="let data of 'templateFilterText'">
								<div *ngIf="filterText" class="highlight mrgR10 pointerMark">
									<span>{{filterText}}</span>
								</div>
							</div> -->

							<dxi-column caption="Type" dataField="ruleBonusType" dataType="string" [allowEditing]="false"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateRuleBonusType">
								<div *dxTemplate="let element of 'cellTemplateRuleBonusType'">
									<span [ngSwitch]="element.data.ruleBonusType">
										<span *ngSwitchCase="'inclusionExclusionRule'">Inclusion/Exclusion Rule</span>
										<span *ngSwitchCase="'rateRule'">Rate Rule</span>
										<span *ngSwitchCase="'bonusAmount'">Bonus Amount</span>
										<span *ngSwitchCase="'bonusRate'">Bonus Rate</span>
										<span *ngSwitchDefault>{{element.data.ruleBonusType}}</span>
									</span>
								</div>
							</dxi-column>

							<dxi-column caption="Condition" dataField="condition" dataType="string" [allowEditing]="false"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateCondition">
								<div *dxTemplate="let element of 'cellTemplateCondition'">
									<span [ngSwitch]="element.data.condition">
										<span *ngSwitchCase="'include'">Include in Calculation</span>
										<span *ngSwitchCase="'exclude'">Exclude in Calculation</span>
										<span *ngSwitchCase="'breakUnitsOfMeasure'">Break Units of Measure</span>
										<span *ngSwitchCase="'itemsLessThan'">No. Items Less Than</span>
										<span *ngSwitchCase="'itemsGreaterThan'">No. Items Greater Than</span>
										<span *ngSwitchCase="'miscellaneousDeduction'">Miscellaneous Deduction</span>
										<span *ngSwitchCase="'miscellaneousDeductionInventory'">Miscellaneous Deduction -
											Inventory Item</span>
										<span *ngSwitchCase="'newAccount'">New Account</span>
										<span *ngSwitchCase="'perActiveCustomer'">Per Active Customer</span>
										<span *ngSwitchCase="'totalPerSettlementPeriodGreaterThan'">Total Per Settlement Period
											Greater Than</span>
										<span *ngSwitchCase="'newAccountWithMonthlyOrders'">New Account With Monthly Orders
											Greater Than</span>
										<span *ngSwitchCase="'grossProfitPerSettlementPeriod'">Gross Profit Per Settlement
											Period
											Greater Than</span>
										<span *ngSwitchCase="'SKUsShippedInSettlementPeriod'">No. SKUs Shipped In Settlement
											Period Greater Than</span>
										<span *ngSwitchDefault>{{element.data.condition}}</span>
									</span>
								</div>
							</dxi-column>

							<dxi-column caption="Criterion" dataField="criterion" dataType="string" [allowEditing]="false"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateCriterion">
								<div *dxTemplate="let element of 'cellTemplateCriterion'">
									<span [ngSwitch]="element.data.criterion">
										<span *ngSwitchCase="'billbacksChargebacks'">Billbacks/Chargebacks</span>
										<span *ngSwitchCase="'unpaidInvoices'">Unpaid Invoices</span>
										<span *ngSwitchCase="'rebates'">Rebates</span>
										<span *ngSwitchCase="'cashDiscounts'">Cash Discounts</span>
										<span *ngSwitchCase="'shippingCosts'">Shipping Costs</span>
										<span *ngSwitchDefault>{{element.data.criterion}}</span>
									</span>
								</div>
							</dxi-column>

							<dxi-column caption="Rate" dataField="ruleRate" dataType="string" [allowEditing]="false"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateRuleRate">
								<div *dxTemplate="let element of 'cellTemplateRuleRate'">
									{{element.data.ruleRate == 'N/A' ? element.data.ruleRate
									:element.data.ruleBonusType == 'bonusAmount' ?
									(+element.data.ruleRate || 0 | customCurrencyPipe:customCurrencyOption) :
									(+element.data.ruleRate || 0 |
									customNumberNoRoundingPipe) + '%'}}
								</div>
							</dxi-column>

							<dxi-column dataField="guid" *ngxPermissionsOnly="isCurrentTabPermission"
								[visible]="isEnabledRulesDatagrid" caption="Action" cellTemplate="cellTemplateRuleAction"
								[allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"
								alignment="center" [allowResizing]="false" [minWidth]="150" [width]="150"
								[allowExporting]="false">
								<div *dxTemplate="let element of 'cellTemplateRuleAction'" class="center">
									<span class="center txtCenter actBtncenter actBtn">
										<a id="editRuleBonus_{{element.rowIndex + 1}}"
											(click)="doEditRuleBonusListItem(element.rowIndex,element.data);">
											<span class="icon-editdxtable"></span>
										</a>
										<a id="deleteRuleBonus_{{element.rowIndex + 1}}"
											(click)="doRemoveRuleBonusListItem(element.data);">
											<span class="icon-deletedxtable"></span>
										</a>
										<dx-tooltip target="#editRuleBonus_{{element.rowIndex + 1}}" showEvent="dxhoverstart"
											hideEvent="dxhoverend" position="bottom">
											<div *dxTemplate="let data = data of 'content'">
												Edit
											</div>
										</dx-tooltip>
										<dx-tooltip target="#deleteRuleBonus_{{element.rowIndex + 1}}" showEvent="dxhoverstart"
											hideEvent="dxhoverend" position="bottom">
											<div *dxTemplate="let data = data of 'content'">
												Delete
											</div>
										</dx-tooltip>
									</span>
								</div>
							</dxi-column>

						</dx-data-grid>
					</div>
					<!--endregion-->

					<!-- #region for Item and Group Tab Content -->
					<div *ngxPermissionsOnly="isCurrentTabPermission">
						<div *ngIf="currentAgreementTab === 'itemsOrgroups'" class="mrgB20">
							<div
								*ngIf="rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive' || rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential'">
								<div *ngIf="isEnabledItemAndGroupDatagrid">
									<div class="row mrgB20" *ngIf="!enable_classification_for_rebate">
										<div class="cols9 radioGroup">
											<div class="radio">
												<dx-radio-group [dataSource]="listOfExclusionsForIndividualAndGrowthIncentive"
													displayExpr="text" valueExpr="value"
													(onValueChanged)="doChangeItemsOrGroupsRadioBtn($event)" valueChangeEvent="blur"
													[(value)]="itemsOrGroupsRadioBtn" layout="horizontal">
												</dx-radio-group>
											</div>
										</div>
										<div
											*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive') && rebatesDetails.rebateType === 'sales' && productSearchItemType === 'price' && !isQBOnlineErpUser"
											class="cols3">
											<div class="right secondaryTitle">
												<div class="noteBx mrg0">
													<span>
														<strong>Customer Price List :</strong>
													</span>
													<span
														*ngIf="rebatesDetails.selectCustomerObj && rebatesDetails.selectCustomerObj.pricelistname">
														{{rebatesDetails.selectCustomerObj.pricelistname || ''}}
													</span>
													<span
														*ngIf="!rebatesDetails.selectCustomerObj || (rebatesDetails.selectCustomerObj && !rebatesDetails.selectCustomerObj.pricelistname)">
														<strong> &mdash; </strong>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div class="row inputmgbtablet mrgB20">
										<div class="cols5"
											*ngIf="(rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'">
											<div class="dx-field feildCol">
												<div class="dx-field-label">End-Users</div>
												<div class="dx-field-value" style="width: 100%;">
													<dx-drop-down-box #refEndUserDrpDwn [dataSource]="listOfEndUserItmGrpTabDsDrpDwn"
														[(value)]="sEndUserLblItmGrpTabDrpDwn"
														[displayExpr]="doDisplayExprEndUserItmGrpTabText" valueExpr="code"
														placeholder="Select End-User..." [showClearButton]="true"
														(onOpened)="doOpenedDrpdwnEndUserItmGrpTab($event)"
														(onValueChanged)="doValueChangedDrpDwnEndUserItmGrpTab($event)"
														class="dx-ix-dropdownbox">
														<div *dxTemplate="let data of 'content'">
															<dx-check-box text="All End-User"
																(onValueChanged)="doChangeAllEndUserItmGrpTabDrpDwnChkBox($event)"
																[(value)]="isCheckedAllEndUserItmGrpTabDrpDwnChkBox" class="mrgB10">
															</dx-check-box>
															<dx-tag-box [value]="aEndUserItmGrpTabDrpDwnTagBox"
																(onValueChanged)="doValueChangeEndUserItmGrpTabTagBox($event)"
																[grouped]="true" class="dx-ix removedCloseIcon">
															</dx-tag-box>
															<dx-data-grid #refEndUserLstItmGrpTabDrpDwn
																[dataSource]="listOfEndUserItmGrpTabDsDrpDwn"
																[columns]="columnsForListOfEndUserItemsItmGrpTabDg"
																[hoverStateEnabled]="true" [height]="345"
																[(selectedRowKeys)]="aSelKeysEndUserItmGrpTabDg"
																[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
																(onSelectionChanged)="doSelChangedEndUserItmGrpTabDg($event)">
																<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																	[scrollByThumb]="true" [showScrollbar]="'always'"
																	[useNative]="false">
																</dxo-scrolling>
																<dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>
																<dxo-filter-row [visible]="true"></dxo-filter-row>
																<dxo-paging [enabled]="true" [pageSize]="100"></dxo-paging>
															</dx-data-grid>
														</div>
													</dx-drop-down-box>
												</div>
											</div>
										</div>
										<div
											[ngClass]="{'cols6 cols6addbtn': (rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales','cols11 cols11addbtn' :!((rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales')}">
											<div class="dropdownWithBtn">
												<div class="dx-field feildCol" *ngIf="!enable_classification_for_rebate">
													<div class="dx-field-label">Item(s)</div>
													<div class="dx-field-value" style="width: 100%;">
														<dx-drop-down-box #refItemsLstItmGrpTabDrpDwn
															[dataSource]="itemsLstItmGrpTabDsDrpDwn"
															[(value)]="sItemsLblItmGrpTabDrpDwn"
															[displayExpr]="doDisplayExprItemsItmGrpTabText" valueExpr="itemname"
															placeholder="Select Item..." [showClearButton]="true"
															(onOpened)="doOpenedDropdownItemsItmGrpTab($event)"
															(onValueChanged)="doChangeItemsItmGrpTabDrpdwn($event)"
															class="dx-ix-dropdownbox">
															<div *dxTemplate="let data of 'content'">
																<dx-check-box text="All Items"
																	(onValueChanged)="doChangeAllItemsItmGrpTabDrpDwnChkBox($event)"
																	[(value)]="isCheckedAllItemsItmGrpTabDrpDwnChkBox" class="mrgB10">
																</dx-check-box>
																<dx-tag-box [value]="aItemsLstItmGrpTabDrpDwnTagBox"
																	(onValueChanged)="doValueChangeItemsItmGrpTabTagBox($event)"
																	[grouped]="true" class="dx-ix removedCloseIcon">
																</dx-tag-box>
																<dx-data-grid #refItemsLstItmGrpTabDg
																	[dataSource]="itemsLstItmGrpTabDsDrpDwn"
																	[columns]="columnsItemsItmGrpTabDg" [selection]="{ mode: 'multiple'}"
																	[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
																	[filterRow]="{ visible: true }" [height]="345"
																	[(selectedRowKeys)]="aSelKeysItemsItmGrpTabDg"
																	[remoteOperations]="true" [wordWrapEnabled]="true"
																	[showBorders]="true"
																	(onSelectionChanged)="doSelChangedItemsItmGrpTabDg($event)">
																	<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																		[scrollByThumb]="true" [showScrollbar]="'always'"
																		[useNative]="false">
																	</dxo-scrolling>
																	<dxo-selection [allowSelectAll]="false"></dxo-selection>
																</dx-data-grid>
															</div>
														</dx-drop-down-box>
													</div>
												</div>
												<div class="dx-field feildCol" *ngIf="enable_classification_for_rebate">
													<div class="dx-field-label">Classes</div>
													<div class="dx-field-value" style="width: 100%;">
														<dx-drop-down-box #refClassesDrpDwn [dataSource]="classesDataSource"
															[displayExpr]="doDisplayExprClassesText" [(value)]="lengthSelectedClasses"
															valueExpr="name" placeholder="Select Classes..." [showClearButton]="true"
															(onValueChanged)="onClassesValueChanged($event)"
															(onOpened)="doOpenedDropdownClassesValue($event)">
															<div *dxTemplate="let data of 'content'">
																<dx-check-box text="All Classes" (onValueChanged)="checkAllClasses()"
																	[(value)]="chkAllClasses" class="mrgB10" width="25%">
																</dx-check-box>
																<dx-tag-box [(value)]="arrSelectedClassesList" [grouped]="true">
																</dx-tag-box>
																<dx-data-grid #refclassesDG [dataSource]="classesDataSource"
																	[columns]="classesGridBoxColumns" [selection]="{ mode: 'multiple'}"
																	[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
																	[filterRow]="{ visible: true }" [height]="325"
																	[(selectedRowKeys)]="arrSelectedClassesList"
																	[remoteOperations]="true" [wordWrapEnabled]="true"
																	[showBorders]="true"
																	(onSelectionChanged)="onClassesSelectionChanged($event)">
																	<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																		[scrollByThumb]="true" [showScrollbar]="'always'"
																		[useNative]="false">
																	</dxo-scrolling>
																	<dxo-selection [allowSelectAll]="false"></dxo-selection>
																</dx-data-grid>
															</div>
														</dx-drop-down-box>
													</div>
												</div>
											</div>
										</div>
										<div class="cols1 cols1addbtn ">
											<dx-button class="secondaryBtn button alignRight center mrgT0" type="normal"
												stylingMode="contained" [useSubmitBehavior]="false" icon="add"
												(onClick)="doAddItemsAndGroupsToListItmGrpTab()" text="Add">
											</dx-button>
										</div>
									</div>
								</div>
								<div class="cf"></div>

								<dx-data-grid #itemsAndGroupsitmGrpTabDataGridRef id="gridContainer"
									[dataSource]="dataSourceItemsAndGroupsLstItmGrpTab" [remoteOperations]="false"
									[allowColumnReordering]="true" [allowColumnResizing]="true" [showBorders]="true"
									(onToolbarPreparing)="doToolbarPreparing($event)"
									[masterDetail]="{ enabled: true, template: 'tiersTemplate','autoExpandAll': false }"
									(onInitialized)="doInitializedItemItmGrpTab($event)"
									(onEditorPreparing)="doOnEditorPreparingItemItmGrpTab($event)"
									(onRowUpdated)="doOnRowUpdatedItemItmGrpTab($event)"
									(onRowRemoved)="doOnRowRemovedItemItmGrpTab($event)"
									(onSaved)="doOnSavedItemItmGrpTab($event)"
									(onRowPrepared)="doOnonRowPreparedItemItmGrpTab($event)"
									(onRowExpanding)="doOnRowExpandingItmGrpTab($event)"
									(onRowValidating)="doRowValidatingItmGrpTab($event)" keyExpr="guid"
									class="hideFilterPanelInx" [height]="450">

									<dxo-filter-row [visible]="true"></dxo-filter-row>
									<dxo-filter-panel [visible]="true"></dxo-filter-panel>
									<dxo-header-filter [visible]="true"></dxo-header-filter>
									<dxo-sorting mode="single"></dxo-sorting>
									<dxo-scrolling mode="standard" [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-editing mode="row" [allowUpdating]="isEnabledItemAndGroupDatagrid"
										[allowDeleting]="isEnabledItemAndGroupDatagrid" [allowAdding]="false" [useIcons]="true">
									</dxo-editing>
									<dxo-column-chooser [enabled]="true">
										<dxo-selection [allowSelectAll]="true" [selectByClick]="true"
											[recursive]="true"></dxo-selection>
									</dxo-column-chooser>
									<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadRebateItemsState"
										[customSave]="doSaveRebateItemsState" [storageKey]="stateStorageKey">
									</dxo-state-storing>

									<div *dxTemplate="let data of 'templateFilterText'">
										<div *ngIf="filterText" class="highlight mrgR10 pointerMark">
											<span>{{filterText}}</span>
										</div>
									</div>

									<dxi-column caption="End-User Code" dataField="endusercode"
										[visible]="(rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'"
										[showInColumnChooser]="(rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'"
										dataType="string" [allowEditing]="false" [allowFiltering]="true"
										[allowHeaderFiltering]="false">
									</dxi-column>

									<dxi-column caption="End-User Name" dataField="endusername"
										[visible]="(rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'"
										[showInColumnChooser]="(rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'"
										dataType="string" [allowEditing]="false" [allowFiltering]="true"
										[allowHeaderFiltering]="false">
									</dxi-column>

									<dxi-column caption="End-User Address Name" dataField="shipto.addressname" dataType="string"
										[visible]="(rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'"
										[showInColumnChooser]="(rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential') && rebatesDetails.rebateType === 'sales'"
										[allowEditing]="false" [allowFiltering]="true" [allowHeaderFiltering]="false">
									</dxi-column>

									<dxi-column caption="SKU" [visible]="isQBOnlineErpUser && !enable_classification_for_rebate"
										[showInColumnChooser]="isQBOnlineErpUser && !enable_classification_for_rebate"
										dataField="itemsku" dataType="string" [allowEditing]="false" [allowFiltering]="true"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemSku">
										<div *dxTemplate="let element of 'cellTemplateItemSku'">
											{{element.data.itemsku || ''}}
										</div>
									</dxi-column>

									<dxi-column caption="Item" [visible]="!enable_classification_for_rebate"
										[showInColumnChooser]="!enable_classification_for_rebate" [allowHiding]="false"
										dataField="itemname" dataType="string" [allowEditing]="false" [allowFiltering]="true"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemName">
										<div *dxTemplate="let element of 'cellTemplateItemName'">
											<span *ngIf="rebatesDetails.tiers_type === 'single_items'">
												{{element.data.itemname || ''}}
											</span>
											<span
												*ngIf="rebatesDetails.tiers_type === 'multiple_items' && element.data.items && element.data.items.length > 0"
												[attr.id]="'itemsToolTip_' + element.data.guid">
												{{element.data.items && element.data.items.length > 0 ?
												element.data.items[0].itemname : ''}}
												<span
													*ngIf="element.data.items[0] && (element.data.items.length === 2 || element.data.items.length >= 3)">
													+ {{element.data.items.length-1}}
													{{ element.data.items.length === 2 ? 'Other' : element.data.items.length >= 3 ?
													'Others' : ''}}
												</span>
												<dx-tooltip target="#itemsToolTip_{{element.data.guid}}" showEvent="dxhoverstart"
													hideEvent="dxhoverend" position="right">
													<div *dxTemplate="let d = d; of: 'content'">
														<div class="tooltip-wrapper">
															<div class="greenTipout">
																<ol class="tipList">
																	<li *ngFor="let item of element.data.items; let p = index;">
																		<a>{{item.itemname || ''}}</a>
																	</li>
																</ol>
															</div>
														</div>
													</div>
												</dx-tooltip>
											</span>
										</div>
									</dxi-column>

									<dxi-column caption="Classes" [visible]="enable_classification_for_rebate"
										[showInColumnChooser]="enable_classification_for_rebate" dataField="classname"
										dataType="string" [allowEditing]="false" cellTemplate="cellTemplateClassesName"
										[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
										<div *dxTemplate="let element of 'cellTemplateClassesName'">
											<span *ngIf="rebatesDetails.tiers_type === 'single_items'">
												{{element.data.classname || ''}}
											</span>
											<span
												*ngIf="rebatesDetails.tiers_type === 'multiple_items' && element.data.items && element.data.items.length > 0"
												[attr.id]="'classesToolTip_' + element.data.guid">
												{{element.data.items && element.data.items.length > 0 ?
												element.data.items[0].classname : ''}}
												<span
													*ngIf="element.data.items[0] && (element.data.items.length === 2 || element.data.items.length >= 3)">
													+ {{element.data.items.length-1}}
													{{ element.data.items.length === 2 ? 'Other' : element.data.items.length >= 3
													?
													'Others' : ''}}
												</span>
												<dx-tooltip target="#classesToolTip_{{element.data.guid}}" showEvent="dxhoverstart"
													hideEvent="dxhoverend" position="right">
													<div *dxTemplate="let d = d; of: 'content'">
														<div class="tooltip-wrapper">
															<div class="greenTipout">
																<ol class="tipList">
																	<li *ngFor="let item of element.data.items; let p = index;">
																		<a>{{item.classname || ''}}</a>
																	</li>
																</ol>
															</div>
														</div>
													</div>
												</dx-tooltip>
											</span>
										</div>
									</dxi-column>

									<dxi-column caption="Description" [visible]="!enable_classification_for_rebate"
										[showInColumnChooser]="!enable_classification_for_rebate" dataField="description"
										dataType="string" [allowEditing]="false" [allowFiltering]="true"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemDesc">
										<div *dxTemplate="let element of 'cellTemplateItemDesc'">
											{{element.data.description || ''}}
										</div>
									</dxi-column>

									<dxi-column caption="Basis" dataField="tier_basis" dataType="string" [allowEditing]="true"
										[allowFiltering]="false" [allowHeaderFiltering]="true" [allowHiding]="false"
										[setCellValue]="setCellValueTierBasisColumn" cellTemplate="cellTemplateTierBasis">
										<dxo-lookup [dataSource]="listOfTierBasis" valueExpr="code"
											[displayExpr]="getDisplayExprTierbasis">
										</dxo-lookup>
										<dxi-validation-rule type="required"></dxi-validation-rule>
										<div *dxTemplate="let element of 'cellTemplateTierBasis'">
											{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label'
											|
											uppercase}}
										</div>
									</dxi-column>

									<dxi-column dataField="in_pricelist" caption="In Price List" dataType="boolean"
										[allowEditing]="true" [allowFiltering]="false" [allowHeaderFiltering]="true"
										cellTemplate="cellTemplateInPricelist" [allowHiding]="false"
										[visible]="rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential'"
										[showInColumnChooser]="rebatesDetails.individualOrGroup === 'distribution' || rebatesDetails.individualOrGroup === 'distribution_differential'">
										<dxo-lookup [dataSource]="listOfInPricelist" valueExpr="value" displayExpr="text">
										</dxo-lookup>
										<div *dxTemplate="let element of 'cellTemplateInPricelist'">
											{{element.data.in_pricelist ? 'Yes':'No'}}
										</div>
									</dxi-column>

									<dxi-column caption="Rebate Basis" dataField="rebate_basis" dataType="string"
										[allowEditing]="true" [allowFiltering]="false" [allowHeaderFiltering]="true"
										[allowHiding]="false" [setCellValue]="setCellValueRebateBasisColumn"
										cellTemplate="cellTemplateRebateBasis">
										<dxo-lookup [dataSource]="listOfRebateBasis" valueExpr="code"
											[displayExpr]="getDisplayExprRebatebasis">
										</dxo-lookup>
										<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
										<div *dxTemplate="let element of 'cellTemplateRebateBasis'">
											{{element.data.rebate_basis || '' | filterFromList:listOfRebateBasis : 'code' :
											'label'
											| uppercase}}
										</div>
									</dxi-column>

									<dxi-column caption="UOM" [visible]="!enable_classification_for_rebate"
										[showInColumnChooser]="!enable_classification_for_rebate" dataField="selectedUom"
										dataType="string" [allowEditing]="true" [setCellValue]="setCellValueUomColumn"
										[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateUomCode">
										<dxo-lookup [dataSource]="listOfUoms" valueExpr="code"
											[displayExpr]="getDisplayExprUomName">
										</dxo-lookup>
										<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
										<div *dxTemplate="let element of 'cellTemplateUomCode'">
											<span *ngIf="element.data.rebate_basis === 'dollarperuom'">
												{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' |
												uppercase}}
											</span>
										</div>
									</dxi-column>

									<dxi-column caption="List Price" dataField="listPrice" dataType="number"
										[visible]="isShowListPriceColumnItmGrpTab"
										[showInColumnChooser]="isShowListPriceColumnItmGrpTab" [allowEditing]="true"
										[allowHiding]="false" [editorOptions]="{ format: 'decimal', showClearButton: true }"
										[allowFiltering]="true" [allowHeaderFiltering]="false"
										cellTemplate="cellTemplateListprice">
										<div *dxTemplate="let element of 'cellTemplateListprice'">
											<span
												*ngIf="element.data.tier_basis === 'notiers' && element.data.rebate_basis === 'dollarperuom'">
												{{element.data.listPrice || 0 | customCurrencyPipe:customCurrencyOption}}
											</span>
										</div>
									</dxi-column>

									<dxi-column caption="Rate" dataField="rate" dataType="number" [allowEditing]="true"
										[allowHiding]="false" [setCellValue]="setCellValueRateColumn"
										[editorOptions]="{ format: 'decimal', showClearButton: true }" [allowFiltering]="true"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateRate">
										<div *dxTemplate="let element of 'cellTemplateRate'">
											<span *ngIf="(element.data.rebate_basis === 'dollar' || element.data.rebate_basis === 'dollarperuom') &&
												(element.data.tier_basis === 'notiers')">
												{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
											</span>
											<span
												*ngIf="element.data.rebate_basis === 'percentage' && (element.data.tier_basis === 'notiers')">
												{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%'}}
											</span>
										</div>
									</dxi-column>

									<dxi-column caption="Final Price" dataField="rebatefinalprice"
										[visible]="isShowFinalPriceColumnItmGrpTab"
										[showInColumnChooser]="isShowFinalPriceColumnItmGrpTab" dataType="number"
										[allowEditing]="true" [allowHiding]="false"
										[editorOptions]="{ format: 'decimal', showClearButton: true }" [allowFiltering]="true"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateRebateFinalPrice">
										<div *dxTemplate="let element of 'cellTemplateRebateFinalPrice'">
											<span
												*ngIf="element.data.tier_basis === 'notiers' && element.data.rebate_basis === 'dollarperuom'">
												{{element.data.rebatefinalprice || 0 | customCurrencyPipe:customCurrencyOption }}
											</span>
										</div>
									</dxi-column>

									<dxi-column type="buttons" caption="Action" [visible]="isEnabledItemAndGroupDatagrid"
										[showInColumnChooser]="isEnabledItemAndGroupDatagrid" [fixed]="true" fixedPosition="right"
										[allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"
										alignment="center" [allowResizing]="false" [minWidth]="150" [width]="150"
										[allowExporting]="false">
										<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip"></dxi-button>
										<dxi-button name="delete" cssClass="dx-grid-delete dx-icon-trash menuTip"></dxi-button>
									</dxi-column>

									<div *dxTemplate="let c of 'tiersTemplate'">
										<dx-data-grid #tiersItemDetailssDataGridRef
											id="tiersItemDetailssDataGridRef_{{c.data.erp_primary_key}}"
											[dataSource]="getTiersByItemDetails(c)" [remoteOperations]="false"
											(onInitNewRow)="doOnInitNewRowTiersItmGrpTab($event,c)"
											(onRowInserting)="doOnRowInsertingTiersItmGrpTab($event,c)"
											(onEditorPreparing)="doOnEditorPreparingTiersItmGrpTab($event,c)"
											(onRowRemoved)="doOnRowRemovedTiersItmGrpTab($event,c)"
											(onSaved)="doOnSavedTiersItmGrpTab($event,c)" keyExpr="guid" [showBorders]="true"
											[columnAutoWidth]="true"
											[masterDetail]="{ enabled: rebatesDetails.tiers_type === 'multiple_items', template: 'itemsTemplate','autoExpandAll': false }">
											<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
												[useNative]="false"></dxo-scrolling>
											<dxo-filter-row [visible]="false"></dxo-filter-row>
											<dxo-filter-panel [visible]="false"></dxo-filter-panel>
											<dxo-header-filter [visible]="false"></dxo-header-filter>

											<dxo-editing mode="row" [allowUpdating]="isEnabledTiersDatagrid"
												[allowDeleting]="isEnabledTiersDatagrid" [useIcons]="true"
												[allowAdding]="(c.data.tier_basis === 'volume' || c.data.tier_basis === 'revenue') && isEnabledTiersDatagrid">
											</dxo-editing>

											<dxi-column caption="Tier Minimum" dataField="mintier" dataType="number"
												[allowEditing]="true" [editorOptions]="{ format: 'decimal', showClearButton: true }"
												[allowFiltering]="true" [allowHeaderFiltering]="false"
												cellTemplate="cellTemplateMinTier">
												<dxi-validation-rule type="required"></dxi-validation-rule>
												<div *dxTemplate="let element of 'cellTemplateMinTier'">
													{{doCustomizeTextTierMinMax(element.data.mintier || '',c,'mintier') }}
												</div>
											</dxi-column>

											<dxi-column caption="Tier Maximum" dataField="maxtier" [allowEditing]="true"
												[editorOptions]="{ showClearButton: true }" [allowFiltering]="true"
												[allowHeaderFiltering]="false" cellTemplate="cellTemplateMaxTier">
												<dxi-validation-rule type="required"></dxi-validation-rule>
												<div *dxTemplate="let element of 'cellTemplateMaxTier'">
													{{doCustomizeTextTierMinMax(element.data.maxtier || '',c,'maxtier') }}
												</div>
											</dxi-column>

											<dxi-column caption="List Price" dataField="listPrice"
												[visible]="isShowTierListPriceColumnItmGrpTab" dataType="number"
												[allowEditing]="false" [allowFiltering]="true" [allowHeaderFiltering]="false"
												cellTemplate="cellTemplateTiersListprice">
												<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format> -->
												<div *dxTemplate="let element of 'cellTemplateTiersListprice'">
													{{element.data.listPrice || 0 | customCurrencyPipe:customCurrencyOption }}
												</div>
											</dxi-column>

											<dxi-column caption="Rate" dataField="rate" dataType="number" [allowEditing]="true"
												[setCellValue]="setCellValueTiersRateColumn" [allowFiltering]="true"
												[allowHeaderFiltering]="false"
												[editorOptions]="{ format: 'decimal', showClearButton: true }"
												cellTemplate="cellTemplateTiersRate"
												[visible]="rebatesDetails.tiers_type === 'single_items'">
												<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
												<div *dxTemplate="let element of 'cellTemplateTiersRate'">
													<span
														*ngIf="(c.data.rebate_basis === 'dollar' || c.data.rebate_basis === 'dollarperuom')">
														{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
													</span>
													<span *ngIf="c.data.rebate_basis === 'percentage'">
														{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%' }}
													</span>
												</div>
											</dxi-column>

											<dxi-column caption="Final Price" dataField="rebatefinalprice"
												[setCellValue]="setCellValueTiersFinalPriceColumn"
												[visible]="isShowTierFinalPriceColumnItmGrpTab" dataType="number"
												[allowEditing]="true" [allowFiltering]="true" [allowHeaderFiltering]="false"
												[editorOptions]="{ format: 'decimal', showClearButton: true }"
												cellTemplate="cellTemplateTiersRebateFinalPrice">
												<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format> -->
												<div *dxTemplate="let element of 'cellTemplateTiersRebateFinalPrice'">
													{{element.data.rebatefinalprice || 0 | customCurrencyPipe:customCurrencyOption }}
												</div>
											</dxi-column>

											<dxi-column type="buttons" [visible]="isEnabledTiersDatagrid" caption="Action"
												[fixed]="true" fixedPosition="right" [allowFiltering]="false"
												[allowHeaderFiltering]="false" [allowSorting]="false" alignment="center"
												[allowResizing]="true" [minWidth]="150" [width]="150" [allowExporting]="false">

												<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip">
												</dxi-button>
												<dxi-button name="delete" cssClass="dx-grid-delete dx-icon-trash menuTip">
												</dxi-button>

											</dxi-column>

											<div *dxTemplate="let item of 'itemsTemplate'">
												<dx-data-grid #itemsOfTiersDataGridRef [dataSource]="getItemsByTiersDetails(item)"
													(onEditorPreparing)="doEditorPreparingItemTierItmTab($event,item,c)"
													(onRowUpdated)="doRowUpdatedItemTierItmTab($event,item,c)"
													(onRowRemoved)="doRowRemovedItemTierItmTab($event,item,c)"
													(onSaved)="doOnSavedItemTierItmTab($event,item,c)" keyExpr="erp_primary_key"
													[showBorders]="true" [allowColumnResizing]="true" [allowColumnReordering]="false"
													[columnAutoWidth]="true">
													<dxo-filter-row [visible]="true"></dxo-filter-row>
													<dxo-filter-panel [visible]="true"></dxo-filter-panel>
													<dxo-header-filter [visible]="true"></dxo-header-filter>
													<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
														[allowAdding]="false" [useIcons]="true">
													</dxo-editing>

													<dxi-column caption="SKU"
														[visible]="isQBOnlineErpUser && !enable_classification_for_commision"
														dataField="itemsku" dataType="string" [allowEditing]="false"
														cellTemplate="cellTemplateItemSku" [allowHeaderFiltering]="false"
														[allowFiltering]="true" [allowSorting]="true">
														<div *dxTemplate="let element of 'cellTemplateItemSku'">
															{{element.data.itemsku || ''}}
														</div>
													</dxi-column>

													<dxi-column caption="Classes" [visible]="enable_classification_for_rebate"
														dataField="classname" dataType="string" [allowEditing]="false"
														cellTemplate="cellTemplateItemClassesName" [allowHeaderFiltering]="false"
														[allowFiltering]="true" [allowSorting]="true">
														<div *dxTemplate="let element of 'cellTemplateItemClassesName'">
															{{element.data.classname || ''}}
														</div>
													</dxi-column>

													<dxi-column caption="Item" dataField="itemname"
														[visible]="!enable_classification_for_rebate" dataType="string"
														[allowEditing]="false" editCellTemplate="itemsListEditor"
														cellTemplate="cellTemplateItemName" [allowHeaderFiltering]="false"
														[allowSorting]="true">
														<div *dxTemplate="let element of 'cellTemplateItemName'">
															{{element.data.itemname}}
														</div>
													</dxi-column>

													<dxi-column caption="Description" dataField="description"
														[visible]="!enable_classification_for_rebate" dataType="string"
														[allowEditing]="false" [allowHeaderFiltering]="false" [allowFiltering]="true"
														[allowSorting]="true" cellTemplate="cellTemplateItemDesc">
														<div *dxTemplate="let element of 'cellTemplateItemDesc'">
															{{element.data.description || ''}}
														</div>
													</dxi-column>

													<dxi-column caption="UOM" dataField="selectedUom"
														[visible]="c.data.rebate_basis === 'dollarperuom' && !enable_classification_for_rebate"
														dataType="string" [allowEditing]="c.data.rebate_basis === 'dollarperuom'"
														[setCellValue]="setCellValueUomColumn" cellTemplate="cellTemplateUomCode"
														[allowHeaderFiltering]="false" [allowFiltering]="false"
														[allowSorting]="false">
														<dxo-lookup [dataSource]="listOfUoms" valueExpr="code"
															[displayExpr]="getDisplayExprUomName">
														</dxo-lookup>
														<div *dxTemplate="let element of 'cellTemplateUomCode'">
															<span *ngIf="c.data.rebate_basis === 'dollarperuom'">
																{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' :
																'name' |
																uppercase}}
															</span>
														</div>
													</dxi-column>

													<dxi-column
														[caption]="'Rate ' + (c.data.rebate_basis === 'dollar' ? '('+globalCurrencySymbol+')' : c.data.rabate_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')')"
														dataField="rate" dataType="number" [allowEditing]="true"
														[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false"
														[editorOptions]="{ format: 'decimal', showClearButton: true }"
														cellTemplate="cellTemplateItemRate" [width]="100">
														<dxi-validation-rule type="required"></dxi-validation-rule>
														<div *dxTemplate="let element of 'cellTemplateItemRate'">
															<span
																*ngIf="c.data.rebate_basis === 'dollar' || c.data.rebate_basis === 'dollarperuom'">
																{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
															</span>
															<span *ngIf="c.data.rebate_basis === 'percentage'">
																{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%' }}
															</span>
														</div>
													</dxi-column>

													<dxi-column type="buttons" caption="Action" [allowFiltering]="false"
														[allowHeaderFiltering]="false" [fixed]="true" fixedPosition="right"
														[allowSorting]="false" alignment="center" [allowResizing]="false"
														[minWidth]="150" [width]="150" [allowExporting]="false">
														<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
														<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
													</dxi-column>

												</dx-data-grid>
											</div>

										</dx-data-grid>
									</div>

								</dx-data-grid>
								<div *ngIf="isUploadExcelCsvFileItmGrpTab" class="noteBx mrgT10 mrgB0">
									<span>
										<b>{{noOfUnmatchedItem || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b> Item(s)
										ignored
										<span class="noteSeprator"></span>
										<b>{{noOfUnmatchedItemUom || 0}}</b> UOM(s) out of <b>{{noOfTotalItem || 0}}</b> UOM(S)
										ignored
										<span
											*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive') && rebatesDetails.rebateType === 'sales' && productSearchItemType === 'price'"
											class="noteSeprator"></span>
										<span
											*ngIf="(rebatesDetails.individualOrGroup === 'individual' || rebatesDetails.individualOrGroup === 'growth_incentive') && rebatesDetails.rebateType === 'sales' && productSearchItemType === 'price'">
											<b>{{noOfmatchedItemPrice || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b>
											item(S)
											Price / Rebate ignored
										</span>
										<strong *ngIf="listOfCsvRowForItmGrpTab && listOfCsvRowForItmGrpTab.length > 0"
											(click)="doShowCsvItemsListItmGrpTab()" class="pointerMark">Click here to
											view</strong>
									</span>
								</div>
								<div class="cf"></div>

							</div>
							<div *ngIf="rebatesDetails.individualOrGroup === 'buyinggroup'">
								<div class="row" *ngIf="!enable_classification_for_rebate">
									<div class="cols12 radioGroup mrgB20">
										<div class="radio">
											<dx-radio-group [dataSource]="listOfExclusionsForBuyingGroup" displayExpr="text"
												valueExpr="value" (onValueChanged)="doChangeItemOrGroupRadioBtnExclsnTab($event)"
												valueChangeEvent="blur" [value]="itemsOrGroupsRadioBtnExclsnTab"
												layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>

								<div class="row mrgB10">
									<div class="cols5">
										<div class="dx-field feildCol">
											<div class="dx-field-label">Member(s)</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-drop-down-box #refMembersLstExclsnTabDrpDwn
													[dataSource]="membersLstExclsnTabDsDrpDwn" [(value)]="sMembersLblExclsnTabDrpDwn"
													[displayExpr]="doDisplayExprMembersText" valueExpr="name"
													placeholder="Select Member..." [showClearButton]="true"
													(onOpened)="doOpenedDropdownMembersExclsnTab($event)"
													(onValueChanged)="doChangeMembersExclsnTabDrpdwn($event)"
													class="dx-ix-dropdownbox">
													<div *dxTemplate="let data of 'content'">
														<dx-check-box text="All Members"
															(onValueChanged)="doChangeAllMembersExclsnTabDrpDwnChkBox($event)"
															[(value)]="isCheckedAllMembersExclsnTabDrpDwnChkBox" class="mrgB10">
														</dx-check-box>
														<dx-tag-box [value]="aMembersLstExclsnTabDrpDwnTagBox"
															(onValueChanged)="doValueChangeMembersExclsnTabTagBox($event)"
															[grouped]="true" class="dx-ix removedCloseIcon">
														</dx-tag-box>
														<dx-data-grid #refMembersLstExclsnTabDg keyExpr="code"
															[dataSource]="membersLstExclsnTabDsDrpDwn"
															[columns]="columnsMembersExclsnTabDg" [selection]="{ mode: 'multiple'}"
															[filterRow]="{ visible: true }" [height]="345"
															[(selectedRowKeys)]="aSelKeysMembersExclsnTabDg" [remoteOperations]="false"
															[wordWrapEnabled]="true" [showBorders]="true"
															(onSelectionChanged)="doSelChangedMembersExclsnTabDg($event)">
															<dxo-scrolling mode="standard" [scrollByThumb]="true"
																[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
															<dxo-selection [allowSelectAll]="false"></dxo-selection>
														</dx-data-grid>
													</div>
												</dx-drop-down-box>
											</div>
										</div>
									</div>
									<div class="cols6 cols6addbtn">
										<div class="dx-field feildCol" *ngIf="!enable_classification_for_rebate">
											<div class="dx-field-label">Item(s)</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-drop-down-box #refItemsLstExclsnTabDrpDwn
													[dataSource]="itemsLstExclsnTabDsDrpDwn" [(value)]="sItemsLblExclsnTabDrpDwn"
													[displayExpr]="doDisplayExprItemsText" valueExpr="itemname"
													placeholder="Select Item..." [showClearButton]="true"
													(onOpened)="doOpenedDropdownItemsExclsnTabDrpdwn($event)"
													(onValueChanged)="doChangeItemsExclsnTabDrpdwn($event)"
													class="dx-ix-dropdownbox">
													<div *dxTemplate="let data of 'content'">
														<dx-check-box text="All Items"
															(onValueChanged)="doChangeAllItemsExclsnTabDrpDwnChkBox($event)"
															[(value)]="isCheckedAllItemsExclsnTabDrpDwnChkBox" class="mrgB10">
														</dx-check-box>
														<dx-tag-box [value]="aItemsLstExclsnTabDrpDwnTagBox"
															(onValueChanged)="doValueChangeItemsExclsnTabTagBox($event)"
															[grouped]="true" class="dx-ix removedCloseIcon">
														</dx-tag-box>
														<dx-data-grid #refItemsLstExclsnTabDg [dataSource]="itemsLstExclsnTabDsDrpDwn"
															[columns]="columnsItemsExclsnTabDg" [selection]="{ mode: 'multiple'}"
															[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
															[filterRow]="{ visible: true }" [height]="345"
															[(selectedRowKeys)]="aSelKeysItemsExclsnTabDg" [remoteOperations]="true"
															[wordWrapEnabled]="true" [showBorders]="true"
															(onSelectionChanged)="doSelChangedItemsExclsnTabDg($event)">
															<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																[scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false">
															</dxo-scrolling>
															<dxo-selection [allowSelectAll]="false"></dxo-selection>
														</dx-data-grid>
													</div>
												</dx-drop-down-box>
											</div>
										</div>
										<div class="dx-field feildCol" *ngIf="enable_classification_for_rebate">
											<div class="dx-field-label">Classes</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-drop-down-box #refClassesDrpDwn [dataSource]="classesDataSource"
													[displayExpr]="doDisplayExprClassesText" [(value)]="lengthSelectedClasses"
													valueExpr="name" placeholder="Select Classes..." [showClearButton]="true"
													(onOpened)="doOpenedDropdownClassesValue($event)"
													(onValueChanged)="onClassesValueChanged($event)">
													<div *dxTemplate="let data of 'content'">
														<dx-check-box text="All Classes" (onValueChanged)="checkAllClasses()"
															[(value)]="chkAllClasses" class="mrgB10" width="25%">
														</dx-check-box>
														<dx-tag-box [(value)]="arrSelectedClassesList" [grouped]="true">
														</dx-tag-box>
														<dx-data-grid #refclassesDG [dataSource]="classesDataSource"
															[columns]="classesGridBoxColumns" [selection]="{ mode: 'multiple'}"
															[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
															[filterRow]="{ visible: true }" [height]="325"
															[(selectedRowKeys)]="arrSelectedClassesList" [remoteOperations]="true"
															[wordWrapEnabled]="true" [showBorders]="true"
															(onSelectionChanged)="onClassesSelectionChanged($event)">
															<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
																[scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false">
															</dxo-scrolling>
															<dxo-selection [allowSelectAll]="false"></dxo-selection>
														</dx-data-grid>
													</div>
												</dx-drop-down-box>
											</div>
										</div>
									</div>

									<div class="cols1 txtRight cols1addbtn">
										<dx-button class="secondaryBtn" id="addMembersToListExclsnBtn" text="Add"
											(onClick)="doAddMembersToListExclsnTab()" type="normal" stylingMode="contained"
											icon="add" [useSubmitBehavior]="false">
										</dx-button>
									</div>
								</div>

								<div class="cf"></div>

								<dx-data-grid #membersListExclsnTabDataGridRef id="gridContainer"
									[dataSource]="dataSourceMembersLstExclsnTab" [remoteOperations]="false" [showBorders]="true"
									[allowColumnResizing]="true" [allowColumnReordering]="true"
									[masterDetail]="{ enabled: true, template: 'tiersTemplate','autoExpandAll': false }"
									(onToolbarPreparing)="doToolbarPreparingExclsnTab($event)"
									(onEditorPreparing)="doEditorPreparingMembersExclsnTab($event)"
									(onRowUpdated)="doRowUpdatedMembersExclsnTab($event)"
									(onRowRemoved)="doRowRemovedMembersExclsnTab($event)"
									(onSaved)="doSavedMembersExclsnTab($event)"
									(onRowPrepared)="doRowPreparedMembersExclsnTab($event)"
									(onRowValidating)="doRowValidatingMembersExclsnTab($event)" keyExpr="member_item_guid"
									class="hideFilterPanelInx" [height]="450">

									<dxo-filter-row [visible]="true"></dxo-filter-row>
									<dxo-filter-panel [visible]="true"></dxo-filter-panel>
									<dxo-header-filter [visible]="true"></dxo-header-filter>
									<dxo-sorting mode="single"></dxo-sorting>
									<dxo-scrolling mode="standard" [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false"
										[useIcons]="true">
									</dxo-editing>
									<dxo-column-chooser [enabled]="true">
										<dxo-selection [allowSelectAll]="true" [selectByClick]="true"
											[recursive]="true"></dxo-selection>
										<dxo-position my="right top" at="right bottom"
											of=".dx-datagrid-column-chooser-button"></dxo-position>
									</dxo-column-chooser>
									<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadRebateItemsState"
										[customSave]="doSaveRebateItemsState" [storageKey]="stateStorageKey">
									</dxo-state-storing>

									<div *dxTemplate="let data of 'templateFilterText'">
										<div *ngIf="filterText" class="highlight mrgR10 pointerMark">
											<span>{{filterText}}</span>
										</div>
									</div>

									<dxi-column caption="Code" [visible]="!isQBErpUser" [allowHiding]="false" dataField="code"
										dataType="string" [allowEditing]="false" [allowHeaderFiltering]="false"
										cellTemplate="cellTemplateCode">
										<div *dxTemplate="let element of 'cellTemplateCode'">
											{{element.data.code || ''}}
											<span *ngIf="element.data.members && element.data.members.length > 0">
												<span
													*ngIf="element.data.members[0] && (element.data.members.length === 2 || element.data.members.length >= 3)"
													id="membersToolTip_{{element.data.member_item_guid}}">
													+ {{element.data.members.length-1}}
													{{ element.data.members.length === 2 ? 'Other' : element.data.members.length >= 3
													?
													'Others' : ''}}
												</span>
												<dx-tooltip target="#membersToolTip_{{element.data.member_item_guid}}"
													showEvent="dxhoverstart" hideEvent="dxhoverend" position="right">
													<div *dxTemplate="let d = d; of: 'content'">
														<div class="tooltip-wrapper">
															<div class="greenTipout">
																<ol class="tipList">
																	<li *ngFor="let member of element.data.members; let p = index;">
																		<a>{{member.code || ''}} {{member.name || ''}}</a>
																	</li>
																</ol>
															</div>
														</div>
													</div>
												</dx-tooltip>
											</span>
										</div>
									</dxi-column>

									<dxi-column caption="Member" dataField="name" dataType="string" [allowEditing]="false"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateMemberName">
										<div *dxTemplate="let element of 'cellTemplateMemberName'">
											<span
												*ngIf="(!element.data.members || (element.data.members && element.data.members.length <= 0))">
												{{element.data.name || ''}}
											</span>
										</div>
									</dxi-column>

									<dxi-column caption="SKU" [visible]="isQBOnlineErpUser && !enable_classification_for_rebate"
										[showInColumnChooser]="isQBOnlineErpUser && !enable_classification_for_rebate"
										dataField="itemsku" dataType="string" [allowEditing]="false"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemSku">
										<div *dxTemplate="let element of 'cellTemplateItemSku'">
											{{element.data.itemsku || ''}}
										</div>
									</dxi-column>

									<dxi-column caption="Item" [visible]="!enable_classification_for_rebate"
										[showInColumnChooser]="!enable_classification_for_rebate" [allowHiding]="false"
										dataField="itemname" dataType="string" [allowEditing]="false"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemName">
										<div *dxTemplate="let element of 'cellTemplateItemName'">
											<span *ngIf="rebatesDetails.tiers_type === 'single_items'">
												{{element.data.itemname || ''}}
											</span>
											<span *ngIf="element.data.items && element.data.items.length > 0">
												{{element.data.items && element.data.items.length > 0 ?
												element.data.items[0].itemname : ''}}
												<span
													*ngIf="element.data.items[0] && (element.data.items.length === 2 || element.data.items.length >= 3)"
													id="itemsToolTip_{{element.data.member_item_guid}}">
													+ {{element.data.items.length-1}}
													{{ element.data.items.length === 2 ? 'Other' : element.data.items.length >= 3 ?
													'Others' : ''}}
												</span>
												<dx-tooltip target="#itemsToolTip_{{element.data.member_item_guid}}"
													showEvent="dxhoverstart" hideEvent="dxhoverend" position="right">
													<div *dxTemplate="let d = d; of: 'content'">
														<div class="tooltip-wrapper">
															<div class="greenTipout">
																<ol class="tipList">
																	<li *ngFor="let item of element.data.items; let p = index;">
																		<a>{{item.itemname || ''}}</a>
																	</li>
																</ol>
															</div>
														</div>
													</div>
												</dx-tooltip>
											</span>
										</div>
									</dxi-column>
									<dxi-column caption="Classes" [visible]="enable_classification_for_rebate"
										[showInColumnChooser]="enable_classification_for_rebate" [allowHiding]="false"
										dataField="classname" dataType="string" [allowEditing]="false"
										cellTemplate="cellTemplateClassesName" [allowHeaderFiltering]="false"
										[allowFiltering]="true" [allowSorting]="true">
										<div *dxTemplate="let element of 'cellTemplateClassesName'">
											{{element.data.classname || ''}}
										</div>
									</dxi-column>
									<dxi-column caption="Description" dataField="description" dataType="string"
										[visible]="!enable_classification_for_rebate"
										[showInColumnChooser]="!enable_classification_for_rebate" [allowEditing]="false"
										[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemDesc">
										<div *dxTemplate="let element of 'cellTemplateItemDesc'">
											{{element.data.description || ''}}
										</div>
									</dxi-column>

									<dxi-column caption="Rebate Type" dataField="tier_basis" dataType="string"
										[allowEditing]="true" [allowHiding]="false" [allowFiltering]="false"
										[allowHeaderFiltering]="true" [setCellValue]="setCellValueItemColumnGridExclsnTab"
										cellTemplate="cellTemplateTierBasis">
										<dxo-lookup [dataSource]="listOfTierBasis" valueExpr="code"
											[displayExpr]="getDisplayExprTierbasis">
										</dxo-lookup>
										<div *dxTemplate="let element of 'cellTemplateTierBasis'">
											{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label'
											|
											uppercase}}
										</div>
									</dxi-column>

									<dxi-column caption="Rebate Basis" dataField="rebate_basis" dataType="string"
										[allowEditing]="true" [allowHiding]="false" [allowFiltering]="false"
										[allowHeaderFiltering]="true" [setCellValue]="setCellValueItemColumnGridExclsnTab"
										cellTemplate="cellTemplateRebateBasis">
										<dxo-lookup [dataSource]="listOfRebateBasis" valueExpr="code"
											[displayExpr]="getDisplayExprRebatebasis">
										</dxo-lookup>
										<div *dxTemplate="let element of 'cellTemplateRebateBasis'">
											{{element.data.rebate_basis || '' | filterFromList:listOfRebateBasis : 'code' :
											'label'
											| uppercase}}
										</div>
									</dxi-column>

									<dxi-column caption="UOM" [visible]="!enable_classification_for_rebate"
										[showInColumnChooser]="!enable_classification_for_rebate" dataField="selectedUom"
										dataType="string" [allowEditing]="true" [allowFiltering]="false"
										[allowHeaderFiltering]="true" [setCellValue]="setCellValueItemColumnGridExclsnTab"
										cellTemplate="cellTemplateUomCode">
										<dxo-lookup [dataSource]="listOfUoms" valueExpr="code"
											[displayExpr]="getDisplayExprUomName">
										</dxo-lookup>
										<div *dxTemplate="let element of 'cellTemplateUomCode'">
											<span *ngIf="element.data.rebate_basis === 'dollarperuom'">
												{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' |
												uppercase}}
											</span>
										</div>
									</dxi-column>

									<!--
									<dxi-column caption="List Price" dataField="listPrice"
										[visible]="isShowListPriceColumnExclsnTab" [showInColumnChooser]="" dataType="number" [allowEditing]="true" [allowFiltering]="true" [allowHeaderFiltering]="false"
										[editorOptions]="{ format: 'decimal', showClearButton: true }"
										cellTemplate="cellTemplateListprice">
										<div *dxTemplate="let element of 'cellTemplateListprice'">
											<span
												*ngIf="element.data.tier_basis === 'notiers' && element.data.rebate_basis === 'dollarperuom'">
												{{element.data.listPrice || 0 | customCurrencyPipe}}
											</span>
										</div>
									</dxi-column>
									-->

									<dxi-column caption="Rate" dataField="rate" dataType="number" [allowEditing]="true"
										[allowHiding]="false" [allowFiltering]="true" [allowHeaderFiltering]="false"
										[editorOptions]="{ format: 'decimal', showClearButton: true }"
										cellTemplate="cellTemplateRate">
										<div *dxTemplate="let element of 'cellTemplateRate'">
											<span
												*ngIf="(element.data.rebate_basis === 'dollar' || element.data.rebate_basis === 'dollarperuom')">
												{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
											</span>
											<span
												*ngIf="element.data.rebate_basis === 'percentage' &&  element.data.tier_basis === 'notiers'">
												{{element.data.rate || 0 | customNumberNoRoundingPipe}}%
											</span>
										</div>
									</dxi-column>

									<!--
									<dxi-column caption="Final Price" dataField="rebatefinalprice"
										[visible]="isShowFinalPriceColumnExclsnTab" dataType="number" [allowEditing]="true" [allowFiltering]="true" [allowHeaderFiltering]="false"
										[editorOptions]="{ format: 'decimal', showClearButton: true }"
										cellTemplate="cellTemplateRebateFinalPrice">
										<div *dxTemplate="let element of 'cellTemplateRebateFinalPrice'">
											<span
												*ngIf="element.data.tier_basis === 'notiers' && element.data.rebate_basis === 'dollarperuom'">
												{{element.data.rebatefinalprice || 0 | customCurrencyPipe }}
											</span>
										</div>
									</dxi-column> 
									-->
									<dxi-column type="buttons" caption="Action" [fixed]="true" [allowFiltering]="false"
										[allowHeaderFiltering]="false" [allowSorting]="false" alignment="center"
										[allowResizing]="false" [minWidth]="150" [width]="150" [allowExporting]="false">
										<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip"></dxi-button>
										<dxi-button name="delete" cssClass="dx-grid-delete dx-icon-trash menuTip"></dxi-button>
									</dxi-column>


									<div *dxTemplate="let t of 'tiersTemplate'">
										<dx-data-grid #tiersItemDetailsExclsnTabDataGridRef
											id="tiersItemDetailsExclsnTabDataGridRef_{{t.data.code}}_{{t.data.erp_primary_key}}"
											[dataSource]="t.data.tiers" [remoteOperations]="false"
											(onInitNewRow)="doInitNewRowTiersExclsnTab($event,t)"
											(onRowRemoved)="doRowRemovedTiersExclsnTab($event,t)"
											(onSaved)="doSavedTiersExclsnTab($event,t)" keyExpr="guid" [showBorders]="true"
											[columnAutoWidth]="true"
											[masterDetail]="{ enabled: rebatesDetails.tiers_type === 'multiple_items', template: 'itemsTemplate','autoExpandAll': false }">

											<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
												[useNative]="false"></dxo-scrolling>
											<dxo-filter-row [visible]="false"></dxo-filter-row>
											<dxo-filter-panel [visible]="false"></dxo-filter-panel>
											<dxo-header-filter [visible]="false"></dxo-header-filter>

											<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
												[allowAdding]="!(t.data.tier_basis === 'notiers')">
											</dxo-editing>

											<dxi-column caption="Tier Minimum" dataField="mintier" dataType="number"
												[allowEditing]="true" [allowFiltering]="true" [allowHeaderFiltering]="false"
												[editorOptions]="{ format: 'decimal', showClearButton: true }"
												cellTemplate="cellTemplateMinTier">
												<div *dxTemplate="let element of 'cellTemplateMinTier'">
													{{doCustomizeTextTierMinMax(element.data.mintier || '',t,'mintier') }}
												</div>
											</dxi-column>

											<dxi-column caption="Tier Maximum" dataField="maxtier" [allowEditing]="true"
												[allowFiltering]="true" [allowHeaderFiltering]="false"
												[editorOptions]="{ showClearButton: true }" cellTemplate="cellTemplateMaxTier">
												<dxi-validation-rule type="required"></dxi-validation-rule>
												<div *dxTemplate="let element of 'cellTemplateMaxTier'">
													{{doCustomizeTextTierMinMax(element.data.maxtier || '',t,'maxtier') }}
												</div>
											</dxi-column>

											<dxi-column caption="List Price" dataField="listPrice" [visible]="false"
												dataType="number" [allowEditing]="false" [allowFiltering]="true"
												[allowHeaderFiltering]="false" cellTemplate="cellTemplateTiersListprice">
												<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format> -->
												<div *dxTemplate="let element of 'cellTemplateTiersListprice'">
													{{element.data.listPrice || 0 | customCurrencyPipe:customCurrencyOption }}
												</div>
											</dxi-column>

											<dxi-column caption="Rate" dataField="rate" dataType="number" [allowEditing]="true"
												[allowFiltering]="true" [allowHeaderFiltering]="false"
												[editorOptions]="{ format: 'decimal', showClearButton: true }"
												cellTemplate="cellTemplateTiersRate"
												[visible]="rebatesDetails.tiers_type === 'single_items'">
												<div *dxTemplate="let element of 'cellTemplateTiersRate'">
													<span
														*ngIf="(t.data.rebate_basis === 'dollar' || t.data.rebate_basis === 'dollarperuom')">
														{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
													</span>
													<span *ngIf="t.data.rebate_basis === 'percentage'">
														{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%' }}
													</span>
												</div>
											</dxi-column>

											<dxi-column caption="Final Price" dataField="rebatefinalprice" [visible]="false"
												dataType="number" [allowEditing]="true" [allowFiltering]="true"
												[allowHeaderFiltering]="false" cellTemplate="cellTemplateTiersRebateFinalPrice"
												[editorOptions]="{ format: 'decimal', showClearButton: true }">
												<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format> -->
												<div *dxTemplate="let element of 'cellTemplateTiersRebateFinalPrice'">
													{{element.data.rebatefinalprice || 0 | customCurrencyPipe:customCurrencyOption }}
												</div>
											</dxi-column>

											<dxi-column type="buttons" caption="Action" [allowFiltering]="false"
												[allowHeaderFiltering]="false" [allowSorting]="false" alignment="center"
												[allowResizing]="true" [minWidth]="150" [width]="150" [allowExporting]="false">
												<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip">
												</dxi-button>
												<dxi-button name="delete" cssClass="dx-grid-delete dx-icon-trash menuTip">
												</dxi-button>
											</dxi-column>

											<div *dxTemplate="let item of 'itemsTemplate'">
												<dx-data-grid #itemsOfTiersDataGridRef
													[dataSource]="getItemsByTiersDetailsMembersExclsnTab(item)"
													(onEditorPreparing)="doEditorPreparingItemTierMembersExclsnTab($event,item,t)"
													(onRowUpdated)="doRowUpdatedItemTierMembersExclsnTab($event,item,t)"
													(onRowRemoved)="doRowRemovedItemTierMembersExclsnTab($event,item,t)"
													(onSaved)="doOnSavedItemTierMembersExclsnTab($event,item,t)"
													keyExpr="erp_primary_key" [showBorders]="true" [allowColumnResizing]="true"
													[columnAutoWidth]="true">
													<dxo-filter-row [visible]="true"></dxo-filter-row>
													<dxo-filter-panel [visible]="true"></dxo-filter-panel>
													<dxo-header-filter [visible]="true"></dxo-header-filter>
													<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
														[allowAdding]="false" [useIcons]="true">
													</dxo-editing>

													<dxi-column caption="SKU" dataField="itemsku"
														[visible]="isQBOnlineErpUser && !enable_classification_for_commision"
														dataType="string" [allowEditing]="false" cellTemplate="cellTemplateItemSku"
														[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
														<div *dxTemplate="let element of 'cellTemplateItemSku'">
															{{element.data.itemsku || ''}}
														</div>
													</dxi-column>

													<dxi-column caption="Classes" dataField="classname"
														[visible]="enable_classification_for_rebate" dataType="string"
														[allowEditing]="false" cellTemplate="cellTemplateClassesName"
														[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
														<div *dxTemplate="let element of 'cellTemplateClassesName'">
															{{element.data.classname || ''}}
														</div>
													</dxi-column>

													<dxi-column caption="Item" dataField="itemname"
														[visible]="!enable_classification_for_rebate" dataType="string"
														[allowEditing]="false" editCellTemplate="itemsListEditor"
														cellTemplate="cellTemplateItemName" [allowHeaderFiltering]="false"
														[allowSorting]="true">
														<div *dxTemplate="let element of 'cellTemplateItemName'">
															{{element.data.itemname}}
														</div>
													</dxi-column>


													<dxi-column caption="Description" dataField="description"
														[visible]="!enable_classification_for_rebate" dataType="string"
														[allowEditing]="false" [allowHeaderFiltering]="false" [allowFiltering]="true"
														[allowSorting]="true" cellTemplate="cellTemplateItemDesc">
														<div *dxTemplate="let element of 'cellTemplateItemDesc'">
															{{element.data.description || ''}}
														</div>
													</dxi-column>

													<dxi-column caption="UOM" dataField="selectedUom" [visible]="t.data.rebate_basis === 'dollarperuom' &&
														!enable_classification_for_rebate" dataType="string"
														[allowEditing]="t.data.rebate_basis === 'dollarperuom'"
														[setCellValue]="setCellValueUomColumn" cellTemplate="cellTemplateUomCode"
														[allowHeaderFiltering]="false" [allowFiltering]="false"
														[allowSorting]="false">
														<dxo-lookup [dataSource]="listOfUoms" valueExpr="code"
															[displayExpr]="getDisplayExprUomName">
														</dxo-lookup>
														<div *dxTemplate="let element of 'cellTemplateUomCode'">
															<span *ngIf="t.data.rebate_basis === 'dollarperuom'">
																{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' :
																'name' |
																uppercase}}
															</span>
														</div>
													</dxi-column>

													<dxi-column
														[caption]="'Rate ' + (t.data.rebate_basis === 'dollar' ? '('+globalCurrencySymbol+')' : t.data.rebate_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')')"
														dataField="rate" dataType="number" [allowEditing]="true"
														[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false"
														[editorOptions]="{ format: 'decimal', showClearButton: true }"
														cellTemplate="cellTemplateItemRate" [width]="100">
														<dxi-validation-rule type="required"></dxi-validation-rule>
														<div *dxTemplate="let element of 'cellTemplateItemRate'">
															<span
																*ngIf="(t.data.rebate_basis === 'dollar' || t.data.rebate_basis === 'dollarperuom')">
																{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
															</span>
															<span *ngIf="t.data.rebate_basis === 'percentage'">
																{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%' }}
															</span>
														</div>
													</dxi-column>

													<dxi-column type="buttons" caption="Action" [allowFiltering]="false"
														[allowHeaderFiltering]="false" [fixed]="true" fixedPosition="right"
														[allowSorting]="false" alignment="center" [allowResizing]="false"
														[minWidth]="150" [width]="150" [allowExporting]="false">
														<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
														<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
													</dxi-column>

												</dx-data-grid>
											</div>
										</dx-data-grid>
									</div>
								</dx-data-grid>

								<div *ngIf="isUploadExcelCsvFileItmGrpTab" class="noteBx mrgT10 mrgB0">
									<span>
										<b>{{noOfUnmatchedItem || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b>
										Member(s) /
										Item(s)
										ignored
										<span class="noteSeprator"></span>
										<b>{{noOfUnmatchedItemUom || 0}}</b> UOM(s) out of <b>{{noOfTotalItem || 0}}</b> UOM(S)
										ignored
										<span *ngIf="rebatesDetails.individualOrGroup === 'buyinggroup'"
											class="noteSeprator"></span>
										<span *ngIf="rebatesDetails.individualOrGroup === 'buyinggroup'">
											<b>{{noOfmatchedItemPrice || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b>
											item(S)
											Rebate ignored
										</span>
										<strong *ngIf="listOfCsvRowForItmGrpTab && listOfCsvRowForItmGrpTab.length > 0"
											(click)="doShowCsvItemsListItmGrpTab()" class="pointerMark">Click here to
											view</strong>
									</span>
								</div>
								<div class="cf"></div>
							</div>
						</div>
						<!-- #endregion  -->

						<!-- #region for Terms and Conditions -->
						<div *ngIf="currentAgreementTab === 'terms_and_conditions'" class="mrgB20">
							<div>
								<span class="highlight noteBx mrgT10 mrgB10">
									<span class="mrgR10"><strong>Note: </strong></span>
									<span>Use [RATE] in your contract text to
										insert a table of rate tiers from the incentX contract</span>
								</span>
							</div>
							<div class="row">
								<div class="cols12">
									<dx-html-editor [(value)]="rebatesDetails.terms_and_conditions"
										[readOnly]="!isEnabledTermsAndConditions" [height]="400">
										<dxo-toolbar>
											<dxi-item name="undo"></dxi-item>
											<dxi-item name="redo"></dxi-item>
											<dxi-item name="separator"></dxi-item>
											<dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
											<dxi-item name="separator"></dxi-item>
											<dxi-item name="bold"></dxi-item>
											<dxi-item name="italic"></dxi-item>
											<dxi-item name="strike"></dxi-item>
											<dxi-item name="underline"></dxi-item>
											<dxi-item name="separator"></dxi-item>
											<dxi-item name="alignLeft"></dxi-item>
											<dxi-item name="alignCenter"></dxi-item>
											<dxi-item name="alignRight"></dxi-item>
											<dxi-item name="alignJustify"></dxi-item>
											<dxi-item name="separator"></dxi-item>
											<dxi-item name="orderedList"></dxi-item>
											<dxi-item name="bulletList"></dxi-item>
										</dxo-toolbar>
										<dxo-media-resizing [enabled]="true"></dxo-media-resizing>
									</dx-html-editor>
								</div>
							</div>
						</div>
						<!-- #endregion -->

						<div class="alignRight" *ngxPermissionsOnly="isCurrentTabPermission">
							<dx-button *ngIf="!isShowMultipleBtns" class="primaryBtn" id="submitBtn" text="Submit"
								(onClick)="saveRebatesContract()" type="normal" stylingMode="contained" icon="save"
								[useSubmitBehavior]="false">
							</dx-button>

							<dx-drop-down-button *ngIf="isShowMultipleBtns" [dropDownOptions]="{ width: 220 }"
								[splitButton]="true" [showArrowIcon]="isShowArrowIconDrpDwnBtn" [useSelectMode]="true"
								[selectedItemKey]="rebatesDetails.status" [text]="getSubmitBtnText()"
								[items]="listOfRebateStatusesBtn" displayExpr="label" keyExpr="value"
								(onButtonClick)="doSubmitBtnClick($event)" (onItemClick)="doChangeRebateStatus($event)"
								class="button dxSubmitBtn submitDDBtn" [width]="220">
							</dx-drop-down-button>
						</div>
					</div>
				</div>
			</div>
			<!--End Rebates details content -->
		</div>
		<!--Close settlement list-->
		<div *ngIf="currentMainTab === 'settlement_period'">
			<app-rebate-tierbase-close-settlement-list-tab *ngIf="isShowBlockSettlementPeriods"
				[rebatesDetails]="rebatesDetails" [rebatesRequest]="rebatesRequest" [salesPplList]="salesPplList"
				[isCurrentTabPermission]="isCurrentTabPermission"></app-rebate-tierbase-close-settlement-list-tab>

			<app-rebate-tierbase-credit-memo-list-tab *ngIf="!isShowBlockSettlementPeriods"
				[rebatesDetails]="rebatesDetails" [rebatesRequest]="rebatesRequest" [salesPplList]="salesPplList"
				[isCurrentTabPermission]="isCurrentTabPermission"></app-rebate-tierbase-credit-memo-list-tab>
		</div>
		<!--End close settlement list-->
	</div>
</div>

<app-view-rebate-members-list-dialog *ngIf="isShowViewRebateMembersListPopup"
	[viewRebateMembersListProps]="viewRebateMembersListProps"
	(closeViewRebateMembersListPopup)="doCloseViewRebateMembersListPopup($event)"></app-view-rebate-members-list-dialog>

<app-view-rebate-credit-memo-for-past-invoices-list-dialog *ngIf="isShowViewRebateCreditMemoForPastInvoicesListPopup"
	[viewRebateCreditMemoInvoicesListProps]="viewRebateCreditMemoForPastInvoicesListProps"
	(closeViewRebateCreditMemoInvoicesListPopup)="doCloseViewRebateCreditMemoInvoicesListPopup($event)"></app-view-rebate-credit-memo-for-past-invoices-list-dialog>

<app-upload-rebate-exclusion-items-dialog *ngIf="isUploadRebateExclusionItemsPopup"
	[uploadRebateExclusionItemsProps]="uploadRebateExclusionItemsProps"
	(closeUploadRebateExclusionItemsPopup)="doCloseUploadRebateExclusionItemsPopup($event)"></app-upload-rebate-exclusion-items-dialog>

<app-view-previous-settlement-period-dialog *ngIf="isViewPreviousSettlementPeriodPopup"
	[viewPreviousSettlementPeriodProps]="viewPreviousSettlementPeriodProps"
	(closeViewPreviousSettlementPeriodPopup)="doCloseViewPreviousSettlementPeriodPopup($event)"></app-view-previous-settlement-period-dialog>

<app-close-current-settlement-period-confirm-dialog *ngIf="isViewCloseCurrentSettlementPeriodPopup"
	[currentStlmtPeriodProps]="currentStlmtPeriodProps"
	(closeCurrentStlmtPeriodPopup)="doCloseCurrentStlmtPeriodPopup($event)"></app-close-current-settlement-period-confirm-dialog>