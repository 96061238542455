<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="isEditMode ? 'Edit Task':'Add Task'" [showCloseButton]="true"
	[(visible)]="isShowPopup" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="row mrgT10">
				<div class="cols12 colsP0">
					<div class="cols6">
						<div class="dx-field mrgB20" [ngClass]="{'inputDisable':isReadOnlyClient}">
							<div class="dx-field-label">Company</div>
							<div class="dx-field-value">
								<dx-drop-down-box valueExpr="guid" displayExpr="name" [(value)]="aSelKeysCompany"
									placeholder="Select a company..." [deferRendering]="true" [showClearButton]="true"
									[dataSource]="clientsList" [disabled]="isReadOnlyClient" [(opened)]="isDropdownOpenedCompany"
									(onOptionChanged)="doCompanydropdownOptionChanged($event)">
									<div *dxTemplate="let data of 'content'">
										<dx-data-grid [dataSource]="clientsList" keyExpr="guid" [selection]="{ mode: 'single'}"
											[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
											[filterRow]="{ visible: true }" [height]="345" [filterSyncEnabled]="true"
											[filterValue]="taskFilterValue" [remoteOperations]="true" [wordWrapEnabled]="true"
											[showBorders]="true" [(selectedRowKeys)]="aSelKeysCompany"
											(onSelectionChanged)="doSelectionChangedCompany($event)">
											<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
											<dxi-column dataField="code" [visible]="!isQBErpUser" caption="ID"
												cellTemplate="cellTemplateCode">
												<div *dxTemplate="let element of 'cellTemplateCode'">
													{{element.data.code || ''}}
												</div>
											</dxi-column>
											<dxi-column dataField="name" caption="Company" cellTemplate="cellTemplateCompanyName">
												<div *dxTemplate="let element of 'cellTemplateCompanyName'">
													{{element.data.name || ''}}
												</div>
											</dxi-column>
											<dxi-column dataField="crm_type" caption="Type" cellTemplate="cellTemplateCrmType">
												<div *dxTemplate="let element of 'cellTemplateCrmType'">
													<span class="highlight">
														<strong *ngIf="element.data.crm_type == 'clients'">Customer</strong>
														<strong *ngIf="element.data.crm_type == 'prospects'">Prospect</strong>
														<strong *ngIf="element.data.crm_type == 'leads'">Lead</strong>
													</span>
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
								</dx-drop-down-box>
							</div>
						</div>
					</div>
					<div class="cols6">
						<div class="dx-field mrgB20" [ngClass]="{'inputDisable':isReadOnlyClient}">
							<div class="dx-field-label">Contact Person</div>
							<div class="dx-field-value" style="width: 100%;">
								<dx-select-box [(value)]="taskInsertUpdateUI.guidcontactperson" [dataSource]="contactemployees"
									[displayExpr]="getContactEmployeeNameDisplayExpr" valueExpr="guid" searchMode="contains"
									searchExpr="selectTaskType" [searchTimeout]="200" [minSearchLength]="0"
									[searchEnabled]="true" [disabled]="isReadOnlyClient" name="taskfor">
								</dx-select-box>
							</div>
						</div>
					</div>
				</div>
				<div class="cols12">
					<div class="dx-field mrgB20" [ngClass]="{'inputDisable':isAllReadOnlyForClosedTask}">
						<div class="dx-field-label">Task Title</div>
						<div class="dx-field-value">
							<dx-text-box mode="text" [(value)]="taskInsertUpdateUI.title" [showClearButton]="true"
								[maxLength]="100" name="tasktitle" [disabled]="isAllReadOnlyForClosedTask">
							</dx-text-box>
						</div>
					</div>
				</div>
				<div class="cols6">
					<div class="dx-field mrgB20" [ngClass]="{'inputDisable':isAllReadOnlyForClosedTask}">
						<div class="dx-field-label">Task Type</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-select-box [(value)]="taskInsertUpdateUI.tasktype" [dataSource]="listOfTaskTypes"
								displayExpr="label" valueExpr="value" searchMode="contains" searchExpr="selectTaskType"
								[searchTimeout]="200" [minSearchLength]="0" [searchEnabled]="true"
								[disabled]="isAllReadOnlyForClosedTask" name="selectTaskType">
							</dx-select-box>
						</div>
					</div>
				</div>
				<div class="cols6">
					<div class="dx-field mrgB20" [ngClass]="{'inputDisable':isAllReadOnlyForClosedTask}">
						<div class="dx-field-label">Assigned To</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-select-box [(value)]="taskInsertUpdateUI.assignto" [dataSource]="listOfAssignToFiltered"
								[displayExpr]="getAssignToDisplayExpr" valueExpr="sub_account_id" searchMode="contains"
								searchExpr="selectTaskType" [searchTimeout]="200" [minSearchLength]="0" [searchEnabled]="true"
								[disabled]="isAllReadOnlyForClosedTask" name="selectAssignTo">
							</dx-select-box>
						</div>
					</div>
				</div>
				<div class="cols6 datePicker">
					<div class="dx-field feildCol" [ngClass]="{'inputDisable':isAllReadOnlyForClosedTask}">
						<div class="dx-field-label">Due Date</div>
						<div class="dx-field-value">
							<!-- (onValueChanged)="doSetSettlementStartDate($event)" valueChangeEvent="change" -->
							<dx-date-box [(value)]="taskInsertUpdateUI.enddate" type="date" displayFormat="yyyy-MM-dd"
								[disabled]="isAllReadOnlyForClosedTask">
							</dx-date-box>
						</div>
					</div>
				</div>
				<div class="cols6 datePicker">
					<div class="dx-field mrgB20" [ngClass]="{'inputDisable':isAllReadOnlyForClosedTask}">
						<div class="dx-field-label">Due Time</div>
						<div class="dx-field-value" style="width: 100%;">
							<dx-select-box [(value)]="taskInsertUpdateUI.timeinterval" [dataSource]="listOfTimeInvervals"
								displayExpr="name" valueExpr="id" searchMode="contains" searchExpr="selectTaskTime"
								[searchTimeout]="200" [minSearchLength]="0" [searchEnabled]="true"
								[disabled]="isAllReadOnlyForClosedTask" name="timeinterval">
							</dx-select-box>
						</div>
					</div>
				</div>
				<div class=" cols12">
					<div class="dx-field textareaCols mrgB20" [ngClass]="{'inputDisable':isAllReadOnlyForClosedTask}">
						<div class="dx-field-label">Notes</div>
						<div class="dx-field-value">
							<dx-text-area [(value)]="taskInsertUpdateUI.notes" [disabled]="isAllReadOnlyForClosedTask"
								[height]="90" [maxLength]="500" name="notes">
							</dx-text-area>
						</div>
					</div>
				</div>

				<div class="cols12 mrgT10" [hidden]="!isAllReadOnlyForClosedTask">
					<div class="dx-field mrgB20">
						<div class="dx-field-label taskstatus">Task Status :</div>
						<div class="dx-field-value">
							<dx-radio-group [(value)]="taskInsertUpdateUI.status" [dataSource]="taskStatuses"
								(onValueChanged)="doChangedTaskStatus($event)" [disabled]="true" valueChangeEvent="change"
								displayExpr="name" valueExpr="id" layout="horizontal" name="taskstatus">
							</dx-radio-group>
						</div>
					</div>
				</div>
				<div class=" cols12" [hidden]="isDisplayRemark">
					<div class="dx-field textareaCols mrgB20">
						<div class="dx-field-label">Closed Task Note</div>
						<div class="dx-field-value">
							<dx-text-area [(value)]="taskInsertUpdateUI.closedtasknote" [height]="90" [maxLength]="500"
								name="closedtasknote">
							</dx-text-area>
						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="saveupdateTask()" text="Submit" class="button primaryBtn" icon="save"></dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>