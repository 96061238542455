import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { ViewEndusersInvoiceDetailPopUpModel, ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';

@Component({
	selector: 'app-view-end-users-invoice-detail-popup',
	templateUrl: './view-end-users-invoice-detail-popup.component.html',
	styleUrls: ['./view-end-users-invoice-detail-popup.component.css'],
	providers: [],
})
export class ViewEndUsersInvoiceDetailPopupComponent implements OnInit, OnDestroy {
	public pageTitle: string = '';
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean = true;
	@Input() viewEndusersInvoiceDetailProps: ViewEndusersInvoiceDetailPopUpModel;
	@Output() viewEndusersInvoiceDetailPopup = new EventEmitter<any>();

	private erpType: string;
	public myMath = Math;
	public erpTypeValEnum = ErpTypeValEnum;
	public isShowCustomerID: boolean;
	public endUserInvoiceDetails: any;
	public listOfUoms: any[] = [];
	private fetchEndUserInvoiceDetailsSbsn: Subscription;

	public isResponse: boolean = false;
	public erp_type: string = '';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	@ViewChild('itemsListOriginDatagridRef') itemsListOriginDatagridRef: DxDataGridComponent;

	public decimalPointPercentageFormat: string = '1.2-2';
	public decimalPointForCurrency: number;

	public endUserInvoiceCustomerDetails: any;
	private fetchCustomerDetailsSbsn: Subscription;
	public enabled_tax_calculation_feature: boolean;
	public isShowTaxCodeColumn: boolean;
	private getEndUserDetailsSbsn: Subscription;
	public endUserDetails: any;
	public listOfPricelist: any[] = [];
	private getListOfPricelistSbsn: Subscription;

	private listOfFeatureCustomFields: any[] = [];
	private listOfEndUsersShipToFeatureCustomFields: any[] = [];
	private listOfItemsFeatureCustomFields: any[] = [];
	public listOfShipToFeatureCustomFields: any[] = [];
	private getFetureCustomFieldsSbsn: Subscription;
	public endUserShipToDetails: any;
	private getItemDetailsSbsn: Subscription;
	private listOfItemDetails: any[] = [];
	public isShowDatagrid: boolean = false;

	constructor(private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
		public _AppCommonSrvc: AppCommonSrvc) {
	}

	ngOnInit(): void {
		if (this.viewEndusersInvoiceDetailProps.docType == 'enduserinvoice') {
			this.pageTitle = 'End-User Invoice Detail'
			this.preInit();
		}
	}
	ngOnDestroy() {
		if (this.fetchEndUserInvoiceDetailsSbsn) {
			this.fetchEndUserInvoiceDetailsSbsn.unsubscribe();
		}
		if (this.fetchCustomerDetailsSbsn) {
			this.fetchCustomerDetailsSbsn.unsubscribe();
		}
		if (this.getEndUserDetailsSbsn) {
			this.getEndUserDetailsSbsn.unsubscribe();
		}
		if (this.getListOfPricelistSbsn) {
			this.getListOfPricelistSbsn.unsubscribe();
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
	}

	public doCloseInvoiceDetailCreditMemoPopup(e) {
		this.isShowPopup = false;
		this.viewEndusersInvoiceDetailPopup.emit({ isSuccess: true });
	}

	private preInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.setErpType();
		if (this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.hasOwnProperty('tax_calculation_feature')) {
			this.enabled_tax_calculation_feature = this._LoginService.loginUser.account_detail.tax_calculation_feature || false;
		}
		this.listOfUoms = this._DataService.getUOMs();
		this.doGetFetureCustomFields();
	}

	private setErpType() {
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erpType = '';
		}

		this.isShowCustomerID = true;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
			this.isShowCustomerID = false;
		}
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
			this.isShowCustomerID = false;
		}
		if (this.erpType === ErpTypeValEnum.NETSUITE) {
			this.isShowCustomerID = false;
		}
	}

	private fetchEndUserInvoiceDetails() {
		const search = [];
		search.push(['docnum', 'contains', this.viewEndusersInvoiceDetailProps.docNo]);
		const invoicesFormData = new FormData();
		invoicesFormData.append('usr', this._LoginService.loginUser.user);
		invoicesFormData.append('token', this._LoginService.loginUser.token);
		invoicesFormData.append('entity', ServerEntity.ENDUSER_INVOICES);
		invoicesFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		invoicesFormData.append('filter', JSON.stringify(search));

		if (this.fetchEndUserInvoiceDetailsSbsn) {
			this.fetchEndUserInvoiceDetailsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.isResponse = false;
		this.fetchEndUserInvoiceDetailsSbsn = this._RestApiService.doDataOutReqFormData(invoicesFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				this.isResponse = true;
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const invoiceDetails = response.data[0];
						this.endUserInvoiceDetails = invoiceDetails;
						this.doFetchedItemDetails();
						this.doHideShowTaxCodeColumn();
						this.fetchedEndUserDetails();
						this.fetchedCustomerDetails();
					} else {
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this.isResponse = true;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private fetchedEndUserDetails() {
		const searchParams = [];
		if (this.endUserInvoiceDetails.cardcode) {
			searchParams.push(['code', 'contains', this.endUserInvoiceDetails.cardcode]);
		}
		// reqFormData = this.loadSalesDetailsData();
		const endUserFormData = new FormData();
		endUserFormData.append('usr', this._LoginService.loginUser.user);
		endUserFormData.append('token', this._LoginService.loginUser.token);
		endUserFormData.append('entity', ServerEntity.ENDUSERS);
		endUserFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		if (searchParams && searchParams.length > 0) {
			endUserFormData.append('filter', JSON.stringify(searchParams));
		}
		if (this.getEndUserDetailsSbsn) {
			this.getEndUserDetailsSbsn.unsubscribe();
		}

		this._LoaderService.show();
		this.getEndUserDetailsSbsn = this._RestApiService.doDataOutReqFormData(endUserFormData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const endUserDetails = response.data[0];
						this.endUserDetails = endUserDetails;
						// Set Billing/Shipping Address
						this.setBillingAddress(endUserDetails);
						this.setDynamicAddresses();
						this.fetchedListOfPrices();
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			},
			error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}


	// Method To Set billing Address
	private setBillingAddress(client: any) {
		if (this.endUserInvoiceDetails.paytocode && client.address && client.address.billto && client.address.billto.length > 0) {
			const clientAddressData = client.address.billto.filter(itemAddress => itemAddress.addressname === this.endUserInvoiceDetails.paytocode);
			this.endUserInvoiceDetails['billtodata'] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.endUserInvoiceDetails["billtodata"] = clientAddressData[0];
			}
		}
	}

	private setDynamicAddresses() {
		this.endUserShipToDetails = null;
		this.listOfShipToFeatureCustomFields = [];
		let shipToAddressIndex = -1;
		if (this.listOfEndUsersShipToFeatureCustomFields && this.listOfEndUsersShipToFeatureCustomFields.length > 0) {
			for (let i = 0; i < this.listOfEndUsersShipToFeatureCustomFields.length; i++) {
				const customField = this.listOfEndUsersShipToFeatureCustomFields[i];
				if (this.endUserInvoiceDetails && this.endUserInvoiceDetails.hasOwnProperty(customField.ix_custom_field)) {
					const shipToIndex = this.endUserDetails.address.shipto.findIndex(ship => ship[customField.ix_custom_field] === this.endUserInvoiceDetails[customField.ix_custom_field]);
					if (shipToIndex !== -1) {
						shipToAddressIndex = shipToIndex;
						break;
					}
				}
			}
		}
		if (shipToAddressIndex !== -1) {
			this.endUserShipToDetails = {};
			if (this.endUserDetails && this.endUserDetails.address && this.endUserDetails.address.shipto && this.endUserDetails.address.shipto.length > 0) {
				this.endUserShipToDetails = this.endUserDetails.address.shipto[shipToAddressIndex];
			}
			for (let i = 0; i < this.listOfEndUsersShipToFeatureCustomFields.length; i++) {
				const customField = this.listOfEndUsersShipToFeatureCustomFields[i];
				if (this.endUserShipToDetails && this.endUserShipToDetails.hasOwnProperty(customField.ix_custom_field)) {
					const newCustFields: any = {
						guid: customField.guid,
						custom_value: null,
						custom_display_value: null,
						custom_label: null
					}
					newCustFields.custom_label = customField.ix_custom_field_title;
					if (customField.custom_field_title) {
						newCustFields.custom_label = customField.custom_field_title;
					}
					newCustFields.custom_value = this.endUserShipToDetails[customField.ix_custom_field];
					newCustFields.custom_display_value = this.endUserShipToDetails[customField.ix_custom_field] || '';
					if (customField.custom_field_output_format === 'percentage') {
						newCustFields.custom_display_value = this._CustomNumberNoRoundingPipe.transform(this.endUserShipToDetails[customField.ix_custom_field] || 0) + '%';
					}
					if (customField.custom_field_output_format === 'amount') {
						newCustFields.custom_display_value = this._CustomCurrencyPipe.transform(this.endUserShipToDetails[customField.ix_custom_field] || 0);
					}
					if (customField.custom_field_output_format === 'boolean') {
						newCustFields.custom_display_value = 'No';
						if (this.endUserShipToDetails[customField.ix_custom_field]) {
							newCustFields.custom_display_value = 'Yes';
						}
					}
					this.listOfShipToFeatureCustomFields.push(newCustFields);
				}
			}
		}
	}

	private doGetFetureCustomFields() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		// formData.append('search', JSON.stringify([searchCriteria]));
		if (this.getFetureCustomFieldsSbsn) {
			this.getFetureCustomFieldsSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getFetureCustomFieldsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfFeatureCustomFields = response.data || [];
						this.doSetItemAndEndUsersFeatureCustomFields();
					}
				}
				this.fetchEndUserInvoiceDetails();
			}, error: (error) => {
				this._LoaderService.hide();
				this.fetchEndUserInvoiceDetails();
			}
		});
	}

	private doSetItemAndEndUsersFeatureCustomFields() {
		this.listOfItemsFeatureCustomFields = [];
		this.listOfEndUsersShipToFeatureCustomFields = [];
		if (this.listOfFeatureCustomFields && this.listOfFeatureCustomFields.length > 0) {
			this.listOfFeatureCustomFields.map(field => {
				if (field.display === true && field.external_entity_field === true) {
					if (field.entity_type === 'items') {
						this.listOfItemsFeatureCustomFields.push(field);
					}
					if (field.entity_type === 'endusers' && field.section === "SHIPTOADDRESS") {
						this.listOfEndUsersShipToFeatureCustomFields.push(field);
					}
				}
			});
		}
	}

	// Method To Get List of Prices from Clients's pricelistnum
	private fetchedListOfPrices(pricelistnum?: string) {
		let pricelistno = '';
		this.listOfPricelist = [];
		if (pricelistnum) {
			pricelistno = pricelistnum;
		} else {
			pricelistno = this.endUserDetails.pricelistnum;
		}
		const viewFields = ['pricelistno', 'pricelistname'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.PRICELISTS);
		formdata.append('is_dropdown', '1');
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistno });
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));

		if (this.getListOfPricelistSbsn) {
			this.getListOfPricelistSbsn.unsubscribe();
		}
		this.getListOfPricelistSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: response => {
				if (response) {
					if (response.flag) {
						this.listOfPricelist = response.data;
					}
				}
			},
			error: error => {
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private fetchedCustomerDetails() {
		if (!this.endUserInvoiceDetails.customer) {
			return;
		}
		const searchParams = [];
		if (this.endUserInvoiceDetails.customer) {
			searchParams.push({ 'code': this.endUserInvoiceDetails.customer });
		}
		// reqFormData = this.loadSalesDetailsData();
		const endUserFormData = new FormData();
		endUserFormData.append('usr', this._LoginService.loginUser.user);
		endUserFormData.append('token', this._LoginService.loginUser.token);
		endUserFormData.append('entity', ServerEntity.CLIENTS);
		endUserFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		endUserFormData.append('view_fields', JSON.stringify(['guid', 'code', 'name']));
		if (searchParams && searchParams.length > 0) {
			endUserFormData.append('search', JSON.stringify(searchParams));
		}
		if (this.fetchCustomerDetailsSbsn) {
			this.fetchCustomerDetailsSbsn.unsubscribe();
		}
		this.fetchCustomerDetailsSbsn = this._RestApiService.doDataOutReqFormData(endUserFormData).subscribe({
			next: response => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const customerDetails = response.data[0];
						this.endUserInvoiceCustomerDetails = customerDetails;
					}
				} else {
				}
			},
			error: error => {
				// this._LoaderService.hide();
			}
		});
	}

	private doHideShowTaxCodeColumn() {
		let isExistsTaxCode = false;
		if (this.endUserInvoiceDetails.documentlines && this.endUserInvoiceDetails.documentlines.length > 0 && this.enabled_tax_calculation_feature) {
			this.endUserInvoiceDetails.documentlines.forEach(dl => {
				if (dl.hasOwnProperty('taxcode') && dl.taxcode) {
					isExistsTaxCode = true;
				}
			});
		}
		this.isShowTaxCodeColumn = false;
		if (isExistsTaxCode) {
			this.isShowTaxCodeColumn = true;
		}
	}

	public doCustomizeTextToPercent(cellInfo) {
		return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	public doInitializedInvoiceDocLineItem(event) {
		const customFieldDataList = this.getFeatureCustomFieldForDocLineItems();
		customFieldDataList.forEach(element => {
			event.component.addColumn(element);
		});
	}

	private doFetchedItemDetails() {
		let listOfErpPrimaryKeys = [];
		if (this.endUserInvoiceDetails.documentlines && this.endUserInvoiceDetails.documentlines.length > 0) {
			listOfErpPrimaryKeys = this.endUserInvoiceDetails.documentlines.map(dl => {
				return dl.erp_primary_key;
			});
		}
		if (listOfErpPrimaryKeys && listOfErpPrimaryKeys.length <= 0) {
			return;
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ITEM_DETAILS);
		formData.append('erp_primary_key', JSON.stringify(listOfErpPrimaryKeys));
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		this.isShowDatagrid = false;
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					this.listOfItemDetails = response.data || [];
					this.doOverwriteItemDetailsValueToDocumentLineWithCustomFields();
				}
				this.isShowDatagrid = true;
			}, error: (error) => {
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				this.isShowDatagrid = true;
			}
		});
	}

	private getFeatureCustomFieldForDocLineItems() {
		let customFieldDataObj: any;
		let customFieldDataList: any[] = [];
		if (this.listOfItemsFeatureCustomFields && this.listOfItemsFeatureCustomFields.length > 0) {
			for (let i = 0; i < this.listOfItemsFeatureCustomFields.length; i++) {
				const customField = this.listOfItemsFeatureCustomFields[i];
				const itemIndex = this.listOfItemDetails.findIndex(item => item.hasOwnProperty(customField.ix_custom_field));
				if (itemIndex !== -1) {
					customFieldDataObj = {
						dataField: customField.ix_custom_field,
						caption: customField['custom_field_title'] ? customField.custom_field_title : customField.ix_custom_field_title,
						dataType: customField.custom_field_type,
						visible: customField.display,
						allowFiltering: true,
						visibleIndex: 3 + i,
						width: 'auto'
					};

					if (customField.custom_field_output_format === 'text' || customField.custom_field_output_format === 'string') {
						customFieldDataObj.dataType = 'string';
						customFieldDataObj['encodeHtml'] = false;
					}

					if (customField.custom_field_type === 'date') {
						customFieldDataObj['format'] = 'yyyy-MM-dd';
					}
					//if (responseData[i].ix_custom_field === 'over_pricelist_amount' || responseData[i].ix_custom_field === 'custom_doctotal' || responseData[i].ix_custom_field === 'base_profit_amount') {
					if (customField.custom_field_output_format === 'amount') {
						customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
					}
					if (customField.custom_field_output_format === 'percentage') {
						customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
					}
					//}
					if (customField.custom_field_type === 'object') {
						if (customField.custom_field_output_format === 'object') {
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key" };
							customFieldDataObj.dataField = customField.ix_custom_field + '.refName';
						}
						if (customField.custom_field_output_format === 'amount') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
						}
						if (customField.custom_field_output_format === 'percentage') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
						}
					}
					const isCustomFieldExists = customFieldDataList.findIndex(cl => cl.dataField === customField.ix_custom_field);
					if (isCustomFieldExists === -1) {
						customFieldDataList.push(customFieldDataObj);
					}
				}
			}
		}
		return customFieldDataList;
	}

	private doOverwriteItemDetailsValueToDocumentLineWithCustomFields() {
		if (this.endUserInvoiceDetails.documentlines && this.endUserInvoiceDetails.documentlines.length > 0) {
			this.endUserInvoiceDetails.documentlines.forEach(dl => {
				const itemIndex = this.listOfItemDetails.findIndex(item => item.erp_primary_key === dl.erp_primary_key);
				if (itemIndex !== -1) {
					this.listOfItemsFeatureCustomFields.forEach(customField => {
						if (this.listOfItemDetails[itemIndex].hasOwnProperty(customField.ix_custom_field)) {
							dl[customField.ix_custom_field] = this.listOfItemDetails[itemIndex][customField.ix_custom_field];
						}
					});
				}
			});
		}
	}
}