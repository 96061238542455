<dx-popup [width]="'1200px'" [dragEnabled]="true" [hideOnOutsideClick]="false" [title]="pageTitle"
	[showCloseButton]="true" [(visible)]="isShowPopup" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div *ngIf="actionFor === 'showUploadFile'" class="forCsv">
				<!-- <div class="row">
					<div class="cols12">
						<span class="highlight">
							<span class="mrgR10">Note: </span>
							Column titles should be <i>item, uom </i> <i *ngIf="billbackType === 'enduserschargeback'">,
								end_user</i>
							and
							<i>quantity</i>.
						</span>
					</div>
				</div> -->

				<div class="row dragdropfullwidth">

					<div class="cols6">
						<div class="feildCol inputDisableFile mrgT0">
							<div class="fileUpbtn">
								<input type="file" [(ngModel)]="chargeDocumentsTabModel.documentURL"
									accept=".doc,.docx,.csv,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
									(change)="uploadDocument($any($event.target).files)" />
								<span class="fileNamelabel">{{chargeDocumentsTabModel.documentURL}}</span>
								<span class="upldBtn">Browse</span>
							</div>
							<div class="inputField">
								<label for="rate">Reference Document</label>
							</div>
						</div>
					</div>

					<div [ngClass]="enable_chargeback_look_back_period ? 'cols4' : 'cols6'">
						<div class="dx-field">
							<div class="dx-field-label"> Document Reference No.</div>
							<dx-text-box mode="text" [(value)]="chargeDocumentsTabModel.documentReferenceNumber"
								maxLength="500">
							</dx-text-box>
						</div>
					</div>

					<div class="cols2" *ngIf="enable_chargeback_look_back_period">
						<div class="datePicker">
							<dx-date-box [(value)]="claim_date" type="date" displayFormat="yyyy-MM-dd">
							</dx-date-box>
							<div class="inputField inputFieldFilterLbl">
								<label for="inputFieldFilterLbl">Claim Date</label>
							</div>
						</div>
					</div>

					<div class="cols12">
						<div class="dx-field">
							<div class="dx-field-label"> User Note</div>
							<div class="dx-field-value">
								<dx-text-area [height]="90" [maxLength]="500"
									[(value)]="chargeDocumentsTabModel.claimedUserNote" name="description">
								</dx-text-area>
							</div>
						</div>
					</div>

					<div class="cols12 colDrag">
						<h2>
							Upload Claim File
						</h2>
						<div class="noteBx mrgB20 mrg0 w100p">
							<span>
								<strong>Note:</strong>
								Required column titles: <i>item, uom </i>
								<i *ngIf="billbackType === 'enduserschargeback'">,end_user</i>
								and
								<i>quantity</i>.
							</span><br>
							<span>
								Optional column title: <i>shortpay_code, amount</i>.
							</span>
						</div>
						<div class="center">
							<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="doOnFileDrop($event)"
								[showBrowseBtn]="false" accept="csv" [multiple]="false" class="fileUploadInput">
								<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
									<div class="csvUpload">
										<div class="uploadLeft">
											<span class="icon-upload"></span>
											<h4>Drag & Drop XLS | XLSX | CSV File Here</h4>
										</div>
										<span class="orStyle">OR</span>
										<div class="uploadRight">
											<button type="button" (click)="openFileSelector()" class="addFileInput"><span
													class="addIcon"></span>
												Browse Files</button>
											<span class="supprtInst">Supported up to 25 MB</span>
										</div>
									</div>
								</ng-template>
							</ngx-file-drop>
							<!-- <input type="file" (change)="changeListener($event)" #input
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
						</div>

						<div *ngIf="uploadedCsvFilesData">
							<div class="fileImage">
								<span class="icon-csv_file"></span>
							</div>
							<div class="fileDetail">
								<h4>{{uploadedCsvFilesData?.name }}</h4>
								<div class="fileProgress">
									<span></span>
								</div>
								<div class="fileProgText">
									{{ uploadedCsvFilesData?.size }} bytes
									<!-- <span>100% uploaded</span> -->
								</div>
								<span class="sprite closeIcon" (click)="doRemoveUploadFiles()"></span>
							</div>
						</div>

					</div>
					<!-- short pay task list section -->
					<div class="cols12" *ngIf="shortPayTaskDataSource?.length > 0">
						<h2 class="mrgT10">
							Do you want this claim to settle an open short pay task?
						</h2>
						<!-- <dx-tag-box [value]="selectedListOfShortPayTaskTags" [grouped]="true" class="dx-ix removedCloseIcon">
						</dx-tag-box> -->
						<dx-data-grid #listOfShortPayTaskDataGrid [dataSource]="shortPayTaskDataSource" keyExpr="claimnum"
							[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true"
							[(selectedRowKeys)]="selectedListOfShortPayTask" [showBorders]="true"
							(onSelectionChanged)="doChangeDatagridShortPayTask($event)"
							(onInitialized)="onInitializedShortPayTask($event)">
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
							<dxo-selection mode="single"></dxo-selection>
							<dxi-column dataType="date" dataField="date" caption="Date" cellTemplate="cellTemplateDate">
								<div *dxTemplate="let element of 'cellTemplateDate'">
									{{ (element.data.date !== null &&
									element.data.date !== '' &&
									element.data.date !== undefined) ?
									(element.data.date | date: 'yyyy-MM-dd') : "" }}
								</div>
							</dxi-column>
							<dxi-column dataType="string" dataField="claimnum" caption="Short Pay ID"></dxi-column>
							<dxi-column dataType="number" dataField="amount" caption="Amount">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateAmount'">
									{{element.data.amount || 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
						</dx-data-grid>
					</div>
					<!-- end short pay task list section -->
					<div class="cols12 feildCol">
						<dx-button class="primaryBtn width100p" id="button" text="Submit Claim" type="normal"
							stylingMode="contained" (click)="doUploadCsvFile()">
						</dx-button>
					</div>
				</div>
			</div>

			<div *ngIf="actionFor === 'showIgnoreItem'">
				<div class="tabbing cf">
					<ul>
						<li (click)="doChangeTab('ignored_claim')" [ngClass]="{active: currentTab == 'ignored_claim'}">
							<a>Ignored Item(s)</a>
						</li>
						<li (click)="doChangeTab('successed_claim')" [ngClass]="{active: currentTab == 'successed_claim'}">
							<a>Successful Item(s)</a>
						</li>

					</ul>
				</div>
				<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="dxDataSource" [showBorders]="true"
					[repaintChangesOnly]="true" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
					[columnAutoWidth]="true" [width]="'100%'">
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false" [showRelevantValues]="false"></dxo-header-filter>
					<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
					</dxo-pager>

					<dxi-column dataField="itemname" caption="Item" dataType="string" [allowEditing]="false"
						cellTemplate="cellTemplateItemName">
						<div *dxTemplate="let element of 'cellTemplateItemName'" class="">
							{{element.data.itemname || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="end_user_name" caption="EndUser" dataType="string" [allowEditing]="false"
						cellTemplate="cellTemplateEndUser" [visible]="billbackType === 'enduserschargeback'">
						<div *dxTemplate="let element of 'cellTemplateEndUser'" class="">
							{{element.data.end_user_name || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="uom" caption="UOM" dataType="string" [allowEditing]="false"
						cellTemplate="cellTemplateUOM">
						<div *dxTemplate="let element of 'cellTemplateUOM'" class="">
							{{element.data.selectedClaimedUom || ''}}
						</div>
					</dxi-column>
					<dxi-column dataField="claimed" caption="Quantity" dataType="string" [allowEditing]="false"
						cellTemplate="cellTemplateQuantity">
						<div *dxTemplate="let element of 'cellTemplateQuantity'" class="">
							{{element.data.claimed || ''}}
						</div>
					</dxi-column>

				</dx-data-grid>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>