<div class="rightBar">
	<div class="rightBlock">
		<div id="mainContent" class="contentRow cf">
			<div class="borderBox quoteDesign cf">
				<div class="orderBox">
					<div class="subTitleRow" *ngIf="arInvoiceDetails && arInvoiceDetails.docnum">
						<!-- AR Credit Memo ID -->
						<h2>
							<span class="lbl">Credit Memo </span>
							{{arInvoiceDetails.docnum ? '# '+ arInvoiceDetails.docnum : ''}}
						</h2>
						<span *ngIf="arInvoiceDetails.virtual && isShowVirtualTag"
							class="ix-virtualTag mrgL20 highlight">Virtual</span>
					</div>
					<div class="orderBox row">
						<div class="orderDetail cols4">
							<span class="custId" *ngIf="isShowCustomerID">
								Customer ID
								{{arInvoiceDetails?.cardcode}}
							</span>
							<span *ngIf="arInvoiceDetails.source_docnum" class="custId">
								<!-- arInvoiceDetails.virtual && arInvoiceDetails.source_docnum -->
								Source Invoice <span (click)="doShowOriginalInvoiceDetails()" class="highlight pointerMark">
									#{{arInvoiceDetails.source_docnum}}</span>
							</span>
							<h3 class="custNm">
								<span class="contTx lbl mrgB0">Customer : </span>
								{{arInvoiceDetails?.cardname}}
							</h3>
							<div class="contDtl">
								<span class="contTx icoDate" *ngIf="arInvoiceDetails?.docdate">
									<span class="lbl">Credit Memo Date: </span>
									{{ arInvoiceDetails?.docdate || '' | customDateFormatPipe:'dateonly'}}
								</span>
								<span class="contTx icoDate" *ngIf="arInvoiceDetails?.docduedate">
									<span class="lbl">Due Date: </span>
									{{ arInvoiceDetails?.docduedate || '' | customDateFormatPipe:'dateonly'}}
								</span>
							</div>
							<div *ngIf="isEnableDistributionChannels" class="contDtl">
								<span class="contTx icoDate">
									<span class="lbl">Distribution Channel: </span>
									{{arInvoiceDetails && arInvoiceDetails.distribution_channels ?
									(arInvoiceDetails.distribution_channels || '' |filterFromList:listOfDistributionChannels :
									'code' : 'code') : ''}}
									{{arInvoiceDetails && arInvoiceDetails.distribution_channels ? ' - '
									+(arInvoiceDetails.distribution_channels || '' | filterFromList:listOfDistributionChannels :
									'code' : 'description') : ''}}
								</span>
							</div>

							<div *ngIf="arInvoiceDetails.ponumber || arInvoiceDetails.shipmethod" class="poShipping">
								<div class="row">
									<div *ngIf="arInvoiceDetails.ponumber" class="cols6">
										<span>PO Number</span>{{arInvoiceDetails.ponumber}}
									</div>
									<div *ngIf="arInvoiceDetails.shipmethod" class="cols6">
										<span>Shipping Method</span>
										{{arInvoiceDetails.shipmethod}}
									</div>
								</div>
							</div>
						</div>

						<div class="orderAddress cols6 padL0"
							*ngIf="arInvoiceDetails && (arInvoiceDetails.address || arInvoiceDetails.address2)">
							<div *ngIf="arInvoiceDetails && arInvoiceDetails.address" class="cols6 addressBox"
								[ngClass]="{'singleBlock':!(arInvoiceDetails.address2)}">
								<h3 class="custNm">Billing Address</h3>
								<span class="contTx" [innerHTML]="arInvoiceDetails.address"></span>
							</div>
							<div *ngIf="arInvoiceDetails && arInvoiceDetails.address2" class="cols6 addressBox"
								[ngClass]="{'singleBlock':!(arInvoiceDetails.address)}">
								<h3 class="custNm">Shipping Address</h3>
								<span class="contTx" [innerHTML]="arInvoiceDetails.address2">
								</span>
							</div>
						</div>

						<div class="orderStatus odStatus cols2">
							<ul class="statusBxLnremove">
								<li>
									<span class="stLbl">Status:</span>
									<span class="inStatusLabel orangeInvoice alignRight"
										*ngIf="!isQBErpUser && arInvoiceDetails?.documentstatus =='bost_Open'">Open</span>
									<span class="inStatusLabel greenInvoice alignRight"
										*ngIf="!isQBErpUser && arInvoiceDetails?.documentstatus == 'bost_Close'">Closed</span>
									<span class="inStatusLabel orangeInvoice alignRight"
										*ngIf="arInvoiceDetails?.documentstatus =='draft'">Draft</span>
									<span class="inStatusLabel greenInvoice alignRight" *ngIf="isQBErpUser && (arInvoiceDetails?.documentstatus =='bost_Close' || arInvoiceDetails?.documentstatus =='bost_Open'
										)">Closed</span>
									<span class="inStatusLabel redInvoice alignRight"
										*ngIf="arInvoiceDetails?.documentstatus =='bost_Cancel'">Canceled</span>
									<span class="inStatusLabel redInvoice alignRight"
										*ngIf="arInvoiceDetails?.documentstatus =='deleted'">Deleted</span>
								</li>
							</ul>
						</div>
					</div>
					<div
						*ngIf="isEnableAmortisePaymentsFeature && !isItemListLevel &&(listOfAmortisePaymentsFieldValue && listOfAmortisePaymentsFieldValue.length > 0)"
						class="orderBox row">
						<div *ngFor="let field of listOfAmortisePaymentsFieldValue;let menuIndex = index;"
							class="orderDetail cols3">
							<span>{{field.fieldLabel}}: </span>
							{{field.fieldValue || '' | customDateFormatPipe:'dateonly'}}
						</div>
					</div>
				</div>
			</div>

			<div class="borderBox padB20 mrgB0 cf">
				<div class="subTitleRow">
					<h2>Document Listing</h2>
				</div>
				<div *ngIf="arInvoiceDetails.documentlines && arInvoiceDetails.documentlines.length > 0">
					<div class="mrgB0 cf">
						<dx-data-grid #dataGridContainer id="gridContainerDocList"
							[dataSource]="arInvoiceDetails.documentlines || []" keyExpr="linenum" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[columnAutoWidth]="true" (onToolbarPreparing)="doToolbarPreparing($event)" [width]="'100%'"
							class="hoverClr hideFilterPanelInx">
							<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>

							<dxi-column dataField="itemname" dataType="string" caption="Item" cellTemplate="cellTemplateItem">
								<div *dxTemplate="let element of 'cellTemplateItem'">
									{{element.data.itemname || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="description" dataType="string" caption="Description"
								cellTemplate="cellTemplateDescription">
								<div *dxTemplate="let element of 'cellTemplateDescription'">
									{{element.data.description || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="billing_start_date"
								[visible]="isEnableAmortisePaymentsFeature && isItemListLevel" caption="Billing Start Date"
								dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateBillingStartDate" [minWidth]="120"
								[width]="'11%'" [allowSorting]="false">
								<div *dxTemplate="let element of 'cellTemplateBillingStartDate'">
									{{element.data.billing_start_date ? element.data.billing_start_date : '' }}
								</div>
							</dxi-column>

							<dxi-column dataField="billing_end_date"
								[visible]="isEnableAmortisePaymentsFeature && isItemListLevel" caption="Billing End Date"
								dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateBillingEndDate" [minWidth]="120"
								[width]="'11%'" [allowSorting]="false">
								<div *dxTemplate="let element of 'cellTemplateBillingEndDate'">
									{{element.data.billing_end_date ? element.data.billing_end_date : '' }}
								</div>
							</dxi-column>

							<dxi-column dataField="cardcode" dataType="boolean" [visible]="isShowCardcodeColumn"
								caption="Customer Code" cellTemplate="cellTemplateCardcode" [minWidth]="80" [width]="'10%'">
								<div *dxTemplate="let element of 'cellTemplateCardcode'">
									{{element.data.cardcode || ''}}
								</div>
							</dxi-column>

							<dxi-column dataField="price" dataType="number" caption="Rate" cellTemplate="cellTemplatePrice">
								<div *dxTemplate="let element of 'cellTemplatePrice'">
									{{(element.data.price || 0 |customCurrencyPipe:customCurrencyOption )}}
								</div>
							</dxi-column>

							<dxi-column dataField="quantity" dataType="number" caption="Quantity"
								cellTemplate="cellTemplateQuantity">
								<div *dxTemplate="let element of 'cellTemplateQuantity'">
									{{element.data.quantity}}
								</div>
							</dxi-column>
							<dxi-column dataField="linetotal" dataType="number" caption="Total"
								cellTemplate="cellTemplateLineTotal">
								<div *dxTemplate="let element of 'cellTemplateLineTotal'">
									{{(element.data.linetotal || 0 |customCurrencyPipe:customCurrencyOption )}}
								</div>
							</dxi-column>
							<dxi-column caption="View Document" cellTemplate="cellTemplateViewDoc" *ngIf="isShowViewDocuments">
								<div *dxTemplate="let element of 'cellTemplateViewDoc'">
									<a *ngIf="arInvoiceDetails.chargebackDocGuid" class="centeredIcon viewIconBtn"
										(click)="viewChargeBackDocumentDetails()"><span class="sprite viewIcon "></span></a>
								</div>
							</dxi-column>
						</dx-data-grid>
					</div>
				</div>
				<div class="cols12 paymentBtmRow">
					<div class="row">
						<diV class="totalRowCal withAddBtn noActClm noBatchClm">
							<div class="alignRight mrgT10">
								<div class="cols12">
									<div class="paySubTotal totalWithAct">
										Subtotal :
										<span class="txtRight">
											{{arInvoiceDetails.doctotalsys || 0 | customCurrencyPipe:customCurrencyOption}}
										</span>
									</div>
								</div>
								<div *ngIf="arInvoiceDetails.totaldiscount && arInvoiceDetails.totaldiscount > 0"
									class="cols12">
									<div class="paySubTotal totalWithAct">
										Discount :
										<span class="txtRight">
											{{arInvoiceDetails.totaldiscount || 0 | customCurrencyPipe:customCurrencyOption }}
										</span>
									</div>
								</div>
								<div *ngIf="arInvoiceDetails?.totalmiscamount && arInvoiceDetails.totalmiscamount > 0"
									class="cols12">
									<div class="paySubTotal totalWithAct">
										Miscellaneous Charge:
										<span class="txtRight">
											{{arInvoiceDetails.totalmiscamount || 0 | customCurrencyPipe:customCurrencyOption }}
										</span>
									</div>
								</div>
								<div *ngIf="arInvoiceDetails.shippingcharges && arInvoiceDetails.shippingcharges > 0"
									class="cols12">
									<div class="paySubTotal totalWithAct">
										Frieght Charge:
										<span class="txtRight">
											{{arInvoiceDetails.shippingcharges || 0 | customCurrencyPipe:customCurrencyOption }}
										</span>
									</div>
								</div>
								<div class="cols12">
									<div class="paySubTotal payFinalTotal">
										Total :
										<span class="txtRight">
											{{arInvoiceDetails.doctotal || 0 | customCurrencyPipe:customCurrencyOption }}
										</span>
									</div>
								</div>
							</div>
						</diV>
						<div class="cols12 mrgT10 padL30">
							<div class="dx-field feildCol mrgT10 mrgB20 remarkBx">
								<div class="dx-field-label">Remarks</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-text-area [height]="60" [maxLength]="400" [(value)]="arInvoiceDetails.journalmemo"
										[readOnly]="true">
									</dx-text-area>
								</div>
							</div>
						</div>
						<div class="cols12 mrgB20" *ngIf="isViewResendCreditMemoBtn">
							<dx-button class="primaryBtn alignRight" id="button" text="Resend Credit Memo" type="normal"
								stylingMode="contained" (click)="doClickResendCreditMemo()">
							</dx-button>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#mainContent' }"
	[(visible)]="isShowLoadPanel" [showIndicator]="true" [showPane]="true" [shading]="false"
	[hideOnOutsideClick]="false">
</dx-load-panel>

<dx-popup [showTitle]="true" [dragEnabled]="true" [fullScreen]="true" [hideOnOutsideClick]="true"
	title="Source Invoice Details" [showCloseButton]="true" [(visible)]="isShowOriginalInvoiceDetails"
	(onHidden)="doHideInvoiceDetailsOriginalPopup($event)" [wrapperAttr]="{ class: 'popupZIndex' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" width="100%" height="100%">
			<app-sales-invoice-details-original></app-sales-invoice-details-original>
		</dx-scroll-view>
	</div>
</dx-popup>

<app-view-charge-documents-dialog *ngIf="isShowChargeDocument" [chargeDocumentProps]="chargeDocumentProps"
	(closeChargeDocumentPopup)="doCloseChargeDocumentPopup($event)"></app-view-charge-documents-dialog>