import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LoginService } from '@app/services/login.service';
import { Subscription } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { CommissionSettlementReportSectionEnum, ContractTypeValEnum, ErpTypeValEnum, PopupHeightWidth } from "@app/constants-enums/enums";
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { ARcreditMemos, ExtraCommissionBasedRules, Invoices, MiscellaneousDeductionRules } from '@app/models/commission-contract.model';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Guid } from '@app/models/guid';
import * as _ from 'underscore';
declare const ExcelJS: ExcelJS;
import { exportDataGrid as exportDataGridPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { proxima_nova, proxima_nova_bold, proxima_nova_semi_bold } from '@app/services/app-common-srvc/app-common-fonts.service';
import { ViewCreditMemoDetailPopUpModel, ViewInvoiceDetailPopUpModel, ViewOrderChangeOrdereDetailPopUpModel } from '@app/models/popup.model';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-commission-tierbase-commission-calculation-view',
	templateUrl: './commission-tierbase-commission-calculation-view.component.html',
	styleUrls: ['./commission-tierbase-commission-calculation-view.component.css'],
	providers: [
		CustomCurrencyPipe,
		CustomNumberNoRoundingPipe
	]
})
export class CommissionTierbaseCommissionCalculationViewComponent implements OnInit {
	public invoiceDetails: any;
	public commissionDetails: any;
	@ViewChild('mainDatagrid') mainDatagrid: DxDataGridComponent;
	@ViewChild('customersInvoiceDataGrid') customersInvoiceDataGrid: DxDataGridComponent;
	@ViewChild('ordersDataGrid') ordersDataGrid: DxDataGridComponent;
	@ViewChild('invoicesItemDataGrid') invoicesItemDataGrid: DxDataGridComponent;
	@ViewChild('invoicesDataGrid') invoicesDataGrid: DxDataGridComponent;
	@ViewChild('changeOrderInvoiceDataGrid') changeOrderInvoiceDataGrid: DxDataGridComponent;
	@ViewChild('extraCommissionBasedRulesDataGrid') extraCommissionBasedRulesDataGrid: DxDataGridComponent;
	@ViewChild('amortizedPaymentsDataGrid') amortizedPaymentsDataGrid: DxDataGridComponent;
	@ViewChild('arcreditmemosDataGrid') arcreditmemosDataGrid: DxDataGridComponent;
	@ViewChild('miscellaneousDeductionRulesDataGrid') miscellaneousDeductionRulesDataGrid: DxDataGridComponent;
	@ViewChild('serviceCreditmemosDataGrid') serviceCreditmemosDataGrid: DxDataGridComponent;
	@ViewChild('modifiedInvoicesDataGrid') modifiedInvoicesDataGrid: DxDataGridComponent;
	@ViewChild('modifiedInvoicesItemDataGrid') modifiedInvoicesItemDataGrid: DxDataGridComponent;
	public sectionLevelFrom: string;
	public pageOptions: PageOptions = new PageOptions();
	public apInvoice: any;
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private commissionSettlementReportSubscription: Subscription;
	private updateUserSettingSbsn: Subscription;
	public invoicesDataSource: {};
	public extraCommissionBasedRulesDataSource: {};
	public amortizedPaymentsDataSource: {};
	public arcreditmemosDataSource: {};
	public serviceCreditmemosDataSource: {};
	public miscellaneousDeductionRulesDataSource: {};
	public changeOrderDataSource: {};
	public modifiedInvoicesDataSource: {};
	public invoicesSource: Invoices[];
	public amortizedPayments: any[];
	public extraCommissionBasedRulesSource: ExtraCommissionBasedRules[];
	public arcreditmemosSource: ARcreditMemos[];
	public miscellaneousDeductionRulesSource: MiscellaneousDeductionRules[];
	public mainDataSource: any[] = [];
	public listOfFeatureCustomFields: any[] = [];
	public settlementPeriodStartDate: any;
	public settlementPeriodEndDate: any;
	public isQBOnlineErpUser: boolean = false;
	private getUomLstSbsn: Subscription;
	private getFeatureCustomFieldsLstSbn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];
	public listOfCommissionBasis: any[] = [];
	public listOfCommissionBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'order', text: 'Order' }];
	public enable_classification_for_commision: boolean = false;
	public enable_invoice_custom_fields_feature: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	public isCustomerAgeTiers: boolean = false;
	public invoiceCodeActionText: string;
	public isVisiblePopup: boolean = false;
	public isviewRebateCalView: boolean = false;
	public estimatedRebateHeaderText: string = 'Estimated Rebate Calculation';
	public estimatedRebateTabData: any[] = [];
	public selectedItem: any;
	public selectedTabIndex: number;
	public selectedTabCode: string;
	public calculate_profit_on_document_header_by: string;
	private guid = new Guid();
	public isLoadedInvoiceDataGrid: boolean = false;
	public enable_amortise_payments: boolean = false;
	public enable_incremental_payment: boolean = false;
	public CommissionSettlementReportSectionEnum = CommissionSettlementReportSectionEnum;

	public isShowExportPdf: boolean;
	public isShowPopupDatagridExport: boolean;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public listOfMasterDatagrid: any[] = [];
	public listOfExportButtons: any[] = [{
		btnCode: 'export_column_configs',
		btnName: 'Configurations',
		btnImage: '',
		icon: 'preferences',
		visible: true
	}, {
		btnCode: 'export_pdf',
		btnName: 'Export',
		btnImage: '',
		icon: 'pdffile',
		visible: true
	}];
	private downloadPdfSbsn: Subscription;
	private getCommissionDetailsSbsn: Subscription;
	private companyImageBase64: any;

	public isShowInvoiceDetailPopUp: boolean = false;
	public isShowOrderChangeOrderDetailPopUp: boolean = false;
	public isShowCreditMemoDetailPopUp: boolean = false;
	public viewInvoiceDetailProps: ViewInvoiceDetailPopUpModel = new ViewInvoiceDetailPopUpModel();
	public viewOrderChangeOrderDetailProps: ViewOrderChangeOrdereDetailPopUpModel = new ViewOrderChangeOrdereDetailPopUpModel();
	public viewCreditMemoDetailProps: ViewCreditMemoDetailPopUpModel = new ViewCreditMemoDetailPopUpModel();
	public listOfActions: any[];
	public globalCurrencySymbol: string;
	public isShowBranchIn: boolean = false;
	public erp_type: string;
	public ErpTypeValEnum = ErpTypeValEnum;
	public decimalPointForCurrency: number;
	public decimalPointPercentageFormat: string = '1.0-0';
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	constructor(private datePipe: DatePipe,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _CommissionService: CommissionService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _RebateService: RebateService,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
		public _AppCommonSrvc: AppCommonSrvc,
		private _IxDxAlertService: IxDxAlertService,
	) {
		this.doConvertPercentValue = this.doConvertPercentValue.bind(this);
		this.doCustomizeTextToPercent = this.doCustomizeTextToPercent.bind(this);
		this.doCustomizeTextToDollar = this.doCustomizeTextToDollar.bind(this);
		this.doCustomizeTotal = this.doCustomizeTotal.bind(this);
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
		this.doCalculateCellValueOrgCommissionAmount = this.doCalculateCellValueOrgCommissionAmount.bind(this);
	}

	ngOnInit() {
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.listOfActions = this._CommissionService.getListOfActionsForCommissionReport() || [];
		this.invoiceDetails = this._CommissionService.getInvoiceDetails();
		this.commissionDetails = this._CommissionService.getCommissionContractDetails();
		this.listOfFeatureCustomFields = this._CommissionService.getlistOfFeatureCustomFields();
		this.settlementPeriodStartDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodStart, 'yyyy-MM-dd');
		this.settlementPeriodEndDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodEnd, 'yyyy-MM-dd');
		this.companyImageBase64 = '';
		if (this._LoginService.loginUser.account_detail.companylogo) {
			this.companyImageBase64 = this._LoginService.loginUser.account_detail.companylogo_base64;
		}
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
		}

		if (this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail) {
			this.isCustomerAgeTiers = false;
			if (this._LoginService.loginUser.account_detail.app_settings.hasOwnProperty('customer_age_tiers')) {
				this.isCustomerAgeTiers = this._LoginService.loginUser.account_detail.app_settings.customer_age_tiers;
			}
			this.enable_classification_for_commision = false;
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_classification_for_commision')) {
				this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision;
			}

			this.enable_invoice_custom_fields_feature = false;
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_invoice_custom_fields_feature')) {
				this.enable_invoice_custom_fields_feature = this._LoginService.loginUser.account_detail.enable_invoice_custom_fields_feature;
			}

			this.enable_amortise_payments = false;
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_amortise_payments')) {
				this.enable_amortise_payments = this._LoginService.loginUser.account_detail.enable_amortise_payments;
			}

			this.enable_incremental_payment = false;
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_incremental_payment')) {
				this.enable_incremental_payment = this._LoginService.loginUser.account_detail.enable_incremental_payment;
			}

			this.enable_profit_on_document_header = false;
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_profit_on_document_header')) {
				this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header;
			}

			this.calculate_profit_on_document_header_by = '';
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('calculate_profit_on_document_header_by')) {
				this.calculate_profit_on_document_header_by = this._LoginService.loginUser.account_detail.calculate_profit_on_document_header_by || '';
			}
		}
		this.isShowExportPdf = false;
		if (this.enable_profit_on_document_header) {
			this.isShowExportPdf = true;
		}
		//	setTimeout(() => {
		this.setMainDataSource();
		//}, 500);
		this.fetchListOfUoms();
		this.listOfTierBasis = this._CommissionService.getTierBasis();
		this.listOfCommissionBasis = this._CommissionService.getCommissionBasis();
		this.listOfTierBasisHeaderFilter = this.getHeaderFilter(this.listOfTierBasis, 'code', 'label');
		this.listOfCommissionBasisHeaderFilter = this.getHeaderFilter(this.listOfCommissionBasis, 'code', 'label');
		this.preInit();
	}

	preInit() {
		this.loadInvoicesDataSource();
		this.loadChangeOrdersDataSource();
		this.loadExtraCommissionBasedRulesDataSource();
		this.loadAmortizedPaymentsDataSource();
		this.loadArcreditmemosSourceDataSource();
		this.loadServicecreditmemosSourceDataSource();
		this.loadMiscellaneousDeductionRulesDataSource();
		this.loadModifiedInvoiceDataSource();

	}
	// tslint:disable-next-line:use-life-cycle-interface
	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.commissionSettlementReportSubscription) {
			this.commissionSettlementReportSubscription.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.downloadPdfSbsn) {
			this.downloadPdfSbsn.unsubscribe();
		}
		if (this.getCommissionDetailsSbsn) {
			this.getCommissionDetailsSbsn.unsubscribe();
		}
	}

	fullNameColumn_calculateCellValue(rowData) {
		const length = rowData.fiscal_sequence.toString().length;
		let fiscal_sequence = rowData.fiscal_sequence;
		if (length === 1) {
			fiscal_sequence = '0' + rowData.fiscal_sequence;
		}
		return fiscal_sequence + '  ' + rowData.monthYear;
	}

	setMainDataSource() {
		let lstMainSection: any = this._CommissionService.getCommissionContractSettlementReportSection();
		this.mainDataSource = lstMainSection.filter(i => i.parent_section_level === 0) || []; // load only base level section

		if (this.commissionDetails.calculate_commission_by === CommissionSettlementReportSectionEnum.INVOICES) {
			this.invoiceCodeActionText = 'Invoice #';
			const indexOrders = this.mainDataSource.findIndex(i => i.key === CommissionSettlementReportSectionEnum.ORDERS);
			this.mainDataSource.splice(indexOrders, 1);
			const indexOrdersChanges = this.mainDataSource.findIndex(i => i.key === CommissionSettlementReportSectionEnum.CHANGE_ORDERS);
			this.mainDataSource.splice(indexOrdersChanges, 1);
		}
		if (this.commissionDetails.calculate_commission_by === CommissionSettlementReportSectionEnum.ORDERS) {
			this.invoiceCodeActionText = 'Order #';
			const indexInvoices = this.mainDataSource.findIndex(i => i.key === CommissionSettlementReportSectionEnum.INVOICES);
			this.mainDataSource.splice(indexInvoices, 1);
		}
		if (!this.enable_amortise_payments) {
			const indexAmortisePayment = this.mainDataSource.findIndex(i => i.key === CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS);
			this.mainDataSource.splice(indexAmortisePayment, 1);
		}
		//Incremental Payment
		if (!this.enable_incremental_payment) {
			const indexModifiedInvoices = this.mainDataSource.findIndex(i => i.key === CommissionSettlementReportSectionEnum.MODIFIED_INVOICES);
			this.mainDataSource.splice(indexModifiedInvoices, 1);
		}
		//End
		// // if (this.invoicesDataSource) {
		// if (this.commissionDetails.calculate_commission_by === 'invoices') {
		// 	this.invoiceCodeActionText = 'Invoice #';
		// 	this.mainDataSource.push({ "key": "invoices", "name": "Invoices" });
		// }
		// if (this.commissionDetails.calculate_commission_by === 'orders') {
		// 	this.invoiceCodeActionText = 'Order #';
		// 	this.mainDataSource.push({ "key": "invoices", "name": "Orders" });
		// }
		// if (this.commissionDetails.calculate_commission_by === 'orders') {
		// 	this.invoiceCodeActionText = 'Order #';
		// 	this.mainDataSource.push({ "key": "change_orders", "name": "Change Order(s)" });
		// }
		// // }
		// // Hide section as of IX-1615 story
		// //this.mainDataSource.push({ "key": "miscellaneousDeductionRules", =--"name": "Miscellaneous Deduction" });
		// // if (this.extraCommissionBasedRulesSource && this.extraCommissionBasedRulesSource.length > 0) {
		// this.mainDataSource.push({ "key": "extraCommissionBasedRules", "name": "Additional incentives" });
		// if (this.enable_classification_for_commision) {
		// 	this.mainDataSource.push({ "key": "amortizedPayments", "name": "Amortized Payments" });
		// }
		// this.mainDataSource.push({ "key": "servicecreditmemos", "name": "Service Credit Memo" });
		// // }

		// // if (this.arcreditmemosSource && this.arcreditmemosSource.length > 0) {
		// // this.mainDataSource.push({ "key": "arcreditmemos", "name": "A/R Credit Memo" });
		// // }


	}

	checkMasterDetails(objMasterDetails, key) {
		if (objMasterDetails.data) {
			if (objMasterDetails.data.key === key) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	onExporting() {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let invoicesSheet, changeOrderSheet, extraCommissionBasedRulesSheet, arCreditMemosSheet, miscellaneousDeductionRulesSheet, serviceCreditMemoSheet, amortizedPaymentsSheet, modifiedInvoiceSheet;
		// let txtWorkSheetName = 'Invoice';
		// if (this.commissionDetails.calculate_commission_by === 'orders') {
		// 	txtWorkSheetName = 'Order';
		// }
		if (this.commissionDetails.calculate_commission_by === 'invoices' && context.customersInvoiceDataGrid && context.customersInvoiceDataGrid.instance) {
			invoicesSheet = workbook.addWorksheet('Invoice');
		}
		if (this.commissionDetails.calculate_commission_by === 'orders' && context.ordersDataGrid && context.ordersDataGrid.instance) {
			invoicesSheet = workbook.addWorksheet('Order');
		}
		if (context.changeOrderInvoiceDataGrid && context.changeOrderInvoiceDataGrid.instance) {
			changeOrderSheet = workbook.addWorksheet('Change Order(s)');
		}
		if (context.miscellaneousDeductionRulesDataGrid && context.miscellaneousDeductionRulesDataGrid.instance) {
			miscellaneousDeductionRulesSheet = workbook.addWorksheet('Miscellaneous Deduction');
		}
		if (context.extraCommissionBasedRulesDataGrid && context.extraCommissionBasedRulesDataGrid.instance) {
			extraCommissionBasedRulesSheet = workbook.addWorksheet('Additional incentives');
		}
		if (context.arcreditmemosDataGrid && context.arcreditmemosDataGrid.instance) {
			arCreditMemosSheet = workbook.addWorksheet('AR Credit Memo');
		}
		if (context.serviceCreditmemosDataGrid && context.serviceCreditmemosDataGrid.instance) {
			serviceCreditMemoSheet = workbook.addWorksheet('Service Credit Memo');
		}
		if (context.amortizedPaymentsDataGrid && context.amortizedPaymentsDataGrid.instance && context.enable_amortise_payments) {
			amortizedPaymentsSheet = workbook.addWorksheet('Amortized Payments');
		}
		if (context.modifiedInvoicesDataGrid && context.modifiedInvoicesDataGrid.instance && context.enable_incremental_payment) {
			modifiedInvoiceSheet = workbook.addWorksheet('Modified Invoice(s)');
		}

		let sheetName = '';
		const subusername = this.invoiceDetails.subusername || '';
		const apInvoiceNumber = this.invoiceDetails.apInvoiceNumber || '';
		let fileName = '';
		if (apInvoiceNumber) {
			fileName = apInvoiceNumber + '_';
		}
		if (subusername) {
			fileName = subusername + '_';
		}
		fileName = fileName + 'Commission_Report.xlsx';
		if (this.invoiceDetails.apInvoiceNumber) {
			sheetName = this.invoiceDetails.apInvoiceNumber;
			fileName = this.invoiceDetails.apInvoiceNumber + '_' + fileName;
		}
		if (this.invoiceDetails.subusername) {
			sheetName = sheetName + '-' + this.invoiceDetails.subusername;
		}
		const groupIndexesWithSingleItem = [];
		const listOfItemMasterDetailsRows = [];
		const listOfInvoiceMasterDetailsRows = [];
		// const worksheet = workbook.addWorksheet(sheetName);
		if (this.companyImageBase64) {
			const image = workbook.addImage({
				base64: this.companyImageBase64,
				extension: 'jpg',
			});
			invoicesSheet.addImage(image, 'A1:A3');
			invoicesSheet.mergeCells(1, 1, 3, 1);
		}
		if ((context.customersInvoiceDataGrid && context.customersInvoiceDataGrid.instance) || (context.ordersDataGrid && context.ordersDataGrid.instance)) {
			exportDataGrid({
				worksheet: invoicesSheet,
				component: this.commissionDetails.calculate_commission_by === 'orders' ? context.ordersDataGrid.instance : context.customersInvoiceDataGrid.instance,
				keepColumnWidths: true,
				autoFilterEnabled: false,
				topLeftCell: { row: 5, column: 1 },
				customizeCell: (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'commissionAmount') {
							gridCell.data.rowIndex = excelCell.fullAddress.row + 1;
							if (gridCell.data['items']) {
								gridCell.data.data = gridCell.data.items;
								for (let i = 0; i < gridCell.data.items.length; i++) {
									gridCell.data.items[i].data = {};
									gridCell.data.items[i].rowIndex = excelCell.fullAddress.row + 1;
									gridCell.data.items[i].data.invoices = gridCell.data.items[i].invoices;
								}
							}
							listOfItemMasterDetailsRows.push({
								rowIndex: excelCell.fullAddress.row + 1,
								data: gridCell.data
							});
							// listOfInvoiceMasterDetailsRows.push({
							// 	rowIndex: excelCell.fullAddress.row + 1,
							// 	data: gridCell.data.items
							// });
							// excelCell.value = '';
							if (gridCell.value) {
								// excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionAmount);
								// excelCell.value = parseFloat(gridCell.value.toString()).toFixed(2);
							}
						}
					}
				}
			}).then((cellRange) => {
				// Logic for Set headers
				const headerRow = invoicesSheet.getRow(2);
				headerRow.height = 30;
				invoicesSheet.mergeCells(2, 2, 2, 9);


				let secondCellValue = this.invoiceDetails.contractName;
				headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14 };
				headerRow.getCell(1).alignment = { horizontal: 'left' };
				headerRow.getCell(2).value = secondCellValue;
				headerRow.getCell(2).font = { name: 'Segoe UI Light', size: 14 };
				headerRow.getCell(2).alignment = { horizontal: 'center', vertical: 'middle' };

				invoicesSheet.mergeCells(4, 2, 4, 9);
				const headerRowForSettlementPeriod = invoicesSheet.getRow(4);
				// headerRowForSettlementPeriod.height = 20;
				headerRowForSettlementPeriod.getCell(1).value = 'Settlement Period ';
				headerRowForSettlementPeriod.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
				headerRowForSettlementPeriod.getCell(1).alignment = { horizontal: 'left' };
				headerRowForSettlementPeriod.getCell(2).value = this.invoiceDetails.settlementPeriodStart + ' To ' + this.invoiceDetails.settlementPeriodEnd;
				headerRowForSettlementPeriod.getCell(2).font = { name: 'Segoe UI Light', size: 12 };
				headerRowForSettlementPeriod.getCell(2).alignment = { horizontal: 'left' };

				groupIndexesWithSingleItem.sort((a, b) => b - a).forEach((rowIndex) => {
					invoicesSheet.getRow(rowIndex + 1).outlineLevel = undefined;
					invoicesSheet.spliceRows(rowIndex, 1);
				});

				// Logic for Invoice Details rows
				const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
				let offset = 0;

				const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
					const currentIndex = index + rowOffset;
					const row = invoicesSheet.insertRow(currentIndex, [], "n");
					for (let j = invoicesSheet.rowCount + 1; j > currentIndex; j--) {
						invoicesSheet.getRow(j).outlineLevel = invoicesSheet.getRow(j - 1).outlineLevel;
					}
					row.outlineLevel = outlineLevel;
					return row;
				};

				let itemColumnsField = [
					"document_type",
					"invoiceCode",
					"uomcode",
					"quantity",
					"linetotal"
				];
				let itemColumns = [
					"Doc. Type",
					"Doc. No",
					"Doc. UOM",
					"Doc. Qty",
					"Line Total (" + this.globalCurrencySymbol + ")"
				];
				let isCommissionBasisDollarPerUom = false;
				let isCommissionBasisPercentage = false;
				for (let i = 0; i < listOfItemMasterDetailsRows.length; i++) {

					let isQBOnlineErpUser = this.isQBOnlineErpUser;
					itemColumnsField = [
						"itemname",
						"description",
						"commissionAmount"
					];
					itemColumns = [
						"Item",
						"Description",
						"Commission"
					];
					if (isQBOnlineErpUser) {
						itemColumnsField = [
							"itemsku",
							"itemname",
							"description",
							"commissionAmount"
						];
						itemColumns = [
							"SKU",
							"Item",
							"Description",
							"Commission"
						];
					}

					const columnIndex = cellRange.from.column;
					// let row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
					// row.height = 24;
					// invoicesSheet.mergeCells(row.number, columnIndex, row.number, 7);
					if (listOfItemMasterDetailsRows[i].data.items && listOfItemMasterDetailsRows[i].data.items.length > 0) {
						let row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
						itemColumns.forEach((columnName, currentColumnIndex) => {
							row.keepColumnWidths = true;
							Object.assign(row.getCell(columnIndex + currentColumnIndex), {
								value: columnName,
								fill: {
									type: "pattern",
									pattern: "solid",
									fgColor: { argb: "BEDFE6" }
								},
								font: { bold: true },
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								}
							});
						});

						let subTotalLineTotal = 0;
						listOfItemMasterDetailsRows[i].data.items.forEach((itemDetails, itemIndex) => {
							const itemColumnIndexDetail = cellRange.from.column;
							row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
							row.keepColumnWidths = true;
							itemColumnsField.forEach((columnName, currentColumnIndex) => {
								const cellObject = {
									value: itemDetails[columnName],
									fill: {
										type: "pattern",
										pattern: "solid",
										fgColor: { argb: "BEDFE6" }
									},
									border: {
										bottom: borderStyle,
										left: borderStyle,
										right: borderStyle,
										top: borderStyle
									},
									alignment: { horizontal: 'left' }
								};

								Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
								row.keepColumnWidths = true;
							});
							row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
							row.keepColumnWidths = true;
							//#region invoice code
							if (listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices && listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.length > 0) {
								isCommissionBasisDollarPerUom = false;
								isCommissionBasisPercentage = false;
								if (listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.commission_basis === 'dollarperuom') {
									isCommissionBasisDollarPerUom = true;
								}
								if (listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.commission_basis === 'percentage') {
									isCommissionBasisPercentage = true;
								}
								let invoiceColumnsField = [
									"document_type",
									"invoiceCode",
									"uomcode",
									"quantity",
									"tier_basis",
									"commission_basis",
									"commissionRate",
									"condition",
									"criterion",
									"linetotal",
									"miscDedAmount",
									"miscdeductioninventory",
									"cashdiscount",
									"finalAmount",
									"commissionAmount"
								];
								let invoiceColumns = [
									"Doc. Type",
									"Doc. No",
									"Doc. UOM",
									"Doc. Qty",
									"Commission Type",
									"Commission Basis",
									"Commission Rate (% | " + this.globalCurrencySymbol + ")",
									"Condition",
									"Criterion",
									"Line Total (" + this.globalCurrencySymbol + ")",
									"Misc. Deduction Amount (" + this.globalCurrencySymbol + ")",
									"Misc. Deduction Percentage (%)",
									"Cash Discount(" + this.globalCurrencySymbol + ")",
									"Final Line Total (" + this.globalCurrencySymbol + ")",
									"Commission"
								];
								if (this.invoiceDetails.calculationOnBasis === 'grossprofit') {
									invoiceColumnsField = [
										"document_type",
										"invoiceCode",
										"uomcode",
										"quantity",
										"tier_basis",
										"commission_basis",
										"commissionRate",
										"condition",
										"criterion",
										"linetotal",
										"miscDedAmount",
										"miscdeductioninventory",
										"cashdiscount",
										"finalAmount",
										"costtotal",
										"grossprofit",
										"commissionAmount"
									];
									invoiceColumns = [
										"Doc. Type",
										"Doc. No",
										"Doc. UOM",
										"Doc. Qty",
										"Commission Type",
										"Commission Basis",
										"Commission Rate (% | " + this.globalCurrencySymbol + ")",
										"Condition",
										"Criterion",
										"Line Total (" + this.globalCurrencySymbol + ")",
										"Misc. Deduction Amount (" + this.globalCurrencySymbol + ")",
										"Misc. Deduction Percentage (%)",
										"Cash Discount(" + this.globalCurrencySymbol + ")",
										"Final Line Total (" + this.globalCurrencySymbol + ")",
										"Cost (" + this.globalCurrencySymbol + ")",
										"Profit (" + this.globalCurrencySymbol + ")",
										"Commission"
									];
								}
								if (isCommissionBasisDollarPerUom) {
									invoiceColumnsField = [
										"document_type",
										"invoiceCode",
										"uomcode",
										"quantity",
										'contract_selectedUom',
										'contract_quantity',
										"linetotal"
									];
									invoiceColumns = [
										"Doc. Type",
										"Doc. No",
										"DOc. UOM",
										"Doc. Qty",
										"Contract UOM",
										"Contract Qty",
										"Line Total (" + this.globalCurrencySymbol + ")"
									];
								}
								if (isCommissionBasisPercentage) {
									invoiceColumnsField = [
										"document_type",
										"invoiceCode",
										"uomcode",
										"quantity",
										"linetotal",
										"cashdiscount",
										"miscdeductioninventory",
										"miscDedAmount",
										"finalAmount"
									];
									invoiceColumns = [
										"Doc. Type",
										"Doc. No",
										"Doc. UOM",
										"Doc. Qty",
										"Line Total (" + this.globalCurrencySymbol + ")",
										"Cash Discount (" + this.globalCurrencySymbol + ")",
										"Misc. Deduction (%)",
										"Misc. Deduction Amount (" + this.globalCurrencySymbol + ")",
										"Final Line Total (" + this.globalCurrencySymbol + ")"
									];
								}
								if (this.erp_type === this.ErpTypeValEnum.EPICOR_PROPHET21) {
									invoiceColumnsField = [
										"document_type",
										"invoiceCode",
										"branch",
										"uomcode",
										"quantity",
										"tier_basis",
										"commission_basis",
										"commissionRate",
										"condition",
										"criterion",
										"linetotal",
										"miscDedAmount",
										"miscdeductioninventory",
										"cashdiscount",
										"finalAmount",
										"costtotal",
										"grossprofit",
										"commissionAmount"
									];
									invoiceColumns = [
										"Doc. Type",
										"Doc. No",
										"Branch",
										"Doc. UOM",
										"Doc. Qty",
										"Commission Type",
										"Commission Basis",
										"Commission Rate (% | " + this.globalCurrencySymbol + ")",
										"Condition",
										"Criterion",
										"Line Total (" + this.globalCurrencySymbol + ")",
										"Misc. Deduction Amount (" + this.globalCurrencySymbol + ")",
										"Misc. Deduction Percentage (%)",
										"Cash Discount(" + this.globalCurrencySymbol + ")",
										"Final Line Total (" + this.globalCurrencySymbol + ")",
										"Cost (" + this.globalCurrencySymbol + ")",
										"Profit (" + this.globalCurrencySymbol + ")",
										"Commission"
									];
								}
								if (this.enable_invoice_custom_fields_feature) {
									if (this.listOfFeatureCustomFields.length > 0) {
										this.listOfFeatureCustomFields.forEach(element => {
											//let customFieldLst: any;
											// if (this.calculate_profit_on_document_header_by === 'base_profit') {
											// 	customFieldLst = ['total_cost', 'pricelist_price'];
											// }
											// if (this.calculate_profit_on_document_header_by === 'list_price_and_cost') {
											// 	customFieldLst = ['base_profit_amount'];
											// }
											// const indexCust = customFieldLst.findIndex(i => i === element.ix_custom_field);
											// if (indexCust === -1) {
											if (element.display) {
												invoiceColumns.push(element.custom_field_title);
												invoiceColumnsField.push(element.ix_custom_field);
											}
											//}
										});
									}
								}
								let row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 3);

								invoiceColumns.forEach((columnName, currentColumnIndex) => {
									row.keepColumnWidths = true;
									Object.assign(row.getCell(itemColumnIndexDetail + currentColumnIndex), {
										value: columnName,
										fill: {
											type: "pattern",
											pattern: "solid",
											fgColor: { argb: "BEDFE6" }
										},
										font: { bold: true },
										border: {
											bottom: borderStyle,
											left: borderStyle,
											right: borderStyle,
											top: borderStyle
										}
									});
								});

								let subTotalLineTotal = 0;
								listOfItemMasterDetailsRows[i].data.items[itemIndex].invoices.forEach((invoiceDetails, invoiceIndex) => {
									row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 3);
									row.keepColumnWidths = true;
									invoiceColumnsField.forEach((columnName, currentColumnIndex) => {
										let valField = invoiceDetails[columnName];
										if (columnName === 'document_type') {
											const indexDT = this.listOfDocTypeHeaderFilter.findIndex(i => i.value === valField);
											if (indexDT !== -1) {
												valField = this.listOfDocTypeHeaderFilter[indexDT].text;
											}
										}
										if (columnName === 'uomcode') {
											const indexDT = this.listOfUoms.findIndex(i => i.code === valField);
											if (indexDT !== -1) {
												valField = this.listOfUoms[indexDT].name;
											}
										}
										if (columnName === 'tier_basis') {
											const indexTB = this.listOfTierBasis.findIndex(i => i.code === valField);
											if (indexTB !== -1) {
												valField = this.listOfTierBasis[indexTB].label;
											}
										}
										if (columnName === 'commission_basis') {
											const indexCB = this.listOfCommissionBasis.findIndex(i => i.code === valField);
											if (indexCB !== -1) {
												valField = this.listOfCommissionBasis[indexCB].label;
											}
										}
										if (columnName === 'miscdeductioninventory') {
											valField = this.doConvertPercentValue(valField);
										}

										const cellObject = {
											value: valField,
											fill: {
												type: "pattern",
												pattern: "solid",
												fgColor: { argb: "BEDFE6" }
											},
											border: {
												bottom: borderStyle,
												left: borderStyle,
												right: borderStyle,
												top: borderStyle
											},
											alignment: { horizontal: 'left' }
										};

										Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
										row.keepColumnWidths = true;
									});
								});
								row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
								let rowLastCell: number;
								row.keepColumnWidths = true;
							}
							//#endregion invoice code
						});
						row = insertRow(listOfItemMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;

					}
					let rowLastCell: number;
					offset--;
				}
			}).then(function () {
				if (context.changeOrderInvoiceDataGrid && context.changeOrderInvoiceDataGrid.instance) {
					return exportDataGrid({
						worksheet: changeOrderSheet,
						component: context.changeOrderInvoiceDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'commissionAmount' || gridCell.column.dataField === 'previousCommissionAmount' || gridCell.column.dataField === 'commissionAmountDiff' || gridCell.column.dataField === 'linetotal') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.arcreditmemosDataGrid && context.arcreditmemosDataGrid.instance) {
					return exportDataGrid({
						worksheet: arCreditMemosSheet,
						component: context.arcreditmemosDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'commissionAmount' || gridCell.column.dataField === 'linetotal') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.extraCommissionBasedRulesDataGrid && context.extraCommissionBasedRulesDataGrid.instance) {
					return exportDataGrid({
						worksheet: extraCommissionBasedRulesSheet,
						component: context.extraCommissionBasedRulesDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'commissionAmount' || gridCell.column.dataField === 'linetotal') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.miscellaneousDeductionRulesDataGrid && context.miscellaneousDeductionRulesDataGrid.instance) {
					return exportDataGrid({
						worksheet: miscellaneousDeductionRulesSheet,
						component: context.miscellaneousDeductionRulesDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'commissionAmount' || gridCell.column.dataField === 'linetotal') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.serviceCreditmemosDataGrid && context.serviceCreditmemosDataGrid.instance) {
					return exportDataGrid({
						worksheet: serviceCreditMemoSheet,
						component: context.serviceCreditmemosDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'doctotal') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.amortizedPaymentsDataGrid && context.amortizedPaymentsDataGrid.instance && context.enable_amortise_payments) {
					return exportDataGrid({
						worksheet: amortizedPaymentsSheet,
						component: context.amortizedPaymentsDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'total_commission' || gridCell.column.dataField === 'payment_amount') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.modifiedInvoicesDataGrid && context.modifiedInvoicesDataGrid.instance && context.enable_incremental_payment) {
					return exportDataGrid({
						worksheet: modifiedInvoiceSheet,
						component: context.modifiedInvoicesDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'total_commission' || gridCell.column.dataField === 'payment_amount') {
									// excelCell.value = '';
									// if (gridCell.value) {
									// 	excelCell.value = '$ ' + parseFloat(gridCell.value.toString()).toFixed(2);;
									// }
								}
							}
						}
					});
				}
			}).then(function () {
				// https://github.com/exceljs/exceljs#writing-xlsx

				workbook.xlsx.writeBuffer().then(function (buffer) {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
				});
			});
			//e.cancel = true;
		} else {
			this._ToastrService.info('Invoice data not found !', 'info', { closeButton: true, tapToDismiss: true });
		}
	}

	//  #region for Export PDF Logics
	public doExportJsPdf() {
		const context = this;
		const subusername = this.invoiceDetails.subusername || '';
		const apInvoiceNumber = this.invoiceDetails.apInvoiceNumber || '';
		let fileName = '';
		if (apInvoiceNumber) {
			fileName = apInvoiceNumber + '_';
		}
		if (subusername) {
			fileName = subusername + '_';
		}
		fileName = fileName + 'Commission_Report.pdf';
		if (this.invoiceDetails.apInvoiceNumber) {
			fileName = this.invoiceDetails.apInvoiceNumber + '_' + fileName;
		}
		const doc = new jsPDF('landscape', 'mm', 'legal');
		const componentInstance = this.commissionDetails.calculate_commission_by === 'orders' ? context.ordersDataGrid.instance : context.customersInvoiceDataGrid.instance;

		const lastPoint = { x: 0, y: 0 };

		/* Put into Center
		const contractName = this.invoiceDetails.contractName;
		const headerWidth = doc.getTextDimensions(contractName).w;
		doc.setFontSize(16);
		doc.text(contractName, (pageWidth - headerWidth) / 2, 20);
		*/
		this.addFontToJsPdf(doc);
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';
		let intialAxisX = 5;
		const intialAxisY = 15;
		const pageWidth = doc.internal.pageSize.getWidth();
		const pageHeight = doc.internal.pageSize.getHeight();

		let incrementalAxisY = 0;
		incrementalAxisY += 55;
		const commissionsLabel = 'Commissions';
		doc.setFont(semiBoldFont, 'normal');
		doc.setFontSize(15);
		doc.text(commissionsLabel, intialAxisX, incrementalAxisY);
		incrementalAxisY += 5;
		exportDataGridPdf({
			jsPDFDocument: doc,
			component: componentInstance,
			margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
			repeatHeaders: true,
			customizeCell(options) {
				const { gridCell, pdfCell } = options;
				context.doCustomizeCell(gridCell, pdfCell);
				if (gridCell.rowType === 'data' && gridCell.column.dataField === 'eligible_for_commission') {
					pdfCell.text = gridCell.value ? 'Yes' : 'No';
				}
			},
			customDrawCell(options) {
				const { gridCell, pdfCell, rect } = options;
				context.doCustomizeDrawCell(gridCell, pdfCell);

				if (lastPoint.x < rect.x + rect.w) {
					lastPoint.x = rect.x + rect.w;
				}
				if (lastPoint.y < rect.y + rect.h) {
					lastPoint.y = rect.y + rect.h;
				}
			},
			onRowExporting(options) {
				const { rowCells, rowHeight } = options;
			},
		}).then(() => {
			if (context.changeOrderInvoiceDataGrid && context.changeOrderInvoiceDataGrid.instance) {
				const listOfVisibileRows = context.changeOrderInvoiceDataGrid.instance.getVisibleRows();
				if (listOfVisibileRows && listOfVisibileRows.length > 0) {
					doc.addPage();
					incrementalAxisY = 0;
					incrementalAxisY += 55;
					const changeOrdersLabel = 'Change Order(s)';
					doc.setFont(semiBoldFont, 'normal');
					doc.setFontSize(15);
					doc.text(changeOrdersLabel, intialAxisX, incrementalAxisY);
					incrementalAxisY += 5;
					return exportDataGridPdf({
						jsPDFDocument: doc,
						component: context.changeOrderInvoiceDataGrid.instance,
						margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
						repeatHeaders: true,
						customizeCell: (options) => {
							const { gridCell, pdfCell } = options;
							context.doCustomizeCell(gridCell, pdfCell);
						},
						customDrawCell(options) {
							const { gridCell, pdfCell, rect } = options;
							context.doCustomizeDrawCell(gridCell, pdfCell);
						},
					});
				}
			}
		}).then(() => {
			if (context.extraCommissionBasedRulesDataGrid && context.extraCommissionBasedRulesDataGrid.instance) {
				const listOfVisibileRows = context.extraCommissionBasedRulesDataGrid.instance.getVisibleRows();
				if (listOfVisibileRows && listOfVisibileRows.length > 0) {
					doc.addPage();
					incrementalAxisY = 0;
					incrementalAxisY += 55;
					const additionalIncentivesLabel = 'Additional Incentives';
					doc.setFont(semiBoldFont, 'normal');
					doc.setFontSize(15);
					doc.text(additionalIncentivesLabel, intialAxisX, incrementalAxisY);
					incrementalAxisY += 5;
					return exportDataGridPdf({
						jsPDFDocument: doc,
						component: context.extraCommissionBasedRulesDataGrid && context.extraCommissionBasedRulesDataGrid.instance,
						margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
						repeatHeaders: true,
						customizeCell: (options) => {
							const { gridCell, pdfCell } = options;
							context.doCustomizeCell(gridCell, pdfCell);
						},
						customDrawCell(options) {
							const { gridCell, pdfCell, rect } = options;
							context.doCustomizeDrawCell(gridCell, pdfCell);
						}
					});
				}
			}
		}).then(() => {
			if (context.miscellaneousDeductionRulesDataGrid && context.miscellaneousDeductionRulesDataGrid.instance) {
				const listOfVisibileRows = context.miscellaneousDeductionRulesDataGrid.instance.getVisibleRows();
				if (listOfVisibileRows && listOfVisibileRows.length > 0) {
					doc.addPage();
					incrementalAxisY = 0;
					incrementalAxisY += 55;
					const miscellaneousDeductionLabel = 'Miscellaneous Deduction';
					doc.setFont(semiBoldFont, 'normal');
					doc.setFontSize(15);
					doc.text(miscellaneousDeductionLabel, intialAxisX, incrementalAxisY);
					incrementalAxisY += 5;
					return exportDataGridPdf({
						jsPDFDocument: doc,
						component: context.miscellaneousDeductionRulesDataGrid && context.miscellaneousDeductionRulesDataGrid.instance,
						margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
						repeatHeaders: true,
						customizeCell: (options) => {
							const { gridCell, pdfCell } = options;
							context.doCustomizeCell(gridCell, pdfCell);
						},
						customDrawCell(options) {
							const { gridCell, pdfCell, rect } = options;
							context.doCustomizeDrawCell(gridCell, pdfCell);
						}
					});
				}
			}
		}).then(() => {
			if (context.serviceCreditmemosDataGrid && context.serviceCreditmemosDataGrid.instance) {
				const listOfVisibileRows = context.serviceCreditmemosDataGrid.instance.getVisibleRows();
				if (listOfVisibileRows && listOfVisibileRows.length > 0) {
					doc.addPage();
					incrementalAxisY = 0;
					incrementalAxisY += 55;
					const serviceCreditMemoLabel = 'Service Credit Memo';
					doc.setFont(semiBoldFont, 'normal');
					doc.setFontSize(15);
					doc.text(serviceCreditMemoLabel, intialAxisX, incrementalAxisY);
					incrementalAxisY += 5;
					return exportDataGridPdf({
						jsPDFDocument: doc,
						component: context.serviceCreditmemosDataGrid && context.serviceCreditmemosDataGrid.instance,
						margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
						repeatHeaders: true,
						customizeCell: (options) => {
							const { gridCell, pdfCell } = options;
							context.doCustomizeCell(gridCell, pdfCell);
						},
						customDrawCell(options) {
							const { gridCell, pdfCell, rect } = options;
							context.doCustomizeDrawCell(gridCell, pdfCell);
						}
					});
				}
			}
		}).then(() => {
			if (context.amortizedPaymentsDataGrid && context.amortizedPaymentsDataGrid.instance) {
				doc.addPage();
				incrementalAxisY = 0;
				incrementalAxisY += 55;
				const amortizedPaymentsLabel = 'Amortized Payments';
				doc.setFont(semiBoldFont, 'normal');
				doc.setFontSize(15);
				doc.text(amortizedPaymentsLabel, intialAxisX, incrementalAxisY);
				incrementalAxisY += 5;
				return exportDataGridPdf({
					jsPDFDocument: doc,
					component: context.amortizedPaymentsDataGrid && context.amortizedPaymentsDataGrid.instance,
					margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
					repeatHeaders: true,
					customizeCell: (options) => {
						const { gridCell, pdfCell } = options;
						context.doCustomizeCell(gridCell, pdfCell);
					},
					customDrawCell(options) {
						const { gridCell, pdfCell, rect } = options;
						context.doCustomizeDrawCell(gridCell, pdfCell);
					}
				});
			}
		}).then(() => {
			this.addHeaderToJsPdf(doc);
			this.addPageNumberToJsPdf(doc);
			doc.save(fileName);
		});
	}

	private addPageNumberToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova';
		const pageCount = doc.getNumberOfPages();
		doc.setFont(regularFont, 'normal');
		doc.setFontSize(9);
		for (var i = 1; i <= pageCount; i++) {
			doc.setPage(i);
			doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, {
				align: 'center'
			});
		}
	}

	private addHeaderToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';
		const pageWidth = doc.internal.pageSize.getWidth();
		const pageHeight = doc.internal.pageSize.getHeight();
		const pageCount = doc.getNumberOfPages();


		let invoiceOrOrderTotal = 0;
		let listOfInvoicesOrOrders: any[] = [];
		if (this.commissionDetails.calculate_commission_by === 'orders') {
			listOfInvoicesOrOrders = this.ordersDataGrid.instance.getDataSource().items() || [];
		} else {
			listOfInvoicesOrOrders = this.customersInvoiceDataGrid.instance.getDataSource().items() || [];
		}

		if (listOfInvoicesOrOrders && listOfInvoicesOrOrders.length > 0) {
			listOfInvoicesOrOrders.map(io => {
				invoiceOrOrderTotal += (io.commissionAmount && io.commissionAmount.toString().includes('e')) ? 0 : io.commissionAmount;
			});
		}
		let changedOrderTotal = 0;
		if (this.changeOrderInvoiceDataGrid && this.changeOrderInvoiceDataGrid.instance.getVisibleRows().length > 0) {
			const listOfChangeOrders: any[] = this.changeOrderInvoiceDataGrid.instance.getDataSource().items() || [];
			listOfChangeOrders.map(co => {
				changedOrderTotal += (co.commissionAmountDiff && co.commissionAmountDiff.toString().includes('e')) ? 0 : co.commissionAmountDiff;
			});
		}

		for (let i = 1; i <= pageCount; i++) {
			let intialAxisX = 5;
			const intialAxisY = 15;
			let incrementalAxisY = 15;
			doc.setPage(i);
			const commissionReport = 'Commission Report';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(18);
			doc.text(commissionReport, intialAxisX, incrementalAxisY);

			incrementalAxisY += 7; // 22;
			const contractName = this.invoiceDetails.contractName;
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(18);
			doc.text(contractName, intialAxisX, incrementalAxisY);

			const fontSizeForSubRow = 12;
			let isSalesRep = false;
			if (this.invoiceDetails.subusername) {
				isSalesRep = true;
				// Sales Rep :
				incrementalAxisY += 10;
				const salesRepLabel = 'Sales Rep: ';
				doc.setFontSize(fontSizeForSubRow);
				doc.setFont(regularFont, 'normal');
				doc.text(salesRepLabel, intialAxisX, incrementalAxisY);
				let row0Xaxis = intialAxisX + doc.getStringUnitWidth(salesRepLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

				const salesRepValue = this.invoiceDetails.subusername;
				doc.setFontSize(fontSizeForSubRow);
				doc.setFont(boldFont, 'bold');
				doc.text(salesRepValue, row0Xaxis, incrementalAxisY);
			}

			// Generated: DATETIME
			incrementalAxisY += isSalesRep ? 6 : 10; // 34
			const generatedLabel = 'Generated: ';
			doc.setFontSize(fontSizeForSubRow);
			doc.setFont(regularFont, 'normal');
			doc.text(generatedLabel, intialAxisX, incrementalAxisY);
			let row1Xaxis = intialAxisX + doc.getStringUnitWidth(generatedLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

			const generatedValue = moment().format('YYYY-MM-DD | hh:mm:ss A');
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(generatedValue, row1Xaxis, incrementalAxisY);
			row1Xaxis += doc.getStringUnitWidth(generatedValue) * fontSizeForSubRow / doc.internal.scaleFactor;

			// Settlement Period: : DATE TO DATE
			const settlementPeriodLabel = 'Settlement Period: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			row1Xaxis += 10;
			doc.text(settlementPeriodLabel, row1Xaxis, incrementalAxisY);
			row1Xaxis += doc.getStringUnitWidth(settlementPeriodLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

			const settlementPeriodValue = this.invoiceDetails.settlementPeriodStart + ' To ' + this.invoiceDetails.settlementPeriodEnd;
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(settlementPeriodValue, row1Xaxis, incrementalAxisY);

			// Commission : $00.00
			incrementalAxisY += 6; // 40
			const commissionText = 'Current Commissions: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(commissionText, intialAxisX, incrementalAxisY);
			let row2Xaxis = intialAxisX + doc.getStringUnitWidth(commissionText) * fontSizeForSubRow / doc.internal.scaleFactor;

			const commissionValueText = this._CommissionService.convertCurrency(invoiceOrOrderTotal || 0, this.customCurrencyOption);
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(commissionValueText, row2Xaxis, incrementalAxisY);
			row2Xaxis += doc.getStringUnitWidth(commissionValueText) * fontSizeForSubRow / doc.internal.scaleFactor;

			if (this.changeOrderInvoiceDataGrid && this.changeOrderInvoiceDataGrid.instance.getVisibleRows().length > 0) {
				// Change Order : $00.00
				const changeOrderText = 'Change Order(s): ';
				doc.setFont(regularFont, 'normal');
				doc.setFontSize(fontSizeForSubRow);
				row2Xaxis += 10;
				doc.text(changeOrderText, row2Xaxis, incrementalAxisY);
				row2Xaxis += doc.getStringUnitWidth(changeOrderText) * fontSizeForSubRow / doc.internal.scaleFactor;

				const changeOrderValueText = this._CommissionService.convertCurrency(changedOrderTotal || 0, this.customCurrencyOption);
				doc.setFont(boldFont, 'bold');
				doc.setFontSize(fontSizeForSubRow);
				doc.text(changeOrderValueText, row2Xaxis, incrementalAxisY);
				row2Xaxis += doc.getStringUnitWidth(changeOrderValueText) * fontSizeForSubRow / doc.internal.scaleFactor;


				// Total : $00.00
				const totalLabel = 'Current Total: ';
				doc.setFont(regularFont, 'normal');
				doc.setFontSize(fontSizeForSubRow);
				row2Xaxis += 10;
				doc.text(totalLabel, row2Xaxis, incrementalAxisY);
				row2Xaxis += doc.getStringUnitWidth(totalLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

				const totalValueText = this._CommissionService.convertCurrency((+invoiceOrOrderTotal + +changedOrderTotal) || 0, this.customCurrencyOption);
				doc.setFont(boldFont, 'bold');
				doc.setFontSize(fontSizeForSubRow);
				doc.text(totalValueText, row2Xaxis, incrementalAxisY);
			}
			if (this.companyImageBase64) {
				const imageWidth = 40;
				const imageHeight = 18;
				const imageXPosition = (pageWidth - imageWidth) - intialAxisX;
				const imageYPosition = intialAxisY;
				const imageProperties = doc.getImageProperties(this.companyImageBase64);
				doc.addImage(this.companyImageBase64, imageProperties.fileType.toLowerCase(), imageXPosition, imageYPosition, imageWidth, imageHeight);
			} else {
				const companyNameLabel = this._LoginService.loginUser.account_detail.company_name;
				doc.setFont(boldFont, 'bold');
				doc.setFontSize(18);
				doc.text(companyNameLabel, pageWidth - intialAxisX, intialAxisY, { align: 'right' });
			}
		}
	}

	private addFontToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova.ttf';
		const semiBoldFont = 'proxima_nova semiBold.ttf';
		const boldFont = 'proxima_nova Bold.ttf';
		doc.addFileToVFS(regularFont, proxima_nova);
		doc.addFileToVFS(semiBoldFont, proxima_nova_semi_bold);
		doc.addFileToVFS(boldFont, proxima_nova_bold);
		doc.addFont(regularFont, 'proxima_nova', 'normal');
		doc.addFont(semiBoldFont, 'proxima_nova semiBold', 'normal');
		doc.addFont(boldFont, 'proxima_nova Bold', 'bold');
	}

	private doCustomizeDrawCell(gridCell: any, pdfCell: any) {
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';

		if (gridCell.rowType === 'header') {
			pdfCell.font.name = boldFont;
			pdfCell.font.style = 'bold';
			pdfCell.font.size = 9;
		}
		if (gridCell.rowType === 'data') {
			pdfCell.font.name = regularFont;
			pdfCell.font.style = 'normal';
			pdfCell.font.size = 9;
		}
		if (gridCell.rowType === 'totalFooter') {
			pdfCell.font.name = boldFont;
			pdfCell.font.style = 'bold';
			pdfCell.font.size = 9;
		}
	}

	private doCustomizeCell(gridCell: any, pdfCell: any) {
		pdfCell.borderColor = '#C4C4C4';
		pdfCell.drawLeftBorder = false;
		pdfCell.drawRightBorder = false;
		pdfCell.borderWidth = 0.5;
		pdfCell.textColor = '#000';
		pdfCell.wordWrapEnabled = true;
	}

	// #endregion for Export PDF Logics
	public doExportItemsActions(event: any) {
		if (event && event.itemData) {
			if (event.itemData.btnCode === 'export_column_configs') {
				this.doOpenPopupForExport();
			}
			if (event.itemData.btnCode === 'export_pdf') {
				const sectionConfigObj = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING);

				if (sectionConfigObj && sectionConfigObj.hasOwnProperty('export_columns_configs')) {
					const exportColumns = sectionConfigObj.export_columns_configs.list_of_columns || [];
					const isExportObj = exportColumns.find(column => column.is_export === true);
					if (isExportObj && isExportObj.guid) {
						this.doDownloadPdf();
					} else {
						this.doOpenPopupForExport();
					}
				} else {
					this.doOpenPopupForExport();
				}
			}
		}
	}

	public doOpenPopupForExport() {
		const listOfMasterDatagrid = [];
		if (this.customersInvoiceDataGrid) {
			let listOfCustomers = [];
			let listOfProducts = [];
			let listOfInvoices = [];
			const columns = this.customersInvoiceDataGrid.instance.getVisibleColumns();
			listOfCustomers = columns.map((column) => {
				return {
					dataField: column.dataField,
					caption: column.caption,
					dataType: column.dataType
				}
			});
			if (this.invoicesItemDataGrid) {
				const columns = this.invoicesItemDataGrid.instance.getVisibleColumns();
				listOfProducts = columns.map((column) => {
					return {
						dataField: column.dataField,
						caption: column.caption,
						dataType: column.dataType
					}
				});
			}
			if (this.invoicesDataGrid) {
				const columns = this.invoicesDataGrid.instance.getVisibleColumns();
				listOfInvoices = columns.map((column) => {
					return {
						dataField: column.dataField,
						caption: column.caption,
						dataType: column.dataType
					}
				});
			}
			listOfMasterDatagrid.push({
				code: 'customers',
				list_of_customers: listOfCustomers,
				list_of_products: listOfProducts,
				list_of_invoices: listOfInvoices
			});
		}
		if (this.ordersDataGrid) {
			const columns = this.ordersDataGrid.instance.getVisibleColumns();
			const listOfColumns = columns.map((column) => {
				return {
					dataField: column.dataField,
					caption: column.caption,
					dataType: column.dataType
				}
			});
			listOfMasterDatagrid.push({
				code: 'orders',
				list_of_orders: listOfColumns,
			});
		}
		this.listOfMasterDatagrid = listOfMasterDatagrid;
		this.isShowPopupDatagridExport = false;
		setTimeout(() => {
			this.isShowPopupDatagridExport = true;
		}, 100);
	}

	public doExportPdfEmitting(event: any) {
		if (event) {
			this.doDownloadPdf();
		}
	}

	private doDownloadPdf() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DOWNLOAD_PDF);
		formData.append('entity', ServerEntity.SETTLEMENTREPORT);
		formData.append('entity_id', this.invoiceDetails.guid);
		if (this.downloadPdfSbsn) {
			this.downloadPdfSbsn.unsubscribe();
		}
		this.downloadPdfSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag && response.data) {
					this.isShowPopupDatagridExport = false;
					const iframe = '<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="' + response.data.pdfLink + '" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>';

					let win: any = window.open('', '_blank', 'toolbar=no,location=no,menubar=no,resizable=yes,height=720,width=1080,scrollbars=yes,status=yes');
					win.document.write(iframe);
				}
			}, error: (error) => {

			}
		})
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code === 'EXPORT_PDF');
		const closeCurrSettIndex = this.listOfActions.findIndex(i => i.btn_code === 'CLOSE_CURRENT_SETTLEMENT_PERIOD');
		const updateCurrSettIndex = this.listOfActions.findIndex(i => i.btn_code === 'UPDATE_CURRENT_SETTLEMENT');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].visible = this.isShowExportPdf;
		}
		if (closeCurrSettIndex !== -1) {
			this.listOfActions[closeCurrSettIndex].visible = this.invoiceDetails.apInvoiceCreatedInERP === 'Open' ? true : false;
		}
		if (updateCurrSettIndex !== -1) {
			this.listOfActions[updateCurrSettIndex].visible = this.invoiceDetails.apInvoiceCreatedInERP === 'Open' ? true : false;
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'CLOSE_CURRENT_SETTLEMENT_PERIOD':
					this.doActionCloseCurrentSettlementPeriod();
					break;
				case 'UPDATE_CURRENT_SETTLEMENT':
					this.doActionUpdateCurrentSettlement();
					break;
				case 'EXPORT_PDF':
					this.doExportJsPdf();
					break;
				case 'EXPORT':
					this.onExporting();
					break;
			}
		}
	}

	loadInvoicesDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.INVOICES,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.invoicesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	loadChangeOrdersDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.CHANGE_ORDERS,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.changeOrderDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	loadExtraCommissionBasedRulesDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.EXTRA_COMMISSION_BASED_RULES,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.extraCommissionBasedRulesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	loadAmortizedPaymentsDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.AMORTIZED_PAYMENTS,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.amortizedPaymentsDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	loadArcreditmemosSourceDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.AR_CREDIT_MEMOS,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.arcreditmemosDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	loadServicecreditmemosSourceDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.SERVICE_AR_CREDIT_MEMOS,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.serviceCreditmemosDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	loadMiscellaneousDeductionRulesDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.MISCELLANEOUS_DEDUCTION_RULES,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.miscellaneousDeductionRulesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	loadModifiedInvoiceDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: ServerSections.CHANGE_INVOICES,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
			contractguid: this.invoiceDetails.guid,
		}
		this.modifiedInvoicesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}
	public doCustomizeTotal(e) {
		if (e.valueText) {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		}
	}
	// Method used to display zero if negative summary value
	public doCustomizeTotalSummary(e) {
		if (e.valueText && e.valueText > 0) {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		}
	}

	public doCalculateCellValueOrgCommissionAmount(rowData?: any) {
		let docCurrencyName = rowData.doccurrency || '';
		let customCurrencyOption: CurrencyOptions = new CurrencyOptions();
		if (rowData.doccurrency) {
			customCurrencyOption.currencyCode = this.customCurrencyOption.currencyCode;
			customCurrencyOption.currencyName = docCurrencyName;
			if (rowData.orgCommissionAmount && rowData.orgCommissionAmount > 0) {
				return this._CommissionService.convertCurrency(rowData.orgCommissionAmount, customCurrencyOption);
			} else {
				return this._CommissionService.convertCurrency(0, customCurrencyOption);
			}
		}
	}

	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
						this.listOfUomsHeaderFilter = this.getHeaderFilter(response.data, 'code', 'name');
					}
				}
			}, error: (error) => {
				// this._LoaderService.hide();
			}
		});
	}

	private getHeaderFilter(listOfArray: any[], codeField: string, textField: string): any[] {
		const listOfHeaderFilters = [];
		listOfArray.forEach(ele => {
			listOfHeaderFilters.push({ value: ele[codeField], text: ele[textField].toString().toUpperCase() });
		});
		return listOfHeaderFilters;
	}
	// #endregion


	public invoiceDataGridCustomizeColumns = (columns, DataType) => {
		// console.log('columns length:: ', columns.length);
		// const preColIndex = 7;
		// let colLen = 0;
		// if (colLen === 0) {
		// 	colLen = columns.length;
		// }
		// const lenCol = colLen + this.listOfFeatureCustomFields.length;

		// if (columns.length === lenCol) {
		// 	for (let j = 0; j < this.listOfFeatureCustomFields.length; j++) {
		// 		columns[columns.length - (this.listOfFeatureCustomFields.length - j)].visibleIndex = preColIndex + j; // action column set on last index in grid-data
		// 		columns[preColIndex + j].visibleIndex = columns.length - (this.listOfFeatureCustomFields.length - j); // action column set on last index in grid-data
		// 	}
		// }
	}
	public orderDataGridCustomizeColumns = (columns, DataType) => {
		// if (this.enable_profit_on_document_header) {
		// 	const sectionLevelKey = this.CommissionSettlementReportSectionEnum.ORDERS;
		// 	const parentSectionLevelKey = '';
		// 	const staticColumnList = this.getStaticColumnList(columns);
		// 	const customFieldDataList = this.getCustomFieldForInvoice(this.listOfFeatureCustomFields);
		// 	const meargeCustomAndStaticColList = this.meargeCustomAndSaticColumn(staticColumnList, customFieldDataList);
		// 	meargeCustomAndStaticColList.forEach(element => {
		// 		// element.visible = this.getStateColVisibleValue(element, sectionLevelKey, parentSectionLevelKey);
		// 		let stateColObj: any = this.getStateColObject(element, sectionLevelKey, parentSectionLevelKey);
		// 		const indexCol = columns.findIndex(i => i.dataField === element.dataField);
		// 		if (stateColObj) {
		// 			element.visible = stateColObj.visible;
		// 			element.visibleIndex = stateColObj.visibleIndex;
		// 		}
		// 		if (indexCol === -1) {
		// 			columns.push(element);
		// 		} else {
		// 			if (stateColObj) {
		// 				columns[indexCol].visible = stateColObj.visible;
		// 				columns[indexCol].visibleIndex = stateColObj.visibleIndex;
		// 			}
		// 		}
		// 	});
		// }
	}
	public orderChangeDataGridCustomizeColumns = (columns, DataType) => {
		// if (this.enable_profit_on_document_header) {
		// 	const sectionLevelKey = this.CommissionSettlementReportSectionEnum.CHANGE_ORDERS;
		// 	const parentSectionLevelKey = '';
		// 	const staticColumnList = this.getStaticColumnList(columns);
		// 	const customFieldDataList = this.getCustomFieldForInvoice(this.listOfFeatureCustomFields);
		// 	const meargeCustomAndStaticColList = this.meargeCustomAndSaticColumn(staticColumnList, customFieldDataList);
		// 	meargeCustomAndStaticColList.forEach(element => {
		// 		// element.visible = this.getStateColVisibleValue(element, sectionLevelKey, parentSectionLevelKey);
		// 		let stateColObj: any = this.getStateColObject(element, sectionLevelKey, parentSectionLevelKey);
		// 		const indexCol = columns.findIndex(i => i.dataField === element.dataField);
		// 		if (stateColObj) {
		// 			element.visible = stateColObj.visible;
		// 			element.visibleIndex = stateColObj.visibleIndex;
		// 		}
		// 		if (indexCol === -1) {
		// 			columns.push(element);
		// 		} else {
		// 			if (stateColObj) {
		// 				columns[indexCol].visible = stateColObj.visible;
		// 				columns[indexCol].visibleIndex = stateColObj.visibleIndex;
		// 			}
		// 		}
		// 	});
		// }
	}

	public initializedInvoiceDataGrid(event) {
		if (this.enable_invoice_custom_fields_feature) {
			const customFieldDataList = this.getCustomFieldForInvoice(this.listOfFeatureCustomFields);
			customFieldDataList.forEach(element => {
				event.component.addColumn(element);
			});
		}
	}
	public initializedOrderDataGrid(event) {
		if (this.enable_profit_on_document_header) {
			const customFieldDataList = this.getCustomFieldForInvoice(this.listOfFeatureCustomFields);
			customFieldDataList.forEach(element => {
				event.component.addColumn(element);
			});
		}
	}
	public initializedChnageOrderDataGrid(event) {
		if (this.enable_profit_on_document_header) {
			const customFieldDataList = this.getCustomFieldForInvoice(this.listOfFeatureCustomFields);
			customFieldDataList.forEach(element => {
				event.component.addColumn(element);
			});
		}
	}

	//comman method for all grid object for column chooser button above grid
	public doToolbarColumnChooserBtnPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		let addRowButton = toolbarItems.find(x => x.name === "columnChooserButton");
		addRowButton.options.text = "Column Chooser";
		addRowButton.showText = "always";
	}


	public getStateColObject(element: any, sectionLevelKey: string, parentSectionLevelKey?: any) {
		let rturnVal: any;
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		const newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
		if (newSection['configs'] && newSection['configs'].length > 0) {
			const statePersistentIndex = newSection.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
			if (statePersistentIndex !== -1) {
				const stateIndex = newSection.configs[statePersistentIndex].state_persistent.columns.findIndex(i => i.dataField === element.dataField);
				if (stateIndex !== -1) {
					rturnVal = newSection.configs[statePersistentIndex].state_persistent.columns[stateIndex];
				}
			}
		}
		return rturnVal;
	}

	//#region Column-chooser

	// column-chooser Method
	public doLoadInvoiceCustomersItemsState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.ITEMS;
		const parentSectionLevelKey = this.CommissionSettlementReportSectionEnum.CUSTOMERS;;
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveInvoiceCustomersItemsState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.ITEMS;
		const parentSectionLevelKey = this.CommissionSettlementReportSectionEnum.CUSTOMERS;
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadInvoiceCustomerItemsInvoiceState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.INVOICES;;
		const parentSectionLevelKey = this.CommissionSettlementReportSectionEnum.ITEMS;;
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveInvoiceCustomerItemsInvoiceState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.INVOICES;
		const parentSectionLevelKey = this.CommissionSettlementReportSectionEnum.ITEMS;
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadInvoiceState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.INVOICES;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveInvoiceState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.INVOICES;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadOrderState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.ORDERS;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveOrderState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.ORDERS;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadChangeOrderState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.CHANGE_ORDERS;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveChangeOrderState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.CHANGE_ORDERS;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadMiscellaneousDeductionRulesState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveMiscellaneousDeductionRulesState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadExtraCommissionBasedRulesState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveExtraCommissionBasedRulesState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadServiceCraditMemoState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveServiceCraditMemoState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadAmortizedPaymentsState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveAmortizedPaymentsState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}

	public doLoadModifiedInvoiceState = () => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.MODIFIED_INVOICES;
		const parentSectionLevelKey = '';
		return this._CommissionService.loadStateData(sectionLevelKey, parentSectionLevelKey);
	}

	public doSaveModifiedInvoiceState = (e) => {
		const sectionLevelKey = this.CommissionSettlementReportSectionEnum.MODIFIED_INVOICES;
		const parentSectionLevelKey = '';
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		this.saveStateData(sLeval, e);
	}
	// End column-chooser Method

	//Save State Data
	public saveStateData(sLeval, e) {
		let commissionSettlementReportConfig: any = {};
		try {
			commissionSettlementReportConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
		} catch (e) {
			commissionSettlementReportConfig = {};
		}

		if (!commissionSettlementReportConfig.configs) {
			commissionSettlementReportConfig.configs = [];
		}

		const configIndex = commissionSettlementReportConfig.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
		if (configIndex !== -1) {
			commissionSettlementReportConfig.configs[configIndex].state_persistent = e;
		} else {
			const statePersistent = {
				guid: this.guid.newGuid(),
				section_level: sLeval.section_level,
				parent_section_level: sLeval.parent_section_level,
				state_persistent: e
			};
			commissionSettlementReportConfig.configs.push(statePersistent);
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING);
		formData.append('user_settings', JSON.stringify(commissionSettlementReportConfig));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING] = commissionSettlementReportConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING, commissionSettlementReportConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING, commissionSettlementReportConfig);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Get state column visible value
	public getStateColVisibleValue(element: any, sectionLevelKey: string, parentSectionLevelKey: string) {
		let rturnVal: boolean = true;
		const sLeval = this._CommissionService.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		const newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
		if (newSection['configs'] && newSection['configs'].length > 0) {
			const statePersistentIndex = newSection.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
			if (statePersistentIndex !== -1) {
				const stateIndex = newSection.configs[statePersistentIndex].state_persistent.columns.findIndex(i => i.dataField === element.dataField);
				if (stateIndex !== -1) {
					rturnVal = newSection.configs[statePersistentIndex].state_persistent.columns[stateIndex].visible;
				}
			}
		}
		return rturnVal;
	}

	//#endregion  column-chooser

	// Convert object array of dynamic column on custom field for invoice
	public getCustomFieldForInvoice(responseData: any) {
		let customFieldDataObj: any;
		let customFieldDataList: any[] = [];
		for (let i = 0; i < responseData.length; i++) {
			customFieldDataObj = {
				dataField: responseData[i].ix_custom_field,
				caption: responseData[i]['custom_field_title'] ? responseData[i].custom_field_title : responseData[i].ix_custom_field_title,
				dataType: responseData[i].custom_field_type,
				visible: responseData[i].display,
				allowFiltering: false,
				visibleIndex: 3 + i,
				width: 'auto'
			};

			if (responseData[i].custom_field_output_format === 'text' || responseData[i].custom_field_output_format === 'string') {
				customFieldDataObj.dataType = 'string';
				customFieldDataObj['encodeHtml'] = false;
			}

			if (responseData[i].custom_field_type === 'date') {
				customFieldDataObj['format'] = 'yyyy-MM-dd';
			}
			//if (responseData[i].ix_custom_field === 'over_pricelist_amount' || responseData[i].ix_custom_field === 'custom_doctotal' || responseData[i].ix_custom_field === 'base_profit_amount') {
			if (responseData[i].custom_field_output_format === 'amount') {
				// customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
				customFieldDataObj['customizeText'] = (cellInfo) => {
					return this._CommissionService.convertCurrency(+cellInfo.value || 0, this.customCurrencyOption);
				}
			}
			if (responseData[i].custom_field_output_format === 'percentage') {
				customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
			}
			//}
			if (responseData[i].custom_field_type === 'object') {
				if (responseData[i].custom_field_output_format === 'object') {
					customFieldDataObj['lookup'] = { dataSource: responseData[i].custom_field_values, displayExpr: "value", valueExpr: "key" };
					customFieldDataObj.dataField = responseData[i].ix_custom_field + '.refName';
				}
				if (responseData[i].custom_field_output_format === 'amount') {
					customFieldDataObj.dataType = 'number';
					// customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
					customFieldDataObj['customizeText'] = (cellInfo) => {
						return this._CommissionService.convertCurrency(+cellInfo.value || 0, this.customCurrencyOption);
					}
				}
				if (responseData[i].custom_field_output_format === 'percentage') {
					customFieldDataObj.dataType = 'number';
					customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
				}
			}
			customFieldDataList.push(customFieldDataObj);
		}
		return customFieldDataList;
	}

	public getStaticColumnList(columns: any) {
		let newColumnsList: any[] = []
		columns.forEach(element => {
			const customFieldDataObj = {
				dataField: element['dataField'],
				caption: element['caption'],
				dataType: element['dataType'],
				visible: element['visible'],
				allowFiltering: false,
			};
			newColumnsList.push(customFieldDataObj);
		});
		return newColumnsList;
	}

	public meargeCustomAndSaticColumn(arr1, arr2) {
		const uniqField = 'dataField';
		_.each(arr2, (arr2obj) => {
			let arr1obj = _.find(arr1, (arr1obj) => {
				return arr1obj[uniqField] === arr2obj[uniqField];
			});
			arr1obj ? _.extend(arr1obj, arr2obj) : arr1.push(arr2obj);
		});
		return arr1;
	}

	public onCellinvoicePrepared(e) {
		// if (e.rowType == 'data') {
		// 	if (e.column['dataField'] && e['value']) {
		// 		const indexCustomField = this.listOfFeatureCustomFields.findIndex(i => i.ix_custom_field === e.column['dataField']);
		// 		if (indexCustomField != -1) {
		// 			if (this.listOfFeatureCustomFields[indexCustomField]['custom_field_output_format']) {
		// 				let customFieldOutputFormatVal = this.listOfFeatureCustomFields[indexCustomField]['custom_field_output_format'] || '';
		// 				if (customFieldOutputFormatVal) {
		// 					if (customFieldOutputFormatVal == 'amount') {
		// 						e.column.customizeText = (element: any) => {
		// 							return this._CustomCurrencyPipe.transform(e['value'] || 0);
		// 						}
		// 					}
		// 					if (customFieldOutputFormatVal == 'percentage') {
		// 						e.column.customizeText = (element: any) => {
		// 							return e['value'] + '%';
		// 						}
		// 					}
		// 				}

		// 			}
		// 		}
		// 	}
		// }
	}

	//#region  estimated rebate calculation pop-up

	public doCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && element.column.dataField === 'rebate_amount') {
				let rebateCalculationTabsList: any = [];
				if (element.data && element.data['rebateCalculation'] && element.data['rebateCalculation'].length > 0) {
					element.data['rebateCalculation'].forEach(element => {
						const objTab = {
							id: element.guid,
							text: element.contractName,
							//icon: 'user',
						}
						rebateCalculationTabsList.push(objTab);
					});
				}
				this.estimatedRebateTabData = rebateCalculationTabsList;
				this.doOpenPopup(element);
			}
		}
	}

	public doOpenPopup(element) {
		this.isVisiblePopup = true;
		this.selectedTabCode = this.estimatedRebateTabData[0].id;
		this.selectedItem = this.estimatedRebateTabData[0];
		this.selectedTabIndex = 0;
		this.getRebatesContractDetails(this.selectedTabCode);
	}

	public doHiddenPopop(e) {
		this.isVisiblePopup = false;
	}

	public doTabSelectionChanged(element) {
		this.getRebatesContractDetails(element.addedItems[0].id);
	}

	private getRebatesContractDetails(rebatesGuid: any) {
		this._RebateService.setActionFrom('rebates');
		if (rebatesGuid) {
			this.featchRebateContract(rebatesGuid);
		}
		//this._RebateService.setEditRebatesGuid(rebatesGuid);
	}

	public doTabDragStart(event: any) {

	}

	public doTabDragStop(event: any) {

	}
	//#endregion
	public featchRebateContract(rebateGuid) {
		this.isviewRebateCalView = false;
		const formData = new FormData();
		const searchParams = [{ 'guid': rebateGuid }];
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('guid', rebateGuid);
		formData.append('settlementPeriodStart', this.invoiceDetails.settlementPeriodStart);
		formData.append('settlementPeriodEnd', this.invoiceDetails.settlementPeriodEnd);
		formData.append('search', JSON.stringify(searchParams));
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						if (response['data'] && response['data'].length > 0) {
							response.data[0]['settlementPeriodStart'] = this.invoiceDetails.settlementPeriodStart;
							response.data[0]['settlementPeriodEnd'] = this.invoiceDetails.settlementPeriodEnd;
							this._RebateService.setInvoiceDetails(response.data[0] || [])
							this.isviewRebateCalView = true;
						}
					}
				} else {
					this._ToastrService.info(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	doConvertPercentValue(value: any) {
		return this._CustomNumberNoRoundingPipe.transform(+value || 0) + '%';
	}
	doCustomizeTextToPercent(cellInfo) {
		return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
	}
	doCustomizeTextToDollar(cellInfo) {
		return this._CommissionService.convertCurrency((+cellInfo.value || 0), this.customCurrencyOption);
	}
	// Order Cell Click
	doOnOrderCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewOrderChnageOrderDetail = new ViewOrderChangeOrdereDetailPopUpModel();
				viewOrderChnageOrderDetail.docNo = element.data[element.column.dataField];
				viewOrderChnageOrderDetail.docType = 'order';
				this.doOpenOrderChangeOrderDetailPopup(viewOrderChnageOrderDetail);
			}
		}
	}
	// ChangeOrder Cell Click
	doOnChangeOrderCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewOrderChangeOrderDetail = new ViewOrderChangeOrdereDetailPopUpModel();
				viewOrderChangeOrderDetail.docNo = element.data[element.column.dataField];
				viewOrderChangeOrderDetail.docType = 'change_order';
				this.doOpenOrderChangeOrderDetailPopup(viewOrderChangeOrderDetail);
			}
		}
	}
	// Credit Memo > Item Cell Click
	doOnCrMemoItemCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewCreditMemoDetail = new ViewCreditMemoDetailPopUpModel();
				viewCreditMemoDetail.docNo = element.data[element.column.dataField];
				viewCreditMemoDetail.docType = 'creditmemo';
				this.doOpenCreditMemoDetailPopup(viewCreditMemoDetail);
			}
		}
	}
	// AmotizedPayment Cell Click
	doOnAmortizedPaymentCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
				viewInvoiceDetail.docNo = element.data[element.column.dataField];
				viewInvoiceDetail.docType = 'invoice';
				this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
			}
		}
	}
	// Invoice > Item Cell Click
	doOnInvoiceItemCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
				viewInvoiceDetail.docNo = element.data[element.column.dataField];
				viewInvoiceDetail.docType = 'invoice';
				this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
			}
		}
	}
	// Invoice > Item > Invoice Cell Click
	doOnInvoiceItemInvoiceCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
				viewInvoiceDetail.docNo = element.data[element.column.dataField];
				viewInvoiceDetail.docType = 'invoice';
				viewInvoiceDetail.docDate = element.data.hasOwnProperty('docdate') ? element.data['docdate'] : '';
				this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
			}
		}
	}
	// Show Invoice Deatil PopUp
	private doOpenInvoiceDetailPopup(modelData: ViewInvoiceDetailPopUpModel) {
		this.viewInvoiceDetailProps.docNo = modelData.docNo;
		this.viewInvoiceDetailProps.docType = modelData.docType;
		this.viewInvoiceDetailProps.docDate = modelData.docDate;
		this.isShowInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowInvoiceDetailPopUp = true;
		}, 300);
	}
	// Show ChangeOrder PopUp
	private doOpenOrderChangeOrderDetailPopup(modelData: ViewOrderChangeOrdereDetailPopUpModel) {
		this.viewOrderChangeOrderDetailProps.docNo = modelData.docNo;
		this.viewOrderChangeOrderDetailProps.docType = modelData.docType;
		this.isShowOrderChangeOrderDetailPopUp = false;
		setTimeout(() => {
			this.isShowOrderChangeOrderDetailPopUp = true;
		}, 300);
	}
	// Show CreditMemo PopUp
	private doOpenCreditMemoDetailPopup(modelData: ViewCreditMemoDetailPopUpModel) {
		this.viewCreditMemoDetailProps.docNo = modelData.docNo;
		this.viewCreditMemoDetailProps.docType = modelData.docType;
		this.isShowCreditMemoDetailPopUp = false;
		setTimeout(() => {
			this.isShowCreditMemoDetailPopUp = true;
		}, 300);
	}
	//Modified Invoice
	doOnModifiedInvoiceItemCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
				viewInvoiceDetail.docNo = element.data[element.column.dataField];
				viewInvoiceDetail.docType = 'invoice';
				this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
			}
		}
	}

	doOnModifiedInvoicesCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
				viewInvoiceDetail.docNo = element.data[element.column.dataField];
				viewInvoiceDetail.docType = 'invoice';
				this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
			}
		}
	}

	//#region close settlement period

	doActionCloseCurrentSettlementPeriod() {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Close Settlement',
			message: 'Are you sure you want to close current settlement period? <br/>You will not be able to undo the operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const IsOpenMultiSettlmentPopUp = this._CommissionService.getIsOpenMultiSettlmentPopUp();
				if (IsOpenMultiSettlmentPopUp) {
					this._ToastrService.info('The previous process is still in progress. Please try after that complete.', 'Info', { closeButton: true, tapToDismiss: true });
					return;
				}
				if (this.commissionDetails.guid !== undefined) {
					// this.calculateSettlementPeriod();
					this.callCloseSettlementReq();
				}
			}
		});
	}

	callCloseSettlementReq() {
		const formData = new FormData();
		this._LoaderService.show();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.CLOSE_CURRENT_SETTELEMENT);
		formData.append('contractguid', this.commissionDetails.guid);
		formData.append('contracttype', 'commissioncontracts');
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						if (response.data) {
							const closeSettlementData = response.data['closesettlement'];
							if (closeSettlementData && closeSettlementData.length > 0) {
								this.invoiceDetails.ixcode = closeSettlementData[0]['ixcode'];
								this.invoiceDetails.apInvoiceCreatedInERP = closeSettlementData[0]['apInvoiceCreatedInERP'];
								this.invoiceDetails.created_date = closeSettlementData[0]['created_date'];
								this.invoiceDetails.settlementValue = closeSettlementData[0]['settlementValue'];
								this.invoiceDetails.totalCommissionGenerated = closeSettlementData[0]['totalCommissionGenerated'];
								this.preInit();
							}
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			},
			error: (error) => {
				console.log('ERROR ::', error)
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#endregion

	//#region update settlement period
	doActionUpdateCurrentSettlement() {
		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
		formData.append('contractguid', this.commissionDetails.guid);
		formData.append('contracttype', 'commissioncontracts');
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag && response.data) {
						if (response.data['settlementValue']) {
							this.invoiceDetails.settlementValue = response.data['settlementValue'];
						}
						if (response.data['totalCommissionGenerated']) {
							this.invoiceDetails.totalCommissionGenerated = response.data['totalCommissionGenerated'];
						}
						this.preInit();
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			},
			error: (error) => {
				console.log('ERROR ::', error)
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	//#endregion

}








