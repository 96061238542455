import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, HostListener } from "@angular/core";
import { SalesDataSharingService } from "@app/services/sales-service/sales-data-sharing.service";
import { LoginService } from "@app/services/login.service";
import { RestApiService } from "@app/services/rest-service/rest-api.service";
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { ErpTypeValEnum, ApPurchasesEnum, ArCreditMemoEnum } from "@app/constants-enums/enums";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CurrencyOptions, GetRecordsReq, PageOptions } from "@app/models/common.model";
import { GlobalPopoupHeight90Ratio, GlobalPopoupWidth90Ratio, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from "@app/constants-enums/constants";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridComponent } from "devextreme-angular";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { CustomCurrencyPipe, CustomCurrencySymbolPipe } from "@app/pipes/custom-currency/custom-currency.pipe";
import { IxDxAlertService } from "@app/services/ix-dx-alert-services/ix-dx-alert-services";
import * as moment from "moment";

@Component({
	selector: 'app-ar-credit-memo-details',
	templateUrl: './ar-credit-memo-details.component.html',
	styleUrls: ['./ar-credit-memo-details.component.css']
})

export class ArCreditMemoDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	public ArCreditMemoEnum = ArCreditMemoEnum;
	private dataOutReqSubscription: Subscription;
	private reSendCreditMemoSbsn: Subscription;
	public arInvoiceDetails: any = {};
	public documentInvoiceDetails: any;
	private getRecordsReq: GetRecordsReq = new GetRecordsReq();
	private searchParams: any[] = [];
	private currentEntity: string;
	// Matrial design object
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public isShowLoadPanel: boolean = false;

	public isShowViewDocuments: boolean = false;
	public pageOptions: PageOptions = new PageOptions();
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public arCreditMemoDetailsOpenFrom: string;
	public isShowOriginalInvoiceDetails: boolean = false;
	public popupWidth: number;
	public popupHeight: number;
	public isShowCustomerID: boolean;
	public isShowVirtualTag: boolean;
	public isEnableAmortisePaymentsFeature: boolean = false;
	public isItemListLevel: boolean = false;
	private getFetureCustomFieldsSbsn: Subscription;
	private listOfFeatureCustomFields: any[] = [];
	public listOfAmortisePaymentsFieldValue: any[] = [];
	public globalCurrencySymbol: string;
	public isShowCardcodeColumn: boolean;
	public isShowChargeDocument: boolean = false;
	public chargeDocumentProps: any;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public canEnableOtherCurrencies: boolean;
	public isViewResendCreditMemoBtn: boolean = false;
	public isEnableDistributionChannels: boolean = false;
	public listOfDistributionChannels: any[] = [];
	private getListOfDistributionChannelsSbsn: Subscription;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _SalesDataSharingService: SalesDataSharingService,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _CustomCurrencySymbolPipe: CustomCurrencySymbolPipe,
		private _CurrencyPipe: CurrencyPipe,
		private _IxDxAlertService: IxDxAlertService,
		private _CustomCurrencyPipe: CustomCurrencyPipe) { }

	ngOnInit() {
		this.preInIt();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.setDxPopupWidth();
		}, 500);
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.reSendCreditMemoSbsn) {
			this.reSendCreditMemoSbsn.unsubscribe();
		}
		if (this.getFetureCustomFieldsSbsn) {
			this.getFetureCustomFieldsSbsn.unsubscribe();
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		this.setDxPopupWidth();
	}

	private setDxPopupWidth() {
		const innerWidth = window.innerWidth;
		const innerHeight = window.innerHeight;
		this.popupWidth = (innerWidth * GlobalPopoupWidth90Ratio) / 100;
		this.popupHeight = (innerHeight * GlobalPopoupHeight90Ratio) / 100;
	}

	private preInIt() {
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		let erpType = '';
		try {
			erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			erpType = '';
		}
		this.isShowCustomerID = true;
		if (erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
			this.isShowCustomerID = false;
		}
		if (erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
			this.isShowCustomerID = false;
		}
		if (erpType === ErpTypeValEnum.NETSUITE) {
			this.isQBOnlineErpUser = true;
			this.isShowCustomerID = false;
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.canEnableOtherCurrencies = this._LoginService.loginUser.account_detail.enable_other_currencies || false;
		}

		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_distribution_channels')) {
			this.isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels || false;
		}

		this.doCheckUseCustomersFrom();
		this.arCreditMemoDetailsOpenFrom = this._SalesDataSharingService.getArCreditMemoDetailsOpenFrom();
		const arCreditMemoID = this._SalesDataSharingService.getARInvoiceId();
		this.doHideShowVirtualInvoiceTag();
		this.currentEntity = ServerEntity.ARCREDITMEMO;
		this.searchParams = [];
		this.searchParams.push({ 'guid': arCreditMemoID });
		const reqFormData = this.loadSalesDetailsData();
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.isShowLoadPanel = true;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						this.arInvoiceDetails = response.data[0];
						this.isShowViewDocuments = false;
						if (this.arInvoiceDetails.chargebackDocGuid) {
							this.isShowViewDocuments = true;
						}
						if (this.canEnableOtherCurrencies) {
							setTimeout(() => {
								this.customCurrencyOption.currencyName = this.arInvoiceDetails.doccurrency;
								if (this.dataGridContainer) {
									console.log('if')
									this.dataGridContainer.instance.columnOption('price', 'caption', 'Rate (' + this._CustomCurrencySymbolPipe.transform(this.customCurrencyOption) + ')');
								}
							});
						}
						if (this.isEnableDistributionChannels) {
							const filterObj = ['code', 'contains', this.arInvoiceDetails.distribution_channels];
							this.doFetchListOfDistributionChannels(filterObj);
						}
						this.doCheckEnableAmortisePayments();
						this.viewResendCreditMemoBtn();
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this.isShowLoadPanel = false;
			}, error: (error) => {
				this.isShowLoadPanel = false;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public viewResendCreditMemoBtn() {
		this.isViewResendCreditMemoBtn = false;
		let isAdminUser: boolean = false; //Button will only be available for ADMIN USER
		let isDocNum: boolean = false; //Button will only be active if docnum is not present
		let isActiveTime: boolean = false; //Button will only be active 10 minutes after credit memo has been created
		let isExternalErpSet: boolean = false; //Button will only be active if external_erp_url is set in account.json
		if (this._LoginService.loginUser.account_detail) {
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('admin')) {
				isAdminUser = this._LoginService.loginUser.account_detail.admin ? true : false;
			}
			if (this._LoginService.loginUser.account_detail.hasOwnProperty('external_erp_url')) {
				isExternalErpSet = this._LoginService.loginUser.account_detail.external_erp_url ? true : false;
			}
		}
		if (this.arInvoiceDetails) {
			if (this.arInvoiceDetails.hasOwnProperty('docnum')) {
				isDocNum = (this.arInvoiceDetails.docnum == "") ? true : false;
			}
			if (this.arInvoiceDetails.hasOwnProperty('modified_date')) {
				const currentDateTime: any = moment().utc().format('YYYY-MM-DD HH:mm:ss');
				const todayDateTime: any = moment(currentDateTime).toDate();
				const modifiedDateTime: any = moment(this.arInvoiceDetails.modified_date, 'YYYY-MM-DD HH:mm:ss');
				if (modifiedDateTime && todayDateTime) {
					let noOfMintues = modifiedDateTime.diff(todayDateTime, 'minutes');
					if (noOfMintues) {
						let mintues = Math.abs(noOfMintues)
						if (mintues > 10) {
							isActiveTime = true;
						}
					}
				}
			}
		}
		if (isAdminUser && isDocNum && isActiveTime && isExternalErpSet) {
			this.isViewResendCreditMemoBtn = true;
		}
	}

	// View chargeback document dialog
	public viewChargeBackDocumentDetails() {
		this.currentEntity = ServerEntity.CHARGEBACKDOC;
		this.searchParams = [];
		this.searchParams.push({ 'guid': this.arInvoiceDetails.chargebackDocGuid });
		const reqFormData = this.loadSalesDetailsData();
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.isShowLoadPanel = true;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: response => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const chargeBackDocumentDetails = response.data[0];
						this.viewChargeBackDocumentPopUp(chargeBackDocumentDetails);
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this.isShowLoadPanel = false;
			}, error: (error) => {
				this.isShowLoadPanel = false;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private viewChargeBackDocumentPopUp(chargeBackDocumentDetails) {
		this.chargeDocumentProps = {
			chargeBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
			billBackType: 'viewcraditmemo',
			buttonText: {
				cancel: 'Ok'
			}
		};
		this.isShowChargeDocument = false;
		setTimeout(() => {
			this.isShowChargeDocument = true;
		}, 20);
	}

	private loadSalesDetailsData() {
		this.getRecordsReq.usr = this._LoginService.loginUser.user;
		this.getRecordsReq.token = this._LoginService.loginUser.token;
		this.getRecordsReq.entity = this.currentEntity;
		this.getRecordsReq.method = ServerMethods.GET_ENTITY_DATA;
		this.getRecordsReq.search = this.searchParams;
		this.getRecordsReq.is_dropdown = true;
		return this.getRequestString(this.getRecordsReq);
	}

	// Method for Get String which passed as parameters
	private getRequestString(getRecordsReq: GetRecordsReq) {
		const formData = new FormData();
		formData.append('usr', getRecordsReq.usr);
		formData.append('token', getRecordsReq.token);
		formData.append('method', getRecordsReq.method);
		formData.append('entity', getRecordsReq.entity);
		// let reqFormData = 'usr=' + getRecordsReq.usr + '&token=' + getRecordsReq.token;
		// reqFormData = reqFormData + '&method=' + getRecordsReq.method;
		// reqFormData = reqFormData + '&entity=' + getRecordsReq.entity;
		if (getRecordsReq.entity_id) {
			// reqFormData = reqFormData + '&entity_id=' + getRecordsReq.entity_id;
			formData.append('entity_id', getRecordsReq.entity_id);
		}

		if (getRecordsReq.sort_field) {
			// reqFormData = reqFormData + '&sort_dir=' + getRecordsReq.sort_dir;
			// reqFormData = reqFormData + '&sort_field=' + getRecordsReq.sort_field;
			formData.append('sort_dir', getRecordsReq.sort_dir);
			formData.append('sort_field', getRecordsReq.sort_field);
		}
		if (getRecordsReq.search && getRecordsReq.search.length > 0) {
			// reqFormData = reqFormData + '&search=' + JSON.stringify(getRecordsReq.search);
			formData.append('search', JSON.stringify(getRecordsReq.search));
		}
		if (getRecordsReq.is_dropdown) {
			// reqFormData = reqFormData + '&is_dropdown=' + getRecordsReq.is_dropdown;
			formData.append('is_dropdown', getRecordsReq.is_dropdown.toString());
		} else {
			if (getRecordsReq.itemsperpage) {
				const intPageIndex: number = getRecordsReq.page + 1;
				// reqFormData = reqFormData + '&itemsperpage=' + getRecordsReq.itemsperpage;
				// reqFormData = reqFormData + '&page=' + intPageIndex;
				formData.append('itemsperpage', getRecordsReq.itemsperpage.toString());
				formData.append('page', intPageIndex.toString());
			}
		}
		// return reqFormData;
		return formData;
	}

	//#region Tool bar
	public doToolbarPreparing(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel();
					}
				}
			}
		);
	}

	private doExportToExcel() {
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'ARCreditMemo_' + this.arInvoiceDetails.ixcode + '.xlsx';
		worksheet = workbook.addWorksheet('ARCreditMemo');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {

					if (gridCell.column.dataField === 'price') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
					if (gridCell.column.dataField === 'linetotal') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
				}
			}
		}).then((cellRange) => {
			// header
			let rowNumber = 2;
			const headerRow = worksheet.getRow(rowNumber);
			headerRow.height = 30;
			if (this.arInvoiceDetails.address) {
				worksheet.mergeCells(2, 4, 2, 8);
			}
			headerRow.getCell(1).value = 'Credit Memo ID:';
			headerRow.getCell(1).font = { 'bold': true };
			let orderIdValue = this.arInvoiceDetails.docnum;
			headerRow.getCell(2).value = orderIdValue;
			headerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.arInvoiceDetails.address) {
				headerRow.getCell(3).value = 'Billing Address';
				headerRow.getCell(3).font = { 'bold': true };
				headerRow.getCell(4).value = this.arInvoiceDetails.address || '';
			}

			// customer
			rowNumber = rowNumber + 1;
			const customerRow = worksheet.getRow(rowNumber);
			if (this.arInvoiceDetails.address2) {
				worksheet.mergeCells(3, 4, 3, 8);
			}
			customerRow.height = 30;
			customerRow.getCell(1).value = 'Customer';
			customerRow.getCell(1).font = { 'bold': true };
			let cardCode = '';
			if (!this.isQBErpUser) {
				cardCode = this.arInvoiceDetails.cardcode || '';
			}
			customerRow.getCell(2).value = (cardCode ? cardCode + ' - ' : '') + this.arInvoiceDetails.cardname;
			customerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.arInvoiceDetails.address2) {
				customerRow.getCell(3).value = 'Shipping Address';
				customerRow.getCell(3).font = { 'bold': true };
				customerRow.getCell(4).value = this.arInvoiceDetails.address2 || '';
			}

			// DocDate
			rowNumber = rowNumber + 1;
			const docDateRow = worksheet.getRow(rowNumber);
			docDateRow.height = 30;
			docDateRow.getCell(1).value = 'Invoice Date';
			docDateRow.getCell(1).font = { 'bold': true };
			docDateRow.getCell(2).value = this.arInvoiceDetails.docdate;
			docDateRow.getCell(2).alignment = { horizontal: 'left' };

			// DocdueDate
			rowNumber = rowNumber + 1;
			const docdueDateRow = worksheet.getRow(rowNumber);
			docdueDateRow.height = 30;
			docdueDateRow.getCell(1).value = 'Invoice Due Date';
			docdueDateRow.getCell(1).font = { 'bold': true };
			docdueDateRow.getCell(2).value = this.arInvoiceDetails.docduedate;
			docdueDateRow.getCell(2).alignment = { horizontal: 'left' };

			if (this.isEnableAmortisePaymentsFeature && !this.isItemListLevel) {
				if (this.listOfAmortisePaymentsFieldValue.length > 0) {
					rowNumber = rowNumber + 1;
					const billingStartDateRow = worksheet.getRow(rowNumber);
					billingStartDateRow.height = 30;
					rowNumber = rowNumber + 1;
					const billingEndDateRow = worksheet.getRow(rowNumber);
					this.listOfAmortisePaymentsFieldValue.forEach(field => {
						// Billing Start Date
						billingStartDateRow.getCell(1).value = field.fieldLabel;
						billingStartDateRow.getCell(1).font = { 'bold': true };
						billingStartDateRow.getCell(2).value = field.fieldValue;
						billingStartDateRow.getCell(2).alignment = { horizontal: 'left' };
						// Billing End Date
						billingEndDateRow.height = 30;
						billingEndDateRow.getCell(1).value = field.fieldLabel;
						billingEndDateRow.getCell(1).font = { 'bold': true };
						billingEndDateRow.getCell(2).value = field.fieldValue;
						billingEndDateRow.getCell(2).alignment = { horizontal: 'left' };
					});
				}
			}
			let numberOfRows = this.dataGridContainer.instance.getVisibleRows().length;

			// Sub Total
			let rowIncrement = rowNumber + 5;
			let subTotal = 0;
			const subTotalRow = worksheet.getRow(numberOfRows + rowIncrement);
			subTotalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				subTotalRow.getCell(5).value = 'Sub Total';
				subTotalRow.getCell(5).font = { 'bold': true };
				subTotalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.arInvoiceDetails.doctotalsys);
				subTotalRow.getCell(6).alignment = { horizontal: 'right' };
			} else {
				subTotalRow.getCell(4).value = 'Sub Total';
				subTotalRow.getCell(4).font = { 'bold': true };
				subTotalRow.getCell(5).value = this._CustomCurrencyPipe.transform(this.arInvoiceDetails.doctotalsys);
				subTotalRow.getCell(5).alignment = { horizontal: 'right' };
			}

			if (this.arInvoiceDetails.totaldiscount > 0) {
				// Discount
				let discount = 0;
				rowIncrement = rowIncrement + 1;
				const discountRow = worksheet.getRow(numberOfRows + rowIncrement);
				discountRow.height = 30;
				discountRow.getCell(5).value = 'Discount';
				discountRow.getCell(5).font = { 'bold': true };
				discountRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.arInvoiceDetails.totaldiscount);
				discountRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.arInvoiceDetails.totalmiscamount > 0) {
				// Miscellaneous Charge
				let totalmiscamount = 0;
				rowIncrement = rowIncrement + 1;
				const totalmiscamountRow = worksheet.getRow(numberOfRows + rowIncrement);
				totalmiscamountRow.height = 30;
				totalmiscamountRow.getCell(5).value = 'Miscellaneous Charge';
				totalmiscamountRow.getCell(5).font = { 'bold': true };
				totalmiscamountRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.arInvoiceDetails.totalmiscamount);
				totalmiscamountRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.arInvoiceDetails.shippingcharges > 0) {
				// Frieght Charge
				let shippingcharges = 0;
				rowIncrement = rowIncrement + 1;
				const shippingchargesRow = worksheet.getRow(numberOfRows + rowIncrement);
				shippingchargesRow.height = 30;
				shippingchargesRow.getCell(5).value = 'Frieght Charge';
				shippingchargesRow.getCell(5).font = { 'bold': true };
				shippingchargesRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.arInvoiceDetails.shippingcharges);
				shippingchargesRow.getCell(6).alignment = { horizontal: 'right' };
			}

			// Total
			let total = 0;
			rowIncrement = rowIncrement + 1;
			const totalRow = worksheet.getRow(numberOfRows + rowIncrement);
			totalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				totalRow.getCell(5).value = 'Total';
				totalRow.getCell(5).font = { 'bold': true };
				totalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.arInvoiceDetails.doctotal);
				totalRow.getCell(6).alignment = { horizontal: 'right' };
			} else {
				totalRow.getCell(4).value = 'Total';
				totalRow.getCell(4).font = { 'bold': true };
				totalRow.getCell(5).value = this._CustomCurrencyPipe.transform(this.arInvoiceDetails.doctotal);
				totalRow.getCell(5).alignment = { horizontal: 'right' };
			}

			// Remark
			const remarkRow = worksheet.getRow(numberOfRows + 12);
			totalRow.height = 30;
			remarkRow.getCell(1).value = 'Remark';
			remarkRow.getCell(1).font = { 'bold': true };
			remarkRow.getCell(2).value = this.arInvoiceDetails.journalmemo || '';
			remarkRow.getCell(2).alignment = { horizontal: 'right' };

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}


	public doShowOriginalInvoiceDetails() {
		const sourceDocNum = this.arInvoiceDetails.source_docnum;
		this._SalesDataSharingService.setOriginSalesInvoiceId(this.arInvoiceDetails);
		this.isShowOriginalInvoiceDetails = true;
	}

	public doHideInvoiceDetailsOriginalPopup(e) {
		this.isShowOriginalInvoiceDetails = false;
		this._SalesDataSharingService.setOriginSalesInvoiceId(undefined);
	}

	private doHideShowVirtualInvoiceTag() {
		let enable_virtual_invoices: boolean = false;
		try {
			enable_virtual_invoices = this._LoginService.loginUser.account_detail.enable_virtual_invoices;
		} catch (e) {
			enable_virtual_invoices = false;
		}
		this.isShowVirtualTag = enable_virtual_invoices;
	}
	//#endregion

	// #region for Show/Hide Amortise Payment field
	private doCheckEnableAmortisePayments() {
		this.isEnableAmortisePaymentsFeature = false;
		try {
			this.isEnableAmortisePaymentsFeature = this._LoginService.loginUser.account_detail.enable_amortise_payments;
		} catch (e) {
			this.isEnableAmortisePaymentsFeature = false;
		}
		if (this.isEnableAmortisePaymentsFeature) {
			this.doGetFetureCustomFields();
		}
	}

	private setAmortisePaymentsFeature() {
		this.isItemListLevel = this.listOfFeatureCustomFields.filter(custField => custField.section === 'ITEMLIST').length > 0 ? true : false;
		this.listOfAmortisePaymentsFieldValue = [];
		const documentLines = this.arInvoiceDetails.documentlines || [];
		this.listOfFeatureCustomFields.forEach(custField => {
			if (custField.custom_field_type === 'date' && custField.entity_type === 'arcreditmemo') {
				if (custField.section === 'ITEMLIST') {
					for (let i = 0; i < documentLines.length; i++) {
						if (documentLines[i].hasOwnProperty(custField.ix_custom_field)) {
							let custom_label = custField.ix_custom_field_title;
							if (custField.custom_field_title) {
								custom_label = custField.custom_field_title;
							}
							this.dataGridContainer.instance.columnOption(custField.ix_custom_field, 'caption', custom_label);
						}
					};
				} else {
					if (this.arInvoiceDetails.hasOwnProperty(custField.ix_custom_field)) {
						let custom_label = custField.ix_custom_field_title;
						if (custField.custom_field_title) {
							custom_label = custField.custom_field_title;
						}
						const amortisePaymentsFieldValueObj = {
							fieldLabel: custom_label,
							fieldValue: this.arInvoiceDetails[custField.ix_custom_field]
						}
						this.listOfAmortisePaymentsFieldValue.push(amortisePaymentsFieldValueObj);
					}
				}
			}
		});
	}
	// #endregion

	// #region for Get Lit of Feature Custom Fields
	public doGetFetureCustomFields() {
		let feature_guid = '';
		if (this.isEnableAmortisePaymentsFeature) {
			feature_guid = '7adc7b7ebcdd4dfea92464d6175b8ba0';
		}
		/*
		if (this.isEnableProfitOnDocHeaderFeature) {
			feature_guid = 'ffc6f6d2127d41a7b4a3e358c8389912';
		}
		if (this._LoginService.loginUser.account_detail['enable_invoice_custom_fields_feature']) {
			feature_guid = 'bc541207c8644ec3b14fe1ade63460b9';
		}
		*/
		if (!feature_guid) {
			return false;
		}
		this.listOfFeatureCustomFields = [];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		formData.append('search', JSON.stringify([{ feature_guid: feature_guid, entity_type: 'arcreditmemo' }]));
		if (this.getFetureCustomFieldsSbsn) {
			this.getFetureCustomFieldsSbsn.unsubscribe();
		}
		this.getFetureCustomFieldsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfFeatureCustomFields = response.data || [];
						if (this.isEnableAmortisePaymentsFeature) {
							setTimeout(() => {
								this.setAmortisePaymentsFeature();
							}, 250);
						}
					} else {
						// this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				// this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	// #endregion

	private doCheckUseCustomersFrom() {
		let use_customers_from: string;
		try {
			use_customers_from = this._LoginService.loginUser.account_detail.use_customers_from;
		} catch (e) {
			use_customers_from = '';
		}
		this.isShowCardcodeColumn = false;
		if (use_customers_from === 'itemlist') {
			this.isShowCardcodeColumn = true;
			// It is displays Customer Code column in document line items.
		}
	}

	public doCloseChargeDocumentPopup(e) {
		this.isShowChargeDocument = false;
	}

	public doClickResendCreditMemo() {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
			showTitle: false,
			subtitle: 'Resend Credit Memo',
			message: 'Are you sure you want to re-send this credit memo to the ERP? This may create a duplicate transaction in the ERP.',
		};
		this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				if (this.arInvoiceDetails && this.arInvoiceDetails.hasOwnProperty('guid')) {
					this.doCallReSendCreditMemo();
				} else {
					this._ToastrService.info('Credit Memo Not Found.', 'Info', { closeButton: true, tapToDismiss: true });
				}
			} else {

			}
		});
	}

	public doCallReSendCreditMemo() {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.RESEND_AR_CREDIT_MEMO_TO_ERP);
		reqFormData.append('guid', this.arInvoiceDetails.guid);

		if (this.reSendCreditMemoSbsn) {
			this.reSendCreditMemoSbsn.unsubscribe();
		}
		this.reSendCreditMemoSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				if (response.flag) {
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
					this.preInIt();
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private doFetchListOfDistributionChannels(distributionChannelSearch?: any[]) {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		reqFormData.append('is_dropdown', 'true');
		reqFormData.append('view_fields', JSON.stringify(['guid', 'code', 'description']));
		if (distributionChannelSearch && distributionChannelSearch.length > 0) {
			reqFormData.append('filter', JSON.stringify(distributionChannelSearch));
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}

		this.getListOfDistributionChannelsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: response => {
				this.listOfDistributionChannels = [];
				if (response && response.flag) {
					this.listOfDistributionChannels = response.data || [];
				}
			}, error: error => {
			}
		});
	}
}
