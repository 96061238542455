import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from 'xlsx';
import { PageOptions } from '@app/models/common.model';
import { LoginService } from '@app/services/login.service';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from "@environments/environment";
import { BillbackService } from "@app/services/contract-service/billback/billback.service";
@Component({
	selector: 'app-bc-upload-items-and-ignored-items-dialog',
	templateUrl: './bc-upload-items-and-ignored-items-dialog.component.html',
	styleUrls: ['./bc-upload-items-and-ignored-items-dialog.component.css']
})
export class BcUploadItemsAndIgnoredItemsDialogComponent implements OnInit {
	public isVisiblePopup: boolean = false;
	public actionFor: string = 'showUploadFile';
	public popUpHeaderText: string = 'Upload Items';
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@Output() viewUploadDialogDataEvent = new EventEmitter<string>();
	public uploadFiles: NgxFileDropEntry[] = [];
	public uploadedCsvFilesData: any;
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	private erp_type: string;
	public productSearchItemType = '';
	private headerByItemOrPrice: string[] = [];
	public dxDataSource: any;
	public pageOptions: PageOptions = new PageOptions();
	public currentTab: string;
	public isShowDatagrid: boolean;
	public isItemExists: boolean;
	public dialogData: any;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public arrSelectedCustomerList: any = [];
	public chkAllCustomer: boolean = false;
	public lengthSelectedCustomers: any;
	public customerGridBoxColumns: any;
	public contractType: string;
	public decimalPointForCurrency: number;
	constructor(
		public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _BillbackService: BillbackService,
		private _ConfirmationDialogService: ConfirmationDialogService
	) { }

	ngOnInit() {
		this.preInit();
	}

	preInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.setErpType();
		this.setItemSearchByType();
		this.setDatagrid();
		this.customerGridBoxColumns = this._BillbackService.getCustomerGridBoxColumn();
		this.loadCustomerDataList();
	}

	public doOpenPopup(dialogData) {
		this.dialogData = dialogData;
		this.actionFor = dialogData.actionFor;
		this.isItemExists = dialogData.isItemExists;
		this.popUpHeaderText = dialogData.actionFor === 'showUploadFile' ? 'Upload Items' : 'Ignored Item(s) List';
		this.preInit();
		this.isVisiblePopup = true;
		this.refreshFormData();
	}
	public refreshFormData() {
		this.uploadedCsvFilesData = undefined;
		this.customerDataGrid.instance.refresh();
		this.arrSelectedCustomerList = [];
		this.customerGridBoxValue = [];
		this.chkAllCustomer = false;
	}

	public doHiddenPopop(dialogData: any) {
		this.viewUploadDialogDataEvent.emit(dialogData);
		this.isVisiblePopup = false;
	}

	// Method used to get Erp Type
	private setErpType() {
		// get current user Erp type
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}

		this.isQBErpUser = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}

		this.isQBOnlineErpUser = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
	}

	// Method used to set which billback mode configuration and set headers for csv or excel.
	private setItemSearchByType() {
		// product_search logic type (item/price)
		let productSearchList = [];
		try {
			productSearchList = this._LoginService.loginUser.account_detail.app_settings.product_search || [];
		} catch (e) {
			productSearchList = [];
		}
		const productSearchIndex = productSearchList.findIndex(item => item.key === 'billbacks');
		this.productSearchItemType = (productSearchIndex !== -1) ? productSearchList[productSearchIndex].type : '';
		this.headerByItemOrPrice = ['item', 'uom', 'billback'];
		// if (this.productSearchItemType === 'item') {
		// 	this.headerByItemOrPrice = ['item', 'uom', 'billback'];
		// } else if (this.productSearchItemType === 'price') {
		// 	this.headerByItemOrPrice = ['item', 'uom', 'billback', 'price'];
		// }

	}

	// Method used to set default selected tab at first time 
	private setDatagrid() {
		if (this.actionFor === 'showIgnoredItemList') {
			this.currentTab = 'ignored_item';
			this.getIgnoredItemLst();
			this.isShowDatagrid = true;
		}
	}

	// Method used to get Ignored Item list
	private getIgnoredItemLst() {
		this.dxDataSource = this.dialogData.listOfUnmatchedItems.filter(itemEle => itemEle.isItemMatched === false);
	}

	// Method used to get Incorrect Uom of Item list
	private getInCorrectUomItemsLst() {
		this.dxDataSource = this.dialogData.listOfUnmatchedItems.filter(itemEle => itemEle.isItemMatched === true && itemEle.isItemUomMatched === 'unmatched');
	}

	// Method used to get Incorrect Price of Item list
	private getIgnoredPriceBillbackItemsLst() {
		this.dxDataSource = this.dialogData.listOfUnmatchedItems.filter(itemEle => itemEle.isItemMatched === true && itemEle.isItemModifiedPrice === true);
	}

	// Method used to set active flag to selected tab
	public doChangeTab(newTab: string) {
		if (this.currentTab === newTab) {
			return;
		}
		this.currentTab = newTab;
		this.isShowDatagrid = false;
		switch (newTab) {
			case 'ignored_item':
				this.getIgnoredItemLst();
				setTimeout(() => {
					this.isShowDatagrid = true;
				}, 50);
				break;
			case 'incorrect_uoms':
				this.getInCorrectUomItemsLst();
				setTimeout(() => {
					this.isShowDatagrid = true;
				}, 50);
				break;
			case 'ignored_price_billback':
				this.getIgnoredPriceBillbackItemsLst();
				setTimeout(() => {
					this.isShowDatagrid = true;
				}, 50);
				break;
			default:
				break;
		}
	}

	// Method used to upload functionality for Csv,xlsx files
	public doOnFileDrop(event) {
		this.uploadFiles = event;
		for (const droppedFile of event) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					const fileExtension = file.name.split('.')[file.name.split('.').length - 1];
					if (fileExtension === 'csv' || fileExtension === 'xlsx' || fileExtension === 'xls') {
						// Allow 25MB file
						if (file.size > 25000000) {
							this._ToastrService.info('Please upload less than 25 MB size CSV file', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						this.uploadedCsvFilesData = file;
					} else {
						this._ToastrService.info('Please upload CSV file', 'Info', { closeButton: true, tapToDismiss: true });
					}
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
			}
		}
	}

	// Method used to Remove file from list of files
	public doRemoveUploadFiles() {
		this.uploadedCsvFilesData = undefined;
	}

	// Method used to get Json from file and send it to datagrid
	public doUploadCsvFile() {
		let selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData() || [];
		if (!this.uploadedCsvFilesData || (this.uploadedCsvFilesData.name && !this.uploadedCsvFilesData.name)) {
			this._ToastrService.info('Choose Csv or Excel File', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (selectedCustomerData.length === 0 && !this.chkAllCustomer) {
			this._ToastrService.info('Select Customer !', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}

		this.getReadCsvFile().then(response => {
			const listOfRows = response;
			if (this.checkFileNotEmpty(listOfRows)) {
				return false;
			}
			if (this.isItemExists) {
				this._ConfirmationDialogService.setDialogConfig({
					popupTitle: "Replace Existing Item(s)?",
					popupMessage: 'Are you sure you want to continue? Your existing item(s) list will be replaced',
					popupOkBtnText: "Yes",
					popupCancelBtnText: "Cancel",
					popupInfoBoxClass: "confirmationIcon",
					popupIconClass: 'ItemsIgnore'
				});
				this._ConfirmationDialogService.confirmDialog().afterClosed().subscribe(result => {
					if (result) {
						this.dialogData.chkAllCustomer = this.chkAllCustomer;
						this.dialogData.listOfCustomerData = selectedCustomerData;
						this.dialogData.listOfCsvRows = listOfRows;
						this.dialogData.fileName = this.uploadedCsvFilesData.name;
						this.doHiddenPopop(this.dialogData);
					}
				});
			} else {
				this.dialogData.chkAllCustomer = this.chkAllCustomer;
				this.dialogData.listOfCustomerData = selectedCustomerData;
				this.dialogData.listOfCsvRows = listOfRows;
				this.dialogData.fileName = this.uploadedCsvFilesData.name;
				this.doHiddenPopop(this.dialogData);
			}
		}).catch(error => {
		});
	}

	// Method used to Read Csv or Xlsx file
	async getReadCsvFile(): Promise<any> {
		return new Promise<string>((resolve, reject) => {
			let csvRowsToJson: any = [];
			if (this.uploadedCsvFilesData && this.uploadedCsvFilesData.name) {
				const file = this.uploadedCsvFilesData;
				const fileReader = new FileReader();
				fileReader.onload = (e) => {
					const arrayBuffer: any = fileReader.result;
					const data = new Uint8Array(arrayBuffer);
					const arr = [];
					for (let i = 0; i !== data.length; ++i) {
						arr[i] = String.fromCharCode(data[i]);
					}
					const bstr = arr.join("");
					const workbook = XLSX.read(bstr, { type: "binary" });
					const firstSheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[firstSheetName];
					let conHeaderByItemOrPrice = this.headerByItemOrPrice;
					const headers = this.getHeaderRow(worksheet);
					if (headers && headers.length === 3) {
						conHeaderByItemOrPrice = headers;
					}
					csvRowsToJson = XLSX.utils.sheet_to_json(worksheet, { header: conHeaderByItemOrPrice, raw: true });
					if (this.checkValidColums(headers)) {
						reject(csvRowsToJson);
						return false;
					} else {
						csvRowsToJson.splice(0, 1);

						// if (this.productSearchItemType === 'price' && (headers.findIndex(h => h === 'price') !== -1 || headers.findIndex(h => h === 'billback') !== -1)) {
						// 	if (headers.length > 3) {
						// 		const headerName = headers[headers.length - 1];
						// 		csvRowsToJson.forEach(csvRow => {
						// 			if (headerName === 'price') {
						// 				csvRow.billback = csvRow.price;
						// 				delete csvRow.price;
						// 			}
						// 			if (headerName === 'billback') {
						// 				delete csvRow.billback;
						// 			}
						// 		});
						// 	}
						// }

						resolve(csvRowsToJson);
					}
				};
				fileReader.readAsArrayBuffer(file);
			} else {
				reject(csvRowsToJson);
			}
		});
	}

	// Method used to get header Csv or Xlsx file
	private getHeaderRow(sheet) {
		const headers = [];
		const range = XLSX.utils.decode_range(sheet['!ref']);
		let C, R = range.s.r; /* start in the first row */
		/* walk every column in the range */
		for (C = range.s.c; C <= range.e.c; ++C) {
			const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
			// console.log("cell",cell)
			let hdr = "UNKNOWN " + C; // <-- replace with your desired default
			if (cell && cell.t) {
				hdr = XLSX.utils.format_cell(cell).trim().toLowerCase();
				headers.push(hdr);
			}
		}
		return headers;
	}

	// Method used to check file is not empty Csv or Xlsx file
	private checkFileNotEmpty(listOfRows): boolean {
		if (listOfRows && listOfRows.length <= 0) {
			this._ToastrService.info('No records available in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		return false;
	}

	// Method used to check columns for rebtae_type Csv or Xlsx file
	private checkValidColums(listOfHeaders: any[]): boolean {
		const headers = listOfHeaders;

		if (headers && headers.length <= 0) {
			this._ToastrService.info('No Header availabled in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}

		if (headers.findIndex(h => h === 'item') === -1) {
			this._ToastrService.info('item column is not in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (headers.findIndex(h => h === 'uom') === -1) {
			this._ToastrService.info('uom column is not in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}

		let isCheckColumnSequence = true;
		let message = 'Column titles should be item and uom and billback in ';
		// if (this.productSearchItemType === 'price') {
		// 	isCheckColumnSequence = false;
		// 	message = 'Column titles should be item and uom and billback | price in';
		// }

		for (let i = 0; i < this.headerByItemOrPrice.length; i++) {
			if (isCheckColumnSequence) {
				const headerIndex = headers.findIndex(csvHeader => csvHeader === this.headerByItemOrPrice[i]);
				if (headerIndex !== i) {
					this._ToastrService.info(this.headerByItemOrPrice[i] + message + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
					return true;
				}
			} else if (!isCheckColumnSequence) {
				const headerIndex = headers.findIndex(csvHeader => csvHeader === this.headerByItemOrPrice[i]);
				if (headerIndex !== i) {
					if (headers.findIndex(h => h === 'billback') === 2) {
						return false;
					} else {
						this._ToastrService.info(this.headerByItemOrPrice[i] + ' sequence should be not proper in ' + this.uploadedCsvFilesData.name + ' File', 'Info', { closeButton: true, tapToDismiss: true });
						return true;
					}
				}

			}
		}

		return false;
	}

	//#region for Customer select box
	loadCustomerDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		let formdata = environment.URL_DATAOUT + "/dataout.php";
		formdata = formdata + "?usr=" + this._LoginService.loginUser.user;
		formdata = formdata + "&token=" + this._LoginService.loginUser.token;
		formdata = formdata + "&method=dxGetEntityData";
		formdata = formdata + "&entity=clients";
		// formdata = formdata + "&is_dropdown=true";	
		// formdata = formdata + "&view_fields=" + JSON.stringify(viewFields)
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "name",
			loadUrl: formdata
		});
	}
	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.customerGridBoxValue = [];
			this.customerDataGrid.instance.refresh();
			this.chkAllCustomer = false;
		}
	}
	checkAllCustomer() {
		// if (this.arrSelectedCustomerList && this.arrSelectedCustomerList.length > 0) {
		// 	let indexCustomer = this.arrSelectedCustomerList.findIndex(item => item === 'All Customer');
		// 	if (this.chkAllCustomer && indexCustomer === -1) {
		// 		this.arrSelectedCustomerList.unshift('All Customer');
		// 	} else {
		// 		this.arrSelectedCustomerList.slice(indexCustomer, 0);
		// 	}
		// } else {
		// 	if (this.chkAllCustomer) {
		// 		this.arrSelectedCustomerList.unshift('All Customer');
		// 	}
		// }
	}
	onCustomerSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedCustomers = e.selectedRowsData.length + ' Customers Selected';
			this.customerGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomer) {
			this.lengthSelectedCustomers = '';
		}
	}

	//#endregion

}
