import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { DataService } from '@app/services/data.service';
import { LoginService } from '@app/services/login.service';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import { CalledFromEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';
@Component({
	selector: 'app-subscription-upgrade-dialog',
	templateUrl: './subscription-upgrade-dialog.component.html',
	styleUrls: ['./subscription-upgrade-dialog.component.css']
})
export class SubscriptionUpgradeDialogComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() subscriptionUpgradeProps: any;
	@Output() closeSubscriptionUpgradePopup = new EventEmitter<any>();
	@ViewChild('purchaseOrUpgradeBtnRef', { static: false }) purchaseOrUpgradeBtnRef: DxButtonComponent;
	@ViewChild('dxPopupRef', { static: false }) dxPopupRef: DxPopupComponent;
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean;
	public popupTitleText: string;
	public contentTemplateVal: string;

	subscription: any;
	is_trial: any;
	trial_remaining_days: number = 0;
	public called_from: string;
	constructor(private _LoginService: LoginService,
		public _UserProfileService: UserProfileService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _DataService: DataService) {
	}

	ngOnInit() {
		this.isShowPopup = true;
		this.trial_remaining_days = this._LoginService.loginUser.account_detail.trial_remaining_days;
		this.called_from = this._UserProfileService.getCalledFrom();
		if (this.trial_remaining_days < 0) {
			this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_TIRAL_EXPIRED);
		} else {
			this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_TIRAL);
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.dxPopupRef.instance.focus();
			this.dxPopupRef.instance.registerKeyHandler('escape', (e) => {
				e.stopPropagation();
			});
			this.dxPopupRef.instance.registerKeyHandler('enter', (e) => {
				this.upgradeSubscription();
			});
			this.purchaseOrUpgradeBtnRef.instance.registerKeyHandler('enter', (e) => {
				this.upgradeSubscription();
			});
		});
	}


	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['subscriptionUpgradeProps']) {
			const subscriptionUpgradeProps = _SimpleChanges['subscriptionUpgradeProps'].currentValue;
			this.subscription = subscriptionUpgradeProps.subscription || '';
			this.is_trial = subscriptionUpgradeProps.is_trial || '';
			if (this.is_trial) {
				this.contentTemplateVal = 'trialContent';
			} else {
				this.contentTemplateVal = 'nonTrialContent';
			}
		}
	}

	public upgradeSubscription() {
		// this.dialogRef.close();
		this.closeSubscriptionUpgradePopup.emit({
			isClickOnCloseBtn: false,
			isAllowed: true,
			isCancel: false,
			redirectTo: 'TRIAL_TO_PURCHASE',
		});
		this.isShowPopup = false;
		/* // this._Router.navigate(['/subscriptions/upgrade-subscriptions']); */
		// this._Router.navigate(['/subscriptions/trial-to-purchase-subscriptions']);
	}

	public purchaseLater() {
		this.closeSubscriptionUpgradePopup.emit({
			isClickOnCloseBtn: false,
			isAllowed: true,
			isCancel: false,
		});
		this.isShowPopup = false;
		// this.dialogRef.close(true);
		/* //this._Router.navigate(['/dashboard']);*/
	}

	public continueTrial() {
		this.closeSubscriptionUpgradePopup.emit({
			isClickOnCloseBtn: false,
			isAllowed: true,
			isCancel: false,
		});
		this.isShowPopup = false;
	}

	public doHiddenPopup(event?: any) {
		this.closeSubscriptionUpgradePopup.emit({
			isClickOnCloseBtn: true
		});
		this.isShowPopup = false;
	}

	public doCancel() {
		this.closeSubscriptionUpgradePopup.emit({
			isClickOnCloseBtn: false,
			isAllowed: true,
			isCancel: true
		});
		this.isShowPopup = false;
	}
}
