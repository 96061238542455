import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { CUSTOM_DATE_FORMATS, MSG_ERROR_MESSAGE, RGX_URL_VALID, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { DistributionChannelsCallFromViewEnum, ErpTypeValEnum } from "@app/constants-enums/enums";
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { DataService } from '@app/services/data.service';
import { RoyaltiesService } from "@app/services/contract-service/royalties/royalties.service";
import { Guid } from '@app/models/guid';
import { RoyaltiesContractModelUI, RoyaltiesContractModelRequest, CustomerTabModel, ProductAndGroupTabModel, ExclusionTabModel, RuleTabModel } from "@app/models/royalties-contract.model";
import { DxDataGridComponent } from 'devextreme-angular';
import { DecimalPipe } from '@angular/common';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { DistributionChannelsDxDropDownBoxModel } from '@app/models/input-Components.model';

@Component({
	selector: 'app-royalties-contract',
	templateUrl: './royalties-contract.component.html',
	styleUrls: ['./royalties-contract.component.css'],
})
export class RoyaltiesContractComponent implements OnInit {

	//#region Local Variable

	// Comman public object
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public isAddMode: boolean = true;
	public editRoyaltiesGuid: string;
	public currentAgreementTab: string;
	public searchTimeout: any;
	public productGroupRadioButton: string;
	public exclusionsGroupRadioButton: string;
	public btnRulesAdd: string = 'Add';
	public rulesAddOrEditIndex: string = '-1';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public erp_type: string;
	public ErpTypeValEnum: ErpTypeValEnum;
	public currentMainTab: string;
	public percentOrDollarRadio: any[] = [];
	public SettlementPeriodRadio: any[] = [];

	// Drop down list object
	public salesPplList: any = [];
	public GroupsList: any = [];
	public selectCustomerList: any = [];
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public closecontractsArray: any = [];
	public selectVendorList: any = [];

	// Agreement tab table list object
	public customerTabList: any = [];
	public productOrGroupTabList: any = [];
	public exclusionTabList: any = [];
	public ruleTabList: any[] = [];

	@ViewChild("vendorGrid", {}) vendorGrid: DxDataGridComponent;
	public vendorDataSource: any;
	public vendorGridBoxValue: any;
	public vendorGridBoxColumns: any;
	public isVendorDropDownBoxOpened: boolean = false;

	// Agreement tab drop down list select all checkbox object
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;
	public isEndUsersSearchTabAllSelected: boolean = false;
	public isAllCustomerSelectedView: boolean = false;
	public isAllProductSelectedView: boolean = false;
	public isAllEndUsersSelectedView: boolean = false;
	public isViewIERuleRuleRate: boolean = true;
	public isViewBounsAmountCriterion: boolean = true;
	public isViewRateRuleCriterion: boolean = true;
	public isViewBonusRateCriterion: boolean = true;

	// Api call request object
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;

	// Model object
	public guid = new Guid();
	public royaltiesContractModelUI = new RoyaltiesContractModelUI();
	public royaltiesContractModelRequest = new RoyaltiesContractModelRequest();

	public customerTabModel = new CustomerTabModel();
	public productAndGroupTabModel = new ProductAndGroupTabModel();
	public exclusionTabModel = new ExclusionTabModel();
	public ruleTabModel = new RuleTabModel();
	public listOfContractCalendar: any[] = [];

	public isViewPreviousSettlementPeriodPopup: boolean;
	public viewPreviousSettlementPeriodProps: any;
	public isEnableAmortisePaymentsFeature: boolean;
	public isDisabledSettlementPeriods: boolean = true;
	private countTotalSettlementReports: number;
	public amortized_payments: any[] = [];
	public decimalPointPercentageFormat: string;

	public distributionChannelsModel: DistributionChannelsDxDropDownBoxModel = new DistributionChannelsDxDropDownBoxModel();
	public isEnableDistributionChannels: boolean;
	//#endregion

	//#region Constructor
	constructor(private _Router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private _ToastrService: ToastrService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _DataService: DataService,
		private _DecimalPipe: DecimalPipe,
		private _RoyaltiesService: RoyaltiesService,
		private _IxDxAlertService: IxDxAlertService,
		private _ChangeDetectorRef?: ChangeDetectorRef) { }

	//#endregion

	//#region Angular Life Cycle Methods
	ngOnInit() {
		this.editRoyaltiesGuid = this._RoyaltiesService.getEditRoyaltiesGuid();
		this.doInitForRoyaltiesDetailsTab();
	}

	doInitForRoyaltiesDetailsTab() {
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		}

		if (this._LoginService.loginUser.account_detail['enable_amortise_payments']) {
			this.isEnableAmortisePaymentsFeature = this._LoginService.loginUser.account_detail.enable_amortise_payments;
		}
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		// Check Edit Mode
		const editRoyaltiesGuid = this._RoyaltiesService.getEditRoyaltiesGuid();
		this.vendorGridBoxColumns = [{ dataField: 'name', caption: 'Vendor' }];
		this.vendorDataSource = this._DataService.getVendors();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per)
		}

		if (permsn.indexOf('INCENTIVE_ROYALTIES_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_ROYALTIES_MODIFY';
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_distribution_channels')) {
			this.isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels || false;
		}
		if (this.isEnableDistributionChannels) {
			this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
			this.distributionChannelsModel.callFromView = DistributionChannelsCallFromViewEnum.ADD_EDIT_ROYALTY;
			this.distributionChannelsModel.selectionMode = 'single';
			this.distributionChannelsModel.showSelectionControls = false;
			this.distributionChannelsModel.applyValueMode = 'instantly';
		}

		this.salesPplList = this._DataService.getSalesPpl();
		this.GroupsList = this._DataService.getGroups();
		this.percentOrDollarRadio = this._RoyaltiesService.percentOrDollarRadio;
		this.SettlementPeriodRadio = this._RoyaltiesService.SettlementPeriodRadio;

		if (editRoyaltiesGuid) {
			this.royaltiesContractModelRequest.guid = editRoyaltiesGuid;
			this.editRoyaltiesGuid = editRoyaltiesGuid;
			this.editRoyaltiesContractObject();
		} else {
			this.currentMainTab = 'royalties_details';
			this.addRoyaltiesContractObject();
		}
		this.listOfContractCalendar = this._RoyaltiesService.listOfContractCalendar || [];

		// Disable royalties inputbox
		// this.disableRoyaltiesInputFields();

		// check Is QB User (no need to view code of QB user)

		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}

		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
	}

	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	//#endregion

	//#region royalties Contract Comman Methods

	// click main tab
	clickMainTab(tabName) {
		this.currentMainTab = tabName;
		if (tabName === 'royalties_details') {
			this.doDisabledSettlementPeriods();
		}
	}

	// Show/hide select box
	hostOnClick(event) {
		if ($(event.target).closest('.showIt').length === 0) {
			if ($(event.target).closest('.serchBox').length > 0) {
				return false;
			}
			$('.showIt').removeClass('showIt');
		}
	}

	// Set default value on add mode
	addRoyaltiesContractObject() {
		this.isAddMode = true;
		this.isDisabledSettlementPeriods = false;
		this.editRoyaltiesGuid = '';
		this.royaltiesContractModelRequest.guid = '';
		this.currentAgreementTab = 'exclusions';
		this.royaltiesContractModelUI.percentOrDollar = 'dollar';
		this.productGroupRadioButton = 'addproducts';
		this.royaltiesContractModelUI.settlementPeriod = 'monthly';
	}

	// Set default value on edit mode
	editRoyaltiesContractObject() {
		this.currentAgreementTab = 'exclusions';
		this.productGroupRadioButton = 'addproducts';
		this.editRoyaltiesContract();
	}

	// Method to add/remove visible class
	public searchSlideDown(id: string) {
		if ($('#' + id + '.showIt').is(':visible') === false) {
			$('#' + id + '').addClass('showIt');
		} else {
			$('#' + id + '').removeClass('showIt');
		}
	}

	// Click agreement tab
	clickAgreementTab(currentAgreementTab) {
		switch (currentAgreementTab) {
			case 'customers':
				this.currentAgreementTab = 'customers';
				this.clearCustomerAgreementTabDetails();
				break;
			case 'products':
				this.currentAgreementTab = 'products';
				this.clearProductAgreementTabDetails();
				break;
			case 'exclusions':
				this.currentAgreementTab = 'exclusions';
				this.clearExclusionAgreementTabDetails();
				break;
			case 'rules':
				this.currentAgreementTab = 'rules';
				break;
		}
	}

	// Get agreement tab list data
	getAgreementTabListData() {

	}

	// Set agreement tab list data
	setAgreementTabListData() {

	}

	// Disable input text after save royalties
	disableRoyaltiesInputFields() {
		if (this.editRoyaltiesGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}
	}
	//#endregion

	//#region Settlement StartDate And EndDate Methods

	// Set Settlement StartDate and EndDate
	compareTwoDates() {
		// check start date and end date validate
		if (new Date(this.royaltiesContractModelUI.endDate) < new Date(this.royaltiesContractModelUI.startDate)) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
		}
		this.setSettlementStartDate();
	}

	// Set Settlement Date 
	public setSettlementStartDate() {
		setTimeout(() => {
			if (!this.royaltiesContractModelUI.startSettlementPeriodOn) {
				this.royaltiesContractModelUI.startSettlementPeriodOn = "1";
			}
		}, 50);
		// check start date and end date validate
		if (new Date(this.royaltiesContractModelUI.endDate) < new Date(this.royaltiesContractModelUI.startDate)) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
			return;
		} else {
			if (this.royaltiesContractModelUI.startDate) {
				this.calculateSettlementPeriod(this.royaltiesContractModelUI);
			}
		}
	}

	// Calculate Settlement Period as per start date
	calculateSettlementPeriod(c, isClose?: boolean) {
		this.callViewSettlementPeriod(this.royaltiesContractModelRequest.guid, (response, data) => {
			this.closecontractsArray = data;
			if (c.startDate) {
				c.startDate = moment(c.startDate).format('YYYY-MM-DD');
				c.endDate = moment(c.endDate).format('YYYY-MM-DD');
				c.settlementPeriodStart = moment(c.settlementPeriodStart).format('YYYY-MM-DD');
				c.settlementPeriodEnd = moment(c.settlementPeriodEnd).format('YYYY-MM-DD');
				let e, s, d;
				let ssPeriodDay: any = 1; //start settlement period on
				let cc = this.royaltiesContractModelUI.contractCalendar; // contract calendar [Fiscal Year,Calendar Year,Contract Year]
				let f: any = 1;// Fiscal Year month as of application configuration
				ssPeriodDay = this.royaltiesContractModelUI.startSettlementPeriodOn ? parseInt(this.royaltiesContractModelUI.startSettlementPeriodOn.toString()) : 1;

				cc = this.royaltiesContractModelUI.contractCalendar;
				if (cc === 'fiscal_year') {
					f = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
				}
				if (!this.closecontractsArray.length) {  // no previous closed settlement periods 
					c.settlementPeriodStart = c.startDate;
					s = c.startDate.split('-');
				} else {
					s = moment(this.closecontractsArray[this.closecontractsArray.length - 1].settlementPeriodEnd).format('YYYY-MM-DD').split('-');
					d = new Date(s[0], s[1] - 1, s[2]);
					const dDate = moment(new Date(d)).format('YYYY-MM-DD');
					const cDate = moment(new Date(c.startDate)).format('YYYY-MM-DD');
					/*
					if (new Date(d) < new Date(c.startDate)) {
						c.settlementPeriodStart = c.startDate;
						s = c.settlementPeriodStart.split('-');
					} else {
						d.setDate(d.getDate() + 1);
						c.settlementPeriodStart = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
						s = c.settlementPeriodStart.split('-');
					}
					*/
					if (dDate < cDate) {
						c.settlementPeriodStart = c.startDate;
						s = c.settlementPeriodStart.split('-');
					} else {
						d.setDate(d.getDate() + 1);
						c.settlementPeriodStart = moment(d).format('YYYY-MM-DD');
						s = c.settlementPeriodStart.split('-');
						// check all settlment closed
						const endDate = moment(c.endDate, "YYYY-MM-DD");
						const settlementPeriodStart = moment(c.settlementPeriodStart, "YYYY-MM-DD");
						const diffDays = settlementPeriodStart.diff(endDate, 'days');
						if (diffDays > 0) {
							// this.isAllSettlmentClosed = true;
						} else {
							// this.isAllSettlmentClosed = false;
						}
						// End check all settlment closed
					}
				}

				if (cc === 'contract_year') {
					f = parseInt(s[1].toString()) || 1;
				}
				const sm = parseInt(s[1].toString()); // start date month
				if (c.settlementPeriod === 'monthly') {
					// tslint:disable-next-line: max-line-length
					// e = new Date(s[0], s[1], 1);  // end date is first day of the next month, and JavaScript months start at 0, month 12 interpreted as Jan of next yr
					e = new Date(s[0], s[1], ssPeriodDay);  // end date is first day of the next month, and JavaScript months start at 0, month 12 interpreted as Jan of next yr
				} else if (c.settlementPeriod === 'quarterly') {
					d = sm - ((f - 1) % 3); // adjust for fiscal year start
					if (d < 0) {
						d = 0;
						// d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 3) + (d % 3 ? 3 : 0);   // end of current quarter
					d = d + ((f - 1) % 3);    // re-adjust for fiscal year start
					// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === 'halfYearly') {
					d = sm - ((f - 1) % 6); // adjust for fiscal year start
					if (d < 0) {
						d = 0;
						// d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 6) + (d % 6 ? 6 : 0);   // end of current quarter
					d = d + ((f - 1) % 6);    // re-adjust for fiscal year start
					// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === 'yearly') {
					if (cc === 'fiscal_year') {
						d = sm - ((f - 1) % 12); // adjust for fiscal year start
						if (d < 0) {
							d = 0;
							// d = d + 12;   // months before fiscal year start are as if at end of year
						}
						d = d - (d % 12) + (d % 12 ? 12 : 0);   // end of current quarter
						d = d + ((f - 1) % 12);    // re-adjust for fiscal year start
						// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
						e = new Date(s[0], d, ssPeriodDay);
					} else {
						e = new Date(parseInt(s[0]) + 1, f - 1, ssPeriodDay);
					}
				}
				// e.setDate(e.getDate() - 1); // last day of the prev month
				// c.settlementPeriodEnd = e.getFullYear() + '-' + (e.getMonth() + 1) + '-' + e.getDate();
				try {
					e.setDate(e.getDate() - 1); // last day of the prev month
					const endDate = c.endDate ? moment(c.endDate, "YYYY-MM-DD") : null;
					const settlementPeriodEnd = moment(e, "YYYY-MM-DD");
					const diffDays = settlementPeriodEnd.diff(endDate, 'days');
					if (diffDays >= 0) {
						c.settlementPeriodEnd = c.endDate;
					} else {
						c.settlementPeriodEnd = moment(e).format('YYYY-MM-DD');
					}
				} catch (e) {
				}

				// console.log('settlementPeriodStart : ', c.settlementPeriodStart);
				// console.log('settlementPeriodEnd : ', c.settlementPeriodEnd);

				if (isClose) {
					// c.contractCode = this.guid.newGuid();
					c.startDate = moment(c.startDate).format('YYYY-MM-DD');
					c.endDate = moment(c.endDate).format('YYYY-MM-DD');
					c.settlementPeriodStart = moment(c.settlementPeriodStart).format('YYYY-MM-DD');
					c.settlementPeriodEnd = moment(c.settlementPeriodEnd).format('YYYY-MM-DD');

					const o = new Object;
					// tslint:disable-next-line: forin
					for (const e in c) {
						o[e] = c[e];
					}
					o['clients'] = this.customerTabList;
					o['items'] = this.productOrGroupTabList;
					// o['excludedItems'] = this.exclusionTabList;
					o['customer_items_groups'] = this.exclusionTabList;
					o['rules'] = this.ruleTabList;
					o['amortized_payments'] = this.amortized_payments;
					o['isSettlementPeriodClosed'] = true;
					o['settelmentCalculatedByBackend'] = '';
					o['apInvoiceCreatedInERP'] = '';
					o['errorMessage'] = '';
					o['settlementValue'] = 0;
					o['isAPInvoicePaid'] = '';
					o['apInvoiceNumber'] = '';
					o['startDate'] = moment(o['startDate']).format('YYYY-MM-DD');
					o['endDate'] = moment(o['endDate']).format('YYYY-MM-DD');
					o['settlementPeriodStart'] = moment(o['settlementPeriodStart']).format('YYYY-MM-DD');
					o['settlementPeriodEnd'] = moment(o['settlementPeriodEnd']).format('YYYY-MM-DD');
					o['baseContractCode'] = c.guid;
					o['guid'] = this.guid.newGuid();

					// this.closecontractsArray.push(o);
					const contractArr = [];
					contractArr.push(o);
					this.callSaveContractCloseSettlement(contractArr);
				} else {
					if (this.editRoyaltiesGuid) {
						this.UpdateSettlementPeriodClick();
					}
				}
			}
		});
	}

	//#endregion

	//#region close current settlement period

	closeCurrentSettlementPeriod(royaltiesContractDetails) {
		if (this.editRoyaltiesGuid !== undefined) {
			// const d = new Date();
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Close Settlement',
				message: 'Are you sure you want to close current settlement period? <br/>You will not be able to undo the operation.'
			};
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					const serverDateSplit = this._LoginService.loginUser.server_date.split('-');
					const d = new Date(+serverDateSplit[0], +serverDateSplit[1] - 1, +serverDateSplit[2]);
					if (!royaltiesContractDetails.startDate) {
						this._IxDxAlertService.doShowAlert("Cannot close Settlement Period - please add a Start Date to contract").then(result => {
						});
						return;
					}
					let end = royaltiesContractDetails.settlementPeriodEnd.split('-');
					end = new Date(end[0], end[1] - 1, end[2]);
					if (d <= end) {
						this._IxDxAlertService.doShowAlert("Cannot close Settlement Period until after its end date").then(result => {
						});
						return;
					}
					const isClose = true;
					this.calculateSettlementPeriod(royaltiesContractDetails, isClose);
				}
			});
		}
	}

	//#endregion

	saveRoyaltiesContract() {
		if (!this.royaltiesContractModelUI.contractName) {
			this._ToastrService.info('Contract Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		/*
		if (!this.royaltiesContractModelUI.royaltiesPercent) {
			this._ToastrService.info('Royalties Percent is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		*/
		if (!this.royaltiesContractModelUI.selectVendor) {
			this._ToastrService.info('Vendor is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		// if (!this.royaltiesContractModelUI.employeeCode) {
		// 	this._ToastrService.info('Sales person is Required.', 'Info', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }

		if (this.royaltiesContractModelRequest.guid === undefined || this.royaltiesContractModelRequest.guid === '' || this.royaltiesContractModelRequest.guid === null) {
			this.royaltiesContractModelRequest.guid = this.guid.newGuid();
		}
		// Get employee name as per id
		// if (this.royaltiesContractModelUI.employeeCode) {
		// 	const emp = this.salesPplList.filter(item => (item.code).toString() === (this.royaltiesContractModelUI.employeeCode).toString())[0];
		// 	this.royaltiesContractModelUI.employeeName = emp !== undefined ? emp.firstname || '' : '';
		// }
		this.royaltiesContractModelRequest.percentOrDollar = this.royaltiesContractModelUI.percentOrDollar;
		this.royaltiesContractModelRequest.contractName = this.royaltiesContractModelUI.contractName;
		this.royaltiesContractModelRequest.royaltiesPercent = this.royaltiesContractModelUI.royaltiesPercent;
		// this.royaltiesContractModelRequest.employeeCode = this.royaltiesContractModelUI.employeeCode;
		this.royaltiesContractModelRequest.employeeName = this.royaltiesContractModelUI.employeeName;
		this.royaltiesContractModelRequest.selectVendor = this.royaltiesContractModelUI.selectVendor;
		this.royaltiesContractModelRequest.selectVendorId = this.royaltiesContractModelUI.selectVendorId;
		this.royaltiesContractModelRequest.startDate = moment(this.royaltiesContractModelUI.startDate).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.endDate = moment(this.royaltiesContractModelUI.endDate).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriodStart = moment(this.royaltiesContractModelUI.settlementPeriodStart).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriodEnd = moment(this.royaltiesContractModelUI.settlementPeriodEnd).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriod = this.royaltiesContractModelUI.settlementPeriod;
		this.royaltiesContractModelRequest.startSettlementPeriodOn = this.royaltiesContractModelUI.startSettlementPeriodOn;
		this.royaltiesContractModelRequest.contractCalendar = this.royaltiesContractModelUI.contractCalendar;
		this.royaltiesContractModelRequest.agreementBy = this.royaltiesContractModelUI.agreementBy;
		this.royaltiesContractModelRequest.allproducts = this.isAllProductSelectedView;
		this.royaltiesContractModelRequest.allclients = this.isAllCustomerSelectedView;
		this.royaltiesContractModelRequest.clients = this.customerTabList;
		this.royaltiesContractModelRequest.items = this.productOrGroupTabList;
		// this.royaltiesContractModelRequest.excludedItems = this.exclusionTabList;
		this.royaltiesContractModelRequest.customer_items_groups = this.exclusionTabList;
		this.royaltiesContractModelRequest.rules = this.ruleTabList;
		this.royaltiesContractModelRequest.amortized_payments = this.amortized_payments;
		this.royaltiesContractModelRequest.is_active = true;

		if (this.isEnableDistributionChannels) {
			this.royaltiesContractModelRequest.distribution_channels = this.distributionChannelsModel.arrSelectedDistributionChannelsList && this.distributionChannelsModel.arrSelectedDistributionChannelsList.length > 0 ? this.distributionChannelsModel.arrSelectedDistributionChannelsList[0] : '';
		}

		const arryRoyaltiesContracts = [];
		arryRoyaltiesContracts.push(this.royaltiesContractModelRequest);
		this.callSaveRoyaltiesContract(arryRoyaltiesContracts);
	}

	ViewPreviousSettlementPeriodDialogClick() {
		if (this.editRoyaltiesGuid !== undefined) {
			this.callViewSettlementPeriod(this.editRoyaltiesGuid, (response, data) => {
				const ViewPreviousSettlementPeriodList = data;
				if (ViewPreviousSettlementPeriodList.length !== 0) {
					// tslint:disable-next-line: max-line-length
					this.viewPreviousSettlementPeriodProps = {
						contractName: this.royaltiesContractModelUI.contractName, salesPersonName: this.royaltiesContractModelUI.employeeName, ViewPreviousSettlementPeriodList: ViewPreviousSettlementPeriodList
					};
					this.doOpenViewPreviousSettlementPeriod();
				} else {
					return;
				}
			});
		}
	}

	private doOpenViewPreviousSettlementPeriod() {
		this.isViewPreviousSettlementPeriodPopup = false;
		setTimeout(() => {
			this.isViewPreviousSettlementPeriodPopup = true;
		}, 150);
	}

	public doCloseViewPreviousSettlementPeriod() {
		this.isViewPreviousSettlementPeriodPopup = false;
	}

	// Select rebates vendor search
	doSearchSelectVendorOnChange(event: any) {
		const view_fields = ['code', 'name'];
		let searchStr: any = event.target.value;
		this.selectVendorList = [];
		if (searchStr && searchStr.trim()) {
			searchStr = searchStr.trim();
			// if (searchStr.length <= 3) {
			// 	if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			// 	this.searchTimeout = setTimeout(() => {
			// 		this._ToastrService.info('Enter more characters to search terms.', 'Info', { closeButton: true, tapToDismiss: true });
			// 		this.selectVendorList = [];
			// 	}, 999);
			// 	return;
			// }
			if (this.dataInReqSubscription) {
				this.dataInReqSubscription.unsubscribe();
			}
			if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			this.searchTimeout = setTimeout(() => {
				const searchArr = [];
				if (searchStr) {
					searchArr.push({ 'name_with_code': encodeURIComponent(searchStr) });
				}
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('entity', ServerEntity.VENDORS);
				formData.append('method', ServerMethods.GET_ENTITY_DATA);
				formData.append('view_fields', JSON.stringify(view_fields));
				formData.append('is_dropdown', '1');
				if (searchArr && searchArr.length > 0) {
					formData.append('search', JSON.stringify(searchArr));
				}
				this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: response => {
						if (response.flag) {
							this.selectVendorList = response.data || [];
							if (this.selectVendorList.length > 0) {
								if ($('#tblSelectVendor.showIt').is(':visible') === false) {
									this.searchSlideDown('tblSelectVendor');
								}
							} else {
								this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
								this.selectVendorList = [];
							}
						} else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
							this.selectVendorList = [];
						}
					}, error: error => {

					}
				});
			}, 750);
		}
	}

	// Select rebates customer search data single checkbox
	selectVendorSearchListClick = function (ct, id) {
		this.royaltiesContractModelUI.selectVendorId = ct.code;
		this.royaltiesContractModelUI.selectVendor = ct.name;
		this.searchSlideDown(id);
	};

	public displayExprVendorName(item) {
		if (!item) {
			return "";
		}
		const name = item.name;
		return name.toString();
	}

	public onVendorSelectionChanged(e) {
		if (e.name === "value") {
			if (this.vendorGrid) {
				const selectedVendorArr = this.vendorGrid.instance.getSelectedRowsData();
				if (selectedVendorArr && selectedVendorArr.length > 0) {
					const selectedVendorObj = selectedVendorArr[0];
					if (selectedVendorObj) {
						this.isVendorDropDownBoxOpened = false;
						this.royaltiesContractModelUI.selectVendorId = selectedVendorObj.code;
						this.royaltiesContractModelUI.selectVendor = selectedVendorObj.name;
					}
				}
			}
			this._ChangeDetectorRef.detectChanges();
		}
	}


	//#region Customer Tab

	// Customer search select list
	doSearchSelectCustomerTabOnChange(event: any) {
		const view_fields = ['code', 'name'];
		let searchStr: any = event.target.value;
		this.selectCustomerSearchTabData = [];
		if (searchStr && searchStr.trim()) {
			searchStr = searchStr.trim();
			// if (searchStr.length <= 3) {
			// 	if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			// 	this.searchTimeout = setTimeout(() => {
			// 		this._ToastrService.info('Enter more characters to search terms.', 'Info', { closeButton: true, tapToDismiss: true });
			// 		this.selectCustomerSearchTabData = [];
			// 	}, 999);
			// 	return;
			// }
			if (this.dataInReqSubscription) {
				this.dataInReqSubscription.unsubscribe();
			}
			if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			this.searchTimeout = setTimeout(() => {
				const searchArr = [];
				if (searchStr) {
					const encodedSearchStr = encodeURIComponent(searchStr);
					if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
						searchArr.push({ 'name': encodedSearchStr });
					} else if (this.erp_type === ErpTypeValEnum.SAPB1_HANA || this.erp_type === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
						searchArr.push({ 'name_with_code': encodedSearchStr });
					} else {
						searchArr.push({ 'name_with_code': encodedSearchStr });
					}
				}
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('entity', ServerEntity.CLIENTS);
				formData.append('method', ServerMethods.GET_ENTITY_DATA);
				formData.append('view_fields', JSON.stringify(view_fields));
				formData.append('is_dropdown', '1');
				if (searchArr && searchArr.length > 0) {
					formData.append('search', JSON.stringify(searchArr));
				}
				this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: response => {
						if (response.flag) {
							this.selectCustomerSearchTabData = response.data || [];
							if (this.selectCustomerSearchTabData.length > 0) {
								if ($('#tblSearchCustomerTab.showIt').is(':visible') === false) {
									this.searchSlideDown('tblSearchCustomerTab');
								}
							} else {
								this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
								this.selectCustomerSearchTabData = [];
							}
						} else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
							this.selectCustomerSearchTabData = [];
						}
					}, error: error => {

					}
				});
			}, 750);
		}
	}

	// Select customer searchtab single item checkbox
	selectCustomerSearchTabDataListItem(event, items) {
		if (items.selected) {
			const isExistItemIndex = this.customerTabList.findIndex(itemEle => itemEle.code === items.code);
			if (isExistItemIndex !== -1) {
				setTimeout(() => {
					items.selected = false;
				}, 50);
				this._ToastrService.info("Customer already exists.", "Info", { closeButton: true, tapToDismiss: true });
			}
		}
		if (this.isCustomerSearchTabAllSelected) {
			this.isCustomerSearchTabAllSelected = false;
		}
	}

	// Select customer searchtab all item checkbox
	isCustomerSearchTabAllSelectedChange() {
		this.selectCustomerSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	// Save customer tab data
	addCustomerAgreementTabDetails() {
		const selectedCustomerTabItem = this.selectCustomerSearchTabData.filter(item => item.selected === true);
		const selectedCustomerTabItemLength = selectedCustomerTabItem.length;
		if (selectedCustomerTabItemLength <= 0 && !this.isCustomerSearchTabAllSelected) {
			this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
		} else if (this.isCustomerSearchTabAllSelected) {
			this.customerTabList = [];
			const item = {
				name: 'All Customer',
			}
			this.customerTabList.push(item);
			this.isAllCustomerSelectedView = true;
		} else {
			selectedCustomerTabItem.forEach(element => {
				let inItem = undefined;

				if (this.customerTabList) {
					// Check duplicate item in list
					inItem = this.customerTabList.filter(function (item) {
						return (item.name || item.itemname) === (element.name || element.itemname) && (item.code || item.itemcode) === (element.code || element.itemcode);
					})[0];

					// Check all customer in list
					const checkAllCustomer = this.customerTabList.filter(function (item) {
						return (item.name || item.itemname) === 'All Customer';
					})[0];

					if (checkAllCustomer) {
						this.customerTabList = [];
					}
				}

				if (inItem === undefined) {
					const item = {
						name: element.itemname || element.name,
						code: element.itemcode || element.code,
					};
					this.customerTabList.push(item);
					this._ToastrService.success('Customer(s) added successfully', 'Success', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.warning('Customer(s) already added in list', 'Duplicate', { closeButton: true, tapToDismiss: true });
				}
			});
			this.isAllCustomerSelectedView = false;
		}
		this.setAgreementTabListData();
		this.searchSlideDown('tblSearchCustomerTab');
		this.clearCustomerAgreementTabDetails();
	}

	// Remove customer tab data
	removeCustomerAgreementTabDetails(ItemIndex) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				// Delete an item from customer Tab list
				this.customerTabList.splice(ItemIndex, 1);
				this.setAgreementTabListData();
				// this._ToastrService.error('Record deleted successfully. ', 'Deleted', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Clear input field customer tab data
	clearCustomerAgreementTabDetails() {
		this.customerTabModel.name = '';
		this.isCustomerSearchTabAllSelected = false;
		this.selectCustomerSearchTabData = [];
	}

	//#endregion

	//#region Product Group Tab

	// Product search select list
	doSearchSelectProductTabOnChange(event: any) {
		const view_fields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		let searchStr: any = event.target.value;
		this.selectProductSearchTabData = [];
		if (searchStr && searchStr.trim()) {
			searchStr = searchStr.trim();
			// if (searchStr.length <= 3) {
			// 	if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			// 	this.searchTimeout = setTimeout(() => {
			// 		this._ToastrService.info('Enter more characters to search terms.', 'Info', { closeButton: true, tapToDismiss: true });
			// 		this.selectProductSearchTabData = [];
			// 	}, 999);
			// 	return;
			// }
			if (this.dataInReqSubscription) {
				this.dataInReqSubscription.unsubscribe();
			}
			if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
			this.searchTimeout = setTimeout(() => {
				const searchArr = [];
				if (searchStr) {
					const encodedSearchStr = encodeURIComponent(searchStr);
					if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
						searchArr.push({ 'name_with_foreignname': encodedSearchStr });
					} else if (this.erp_type === ErpTypeValEnum.SAPB1_HANA || this.erp_type === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
						searchArr.push({ 'name_with_code': encodedSearchStr });
					} else {
						searchArr.push({ 'name_with_code': encodedSearchStr });
					}
				}
				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('entity', ServerEntity.ITEMS);
				formData.append('method', ServerMethods.GET_ENTITY_DATA);
				formData.append('view_fields', JSON.stringify(view_fields));
				formData.append('is_dropdown', '1');
				if (searchArr && searchArr.length > 0) {
					formData.append('search', JSON.stringify(searchArr));
				}
				this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
					next: response => {
						if (response.flag) {
							this.selectProductSearchTabData = response.data || [];
							if (this.selectProductSearchTabData.length > 0) {
								if ($('#tblSearchProductTab.showIt').is(':visible') === false) {
									this.searchSlideDown('tblSearchProductTab');
								}
							} else {
								this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
								this.selectProductSearchTabData = [];
							}
						} else {
							this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
							this.selectProductSearchTabData = [];
						}
					}, error: error => {

					}
				});
			}, 750);
		}
	}

	// Select product searchtab single item checkbox
	selectProductSearchTabDataListItem(event, items) {
		if (items.selected) {
			const isExistItemIndex = this.productOrGroupTabList.findIndex(itemEle => itemEle.erp_primary_key === items.erp_primary_key && itemEle.itemname === items.itemname);
			if (isExistItemIndex !== -1) {
				setTimeout(() => {
					items.selected = false;
				}, 50);
				this._ToastrService.info("Item already exists.", "Info", { closeButton: true, tapToDismiss: true });
			}
		}
		if (this.isProductSearchTabAllSelected) {
			this.isProductSearchTabAllSelected = false;
		}
	}

	// Select product searchtab all item checkbox
	isProductSearchTabAllSelectedChange(e) {
		if (this.isProductSearchTabAllSelected && this.productGroupRadioButton !== 'addgroups') {
			this.selectProductSearchTabData.forEach(element => {
				element.selected = !e.target.checked;
			});
		} else {
			this.selectProductSearchTabData.forEach(element => {
				element.selected = e.target.checked;
			});
		}
	}

	// Save Product tab data
	addProductAgreementTabDetails() {
		const selectedProductTabItem = this.selectProductSearchTabData.filter(item => item.selected === true);
		const selectedProductTabItemLength = selectedProductTabItem.length;
		// Check validation 
		if (selectedProductTabItemLength <= 0 && !this.isProductSearchTabAllSelected) {
			this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		} else if (this.isProductSearchTabAllSelected && this.productGroupRadioButton !== 'addgroups') {
			this.productOrGroupTabList = [];
			const item = {
				itemsku: '',
				itemname: 'All Product',
				erp_primary_key: '',
				itemOrGroup: 'item',
				description: ''
			}
			this.productOrGroupTabList.push(item);
			this.isAllProductSelectedView = true;
		} else {
			// Check object product and group not clear 
			const isExistItemIndex = this.productOrGroupTabList.findIndex(itemEle => itemEle.itemOrGroup === (this.productGroupRadioButton === 'addproducts' ? 'item' : 'group'));
			if (isExistItemIndex === -1) {
				this.productOrGroupTabList = [];
			}

			selectedProductTabItem.forEach(element => {
				// Check duplicate item in list
				const isExistItemIndex = this.productOrGroupTabList.findIndex(itemEle => itemEle.erp_primary_key === element.erp_primary_key && itemEle.itemname === element.itemname);
				if (isExistItemIndex === -1) {
					const item = {
						itemsku: element.itemsku || '',
						itemname: element.itemname || '',
						erp_primary_key: element.erp_primary_key,
						itemOrGroup: this.productGroupRadioButton === 'addproducts' ? 'item' : 'group',
						description: element.description || ''
					}
					this.productOrGroupTabList.push(item);
					this._ToastrService.success('Product(s) added successfully', 'Success', { closeButton: true, tapToDismiss: true });
				}
				else {
					this._ToastrService.info('Product(s) already added in list', 'Duplicate', { closeButton: true, tapToDismiss: true });
				}
			});
			this.isAllProductSelectedView = false;
		}
		this.searchSlideDown('tblSearchProductTab');
		this.clearProductAgreementTabDetails();
	}

	// Remove product tab data
	removeProductAgreementTabDetails(ItemIndex) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				this.productOrGroupTabList.splice(ItemIndex, 1);
				this.setAgreementTabListData();
				// this._ToastrService.error('Record deleted successfully. ', 'Deleted', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Clear input field product tab data
	clearProductAgreementTabDetails() {
		this.productAndGroupTabModel.itemname = '';
		this.productGroupRadioButton = 'addproducts';
		this.selectProductSearchTabData = [];
		this.isProductSearchTabAllSelected = false;
	}

	// Click Product Group RadioButton
	clickProductGroupRadioButton(tab) {
		this.selectProductSearchTabData = [];
		this.isProductSearchTabAllSelected = false;
		this.productAndGroupTabModel.itemname = '';
		if (tab === 'addgroups') {
			this.selectProductSearchTabData = JSON.parse(JSON.stringify(this.GroupsList));
		}
		this.selectProductSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	//#endregion

	//#region Exclusions Tab
	addExclusionAgreementTabDetails() {
		const selectedProductTabItem = this.selectProductSearchTabData.filter(item => item.selected === true);
		const selectedProductTabItemLength = selectedProductTabItem.length;
		if (!this.exclusionTabModel.clientname) {
			this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		} else if (selectedProductTabItemLength <= 0 && !this.isProductSearchTabAllSelected) {
			this._ToastrService.info('Select at least one item', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		} else if (!this.exclusionTabModel.rate) {
			this._ToastrService.info('Rate is required', 'Info!', { closeButton: true, tapToDismiss: true });
			return false;
		} else if (this.isProductSearchTabAllSelected && this.exclusionsGroupRadioButton !== 'addgroupexclusion') {
			// this.exclusionTabList = [];
			// Check duplicate item in list
			const isExistItemIndex = this.exclusionTabList.findIndex(itemEle => itemEle.clientcode === this.exclusionTabModel.clientcode && itemEle.itemcode === this.exclusionTabModel.clientcode);
			if (isExistItemIndex === -1) {
				const item = {
					clientcode: this.exclusionTabModel.clientcode,
					clientname: this.exclusionTabModel.clientname,
					itemOrGroup: 'item',
					itemsku: '',
					erp_primary_key: '',
					itemname: 'All Product',
					description: '',
					rate: +(this._DecimalPipe.transform(+this.exclusionTabModel.rate || 0, this.decimalPointPercentageFormat))
				}
				this.exclusionTabList.push(item);
			}
			this.isAllProductSelectedView = true;
		} else {
			selectedProductTabItem.forEach(element => {

				const item = {
					clientcode: this.exclusionTabModel.clientcode,
					clientname: this.exclusionTabModel.clientname,
					itemOrGroup: this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group',
					itemsku: element.itemsku || '',
					erp_primary_key: element.erp_primary_key || '',
					itemname: element.itemname || '',
					description: element.description || '',
					rate: +(this._DecimalPipe.transform(+this.exclusionTabModel.rate || 0, this.decimalPointPercentageFormat))
				}
				this.exclusionTabList.push(item);

			});
		}
		this.clearExclusionAgreementTabDetails();
		this.searchSlideDown('tblSearchProductTab');

	}

	// Remove exclusion tab data
	removeExclusionAgreementTabDetails(ItemIndex) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				this.exclusionTabList.splice(ItemIndex, 1);
				this.setAgreementTabListData();
				// this._ToastrService.error('Record deleted successfully. ', 'Deleted', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Clear input field exclusion tab data
	clearExclusionAgreementTabDetails() {
		this.exclusionsGroupRadioButton = 'addproductexclusion';
		this.isCustomerSearchTabAllSelected = false;
		this.isProductSearchTabAllSelected = false;
		this.selectCustomerSearchTabData = [];
		this.selectProductSearchTabData = [];
		this.exclusionTabModel.clientcode = '';
		this.exclusionTabModel.clientname = '';
		this.exclusionTabModel.productname = '';
		this.exclusionTabModel.rate = '';
	}

	clickExclusionsGroupRadioButton(tab) {
		this.selectProductSearchTabData = [];
		this.isProductSearchTabAllSelected = false;
		this.exclusionTabModel.productname = '';
		if (tab === 'addgroupexclusion') {
			this.selectProductSearchTabData = JSON.parse(JSON.stringify(this.GroupsList));
		}
		this.selectProductSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	exclusionsCustomerSearchListClick = function (ct, id) {
		this.exclusionTabModel.clientname = ct.name;
		this.exclusionTabModel.clientcode = ct.code;
		this.searchSlideDown(id);
	};


	isExclusionsProductSearchTabAllSelectedChange(e) {
		if (this.isProductSearchTabAllSelected && this.exclusionsGroupRadioButton !== 'addgroupexclusion') {
			this.selectProductSearchTabData.forEach(element => {
				element.selected = !e.target.checked;
			});
		} else {
			this.selectProductSearchTabData.forEach(element => {
				element.selected = e.target.checked;
			});
		}
	}

	editExclusionsListItem(exclusionItemIndex, newRate) {
		this.exclusionTabList[exclusionItemIndex].rate = newRate.value;
	}

	public doAddUploadedMatchedItemsToDatagridExclusionEvent(event: any) {
		this.exclusionTabList = event.listOfUploadedMatchedItems;
		if (this.royaltiesContractModelRequest.guid) {
			setTimeout(() => {
				this.saveRoyaltiesContract();
			}, 750);
		}
	}

	//#endregion

	//#region Call API Request

	callSaveRoyaltiesContract(arryRoyaltiesContracts) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('royaltiescontracts', JSON.stringify(arryRoyaltiesContracts));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				if (response.flag) {
					this.UpdateSettlementPeriodClick();
					this._ToastrService.success(((!this.editRoyaltiesGuid) ? ' Added' : ' Updated') + ' Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					this.royaltiesContractModelRequest.ixcode = response.data.royaltiescontracts[0].ixcode;
					this.royaltiesContractModelRequest.guid = response.data.royaltiescontracts[0].guid;
					this.royaltiesContractModelUI.guid = response.data.royaltiescontracts[0].guid;
					this.editRoyaltiesGuid = this.royaltiesContractModelRequest.guid;
					this.isAddMode = false;
					this._RoyaltiesService.setEditRoyaltiesGuid(this.editRoyaltiesGuid);
					// Disable royalties inputbox
					// this.disableRoyaltiesInputFields();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: error => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	async callSaveContractCloseSettlement(arryContracts) {
		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contracttype', 'royaltiescontracts');
		formData.append('royaltiescontracts', JSON.stringify(arryContracts));
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					// this.currentcontracts.push(arryContracts[0]);
					const roayltycontracts = response.data['royaltiescontracts'] || [];
					if (roayltycontracts.length > 0) {
						const indexCon = roayltycontracts.findIndex(i => i.guid === this.editRoyaltiesGuid);
						if (indexCon !== -1) {
							this.amortized_payments = roayltycontracts[indexCon]['amortized_payments'] || [];
						}
					}
					this.calculateSettlementPeriod(this.royaltiesContractModelUI, false);
					this.royaltiesContractModelUI.current_settlement_invoice_total = '0';
					this.royaltiesContractModelUI.current_settlement_commission_total = '0';
					this.royaltiesContractModelUI.current_settlement_creditmemo_total = '0';
					// this.closecontractsArray = [];
					if (!this.isDisabledSettlementPeriods) {
						if (this.isEnableAmortisePaymentsFeature && !this.isAddMode) {
							this.isDisabledSettlementPeriods = true;
						}
					}
					this._ToastrService.success('Close and save settlement successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});

	}

	editRoyaltiesContract() {
		let searchParams = [];
		if (this.editRoyaltiesGuid) {
			searchParams = [{ 'guid': this.editRoyaltiesGuid }];
		}
		this.doDisabledSettlementPeriods();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.ROYALTIESCONTRACTS);
		if (searchParams && searchParams.length > 0) {
			formData.append('search', JSON.stringify(searchParams));
		}
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				if (response.flag) {
					const editRoyaltiesContractDetails = response.data[0];

					this.isAddMode = false;
					this.editRoyaltiesGuid = editRoyaltiesContractDetails.guid;

					this.royaltiesContractModelUI.guid = editRoyaltiesContractDetails.guid;
					this.royaltiesContractModelUI.percentOrDollar = editRoyaltiesContractDetails.percentOrDollar;
					this.royaltiesContractModelUI.contractName = editRoyaltiesContractDetails.contractName;
					this.royaltiesContractModelUI.selectVendorId = editRoyaltiesContractDetails.selectVendorId;
					this.royaltiesContractModelUI.selectVendor = editRoyaltiesContractDetails.selectVendor;
					this.vendorGridBoxValue = [editRoyaltiesContractDetails.selectVendorId];
					this.royaltiesContractModelUI.royaltiesPercent = editRoyaltiesContractDetails.royaltiesPercent;
					// this.royaltiesContractModelUI.employeeCode = editRoyaltiesContractDetails.employeeCode;
					this.royaltiesContractModelUI.employeeName = editRoyaltiesContractDetails.employeeName;
					this.royaltiesContractModelUI.startDate = moment(editRoyaltiesContractDetails.startDate).format('YYYY-MM-DD');
					this.royaltiesContractModelUI.endDate = moment(editRoyaltiesContractDetails.endDate).format('YYYY-MM-DD');
					this.royaltiesContractModelUI.settlementPeriodStart = moment(editRoyaltiesContractDetails.settlementPeriodStart).format('YYYY-MM-DD');
					this.royaltiesContractModelUI.settlementPeriodEnd = moment(editRoyaltiesContractDetails.settlementPeriodEnd).format('YYYY-MM-DD');
					this.royaltiesContractModelUI.settlementPeriod = editRoyaltiesContractDetails.settlementPeriod;
					this.royaltiesContractModelUI.contractCalendar = editRoyaltiesContractDetails.contractCalendar;
					this.royaltiesContractModelUI.startSettlementPeriodOn = editRoyaltiesContractDetails.startSettlementPeriodOn;
					this.royaltiesContractModelUI.agreementBy = editRoyaltiesContractDetails.agreementBy;
					this.royaltiesContractModelUI.current_settlement_invoice_total = editRoyaltiesContractDetails.current_settlement_invoice_total;
					this.royaltiesContractModelUI.current_settlement_commission_total = editRoyaltiesContractDetails.current_settlement_commission_total;
					this.royaltiesContractModelUI.current_settlement_creditmemo_total = editRoyaltiesContractDetails.current_settlement_creditmemo_total;
					this.royaltiesContractModelUI.is_active = editRoyaltiesContractDetails.is_active;
					this.royaltiesContractModelUI.allclients = editRoyaltiesContractDetails.allclients;
					this.royaltiesContractModelUI.allproducts = editRoyaltiesContractDetails.allproducts;
					this.royaltiesContractModelUI.clients = [];
					this.royaltiesContractModelUI.items = [];
					this.royaltiesContractModelUI.excludedItems = [];
					this.royaltiesContractModelUI.rules = [];
					this.royaltiesContractModelUI.amortized_payments = [];
					this.customerTabList = editRoyaltiesContractDetails.clients;
					this.productOrGroupTabList = editRoyaltiesContractDetails.items;
					// this.exclusionTabList = editRoyaltiesContractDetails.excludedItems;
					this.exclusionTabList = editRoyaltiesContractDetails.customer_items_groups || [];
					this.ruleTabList = editRoyaltiesContractDetails.rules;
					this.amortized_payments = editRoyaltiesContractDetails['amortized_payments'] || [];
					if (editRoyaltiesContractDetails.allclients) {
						this.isAllCustomerSelectedView = true;
					}
					if (editRoyaltiesContractDetails.allproducts) {
						this.isAllProductSelectedView = true;
					}
					if (this.isEnableDistributionChannels) {
						this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
						if (editRoyaltiesContractDetails.distribution_channels) {
							this.distributionChannelsModel.arrSelectedDistributionChannelsList.push(editRoyaltiesContractDetails.distribution_channels);
						}
					}
					if (this.royaltiesContractModelUI.startDate !== '' && this.royaltiesContractModelUI.endDate !== '') {
						this.calculateSettlementPeriod(this.royaltiesContractModelUI);
					}
					this.setAgreementTabListData();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: error => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	callViewSettlementPeriod(contractCode, callbackMethod: any) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_CLOSE_SETTLEMENTS);
		formData.append('baseContractCode', contractCode);
		formData.append('contracttype', 'royaltiescontracts');

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response.flag) {
					callbackMethod(true, response.data);
				} else {
					//this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					callbackMethod(false, []);
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				callbackMethod(false, []);
			}
		});
	}

	UpdateSettlementPeriodClick() {

		const arryContracts = [];
		// Get employee name as per id
		// if (this.royaltiesContractModelUI.employeeCode) {
		// 	const emp = this.salesPplList.filter(item => (item.code).toString() === (this.royaltiesContractModelUI.employeeCode).toString())[0];
		// 	this.royaltiesContractModelUI.employeeName = emp !== undefined ? emp.firstname || '' : '';
		// }

		this.royaltiesContractModelRequest.percentOrDollar = this.royaltiesContractModelUI.percentOrDollar;
		this.royaltiesContractModelRequest.contractName = this.royaltiesContractModelUI.contractName;
		this.royaltiesContractModelRequest.selectVendorId = this.royaltiesContractModelUI.selectVendorId;
		this.royaltiesContractModelRequest.selectVendor = this.royaltiesContractModelUI.selectVendor;
		this.royaltiesContractModelRequest.royaltiesPercent = this.royaltiesContractModelUI.royaltiesPercent;
		// this.royaltiesContractModelRequest.employeeCode = this.royaltiesContractModelUI.employeeCode;
		this.royaltiesContractModelRequest.employeeName = this.royaltiesContractModelUI.employeeName;
		this.royaltiesContractModelRequest.startDate = moment(this.royaltiesContractModelUI.startDate).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.endDate = moment(this.royaltiesContractModelUI.endDate).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriodStart = moment(this.royaltiesContractModelUI.settlementPeriodStart).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriodEnd = moment(this.royaltiesContractModelUI.settlementPeriodEnd).format('YYYY-MM-DD');
		this.royaltiesContractModelRequest.settlementPeriod = this.royaltiesContractModelUI.settlementPeriod;
		this.royaltiesContractModelRequest.startSettlementPeriodOn = this.royaltiesContractModelUI.startSettlementPeriodOn;
		this.royaltiesContractModelRequest.contractCalendar = this.royaltiesContractModelUI.contractCalendar;
		this.royaltiesContractModelRequest.agreementBy = this.royaltiesContractModelUI.agreementBy;
		this.royaltiesContractModelRequest.allproducts = this.isAllProductSelectedView;
		this.royaltiesContractModelRequest.allclients = this.isAllCustomerSelectedView;
		this.royaltiesContractModelRequest.clients = this.customerTabList;
		this.royaltiesContractModelRequest.items = this.productOrGroupTabList;
		// this.royaltiesContractModelRequest.excludedItems = this.exclusionTabList;
		this.royaltiesContractModelRequest.customer_items_groups = this.exclusionTabList;
		this.royaltiesContractModelRequest.rules = this.ruleTabList;
		this.royaltiesContractModelRequest.amortized_payments = this.amortized_payments;
		this.royaltiesContractModelRequest.is_active = this.royaltiesContractModelUI.is_active;
		if (this.isEnableDistributionChannels) {
			this.royaltiesContractModelRequest.distribution_channels = this.distributionChannelsModel.arrSelectedDistributionChannelsList && this.distributionChannelsModel.arrSelectedDistributionChannelsList.length > 0 ? this.distributionChannelsModel.arrSelectedDistributionChannelsList[0] : '';
		}
		if (this.royaltiesContractModelRequest) {
			arryContracts.push(this.royaltiesContractModelRequest);
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
			formData.append('royaltiescontracts', JSON.stringify(arryContracts));
			formData.append('contracttype', 'royaltiescontracts');
			this._LoaderService.show();
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
				next: response => {
					this._LoaderService.hide();
					if (this.currentMainTab !== 'royalties_details') {
						this.currentMainTab = 'settlement_period';
					}
					if (response.flag) {
						this.royaltiesContractModelUI.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
						this.royaltiesContractModelUI.current_settlement_commission_total = response.data.current_settlement_commission_total;
						this.royaltiesContractModelUI.current_settlement_creditmemo_total = response.data.current_settlement_creditmemo_total;
						// this._ToastrService.success(response.message, 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				}, error: error => {
					this._LoaderService.hide();
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
			});
		}
	}
	//#endregion

	// call from child exlusion page
	saveRoyaltiesExclusionData(event) {
		this.saveRoyaltiesContract();
	}

	saveRoyaltiesRuleData(event) {
		this.saveRoyaltiesContract();
	}

	private doDisabledSettlementPeriods() {
		this.countTotalSettlementReports = this._RoyaltiesService.totalCountOfSettlementReports;
		this.isDisabledSettlementPeriods = false;
		if (this.isEnableAmortisePaymentsFeature && this.countTotalSettlementReports > 1 && !this.isAddMode) {
			this.isDisabledSettlementPeriods = true;
		}
	}
}
