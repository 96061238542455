export class BillbackContractModelUI {
	public ixcode: string;
	public guid: string;
	public contractName: string;
	public selectSupplier: string;
	public selectSupplierId: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public billBackType: string;
	public is_active: boolean;
	public pricelist_modified_date?: string;
	public pricelist_modified_user_action?: string;
	public billBackTabData: BillbackTabData = new BillbackTabData();
}

export class BillbackContractModelRequest {
	public ixcode: string;
	public guid: string;
	public contractName: string;
	public selectSupplier: string;
	public selectSupplierId: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public billBackType: string;
	public is_active: boolean;
	public pricelist_modified_date?: string;
	public pricelist_modified_user_action?: string;
	public items: BillBackItemTabModel[] = [];
	public billbackDocuments: BillBackDocumentsTabModel[] = [];
}

export class BillbackTabData {
	public itemTabData: any[] = [];
	public billBackDocumentsTabData: any[] = [];
}

export class CustomerTabModel {
	public selectCustomer: string;
	public selectCustomerId: string;
}

export class BillBackItemTabModel {
	public guid: string;
	public erp_primary_key: string;
	public itemsku: string;
	public itemname: string;
	public item_guid: string;
	public description?: string;
	public uomList: any;
	public uomgroupentry: any;
	public selectedUom: any;
	public listPrice: any;
	public billbackAmount: any;
	public final_amount: any;
	public is_disbled_input_field: boolean;
	public bought: any;
	public sold: any;
	public billed: any;
	public available: any;
	public last_invoice_date: string;
	public customers: CustomerMasterDetailsModel[] = []
	constructor() {
	}
}

export class CustomerMasterDetailsModel {
	public guid: string;
	public customer_guid: string;
	public customer_code: string;
	public customer_name: string;
	public allcustomers: boolean;
	public listPrice: any;
	public billbackAmount: any;
	public final_amount: any;
	public bought: any;
	public sold: any;
	public billed: any;
	public available: any;
	public climed_qty?: any;
	constructor() {
		this.allcustomers = false;
	}
}

export class BillBackDocumentsTabModel {
	public guid: string;
	public contractGuid: string;
	public documentURL: string;
	public documentReferenceNumber: string;
	public documentDate: string;
	public documentTotal: string;
	public claimedStatus: string;
	public claimedStatusReason: string;
	public claimedUserNote: string;
	public claimedItems: ClaimedItemsModel[] = [];
}

export class ClaimedItemsModel {
	public itemCode: string;
	public itemName: string;
	public endUserCode: string;
	public endUserName: string;
	public claimed: number;
}

export class ClaimAttachedDocumentModel {
	public currentClaimElement: any;
	public categoriesList: any = [];
	constructor() {
	}
}

export class ManualAdjustmentClaimDetailModel {
	guid: string;
	end_user_id: string;
	end_user_code: string;
	end_user_name: string;
	pricelistno: string;
	pricelistname: string;
	erp_primary_key: string;
	itemname: string;
	description: string;
	min: string;
	max: string;
	selectedUom: string;
	total_bought: number;
	other_bought: number;
	quantity: number;
	listPrice: number;
	final_amount: number;
	chargebackAmount: number;
	enduser_payout_code: string;
	taxexempt: string;
	taxrate: number;
	taxamount: number;
	linetotal: number;
	remark: string;
	reason_value: string;
	reason_description: string;
	status: string;
	type: string;
}

export class ManualAdjustmentClaimModel {
	guid: string;
	// ixcode: string;
	chargeback_period: any;
	claimedItems: any[];
	claimedStatus: string;
	contractName?: string;
	contractGuid?: string;
	// contracttype?: string;
	// created_by_guid?: string;
	// created_by_name?: string;
	// created_date?: Date;
	// modified_date?: Date;
	// documentDate: Date;
	//documentTotal: number;
	ignoredItems: any[];
	selectCustomer: string;
	selectCustomerId: string;
	// source_claim_no?: string;
	reason_code_guid?: string;
	reason_code_label?: string;
	reason_code_erpcode?: string;
	claimtype?: string;
	cut_off_date?: any;
	transaction_date?: any;
	remarkOrMemo?: string;
	constructor() { }
}
