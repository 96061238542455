<dx-popup width="90%" height="90%" maxHeight="90%" [showTitle]="true" [title]="popUpHeaderText" [dragEnabled]="true"
	[hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'"
			[bounceEnabled]="false">
			<div *ngIf="actionFor === 'showUploadFile'" class="forCsv">
				<div class="row">
					<div class="cols12">
						<div class="remarkBx fullNote ecaRemark mrgT0 mrgB10">
							<span class="mrgR10"><strong>Note:</strong></span> Columns should be <strong>item, uom,</strong>
							and <strong>billback</strong>
						</div>
					</div>
				</div>
				<div class="row dragdropfullwidth">
					<div class="cols12 colDrag fileUploadBillback mrgT10">
						<div class="row">
							<div class="center cols12">
								<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="doOnFileDrop($event)"
									[showBrowseBtn]="false" accept="csv" [multiple]="false" class="fileUploadInput ">
									<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
										<div class="csvUpload">
											<div class="uploadLeft">
												<span class="icon-upload"></span>
												<h4>Drag & Drop Files Here</h4>
											</div>
											<span class="orStyle"><span>OR</span></span>
											<div class="uploadRight">
												<button type="button" (click)="openFileSelector()" class="addFileInput"><span
														class="addIcon"></span> Browse Files</button>
												<span class="supprtInst">Supported up to 25 MB</span>
											</div>
										</div>
									</ng-template>
								</ngx-file-drop>
								<!-- <input type="file" (change)="changeListener($event)" #input
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
							</div>

							<div *ngIf="uploadedCsvFilesData" class="cols12 fileshow">
								<div class="fileImage">
									<span class="icon-csv_file"></span>
								</div>
								<div class="fileDetail">
									<h4>{{uploadedCsvFilesData?.name }}</h4>
									<span class="icon-deletedxtable" (click)="doRemoveUploadFiles()"></span>
									<div class="fileProgress">
										<span></span>
									</div>
									<div class="fileProgText">
										{{ uploadedCsvFilesData?.size }} bytes
										<!-- <span>100% uploaded</span> -->
									</div>
								</div>
							</div>

						</div>
					</div>
					<div class="cols12">
						<div class="hrSeparator mrgT20 mrgB10"></div>
					</div>
					<div class="cols12 mrgT10">
						<div class="row">
							<div class="cols8">
								<h2 class="subTitleRow mrgB10">Choose Customes(s)</h2>

								<dx-check-box text="All Customers" (onValueChanged)="checkAllCustomer()"
									[(value)]="chkAllCustomer" class="mrgB10">
								</dx-check-box>

								<dx-data-grid #customerDataGrid [dataSource]="customerDataSource"
									[columns]="customerGridBoxColumns" [selection]="{ mode: 'multiple' }"
									[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
									[filterRow]="{ visible: true }" [height]="391" [(selectedRowKeys)]="arrSelectedCustomerList"
									[showBorders]="true" [remoteOperations]="true"
									(onSelectionChanged)="onCustomerSelectionChanged($event)">
									<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
							<div class="cols4 ix-searchbox">
								<h2 class="subTitleRow mrgB10">Selected Customer(s)</h2>
								<!-- <dx-tag-box [(value)]="arrSelectedCustomerList"
									placeholder="Selected customer(s) will Show up here" class="customerListSlct" [grouped]="true">
								</dx-tag-box> -->
								<dx-list [dataSource]="arrSelectedCustomerList" [height]="395" [allowItemDeleting]="true"
									itemDeleteMode="static" [searchEnabled]="true" pageLoadMode="scrollBottom">
								</dx-list>
							</div>
						</div>
					</div>
				</div>
				<div class="alignRight mrgT10">
					<input name="doUploadCsvFile" type="button" (click)="doUploadCsvFile()" class="button " value="Upload">
				</div>
			</div>

			<div *ngIf="actionFor === 'showIgnoredItemList'">
				<div class="tabbing cf">
					<ul>
						<li (click)="doChangeTab('ignored_item')" [ngClass]="{active: currentTab == 'ignored_item'}">
							<a>Ignored Item</a>
						</li>
						<li (click)="doChangeTab('incorrect_uoms')" [ngClass]="{active: currentTab == 'incorrect_uoms'}">
							<a>Incorrect UOM(s)</a>
						</li>
						<li (click)="doChangeTab('ignored_price_billback')"
							[ngClass]="{active: currentTab == 'ignored_price_billback'}">
							<a>Price / Billback Ignored</a>
						</li>
					</ul>
				</div>
				<div *ngIf="isShowDatagrid">
					<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="dxDataSource" [showBorders]="true"
						keyExpr="guid" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
						[columnAutoWidth]="true" [width]="'100%'">
						<dxo-filter-row [visible]="true"></dxo-filter-row>
						<dxo-filter-panel [visible]="false"></dxo-filter-panel>
						<dxo-header-filter [visible]="false" [showRelevantValues]="false"></dxo-header-filter>
						<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
						<dxo-pager [visible]="true" [showPageSizeSelector]="true"
							[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
						</dxo-pager>

						<dxi-column caption="Member" dataField="member" [visible]="contractType === 'buyinggroup'"
							dataType="string" cellTemplate="cellTemplateMember">
							<div *dxTemplate="let element of 'cellTemplateMember'" class="">
								{{element.data.member || ''}}
							</div>
						</dxi-column>

						<dxi-column caption="Item" dataField="item" dataType="string" cellTemplate="cellTemplateItemName">
							<div *dxTemplate="let element of 'cellTemplateItemName'" class="">
								{{element.data.item || ''}}
							</div>
						</dxi-column>

						<dxi-column caption="Description" dataField="description" dataType="string"
							[visible]="currentTab === 'incorrect_uoms' || currentTab === 'ignored_price_billback'"
							cellTemplate="cellTemplateDescription">
							<div *dxTemplate="let element of 'cellTemplateDescription'" class="">
								{{element.data.description || ''}}
							</div>
						</dxi-column>

						<dxi-column caption="UOM" dataField="uom" [allowFiltering]="false" [allowEditing]="false"
							cellTemplate="cellTemplateUom">
							<div *dxTemplate="let element of 'cellTemplateUom'">
								<span *ngIf="element.data.uom">{{element.data.uom.toString() || '' | uppercase}}</span>
							</div>
						</dxi-column>

						<dxi-column caption="Default UOM" dataField="isItemModifiedUom"
							[visible]="currentTab == 'incorrect_uoms'" [allowFiltering]="true" [allowEditing]="false"
							cellTemplate="cellTemplateItemUomModified">
							<div *dxTemplate="let element of 'cellTemplateItemUomModified'">
								<span *ngIf="element.data.isItemModifiedUom">{{element.data.isItemModifiedUom.toString() || '' |
									uppercase}}</span>
							</div>
						</dxi-column>

						<dxi-column caption="Price"
							[visible]="(contractType === 'individual' || contractType === 'growth_incentive') && productSearchItemType === 'price'"
							dataField="price" dataType="number" [allowFiltering]="true" [allowEditing]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplatePrice'">
								<span *ngIf="element.data.price >= 0">
									{{element.data.price || 0 | customCurrencyPipe}}
								</span>
							</div>
						</dxi-column>

						<dxi-column caption="Billback" dataField="billback" dataType="number" [allowFiltering]="true"
							[allowEditing]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateBillbackPrice'">
								<span *ngIf="element.data.billback >= 0">
									{{element.data.billback || 0 | customCurrencyPipe}}
								</span>
							</div>
						</dxi-column>

					</dx-data-grid>
				</div>
			</div>
		</dx-scroll-view>
	</div>
	<!-- <dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
		</div>
	</dxi-toolbar-item> -->
</dx-popup>