<div class="row equalHight">
	<div class="cols12">
		<div class="chartAreaBox quarterlyReportBox cf padL0 padR0">
			<div class="chartArea">
				<div class="row mrgB20">
					<div class="cols12">
						<div class="cf"></div>
						<div class="dx-field txtcenter">
							<h2 class="chartTitlesmall">Sales Performance By Territory</h2>
						</div>
					</div>
				</div>

				<div class="posRelative">
					<dx-vector-map #vectorMapPerformance id="vector-map" [bounds]="[-138, 65, -90, 30]"
						(onClick)="click($event)" [touchEnabled]="false" [wheelEnabled]="false">
						<!-- [bounds]="[-118, 60, -80, 23]" -->
						<dxo-size [height]="370"> </dxo-size>
						<dxo-control-bar [enabled]="true" horizontalAlignment="right" verticalAlignment="top">
						</dxo-control-bar>
						<dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
							<!-- <dxo-font color="#fff"></dxo-font> -->
							<!-- <dxo-border [visible]="false"></dxo-border> -->
						</dxo-tooltip>
						<dxi-layer name="areas" [dataSource]="usaMap" [customize]="customizeLayers" colorGroupingField="total"
							[palette]="[ '#CCCC00', '#00AA00','#CC0000']" [colorGroups]="[0, 2500, 5000, 10000, 15000, 20000]">
							<dxo-label [enabled]="true" dataField="name"></dxo-label>
						</dxi-layer>
						<dxi-legend [customizeText]="customizeLegendText" verticalAlignment="top">
							<dxo-font size="11"></dxo-font>
							<dxo-source layer="areas" grouping="color"></dxo-source>
						</dxi-legend>
					</dx-vector-map>

					<!-- <dx-vector-map class="map" [bounds]="bounds" [tooltip]="tooltip" (onInitialized)="initialized($event)"
						(onCenterChanged)="centerChanged($event)">
						<dxo-size [height]="370"> </dxo-size>
						<dxo-control-bar [enabled]="false"></dxo-control-bar>
						<dxo-background borderColor="#fff"></dxo-background>

						<dxi-layer [dataSource]="dataSource" [opacity]="usaLayerOpacity" type="area"
							[colorGroupingField]="'democracy' + year" [colorGroups]="[0, 0.5, 1]"
							[palette]="[ '#3689d6', '#e55253' ]" [label]="{
					            enabled: true,
					            dataField: 'NAME',
					            'stroke-opacity': 0.3,
					            'stroke': '#000',
					            font: {
					                size: 12,
					                color: '#fff'
					            }
					        }">
						</dxi-layer>
						<dxi-layer [dataSource]="countyDataSource" type="area" [colorGroupingField]="'democracy' + year"
							[colorGroups]="[0, 0.5, 1]" [palette]="[ '#3689d6', '#e55253' ]" [label]="{
					            enabled: true,
					            dataField: 'NAME',
					            'stroke-opacity': 0.3,
					            'stroke': '#000',
					            font: {
					                size: 12,
					                color: '#fff'
					            }
					        }">
						</dxi-layer>
					</dx-vector-map> -->

					<app-ix-load-panel *ngIf="isVisibleChartLoader"></app-ix-load-panel>
				</div>
			</div>
		</div>
	</div>