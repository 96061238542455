<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="billbackChargebackContractDataSource"
	[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
	[filterSyncEnabled]="true" [dateSerializationFormat]="'yyyy-MM-dd'" [remoteOperations]="true"
	[columnAutoWidth]="true" [width]="'100%'" (onRowClick)="doEditBillbackContract($event.data);"
	[allowColumnReordering]="true" (onCellClick)="doCellClickDetails($event)"
	(onToolbarPreparing)="doToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">

	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>

	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadBillbackChargebackAgreementsState"
		[customSave]="doSaveBillbackChargebackState" [storageKey]="stateStorageKey">
	</dxo-state-storing>
	<dxo-filter-panel [visible]="actionFrom === 'billback_chargeback'"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<!-- <dxi-column dataField="selectCustomerId" caption="ID" dataType="string" [allowHeaderFiltering]="false"
		[visible]="false">
	</dxi-column> -->

	<dxi-column dataField="contractName" caption="Name" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<dxi-column dataField="selectCustomerId" caption="Customer Code" dataType="string" [allowHeaderFiltering]="false"
		[visible]="actionFrom === 'billback_chargeback'" [showInColumnChooser]="actionFrom === 'billback_chargeback'">
	</dxi-column>

	<dxi-column dataField="look_back_period" caption="Look Back Period (Days)" dataType="string"
		[allowHeaderFiltering]="false"
		[visible]="(actionFrom === 'billback_chargeback') && enable_chargeback_look_back_period"
		[showInColumnChooser]="(actionFrom === 'billback_chargeback') && enable_chargeback_look_back_period">
	</dxi-column>


	<!-- <dxi-column dataField="subusername" caption="Salesperson" dataType="string">
						</dxi-column> -->

	<dxi-column dataField="startDate" alignment="center" caption="Start Date" dataType="date" format="yyyy-MM-dd"
		cellTemplate="cellTemplateStartDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateStartDate'">
			{{ (element.data.startDate !== null && element.data.startDate !== '' && element.data.startDate !==
			undefined && element.data.startDate !== 'Invalid date') ? (element.data.startDate | date:
			'yyyy-MM-dd') : "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="endDate" alignment="center" caption="End Date" dataType="date" format="yyyy-MM-dd"
		cellTemplate="cellTemplateEndDate" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateEndDate'">
			{{ (element.data.endDate !== null && element.data.endDate !== '' && element.data.endDate !==
			undefined && element.data.endDate !== 'Invalid date') ? (element.data.endDate | toDateObj | date: 'yyyy-MM-dd')
			: "" }}
		</div>
	</dxi-column>

	<dxi-column dataField="contractType" [visible]="isShowContractTypeColumn"
		[showInColumnChooser]="isShowContractTypeColumn" alignment="center" caption="Type" dataType="string"
		cellTemplate="cellContractType" [allowHeaderFiltering]="true">
		<dxo-header-filter [dataSource]="listContractType">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellContractType'">
			{{element.data.contractType === 'billbackcontracts' ? 'Billback' : element.data.contractType ===
			'chargebackcontracts' ? 'Chargeback' : element.data.contractType === 'distributionchargebackcontracts' ?
			'Distribution Chargeback':element.data.contractType === 'minmaxenduserschargebackcontracts'? 'Min-Max
			Chargeback' :
			'End-User Chargeback'}}
		</div>
	</dxi-column>

	<dxi-column dataField="is_active" [allowFiltering]="false" [allowHeaderFiltering]="true" alignment="center"
		caption="Status" dataType="string" cellTemplate="cellTemplateStatus" [visible]="isShowIsActiveColumn"
		[showInColumnChooser]="isShowIsActiveColumn">
		<dxo-header-filter [dataSource]="listOfStatusHeaderFilter">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateStatus'">
			<dx-drop-down-button [splitButton]="false" [ngClass]="getClassNameForStatusField(element.data.is_active)"
				[useSelectMode]="false" [text]="element.data.is_active ? 'ACTIVE' : 'INACTIVE'" stylingMode="contained"
				class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign" [items]="listOfStatuses"
				[wrapItemText]="true" [useSelectMode]="true" displayExpr="label" keyExpr="value"
				(onButtonClick)="getListOfStatus(element.data)"
				(onItemClick)="doChangedBillBackContractStatusAction(element.data)" [width]="'100%'">
			</dx-drop-down-button>
		</div>
	</dxi-column>
	<!-- <dxi-column caption="Action" alignment="center" dataType="string" cellTemplate="cellTemplateAction"
						[visible]="isColumneVisible">
						<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
							<a  (click)="editBillbackContract(element.data);$event.stopPropagation();">
								<span class="icon-editdxtable"></span>
							</a>
							 <a  (click)="copyRebatesContract(element.data);$event.stopPropagation();">
									<span class="icon-copydxtable"></span>
								</a> 
						</div>
						<dxo-filter-row [visible]="false"></dxo-filter-row>
						<dxo-header-filter [visible]="false"></dxo-header-filter>
					</dxi-column> -->

</dx-data-grid>
<app-mmeucc-multi-claim-approval-popup *ngIf="isShowApproveMultiChargebackClaim"
	[ApproveMultiChargebackClaimProps]="ApproveMultiChargebackClaimProps"
	(closeApproveMultiChargebackClaimPopup)="doCloseApproveMultiChargebackClaimPopup($event)">
</app-mmeucc-multi-claim-approval-popup>
<app-eucc-view-claim-detail-popup *ngIf="isShowViewDetailsPopUp"
	[endUserViewDetailRptProps]="endUserViewDetailRptProps">
</app-eucc-view-claim-detail-popup>