import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
import { LoginService } from '@app/services/login.service';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Guid } from '@app/models/guid';
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { PageOptions } from '@app/models/common.model';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { BillbackChargebackService } from '@app/services/contract-service/billback-chargeback/billback-chargeback.service';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Subscription } from 'rxjs';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { DistributionChargebackContractService } from '@app/services/contract-service/billback/distribution-chargeback-contract.service';
import { ChargeDocumentsTabModel, ClaimAttachedDocumentModel, DistributionChargebackContractModelUI } from '@app/models/distribution-chargeback-contract.model';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-dcc-claim-attached-documents',
	templateUrl: './dcc-claim-attached-documents.component.html',
	styleUrls: ['./dcc-claim-attached-documents.component.css']
})
export class DccClaimAttachedDocumentsComponent implements OnInit, OnDestroy {

	public pageOptions: PageOptions = new PageOptions();
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
	@ViewChild('statusMenuTrigger') statusMenuTrigger: MatMenuTrigger;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	private confirmMatDialogRef: MatDialogRef<any>;
	public statusId: any;
	public guid = new Guid();
	public categoriesList: any = [];
	public currentClaimElement: any;
	public chargebackDocumentsTabList: any = [];
	public chargeDocumentsTabDataSource: any = [];
	public chargeDocumentsTabModel: any = new ChargeDocumentsTabModel();
	public documentFileData: any;
	public dropTextValue: any;
	public currentIndex: any;
	public editDistributionChargebackGuid: any;
	public is_disputable: boolean = false;
	public is_disabled_submit_credit_button: boolean = false;
	private dataInReqSubscription: Subscription;
	private getGroupObjectSubscription: Subscription;
	public listOfStatuses: any[] = [];
	public isDocumentLen: boolean = false;
	public claimAttachedDocumentModel = new ClaimAttachedDocumentModel();
	public distributionChargebackContractModelUI = new DistributionChargebackContractModelUI();
	// public CalledFormClaimAttachedDocument: string;
	public chargebackTypeDataList: any[] = [];
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public permsn: any = [];
	constructor(
		public _ToastrService: ToastrService,
		public _LoginService: LoginService,
		public _LoaderService: LoaderService,
		public _RestApiService: RestApiService,
		public _ConfirmationDialogService: ConfirmationDialogService,
		private _BillbackChargebackService: BillbackChargebackService,
		public _DistributionChargebackContractService: DistributionChargebackContractService,
		private _Router: Router,
		private _PermissionsService: NgxPermissionsService,
	) { }

	ngOnInit() {
		//	this.CalledFormClaimAttachedDocument = this._BillbackChargebackService.getCalledFormClaimAttachedDocument();
		this.dropTextValue = 'Save as ';

		this.chargebackDocumentsTabList = [];
		//	if (this.CalledFormClaimAttachedDocument === 'distribution-chargeback') {
		this.editDistributionChargebackGuid = this._DistributionChargebackContractService.getEditDistributionChargebackGuid();
		this.claimAttachedDocumentModel = this._DistributionChargebackContractService.getClaimAttachmentDocumentData();
		this.currentClaimElement = this.claimAttachedDocumentModel.currentClaimElement;
		this.distributionChargebackContractModelUI = this._DistributionChargebackContractService.getDistributionChargebackObject();
		this.is_disputable = false;
		this.getChargebackTypeDataListById();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		this.permsn = [];
		for (const per in permissions) {
			this.permsn.push(per)
		}
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		// } else {
		// 	this.editBillBackGuid = this._BillbackChargebackService.getEditBillBackGuid();
		// 	this.claimAttachedDocumentModel = this._BillbackChargebackService.getClaimAttachmentDocumentData();
		// 	this.categoriesList = this.claimAttachedDocumentModel.categoriesList;
		// 	this.currentClaimElement = this.claimAttachedDocumentModel.currentClaimElement;
		// 	this.onInitForBillback();
		// }
		this.categoriesList = this.claimAttachedDocumentModel.categoriesList;
		this.onInitForBillback();

	}

	onInitForBillback() {
		this.getAttachedDocuments();
		this.getChargebackStatusTabDataSource();
		const categoriesIndex = this.categoriesList.findIndex(p => p.guid === this.currentClaimElement.categoryId);
		if (categoriesIndex !== -1) {
			if (this.categoriesList[categoriesIndex].is_disputable) {
				this.is_disputable = this.categoriesList[categoriesIndex].is_disputable;
			}
		}
		if (this.currentClaimElement.status === 'claimsubmitted') {
			this.is_disabled_submit_credit_button = true;
			this.is_disputable = false;
		}
	}

	onInitForDistributionChargeback() {
		this.getAttachedDocuments();
		this.getChargebackStatusTabDataSource();
		const categoriesIndex = this.categoriesList.findIndex(p => p.guid === this.currentClaimElement.chargebackTypeId);
		if (categoriesIndex !== -1) {
			if (this.categoriesList[categoriesIndex].is_disputable) {
				this.is_disputable = this.categoriesList[categoriesIndex].is_disputable;
			}
		}
		if (this.currentClaimElement.status === 'claimsubmitted') {
			this.is_disabled_submit_credit_button = true;
			this.is_disputable = false;
		}
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
	}

	public getChargebackTypeDataListById() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'distributionChargebackTypes');
		if (this.currentClaimElement.guid) {
			formData.append('contractGuid', this.distributionChargebackContractModelUI.guid);
		}
		if (this.getGroupObjectSubscription) {
			this.getGroupObjectSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.getGroupObjectSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this.categoriesList = response.data || [];
					this.onInitForDistributionChargeback();
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			} else {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	// Method get distribution Chargeback Status list
	public getChargebackStatusTabDataSource() {

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'dxGetEntityData');
		formData.append('entity', 'distributionChargebackStatus');
		if (this.getGroupObjectSubscription) {
			this.getGroupObjectSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.getGroupObjectSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this.listOfStatuses = response.data || [];
					if (this.listOfStatuses && this.listOfStatuses.length > 0) {
						if (this.currentClaimElement.statusId) {
							const statusObj = this.listOfStatuses.filter(p => p.guid === this.currentClaimElement.statusId)[0];
							if (statusObj) {
								this.dropTextValue = 'Save as ' + statusObj.label;
							}
						}
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			} else {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	getAttachedDocuments() {
		let queryString = environment.URL_DATAOUT;
		queryString = queryString + '?usr=' + this._LoginService.loginUser.user;
		queryString = queryString + '&token=' + this._LoginService.loginUser.token;
		queryString = queryString + '&method=dxGetEntityData';
		queryString = queryString + '&entity=claimAttachments';
		queryString = queryString + '&chargebackClaimGuid=' + this.editDistributionChargebackGuid;
		queryString = queryString + '&chargebackLineItemGuid=' + this.currentClaimElement.guid;
		// if (this.CalledFormClaimAttachedDocument === 'distribution-chargeback') {
		// 	queryString = queryString + '&chargebackClaimGuid=' + this.editDistributionChargebackGuid;
		// 	queryString = queryString + '&chargebackLineItemGuid=' + this.currentClaimElement.guid;
		// } else {
		// 	queryString = queryString + '&contractguid=' + this.editBillBackGuid;
		// 	queryString = queryString + '&chargebackClaimGuid=' + this.currentClaimElement.guid;
		// }

		this.chargeDocumentsTabDataSource = AspNetData.createStore({
			key: "guid",
			loadUrl: queryString,
			onLoaded(result) {
				if (result) {
					this.isDocumentLen = result.length > 0 ? true : false;
				}
			}
		});
	}

	downloadChargeBackDocument(chargeBackDocumentDetails) {
		const documentURL = chargeBackDocumentDetails.documentURL;
		const documentNewURLArr = documentURL.split('-');
		if (documentNewURLArr && documentNewURLArr.length > 0) {
			const docId = documentNewURLArr[0];
			const docDate = documentNewURLArr[1];
			const docCode = documentNewURLArr[2];
			const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=claimAttachmentDoc';
			window.location.href = finalUrl;
		}
	}

	submitCreditMemo() {
		this._ConfirmationDialogService.setDialogConfig({
			popupTitle: "Confirm",
			popupMessage: 'Are you sure you want to submit the Credit Memo to the ERP? This action cannot be undone',
			popupOkBtnText: "Ok",
			popupCancelBtnText: "Cancel",
			popupInfoBoxClass: "confirmationIcon"
		});
		this.confirmMatDialogRef = this._ConfirmationDialogService.confirmDialog();
		this.confirmMatDialogRef.afterClosed().subscribe(isSkiped => {
			if (isSkiped) {
				this.callSubmitCraditMemo();
			}
		});
	}

	callSubmitCraditMemo() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'claimDistributionChargeback');
		formData.append('chargebackClaimGuid', this.editDistributionChargebackGuid);
		formData.append('chargebackLineItemGuid', this.currentClaimElement.guid);
		// if (this.CalledFormClaimAttachedDocument === 'distribution-chargeback') {
		// 	formData.append('chargebackClaimGuid', this.editDistributionChargebackGuid);
		// 	formData.append('chargebackLineItemGuid', this.currentClaimElement.guid);
		// } else {
		// 	formData.append('contractguid', this.editBillBackGuid);
		// 	formData.append('chargebackClaimGuid', this.currentClaimElement.guid);
		// }
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(
			response => {
				if (response.flag) {
					this.goToBackPage('claimsubmitted');
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	onItemClick(e) {
		// if (!this.isDocumentLen) {
		// 	this._ToastrService.info('At least one document is required !', 'Info!', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }
		this.dropTextValue = 'Save as ' + e.itemData.label;
	}

	onStatusDropButtonClick(e) {

		// if (!this.isDocumentLen) {
		// 	this._ToastrService.info('At least one document is required !', 'Info!', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }

		if (e.selectedItem) {
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', 'updateClaimStatus');
			formData.append('chargebackClaimGuid', this.editDistributionChargebackGuid);
			formData.append('chargebackLineItemGuid', this.currentClaimElement.guid);
			formData.append('statusId', e.selectedItem.guid);
			if (this.dataInReqSubscription) {
				this.dataInReqSubscription.unsubscribe();
			}
			this._LoaderService.show();
			this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(
				response => {
					if (response.flag) {
						this.goToBackPage(e.selectedItem.value);
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
				}, error => {
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				});
		} else {
			this.goToBackPage();
		}
	}
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		const fileName = 'Attached-Document.xlsx';
		worksheet = workbook.addWorksheet('Attached-Document List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	addChargebackDocumentsAgreementTabDetails() {
		if (this.chargeDocumentsTabModel.documentURL) {
			if (!this.chargeDocumentsTabModel.documentReferenceNumber && !this.chargeDocumentsTabModel.documentDocumentDescription && !this.chargeDocumentsTabModel.claimedUserNote) {
				this._ToastrService.info('Document Description Or Document Reference No. Or User Note is required!', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		} else {
			if (!this.chargeDocumentsTabModel.claimedUserNote) {
				this._ToastrService.info('Document Or User Note is required!', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}

		const item = {
			guid: this.chargeDocumentsTabModel.guid ? this.chargeDocumentsTabModel.guid : this.guid.newGuid(),
			documentURL: this.chargeDocumentsTabModel.documentURL,
			documentReferenceNumber: this.chargeDocumentsTabModel.documentReferenceNumber,
			documentDocumentDescription: this.chargeDocumentsTabModel.documentDocumentDescription,
			documentDate: this._LoginService.loginUser.server_date,
			claimedUserNote: this.chargeDocumentsTabModel.claimedUserNote || '',
		}
		const arrAttached: any = [];
		arrAttached.push(item);
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'saveClaimAttachment');
		formData.append('chargebackClaimGuid', this.editDistributionChargebackGuid);
		formData.append('chargebackLineItemGuid', this.currentClaimElement.guid);
		// if (this.CalledFormClaimAttachedDocument === 'distribution-chargeback') {
		// 	formData.append('chargebackClaimGuid', this.editDistributionChargebackGuid);
		// 	formData.append('chargebackLineItemGuid', this.currentClaimElement.guid);
		// } else {
		// 	formData.append('contractguid', this.editBillBackGuid);
		// 	formData.append('chargebackClaimGuid', this.currentClaimElement.guid);
		// }
		formData.append('claimAttachmentFile', this.documentFileData);
		formData.append('claimAttachment', JSON.stringify(arrAttached));
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(
			response => {
				if (response.flag) {
					this._ToastrService.success(response.message, 'Success!', { closeButton: true, tapToDismiss: true });
					this.getAttachedDocuments();
					this.chargeDocumentsTabModel = new ChargeDocumentsTabModel();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});

		// this.chargebackDocumentsTabList.push(item);
		// this.chargeDocumentsTabDataSource = this.chargebackDocumentsTabList;
		// this.chargeDocumentsTabModel = new ChargeDocumentsTabModel();
		//const editBillBackGuid = this._BillbackChargebackService.getEditBillBackGuid();
		// if (editBillBackGuid) {
		// 	let billbackChargebackContractComponent = new BillbackChargebackContractComponent();
		// 	billbackChargebackContractComponent.saveBillBackContract();
		// }
	}

	// Upload document
	uploadDocument(files: File[]) {
		if (files.length === 1) {
			// file type verify
			const fileType = files[0].type;
			if (fileType !== '.doc' && fileType !== '.docx' && fileType !== 'application/msword' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				&& fileType !== '.csv' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && fileType !== 'application/vnd.ms-excel' && fileType !== 'application/pdf') {
				this._ToastrService.info('File extension not valid', 'Info!', { closeButton: true, tapToDismiss: true });
				this.chargeDocumentsTabModel.documentURL = '';
				return false;
			}
			// file size convert KB
			const fileSize = Math.round(files[0].size / 1024);
			if (fileSize > 1024) {
				this._ToastrService.info('File size should not greater then 1 MB', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
			this.documentFileData = files[0];
			this.chargeDocumentsTabModel.documentURL = files[0].name;
			// this._FileUploadService.upload(files);

		}
	}

	doChangedStatus(btnIndex, element) {
		this.statusId = element.value;
		this.goToBackPage();
	}

	goToBackPage(statusId?: any) {

		this.currentClaimElement.attachedDocumentData = this.chargebackDocumentsTabList;
		if (statusId) {
			this.currentClaimElement.statusId = statusId;
		}
		this._BillbackChargebackService.claimAttachedDocumentModel.currentClaimElement = this.currentClaimElement;
		this._Router.navigate(['/sales/distribution-chargebacks']);
		// if (this.CalledFormClaimAttachedDocument === 'distribution-chargeback') {
		// 	this._Router.navigate(['/sales/distribution-chargebacks']);
		// } else {
		// 	this._Router.navigate(['/contracts/billback']);
		// }
	}

	editChargeBackDocumentDetails(element) {
		this.chargeDocumentsTabModel = new ChargeDocumentsTabModel();
		this.chargeDocumentsTabModel = JSON.parse(JSON.stringify(element));
	}

}

