import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { RoyaltiesService } from '@app/services/contract-service/royalties/royalties.service';
import { Guid } from '@app/models/guid';

@Component({
	selector: 'app-royalties-contract-list',
	templateUrl: './royalties-contract-list.component.html',
	styleUrls: ['./royalties-contract-list.component.css']
})
export class RoyaltiesContractListComponent implements OnInit, OnDestroy {

	//#region Local Variable

	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	public allPermissions: any[] = [];
	public pageOptions: PageOptions = new PageOptions();
	public dataInReqSubscription: Subscription;
	public dataOutReqSubscription: Subscription;

	public royaltiesPermission: string = 'INCENTIVE_ROYALTIES_MODIFY';
	public storedFiltersValue: any;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'Inactive' }];
	public listOfSettlementPeriodHeaderFilter: any[] = [
		{ value: 'monthly', text: 'Monthly' },
		{ value: 'quarterly', text: 'Quarterly' },
		{ value: 'halfYearly', text: 'Bi-annually' },
		{ value: 'yearly', text: 'Annually' },
	];
	public listOfIndividualOrGroup: any[] = [
		{ value: 'individual', text: 'Individual' },
		{ value: 'buyinggroup', text: 'Buying Group' },
	];

	public royaltiesContractDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public listOfStatuses: any[] = this._AppCommonSrvc.doGetBooleanStatusList();
	private isCellClickDetail: boolean = false;
	public isShowAction: boolean = true;
	public isDisabledToModifyStatus: boolean = false;
	private doCopyContractSbsn: Subscription;
	public listOfContractCalendar: any[] = [];
	private guid = new Guid();
	private updateUserSettingSbsn: Subscription;
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	public updateSettlementPeriodBtnText = 'Update Current Settlement Period';
	public isShowLoaderIndicator: boolean;
	private updateMultiOpenSettlementPeriodsSbsn: Subscription;
	public decimalPointForCurrency: number;
	public listOfActions: any[];
	//#endregion

	//#region Constructor
	constructor(private _Router: Router,
		private _RoyaltiesService: RoyaltiesService,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _LoaderService: LoaderService,
		public _AppCommonSrvc: AppCommonSrvc) {
		this.doLoadRoyaltiesState = this.doLoadRoyaltiesState.bind(this);
		this.doSaveRoyaltiesState = this.doSaveRoyaltiesState.bind(this);
	}
	//#endregion

	//#region Angular Life Cycle Methods

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.mainSectionStorageKey = ServerSections.INCENTIVES_LISTING_SETTING;
		this.stateStorageKey = ServerSections.INCENTIVES_ROYALTIES_LISTING;
		this.setAllPermissions();
		this.listOfContractCalendar = this._RoyaltiesService.listOfContractCalendar || [];
		this.listOfActions = this._RoyaltiesService.getListOfActionsForDatagrid() || [];
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);

		this.storedFiltersValue = [{ dataType: 'string', dataField: 'is_active', searchMode: 'contains', filterValues: [true] }];
		this.applyStoredFilter();
		this.loadRoyaltiesContractDataSource();
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.doCopyContractSbsn) {
			this.doCopyContractSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.updateMultiOpenSettlementPeriodsSbsn) {
			this.updateMultiOpenSettlementPeriodsSbsn.unsubscribe();
		}
	}

	//#endregion

	//#region royalties List Methods
	private setAllPermissions() {
		//Get current permissions
		const permissions = this._NgxPermissionsService.getPermissions();
		this.allPermissions = [];
		for (let per in permissions) {
			this.allPermissions.push(per);
		}
		this.isShowAction = true;
		this.isDisabledToModifyStatus = false;
		if ((this.allPermissions.indexOf('INCENTIVE_ROYALTIES_MODIFY') === -1)) {
			this.isShowAction = false;
			this.isDisabledToModifyStatus = true;
		}
	}

	public changedRoyaltiesContractStatusAction(royaltiesGuid, is_active) {
		const view_fields = ['number', 'guid', 'ixcode', 'contractName', 'selectVendor', 'startDate', 'endDate', 'is_active', 'settlementPeriod', 'royaltiesPercent', 'subusername'];
		if (this.allPermissions.indexOf('INCENTIVE_ROYALTIES_MODIFY') === -1) {
			return false;
		}
		this._LoaderService.show();
		const objUpdateData = { 'is_active': is_active };

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.ROYALTIESCONTRACTS);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('guid', royaltiesGuid);
		formData.append('updateData', JSON.stringify(objUpdateData));
		formData.append('view_fields', JSON.stringify(view_fields));
		this.dataOutReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.loadRoyaltiesContractDataSource();
				} else {
					this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doUpdateCurrentSettlementPeriods() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_MULTIPLE_OPEN_SETTELEMENT_PERIOD);
		formData.append('contracttype', 'royaltiescontracts');
		if (this.updateMultiOpenSettlementPeriodsSbsn) {
			this.updateMultiOpenSettlementPeriodsSbsn.unsubscribe();
		}
		this._ToastrService.info('Update Current Settlement Process has been started', 'Info', { closeButton: true, tapToDismiss: true, newestOnTop: true });
		this.isShowLoaderIndicator = true;
		this.updateMultiOpenSettlementPeriodsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this.isShowLoaderIndicator = false;
				if (response.flag) {
					this._ToastrService.success('Update Current Settlement Process has been completed', 'Success', { closeButton: true, tapToDismiss: true, newestOnTop: true });
					this.doRefreshDataGrid();
				}
			}, error: (error) => {
				this.isShowLoaderIndicator = false;
			}
		});
	}

	public addNewRoyaltiesContract() {
		this._Router.navigate(['/contracts/royalties/']);
		this._RoyaltiesService.setEditRoyaltiesGuid('');
	}

	public editRoyaltiesContract(data) {
		if (this.isCellClickDetail) {
			return;
		}
		if (!data.is_active) {
			return false;
		}
		this._Router.navigate(['/contracts/royalties/']);
		this._RoyaltiesService.setEditRoyaltiesGuid(data.guid);
	}

	//#endregion

	//#region Dev_extreme Source  

	private loadRoyaltiesContractDataSource(call?: boolean) {
		const viewFields = ['number', 'guid', 'ixcode', 'individualOrGroup', 'selectVendor', 'contractName', 'startDate', 'endDate', 'is_active', 'settlementPeriod', 'royaltiesPercent', 'contractCalendar', 'settlementPeriodStart', 'settlementPeriodEnd', 'current_settlement_commission_total'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			entity: ServerEntity.ROYALTIESCONTRACTS,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			view_fields: JSON.stringify(viewFields),
		}
		this.royaltiesContractDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// For Set already applied filter from anywhere
	private applyStoredFilter() {
		const storedFilters = this.storedFiltersValue;
		if (storedFilters && storedFilters.length > 0) {
			setTimeout(() => {
				storedFilters.forEach(sf => {
					if (sf.dataType === 'string') {
						if (sf.selectedFilterOperation) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "selectedFilterOperation", sf.selectedFilterOperation);
						}
						if (sf.searchMode) {
							this.dataGridContainer.instance.columnOption(sf.dataField, "searchMode", sf.searchMode);
						}
						this.dataGridContainer.instance.columnOption(sf.dataField, "filterValues", sf.filterValues);
					}
				});
			}, 75);
		}
	}


	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateUpdateCurrentSettlementBtn',
				visible: true,
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'ADD':
					this.doAddContract();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;
				case 'UPDATE_CURRENT_SETTLEMENT_PERIODS':
					this.doUpdateCurrentSettlementPeriods();
					break;
				case 'COLUMN_CHOOSER':
					this.dataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowAction;
		}
	}

	private doAddContract() {
		this.addNewRoyaltiesContract();
	}

	private doRefreshDataGrid() {
		this.dataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = 'Royalties_Contract_List.xlsx';
		worksheet = workbook.addWorksheet('Royalties List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'settlementPeriod') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value === 'halfYearly' ? 'Bi-annually' : gridCell.value === 'yearly' ? 'Annually' : gridCell.value.toString().charAt(0).toUpperCase() + gridCell.value.toString().slice(1);
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
					if (gridCell.column.dataField === 'contractCalendar') {
						const ccIndex = this.listOfContractCalendar.findIndex(cc => cc.value === gridCell.data.contractCalendar);
						if (ccIndex !== -1) {
							excelCell.value = this.listOfContractCalendar[ccIndex].text;
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}


	//#endregion

	public getClassNameForStatusField(status) {
		const gotStatusIndex = this.listOfStatuses.findIndex(s => s.value === status);
		let dynamicClass = 'dynamic_21b3c6';
		if (gotStatusIndex !== -1) {
			const statusObj = JSON.parse(JSON.stringify(this.listOfStatuses[gotStatusIndex]));
			if (statusObj.bgcolor) {
				dynamicClass = 'dynamic_' + statusObj.bgcolor.toString().replace('#', '');
			}
		}
		return dynamicClass;
	}

	// Method used to prevent rowclick for particular column click
	public doCellClickDetails(e) {
		this.isCellClickDetail = false;
		if (e.column.dataField === "is_active") {
			this.isCellClickDetail = true;
			return;
		}
	}

	public getListOfStatus(data) {
		this.listOfStatuses.forEach(action => {
			action.label = action.label.toUpperCase();
		});
		this.listOfStatuses.forEach(action => {
			if (action.value === data.is_active) {
				action.visible = false;
			} else {
				action.visible = true;
			}
		});
	}

	public doCopyRoyaltyContract(element: any) {
		if (!element.is_active) {
			return false;
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contracttype', 'royaltiescontracts');
		formData.append('method', ServerMethods.COPY_CONTRACT);
		formData.append('contractguid', element.guid);
		if (this.doCopyContractSbsn) {
			this.doCopyContractSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.doCopyContractSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					this._ToastrService.success(response.message, 'Saved', { closeButton: true, tapToDismiss: true });
					this.loadRoyaltiesContractDataSource();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doLoadRoyaltiesState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveRoyaltiesState(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}
}
