import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LoginService } from '@app/services/login.service';
import { ErpTypeValEnum, RebateApprovalStatusValEnum } from "@app/constants-enums/enums";
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { Subscription } from 'rxjs';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { ToastrService } from 'ngx-toastr';
declare const ExcelJS: ExcelJS;


@Component({
	selector: 'app-rebates-tierbase-reportview-buyinggroup',
	templateUrl: './rebates-tierbase-reportview-buyinggroup.component.html',
	styleUrls: ['./rebates-tierbase-reportview-buyinggroup.component.css'],
})
export class RebatesTierbaseReportviewBuyinggroupComponent implements OnInit {

	private erpType: string;
	private rebateGuid: string;
	public rebateDetails: any;
	public invoiceDetails: any;
	public pageOptions: PageOptions = new PageOptions();
	public settlementPeriodStartDate: any;
	public settlementPeriodEndDate: any;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public globalCurrencySymbol: string;
	public itemsInvoicesDataSource: any;
	public listOfBuyyinggroupMemberTypeHeaderFilter: any[] = [{ text: 'Buying Group', value: 'buyinggroup' },
	{ text: 'Buying Group Member', value: 'buyinggroupmember' },
	{ text: 'Split', value: 'split' }];
	public decimalPointPercentageFormat: string = '1.0-0';
	public decimalPointForCurrency: number;
	private getRebateDetailsSbsn: Subscription;
	private updateApprovalStatusSbsn: Subscription;
	public pageFromMainModule: string;
	private enable_rebate_settlement_approval_workflow: boolean;
	public listOfApprovalStatusesBtns: any[] = [];
	public listOfApprovalStatuses: any[] = [];
	public selectedKeyDropDownBtn: string;
	public isShowDropDownButton: boolean;
	public RebateApprovalStatusValEnum = RebateApprovalStatusValEnum;
	public remark: string;
	public isShowRebateSettlementApprovalTrailsPopup: boolean;
	public isShowApprovalTrailsBtn: boolean;
	public approvalTrailDetailsProps: any;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();

	constructor(private datePipe: DatePipe,
		private _LoginService: LoginService,
		private _RebateService: RebateService,
		private _RestApiService: RestApiService,
		private _IxDxAlertService: IxDxAlertService,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _Router: Router,
		private _ActivatedRoute: ActivatedRoute) {
	}

	ngOnInit() {
		const currentUrl = this._Router.url;
		if (currentUrl.startsWith('/contracts/rebates/')) {
			this.pageFromMainModule = 'CONTRACT_REBATES';
		}
		if (currentUrl.startsWith('/notifications/approvals/')) {
			this.pageFromMainModule = 'NOTIFICATIONS_APPROVALS';
		}

		this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;

		this.invoiceDetails = this._RebateService.getInvoiceDetails();
		this.setErpType();
		this.doCheckEnabledRebateSettlementApprovalWorkflowFeature();
		this.settlementPeriodStartDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodStart, 'yyyy-MM-dd');
		this.settlementPeriodEndDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodEnd, 'yyyy-MM-dd');
		this.loadBuyingGroupsOrMembersDataSource();
		this.doFetchRebateDetails();
		this.doShowApprovalTrailBtn();
		if (this.pageFromMainModule = 'NOTIFICATIONS_APPROVALS') {
			if (this.enable_rebate_settlement_approval_workflow) {
				this.listOfApprovalStatuses = this._RebateService.getListOfApprovalStatuses;
				this.setMultipleActionsBtns();
			}
		}
	}


	ngOnDestroy(): void {
		if (this.updateApprovalStatusSbsn) {
			this.updateApprovalStatusSbsn.unsubscribe();
		}
	}

	private setErpType() {
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		}
		catch (e) {
			this.erpType = '';
		}
		// check Is QB User (no need to view code of QB user)

		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
	}

	// Method used to fetch List of BuyingGroup/Member
	private loadBuyingGroupsOrMembersDataSource() {
		const loadUrl = environment.URL_DATAOUT;
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			section: 'paymentItems',
			contracttype: 'rebatescontracts',
			contractguid: this.invoiceDetails.guid,
		}
		this.itemsInvoicesDataSource = AspNetData.createStore({
			key: 'cardcode',
			loadUrl: loadUrl,
			loadParams: loadParams
		});
	}

	public doRedirecToReport(element) {
		this._RebateService.setBuyingGroupDetails(element);
		let invoiceDetails = JSON.parse(JSON.stringify(this.invoiceDetails));
		if (this.rebateDetails && this.rebateDetails.guid) {
			invoiceDetails = { ...this.rebateDetails, ...invoiceDetails };
		}
		// "settlementValue": 6110,
		// approval_guid: rowData.guid,
		// approval_status: rowData.approval_status,

		this._RebateService.setInvoiceDetails(invoiceDetails);
		this._Router.navigate(['../report'], { relativeTo: this._ActivatedRoute });
	}

	private doFetchRebateDetails() {
		const searchParams = [{ 'guid': this.invoiceDetails.baseContractCode }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.REBATESCONTRACTS);
		formData.append('search', JSON.stringify(searchParams));
		// this._LoaderService.show();
		if (this.getRebateDetailsSbsn) {
			this.getRebateDetailsSbsn.unsubscribe();
		}
		this.getRebateDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				// this._LoaderService.hide();
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						this.rebateDetails = response.data[0];
						this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.rebateDetails);
						this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
					}
				} else {

				}
			}, error: error => {
				console.error('error', error);
				// this._LoaderService.hide();

			}
		});
	}

	private doCheckEnabledRebateSettlementApprovalWorkflowFeature() {
		let isEnabledRebateSettlementApprovalWorkflow = false;
		try {
			isEnabledRebateSettlementApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_rebate_settlement_approval_workflow;
		} catch (e) {
			isEnabledRebateSettlementApprovalWorkflow = false;
		}
		this.enable_rebate_settlement_approval_workflow = isEnabledRebateSettlementApprovalWorkflow;
	}

	private setMultipleActionsBtns() {
		if (this.enable_rebate_settlement_approval_workflow) {
			// approval_guid: rowData.guid,
			// 	approval_status: rowData.approval_status,
			const approvalStatus = this.invoiceDetails.approval_status || null;
			this.listOfApprovalStatusesBtns = [];
			if (approvalStatus === RebateApprovalStatusValEnum.DRAFT) {
				const signatureReqStatusIndex = this.listOfApprovalStatuses.findIndex(rs => rs.value === RebateApprovalStatusValEnum.PENDING_APPROVAL);
				const signatureReqClone = JSON.parse(JSON.stringify(this.listOfApprovalStatuses[signatureReqStatusIndex]));
				signatureReqClone.label = 'Submit For Approval';
				this.listOfApprovalStatusesBtns.push(signatureReqClone);
			} else if (approvalStatus === RebateApprovalStatusValEnum.PENDING_APPROVAL) {
				const currentStatusIndex = this.listOfApprovalStatuses.findIndex(rs => rs.value === RebateApprovalStatusValEnum.APPROVED);
				const currentStatusClone = JSON.parse(JSON.stringify(this.listOfApprovalStatuses[currentStatusIndex]));
				currentStatusClone.label = 'Approve';
				this.listOfApprovalStatusesBtns.push(currentStatusClone);
				const rejectedStatusIndex = this.listOfApprovalStatuses.findIndex(rs => rs.value === RebateApprovalStatusValEnum.REJECTED);
				const rejectedClone = JSON.parse(JSON.stringify(this.listOfApprovalStatuses[rejectedStatusIndex]));
				rejectedClone.label = 'Reject';
				this.listOfApprovalStatusesBtns.push(rejectedClone);
			} else if (approvalStatus === RebateApprovalStatusValEnum.APPROVED) {
				const draftStatusIndex = this.listOfApprovalStatuses.findIndex(rs => rs.value === RebateApprovalStatusValEnum.DRAFT);
				const draftClone = JSON.parse(JSON.stringify(this.listOfApprovalStatuses[draftStatusIndex]));
				draftClone.label = 'Set To Draft';
				this.listOfApprovalStatusesBtns.push(draftClone);
			} else {
				const currentStatusIndex = this.listOfApprovalStatuses.findIndex(rs => rs.value === RebateApprovalStatusValEnum.DRAFT);
				const draftStatusClone = JSON.parse(JSON.stringify(this.listOfApprovalStatuses[currentStatusIndex]));
				draftStatusClone.label = 'Submit';
				this.listOfApprovalStatusesBtns.push(draftStatusClone);
				const signatureReqStatusIndex = this.listOfApprovalStatuses.findIndex(rs => rs.value === RebateApprovalStatusValEnum.PENDING_APPROVAL);
				const signatureReqClone = JSON.parse(JSON.stringify(this.listOfApprovalStatuses[signatureReqStatusIndex]));
				signatureReqClone.label = 'Submit For Approval';
				this.listOfApprovalStatusesBtns.push(signatureReqClone);
			}
			this.selectedKeyDropDownBtn = this.listOfApprovalStatusesBtns[0].value;
		}
		this.isHideShowDropDownButton();
	}

	private isHideShowDropDownButton() {
		this.isShowDropDownButton = false;
		if (this.pageFromMainModule = 'NOTIFICATIONS_APPROVALS' && this.invoiceDetails.approval_status) {
			if (this.invoiceDetails.approval_status === RebateApprovalStatusValEnum.DRAFT) {
				this.isShowDropDownButton = false;
			}
			if (this.invoiceDetails.approval_status === RebateApprovalStatusValEnum.PENDING_APPROVAL) {
				this.isShowDropDownButton = true;
			}
			if (this.invoiceDetails.approval_status === RebateApprovalStatusValEnum.APPROVED) {
				this.isShowDropDownButton = false;
			}
		}
	}

	public getMultipleActionsBtns() {
		return this.listOfApprovalStatusesBtns && this.listOfApprovalStatusesBtns.length > 0 ? this.listOfApprovalStatusesBtns[0].text : '';
	}

	public doSaveUpdateWorkflowStatus(e: any) {
		const status = e.selectedItem.value;
		if (status === RebateApprovalStatusValEnum.REJECTED) {
			this.doSubmitToRejected();
		}
		if (status === RebateApprovalStatusValEnum.APPROVED) {
			this.doSubmitToApproved();
		}
	}

	public doSaveUpdateWorkflowStatusChanged(e: any) {
		const status = e.itemData.value;
		this.selectedKeyDropDownBtn = status;
		if (status === RebateApprovalStatusValEnum.APPROVED) {
			this.doSubmitToApproved();
		}
	}

	private doGetRequestFormData(approval_status: string) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_ENTITY_DATA);
		formData.append('entity', ServerEntity.APPROVALS);
		formData.append('type', 'rebatescontracts');
		formData.append('updateData', JSON.stringify({ "approval_status": approval_status }));
		formData.append('guid', this.invoiceDetails.approval_guid);
		if (this.remark) {
			formData.append('remark', this.remark);
		}
		return formData;
	}

	private doUpdateApprovalStatus(formData: FormData) {
		if (this.updateApprovalStatusSbsn) {
			this.updateApprovalStatusSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.updateApprovalStatusSbsn = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && response.flag) {
					this.remark = '';
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
					this._Router.navigate(['../rebates_settlements'], { relativeTo: this._ActivatedRoute });
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private doSubmitToRejected() {
		if (!this.remark || (this.remark && !this.remark.trim())) {
			this._ToastrService.info('Remark is required', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (this.invoiceDetails.approval_guid) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Reject Rebate Settlement',
				message: 'Are you sure you want to reject a rebate settlement? <br/> <br/>You will not be able to undo the operation.'
			};
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					const formData = this.doGetRequestFormData(RebateApprovalStatusValEnum.REJECTED);
					this.doUpdateApprovalStatus(formData);
				}
			});
		}
	}

	private doSubmitToApproved() {
		if (this.invoiceDetails.approval_guid) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="sendIcon"><div class="infoIcon mrgT0"></div></div>',
				title: '',
				showTitle: false,
				subtitle: 'Approve Rebate Settlement',
				message: 'Are you sure you want to approve a rebate settlement? <br/>You will not be able to undo the operation.'
			};
			this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					const formData = this.doGetRequestFormData(RebateApprovalStatusValEnum.APPROVED);
					this.doUpdateApprovalStatus(formData);
				}
			});
		}
	}

	public doViewApprovalTrails(element: any) {
		const settlementGuid = this.invoiceDetails.hasOwnProperty('settlement_guid') ? this.invoiceDetails.settlement_guid : this.invoiceDetails.guid;
		this.approvalTrailDetailsProps = {
			guid: this.rebateDetails.guid,
			settlement_guid: settlementGuid
		}
		this.doOpenApprovalTrailsPopup();
	}

	private doOpenApprovalTrailsPopup() {
		this.isShowRebateSettlementApprovalTrailsPopup = false;
		setTimeout(() => {
			this.isShowRebateSettlementApprovalTrailsPopup = true;
		}, 100);
	}

	private doShowApprovalTrailBtn() {
		this.isShowApprovalTrailsBtn = false;
		if (this.enable_rebate_settlement_approval_workflow) {
			this.isShowApprovalTrailsBtn = true;
			if (this.invoiceDetails.approval_status === RebateApprovalStatusValEnum.DRAFT) {
				this.isShowApprovalTrailsBtn = false;
			}
		}
	}
}
