<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitle" [showCloseButton]="true" [(visible)]="isShowPopup"
	(onHidden)="doHiddenPopup($event)" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div *ngIf="isShowDatagrid">
				<dx-data-grid #membersListDataGridRef id="gridContainer" [dataSource]="dxDataSource"
					[remoteOperations]="isRemoteOperations" [showBorders]="true" [columnAutoWidth]="true" keyExpr="code"
					class="hideFilterPanelInx" [height]="450">

					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
					</dxo-pager>

					<dxi-column caption="Code" [visible]="!isQBErpUser" dataField="code" [allowHeaderFiltering]="false"
						cellTemplate="cellTemplateCode">
						<div *dxTemplate="let element of 'cellTemplateCode'">
							{{element.data.code || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="Member" dataField="name" [allowEditing]="false" [allowHeaderFiltering]="false"
						cellTemplate="cellTemplateMemberName">
						<div *dxTemplate="let element of 'cellTemplateMemberName'">
							{{element.data.name || ''}}
						</div>
					</dxi-column>
				</dx-data-grid>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>