<div class="numberBlock hoverClr" id="invoicePopover">
	<span (click)="doOpenInvoicePopover($event)"
		[class]="isUserSettingsExisting ? 'settingIcon icon-setting-purple' : 'settingIcon icon-setting'"
		title="Setting Icon">
	</span>
	<div (click)="tilesClick('INVOICES')">
		<span class="contractIcon">
			<span class="icon-bill_pay icon-set1"></span>
		</span>
		<div class="blockText">
			<strong>
				<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
				<span *ngIf="!resourcesLoaded">
					<span *ngIf="!isShowFullValue" (click)="doClickToShowFullValue($event)"
						class="dashboardamount shortamount">
						{{salesTotalForTheMonth || 0 | customFormatCurrencyPipe}}
						<span *ngIf="salesTotalForTheMonth >= 1000" class="dashboardamounttext">Click to see full value</span>
					</span>
					<div *ngIf="isShowFullValue" class="fullamount"
						[ngClass]="{'trillionamount ': getLastCharacterFromSortValue ===  'T' ,'quadrillionamount': getLastCharacterFromSortValue === 'Q' || getLastCharacterFromSortValue ==='E'}">
						{{salesTotalForTheMonth || 0 | customCurrencyPipe}}
					</div>
				</span>
			</strong>
			<span class="blockDate">{{currentMonthName || ''}}</span>
			<!-- <span class="blockDate"
			*ngIf="settlementPeriodStart && settlementPeriodEnd && settlementPeriodStart !== '' && settlementPeriodEnd !==''">{{ settlementPeriodStart | date: 'yyyy-MM-dd' }}
			to
			{{ settlementPeriodEnd | date: 'yyyy-MM-dd' }}
		</span> -->
			Invoice Total
		</div>
	</div>
</div>

<dx-popover target="#invoicePopover" position="bottom" [(visible)]="isShowInvoicePopover"
	(onHidden)="doCloseInvoicePopover($event)" [hideOnOutsideClick]="true" [showCloseButton]="true" [showTitle]="true"
	title="Invoice Tile" width="600">
	<div *dxTemplate="let data = model of 'content'">
		<app-tile-invoice-settings *ngIf="isShowInvoicePopover" [openFrom]="'invoice_tile'"
			(refreshTile)="doRefreshTile($event)">
		</app-tile-invoice-settings>
	</div>
</dx-popover>