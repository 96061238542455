import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';

@Component({
	selector: 'app-rebates-contract-list',
	templateUrl: './rebates-contract-list.component.html',
	styleUrls: ['./rebates-contract-list.component.css']
})

export class RebatesContractListComponent implements OnInit, OnDestroy {

	//#region Local Variable
	public rebatesPermission: string = 'INCENTIVE_REBATES_MODIFY';
	//#endregion

	//#region Constructor
	constructor(public _Router: Router,
		private _RebateService: RebateService) {
	}
	//#endregion

	//#region Angular Life Cycle Methods

	ngOnInit() {

	}

	ngOnDestroy() {

	}

	//#endregion
}
