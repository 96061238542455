// use for user role name
export enum UserRolesEnum {
	SUPER_ADMIN = 'superadmin',
	SALES_MANAGER = 'salesmanager',
	SALES_REP = 'salesrep',
	ADMIN_VIEW = 'adminview',
	CRMUSER = 'crmuser',
	EXTREFPARTNER = 'externalrefuser',
}
// End use for user role name

export enum NameFromServerKeyEnum {
	LEAD = 'leads',
	PROSPECT = 'prospects',
	CLIENT = 'clients',
	VENDOR = 'vendors',
	BUY_GROUP = 'buyinggroups',
	TASKS = 'tasks',
	QUOTATIONS = 'quotations',
	ORDERS = 'orders',
	INVOICES = 'invoices',
	CRMDATA = 'crmdata'
}

export enum BusinessPartnerTabValEnum {
	CLIENT = 'clients',
	VENDOR = 'vendors',
	BUY_GROUP = 'buyingGroups',
	TERRITORY = 'territory',
	END_USERS = 'endUsers',
	CUSTOMERS = 'customers'

}

export enum BusinessPartnerTabLblEnum {
	CLIENT = 'Customers',
	VENDOR = 'Vendors',
	BUY_GROUP = 'Buying Groups',
	TERRITORY = 'Territory',
	END_USERS = 'End-Users'
}

export enum BusinessPartnerActionBtnsEnum {
	LEAD = 'leads',
	PROSPECT = 'prospects',
	CUSTOMER = 'clients',
	VENDOR = 'vendors',
	BUY_GROUP = 'buyingGroups',
	TERRITORY = 'territory',
	EDIT = 'edit',
	DELETE = 'delete'
}

export enum ContactDetailTabDataEnum {
	TIMELINES = 'timelines',
	TASKS = 'tasks',
	DEALS = 'deals',
	MAILS = 'mails',
	DOCUMENTS = 'documents',
	ORDERS = 'orders',
	SHIPMENTS = 'shipments',
	INVOICES = 'invoices',
	ARINVOICES = 'arinvoices',
	AR_CREDIT_MEMO = 'ar_credit_memo',
	PAYMENTS = 'payments',
	NOTES = 'notes',
	CUSTOMER360 = 'customer360',
	REBATES = 'rebates',
	CHARGEBACKS = 'chargebacks',
	CONTACTEMPLOYEES = 'contactemployees',
	ADDRESSES = 'addresses',
	CREDIT_CARDS = 'credit_cards',
	ROLE = 'role',
	CUSTOM_FIELDS = 'custom_fields',
}

export enum ContactDetailActionBtnsLblEnum {
	PHONECALL = 'Phone Call',
	SENDEMAIL = 'Send Email',
	SITEVISIT = 'Site Visit',
	APPOINTMENT = 'Appointment',
	MARKASPROSPECT = 'Mark as Prospect',
	MARKASCUSTOMER = 'Mark as Customer',
	MARKASBUYINGGROUP = 'Mark as BuyingGroup',
	ADD_TO_PIPELINE = 'Add To Pipeline',
	ADDTASK = 'Add Task',
	ADDDEAL = 'Add Quote',
	ADDDOCUMENT = 'Add Document',
	EDIT = 'Edit',
	DELETE = 'Delete',
	ADDORDER = 'Add Order',
	ADDINVOICE = 'Add Invoice',
	EDITTASK = 'Edit Task',
	OPENTASKSTATUS = 'Mark as Open',
	CLOSETASKSTATUS = 'Mark as Closed',
	ADDPAYMENTS = 'Add Payment',
	ADDREBATES = 'Add Rebates',
	ADDCHARGESBACKS = 'Add Chargesbacks',
	ADDCONTACT = 'Add Contact',
	ADDADDRESS = 'Add Address',
	ADDCUSTOMERROLE = 'Add Role',
}
export enum ContactDetailActionBtnsValEnum {
	PHONECALL = 'phonecalls',
	SENDEMAIL = 'sendemail',
	SITEVISIT = 'sitevisit',
	APPOINTMENT = 'appointment',
	MARKASPROSPECT = 'prospects',
	MARKASCUSTOMER = 'clients',
	MARKASBUYINGGROUP = 'buyingGroup',
	ADD_TO_PIPELINE = 'add_to_pipeline',
	ADDTASK = 'addtask',
	ADDDEAL = 'adddeal',
	ADDDOCUMENT = 'adddocument',
	EDIT = 'edit',
	DELETE = 'delete',
	ADDORDER = 'addorder',
	ADDINVOICE = 'addinvoice',
	EDITTASK = 'edittask',
	OPENTASKSTATUS = 'opentask',
	CLOSETASKSTATUS = 'closetask',
	ADDPAYMENTS = 'addpayments',
	ADDREBATES = 'addrebates',
	ADDCHARGESBACKS = 'addchargesbacks',
	ADDCONTACT = 'addcontact',
	ADDADDRESS = 'addaddress',
	ADDCUSTOMERROLE = 'addcustomerrole',
}
export enum CardPaymentActionBtnsValEnum {
	SETASDEFAULT = 'setasdefault',
	DELETE = 'delete',
}
export enum ConfigTierFeatureValEnum {
	DYNAMIC = 'dynamic',
	STATIC = 'static',
}
export enum DistChargebackActionBtnsValEnum {
	EDIT = 'edit',
	DELETE = 'delete',
	ADD_DOCUMENTS = 'adddocuments',
}
export enum TaskTypesLblEnum {
	PHONECALL = 'Phone Call',
	SENDEMAIL = 'Send Email',
	APPOINTMENT = 'Appointment',
	SITEVISIT = 'Site Visit'
}

export enum TaskTypesValEnum {
	PHONECALL = 'phonecall',
	SENDEMAIL = 'sendemail',
	APPOINTMENT = 'appointment',
	SITEVISIT = 'sitevisit'
}

export enum TaskStatusLblEnum {
	OPEN = 'Open',
	CLOSE = 'Closed'
}

export enum TaskStatusValEnum {
	OPEN = 'open',
	CLOSE = 'close'
}

export enum DealStatusLblEnum {
	OPEN = 'Open',
	WON = 'Won',
	LOST = 'Lost',
}

export enum DealStatusValEnum {
	OPEN = 'open',
	WON = 'won',
	LOST = 'lost',
}

export enum SalesTabsLblEnum {
	QUOTATIONS = 'Quotes',
	ORDERS = 'Orders',
	SALESINVOICES = 'AR Invoices',
	ARINVOICES = 'AR Credit Memo',
	AR_CREDIT_MEMO = 'AR Credit Memos',
	CHAGEBACK = 'Chargebacks',
	PAYMENTS = 'Payments',
	SHIPMENT = 'Shipments',
	BILLING_INVOICES = 'AR Invoices',
	END_USER_INVOICES = 'End-User Invoices',
}

export enum SalesTabsValEnum {
	QUOTATIONS = 'QUOTATIONS',
	ORDERS = 'ORDERS',
	SALESINVOICES = 'SALESINVOICES',
	ARINVOICES = 'ARINVOICES',
	AR_CREDIT_MEMO = 'AR_CREDIT_MEMO',
	CHAGEBACK = 'CHAGEBACK',
	PAYMENTS = 'PAYMENTS',
	SHIPMENT = 'SHIPMENTS',
	BILLING_INVOICES = 'INVOICES',
	END_USER_INVOICES = 'END_USER_INVOICES',
}


export enum SalesChargebacksTabsValEnum {
	DISTRIBUTION_CHARGEBACKS = 'DISTRIBUTION_CHARGEBACKS',
	END_USERS_CHARGEBACKS = 'END_USERS_CHARGEBACKS',
	TPM_CLAIMS = 'TPM_CLAIMS'
}
export enum SalesChargebacksTabsLblEnum {
	DISTRIBUTION_CHARGEBACKS = 'Distribution Chargebacks',
	END_USERS_CHARGEBACKS = 'End-Users Chargebacks',
	TPM_CLAIMS = 'TPM Claims'
}

// ============ CRM Enums ============ //

export enum CRMTabValEnum {
	DASHBOARD = 'dashboard',
	LEAD = 'leads',
	PROSPECT = 'prospects',
	CLIENT = 'clients',
	TASKS = 'tasks'
}

export enum CRMTabLblEnum {
	DASHBOARD = 'Dashboard',
	LEAD = 'Leads',
	PROSPECT = 'Prospects',
	CLIENT = 'Customers',
	TASKS = 'Tasks'
}

export enum CRMActionBtnsEnum {
	LEAD = 'leads',
	PROSPECT = 'prospects',
	CUSTOMER = 'clients',
	ADD_TO_PIPELINE = 'add_to_pipeline',
	VENDOR = 'vendors',
	BUY_GROUP = 'buyingGroups',
	TERRITORY = 'territory',
	EDIT = 'edit',
	DELETE = 'delete',
	ADDTASK = 'addtask',
	ADDDEAL = 'adddeal',
	ADDORDER = 'addorder',
}

export enum CRMValStatuses {
	INITIALCONTACT = 'initialcontact',
	NEWLEAD = 'newlead',
	FOLLOWUP = 'followup',
	DEMOSAMPLES = 'demosamples',
	PROPOSALDEAL = 'proposaldeal',
	PRICENEGOTIATION = 'pricenegotiation',
	WON = 'won',
	LOST = 'lost',
	OPEN = 'open',
	CLOSED = 'close',
}

export enum CRMLblStatuses {
	INITIALCONTACT = 'Initial Contact',
	NEWLEAD = 'New Lead',
	FOLLOWUP = 'Follow Up',
	DEMOSAMPLES = 'Demo / Samples',
	PROPOSALDEAL = 'Proposal / Deal',
	PRICENEGOTIATION = 'Price Negotiation',
	WON = 'Won',
	LOST = 'Lost',
	OPEN = 'Open',
	CLOSED = 'Closed',
}

export enum CRMLblSources {
	BY_GOOGLE = 'Google',
	BY_CUSTOMER = 'Customer',
	BY_REFERRAL_PARTNER = 'Referral Partner'
}

export enum CRMValSources {
	BY_GOOGLE = 'bygoogle',
	BY_CUSTOMER = 'bycustomer',
	BY_REFERRAL_PARTNER = 'byreferralpartner'
}

export enum AddressTypesValEnum {
	BILTO = 'bilto',
	SHIPTO = 'shipto',
	BO_BILLTO = 'bo_BillTo',
	BO_SHIPTO = 'bo_ShipTo',
	BILLTO = 'billto',
}

export enum QuotationForValEnum {
	CUSTOMERS = 'customers',
	PROSPECTS = 'prospects'
}

export enum ComponentNamesAsValEnum {
	CRMCONTACTDETAIL = 'CrmContactDetailComponent',
	SALESLIST = 'SalesListComponent'
}

export enum QuotesInternalTypeValEnum {
	QUOTES = 'QUOTES', // Used for Quotation
	SALESINVOICES = 'SALESINVOICES',
	DEALCRMPROSPECT = 'DEALCRMPROSPECT', // Used for Quotation
	DEALCRMCLIENT = 'DEALCRMCLIENT', // Used for Quotation
	ORDERSSALES = 'ORDERSSALES',
	ORDERSCRMCLIENT = 'ORDERSCRMCLIENT',
	INVOICEDETSALES = 'INVOICEDETSALES',
	INVOICEDETCRMCLIENT = 'INVOICEDETCRMCLIENT',
	INVOICEDETCRMCUSTOMER360 = 'INVOICEDETCRMCUSTOMER360',
	ORDERDETSALES = 'ORDERDETSALES',
	ORDERDETCRMCLIENT = 'ORDERDETCRMCLIENT',
	QUOTEDETSALES = 'QUOTEDETSALES', // Used for Quotation Detail from Sales
	QUOTEDETCRMPROSPECT = 'QUOTEDETCRMPROSPECT', // Used for Quotation Detail from CRM Prospect
	QUOTEDETCRMCLIENT = 'QUOTEDETCRMCLIENT', // Used for Quotation Detail from CRM Client
	SHIPMENTDETSALES = 'SHIPMENTDETSALES',
	SHIPMENTDETCRMCLIENT = 'SHIPMENTDETCRMCLIENT',
}

export enum AppsCofigsTabValEnum {
	EXTERNALSYSCONFIGS = 'EXTERNALSYSCONFIGS',
	SYNCSETTINGS = 'SYNCSETTINGS',
	CUSTOMFIELDS = 'CUSTOMFIELDS',
	VARIABLEMAPPING = 'VARIABLEMAPPING',
	TERRITORIESDEFINITION = 'TERRITORIESDEFINITION',
	FISCALCALCULATIONOPTIONS = 'FISCALCALCULATIONOPTIONS',
	SYSTEMCONFIGURATIONS = 'SYSTEMCONFIGURATIONS',
	DYNAMICFIELDS = 'DYNAMICFIELDS',
	WORKFLOWS = 'WORKFLOWS',
	AUDIT_LOGS = 'AUDIT_LOGS',
	APPROVAL_TRAILS_LOGS = 'APPROVAL_TRAILS_LOGS',
}
export enum AppsSystemCofigsTabValEnum {
	INCENTXCONFIGURATIONS = 'INCENTXCONFIGURATIONS',
	ERPCONFIGURATIONS = 'ERPCONFIGURATIONS',
	DISPOSABLEEMAIL = 'DISPOSABLEEMAIL',
	IXSHIPPINGTYPES = 'IXSHIPPINGTYPES',
	IXCREDITCARDTYPES = 'IXCREDITCARDTYPES',
	IXDEVICEMAPPING = 'IXDEVICEMAPPING',
	IXAPPVERSION = 'IXAPPVERSION',
}

export enum ErpTypeValEnum {
	SAPB1_HANA_DIAPI = 'SAPB1_HANA_DIAPI',
	SAPB1_HANA = 'SAPB1_HANA',
	QUICKBOOKS = 'QUICKBOOKS',
	QUICKBOOKS_ONLINE = 'QUICKBOOKS_ONLINE',
	MDS = 'MDS',
	NETSUITE = 'NETSUITE',
	SAGE300 = 'SAGE300',
	EPICOR_PROPHET21 = 'EPICOR_PROPHET21',
	DYNAMICS_BUSINESS_CENTRAL = 'DYNAMICS_BUSINESS_CENTRAL',
}
export enum CrmTypeValEnum {
	SALESFORCE = 'SALESFORCE'
}
export enum ErpTypeLblEnum {
	SAPB1_HANA_DIAPI = 'SAP Business One DI API',
	SAPB1_HANA = 'SAP Business One HANA',
	QUICKBOOKS = 'QuickBooks',
	QUICKBOOKS_ONLINE = 'QuickBooks Online',
	MDS = 'MDS',
	NETSUITE = 'NetSuite',
	SAGE300 = 'Sage 300',
	EPICOR_PROPHET21 = 'Epicor Prophet 21',
	DYNAMICS_BUSINESS_CENTRAL = 'Dynamics 365 Business Central',
}

export enum IncludeInCommissionCalValEnum {
	UNPAID_INVOICES = 'unpaid_invoices',
	REBATES = 'rebates',
	BILLBACKS_CHARGEBACKS = 'billbacks_chargebacks',
	CASH_DISCOUNTS = 'cash_discounts',
	SHIPPING_COSTS = 'shipping_costs'
}

export enum SubscriptionPlansTabValEnum {
	PUBLIC_PLANS = 'PUBLIC_PLANS',
	CUSTOMISED_PLANS = 'CUSTOMISED_PLANS',
	SUBSCRIPTION_PLANS = 'SUBSCRIPTION_PLANS',
	MODULE_PERMISSIONS = 'MODULE_PERMISSIONS',
	USER_TYPE_CREATION = 'USER_TYPE_CREATION',
}

export enum SubscriptionPlansTabLblEnum {
	PUBLIC_PLANS = 'Public Plans',
	CUSTOMISED_PLANS = 'Customised Plans',
	SUBSCRIPTION_PLANS = 'Subscription Plans',
	MODULE_PERMISSIONS = 'Module Permissions',
	USER_TYPE_CREATION = 'User Type Creation',
}

export enum ServerEntityEnum {
	CUSTOMERS = 'customers',
	TIERS = 'tiers',
}

export enum SubscriptionPlanStatusesValEnum {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export enum SubscriptionPlanStatusesLblEnum {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
}

// upgrade subscriptions view status
export enum CalledFromEnum {
	CALLED_AS_TIRAL = 'CALLED_AS_TIRAL',
	CALLED_AS_TIRAL_EXPIRED = 'CALLED_AS_TIRAL_EXPIRED',
	CALLED_AS_UPDATE_PACKAGE = 'CALLED_AS_UPDATE_PACKAGE',
	CALLED_AS_OVERDUE_PAYMENT = 'CALLED_AS_OVERDUE_PAYMENT',
}


export enum CrmClientForEnum {
	LEADS = 'leads',
	PROSPECTS = 'prospects',
	CLIENTS = 'clients',
	TASKS = 'tasks'
}

export enum SalesForEnum {
	QUOTES = 'quotes',
	ORDERS = 'orders',
	INVOICES = 'invoices'
}

export enum SalesByEnum {
	PROSPECTSQUOTES = 'prospectsquotes',
	CLIENTSQUOTES = 'clientsquotes',
	CLIENTSORDERS = 'clientsorders',
	SALESQUOTES = 'salesquotes',
	SALESORDERS = 'salesorders',
	SALESINVOICES = 'salesinvoices'
}

export enum SideMenuForEnum {
	QUOTATIONS = 'quotes',
	ORDERS = 'salesorders',
	SHIPMENT = 'shipments',
	SALESINVOICES = 'salesinvoices',
	AR_CREDIT_MEMOS_LIST = 'ar-credit-memos-list',
	CHAGEBACK = 'chargebacklist',
	DASHBOARD = 'dashboard',
	LEAD = 'leads',
	PROSPECT = 'prospects',
	CLIENT = 'clients',
	TASKS = 'tasks',
	COMMISSION = 'commission',
	Product = 'products',
	PriceList = 'priceLists',
	Users = 'allusers',
	SupportUsers = 'supportusers',
	UserGroups = 'usergroups',
	Roles = 'rolesmanagement',
	EXTERNALSYSCONFIGS = 'externalsystemconfiguration',
	FISCALCALCULATIONOPTIONS = 'fiscalcalculationoptions',
	SYSTEMCONFIGURATIONS = 'systemconfigurations',
	DYNAMICFIELDS = 'dynamicfields',
	WORKFLOWS = 'workflows',
	VENDOR = 'vendors',
	BUY_GROUP = 'buyinggroups',
	TERRITORY = 'territory',
	END_USERS = 'endusers',
	CUSTOMERS = 'customers',
	PAYMENTS = 'payments',
	REBATES = 'rebates',
	ROYALTIES = 'royalties',
	END_USER_INVOICES_LIST = 'end-user-invoices-list',
	AUDIT_LOGS = 'audit_logs',
	APPROVAL_TRAILS_LOGS = 'approval_trails_logs',
}

export enum CrmDataEnum {
	VIEW_ALL = 'view_all',
	VIEW_OWN = 'view_own'
}

export enum ApPurchasesEnum {
	AP_INVOICES_TAB = 'AP_INVOICES_TAB',
	AP_CREDIT_MEMOS_TAB = 'AP_CREDIT_MEMOS_TAB',
	AP_PURCHASE_INVOICE_LIST = 'AP_PURCHASE_INVOICE_LIST',
	AP_VENDOR_PURCHASE_INVOICE_LIST = 'AP_VENDOR_PURCHASE_INVOICE_LIST',
	AP_COST_ACCOUNTING_TAB = 'AP_COST_ACCOUNTING_TAB',
	AP_REBATE_CREDIT_MEMOS_LIST = 'AP_REBATE_CREDIT_MEMOS_LIST',
	AP_CUSTOMER_360_CREDIT_MEMOS_LIST = 'AP_CUSTOMER_360_CREDIT_MEMOS_LIST',
}

export enum ApCreditMemoEnum {
	FROM_REBATE_CREDIT_MEMOS_LIST = 'FROM_REBATE_CREDIT_MEMOS_LIST',
	FROM_PURCHASE_AP_CREDIT_MEMO_LIST = 'FROM_PURCHASE_AP_CREDIT_MEMO_LIST',
}
export enum ArCreditMemoEnum {
	FROM_SALES_AR_CREDIT_MEMO_LIST = 'FROM_SALES_AR_CREDIT_MEMO_LIST',
	FROM_CLIENT_AR_CREDIT_MEMOS_LIST = 'FROM_CLIENT_AR_CREDIT_MEMOS_LIST',
	FROM_REBATE_AR_CREDIT_MEMOS_LIST = 'FROM_REBATE_AR_CREDIT_MEMOS_LIST',
	FROM_CUSTOMER_360_AR_CREDIT_MEMOS_LIST = 'FROM_CUSTOMER_360_AR_CREDIT_MEMOS_LIST',
	AR_CREDIT_MEMOS_LIST_FOR_CLIENT = 'AR_CREDIT_MEMOS_LIST_FOR_CLIENT',
	AR_CREDIT_MEMOS_LIST_FOR_SALES = 'AR_CREDIT_MEMOS_LIST_FOR_SALES',
}


export enum PaymentMethodsValEnum {
	CARD_NOX = 'cardknox',
	USA_EPAY = 'usaepay',
	AUTHORIZE_DOTNET = 'authorizedotnet',
}

export enum PaymentMethodsLblEnum {
	CARD_NOX = 'CardKnox',
	USA_EPAY = 'USAePay',
	AUTHORIZE_DOTNET = 'Authorize .Net',
}

export enum Customer360TilesEnum {
	PROFIT = 'PROFIT',
	REVENUE = 'REVENUE',
	VARIETY = 'VARIETY',
	VOLUME = 'VOLUME',
	MARGIN = 'MARGIN',
	REBATES = 'REBATES',
	CHARGEBACKS = 'CHARGEBACKS',
	INVOICE = 'INVOICE',
	ORDER = 'ORDER',
	AR_CREDITMEMO = 'AR_CREDITMEMO',
}

export enum Customer360TimePeriodValEnum {
	YEAR_TO_DATE = 'year_to_date',
	YEAR_OVER_YEAR = 'year_over_year',
}
export enum Customer360TimePeriodLblEnum {
	YEAR_TO_DATE = 'Year To Date',
	YEAR_TO_DATE_SHORTNAME = 'YTD',
	YEAR_OVER_YEAR = 'Year Over Year',
	YEAR_OVER_YEAR_SHORTNAME = 'YOY',
}

export enum RebateStatusLblEnum {
	DRAFT = 'Draft',
	SIGNATURE_REQUESTED = 'Request Signature',
	SIGNED = 'Signed',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	EXPIRED = 'Expired',
}

export enum RebateStatusValEnum {
	DRAFT = 'draft',
	SIGNATURE_REQUESTED = 'signature_requested',
	SIGNED = 'signed',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	EXPIRED = 'expired',
}

export enum RebateSettlementStatusValEnum {
	OPEN = 'Open',
	PROCESSING = 'Processing',
	SUCCESS = 'Success',
	ERROR = 'Error',
	FAILED = 'Failed',
}

export enum RebateSettlementStatusLblEnum {
	OPEN = 'Open',
	PROCESSING = 'Processing',
	SUCCESS = 'Success',
	ERROR = 'Error',
	FAILED = 'Failed',
}

export enum RebateApprovalStatusValEnum {
	DRAFT = 'draft',
	PENDING_APPROVAL = 'pending_approval',
	APPROVED = 'approved',
	REJECTED = 'rejected',
}

export enum RebateApprovalStatusLblEnum {
	DRAFT = 'Draft',
	PENDING_APPROVAL = 'Pending Approval',
	APPROVED = 'Approved',
	REJECTED = 'Rejected',
}

export enum ContractLifeCycleValEnum {
	ALWAYS_ON = 'always_on',
	ALWAYS_OFF = 'always_off',
	PER_CONTRACT_BASIS = 'per_contract_basis',
}
export enum ContractLifeCycleLblEnum {
	ALWAYS_ON = 'Always On',
	ALWAYS_OFF = 'Always Off',
	PER_CONTRACT_BASIS = 'Per Contract Basis',
}

export enum PaymentTypeValEnum {
	CREDIT_CARD = 'cc',
	ACH = 'ach',
}
export enum PaymentTypeLblEnum {
	CREDIT_CARD = 'Credit Card',
	ACH = 'ACH',
}

export enum SettlementPeriodsValEnum {
	WEEKLY = 'weekly',
	BIWEEKLY = 'biweekly',
	AUTOPAYMENT = 'autopayment',
	MONTHLY = 'monthly',
	QUARTERLY = 'quarterly',
	HALFYEARLY = 'halfYearly',
	YEARLY = 'yearly',
	CUSTOM = 'custom',
}

export enum SettlementPeriodsLblEnum {
	AUTOPAYMENT = 'Automatic/Per Invoice',
	MONTHLY = 'Monthly',
	QUARTERLY = 'Quarterly',
	HALFYEARLY = 'Bi-annually',
	YEARLY = 'Annually',
	CUSTOM = 'Custom'
}

export enum TerritoryByAddressValEnum {
	TERRITORY_BY_BILLING_ADDRESS = 'territory_by_billing_address',
	TERRITORY_BY_SHIPPING_ADDRESS = 'territory_by_shipping_address',
}

export enum TerritoryByAddressLblEnum {
	TERRITORY_BY_BILLING_ADDRESS = 'By Billing Address',
	TERRITORY_BY_SHIPPING_ADDRESS = 'By Shipping Address',
}

export enum Company360DashboardArCreditmemoValEnum {
	CURRENT_MONTH = 'current_month',
	LAST_MONTH = 'last_month',
	DATE_DOC_DATE = 'docdate',
	DATE_DUE_DATE = 'docduedate',
	DATE_DATE_PAID = 'doc_close_date',
	STATUS_ALL = 'all',
	STATUS_OPEN = 'open',
	STATUS_DRAFT = 'draft',
	STATUS_CLOSED = 'close',
	STATUS_CANCEL = 'cancel',
	STATUS_CANCELLATION = 'cancellation',
	TYPE_ALL = 'all',
	TYPE_SERVICE = 'service_type',
	TYPE_INVENTORY = 'inventory_type',
}

export enum Company360DashboardInvoiceValEnum {
	CURRENT_MONTH = 'current_month',
	LAST_MONTH = 'last_month',
	DATE_DOC_DATE = 'docdate',
	DATE_DUE_DATE = 'docduedate',
	DATE_DATE_PAID = 'doc_close_date',
	STATUS_ALL = 'all',
	STATUS_OPEN = 'open',
	STATUS_CLOSED = 'close',
	STATUS_CANCEL = 'cancel',
}

export enum Company360DashboardOrderValEnum {
	CURRENT_MONTH = 'current_month',
	LAST_MONTH = 'last_month',
	DATE_DOC_DATE = 'docdate',
	DATE_DUE_DATE = 'docduedate',
	DATE_DATE_PAID = 'doc_close_date',
	STATUS_ALL = 'all',
	STATUS_OPEN = 'open',
	STATUS_CLOSED = 'close',
}

export enum Company360DashboardQuoteValEnum {
	CURRENT_MONTH = 'current_month',
	LAST_MONTH = 'last_month',
	DATE_DOC_DATE = 'docdate',
	DATE_DUE_DATE = 'docduedate',
	DATE_DATE_PAID = 'doc_close_date',
	STATUS_ALL = 'all',
	STATUS_OPEN = 'open',
	STATUS_CLOSED = 'close',
}

export enum Company360DashboardArCreditmemoLblEnum {
	CURRENT_MONTH = 'Current Month',
	LAST_MONTH = 'Last Month',
	DATE_DOC_DATE = 'Creation Date',
	DATE_DUE_DATE = 'Due Date',
	DATE_DATE_PAID = 'Date Paid',
	STATUS_ALL = 'All Credit Memos',
	STATUS_DRAFT = 'Only Draft Credit Memos',
	STATUS_OPEN = 'Only Open Credit Memos',
	STATUS_CLOSED = 'Only Closed Credit Memos',
	STATUS_CANCEL = 'Only Cancelled Credit Memos',
	STATUS_CANCELLATION = 'Only Cancellation Credit Memos',
	TYPE_ALL = 'All',
	TYPE_SERVICE = 'Service Type',
	TYPE_INVENTORY = 'Inventory Type',
}

export enum Company360DashboardInvoiceLblEnum {
	CURRENT_MONTH = 'Current Month',
	LAST_MONTH = 'Last Month',
	DATE_DOC_DATE = 'Creation Date',
	DATE_DUE_DATE = 'Due Date',
	DATE_DATE_PAID = 'Date Paid',
	STATUS_ALL = 'All Invoices',
	STATUS_OPEN = 'Only Open Invoices',
	STATUS_CLOSED = 'Only Closed Invoices',
	STATUS_CANCEL = 'Only Cancelled Invoices',
}


export enum Company360DashboardOrderLblEnum {
	CURRENT_MONTH = 'Current Month',
	LAST_MONTH = 'Last Month',
	DATE_DOC_DATE = 'Creation Date',
	DATE_DUE_DATE = 'Due Date',
	DATE_DATE_PAID = 'Date Paid',
	STATUS_ALL = 'All Orders',
	STATUS_OPEN = 'Only Open Orders',
	STATUS_CLOSED = 'Only Closed Orders',
}

export enum Company360DashboardQuoteLblEnum {
	CURRENT_MONTH = 'Current Month',
	LAST_MONTH = 'Last Month',
	DATE_DOC_DATE = 'Creation Date',
	DATE_DUE_DATE = 'Due Date',
	DATE_DATE_PAID = 'Date Paid',
	STATUS_ALL = 'All Quotes',
	STATUS_OPEN = 'Only Open Quotes',
	STATUS_CLOSED = 'Only Closed Quotes',
}

export enum SalesListingOpenFromEnum {
	TILE_ORDER = 'order_tile',
	TILE_INVOICE = 'invoice_tile',
	TILE_AR_CREDITMEMO = 'ar_creditmemo_tile',
	SIDEMENU_INVOICE = 'sidemenu_invoice',
	SIDEMENU_ORDER = 'sidemenu_order',
	SIDEMENU_AR_CREDITMEMO = 'sidemenu_ar_creditmemo',
}

export enum AppStatesKeyEnum {
	CUSTOMER_360_DETAILS = 'CUSTOMER_360_DETAILS',
}

export enum Customer360TileSettingsValEnum {
	YEAR_TO_DATE = 'year_to_date',
	YEAR_OVER_YEAR = 'year_over_year',

	CURRENT_MONTH = 'current_month',
	LAST_MONTH = 'last_month',
	CUSTOM_RANGE = 'custom_range',

	DATE_DOC_DATE = 'docdate',
	DATE_DUE_DATE = 'docduedate',
	DATE_DATE_PAID = 'doc_close_date',

	STATUS_ALL = 'all',
	STATUS_OPEN = 'open',
	STATUS_DRAFT = 'draft',
	STATUS_CLOSED = 'close',
	STATUS_CANCELLED = 'cancel',
	STATUS_CANCELLATION = 'cancellation',

	TYPE_ALL = 'all',
	TYPE_SERVICE = 'service_type',
	TYPE_INVENTORY = 'inventory_type',
}

export enum Customer360TileSettingsLblEnum {
	YEAR_TO_DATE = 'Last Period',
	YEAR_OVER_YEAR = 'Same Period last year',
	YEAR_TO_DATE_SHORTNAME = 'YTD',
	YEAR_OVER_YEAR_SHORTNAME = 'YOY',

	CURRENT_MONTH = 'Current Month',
	LAST_MONTH = 'Last Month',
	CUSTOM_RANGE = 'Custom Range',

	DATE_DOC_DATE = 'Creation Date',
	DATE_DUE_DATE = 'Due Date',
	DATE_DATE_PAID = 'Date Paid',

	STATUS_ALL_INVOICE = 'All Invoices',
	STATUS_OPEN_INVOICE = 'Only Open Invoices',
	STATUS_CLOSED_INVOICE = 'Only Closed Invoices',
	STATUS_CANCELLED_INVOICE = 'Only Cancelled Invoices',

	STATUS_ALL_ORDER = 'All Orders',
	STATUS_OPEN_ORDER = 'Only Open Orders',
	STATUS_CLOSED_ORDER = 'Only Closed Orders',

	STATUS_ALL_AR_CREDITMEMO = 'All Credit Memos',
	STATUS_DRAFT_AR_CREDITMEMO = 'Only Draft Credit Memos',
	STATUS_OPEN_AR_CREDITMEMO = 'Only Open Credit Memos',
	STATUS_CLOSED_AR_CREDITMEMO = 'Only Closed Credit Memos',
	STATUS_CANCELLED_AR_CREDITMEMO = 'Only Cancelled Credit Memos',
	STATUS_CANCELLATION_AR_CREDITMEMO = 'Only Cancellation Credit Memos',

	TYPE_ALL = 'All',
	TYPE_SERVICE = 'Service Type',
	TYPE_INVENTORY = 'Inventory Type',
}

export enum DateRangeGlobalSettingValEnum {
	CURRENT_MONTH = 'current_month',
	LAST_MONTH = 'last_month',
	CALENDAR_YEAR_TO_DATE = 'calendar_year_to_date',
	FISCAL_YEAR_TO_DATE = 'fiscal_year_to_date',
	CUSTOM_DATE_RANGE = 'custom_date_range',
}

export enum DateRangeGlobalSettingLblEnum {
	CURRENT_MONTH = 'Current Month',
	LAST_MONTH = 'Last Month',
	CALENDAR_YEAR_TO_DATE = 'Calendar Year To Date',
	FISCAL_YEAR_TO_DATE = 'Year To Date',
	CUSTOM_DATE_RANGE = 'Custom Range',
}

export enum EndUserDetailsTabsValEnum {
	END_USER_360 = 'end_user_360',
	INVOICES = 'invoices',
	CREDIT_MEMOS = 'credit_memos',
	CHARGEBACKS = 'chargebacks',
	CUSTOMERS = 'customers',
	ADDRESSES = 'addresses',
	ROLE = 'role',
	REBATE_PAYOUT = 'rebate_payout',
	CUSTOM_FIELDS = 'custom_fields',
}

export enum EndUserDetailsTabsLblEnum {
	END_USER_360 = 'End User 360&#176',
	INVOICES = 'Invoices',
	CREDIT_MEMOS = 'Credit Memos',
	CHARGEBACKS = 'Chargebacks',
	CUSTOMERS = 'Customers',
	ADDRESSES = 'Addresses',
	ROLE = 'Role',
	REBATE_PAYOUT = 'Rebate Payout',
	CUSTOM_FIELDS = 'Custom Fields',
}

export enum EndUserAddressTypesValEnum {
	BILLTO = 'billto',
	SHIPTO = 'shipto'
}

export enum EndUserAddressTypesLblEnum {
	BILLTO = 'Billing Address',
	SHIPTO = 'Shipping Address'
}


export enum PricelistStatusLblEnum {
	DRAFT = 'Draft',
	PENDING_APPROVAL = 'Pending Approval',
	APPROVED = 'Approved',
	SIGNATURE_REQUESTED = 'Signature Requested',
	SIGNED = 'Signed',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	EXPIRED = 'Expired',
}

export enum PricelistStatusValEnum {
	DRAFT = 'draft',
	PENDING_APPROVAL = 'pending_approval',
	APPROVED = 'approved',
	SIGNATURE_REQUESTED = 'signature_requested',
	SIGNED = 'signed',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	EXPIRED = 'expired',
}

export enum MinMaxChargebackStatusValEnum {
	DRAFT = 'draft',
	PENDING_APPROVAL = 'pending_approval',
	APPROVED = 'approved',
	REJECTED = 'rejected',
}
export enum MinMaxChargebackStatusLblEnum {
	DRAFT = 'Draft',
	PENDING_APPROVAL = 'Pending Approval',
	APPROVED = 'Approved',
	REJECTED = 'Rejected',
}

export enum PopupHeightWidth {
	Width100 = 100,
	Height100 = 100,
	Width95 = 95,
	Height95 = 95,
	Width90 = 90,
	Height90 = 90,
	Width85 = 85,
	Width80 = 80,
	Width75 = 75,
	Width70 = 70,
	Width65 = 65,
	Width60 = 60,
	Width50 = 50,
	Width45 = 45,
	Width40 = 40,
	Width30 = 30,
	Width20 = 20,
	Height85 = 85,
	Height80 = 80,
	Height75 = 75,
	Height70 = 70,
	Height65 = 65,
	Height60 = 60,
	Height55 = 55,
	Height50 = 50,
	Height45 = 45,
	Height40 = 40,
	Height30 = 30,
	Height20 = 20
}

export enum CommissionSettlementReportSectionEnum {
	INVOICES = 'invoices',
	CUSTOMERS = 'customers',
	ITEMS = 'items',
	ORDERS = 'orders',
	CHANGE_ORDERS = 'change_orders',
	MISCELLANEOUS_DEDUCTION_RULES = 'miscellaneous_deduction_rules',
	EXTRA_COMMISSION_BASED_RULES = 'extra_commission_based_rules',
	AMORTIZED_PAYMENTS = 'amortized_payments',
	SERVICE_CREDIT_MEMOS = 'service_credit_memos',
	MODIFIED_INVOICES = 'modified_invoices',
}
export enum RoyaltiesSettlementReportSectionEnum {
	INVOICES = 'invoices',
	CUSTOMERS = 'customers',
	ITEMS = 'items',
	//ORDERS = 'orders',
	//CHANGE_ORDERS = 'change_orders',
	//MISCELLANEOUS_DEDUCTION_RULES = 'miscellaneous_deduction_rules',
	EXTRA_ROYALTIES_BASED_RULES = 'extraRoyaltiesBasedRules',
	AMORTIZED_PAYMENTS = 'amortized_payments',
	//SERVICE_CREDIT_MEMOS = 'service_credit_memos',
	AR_CREDIT_MEMOS = 'arcreditmemos',
}

export enum PriceListTypesValEnum {
	STANDARD = 'standardpricelists',
	GUIDE = 'guidepricelists',
	NHS = 'nhspricelists'
}

export enum PriceListTypesLblEnum {
	STANDARD = 'Standard Pricelist',
	GUIDE = 'Guide Pricelist',
	NHS = 'NHS Pricelist'
}

export enum SalesDocumentStatusesValEnum {
	OPEN = 'bost_Open',
	CLOSE = 'bost_Close',
	CANCEL = 'bost_Cancel',
	DELETED = 'deleted',
	DRAFT = 'draft',
	CANCELLATION = 'cancellation',
}

export enum SalesDocumentStatusesLblEnum {
	OPEN = 'Open',
	CLOSE = 'Closed',
	CANCEL = 'Canceled',
	DELETED = 'Deleted',
	DRAFT = 'Draft',
	CANCELLATION = 'Cancellation',
}

export enum ContractTypeValEnum {
	COMMISSIONCONTRACTS = 'commissioncontracts',
	BILLBACKCONTRACTS = 'billbackcontracts',
	ENDUSERSCHARGEBACKCONTRACTS = 'enduserschargebackcontracts',
	CHARGEBACKCONTRACTS = 'chargebackcontracts',
	MINMAXENDUSERSCHARGEBACKCONTRACTS = 'minmaxenduserschargebackcontracts',
}
export enum ApprovalTabsValEnum {
	APPROVALS_CHARGEBACKS = 'approvals_chargebacks',
	APPROVALS_PRICELISTS = 'approvals_pricelists',
	APPROVALS_REBATES_SETTLEMENTS = 'approvals_rebates_settlements',
	APPROVALS_MIN_MAX_CHARGEBACKS = 'approvals_min_max_chargebacks',
}

export enum StatementReportTypeEnum {
	ENDUSER_DISCOUNT_STATEMENT = 'enduser_discount_statement'
}

export enum DistributionChannelsCallFromViewEnum {
	DEFAULT = 'DEFAULT',
	HEADER = 'HEADER',
	ADD_EDIT_CRM = 'ADD_EDIT_CRM',
	ADD_EDIT_PRICELIST = 'ADD_EDIT_PRICELIST',
	ADD_EDIT_ORDER = 'ADD_EDIT_ORDER',
	ADD_EDIT_QUOTE = 'ADD_EDIT_QUOTE',
	ADD_EDIT_END_USERS = 'ADD_EDIT_END_USERS',
	MIN_MAX_CHARGEBACK_CONTRACT = 'MIN_MAX_CHARGEBACK_CONTRACT',
	COMMISSION_TIERBASE_CONTRACT = 'COMMISSION_TIERBASE_CONTRACT',
	ADD_EDIT_REBATE = 'ADD_EDIT_REBATE',
	ADD_EDIT_ROYALTY = 'ADD_EDIT_ROYALTY',
	ADD_EDIT_TPM = 'ADD_EDIT_TPM',
}

