import { Inject, Injectable } from '@angular/core';
import { DynamicScriptLoaderService } from '@app/services/dynamic-script-loader/dynamic-script-loader.service';
import { DOCUMENT } from '@angular/common';

declare var setAccount: any;
declare var getTokens: any;
declare var setIfieldStyle: any;
declare var addIfieldCallback: any;
declare var clearIfield: any;
declare var enableAutoFormatting: any;
declare var addIfieldKeyPressCallback: any;

@Injectable({
	providedIn: 'root'
})

export class CardNoxPaymentService {
	private iFieldsKey: string;
	private softwareName: string;
	private softwareVersion: string;
	private isValidCardNumber: string = 'ISREQUIRED';
	private isValidCvvNumber: string = 'ISREQUIRED';
	constructor(private _DynamicScriptLoaderService: DynamicScriptLoaderService,
		@Inject(DOCUMENT) private document: Document) {
		this.loadCardNoxJsScripts();
	}

	private loadCardNoxJsScripts() {
		// You can load multiple scripts by just providing the key as argument into load method of the service
		this._DynamicScriptLoaderService.load('card_knox').then((response: any) => {
			if (response.loaded) {
				setTimeout(() => {
					this.setCardNumberStyle();
					this.setCardCvvStyle();
				}, 1200);
			}
		}).catch(error => {
			// console.log(error);
		});
	}

	public setCardNoxAccountDetails() {
		try {
			setAccount(this.getIFieldsKey(), this.getSoftwareName(), this.getSoftwareVersion());
		} catch (e) {
			console.error('setAccount did not load from JS.');
		}
	}


	public getToken() {
		try {
			getTokens((success) => {
				const getPaymentFormEle: any = document.getElementById('payment-form');
				if (getPaymentFormEle) {
					getPaymentFormEle.submit();
				}
			}, () => { // onError
			}, 30000);		// 30 second timeout
		} catch (e) {
			console.error('getTokens did not load from JS.');
		}
	}

	public doAddFieldCallbackBlur() {
		try {
			addIfieldCallback('blur', (data) => {
				if (data.cardNumberIsEmpty) {
					this.setIsValidCardNumber('ISREQUIRED');
				}
				if (!data.cardNumberIsEmpty && (!data.cardNumberFormattedLength || data.cardNumberFormattedLength < 19)) {
					this.setIsValidCardNumber('INVALIDLENGTH');
				}
				if (data.cvvIsEmpty) {
					this.setIsValidCvvNumber('ISREQUIRED');
				}
				if (!data.cvvIsEmpty && (!data.cvvLength || data.cvvLength <= 0 || data.cvvLength <= 2)) {
					this.setIsValidCvvNumber('INVALIDLENGTH');
				}
				if (!data.cardNumberIsEmpty && data.cardNumberFormattedLength >= 19 && data.cardNumberFormattedLength <= 19) {
					this.setIsValidCardNumber('VALID');
				}
				if (!data.cvvIsEmpty && data.cvvLength >= 3) {
					this.setIsValidCvvNumber('VALID');
				}
				return data;
			});
		} catch (e) {
			console.error('addIfieldCallback did not load from JS.');
		}
	}

	public doAddIfieldKeyPressCallback() {
		try {
			addIfieldKeyPressCallback((data) => {
				return data;
			});
		} catch (e) {
			console.error('addIfieldKeyPressCallback did not load from JS.');
		}
	}

	public doEnableAutoFormatting() {
		try {
			enableAutoFormatting('-');
		} catch (e) {
			console.error('enableAutoFormatting did not load from JS.');
		}
	}

	public setCardNumberStyle() {
		const defaultStyle = {
			'background-color': '#fff',
			border: '1px solid #e5e5e5',
			width: '100%',
			height: '42px',
			padding: '9px 15px',
			'font-size': '15px',
			outline: 'none',
			'box-sizing': 'border-box'
		};
		try {
			setIfieldStyle('card-number', defaultStyle);
		} catch (e) {
			console.error('setIfieldStyle did not load from JS.');
		}
	}

	public setCardCvvStyle() {
		const defaultStyle = {
			'background-color': '#fff',
			border: '1px solid #e5e5e5',
			width: '100%',
			height: '42px',
			padding: '9px 15px',
			'font-size': '15px',
			outline: 'none',
			'box-sizing': 'border-box'
		};
		try {
			setIfieldStyle('cvv', defaultStyle);
		} catch (e) {
			console.error('setIfieldStyle did not load from JS.');
		}
	}

	public clearFields(fieldName: string) {
		try {
			clearIfield(fieldName);
		} catch (e) {
			console.error('setIfieldStyle did not load from JS.');
		}
	}

	public setIFieldsKey(val: string) {
		this.iFieldsKey = val;
	}
	public getIFieldsKey() {
		return this.iFieldsKey || '';
	}

	public setISoftwareName(val: string) {
		this.softwareName = val;
	}
	public getSoftwareName() {
		return this.softwareName || '';
	}

	public setISoftwareVersion(val: string) {
		this.softwareVersion = val;
	}
	public getSoftwareVersion() {
		return this.softwareVersion || '';
	}

	public setIsValidCardNumber(val: string) {
		this.isValidCardNumber = val;
	}
	public getIsValidCardNumber() {
		return this.isValidCardNumber || '';
	}

	public setIsValidCvvNumber(val: string) {
		this.isValidCvvNumber = val;
	}
	public getIsValidCvvNumber() {
		return this.isValidCvvNumber || '';
	}
}
