import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ADMIN_PORTAL } from '@app/constants-enums/constants';
import { CalledFromEnum } from '@app/constants-enums/enums';
import { AccountDetails, Login } from '@app/models/auth';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import * as moment from "moment";
import config from "devextreme/core/config";
import { locale } from 'devextreme/localization';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	loginUser: Login;
	// loginUser: {
	// 	user: string,
	// 	pwd: string,
	// 	token: string,
	// 	server_timezone: any,
	// 	server_date: any,
	// 	account_detail: {
	// 		access_rights: any[],
	// 		company_shortcode: string,
	// 		country_code: string,
	// 		email: string,
	// 		first_name: string,
	// 		fiscal_year_start: number,
	// 		is_company_verified: string,
	// 		last_name: string,
	// 		mobile: string,
	// 		role: string,
	// 		sub_accounts: any[],
	// 		app_settings: {
	// 			erp_host_url: string,
	// 			erp_type: string,
	// 			erp_user_db: string,
	// 			erp_user_name: string,
	// 			erp_user_password: any
	// 			twofa_pass_phrase: any
	// 			master_data_update_frequency: number,
	// 			transaction_update_frequency: number
	// 			inactive_customer_months: number,
	// 			include_in_commission_cal: string[],
	// 			erp_data_start_date: string,
	// 		}
	// 	}
	// }
	currentsidemenu: string;
	globalCurrency: string;
	globalCurrencySymbol: string;
	currencyLocale: string;
	decimalPointForCurrency: number;
	decimalPointCurrencyFormat: string;
	decimalPointForPercentage: number;
	decimalPointPercentageFormat: string;

	constructor(private _Router: Router,
		private _UserProfileService: UserProfileService) {
	}

	public doResponseForTwoFactor(outerResponse: any) {
		if (!this.loginUser) {
			this.loginUser = new Login();
		}
		if (!this.loginUser.account_detail) {
			this.loginUser['account_detail'] = new AccountDetails();
		}
		this.loginUser.account_detail = outerResponse.data.account_detail;

		if (this.loginUser.account_detail && this.loginUser.account_detail.expirydate && outerResponse.data.server_date) {
			const a = moment(this.loginUser.account_detail.expirydate, "YYYY-MM-DD");
			const b = moment(outerResponse.data.server_date, "YYYY-MM-DD");
			const remDays = a.diff(b, 'days');
			this.loginUser.account_detail.trial_remaining_days = remDays;
		}

		//As per discussed with Prasanna for now not show rebate status changes Date-17112021
		//this._LoginService.loginUser.account_detail.contract_lifecycle_management = ContractLifeCycleValEnum.ALWAYS_OFF;
		//End

		// tslint:disable-next-line: max-line-length tslint:disable-next-line: no-unused-expression
		this.loginUser.account_detail.sub_accounts = outerResponse.data.account_detail.sub_accounts === null || undefined || '' ? [] : outerResponse.data.account_detail.sub_accounts;
		this.loginUser.server_date = outerResponse.data.server_date;
		this.loginUser.server_timezone = outerResponse.data.server_timezone;
		this.loginUser.token = outerResponse.data.token.trim();
	}

	public doFinalResponseForLogin(response: any) {
		if (!this.loginUser.user) {
			this.loginUser.user = response.data.email;
		}
		const data = response.data;
		this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_UPDATE_PACKAGE);

		// #region for Default currency set use for all
		this.globalCurrency = this.loginUser.account_detail.app_settings.currency_code || 'USD';
		this.currencyLocale = this.loginUser.account_detail.app_settings.currency_language || 'en-US';
		this.decimalPointForCurrency = this.loginUser.account_detail.app_settings.decimal_point_currency || 2;

		this.globalCurrencySymbol = new Intl.NumberFormat(this.currencyLocale, {
			style: 'currency',
			currency: this.globalCurrency,
			minimumIntegerDigits: 1,
			minimumFractionDigits: this.decimalPointForCurrency,
			maximumFractionDigits: this.decimalPointForCurrency,
		}).formatToParts(1).find(x => x.type === "currency").value || '$';

		this.decimalPointCurrencyFormat = ('1.' + this.decimalPointForCurrency + '-' + this.decimalPointForCurrency).toString() || '1.2-2';
		this.decimalPointForPercentage = this.loginUser.account_detail.app_settings.decimal_point || 2;
		this.decimalPointPercentageFormat = ('1.' + this.decimalPointForPercentage + '-' + this.decimalPointForPercentage).toString() || '1.2-2';
		locale(this.currencyLocale);
		config({
			defaultUseCurrencyAccountingStyle: false,
			defaultCurrency: this.globalCurrency
		});
		// #endregion END Default currency set use for all

		let portal_type = '';
		try {
			portal_type = this.loginUser.account_detail.portal_type;
		} catch (e) {
			portal_type = '';
		}

		if (ADMIN_PORTAL === portal_type) {
			this._Router.navigate(['/adminportal/dashboard'], { replaceUrl: true });
		} else {
			if (this.loginUser && this.loginUser.account_detail) {
				this._Router.navigate(['/dashboard'], { replaceUrl: true });
			}
		}
	}

}
