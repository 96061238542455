export class BillBackChargebackContractModelUI {
	public ixcode: string;
	public guid: string;
	public percentOrDollar: string;
	public contractName: string;
	// public rate: string;
	// public settlementPeriod: string;
	public selectSupplier: string;
	public selectSupplierId: string;
	public selectCustomer: string;
	public selectCustomerId: string;
	public selectCustomerPriceListNum: string;

	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	// public settlementPeriodStart: string;
	// public settlementPeriodEnd: string;
	public billBackType: string;
	public is_active: boolean;
	public billBackTabData: BillBackTabData = new BillBackTabData();
	public chargeBackTabData: ChargeBackTabData = new ChargeBackTabData();
	public endUserChangeBackTabData: EndUserChangeBackTabData = new EndUserChangeBackTabData();
	public distributionChangeBackTabData: DistributionChangeBackTabData = new DistributionChangeBackTabData();
}

export class BillBackTabData {
	public customerTabData: any[] = [];
	public productsAndGroupTabData: any[] = [];
	public billBackDocumentsTabData: any[] = [];
}

export class ChargeBackTabData {
	public productsAndGroupTabData: any[] = [];
	public chargeBackDocumentsTabData: any[] = [];
}

export class EndUserChangeBackTabData {
	public productsAndGroupTabData: any[] = [];
	public chargeBackDocumentsTabData: any[] = [];
}

export class DistributionChangeBackTabData {
	public categoryTabData: any[] = [];
	public chargeBackClaimsTabData: any[] = [];
}

export class BillBackChargebackContractModelRequest {
	public ixcode: string;
	public guid: string;
	public contractName: string;
	// public rate: string;
	// public settlementPeriod: string;
	public selectSupplier: string;
	public selectSupplierId: string;
	public selectCustomer: string;
	public selectCustomerId: string;
	public selectCustomerPriceListNum: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	// public settlementPeriodStart: string;
	// public settlementPeriodEnd: string;
	public billBackType: string;
	public is_active: boolean;
	public clients: CustomerTabModel[] = [];
	public categories: CategoryTabModel[] = [];
	public items: ProductAndGroupTabModel[] = [];
	public chargebackClaims: ChargeClaimsTabModel[] = [];
	public chargeDocuments: ChargeDocumentsTabModel[] = [];
	public billbackDocuments: BillBackDocumentsTabModel[] = [];
}

export class CustomerTabModel {
	public selectCustomer: string;
	public selectCustomerId: string;
}

export class CategoryTabModel {
	public guid: number;
	public categoryId: number;
	public categoryname: string;
	public description: string;
	public rate: number;
	public percentage_or_amount: string; // value of percentage or amount
	public is_invoicebased: boolean;
	public is_itembased: boolean;
	public is_disputable: boolean;
	public is_recoverable: boolean;
}

export class ProductAndGroupTabModel {
	public selectProduct: string;
	public selectProductId: string;
	public selectCustomer: string;
	public selectCustomerId: string;
	public selectEndUsers: string;
	public selectEndUsersId: string;
	public sold: string;
	public claimed: number;
	public available: number;
	public rate: string;
	public discountedPrice: string;
}

export class ChargeClaimsTabModel {
	public guid: any;
	public contractGuid: string;
	public categoryId: number;
	public categoryName: string;
	public percentage_or_amount: string; // value of percentage or amount
	public rate: number;
	public date: any;
	public claimNo: string;
	public claimedQty: number;
	public invoiceQty: string;
	public invoiceNo: string;
	public selectProduct: any;
	public selectProductId: string;
	public expectedAmount: number;
	public claimedAmount: number;
	public status: string;
	public attachedDocumentData: any[];
}
export class ChargeDocumentsTabModel {
	public guid: string;
	public contractGuid: string;
	public documentURL: string;
	public documentOriginalName: string;
	public documentReferenceNumber: string;
	public documentDocumentDescription?: string;
	public documentDate: string;
	public claimedStatus: string;
	public claimedStatusReason: string;
	public claimedUserNote: string;
	public claimedItems: ClaimedItemsModel[] = [];
}

export class BillBackDocumentsTabModel {
	public guid: string;
	public contractGuid: string;
	public documentURL: string;
	public documentReferenceNumber: string;
	public documentDate: string;
	public documentTotal: string;
	public claimedStatus: string;
	public claimedStatusReason: string;
	public claimedUserNote: string;
	public claimedItems: ClaimedItemsModel[] = [];
}

export class ClaimedItemsModel {
	public itemCode: string;
	public itemName: string;
	public endUserCode: string;
	public endUserName: string;
	public claimed: number;
}

export class ClaimAttachedDocumentModel {
	public currentClaimElement: any;
	public categoriesList: any = [];
	constructor() {

	}
}