import { Injectable } from '@angular/core';
import { MSG_CONFIRMED_MESSAGE } from '@app/constants-enums/constants';
import { CustomDialogOptions, custom } from 'devextreme/ui/dialog';
export interface CustomAlertOptions extends CustomDialogOptions {
	subtitle?: string;
	subtitleHtml?: string;
	message?: string;
	messageHtml?: string;
	popupIcons?: string;
	popupIconsHtml?: string;
}
@Injectable({
	providedIn: 'root'
})
export class IxDxAlertService {

	constructor() {
	}

	public doShowAlert(messageHtml: string, ixCustomDialogOptions?: CustomDialogOptions): Promise<any> {
		let showTitle = true;
		let dragEnabled = true;
		if (ixCustomDialogOptions) {
			if (ixCustomDialogOptions.hasOwnProperty('showTitle')) {
				showTitle = ixCustomDialogOptions.showTitle;
			}
			if (ixCustomDialogOptions.hasOwnProperty('dragEnabled')) {
				dragEnabled = ixCustomDialogOptions.dragEnabled;
			}
		}
		return custom({
			title: ixCustomDialogOptions?.title || "Alert",
			showTitle: showTitle,
			dragEnabled: dragEnabled,
			messageHtml: ixCustomDialogOptions?.messageHtml || "<p class='customAlertText'>" + messageHtml + "</p>",
			buttons: ixCustomDialogOptions?.buttons || [{
				elementAttr: { class: 'customPrimaryBtn' },
				text: "Ok",
				type: "normal",
				stylingMode: "contained",
				onClick: (e) => {
					return true;
				}
			}]
		}).show();
	}

	public doShowDefaultConfirmAlert(ixCustomDialogOptions?: CustomAlertOptions): Promise<any> {

		let showTitle = true;
		if (ixCustomDialogOptions.hasOwnProperty('showTitle')) {
			showTitle = ixCustomDialogOptions.showTitle;
		}

		let dragEnabled = true;
		if (ixCustomDialogOptions.hasOwnProperty('dragEnabled')) {
			dragEnabled = ixCustomDialogOptions.dragEnabled;
		}

		let messageHtmlStr = '';
		if (ixCustomDialogOptions.popupIcons) {
			messageHtmlStr = '<div class="' + ixCustomDialogOptions.popupIcons + '"></div>';
		} else if (ixCustomDialogOptions.popupIconsHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomDialogOptions.popupIconsHtml;
		}

		if (ixCustomDialogOptions.subtitle) {
			messageHtmlStr = messageHtmlStr + '<h2 class="infoTitle">' + ixCustomDialogOptions.subtitle + '</h2>';
		} else if (ixCustomDialogOptions.subtitleHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomDialogOptions.subtitleHtml;
		}

		if (ixCustomDialogOptions.message) {
			messageHtmlStr = messageHtmlStr + '<p class="infoDescription">' + ixCustomDialogOptions.message + '</p>';
		} else if (ixCustomDialogOptions.messageHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomDialogOptions.messageHtml;
		}

		let listOfButtons: any[] = [
			{
				elementAttr: { class: 'customSecondaryBtn' },
				text: "Cancel",
				type: "normal",
				stylingMode: "contained",
				onClick: (e) => {
					return false;
				}
			},
			{
				elementAttr: { class: 'customPrimaryBtn' },
				text: "Yes",
				type: "normal",
				stylingMode: "contained",
				onClick: (e) => {
					return true;
				}
			}
		];

		const titleText = ixCustomDialogOptions.title ? ixCustomDialogOptions.title : "Custom Alert";
		return custom({
			title: titleText,
			showTitle: showTitle,
			dragEnabled: dragEnabled,
			messageHtml: messageHtmlStr,
			buttons: listOfButtons
		}).show();

	}

	public doShowDeleteConfirmAlert(ixCustomAlertOptions?: CustomAlertOptions): Promise<any> {
		let showTitle = false;
		if (ixCustomAlertOptions?.hasOwnProperty('showTitle')) {
			showTitle = ixCustomAlertOptions.showTitle;
		}

		let dragEnabled = true;
		if (ixCustomAlertOptions?.hasOwnProperty('dragEnabled')) {
			dragEnabled = ixCustomAlertOptions.dragEnabled;
		}

		let messageHtmlStr = '';
		if (ixCustomAlertOptions?.popupIcons) {
			messageHtmlStr = '<div class="' + ixCustomAlertOptions.popupIcons + '"></div>';
		} else if (ixCustomAlertOptions?.popupIconsHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomAlertOptions.popupIconsHtml;
		} else {
			messageHtmlStr = '<div class="deletePopupIcon"></div>';
		}

		if (ixCustomAlertOptions?.subtitle) {
			messageHtmlStr = messageHtmlStr + '<h2 class="infoTitle">' + ixCustomAlertOptions.subtitle + '</h2>';
		} else if (ixCustomAlertOptions?.subtitleHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomAlertOptions.subtitleHtml;
		} else {
			messageHtmlStr = messageHtmlStr + '<h2 class="infoTitle">Delete</h2>';
		}

		if (ixCustomAlertOptions?.message) {
			messageHtmlStr = messageHtmlStr + '<p class="infoDescription">' + ixCustomAlertOptions.message + '</p>';
		} else if (ixCustomAlertOptions?.messageHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomAlertOptions.messageHtml;
		} else {
			messageHtmlStr = messageHtmlStr + '<p class="infoDescription">' + MSG_CONFIRMED_MESSAGE + '</p>';
		}

		let listOfButtons: any[] = [
			{
				elementAttr: { class: 'customSecondaryBtn' },
				text: "Cancel",
				type: "normal",
				stylingMode: "contained",
				onClick: (e) => {
					return false;
				}
			},
			{
				elementAttr: { class: 'customPrimaryBtn' },
				text: "Ok",
				type: "normal",
				stylingMode: "contained",
				onClick: (e) => {
					return true;
				}
			}
		];
		if (ixCustomAlertOptions?.buttons && ixCustomAlertOptions?.buttons.length > 0) {
			listOfButtons = ixCustomAlertOptions.buttons;
		}
		const titleText = ixCustomAlertOptions?.title ? ixCustomAlertOptions.title : "Delete";

		return custom({
			title: titleText,
			showTitle: showTitle,
			messageHtml: messageHtmlStr,
			dragEnabled: dragEnabled,
			buttons: listOfButtons
		}).show();
	}

	public doShowCustomConfirmAlert(ixCustomDialogOptions?: CustomAlertOptions): Promise<any> {
		/* // You can add different 
		Class name : 
		 - addTopIcon for Add icon
		 - infoTopIcon for Info icon
		 - confirmationIcon for Confirmation icon
		 - deletePopupIcon for Delete icon
		*/
		let showTitle = true;
		if (ixCustomDialogOptions.hasOwnProperty('showTitle')) {
			showTitle = ixCustomDialogOptions.showTitle;
		}

		let dragEnabled = true;
		if (ixCustomDialogOptions.hasOwnProperty('dragEnabled')) {
			dragEnabled = ixCustomDialogOptions.dragEnabled;
		}

		let messageHtmlStr = '';
		if (ixCustomDialogOptions.popupIcons) {
			messageHtmlStr = '<div class="' + ixCustomDialogOptions.popupIcons + '"></div>';
		} else if (ixCustomDialogOptions.popupIconsHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomDialogOptions.popupIconsHtml;
		}

		if (ixCustomDialogOptions.subtitle) {
			messageHtmlStr = messageHtmlStr + '<h2 class="infoTitle">' + ixCustomDialogOptions.subtitle + '</h2>';
		} else if (ixCustomDialogOptions.subtitleHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomDialogOptions.subtitleHtml;
		}

		if (ixCustomDialogOptions.message) {
			messageHtmlStr = messageHtmlStr + '<p class="infoDescription">' + ixCustomDialogOptions.message + '</p>';
		} else if (ixCustomDialogOptions.messageHtml) {
			messageHtmlStr = messageHtmlStr + ixCustomDialogOptions.messageHtml;
		}

		let listOfButtons: any[] = [
			{
				elementAttr: { class: 'customSecondaryBtn' },
				text: "Cancel",
				type: "normal",
				stylingMode: "contained",
				onClick: (e) => {
					return false;
				}
			},
			{
				elementAttr: { class: 'customPrimaryBtn' },
				text: "Ok",
				type: "normal",
				stylingMode: "contained",
				onClick: (e) => {
					return true;
				}
			}
		];
		if (ixCustomDialogOptions.buttons && ixCustomDialogOptions.buttons.length > 0) {
			listOfButtons = ixCustomDialogOptions.buttons;
		}
		const titleText = ixCustomDialogOptions.title ? ixCustomDialogOptions.title : "Custom Alert";
		return custom({
			title: titleText,
			showTitle: showTitle,
			dragEnabled: dragEnabled,
			messageHtml: messageHtmlStr,
			buttons: listOfButtons
		}).show();
	}
}
