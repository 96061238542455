import { Component, OnInit, OnDestroy, Input, ElementRef, HostListener } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { ServerSections, ServerMethods } from '@app/constants-enums/constants';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CustomNumberDecimalSuffixPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';

@Component({
	selector: 'app-rebate-total-tiles',
	templateUrl: './rebate-total-tiles.component.html',
	styleUrls: ['./rebate-total-tiles.component.css']
})
export class RebateTotalTilesComponent implements OnInit, OnDestroy {
	public globalCurrency: string;
	public rebateDueForTheMonth: number;
	public resourcesLoaded: boolean = false;
	public currentMonthName: string;
	private getRebateTotalSbsn: Subscription;
	public isShowFullValue: boolean = false;
	public getLastCharacterFromSortValue: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _DashboardService: DashboardService,
		private _GlobalStateService: GlobalStateService,
		private _ElementRef: ElementRef,
	) {

	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this._ElementRef.nativeElement.contains(event.target)) {
			// click on inside 
		} else {
			this.isShowFullValue = false; // click on outside 
		}
	}

	ngOnInit() {
		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.currentMonthName = this._DashboardService.getCurrentMonthName();
		this.getRebateTotal();
		this._GlobalStateService.subscribe('REFRESH_DASHBOARD_REBATE_TOTAL_TILES_EVENT', response => {
			if (response) {
				this.getRebateTotal();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.getRebateTotalSbsn) {
			this.getRebateTotalSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('REFRESH_DASHBOARD_REBATE_TOTAL_TILES_EVENT');
	}

	private getRebateTotal() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_DASHBOARD_DATA);
		formData.append('section', ServerSections.REBATE_TILE);
		if (this.getRebateTotalSbsn) {
			this.getRebateTotalSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getRebateTotalSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this.resourcesLoaded = false;
				if (response.flag) {
					if (response.data.tiles) {
						this.rebateDueForTheMonth = response.data.tiles.rebate_due_for_the_month || 0;
						const getStringValue = new CustomNumberDecimalSuffixPipe().transform(this.rebateDueForTheMonth, 2).toString();
						this.getLastCharacterFromSortValue = '';
						if (/^[a-zA-Z]+$/.test(getStringValue.substr(getStringValue.length - 1))) {
							this.getLastCharacterFromSortValue = getStringValue.substr(getStringValue.length - 1);
						}
					}
				}
			}, error: (error) => {
				this.resourcesLoaded = false;
				this.rebateDueForTheMonth = 0;
			}
		});
	}

	public tilesClick(clickFor: string) {
		this._DashboardService.tilesClick(clickFor);
	}

	public doClickToShowFullValue(event) {
		if (this.rebateDueForTheMonth <= 0) {
			return;
		}
		if (this.rebateDueForTheMonth > 0 && this.rebateDueForTheMonth < 1000) {
			return;
		}
		event.stopPropagation();
		this.isShowFullValue = true;
	}
}