import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
	selector: 'app-eucc-pricelist-unmatched-items-dialog',
	templateUrl: './eucc-pricelist-unmatched-items-dialog.component.html',
	styleUrls: ['./eucc-pricelist-unmatched-items-dialog.component.css']
})
export class EuccPricelistUnmatchedItemsDialogComponent implements OnInit {
	@ViewChild("endUserPriceListMismatchDataGridContainer", {}) endUserPriceListMismatchDataGridContainer: DxDataGridComponent;
	// public unMatchedItems: any[] = [];
	public endUserPriceListMismatchDataSource: any;
	public isShowPopup: boolean = true;
	@Input() pricelistUnmatchedItemsProps: any;
	@Output() closePricelistUnmatchedItemsPopup = new EventEmitter<any>();
	constructor(
	) { }

	ngOnInit() {
		this.endUserPriceListMismatchDataSource = this.pricelistUnmatchedItemsProps.unMatchedItems;
	}

	public doHiddenPopop(e) {
		this.isShowPopup = false;
	}

}
