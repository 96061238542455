<div class="rightBar">
	<div class="rightBlock">
		<div class="contentRow cf">
			<div class="borderBox cf">
				<div class="tableOut">
					<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="commissionContractDataSource"
						[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
						[filterSyncEnabled]="true" [filterValue]="filterValue" [dateSerializationFormat]="'yyyy-MM-dd'"
						[remoteOperations]="{ groupPaging: true }" [columnAutoWidth]="true" [allowColumnReordering]="true"
						[width]="'100%'" (onToolbarPreparing)="doToolbarPreparing($event)"
						(onRowClick)="editCommissionContract($event.data);" class="cellColor hoverClr hideFilterPanelInx"
						(onCellClick)="doCellClickDetails($event)">
						<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
						<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
						<dxo-pager [visible]="true" [showPageSizeSelector]="true"
							[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
						</dxo-pager>
						<dxo-filter-row [visible]="true"></dxo-filter-row>
						<dxo-filter-panel [visible]="true"></dxo-filter-panel>
						<dxo-header-filter [visible]="true"></dxo-header-filter>
						<dxo-sorting mode="multiple"></dxo-sorting>
						<dxo-filter-row [visible]="true"></dxo-filter-row>
						<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
						<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadCommissionAgreementsState"
							[customSave]="doSaveCommissionAgreementsState" [storageKey]="stateStorageKey">
						</dxo-state-storing>
						<div *dxTemplate="let data of 'templateFilterText'">
							<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
								<span>{{filterText}}</span>
							</div>
						</div>
						<div *dxTemplate="let data of 'templateUpdateCurrentSettlementBtn'">
							<dx-button class="secondaryBtn toolbarbtnix" type="normal" stylingMode="contained"
								(onClick)="doUpdateCurrentSettlementPeriods()" [useSubmitBehavior]="false">
								<div *dxTemplate="let data of 'content'">
									<dx-load-indicator height="25" width="25" class="button-indicator"
										[elementAttr]="{ class: 'button-loader-indicator-blue' }"
										[visible]="isShowLoaderIndicator"></dx-load-indicator>
									<span class="dx-button-text"
										[class]="{'mrgL10':isShowLoaderIndicator}">{{updateSettlementPeriodBtnText}}</span>
								</div>
							</dx-button>
						</div>
						<div *dxTemplate="let data of 'templateActionBtns'">
							<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
								[showArrowIcon]="false" text="Action"
								class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
								[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
								icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
								(onItemClick)="doActionsBtnItemClick($event)"
								[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
						</div>

						<dxi-column dataField="contractName" caption="Contract Name" dataType="string"
							[allowHeaderFiltering]="false">
						</dxi-column>

						<dxi-column dataField="salesperson_name" caption="Sales Person" dataType="string" sortOrder="asc"
							sortIndex="1" [allowFiltering]="true" [allowHeaderFiltering]="false">
						</dxi-column>

						<dxi-column dataField="settlementPeriod" alignment="center" caption="Settlement period"
							dataType="string" cellTemplate="cellTemplateSettlementPeriod">
							<dxo-header-filter [dataSource]="listOfSettlementPeriodHeaderFilter">
							</dxo-header-filter>
							<div *dxTemplate="let element of 'cellTemplateSettlementPeriod'">
								{{element.data.settlementPeriod || '' |filterFromList:listOfSettlementPeriodHeaderFilter:
								'value'
								: 'text'}}
							</div>
						</dxi-column>

						<dxi-column dataField="startDate" alignment="center" caption="Contract Start Date" dataType="date"
							format="yyyy-MM-dd" cellTemplate="cellTemplateStartDate" [allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateStartDate'">
								{{ (element.data.startDate !== null && element.data.startDate !== '' &&
								element.data.startDate
								!== undefined && element.data.startDate !== 'Invalid date') ?
								(element.data.startDate | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>

						<dxi-column dataField="endDate" alignment="center" caption="Contract End Date" dataType="date"
							format="yyyy-MM-dd" cellTemplate="cellTemplateEndDate" [allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateEndDate'">
								{{ (element.data.endDate !== null && element.data.endDate !== '' &&
								element.data.endDate !==
								undefined && element.data.endDate !== 'Invalid date') ?
								(element.data.endDate | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>
						<dxi-column dataField="settlementPeriodStart" alignment="center" caption="Open Settlement Start Date"
							dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementStartDate"
							[allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateOpenSettlementStartDate'">
								{{ (element.data.settlementPeriodStart !== null && element.data.settlementPeriodStart !== '' &&
								element.data.settlementPeriodStart
								!== undefined && element.data.settlementPeriodStart !== 'Invalid date') ?
								(element.data.settlementPeriodStart | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>

						<dxi-column dataField="settlementPeriodEnd" alignment="center" caption="Open Settlement End Date"
							dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementEndDate"
							[allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateOpenSettlementEndDate'">
								{{ (element.data.settlementPeriodEnd !== null && element.data.settlementPeriodEnd !== '' &&
								element.data.settlementPeriodEnd !==
								undefined && element.data.settlementPeriodEnd !== 'Invalid date') ?
								(element.data.settlementPeriodEnd | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>

						<dxi-column dataField="current_settlement_commission_total" caption="Settlement Amount"
							dataType="number" [allowHeaderFiltering]="false" [allowFiltering]="false"
							[calculateDisplayValue]="doCurrencyCalculateCurrentSettlementCommissionTotal">
						</dxi-column>

						<!-- <dxi-column dataField="commissionPercent" alignment="center" caption=" Commission (%)"
							dataType="number" [allowHeaderFiltering]="false">
						</dxi-column> -->

						<dxi-column dataField="is_active" [allowFiltering]="false" [allowHeaderFiltering]="true"
							[allowHeaderFiltering]="true" alignment="center" caption="Status" dataType="string"
							cellTemplate="cellTemplateStatus" [visible]="isShowAction">
							<dxo-header-filter [dataSource]="listOfStatusHeaderFilter">
							</dxo-header-filter>
							<div *dxTemplate="let element of 'cellTemplateStatus'">
								<dx-drop-down-button [splitButton]="false"
									[ngClass]="getClassNameForStatusField(element.data.is_active)" [useSelectMode]="false"
									[text]="element.data.is_active ? 'ACTIVE' : 'INACTIVE'" stylingMode="contained"
									class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign" [items]="listOfStatuses"
									[wrapItemText]="true" [useSelectMode]="true" displayExpr="label" keyExpr="value"
									(onButtonClick)="getListOfStatus(element.data)"
									(onItemClick)="changedCommissionContractStatusAction(element.data.guid,!element.data.is_active)"
									[width]="'100%'">
								</dx-drop-down-button>
							</div>
						</dxi-column>

						<dxi-column caption="Action" [allowHiding]="false" [fixed]="true" fixedPosition="right"
							alignment="center" dataType="string" cellTemplate="cellTemplateAction" [allowExporting]="false"
							[visible]="isShowAction" [allowFiltering]="false" [allowHeaderFiltering]="false"
							[allowSorting]="false" [allowResizing]="false" [width]="75">
							<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
								<!-- <a matTooltip="Edit" (click)="editCommissionContract(element.data);$event.stopPropagation();">
									<span class="icon-editdxtable"></span>
								</a> -->
								<a id="copyContract_{{element.rowIndex + 1}}"
									(click)="copyCommissionContract(element.data);$event.stopPropagation();">
									<span class="icon-copydxtable"></span>
								</a>
								<dx-tooltip target="#copyContract_{{element.rowIndex + 1}}" showEvent="dxhoverstart"
									hideEvent="dxhoverend" position="bottom">
									<div *dxTemplate="let data = data of 'content'">
										Copy
									</div>
								</dx-tooltip>
							</div>

						</dxi-column>

						<dxo-summary *ngIf="!enable_other_currencies">
							<dxi-total-item column="current_settlement_commission_total" summaryType="sum" displayFormat="{0}">
								<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-value-format>
							</dxi-total-item>
						</dxo-summary>
					</dx-data-grid>
				</div>
			</div>
		</div>
	</div>
</div>

<app-close-multiple-settlement-dx-dialog *ngIf="isShowMultiSettlementPopUp"
	[closeMultipleSettlementProp]="closeMultipleSettlementProp"
	(closeMultipalSettlementPopUp)="doCloseMultipleSettlementPopUp($event)">
</app-close-multiple-settlement-dx-dialog>