import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { TwoFactorAuthenticationComponent } from './auth/two-factor-authentication/two-factor-authentication.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ForgotPasswordExtendedComponent } from './auth/forgot-password-extended/forgot-password-extended.component';
import { VerificationEmailComponent } from './auth/verification-email/verification-email.component';


const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
	},
	{
		path: 'adminportal',
		loadChildren: () => import('./adminportal/adminportal.module').then(m => m.AdminportalModule)
	},
	{
		path: 'auth',
		component: AuthComponent,
		children: [
			{
				path: '',
				component: LoginComponent,
			},
			{
				path: 'login',
				component: LoginComponent,
			},
			{
				path: 'forgotpassword',
				component: ForgotPasswordExtendedComponent
			},
			{
				path: 'verificationemail',
				component: VerificationEmailComponent
			},
			{
				path: 'twofactorauthentication',
				component: TwoFactorAuthenticationComponent,
				canActivate: [AuthGuardService]
			},
		],
	},

	{ path: '', redirectTo: 'auth/login', pathMatch: 'full' },
	{
		path: '**',
		redirectTo: 'auth/login'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
