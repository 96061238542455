<app-header (removeEmailVarifiedSection)="doRemoveEmailVarifiedSection($event)"></app-header>
<app-sidemenu></app-sidemenu>

<div id="content" [ngClass]="isRemoveEmailVarifiedSection ? 'removeEmailVarified' :''">
	<!-- <div class="content"> -->
	<app-ng-dynamic-breadcrumb [bgColor]="'#0000'" [fontSize]="'16px'" [fontColor]="'#6e6aaf'" [lastLinkColor]="'#555'"
		[symbol]="' &#10095; '" class=" ix-dynamic-breadcrumb"></app-ng-dynamic-breadcrumb>
	<router-outlet></router-outlet>
	<!-- </div> -->
</div>

<app-subscription-upgrade-dialog *ngIf="(isShowSubscriptionUpgradePopup && !is_staging_environment)"
	[subscriptionUpgradeProps]="subscriptionUpgradeProps"
	(closeSubscriptionUpgradePopup)="doCloseSubscriptionUpgradePopup($event)"></app-subscription-upgrade-dialog>

<app-overdue-payment-dialog *ngIf="isShowInvoiceOverduePaymentPopup"
	(closeInvoiceOverduePaymentPopup)="doCloseInvoiceOverduePaymentPopup($event)"></app-overdue-payment-dialog>