import { ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import * as $ from 'jquery';
import * as _ from 'underscore';
import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, SimpleChanges } from '@angular/core';
import { CommissionContractModelUI, RuleTabModel } from '@app/models/commission-contract.model';
import { ToastrService } from 'ngx-toastr';
import { Guid } from '@app/models/guid';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoginService } from '@app/services/login.service';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { environment } from '@environments/environment';
declare const ExcelJS: ExcelJS;
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Subscription } from 'rxjs';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyOptions } from '@app/models/common.model';

@Component({
	selector: 'app-commission-tierbase-rules-tab',
	templateUrl: './commission-tierbase-rules-tab.component.html',
	styleUrls: ['./commission-tierbase-rules-tab.component.css'],
	providers: [
		CustomCurrencyPipe,
		CustomNumberNoRoundingPipe
	]
})
export class CommissionTierbaseRulesTabComponent implements OnInit, AfterContentChecked, OnDestroy {
	public guid = new Guid();
	@Input() commissionDetailsProps: any;
	@Input() ruleTabList: any[] = [];
	@Input() exclusionTabList: any[] = [];
	@Input() isCurrentTabPermission: string;
	@Output() saveCommissionRulesEvent = new EventEmitter<string>();
	@ViewChild('rulesListRulesTabDataGridRef') rulesListRulesTabDataGridRef: DxDataGridComponent;
	@ViewChild('ruleBonusTypeSelectBoxRef') ruleBonusTypeSelectBoxRef: DxSelectBoxComponent;
	@ViewChild('conditionSelectBoxRef') conditionSelectBoxRef: DxSelectBoxComponent;
	@ViewChild('criterionSelectBoxRef') criterionSelectBoxRef: DxSelectBoxComponent;
	//Rule Customer Drop Down list Object
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	public chkAllCustomer: boolean = false;
	public chkByTransaction: boolean = false;
	// public isViewAllCustomerChk: boolean = false;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public arrSelectedCustomerListTagBox: any[] = [];
	public customerGridBoxColumns: any;
	public arrSelectedCustomerList: any = [];
	public lengthSelectedCustomers: any;
	public selectedCustomerData: any = [];
	public itemDataSource: any;
	public itemGridBoxValue: any[] = [];
	public itemGridBoxColumns: any;
	public arrSelectedItemList: any = [];
	public arrSelectedItemListTagBox: any[] = [];
	public lengthSelectedItems: any;
	public chkAllItem: boolean = false;
	public selectedItemData: any = [];
	public editCommissionGuid: string;
	public chkAddOrUpdateRule: string = ''; // use for stop rule object overright on edit time
	public btnRulesAdd: string = 'Add';
	public rulesAddOrEditIndex: string = '-1';
	public ruleTabModel = new RuleTabModel();
	public isViewIERuleRuleRate: boolean = true;
	public isViewBounsAmountCriterion: boolean = true;
	public isViewRateRuleCriterion: boolean = true;
	public isViewBonusRateCriterion: boolean = true;
	public listOfRuleBonusTypes: any[] = [];
	public listOfInclusionExclusionRuleConditions: any[] = [];
	public listOfRateRuleConditions: any[] = [];
	public listOfBonusRateConditions: any[] = [];
	public listOfBonusAmountConditions: any[] = [];
	public listOfInclusionExclusionRuleCriterions: any[] = [];
	public listOfExclusionsForBuyingGroup: any[] = [];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	public textRuleBonusType: string;
	public textCondition: string;
	public textCriterion: string;
	private dataOutReqSubscription: Subscription;
	public isProfiltOnDocumentHeaderEntityUdfField: boolean = false;
	public listOfFeatureCustomFields: any[] = [];
	public listOfMasterCriterions: any[] = [
		{
			value: 'billbacksChargebacks',
			text: 'Billbacks/Chargebacks'
		},
		{
			value: 'unpaidInvoices',
			text: 'Unpaid Invoices'
		},
		{
			value: 'rebates',
			text: 'Rebates'
		},
		{
			value: 'cashDiscounts',
			text: 'Cash Discounts'
		},
		{
			value: 'partialpaidInvoices',
			text: 'Partially Paid Invoices'
		},
		{
			value: 'vendorsponsoredpromo',
			text: 'Vendor Sponsored Promo'
		},
		// {
		// 	value: 'numberOfDaysLate',
		// 	text: 'Number Of Days Late'
		// }
		// {
		// 	value: 'shippingCosts',
		// 	text: 'Shipping Costs'
		// },
	];
	private erpType: string;
	public isEnabledRulesDatagrid: boolean = true;
	public classesDataSource: any;
	public chkAllClasses: boolean = false;
	public lengthSelectedClasses: any;
	public arrSelectedClassesList: any = [];
	public arrSelectedClassesListTagBox: any[] = [];
	public classesGridBoxValue: any[] = [];
	public classesGridBoxColumns: any;
	public enable_classification_for_commision: boolean;
	public selectedClassData: any = [];
	@ViewChild("classesDataGrid", {}) classesDataGrid: DxDataGridComponent;
	public globalCurrencySymbol: string;
	public commissionDetails: any = new CommissionContractModelUI();
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();

	constructor(private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _IxDxAlertService: IxDxAlertService,
		private _CommissionService: CommissionService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _ChangeDetectorRef: ChangeDetectorRef) {

		this.doDisplayExprItemsText = this.doDisplayExprItemsText.bind(this);
		this.doDisplayExprCustomerText = this.doDisplayExprCustomerText.bind(this);
		this.doDisplayExprClassesText = this.doDisplayExprClassesText.bind(this);
	}

	ngOnInit() {
		this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		// check Is QB User (no need to view code of QB user)
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision || false;
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;
		} catch (e) {
			this.erpType = '';
			this.enable_classification_for_commision = false;
			this.enable_profit_on_document_header = false;
		}

		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.customerGridBoxColumns = [
				{ dataField: 'guid', caption: 'Guid', visible: false },
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
			this.isQBErpUser = true;
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
			this.customerGridBoxColumns = [
				{ dataField: 'guid', caption: 'Guid', visible: false },
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];

			this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erpType === ErpTypeValEnum.NETSUITE) {
			if (this.enable_classification_for_commision) {
				this.classesGridBoxColumns = [
					{ dataField: 'guid', caption: 'Guid', visible: false },
					{ dataField: 'id', caption: 'Id', visible: false },
					{ dataField: 'name', caption: 'Classes' }
				];
			}
			this.customerGridBoxColumns = [
				{ dataField: 'guid', caption: 'Guid', visible: false },
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			this.customerGridBoxColumns = [
				{ dataField: 'guid', caption: 'Guid', visible: false },
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}

		this.loadCustomerDataList();
		this.loadItemDataList();
		this.loadClassesDataList();
		this.clearRulesAgreementTabDetails();
		this.listOfRuleBonusTypes = this._CommissionService.listOfRuleBonusTypes;
		this.listOfInclusionExclusionRuleConditions = this._CommissionService.listOfInclusionExclusionRuleConditions;
		this.listOfRateRuleConditions = this._CommissionService.listOfRateRuleConditions;
		this.listOfBonusRateConditions = this._CommissionService.listOfBonusRateConditions;
		this.listOfBonusAmountConditions = this._CommissionService.listOfBonusAmountConditions;
		this.listOfInclusionExclusionRuleCriterions = this._CommissionService.listOfInclusionExclusionRuleCriterions;
		this.listOfExclusionsForBuyingGroup = this._CommissionService.listOfExclusionsForBuyingGroup;
		this.editCommissionGuid = this._CommissionService.editCommissionGuid;
		if (this.enable_profit_on_document_header) {
			this.doGetProfitOnDocumentUDFEntityData();
		}
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
	}
	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['commissionDetailsProps']) {
			const commissionDetailsProps = _SimpleChanges['commissionDetailsProps'].currentValue;
			this.commissionDetails = commissionDetailsProps;
		}
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}
	ngAfterContentChecked(): void {
		this._ChangeDetectorRef.detectChanges();
	}
	clearRulesAgreementTabDetails() {
		this.btnRulesAdd = 'Add';
		this.rulesAddOrEditIndex = '-1';
		this.ruleTabModel.ruleBonusType = 'inclusionExclusionRule';
		this.ruleTabModel.condition = 'include';
		this.ruleTabModel.criterion = 'unpaidInvoices';
		this.ruleTabModel.ruleRate = 'N/A';
		this.ruleTabModel.guid = '';
		if (this.itemDataGrid) {
			this.itemDataGrid.instance.refresh();
			this.itemDataGrid.instance.clearFilter();
			this.itemDataGrid.instance.clearSelection();
		}
		if (this.customerDataGrid) {
			this.customerDataGrid.instance.refresh();
			this.customerDataGrid.instance.clearFilter();
			this.customerDataGrid.instance.clearSelection();
		}
		if (this.classesDataGrid) {
			this.classesDataGrid.instance.refresh();
			this.classesDataGrid.instance.clearFilter();
			this.classesDataGrid.instance.clearSelection();
		}
	}

	ruleBonusTypeChange(ruleBonusType) {
		if (this.chkAddOrUpdateRule == '') {
			if (ruleBonusType === 'inclusionExclusionRule') {
				this.ruleTabModel.ruleBonusType = 'inclusionExclusionRule';
				this.ruleTabModel.condition = 'include';
				this.ruleTabModel.criterion = 'unpaidInvoices';
				this.ruleTabModel.ruleRate = 'N/A';
			} else if (ruleBonusType === 'rateRule') {
				this.ruleTabModel.ruleBonusType = 'rateRule';
				this.ruleTabModel.condition = 'breakUnitsOfMeasure';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';
			} else if (ruleBonusType === 'bonusAmount') {
				this.ruleTabModel.ruleBonusType = 'bonusAmount';
				this.ruleTabModel.condition = 'newAccount';
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';
			} else if (ruleBonusType === 'bonusRate') {
				this.ruleTabModel.ruleBonusType = 'bonusRate';
				if (this.isProfiltOnDocumentHeaderEntityUdfField) {
					this.ruleTabModel.condition = 'overListRate';
				} else {
					this.ruleTabModel.condition = 'newAccount';
				}
				this.ruleTabModel.criterion = 'N/A';
				this.ruleTabModel.ruleRate = '';

			}
			if (ruleBonusType !== 'inclusionExclusionRule') {
				this.conditionChange(this.ruleTabModel.condition);
			}
			if (ruleBonusType === 'inclusionExclusionRule') {
				this.criterionChange(this.ruleTabModel.criterion);
			}
		}
	}

	public doChangeConditionsDrpDwn(event: any) {
		this.doSetCustomFieldsCriteria();
		if (this.ruleTabModel.condition === 'excludeLateInvoice') {
			this.isViewIERuleRuleRate = true;
			if (this.chkAddOrUpdateRule == '') {
				this.ruleTabModel.criterion = '';
				this.ruleTabModel.ruleRate = 'N/A';
			}
		}
	}

	private doSetCustomFieldsCriteria() {
		const cardFinanceCostObj = this.listOfFeatureCustomFields.find(cf => cf.ix_custom_field === 'card_finance_cost');
		const cardFinanceCost2Obj = this.listOfFeatureCustomFields.find(cf => cf.ix_custom_field === 'card_finance_cost_2');
		const bonusPercentage1Obj = this.listOfFeatureCustomFields.find(cf => cf.ix_custom_field === 'bonus_percentage_1');
		let isCardFinanceCostIndex = -1;
		if (cardFinanceCostObj && cardFinanceCostObj.ix_custom_field) {
			isCardFinanceCostIndex = this.listOfInclusionExclusionRuleCriterions.findIndex(c => c.value === cardFinanceCostObj.ix_custom_field);
		}
		let isCardFinanceCost2Index = -1;
		if (cardFinanceCost2Obj && cardFinanceCost2Obj.ix_custom_field) {
			isCardFinanceCost2Index = this.listOfInclusionExclusionRuleCriterions.findIndex(c => c.value === cardFinanceCost2Obj.ix_custom_field);
		}
		let isBonusPercentage1Index = -1;
		if (bonusPercentage1Obj && bonusPercentage1Obj.ix_custom_field) {
			isBonusPercentage1Index = this.listOfInclusionExclusionRuleCriterions.findIndex(c => c.value === bonusPercentage1Obj.ix_custom_field);
		}

		if (this.ruleTabModel.condition === 'exclude') {
			if (this.enable_profit_on_document_header) {
				if (isCardFinanceCostIndex === -1) {
					this.listOfInclusionExclusionRuleCriterions.push({ value: cardFinanceCostObj.ix_custom_field, text: cardFinanceCostObj.custom_field_title ? cardFinanceCostObj.custom_field_title : cardFinanceCostObj.ix_custom_field_title })
				}
				if (isCardFinanceCost2Index === -1) {
					this.listOfInclusionExclusionRuleCriterions.push({ value: cardFinanceCost2Obj.ix_custom_field, text: cardFinanceCost2Obj.custom_field_title ? cardFinanceCost2Obj.custom_field_title : cardFinanceCost2Obj.ix_custom_field_title });
				}
				if (isBonusPercentage1Index === -1) {
					this.listOfInclusionExclusionRuleCriterions.push({ value: bonusPercentage1Obj.ix_custom_field, text: bonusPercentage1Obj.custom_field_title ? bonusPercentage1Obj.custom_field_title : bonusPercentage1Obj.ix_custom_field_title });
				}
			}
		} else {
			if (cardFinanceCostObj != undefined && cardFinanceCostObj.ix_custom_field) {
				isCardFinanceCostIndex = this.listOfInclusionExclusionRuleCriterions.findIndex(c => c.value === cardFinanceCostObj.ix_custom_field);
				this.listOfInclusionExclusionRuleCriterions.splice(isCardFinanceCostIndex, 1);
			}

			if (cardFinanceCost2Obj != undefined && cardFinanceCost2Obj.ix_custom_field) {
				isCardFinanceCost2Index = this.listOfInclusionExclusionRuleCriterions.findIndex(c => c.value === cardFinanceCost2Obj.ix_custom_field);
				this.listOfInclusionExclusionRuleCriterions.splice(isCardFinanceCost2Index, 1);
			}

			if (bonusPercentage1Obj != undefined && bonusPercentage1Obj.ix_custom_field) {
				isBonusPercentage1Index = this.listOfInclusionExclusionRuleCriterions.findIndex(c => c.value === bonusPercentage1Obj.ix_custom_field);
				this.listOfInclusionExclusionRuleCriterions.splice(isBonusPercentage1Index, 1);
			}

			if ((cardFinanceCostObj != undefined && this.ruleTabModel.criterion === cardFinanceCostObj.ix_custom_field) || (cardFinanceCost2Obj != undefined && this.ruleTabModel.criterion === cardFinanceCost2Obj.ix_custom_field) || (bonusPercentage1Obj != undefined && this.ruleTabModel.criterion === bonusPercentage1Obj.ix_custom_field)) {
				this.ruleTabModel.criterion = this.listOfInclusionExclusionRuleCriterions.length > 0 ? this.listOfInclusionExclusionRuleCriterions[0].value : '';
			}
		}
	}

	concatNamePipe() {
		let name = this.ruleBonusTypeSelectBoxRef.displayValue + '|' + this.conditionSelectBoxRef.displayValue;
		const txtCriterion = this.criterionSelectBoxRef && this.criterionSelectBoxRef.displayValue ? this.criterionSelectBoxRef.displayValue : '';
		if (txtCriterion) {
			name = name + '|' + txtCriterion;
		}
		const fullname = name;
		return fullname;
	}

	addRuleBonusItem() {
		if (this.ruleTabModel.condition === 'miscellaneousDeductionFixedCost') {
			this.addRuleBonusItemWithItem();
			return;
		}
		if (this.ruleTabModel.condition === 'miscellaneousDeductionInventory') {
			this.addRuleBonusItemWithCustomer();
			return;
		}
		if (this.ruleTabModel.condition === 'miscellaneousDeductionAllItem') {
			this.addRuleBonusItemWithCustomer();
			return;
		}
		// if (this.ruleTabModel.condition === 'miscellaneousDeductionItemPercent' && this.enable_classification_for_commision) {
		// 	this.addRateRuleClasses();
		// 	return;
		// } 
		if (this.ruleTabModel.condition === 'miscellaneousDeductionItemPercent') {
			this.addRuleBonusItemWithItem();
			return;
		}

		let name = this.concatNamePipe();
		const ruleItem = {
			ruleBonusType: this.ruleTabModel.ruleBonusType,
			condition: this.ruleTabModel.condition,
			criterion: $.isNumeric(this.ruleTabModel.criterion) ? parseFloat(this.ruleTabModel.criterion) : this.ruleTabModel.criterion,
			ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
			name: name,
			guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
		};
		let isExistRuleIndex;
		let removedArray = _.reject(this.ruleTabList, item => {
			return item === this.ruleTabList[this.rulesAddOrEditIndex];
		});
		// Check duplicate item in list
		// tslint:disable-next-line:radix
		if (parseInt(this.rulesAddOrEditIndex) === -1) {
			isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === this.ruleTabModel.criterion && item.guid !== this.ruleTabModel.guid);
		} else {
			isExistRuleIndex = removedArray.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === this.ruleTabModel.criterion && item.guid !== this.ruleTabModel.guid);
		}
		if (this.ruleTabModel.condition === 'excludeLateInvoice') {
			isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.guid !== this.ruleTabModel.guid);
		}

		if (isExistRuleIndex !== -1) {
			this._ToastrService.info('Rule already added in list .', 'Duplicate', { closeButton: true, tapToDismiss: true });
			return false;
		}
		const objRuleCriterions = this.checkExcludeOrIncludeRuleValidate(removedArray, ruleItem);
		if (objRuleCriterions) {
			this._ToastrService.info('"' + objRuleCriterions.text + '" criterion is already added', 'Duplicate', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (ruleItem.criterion === 'unpaidInvoices' || ruleItem.criterion === 'partialpaidInvoices') {
			const objkPartialOrUnpaidInvoices = this.checkPartialOrUnpaidInvoices(removedArray, ruleItem);
			if (objkPartialOrUnpaidInvoices) {
				this._ToastrService.info(objkPartialOrUnpaidInvoices, 'Duplicate', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}
		// tslint:disable-next-line:radix
		this.chkAddOrUpdateRule = '';
		if (parseInt(this.rulesAddOrEditIndex) !== -1) {
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
			this._ToastrService.success('Rule updated successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
		} else {
			this.ruleTabList.push(ruleItem);
			this._ToastrService.success('Rule added successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
		}
		// Latest rule save on edit commission contract
		if (this.editCommissionGuid) {
			this.saveCommissionRulesEvent.emit('save');
		}
		this.clearRulesAgreementTabDetails();
	}

	private addRuleBonusItemWithCustomer() {
		this.selectedCustomerData = [];
		if (this.customerDataGrid) {
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData() || [];
			if (this.chkAllCustomer) {
				const custItem = {
					name: 'All Customers',
					code: 'allcustomers'
				}
				this.selectedCustomerData.push(custItem);
			}
			if (this.chkByTransaction) {
				const custItem = {
					name: 'By Transaction',
					code: 'bytransactions'
				}
				this.selectedCustomerData.push(custItem);
			}
			if (this.selectedCustomerData && this.selectedCustomerData.length > 0) {
				for (let i = 0; i < this.selectedCustomerData.length; i++) {
					let name = this.concatNamePipe();
					const ruleItem = {
						ruleBonusType: this.ruleTabModel.ruleBonusType,
						condition: this.ruleTabModel.condition,
						criterion: this.selectedCustomerData[i].code + '@$$@' + this.selectedCustomerData[i].name,
						ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
						name: name,
						guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
					};
					let isExistRuleIndex;

					let removedArray = _.reject(this.ruleTabList, item => {
						return item === this.ruleTabList[this.rulesAddOrEditIndex];
					});
					// Check duplicate item in list
					// tslint:disable-next-line:radix
					if (parseInt(this.rulesAddOrEditIndex) === -1) {
						isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === ruleItem.criterion);
					} else {
						isExistRuleIndex = removedArray.findIndex(item => item.ruleBonusType === this.ruleTabModel.ruleBonusType && item.condition === this.ruleTabModel.condition && item.criterion === ruleItem.criterion);
					}

					if (isExistRuleIndex !== -1) {
						this._ToastrService.info('Rule already added in list .', 'Duplicate', { closeButton: true, tapToDismiss: true });
						return false;
					}

					const objRuleCriterions = this.checkExcludeOrIncludeRuleValidate(removedArray, ruleItem);
					if (objRuleCriterions) {
						this._ToastrService.info('"' + objRuleCriterions.text + '" criterion is already added', 'Duplicate', { closeButton: true, tapToDismiss: true });
						return false;
					}
					if (ruleItem.criterion === 'unpaidInvoices' || ruleItem.criterion === 'partialpaidInvoices') {
						this.checkPartialOrUnpaidInvoices(removedArray, ruleItem);
					}
					// tslint:disable-next-line:radix
					if (parseInt(this.rulesAddOrEditIndex) !== -1) {
						if (i === 0) {
							this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
						} else {
							this.ruleTabList.push(ruleItem);
						}
						this._ToastrService.success('Rule updated successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this.ruleTabList.push(ruleItem);
						this._ToastrService.success('Rule added successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
					}
				}
			}
		} else {
			let name = this.concatNamePipe();
			const ruleItem = {
				ruleBonusType: this.ruleTabModel.ruleBonusType,
				condition: this.ruleTabModel.condition,
				criterion: this.ruleTabModel.criterion,
				ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
				name: name,
				guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
			};
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
		}
		// Latest rule save on edit commission contract
		if (this.editCommissionGuid) {
			this.saveCommissionRulesEvent.emit('save');
		}
		this.clearRulesAgreementTabDetails();
	}

	private addRuleBonusItemWithItem() {
		this.selectedItemData = [];
		/*
		if (this.rulesAddOrEditIndex !== '-1' && this.ruleTabModel.criterion) {
			let name = this.concatNamePipe();
			const ruleItem = {
				ruleBonusType: this.ruleTabModel.ruleBonusType,
				condition: this.ruleTabModel.condition,
				criterion: this.ruleTabModel.criterion,
				ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
				name: name,
				guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
			};
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
		} else {
		*/
		if (this.itemDataGrid) {
			this.selectedItemData = this.itemDataGrid.instance.getSelectedRowsData() || [];
			if (this.chkAllItem) {
				const custItem = {
					itemname: 'All Items',
					description: 'All Items',
					erp_primary_key: 'allproducts'
				}
				this.selectedItemData.push(custItem);
			}
			if (this.selectedItemData && this.selectedItemData.length > 0) {
				for (let i = 0; i < this.selectedItemData.length; i++) {
					let name = this.concatNamePipe();
					const ruleItem = {
						ruleBonusType: this.ruleTabModel.ruleBonusType,
						condition: this.ruleTabModel.condition,
						criterion: this.selectedItemData[i].erp_primary_key + '@$$@' + this.selectedItemData[i].itemname,
						ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
						name: name,
						guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
					};
					let isExistRuleIndex;
					let removedArray = _.reject(this.ruleTabList, item => {
						return item === this.ruleTabList[this.rulesAddOrEditIndex];
					});
					// Check duplicate item in list
					// tslint:disable-next-line:radix
					if (parseInt(this.rulesAddOrEditIndex) === -1) {
						isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === ruleItem.ruleBonusType && item.condition === ruleItem.condition && item.criterion === ruleItem.criterion);
					} else {
						isExistRuleIndex = removedArray.findIndex(item => item.ruleBonusType === ruleItem.ruleBonusType && item.condition === ruleItem.condition && item.criterion === ruleItem.criterion);
					}

					if (isExistRuleIndex !== -1) {
						this._ToastrService.info('Rule already added in list .', 'Duplicate', { closeButton: true, tapToDismiss: true });
						return false;
					}

					const objRuleCriterions = this.checkExcludeOrIncludeRuleValidate(removedArray, ruleItem);
					if (objRuleCriterions) {
						this._ToastrService.info('"' + objRuleCriterions.text + '" criterion is already added', 'Duplicate', { closeButton: true, tapToDismiss: true });
						return false;
					}

					if (parseInt(this.rulesAddOrEditIndex) !== -1) {
						if (i === 0) {
							this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
						} else {
							this.ruleTabList.push(ruleItem);
						}
						this._ToastrService.success('Rule updated successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this.ruleTabList.push(ruleItem);
						this._ToastrService.success('Rule added successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
					}
				}
			}
		} else {
			let name = this.concatNamePipe();
			const ruleItem = {
				ruleBonusType: this.ruleTabModel.ruleBonusType,
				condition: this.ruleTabModel.condition,
				criterion: this.ruleTabModel.criterion,
				ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
				name: name,
				guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
			};
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
		}
		// }
		// Latest rule save on edit commission contract
		if (this.editCommissionGuid) {
			this.saveCommissionRulesEvent.emit('save');
		}
		this.clearRulesAgreementTabDetails();
	}


	private addRateRuleClasses() {
		this.selectedClassData = [];
		if (this.classesDataGrid) {
			this.selectedClassData = this.classesDataGrid.instance.getSelectedRowsData() || [];
			if (this.chkAllClasses) {
				const custItem = {
					name: 'All Classes',
					id: 'allclasses'
				};
				this.selectedClassData.push(custItem);
			}
			if (this.selectedClassData && this.selectedClassData.length > 0) {
				for (let i = 0; i < this.selectedClassData.length; i++) {
					let name = this.concatNamePipe();
					const ruleItem = {
						ruleBonusType: this.ruleTabModel.ruleBonusType,
						condition: this.ruleTabModel.condition,
						criterion: this.selectedClassData[i].id + '@$$@' + this.selectedClassData[i].name,
						ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
						name: name,
						guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
					};
					let isExistRuleIndex;
					let removedArray = _.reject(this.ruleTabList, item => {
						return item === this.ruleTabList[this.rulesAddOrEditIndex];
					});
					// Check duplicate item in list
					// tslint:disable-next-line:radix
					if (parseInt(this.rulesAddOrEditIndex) === -1) {
						isExistRuleIndex = this.ruleTabList.findIndex(item => item.ruleBonusType === ruleItem.ruleBonusType && item.condition === ruleItem.condition && item.criterion === ruleItem.criterion);
					} else {
						isExistRuleIndex = removedArray.findIndex(item => item.ruleBonusType === ruleItem.ruleBonusType && item.condition === ruleItem.condition && item.criterion === ruleItem.criterion);
					}

					if (isExistRuleIndex !== -1) {
						this._ToastrService.info('Rule already added in list .', 'Duplicate', { closeButton: true, tapToDismiss: true });
						return false;
					}

					const objRuleCriterions = this.checkExcludeOrIncludeRuleValidate(removedArray, ruleItem);
					if (objRuleCriterions) {
						this._ToastrService.info('"' + objRuleCriterions.text + '" criterion is already added', 'Duplicate', { closeButton: true, tapToDismiss: true });
						return false;
					}
					if (ruleItem.criterion === 'unpaidInvoices' || ruleItem.criterion === 'partialpaidInvoices') {
						this.checkPartialOrUnpaidInvoices(removedArray, ruleItem);
					}
					// tslint:disable-next-line:radix
					if (parseInt(this.rulesAddOrEditIndex) !== -1) {
						if (i === 0) {
							this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
						} else {
							this.ruleTabList.push(ruleItem);
						}
						this._ToastrService.success('Rule updated successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this.ruleTabList.push(ruleItem);
						this._ToastrService.success('Rule added successfully !', 'Saved', { closeButton: true, tapToDismiss: true });
					}
				}
			}
		} else {
			let name = this.concatNamePipe();
			const ruleItem = {
				ruleBonusType: this.ruleTabModel.ruleBonusType,
				condition: this.ruleTabModel.condition,
				criterion: this.ruleTabModel.criterion,
				ruleRate: $.isNumeric(this.ruleTabModel.ruleRate) ? parseFloat(this.ruleTabModel.ruleRate) : this.ruleTabModel.ruleRate,
				name: name,
				guid: !this.ruleTabModel['guid'] ? this.guid.newGuid() : this.ruleTabModel.guid,
			};
			this.ruleTabList[this.rulesAddOrEditIndex] = ruleItem;
		}
		// Latest rule save on edit commission contract
		if (this.editCommissionGuid) {
			this.saveCommissionRulesEvent.emit('save');
		}
		this.clearRulesAgreementTabDetails();
	}

	checkExcludeOrIncludeRuleValidate(removedArray, ruleItem) {
		if (removedArray && removedArray.length > 0 && ruleItem.ruleBonusType === 'inclusionExclusionRule' && (ruleItem.condition === 'include' || ruleItem.condition === 'exclude')) {
			const checkExcludeOrIncludeRuleIndex = removedArray.findIndex(item => item.ruleBonusType === ruleItem.ruleBonusType && (item.condition === 'include' || item.condition === 'exclude') && item.criterion === ruleItem.criterion);
			if (checkExcludeOrIncludeRuleIndex !== -1) {
				const objRuleCriterions = this.listOfInclusionExclusionRuleCriterions.find(i => i.value === ruleItem.criterion);
				return objRuleCriterions;
			} else {
				return null;
			}
		} else {
			return null
		}
	}
	checkPartialOrUnpaidInvoices(removedArray, ruleItem) {
		if (removedArray && removedArray.length > 0) {
			let objItem;
			if (ruleItem.criterion === 'unpaidInvoices') {
				objItem = 'partialpaidInvoices';
			}
			if (ruleItem.criterion === 'partialpaidInvoices') {
				objItem = 'unpaidInvoices';
			}
			const checkExcludeOrIncludeRuleIndex = removedArray.findIndex(item => item.criterion === objItem);
			if (checkExcludeOrIncludeRuleIndex !== -1) {
				if (removedArray[checkExcludeOrIncludeRuleIndex].condition === 'exclude' && removedArray[checkExcludeOrIncludeRuleIndex].criterion === 'unpaidInvoices') {
					return null;
				}
				if (removedArray[checkExcludeOrIncludeRuleIndex].condition === 'include' && removedArray[checkExcludeOrIncludeRuleIndex].criterion === 'partialpaidInvoices') {
					return null;
				}
				if (objItem === 'partialpaidInvoices') {
					return '"Partially Paid Invoices" criterion conflicts with "Unpaid Invoices" ';
				} else {
					return '"Unpaid Invoices" criterion conflicts with "Partially Paid Invoices"';
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	conditionChange(condition) {
		// tslint:disable-next-line: max-line-length
		if (condition !== '' && condition !== null && condition !== undefined) {
			if ((condition === 'breakUnitsOfMeasure') || (condition === 'newAccount') || (condition === 'perActiveCustomer') || (condition === 'perActiveAccount')) {
				this.isViewBounsAmountCriterion = true;
				this.isViewRateRuleCriterion = true;
				this.isViewBonusRateCriterion = true;
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.criterion = 'N/A';
				}
			} else {
				this.isViewBounsAmountCriterion = false;
				this.isViewRateRuleCriterion = false;
				this.isViewBonusRateCriterion = false;
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.criterion = '';
				}
			}
		}
		if (this.chkAddOrUpdateRule === 'edit' && this.ruleTabModel.ruleBonusType !== 'inclusionExclusionRule') {
			this.chkAddOrUpdateRule = '';
		}

		if (this.isProfiltOnDocumentHeaderEntityUdfField) {
			this.ruleTabModel.criterion = 'N/A';
			this.isViewBonusRateCriterion = true;
		}
	}

	criterionChange(criterion) {
		if (criterion !== '' && criterion !== null && criterion !== undefined) {
			if (criterion === 'shippingCosts') {
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.ruleRate = '';
				}
				this.isViewIERuleRuleRate = false;
			} else {
				if (this.chkAddOrUpdateRule == '') {
					this.ruleTabModel.ruleRate = 'N/A';
				}
				this.isViewIERuleRuleRate = true;
			}
		}
		if (this.chkAddOrUpdateRule === 'edit' && this.ruleTabModel.ruleBonusType === 'inclusionExclusionRule') {
			this.chkAddOrUpdateRule = '';
		}
	}

	doRemoveRuleBonusListItem(ruleItemIndex) {
		this._IxDxAlertService.doShowDeleteConfirmAlert().then(result => {
			if (result) {
				// Delete an item from customer Tab list
				this.ruleTabList.splice(ruleItemIndex, 1);
				// Latest rule save on edit commission contract
				if (this.editCommissionGuid) {
					this.saveCommissionRulesEvent.emit('save');
				}

				// this._ToastrService.error('Record deleted successfully. ', 'Deleted', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	doEditRuleBonusListItem(ruleItemIndex, ruleItem) {
		this.btnRulesAdd = 'Update';
		this.chkAddOrUpdateRule = 'edit';
		this.rulesAddOrEditIndex = ruleItemIndex;
		this.ruleTabModel.guid = ruleItem.guid;
		this.ruleTabModel.name = ruleItem.name;
		this.ruleTabModel.condition = ruleItem.condition;
		this.ruleTabModel.ruleRate = ruleItem.ruleRate;
		this.ruleTabModel.ruleBonusType = ruleItem.ruleBonusType;
		this.arrSelectedCustomerList = [];
		if (this.ruleTabModel.condition === 'miscellaneousDeductionInventory' || this.ruleTabModel.condition === 'miscellaneousDeductionAllItem') {
			this.loadCustomerDataList();
			if (ruleItem.criterion) {
				const criterionSpit = ruleItem.criterion.toString().split('@$$@');
				this.chkAllCustomer = false;
				this.chkByTransaction = false;
				if (criterionSpit[0] === 'allcustomers') {
					this.chkAllCustomer = true;
				} else if (criterionSpit[0] === 'bytransactions') {
					this.chkByTransaction = true;
				} else {
					// this.lengthSelectedCustomers = ruleItem.criterion.split('@$$@')[1];
					this.customerGridBoxValue = [];
					this.customerGridBoxValue.push(criterionSpit[0]);
				}
				this.doDisplayExprCustomerText();
			}
			this.ruleTabModel.criterion = ruleItem.criterion;
			// this.setCustomersText();
		} else if (this.ruleTabModel.condition === 'miscellaneousDeductionFixedCost' || this.ruleTabModel.condition === 'miscellaneousDeductionItemPercent') {
			this.loadItemDataList();
			if (ruleItem.criterion) {
				const criterionSpit = ruleItem.criterion.toString().split('@$$@');
				if (criterionSpit && criterionSpit.length > 0) {
					if (criterionSpit[0] === 'allproducts') {
						this.chkAllItem = true;
					} else {
						this.arrSelectedItemList = [];
						this.arrSelectedItemList.push(criterionSpit[0]);
					}
					this.doDisplayExprItemsText();
				}
			}
			this.ruleTabModel.criterion = ruleItem.criterion;
		} else {
			this.ruleTabModel.criterion = ruleItem.criterion;
		}
		/*
		else if (this.ruleTabModel.condition === 'miscellaneousDeductionItemPercent' && this.enable_classification_for_commision) {
			this.loadClassesDataList();
			if (ruleItem.criterion) {
				const criterionSpit = ruleItem.criterion.toString().split('@$$@');
				if (criterionSpit && criterionSpit.length > 0) {
					if (criterionSpit[0] === 'allclasses') {
						this.chkAllClasses = true;
					} else {
						this.arrSelectedClassesList = [];
						this.arrSelectedClassesList.push(criterionSpit[0]);
					}
					this.doDisplayExprClassesText();
				}
			}
			this.ruleTabModel.criterion = ruleItem.criterion;
		} 
		*/


		if (ruleItem.criterion === 'N/A' || ruleItem.condition === 'N/A' || this.ruleTabModel.ruleRate === 'N/A') {
			this.isViewRateRuleCriterion = true;
			this.isViewBonusRateCriterion = true;
			this.isViewBounsAmountCriterion = true;
			this.isViewIERuleRuleRate = true;
		} else {
			this.isViewRateRuleCriterion = false;
			this.isViewBonusRateCriterion = false;
			this.isViewBounsAmountCriterion = false;
			this.isViewIERuleRuleRate = false;
		}
	}

	public doBuyingGroupPercentageKeyDown(e) {
		const event = e.event;
		const str = event.key || String.fromCharCode(event.which);
		if (/^[.,e]$/.test(str)) {
			event.preventDefault();
		}
	}

	//#region for Customer select box
	private loadCustomerDataList() {
		/*
		const customeList = _.map(this.exclusionTabList, group => {
			return {
				guid: group['clientguid'] || '',
				code: group['clientcode'] || '',
				name: group['clientname'] || '',
				allcustomers: group['allcustomers'] || '',
				bytransactions: group['bytransactions'] || '',
			}
		});
		// const indexAllCustomer = customeList.findIndex(i => i.allcustomers === true);
		// if (indexAllCustomer !== -1) {
		// 	this.isViewAllCustomerChk = true;
		// }
		// const notAllCustomerInList = _.where(customeList, { allcustomers: '' });	
		const notAllCustomerInList = _.where(customeList, { allcustomers: '', bytransactions: '' });
		this.customerDataSource = _.map(_.uniq(notAllCustomerInList, 'code'), item => {
			return {
				// guid: item.guid || '',
				code: item.code || '',
				name: item.name || '',
			}
		});
		*/
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS
		}
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "code",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.customerGridBoxValue = [];
			this.arrSelectedCustomerListTagBox = [];
			this.customerDataGrid.instance.refresh();
			this.chkAllCustomer = false;
			this.lengthSelectedCustomers = '';
		}
	}

	// Method used to changed value of items tag box
	public doValueChangeCustomersTagBox(event) {
		const listTags = event.value || [];
		if (listTags.length !== event.previousValue.length) {
			for (let i = 0; i < this.arrSelectedCustomerListTagBox.length; i++) {
				const tagIndex = listTags.findIndex(tag => tag === this.arrSelectedCustomerListTagBox[i]);
				if (tagIndex === -1) {
					const elementIndex = this.arrSelectedCustomerList.findIndex(element => element.name === this.arrSelectedCustomerListTagBox[i]);
					this.arrSelectedCustomerList.splice(elementIndex, 1);
					this.arrSelectedCustomerListTagBox.splice(i, 1);
				}
			}
			this.doDisplayExprCustomerText();
		}
	}

	public onCustomerSelectionChanged(e) {
		this.arrSelectedCustomerListTagBox = [];
		this.customerGridBoxValue = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].code);
				this.arrSelectedCustomerListTagBox.push(e.selectedRowsData[i].name);
			};
		}
		this.doDisplayExprCustomerText();
	}

	public checkAllCustomer() {
		this.doDisplayExprCustomerText();
	}
	public checkByTransaction() {
		this.doDisplayExprCustomerText();
	}

	public doDisplayExprCustomerText(event?: any) {
		let listOfTexts: any[] = [];
		if (this.chkAllCustomer) {
			listOfTexts.push('All Customers In Contract');
		}
		if (this.chkByTransaction) {
			listOfTexts.push('By Transaction');
		}
		if (this.arrSelectedCustomerList && this.arrSelectedCustomerList.length > 0) {
			listOfTexts.push(this.arrSelectedCustomerList.length + ' Customer(s)');
		}
		this.lengthSelectedCustomers = listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}
	//#endregion

	//#region for Item|Product select box
	private loadItemDataList() {
		const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ITEMS,
			view_fields: JSON.stringify(viewFields)
		};
		this.itemDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "erp_primary_key",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public onItemValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedItemList = [];
			this.itemGridBoxValue = [];
			this.arrSelectedItemListTagBox = [];
			this.itemDataGrid.instance.refresh();
			this.chkAllItem = false;
			this.lengthSelectedItems = '';
		}
	}

	// Method used to changed value of items tag box
	public doValueChangeItemTagBox(event) {
		const listTags = event.value || [];
		if (listTags.length !== event.previousValue.length) {
			for (let i = 0; i < this.arrSelectedItemListTagBox.length; i++) {
				const tagIndex = listTags.findIndex(tag => tag === this.arrSelectedItemListTagBox[i]);
				if (tagIndex === -1) {
					const elementIndex = this.arrSelectedItemList.findIndex(element => element.itemname === this.arrSelectedItemListTagBox[i]);
					this.arrSelectedItemList.splice(elementIndex, 1);
					this.arrSelectedItemListTagBox.splice(i, 1);
				}
			}
			this.doDisplayExprItemsText();
		}
	}

	public checkAllItem() {
		this.doDisplayExprItemsText();
	}

	public onItemSelectionChanged(e) {
		this.arrSelectedItemListTagBox = [];
		this.itemGridBoxValue = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].erp_primary_key);
				this.arrSelectedItemListTagBox.push(e.selectedRowsData[i].itemname);
			};
		}
		this.doDisplayExprItemsText();
	}

	public doDisplayExprItemsText(event?: any) {
		let listOfTexts: any[] = [];
		if (this.chkAllItem) {
			listOfTexts.push('All Items');
		}
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			listOfTexts.push(this.arrSelectedItemList.length + ' Item(s)');
		}
		this.lengthSelectedItems = listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}
	//#endregion

	private doGetProfitOnDocumentUDFEntityData() {
		const feature_guid = this._CommissionService.getFeatureGuid() || '';
		if (!feature_guid) {
			return false;
		}
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		// formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		// formdata.append('entity', ServerEntity.PROFIT_ON_DOCUMENT_HEADER);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		formdata.append('search', JSON.stringify([{ feature_guid: feature_guid }]));
		//formdata.append('udf_for', entityName);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response.flag) {
					const erpEntityListData = response.data;
					this.listOfFeatureCustomFields = response.data;
					this.setListOfCriterionsWithCustomFields();

					if (erpEntityListData && erpEntityListData.length > 0) {
						erpEntityListData.forEach(element => {
							if (element && element['custom_field_id']) {
								this.listOfBonusRateConditions = this._CommissionService.listOfBonusRateConditionsForProfiltOnDocumentHeader;
								this.isProfiltOnDocumentHeaderEntityUdfField = true;
							}
						});
					}
				}
			}, error: (error) => {
			}
		});
	}

	private setListOfCriterionsWithCustomFields() {
		const cardFinanceCostObj = this.listOfFeatureCustomFields.find(cf => cf.ix_custom_field === 'card_finance_cost');
		if (cardFinanceCostObj && cardFinanceCostObj.ix_custom_field) {
			const isCardFinanceCostIndex = this.listOfMasterCriterions.findIndex(criterion => criterion.value === cardFinanceCostObj.ix_custom_field);
			if (isCardFinanceCostIndex === -1) {
				this.listOfMasterCriterions.push({ value: cardFinanceCostObj.ix_custom_field, text: cardFinanceCostObj.custom_field_title ? cardFinanceCostObj.custom_field_title : cardFinanceCostObj.ix_custom_field_title });
			}
		}
		const cardFinanceCost2Obj = this.listOfFeatureCustomFields.find(cf => cf.ix_custom_field === 'card_finance_cost_2');
		if (cardFinanceCost2Obj && cardFinanceCost2Obj.ix_custom_field) {
			const isCardFinanceCost2Index = this.listOfMasterCriterions.findIndex(criterion => criterion.value === cardFinanceCost2Obj.ix_custom_field);
			if (isCardFinanceCost2Index === -1) {
				this.listOfMasterCriterions.push({ value: cardFinanceCost2Obj.ix_custom_field, text: cardFinanceCost2Obj.custom_field_title ? cardFinanceCost2Obj.custom_field_title : cardFinanceCost2Obj.ix_custom_field_title });
			}
		}

		const bonusPercentage1Obj = this.listOfFeatureCustomFields.find(cf => cf.ix_custom_field === 'bonus_percentage_1');
		if (bonusPercentage1Obj && bonusPercentage1Obj.ix_custom_field) {
			const isBonusPercentage1Index = this.listOfMasterCriterions.findIndex(criterion => criterion.value === bonusPercentage1Obj.ix_custom_field);
			if (isBonusPercentage1Index === -1) {
				this.listOfMasterCriterions.push({ value: bonusPercentage1Obj.ix_custom_field, text: bonusPercentage1Obj.custom_field_title ? bonusPercentage1Obj.custom_field_title : bonusPercentage1Obj.ix_custom_field_title });
			}
		}
	}

	//#region for Classes select box |  only show  while ERP type NETSUITE

	private loadClassesDataList() {
		// const viewFields = ['erp_primary_key', 'classesku', 'classname', 'description'];
		const loadUrl = environment.URL_DATAOUT;
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.IX_CLASSIFICATIONS,
		}
		// loadParams.view_fields =JSON.stringify(viewFields);

		this.classesDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "id",
			loadUrl: loadUrl,
			loadParams: loadParams
		});
	}

	public doDisplayExprClassesText(event?: any) {
		let listOfText: any[] = [];
		if (this.chkAllClasses) {
			listOfText.push('All Classes');
		}
		if (this.arrSelectedClassesList.length > 0) {
			listOfText.push(this.arrSelectedClassesList.length + ' Classes');
		}
		this.lengthSelectedClasses = listOfText.length > 0 ? listOfText.join(', ') : '';
		return this.lengthSelectedClasses;
	}

	public onClassesValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedClassesList = [];
			this.classesGridBoxValue = [];
			this.arrSelectedClassesListTagBox = [];
			this.classesDataGrid.instance.refresh();
			this.chkAllClasses = false;
			this.lengthSelectedClasses = '';
		}
	}

	public checkAllClasses() {
		this.doDisplayExprClassesText();
	}

	public onClassesSelectionChanged(e) {
		this.classesGridBoxValue = [];
		this.arrSelectedClassesListTagBox = [];
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.classesGridBoxValue.push(e.selectedRowsData[i].id);
				this.arrSelectedClassesListTagBox.push(e.selectedRowsData[i].name);
			};
		}
		this.doDisplayExprClassesText();
	}

	// Method used to changed value of Class tag box
	public doValueChangeClassesTagBox(event) {
		const listTags = event.value || [];
		if (listTags.length !== event.previousValue.length) {
			for (let i = 0; i < this.arrSelectedClassesListTagBox.length; i++) {
				const tagIndex = listTags.findIndex(tag => tag === this.arrSelectedClassesListTagBox[i]);
				if (tagIndex === -1) {
					const elementIndex = this.arrSelectedClassesList.findIndex(element => element.name === this.arrSelectedClassesListTagBox[i]);
					this.arrSelectedClassesList.splice(elementIndex, 1);
					this.arrSelectedClassesListTagBox.splice(i, 1);
				}
			}
			this.doDisplayExprClassesText();
		}
	}
	//#endregion

	public setCriterionValForGrid(data: any) {
		let returbVal: any = '';
		if (data && data.hasOwnProperty('condition')) {
			const ruleBonusTypeVal: any = data.ruleBonusType;
			const conditionVal: any = data.condition;
			const criterionVal: any = data.criterion;
			if (conditionVal === 'miscellaneousDeductionInventory' || conditionVal === 'miscellaneousDeductionFixedCost' || conditionVal === 'miscellaneousDeductionItemPercent' || conditionVal === 'miscellaneousDeductionAllItem') {
				returbVal = criterionVal ? criterionVal.split('@$$@')[1] : criterionVal;
			} else {
				const indexMC = this.listOfMasterCriterions.findIndex(i => i.value === criterionVal);
				if (indexMC !== -1) {
					returbVal = this.listOfMasterCriterions[indexMC].text;
				}
			}
			if (conditionVal === 'excludeLateInvoice') {
				returbVal = criterionVal;
			}
			if ((conditionVal === 'breakUnitsOfMeasure') || (conditionVal === 'newAccount') || (conditionVal === 'perActiveCustomer') || (conditionVal === 'perActiveAccount')) {
				returbVal = 'N/A';
			}
			if ((conditionVal === 'totalPerSettlementPeriodGreaterThan') || (conditionVal === 'newAccountWithMonthlyOrdersGreaterThan') || (conditionVal === 'grossProfitPerSettlementPeriod' || (conditionVal === 'numberOfuniqueSKUsGreaterThan'))) {
				returbVal = this._CommissionService.convertCurrency(criterionVal, this.customCurrencyOption);
			}
		}
		return returbVal
	}
}
