<div class="rightBar">
	<!-- <ul *ngIf="invoiceDetails.rebate_for === 'buyinggroup'" class="topBreadcrumb">
		<li><a routerLink="/contracts/rebateslist">Rebate</a></li>
		<li><a routerLink="/contracts/rebates">Settlement Report</a></li>
		<li><a routerLink="/contracts/rebates/reportview-buyinggroup">Rebate Report</a></li>
		<li><a *ngIf="selectedBuyingGroupMember">{{selectedBuyingGroupMember}} Report Details</a></li>
	</ul> -->
	<div class="rightBlock">
		<div class="borderBox cf">
			<!-- <div class="titleRow">
				<h2 class="">
					<strong *ngIf="invoiceDetails && invoiceDetails.cardcode" class="purple">
						{{ invoiceDetails.name ?  invoiceDetails.name : ''}}
					</strong>
					Rebate Calculation
				</h2>
			</div> -->
			<div class="row">
				<div class="cols12">
					<ul class="listText">
						<li>Contract Name:
							<strong class="purple">{{invoiceDetails.contractName || ''}}</strong>
						</li>

						<li
							*ngIf="(invoiceDetails.rebate_for === 'individual' || invoiceDetails.rebate_for === 'growth_incentive' || invoiceDetails.rebate_for === 'distribution' || invoiceDetails.rebate_for === 'distribution_differential') && invoiceDetails.rebateType === 'sales'">
							Customer Name:
							<strong class="purple">{{ invoiceDetails.name ? invoiceDetails.name : ''}}</strong>
						</li>
						<li
							*ngIf="(invoiceDetails.rebate_for === 'individual' || invoiceDetails.rebate_for === 'growth_incentive')  && invoiceDetails.rebateType === 'purchasing'">
							Vendor Name:
							<strong class="purple">{{invoiceDetails.name || ''}}</strong>
						</li>
						<li *ngIf="invoiceDetails.rebate_for === 'buyinggroup'">
							Buying Group:
							<strong class="purple">{{invoiceDetails.name || ''}}</strong>
						</li>
						<li *ngIf="invoiceDetails.rebate_for === 'individual'"> Type:
							<strong class="purple">Individual
								<span *ngIf="invoiceDetails.rebateType == 'sales'">(Sales Rebate)</span>
								<span *ngIf="invoiceDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
							</strong>
						</li>
						<li *ngIf="invoiceDetails.rebate_for === 'growth_incentive'"> Type:
							<strong class="purple">Growth Incentive
								<span *ngIf="invoiceDetails.rebateType == 'sales'">(Sales Rebate)</span>
								<span *ngIf="invoiceDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
							</strong>
						</li>
						<li *ngIf="invoiceDetails.rebate_for === 'distribution'"> Type:
							<strong class="purple">Distribution
								<span *ngIf="invoiceDetails.rebateType == 'sales'">(Sales Rebate)</span>
								<span *ngIf="invoiceDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
							</strong>
						</li>
						<li *ngIf="invoiceDetails.rebate_for === 'distribution_differential'"> Type:
							<strong class="purple">Distribution Differential
								<span *ngIf="invoiceDetails.rebateType == 'sales'">(Sales Rebate)</span>
								<span *ngIf="invoiceDetails.rebateType == 'purchasing'">(Purchasing Rebate)</span>
							</strong>
						</li>
					</ul>
				</div>
			</div>
			<div *ngIf="false" id="exportContainer" class="right">
				<dx-button class="secondaryBtn" text="Export Data" icon="xlsxfile"
					(onClick)="onExporting($event)"></dx-button>
			</div>

			<dx-data-grid *ngIf="false" id="gridContainer" [dataSource]="mainDataSource" [remoteOperations]="true"
				[showBorders]="true" [allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'">

				<dxi-column dataField="name" dataType="string" caption="Settlement Period : {{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}}
					To
					{{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}"></dxi-column>
				<dxo-summary>
					<dxi-total-item column="name" alignment="right"
						displayFormat=" Total Rebate : {{invoiceDetails.settlementValue || 0 | customCurrencyPipe:customCurrencyOption }}">
					</dxi-total-item>
				</dxo-summary>

				<dxo-master-detail [enabled]="true" template="masterDetail"></dxo-master-detail>
				<div *dxTemplate="let masterDetailItem of 'masterDetail'">
					<div *ngIf="checkMasterDetails(masterDetailItem,'invoices')">
						<dx-data-grid #invoicesDataGrid id="invoicesDataGrid" [dataSource]="invoicesDataSource"
							keyExpr="invoiceCode" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [dateSerializationFormat]="'yyyy-MM-dd'" [filterSyncEnabled]="true"
							[remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<!-- <dxi-column dataField="fiscal_sequence" [groupIndex]="1" [allowSorting]="true" sortOrder="asc"
								[autoExpandGroup]="false" caption="Period"
								[calculateCellValue]="fullNameColumn_calculateCellValue">
							</dxi-column> -->
							<!-- <dxi-column dataField="invoiceDate" sortOrder="asc" dataType="date"></dxi-column> -->
							<dxi-column dataField="invoiceCode" [allowSorting]="true" [autoExpandGroup]="false"
								caption="Invoice"></dxi-column>
							<dxi-column dataField="clientName" [groupIndex]="1" caption="Customer Name"></dxi-column>
							<!-- <dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku" caption="SKU"></dxi-column> -->
							<dxi-column dataField="itemname" dataType="string" [visible]="!enable_classification_for_rebate"
								[groupIndex]="2" [autoExpandGroup]="false" caption="Item" cellTemplate="cellTemplateItemname">
								<div *dxTemplate="let element of 'cellTemplateItemname'">
									{{ element.data.clientName }} - {{element.data.itemname || ''}}
									{{ element.data.description ? ' - '+element.data.description : ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="classname" dataType="string" [visible]="enable_classification_for_rebate"
								[groupIndex]="2" [autoExpandGroup]="false" caption="Class" cellTemplate="cellTemplateClassName">
								<div *dxTemplate="let element of 'cellTemplateClassName'">
									{{ element.data.clientName }} - {{element.data.classname || ''}}
								</div>
							</dxi-column>
							<!-- <dxi-column dataField="description" caption="Description"></dxi-column> -->
							<!-- <dxi-column dataField="uomcode" caption="UOM" cellTemplate="cellTemplateUOM">
								<div *dxTemplate="let element of 'cellTemplateUOM'">
									{{element.data.uomcode || '' | filterFromList:listOfUoms :'code' : 'name' | uppercase }}
								</div>
							</dxi-column> -->
							<dxi-column dataField="quantity" dataType="number" caption="Qty"
								cellTemplate="cellTemplateQuantity">
								<div *dxTemplate="let element of 'cellTemplateQuantity'">
									{{element.data.quantity || '' }}
								</div>
							</dxi-column>
							<dxi-column dataField="price" dataType="number" [caption]="'Price ('+globalCurrencySymbol+')'"
								[calculateDisplayValue]="getCurrencyDisplayValue">
							</dxi-column>
							<dxi-column dataField="tier_basis" dataType="string" caption="Rebate Type"
								cellTemplate="cellTemplateTierBasis">
								<div *dxTemplate="let element of 'cellTemplateTierBasis'">
									{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label' |
									uppercase}}
								</div>
							</dxi-column>
							<dxi-column dataField="rebate_basis" dataType="string" caption="Rebate Basis"
								cellTemplate="cellTemplateRebateBasis">
								<div *dxTemplate="let element of 'cellTemplateRebateBasis'">
									{{element.data.rebate_basis || '' | filterFromList:listOfRebateBasis : 'code' : 'label' |
									uppercase}}
								</div>
							</dxi-column>

							<!-- <dxi-column dataField="linetotal" caption="Line Total ({{globalCurrencySymbol}})" cellTemplate="cellTemplateLinetotal">
								<div *dxTemplate="let element of 'cellTemplateLinetotal'">
									{{element.data.linetotal || 0 | customCurrencyPipe }}
								</div>
							</dxi-column> -->
							<!-- <dxi-column dataField="condition" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" caption="Criterion"></dxi-column> -->
							<dxi-column dataField="commissionRate" dataType="number"
								[caption]="'Rebate Rate (% | '+globalCurrencySymbol+')'" alignment="center"
								cellTemplate="cellTemplateCommitionsRate">
								<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
									<span *ngIf="element.data.commissionRate">
										<span *ngIf="element.data.commissionRateIn === '$'">{{element.data.commissionRate || 0 |
											customCurrencyPipe:customCurrencyOption}}</span>
										<span *ngIf="element.data.commissionRateIn === '%'">{{element.data.commissionRate +
											element.data.commissionRateIn}}</span>
									</span>
									<!-- {{element.data.commissionRate ? element.data.commissionRateIn === '$' ? element.data.commissionRateIn : '' : ''}}
									{{element.data.commissionRate || ''}}
									{{element.data.commissionRate ? element.data.commissionRateIn === '%' ? element.data.commissionRateIn : '' : ''}} -->
								</div>
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number"
								[calculateDisplayValue]="getCurrencyDisplayValue"
								[caption]="'Rebate Amount ('+globalCurrencySymbol+')'" [width]="180">
							</dxi-column>

							<dxo-summary>
								<!-- <dxi-group-item column="invoiceCode" summaryType="count" displayFormat="invoice {0}">
								</dxi-group-item> -->
								<dxi-group-item column="commissionAmount" summaryType="sum" displayFormat="Sub Total: {0}"
									[showInGroupFooter]="true" [customizeText]="doCustomizeSubTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-group-item>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Total: {0}"
									[customizeText]="doCustomizeTextTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-total-item>
							</dxo-summary>

							<!-- <dxi-sort-by-group-summary-info summaryItem="count"></dxi-sort-by-group-summary-info> -->
						</dx-data-grid>
					</div>

					<div *ngIf="checkMasterDetails(masterDetailItem,'extraCommissionBasedRules')">
						<dx-data-grid #extraRebatesBasedRulesDataGrid id="gridContainer"
							[dataSource]="extraRebatesBasedRulesDataSource" keyExpr="criterion" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[filterSyncEnabled]="true" [remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxi-column dataField="condition" dataType="string" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" dataType="string" caption="Criterion"></dxi-column>
							<dxi-column dataField="rebatesType" dataType="string" caption="Rebate Type"></dxi-column>
							<dxi-column dataField="rebatesRate" dataType="number" alignment="center" caption="Rebate Rate (%)">
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number"
								[calculateDisplayValue]="getCurrencyDisplayValue"
								[caption]="'Rebate Amount ('+globalCurrencySymbol+')'" [width]="180">
								<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format> -->
								<div *dxTemplate="let element of 'cellTemplateRebatesAmount'">
									{{element.data.commissionAmount || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Sub Total: {0}"
									[customizeText]="doCustomizeSubTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>

					<div *ngIf="checkMasterDetails(masterDetailItem,'arcreditmemos')">
						<dx-data-grid #arcreditmemosDataGrid id="gridContainer" [dataSource]="arcreditmemosDataSource"
							keyExpr="clientCode" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							[columnAutoWidth]="true" [width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxi-column dataField="invoiceCode" dataType="string" [groupIndex]="1" [allowSorting]="true"
								[autoExpandGroup]="false" caption="Invoice"></dxi-column>
							<dxi-column dataField="clientName" dataType="string" caption="Customer Name"></dxi-column>
							<dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku"
								[visible]="!enable_classification_for_rebate" caption="Item"></dxi-column>
							<dxi-column dataField="itemname" dataType="string" caption="Item"
								[visible]="!enable_classification_for_rebate">
							</dxi-column>
							<dxi-column dataField="description" dataType="string" [visible]="!enable_classification_for_rebate"
								caption="Description">
							</dxi-column>
							<dxi-column dataField="classname" dataType="string" caption="Class"
								[visible]="enable_classification_for_rebate">
							</dxi-column>
							<dxi-column dataField="linetotal" dataType="number"
								[caption]="'Line Total ('+globalCurrencySymbol+')'">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateLineTotal'">
									{{element.data.linetotal || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column>
							<dxi-column dataField="rebatesType" dataType="string" caption="Rebate Type"></dxi-column>
							<dxi-column dataField="condition" dataType="string" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" dataType="string" caption="Criterion"></dxi-column>
							<dxi-column dataField="rebatesRate" dataType="number" alignment="center" caption="Rebate Rate (%)">
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number"
								[calculateDisplayValue]="getCurrencyDisplayValue"
								[caption]="'Rebate Amount ('+globalCurrencySymbol+')'" [width]="180">
								<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format> -->
								<div *dxTemplate="let element of 'cellTemplateRebatesAmount'">
									{{element.data.commissionAmount || 0 | customCurrencyPipe:customCurrencyOption }}
								</div>
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat=" Sub Total: {0}"
									[customizeText]="doCustomizeSubTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>
				</div>
				<dxo-export [enabled]="false"></dxo-export>
			</dx-data-grid>
			<div class="row">
				<div class="cols10">
					<div class="txtLeft">
						<span>
							<span>Settlement Period : </span>
							<strong class="highlight">{{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} To
								{{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}</strong>
						</span>
					</div>
				</div>
				<div
					*ngIf="!(invoiceDetails.rebate_for === 'distribution' || invoiceDetails.rebate_for === 'distribution_differential')"
					class="cols2">
					<div class="txtRight">
						<dx-button class="secondaryBtn" text="Export" icon="xlsxfile"
							(onClick)="doExportToExcel($event)"></dx-button>
					</div>
				</div>
			</div>
			<div class="titleRow" *ngIf="isShowDatagrid">
				<h2 class="mrgB0">
					<strong class="">Revenue</strong>
				</h2>
			</div>

			<div *ngIf="isShowDatagrid">
				<dx-data-grid #revenueDataSourceRef id="revenueGridContainer" [dataSource]="listOfRevenues"
					(onToolbarPreparing)="doRevenueToolbarPreparing($event)" [showBorders]="true" [remoteOperations]="true"
					[allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'" [filterSyncEnabled]="true"
					[filterValue]="revenuefilterValue">
					<dxo-header-filter [visible]="false" [showRelevantValues]="true"></dxo-header-filter>
					<dxo-filter-row [visible]="false"></dxo-filter-row>
					<dxo-sorting mode="single"></dxo-sorting>
					<div *dxTemplate="let data of 'templateRevenueCounters'">
						<div class="revHighlight">
							<label>Average Revenue:
								<span class="revValue">
									{{revenueAverage || 0 | customFormatCurrencyPipe}}
								</span>
							</label>
							<span class="divPipe">|</span>
							<label>Total Revenue to Date:
								<span class="revValue">
									{{revenueAccuredTillDate || 0 | customFormatCurrencyPipe}}
								</span>
							</label>
							<span class="divPipe">|</span>
							<label>Estimated Total:
								<span class="revValue">
									{{revenueEstimatedTotal || 0 | customFormatCurrencyPipe}}
								</span>
							</label>
						</div>
					</div>

					<div *dxTemplate="let data of 'templateRevenueSettlementPeriod'">
						<div class="mrgR10">
							<span class="highlight pointerMark">
								Settlement Period : {{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} To
								{{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}
							</span>
						</div>
					</div>

					<dxi-column dataField="guid" caption="ID" [visible]="false" dataType="string"
						cellTemplate="cellTemplateGuid">
						<div *dxTemplate="let element of 'cellTemplateGuid'">
							{{ element.data.guid || '' }}
						</div>
					</dxi-column>

					<ng-container *ngIf="revenueColumnArray && revenueColumnArray.length > 0">
						<div *ngFor="let m of revenueColumnArray">
							<dxi-column [caption]="m.caption" [dataField]="m.dataField" [allowHeaderFiltering]="false"
								[allowFiltering]="false" [cssClass]="m.cssClass" cellTemplate="cellTemplateValue"
								alignment="right">
								<dxo-format type="currency" [precision]="2"></dxo-format>
							</dxi-column>
						</div>
					</ng-container>
					<div *dxTemplate="let element of 'cellTemplateValue'">
						{{element.value || 0 | customFormatCurrencyPipe}}
					</div>
				</dx-data-grid>
			</div>
			<div class="mrgT10"></div>
			<div
				*ngIf="invoiceDetails.rebate_for === 'distribution' || invoiceDetails.rebate_for === 'distribution_differential'">
				<dx-data-grid #itemsInvoicesDataSourceRef id="gridContainer" [dataSource]="itemsInvoicesDataSource"
					[showBorders]="true" (onToolbarPreparing)="doToolbarPreparing($event)"
					(onInitialized)="doInitializedItemInvoiceReport($event)"
					(onCellClick)="doCellClickForInvoiceDetails($event)" [filterValue]="filterValue" [showColumnLines]="true"
					[showRowLines]="false" [allowColumnReordering]="true" [allowColumnResizing]="true"
					[columnAutoWidth]="true" [width]="'100%'">
					<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>

					<div *dxTemplate="let data of 'templateSettlementPeriod'">
						<div class="mrgR10">
							<span class="highlight pointerMark">
								Settlement Period : {{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} To
								{{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}
							</span>
						</div>
					</div>

					<div *dxTemplate="let data of 'templateActionBtns'">
						<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
							[showArrowIcon]="false" text="Action"
							class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
							[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
							icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
							(onItemClick)="doActionsBtnItemClick($event)"
							[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
					</div>

					<dxi-column dataField="clientCode" dataType="string" caption="Customer Code"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateClientCode">
						<div *dxTemplate="let element of 'cellTemplateClientCode'">
							{{ element.data.clientCode || '' }}
						</div>
					</dxi-column>

					<dxi-column dataField="clientName" dataType="string" [allowSorting]="true" caption="Customer Name"
						[allowHeaderFiltering]="false">
					</dxi-column>

					<dxi-column caption="End-User Code" dataField="endusercode" dataType="string" [allowEditing]="false"
						[allowFiltering]="true" [allowHeaderFiltering]="false">
					</dxi-column>

					<dxi-column caption="End-User Name" dataField="endusername" dataType="string" [allowEditing]="false"
						[allowFiltering]="true" [allowHeaderFiltering]="false">
					</dxi-column>

					<dxi-column dataField="itemsku" dataType="string" caption="SKU" [visible]="isQBOnlineErpUser"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemsku">
						<div *dxTemplate="let element of 'cellTemplateItemsku'">
							{{ element.data.itemsku || '' }}
						</div>
					</dxi-column>

					<dxi-column dataField="itemname" dataType="string" caption="Item" cellTemplate="cellTemplateItemname"
						[allowHeaderFiltering]="false">
						<div *dxTemplate="let element of 'cellTemplateItemname'">
							{{ element.data.itemname }}
						</div>
					</dxi-column>

					<dxi-column dataField="description" dataType="string" caption="Description"
						cellTemplate="cellTemplateDescription" [allowHeaderFiltering]="false" [width]="330">
						<div *dxTemplate="let element of 'cellTemplateDescription'">
							{{ element.data.description || ''}}
						</div>
					</dxi-column>

					<dxi-column dataField="tier_basis" dataType="string" caption="Rebate Type"
						cellTemplate="cellTemplateTierBasis" [allowFiltering]="false" [allowHeaderFiltering]="true"
						[allowSorting]="true">
						<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter">
						</dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateTierBasis'">
							{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label' | uppercase}}
						</div>
					</dxi-column>

					<dxi-column dataField="rebate_basis" dataType="string" caption="Rebate Basis"
						cellTemplate="cellTemplateRebateBasis" [allowFiltering]="false" [allowHeaderFiltering]="true"
						[allowSorting]="true">
						<dxo-header-filter [dataSource]="listOfRebateBasisHeaderFilter">
						</dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateRebateBasis'">
							{{element.data.rebate_basis || '' | filterFromList:listOfRebateBasis : 'code' : 'label' |
							uppercase}}
						</div>
					</dxi-column>

					<dxi-column dataField="commissionRate" dataType="number"
						[caption]="'Rebate Rate (% | '+ globalCurrencySymbol+')'" alignment="center"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateCommitionsRate">
						<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
							<span *ngIf="element.data.commissionRate">
								<span *ngIf="element.data.commissionRateIn === '$'">
									{{element.data.commissionRate || 0 | customCurrencyPipe:customCurrencyOption}}
								</span>
								<span *ngIf="element.data.commissionRateIn === '%'">
									{{element.data.commissionRate + element.data.commissionRateIn}}
								</span>
							</span>
						</div>
					</dxi-column>

					<dxi-column dataField="appliedTier" dataType="string" caption="Tier" alignment="center"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateChoosenTier">
						<div *dxTemplate="let element of 'cellTemplateChoosenTier'">
							<span *ngIf="element.data.appliedTier">
								{{element.data.appliedTier}}
							</span>
						</div>
					</dxi-column>

					<dxi-column dataField="commissionAmount" dataType="number"
						[caption]="(invoiceDetails.rebate_for === 'distribution_differential' ? 'Difference (Rebate Owed) ' : 'Rebate Amount ') + '('+globalCurrencySymbol+')'"
						[calculateDisplayValue]="getCurrencyDisplayValue" [allowHeaderFiltering]="false">
						<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format> -->
					</dxi-column>

					<dxi-column dataField="document_type" dataType="string" [allowSorting]="true" caption="Doc. Type"
						[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateDocType">
						<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
						</dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateDocType'">
							{{element.data.document_type | filterFromList:listOfDocTypeHeaderFilter : 'value' : 'text' }}
						</div>
					</dxi-column>

					<dxi-column dataField="invoiceCode" dataType="string" [allowSorting]="true" caption="Doc. No"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateInvoiceCode">
						<div *dxTemplate="let element of 'cellTemplateInvoiceCode'">
							<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
						</div>
					</dxi-column>

					<dxi-column dataField="uomcode" dataType="string" caption="Doc. UOM" cellTemplate="cellTemplateUom"
						[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true">
						<dxo-header-filter [dataSource]="listOfUomsHeaderFilter"> </dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateUom'">
							{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase }}
						</div>
					</dxi-column>

					<dxi-column dataField="quantity" dataType="string" caption="Doc. Qty" dataType="number"
						cellTemplate="cellTemplateQuantity" [allowHeaderFiltering]="false">
						<div *dxTemplate="let element of 'cellTemplateQuantity'">
							{{element.data.quantity || '' }}
						</div>
					</dxi-column>

					<dxi-column dataField="contract_selectedUom" dataType="string" caption="Contract UOM"
						cellTemplate="cellTemplateContractUom" [allowFiltering]="false" [allowHeaderFiltering]="true"
						[allowSorting]="true">
						<dxo-header-filter [dataSource]="listOfUomsHeaderFilter"> </dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateContractUom'">
							{{element.data.contract_selectedUom || '' | filterFromList:listOfUoms :'code' : 'name' |
							uppercase }}
						</div>
					</dxi-column>

					<dxi-column dataField="contract_quantity" dataType="string" caption="Contract Qty"
						cellTemplate="cellTemplateContractQty" [allowHeaderFiltering]="false">
						<div *dxTemplate="let element of 'cellTemplateContractQty'">
							{{element.data.contract_quantity || ''}}
						</div>
					</dxi-column>

					<dxi-column dataField="price" dataType="number" [calculateDisplayValue]="getCurrencyDisplayValue"
						[caption]="invoiceDetails.rebate_for === 'distribution_differential' ? 'Price (Master Price List)' : 'Price'"
						[allowHeaderFiltering]="false">
						<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
					</dxi-column>

					<dxi-column dataField="baseCommissionAmount" caption="Base Rebate Amount" dataType="number"
						[calculateDisplayValue]="getCurrencyDisplayValue" [allowHeaderFiltering]="false">
						<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
					</dxi-column>

					<dxi-column dataField="differential_price" dataType="number"
						caption="Differential Price List Price (Actual Price Paid)"
						[calculateDisplayValue]="getCurrencyDisplayValue" [allowHeaderFiltering]="false"
						[visible]="invoiceDetails.rebate_for === 'distribution_differential'">
						<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
					</dxi-column>

					<dxi-column dataField="linetotal" dataType="number" [caption]="'Line Total ('+globalCurrencySymbol+')'"
						[allowHeaderFiltering]="false" [calculateDisplayValue]="getCurrencyDisplayValue">
						<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
					</dxi-column>

					<dxi-column dataField="in_pricelist" caption="In Price List" dataType="boolean" [allowEditing]="true"
						[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateInPricelist">
						<dxo-header-filter [dataSource]="listOfInPricelist" valueExpr="value" displayExpr="text">
						</dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateInPricelist'">
							{{element.data.in_pricelist ? 'Yes':'No'}}
						</div>
					</dxi-column>

					<dxo-summary>
						<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Rebate Total: {0}"
							[customizeText]="doCustomizeTotalSummary">
							<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format> -->
						</dxi-total-item>
						<dxi-total-item column="quantity" summaryType="sum" displayFormat="Total Qty: {0}">
						</dxi-total-item>
						<dxi-total-item *ngIf="rebate_basis === 'percentage'" column="finalAmount" summaryType="sum"
							displayFormat=" Sub Total: {0}" [customizeText]="doCustomizeSubTotalSummary">
							<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format> -->
						</dxi-total-item>
						<dxi-total-item column="linetotal" summaryType="sum" displayFormat="Sub Total: {0}"
							[customizeText]="doCustomizeSubTotalSummary">
							<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format> -->
						</dxi-total-item>
					</dxo-summary>
				</dx-data-grid>
			</div>
			<div
				*ngIf="!(invoiceDetails.rebate_for === 'distribution' || invoiceDetails.rebate_for === 'distribution_differential')">
				<dx-data-grid #itemsInvoicesDataSourceRef id="gridContainer" [dataSource]="itemsInvoicesDataSource"
					[remoteOperations]="true" [showBorders]="true" (onToolbarPreparing)="doToolbarPreparing($event)"
					[masterDetail]="{ enabled: true, template: 'invoiceDetailsTemplate','autoExpandAll': false }"
					[filterValue]="filterValue" [allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'">
					<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-sorting mode="single"></dxo-sorting>
					<div *dxTemplate="let data of 'templateSettlementPeriod'">
						<div class="mrgR10">
							<span class="highlight pointerMark">
								Settlement Period : {{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} To
								{{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}
							</span>
						</div>
					</div>
					<!-- <dxi-column dataField="name"
					caption="Settlement Period : {{invoiceDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} To {{invoiceDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}">
				</dxi-column> -->

					<dxi-column dataField="clientCode" dataType="string" caption="Code" [visible]="false"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateClientCode">
						<div *dxTemplate="let element of 'cellTemplateClientCode'">
							{{ element.data.clientCode || '' }}
						</div>
					</dxi-column>

					<dxi-column dataField="itemsku" dataType="string" [visible]="!enable_classification_for_rebate"
						caption="SKU" [visible]="isQBOnlineErpUser" [allowHeaderFiltering]="false"
						cellTemplate="cellTemplateItemsku">
						<div *dxTemplate="let element of 'cellTemplateItemsku'">
							{{ element.data.itemsku || '' }}
						</div>
					</dxi-column>

					<dxi-column dataField="itemname" dataType="string" [visible]="!enable_classification_for_rebate"
						caption="Item" cellTemplate="cellTemplateItemname" [allowHeaderFiltering]="false">
						<div *dxTemplate="let element of 'cellTemplateItemname'">
							{{ element.data.itemname }}
						</div>
					</dxi-column>

					<dxi-column dataField="description" dataType="string" [visible]="!enable_classification_for_rebate"
						caption="Description" cellTemplate="cellTemplateDescription" [allowHeaderFiltering]="false"
						[width]="330">
						<div *dxTemplate="let element of 'cellTemplateDescription'">
							{{ element.data.description || ''}}
						</div>
					</dxi-column>

					<dxi-column dataField="classname" dataType="string" [visible]="enable_classification_for_rebate"
						caption="Class" cellTemplate="cellTemplateClassName" [allowHeaderFiltering]="false">
						<div *dxTemplate="let element of 'cellTemplateClassName'">
							{{ element.data.classname }}
						</div>
					</dxi-column>

					<dxi-column dataField="tier_basis" dataType="string" caption="Rebate Type"
						cellTemplate="cellTemplateTierBasis" [allowFiltering]="false" [allowHeaderFiltering]="true"
						[allowSorting]="true">
						<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter">
						</dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateTierBasis'">
							{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label' | uppercase}}
						</div>
					</dxi-column>

					<dxi-column dataField="rebate_basis" dataType="string" caption="Rebate Basis"
						cellTemplate="cellTemplateRebateBasis" [allowFiltering]="false" [allowHeaderFiltering]="true"
						[allowSorting]="true">
						<dxo-header-filter [dataSource]="listOfRebateBasisHeaderFilter">
						</dxo-header-filter>
						<div *dxTemplate="let element of 'cellTemplateRebateBasis'">
							{{element.data.rebate_basis || '' | filterFromList:listOfRebateBasis : 'code' : 'label' |
							uppercase}}
						</div>
					</dxi-column>

					<dxi-column dataField="commissionRate" dataType="number"
						[caption]="'Rebate Rate (% | '+ globalCurrencySymbol+')'" alignment="center"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateCommitionsRate">
						<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
							<span *ngIf="element.data.commissionRate">
								<span *ngIf="element.data.commissionRateIn === '$'">
									{{element.data.commissionRate || 0 | customCurrencyPipe:customCurrencyOption}}
								</span>
								<span *ngIf="element.data.commissionRateIn === '%'">
									{{element.data.commissionRate + element.data.commissionRateIn}}
								</span>
							</span>
						</div>
					</dxi-column>

					<dxi-column dataField="appliedTier" dataType="string" caption="Tier" alignment="center"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateChoosenTier">
						<div *dxTemplate="let element of 'cellTemplateChoosenTier'">
							<span *ngIf="element.data.appliedTier">
								{{element.data.appliedTier}}
							</span>
						</div>
					</dxi-column>

					<dxi-column dataField="commissionAmountBeforeSplit" dataType="number"
						[caption]="'Rebate Amount ('+globalCurrencySymbol+')'"
						[visible]="invoiceDetails.rebate_for === 'buyinggroup' && invoiceDetails.paymentType ==='split'"
						[allowHeaderFiltering]="false" [calculateDisplayValue]="getCurrencyDisplayValue">
						<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
					</dxi-column>

					<dxi-column dataField="split_percentage" dataType="number" caption="Split (%)"
						[visible]="invoiceDetails.rebate_for === 'buyinggroup' && invoiceDetails.paymentType ==='split'"
						[allowHeaderFiltering]="false" cellTemplate="cellTemplateSplitPercentage">
						<div *dxTemplate="let element of 'cellTemplateSplitPercentage'">
							{{element.data.split_percentage || 0 | customNumberNoRoundingPipe }}%
						</div>
					</dxi-column>

					<dxi-column dataField="commissionAmount" dataType="number"
						[caption]="'Rebate Amount ('+globalCurrencySymbol+')'"
						[calculateDisplayValue]="getCurrencyDisplayValue" [allowHeaderFiltering]="false">
						<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
					</dxi-column>

					<dxo-summary>
						<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Rebate Total: {0}"
							[customizeText]="doCustomizeTotalSummary">
							<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format> -->
						</dxi-total-item>
					</dxo-summary>
					<!-- <dxo-summary>
					<dxi-total-item column="name" alignment="right"
						displayFormat=" Total Rebate : {{invoiceDetails.settlementValue || 0 | customCurrencyPipe }}">
					</dxi-total-item>
				</dxo-summary> -->

					<div *dxTemplate="let invoice of 'invoiceDetailsTemplate'">
						<dx-data-grid #invoicesDataGrid [dataSource]="invoice.data.invoices" keyExpr="invoiceCode"
							[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[remoteOperations]="false" [columnAutoWidth]="true"
							(onCellClick)="doCellClickForInvoiceDetails($event)">
							<dxo-sorting mode="single"></dxo-sorting>
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>

							<dxi-column dataField="document_type" dataType="string" [allowSorting]="true" caption="Doc. Type"
								[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateDocType">
								<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
								</dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateDocType'">
									{{element.data.document_type | filterFromList:listOfDocTypeHeaderFilter : 'value' : 'text' }}
								</div>
							</dxi-column>

							<dxi-column dataField="itemname" dataType="string" [allowSorting]="true"
								[visible]="enable_classification_for_rebate" caption="Items" [allowHeaderFiltering]="false">
							</dxi-column>

							<dxi-column dataField="invoiceCode" dataType="string" [allowSorting]="true" caption="Doc. No"
								[allowHeaderFiltering]="false" cellTemplate="cellTemplateInvoiceCode">
								<div *dxTemplate="let element of 'cellTemplateInvoiceCode'">
									<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
								</div>
							</dxi-column>

							<dxi-column dataField="clientCode" dataType="string" [allowSorting]="true" caption="Customer Code"
								[allowHeaderFiltering]="false">
							</dxi-column>

							<dxi-column dataField="clientName" dataType="string" [allowSorting]="true" caption="Customer Name"
								[allowHeaderFiltering]="false">
							</dxi-column>

							<dxi-column dataField="cardcode" [visible]="isShowCardcodeColumn" dataType="string"
								[allowSorting]="true" caption="Line Item Customer Code" [allowHeaderFiltering]="false">
							</dxi-column>

							<dxi-column dataField="uomcode" dataType="string" caption="Doc. UOM" cellTemplate="cellTemplateUom"
								[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true">
								<dxo-header-filter [dataSource]="listOfUomsHeaderFilter"> </dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateUom'">
									{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase }}
								</div>
							</dxi-column>

							<dxi-column dataField="quantity" dataType="string" caption="Doc. Qty" dataType="number"
								cellTemplate="cellTemplateQuantity" [allowHeaderFiltering]="false">
								<div *dxTemplate="let element of 'cellTemplateQuantity'">
									{{element.data.quantity || '' }}
								</div>
							</dxi-column>

							<dxi-column dataField="contract_selectedUom" dataType="string" caption="Contract UOM"
								[visible]="invoice.data.rebate_basis === 'dollarperuom'" cellTemplate="cellTemplateContractUom"
								[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true">
								<dxo-header-filter [dataSource]="listOfUomsHeaderFilter"> </dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateContractUom'">
									{{element.data.contract_selectedUom || '' | filterFromList:listOfUoms :'code' : 'name' |
									uppercase }}
								</div>
							</dxi-column>

							<dxi-column dataField="contract_quantity" dataType="string" caption="Contract Qty"
								[visible]="invoice.data.rebate_basis === 'dollarperuom'" cellTemplate="cellTemplateContractQty"
								[allowHeaderFiltering]="false">
								<div *dxTemplate="let element of 'cellTemplateContractQty'">
									{{element.data.contract_quantity || ''}}
								</div>
							</dxi-column>

							<dxi-column dataField="linetotal" dataType="number"
								[caption]="'Line Total ('+globalCurrencySymbol+')'"
								[calculateDisplayValue]="getCurrencyDisplayValue" [allowHeaderFiltering]="false">
								<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
							</dxi-column>

							<dxi-column dataField="cashdiscount" [caption]="'Cash Discount ('+globalCurrencySymbol+')'"
								dataType="number" [calculateDisplayValue]="getCurrencyDisplayValue"
								[visible]="invoice.data.rebate_basis === 'percentage'" [allowHeaderFiltering]="false">
								<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
							</dxi-column>

							<dxi-column dataField="miscdeductioninventory" caption="Misc. Deduction (%)" dataType="number"
								[visible]="invoice.data.rebate_basis === 'percentage'" cellTemplate="cellTemplateMiscDeduction"
								[allowHeaderFiltering]="false">
								<div *dxTemplate="let element of 'cellTemplateMiscDeduction'">
									{{element.data.miscdeductioninventory || 0 | customNumberNoRoundingPipe }}%
								</div>
							</dxi-column>

							<dxi-column dataField="miscDedAmount"
								[caption]="'Misc. Deduction Amount ('+globalCurrencySymbol+')'" dataType="number"
								[calculateDisplayValue]="getCurrencyDisplayValue"
								[visible]="invoice.data.rebate_basis === 'percentage'" [allowHeaderFiltering]="false">
								<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
							</dxi-column>

							<dxi-column dataField="finalAmount" [caption]="'Final Line Total ('+globalCurrencySymbol+')'"
								dataType="number" [calculateDisplayValue]="getCurrencyDisplayValue"
								[visible]="invoice.data.rebate_basis === 'percentage'" [allowHeaderFiltering]="false">
								<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
							</dxi-column>

							<dxo-summary>
								<dxi-total-item column="quantity" summaryType="sum" displayFormat="Total Qty: {0}">
								</dxi-total-item>
								<dxi-total-item *ngIf="invoice.data.rebate_basis === 'percentage'" column="finalAmount"
									summaryType="sum" displayFormat=" Sub Total: {0}"
									[customizeText]="doCustomizeSubTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-total-item>
								<dxi-total-item *ngIf="invoice.data.rebate_basis !== 'percentage'" column="linetotal"
									summaryType="sum" displayFormat=" Sub Total: {0}"
									[customizeText]="doCustomizeSubTotalSummary">
									<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format> -->
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>
				</dx-data-grid>
			</div>
			<div class="mrgT20"></div>

			<div *ngIf="isShowRebateDatagrid">
				<dx-data-grid #rebateDataSourceRef id="rebateGridContainer" [dataSource]="listOfRebates"
					(onToolbarPreparing)="doRebateToolbarPreparing($event)" [showBorders]="true" [remoteOperations]="true"
					[allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'" [filterSyncEnabled]="true"
					[filterValue]="rebatefilterValue">

					<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
					<dxo-header-filter [visible]="false" [showRelevantValues]="true"></dxo-header-filter>
					<dxo-filter-row [visible]="false"></dxo-filter-row>
					<dxo-sorting mode="single"></dxo-sorting>

					<div *dxTemplate="let data of 'templateRebateCounters'">
						<div class="revHighlight">
							<label>Average Rebate:
								<span class="revValue">
									{{rebateAverage || 0 | customFormatCurrencyPipe}}
								</span>
							</label>
							<span class="divPipe">|</span>
							<label>Total Rebate to Date:
								<span class="revValue">
									{{rebateAccuredTillDate || 0 | customFormatCurrencyPipe}}
								</span>
							</label>
							<span class="divPipe">|</span>
							<label>Estimated Total:
								<span class="revValue">
									{{rebateEstimatedTotal || 0 | customFormatCurrencyPipe}}
								</span>
							</label>
						</div>
					</div>

					<dxi-column dataField="guid" caption="ID" [visible]="false" dataType="string"
						cellTemplate="cellTemplateGuid">
						<div *dxTemplate="let element of 'cellTemplateGuid'">
							{{ element.data.guid || '' }}
						</div>
					</dxi-column>

					<ng-container *ngIf="rebateColumnArray && rebateColumnArray.length > 0">
						<div *ngFor="let m of rebateColumnArray">
							<dxi-column [caption]="m.caption" [dataField]="m.dataField" [dataType]="m.dataType"
								[calculateDisplayValue]="m.calculateDisplayValue" [allowHeaderFiltering]="false"
								[allowFiltering]="false" [cssClass]="m.cssClass" cellTemplate="cellTemplateValue"
								alignment="right">
								<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"></dxo-format> -->
							</dxi-column>
						</div>
					</ng-container>

					<div *dxTemplate="let element of 'cellTemplateValue'">
						{{element.value || 0 | customFormatCurrencyPipe}}
					</div>
				</dx-data-grid>
			</div>
		</div>
	</div>
</div>

<app-view-invoice-detail-popup *ngIf="isShowInvoiceDetailPopUp" [viewInvoiceDetailProps]="viewInvoiceDetailProps">
</app-view-invoice-detail-popup>

<app-view-end-users-invoice-detail-popup *ngIf="isShowEndUserInvoiceDetailPopUp"
	[viewEndusersInvoiceDetailProps]="viewEndUserInvoiceDetailProps">
</app-view-end-users-invoice-detail-popup>