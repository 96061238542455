import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AccountDetails } from "@app/models/auth";
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { GlobalStateService } from "@app/services/global-state/global-state.service";
import { LoaderService } from "@app/services/loaderservices/loader.service";
import { LoginService } from "@app/services/login.service";
import { RestApiService } from "@app/services/rest-service/rest-api.service";
import { UserIdleService } from "angular-user-idle";
import { NgxPermissionsService } from "ngx-permissions";
import { Subscription } from "rxjs";

@Component({
	selector: "app-logout",
	templateUrl: "./logout.component.html",
	styleUrls: ["./logout.component.css"]
})
export class LogoutComponent implements OnInit, OnChanges, OnDestroy {
	@Input() portalType: string;
	@Output() closeLogoutPopup = new EventEmitter<string>();
	public first_name: string;
	public last_name: string;
	public role_name: string;
	public customer_no: string;
	public isAdminUser: boolean;

	private doLogoutReqSbsn: Subscription;
	constructor(private _Router: Router,
		private _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _GlobalStateService: GlobalStateService,
		private _UserIdleService: UserIdleService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _RestApiService: RestApiService) {
		console.log('portalType', this.portalType);
	}

	ngOnInit() {
		//Stop Idle Timer 
		this.getUserInfo();
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges.portalType) {
			if (_SimpleChanges.portalType.currentValue) {
				console.log('_SimpleChanges.portalType.currentValue', _SimpleChanges.portalType.currentValue)
			}
		}
	}

	ngOnDestroy() {
		if (this.doLogoutReqSbsn) {
			this.doLogoutReqSbsn.unsubscribe();
		}
	}

	public doLogoutPopup() {
		if (this._LoginService.loginUser && this._LoginService.loginUser.user) {

			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('logout', '1');

			if (this.doLogoutReqSbsn) {
				this.doLogoutReqSbsn.unsubscribe();
			}
			this._LoaderService.show();
			this.doLogoutReqSbsn = this._RestApiService.doLoginReqFormData(formData).subscribe({
				next: (response) => {
					this.closeLogoutPopup.emit('CLOSE_' + new Date().getTime());
					this._LoaderService.hide();
					this._AppCommonSrvc.doClearAllSetterGetter();
					this._NgxPermissionsService.flushPermissions();
					this._UserIdleService.stopTimer();
					this._UserIdleService.stopWatching();
					this._LoginService.loginUser = undefined;
					this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
					this._Router.navigate(['/auth/login'], { replaceUrl: true });
				}, error: (error) => {

					this._LoaderService.hide();
					console.error(error);
				}
			});
		} else {
			this.closeLogoutPopup.emit('CLOSE_' + new Date().getTime());
			this._Router.navigate(['/auth/login'], { replaceUrl: true });
		}
	}

	public doClosePopup() {
		this.closeLogoutPopup.emit('CLOSE_' + new Date().getTime());
	}

	// Method used to get User Information
	private getUserInfo() {
		let accountDetails: AccountDetails;
		try {
			accountDetails = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail));
			this.isAdminUser = this._LoginService.loginUser.account_detail.admin;
		} catch (e) { }

		if (Object.keys(accountDetails).length > 0) {
			this.first_name = accountDetails.first_name;
			this.last_name = accountDetails.last_name;
			this.role_name = accountDetails.role_name;
			this.customer_no = accountDetails.usr;
		}
	}

	public doPopupShowing(e) {
		e.component.content().classList.add("logOutPopupContent");
	}

}