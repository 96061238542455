<div class="loaderOverlay" [ngClass]="overlayClass ? overlayClass : ''">
	<div class="custLoader">
		<div class="loaderGif">
			<div class="dx-loadindicator-icon">
				<div class="dx-loadindicator-segment dx-loadindicator-segment7"></div>
				<div class="dx-loadindicator-segment dx-loadindicator-segment6"></div>
				<div class="dx-loadindicator-segment dx-loadindicator-segment5"></div>
				<div class="dx-loadindicator-segment dx-loadindicator-segment4"></div>
				<div class="dx-loadindicator-segment dx-loadindicator-segment3"></div>
				<div class="dx-loadindicator-segment dx-loadindicator-segment2"></div>
				<div class="dx-loadindicator-segment dx-loadindicator-segment1"></div>
				<div class="dx-loadindicator-segment dx-loadindicator-segment0"></div>
			</div>
		</div>
		<div class="loaderText" [innerHtml]="loaderText"></div>
	</div>
</div>