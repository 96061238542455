<div id="wrapper" class="loginScreens">
	<div class="loginContainer forgotPasswordContainer cf" *ngIf="forgotPasswordForm.isVerified">
		<div class="cols12 cf mrgB20 txtCenter">
			<a>
				<img [src]="_AppCommonSrvc.cdnImgPath + 'logo.png'" width="222" height="48" alt="IncentX" title="IncentX">
			</a>
		</div>
		<div class="regTitles">
			<h4>To reset your password</h4>
		</div>

		<div class="loginForm cf">
			<form autocomplete="off">
				<div class="formControls">
					<div class="row">
						<!-- <div class="cols12 inputDisable">
							<div class="feildCol loginIcon">
								<label class="icon-Userlogin"></label>
								<input type="text" [(ngModel)]="forgotPasswordForm.emailID" id="emailID" class=""
									[disabled]="true" placeholder="Mobile Number or Email ID" />
							</div>
						</div> -->
						<div class="cols12 inputDisable">
							<div class="dx-field mrgB20">
								<div class="dx-field-label">Email ID</div>
								<div class="dx-field-value resetPasswordInput">
									<dx-text-box [(value)]="forgotPasswordForm.emailID" [(mode)]="forgotPasswordForm.emailID"
										[disabled]="true">
										<dx-validator>
											<dxi-validation-rule type="required"
												message="Email ID is required"></dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<!-- <div class="cols12">
						<div class="feildCol passwordIcon loginIcon">
							<label class="icon-passwordlogin"></label>
							<input type="password" [(ngModel)]="forgotPasswordForm.password" id="authenticationCode" class=""
								placeholder="New Password 1" #password (onValueChanged)="doOnValueChangedNewPassword($event)"
								(onChange)="doOnChangeNewPassword($event)" />
							<span class="sprite"
								[ngClass]="{'hideEyeIcon' : password.type=='password','showEyeIcon' : password.type=='text'}"
								(click)="password.type=password.type=='password'?'text':'password'"></span>
						</div>
					</div> -->
						<div class="cols12">
							<div class="dx-field mrgB20">
								<div class="dx-field-label">New Password</div>
								<div class="dx-field-value resetPasswordInput">
									<dx-text-box [(value)]="forgotPasswordForm.password" [(mode)]="newpasswordMode"
										valueChangeEvent="keyup" (onValueChanged)="doOnValueChangedNewPassword($event)"
										(onChange)="doOnChangeNewPassword($event)">
										<dxi-button name="btn_new_password" location="after"
											[options]="newpasswordButton"></dxi-button>
										<dx-validator>
											<dxi-validation-rule type="required"
												message="New Password is required"></dxi-validation-rule>
											<!-- <dxi-validation-rule type="pattern" pattern="^(?=.*[A-Z]).{8,50}$"
												message="Must follow password pattern."> -->
											<!-- <dxi-validation-rule type="pattern" [pattern]="passwordPolicyRegex"
											message="Must follow password pattern.">
										</dxi-validation-rule> -->
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
						</div>

					</div>
					<div class="row">
						<!-- <div class="cols12 ">
							<div class="feildCol passwordIcon loginIcon">
								<label class="icon-passwordlogin"></label>
								<input type="password" [(ngModel)]="forgotPasswordForm.confirmpassword" id="authenticationCode"
									class="" placeholder="Confirm Password" #confirmpassword />
								<span class="sprite"
									[ngClass]="{'hideEyeIcon' : confirmpassword.type=='password','showEyeIcon' : confirmpassword.type=='text'}"
									(click)="confirmpassword.type=confirmpassword.type=='password'?'text':'password'"></span>
							</div>
						</div> -->
						<div class="cols12">
							<div class="dx-field relative mrgB20">
								<div class="dx-field-label">Confirm New Password</div>
								<div class="dx-field-value resetPasswordInput">
									<dx-text-box [(value)]="forgotPasswordForm.confirmpassword" [(mode)]="confirmpasswordMode">
										<dxi-button name="btn_confirm_password" location="after"
											[options]="confirmpasswordButton"></dxi-button>
										<dx-validator>
											<dxi-validation-rule type="required"
												message="Confirm Password is required"></dxi-validation-rule>
											<dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
												message="Password and Confirm Password do not match"></dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
								<div class="dx-field-value mrgT20">
									<span class="generatePass" (click)="newGeneratePassword()">
										Generate Password
									</span>
								</div>
							</div>
						</div>
						<div class="cols12 newPasswordPolicy">
							<h3>New Password Policy will be</h3>
							<ul class="pad0 mrgB0 newPasswordPolicyTwoColumn">
								<li
									[ngClass]="{'green':(strHasMinLength === 'T'),'red':(strHasMinLength === 'F') ,'':(strHasMinLength === '')}">
									<span
										[ngClass]="{'rightFontIcon':(strHasMinLength === 'T'),'closeFontIcon':(strHasMinLength === 'F'),'bulletPointIcon':(strHasMinLength === '') }"></span>
									Minimum length 12 characters
								</li>
								<li
									[ngClass]="{'green':(strHasUpper === 'T'),'red':(strHasUpper === 'F') ,'':(strHasUpper === '')}">
									<span
										[ngClass]="{'rightFontIcon':(strHasUpper === 'T'),'closeFontIcon':(strHasUpper === 'F'),'bulletPointIcon':(strHasUpper === '') }"></span>
									At least one uppercase letter
								</li>
								<li
									[ngClass]="{'green':(strHasLower === 'T'),'red':(strHasLower === 'F') ,'':(strHasLower === '')}">
									<span
										[ngClass]="{'rightFontIcon':(strHasLower === 'T'),'closeFontIcon':(strHasLower === 'F'),'bulletPointIcon':(strHasLower === '') }"></span>
									At least one lowercase letter
								</li>
								<li
									[ngClass]="{'green':(strHasNumber === 'T'),'red':(strHasNumber === 'F') ,'':(strHasNumber === '')}">
									<span
										[ngClass]="{'rightFontIcon':(strHasNumber === 'T'),'closeFontIcon':(strHasNumber === 'F'),'bulletPointIcon':(strHasNumber === '') }"></span>
									At least one number
								</li>
								<li
									[ngClass]="{'green':(strHasNonalphas === 'T'),'red':(strHasNonalphas === 'F') ,'':(strHasNonalphas === '')}">
									<span
										[ngClass]="{'rightFontIcon':(strHasNonalphas === 'T'),'closeFontIcon':(strHasNonalphas === 'F'),'bulletPointIcon':(strHasNonalphas === '') }"></span>
									At least one symbol
								</li>
							</ul>
						</div>
					</div>

					<div class="row">
						<div class="cols12">
							<div class="feildCol radioGroup mrgT0">
								<div class="radio">
									<input [(ngModel)]="forgotPasswordForm.verifythrough" (change)="changeVerifyThrough()"
										value="authyapp" name="verifythrough" type="radio" id="authyapp">
									<label for="authyapp" class="radio-label">Authy App</label>
									<input [(ngModel)]="forgotPasswordForm.verifythrough" (change)="changeVerifyThrough()"
										value="smsapp" name="verifythrough" type="radio" id="smsapp">
									<label for="smsapp" class="radio-label">SMS</label>
								</div>
							</div>
						</div>
					</div>

					<div class="row padL15">
						<label *ngIf="forgotPasswordForm.verifythrough === 'authyapp'">Please enter authentication code
							from Authy app.</label>
						<label *ngIf="forgotPasswordForm.verifythrough === 'smsapp' && forgotPasswordForm.mobile">Input the
							code
							we sent to
							{{forgotPasswordForm.mobile || ''}} to change
							your account.</label>
					</div>

					<div class="row">
						<!-- <div class="cols12">
							<div class="feildCol verificationCodeInput">
								<input type="text" [(ngModel)]="forgotPasswordForm.authenticationCode" id="authenticationCode"
									class="" placeholder="Authentication Code" />
							</div>
						</div> -->
						<div class="cols12">
							<div class="dx-field mrgB20">
								<div class="dx-field-value resetPasswordInput verificationCodeInput">
									<dx-text-box [(value)]="forgotPasswordForm.authenticationCode"
										[(mode)]="forgotPasswordForm.authenticationCode" placeholder="Authentication Code">
										<dx-validator>
											<dxi-validation-rule type="required"
												message="Authentication Code is required"></dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
						</div>
					</div>
					<div class="row forgotRow" *ngIf="forgotPasswordForm.verifythrough === 'smsapp'">
						<div class="cols12 txtRight" (click)="doResendCode()">
							<a class="forgotPass">Resend Code</a>
						</div>
					</div>
					<input (click)="verifyEmailAndAuthCode()" type="button" class="button payBtn cols12 mrgT10"
						value="Verify" />
				</div>
			</form>
		</div>
	</div>