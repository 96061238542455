<div class="rightBar">

	<div class="rightBlock">
		<!--Commission details content -->
		<div class="titleRow">
			<!-- <h1> {{editCommissionGuid ? 'Update' : 'Add New'}} Commission Agreement</h1> -->
		</div>
		<div class="contentRow cf">
			<div class="borderBox cf padB0">
				<!-- <div class="subTitleRow">
						<h2>Commission Details</h2>
					</div> -->
				<div class="formControls editAgreeDetail agreementtemplates mrgT10">
					<div class="row">
						<div [ngClass]="{'cols12': !enable_other_currencies,'cols9':enable_other_currencies}">
							<div class="dx-field">
								<div class="dx-field-label">Template Name</div>
								<div class="dx-field-value">
									<dx-text-box mode="text" [(value)]="commissionDetails.template_name"
										[disabled]="isDisablePermission" [showClearButton]="true">
									</dx-text-box>
								</div>
							</div>
						</div>
						<div class="cols3" *ngIf="enable_other_currencies">
							<div class="dx-field">
								<div class="dx-field-label">Currency</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-lookup [items]="listOfOtherCurrency" [displayExpr]="displayExprForCurrency"
										[value]="commissionDetails.currency_code" valueExpr="currency_code"
										[searchEnabled]="false" [searchPlaceholder]="''" [applyValueMode]="'instantly'"
										[cleanSearchOnOpening]="true" fieldTemplate="field" [showCancelButton]="false"
										itemTemplate="listItem" (onContentReady)="currencyLookUpContentReady($event)"
										(onValueChanged)="doSelectionChangedForCurrency($event)" [wrapItemText]="true"
										[disabled]="isDisablePermission" [elementAttr]="{ 'class': 'currencyDropdownIx' }">
										<dxo-drop-down-options title="Select Currency" [hideOnOutsideClick]="true" [height]="310"
											[maxHeight]="290" [showTitle]="false"></dxo-drop-down-options>
										<div *dxTemplate="let currency of 'field'">
											<div *ngIf="currency" class="custom-field d-flex">
												<span class="currency-flag mrgL10 mrgT3" [ngClass]="currency.icon"></span>
												<div class="flex-grow-1">
													<p class="mrgB0">{{ currency.currency_code}} -
														{{currency.erp_currency_name}}
														<span class="currencySymbol alignRight"
															[innerHtml]="currency.currency_symbol"></span>
													</p>
												</div>
											</div>
										</div>
										<div *dxTemplate="let currency of 'listItem'">
											<div class="custom-item d-flex currencyDropdownCustom-item">
												<span class="currency-flag mrgT10" [ngClass]="currency.icon"></span>
												<div class="flex-grow-1">
													<h5 class="mrgB0" [innerHtml]="currency.currency_code"></h5>
													<p class="mrgB0" [innerHtml]="currency.erp_currency_name"></p>
												</div>
												<span class="currencySymbol mrgT10" [innerHTML]="currency.currency_symbol"></span>
											</div>
										</div>
									</dx-lookup>
								</div>
							</div>
						</div>
						<div class="cols12 colsP0">
							<div class="cols3">
								<div class="dx-field labelRadio mrgT5">
									<div class="dx-field-label">Contract Type:</div>
									<div class="dx-field-value">
										<dx-radio-group [dataSource]="radioCommissionTypeDataList" displayExpr="text"
											valueExpr="value" (onValueChanged)="doCommissionTypeValueChanged($event)"
											valueChangeEvent="blur" [value]="commissionDetails.contract_type"
											[disabled]="isDisablePermission" layout="horizontal"> </dx-radio-group>
									</div>
								</div>
							</div>

							<div class="cols3">
								<div class="dx-field labelRadio mrgT5">
									<div class="dx-field-label">Calculation Basis:</div>
									<div class="dx-field-value">
										<dx-radio-group [dataSource]="listOfCalculationOnBasis" displayExpr="text"
											valueExpr="value" valueChangeEvent="blur"
											[(value)]="commissionDetails.calculationOnBasis"
											[disabled]="isDisablePermission || isDisabledCalculationOnBasisType"
											layout="horizontal">
										</dx-radio-group>
									</div>
								</div>
							</div>

							<div class="cols3">
								<div class="dx-field labelRadio mrgT5">
									<div class="dx-field-label">Customer/Item Match:</div>
									<div class="dx-field-value">
										<dx-radio-group [dataSource]="listOfCustomerItemMatch" displayExpr="text"
											valueExpr="value" valueChangeEvent="blur"
											[(value)]="commissionDetails.customer_item_match"
											[disabled]="isDisablePermission || isDisabledCustomerOrItemMatch || !isAddMode"
											layout="horizontal">
										</dx-radio-group>
									</div>
								</div>
							</div>

							<div class="cols3">
								<div class="dx-field labelRadio mrgT5">
									<div class="dx-field-label">Tiers Type:</div>
									<div class="dx-field-value">
										<dx-radio-group [dataSource]="listOfTiersType" displayExpr="text" valueExpr="value"
											valueChangeEvent="blur" [value]="commissionDetails.tiers_type" valueChangeEvent="blur"
											(onValueChanged)="doTiersTypeChanged($event)"
											[disabled]="isDisablePermission || isDisabledTierType || !isAddMode"
											layout="horizontal">
										</dx-radio-group>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="cols8">
							<div class="dx-field feildCol" [ngClass]="{inputDisable: isDisablePermission}">
								<div class="dx-field-label">Salespersons Name</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-drop-down-box [(value)]="salesPersonGridBoxValue" valueExpr="sub_account_id"
										[displayExpr]="displayExprSalesPersonName" placeholder="Select Salespersons Name..."
										[(opened)]="isSalesPersonDropDownBoxOpened" [showClearButton]="true"
										[deferRendering]="false" [dataSource]="salesPersonDataSource"
										(onOptionChanged)="onSalesPersonSelectionChanged($event)">
										<div *dxTemplate="let data of 'content'">
											<dx-data-grid [dataSource]="salesPersonDataSource" keyExpr="sub_account_id"
												[columns]="salespersonGridBoxColumns" [hoverStateEnabled]="true" height="100%"
												[(selectedRowKeys)]="salesPersonGridBoxValue">
												<dxo-selection mode="single"></dxo-selection>
												<dxo-filter-row [visible]="true"></dxo-filter-row>
												<dxo-scrolling mode="virtual"></dxo-scrolling>
												<dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
											</dx-data-grid>
										</div>
									</dx-drop-down-box>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="dx-field feildCol">
								<div class="dx-field-label">Start Date</div>
								<div class="dx-field-value">
									<dx-date-box [(value)]="commissionDetails.startDate"
										(onValueChanged)="setSettlementStartDate($event)" type="date" displayFormat="yyyy-MM-dd"
										[disabled]="isDisablePermission">
									</dx-date-box>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="dx-field feildCol">
								<div class="dx-field-label">End Date</div>
								<div class="dx-field-value">
									<dx-date-box [(value)]="commissionDetails.endDate" (onValueChanged)="compareTwoDates()"
										type="date" displayFormat="yyyy-MM-dd" [disabled]="isDisablePermission">
									</dx-date-box>
								</div>
							</div>
						</div> -->
						<div class="cols12 colsP0">
							<div class="cols2"
								*ngIf="commissionDetails.settlementPeriod !== 'monthly' && commissionDetails.settlementPeriod !== 'custom' && commissionDetails.settlementPeriod !== 'weekly' && commissionDetails.settlementPeriod !== 'biweekly'">
								<div class="dx-field mrgT15 mrgB10">
									<div class="dx-field-label">Contract Calendar</div>
									<div class="dx-field-value">
										<dx-select-box #contractCalendarSelectBoxRef [dataSource]="listOfContractCalendar"
											[(value)]="commissionDetails.contractCalendar" displayExpr="text" valueExpr="value"
											(onValueChanged)="setSettlementStartDate()" customItemCreateEvent="blur"
											placeholder="Contract Calendar">
										</dx-select-box>
									</div>
								</div>
							</div>

							<div class="cols2" *ngIf="commissionDetails.settlementPeriod !== 'custom'">
								<div class="dx-field mrgT15 mrgB10">
									<div class="dx-field-label">Start Settlement Period On</div>
									<div
										*ngIf="commissionDetails.settlementPeriod !== 'weekly' && commissionDetails.settlementPeriod !== 'biweekly'"
										class="dx-field-value">
										<dx-number-box [(value)]="commissionDetails.startSettlementPeriodOn"
											[disabled]="isDisablePermission" [showClearButton]="true"
											(onValueChanged)="setSettlementStartDate()" maxLength="2" [min]="1" [max]="30">
										</dx-number-box>
									</div>
									<div
										*ngIf="commissionDetails.settlementPeriod === 'weekly' || commissionDetails.settlementPeriod === 'biweekly'"
										class="dx-field-value">
										<dx-select-box #weekDaysSelectBoxRef [dataSource]="listOfWeekDays"
											[(value)]="commissionDetails.startSettlementPeriodWeekOfDayOn" displayExpr="text"
											(onValueChanged)="setSettlementStartDate()" valueExpr="value"
											customItemCreateEvent="blur" placeholder="Contract Calendar">
										</dx-select-box>
									</div>
								</div>
							</div>

							<div class=""
								[ngClass]="commissionDetails.settlementPeriod === 'monthly' || commissionDetails.settlementPeriod === 'custom' ? 'cols8' : 'cols7'">
								<div class="dx-field labelRadio mrgT5">
									<div class="dx-field-label">Settlement Period:</div>
									<div class="dx-field-value">
										<dx-radio-group [dataSource]="listOfSettlementPeriods" displayExpr="text"
											valueExpr="value" valueChangeEvent="blur"
											[(value)]="commissionDetails.settlementPeriod" [disabled]="isDisablePermission"
											layout="horizontal">
											<!-- (onValueChanged)="calculateSettlementPeriod(commissionDetails)" -->
										</dx-radio-group>
									</div>
								</div>
							</div>
						</div>
						<div class="cols12 colsP0">
							<div class="cols3" *ngIf="enable_commission_on_sales_orders_feature">
								<div class="dx-field labelRadio mrgT5">
									<div class="dx-field-label">Calculate Commission By:</div>
									<div class="dx-field-value">
										<dx-radio-group [dataSource]="radioSalesOrderOrInvoiceList" displayExpr="text"
											valueExpr="value" [(value)]="commissionDetails.calculate_commission_by"
											[disabled]="isDisablePermission" layout="horizontal">
										</dx-radio-group>
									</div>
								</div>
							</div>
							<div class="cols3">
								<div class="dx-field dx-lableset mrgT5">
									<div class="dx-field-label">Tier Mode:</div>
									<div class="dx-field-value" style="width:100%">
										<dx-radio-group [dataSource]="listOfTierModes" displayExpr="text" valueExpr="value"
											[(value)]="commissionDetails.tier_mode" (onValueChanged)="doChangeTierMode($event)"
											valueChangeEvent="blur" [disabled]="true" layout="horizontal">
										</dx-radio-group>
									</div>
								</div>
							</div>
							<div class="cols3" *ngIf="isShowTierAccrualPeriodField">
								<div class="dx-field mrgT15 mrgB10">
									<div class="dx-field-label">Tier Accrual Period</div>
									<div class="dx-field-value">
										<dx-select-box #tierAccrualPeriodSelectBoxRef [dataSource]="listOfTierAccrualPeriods"
											[(value)]="commissionDetails.tier_accrual_period" displayExpr="text"
											[disabled]="!isAddMode" valueExpr="value" [showClearButton]="true"
											placeholder="Tier Accrual Periods">
										</dx-select-box>
									</div>
								</div>
							</div>
							<div class="cols6">
								<div class="dx-field dx-lableset mrgT5">
									<div class="dx-field-label">Tiers Include:</div>
									<div class="dx-field-value" style="width: 100%;">
										<dx-radio-group [dataSource]="listOfTiersInclude" displayExpr="text" valueExpr="value"
											[(value)]="commissionDetails.tiers_include" layout="horizontal">
										</dx-radio-group>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="borderBox cf">
				<div class="tabbing cf">
					<ul>
						<li *ngIf="commissionDetails.contract_type == 'standard'" (click)="clickAgreementTab('exclusions')"
							[ngClass]="{active: currentAgreementTab == 'exclusions'}">
							<a
								*ngIf="!enable_classification_for_commision && !is_use_territory_for_commission_contract">Customers/Items</a>
							<a *ngIf="enable_classification_for_commision">Customers/Classes</a>
							<a *ngIf="is_use_territory_for_commission_contract">Territories</a>
						</li>

						<li *ngIf="commissionDetails.contract_type == 'growth_incentive'"
							(click)="clickAgreementTab('growth-incentive')"
							[ngClass]="{active: currentAgreementTab == 'growth-incentive'}">
							<a>Growth Incentive</a>
						</li>

						<li (click)="clickAgreementTab('rules')" [ngClass]="{active: currentAgreementTab == 'rules'}">
							<a>Rules</a>
						</li>
					</ul>
				</div>

				<!--#region Exclusions content -->
				<app-commission-tierbase-template-exclusions-tab
					(saveCommissionExclusionEvent)="saveCommissionExclusionData($event)"
					[commissionDetailsProps]="commissionDetails" [exclusionTabList]="exclusionTabList"
					*ngIf="currentAgreementTab == 'exclusions'">
				</app-commission-tierbase-template-exclusions-tab>
				<!--endregion-->

				<!--#region Commission Tierbase Growth Icentive tab content-->
				<app-commission-tierbase-growth-incentive-tab [commissionDetailsProps]="commissionDetails"
					(saveCommissionGrowthIncentiveEvent)="saveGrowthIncentiveData($event)"
					[growthIncentiveTabList]="growthIncentiveTabList" *ngIf="currentAgreementTab == 'growth-incentive'">
				</app-commission-tierbase-growth-incentive-tab>
				<!--endregion-->

				<!--#region Activities tab content-->
				<app-commission-tierbase-activities-tab
					(saveCommissionActivitiesEvent)="saveCommissionActivitiesData($event)"
					[activitiesTabList]="activitiesTabList" *ngIf="currentAgreementTab === 'activities'">
				</app-commission-tierbase-activities-tab>
				<!--endregion-->

				<!--#region Rules content -->
				<app-commission-tierbase-rules-tab [commissionDetailsProps]="commissionDetails"
					(saveCommissionRulesEvent)="saveCommissionRulesData($event)" [ruleTabList]="ruleTabList"
					[exclusionTabList]="exclusionTabList" [isCurrentTabPermission]="isCurrentTabPermission" *ngIf="currentAgreementTab ===
						'rules'">
				</app-commission-tierbase-rules-tab>
				<!--endregion-->

				<div class="alignRight mrgT10" *ngxPermissionsOnly="isCurrentTabPermission">
					<dx-button class="primaryBtn" type="normal" stylingMode="contained" icon="save" text="Submit"
						(onClick)="saveCommissionContract()" [useSubmitBehavior]="false">
					</dx-button>
				</div>
			</div>
		</div>
		<!--End commission details content -->
	</div>
</div>