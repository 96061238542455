<dx-popup [showTitle]="true" [width]="popupWidth" [height]="popupHeight" [title]="titleText" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" id="UploadClaimPopUp">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" width="100%" height="100%">
			<div class="forCsv">
				<div class="row dragdropfullwidth mrgT10">
					<div class="cols12 mrgB20" *ngIf="callFrom === 'sales'">
						<div class="dx-field feildCol">
							<div class="dx-field-label">Contract Name</div>
							<div class="dx-field-value" style="width: 100%;">
								<dx-drop-down-box [(value)]="contractGridBoxValue" valueExpr="guid"
									[displayExpr]="displayExprContractName" placeholder="Select Contract..."
									[(opened)]="isContractDropDownBoxOpened" [showClearButton]="true"
									[dataSource]="contractDataSource" (onValueChanged)="onContractValueChanged($event)"
									(onOptionChanged)="onContractOptionChanged($event)">
									<div *dxTemplate="let data of 'content'">
										<dx-data-grid #contractDataGrid [dataSource]="contractDataSource"
											[hoverStateEnabled]="true" [remoteOperations]="true" [wordWrapEnabled]="true"
											[showBorders]="true" [(selectedRowKeys)]="contractGridBoxValue"
											[filterSyncEnabled]="true" [filterValue]="contractFilterValue"
											(onSelectionChanged)="onSelectionChangedContract($event)" [height]="345">
											<dxo-header-filter [visible]="true"></dxo-header-filter>
											<dxo-filter-row [visible]="true"></dxo-filter-row>
											<dxo-selection mode="single"></dxo-selection>
											<dxo-scrolling mode="virtual" rowRenderingMode="virtual"
												[useNative]="false"></dxo-scrolling>
											<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
											<dxi-column dataField="guid" [visible]="false" caption="Guid" dataType="string">
											</dxi-column>
											<dxi-column dataField="is_active" [visible]="false" caption="Status"
												dataType="boolean">
											</dxi-column>
											<dxi-column dataField="contractType" [visible]="false" caption="Contract Type"
												dataType="string">
											</dxi-column>
											<dxi-column dataField="contractName" caption="Name" dataType="string"
												[allowHeaderFiltering]="false">
											</dxi-column>
											<dxi-column dataField="selectCustomerId" caption="Customer Code" dataType="string"
												[allowHeaderFiltering]="false">
											</dxi-column>
											<dxi-column dataField="startDate" alignment="center" caption="Start Date"
												dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateStartDate"
												[allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateStartDate'">
													{{ (element.data.startDate !== null && element.data.startDate !== '' &&
													element.data.startDate !==
													undefined && element.data.startDate !== 'Invalid date') ?
													(element.data.startDate | date:
													'yyyy-MM-dd') : "" }}
												</div>
											</dxi-column>

											<dxi-column dataField="endDate" alignment="center" caption="End Date" dataType="date"
												format="yyyy-MM-dd" cellTemplate="cellTemplateEndDate"
												[allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateEndDate'">
													{{ (element.data.endDate !== null && element.data.endDate !== '' &&
													element.data.endDate !==
													undefined && element.data.endDate !== 'Invalid date') ? (element.data.endDate
													| toDateObj | date: 'yyyy-MM-dd')
													: "" }}
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
								</dx-drop-down-box>
							</div>
						</div>
					</div>

					<div class="cols6">
						<div class="feildCol inputDisableFile mrgT0">
							<div class="fileUpbtn">
								<input type="file" [(ngModel)]="chargeDocumentsTabModel.documentURL"
									accept=".doc,.docx,.csv,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
									(change)="uploadDocument($any($event.target).files)" />
								<span class="fileNamelabel">{{chargeDocumentsTabModel.documentURL}}</span>
								<span class="upldBtn">Browse</span>
							</div>
							<div class="inputField">
								<label for="rate">Reference Document</label>
							</div>
						</div>
					</div>

					<div [ngClass]="enable_chargeback_look_back_period ? 'cols4' : 'cols6'">
						<div class="dx-field">
							<div class="dx-field-label"> Document Reference No.</div>
							<dx-text-box mode="text" [(value)]="chargeDocumentsTabModel.documentReferenceNumber"
								maxLength="500">
							</dx-text-box>
						</div>
					</div>

					<div class="cols2" *ngIf="enable_chargeback_look_back_period">
						<div class="datePicker">
							<dx-date-box [(value)]="claim_date" [max]="maxDateValue" type="date" displayFormat="yyyy-MM-dd">
							</dx-date-box>
							<div class="inputField inputFieldFilterLbl">
								<label for="inputFieldFilterLbl">Claim Date</label>
							</div>
						</div>
					</div>

					<div class="cols12">
						<div class="dx-field">
							<div class="dx-field-label"> User Note</div>
							<div class="dx-field-value">
								<dx-text-area [height]="90" [maxLength]="500"
									[(value)]="chargeDocumentsTabModel.claimedUserNote" name="description">
								</dx-text-area>
							</div>
						</div>
					</div>

					<div class="cols12 colDrag mrgT10">
						<h2>
							Upload Claim File
						</h2>
						<div class="noteBx mrgB20 mrg0 width100p">
							<span>
								<strong>Note:</strong>
								Required column titles: <i>customer_code, item, uom </i>
								<i *ngIf="billbackType === 'enduserschargeback'">,end_user / pricelist_no</i>
								and
								<i>quantity</i>.
							</span>
							<span>
								Optional column title: <i>shortpay_code, amount</i>.
							</span>
						</div>
						<div class="center">
							<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="doOnFileDrop($event)"
								[showBrowseBtn]="false" accept="csv" [multiple]="false" class="fileUploadInput">
								<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
									<div class="csvUpload">
										<div class="uploadLeft">
											<span class="icon-upload"></span>
											<h4>Drag & Drop XLS | XLSX | CSV File Here</h4>
										</div>
										<span class="orStyle">OR</span>
										<div class="uploadRight">
											<button type="button" (click)="openFileSelector()" class="addFileInput"><span
													class="addIcon"></span>
												Browse Files</button>
											<span class="supprtInst">Supported up to 25 MB</span>
										</div>
									</div>
								</ng-template>
							</ngx-file-drop>
							<!-- <input type="file" (change)="changeListener($event)" #input
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
						</div>

						<div *ngIf="uploadedCsvFilesData">
							<div class="fileImage">
								<span class="icon-csv_file"></span>
							</div>
							<div class="fileDetail">
								<h4>{{uploadedCsvFilesData?.name }}</h4>
								<div class="fileProgress">
									<span></span>
								</div>
								<div class="fileProgText">
									{{ uploadedCsvFilesData?.size }} bytes
									<!-- <span>100% uploaded</span> -->
								</div>
								<span class="sprite closeIcon" (click)="doRemoveUploadFiles()"></span>
							</div>
						</div>

					</div>
					<!-- short pay task list section -->
					<div class="cols12" *ngIf="shortPayTaskDataSource?.length > 0">
						<h2 class="mrgT10">
							<!-- Do you want this claim to settle an open short pay task? -->
							Short Pay Task List
						</h2>
						<!-- <dx-tag-box [value]="selectedListOfShortPayTaskTags" [grouped]="true" class="dx-ix removedCloseIcon">
					</dx-tag-box> -->
						<dx-data-grid #listOfShortPayTaskDataGrid [dataSource]="shortPayTaskDataSource" keyExpr="claimnum"
							[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[hoverStateEnabled]="true" [height]="345" [(selectedRowKeys)]="selectedListOfShortPayTask"
							[showBorders]="true" (onSelectionChanged)="doChangeDatagridShortPayTask($event)"
							(onInitialized)="onInitializedShortPayTask($event)">
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<!-- <dxo-header-filter [visible]="true"></dxo-header-filter> -->
							<dxi-column dataType="date" dataField="date" caption="Date" cellTemplate="cellTemplateDate">
								<div *dxTemplate="let element of 'cellTemplateDate'">
									{{ (element.data.date !== null &&
									element.data.date !== '' &&
									element.data.date !== undefined) ?
									(element.data.date | date: 'yyyy-MM-dd') : "" }}
								</div>
							</dxi-column>
							<dxi-column dataType="string" dataField="claimnum" caption="Short Pay ID"></dxi-column>
							<dxi-column dataType="string" dataField="customer_code" caption="Customer Code"></dxi-column>
							<dxi-column dataType="number" dataField="amount" caption="Amount" [allowFiltering]="false"
								[allowHeaderFiltering]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateAmount'">
									{{element.data.amount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
						</dx-data-grid>
					</div>
					<!-- end short pay task list section -->
					<!-- <div class="cols12 feildCol">
						<dx-button class="primaryBtn alignRight" id="button" text="Validate Claim" type="normal"
							stylingMode="contained" (click)="doVerifyClaim()">
						</dx-button>
					</div> -->
				</div>
			</div>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button class="primaryBtn alignRight" id="button" text="Validate Claim" type="normal"
				stylingMode="contained" (click)="doVerifyClaim()">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>
<app-unmatched-claim-item-dx-popup [loadUnMatchedClaimItemDxDialogSubject]="loadUnMatchedClaimItemDxDialogSubject"
	(hideUnMatachedPopUpDataEvent)="doHideUmMatachedUploadEndUserDxDialog($event)"
	[modifyClaimDetails]="modifyClaimDetails"></app-unmatched-claim-item-dx-popup>