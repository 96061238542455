import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { CommissionSettlementReportSectionEnum, ContractTypeValEnum, ErpTypeValEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { IMG_ADD_FILE_SVG, IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_EXPORT_PDF, IMG_ICON_REFRESH, IMG_ICON_UPDATE_CURRENT_SETTLEMENT, IMG_ICON_XLSXFILEPOPUPIX, MSG_ERROR_MESSAGE, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyModel, CurrencyOptions } from '@app/models/common.model';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { Subscription } from 'rxjs';
import { Guid } from '@app/models/guid';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
@Injectable({
	providedIn: 'root'
})
export class CommissionService {

	public editCommissionGuid: string;
	public editCommissionTemplateGuid: string;
	public invoiceDetails: any;
	public commissionDetails: any;
	public itemGridBoxColumns: any;
	public customerGridBoxColumns: any;
	public erpType: string;
	public productSearchItemType: string;
	public agreementMainTab: string;
	public listOfFeatureCustomFields: any;
	public isOpenMultiSettlmentPopUp: boolean;
	private updateUserSettingSbsn: Subscription;
	private guid = new Guid();
	public mainSectionStorageKey = ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING;
	public listOfRuleBonusTypes: any[] = [
		{ text: 'Inclusion/Exclusion Rule', value: 'inclusionExclusionRule' },
		{ text: 'Rate Rule', value: 'rateRule' },
		{ text: 'Bonus Amount', value: 'bonusAmount' },
		{ text: 'Bonus Rate', value: 'bonusRate' },
	];
	public listOfInclusionExclusionRuleConditions: any[] = [
		{ text: 'Include in Calculation', value: 'include' },
		{ text: 'Exclude in Calculation', value: 'exclude' },
		{ text: 'Exclude Late Invoice', value: 'excludeLateInvoice' },
	];
	public listOfRateRuleConditions: any[] = [
		{ text: 'Break Units of Measure', value: 'breakUnitsOfMeasure' },
		{ text: 'No. Items Less Than', value: 'itemsLessThan' },
		{ text: 'No. Items Greater Than', value: 'itemsGreaterThan' },
		{ text: 'Miscellaneous Deduction - Fixed Cost', value: 'miscellaneousDeductionFixedCost' },
		{ text: 'Miscellaneous Deduction - Item Percent', value: 'miscellaneousDeductionItemPercent' },
		// { text: 'Miscellaneous Deduction', value: 'miscellaneousDeduction' }, // hide this option as of IX-1615 story
		{ text: 'Miscellaneous Deduction - Inventory Item', value: 'miscellaneousDeductionInventory' },
		{ text: 'Miscellaneous Deduction - All Item', value: 'miscellaneousDeductionAllItem' },
	];
	public listOfBonusRateConditions: any[] = [
		{ text: 'New Account', value: 'newAccount' },
		{ text: 'Total Per Settlement Period Greater Than', value: 'totalPerSettlementPeriodGreaterThan' },
		{ text: 'Gross Profit Per Settlement Period Greater Than', value: 'grossProfitPerSettlementPeriod' },
		{ text: 'Number of Unique SKUs Greater Than', value: 'numberOfuniqueSKUsGreaterThan' },
		{ text: 'No. SKUs Shipped In Settlement Period Greater Than', value: 'SKUsShippedInSettlementPeriod' },

	];
	public listOfBonusRateConditionsForProfiltOnDocumentHeader: any[] = [
		{ text: 'Over List Rate', value: 'overListRate' }, // only enable while "Profit On Document Header" entity UDF 
	];
	public listOfBonusAmountConditions: any[] = [
		{ text: 'New Account', value: 'newAccount' },
		{ text: 'Per Active Customer', value: 'perActiveCustomer' },
		{ text: 'Total Per Settlement Period Greater Than', value: 'totalPerSettlementPeriodGreaterThan' },
		{ text: 'New Account With Monthly Orders Greater Than', value: 'newAccountWithMonthlyOrdersGreaterThan' },
	];
	public listOfInclusionExclusionRuleCriterions: any[] = [
		{ text: 'Unpaid Invoices', value: 'unpaidInvoices' },
		{ text: 'Billbacks / Chargebacks', value: 'billbacksChargebacks' },
		{ text: 'Cash Discounts', value: 'cashDiscounts' },
		{ text: 'Partially Paid Invoices', value: 'partialpaidInvoices' },
		{ text: 'Vendor Sponsored Promo', value: 'vendorsponsoredpromo' },
		{ text: 'Credit Memos', value: 'credit_memos' },
		// { text: 'Number Of Days Late', value: 'numberOfDaysLate' },
		// { text: 'Shipping Costs', value: 'shippingCosts' },
	];
	public listOfExclusionsForBuyingGroup: any[] = [
		{ text: 'Exclusion by Items', value: 'item' },
		{ text: 'Exclusion by Groups', value: 'group' },
	];
	public listOfContractCalendar: any[] = [
		{ text: 'Fiscal Year', value: 'fiscal_year' },
		{ text: 'Calender Year', value: 'calendar_year' },
		{ text: 'Contract Year', value: 'contract_year' },
	];
	public ctsInvoiceRptColumns: any[] =
		[
			{ 'visibleIndex': 0, 'visible': true, dataField: 'clientName', caption: 'Customer Name' },
			{ 'visibleIndex': 1, 'visible': true, dataField: 'customer_age', caption: 'Customer Age' },
			{ 'visibleIndex': 2, 'visible': true, dataField: 'document_type', caption: 'Doc. Type' },
			{ 'visibleIndex': 3, 'visible': true, dataField: 'classname', caption: 'Class' },
			{ 'visibleIndex': 4, 'visible': true, dataField: 'itemname', caption: 'Items' },
			{ 'visibleIndex': 5, 'visible': true, dataField: 'invoiceCode', caption: 'Doc. No' },
			{ 'visibleIndex': 6, 'visible': true, dataField: 'branch', caption: 'Branch' },
			{ 'visibleIndex': 7, 'visible': true, dataField: 'uomcode', caption: 'Doc. UOM' },
			{ 'visibleIndex': 8, 'visible': true, dataField: 'quantity', caption: 'Doc. Qty' },
			{ 'visibleIndex': 9, 'visible': true, dataField: 'contract_selectedUom', caption: 'Contract UOM' },
			{ 'visibleIndex': 10, 'visible': true, dataField: 'contract_quantity', caption: 'Contract Qty' },
			{ 'visibleIndex': 11, 'visible': true, dataField: 'tier_basis', caption: 'Commission Type' },
			{ 'visibleIndex': 12, 'visible': true, dataField: 'commission_basis', caption: 'Commission Basis' },
			{ 'visibleIndex': 13, 'visible': true, dataField: 'commissionRate', caption: 'Commission Rate' },
			{ 'visibleIndex': 14, 'visible': true, dataField: 'serviceArCreditMemoValue', caption: 'Credit Memos (Service Type)' },
			{ 'visibleIndex': 15, 'visible': true, dataField: 'miscDedAmount', caption: 'Misc. Deduction' },
			{ 'visibleIndex': 16, 'visible': true, dataField: 'miscDedFcAmount', caption: 'Misc. Deduction Fixed Cost' },
			{ 'visibleIndex': 17, 'visible': true, dataField: 'cashdiscount', caption: 'Cash Discount' },
			{ 'visibleIndex': 18, 'visible': true, dataField: 'finalAmount', caption: 'Final Line Total' },
			{ 'visibleIndex': 19, 'visible': true, dataField: 'costtotal', caption: 'Cost' },
			{ 'visibleIndex': 20, 'visible': true, dataField: 'paymentParcent', caption: 'Payment (%)' },
			{ 'visibleIndex': 21, 'visible': true, dataField: 'grossprofit', caption: 'Profit' },
			{ 'visibleIndex': 22, 'visible': true, dataField: 'doccurrency', caption: 'Doc Currency' },
			{ 'visibleIndex': 23, 'visible': true, dataField: 'exchangerate', caption: 'Exchange Rate' },
			{ 'visibleIndex': 24, 'visible': true, dataField: 'orgCommissionAmount', caption: 'Original Commission' },
			{ 'visibleIndex': 25, 'visible': true, dataField: 'rebate_amount', caption: 'Estimated Rebate Commission' },
			{ 'visibleIndex': 26, 'visible': true, dataField: 'commissionAmount', caption: 'Commission' },
		]
	public ctsOrderRptColumns: any[] = [
		{ 'visibleIndex': 0, 'visible': true, dataField: 'clientName', caption: 'Customer Name' },
		{ 'visibleIndex': 1, 'visible': true, dataField: 'invoiceCode', caption: 'Order #' },
		{ 'visibleIndex': 2, 'visible': true, dataField: 'linetotal', caption: 'Gross Revenue' },
		{ 'visibleIndex': 3, 'visible': true, dataField: 'grossprofit', caption: 'Profit' },
		{ 'visibleIndex': 4, 'visible': true, dataField: 'overListRuleRate', caption: 'Over List Rate %' },
		{ 'visibleIndex': 5, 'visible': true, dataField: 'overListCommissionAmount', caption: 'Over List Bonus' },
		{ 'visibleIndex': 6, 'visible': true, dataField: 'appliedTier', caption: 'Tier' },
		{ 'visibleIndex': 7, 'visible': true, dataField: 'commissionRate', caption: 'Commission %' },
		{ 'visibleIndex': 8, 'visible': true, dataField: 'commissionAmount', caption: 'Commission' },
		{ 'visibleIndex': 9, 'visible': true, dataField: 'salespersonname', caption: 'Sales Person' },
	];
	public ctsChangeOrderRptColumns: any[] = [
		{ 'visibleIndex': 0, 'visible': true, dataField: 'clientName', caption: 'Customer Name' },
		{ 'visibleIndex': 1, 'visible': true, dataField: 'invoiceCode', caption: 'Order #' },
		{ 'visibleIndex': 2, 'visible': true, dataField: 'linetotal', caption: 'Gross Revenue' },
		{ 'visibleIndex': 3, 'visible': true, dataField: 'grossprofit', caption: 'Profit' },
		{ 'visibleIndex': 4, 'visible': true, dataField: 'overListRuleRate', caption: 'Over List Rate %' },
		{ 'visibleIndex': 5, 'visible': true, dataField: 'overListCommissionAmount', caption: 'Over List Bonus' },
		{ 'visibleIndex': 6, 'visible': true, dataField: 'appliedTier', caption: 'Tier' },
		{ 'visibleIndex': 7, 'visible': true, dataField: 'commissionRate', caption: 'Commission %' },
		{ 'visibleIndex': 8, 'visible': true, dataField: 'commissionAmount', caption: 'Commission' },
		{ 'visibleIndex': 9, 'visible': true, dataField: 'salespersonname', caption: 'Sales Person' },
	];
	public ctsChangeInvoiceRptColumns: any[] = [
		{ 'visibleIndex': 0, 'visible': true, dataField: 'clientName', caption: 'Customer Name' },
		{ 'visibleIndex': 1, 'visible': true, dataField: 'customer_age', caption: 'Customer Age' },
		{ 'visibleIndex': 2, 'visible': true, dataField: 'document_type', caption: 'Doc. Type' },
		{ 'visibleIndex': 3, 'visible': true, dataField: 'classname', caption: 'Class' },
		{ 'visibleIndex': 4, 'visible': true, dataField: 'itemname', caption: 'Items' },
		{ 'visibleIndex': 5, 'visible': true, dataField: 'invoiceCode', caption: 'Doc. No' },
		{ 'visibleIndex': 6, 'visible': true, dataField: 'branch', caption: 'Branch' },
		{ 'visibleIndex': 7, 'visible': true, dataField: 'uomcode', caption: 'Doc. UOM' },
		{ 'visibleIndex': 8, 'visible': true, dataField: 'quantity', caption: 'Doc. Qty' },
		{ 'visibleIndex': 9, 'visible': true, dataField: 'contract_selectedUom', caption: 'Contract UOM' },
		{ 'visibleIndex': 10, 'visible': true, dataField: 'contract_quantity', caption: 'Contract Qty' },
		{ 'visibleIndex': 11, 'visible': true, dataField: 'tier_basis', caption: 'Commission Type' },
		{ 'visibleIndex': 12, 'visible': true, dataField: 'commission_basis', caption: 'Commission Basis' },
		{ 'visibleIndex': 13, 'visible': true, dataField: 'commissionRate', caption: 'Commission Rate' },
		{ 'visibleIndex': 14, 'visible': true, dataField: 'serviceArCreditMemoValue', caption: 'Credit Memos (Service Type)' },
		{ 'visibleIndex': 15, 'visible': true, dataField: 'miscDedAmount', caption: 'Misc. Deduction' },
		{ 'visibleIndex': 16, 'visible': true, dataField: 'miscDedFcAmount', caption: 'Misc. Deduction Fixed Cost' },
		{ 'visibleIndex': 17, 'visible': true, dataField: 'cashdiscount', caption: 'Cash Discount' },
		{ 'visibleIndex': 18, 'visible': true, dataField: 'finalAmount', caption: 'Final Line Total' },
		{ 'visibleIndex': 19, 'visible': true, dataField: 'costtotal', caption: 'Cost' },
		{ 'visibleIndex': 20, 'visible': true, dataField: 'paymentParcent', caption: 'Payment (%)' },
		{ 'visibleIndex': 21, 'visible': true, dataField: 'grossprofit', caption: 'Profit' },
		{ 'visibleIndex': 22, 'visible': true, dataField: 'doccurrency', caption: 'Doc Currency' },
		{ 'visibleIndex': 23, 'visible': true, dataField: 'exchangerate', caption: 'Exchange Rate' },
		{ 'visibleIndex': 24, 'visible': true, dataField: 'orgCommissionAmount', caption: 'Original Commission' },
		{ 'visibleIndex': 25, 'visible': true, dataField: 'rebate_amount', caption: 'Estimated Rebate Commission' },
		{ 'visibleIndex': 26, 'visible': true, dataField: 'commissionAmount', caption: 'Commission' },
	];
	public ctsExtraCommissionBasedRulesRptColumns: any[] = [
		{ 'visibleIndex': 0, 'visible': true, dataField: 'commissionType', caption: 'Commission Type' },
		{ 'visibleIndex': 1, 'visible': true, dataField: 'condition', caption: 'Condition' },
		{ 'visibleIndex': 2, 'visible': true, dataField: 'criterion', caption: 'Criterion' },
		{ 'visibleIndex': 3, 'visible': true, dataField: 'commissionRate', caption: 'Commission Rate (%)' },
		{ 'visibleIndex': 4, 'visible': true, dataField: 'commissionAmount', caption: 'Commission Amount' },
	];
	public ctsAmortizedPaymentsRptColumns: any[] = [
		{ 'visibleIndex': 0, 'visible': true, dataField: 'settlement_close_date', caption: 'Payment Start' },
		{ 'visibleIndex': 1, 'visible': true, dataField: 'condition', caption: 'Payment #' },
		{ 'visibleIndex': 2, 'visible': true, dataField: 'docnum', caption: 'Doc Num' },
		{ 'visibleIndex': 3, 'visible': true, dataField: 'billing_start_date', caption: 'Billing Start Date' },
		{ 'visibleIndex': 4, 'visible': true, dataField: 'billing_end_date', caption: 'Billing End Date' },
		{ 'visibleIndex': 5, 'visible': true, dataField: 'total_commission', caption: 'Total Commission Amount' },
		{ 'visibleIndex': 6, 'visible': true, dataField: 'payment_amount', caption: 'Amortized Amount' },
	];
	public ctsServiceArcreditmemosColumns: any[] = [
		{ 'visibleIndex': 0, 'visible': true, dataField: 'clientName', caption: 'Customer Name' },
		{ 'visibleIndex': 1, 'visible': true, dataField: 'docnum', caption: 'Doc No.' },
		{ 'visibleIndex': 2, 'visible': true, dataField: 'docdate', caption: 'Doc Date' },
		{ 'visibleIndex': 3, 'visible': true, dataField: 'doctotal', caption: 'Doc Total' },
	];
	public ctsMiscellaneousDeductionRulesolumns: any[] = [
		{ 'visibleIndex': 0, 'visible': true, dataField: 'commissionType', caption: 'Commission Type' },
		{ 'visibleIndex': 1, 'visible': true, dataField: 'condition', caption: 'Condition' },
		{ 'visibleIndex': 2, 'visible': true, dataField: 'criterion', caption: 'Criterion' },
		{ 'visibleIndex': 3, 'visible': true, dataField: 'commissionRate', caption: 'Commission Rate (%)' },
		{ 'visibleIndex': 4, 'visible': true, dataField: 'commissionAmount', caption: 'Commission Amount' },
	];

	public commissionRptTabColumns: any[] = [];
	public decimalPointForCurrency: number;
	public decimalPointPercentageFormat: string;
	public listOfCurrency: any[] = [];
	public listOfOtherCurrency: any[] = [];
	public globalCurrencySymbol: string;
	public commissionRptDetails: CommissionSettlemntRptModel = new CommissionSettlemntRptModel();
	constructor(
		public _RestApiService: RestApiService,
		public _ToastrService: ToastrService,
		public _DataService?: DataService,
		public _LoginService?: LoginService,
		public _AppCommonSrvc?: AppCommonSrvc,
		public _CustomNumberNoRoundingPipe?: CustomNumberNoRoundingPipe,
		public _CustomCurrencyPipe?: CustomCurrencyPipe,
	) {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
	}

	getAgreementMainTab() {
		return this.agreementMainTab;
	}
	setAgreementMainTab(val: any) {
		this.agreementMainTab = val;
	}
	getEditCommissionGuid() {
		return this.editCommissionGuid;
	}
	setEditCommissionGuid(val: any) {
		this.editCommissionGuid = val;
	}
	getEditCommissionTemplateGuid() {
		return this.editCommissionTemplateGuid;
	}
	setEditCommissionTemplateGuid(val: any) {
		this.editCommissionTemplateGuid = val;
	}
	getInvoiceDetails() {
		return this.invoiceDetails;
	}
	setInvoiceDetails(val: any) {
		this.invoiceDetails = val;
	}
	getlistOfFeatureCustomFields() {
		return this.listOfFeatureCustomFields;
	}
	setlistOfFeatureCustomFields(val: any) {
		this.listOfFeatureCustomFields = val;
	}
	getCommissionContractDetails() {
		return this.commissionDetails;
	}
	setCommissionContractDetails(val: any) {
		this.commissionDetails = val;
	}
	getIsOpenMultiSettlmentPopUp() {
		return this.isOpenMultiSettlmentPopUp;
	}
	setIsOpenMultiSettlmentPopUp(val: any) {
		this.isOpenMultiSettlmentPopUp = val;
	}

	public getlistOfUomByItemDetails(listOfUoms: any[], listOfUomGroups: any[], itemDetails: any, priceListNum?: string) {
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	private setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails) {
		const listOfSpecificUOM = [];
		const uomGroupLst = listOfUomGroups || [];
		if (itemDetails['uomgroupentry']) {
			const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
			if (uomGroupIndex !== -1) {
				const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
				let newItemUomPice: any = {};
				uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
					newItemUomPice = {};
					newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
					const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
					if (uomIndex !== -1) {
						newItemUomPice.absentry = listOfUoms[uomIndex].absentry;
						newItemUomPice.code = listOfUoms[uomIndex].code;
						newItemUomPice.name = listOfUoms[uomIndex].name;
						newItemUomPice.price = 0;
						listOfSpecificUOM.push(newItemUomPice);
					}
				});
			}

		}
		return listOfSpecificUOM;
	}

	public getTierBasisForGrowth() {
		const tierBasis = [
			{
				code: 'volume',
				label: 'Volume'
			},
			{
				code: 'revenue',
				label: 'Revenue'
			}
		];
		return tierBasis;
	}

	public getTierBasis() {
		const tierBasis = [
			{
				code: 'volume',
				label: 'Volume'
			},
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}

	public getCommissionBasis(currencySymbol?: string) {
		currencySymbol = this.globalCurrencySymbol ? this.globalCurrencySymbol : currencySymbol;
		const commissionBasis = [
			{
				code: 'dollar',
				label: currencySymbol ? currencySymbol : this._LoginService.globalCurrencySymbol
			},
			{
				code: 'percentage',
				label: '%'
			},
			{
				code: 'dollarperuom',
				label: currencySymbol ? currencySymbol + ' / UOM' : this._LoginService.globalCurrencySymbol + ' / UOM'
			}
		];
		return commissionBasis;
	}

	public getTierBasisForClasses() {
		const tierBasis = [
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}
	public getTierBasisForProfitOnHeader() {
		const tierBasis = [
			{
				code: 'revenue',
				label: 'Revenue'
			},
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}

	public getCommissionBasisForClasses(currencySymbol?: string) {
		const commissionBasis = [
			{
				code: 'dollar',
				label: currencySymbol ? currencySymbol : this._LoginService.globalCurrencySymbol
			},
			{
				code: 'percentage',
				label: '%'
			}
		];
		return commissionBasis;
	}
	public getCommissionBasisForProfitOnHeader() {
		const commissionBasis = [
			{
				code: 'percentage',
				label: '%'
			}
		];
		return commissionBasis;
	}

	getQbOnlineErpUser() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return true;
		} else {
			return false;
		}
	}

	getQbErpUser() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return true;
		} else {
			return false;
		}
	}

	getItemGridBoxColumn() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
	}

	getCustomerGridBoxColumn() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
		} else {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
		}
	}

	getProductSearchType() {
		// product_search logic type (item/price)
		const productSearchList = this._LoginService.loginUser.account_detail.app_settings.product_search || [];
		if (productSearchList && productSearchList.length > 0) {
			const productSearchItem = productSearchList.filter(function (item) {
				return (item['key'] === 'chargebacks');
			});
			return this.productSearchItemType = (productSearchItem[0]) ? productSearchItem[0].type : '';
		}
	}

	getMonth() {
		return [
			{ value: '1', text: 'Jan' },
			{ value: '2', text: 'Feb' },
			{ value: '3', text: 'Mar' },
			{ value: '4', text: 'Apr' },
			{ value: '5', text: 'May' },
			{ value: '6', text: 'Jun' },
			{ value: '7', text: 'Jul' },
			{ value: '8', text: 'Aug' },
			{ value: '9', text: 'Sep' },
			{ value: '10', text: 'Oct' },
			{ value: '11', text: 'Nov' },
			{ value: '12', text: 'Dec' },
			{ value: '13', text: 'Jan' },
		];
	}

	// Convert object array of dynamic column on custom field for invoice
	public getCustomFieldForInvoice(responseData: any, tableColLen?: number, customCurrencyOption?: CurrencyOptions) {
		let customFieldDataObj: any;
		let customFieldDataList: any[] = [];
		if (responseData && responseData.length > 0) {
			for (let i = 0; i < responseData.length; i++) {
				customFieldDataObj = {
					dataField: responseData[i].ix_custom_field,
					caption: responseData[i].custom_field_title,
					dataType: responseData[i].custom_field_type,
					visible: responseData[i].display,
					visibleIndex: tableColLen + i
				};
				if (responseData[i].custom_field_output_format === 'text' || responseData[i].custom_field_output_format === 'string') {
					customFieldDataObj.dataType = 'string';
					customFieldDataObj['encodeHtml'] = false;
				}
				if (responseData[i].custom_field_type === 'object') {
					customFieldDataObj['editorOptions'] = { showClearButton: true }
					customFieldDataObj['lookup'] = { dataSource: responseData[i].custom_field_values, displayExpr: "value", valueExpr: "key", showClearButton: true }
					customFieldDataObj['customizeText'] = (cellInfo) => {
						return cellInfo.value || null;
					}
				}
				if (responseData[i].custom_field_type === 'date') {
					customFieldDataObj['format'] = 'yyyy-MM-dd';
				}
				if (responseData[i].custom_field_output_format === 'amount') {
					customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
					// customFieldDataObj['customizeText'] = (cellInfo) => {
					// 	return this.convertCurrency(+cellInfo.value || 0, customCurrencyOption);
					// }
				}
				if (responseData[i].custom_field_output_format === 'percentage') {
					customFieldDataObj['customizeText'] = (cellInfo) => {
						return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
					}
				}
				if (responseData[i].display) {
					customFieldDataList.push(customFieldDataObj);
				}
			}
		}
		return customFieldDataList;
	}

	public getFeatureGuid() {
		let returnVal = '';
		if (this._LoginService.loginUser.account_detail['enable_invoice_custom_fields_feature']) {
			returnVal = 'bc541207c8644ec3b14fe1ade63460b9'
		}
		if (this._LoginService.loginUser.account_detail['enable_profit_on_document_header']) {
			returnVal = 'ffc6f6d2127d41a7b4a3e358c8389912'
		}
		return returnVal;
	}

	public getCommissionContractSettlementReportSection() {
		const commission_settlement_report_setting = [
			{
				name: 'Invoices',
				key: CommissionSettlementReportSectionEnum.INVOICES,
				section_level: 1,
				parent_section_level: 0
			},
			{
				name: 'Customers',
				key: CommissionSettlementReportSectionEnum.CUSTOMERS,
				section_level: 2,
				parent_section_level: 1
			},
			{
				name: 'Items',
				key: CommissionSettlementReportSectionEnum.ITEMS,
				section_level: 3,
				parent_section_level: 2
			},
			{
				name: 'Invoices',
				key: CommissionSettlementReportSectionEnum.INVOICES,
				section_level: 4,
				parent_section_level: 3
			},
			{
				name: 'Orders',
				key: CommissionSettlementReportSectionEnum.ORDERS,
				section_level: 5,
				parent_section_level: 0
			},
			{
				name: 'Change Order(s)',
				key: CommissionSettlementReportSectionEnum.CHANGE_ORDERS,
				section_level: 6,
				parent_section_level: 0
			},
			{
				name: 'Modified Invoice(s)',
				key: CommissionSettlementReportSectionEnum.MODIFIED_INVOICES,
				section_level: 10,
				parent_section_level: 0
			},
			{
				name: 'Additional Incentives',
				key: CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES,
				section_level: 7,
				parent_section_level: 0
			},
			{
				name: 'Amortized Payments',
				key: CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS,
				section_level: 8,
				parent_section_level: 0
			},
			{
				name: 'Service Credit Memo',
				key: CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS,
				section_level: 9,
				parent_section_level: 0
			},
			// // Hide section as of IX-1615 story
			// {
			// 	name: 'Miscellaneous Deduction',
			// 	key: 'miscellaneous_deduction_rules',
			// 	section_level: 10,
			// 	parent_section_level: 0
			// },
		]
		return commission_settlement_report_setting;
	}
	public getCommissionSttlementReportTab() {
		const commission_settlement_report_setting = [
			{
				name: 'Invoices',
				title: 'Invoices',
				key: CommissionSettlementReportSectionEnum.INVOICES,
				section: ServerSections.INVOICES,
				value: 0
			},
			{
				name: 'Orders',
				title: 'Orders',
				key: CommissionSettlementReportSectionEnum.ORDERS,
				section: ServerSections.ORDERS,
				value: 0
			},
			{
				name: 'Change Order(s)',
				title: 'Change Order(s)',
				key: CommissionSettlementReportSectionEnum.CHANGE_ORDERS,
				section: ServerSections.CHANGE_ORDERS,
				value: 0
			},
			{
				name: 'Modified Invoices',
				title: 'Modified Invoices',
				key: CommissionSettlementReportSectionEnum.MODIFIED_INVOICES,
				section: ServerSections.CHANGE_INVOICES,
				value: 0
			},
			{
				name: 'Additional Incentives',
				title: 'Additional Incentives',
				key: CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES,
				section: ServerSections.EXTRA_COMMISSION_BASED_RULES,
				value: 0
			},
			{
				name: 'Amortized Payments',
				title: 'Amortized Payments',
				key: CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS,
				section: ServerSections.AMORTIZED_PAYMENTS,
				value: 0
			},
			{
				name: 'Service Credit Memo',
				title: 'Service Credit Memo',
				key: CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS,
				section: ServerSections.SERVICE_AR_CREDIT_MEMOS,
				value: 0
			},
			// // Hide section as of IX-1615 story
			// {
			// 	name: 'Miscellaneous Deduction',
			// 	title: 'Miscellaneous Deduction',
			// 	key: CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES,
			// 	value: 0
			// },
		]
		return commission_settlement_report_setting;
	}

	//Load State Data
	public loadStateData(sectionLevelKey, parentSectionLevelKey) {
		const sLeval = this.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		let newSection = null;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
		} catch (e) {
			newSection = null;
		}
		const commissionSettlementReportConfigClone = newSection;
		if (!commissionSettlementReportConfigClone.configs) {
			commissionSettlementReportConfigClone.configs = [];
		}
		const statePersistentIndex = commissionSettlementReportConfigClone.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
		if (statePersistentIndex !== -1) {
			if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent) {
				return commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	//Save State Data
	public saveStateData(sLeval, e) {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		let commissionSettlementReportConfig: any = {};
		try {
			commissionSettlementReportConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
		} catch (e) {
			commissionSettlementReportConfig = {};
		}

		if (!commissionSettlementReportConfig.configs) {
			commissionSettlementReportConfig.configs = [];
		}

		const configIndex = commissionSettlementReportConfig.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
		if (configIndex !== -1) {
			commissionSettlementReportConfig.configs[configIndex].state_persistent = e;
		} else {
			const statePersistent = {
				guid: this.guid.newGuid(),
				section_level: sLeval.section_level,
				parent_section_level: sLeval.parent_section_level,
				state_persistent: e
			};
			commissionSettlementReportConfig.configs.push(statePersistent);
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING);
		formData.append('user_settings', JSON.stringify(commissionSettlementReportConfig));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING] = commissionSettlementReportConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING, commissionSettlementReportConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING, commissionSettlementReportConfig);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	// Get grid section level
	public getSectionLevel(sectionLevel, parentSectionLevel?) {
		const lstSection = this.getCommissionContractSettlementReportSection();
		let psLevel, sLeval;
		if (!parentSectionLevel) {
			const sectionLeval = lstSection.filter(i => i.key === sectionLevel && i.parent_section_level === 0);
			sLeval = sectionLeval[0];
		} else {
			const sLeval1 = lstSection.filter(i => i.key === sectionLevel && i.parent_section_level !== 0);
			sLeval1.forEach(element => {
				const psLevel1 = lstSection.filter(i => i.key === parentSectionLevel);
				if (psLevel1) {
					psLevel1.forEach(pElement => {
						if (element.parent_section_level === pElement.section_level) {
							sLeval = element;
						}
					});
				}
			});
		}
		// console.log('sLeval ::: ', sLeval);
		return sLeval;
	}

	// Load RPT tab state data
	public loadStateDataGrid(configCode: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(this.mainSectionStorageKey)));
		} catch (e) {
			newSection = null;
		}
		const commissionSettlementReportConfigClone = newSection;
		if (!commissionSettlementReportConfigClone.configs) {
			commissionSettlementReportConfigClone.configs = [];
		}
		const statePersistentIndex = commissionSettlementReportConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (statePersistentIndex !== -1) {
			if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent.hasOwnProperty('columns')) {
				if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent.columns.length > 0) {
					commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent.columns.forEach(elementCol => {
						if (elementCol.hasOwnProperty('added')) {
							if (elementCol.added && elementCol.added.hasOwnProperty('format')) {
								const currencyCode = this.commissionRptDetails.currency_code ? this.commissionRptDetails.currency_code : this._LoginService.globalCurrency;
								elementCol.added.format = this._AppCommonSrvc.currencyFormat(currencyCode);
							}
						}
					});
				}
			}
			if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent) {
				return commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	//Save State Data
	public saveStateDataGrid(statePersistent: any, configCode: string) {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		let salesListingConfig: any = {};

		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(this.mainSectionStorageKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}
		if (statePersistent.hasOwnProperty('columns')) {
			if (statePersistent.columns.length > 0) {
				statePersistent.columns.forEach(elementCol => {
					if (elementCol.hasOwnProperty('added')) {
						if (elementCol.added && elementCol.added.hasOwnProperty('format')) {
							const currencyCode = this.commissionRptDetails.currency_code ? this.commissionRptDetails.currency_code : this._LoginService.globalCurrency;
							elementCol.added.format = this._AppCommonSrvc.currencyFormat(currencyCode);
						}
					}
				});
			}
		}
		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING);
		formData.append('user_settings', JSON.stringify(salesListingConfig));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'CLOSE_MULTIPLE_SETTLEMENT',
				btn_name: 'Close Multiple settlement',
				btn_image: 'close_multiple_settlement',
				icon: IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForAgrementTemplateDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForCommissionReport() {
		return [
			{
				btn_code: 'CLOSE_CURRENT_SETTLEMENT_PERIOD',
				btn_name: 'Close Current Settlement Period',
				btn_image: 'close_current_settlement_period',
				icon: IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'UPDATE_CURRENT_SETTLEMENT',
				btn_name: 'Update Current Settlement',
				btn_image: 'update_current_settlement',
				icon: IMG_ICON_UPDATE_CURRENT_SETTLEMENT,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT_PDF',
				btn_name: 'Export Pdf',
				btn_image: 'pdffile',
				icon: IMG_ICON_EXPORT_PDF,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export Data',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
		];
	}

	get getListOfSettlementPeriods() {
		return [
			{ text: 'Monthly', value: 'monthly', disabled: false, visible: true },
			{ text: 'Quarterly', value: 'quarterly', disabled: false, visible: true },
			{ text: 'Bi-annually', value: 'halfYearly', disabled: false, visible: true },
			{ text: 'Annually', value: 'yearly', disabled: false, visible: true },
			{ text: 'Weekly', value: 'weekly', disabled: false, visible: true },
			{ text: 'Bi-weekly', value: 'biweekly', disabled: false, visible: true },
			{ text: 'Custom', value: 'custom', disabled: false, visible: true },
		];
	}

	get getListOfCalculationBasis() {
		return [
			{ text: 'Gross Profit', value: 'grossprofit', disabled: false, visible: true },
			{ text: 'Gross Sales', value: 'revenue', disabled: false, visible: true },
		];
	}

	get getListOfCustomerOrItemMatch() {
		return [
			{ text: 'Exclusive', value: 'exclusive', disabled: false, visible: true },
			{ text: 'Inclusive', value: 'inclusive', disabled: false, visible: true },
		];
	}
	get getListOfTiers() {
		return [
			{ text: 'Single Item', value: 'single_items', disabled: false, visible: true },
			{ text: 'Multiple Item', value: 'multiple_items', disabled: false, visible: true },
		];
	}

	get getListOfCalculateCommissionBy() {
		return [
			{ value: 'orders', text: 'Orders', disabled: false, visible: true },
			{ value: 'invoices', text: 'Invoices', disabled: false, visible: true }
		];
	}

	get getListOfTierModes() {
		return [
			{ text: 'Absolute', value: 'absolute', disabled: false, visible: true },
			{ text: 'Marginal', value: 'marginal', disabled: false, visible: true },
		];
	}

	get getListOfTierAccrualPeriods() {
		return [
			{ text: 'Weekly', value: 'weekly', disabled: false, visible: false },
			{ text: 'Monthly', value: 'monthly', disabled: false, visible: true },
			{ text: 'Quarterly', value: 'quarterly', disabled: false, visible: true },
			{ text: 'Bi-annually', value: 'halfYearly', disabled: false, visible: true },
			{ text: 'Annually', value: 'yearly', disabled: false, visible: true },
		];
	}

	get getListOfTierInclude() {
		return [
			{ text: 'Only items in tier', value: 'only_items_in_tier', disabled: false, visible: true },
			{ text: 'All items in contract', value: 'all_items_in_contract', disabled: false, visible: true }
		];
	}

	get getListOfYears() {
		const year = new Date().getFullYear();
		const range = [];
		range.push(year);
		for (let i = 1; i < 5; i++) {
			range.push(year - i);
		}
		return range;
	}

	getCommissionRptDetails() {
		return this.commissionRptDetails;
	}
	setCommissionRptDetails(val: any) {
		this.commissionRptDetails = val;
	}

	//set default as selected Other currency 
	public getCustomCurrencyOption(commissionDetails?: any) {
		let customCurrencyOption: CurrencyOptions = new CurrencyOptions();
		//set default primary currency 
		if (this._LoginService.loginUser.account_detail.app_settings.hasOwnProperty('currency_code')) {
			let currency_code = this._LoginService.loginUser.account_detail.app_settings.currency_code;
			customCurrencyOption.currencyCode = currency_code;
		}
		if (commissionDetails) {
			if (commissionDetails.currency_code) {
				customCurrencyOption.currencyCode = commissionDetails.currency_code;
			}
		}
		this.globalCurrencySymbol = this.setGlobalCurrencySymbol(customCurrencyOption);
		return customCurrencyOption;
	}

	public setGlobalCurrencySymbol(customCurrencyOption: CurrencyOptions) {
		let currencyModel: CurrencyModel = this._AppCommonSrvc.getCurrencyDetail(customCurrencyOption);
		if (currencyModel.currency_code) {
			return new Intl.NumberFormat(currencyModel.currency_language, {
				style: 'currency',
				currency: currencyModel.currency_code,
				minimumIntegerDigits: 1,
				minimumFractionDigits: this.decimalPointForCurrency,
				maximumFractionDigits: this.decimalPointForCurrency,
			}).formatToParts(1).find(x => x.type === "currency").value || '$';
		} else {
			return this._LoginService.globalCurrencySymbol;
		}
	}

	public convertCurrency(val: any, customCurrencyOption?: CurrencyOptions) {
		return this._CustomCurrencyPipe.transform(val || 0, customCurrencyOption);
	}

	public doCustomizeTotal(e, customCurrencyOption?: CurrencyOptions) {
		if (e.valueText) {
			return this.convertCurrency(e.valueText, customCurrencyOption);
		} else {
			return this.convertCurrency(0, customCurrencyOption);
		}
	}

	public checkCommissionReportMordenView(): boolean {
		let isModernView: boolean = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('settlement_report_modern_view')) {
			const modernViewItemlst: any[] = this._LoginService.loginUser.account_detail.settlement_report_modern_view || [];
			if (modernViewItemlst.length > 0) {
				const indexCommissionView = modernViewItemlst.findIndex(i => i === ContractTypeValEnum.COMMISSIONCONTRACTS);
				if (indexCommissionView !== -1) {
					isModernView = true;
				}
			}
		}
		return isModernView;
	}

	public getListOfActionsDatagrid() {
		return [
			// {
			// 	btn_code: 'ADD',
			// 	btn_name: 'Add New',
			// 	btn_image: IMG_ADD_FILE_SVG,
			// 	icon: ' ' + IMG_ADD_FILE_SVG,
			// 	visible: true,
			// 	disabled: false,
			// },
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	// Convert object array of dynamic column on custom field for invoice
	public getCustomField(responseData: any, currency_code?: string) {
		let customFieldDataObj: any;
		let customFieldDataList: any[] = [];
		for (let i = 0; i < responseData.length; i++) {
			customFieldDataObj = {
				dataField: responseData[i].ix_custom_field,
				caption: responseData[i]['custom_field_title'] ? responseData[i].custom_field_title : responseData[i].ix_custom_field_title,
				dataType: responseData[i].custom_field_type,
				visible: responseData[i].display,
				allowFiltering: false,
				visibleIndex: 3 + i,
				width: 'auto'
			};

			if (responseData[i].custom_field_output_format === 'text' || responseData[i].custom_field_output_format === 'string') {
				customFieldDataObj.dataType = 'string';
				customFieldDataObj['encodeHtml'] = false;
			}

			if (responseData[i].custom_field_type === 'date') {
				customFieldDataObj['format'] = 'yyyy-MM-dd';
			}
			//if (responseData[i].ix_custom_field === 'over_pricelist_amount' || responseData[i].ix_custom_field === 'custom_doctotal' || responseData[i].ix_custom_field === 'base_profit_amount') {
			if (responseData[i].custom_field_output_format === 'amount') {
				customFieldDataObj['format'] = this._AppCommonSrvc.currencyFormat(currency_code);
				// customFieldDataObj['customizeText'] = (cellInfo) => {
				// 	return this.convertCurrency(+cellInfo.value || 0, customCurrencyOption);
				// }
			}
			if (responseData[i].custom_field_output_format === 'percentage') {
				//customFieldDataObj['customizeText'] = this.convertTextToPer;
			}
			//}
			if (responseData[i].custom_field_type === 'object') {
				if (responseData[i].custom_field_output_format === 'object') {
					customFieldDataObj['lookup'] = { dataSource: responseData[i].custom_field_values, displayExpr: "value", valueExpr: "key" };
					customFieldDataObj.dataField = responseData[i].ix_custom_field + '.refName';
				}
				if (responseData[i].custom_field_output_format === 'amount') {
					//customFieldDataObj.dataType = 'number';
					// customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
					customFieldDataObj['format'] = this._AppCommonSrvc.currencyFormat(currency_code);
					// customFieldDataObj['customizeText'] = (cellInfo) => {
					// 	return this.convertCurrency(+cellInfo.value || 0, customCurrencyOption);
					// }
				}
				if (responseData[i].custom_field_output_format === 'percentage') {
					customFieldDataObj.dataType = 'number';
					//customFieldDataObj['customizeText'] = this.convertTextToPer;
				}
			}
			customFieldDataList.push(customFieldDataObj);
		}
		return customFieldDataList;
	}

	convertTextToPer(itemobj: any) {
		if (itemobj && itemobj.hasOwnProperty('value')) {
			return this._CustomNumberNoRoundingPipe.transform((+itemobj.value || 0)) + '%'
		} else {
			return 0;
		}
	}

	setAllDataGridColumn() {
		const commissionRptTabs = this.getCommissionSttlementReportTab();
		if (commissionRptTabs.length > 0) {
			commissionRptTabs.forEach(elementTab => {
				let state_persistent: any = this.loadStateDataGrid(elementTab.section);
				let section_Columns: any[] = [];
				switch (elementTab.section) {
					case ServerSections.INVOICES:
						section_Columns = this.addCustomFieldColumns(this.ctsInvoiceRptColumns);
						break;
					case ServerSections.ORDERS:
						section_Columns = this.addCustomFieldColumns(this.ctsOrderRptColumns);
						break;
					case ServerSections.CHANGE_ORDERS:
						section_Columns = this.addCustomFieldColumns(this.ctsChangeOrderRptColumns);
						break;
					case ServerSections.CHANGE_INVOICES:
						section_Columns = this.addCustomFieldColumns(this.ctsChangeInvoiceRptColumns);
						break;
					case ServerSections.AMORTIZED_PAYMENTS:
						section_Columns = this.ctsAmortizedPaymentsRptColumns;
						break;
					case ServerSections.SERVICE_AR_CREDIT_MEMOS:
						section_Columns = this.ctsServiceArcreditmemosColumns;
						break;
					case ServerSections.EXTRA_COMMISSION_BASED_RULES:
						section_Columns = this.ctsExtraCommissionBasedRulesRptColumns;
						break;
					case ServerSections.MISCELLANEOUS_DEDUCTION_RULES:
						section_Columns = this.ctsMiscellaneousDeductionRulesolumns;
						break;
					default:
						break;
				}
				if (state_persistent) {
					const statePersistentCol: any = state_persistent.hasOwnProperty('columns') ? state_persistent['columns'] : [];
					section_Columns = this.updateColProperty(statePersistentCol, section_Columns);
				}
				let SectionColObj = {
					section: elementTab.section,
					columns: section_Columns
				}
				this.commissionRptTabColumns.push(SectionColObj);
			});
		}
	}

	// replace column visibleIndex,visible property
	public updateColProperty(statePersistentCol, section_Columns) {
		if (statePersistentCol.length > 0 && section_Columns.length > 0) {
			statePersistentCol.forEach(stateCol => {
				const indexCol = section_Columns.findIndex(i => i.dataField === stateCol.dataField);
				if (indexCol !== -1) {
					let sectionColObj = section_Columns[indexCol];
					sectionColObj['visible'] = stateCol.visible;
					sectionColObj['visibleIndex'] = stateCol.visibleIndex;
				}
			});
		}
		return section_Columns;
	}

	public addCustomFieldColumns(listOfColumns) {
		let listOfFeatureCustomFields: any = this.getlistOfFeatureCustomFields();
		for (let i = 0; i < listOfFeatureCustomFields.length; i++) {
			listOfFeatureCustomFields[i]['visibleIndex'] = 3 + i;
			listOfColumns.push(listOfFeatureCustomFields[i]);
		}
		return listOfColumns;
	}
}
