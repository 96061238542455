import ArrayStore from "devextreme/data/array_store";

export class ComponentsItem {
	public erp_primary_key: string;
	public itemname: string;
	public itemsku?: string;
	public description: string;
	public uomgroupentry: string;
	public uomcode: string;
	public quantity: number;
	public componentcode: string;
	public price: number;
	public itemimages: any[];
}

export class ConfigurationsComponent {
	public guid: string;
	public name: string;
	public required: boolean;
	public components: ComponentsItem[] = [];
}

export class ItemConfigurationsReq {
	public guid: string;
	public erp_primary_key: string;
	public configurations: ConfigurationsComponent[] = [];
}

export class ItemComponentUI {
	public name: string;
	public required: boolean;
	public listOfSelectedItems: any[] = [];
}
export class AddEditPriceListUI {
	public guid?: string;
	public unique_id?: string;
	public ixcode?: string;
	public pricelistno?: string;
	public pricelistname?: string;
	public active?: string;
	public status?: string;
	public startdate?: Date;
	public enddate?: Date;
	public issynctoerp?: boolean;
	public oldpricelists?: any[];
	public items?: any[];
	public listOfItems?: ArrayStore;
	public is_approval_workflow?: boolean;
	public approvers?: any[];
	public pricelist_type?: string;
	public distribution_channels?: string;
	constructor() { }
}
export class AddEditPriceListReq {
	public guid?: string;
	public unique_id?: string;
	public ixcode?: string;
	public pricelistno?: string;
	public pricelistname?: string;
	public active?: string;
	public status?: string;
	public startdate?: string;
	public enddate?: string;
	public issynctoerp?: boolean;
	public oldpricelists?: any[];
	public items?: any[];
	public is_approval_workflow?: boolean;
	public approvers?: any[];
	public pricelist_type?: string;
	public terms?: string;
	public distribution_channels?: string;
	constructor() { }
}