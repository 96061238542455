import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractsComponent } from './contracts.component';
import { BillbackChargebackContractListComponent } from './billback-chargeback-contract-list/billback-chargeback-contract-list.component';
import { CommissionContractListComponent } from './commission-contract-list/commission-contract-list.component';
import { RebatesContractListComponent } from './rebates-contract-list/rebates-contract-list.component';
import { RoyaltiesContractComponent } from './royalties-contract/royalties-contract.component';
import { RoyaltiesContractListComponent } from './royalties-contract-list/royalties-contract-list.component';
import { RebatesTierbaseCalculationViewComponent } from './rebates-tierbase-contract/rebates-tierbase-calculation-view/rebates-tierbase-calculation-view.component';
import { RoyaltiesClculationViewComponent } from './royalties-contract/royalties-clculation-view/royalties-clculation-view.component';
import { RebatesTierbaseContractComponent } from './rebates-tierbase-contract/rebates-tierbase-contract.component';
import { RebatesTierbaseReportviewBuyinggroupComponent } from './rebates-tierbase-contract/rebates-tierbase-reportview-buyinggroup/rebates-tierbase-reportview-buyinggroup.component';
import { CommissionTierbaseContractComponent } from './commission-tierbase-contract/commission-tierbase-contract.component';
import { CommissionTierbaseCommissionCalculationViewComponent } from './commission-tierbase-contract/commission-tierbase-commission-calculation-view/commission-tierbase-commission-calculation-view.component';
import { ArCreditMemoDetailsComponent } from '../sales/ar-credit-memo-details/ar-credit-memo-details.component';
import { PrchseApCreditMemoDetailsComponent } from '../purchases/prchse-ap-credit-memo-details/prchse-ap-credit-memo-details.component';
import { CommissionTierbaseContractListTabComponent } from './commission-tierbase-contract-list-tab/commission-tierbase-contract-list-tab.component';
import { CommissionTierbaseContractTemplateComponent } from './commission-tierbase-contract-template/commission-tierbase-contract-template.component';
import { CommissionTierbaseSettlementReportComponent } from './commission-tierbase-contract/commission-tierbase-settlement-report/commission-tierbase-settlement-report.component';

const routes: Routes = [{
	path: '',
	component: ContractsComponent,

	children: [
		// Commission contract route
		{
			path: 'commissionlist',
			component: CommissionTierbaseContractListTabComponent,
			data: {
				permission: 'INCENTIVE_COMMISSION_VIEW',
				title: 'Commission Agreements',
				breadcrumb: [
					{
						label: 'Commission Agreements',
						url: ''
					}
				]
			},
		},
		// {
		// 	path: 'commissionlist',
		// 	component: CommissionContractListComponent,
		// 	data: {
		// 		permission: 'INCENTIVE_COMMISSION_VIEW',
		// 	}
		// },
		{
			path: 'commission',
			component: CommissionTierbaseContractComponent,
			data: {
				title: 'Commission Contract',
				breadcrumb: [
					{
						label: 'Commission',
						url: '/contracts/commissionlist'
					},
					{
						label: 'Commission Contract',
						url: ''
					}
				]
			},

		},
		{
			path: 'commission-template',
			component: CommissionTierbaseContractTemplateComponent,
			data: {
				title: 'Agreement Templates',
				breadcrumb: [
					{
						label: 'Commission',
						url: '/contracts/commissionlist'
					},
					{
						label: 'Agreement Templates',
						url: ''
					}
				]
			},
		},
		{
			path: 'commission/report',
			component: CommissionTierbaseCommissionCalculationViewComponent,
			data: {
				title: 'Commission Report',
				breadcrumb: [
					{
						label: 'Commission',
						url: '/contracts/commissionlist'
					},
					{
						label: 'Settlement Report',
						url: '/contracts/commission/'
					},
					{
						label: 'Commission Report',
						url: ''
					}
				]
			},
		},
		{
			path: 'commission/modern-report',
			component: CommissionTierbaseSettlementReportComponent,
			data: {
				title: 'Commission Report',
				breadcrumb: [
					{
						label: 'Commission',
						url: '/contracts/commissionlist'
					},
					{
						label: 'Settlement Report',
						url: '/contracts/commission/'
					},
					{
						label: 'Commission Report',
						url: ''
					}
				]
			},
		},
		// End commission contract route

		// Billback chargeback route
		{
			path: 'billbacklist',
			component: BillbackChargebackContractListComponent,
			data: {
				permission: 'INCENTIVE_BILLBACKCHARGEBACK_VIEW',
				title: 'Billback & Chargeback Agreements',
				breadcrumb: [
					{
						label: 'Billback & Chargeback Agreements',
						url: ''
					},
				]
			},
		},
		{
			path: 'billbackchargeback',
			loadChildren: () => import('./billback/billback.module').then(m => m.BillbackModule),
			data: {
				// permission: 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY',
			}
		},
		//End billback chargeback route

		// Rebates route
		{
			path: 'rebateslist',
			component: RebatesContractListComponent,
			data: {
				permission: 'INCENTIVE_REBATES_VIEW',
				title: 'Rebate Agreements',
				breadcrumb: [
					{
						label: 'Rebate Agreements',
						url: ''
					}
				]
			},
		},
		{
			path: 'rebates',
			component: RebatesTierbaseContractComponent,
			data: {
				title: 'Rebate Contract',
				breadcrumb: [
					{
						label: 'Rebate',
						url: '/contracts/rebateslist'
					},
					{
						label: 'Rebate Contract',
						url: ''
					}
				]
			},
		},
		{
			path: 'rebates/reportview-buyinggroup',
			component: RebatesTierbaseReportviewBuyinggroupComponent,
			data: {
				title: 'Rebate Report',
				breadcrumb: [
					{
						label: 'Rebate',
						url: '/contracts/rebateslist'
					},
					{
						label: 'Settlement Report',
						url: '/contracts/rebates'
					},
					{
						label: 'Rebate Report',
						url: ''
					}
				]
			},
		},
		{
			path: 'rebates/report',
			component: RebatesTierbaseCalculationViewComponent,
			data: {
				title: 'Rebate Report',
				breadcrumb: [
					{
						label: 'Rebate',
						url: '/contracts/rebateslist'
					},
					{
						label: 'Settlement Report',
						url: '/contracts/rebates'
					},
					{
						label: 'Rebate Report',
						url: ''
					}
				]
			},
		},
		{
			path: 'rebates/ar-credit-memo-details',
			component: ArCreditMemoDetailsComponent,
			data: {
				title: 'AR Credit Memo Details',
				breadcrumb: [
					{
						label: 'Rebate',
						url: '/contracts/rebateslist'
					},
					{
						label: 'Credit Memo Report',
						url: '/contracts/rebates'
					},
					{
						label: 'AR Credit Memo Details',
						url: ''
					}
				]
			},
		},
		{
			path: 'rebates/ap-credit-memo-details',
			component: PrchseApCreditMemoDetailsComponent,
			data: {
				title: 'AP Credit Memo Details',
				breadcrumb: [
					{
						label: 'Rebate',
						url: '/contracts'
					},
					{
						label: 'Credit Memo Report',
						url: '/contracts/rebates'
					},
					{
						label: 'AP Credit Memo Details',
						url: ''
					}
				]
			},
		},
		// End rebates route

		// Royalties route
		{
			path: 'royaltieslist',
			component: RoyaltiesContractListComponent,
			data: {
				title: 'Royalty Agreements',
				permission: 'INCENTIVE_ROYALTIES_VIEW',
				breadcrumb: [
					{
						label: 'Royalty Agreements',
						url: ''
					}
				]
			},
		},
		{
			path: 'royalties',
			component: RoyaltiesContractComponent,
			data: {
				title: 'Royalty Details',
				permission: 'INCENTIVE_ROYALTIES_VIEW',
				breadcrumb: [
					{
						label: 'Royalty',
						url: '/contracts/royaltieslist'
					},
					{
						label: 'Royalty Details',
						url: ''
					}
				]
			},
		},
		{
			path: 'royalties/report',
			component: RoyaltiesClculationViewComponent,
			data: {
				title: 'Royalty Details',
				permission: 'INCENTIVE_ROYALTIES_VIEW',
				breadcrumb: [
					{
						label: 'Royalty',
						url: '/contracts/royaltieslist'
					},
					{
						label: 'Settlement Report',
						url: '/contracts/royalties'
					},
					{
						label: 'Royalty Report',
						url: ''
					}
				]
			},

		},
		// End Royalties route

		//Trade Promotion route
		{
			path: 'trade-promotion',
			loadChildren: () => import('./trade-promotion/trade-promotion.module').then(m => m.TradePromotionModule),
			data: {
				// permission: 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY',
			}
		},
		//End trade promotion
		{
			path: '',
			component: CommissionContractListComponent
		}
	]
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContractsRoutingModule { }
