import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_REFRESH } from '@app/constants-enums/constants';

@Injectable({
	providedIn: 'root'
})
export class ApApplicationConfigsService {

	constructor() { }

	public getListOfActionsForDisposableEmail() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForShippingTypes() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForCreditCardType() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForDeviceMapping() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForAppVersions() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForCurrency() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}

	public getSystemConfigurationsList() {
		return {
			"INCENTXCONFIGURATIONS": [
				{
					"id": 4,
					"text": "Twillio / Authy",
					"expanded": true
				},
				{
					"id": 5,
					"text": "SMTP Setting",
					"expanded": true
				},
				{
					"id": 6,
					"text": "AWS Setting",
					"expanded": true
				},
				{
					"id": 7,
					"text": "Miscellaneous",
					"expanded": true
				},
				{
					"id": 8,
					"text": "DocuSign",
					"expanded": true
				},
				{
					"id": 9,
					"text": "Zoho Sign",
					"expanded": true
				},
				{
					"id": 10,
					"text": "NETSUITE Setting",
					"expanded": true
				},
				{
					"id": 11,
					"text": "IOS App Store Review",
					"expanded": true
				},
				{
					"id": 13,
					"text": "Salesforce Setting",
					"expanded": true
				},
				{
					"id": 14,
					"text": "Send Email Setting",
					"expanded": true
				},
				{
					"id": 15,
					"text": "Azure SSO Settings",
					"expanded": true
				},
				{
					"id": 16,
					"text": "Okta SSO Settings",
					"expanded": true
				},
				{
					"id": 17,
					"text": "OpenSearch Settings",
					"expanded": true
				},
				{
					"id": 18,
					"text": "Zoho Billing",
					"expanded": true
				},
				{
					"id": 19,
					"text": "Dynamics 365",
					"expanded": true
				}
			],
			"ERPCONFIGURATIONS": [
				{
					"ID": 1,
					"Title": "QB ONLINE"
				},
				{
					"ID": 2,
					"Title": "QUICKBOOKS"
				},
				{
					"ID": 3,
					"Title": "SAPB1 HANA DIAPI"
				},
				{
					"ID": 4,
					"Title": "SAPB1 HANA"
				}
			]
		}
	}
}
