export class RebatesContractModelUI {
	public ixcode: string;
	public guid: string;
	public baseContractCode: string;
	public contractName: string;
	public rebatesPercent: string;
	// public employeeCode:string;
	// public employeeName:string;
	public selectBuyingGroupId: string;
	public selectBuyingGroup: string;
	public selectBuyingGroupObj: any;
	public selectCustomerId: string;
	public selectCustomer: string;
	public selectCustomerObj: any;
	public selectVendorId: string;
	public selectVendor: string;
	public selectVendorObj: any;
	public startDate: any;
	public endDate: any;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public startSettlementPeriodOn: any;
	public contractCalendar: string;
	public agreementBy: string;
	public individualOrGroup: string;
	public rebateType: string;
	public paymentType: string;
	public buyingGroup: any;
	public buyingGroupMember: any;
	public allproducts: boolean;
	public allclients: boolean;
	public isSettlementPeriodClosed: boolean;
	public is_active: boolean;
	public clients: CustomerTabModel[] = [];
	public members: MemberTabModel[] = [];
	public items: ProductAndGroupTabModel[] = [];
	public enduser_items_groups?: ProductAndGroupTabModel[] = [];
	public excludedItems: ExclusionTabModel[] = [];
	public rules: RuleTabModel[] = [];
	public current_settlement_invoice_total: string;
	public current_settlement_commission_total: string;
	public gl_account_number?: string;
	public comparison_year?: string;
	public pricelist_modified_date?: string;
	public pricelist_modified_user_action?: string;
	public status: string;
	public selectContactEmployeeId?: string;
	public selectContactEmployee?: string;
	public selectContactEmployeeObj?: any;
	public terms_and_conditions?: any;
	public is_contract_lifecycle_management?: boolean;
	public customer_item_match?: string;
	public tiers_type?: string;
	public tier_mode?: string;
	public tier_accrual_period?: string;
	public tiers_include?: string;
	public current_settlement_creditmemo_total?: string;
	public current_settlement_sales_total?: string;
	public selectPricelistno?: string;
	public selectPricelistName?: string;
	public selectPricelistObj: any;
	public selectDifferentialPricelistno?: string;
	public selectDifferentialPricelistName?: string;
	public selectDifferentialPricelistObj: any;
	public member_mode?: string; // single_member / multiple_member
	public currency_code?: string;
	constructor() {
	}
}

export class RebatesContractModelRequest {
	public guid: string;
	public contractName: string;
	public cardcode: string; //  Could be Vendor OR Customer or Buying Group ERP Primary key
	public cardcode_guid: string;  //  Could be Vendor OR Customer or Buying Group GUID
	public name: string; //  Could be Vendor OR Customer or Buying Group Name
	public startDate: any;
	public endDate: any;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public startSettlementPeriodOn: string;
	public contractCalendar: string;
	public rebate_for: string; // individualOrGroup
	public rebateType: string;
	public paymentType?: string;
	public rebatesPercent?: string;
	public buyingGroup?: string;
	public buyingGroupMember?: string;
	public gl_account_number?: string;
	public comparison_year?: string;
	public current_settlement_invoice_total?: string;
	public current_settlement_rebate_total?: string; // current_settlement_commission_total
	public is_active: boolean;
	public rules: RuleTabModel[] = [];
	public items: ProductAndGroupTabModel[] = [];
	public clients?: CustomerTabModel[];
	public members?: MemberTabModel[];
	public excludedItems?: ExclusionTabModel[];
	public pricelist_modified_date?: string;
	public pricelist_modified_user_action?: string;

	public isSettlementPeriodClosed?: boolean;
	public settelmentCalculatedByBackend?: any;
	public creditMemoCreatedInERP?: string;
	public errorMessage?: string;
	public settlementValue?: number;
	public isCreditMemoPaid?: string;
	public creditMemoNumber?: string;
	public baseContractCode?: string;
	public creditMemoPath?: string;

	public ixcode?: string;
	// public employeeCode:string;
	// public employeeName:string;
	public selectBuyingGroupId?: string;
	public selectBuyingGroup?: string;
	public selectCustomerId?: string;
	public selectCustomer?: string;
	public selectVendorId?: string;
	public selectVendor?: string;
	public agreementBy?: string;
	public individualOrGroup?: string;
	public allproducts?: boolean;
	public allclients?: boolean;
	public current_settlement_commission_total?: string;
	public status?: string;
	public contactemployee_guid?: string;
	public contactemployee_name?: string;
	public terms_and_conditions?: any;
	public is_contract_lifecycle_management?: boolean;

	public customer_item_match?: string;
	public tiers_type?: string;
	public tier_mode?: string;
	public tier_accrual_period?: string;
	public tiers_include?: string;
	public pricelistno?: string;
	public pricelistname?: string;
	public differential_pricelistno?: string;
	public differential_pricelistname?: string;
	public enduser_items_groups?: ItemsAndGroupsListItmsGrpsTabModel[] = [];
	public member_mode?: string; // single_member / multiple_member
	public distribution_channels?: string; // distribution channel
	public currency_code?: string;
}

export class CustomerTabModel {
	public code: string;
	public name: string;
}
export class MemberTabModel {
	public code: string;
	public name: string;
}

export class ProductAndGroupTabModel {
	public itemcode: string;
	public itemname: string;
	public itemOrGroup: string;
}

export class ItemsAndGroupsTabModel {
	public itemcode: string;
	public itemname: string;
	public itemOrGroup: string;
	public membercode?: string;
	public membername?: string;
}

export class ItemsAndGroupsListItmsGrpsTabModel {
	public guid?: string;
	public item_group_guid?: string;
	public item_guid?: string;
	public allmembers?: boolean;
	public membercode?: string;
	public membername?: string;
	public erp_primary_key?: string;
	public itemsku?: string;
	public itemname?: string;
	public itemOrGroup: string;
	public classguid: string;  //Use Only ERP type as NETSUITE
	public classid: string;  //Use Only ERP type as NETSUITE
	public classname: string; //Use Only ERP type as NETSUITE
	public allclasses: boolean; //Use Only ERP type as NETSUITE
	public description: string;
	public uomgroupentry?: any;
	public allproducts?: boolean;
	public tier_basis?: string; // Value Could be "volume" | "revenue" | "notiers"
	public rebate_basis?: string;  // Value could be "doller" | "percentage" | "dollerperuom"
	public uomlist?: any[];
	public selectedUom?: string;
	public listPrice?: any;
	public rate?: any;
	public rebatefinalprice?: number;
	public tiers?: any[];
	public items?: any[];
	public id?: string;
	public name?: string;
	public in_pricelist?: boolean;
	public allenduser?: boolean;
	public enduserguid?: string;
	public endusercode?: string;
	public endusername?: string;
	public shipto?: EndUserShippingAddressInterface;

}

export interface EndUserShippingAddressInterface {
	guid: string,
	addresstype: string,
	addressname: string,
	addressname2: string,
	addressname3: string,
	city: string,
	state: string,
	country: string,
	zipcode: string,
	taxcode: string,
	taxexempt: string,
	block: string,
	street: string
}

export class ExclusionTabModel {
	public clientcode: string;
	public clientname: string;
	public membercode: string;
	public membername: string;
	public itemOrGroup: string;
	public productcode: string;
	public productname: string;
	public rate: string;
	public memberObj: any;
}

export class ExclusionListExclusionTabModel {
	public member_item_guid: string;
	public code: string;
	public name: string;
	public allmembers: boolean;
	public allproducts: boolean;
	public itemOrGroup: string;
	public erp_primary_key: string;
	public item_guid: string;
	public itemname: string;
	public itemsku: string;
	public description: string;
	public classguid: string;  //Use Only ERP type as NETSUITE
	public classid: string;  //Use Only ERP type as NETSUITE
	public classname: string; //Use Only ERP type as NETSUITE
	public allclasses: boolean; //Use Only ERP type as NETSUITE
	public uomgroupentry: string;
	public tier_basis: string;
	public rebate_basis: string;
	public selectedUom: string;
	public uomlist: any[] = [];
	public listPrice?: number;
	public rate: number;
	public finalrebateprice?: number;
	public tiers: any[] = [];
	public items: any[] = [];
	public members: any[] = [];
}


export class RuleTabModel {
	public condition: string;
	public criterion: any;
	public name: string;
	public ruleBonusType: string;
	public ruleRate: any;
	public guid: string;
}

export class Invoices {
	linenum: string;
	itemsku: string;
	itemdescription: string;
	price: number;
	linetotal: number;
	quantity: number;
	grossprice: number;
	grosstotal: number;
	costperitem: number;
	pickstatus: string;
	linestatus: string;
	taxpercentageperrow: number;
	taxtotal: number;
	uomcode: string;
	discountpercent: number;
	grossprofit: number;
	commissionRate: string;
	commissionType: string;
	condition: string;
	criterion: string;
	itemname: string;
	description: string;
	isRuleApplied: boolean;
	commissionRateIn: string;
	commissionAmount: number;
	invoiceCode: number;
	invoiceDate: string;
	clientCode: string;
	clientName: string;
	year: number;
	month: string;
	monthYear: string;
}

export class ExtraCommissionBasedRules {
	commissionAmount: number;
	commissionRate: number;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
}

export class ARcreditMemos {
	clientCode: string;
	clientName: string;
	commissionAmount: string;
	commissionRate: string;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
	doctype: string;
	description: string;
	invoiceCode: string;
	isRuleApplied: string;
	itemsku: string;
	// itemdescription: string;
	itemname: string;
	linetotal: number;
	price: number;
	quantity: number;
}

export class MiscellaneousDeductionRules {
	commissionAmount: number;
	commissionRate: number;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
	linetotal: string;
}

