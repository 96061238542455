import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmPopupData } from '../../models/common.model';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
	@Input() popupTitle: string;
	@Input() popupMessage: string;
	@Input() btnOkText: string;
	@Input() btnCancelText: string;
	@Input() popupInfoBoxClass: string;
	@Input() popupIconClass: string;

	constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmPopupData,
		private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
		if (this.data) {
			this.popupTitle = this.data.popupTitle || this.popupTitle;
			this.popupMessage = this.data.popupMessage || this.popupMessage;
			this.btnOkText = this.data.popupOkBtnText || this.btnOkText;
			this.btnCancelText = this.data.popupCancelBtnText || this.btnCancelText;
			this.popupInfoBoxClass = this.data.popupInfoBoxClass || '';
			this.popupIconClass = this.data.popupIconClass || '';
		}
	}

	ngOnInit() {
	}

	doOk(): void {
		this.dialogRef.close(true);
	}

	doCancelled(): void {
		this.dialogRef.close(false);
	}

	dismiss() {
		this.dialogRef.close();
	}
}
