<dx-popup [fullScreen]="true" [showTitle]="true" [title]="titleText" [dragEnabled]="true" [hideOnOutsideClick]="false"
	[showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopup()"
	[wrapperAttr]="{ class: 'popupToolbarPadding popupZIndex popup-content-bgchange' }" id="ViewClaimPopUp">
	<div class="rightBar">
		<div class="rightBlock">
			<div class="contentRow cf">
				<div class="borderBox mrgB20 cf">
					<div class="row">
						<div class="cols6">
							<div class="dx-field mrgB20">
								<div class="dx-field-label">Customer(s)</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-drop-down-box #refDrpDwnCustomers [dataSource]="dxDataSourceCustomers"
										[(value)]="sCustomersSelectedText" [displayExpr]="doDisplayExprCustomersName"
										valueExpr="code" [deferRendering]="true" placeholder="Select Customer(s)..."
										[showClearButton]="true" (onValueChanged)="doValueChangedCustomersDrpDwn($event)"
										(onClosed)="doClosedCustomersDrpDwn($event)" class="dx-ix-dropdownbox">
										<div *dxTemplate="let data of 'content'">
											<dx-data-grid #refDrpDwnDatagridCustomers [dataSource]="dxDataSourceCustomers"
												[hoverStateEnabled]="true" [height]="345" [filterSyncEnabled]="true"
												[filterValue]="filterValueCustomers" [(selectedRowKeys)]="selectedCustomers"
												[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
												(onSelectionChanged)="doSelectedChangedCustomers($event)">
												<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
													[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
												<dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>
												<dxo-header-filter [visible]="false"></dxo-header-filter>
												<dxo-filter-row [visible]="true"></dxo-filter-row>
												<dxo-paging [visible]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>

												<dxi-column [visible]="isShowCodeColumn" dataField="code" dataType="string"
													caption="Code" cellTemplate="cellTemplateCode" [allowHeaderFiltering]="false">
													<div *dxTemplate="let element of 'cellTemplateCode'">
														<span class="pointerMark">{{element.data.code}}</span>
													</div>
												</dxi-column>
												<dxi-column dataField="name" dataType="string" sortOrder="asc" caption="Company"
													cellTemplate="cellTemplateCompanyName" [allowHeaderFiltering]="false">
													<div *dxTemplate="let element of 'cellTemplateCompanyName'">
														{{element.data.name}}
													</div>
												</dxi-column>

												<!-- <dxi-column dataField="pricelistnum" dataType="string" caption="Price List Name" [visible]="false">
									  </dxi-column> -->

											</dx-data-grid>
										</div>
									</dx-drop-down-box>
								</div>
							</div>
						</div>
						<div class="cols2" *ngIf="enable_chargeback_look_back_period">
							<div class="datePicker">
								<dx-date-box [(value)]="claimDate" type="date" displayFormat="yyyy-MM-dd">
								</dx-date-box>
								<div class="inputField inputFieldFilterLbl">
									<label for="inputFieldFilterLbl">Claim Date</label>
								</div>
							</div>
						</div>
						<div class="cols2">
							<dx-button class="primaryBtn" text="Generate Report" (onClick)="doGenerateReport()" type="normal"
								stylingMode="contained" icon="" [useSubmitBehavior]="false"
								[disabled]="(selectedCustomers.length === 0 && (this.claimDate === 'null' || this.claimDate === null || this.claimDate === 'undefined' || this.claimDate === '') )">
							</dx-button>
						</div>
					</div>
				</div>
				<div class="borderBox mrgB20 cf" *ngIf="isShowRptData">
					<div class="row">
						<div class="cols12 mrgB10">
							<dx-button class="primaryBtn alignRight" text="Export Excel" (onClick)="doExportExcel()"
								type="normal" stylingMode="contained" icon="importwhite" [useSubmitBehavior]="false">
							</dx-button>
						</div>
					</div>
					<dx-tab-panel id="tabPanel" [deferRendering]="false" (onOptionChanged)="doOnTabOptionChanged($event)"
						[dataSource]="customersTabs">
						<div *dxTemplate="let tabPanelItem of 'item'">
							<div class="tableOut">
								<dx-data-grid #endUsersRptDataGridRef_{{tabPanelItem.key}}
									id="endUsersRptDataGridRef_{{tabPanelItem.key}}" [dataSource]="tabPanelItem.data"
									keyExpr="guid" [wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true"
									[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
									[filterSyncEnabled]="true" [filterValue]="filterValue" [remoteOperations]="false"
									[columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
									[height]="'calc(100vh - 390px)'" (onToolbarPreparing)="doEndUsersRptToolbarPreparing($event)"
									(onInitialized)="initializedEndUsersRptDataGrid($event)"
									class="cellColor hoverClr headerPanelInx" (onCellClick)="doOnCellClick($event)">
									<dxo-scrolling columnRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
									<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
									<dxo-pager [visible]="true" [showPageSizeSelector]="true"
										[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
									</dxo-pager>
									<dxo-filter-row [visible]="true"></dxo-filter-row>
									<dxo-filter-panel [visible]="false"></dxo-filter-panel>
									<dxo-group-panel [visible]="true"></dxo-group-panel>
									<dxo-header-filter [visible]="true"></dxo-header-filter>
									<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
									<!-- <dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadEndUsersRptState"
										[customSave]="doSaveEndUsersRptState" [storageKey]="stateStorageKey">
									</dxo-state-storing> -->
									<div *dxTemplate="let data of 'templateGridText'">
										<!-- <div class="dx-field mrgB20">
											<div class="dx-field-label">Customer Code:</div>
											<div class="dx-field-value"> {{tabPanelItem.title}}</div>
											<div class="dx-field-label">Customer Name:</div>
											<div class="dx-field-value"> {{tabPanelItem.customer_name}}</div>
										</div> -->
										<div class="custDetail width100p mrgR20">
											<h4 class="graySubHead textWrap">Customer Code:
												<span class="black">{{tabPanelItem.title}}</span>
											</h4>
											<h4 class="graySubHead textWrap">Customer Name:
												<span class="black">{{tabPanelItem.customer_name}}</span>
											</h4>
										</div>
									</div>
									<div *dxTemplate="let data of 'templateFilterText'">
										<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
											<span>{{filterText}}</span>
										</div>
									</div>
									<div *dxTemplate="let data of 'templateActionBtns'">
										<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
											[showArrowIcon]="false" text="Action"
											class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
											[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
											keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
											(onItemClick)="doActionsBtnItemClick($event)"
											[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
									</div>
									<dxi-column dataField="end_user_code" [allowHeaderFiltering]="false" caption="End-user Code"
										dataType="string" [width]="'auto'"></dxi-column>
									<dxi-column dataField="end_user_name" [allowHeaderFiltering]="false" caption="End-user Name"
										dataType="string" [width]="'auto'"></dxi-column>
									<dxi-column dataField="pricelistno" [allowHeaderFiltering]="false" caption="Pricelist No."
										dataType="string" [width]="'auto'"></dxi-column>
									<dxi-column dataField="pricelistname" [allowHeaderFiltering]="false" caption="Pricelist Name"
										dataType="string" [width]="'auto'"></dxi-column>
									<dxi-column dataField="itemname" [allowHeaderFiltering]="false" caption="Item"
										dataType="string" [width]="'auto'"></dxi-column>
									<dxi-column dataField="description" [allowHeaderFiltering]="false" caption="Description"
										dataType="string" [width]="'auto'"></dxi-column>
									<dxi-column dataField="docnum" [allowHeaderFiltering]="false" caption="Doc No."
										cellTemplate="cellTemplateDocnum" alignment="center" dataType="string">
										<div *dxTemplate="let element of 'cellTemplateDocnum'">
											<span *ngIf="element.data.type !== 'invoice'">{{element.data.docnum || ''}}</span>
											<span *ngIf="element.data.type === 'invoice'"
												class="highlight pointerMark">{{element.data.docnum || ''}}</span>
										</div>
									</dxi-column>
									<dxi-column dataField="type" dataType="string" [allowSorting]="true" caption="Doc. Type"
										[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateDocType"
										[width]="'auto'">
										<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
										</dxo-header-filter>
										<div *dxTemplate="let element of 'cellTemplateDocType'">
											{{element.data.type | filterFromList:listOfDocTypeHeaderFilter : 'value'
											: 'text' }}
										</div>
									</dxi-column>
									<dxi-column dataField="claim_no" [allowHeaderFiltering]="false" caption="Claim No."
										cellTemplate="cellTemplateClaimNo" alignment="center" dataType="string">
										<div *dxTemplate="let element of 'cellTemplateClaimNo'">
											<span class="highlight pointerMark">{{element.data.claim_no || ''}}</span>
										</div>
									</dxi-column>
									<dxi-column caption="Claim Status" dataField="claimedStatus" [allowEditing]="false"
										cellTemplate="cellTemplateStatus" [allowHeaderFiltering]="true" [allowFiltering]="true"
										[allowSorting]="true" dataType="string">
										<dxo-header-filter [dataSource]="listOfHeaderFilterStatuses"></dxo-header-filter>
										<div *dxTemplate="let element of 'cellTemplateStatus'">
											<div
												[ngClass]="element.data.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'classname'">
												{{element.data.claimedStatus || '' | filterFromList:listOfStatuses : 'value' :
												'finaltext'}}
											</div>
										</div>
									</dxi-column>
									<dxi-column caption="UOM" dataField="selectedUom" cellTemplate="cellTemplateContractUOM"
										alignment="center" dataType="string" [allowHeaderFiltering]="true"
										[allowFiltering]="false">
										<div *dxTemplate="let element of 'cellTemplateContractUOM'">
											{{element.data.selectedUom | uppercase}}
										</div>
									</dxi-column>
									<dxi-column dataField="docdate" [allowHeaderFiltering]="false"
										caption="Invoice Date/ Claimed Date" cellTemplate="cellTemplateDocdate" dataType="date"
										format="yyyy-MM-dd">
										<div *dxTemplate="let element of 'cellTemplateDocdate'">
											{{element.data.docdate ||''}}
										</div>
									</dxi-column>
									<dxi-column dataField="price" [allowHeaderFiltering]="false" caption="Invoice Price"
										dataType="number">
										<dxo-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-format>
										<div *dxTemplate="let element of 'cellTemplatePrice'">
											{{element.data.price || 0 | customCurrencyPipe}}
										</div>
									</dxi-column>
									<dxi-column dataField="quantity" [allowHeaderFiltering]="false"
										caption="Doc./Approved Quantity" cellTemplate="cellTemplateQuantity" dataType="number">
										<div *dxTemplate="let element of 'cellTemplateQuantity'">
											{{element.data.quantity || ''}}
										</div>
									</dxi-column>
									<dxi-column dataField="lookBackPeriod" [allowHeaderFiltering]="false"
										caption="Lookback Period" cellTemplate="cellLookbackPeriod" dataType="string">
										<div *dxTemplate="let element of 'cellLookbackPeriod'">
											{{element.data.lookBackPeriod || ''}}
										</div>
									</dxi-column>
									<dxi-column dataField="claim_date" [allowHeaderFiltering]="false"
										caption="Claim Date" cellTemplate="cellTemplateClaimdate" dataType="date"
										format="yyyy-MM-dd">
										<div *dxTemplate="let element of 'cellTemplateClaimdate'">
											{{element.data.claim_date ||''}}
										</div>
									</dxi-column>
								</dx-data-grid>
							</div>
						</div>
					</dx-tab-panel>
				</div>
			</div>
		</div>
	</div>
</dx-popup>
<app-view-invoice-detail-popup *ngIf="isShowInvoiceDetailPopUp" [viewInvoiceDetailProps]="viewInvoiceDetailProps">
</app-view-invoice-detail-popup>
<app-view-craditmemo-detail-popup *ngIf="isShowCreditMemoDetailPopUp"
	[viewCreditMemoDetailProps]="viewCreditMemoDetailProps"></app-view-craditmemo-detail-popup>
<app-eucc-view-chargeback-documents-dx-popup
	[loadViewChargebackDocDxDialogSubject]="loadViewChargebackDocDxDialogSubject"></app-eucc-view-chargeback-documents-dx-popup>