<div class="rightBar">
	<div class="rightBlock">
		<!-- <div class="titleRow">
			<h1>Admin Dashboard</h1>
		</div> -->
		<div class="contentRow cf">
			<div class="statisticBlock adminStatisticBlock newStBlock newTileBlock">
				<div class="row">
					<div *ngxPermissionsOnly="['CRM_LEAD_VIEW']" class="cols4">
						<app-ap-open-leads-tiles></app-ap-open-leads-tiles>
					</div>
					<div *ngxPermissionsOnly="['CRM_PROSPECT_VIEW']" class="cols4">
						<app-ap-open-prospects-tiles></app-ap-open-prospects-tiles>
					</div>
					<div *ngxPermissionsOnly="['CRM_CUSTOMER_VIEW']" class="cols4">
						<app-ap-active-customer-tiles></app-ap-active-customer-tiles>
					</div>
					<div *ngxPermissionsOnly="['CRM_TASK_VIEW']" class="cols4">
						<app-ap-open-task-tiles></app-ap-open-task-tiles>
					</div>
					<div class="cols6">
						<app-ap-sales-invoice-total-tiles></app-ap-sales-invoice-total-tiles>
					</div>
					<div *ngxPermissionsOnly="['INCENTIVE_COMMISSION_VIEW']" class="cols6">
						<app-ap-commission-total-tiles></app-ap-commission-total-tiles>
					</div>
				</div>
				<div class="cf"></div>
			</div>
			<!-- <div class="reportsSection posRelativeAndWidth">
				<app-ap-quarterly-report></app-ap-quarterly-report>
			</div> -->
			<div class="borderBox agreementBox posRelativeAndWidth" *ngxPermissionsOnly="['CRM_TASK_VIEW']">
				<app-ap-dashboard-task-list></app-ap-dashboard-task-list>
			</div>

		</div>
	</div>
</div>