import { Component, OnInit, OnDestroy, ViewChild, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { Guid } from '@app/models/guid';
import { ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { DxTreeListComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-incentives-export-pdf',
	templateUrl: './incentives-export-pdf.component.html',
	styleUrls: ['./incentives-export-pdf.component.css']
})

export class IncentivesExportPdfComponent implements OnInit, OnChanges, OnDestroy {
	@Input() listOfColumnsProps: any;
	@Input() listOpenFrom: string;
	@Output() exportPdfEmitter: any = new EventEmitter<any>();;
	@ViewChild('treeList') treeList: DxTreeListComponent;
	private guid = new Guid();
	public listOfColumns: any[] = [];
	public listOfSelectedColumns: any[];
	private updateUserSettingSbsn: Subscription;
	public report_layout: string;
	public no_of_column: number;
	public listOfReportLayout = [
		{
			value: 'portrait',
			text: 'Portrait',
			no_of_column: 6,
		},
		{
			value: 'landscape',
			text: 'Landscape',
			no_of_column: 10,
		}
	];
	private cancelTimeout: any;

	// #region Angular Lify cycle Methods
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService) {
		this.doReorderColumns = this.doReorderColumns.bind(this);
	}

	ngOnInit() {

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['listOfColumnsProps'] && changes['listOfColumnsProps'].currentValue) {
			const listOfColumnsDetails = changes['listOfColumnsProps'].currentValue;
			if (listOfColumnsDetails && listOfColumnsDetails.length > 0) {
				this.preInit();
			}
		}
		if (changes['listOpenFrom'] && changes['listOpenFrom'].currentValue) {
			const listOpenFrom = changes['listOpenFrom'].currentValue;
		}
	}

	ngOnDestroy() {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.cancelTimeout) {
			clearTimeout(this.cancelTimeout);
		}
	}

	ngAfterViewInit() {

	}
	// #endregion

	public doChangeReportLayout(event) {
		const reportLayoutObj = this.listOfReportLayout.find(rl => rl.value === this.report_layout);
		this.no_of_column = reportLayoutObj.no_of_column;
		this.treeList.instance.deselectAll();
		if (this.cancelTimeout) {
			clearTimeout(this.cancelTimeout);
		}
		this.cancelTimeout = setTimeout(() => {
			this.doSaveUpdateListOfColums();
		}, 600);
	}

	public doDragChangeColumns(e) {
		const visibleRows = e.component.getVisibleRows();
		const sourceNode = e.component.getNodeByKey(e.itemData.guid);
		let targetNode = visibleRows[e.toIndex].node;
		while (targetNode && targetNode.data) {
			if (targetNode.data.guid === sourceNode.data.guid) {
				e.cancel = true;
				break;
			}
			targetNode = targetNode.parent;
		}
	}

	public doReorderColumns(e) {
		const visibleRows = e.component.getVisibleRows();
		if (e.dropInsideItem) {
			e.itemData.parent_guid = visibleRows[e.toIndex].key;
			e.component.refresh();
		} else {
			const sourceData = e.itemData;
			const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
			let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;
			if (sourceData && targetData) {
				if (sourceData.parent_guid !== targetData.parent_guid) {
					targetData = sourceData;
					return false;
				}
			}
			if (targetData && e.component.isRowExpanded(targetData.guid)) {
				sourceData.parent_guid = targetData.guid;
				targetData = null;
			} else {
				sourceData.parent_guid = targetData ? targetData.parent_guid : '';
			}
			const sourceIndex = this.listOfColumns.indexOf(sourceData);
			this.listOfColumns.splice(sourceIndex, 1);
			const targetIndex = this.listOfColumns.indexOf(targetData) + 1;
			this.listOfColumns.splice(targetIndex, 0, sourceData);
		}

		if (this.cancelTimeout) {
			clearTimeout(this.cancelTimeout);
		}
		this.cancelTimeout = setTimeout(() => {
			this.doSaveUpdateListOfColums();
		}, 600);
	}

	public doSelectionChangedColumn(event: any) {
		if (event.selectedRowKeys.length > this.no_of_column) {
			event.component.deselectRows(event.currentSelectedRowKeys);
			this._ToastrService.info('For ' + this.report_layout + ' type, only ' + this.no_of_column + ' columns can be added in to the report.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (this.cancelTimeout) {
			clearTimeout(this.cancelTimeout);
		}
		this.cancelTimeout = setTimeout(() => {
			this.doSaveUpdateListOfColums();
		}, 600);
	}

	private doSaveUpdateListOfColums() {
		if (this.listOfColumns && this.listOfColumns.length > 0) {
			const listOfSelectedRowKeys = this.treeList.instance.getSelectedRowKeys() || [];
			const listOfColumns = [];
			this.listOfColumns.forEach(column => {
				if (column && column.guid) {
					const dataObj = JSON.parse(JSON.stringify(column));
					dataObj.is_export = listOfSelectedRowKeys.filter(key => key === column.guid).length > 0;
					listOfColumns.push(dataObj);
				}
			});
			let newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
			const exportColumnConfig = {
				list_of_columns: listOfColumns,
				report_layout: this.report_layout
			};
			if (!newSection) {
				newSection = {};
			}
			if (!newSection.hasOwnProperty('export_columns_configs')) {
				newSection.export_columns_configs = {};
			}
			newSection.export_columns_configs = exportColumnConfig;

			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
			formData.append('section', ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING);
			formData.append('user_settings', JSON.stringify(newSection));
			if (this.updateUserSettingSbsn) {
				this.updateUserSettingSbsn.unsubscribe();
			}
			this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
				next: (response) => {
					if (response && response.flag) {
						const userSettings = this._AppCommonSrvc.getUserSettings();
						if (userSettings && userSettings.length <= 0) {
							const newSection = {};
							newSection[ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING] = newSection;
							this._AppCommonSrvc.setUserSettings([newSection]);
							this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING, newSection);
						} else {
							this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING, newSection);
						}
					}
				}, error: (error) => {
				}
			});

		}
	}

	private setOrderListForTreeList(list: any[]) {
		const listOfColumns = [];
		list.forEach(column => {
			if (column.dataField) {
				column['guid'] = this.guid.newGuid();
				column['parent_guid'] = '';
				listOfColumns.push(column);
			}
		});
		this.listOfColumns = listOfColumns;
	}

	private setInvoiceListForTreeList(listOfCustColumns: any[], listOfProdColumns: any[], listOfInvoicesColumns: any[]) {
		const listOfColumns = [];
		const customerGuid = this.guid.newGuid();
		const customersObj = {
			guid: customerGuid,
			dataField: 'customerscolumns',
			parent_guid: '',
			caption: 'Customers'
		};
		listOfColumns.push(customersObj);
		listOfCustColumns.forEach(column => {
			if (column.dataField) {
				column['guid'] = this.guid.newGuid();
				column['parent_guid'] = customerGuid;
				listOfColumns.push(column);
			}
		});
		const productGuid = this.guid.newGuid();
		const productsObj = {
			guid: productGuid,
			dataField: 'productscolumns',
			parent_guid: customerGuid,
			caption: 'Products'
		};
		listOfColumns.push(productsObj);
		listOfProdColumns.forEach(column => {
			if (column.dataField) {
				column['guid'] = this.guid.newGuid();
				column['parent_guid'] = productGuid;
				listOfColumns.push(column);
			}
		});
		const invoiceGuid = this.guid.newGuid();
		const invoicesObj = {
			guid: invoiceGuid,
			dataField: 'invoicescolumns',
			parent_guid: productGuid,
			caption: 'Invoices'
		};
		listOfColumns.push(invoicesObj);
		listOfInvoicesColumns.forEach(column => {
			if (column.dataField) {
				column['guid'] = this.guid.newGuid();
				column['parent_guid'] = invoiceGuid;
				listOfColumns.push(column);
			}
		});
		this.listOfColumns = listOfColumns;
	}

	public doExportPdf() {
		const listOfSelectedRows = this.treeList.instance.getSelectedRowKeys();
		if (listOfSelectedRows.length <= 0) {
			this._ToastrService.info('Please select which columns you are required into PDF', 'Info', { closeButton: true, tapToDismiss: true });
		} else {
			this.exportPdfEmitter.emit(new Date().getTime());
		}
	}

	private preInit() {
		let newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING)));
		const listOfColumnsDetails = this.listOfColumnsProps;
		if (newSection && newSection.hasOwnProperty('export_columns_configs')) {
			this.report_layout = newSection.export_columns_configs.report_layout || this.listOfReportLayout[0].value;
			if (newSection.export_columns_configs.hasOwnProperty('list_of_columns') && newSection.export_columns_configs.list_of_columns.length > 0) {
				let listOfExistingColumn = JSON.parse(JSON.stringify(newSection.export_columns_configs.list_of_columns));
				const invoiceDatagrid = listOfColumnsDetails[0];
				if (listOfColumnsDetails[0].code === 'customers') {
					listOfExistingColumn = this.mergeCustomerBothNewAndExistingColumn(listOfExistingColumn, invoiceDatagrid.list_of_customers, 'customerscolumns');

					listOfExistingColumn = this.mergeCustomerBothNewAndExistingColumn(listOfExistingColumn, invoiceDatagrid.list_of_products, 'productscolumns');

					listOfExistingColumn = this.mergeCustomerBothNewAndExistingColumn(listOfExistingColumn, invoiceDatagrid.list_of_invoices, 'invoicescolumns');
				}
				if (listOfColumnsDetails[0].code === 'orders') {
					listOfExistingColumn = this.mergeOrderBothNewAndExistingColumn(listOfExistingColumn, invoiceDatagrid.list_of_orders, '');
				}
				this.listOfColumns = listOfExistingColumn;
				this.listOfSelectedColumns = [];
				this.listOfColumns.forEach(col => {
					if (col.is_export) {
						this.listOfSelectedColumns.push(col.guid);
					}
				});
			} else {
				if (listOfColumnsDetails[0].code === 'customers') {
					const invoiceDatagrid = listOfColumnsDetails[0];
					this.setInvoiceListForTreeList(invoiceDatagrid.list_of_customers, invoiceDatagrid.list_of_products, invoiceDatagrid.list_of_invoices);
				}
				if (listOfColumnsDetails[0].code === 'orders') {
					this.setOrderListForTreeList(listOfColumnsDetails[0].list_of_orders);
				}
			}
		} else {
			this.report_layout = this.listOfReportLayout[0].value;
			if (listOfColumnsDetails[0].code === 'customers') {
				const invoiceDatagrid = listOfColumnsDetails[0];
				this.setInvoiceListForTreeList(invoiceDatagrid.list_of_customers, invoiceDatagrid.list_of_products, invoiceDatagrid.list_of_invoices);
			}
			if (listOfColumnsDetails[0].code === 'orders') {
				this.setOrderListForTreeList(listOfColumnsDetails[0].list_of_orders);
			}
		}
		if (this.report_layout) {
			const reportLayoutObj = this.listOfReportLayout.find(rl => rl.value === this.report_layout);
			this.no_of_column = reportLayoutObj.no_of_column;
		}
	}

	private mergeCustomerBothNewAndExistingColumn(listOfExistingColumn: any[], latestColumns: any[], parentColumnName: string) {
		const existingColumns = JSON.parse(JSON.stringify(listOfExistingColumn));
		const parentGuidIndex = existingColumns.findIndex(col => col.dataField === parentColumnName);
		const parentGuid = parentGuidIndex !== -1 ? existingColumns[parentGuidIndex].guid : '';
		const existingColumnLength = existingColumns.filter(col => col.parent_guid === parentGuid).length;
		const newColumnsLength = latestColumns.length;
		if (newColumnsLength !== existingColumnLength) {

			// Add colums to Existing array which was not in existing column array
			latestColumns.forEach(col => {
				if (col.dataField) {
					const isExistsIndex = existingColumns.findIndex(col => col.dataField === col.dataField);
					if (isExistsIndex === -1) {
						const customersObj = {
							guid: this.guid.newGuid(),
							dataField: col.dataField,
							parent_guid: parentGuid,
							caption: col.caption,
							dataType: col.dataType,
							is_export: false
						};
						const invoiceColumnsLength = existingColumns.filter(col => col.parent_guid === parentGuid).length;
						existingColumns.splice(invoiceColumnsLength - 1, 0, customersObj);
					}
				}
			});

			// // Removed colums from Existing which was not in Latest column array
			existingColumns.forEach((existCol, i) => {
				if (parentGuid === existCol.parent_guid && (existCol.dataField !== 'productscolumns' && existCol.dataField !== 'invoicescolumns' && existCol.dataField !== 'customerscolumns')) {
					const isMatchedIndex = latestColumns.findIndex(col => col.dataField && col.dataField === existCol.dataField);
					if (isMatchedIndex === -1) {
						existingColumns.splice(i, 1);
					}
				}
			});
		}
		return existingColumns;
	}

	private mergeOrderBothNewAndExistingColumn(listOfExistingColumn: any[], latestColumns: any[], parentColumnName: string) {
		const existingColumns = JSON.parse(JSON.stringify(listOfExistingColumn));
		const parentGuid = parentColumnName;
		const existingColumnLength = existingColumns.length;
		const newColumnsLength = latestColumns.length;
		if (newColumnsLength !== existingColumnLength) {
			latestColumns.forEach(col => {
				if (col.dataField) {
					const isExistsIndex = existingColumns.findIndex(col => col.dataField === col.dataField);
					if (isExistsIndex === -1) {
						const customersObj = {
							guid: this.guid.newGuid(),
							dataField: col.dataField,
							parent_guid: parentGuid,
							caption: col.caption,
							dataType: col.dataType,
							is_export: false
						};
						const invoiceColumnsLength = existingColumns.filter(col => col.parent_guid === parentGuid).length;
						existingColumns.splice(invoiceColumnsLength - 1, 0, customersObj);
					}
				}
			});

			existingColumns.forEach((existCol, i) => {
				const isMatchedIndex = latestColumns.findIndex(col => col.dataField && col.dataField === existCol.dataField);
				if (isMatchedIndex === -1) {
					existingColumns.splice(i, 1);
				}
			});
		}
		return existingColumns;
	}
}
