<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="pageTitle" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{class: 'twoPopupUpLoaderIx' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="rightBar" id="mainContent1">
				<div class="rightBlock">
					<div class="contentRow cf">
						<div
							*ngIf="(!endUserInvoiceDetails || (endUserInvoiceDetails && !endUserInvoiceDetails.guid)) && isResponse"
							class="row">
							<div class="cols12 center">No Records Found.</div>
						</div>
						<div *ngIf="endUserInvoiceDetails && endUserInvoiceDetails.guid" class="quoteDesign cf">
							<div class="contBtn titleBtnCol">
							</div>

							<div class="subTitleRow" *ngIf="endUserInvoiceDetails.docnum">
								<!-- Invoice ID -->
								<h2 class="invoiceLabel">
									<span class="lbl"> Invoice </span>
									{{endUserInvoiceDetails.docnum ? ' #' + endUserInvoiceDetails.docnum : ''}}
								</h2>
								<span *ngIf="endUserInvoiceDetails.documentstatus =='bost_Open'"
									class="inStatusLabel orangeInvoice mrgL20">Open</span>
								<span *ngIf="endUserInvoiceDetails.documentstatus =='bost_Close'"
									class="inStatusLabel greenInvoice mrgL20">Closed</span>
								<span *ngIf="endUserInvoiceDetails.documentstatus =='bost_Cancel'"
									class="inStatusLabel redInvoice mrgL20">Canceled</span>
								<span *ngIf="endUserInvoiceDetails.documentstatus =='deleted'"
									class="inStatusLabel redInvoice mrg210">Deleted</span>
							</div>
							<div class="orderBox row">
								<div class="orderDetail cols4">
									<!-- <div class="custImg">
										<span class="icon-default-allcompany icon-set1"></span>
									</div> -->

									<div class="custDetail">
										<span class="custId" *ngIf="!isQBErpUser">
											<!-- Customer ID -->
											<span class="lbl">End-User ID #</span>
											<span>{{endUserInvoiceDetails.cardcode}}</span>
										</span>

										<h3 class="custNm">
											<span class="contTx lbl mrgB0 mrgR0">End-User: </span>
											<strong>{{endUserInvoiceDetails.cardname}}</strong>
										</h3>
										<div
											*ngIf="endUserInvoiceDetails.contactpersondata && endUserInvoiceDetails.contactpersondata.name">
											<!-- <h4 class="graySubHead">Contact Persons</h4> -->
											<div class="contDtl">
												<span class="contTx">
													<strong>{{endUserInvoiceDetails.contactpersondata.name}}</strong>
													<span *ngIf="endUserInvoiceDetails.contactpersondata.position">
														{{endUserInvoiceDetails.contactpersondata.position}}
													</span>
												</span>
												<span class="contTx icoCall" *ngIf="endUserInvoiceDetails.contactpersondata.phone1">
													<span class="icon-call-1"></span>
													<a>
														{{endUserInvoiceDetails.contactpersondata.phone1}}
													</a>
												</span>
												<span class="contTx icoMail" *ngIf="endUserInvoiceDetails.contactpersondata.e_mail">
													<span class="icon-mail"></span>
													<a>
														{{endUserInvoiceDetails.contactpersondata.e_mail}}
													</a>
												</span>
												<!-- <span class="contTx">
																			<span class="lbl">Shipping Type: </span>[SHIPPING TYPE]
																		</span> -->
											</div>
										</div>

										<div class="contDtl"
											*ngIf="endUserInvoiceDetails.docdate || endUserInvoiceDetails.docduedate">
											<span class="contTx icoDate" *ngIf="endUserInvoiceDetails.docdate">
												<span class="lbl">Invoice Date: </span>
												{{ endUserInvoiceDetails.docdate || '' | customDateFormatPipe:'dateonly'}}
											</span>
										</div>

										<span class="custId"
											*ngIf="endUserInvoiceCustomerDetails && endUserInvoiceCustomerDetails.guid">
											<span class="lbl">Customer ID</span>
											<span>{{ endUserInvoiceCustomerDetails.code ? ' #'+ endUserInvoiceCustomerDetails.code
												:
												''}}</span>
										</span>
										<h3 class="custNm"
											*ngIf="endUserInvoiceCustomerDetails && endUserInvoiceCustomerDetails.guid">
											<span class="contTx lbl mrgB0 mrgR0">
												<span>Customer Name :</span>
												<strong> {{ endUserInvoiceCustomerDetails.name || ''}}</strong>
											</span>
										</h3>
									</div>
									<div *ngIf="endUserInvoiceDetails.ponumber || endUserInvoiceDetails.shipmethod"
										class="poShipping">
										<div class="row">
											<div *ngIf="endUserInvoiceDetails.ponumber" class="cols6">
												<span>PO Number: </span>{{endUserInvoiceDetails.ponumber}}
											</div>
											<div *ngIf="endUserInvoiceDetails.shipmethod" class="cols6">
												<span>Shipping Method: </span>
												{{endUserInvoiceDetails.shipmethod}}
											</div>
										</div>
									</div>
								</div>

								<div class="orderAddress cols6 detailsPage">
									<div class="cols6 addressBox">
										<h3 class="custNm">Billing Address</h3>
										<span class="contTx" *ngIf="endUserInvoiceDetails.address"
											[innerHTML]="endUserInvoiceDetails.address"></span>
									</div>
									<div class="cols6 addressBox">
										<h3 class="custNm">Shipping Address</h3>
										<span class="contTx" *ngIf="endUserInvoiceDetails.address2"
											[innerHTML]="endUserInvoiceDetails.address2"> </span>
										<span *ngIf="endUserShipToDetails && endUserShipToDetails.guid" class="contTx">
											{{endUserShipToDetails.addressname || ''}} <br />
											<span class="contTx" [innerHTML]="endUserShipToDetails | showClientAddressesPipe">
											</span>
										</span>
										<span
											*ngIf="endUserShipToDetails && endUserShipToDetails.guid && enabled_tax_calculation_feature"
											class="contTx mrgT10">
											<strong>Tax Exempt: </strong>
											<span> {{endUserShipToDetails.taxexempt ? 'YES' :'NO'}} </span>
										</span>
										<span
											*ngIf="endUserShipToDetails && endUserShipToDetails.guid && enabled_tax_calculation_feature"
											class="contTx mrgT10">
											<strong>Tax Code: </strong>
											<span> {{endUserShipToDetails.taxcode || ''}} </span>
										</span>
										<div
											*ngIf="listOfShipToFeatureCustomFields && listOfShipToFeatureCustomFields.length > 0 "
											class="mrgT10">
											<p *ngFor="let specificField of listOfShipToFeatureCustomFields;let menuIndex = index;"
												class="externalFieldDescriptionix">
												<span class="externalFieldLabelix">{{specificField.custom_label}}:
												</span> {{specificField.custom_display_value}}
											</p>
										</div>
									</div>
								</div>

								<!--
							<div class="orderStatus odStatus cols2 noBdr mrgT0">
										<ul *ngIf="endUserInvoiceDetails.totalIncentivePaid !== 0" class="statusBxLnremove">
									<li>
										<span class="stLbl">Incentives:</span>
										<span class="txtRight inlineBlock alignRight"
											[attr.id]="endUserInvoiceDetails.totalIncentivePaid > 0 ? 'incentiveDetails' : null"
											[ngClass]="{'highlight pointerMark':endUserInvoiceDetails.totalIncentivePaid > 0}">
											{{endUserInvoiceDetails.totalIncentivePaid || 0 | customCurrencyPipe}}
										</span>
										<dx-tooltip target="#incentiveDetails" showEvent="dxhoverstart" hideEvent="dxhoverend"
											position="bottom">
											<div *dxTemplate="let data = data of 'content'">
												Incentive Details
											</div>
										</dx-tooltip>
									</li>
								</ul>
								<ul *ngIf="endUserInvoiceDetails.doctotal !== 0" class="statusBxLnremove">
									<li>
										<span class="stLbl">Invoice Total:</span>
										<span class="alignRight">
											{{myMath.abs(endUserInvoiceDetails.doctotal) || 0 | customCurrencyPipe}}
										</span>
									</li>
								</ul>
								<ul *ngIf="endUserInvoiceDetails.cashdiscount" class="statusBxLnremove">
									<li>
										<span class="stLbl">Cash Discount:</span>
										<span class="alignRight">
											{{myMath.abs(endUserInvoiceDetails.cashdiscount) || 0 | customCurrencyPipe}}
										</span>
									</li>
								</ul>
								<ul *ngIf="endUserInvoiceDetails.totalpaidamount" class="statusBxLnremove">
									<li>
										<span class="stLbl">Total Payment:</span>
										<span class="alignRight">
											{{myMath.abs(endUserInvoiceDetails.totalpaidamount) || 0 | customCurrencyPipe}}
										</span>
									</li>
								</ul> 
							</div>
							-->

							</div>
						</div>
						<div *ngIf="endUserInvoiceDetails && endUserInvoiceDetails.guid" class="seprator mrgB20 mrgT10"></div>
						<div class="padB10 mrgB0 cf" *ngIf="isShowDatagrid">
							<div class="subTitleRow">
								<div class="right secondaryTitle">
									<div *ngIf="!isQBOnlineErpUser" class="noteBx mrg0">
										<span>
											<strong>End-User Price List :</strong>
										</span>
										<span *ngIf="listOfPricelist && listOfPricelist.length > 0">
											{{listOfPricelist[0].pricelistname || ''}}
										</span>
										<span *ngIf="!listOfPricelist || (listOfPricelist && listOfPricelist.length <= 0)">
											<strong> &mdash; </strong>
										</span>
									</div>
								</div>
								<h2>List of Items</h2>
							</div>

							<div class="mrgB0 mrgT10 cf">
								<dx-data-grid #itemsListDatagridRef id="gridContainer"
									[dataSource]="endUserInvoiceDetails.documentlines" keyExpr="linenum"
									(onInitialized)="doInitializedInvoiceDocLineItem($event)" [showBorders]="true"
									[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
									[columnAutoWidth]="true" [width]="'100%'"
									class="hoverClr hideFilterPanelInx minHeightDataGrid150 hideAccordion">
									<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>

									<dxi-column dataField="itemsku" dataType="string" caption="SKU" [visible]="isQBOnlineErpUser"
										[width]="'9%'" [minWidth]="90" [allowSorting]="false" cellTemplate="cellTemplateItemSku">
										<div *dxTemplate="let element of 'cellTemplateItemSku'">
											{{element.data.itemsku || ''}}
										</div>
									</dxi-column>

									<dxi-column dataField="itemname" dataType="string" caption="Item" [minWidth]="100"
										[width]="'9%'" cellTemplate="cellTemplateItemName">
										<div *dxTemplate="let element of 'cellTemplateItemName'">
											{{element.data.itemname || ''}}
										</div>
									</dxi-column>

									<dxi-column dataField="description" dataType="string" caption="Description"
										[allowSorting]="false" cellTemplate="cellTemplateDescription" [minWidth]="130">
										<div *dxTemplate="let element of 'cellTemplateDescription'">
											{{element.data.description || ''}}
										</div>
									</dxi-column>


									<dxi-column dataField="price" caption="Price" cellTemplate="cellTemplateUnitPrice"
										[minWidth]="90" [width]="'7%'" dataType="number" [allowSorting]="false">
										<div *dxTemplate="let element of 'cellTemplateUnitPrice'">
											{{element.data.price || 0 | customCurrencyPipe}}
										</div>
									</dxi-column>

									<dxi-column dataField="quantity" caption="Quantity" cellTemplate="cellTemplateQuantity"
										[minWidth]="90" [width]="'7%'" dataType="number" [allowSorting]="false">
										<div *dxTemplate="let element of 'cellTemplateQuantity'">
											{{element.data.quantity || ''}}
										</div>
									</dxi-column>

									<dxi-column dataField="uomcode" dataType="string" caption="UOM"
										cellTemplate="cellTemplateUomCode" [minWidth]="80" [width]="'5%'" [allowSorting]="false">
										<dxo-lookup [dataSource]="listOfUoms" [displayExpr]="getDisplayExprUomName"
											valueExpr="code">
										</dxo-lookup>
										<div *dxTemplate="let element of 'cellTemplateUomCode'">
											{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' |
											titlecase}}
										</div>
									</dxi-column>


									<dxi-column dataField="taxcode" dataType="string" [visible]="enabled_tax_calculation_feature"
										caption="Tax Code" [width]="'9%'" [minWidth]="90" [allowEditing]="false"
										[allowSorting]="false" cellTemplate="cellTemplateTaxCode" alignment="center">
										<div *dxTemplate="let element of 'cellTemplateTaxCode'">
											{{(!element.data.taxexempt) ? element.data.taxcode : ''}}
										</div>
									</dxi-column>

									<dxi-column dataField="taxexempt" dataType="string"
										[visible]="enabled_tax_calculation_feature" caption="Tax Exempt" [width]="'9%'"
										[minWidth]="90" [allowEditing]="false" [allowSorting]="false"
										cellTemplate="cellTemplateTaxExempt" alignment="center">
										<div *dxTemplate="let element of 'cellTemplateTaxExempt'">
											{{(element.data.taxexempt) ? 'Yes' : 'No'}}
										</div>
									</dxi-column>

									<dxi-column dataField="linetotal" caption="Line Total" [allowSorting]="false" [width]="'10%'"
										[minWidth]="100" dataType="number">
										<dxo-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-format>
									</dxi-column>

								</dx-data-grid>
							</div>

							<div class="cols12 paymentBtmRow">
								<div class="row">
									<diV class="totalRowCal withAddBtn noActClm noBatchClm">
										<div class="alignRight mrgT10">
											<div class="cols12">
												<div class="paySubTotal totalWithAct">
													Subtotal :
													<span class="txtRight">
														{{endUserInvoiceDetails.doctotalsys || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div *ngIf="endUserInvoiceDetails.totaldiscount > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Discount :
													<span class="txtRight">
														{{endUserInvoiceDetails.totaldiscount || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div *ngIf="endUserInvoiceDetails.totalmiscamount > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Miscellaneous Charge:
													<span class="txtRight">
														{{endUserInvoiceDetails.totalmiscamount || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div *ngIf="endUserInvoiceDetails.shippingcharges > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Frieght Charge:
													<span class="txtRight">
														{{endUserInvoiceDetails.shippingcharges || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>

											<div class="cols12">
												<div class="payFinalTotal totalWithAct">Total :
													<span class="txtRight">
														{{endUserInvoiceDetails.doctotal || 0 | customCurrencyPipe }}
													</span>
												</div>
											</div>
										</div>
									</diV>

									<div class="cols12 mrgT10 padL30">
										<div class="dx-field feildCol mrgT10 mrgB20 remarksinputDisable">
											<div class="dx-field-label">Remarks</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-text-area [height]="60" [maxLength]="400"
													[(value)]="endUserInvoiceDetails.comments" [disabled]="true">
												</dx-text-area>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>