import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Subscription } from 'rxjs';
import { ApDashboardService } from '@app/services/ap-services/ap-dashboard-service/ap-dashboard.service';

@Component({
	selector: 'app-ap-open-leads-tiles',
	templateUrl: './ap-open-leads-tiles.component.html',
	styleUrls: ['./ap-open-leads-tiles.component.css']
})

export class ApOpenLeadsTilesComponent implements OnInit, OnDestroy {
	private getCounterSbsn: Subscription;
	public openLeads: number;
	public resourcesLoaded: boolean = false;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _RestApiService: RestApiService,
		private _ApDashboardService: ApDashboardService,) {

	}

	ngOnInit() {
		this.getOpenLead();
	}

	ngOnDestroy(): void {
		if (this.getCounterSbsn) {
			this.getCounterSbsn.unsubscribe();
		}
	}

	private getOpenLead() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getDashboardData');
		formData.append('section', 'openLeadsTile');
		if (this.getCounterSbsn) {
			this.getCounterSbsn.unsubscribe();
		}
		this.resourcesLoaded = true;
		this.getCounterSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(
			response => {
				this.resourcesLoaded = false;
				if (response.flag) {
					if (response.data.tiles) {
						this.openLeads = response.data.tiles.open_leads || 0;
					}
				}
			}, error => {
				this.resourcesLoaded = false;
			});
	}

	public tilesClick(clickFor: string) {
		this._ApDashboardService.tilesClick(clickFor);
	}
}
