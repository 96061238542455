<!-- Note : There is two diffrent view one for billback and other for chargeback, enduser chargeback and viewcraditmemo billback type -->
<!-- Note : call from ar-invoice-details.component.ts as 'viewcraditmemo' -->

<!---(1)--- changeback , end-users chargeback and viewcraditmemo document ---(1)--->
<dx-popup [width]="'1150px'" [dragEnabled]="true" [hideOnOutsideClick]="false" [title]="'Chargeback Document'+
(chargeBackDocumentDetails.ixcode ? '- ' + chargeBackDocumentDetails.ixcode : '')" [showCloseButton]="true"
	[visible]="isShowPopup && (billBackType === 'chargeback' || billBackType === 'enduserschargeback' || billBackType === 'viewcraditmemo')"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" (onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="orderDetail orderPdetails">

				<div class="custDetail">
					<h4 class="graySubHead textWrap">Document Reference No. :
						{{chargeBackDocumentDetails.documentReferenceNumber}}</h4>
					<!-- <h4 class="graySubHead textWrap">Document URL : {{chargeBackDocumentDetails.documentURL}}</h4> -->
					<h4 class="graySubHead textWrap">Document :
						<a href="{{downloadDocumentUrl}}" class="downloadIcon">
						</a>
						Download
					</h4>
					<h4 class="graySubHead">Claimed Reason : <span
							[ngClass]="chargeBackDocumentDetails.claimedStatus === 'approved' ? 'green' : 'red'">
							{{chargeBackDocumentDetails.claimedStatusReason}}</span></h4>

				</div>
				<div class="orderStatus">
					<ul class="statusBx">
						<li><span class="stLbl">Claimed Status : </span><span class="stData stBtn "
								[ngClass]="chargeBackDocumentDetails.claimedStatus === 'approved' ? 'greenBg' : 'redBg'">
								{{chargeBackDocumentDetails.claimedStatus}}</span></li>
						<li><span class="stLbl">Document Date :</span><strong
								class="black">{{chargeBackDocumentDetails.documentDate | date: 'yyyy-MM-dd' }}</strong></li>
					</ul>
				</div>
			</div>
			<div class="hrLine"></div>

			<div class="tableOut">

				<table class="tableData invoiceTbl">
					<tbody>
						<tr>
							<!-- <th class="center width80">No.</th> -->
							<th *ngIf="isQBOnlineErpUser" class="left width80">SKU</th>
							<th class="width100">Item</th>
							<th> Description</th>
							<th *ngIf="billBackType === 'enduserschargeback'" class="left width100">End User Name</th>
							<th class="right width80">Chargeback Amount</th>
							<!-- <th>Sold</th> -->
							<th class="center width60">Claimed</th>
							<th class="center width60">UOM</th>
							<th class="right width80">Claimed Amount</th>
							<!-- <th>Available</th> -->
						</tr>
						<tr *ngFor="let ci of chargeBackDocumentDetails.claimedItems;let i = index">
							<!-- <td class="center">{{i+1}}</td> -->
							<td *ngIf="isQBOnlineErpUser" class="left width80">{{ci.itemsku}}</td>
							<td class="width100">{{ci.itemname}}</td>
							<td>{{ci.description}}</td>
							<td class="left width100" *ngIf="billBackType === 'enduserschargeback'">{{ci.endUserName}}</td>
							<td class="right width80">{{ci.discountedPrice || 0 | customCurrencyPipe }}</td>
							<!-- <td>{{ci.sold}}</td> -->
							<td class="center width60">{{ci.claimed || 0}}</td>
							<td class="center width60">
								{{ci.selectedClaimedUom | uppercase}}
							</td>
							<td class="right width80">{{ci.linetotal || 0 | customCurrencyPipe}}</td>
							<!-- <td>{{ci.available}}</td> -->
						</tr>
						<tr class="totalRowLast">
							<td
								[attr.colspan]="isQBErpUser ? (billBackType==='enduserschargeback'? '4' :3) : (billBackType!=='enduserschargeback'? '3' : '4')">
								<div class="remarkBx fullNote" *ngIf="chargeBackDocumentDetails.claimedUserNote">
									<strong>User Note:- {{chargeBackDocumentDetails.claimedUserNote}}</strong>
								</div>
							</td>
							<td [attr.colspan]="3" class="totalCount">
								<div class="total">Total Claimed Quantity: <span>{{totalClaimed}}</span></div>
								<div class="total">Total Claimed Amount:
									<span>{{totalClaimedAmount || 0 | customCurrencyPipe }}</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
		</dx-scroll-view>
	</div>
</dx-popup>
<!-- end -->

<!---(2)--- billback document ---(1)--->

<dx-popup [width]="'1150px'" [dragEnabled]="true" [hideOnOutsideClick]="false" [title]="'Billback Document'"
	[showCloseButton]="true" [visible]="isShowPopup && (billBackType === 'billback')"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" (onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="orderDetail orderPdetails">

				<div class="custDetail">
					<h4 class="graySubHead textWrap">Document Reference No. :
						{{chargeBackDocumentDetails.documentReferenceNumber}}</h4>
					<!-- <h4 class="graySubHead textWrap">Document URL : {{chargeBackDocumentDetails.documentURL}}</h4> -->
					<h4 class="graySubHead textWrap">Document :
						<a href="{{downloadDocumentUrl}}" class="downloadIcon">
						</a>
						Download
					</h4>
					<!-- <h4 class="graySubHead">Claimed Reason : <span
								[ngClass]="chargeBackDocumentDetails.documentStatus === 'approved' ? 'green' : 'red'">
								{{chargeBackDocumentDetails.documentStatusReason}}</span></h4> -->

				</div>
				<div class="orderStatus">
					<ul class="statusBx">
						<!-- <li><span class="stLbl">Claimed Status : </span><span class="stData stBtn "
									[ngClass]="chargeBackDocumentDetails.documentStatus === 'approved' ? 'greenBg' : 'redBg'">
									{{chargeBackDocumentDetails.documentStatus}}</span></li> -->
						<li><span class="stLbl">Document Date :</span><strong
								class="black">{{chargeBackDocumentDetails.documentDate | date: 'yyyy-MM-dd' }}</strong>
						</li>
					</ul>
				</div>
			</div>
			<div class="hrLine"></div>

			<div class="tableOut">
				<table class="tableData invoiceTbl ">
					<tbody>
						<tr>
							<!-- <th class="center width80">No.</th> -->
							<th *ngIf="isQBOnlineErpUser" class="left width80">SKU</th>
							<th class="left width80">Item</th>
							<th> Description</th>
							<th class="left width200"> Customer </th>
							<th class="center width60">UOM</th>
							<th class="right width80">Billback Amount</th>
							<!-- <th>Sold</th> -->
							<th class="center width60">Claimed</th>
							<th class="right width80">Claimed Amount</th>
							<!-- <th>Available</th> -->
						</tr>
						<tr *ngFor="let ci of chargeBackDocumentDetails.billedItems;let i = index">
							<!-- <td class="center">{{i+1}}</td> -->
							<td *ngIf="isQBOnlineErpUser" class="left width80">{{ci.itemsku}}</td>
							<td class="width100">{{ci.itemname}}</td>
							<td> {{ci.description}}</td>
							<td class="left width200"> {{ci.customerName}}</td>
							<td class="center width60">
								{{ci.selectedClaimedUom |filterFromList: uomListData : 'code' : 'name' | uppercase}}
							</td>
							<td class="right width80">{{ci.billbackamount || 0 | customCurrencyPipe}}</td>
							<!-- <td>{{ci.sold}}</td> -->
							<td class="center width60">{{ci.quantity || 0}}</td>
							<td class="right width80">{{ci.linetotal || 0 | customCurrencyPipe}}</td>
							<!-- <td>{{ci.available}}</td> -->
						</tr>
						<tr class="totalRowLast">
							<td [attr.colspan]="4">
								<div class="remarkBx fullNote" *ngIf="chargeBackDocumentDetails.claimedUserNote">
									<strong>User Note:- {{chargeBackDocumentDetails.claimedUserNote}}</strong>
								</div>
							</td>
							<td [attr.colspan]="3" class="totalCount">
								<div class="total">Total Claimed Quantity: <span>{{totalClaimed}}</span></div>
								<div class="total">Total Claimed Amount:
									<span>{{totalClaimedAmount || 0 | customCurrencyPipe}}</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>
<!-- end -->