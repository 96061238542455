import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-verification-email',
	templateUrl: './verification-email.component.html',
	styleUrls: ['./verification-email.component.css']
})
export class VerificationEmailComponent implements OnInit, OnDestroy {
	public queryStringMethod: string = undefined;
	public queryStringToken: string = undefined;
	public isViewHTML: boolean = false;
	public isEmailAlreadyVerified: boolean = false;
	public isErrorFlag: boolean = false;
	public ErrorFlagMsg: string;
	private verificationEmailReqSbsn: Subscription;

	constructor(private _RestApiService: RestApiService,
		private _ToastrService: ToastrService,
		private _ActivatedRoute: ActivatedRoute,
		private _LoaderService: LoaderService,
		private _Router: Router,
		public _AppCommonSrvc: AppCommonSrvc) {
		this._ActivatedRoute.queryParams.subscribe(params => {
			this.queryStringMethod = params.method || undefined;
			this.queryStringToken = params.token_encrypted || undefined;
			this.validateEmailToken();
		});
	}

	ngOnInit() {
		this.isEmailAlreadyVerified = false;
		this.isErrorFlag = false;
	}

	ngOnDestroy() {
		if (this.verificationEmailReqSbsn) {
			this.verificationEmailReqSbsn.unsubscribe();
		}
	}

	async login() {
		this._Router.navigate(['/auth/login']);
	}

	private validateEmailToken() {
		this.isEmailAlreadyVerified = false;
		this.isErrorFlag = false;
		if (this.queryStringToken) {
			const formData = new FormData();
			formData.append('method', this.queryStringMethod);
			formData.append('token_encrypted', this.queryStringToken);

			if (this.verificationEmailReqSbsn) {
				this.verificationEmailReqSbsn.unsubscribe();
			}
			this._LoaderService.show();
			this.verificationEmailReqSbsn = this._RestApiService.doUserSetUpReqFormData(formData).subscribe({
				next: response => {
					this._LoaderService.hide();
					if (response.flag) {
						if (response.errorCode == '500') {
							// If Email already verified
							this.isEmailAlreadyVerified = true;
						}
						else {
							// If newly email verified (with the new request) ErrorCode=200
							this.isEmailAlreadyVerified = false;
						}
						this.isViewHTML = true;
					} else {
						this.isViewHTML = true;
						this.isErrorFlag = true;
						this.ErrorFlagMsg = response.message || 'Error';
					}
				}, error: error => {
					this._LoaderService.hide();
					this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			});
		} else {
			this._Router.navigate(['/auth/login'], { replaceUrl: true });
			this._ToastrService.error('Token was missing!', 'Error', { closeButton: true, tapToDismiss: true });
		}
	}

}
