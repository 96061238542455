<div class="rightBar"
	[ngClass]="{disabledContent: called_from === 'CALLED_AS_TIRAL_EXPIRED' || called_from === 'CALLED_AS_OVERDUE_PAYMENT'}">
	<div class="rightBlock">
		<div class="titleRow">
			<div class="titleBtnCol mrgB10">
				<span class="show-data-from"> Data From : </span>
				<span class="show-data-from">
					{{dateRangeConfigUI.showDataFrom | getDateRangeTextPipe }}
				</span>
				<dx-button id="dateRangeGlobalSetingPopover" (onClick)="doOpenDateRangeSetting()" class="button"
					[ngClass]="{'data-calendar-blue': isDateRangeSettingsExisting,'data-calendar-gray':!isDateRangeSettingsExisting}"
					[useSubmitBehavior]="false" stylingMode="text" icon="add" class="mrgB10">
				</dx-button>

				<dx-button (click)="doRefreshAllOnDashboard()" icon="refresh" text="Refresh" class="mrgL10 mrgB10">
				</dx-button>

				<dx-button id="dashboardConfigSettingPopover" (click)="doOpenDashboardConfigSetting()"
					icon=" icon-dashboard" text="" class="mrgL10 mrgB10 ix-borderbgnone">
				</dx-button>

				<!-- <dx-drop-down-box [dataSource]="listOfDashboardConfigs" [(value)]="selectedDashboardConfigs"
					[displayValueFormatter]="dispNoOfSelDashboardConfig" valueExpr="code" [showClearButton]="false"
					[deferRendering]="true" class="dx-ix-dropdownbox">
					<div *dxTemplate="let data of 'content'">
						<dx-list #listOfDashboardConfigRef [dataSource]="listOfDashboardConfigs" keyExpr="code"
							selectionMode="multiple" [focusStateEnabled]="false" [hoverStateEnabled]="false"
							selectAllMode="allPages" [showSelectionControls]="true"
							[(selectedItemKeys)]="selectedDashboardConfigs"
							(onSelectionChanged)="doSelectionChangedDashboardConfig($event)" itemTemplate="item">
							<dxo-sorting mode="single"></dxo-sorting>
							<div *dxTemplate="let element of 'item'">
								<div>{{ element.title }}</div>
							</div>
						</dx-list>
					</div>
				</dx-drop-down-box> -->

			</div>
		</div>
		<div class="contentRow cf">
			<div class="statisticBlock newStBlock newTileBlock">
				<div class="row">
					<div *ngIf="isShowLeadTile" class="cols3">
						<app-open-lead-tiles></app-open-lead-tiles>
					</div>
					<div *ngIf="isShowProspectTile" class="cols3">
						<app-open-prospect-tiles></app-open-prospect-tiles>
					</div>
					<div *ngIf="isShowCustomerTile" class="cols3">
						<app-active-customer-tiles></app-active-customer-tiles>
					</div>
					<div *ngIf="isShowTaskTile" class="cols3">
						<app-open-task-tiles></app-open-task-tiles>
					</div>
					<div *ngIf="isShowQuoteTile" class="cols3 newCols4">
						<app-sales-quotes-total-tiles></app-sales-quotes-total-tiles>
					</div>
					<div *ngIf="isShowOrderTile" class="cols3">
						<app-sales-order-total-tiles></app-sales-order-total-tiles>
					</div>
					<div *ngIf="isShowInvoiceTile" class="cols3 newCols4">
						<app-sales-invoice-total-tiles></app-sales-invoice-total-tiles>
					</div>
					<div *ngIf="isShowArCreditMemoTile" class="cols3 newCols4">
						<app-sales-ar-creditmemo-total-tiles></app-sales-ar-creditmemo-total-tiles>
					</div>
					<div *ngIf="isShowCommissionTile" class="cols3 newCols4">
						<app-commssion-total-tiles></app-commssion-total-tiles>
					</div>
					<div *ngIf="isShowRebateTile" class="cols3 newCols4">
						<app-rebate-total-tiles></app-rebate-total-tiles>
					</div>
					<div *ngIf="isShowRoyaltyTile" class="cols3 newCols4">
						<app-royalty-total-tiles></app-royalty-total-tiles>
					</div>
				</div>

				<div class="cf"></div>
			</div>
			<div class="row">
				<div class="" [ngClass]="{'cols12':!isShowSalesLeaderBoard,'cols7':isShowSalesLeaderBoard}">
					<div class="reportsSection posRelativeAndWidth" *ngIf="!isShowRebateTiles">
						<app-drill-down-bar-chart></app-drill-down-bar-chart>
					</div>
				</div>
				<div *ngIf="isShowSalesLeaderBoard" class="cols5">
					<div class="borderBox posRelativeAndWidth">
						<app-sales-performance-chart></app-sales-performance-chart>
					</div>
				</div>
			</div>
			<div *ngIf="isShowSalesLeaderBoard" class="row">
				<div class="cols4">
					<div class="borderBox posRelativeAndWidth">
						<app-sales-goal-chart></app-sales-goal-chart>
					</div>
				</div>
				<div class="cols8">
					<div class="borderBox posRelativeAndWidth">
						<app-sales-leader-board-chart></app-sales-leader-board-chart>
					</div>
				</div>
			</div>

			<div *ngIf="isShowRebateTiles">
				<div *ngxPermissionsOnly="['INCENTIVE_REBATES_VIEW']" class="reportsSection posRelativeAndWidth">
					<app-rebate-forecast-bar-chart></app-rebate-forecast-bar-chart>
				</div>
			</div>
			<div *ngIf="isShowRebateTiles">
				<div *ngxPermissionsOnly="['INCENTIVE_REBATES_VIEW']" class="reportsSection posRelativeAndWidth">
					<app-rebate-revenue-bar-chart></app-rebate-revenue-bar-chart>
				</div>

			</div>

			<div class="borderBox agreementBox posRelativeAndWidth" *ngxPermissionsOnly="['CRM_TASK_VIEW']">
				<app-dashboard-task-list></app-dashboard-task-list>
			</div>


		</div>
	</div>
</div>

<dx-popover target="#dateRangeGlobalSetingPopover" position="bottom" [(visible)]="isShowDateRangeGlobalSettingPopover"
	(onHidden)="doCloseDateRangeGlobalSettingPopover($event)" [hideOnOutsideClick]="true" [showCloseButton]="true"
	[showTitle]="true" title="Show Data From" width="300" class="date-range-setting-popup-title">
	<div *dxTemplate="let data = model of 'content'">
		<app-date-range-global-settings *ngIf="isShowDateRangeGlobalSettingComponent" [openFrom]="'dashboard'"
			(refreshTile)="doRefreshTile($event)">
		</app-date-range-global-settings>
	</div>
</dx-popover>

<dx-popover target="#dashboardConfigSettingPopover" position="bottom" [(visible)]="isShowDashboardConfigSettingPopover"
	(onHidden)="doCloseDashboardConfigSettingPopover()" [hideOnOutsideClick]="true" [showCloseButton]="true"
	[showTitle]="true" title="Select Dashboard Tile" width="250" (onShowing)="onShowing($event)">

	<div *dxTemplate="let data = model of 'content'">
		<dx-list #listOfDashboardConfigRef [dataSource]="listOfDashboardConfigs" keyExpr="code" selectionMode="multiple"
			[focusStateEnabled]="false" [hoverStateEnabled]="false" selectAllMode="allPages" [showSelectionControls]="true"
			[(selectedItemKeys)]="selectedDashboardConfigs"
			(onSelectionChanged)="doSelectionChangedDashboardConfig($event)" itemTemplate="item">
			<dxo-sorting mode="single"></dxo-sorting>
			<div *dxTemplate="let element of 'item'">
				<div class="ix-dashboardtile">
					{{ element.title }}
					<span [ngSwitch]="element.code">
						<span *ngSwitchCase="'lead_tile'" class="icon-leads_1 ix-selectdashboardicon">
						</span>
						<span *ngSwitchCase="'prospect_tile'" class="icon-prospects ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'customer_tile'" class="icon-customer ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'task_tile'" class="icon-tasks_1 ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'quote_tile'" class="icon-quotes_1 ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'order_tile'" class="icon-icondx-6 ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'invoice_tile'" class="icon-bill_pay ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'ar_creditmemo_tile'" class="icon-creditmemos ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'commission_tile'" class="icon-commission ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'rebate_tile'" class="icon-rebate ix-selectdashboardicon"></span>
						<span *ngSwitchCase="'royalty_tile'" class="icon-royalty ix-selectdashboardicon"></span>
					</span>
				</div>

			</div>
		</dx-list>
	</div>
</dx-popover>