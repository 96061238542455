import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Subscription, Observable } from 'rxjs';
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from "devextreme/excel_exporter";
import { ChargeDocumentsTabModel } from "@app/models/chargeback-contract.model";
import { CcViewChargebackDocumentsDialogComponent } from "../cc-view-chargeback-documents-dialog/cc-view-chargeback-documents-dialog.component";
import { UploadChargebackEnduserClaimsDialogComponent } from '@app/custom-components/upload-chargeback-enduser-claims-dialog/upload-chargeback-enduser-claims-dialog.component';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-cc-chargeback-documents-tab',
	templateUrl: './cc-chargeback-documents-tab.component.html',
	styleUrls: ['./cc-chargeback-documents-tab.component.css'],
})
export class CcChargebackDocumentsTabComponent implements OnInit, OnDestroy {
	@Input() itemTabDataList: any = [];
	@Input() chargeBackDocumentsTabDatalist: any[] = [];
	@ViewChild("chargeDocumentsTabDataGridContainer", {}) chargeDocumentsTabDataGridContainer: DxDataGridComponent;
	@Output() saveChargeDocumentsTabDataEvent = new EventEmitter<string>();
	private getUomLstSbsn: Subscription;
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public chargeDocumentsTabModel = new ChargeDocumentsTabModel();
	public editChargebackGuid: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public documentFileData: any;
	public claimDocumentUploadFile: any;
	public productSearchItemType: string;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public chargeDocumentsTabDataSource: any;
	public viewDocumentDataGrid: boolean = false;
	public isUploadExcelCsvFileItmGrpTab: boolean = false;
	// Matrial design object
	private dialogConfig: MatDialogConfig = new MatDialogConfig();
	private dialogRef: MatDialogRef<any>;
	public pageOptions: PageOptions = new PageOptions();
	public successedListOfCsvRows: any = [];
	public ignoredListOfCsvRows: any = [];
	public guid = new Guid();
	public noOfUnmatchedClaim: any;
	public noOfMatchedClaim: any;
	public noOfTotaClaim: any;
	public shortPayTaskList: any[] = [];
	private dataOutShortPayReqSubscription: Subscription;
	public decimalPointForCurrency: number;
	public isShowClaimedItemListPopup: boolean = false;
	public claimedItemsListProps: any;
	public isShowChargebackDocumentPopup: boolean = false;
	public viewChargebackDocumentProps: any;
	public isUploadChargebackEnduserClaimsPopup: boolean = false;
	public uploadChargebackEnduserClaimsProps: any;
	constructor(
		public _MatDialog?: MatDialog,
		private _Router?: Router,
		private _ToastrService?: ToastrService,
		private _PermissionsService?: NgxPermissionsService,
		private _LoginService?: LoginService,
		private _LoaderService?: LoaderService,
		private _RestApiService?: RestApiService,
		private _ConfirmationDialogService?: ConfirmationDialogService,
		private _DataService?: DataService,
		private _ChargebackContractService?: ChargebackContractService,
		private _FileUploadService?: FileUploadService,
		private _GlobalStateService?: GlobalStateService,
		public _AppCommonSrvc?: AppCommonSrvc,
		private _CustomCurrencyPipe?: CustomCurrencyPipe,
		public _BillbackService?: BillbackService,
	) { }

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.editChargebackGuid = this._ChargebackContractService.getEditChargebackGuid();
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}
		// product_search logic type (item/price)
		this.productSearchItemType = this._BillbackService.getProductSearchType();
		this.editChargebackGuid = this._ChargebackContractService.getEditChargebackGuid();
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataOutShortPayReqSubscription) {
			this.dataOutShortPayReqSubscription.unsubscribe();
		}
	}

	preInit() {
		if (this.chargeBackDocumentsTabDatalist && this.chargeBackDocumentsTabDatalist.length > 0) {
			this.chargeDocumentsTabDataSource = this.chargeBackDocumentsTabDatalist;
		}
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.getChargeBackDocumentTabData();
		// short pay task list
		if (this._LoginService.loginUser.account_detail['enable_shortpay_notification']) {
			this.getShortPayTaskList();
		}
	}

	getChargeBackDocumentTabData() {
		if (this.editChargebackGuid) {
			this._LoaderService.show();
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('contractguid', this.editChargebackGuid);
			formData.append('method', 'getChargebackDocuments');
			formData.append('contracttype', 'chargebackcontracts');
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
				next: (response) => {
					this.viewDocumentDataGrid = true;
					if (response.flag) {
						this.chargeBackDocumentsTabDatalist = JSON.parse(JSON.stringify(response.data));

						if (this.chargeBackDocumentsTabDatalist.length > 0) {
							this.chargeDocumentsTabDataSource = this.chargeBackDocumentsTabDatalist
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
				}, error: (error) => {
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			});
		}
	}

	addChargebackDocumentsAgreementTabDetails() {
		if (this.checkValidation()) {
			// this.getAgreementTabListData();

			if (this.itemTabDataList.length > 0) {
				this.claimedItemsListProps = {
					productList: JSON.parse(JSON.stringify(this.itemTabDataList)),
					buttonText: {
						cancel: 'Ok'
					}
				}
				this.isShowClaimedItemListPopup = false;
				setTimeout(() => {
					this.isShowClaimedItemListPopup = true;
				}, 20);
			}
		}
	}

	checkValidation() {
		let valDoc: boolean = true;
		if (!this.editChargebackGuid) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (this.itemTabDataList.length < 0) {
			this._ToastrService.info('Please add at least one product.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.documentFileData) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.chargeDocumentsTabModel.documentURL || !this.chargeDocumentsTabModel.documentURL.trim()) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
			// } else if (!this.chargeDocumentsTabModel.documentURL.match(RGX_URL_VALID)) {
			// 	this._ToastrService.info('Document URL is Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			// 	return false;
		} else if (!this.chargeDocumentsTabModel.documentReferenceNumber || !this.chargeDocumentsTabModel.documentReferenceNumber.trim()) {
			this._ToastrService.info('Document Reference No. Required .', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		}
		return valDoc;
	}
	// Clear input field changeback document tab data
	clearChargebackDocumentsAgreementTabDetails() {
		this.chargeDocumentsTabModel = new ChargeDocumentsTabModel();
		this.documentFileData = '';
	}

	// View chargeback document dialog
	viewChargeBackDocumentDetails(chargeBackDocumentDetails) {
		this.viewChargebackDocumentProps = {
			chargeBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
			billBaBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
			buttonText: {
				cancel: 'Ok'
			}
		}
		this.isShowChargebackDocumentPopup = false;
		setTimeout(() => {
			this.isShowChargebackDocumentPopup = true;
		}, 20);
	}

	downloadChargeBackDocument(chargeBackDocumentDetails) {
		const documentURL = chargeBackDocumentDetails.documentURL;
		const documentNewURLArr = documentURL.split('-');
		if (documentNewURLArr && documentNewURLArr.length > 0) {
			const docId = documentNewURLArr[0];
			const docDate = documentNewURLArr[1];
			const docCode = documentNewURLArr[2];
			const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackDoc';
			window.location.href = finalUrl;
		}
	}


	public doOpenFilterBuilder() {
		this.chargeDocumentsTabDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.chargeDocumentsTabDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Chargeback_Documnets.xlsx';
		worksheet = workbook.addWorksheet('Documents');

		exportDataGrid({
			worksheet: worksheet,
			component: this.chargeDocumentsTabDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'documentTotal') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doOnRowUpdatedChargeDocumentsTab(event) {
		if (this.editChargebackGuid) {
			this.saveChargeDocumentsTabDataEvent.emit('save');
		}
	}

	public doOnRowRemovedChargeDocumentsTab(event) {
		if (this.editChargebackGuid) {
			this.saveChargeDocumentsTabDataEvent.emit('save');
		}
	}

	public doOnSavedChargeDocumentsTab() {
		if (this.editChargebackGuid) {
			this.saveChargeDocumentsTabDataEvent.emit('save');
		}
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	// Upload document
	uploadDocument(files: File[]) {
		if (files.length === 1) {
			// file type verify
			const fileType = files[0].type;
			if (fileType !== '.doc' && fileType !== '.docx' && fileType !== 'application/msword' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				&& fileType !== '.csv' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && fileType !== 'application/vnd.ms-excel' && fileType !== 'application/pdf') {
				this._ToastrService.info('File extension not valid', 'Info!', { closeButton: true, tapToDismiss: true });
				this.chargeDocumentsTabModel.documentURL = '';
				return false;
			}
			// file size convert KB
			const fileSize = Math.round(files[0].size / 5120);
			if (fileSize > 5120) {
				this._ToastrService.info('File size should not greater then 5 MB', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
			this.documentFileData = files[0];
			this.chargeDocumentsTabModel.documentURL = files[0].name;
			// this._FileUploadService.upload(files);

		}
	}
	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	saveClaimedItems(claimedItems) {
		this._LoaderService.show();

		const item = {
			guid: this.guid.newGuid(),
			documentURL: this.chargeDocumentsTabModel.documentURL,
			documentReferenceNumber: this.chargeDocumentsTabModel.documentReferenceNumber,
			documentDate: '',
			claimedUserNote: this.chargeDocumentsTabModel.claimedUserNote || '',
			claimedStatus: '',
			claimedItems: claimedItems,
		}
		const arrChargebackDocumentsTabList = [];
		arrChargebackDocumentsTabList.push(item);
		// const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.billBackChargeBackRequest.guid + '&method=claimChargeback' + '&chargebackDocs=' + encodeURIComponent(JSON.stringify(arrChargebackDocumentsTabList))
		// this.dataInReqSubscription = this._RestApiService.doDataOutReq((reqFormData)).subscribe(

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.editChargebackGuid);
		formData.append('documentUploadFile', this.documentFileData);
		formData.append('claimDocumentUploadFile', this.claimDocumentUploadFile);
		formData.append('chargebackDocs', (JSON.stringify(arrChargebackDocumentsTabList)));
		formData.append('contracttype', 'chargebackcontracts');
		formData.append('method', 'claimChargeback');

		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe(
			response => {
				if (response.flag) {
					this.itemTabDataList = response.data.itemSold;
					this.chargeBackDocumentsTabDatalist = response.data.chargebackDocuments;
					this.chargeDocumentsTabDataSource = this.chargeBackDocumentsTabDatalist;
					if (this.editChargebackGuid) {
						this._ChargebackContractService.setItemTabDataList(this.itemTabDataList);
						this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
					}
					this.clearChargebackDocumentsAgreementTabDetails();
					this._ToastrService.success('Document Added Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			});
	}

	doClickImportClaimItemsExcel() {
		if (this.checkValidation()) {
			this.isUploadExcelCsvFileItmGrpTab = false;
			this.successedListOfCsvRows = [];
			this.ignoredListOfCsvRows = [];
			this.uploadChargebackEnduserClaimsProps = {
				billbackType: 'chargeback',
				actionFor: 'showUploadFile',
				itemTabDataList: this.itemTabDataList
			};
			this.isUploadChargebackEnduserClaimsPopup = false;
			setTimeout(() => {
				this.isUploadChargebackEnduserClaimsPopup = true;
			}, 20);
		}
	}

	doViewImportClaimItemsExcel() {
		this.uploadChargebackEnduserClaimsProps = {
			billbackType: 'chargeback',
			actionFor: 'showIgnoreItem',
			successedListOfCsvRows: this.successedListOfCsvRows,
			ignoredListOfCsvRows: this.ignoredListOfCsvRows,
		};
		this.isUploadChargebackEnduserClaimsPopup = false;
		setTimeout(() => {
			this.isUploadChargebackEnduserClaimsPopup = true;
		}, 20);
	}
	//Short Pay task
	getShortPayTaskList() {
		if (this.dataOutShortPayReqSubscription) {
			this.dataOutShortPayReqSubscription.unsubscribe();
		}
		this.dataOutShortPayReqSubscription = this._ChargebackContractService.getShortPayTaskList().subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						if (response.data && response.data.length > 0) {
							this.shortPayTaskList = response.data;
						}
					}
					else {
						this._ToastrService.info(response.message, 'Info', { closeButton: true, tapToDismiss: true });
					}
				}
			},
			error: (error) => {
				this._ToastrService.error(error, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	//end

	public closeUploadChargebackEnduserClaimsPopup(result) {
		this.isUploadChargebackEnduserClaimsPopup = false;
		if (result) {
			const itemTabDataList = result.itemTabDataList || [];
			const listOfCsvRows = result.listOfCsvRows || [];
			this.claimDocumentUploadFile = result.claimDocumentUploadFile || [];
			let claimItemList: any = [];
			if (this.productSearchItemType === 'item') {
				if (listOfCsvRows.length > 0 && itemTabDataList.length > 0) {
					itemTabDataList.forEach(elementTab => {
						if (elementTab['items'] && elementTab['items'].length) {
							elementTab['items'].forEach(element => {
								const filterItemIndex = listOfCsvRows.findIndex(i => i.item.toString() === element.itemname.toString());
								element['claimed'] = 0;
								const itemObj = {
									itemsku: element['itemsku'] || '',
									itemname: element['itemname'] || '',
									description: element['description'] || '',
									erp_primary_key: element['erp_primary_key'] || '',
									uomList: element['uomList'] || [],
									selectedClaimedUom: element['selectedUom'] || '',
									last_invoice_date: element['last_invoice_date'] || '',
									// discountedPrice: item['chargebackAmount'] || '',
									chargebackAmount: element['chargebackAmount'] || '',
									available: 0,
									billed: 0,
									bought: 0,
									claimed: filterItemIndex !== -1 ? listOfCsvRows[filterItemIndex]['quantity'] : 0,
									sold: 0
								}

								if (filterItemIndex !== -1) {
									this.successedListOfCsvRows.push(itemObj);
									claimItemList.push(itemObj);
								} else {
									this.ignoredListOfCsvRows.push(itemObj);
								}
							});
						}
					});
					this.isUploadExcelCsvFileItmGrpTab = true;
					this.noOfUnmatchedClaim = this.ignoredListOfCsvRows.length || 0;
					this.noOfMatchedClaim = this.successedListOfCsvRows.length || 0;
					this.noOfTotaClaim = listOfCsvRows.length || 0;

					//if (this.successedListOfCsvRows.length > 0) {
					this.saveClaimedItems(claimItemList);
					//}
				}
			}

			if (this.productSearchItemType === 'price') {
				if (listOfCsvRows.length > 0 && itemTabDataList.length > 0) {
					itemTabDataList.forEach(elementEU => {
						const itemDetails = elementEU['items'] || [];
						if (itemDetails && itemDetails.length > 0) {
							itemDetails.forEach(element => {
								// const filterItemIndex = listOfCsvRows.findIndex(i => i.item.toString() === element.itemname.toString() && i.pricelist.toString() === elementEU['pricelistname'].toString());
								const filterItemIndex = listOfCsvRows.findIndex(i => i.item.toString() === element.itemname.toString());
								element['claimed'] = 0;
								const itemObj = {
									pricelistno: elementEU['pricelistno'] || '',
									pricelistname: elementEU['pricelistname'] || '',
									itemsku: element['itemsku'] || '',
									itemname: element['itemname'] || '',
									description: element['description'] || '',
									erp_primary_key: element['erp_primary_key'] || '',
									uomList: element['uomList'] || [],
									selectedClaimedUom: filterItemIndex !== -1 ? listOfCsvRows[filterItemIndex]['uom'] : element['selectedClaimedUom'],
									last_invoice_date: element['last_invoice_date'] || '',
									// discountedPrice: item['chargebackAmount'] || '',
									chargebackAmount: element['chargebackAmount'] || '',
									available: 0,
									billed: 0,
									bought: 0,
									claimed: filterItemIndex !== -1 ? listOfCsvRows[filterItemIndex]['quantity'] : 0,
									sold: 0
								}
								if (filterItemIndex !== -1) {
									this.successedListOfCsvRows.push(itemObj);
									claimItemList.push(itemObj);
								} else {
									this.ignoredListOfCsvRows.push(itemObj);
								}
							});
						}
					});
					this.isUploadExcelCsvFileItmGrpTab = true;
					this.noOfUnmatchedClaim = this.ignoredListOfCsvRows.length || 0;
					this.noOfMatchedClaim = this.successedListOfCsvRows.length || 0;
					this.noOfTotaClaim = listOfCsvRows.length || 0;

					//if (this.successedListOfCsvRows.length > 0) {
					this.saveClaimedItems(claimItemList);
					//}
				}
			}
		}
	}
	public doCloseViewChargebackDocumentPopup(result) {
		this.isShowChargebackDocumentPopup = false;
	}

	public doCloseClaimedItemListPopup(result) {
		this.isShowClaimedItemListPopup = false;
		if (result) {
			this.saveClaimedItems(result);
		}
	}
}
