import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { RebatesContractModelRequest, RebatesContractModelUI } from '@app/models/rebates-contract.model';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { LoginService } from '@app/services/login.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { Observable, Subscription } from 'rxjs';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ApCreditMemoEnum, ArCreditMemoEnum, ErpTypeValEnum } from '@app/constants-enums/enums';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { PurchasesService } from '@app/services/purchases-service/purchases.service';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
@Component({
	selector: 'app-rebate-tierbase-credit-memo-list-tab',
	templateUrl: './rebates-tierbase-credit-memo-list-tab.component.html',
	styleUrls: ['./rebates-tierbase-credit-memo-list-tab.component.css']
})
export class RebatesTierbaseCreditMemoListTabComponent implements OnInit, OnChanges {

	@Input() rebatesDetails = new RebatesContractModelUI();
	@Input() salesPplList: any = [];
	@Input() isAllCustomerSelectedView: boolean = false;
	@Input() isAllProductSelectedView: boolean = false;
	@Input() customerTabList: any = [];
	@Input() memberTabList: any = [];
	@Input() productOrGroupTabList: any = [];
	@Input() listOfItemsAndGroupsItmGrpTab: any = [];
	@Input() exclusionTabList: any = [];
	@Input() ruleTabList: any[] = [];
	@Input() rebatesRequest = new RebatesContractModelRequest();
	@Input() isCurrentTabPermission: string;
	@ViewChild('invoiceDataGridContainer') invoiceDataGridContainer: DxDataGridComponent;
	public pageOptions: PageOptions = new PageOptions();
	public globalCurrencySymbol: string;
	public closeSettlementDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	private dataOutReqSubscription: Subscription;
	public creditMemoListDataSource: any;
	public erp_type: string;
	public isQBErpUser: boolean;
	public isQBOnlineErpUser: boolean;
	public listOfRebateBasis: any[] = [];
	public listOfRebateBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public decimalPointPercentageFormat: string = '1.0-0';
	private guid = new Guid();
	private updateUserSettingSbsn: Subscription;
	public decimalPointForCurrency: number;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();

	constructor(public _Router: Router,
		public _ActivatedRoute: ActivatedRoute,
		private _LoginService: LoginService,
		private _RebateService: RebateService,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
		private _PurchasesService: PurchasesService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _SalesDataSharingService: SalesDataSharingService) {
		this.getCurrencyDisplayValue = this.getCurrencyDisplayValue.bind(this);
		this.doCustomizeRebateTotalSummary = this.doCustomizeRebateTotalSummary.bind(this);
		this.doCustomizeLineTotalSummary = this.doCustomizeLineTotalSummary.bind(this);
	}


	ngOnInit() {
		// this.doGetCreditMemoList();
		this.preInIt();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.rebatesDetails) {
			this.rebatesDetails = changes['rebatesDetails'].currentValue;
			this.doGetCreditMemoList();
		}
	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	// Method used to Init Tab and load data.
	private preInIt() {
		// get global currency
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.setErpType();
		this.listOfTierBasis = this._RebateService.getTierBasis();
		this.listOfRebateBasis = this._RebateService.getRebateBasis();
		this.listOfTierBasisHeaderFilter = this.getHeaderFilter(this.listOfTierBasis, 'code', 'label');
		this.listOfRebateBasisHeaderFilter = this.getHeaderFilter(this.listOfRebateBasis, 'code', 'label');
		setTimeout(() => {
			this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.rebatesDetails);
			this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
		}, 100);
		setTimeout(() => {
			this.filterValueChangeSbsn = this.invoiceDataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
	}

	// Method used to set Erp Type
	private setErpType() {
		// get current user Erp type
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		// check Is QB User (no need to view code of QB user)
		this.isQBErpUser = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		this.isQBOnlineErpUser = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
	}

	private getHeaderFilter(listOfArray: any[], codeField: string, textField: string): any[] {
		const listOfHeaderFilters = [];
		listOfArray.forEach(ele => {
			listOfHeaderFilters.push({ value: ele[codeField], text: ele[textField].toString().toUpperCase() });
		});
		return listOfHeaderFilters;
	}

	private doGetCreditMemoList() {
		const editRebatesGuid = this._RebateService.getEditRebatesGuid();
		const viewFields = ['creditMemoGuid', 'invoiceCode', 'invoiceDate', 'invoiceCloseDate', 'finalAmount', 'commissionAmount', 'creditMemoCreatedInERP', 'errorMessage', 'creditMemoNumber'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: editRebatesGuid,
			section: ServerSections.INVOICES,
			contracttype: 'rebatescontracts',
			viewFields: JSON.stringify(viewFields),
		}
		/*
		if (this.invoiceDetails.rebate_for === 'buyinggroup') {
			const seletedBuyinggroupOrMember = this._RebateService.getBuyingGroupDetails();
			if (seletedBuyinggroupOrMember && seletedBuyinggroupOrMember.cardcode) {
				this.selectedBuyingGroupMember = seletedBuyinggroupOrMember.name;
				if (seletedBuyinggroupOrMember.type === 'buyinggroup') {
					loadParams.section = 'buyinggroupitems';
				}
				if (seletedBuyinggroupOrMember.type === 'buyinggroupmember') {
					loadParams.section = 'buyinggroupmemberitems';
					this.filterValue = [
						['clientCode', '=', seletedBuyinggroupOrMember.cardcode]
					]
				}
			}
		}
		*/
		this.creditMemoListDataSource = AspNetData.createStore({
			key: 'creditMemoGuid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}


	public viewRebatesCalculation(invoiceDetails) {
		if (invoiceDetails.rebate_for === 'buyinggroup') {
			this._RebateService.setInvoiceDetails(invoiceDetails);
			// this._Router.navigate(['/contracts/rebates/reportview-buyinggroup']);
			this._Router.navigate(['../rebates/reportview-buyinggroup'], { relativeTo: this._ActivatedRoute });
		} else {
			this._RebateService.setInvoiceDetails(invoiceDetails);
			// this._Router.navigate(['/contracts/rebates/report']);
			this._Router.navigate(['../rebates/report'], { relativeTo: this._ActivatedRoute });
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		let addRowButton = toolbarItems.find(x => x.name === "columnChooserButton");
		addRowButton.options.text = "Column Chooser";
		addRowButton.showText = "always";
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.invoiceDataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'refresh',
					text: "Refresh",
					onClick: (event: any) => {
						this.doRefreshDataGrid();
					}
				}
			}
		);
	}


	// column-chooser Method
	public doLoadRebateCreditMemoState = () => {
		return this._RebateService.loadStateData();
	}

	public doSaveRebateCredutMemoState = (e) => {
		this.saveStateData(e);
	}

	//Save State Data
	public saveStateData(e) {
		let rebatesCraditMemoListConfigClone: any = {};
		try {
			rebatesCraditMemoListConfigClone = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.REBATE_CREDIT_MEMO_lIST_SETTING)));
		} catch (e) {
			rebatesCraditMemoListConfigClone = {};
		}
		if (!rebatesCraditMemoListConfigClone.configs) {
			rebatesCraditMemoListConfigClone.configs = [];
		}

		if (rebatesCraditMemoListConfigClone.configs) {
			rebatesCraditMemoListConfigClone.configs = e;
		} else {
			rebatesCraditMemoListConfigClone.configs.push(e);
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.REBATE_CREDIT_MEMO_lIST_SETTING);
		formData.append('user_settings', JSON.stringify(rebatesCraditMemoListConfigClone));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[ServerSections.REBATE_CREDIT_MEMO_lIST_SETTING] = rebatesCraditMemoListConfigClone;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.REBATE_CREDIT_MEMO_lIST_SETTING, rebatesCraditMemoListConfigClone);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.REBATE_CREDIT_MEMO_lIST_SETTING, rebatesCraditMemoListConfigClone);
					}
				}
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.invoiceDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		const cName = this.rebatesDetails.contractName || '';
		let fileName = '';
		if (cName) {
			fileName = cName + '-';
		}
		fileName = fileName + '_Settlement_Credit_Memo_Report.xlsx';
		worksheet = workbook.addWorksheet('Credit Memo List');
		const listOfMasterDetailsRows: any[] = [];

		exportDataGrid({
			worksheet: worksheet,
			component: this.invoiceDataGridContainer.instance,
			keepColumnWidths: true,
			autoFilterEnabled: false,
			topLeftCell: { row: 5, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'invoiceCode') {
						listOfMasterDetailsRows.push({
							rowIndex: excelCell.fullAddress.row + 1,
							data: gridCell.data
						});
					}
					if (gridCell.column.dataField === 'tier_basis') {
						excelCell.value = '';
						const tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === gridCell.data.tier_basis);
						if (tierIndex !== -1) {
							excelCell.value = this.listOfTierBasis[tierIndex].label;
						}
					}

					if (gridCell.column.dataField === 'commissionRate') {
						if (gridCell.data.commissionRate) {
							if (gridCell.data.commissionRateIn === '$') {
								excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionRate, this.customCurrencyOption);
							} else if (gridCell.data.commissionRateIn === '%') {
								excelCell.value = this._CustomNumberNoRoundingPipe.transform(gridCell.data.commissionRate) + gridCell.data.commissionRateIn;
							} else {
								excelCell.value = gridCell.data.commissionRate;
							}
						}
					}
					if (gridCell.column.dataField === 'finalAmount') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.finalAmount, this.customCurrencyOption);
						}
					}

					if (gridCell.column.dataField === 'commissionAmount') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionAmount, this.customCurrencyOption);
						}
					}

					if (gridCell.column.dataField === 'creditMemoNumber') {
						excelCell.value = '';
						if (gridCell.data.creditMemoNumber === '') {
							excelCell.value = 'Waiting for Sync';
						}
						if (gridCell.data.creditMemoNumber === true) {
							excelCell.value = gridCell.data.creditMemoNumber;
						}
						if (gridCell.data.creditMemoNumber === false) {
							excelCell.value = 'Synced Error';
						}
					}

					if (gridCell.column.dataField === 'errorMessage') {
						excelCell.value = '';
						if (gridCell.data.creditMemoNumber === false) {
							excelCell.value = gridCell.data.errorMessage;
						}
					}
				}
			}
		}).then((cellRange) => {
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 2, 2, 9);
			let secondCellValue = this.rebatesDetails.contractName;
			if (this.rebatesDetails.individualOrGroup === 'individual') {
				secondCellValue = this.rebatesDetails.contractName;
				if (this.rebatesDetails.rebateType === 'sales') {
					headerRow.getCell(1).value = 'Customer Name';
					headerRow.keepColumnWidths = true;
				}
				if (this.rebatesDetails.rebateType === 'purchasing') {
					headerRow.getCell(1).value = 'Vendor Name';
				}
			}
			// if (this.rebatesDetails.rebate_for === 'buyinggroup') {
			// 	headerRow.getCell(1).value = 'Buyinggroup Name';
			// 	headerRow.keepColumnWidths = true;
			// 	secondCellValue = secondCellValue + ' (' + this.selectedBuyingGroupMember + ')';
			// }
			headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14 };
			headerRow.getCell(1).alignment = { horizontal: 'left' };
			headerRow.getCell(2).value = secondCellValue;
			headerRow.getCell(2).font = { name: 'Segoe UI Light', size: 14 };
			headerRow.getCell(2).alignment = { horizontal: 'left' };

			worksheet.mergeCells(4, 2, 4, 9);
			const headerRowForSettlementPeriod = worksheet.getRow(4);
			headerRowForSettlementPeriod.getCell(1).value = 'Settlement Period ';
			headerRowForSettlementPeriod.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
			headerRowForSettlementPeriod.getCell(1).alignment = { horizontal: 'left' };
			headerRowForSettlementPeriod.getCell(2).value = this.rebatesDetails.settlementPeriodStart + ' To ' + this.rebatesDetails.settlementPeriodEnd;
			headerRowForSettlementPeriod.getCell(2).font = { name: 'Segoe UI Light', size: 12 };
			headerRowForSettlementPeriod.getCell(2).alignment = { horizontal: 'left' };

			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = worksheet.insertRow(currentIndex, [], "n");
				for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
					worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			let columnsField = [
				"itemname",
				"description",
				"quantity",
				"price",
				"finalAmount",
				"tier_basis",
				"rebate_basis",
				"commissionRate",
				"commissionAmount",
			];
			let columns = [
				"Item",
				"Description",
				"Qty",
				"Price ($)",
				"Line Total ($)",
				"Rebate Type",
				"Rebate Basis",
				"Rebate Rate (% | $)",
				"Rebate Amount (USD)"
			];

			for (let i = 0; i < listOfMasterDetailsRows.length; i++) {
				columnsField = [
					"itemname",
					"description",
					"quantity",
					"price",
					"finalAmount",
					"tier_basis",
					"commissionRate",
					"commissionAmount",
				];
				columns = [
					"Item",
					"Description",
					"Qty",
					"Price ($)",
					"Line Total ($)",
					"Rebate Type",
					"Rebate Rate (% | $)",
					"Rebate Amount (USD)"
				];
				if (this.isQBOnlineErpUser) {
					columnsField = [
						"itemsku",
						"itemname",
						"description",
						"quantity",
						"price",
						"finalAmount",
						"tier_basis",
						"commissionRate",
						"commissionAmount",
					];
					columns = [
						"SKU",
						"Item",
						"Description",
						"Qty",
						"Price ($)",
						"Line Total ($)",
						"Rebate Type",
						"Rebate Rate (% | $)",
						"Rebate Amount (USD)"
					];
				}

				let row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				const columnIndex = cellRange.from.column;
				const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
				// row.height = 24;
				// worksheet.mergeCells(row.number, columnIndex, row.number, 7);
				if (listOfMasterDetailsRows[i].data.items && listOfMasterDetailsRows[i].data.items.length > 0) {
					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
					columns.forEach((columnName, currentColumnIndex) => {
						row.keepColumnWidths = true;
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							fill: {
								type: "pattern",
								pattern: "solid",
								fgColor: { argb: "BEDFE6" }
							},
							font: { bold: true },
							border: {
								bottom: borderStyle,
								left: borderStyle,
								right: borderStyle,
								top: borderStyle
							}
						});
					});

					let totalFinalAmount = 0;
					let totalCommissionAmount = 0;
					listOfMasterDetailsRows[i].data.items.forEach((invoiceDetails, invoiceIndex) => {
						row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						columnsField.forEach((columnName, currentColumnIndex) => {
							const cellObject = {
								value: invoiceDetails[columnName],
								fill: {
									type: "pattern",
									pattern: "solid",
									fgColor: { argb: "BEDFE6" }
								},
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								},
								alignment: { horizontal: 'left' }
							};

							if (columnName === 'quantity') {
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'price') {
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'commissionRate') {
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'commissionAmount') {
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'finalAmount') {
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'tier_basis') {
								const tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === invoiceDetails[columnName]);
								if (tierIndex !== -1) {
									cellObject.value = this.listOfTierBasis[tierIndex].label;
								}
								cellObject.alignment.horizontal = 'left';
							}

							if (columnName === 'price') {
								cellObject.value = this._CustomCurrencyPipe.transform(invoiceDetails[columnName] || 0, this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'commissionRate') {
								cellObject.value = '';
								if (invoiceDetails['commissionRateIn'] === '$') {
									cellObject.value = this._CustomCurrencyPipe.transform(invoiceDetails[columnName] || 0, this.customCurrencyOption);
								}
								if (invoiceDetails['commissionRateIn'] === '%') {
									cellObject.value = this._CustomNumberNoRoundingPipe.transform(invoiceDetails[columnName]) + '%';
								}
							}

							if (columnName === 'commissionAmount') {
								const commissionAmount = invoiceDetails[columnName] || 0;
								totalCommissionAmount = totalCommissionAmount + commissionAmount;
								cellObject.value = this._CustomCurrencyPipe.transform(commissionAmount, this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'finalAmount') {
								const finalAmount = invoiceDetails[columnName] || 0;
								totalFinalAmount = totalFinalAmount + finalAmount;
								cellObject.value = this._CustomCurrencyPipe.transform(finalAmount, this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
							row.keepColumnWidths = true;
						});
					});


					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;
					let totalCommissionAmountRowCell: number;
					row.keepColumnWidths = true;

					if (this.isQBOnlineErpUser) {
						worksheet.mergeCells(row.number, columnIndex, row.number, 5);
						rowLastCell = 6;
						totalCommissionAmountRowCell = 9;
					} else {
						worksheet.mergeCells(row.number, columnIndex, row.number, 4);
						rowLastCell = 5;
						totalCommissionAmountRowCell = 8;
					}
					Object.assign(row.getCell(columnIndex), {
						value: 'Line Total',
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'right' }
					});

					Object.assign(row.getCell(3), {
						value: 'Line Total',
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});
					Object.assign(row.getCell(rowLastCell), {
						value: this._CustomCurrencyPipe.transform(totalFinalAmount, this.customCurrencyOption),
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});

					Object.assign(row.getCell(7), {
						value: 'Rebate Amount Total',
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});
					Object.assign(row.getCell(totalCommissionAmountRowCell), {
						value: this._CustomCurrencyPipe.transform(totalCommissionAmount, this.customCurrencyOption),
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				}
				offset--;
			}

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
			e.cancel = true;
		});
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.invoiceDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.invoiceDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	public doUpdateSettlementPeriodClick() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		if (this.rebatesRequest.guid) {
			formData.append('contractguid', this.rebatesRequest.guid);
		}

		formData.append('contracttype', 'rebatescontracts');
		formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response.flag) {
				this.rebatesDetails.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
				this.rebatesDetails.current_settlement_commission_total = response.data.current_settlement_rebate_total;
				this.doGetCreditMemoList();
			} else {
				this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			console.error('error', error);
			this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
		});
	}

	public doCellClickCreditMemo(e: any) {
		if (e.column.dataField === "creditMemoNumber") {
			this.doOpenCreditMemoDetails(e.key);
		}
	}

	private doOpenCreditMemoDetails(creditMemoGuid: string) {
		if (this.rebatesDetails.rebateType == 'sales') {
			this._SalesDataSharingService.setArCreditMemoDetailsOpenFrom(ArCreditMemoEnum.FROM_REBATE_AR_CREDIT_MEMOS_LIST);
			this._SalesDataSharingService.setARInvoiceId(creditMemoGuid);
			// this._Router.navigate(['/contracts/rebates/ar-credit-memo-details'], { relativeTo: this._ActivatedRoute });
			this._Router.navigate(['../rebates/ar-credit-memo-details'], { relativeTo: this._ActivatedRoute });
		}

		if (this.rebatesDetails.rebateType == 'purchasing') {
			this._PurchasesService.setApCreditMemoGuid(creditMemoGuid);
			this._PurchasesService.setApCreditMemoDetailsOpenFrom(ApCreditMemoEnum.FROM_REBATE_CREDIT_MEMOS_LIST);
			// this._Router.navigate(['/contracts/rebates/ap-credit-memo-details'], { relativeTo: this._ActivatedRoute });
			this._Router.navigate(['../rebates/ap-credit-memo-details'], { relativeTo: this._ActivatedRoute });
		}
	}

	// Method used to Refresh DataGrid
	private doRefreshDataGrid() {
		this.invoiceDataGridContainer.instance.refresh();
	}

	public getCurrencyDisplayValue(rowData: any) {
		if (rowData && rowData.hasOwnProperty('finalAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.finalAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('commissionAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.commissionAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('price')) {
			return this._CustomCurrencyPipe.transform(rowData.price || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('price')) {
			return this._CustomCurrencyPipe.transform(rowData.price || 0, this.customCurrencyOption);
		}
	}

	public doCustomizeRebateTotalSummary(e) {
		if (e.value < 0) {
			return ' Rebate Amount Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return ' Rebate Amount Total: ' + this._CustomCurrencyPipe.transform(e.value, this.customCurrencyOption);
		}
	}

	public doCustomizeLineTotalSummary(e) {
		if (e.value < 0) {
			return ' Line Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return ' Line Total: ' + this._CustomCurrencyPipe.transform(e.value, this.customCurrencyOption);
		}
	}
}
