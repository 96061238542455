<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="popupTitleText" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">

			<div class="forCsv">
				<div class="row">
					<div class="cols12">
						<span class="highlight">
							<span class="mrgR10">Note: </span>
							Column titles should be
							<i>ContractName, CustomerCode, ContractEmail, SettlementPeriod, ContractCalendar,
								StartSettlementPeriodOn,
								StartDate, EndDate, ItemCode,
								RebateType, RebateBasis, TierMin, TierMax,</i>
							and <i>Rate</i>.
						</span>
					</div>
				</div>
				<div class="row">
					<div class="cols12 colDrag">
						<div class="center">
							<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="doOnFileDrop($event)"
								[showBrowseBtn]="false" accept="csv" [multiple]="false" class="fileUploadInput">
								<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
									<div class="csvUpload">
										<span class="icon-upload"></span>
										<h4>Drag & Drop CSV File Here</h4>
										<span class="orStyle">OR</span>
										<button type="button" (click)="openFileSelector()" class="addFileInput"><span
												class="addIcon"></span>
											Browse Files</button>
										<!-- <span class="supprtInst">Supported up to 25 MB</span> -->
									</div>
								</ng-template>
							</ngx-file-drop>
							<!-- <input type="file" (change)="changeListener($event)" #input
											accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
						</div>

						<div *ngIf="uploadedCsvFilesData">
							<div class="fileImage">
								<span class="icon-csv_file"></span>
							</div>
							<div class="fileDetail">
								<h4>{{uploadedCsvFilesData?.name }}</h4>
								<div class="fileProgress">
									<span></span>
								</div>
								<div class="fileProgText">
									{{ uploadedCsvFilesData?.size }} bytes
									<!-- <span>100% uploaded</span> -->
								</div>
								<span class="sprite closeIcon" (click)="doRemoveUploadFiles()"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="before" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="doDownloadCsvFile()" icon="upload" text="Download Blank CSV Template"
				class="button secondaryBtn" icon=" downloadIcon"></dx-button>
		</div>
	</dxi-toolbar-item>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="doUploadCsvFile()" text="Upload" class="button primaryBtn"></dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>