import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Company360DashboardArCreditmemoValEnum, DateRangeGlobalSettingValEnum, ErpTypeValEnum } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DashboardService } from '@app/services/dashboard-service/dashboard.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Guid } from '@app/models/guid';
import { DATETIME_FORMAT_BACKEND, ServerMethods, ServerSections } from '@app/constants-enums/constants';

@Component({
	selector: 'app-tile-ar-creditmemo-settings',
	templateUrl: './tile-ar-creditmemo-settings.component.html',
	styleUrls: ['./tile-ar-creditmemo-settings.component.css']
})
export class TileArCreditmemoSettingsComponent implements OnInit, OnChanges, OnDestroy {
	@Input() openFrom: string;
	@Output() refreshTile: EventEmitter<any> = new EventEmitter<any>();
	public userConfigsUI: any = {
		showDataFrom: '',
		startDate: null,
		endDate: null,
		which_status_to_consider: '',
		which_date_to_use: '',
		which_type: '',
	};
	private guid = new Guid();
	public listOfShowDataFrom: any[] = [];
	public listOfStatusConsider: any[] = [];
	private listOfAllStatusConsider: any[] = [];
	public listOfDateUses: any[] = [];
	public listOfTypeOptions: any[] = [];
	private updateUserSettingSbsn: Subscription;
	private userSettingConfigCode = 'ar_creditmemo_tile';
	private actionFrom = 'ar_creditmemo_tile';
	public isShowLoadPanel: boolean = false;
	public isShowDataFromField: boolean = false;
	public DateRangeGlobalSettingValEnum = DateRangeGlobalSettingValEnum;
	public isShowConfimBtn: boolean = true;
	public minDate: any;
	private erpType: string;
	public isShowTypeFields: boolean = false;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _SalesDataSharingService: SalesDataSharingService,
		private _GlobalStateService: GlobalStateService,
		private _DashboardService: DashboardService,
		private _RestApiService: RestApiService,
		private _ToastrService: ToastrService) { }

	ngOnInit() {
		this.preInit();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.openFrom) {
			this.actionFrom = changes.openFrom.currentValue;
			this.doShowGlobalSettings();
		}
	}

	ngOnDestroy() {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	public doChangeStatusConsider(e) {
		if (e.event && e.previousValue && e.value) {
			// this.doUpdateUserSettings('which_status_to_consider', e.value, e.previousValue);
		}
	}

	public doChangeDateToUse(e) {
		if (e.event && e.previousValue && e.value) {
			// this.doUpdateUserSettings('which_date_to_use', e.value, e.previousValue);
		}
	}

	public doChangeTypeOption(e) {
		if (e.event && e.previousValue && e.value) {
			// this.doUpdateUserSettings('which_date_to_use', e.value, e.previousValue);
		}
	}

	// Method used to updateUserSetting
	private doUpdateUserSettings(propertyName: string, propertyValue: string, propertyPreviousValue: string) {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.isShowLoadPanel = true;
		this.updateUserSettingSbsn = this._AppCommonSrvc.fetchUserSettings().subscribe(response => {
			this.isShowLoadPanel = false;
			if (response) {
				this._AppCommonSrvc.setUserSettings(response.data || []);
				const orderConfig = {
					guid: '',
					code: '',
				};

				let dashboardSettingsConfig: any = {};
				try {
					dashboardSettingsConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS)));
				} catch (e) {
					dashboardSettingsConfig = {};
				}

				if (!dashboardSettingsConfig.hasOwnProperty('configs')) {
					dashboardSettingsConfig['configs'] = [];
				}
				let configsIndex = dashboardSettingsConfig.configs.findIndex(cg => cg.code === this.userSettingConfigCode);
				if (configsIndex === -1) {
					orderConfig.guid = this.guid.newGuid();
					orderConfig.code = this.userSettingConfigCode;
					dashboardSettingsConfig.configs.push(orderConfig);
				}

				configsIndex = dashboardSettingsConfig.configs.findIndex(cg => cg.code === this.userSettingConfigCode);
				dashboardSettingsConfig.configs[configsIndex][propertyName] = propertyValue;

				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
				formData.append('section', ServerSections.DASHBOARD_SETTINGS);
				formData.append('user_settings', JSON.stringify(dashboardSettingsConfig));
				if (this.updateUserSettingSbsn) {
					this.updateUserSettingSbsn.unsubscribe();
				}
				this.isShowLoadPanel = true;
				this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe(response => {
					this.isShowLoadPanel = false;
					if (response) {
						if (response.flag) {
							const userSettings = this._AppCommonSrvc.getUserSettings();
							if (userSettings && userSettings.length <= 0) {
								const newSection = {};
								newSection[ServerSections.DASHBOARD_SETTINGS] = dashboardSettingsConfig;
								this._AppCommonSrvc.setUserSettings([newSection]);
								this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS, dashboardSettingsConfig);
							} else {
								this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS, dashboardSettingsConfig);
							}
							this.userConfigsUI[propertyName] = propertyValue;
							if (this.actionFrom === 'ar_creditmemo_tile') {
								this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_AR_CREDITMEMO_TILES_EVENT', new Date().getTime());
							}
							if (this.actionFrom === 'sales_ar_creditmemo_listing') {
								const filterValue = [];
								const getFiltersObj = this._DashboardService.getArCreditmemoFiltersForTiles('ar_creditmemo_tile');
								if (getFiltersObj.whichDateToUse === Company360DashboardArCreditmemoValEnum.DATE_DOC_DATE) {
									const documentStatusObj = { dataType: 'date', dataField: 'docdate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichDateToUse === Company360DashboardArCreditmemoValEnum.DATE_DUE_DATE) {
									const documentStatusObj = { dataType: 'date', dataField: 'docduedate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_OPEN) {
									const documentStatusObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Open'] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_CLOSED) {
									const documentStatusObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Close'] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichType === Company360DashboardArCreditmemoValEnum.TYPE_INVENTORY) {
									const documentTypeObj = { dataType: 'string', dataField: 'doctype', selectedFilterOperation: 'equals', filterValues: ['dDocument_Items'] };
									filterValue.push(documentTypeObj);
								}
								if (getFiltersObj.whichType === Company360DashboardArCreditmemoValEnum.TYPE_SERVICE) {
									const documentTypeObj = { dataType: 'string', dataField: 'doctype', selectedFilterOperation: 'equals', filterValues: ['dDocument_Service'] };
									filterValue.push(documentTypeObj);
								}
								this._SalesDataSharingService.setStoredFilters(filterValue);
							}
							setTimeout(() => {
								this.refreshTile.emit(new Date().getTime());
							}, 750);
							// Refresh Order Tiles as well as Chart on Dashboard
							// Refresh Chart on Order Listing
						}
					}
				}, error => {
					this.isShowLoadPanel = false;
				});
			}
		}, error => {
			this.isShowLoadPanel = false;
		});
	}

	private getDateRangeConfig() {
		this.userConfigsUI.showDataFrom = this.listOfShowDataFrom[0].value;
		const isDateRangeConfigsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.GLOBAL_SETTINGS);
		if (isDateRangeConfigsExists) {
			const globalSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS);
			if (globalSettings.date_range && Object.keys(globalSettings.date_range).length > 0) {
				const dateRangeConfig = globalSettings.date_range;
				if (dateRangeConfig.show_data_from) {
					this.userConfigsUI.showDataFrom = dateRangeConfig.show_data_from;
				}
				if (dateRangeConfig.show_data_from === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
					if (dateRangeConfig.custom_start_date) {
						this.userConfigsUI.startDate = moment(dateRangeConfig.custom_start_date).toDate();
					}
					if (dateRangeConfig.custom_end_date) {
						this.userConfigsUI.endDate = moment(dateRangeConfig.custom_end_date).toDate();
					}
				}
			}
		}
	}

	// Method used to get Customer360 Configuration
	private getCustomer360Config() {
		const isDashboardSettingsExists = this._AppCommonSrvc.isCheckSectionInUserSettings(ServerSections.DASHBOARD_SETTINGS);
		this.setDefaultSettings();
		if (isDashboardSettingsExists) {
			const dashboardSettings = this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS);
			if (dashboardSettings.configs && dashboardSettings.configs.length > 0) {
				const configIndex = dashboardSettings.configs.findIndex(cg => cg.code === this.userSettingConfigCode);
				let orderConfig: any = {};
				if (configIndex !== -1) {
					orderConfig = dashboardSettings.configs[configIndex];
				}
				let whichStatusToConsider = this.listOfStatusConsider[0].value;
				let whichDateToUse = this.listOfDateUses[0].value;
				let whichType = this.listOfTypeOptions[0].value;
				if (orderConfig && orderConfig.which_status_to_consider) {
					whichStatusToConsider = orderConfig.which_status_to_consider;
				}
				if (orderConfig && orderConfig.which_date_to_use) {
					whichDateToUse = orderConfig.which_date_to_use;
				}
				if (orderConfig && orderConfig.which_type) {
					whichType = orderConfig.which_type;
				}
				this.userConfigsUI.which_status_to_consider = whichStatusToConsider;
				this.userConfigsUI.which_date_to_use = whichDateToUse;
				this.userConfigsUI.which_type = whichType;
			}
		}
	}

	private preInit() {
		try {
			this.erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erpType = '';
		}
		this.listOfAllStatusConsider = this._SalesDataSharingService.getListOfArCreditmemoStatusConsider();
		this.isShowTypeFields = true;
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			this.isShowTypeFields = false;
			const listOfStatus = [
				Company360DashboardArCreditmemoValEnum.STATUS_ALL,
				Company360DashboardArCreditmemoValEnum.STATUS_DRAFT, Company360DashboardArCreditmemoValEnum.STATUS_OPEN
			];
			listOfStatus.forEach(ls => {
				const statusIndex = this.listOfAllStatusConsider.findIndex(s => s.value === ls);
				this.listOfStatusConsider.push(this.listOfAllStatusConsider[statusIndex]);
			});
		} else if (this.erpType === ErpTypeValEnum.SAPB1_HANA || this.erpType === ErpTypeValEnum.SAPB1_HANA_DIAPI) {
			const listOfStatus = [
				Company360DashboardArCreditmemoValEnum.STATUS_ALL,
				Company360DashboardArCreditmemoValEnum.STATUS_OPEN, Company360DashboardArCreditmemoValEnum.STATUS_CLOSED, Company360DashboardArCreditmemoValEnum.STATUS_CANCEL, Company360DashboardArCreditmemoValEnum.STATUS_CANCELLATION
			];
			listOfStatus.forEach(ls => {
				const statusIndex = this.listOfAllStatusConsider.findIndex(s => s.value === ls);
				this.listOfStatusConsider.push(this.listOfAllStatusConsider[statusIndex]);
			});

		} else {
			this.listOfStatusConsider = this.listOfAllStatusConsider;
		}

		this.listOfDateUses = this._SalesDataSharingService.getlistOfArCreditmemoDateUses();
		this.listOfTypeOptions = this._SalesDataSharingService.getlistOfArCreditmemoTypeOptions();
		this.getCustomer360Config();
		let minDate = null;
		try {
			minDate = this._LoginService.loginUser.account_detail.app_settings.erp_data_start_date || null;
		} catch (e) {
			minDate = null;
		}
		this.minDate = minDate ? moment(minDate).toDate() : null;
	}

	private setDefaultSettings() {
		this.userConfigsUI.which_status_to_consider = this.listOfStatusConsider[0].value;
		this.userConfigsUI.which_date_to_use = this.listOfDateUses[0].value;
		this.userConfigsUI.which_type = this.listOfTypeOptions[0].value;
	}

	private doShowGlobalSettings() {
		this.isShowDataFromField = false;
		if (this.actionFrom === 'sales_ar_creditmemo_listing') {
			this.isShowDataFromField = true;
			this.listOfShowDataFrom = this._DashboardService.getListOfDateRangeShowDataFrom();
			this.getDateRangeConfig();
		}
	}

	public doConfirmedSettings() {
		if (this.actionFrom === 'sales_ar_creditmemo_listing') {
			if (this.isCheckValidation()) {
				return false;
			}
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.isShowLoadPanel = true;
		this.updateUserSettingSbsn = this._AppCommonSrvc.fetchUserSettings().subscribe(response => {
			this.isShowLoadPanel = false;
			if (response) {
				this._AppCommonSrvc.setUserSettings(response.data || []);

				const orderConfig = {
					guid: '',
					code: '',
				};

				let dashboardSettingsConfig: any = {};
				try {
					dashboardSettingsConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS)));
				} catch (e) {
					dashboardSettingsConfig = {};
				}

				if (!dashboardSettingsConfig.hasOwnProperty('configs')) {
					dashboardSettingsConfig['configs'] = [];
				}
				let configsIndex = dashboardSettingsConfig.configs.findIndex(cg => cg.code === this.userSettingConfigCode);
				if (configsIndex === -1) {
					orderConfig.guid = this.guid.newGuid();
					orderConfig.code = this.userSettingConfigCode;
					dashboardSettingsConfig.configs.push(orderConfig);
				}

				configsIndex = dashboardSettingsConfig.configs.findIndex(cg => cg.code === this.userSettingConfigCode);
				dashboardSettingsConfig.configs[configsIndex]['which_status_to_consider'] = this.userConfigsUI.which_status_to_consider;
				dashboardSettingsConfig.configs[configsIndex]['which_date_to_use'] = this.userConfigsUI.which_date_to_use;
				dashboardSettingsConfig.configs[configsIndex]['which_type'] = this.userConfigsUI.which_type;

				const dashboardSettingsConfigObj = {
					section: ServerSections.DASHBOARD_SETTINGS,
					section_value: dashboardSettingsConfig
				};

				const userSettings = [];
				const dashboardIndex = userSettings.findIndex(section => section.section === ServerSections.DASHBOARD_SETTINGS);
				if (dashboardIndex === -1) {
					userSettings.push(dashboardSettingsConfigObj);
				}

				let dateRangeGlobalConfig: any = {};
				if (this.actionFrom === 'sales_ar_creditmemo_listing') {
					try {
						dateRangeGlobalConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS)));
					} catch (e) {
						dateRangeGlobalConfig = {};
					}
					const dateRangeGlobalConfigObj = {
						section: '',
						section_value: ''
					}
					if (!dateRangeGlobalConfig.hasOwnProperty('date_range')) {
						dateRangeGlobalConfig['date_range'] = {};
					}

					if (dateRangeGlobalConfig.date_range && Object.keys(dateRangeGlobalConfig.date_range).length <= 0) {
						dateRangeGlobalConfig.date_range.guid = this.guid.newGuid();
						dateRangeGlobalConfig.date_range.code = 'date_range';
					}

					dateRangeGlobalConfig.date_range.show_data_from = this.userConfigsUI.showDataFrom;
					if (this.userConfigsUI.showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
						if (this.userConfigsUI.startDate) {
							dateRangeGlobalConfig.date_range.custom_start_date = moment(this.userConfigsUI.startDate).format(DATETIME_FORMAT_BACKEND);
						}
						if (this.userConfigsUI.endDate) {
							dateRangeGlobalConfig.date_range.custom_end_date = moment(this.userConfigsUI.endDate).format(DATETIME_FORMAT_BACKEND);
						}
					} else {
						if (dateRangeGlobalConfig.date_range.hasOwnProperty('custom_start_date')) {
							dateRangeGlobalConfig.date_range.custom_start_date = null;
						}
						if (dateRangeGlobalConfig.date_range.hasOwnProperty('custom_end_date')) {
							dateRangeGlobalConfig.date_range.custom_end_date = null;
						}
					}
					dateRangeGlobalConfigObj.section = ServerSections.GLOBAL_SETTINGS;
					dateRangeGlobalConfigObj.section_value = dateRangeGlobalConfig;
					if (dateRangeGlobalConfigObj.section) {
						const globalSettingIndex = userSettings.findIndex(section => section.section === ServerSections.GLOBAL_SETTINGS);
						if (globalSettingIndex === -1) {
							userSettings.push(dateRangeGlobalConfigObj);
						}
					}
				}

				const formData = new FormData();
				formData.append('usr', this._LoginService.loginUser.user);
				formData.append('token', this._LoginService.loginUser.token);
				formData.append('method', ServerMethods.SAVE_MULTIPLE_USER_SETTINGS);
				formData.append('user_settings', JSON.stringify(userSettings));
				if (this.updateUserSettingSbsn) {
					this.updateUserSettingSbsn.unsubscribe();
				}
				this.isShowLoadPanel = true;
				this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe(response => {
					this.isShowLoadPanel = false;
					if (response) {
						if (response.flag) {
							const userSettings = this._AppCommonSrvc.getUserSettings();
							if (userSettings && userSettings.length <= 0) {
								const newSection = {};
								newSection[ServerSections.DASHBOARD_SETTINGS] = dashboardSettingsConfig;
								this._AppCommonSrvc.setUserSettings([newSection]);
								this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS, dashboardSettingsConfig);
							} else {
								this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.DASHBOARD_SETTINGS, dashboardSettingsConfig);
							}

							if (this.actionFrom === 'ar_creditmemo_tile') {
								this._GlobalStateService.notifyDataChangedDuplicate('REFRESH_DASHBOARD_AR_CREDITMEMO_TILES_EVENT', new Date().getTime());
							}
							if (this.actionFrom === 'sales_ar_creditmemo_listing') {
								if (userSettings && userSettings.length <= 0) {
									const newSection = {};
									newSection[ServerSections.GLOBAL_SETTINGS] = dateRangeGlobalConfig;
									this._AppCommonSrvc.setUserSettings([newSection]);
									this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS, dateRangeGlobalConfig);
								} else {
									this._AppCommonSrvc.setSectionFromUserSettings(ServerSections.GLOBAL_SETTINGS, dateRangeGlobalConfig);
								}
								const filterValue = [];
								const getFiltersObj = this._DashboardService.getArCreditmemoFiltersForTiles('ar_creditmemo_tile');
								if (getFiltersObj.whichDateToUse === Company360DashboardArCreditmemoValEnum.DATE_DOC_DATE) {
									const documentStatusObj = { dataType: 'date', dataField: 'docdate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichDateToUse === Company360DashboardArCreditmemoValEnum.DATE_DUE_DATE) {
									const documentStatusObj = { dataType: 'date', dataField: 'docduedate', selectedFilterOperation: 'between', filterValues: [getFiltersObj.fromDate, getFiltersObj.toDate] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_OPEN) {
									const documentStatusObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Open'] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_CLOSED) {
									const documentStatusObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Close'] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichStatusToConsider === Company360DashboardArCreditmemoValEnum.STATUS_CANCEL) {
									const documentStatusObj = { dataType: 'string', dataField: 'documentstatus', selectedFilterOperation: 'equals', filterValues: ['bost_Cancel'] };
									filterValue.push(documentStatusObj);
								}
								if (getFiltersObj.whichType === Company360DashboardArCreditmemoValEnum.TYPE_INVENTORY) {
									const documentTypeObj = { dataType: 'string', dataField: 'doctype', selectedFilterOperation: 'equals', filterValues: ['dDocument_Items'] };
									filterValue.push(documentTypeObj);
								}
								if (getFiltersObj.whichType === Company360DashboardArCreditmemoValEnum.TYPE_SERVICE) {
									const documentTypeObj = { dataType: 'string', dataField: 'doctype', selectedFilterOperation: 'equals', filterValues: ['dDocument_Service'] };
									filterValue.push(documentTypeObj);
								}
								this._SalesDataSharingService.setStoredFilters(filterValue);
							}
							setTimeout(() => {
								this.refreshTile.emit(new Date().getTime());
							}, 750);
							// Refresh Order Tiles as well as Chart on Dashboard
							// Refresh Chart on Order Listing
						}
					}
				}, error => {
					this.isShowLoadPanel = false;
				});
			}
		}, error => {
			this.isShowLoadPanel = false;
		});
	}

	private isCheckValidation() {
		if (this.userConfigsUI.showDataFrom === DateRangeGlobalSettingValEnum.CUSTOM_DATE_RANGE) {
			if (!this.userConfigsUI.startDate) {
				this._ToastrService.info('Start Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
			if (!this.userConfigsUI.endDate) {
				this._ToastrService.info('End Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
			const mStart = moment(this.userConfigsUI.startDate);
			const mEnd = moment(this.userConfigsUI.endDate);
			if (!mStart.isSameOrBefore(mEnd)) {
				this._ToastrService.info('End Date should be greater than or equal to Start Date.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
		}
		return false;
	}

}
