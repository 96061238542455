import { Injectable } from '@angular/core';
import { ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ChargebackContractService {

	public tabItemsList: any[] = [
		{ key: 'items', displayVal: 'Items' },
		{ key: 'chargebackdocuments', displayVal: 'Chargeback Documents' }
	];
	public editChargebackGuid: any;
	public itemTabDataList: any = [];
	public calledClaimedItem: string;
	constructor(
		public _LoginService: LoginService,
		public _RestApiService: RestApiService,
		public _ToastrService: ToastrService,
	) { }

	public setEditChargebackGuid(editChargebackGuid) {
		this.editChargebackGuid = editChargebackGuid;
	}
	public getEditChargebackGuid() {
		return this.editChargebackGuid;
	}
	public setItemTabDataList(list) {
		this.itemTabDataList = list;
	}
	public getItemTabDataList() {
		return this.itemTabDataList;
	}

	//short pay task list
	public getShortPayTaskList(): any {
		//const viewFields = ['guid', 'title', 'datetime', 'method', 'status', 'type', 'subtype', 'attachment', 'created_date'];
		let searchParams: any[] = [['tasktype', '=', 'short_pay'], 'and', ['status', '=', 'open']];
		let sortParams: any[] = [{ "selector": "created_date", "desc": true }];

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.TASKS);
		formData.append('is_dropdown', 'true');
		formData.append('filter', JSON.stringify(searchParams));
		formData.append('sort', JSON.stringify(sortParams));
		//formData.append('view_fields', JSON.stringify(viewFields));
		return this._RestApiService.doDataOutReqFormData(formData);
	}
	//end 
}
