<dx-tree-list #treeList [dataSource]="listOfColumns" [rootValue]="''" keyExpr="guid" [showRowLines]="true"
	[showBorders]="true" parentIdExpr="parent_guid" [columnAutoWidth]="true" [(selectedRowKeys)]="listOfSelectedColumns"
	[autoExpandAll]="true" (onSelectionChanged)="doSelectionChangedColumn($event)" height="450"
	class="configurationsFieldChooser">
	<dxo-selection mode="multiple" [allowSelectAll]="false" [recursive]="true">
	</dxo-selection>
	<dxo-row-dragging [onDragChange]="doDragChangeColumns" [onReorder]="doReorderColumns" [allowDropInsideItem]="false"
		[allowReordering]="true" [showDragIcons]="true"></dxo-row-dragging>
	<dxi-column dataField="dataField" caption="Column Name" cellTemplate="cellTemplateDataFields">
		<div *dxTemplate="let element of 'cellTemplateDataFields'">
			<a>{{element.data.caption}}</a>
		</div>
	</dxi-column>

</dx-tree-list>

<div class="row">
	<div class="cols12 mrgT10">
		<div class="dx-field dx-lableset">
			<div class="dx-field-label">Report Layout</div>
			<div class="dx-field-value" style="width: 100%;">
				<dx-radio-group [dataSource]="listOfReportLayout" (onValueChanged)="doChangeReportLayout($event)"
					valueChangeEvent="blur" displayExpr="text" valueExpr="value" [(value)]="report_layout"
					layout="horizontal">
				</dx-radio-group>
			</div>
		</div>
	</div>
	<div class="cols12">
		<span class="highlight">
			<span class="mrgR10">Note: </span>
			You can not select more than {{no_of_column}} columns.
		</span>
	</div>
	<div class="cols12">
		<div class="btnCols alignRight">
			<dx-button (click)="doExportPdf()" class="secondaryBtn button" text="Export" type="normal"
				stylingMode="contained" [useSubmitBehavior]="false" icon="pdffile">
			</dx-button>
		</div>
	</div>
</div>