<div *ngxPermissionsOnly="isCurrentTabPermission">
	<!-- <div class="row">
		<div class="cols12 radioGroup ">
			<div class="feildCol mrgT0 feildColAuto radio">
				<input name="exclusionsgroupradiobutton" type="radio" value="addproductexclusion" id="addProductExclusion"
					(click)="clickExclusionsGroupRadioButton('addproductexclusion')" [(ngModel)]="exclusionsGroupRadioButton"
					checked>
				<label for="addProductExclusion" class="radio-label">Items</label>
				<input name="exclusionsgroupradiobutton" type="radio" value="addgroupexclusion" id="addGroupExclusion"
					(click)="clickExclusionsGroupRadioButton('addgroupexclusion')" [(ngModel)]="exclusionsGroupRadioButton">
				<label for="addGroupExclusion" class="radio-label">Item Groups</label>
			</div>
		</div>
	</div> -->
	<div class="row proSearch inputmgbtablet">

		<div class="cols5" *ngIf="exclusionsGroupRadioButton === 'addproductexclusion'">
			<div class="dx-field feildCol">
				<div class="dx-field-label">Customers</div>
				<div class="dx-field-value" style="width: 100%;">
					<dx-drop-down-box #customerDropDown [dataSource]="customerDataSource"
						[(value)]="lengthSelectedCustomersAndGroups" [displayExpr]="doDisplayExprCustomerGroupText"
						valueExpr="name" placeholder="Select Customer..." [showClearButton]="true"
						(onValueChanged)="onCustomerValueChanged($event)">
						<div *dxTemplate="let data of 'content'">
							<!-- <div class="tabbing cf">
								<ul>
									<li (click)="clickCustomersOrGroupTab($event,'customers')"
										[ngClass]="{active: customerOrGroupTab == 'customers'}">
										<a>Customers</a>
									</li>
									<li (click)="clickCustomersOrGroupTab($event,'groups')"
										[ngClass]="{active: customerOrGroupTab == 'groups'}">
										<a>Groups</a>
									</li>

								</ul>
							</div> -->
							<div *ngIf="customerOrGroupTab == 'customers'">
								<dx-check-box text="All Customers" (onValueChanged)="checkAllCustomer()"
									[(value)]="chkAllCustomer" class="mrgB10" width="25%">
								</dx-check-box>
								<!-- <dx-check-box text="My Customer(s)" (onValueChanged)="checkMyCustomers()"
									[(value)]="chkMyCustomers" class="mrgB10" width="25%">
								</dx-check-box> -->
								<dx-tag-box [(value)]="arrSelectedCustomerList" [grouped]="true"
									class="limitedTagBoxHeight mrgB10"></dx-tag-box>
								<dx-data-grid #customerDataGrid id="gridContainer" [dataSource]="customerDataSource"
									[columns]="customerGridBoxColumns" [selection]="{ mode: 'multiple' }"
									[hoverStateEnabled]="true"
									[paging]="{ enabled: true, pageSize: pageOptionsDropDownDatagrid.pageSize }"
									[filterRow]="{ visible: true }" [height]="345" [(selectedRowKeys)]="arrSelectedCustomerList"
									[showBorders]="true" [remoteOperations]="true"
									(onSelectionChanged)="onCustomerSelectionChanged($event)" [allowColumnResizing]="true">
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
							<div *ngIf="customerOrGroupTab === 'groups'">
								<dx-check-box text="All Groups" (onValueChanged)="checkAllGroup()"
									[(value)]="chkAllCustomrGroup" class="mrgB10" width="25%">
								</dx-check-box>
								<dx-tag-box [(value)]="arrSelectedCustomerGroupList" [grouped]="true"
									class="limitedTagBoxHeight mrgB10"></dx-tag-box>
								<dx-data-grid #customerGroupDataGrid id="gridContainer" [dataSource]="groupDataSource"
									[masterDetail]="{ enabled: true, template: 'userListTemplate','autoExpandAll': false }"
									[selection]="{mode: 'multiple'}" [hoverStateEnabled]="true"
									[paging]="{ enabled: true, pageSize: pageOptionsDropDownDatagrid.pageSize }"
									[filterRow]="{ visible: true }" [height]="345"
									[(selectedRowKeys)]="arrSelectedCustomerGroupList" [showBorders]="true"
									[remoteOperations]="true" (onSelectionChanged)="onCustomerGroupSelectionChanged($event)"
									[allowColumnResizing]="true">

									<dxi-column caption="Group" dataField="group_name" dataType="string" [allowEditing]="false"
										cellTemplate="cellTemplateGroupName" [allowHeaderFiltering]="false"
										[allowFiltering]="true" [allowSorting]="true">
										<div *dxTemplate="let element of 'cellTemplateGroupName'">
											{{element.data.group_name || ''}}
										</div>
									</dxi-column>
									<div *dxTemplate="let c of 'userListTemplate'">
										<dx-data-grid #userListDetailssDataGridRef
											id="userListDetailssDataGridRef_{{c.data.guid}}"
											[dataSource]="getUserListByGroupDetails(c)" [remoteOperations]="false"
											keyExpr="sub_account_id" [showBorders]="true" [columnAutoWidth]="true"
											[allowColumnResizing]="true">

											<dxo-filter-row [visible]="true"></dxo-filter-row>
											<dxo-filter-panel [visible]="false"></dxo-filter-panel>
											<dxo-header-filter [visible]="false"></dxo-header-filter>
											<!-- 
											<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="false"
												[allowAdding]="false">
											</dxo-editing> -->

											<dxi-column caption="Salesperson" cellTemplate="cellTemplateSalesperson"
												[allowFiltering]="true" [allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateSalesperson'">
													{{element.data.first_name || ''}} {{' '+ element.data.last_name || ''}}
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>

									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
						</div>
					</dx-drop-down-box>
				</div>
			</div>

		</div>

		<div class="cols6 cols6addbtn">
			<div class="dx-field feildCol">
				<div class="dx-field-label">Item</div>
				<div class="dx-field-value" style="width: 100%;">
					<dx-drop-down-box [dataSource]="itemDataSource" [(value)]="lengthSelectedItems"
						[displayExpr]="doDisplayExprItemsText" valueExpr="itemname" placeholder="Select Item..."
						[showClearButton]="true" (onValueChanged)="onItemValueChanged($event)">
						<div *dxTemplate="let data of 'content'">
							<dx-check-box text="All Items" (onValueChanged)="checkAllItem()" [(value)]="chkAllItem"
								class="mrgB10" width="25%">
							</dx-check-box>
							<!-- <dx-text-box *ngIf="itemGridBoxValue && itemGridBoxValue.length > 0" [readOnly]="true"
								[hoverStateEnabled]="false" [(value)]="strSelectedItemList"></dx-text-box> -->
							<dx-tag-box [(value)]="arrSelectedItemList" [grouped]="true" class="limitedTagBoxHeight mrgB10">
							</dx-tag-box>
							<dx-data-grid #itemDataGrid [dataSource]="itemDataSource" [columns]="itemGridBoxColumns"
								[selection]="{ mode: 'multiple'}" [hoverStateEnabled]="true"
								[paging]="{ enabled: true, pageSize: pageOptionsDropDownDatagrid.pageSize }"
								[filterRow]="{ visible: true }" [height]="345" [(selectedRowKeys)]="arrSelectedItemList"
								[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
								(onSelectionChanged)="onItemSelectionChanged($event)" [allowColumnResizing]="true">
								<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
									[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
								<dxo-selection [allowSelectAll]="false"></dxo-selection>
							</dx-data-grid>
						</div>
					</dx-drop-down-box>
				</div>
			</div>
		</div>
		<div class="cols1 cols1addbtn txtRight">
			<dx-button class="secondaryBtn button center" type="normal" stylingMode="contained" [useSubmitBehavior]="false"
				icon="add" (onClick)="addExclusionAgreementTabDetails()" text="Add">
			</dx-button>
		</div>
	</div>
</div>
<div class="cf mrgT10"></div>
<div class="dx-field feildCol">
	<div class="dx-field-value" style="width: 100%;">
		<dx-data-grid #exclusionTabDataGridRef id="gridContainer" [dataSource]="dataSourceExclusionTab"
			[remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true"
			(onToolbarPreparing)="doToolbarPreparing($event)"
			[masterDetail]="{ enabled: true, template: 'tiersTemplate','autoExpandAll': false }"
			(onEditorPreparing)="doOnEditorPreparingExclusionTab($event)"
			(onRowUpdated)="doOnRowUpdatedExclusionTab($event)" (onRowRemoved)="doOnRowRemovedExclusionTab($event)"
			(onSaved)="doOnSavedExclusionTab()" (onRowPrepared)="doOnonRowPreparedExclusionTab($event)"
			[paging]="{ enabled: true, pageSize: 10 }" [height]="450" keyExpr="guid" class="hideFilterPanelInx">

			<dxo-filter-row [visible]="false"></dxo-filter-row>
			<dxo-filter-panel [visible]="true"></dxo-filter-panel>
			<dxo-header-filter [visible]="false"></dxo-header-filter>
			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-filter-panel [visible]="true"></dxo-filter-panel>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
				[useNative]="false"></dxo-scrolling>
			<!-- <dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>  -->
			<!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
									[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true"></dxo-pager> -->
			<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false" [useIcons]="true">
			</dxo-editing>
			<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
			<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadRoyaltiesExclusionsAgreeState"
				[customSave]="doSaveRoyaltiesExclusionsAgreeState" [storageKey]="stateStorageKey">
			</dxo-state-storing>
			<div *dxTemplate="let data of 'templateFilterText'">
				<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
					<span>{{filterText}}</span>
				</div>
			</div>
			<div *dxTemplate="let data of 'templateActionBtns'">
				<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
					[showArrowIcon]="false" text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx"
					[items]="listOfActions" [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
					keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
					(onItemClick)="doActionsBtnItemClick($event)" [dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
			</div>
			<dxi-column caption="Customer/Group" dataField="clientname" [allowEditing]="false"
				cellTemplate="cellTemplateCustomer" [allowHeaderFiltering]="false" [allowFiltering]="true" dataType="string"
				[allowSorting]="true" [allowResizing]="true">
				<div *dxTemplate="let element of 'cellTemplateCustomer'">
					{{element.data.clientname || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="SKU" [visible]="isQBOnlineErpUser" dataField="itemsku" [allowEditing]="false"
				dataType="string" cellTemplate="cellTemplateItemSku" [allowHeaderFiltering]="false" [allowFiltering]="true"
				[allowSorting]="true" [allowResizing]="true">
				<div *dxTemplate="let element of 'cellTemplateItemSku'">
					{{element.data.itemsku || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Item" dataField="itemname" [allowEditing]="false" cellTemplate="cellTemplateItemName"
				dataType="string" [allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true"
				[allowResizing]="true">
				<div *dxTemplate="let element of 'cellTemplateItemName'">
					{{element.data.itemname || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Description" dataField="description" [allowEditing]="false"
				cellTemplate="cellTemplateItemDesc" [allowHeaderFiltering]="false" [allowFiltering]="true" dataType="string"
				[allowSorting]="true" width="350" [allowResizing]="true">
				<div *dxTemplate="let element of 'cellTemplateItemDesc'">
					{{element.data.description || ''}}
				</div>
			</dxi-column>

			<dxi-column caption="Royalty Type" dataField="tier_basis" [allowEditing]="true"
				[setCellValue]="setCellValueTierBasisColumn" cellTemplate="cellTemplateTierBasis"
				[allowHeaderFiltering]="true" [allowFiltering]="false" [allowSorting]="false" [allowResizing]="true"
				dataType="string">
				<dxo-lookup [dataSource]="listOfTierBasis" valueExpr="code" [displayExpr]="getDisplayExprTierbasis">
				</dxo-lookup>
				<dxi-validation-rule type="required"></dxi-validation-rule>
				<div *dxTemplate="let element of 'cellTemplateTierBasis'">
					{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label' | uppercase}}
				</div>
			</dxi-column>

			<dxi-column caption="Royalty Basis" dataField="commission_basis" [allowEditing]="true"
				[setCellValue]="setCellValueRoyaltiesBasisColumn" cellTemplate="cellTemplateCommissionBasis"
				[allowHeaderFiltering]="true" [allowFiltering]="false" [allowSorting]="false" [width]="120"
				dataType="string" [allowResizing]="true">
				<dxo-lookup [dataSource]="listOfRoyaltiesBasis" valueExpr="code"
					[displayExpr]="getDisplayExprRoyaltiesbasis">
				</dxo-lookup>
				<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
				<div *dxTemplate="let element of 'cellTemplateCommissionBasis'">
					{{element.data.commission_basis || '' | filterFromList:listOfRoyaltiesBasis : 'code' : 'label' |
					uppercase}}
				</div>
			</dxi-column>

			<dxi-column caption="UOM" dataField="selectedUom" [allowEditing]="true" [setCellValue]="setCellValueUomColumn"
				dataType="string" [visible]="false" cellTemplate="cellTemplateUomCode" [allowResizing]="true">
				<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
				</dxo-lookup>
				<div *dxTemplate="let element of 'cellTemplateUomCode'">
					<span *ngIf="element.data.rebate_basis === 'dollarperuom'">
						{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase}}
					</span>
				</div>
			</dxi-column>

			<dxi-column caption="Rate" dataField="rate" dataType="number" [allowEditing]="true"
				[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false"
				[editorOptions]="{ format: 'decimal', showClearButton: true }" cellTemplate="cellTemplateRate" [width]="100"
				[allowResizing]="true">
				<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
				<div *dxTemplate="let element of 'cellTemplateRate'">
					<span
						*ngIf="(element.data.commission_basis === 'dollar' || element.data.commission_basis === 'dollarperuom') && element.data.rate">
						{{element.data.rate || 0 | customCurrencyPipe }}
					</span>
					<span *ngIf="element.data.commission_basis === 'percentage' && element.data.rate">
						{{element.data.rate || 0 | customNumberNoRoundingPipe}} %
					</span>
				</div>
			</dxi-column>
			<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
				[allowSorting]="false" alignment="center" [allowResizing]="false" [minWidth]="120" [width]="100"
				[allowExporting]="false">
				<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
				<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
			</dxi-column>
			<div *dxTemplate="let c of 'tiersTemplate'">
				<dx-data-grid #tiersItemDetailssDataGridRef id="tiersItemDetailssDataGridRef"
					[dataSource]="getTiersByItemDetails(c)" (onInitNewRow)="doOnInitNewRowTiersItmTab($event,c)"
					(onRowInserted)="doOnRowInsertedTiersItmTab($event,c)"
					(onEditorPreparing)="doOnEditorPreparingTiersItmTab($event,c)"
					(onRowUpdated)="doOnRowUpdatedTiersItmTab($event,c)" (onRowRemoved)="doOnRowRemovedTiersItmTab($event,c)"
					(onSaved)="doOnSavedTiersItmTab($event,c)" keyExpr="guid" [showBorders]="true" [columnAutoWidth]="true">
					<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
					<dxo-filter-row [visible]="false"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>

					<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
						[allowAdding]="!(c.data.tier_basis === 'notiers')" [useIcons]="true">
					</dxo-editing>

					<dxi-column caption="Tier Minimum" dataField="mintier" dataType="number" [allowEditing]="true"
						[editorOptions]="{ format: 'decimal', showClearButton: true }">
					</dxi-column>

					<dxi-column caption="Tier Maximum" dataField="maxtier" [allowEditing]="true"
						[editorOptions]="{ showClearButton: true }">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>

					<dxi-column
						[caption]="'Rates '+ (c.data.commission_basis === 'dollar' ? globalCurrencySymbol : c.data.commission_basis === 'percentage' ? '(%)' : globalCurrencySymbol)"
						dataField="rate" dataType="number" [allowEditing]="true"
						[editorOptions]="{ format: 'decimal', showClearButton: true }" cellTemplate="cellTemplateRate">
						<dxi-validation-rule type="required"></dxi-validation-rule>
						<div *dxTemplate="let element of 'cellTemplateRate'">
							<span
								*ngIf="(c.data.commission_basis === 'dollar' || c.data.commission_basis === 'dollarperuom') && element.data.rate">
								{{element.data.rate || 0 | customCurrencyPipe }}
							</span>
							<span *ngIf="c.data.commission_basis === 'percentage' && element.data.rate">
								{{(element.data.rate || 0 | customNumberNoRoundingPipe) + '%' }}
							</span>
						</div>
					</dxi-column>
					<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
						[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
						[allowExporting]="false">
						<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
						<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</div>
		</dx-data-grid>
		<div *ngIf="isUploadedImportFileExclusionTab" class="noteBx mrgT10 mrgB0">
			<span>
				<b>{{listOfUnmatchedUploadedItems.length || 0}}</b> Item(s) out of <b>{{listOfTotalUploadedItems.length ||
					0}}</b>
				Customer(s) / Item(s) ignored
				<strong *ngIf="listOfUnmatchedUploadedItems && listOfUnmatchedUploadedItems.length > 0"
					(click)="doShowIgnoredItemsExculsionTab()" class="pointerMark">Click here to
					view</strong>
			</span>
		</div>
	</div>
</div>
<div class="cf"></div>


<dx-popup [width]="popupWidth | heightwidthRatio : _PopupHeightWidth.Width70:'X'"
	[height]="popupHeight | heightwidthRatio : _PopupHeightWidth.Width70:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" title="Upload Customer(s) / Item(s)" [showCloseButton]="true"
	[(visible)]="isShowUploadExclusionItemsPopup" (onHidden)="doHideExclusionItemsPopup($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" class="uploadItemsDxPopup">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'" class="uploadItemsContent">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" [elementAttr]="{class: 'scrollViewPopupClassIx' }" width="100%" height="100%">
			<div class="row">
				<div class="cols12">
					<span class="highlight">
						<span class="mrgR10">Note: </span>
						Column titles should be
						<span>
							<i>customer</i> and <i>item</i> and <i>rate</i>.
						</span>
					</span>
				</div>

				<div class="cols12 colDrag">
					<div class="center">
						<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="doOnFileDrop($event)"
							[showBrowseBtn]="false" accept="csv" [multiple]="false" class="fileUploadInput">
							<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
								<div class="csvUpload">
									<span class="icon-upload"></span>
									<h4>Drag & Drop XLS | XLSX | CSV File Here</h4>
									<span class="orStyle">OR</span>
									<button type="button" (click)="openFileSelector()" class="addFileInput"><span
											class="addIcon"></span>
										Browse Files</button>
									<span class="supprtInst">Supported up to 25 MB</span>
								</div>
							</ng-template>
						</ngx-file-drop>
						<!-- <input type="file" (change)="changeListener($event)" #input
												accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> -->
					</div>

					<div *ngIf="uploadedCsvFilesData">
						<div class="fileImage">
							<span class="icon-csv_file"></span>
						</div>
						<div class="fileDetail">
							<h4>{{uploadedCsvFilesData?.name }}</h4>
							<div class="fileProgress">
								<span></span>
							</div>
							<div class="fileProgText">
								{{ uploadedCsvFilesData?.size }} bytes
								<!-- <span>100% uploaded</span> -->
							</div>
							<span class="sprite closeIcon" (click)="doRemoveUploadFiles()"></span>
						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="doUploadCsvFile()" class="primaryBtn button" text="Upload" icon="upload" type="normal"
				stylingMode="contained" [useSubmitBehavior]="false">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>

<dx-popup [width]="popupWidth | heightwidthRatio : _PopupHeightWidth.Width80:'X'"
	[height]="popupHeight  | heightwidthRatio : _PopupHeightWidth.Height80:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="true" title="Ignored Customer(s) / Item(s) List" [showCloseButton]="true"
	[(visible)]="isShowIgnoredItemsListExclusionItemsPopup"
	(onHidden)="doHideIgnoredItemsListExclusionItemsPopup($event)" [wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-data-grid #ignoredItemsExclusionTabDataGridRef id="ignoredItemsExclusionTabDataGridRef"
			[dataSource]="listOfUnmatchedUploadedItems" [remoteOperations]="false" [showBorders]="true"
			[allowColumnResizing]="true" [columnAutoWidth]="true" keyExpr="guid" class="hideFilterPanelInx"
			[height]="'100%'">

			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-scrolling mode="virtual"></dxo-scrolling>

			<dxi-column caption="Customer(s)" dataField="customer" cellTemplate="cellTemplateCustomer" dataType="string"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateCustomer'">
					{{element.data.customer || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Item(s)" dataField="item" cellTemplate="cellTemplateItem" dataType="string"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItem'">
					{{element.data.item || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Rate" dataField="rate" cellTemplate="cellTemplateIgnoredRate" dataType="number"
				[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateIgnoredRate'">
					{{element.data.rate || 0 | number:decimalPointPercentageFormat}} %
				</div>
			</dxi-column>
			<dxi-column caption="Remark(s)" dataField="remark" cellTemplate="cellTemplateRemark" dataType="string"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateRemark'">
					<span [innerHtml]="element.data.remark"></span>
				</div>
			</dxi-column>

		</dx-data-grid>
	</div>
</dx-popup>