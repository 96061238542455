<div *ngIf="distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.DEFAULT"
	class="dx-field feildCol">
	<div class="dx-field-label">Distribution Channel(s)</div>
	<div class="dx-field-value" style="width: 100%;">
		<dx-tag-box [elementAttr]="{ id: 'elementId', class: 'dc-tag-box' }" class="removedCloseIcon"
			[dataSource]="distributionChannelsModel.distributionChannelsDataSource" [showSelectionControls]="true"
			[searchEnabled]="false" applyValueMode="useButtons" selectAllMode="allPages" [maxDisplayedTags]="2"
			[showMultiTagOnly]="false" [(value)]="distributionChannelsModel.arrSelectedDistributionChannelsList"
			valueExpr="code" [displayExpr]="doDisplayExprDistributionChannelsTagText" [multiline]="false"
			itemTemplate="customDistributionChannelItem">
			<div *dxTemplate="let dc of 'customDistributionChannelItem'">
				{{dc.code}} - {{dc.description}}
			</div>
		</dx-tag-box>
	</div>
</div>
<div *ngIf="isCallFromViewCondtion" class="dx-field feildCol">
	<div class="dx-field-label">Distribution Channel(s)</div>
	<div class="dx-field-value" style="width: 100%;">
		<dx-tag-box [elementAttr]="{ id: 'elementId', class: 'dc-tag-box' }" class="removedCloseIcon"
			[dataSource]="distributionChannelsModel.distributionChannelsDataSource"
			[showSelectionControls]="distributionChannelsModel.showSelectionControls"
			[applyValueMode]="distributionChannelsModel.applyValueMode"
			[disabled]="distributionChannelsModel.isDisabledControl" [searchEnabled]="false" selectAllMode="allPages"
			[maxDisplayedTags]="2" [showMultiTagOnly]="false"
			[(value)]="distributionChannelsModel.arrSelectedDistributionChannelsList"
			(onValueChanged)="doDistributionChannelValueChanged($event)" valueExpr="code"
			[displayExpr]="doDisplayExprDistributionChannelsTagText" [multiline]="false"
			itemTemplate="customDistributionChannelItem">
			<div *dxTemplate="let dc of 'customDistributionChannelItem'">
				{{dc.code}} - {{dc.description}}
			</div>
		</dx-tag-box>
	</div>
</div>
<div *ngIf="distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.HEADER"
	class="d-flex d-flex-center" id="distributionChannelHeader">
	<div class="distributionChannelLable">Distribution Channel(s)</div>
	<div class="distributionChannelInput flex-grow-1" style="width: 100%;">
		<dx-tag-box class="removedCloseIcon" [elementAttr]="{ id: 'elementId', class: 'dc-tag-box1' }"
			[dataSource]="distributionChannelsModel.distributionChannelsDataSource" [showSelectionControls]="true"
			[searchEnabled]="false" applyValueMode="useButtons" selectAllMode="allPages" [maxDisplayedTags]="2"
			[multiline]="false" height="36px" [showMultiTagOnly]="false"
			(onSelectionChanged)="doOnSelectionChanged($event)"
			[(value)]="distributionChannelsModel.arrSelectedDistributionChannelsList" valueExpr="code"
			(onOpened)="onOpened($event)" [displayExpr]="doDisplayExprDistributionChannelsTagText"
			dropDownButtonTemplate="custDropDownButtonTemplate" itemTemplate="customDistributionChannelItem">
			<div *dxTemplate="let dc of 'customDistributionChannelItem'">
				{{dc.code}} - {{dc.description}}
			</div>
			<div *dxTemplate="let b of 'custDropDownButtonTemplate'">
				<dx-button class="primaryBtn" text="Ok" type="normal" stylingMode="contained">
				</dx-button>
			</div>
		</dx-tag-box>
	</div>
</div>