<dx-popup [showTitle]="true" [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[fullScreen]="true" [height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [title]="titleText"
	[dragEnabled]="true" [hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="isShowPopup"
	(onHidden)="doHiddenPopop()" [wrapperAttr]="{ class: 'popupToolbarBottomPaddingRemoveIx' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" width="100%" height="100%">
			<dx-data-grid #multiClaimApprovalDataGridContainer [dataSource]="multiClaimApprovalDataSource"
				[remoteOperations]="false" [wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true"
				[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true"
				[filterValue]="filterValue" [remoteOperations]="false" [columnAutoWidth]="true"
				[allowColumnReordering]="true" [width]="'100%'" [height]="'calc(100vh - 150px)'"
				(onEditorPreparing)="onEditorPreparing($event)"
				(onSelectionChanged)="onMultiClaimApprovalSelectionChanged($event)" [(selectedRowKeys)]="selectedRows"
				(onCellPrepared)="doOnCellPreparedCloseMultipalTab($event)"
				(onToolbarPreparing)="doToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx mrgT10"
				keyExpr="guid">
				<dxo-selection mode="multiple" [allowSelectAll]="false"> </dxo-selection>
				<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-filter-panel [visible]="true"></dxo-filter-panel>
				<dxo-header-filter [visible]="true"></dxo-header-filter>
				<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
				<dxo-state-storing [enabled]="true" type="custom"
					[customLoad]="doLoadViewMultiClaimApprovalSettlementDocument"
					[customSave]="doSaveViewMultiClaimApprovalSettlementDocument" [storageKey]="stateStorageKey">
				</dxo-state-storing>
				<dxi-column dataField="ixcode" [allowHeaderFiltering]="false" caption="Claim No."
					cellTemplate="cellTemplateClaimId" alignment="left" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateClaimId'">
						{{element.data.ixcode || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Chargeback Period" dataField="chargeback_period" [allowEditing]="false"
					cellTemplate="cellTemplateChargeback" [allowHeaderFiltering]="false" [allowFiltering]="true"
					[allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateChargeback'">
						{{ (element.data.chargeback_period !== null && element.data.chargeback_period !== '' &&
						element.data.chargeback_period !==
						undefined && element.data.chargeback_period !== 'Invalid date') ? (element.data.chargeback_period |
						toDateObj | date:'MMMM,yyyy') : "" }}
					</div>
				</dxi-column>
				<dxi-column dataField="chargebackBasis" [allowHeaderFiltering]="false" caption="Chargeback Basis"
					cellTemplate="cellTemplateChargebackBasis" alignment="left" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateChargebackBasis'">
						{{element.data.chargebackBasis || '' | filterFromList:chargebackBasisRadio:'value':'text'}}
					</div>
				</dxi-column>
				<dxi-column dataField="contractName" [allowHeaderFiltering]="false" caption="Contract Name"
					cellTemplate="cellTemplateContractName" alignment="left" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateContractName'">
						{{element.data.contractName || ''}}
					</div>
				</dxi-column>
				<dxi-column dataField="customerCode" [allowHeaderFiltering]="false" caption="Customer Code"
					cellTemplate="cellTemplateCustomerCode" alignment="left" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateCustomerCode'">
						{{element.data.customerCode || ''}}
					</div>
				</dxi-column>
				<dxi-column dataField="customerName" [allowHeaderFiltering]="false" caption="Customer Name"
					cellTemplate="cellTemplateCustomerName" alignment="left" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateCustomerName'">
						{{element.data.customerName || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Document Date" dataField="documentDate" [allowEditing]="false"
					cellTemplate="cellTemplatedocumentDocumentDate" [allowHeaderFiltering]="false" [allowFiltering]="true"
					[allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplatedocumentDocumentDate'">
						{{ (element.data.documentDate !== null && element.data.documentDate !== '' &&
						element.data.documentDate !==
						undefined && element.data.documentDate !== 'Invalid date') ? (element.data.documentDate | toDateObj |
						date:
						'yyyy-MM-dd') : "" }}
					</div>
				</dxi-column>
				<dxi-column dataField="created_by_name" caption="Created By" dataType="string" [allowHeaderFiltering]="true"
					cellTemplate="cellTemplateCreatedBy" [allowFiltering]="false">
					<div *dxTemplate="let element of 'cellTemplateCreatedBy'">
						{{element.data.created_by_name}}
					</div>
				</dxi-column>
				<dxi-column caption="Total Claimed Amount" dataField="documentTotal" [allowEditing]="false"
					[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="number">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateTotalClaimedAmount'">
						{{element.data.documentTotal|| 0 | customCurrencyPipe}}
					</div>
				</dxi-column>

				<dxo-toolbar>
					<dxi-item location="before" toolbar="top">
						<dx-check-box [(value)]="selectAllItem" (onValueChanged)="doOnValueChangedSelectAll($event)">
						</dx-check-box>
						<span for="selectAllItem" class="top1px mrgL5"> Select All</span>
					</dxi-item>
					<dxi-item location="before" toolbar="top" cssClass="padL20 chargebackbasisWidth">
						<div *dxTemplate="let data of 'templateChargebackBasisRadio'">
							<label class="alignLeft mrgR10 mrgT3">Chargeback Basis:</label>
							<dx-radio-group [items]="chargebackBasisRadio" [value]="chargebackBasis"
								(onValueChanged)="doChangeChargebackBasis($event)" [disabled]="false" layout="horizontal"
								displayExpr="text" valueExpr="value">
							</dx-radio-group>
						</div>
					</dxi-item>
					<dxi-item location="before" toolbar="top">
						<div *dxTemplate="let data of 'templateChargebackPriodDateBox'">
							<dx-date-box type="date" [value]="chargebackPeriod || nowDate" displayFormat="monthAndYear"
								[elementAttr]="{ class: '' }" (onValueChanged)="doFilterDateOnValueChanged($event)"
								[width]="210" [showClearButton]="true"
								[calendarOptions]="{ maxZoomLevel: 'year', minZoomLevel: 'decade' }">
							</dx-date-box>
							<div class="inputField inputFieldFilterLbl">
								<label for="inputFieldFilterLbl">Chargeback Period</label>
							</div>
						</div>
					</dxi-item>
					<dxi-item location="after" toolbar="top">
						<dx-button (onClick)="columnChooserClick()">
							<i class="dx-icon dx-icon-columnChooser"></i>
							&nbsp;Column Chooser
						</dx-button>
					</dxi-item>
				</dxo-toolbar>
			</dx-data-grid>
		</dx-scroll-view>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10 width100p padL0">
		<div *dxTemplate>
			<dx-button class="primaryBtn alignCenter width100p" id="button" text="Send Claim(s) For Approval" type="normal"
				stylingMode="contained" [disabled]="!selectedRows || (selectedRows && selectedRows.length <= 0)"
				(click)="doSubmitClaimForApproval()">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>