import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TerritoryDxDropDownBoxModel } from '@app/models/input-Components.model';
import { LoginService } from '@app/services/login.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
import { InputControlService } from "@app/services/input-control-service/input-control-service";
import { ServerEntity, ServerMethods } from "@app/constants-enums/constants";
import { PageOptions } from "@app/models/common.model";
@Component({
	selector: 'app-territory-dx-drop-down-box',
	templateUrl: './territory-dx-drop-down-box.component.html',
	styleUrls: ['./territory-dx-drop-down-box.component.css']
})
export class TerritoryDxDropDownBoxComponent implements OnInit {
	@ViewChild("territoryDropDown", {}) territoryDropDown: DxDropDownBoxComponent;
	@ViewChild("territoryDataGrid", {}) territoryDataGrid: DxDataGridComponent;
	@Input('territoryModel') territoryModel: TerritoryDxDropDownBoxModel;
	public pageOptions: PageOptions = new PageOptions();
	constructor(private _LoginService: LoginService,
		private _InputControlService: InputControlService) {
		this.doDisplayExprTerritoriesText = this.doDisplayExprTerritoriesText.bind(this);
	}

	ngOnInit() {
		this.territoryModel.territoryGridBoxColumns = this._InputControlService.territoryGridBoxColumns;
		this.loadTerritoryDataList();
	}

	loadTerritoryDataList() {
		// const viewFields = ['erp_primary_key', 'distributionChannelsku', 'classname', 'description'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.TERRITORIES,
			//view_fields: JSON.stringify(viewFields)
		};
		this.territoryModel.territoryDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			loadMode: "processed",
			key: "territory_name",
		});
	}

	public doDisplayExprTerritoriesText(event: any) {
		let listOfText = [];
		if (this.territoryModel.chkAllTerritory) {
			listOfText.push('All Territory');
		}
		if (this.territoryModel.arrSelectedTerritoryList && this.territoryModel.arrSelectedTerritoryList.length > 0) {
			listOfText.push(this.territoryModel.arrSelectedTerritoryList.length + ' Territory(s) Selected');
		}
		return listOfText.length > 0 ? listOfText.join(', ') : '';
	}

	onTerritoryValueChanged(e: any) {
		if (e.value === null) {
			this.territoryModel.arrSelectedTerritoryList = [];
			this.territoryModel.territoryGridBoxValue = [];
			this.territoryDataGrid.instance.refresh();
			this.territoryModel.chkAllTerritory = false;
			this.territoryModel.lengthSelectedTerritory = '';
		}
	}
	checkAllTerritory() {
		// if (this.chkAllTerritory) {
		// 	this.lengthSelectedTerritory = 'All Territory Selected';
		// 	this.arrSelectedTerritoryList = [];
		// 	this.territoryDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedTerritory = '';
		// }
		// if (this.chkAllTerritory) {
		this.setTerritorySelctedText();
	}
	onTerritorySelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllTerritory = false;
			//this.lengthSelectedTerritory = e.selectedRowsData.length + ' Territory Selected';
			this.territoryModel.territoryGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.territoryModel.territoryGridBoxValue.push(e.selectedRowsData[i].id);
			};
		}
		// if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllTerritory) {
		// 	this.lengthSelectedTerritory = '';
		// }
		this.setTerritorySelctedText();
	}
	setTerritorySelctedText() {
		let lengthSelectedTerritory = '';
		this.territoryModel.lengthSelectedTerritory = '';
		if (this.territoryModel.arrSelectedTerritoryList && this.territoryModel.arrSelectedTerritoryList.length > 0) {
			lengthSelectedTerritory = this.territoryModel.arrSelectedTerritoryList.length + ' Territory Selected';
		}

		if (this.territoryModel.chkAllTerritory) {
			lengthSelectedTerritory = 'All Territory ' + (lengthSelectedTerritory ? ', ' : '') + (lengthSelectedTerritory || '');
		}
		this.territoryModel.lengthSelectedTerritory = lengthSelectedTerritory;
	}
	clearTerritorySelectList() {
		this.territoryModel.chkAllTerritory = false;
		this.territoryModel.lengthSelectedTerritory = '';
		this.territoryModel.arrSelectedTerritoryList = [];
		if (this.territoryDataGrid) {
			this.territoryDataGrid.instance.refresh();
			this.territoryDataGrid.instance.clearFilter();
		}
		if (this.territoryDropDown) {
			this.territoryDropDown.instance.reset();
		}
		this.loadTerritoryDataList();
	}

}
