import { formatNumber } from 'devextreme/localization';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyModel, CurrencyOptions } from '@app/models/common.model';
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { locale } from 'devextreme/localization';

@Pipe({
	name: 'customCurrencyPipe'
})

export class CustomCurrencyPipe implements PipeTransform {
	// https://stackoverflow.com/questions/38752324/angular-2-formatting-currency-brl-format
	private currency_locale: string;
	private currency_code: string;
	private currency_fraction: number;
	private numberFormatstyle: string = 'currency';
	public listOfOtherCurrency: any = []
	public listOfCurrency: any = []
	public enable_other_currencies: boolean = false;
	constructor(private _LoginService: LoginService,
		private _AppCommonSrvc: AppCommonSrvc
	) {

		this.loadCurrencyObj();
	}

	transform(price: number, currencyOptions?: CurrencyOptions): any {
		this.loadCurrencyObj();
		if (this.enable_other_currencies && (currencyOptions && (currencyOptions.currencyCode || currencyOptions.currencyName))) {
			if (this.listOfOtherCurrency.length > 0) {
				let currencyModel = new CurrencyModel();
				if (currencyOptions?.currencyCode || currencyOptions?.currencyName) {
					currencyModel = this._AppCommonSrvc.getCurrencyDetail(currencyOptions);
					if (currencyModel) {
						if (currencyModel.currency_language) {
							currencyOptions.locale = currencyModel.currency_language;
						}
						if (currencyModel.currency_code) {
							currencyOptions.currencyCode = currencyModel.currency_code;
						}
					}
				}
			}
		}
		if (currencyOptions?.locale) {
			this.currency_locale = currencyOptions.locale;
		} else {
			this.currency_locale = this._LoginService.currencyLocale;
		}

		const numberFormatOptions: any = {
			style: this.numberFormatstyle,
			currency: 'USD',
			minimumIntegerDigits: 1,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			roundingMode: (price < 0) ? 'ceil' : 'floor'
		};

		if (currencyOptions?.style) {
			numberFormatOptions.style = currencyOptions?.style;
		} else {
			numberFormatOptions.style = this.numberFormatstyle;
		}
		if (currencyOptions?.currencyCode) {
			numberFormatOptions.currency = currencyOptions.currencyCode;
		} else {
			numberFormatOptions.currency = this.currency_code;
		}
		if (currencyOptions?.fractionDigit) {
			numberFormatOptions.minimumFractionDigits = currencyOptions.fractionDigit;
			numberFormatOptions.maximumFractionDigits = currencyOptions.fractionDigit;
		} else {
			numberFormatOptions.minimumFractionDigits = this.currency_fraction;
			numberFormatOptions.maximumFractionDigits = this.currency_fraction;
		}
		// once get nagatvie zero (-0.000) value 

		const val: any = Intl.NumberFormat(this.currency_locale, { minimumFractionDigits: numberFormatOptions.maximumFractionDigits }).format(price);
		if (1 / val == -Infinity) {
			price = 0;
		}

		//end 
		return new Intl.NumberFormat(this.currency_locale, numberFormatOptions).format(price || 0);
	}

	loadCurrencyObj() {
		this.currency_code = this._LoginService.globalCurrency;
		this.currency_locale = this._LoginService.currencyLocale;
		this.currency_fraction = this._LoginService.decimalPointForCurrency;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('other_currencies_lst')) {
			this.listOfOtherCurrency = this._LoginService.loginUser.account_detail.other_currencies_lst;
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('currency_lst')) {
			this.listOfCurrency = this._LoginService.loginUser.account_detail.currency_lst;
		}
	}
}

@Pipe({
	name: 'customCurrencySymbolPipe'
})

export class CustomCurrencySymbolPipe implements PipeTransform {
	// https://stackoverflow.com/questions/38752324/angular-2-formatting-currency-brl-format
	private currency_locale: string;
	private currency_code: string;
	private currency_fraction: number;
	private numberFormatstyle: string = 'currency';
	public listOfOtherCurrency: any = []
	public listOfCurrency: any = []
	public enable_other_currencies: boolean = false;

	constructor(private _LoginService: LoginService, private _AppCommonSrvc: AppCommonSrvc) {
		this.loadCurrencyObj();
	}

	loadCurrencyObj() {
		this.currency_code = this._LoginService.globalCurrency;
		this.currency_locale = this._LoginService.currencyLocale;
		this.currency_fraction = this._LoginService.decimalPointForCurrency;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('other_currencies_lst')) {
			this.listOfOtherCurrency = this._LoginService.loginUser.account_detail.other_currencies_lst;
		}
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('currency_lst')) {
			this.listOfCurrency = this._LoginService.loginUser.account_detail.currency_lst;
		}
	}
	transform(currencyOptions?: CurrencyOptions): any {
		this.loadCurrencyObj();
		if (this.enable_other_currencies && (currencyOptions && (currencyOptions.currencyCode || currencyOptions.currencyName))) {
			if (this.listOfOtherCurrency.length > 0) {
				let currencyModel = new CurrencyModel();
				if (currencyOptions?.currencyCode || currencyOptions?.currencyName) {
					currencyModel = this._AppCommonSrvc.getCurrencyDetail(currencyOptions);
					if (currencyModel) {
						if (currencyModel.currency_language) {
							currencyOptions.locale = currencyModel.currency_language;
						}
						if (currencyModel.currency_code) {
							currencyOptions.currencyCode = currencyModel.currency_code;
						}
					}
				}
			}
		}
		if (currencyOptions?.locale) {
			this.currency_locale = currencyOptions.locale;
		} else {
			this.currency_locale = this._LoginService.currencyLocale;
		}
		const numberFormatOptions: any = {
			style: this.numberFormatstyle,
			currency: 'USD',
			minimumIntegerDigits: 1,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		};

		if (currencyOptions?.style) {
			numberFormatOptions.style = currencyOptions?.style;
		} else {
			numberFormatOptions.style = this.numberFormatstyle;
		}
		if (currencyOptions?.currencyCode) {
			numberFormatOptions.currency = currencyOptions.currencyCode;
		} else {
			numberFormatOptions.currency = this.currency_code;
		}
		if (currencyOptions?.fractionDigit) {
			numberFormatOptions.minimumFractionDigits = currencyOptions.fractionDigit;
			numberFormatOptions.maximumFractionDigits = currencyOptions.fractionDigit;
		} else {
			numberFormatOptions.minimumFractionDigits = this.currency_fraction;
			numberFormatOptions.maximumFractionDigits = this.currency_fraction;
		}
		return new Intl.NumberFormat(this.currency_locale, numberFormatOptions)
			.formatToParts(1).find(x => x.type === "currency").value;
	}
}

@Pipe({
	name: 'customFormatCurrencyPipe'
})

export class CustomFormatCurrencyPipe implements PipeTransform {
	// https://stackoverflow.com/questions/38752324/angular-2-formatting-currency-brl-format
	private currency_locale: string;
	private currency_code: string;
	private currency_fraction: number;
	private numberFormatstyle: string = 'currency';

	constructor(private _LoginService: LoginService) {
		this.currency_code = this._LoginService.globalCurrency;
		this.currency_locale = this._LoginService.currencyLocale;
		this.currency_fraction = this._LoginService.decimalPointForCurrency;
	}

	transform(price: number, currencyOptions?: CurrencyOptions): any {
		if (currencyOptions?.locale) {
			this.currency_locale = currencyOptions.locale;
		}
		const numberFormatOptions = {
			style: this.numberFormatstyle,
			currency: 'USD',
			minimumIntegerDigits: 1,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			roundingMode: (price < 0) ? 'ceil' : 'floor'
		};

		if (currencyOptions?.style) {
			numberFormatOptions.style = currencyOptions?.style;
		} else {
			numberFormatOptions.style = this.numberFormatstyle;
		}
		if (currencyOptions?.currencyCode) {
			numberFormatOptions.currency = currencyOptions.currencyCode;
		} else {
			numberFormatOptions.currency = this.currency_code;
		}
		if (currencyOptions?.fractionDigit) {
			numberFormatOptions.minimumFractionDigits = currencyOptions.fractionDigit;
			numberFormatOptions.maximumFractionDigits = currencyOptions.fractionDigit;
		} else {
			numberFormatOptions.minimumFractionDigits = this.currency_fraction;
			numberFormatOptions.maximumFractionDigits = this.currency_fraction;
		}
		// return new Intl.NumberFormat(this.currency_locale, numberFormatOptions)
		// .formatToParts(1).find(x => x.type === "currency").value;
		return formatNumber(price, { type: "currency largeNumber", currency: numberFormatOptions.currency, precision: numberFormatOptions.minimumFractionDigits });
		// return formatNumber(123456.78, this.currency_locale, '1.' + 2 + '-' + 2);
	}
}
