<dx-popup #dxCardViewPopupComponent [showTitle]="true" [width]="addEditPopupWidth | heightwidthRatio : 22:'X'"
	[height]="'100vh'" [title]="titleText" [dragEnabled]="true" [hideOnOutsideClick]="false" [showCloseButton]="true"
	[(visible)]="isShowPopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'dx-overlay-shader-disable listofSettelmentix' }" [shading]="false"
	[position]="{ my: 'right top', at: 'right top', offset: offsetY}">
	<dxi-toolbar-item cssClass="settlement-open-close-ix" location="after" widget="dxButton" toolbar="top"
		[options]="minimizeButtonOptions">
	</dxi-toolbar-item>
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<h2>{{successListOfSettlement.length}} Out of {{selectedMultiSettlementRowData.length}} Processed</h2>
		<div class="tabbing cf">
			<ul>
				<li (click)="clickMainTab('inprocess')" [ngClass]="{active: currentTab == 'inprocess'}">
					<a>In Process</a>
				</li>
				<li (click)="clickMainTab('successful')" [ngClass]="{active: currentTab == 'successful'}">
					<a>Successful</a>
				</li>
			</ul>
		</div>
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false" width="100%" height="90%">
			<div *ngIf="currentTab === 'inprocess'">
				<dx-list [dataSource]="inprocessListOfSettlement" height="100%">
					<div *dxTemplate="let selectedRow of 'item'">
						<div class="listofSettelmentinnerix">
							<h3>Contract Name
								<span *ngIf="selectedRow.status === 'loading'" class="loadingPrice custSpinner"></span>
								<!-- <span *ngIf="selectedRow.status === 'inprocess'" class="orange">In Process</span> -->
								<span *ngIf="selectedRow.status === 'inqueue'" class="orange">In Queue</span>
								<span *ngIf="selectedRow.status === 'warning'" class="red">Warning</span>
							</h3>
							<h4>{{selectedRow.contractName}}</h4>
							<p>Open Settlement Period </p>
							<h5>{{selectedRow.settlementPeriodStart}} to {{selectedRow.settlementPeriodEnd}}</h5>
							<p>Settlement Amount: <span class="purple">{{selectedRow.current_settlement_commission_total || 0 |
									customCurrencyPipe}}</span></p>
							<span *ngIf="selectedRow.status === 'warning'" class="red">{{selectedRow.warningMessage ||
								''}}</span>
						</div>
					</div>
				</dx-list>
			</div>
			<div *ngIf="currentTab === 'successful'">
				<dx-list [dataSource]="successListOfSettlement" height="100%">
					<div *dxTemplate="let selectedRow of 'item'">
						<div class="listofSettelmentinnerix">
							<p>Settlement No.: <span class="black">{{selectedRow.settlementNo}}</span></p>
							<h3>Contract Name <span class="green">Successful</span></h3>
							<h4>{{selectedRow.contractName}}</h4>
							<p>Open Settlement Period </p>
							<h5>{{selectedRow.settlementPeriodStart}} to {{selectedRow.settlementPeriodEnd}}</h5>
							<p>Settlement Amount: <span class="purple">{{selectedRow.current_settlement_commission_total || 0 |
									customCurrencyPipe}}</span></p>
						</div>
					</div>
				</dx-list>
			</div>
		</dx-scroll-view>
		<!-- <dx-tab-panel [dataSource]="listOfTabs" [height]="'inherit'" itemTitleTemplate="tabTitle"
			itemTemplate="tabContent" [deferRendering]="true" [showNavButtons]="false" [repaintChangesOnly]="true"
			[selectedItem]="selectedItem" [selectedIndex]="selectedTabIndex" [swipeEnabled]="false"
			(onSelectionChanged)="doChangeTab($event)">
			<div *dxTemplate="let content of 'tabTitle'">
				<span>{{content.title}}</span>
			</div>
			<div *dxTemplate="let tab of 'tabContent'">
				<div class="listofSettelmentinnerix" *ngFor="let selectedRow of listOfSettlement">
					<h3>Contract Name </h3>
					<h4>{{selectedRow.contractName}}</h4>
					<p>Settlement No.: <span class="black">IXCSP157</span></p>
					<p>Open Settlement Period </p>
					<h5>{{selectedRow.settlementPeriodStart}} to {{selectedRow.settlementPeriodEnd}}</h5>
					<p>Settlement Amount: <span class="purple">{{selectedRow.current_settlement_commission_total || 0 |
							customCurrencyPipe}}</span></p>
				</div>
			</div>
		</dx-tab-panel> -->
	</div>
</dx-popup>