<div id="wrapper" class="loginScreens">
	<div class="loginContainer authyCode cf">
		<div class="regTitles" *ngIf="authenticationType === 'authycode'">
			<h3>Enter your verification code
				shown in Authy
			</h3>
			<h4>
				Input the six digit code shown for <span class="skyblue">incentX</span>
				in the Authy app to verify your identity
			</h4>

			<div id="2fa-message" style="color:red">
				{{errorMessage || ''}}
			</div>
		</div>
		<div class="regTitles" *ngIf="authenticationType === 'smscode'">
			<h3>Enter your verification code</h3>
			<h4>
				Input the code we sent to <span class="skyblue">{{mobileNumber}}</span>
				to access your account.
			</h4>
			<div id="2fa-message" style="color:red">
				{{errorMessage || ''}}
			</div>
		</div>
		<div class="loginForm cf">
			<div class="formControls">
				<form (ngSubmit)="checkTwoFactorAuthenticationClick()" autocomplete="off">
					<div class="row">
						<div class="cols12">
							<div class="cf">
								<input type="text" [(ngModel)]="twoFactorAuthenticationCode" [disabled]="isCallVerifyOTP"
									name="twoFactorAuthenticationCode" class="mrgB10 verificationCode"
									(keyup)="doKeyUpVerificationCode($event)" (paste)="doOnPasteVerificationCode($event)"
									maxlength="6" placeholder="" numbersOnly autoFocus pattern="[0-9]*">
							</div>
							<span class="alignRight" *ngIf="authenticationType === 'smscode'"><a (click)="doClickResendPIN()"
									class="rsndPing">Resend PIN</a></span>
						</div>
					</div>
					<div class="cf mrgB20">
						<input type="submit" class="button payBtn cols12" value="Verify" />
					</div>
				</form>
				<div class="cf mrgB20 mrgT20" *ngIf="authenticationType === 'authycode'">
					<div class="alignLeft">Never used Authy before?</div>
					<div class="alignRight">
						<a href="https://authy.com/download/" target="_blank" class="downAppy">
							<span class="authyIcon">
								<span class="icon-logos_authy"></span>
							</span>
							<span class="underLine">Download the app</span>
						</a>
					</div>
				</div>
				<div class="cf">
					<h3 class="anotherMethod"><span>Choose another method</span></h3>
				</div>
				<div class="row">
					<div class="methodIcons" *ngIf="authenticationType === 'authycode'">
						<a (click)="doClickMethodIcon('smscode')">
							<span class="icon-sms"></span>
							Use SMS
						</a>
					</div>
					<div class="methodIcons" *ngIf="authenticationType === 'smscode'">
						<a (click)="doClickMethodIcon('authycode')">
							<span class="authyAppIconRed"></span>
							Authy App
						</a>
					</div>
					<!-- <div class="methodIcons">
						<a href="#">
							<span class="rcvCallIcon"></span>
							Receive a Call
						</a>
					</div>
					<div class="methodIcons">
						<a href="#">
							<span class="rcvrCode"></span>
							Recovery Code
						</a>
					</div> -->
				</div>

			</div>
		</div>
	</div>
</div>