<div class="numberBlock hoverClr" id="quotePopover">
	<span (click)="doOpenQuotePopover($event)"
		[class]="isUserSettingsExisting ? 'settingIcon icon-setting-purple' : 'settingIcon icon-setting'"
		title="Setting Icon">
	</span>
	<div (click)="tilesClick('QUOTATIONS')">
		<span class="contractIcon">
			<span class="icon-quotes_1 icon-set1"></span>
		</span>
		<div class="blockText">
			<strong>
				<span *ngIf="resourcesLoaded" class="loadingPrice custSpinner"></span>
				<span *ngIf="!resourcesLoaded">
					<span *ngIf="!isShowFullValue" (click)="doClickToShowFullValue($event)"
						class="dashboardamount shortamount">
						{{valueOfSalesQuotesForTheMonth || 0 | customFormatCurrencyPipe }}
						<span *ngIf="valueOfSalesQuotesForTheMonth >= 1000" class="dashboardamounttext">Click to see full
							value</span>
					</span>
					<div *ngIf="isShowFullValue" class="fullamount"
						[ngClass]="{'trillionamount ': getLastCharacterFromSortValue ===  'T' ,'quadrillionamount': getLastCharacterFromSortValue === 'Q' || getLastCharacterFromSortValue ==='E'}">
						{{valueOfSalesQuotesForTheMonth || 0 | customCurrencyPipe }}
					</div>
				</span>
			</strong>
			<span class="blockDate">{{currentMonthName || ''}}</span>
			<!-- <span class="blockDate">{{currentMonthName}} </span> -->
			Quotes Total
		</div>
	</div>
</div>

<dx-popover target="#quotePopover" position="bottom" [(visible)]="isShowQuotePopover"
	(onHidden)="doCloseQuotePopover($event)" [hideOnOutsideClick]="true" [showCloseButton]="true" [showTitle]="true"
	title="Quote Tile" width="600">
	<div *dxTemplate="let data = model of 'content'">
		<app-tile-quote-settings *ngIf="isShowQuotePopover" [openFrom]="'quote_tile'"
			(refreshTile)="doRefreshTile($event)">
		</app-tile-quote-settings>
	</div>
</dx-popover>