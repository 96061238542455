export class DistributionChargebackContractModelUI {
	public guid: string;
	public billBackType: string;
	public contractName: string;
	public selectCustomerName: string;
	public selectCustomerId: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public is_active: boolean;
	public chargeBackClaimsTabData: ChargeClaimsTabModel[] = [];
	public chargeBackTypesTabData: ChargeTypesTabModel[] = [];
}
export class DistributionChargebackContractModelRequest {
	public guid: string;
	public contractName: string;
	public selectCustomerName: string;
	public selectCustomerId: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public billBackType: string;
	public is_active: boolean;
	public chargebackClaims: ChargeClaimsTabModel[] = [];
	public categories: ChargeTypesTabModel[] = [];
}

export class ChargeClaimsTabModel {
	public guid: any;
	public contractGuid: string;
	public categoryId: number;
	public categoryName: string;
	public percentage_or_amount: string; // value of percentage or amount
	public rate: number;
	public date: any;
	public claimNo: string;
	public claimedQty: number;
	public invoiceQty: string;
	public invoiceNo: string;
	public selectProduct: any;
	public selectProductId: string;
	public expectedAmount: number;
	public claimedAmount: number;
	public status: string;
	public attachedDocumentData: any[];
}

export class ChargeTypesTabModel {
	public guid: string;
	public categoryname: string;
	public description: string;
	public rate: any;
	public percentage_or_amount: any;
	public is_invoicebased: boolean;
	public is_itembased: boolean;
	public is_disputable: boolean;
	public is_recoverable: boolean;
	constructor() {
		this.is_invoicebased = false;
		this.is_itembased = false;
		this.is_disputable = false;
		this.is_recoverable = false;
	}
}

export class ClaimAttachedDocumentModel {
	public currentClaimElement: any;
	public categoriesList: any = [];
	constructor() {
	}
}

export class ChargeDocumentsTabModel {
	public guid: string;
	public contractGuid: string;
	public documentURL: string;
	public documentOriginalName: string;
	public documentReferenceNumber: string;
	public documentDocumentDescription?: string;
	public documentDate: string;
	public claimedStatus: string;
	public claimedStatusReason: string;
	public claimedUserNote: string;
	public claimedItems: ClaimedItemsModel[] = [];
}


export class ClaimedItemsModel {
	public itemCode: string;
	public itemName: string;
	public claimed: number;
}