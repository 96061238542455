import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { Customer360TilesEnum, ErpTypeValEnum, PopupHeightWidth } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
import { ViewCreditMemoDetailPopUpModel } from '@app/models/popup.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-view-craditmemo-detail-popup',
	templateUrl: './view-craditmemo-detail-popup.component.html',
	styleUrls: ['./view-craditmemo-detail-popup.component.css']
})
export class ViewCraditmemoDetailPopupComponent implements OnInit, OnDestroy {
	public pageTitle: string = '';
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public isShowPopup: boolean = true;
	@Input() viewCreditMemoDetailProps: ViewCreditMemoDetailPopUpModel;
	@Output() viewCreditDetailPopup = new EventEmitter<any>();

	public myMath = Math;
	public erpTypeValEnum = ErpTypeValEnum;
	public creditMemoDetails: any;
	public uomList: any[] = [];
	private dataOutReqSubscription: Subscription;
	private searchParams: any[] = [];
	public isResponse: boolean = false;
	public erp_type: string = '';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public listOfPrices: any[] = [];
	public isShowPickStatusColumn: boolean = false;
	public isShowDataGrid: boolean = false;
	@ViewChild('itemsListOriginDatagridRef') itemsListOriginDatagridRef: DxDataGridComponent;
	private loaderConfig: any;
	public isShowClassesColumn: boolean;
	public Customer360TilesEnum = Customer360TilesEnum;
	public isShowCostColumn: boolean = false;
	public isEnabledMasterDetails: boolean = false;
	public pageOptions: PageOptions = new PageOptions();
	public isShowOriginalCreditMemoDetails: boolean = false;
	public originalCreditMemoDetails: any;
	public isShowEligibleField: boolean;
	public isShowEligibleColumn: boolean;
	public listOfSalesPpl: any[];
	public isMultipleSalesReps: boolean;
	public globalCurrencySymbol: string;
	public decimalPointForCurrency: number;
	public isShowCardcodeColumn: boolean;
	public isEnableDistributionChannels: boolean = false;
	public listOfDistributionChannels: any[] = [];
	private getListOfDistributionChannelsSbsn: Subscription;

	constructor(private _Router: Router,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _CurrencyPipe: CurrencyPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		public _AppCommonSrvc: AppCommonSrvc
	) {
		this.loaderConfig = {
			dx_position: '{of: #mainContent1}'
		};
	}

	ngOnInit(): void {
		// if (this.viewCreditMemoDetailProps.docType == 'creditmemo') {
		this.pageTitle = 'AR Credit Memo Details'
		this.onInitCreditMemo();
		// }
	}
	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}
	}

	doCloseCreditMemoDetailCreditMemoPopup(e) {
		this.isShowPopup = false;
		this.viewCreditDetailPopup.emit({ isSuccess: true });
	}

	onInitCreditMemo() {
		this.isShowDataGrid = true;
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}

		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
		}
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		this.listOfSalesPpl = this._DataService.getSalesPpl() || [];
		this.uomList = this._DataService.getUOMs();
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_distribution_channels')) {
			this.isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels || false;
		}
		this.doCheckUseCustomersFrom();
		this.setIsShowIxcodeOrDocnum();
		this.doCallCreditMemoEntity();
	}

	displayPhoneNoWithCode(element) {
		let phonenumberstr = "";
		phonenumberstr = element.phone1 || "";
		if (element.dialcode) {
			const getPhoneCodeData = this._DataService.getSplitPhoneCode(
				element.dialcode
			);
			if (getPhoneCodeData && getPhoneCodeData.length > 0) {
				phonenumberstr = getPhoneCodeData[0].code + " " + element.phone1;
			}
		}
		return phonenumberstr;
	}

	// Method used to Show ixode or docnum with synced
	private setIsShowIxcodeOrDocnum() {
		this.isShowClassesColumn = false;
		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			this.isShowClassesColumn = true;
		}
	}

	private doCallCreditMemoEntity() {
		this.creditMemoDetails = {};
		this.creditMemoDetails.contactpersondata = undefined;
		this.creditMemoDetails.salespersondata = undefined;
		this.searchParams = [];
		this.searchParams.push({ 'docnum': this.viewCreditMemoDetailProps.docNo });
		const creditMemosFormData = new FormData();
		creditMemosFormData.append('usr', this._LoginService.loginUser.user);
		creditMemosFormData.append('token', this._LoginService.loginUser.token);
		creditMemosFormData.append('entity', ServerEntity.ARCREDITMEMO);
		creditMemosFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		creditMemosFormData.append('search', JSON.stringify(this.searchParams));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show(this.loaderConfig);

		this.isResponse = false;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(creditMemosFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				this.isResponse = true;
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const creditMemoDetails = response.data[0];
						this.creditMemoDetails = creditMemoDetails;
						this.isShowCostColumn = false;
						this.isEnabledMasterDetails = false;
						if (this.creditMemoDetails.documentlines && this.creditMemoDetails.documentlines.length > 0) {
							this.creditMemoDetails.documentlines.forEach(dl => {
								if (dl.costassignments && dl.costassignments.length > 0) {
									this.isEnabledMasterDetails = true;
								}
								if (dl.costperitem !== 0) {
									this.isShowCostColumn = true;
								}
								if (dl.assigned_date && dl.costassignment_id) {
									this.isShowCostColumn = true;
								}
							});
						}

						this.isShowEligibleField = false;
						if (this.creditMemoDetails.hasOwnProperty('eligible')) {
							this.isShowEligibleField = true;
						}

						this.isShowEligibleColumn = creditMemoDetails.documentlines.some(dl => {
							return dl.hasOwnProperty('eligible');
						});

						if (this.isEnableDistributionChannels) {
							const filterObj = ['code', 'contains', creditMemoDetails.distribution_channels];
							this.doFetchListOfDistributionChannels(filterObj);
						}

						this.isMultipleSalesReps = false;
						if (this.creditMemoDetails.hasOwnProperty('salesreps')) {
							this.isMultipleSalesReps = true;
						}

						this.searchParams = [];
						if (creditMemoDetails.guidclient) {
							this.searchParams.push({ 'guid': creditMemoDetails.guidclient });
						} else if (creditMemoDetails.cardcode) {
							this.searchParams.push({ 'code': creditMemoDetails.cardcode });
						}
						// reqFormData = this.loadSalesDetailsData();
						const clientsFormData = new FormData();
						clientsFormData.append('usr', this._LoginService.loginUser.user);
						clientsFormData.append('token', this._LoginService.loginUser.token);
						clientsFormData.append('entity', ServerEntity.CLIENTS);
						clientsFormData.append('method', ServerMethods.GET_ENTITY_DATA);
						// clientsFormData.append('is_dropdown', 'true');
						clientsFormData.append('search', JSON.stringify(this.searchParams));
						if (this.dataOutReqSubscription) {
							this.dataOutReqSubscription.unsubscribe();
						}
						this._LoaderService.show(this.loaderConfig);
						this.isResponse = false;
						this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(clientsFormData).subscribe({
							next: (response) => {
								this.isResponse = true;
								this._LoaderService.hide();
								if (response.flag) {
									if (response.data && response.data.length > 0) {
										const client = response.data[0];
										// Find Contact Person Details
										if (client.contactemployees && client.contactemployees.length > 0) {
											const contactPplData = client.contactemployees.filter(itemContact => itemContact.internalcode === creditMemoDetails.contactpersoncode);
											this.creditMemoDetails.contactpersondata = contactPplData && contactPplData.length > 0 ? contactPplData[0] : undefined;
										}
										// Set Billing/Shipping Address
										if (this.creditMemoDetails.contactpersondata === undefined) {
											this.creditMemoDetails.contactpersondata = {};
										}
										if (client && client.human_readable_account_code) {
											this.creditMemoDetails.human_readable_account_code = client.human_readable_account_code;
										}
										this.setBillingAddress(client);
										this.setShippingAddress(client);
										this.fetchedListOfPrices(client.pricelistnum);
									} else {
										this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
									}
								} else {
									this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
								}
							}, error: error => {
								this._LoaderService.hide();
								this.isResponse = true;
								this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
							}
						});
					} else {
						this.creditMemoDetails = undefined;
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this.isResponse = true;
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method To Set billing Address
	private setBillingAddress(client: any) {
		if (this.creditMemoDetails.paytocode && client.address && client.address.billto && client.address.billto.length > 0) {
			const clientAddressData = client.address.billto.filter(itemAddress => itemAddress.addressname === this.creditMemoDetails.paytocode);
			this.creditMemoDetails['contactpersondata']['billtodata'] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.creditMemoDetails.contactpersondata["billtodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Set billing Address
	private setShippingAddress(client: any) {
		if (this.creditMemoDetails.shiptocode && client.address && client.address.shipto && client.address.shipto.length > 0) {
			const clientAddressData = client.address.shipto.filter(itemAddress => itemAddress.addressname === this.creditMemoDetails.shiptocode);
			this.creditMemoDetails['contactpersondata']["shipptodata"] = undefined;
			if (clientAddressData && clientAddressData.length > 0) {
				this.creditMemoDetails.contactpersondata["shipptodata"] =
					clientAddressData[0];
			}
		}
	}

	// Method To Get List of Prices from Clients's pricelistnum
	private fetchedListOfPrices(pricelistnum?: string) {
		this.listOfPrices = [];
		if (!pricelistnum) {
			return;
		}
		const viewFields = ['pricelistno', 'pricelistname'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', ServerMethods.GET_ENTITY_DATA);
		formdata.append('entity', ServerEntity.PRICELISTS);
		// formdata.append('is_dropdown', '1');
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		// this._LoaderService.show(this.loaderConfig);
		this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: response => {
				// this._LoaderService.hide();
				this.listOfPrices = [];
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data;
					} else {
						this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				// this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doToolbarPreparing(event) {
		event.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel();
					}
				}
			}
		);
	}

	private doExportToExcel() {
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'CreditMemo_' + this.creditMemoDetails.ixcode + '.xlsx';
		worksheet = workbook.addWorksheet('CreditMemo');

		exportDataGrid({
			worksheet: worksheet,
			component: this.itemsListOriginDatagridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 8, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'itemname') {
						if (gridCell.data.itemname) {
							excelCell.value = gridCell.data.itemname;
						}
					}
					if (gridCell.column.dataField === 'pickstatus') {
						if (gridCell.data.pickstatus && gridCell.data.pickstatus.toString().toLowerCase() === 'tyes') {
							excelCell.value = 'Yes';
						} else {
							excelCell.value = 'No';
						}
					}
					if (gridCell.column.dataField === 'price') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
					if (gridCell.column.dataField === 'linetotal') {
						excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value);
					}
				}
			}
		}).then((cellRange) => {
			// header
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			if (this.creditMemoDetails.address) {
				worksheet.mergeCells(2, 4, 2, 7);
			}
			headerRow.getCell(1).value = 'CreditMemo ID:';
			headerRow.getCell(1).font = { 'bold': true };
			let orderIdValue = this.creditMemoDetails.docnum;
			headerRow.getCell(2).value = orderIdValue;
			headerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.creditMemoDetails.address) {
				headerRow.getCell(3).value = 'Billing Address';
				headerRow.getCell(3).font = { 'bold': true };
				headerRow.getCell(4).value = this.creditMemoDetails.address || '';
			}

			// customer
			const customerRow = worksheet.getRow(3);
			if (this.creditMemoDetails.address2) {
				worksheet.mergeCells(3, 4, 3, 7);
			}
			customerRow.height = 30;
			customerRow.getCell(1).value = 'Customer';
			customerRow.getCell(1).font = { 'bold': true };
			let cardCode = '';
			if (!this.isQBErpUser) {
				cardCode = this.creditMemoDetails.cardcode || '';
			}
			customerRow.getCell(2).value = (cardCode ? cardCode + ' - ' : '') + this.creditMemoDetails.cardname;
			customerRow.getCell(2).alignment = { horizontal: 'left' };
			if (this.creditMemoDetails.address2) {
				customerRow.getCell(3).value = 'Shipping Address';
				customerRow.getCell(3).font = { 'bold': true };
				customerRow.getCell(4).value = this.creditMemoDetails.address2 || '';
			}

			// DocDate
			const docDateRow = worksheet.getRow(4);
			docDateRow.height = 30;
			docDateRow.getCell(1).value = 'CreditMemo Date';
			docDateRow.getCell(1).font = { 'bold': true };
			docDateRow.getCell(2).value = this.creditMemoDetails.docdate;
			docDateRow.getCell(2).alignment = { horizontal: 'left' };

			// DocdueDate
			const docdueDateRow = worksheet.getRow(5);
			docdueDateRow.height = 30;
			docdueDateRow.getCell(1).value = 'CreditMemo Due Date';
			docdueDateRow.getCell(1).font = { 'bold': true };
			docdueDateRow.getCell(2).value = this.creditMemoDetails.docduedate;
			docdueDateRow.getCell(2).alignment = { horizontal: 'left' };

			// Order Status
			const documentStatusRow = worksheet.getRow(6);
			documentStatusRow.height = 30;
			documentStatusRow.getCell(1).value = 'CreditMemo Status';
			documentStatusRow.getCell(1).font = { 'bold': true };
			let orderStatus = '';
			if (this.creditMemoDetails.documentstatus.toString().toLowerCase() === 'bost_open') {
				orderStatus = 'Open';
			} else {
				orderStatus = 'Closed';
			}
			documentStatusRow.getCell(2).value = orderStatus;
			documentStatusRow.getCell(2).alignment = { horizontal: 'left' };
			let numberOfRows = this.itemsListOriginDatagridRef.instance.getVisibleRows().length;

			// Sub Total
			let rowIncrement = 11;
			let subTotal = 0;
			const subTotalRow = worksheet.getRow(numberOfRows + rowIncrement);
			subTotalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				subTotalRow.getCell(6).value = 'Sub Total';
				subTotalRow.getCell(6).font = { 'bold': true };
				subTotalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.doctotalsys);
				subTotalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				subTotalRow.getCell(5).value = 'Sub Total';
				subTotalRow.getCell(5).font = { 'bold': true };
				subTotalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.doctotalsys);
				subTotalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			if (this.creditMemoDetails.totaldiscount > 0) {
				// Discount
				let discount = 0;
				rowIncrement = rowIncrement + 1;
				const discountRow = worksheet.getRow(numberOfRows + rowIncrement);
				discountRow.height = 30;
				discountRow.getCell(6).value = 'Discount';
				discountRow.getCell(6).font = { 'bold': true };
				discountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.totaldiscount);
				discountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.creditMemoDetails.totalmiscamount > 0) {
				// Miscellaneous Charge
				let totalmiscamount = 0;
				rowIncrement = rowIncrement + 1;
				const totalmiscamountRow = worksheet.getRow(numberOfRows + rowIncrement);
				totalmiscamountRow.height = 30;
				totalmiscamountRow.getCell(6).value = 'Miscellaneous Charge';
				totalmiscamountRow.getCell(6).font = { 'bold': true };
				totalmiscamountRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.totalmiscamount);
				totalmiscamountRow.getCell(7).alignment = { horizontal: 'right' };
			}

			if (this.creditMemoDetails.shippingcharges > 0) {
				// Frieght Charge
				let shippingcharges = 0;
				rowIncrement = rowIncrement + 1;
				const shippingchargesRow = worksheet.getRow(numberOfRows + rowIncrement);
				shippingchargesRow.height = 30;
				shippingchargesRow.getCell(6).value = 'Frieght Charge';
				shippingchargesRow.getCell(6).font = { 'bold': true };
				shippingchargesRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.shippingcharges);
				shippingchargesRow.getCell(7).alignment = { horizontal: 'right' };
			}

			// Total
			let total = 0;
			rowIncrement = rowIncrement + 1;
			const totalRow = worksheet.getRow(numberOfRows + rowIncrement);
			totalRow.height = 30;
			if (this.isQBOnlineErpUser) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else if (this.isShowPickStatusColumn) {
				totalRow.getCell(6).value = 'Total';
				totalRow.getCell(6).font = { 'bold': true };
				totalRow.getCell(7).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.doctotal);
				totalRow.getCell(7).alignment = { horizontal: 'right' };
			} else {
				totalRow.getCell(5).value = 'Total';
				totalRow.getCell(5).font = { 'bold': true };
				totalRow.getCell(6).value = this._CustomCurrencyPipe.transform(this.creditMemoDetails.doctotal);
				totalRow.getCell(6).alignment = { horizontal: 'right' };
			}

			// Remark
			const remarkRow = worksheet.getRow(numberOfRows + 13);
			totalRow.height = 30;
			remarkRow.getCell(1).value = 'Remark';
			remarkRow.getCell(1).font = { 'bold': true };
			remarkRow.getCell(2).value = this.creditMemoDetails.comments || '';
			remarkRow.getCell(2).alignment = { horizontal: 'right' };

		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	public doRowPreparedDocLine(e: any) {
		if (e.rowType == "data" && (!e.data.costassignments || e.data.costassignments && e.data.costassignments.lenght <= 0)) {
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
			}
			if (e.rowElement.querySelector(".dx-command-expand")) {
				e.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
			}
		}
	}

	public doOriginalCreditMemoDetails() {
		const sourceDocNum = this.creditMemoDetails.source_docnum;

		const creditMemosFormData = new FormData();
		creditMemosFormData.append('usr', this._LoginService.loginUser.user);
		creditMemosFormData.append('token', this._LoginService.loginUser.token);
		creditMemosFormData.append('entity', ServerEntity.ARCREDITMEMO);
		creditMemosFormData.append('method', ServerMethods.GET_ENTITY_DATA);
		// creditMemosFormData.append('is_dropdown', 'true');
		creditMemosFormData.append('search', JSON.stringify([{ docnum: sourceDocNum }]));

		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._LoaderService.show(this.loaderConfig);
		this.isResponse = false;
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(creditMemosFormData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response && response.flag) {
					this.isShowOriginalCreditMemoDetails = true;
					if (response.data && response.data.length > 0) {
						const creditMemoDetails = response.data[0];
						this.originalCreditMemoDetails = creditMemoDetails;
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}

	private doCheckUseCustomersFrom() {
		let use_customers_from: string;
		try {
			use_customers_from = this._LoginService.loginUser.account_detail.use_customers_from;
		} catch (e) {
			use_customers_from = '';
		}
		this.isShowCardcodeColumn = false;
		if (use_customers_from === 'itemlist') {
			this.isShowCardcodeColumn = true;
			// It is displays Customer Code column in document line items.
		}
	}

	private doFetchListOfDistributionChannels(distributionChannelSearch?: any[]) {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		reqFormData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		reqFormData.append('is_dropdown', 'true');
		reqFormData.append('view_fields', JSON.stringify(['guid', 'code', 'description']));
		if (distributionChannelSearch && distributionChannelSearch.length > 0) {
			reqFormData.append('filter', JSON.stringify(distributionChannelSearch));
		}
		if (this.getListOfDistributionChannelsSbsn) {
			this.getListOfDistributionChannelsSbsn.unsubscribe();
		}

		this.getListOfDistributionChannelsSbsn = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: response => {
				this.listOfDistributionChannels = [];
				if (response && response.flag) {
					this.listOfDistributionChannels = response.data || [];
				}
			}, error: error => {
			}
		});
	}
}