import { MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Subscription, Observable } from 'rxjs';
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from "devextreme/excel_exporter";
import { ChargeDocumentsTabModel } from '@app/models/billback-chargeback-contract.model';
import { BcViewBillbackDocumentsDialogComponent } from '../bc-view-billback-documents-dialog/bc-view-billback-documents-dialog.component';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-bc-billback-documents-tab',
	templateUrl: './bc-billback-documents-tab.component.html',
	styleUrls: ['./bc-billback-documents-tab.component.css'],
})
export class BcBillbackDocumentsTabComponent implements OnInit, OnDestroy {
	@Input() itemTabDataList: any = [];
	@Input() billBackDocumentsTabDatalist: any = [];
	@ViewChild("billbackDocumentsTabDataGridContainer", {}) billbackDocumentsTabDataGridContainer: DxDataGridComponent;

	private getUomLstSbsn: Subscription;
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public billbackDocumentsTabModel = new ChargeDocumentsTabModel();
	public editBillbackGuid: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public documentFileData: any;
	public productSearchItemType: string;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public billbackDocumentsTabDataSource: any;
	public viewDocumentDataGrid: boolean = false;
	// Matrial design object
	private dialogConfig: MatDialogConfig = new MatDialogConfig();
	private dialogRef: MatDialogRef<any>;
	public pageOptions: PageOptions = new PageOptions();
	public guid = new Guid();
	public decimalPointForCurrency: number;
	public isShowBillbackDocumentPopup: boolean = false;
	public viewBillbackDocumentProps: any;
	constructor(
		public _MatDialog?: MatDialog,
		private _Router?: Router,
		private _ToastrService?: ToastrService,
		private _PermissionsService?: NgxPermissionsService,
		private _LoginService?: LoginService,
		private _LoaderService?: LoaderService,
		private _RestApiService?: RestApiService,
		public _AppCommonSrvc?: AppCommonSrvc,
		public _BillbackService?: BillbackService,
		private _BillbackContractService?: BillbackContractService,
		private _CustomCurrencyPipe?: CustomCurrencyPipe,
	) { }

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.editBillbackGuid = this._BillbackContractService.getEditBillbackGuid();
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}
		// product_search logic type (item/price)
		this.productSearchItemType = this._BillbackService.getProductSearchType();
		this.editBillbackGuid = this._BillbackContractService.getEditBillbackGuid();
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
	}

	preInit() {
		if (this.billBackDocumentsTabDatalist && this.billBackDocumentsTabDatalist.length > 0) {
			this.billbackDocumentsTabDataSource = this.billBackDocumentsTabDatalist;
		}
		this.getBillBackDocumentTabData();
	}

	getBillBackDocumentTabData() {
		if (this.editBillbackGuid) {
			this._LoaderService.show();
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('contractguid', this.editBillbackGuid);
			formData.append('method', 'getBillbackDocuments');
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
				next: (response) => {
					this.viewDocumentDataGrid = true;
					if (response.flag) {
						this.billBackDocumentsTabDatalist = JSON.parse(JSON.stringify(response.data));

						if (this.billBackDocumentsTabDatalist.length > 0) {
							this.billbackDocumentsTabDataSource = this.billBackDocumentsTabDatalist
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
				}, error: (error) => {
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			});
		}
	}

	// View billback document dialog
	viewBillBackDocumentDetails(billBackDocumentDetails) {
		this.viewBillbackDocumentProps = {
			billBackDocumentDetails: JSON.parse(JSON.stringify(billBackDocumentDetails)),
			buttonText: {
				cancel: 'Ok'
			}
		}
		this.isShowBillbackDocumentPopup = false;
		setTimeout(() => {
			this.isShowBillbackDocumentPopup = true;
		}, 20);
	}

	downloadBillBackDocument(billBackDocumentDetails) {
		const documentURL = billBackDocumentDetails.documentURL;
		const documentNewURLArr = documentURL.split('-');
		if (documentNewURLArr && documentNewURLArr.length > 0) {
			const docId = documentNewURLArr[0];
			const docDate = documentNewURLArr[1];
			const docCode = documentNewURLArr[2];
			const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=billbackDoc';
			window.location.href = finalUrl;
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'refresh',
					text: "Refresh",
					onClick: (event: any) => {
						this.refreshDataGridComponent();
					}
				}
			}
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}

	public refreshDataGridComponent() {
		this.billbackDocumentsTabDataGridContainer.instance.refresh();
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.billbackDocumentsTabDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Billback_Documnets.xlsx';
		worksheet = workbook.addWorksheet('Documents');

		exportDataGrid({
			worksheet: worksheet,
			component: this.billbackDocumentsTabDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'documentTotal') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doCloseViewBillbackDocumentPopup(e) {
		this.isShowBillbackDocumentPopup = false;
	}
}

