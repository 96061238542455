import { environment } from '@environments/environment';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_XLSXFILEPOPUPIX, MSG_ERROR_MESSAGE, PAGE_SIZE_OPTIONS, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { ErpTypeValEnum, NameFromServerKeyEnum } from '@app/constants-enums/enums';
import { CommissionContractModelUI, ExclusionTabModel, ExclusionTierbaseTabModel } from '@app/models/commission-contract.model';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { DataService } from '@app/services/data.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
//import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Guid } from '@app/models/guid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { TerritoryDxDropDownBoxComponent } from 'app/input-components/territory-dx-drop-down-box/territory-dx-drop-down-box.component';
import { TerritoryDxDropDownBoxModel } from '@app/models/input-Components.model';
import * as _ from 'underscore';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyOptions } from '@app/models/common.model';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-commission-tierbase-template-exclusions-tab',
	templateUrl: './commission-tierbase-template-exclusions-tab.component.html',
	styleUrls: ['./commission-tierbase-template-exclusions-tab.component.css'],
	providers: [
		CustomCurrencyPipe,
		CustomNumberNoRoundingPipe
	]
})
export class CommissionTierbaseTemplateExclusionsTabComponent implements OnInit, OnChanges, OnDestroy {
	public guid = new Guid();
	@Input() exclusionTabList: any = [];
	@Input() commissionDetailsProps: any;
	@Output() saveCommissionExclusionEvent = new EventEmitter<string>();
	@ViewChild("userListDetailssDataGridRef", {}) userListDetailssDataGridRef: DxDataGridComponent;
	@ViewChild("exclusionTabDataGridRef", {}) exclusionTabDataGridRef: DxDataGridComponent;
	@ViewChild("tiersItemDetailssDataGridRef", {}) tiersItemDetailssDataGridRef: DxDataGridComponent;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'Inactive' }];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public productGroupRadioButton: string;
	public erp_type: string;
	public editCommissionGuid: string;
	public getUomLstSbsn: Subscription;
	public updateUserSettingSbsn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public searchTimeout: any;
	public isCurrentTabPermission: string;
	public isDisablePermission: boolean = false;
	public exclusionsGroupRadioButton: string;
	public GroupsList: any = [];
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public selectedCustomerData: any = [];
	public selectedCustomerGroupData: any = [];
	public selectedProductData: any = [];
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;
	public isAllProductSelectedView: boolean = false;
	public exclusionTabModel = new ExclusionTabModel();
	public dataSourceExclusionTab: any;
	public listOfCommissionBasis: any[] = [];
	public listOfTierBasis: any[] = [];
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public customerOrGroupTab: string = 'customers';
	// public strSelectedItemList: string;
	// public strSelectedCustomerList: string;
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("customerGroupDataGrid", {}) customerGroupDataGrid: DxDataGridComponent;
	@ViewChild("customercustomerGroupDataGrid", {}) customercustomerGroupDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	public chkAllCustomer: boolean = false;
	public chkAllCustomrGroup: boolean = false;
	public chkAssignedCustomer: boolean = false;
	public chkByTransaction: boolean = false;
	public chkSalesTeamTransaction: boolean = false;
	public chkAllItem: boolean = false;
	public chkMyCustomers: boolean = false;
	public lengthSelectedCustomersAndGroups: any;
	public lengthSelectedCustomers: any;
	public lengthSelectedGroups: any;
	public lengthSelectedItems: any;
	public arrSelectedCustomerList: any = [];
	public arrSelectedItemList: any = [];
	public itemDataSource: any;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public itemGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public itemGridBoxColumns: any;
	public groupDataSource: any;
	public customerGroupGridBoxValue: any[] = [];
	public customerGroupGridBoxColumns: any;
	public arrSelectedCustomerGroupList: any = [];
	public filterText: string = '';
	public decimalPointPercentageFormat: string = '1.0-0';
	public productSearchItemType: string;
	public isCustomerAgeTiers: boolean = false;
	public is_use_territory_for_commission_contract: boolean;
	public enable_classification_for_commision: boolean = false;
	public enable_invoice_custom_fields_feature: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	@ViewChild(TerritoryDxDropDownBoxComponent) childTerritoryComponent: TerritoryDxDropDownBoxComponent;
	public territoryModel: any = new TerritoryDxDropDownBoxModel();
	public selectedTerritoryData: any = [];
	public chkAllClasses: boolean = false;
	public arrSelectedClassesList: any = [];
	public classesGridBoxValue: any = [];
	public classesDataSource: any;
	public lengthSelectedClasses: any;
	public classesGridBoxColumns: any;
	private isNetsuiteErpUser: boolean = false;
	@ViewChild("classesDataGrid", {}) classesDataGrid: DxDataGridComponent;
	public isShowSkuColumn: boolean;
	public isShowItemColumn: boolean;
	public isShowClassesColumn: boolean;
	public isShowDescriptionColumn: boolean;
	public isShowUomColumn: boolean;
	public isViewExlusionTabGrid: boolean = false; // use for Initialized dynamic custom column for Netsuite
	private listOfFeatureCustomFields: any = [];
	public selectedClassesData: any = [];
	private getFeatureCustomFieldsLstSbn: Subscription;
	public pageSizeDropDownDatagrid = PAGE_SIZE_OPTIONS[PAGE_SIZE_OPTIONS.length - 1];
	public commissionDetails: any = new CommissionContractModelUI();
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public globalCurrencySymbol: string;
	public listOfActions: any[] = [
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		},
	];
	public stateStorageKey: string;
	private mainSectionStorageKey: string;
	private filterValueChangeSbsn: Observable<any>;
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _PermissionsService: NgxPermissionsService,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		//private _LoaderService: LoaderService,
		private _CommissionService: CommissionService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe) {

		this.doDisplayExprCustomersText = this.doDisplayExprCustomersText.bind(this);
		this.doDisplayExprItemsText = this.doDisplayExprItemsText.bind(this);
		this.doDisplayExprClassesText = this.doDisplayExprClassesText.bind(this);
		this.doCustomizeTextTierMinMax = this.doCustomizeTextTierMinMax.bind(this);
		this.exclusionGridCustomizeColumns = this.exclusionGridCustomizeColumns.bind(this);
		this.isValidRateCallback = this.isValidRateCallback.bind(this);
		this.doReorderRows = this.doReorderRows.bind(this);
		this.doLoadCommExclusionsAgreeState = this.doLoadCommExclusionsAgreeState.bind(this);
		this.doSaveCommExclusionsAgreeState = this.doSaveCommExclusionsAgreeState.bind(this);
	}

	ngOnInit() {
		this.mainSectionStorageKey = ServerSections.INCENTIVES_COMMISSION_TEMPLATE;
		this.stateStorageKey = ServerSections.INCENTIVES_COMMISSION_TEMPLATE_EXCLUSIONS;
		this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			this.dataSourceExclusionTab = this.exclusionTabList;
		}
		if (this.commissionDetailsProps && this.commissionDetailsProps.currency_code) {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetailsProps);
		} else {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption();
		}
		// get current user Erp type
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		// check Is QB User (no need to view code of QB user)

		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erp_type === ErpTypeValEnum.QUICKBOOKS) {
			this.isQBErpUser = true;
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
			this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
		// this.customerGroupGridBoxColumns = [
		// 	{ dataField: 'guid', caption: 'Guid', visible: false },
		// 	{ dataField: 'group_name', caption: 'Name' },
		// 	{ dataField: 'user_list', caption: 'List', groupIndex: "0" },
		// ];

		try {
			this.is_use_territory_for_commission_contract = this._LoginService.loginUser.account_detail.app_settings.is_use_territory_for_commission_contract || false;
			this.isCustomerAgeTiers = this._LoginService.loginUser.account_detail.app_settings.customer_age_tiers || false;
			this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision || false;
			this.enable_invoice_custom_fields_feature = this._LoginService.loginUser.account_detail.enable_invoice_custom_fields_feature || false;
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;
		} catch (e) {
			this.is_use_territory_for_commission_contract = false;
			this.isCustomerAgeTiers = false;
			this.enable_classification_for_commision = false;
			this.enable_invoice_custom_fields_feature = false;
			this.enable_profit_on_document_header = false;
		}

		if (this.enable_classification_for_commision) {
			this.isNetsuiteErpUser = true;
			this.classesGridBoxColumns = [
				{ dataField: 'guid', caption: 'Guid', visible: false },
				{ dataField: 'id', caption: 'Id', visible: false },
				{ dataField: 'name', caption: 'Classes' }
			];
			// this.customFieldBoxColumns = [
			// 	'clientname', 'itemsku', 'itemname', 'classname', 'description', 'tier_basis', 'commission_basis', 'selectedUom', 'rate'
			// ];
		}
		// Check Edit Mode
		const editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		this.getAllPermissions();
		this.doHideShowDatagridColumns();
		this.editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		this.GroupsList = this._DataService.getGroups();
		this.clearExclusionAgreementTabDetails();
		if (this.enable_classification_for_commision) {
			this.listOfTierBasis = this._CommissionService.getTierBasisForClasses();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasisForClasses();
		} else if (this.enable_profit_on_document_header) {
			this.listOfTierBasis = this._CommissionService.getTierBasisForProfitOnHeader();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasisForProfitOnHeader();
		} else {
			this.listOfTierBasis = this._CommissionService.getTierBasis();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasis(this.globalCurrencySymbol);
		}

		if (this.enable_invoice_custom_fields_feature || this.enable_profit_on_document_header) {
			this.doGetFeatureCustomFields();
		} else {
			this.isViewExlusionTabGrid = true;
		}

		this.getCustomerData();
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadCustomerDataList();
		this.loadCustomerGroupDataList();
		if (this.enable_classification_for_commision) {
			this.loadClassesDataList();
		} else {
			this.loadItemDataList();
		}

		setTimeout(() => {
			if (this.exclusionTabDataGridRef) {
				this.filterValueChangeSbsn = this.exclusionTabDataGridRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterText();
				});
			}
		}, 120);
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['commissionDetailsProps']) {
			const commissionDetailsProps = _SimpleChanges['commissionDetailsProps'].currentValue;
			this.commissionDetails = commissionDetailsProps;
		}
		if (_SimpleChanges['exclusionTabList']) {
			const exclusionTabListProps = _SimpleChanges['exclusionTabList'].currentValue;
			setTimeout(() => {
				const customFieldDataList = this._CommissionService.getCustomFieldForInvoice(this.listOfFeatureCustomFields);
				if (exclusionTabListProps && exclusionTabListProps.length > 0 && customFieldDataList && customFieldDataList.length > 0) {
					exclusionTabListProps.forEach(exclusion => {
						customFieldDataList.forEach(customField => {
							if (!exclusion.hasOwnProperty(customField.dataField) && customField.display) {
								exclusion[customField.dataField] = null;
							}
						});
					});
				}
				this.dataSourceExclusionTab = exclusionTabListProps || [];
			}, 150);
		}
	}

	ngOnDestroy(): void {

		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	private getAllPermissions() {
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}
		this.isDisablePermission = false;
		if (permsn.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_COMMISSION_MODIFY';
		}
		// End current permissions
	}

	private doHideShowDatagridColumns() {
		this.isShowSkuColumn = false;
		if (this.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isShowSkuColumn = true;
		}

		this.isShowItemColumn = true;
		this.isShowClassesColumn = false;
		this.isShowDescriptionColumn = true;
		this.isShowUomColumn = true;
		if (this.enable_classification_for_commision) {
			this.isShowItemColumn = false;
			this.isShowClassesColumn = true;
			this.isShowDescriptionColumn = false;
			this.isShowUomColumn = false;
		}
	}

	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		//this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				//this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				//this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOMGROUPS);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		//this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				//	this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				//	this._LoaderService.hide();
			}
		});
	}

	// #endregion

	//#region for Customer select box
	private loadCustomerDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS,
			// is_dropdown: true,
			// view_fields: JSON.stringify(viewFields)
		};
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "name",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}
	public onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.arrSelectedCustomerGroupList = [];
			this.customerGridBoxValue = [];
			if (this.customerDataGrid) {
				this.customerDataGrid.instance.refresh();
			}
			this.chkMyCustomers = false;
			this.chkAllCustomer = false;
			this.chkAssignedCustomer = false;
			this.chkByTransaction = false;
			this.chkSalesTeamTransaction = false;
			this.chkAllCustomrGroup = false;
			this.lengthSelectedCustomers = '';
			this.lengthSelectedCustomersAndGroups = '';
		}
	}

	public doDisplayExprCustomersText(event: any) {
		let customersText = '';
		let listOfTexts = [];
		if (this.customerOrGroupTab == 'customers') {
			listOfTexts = [];
			if (this.chkAllCustomer) {
				listOfTexts.push('All Customers');
			}
			if (this.chkAssignedCustomer) {
				listOfTexts.push('Assigned Customer(s)');
			}
			if (this.chkByTransaction) {
				listOfTexts.push('By Transaction');
			}
			if (this.chkSalesTeamTransaction) {
				listOfTexts.push('Sales Team Transaction');
			}
			if (this.arrSelectedCustomerList.length > 0) {
				listOfTexts.push(this.arrSelectedCustomerList.length + ' Customer(s)');
			}
		}
		if (this.customerOrGroupTab === 'groups') {
			listOfTexts = [];
			if (this.chkAllCustomrGroup) {
				listOfTexts.push('All Groups');
			}
			if (this.arrSelectedCustomerGroupList.length > 0) {
				listOfTexts.push(this.arrSelectedCustomerGroupList.length + ' Groups');
			}
		}
		customersText = listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
		return customersText;
	}

	public checkAllCustomer() {
		if (this.chkAllCustomer) {
			this.chkMyCustomers = false;
		}
		this.setCustomersAndGroupsText();
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }
	}
	public checkAssignedCustomer() {
		if (this.chkAssignedCustomer) {
			this.chkByTransaction = false;
		}
		this.setCustomersAndGroupsText();
	}
	public checkByTransaction() {
		if (this.chkByTransaction) {
			this.chkAssignedCustomer = false;
		}
		this.setCustomersAndGroupsText();
	}
	public checkSalesTeantransaction() {
		// if (this.chkSalesTeamTransaction) {
		// 	this.chkByTransaction = false;
		// 	this.chkAssignedCustomer = false;
		// }
		this.setCustomersAndGroupsText();
	}
	public onCustomerSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedCustomers = e.selectedRowsData.length + ' Customer(s) Selected';
			this.customerGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomer) {
			this.lengthSelectedCustomers = '';
		}
		this.setCustomersAndGroupsText();
	}

	//#endregion

	//#region for Item|Product select box
	private loadItemDataList() {
		// const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ITEMS,
			// view_fields: JSON.stringify(viewFields)
		};
		this.itemDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "itemname",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}
	public onItemValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedItemList = [];
			this.itemGridBoxValue = [];
			this.chkAllItem = false;
			this.lengthSelectedItems = '';
		}
	}

	public doDisplayExprItemsText(event: any) {
		let listOfTexts: any[] = [];
		if (this.chkAllItem) {
			listOfTexts.push('All Items');
		}
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			listOfTexts.push(this.arrSelectedItemList.length + ' Item(s)');
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}

	public checkAllItem() {
		// if (this.chkAllItem) {
		// 	this.lengthSelectedItems = 'All Items Selected';
		// 	this.arrSelectedItemList = [];
		// 	this.itemDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedItems = '';
		// }
		// if (this.chkAllItem) {
		this.setItemSelctedText();
	}
	public onItemSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.itemGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].erp_primary_key);
			};
		}
		this.setItemSelctedText();
	}

	private setItemSelctedText() {
		let lengthSelectedItems = '';
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			lengthSelectedItems = this.arrSelectedItemList.length + ' Item(s) Selected';
		}

		if (this.chkAllItem) {
			lengthSelectedItems = 'All Item ' + (lengthSelectedItems ? ', ' : '') + (lengthSelectedItems || '');
		}
		this.lengthSelectedItems = lengthSelectedItems;
	}
	//#endregion

	// #region for Classes select box |  only show  while ERP type NETSUITE

	private loadClassesDataList() {
		// const viewFields = ['erp_primary_key', 'classesku', 'classname', 'description'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.IX_CLASSIFICATIONS,
			// view_fields: JSON.stringify(viewFields)
		};

		this.classesDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "name",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	public doDisplayExprClassesText(event: any) {
		let listOfText: any[] = [];
		if (this.chkAllClasses) {
			listOfText.push('All Classes');
		}
		if (this.arrSelectedClassesList.length > 0) {
			listOfText.push(this.arrSelectedClassesList.length + ' Classes');
		}
		return listOfText.length > 0 ? listOfText.join(', ') : '';
	}

	public onClassesValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedClassesList = [];
			this.classesGridBoxValue = [];
			this.classesDataGrid.instance.refresh();
			this.chkAllClasses = false;
			this.lengthSelectedClasses = '';
		}
	}
	public checkAllClasses() {
		// if (this.chkAllClasses) {
		// 	this.lengthSelectedClasses = 'All Classes Selected';
		// 	this.arrSelectedClassesList = [];
		// 	this.classesDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedClasses = '';
		// }
		// if (this.chkAllClasses) {
		this.setClassesSelctedText();
	}
	public onClassesSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllClasses = false;
			//this.lengthSelectedClasses = e.selectedRowsData.length + ' Classes Selected';
			this.classesGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.classesGridBoxValue.push(e.selectedRowsData[i].id);
			};
		}
		// if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllClasses) {
		// 	this.lengthSelectedClasses = '';
		// }
		this.setClassesSelctedText();
	}
	private setClassesSelctedText() {
		let lengthSelectedClasses = '';
		this.lengthSelectedClasses = '';
		if (this.arrSelectedClassesList && this.arrSelectedClassesList.length > 0) {
			lengthSelectedClasses = this.arrSelectedClassesList.length + ' Classes Selected';
		}

		if (this.chkAllClasses) {
			lengthSelectedClasses = 'All Classes ' + (lengthSelectedClasses ? ', ' : '') + (lengthSelectedClasses || '');
		}
		this.lengthSelectedClasses = lengthSelectedClasses;
	}
	//#endregion

	// #region customer Groups select box

	public clickCustomersOrGroupTab(event, customerOrGroupTab: string) {
		this.customerOrGroupTab = customerOrGroupTab;
		if (this.customerDataGrid) {
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}
	}

	public checkMyCustomers() {
		if (this.chkMyCustomers) {
			this.chkAllCustomer = false;
		}
		this.setCustomersAndGroupsText();
	}

	private loadCustomerGroupDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.USERGROUPS,
			// is_dropdown: true,
			// view_fields: JSON.stringify(viewFields)
		};

		this.groupDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: "group_name",
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	private checkAllCustomerGroup() {
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }
	}

	public onCustomerGroupSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.chkAllCustomer = false;
			this.lengthSelectedGroups = e.selectedRowsData.length + ' Group(s) Selected';
			this.customerGroupGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGroupGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomrGroup) {
			this.lengthSelectedGroups = '';
		}
		this.setCustomersAndGroupsText();
	}

	private setCustomersAndGroupsText() {
		let lengthSelectedCustomersAndGroups: string = '';
		if (this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers;
		}
		if (this.lengthSelectedGroups) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedGroups;
		}
		if (this.lengthSelectedGroups && this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers + ' And ' + this.lengthSelectedGroups;
		}

		if (this.chkAllCustomer) {
			lengthSelectedCustomersAndGroups = 'All Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkAssignedCustomer) {
			lengthSelectedCustomersAndGroups = 'Assigned Customer(s) ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkByTransaction) {
			lengthSelectedCustomersAndGroups = 'By Transactions ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkSalesTeamTransaction) {
			lengthSelectedCustomersAndGroups = 'Sales Team Transactions ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkMyCustomers) {
			lengthSelectedCustomersAndGroups = 'My Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkAllCustomrGroup) {
			lengthSelectedCustomersAndGroups = 'All Group ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}
		this.lengthSelectedCustomersAndGroups = lengthSelectedCustomersAndGroups;
	}

	public checkAllGroup() {
		this.setCustomersAndGroupsText();
	}

	// #region for Items And Groups Datagrid Methods
	public getUserListByGroupDetails(element) {
		return element.data.user_detail_list || [];
	}
	//#endregion

	public clickExclusionsGroupRadioButton(tab) {
		this.selectProductSearchTabData = [];
		this.isProductSearchTabAllSelected = false;
		this.exclusionTabModel.productname = '';
		if (tab === 'addgroupexclusion') {
			this.selectProductSearchTabData = JSON.parse(JSON.stringify(this.GroupsList));
		}
		this.selectProductSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	public clearExclusionAgreementTabDetails() {
		this.exclusionsGroupRadioButton = 'addproductexclusion';
		this.arrSelectedCustomerList = [];
		this.arrSelectedClassesList = [];
		this.arrSelectedCustomerGroupList = [];
		this.lengthSelectedCustomersAndGroups = '';
		this.lengthSelectedCustomers = '';
		this.lengthSelectedGroups = '';
		this.lengthSelectedItems = '';
		this.lengthSelectedClasses = '';
		this.customerGridBoxValue = [];
		this.chkAllCustomer = false;
		this.chkAssignedCustomer = false;
		this.chkByTransaction = false;
		this.chkSalesTeamTransaction = false;
		if (this.customerDataGrid) {
			this.customerDataGrid.instance.refresh();
			this.customerDataGrid.instance.clearFilter();
		}
		if (this.customerGroupDataGrid) {
			this.customerGroupDataGrid.instance.refresh();
			this.customerGroupDataGrid.instance.clearFilter();
		}
		// if (this.customerDropDown) {
		// 	this.customerDropDown.instance.reset();
		// }
		this.arrSelectedItemList = [];
		this.itemGridBoxValue = [];
		this.chkAllItem = false;
		this.chkAllClasses = false;
		if (this.itemDataGrid) {
			this.itemDataGrid.instance.refresh();
			this.itemDataGrid.instance.clearFilter();
		}
		if (this.classesDataGrid) {
			this.classesDataGrid.instance.refresh();
			this.classesDataGrid.instance.clearFilter();
		}
		this.chkAllCustomrGroup = false;
		this.chkMyCustomers = false;
		if (this.childTerritoryComponent) {
			this.childTerritoryComponent.clearTerritorySelectList();
		}
	}

	public exclusionGridCustomizeColumns(columns, DataType) {
		if (this.enable_invoice_custom_fields_feature) {
			if (columns && columns.length > 0) {
				const actionColIndex = columns.findIndex(i => i.caption === 'Action');
				if (actionColIndex !== -1) {
					columns[actionColIndex].visibleIndex = columns.length; // action column set on last index in grid-data
				}
			}
		}
	}

	public doOnRowValidatingExclusionTab(event) {
		if (event['newData']) {
			let itemObject = JSON.parse(JSON.stringify(event['oldData']));
			let dataFiledsList = Object.keys(event['newData']);
			dataFiledsList.forEach(element => {
				itemObject[element] = event['newData'][element];
			});
			// this.exclusionTabListClone = JSON.parse(JSON.stringify(this.exclusionTabList));
			// const indexOfRow = this.exclusionTabListClone.findIndex(i => i.guid === event.key);
			// this.exclusionTabListClone[indexOfRow] = event['newData'];
			if (this.erp_type === ErpTypeValEnum.NETSUITE) {
				if (this.checkExclusionListDuplicateRecords(itemObject)) {
					this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists', 'Info', { closeButton: true, tapToDismiss: true });
					const indexOfOldRow = this.exclusionTabList.findIndex(i => i.guid === event.key);
					if (indexOfOldRow !== -1) {
						dataFiledsList.forEach(element => {
							event['newData'][element] = this.exclusionTabList[indexOfOldRow][element];
						});
					}
				} else {
					this.calSaveCommissionContract();
				}
			} else {
				if (this.commissionDetails.tiers_type === 'multiple_items') {
					if (this.checkExclusionListDuplicateRecords(itemObject)) {
						this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists', 'Info', { closeButton: true, tapToDismiss: true });
						const indexOfOldRow = this.exclusionTabList.findIndex(i => i.guid === event.key);
						if (indexOfOldRow !== -1) {
							dataFiledsList.forEach(element => {
								event['newData'][element] = this.exclusionTabList[indexOfOldRow][element];
							});
						}
					} else {
						this.calSaveCommissionContract();
					}
				} else {
					this.calSaveCommissionContract();
				}
			}
		}
	}

	public initializedInvoiceDataGrid(event) {
		setTimeout(() => {
			if (this.enable_invoice_custom_fields_feature || this.enable_profit_on_document_header) {
				const tableColLen = event && event.component ? event.component.getVisibleColumns().length : 0;
				const listOfColumns = event && event.component ? event.component.getVisibleColumns() : [];
				const customFieldDataList = this._CommissionService.getCustomFieldForInvoice(this.listOfFeatureCustomFields, tableColLen, this.customCurrencyOption);
				if (event.component.columnCount() > 0) {
					const listOfColumns = this.exclusionTabDataGridRef.instance.getVisibleColumns();
					customFieldDataList.forEach(element => {
						const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
						if (columnIndex === -1) {
							this.exclusionTabDataGridRef.instance.addColumn(element);
						}
					});
				}
			}
		}, 500);
	}

	public doReorderRows = (e: DxDataGridTypes.RowDraggingReorderEvent) => {
		e.promise = this.processReorder(e);
	};

	async processReorder(e) {
		const visibleRows = e.component.getVisibleRows();
		const fromIndex = this.dataSourceExclusionTab.findIndex((item) => item.rownum === e.itemData.rownum);
		const toIndex = this.dataSourceExclusionTab.findIndex((item) => item.rownum === visibleRows[e.toIndex].data.rownum);
		this.dataSourceExclusionTab.splice(fromIndex, 1);
		this.dataSourceExclusionTab.splice(toIndex, 0, e.itemData);
		await this.doSequenceUpdate(e);
		//await e.component.refresh();
	}

	public doSequenceUpdate(event: any) {
		this.exclusionTabList.forEach((i, index) => {
			i.rownum = index + 1;
		});
		this.calSaveCommissionContract();
	}

	public calSaveCommissionContract() {
		this.saveCommissionExclusionEvent.emit('save');
	}

	public doOnEditorPreparingExclusionTab(event) {
		// if (event && event['row'] && event['row']['data']) {
		// 	return true;
		// } else {
		// 	return false;
		// }
		if (event && event['row']) {
			const itemObject = event.row.data || [];
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (itemObject.commission_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = itemObject.uomlist;
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			if (event.dataField === 'tier_basis') {
				let listOfTierBasis = this.listOfTierBasis;
				event.editorOptions.dataSource = listOfTierBasis;
			}

			if (event.dataField === 'commission_basis') {
				let listOfCommissionBasis = this.listOfCommissionBasis;
				if (itemObject.allproducts) {
					listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}

				if (itemObject['itemType']) {
					if (itemObject['itemType'] === 'OtherCharge' || itemObject['itemType'] === 'Service') {
						listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
					}
				}
				event.editorOptions.dataSource = listOfCommissionBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.visible = false;
				if (itemObject.allproducts) {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.visible = true;
					}
				} else {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.visible = true;
					}
				}
			}
		}
	}

	public doOnRowUpdatedExclusionTab(event) {
		if (event.data.commission_basis === 'percentage' || event.data.commission_basis === 'dollar') {
			event.data.selectedUom = '';
		}
		if (event.data.tier_basis === 'notiers') {
			event.data.tiers = [];
		}
		if (event.data.tier_basis !== 'notiers') {
			event.data.rate = null;
		}
	}

	public doOnRowRemovedExclusionTab(event) {
		this.exclusionTabList.forEach((i, index) => {
			i.rownum = index + 1;
		});
		this.calSaveCommissionContract();
	}

	public doOnonRowPreparedExclusionTab(event) {
		if (event.rowType === "data" && event.data.tier_basis === 'notiers') {
			if (event.rowElement.querySelector(".dx-command-expand") && event.rowElement.querySelector(".dx-command-expand").firstChild && event.rowElement.querySelector(".dx-command-expand").firstChild.classList) {
				event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
				event.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
				const isExpanded = event.component.isRowExpanded(event.key);
				setTimeout(() => {
					if (isExpanded) {
						event.component.collapseRow(event.key);
					}
				}, 20);
			}
		}
	}

	public doOnInitNewRowTiersItmTab(event, element) {
		const itemObject = element.data;
		event.data = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null
		};
		if (this.commissionDetails.tiers_type === 'multiple_items') {
			if (!this.enable_classification_for_commision) {
				event.data.items = itemObject.items.map((item) => {
					let listOfSpecificUoms = [];
					if (!item.allproducts) {
						const priceListNum = itemObject.pricelistnum || '';
						listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);
					}
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					return {
						allproducts: item.allproducts,
						allcustomergroups: item.allcustomergroups,
						mycustomers: item.mycustomers,
						erp_primary_key: item.erp_primary_key,
						itemType: item.itemType,
						itemname: item.itemname,
						itemsku: item.itemsku,
						description: item.description,
						selectedUom: !item.allproducts && itemObject.commission_basis === 'dollarperuom' ? defaultSelectedUom : '',
						rate: null,
						listPrice: +defaultListPrice.toFixed(2),
						commissionfinalprice: defaultListPrice,
					}
				});
			}
			if (this.enable_classification_for_commision) {
				event.data.items = itemObject.items.map((item) => {
					let defaultListPrice = 0;
					return {
						erp_primary_key: item.erp_primary_key,
						mycustomers: item.mycustomers,
						allclasses: item.allclasses,
						classguid: item.classguid || '',
						classid: item.classid || '',
						classname: item.classname || '',
						rate: null,
						listPrice: +defaultListPrice.toFixed(2),
						commissionfinalprice: defaultListPrice,
					}
				});
			}
		}
		event.data.guid = this.guid.newGuid();
		if (itemObject.tiers && itemObject.tiers.length > 0) {
			// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ? parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1 : parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
			if (itemObject.tier_basis === 'volume' || itemObject.tier_basis === 'revenue') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = mintier;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = mintier;
				}
			}
			// event.data.maxtier = '-';
		} else {
			if (itemObject.tier_basis === 'volume') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = 1;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = 0.01;
				}
			}
		}
	}

	public doOnRowInsertedTiersItmTab(event, element) {
		// const itemObject = event.row.data;
		// this.saveCommissionExclusionEvent.emit('save');
	}

	public doOnEditorPreparingTiersItmTab(event, element) {
		if (event && event['row']) {
			const itemObject = event.row.data;
			if (event.dataField === 'rate') {
				event.editorOptions.visible = false;
				if (this.commissionDetails.tiers_type === 'single_items') {
					if (element.data.tier_basis !== 'notiers') {
						event.editorOptions.visible = true;
					}
				}
			}
		}
	}

	public doOnRowUpdatedTiersItmTab(event, element) {
	}

	public doOnRowRemovedTiersItmTab(event, element) {
		this.calSaveCommissionContract();
	}

	public doOnSavedTiersItmTab(event, element) {
		this.doConvertDecimalPointByConfiguration();
		if (this.tiersItemDetailssDataGridRef) {
			this.tiersItemDetailssDataGridRef.instance.refresh();
		}
		this.calSaveCommissionContract();
	}

	public isValidRateCallback(params: any): boolean {
		var isValid = true;
		if (this.commissionDetails.tiers_type === 'single_items') {
			if (!params.value || (params.value && !params.value.toString().trim())) {
				isValid = false;
			}
		}
		return isValid;
	}

	public doOnSavedExclusionTab() {
		this.doConvertDecimalPointByConfiguration();
		if (this.exclusionTabDataGridRef) {
			this.exclusionTabDataGridRef.instance.refresh();
		}
		if (this.tiersItemDetailssDataGridRef) {
			this.tiersItemDetailssDataGridRef.instance.refresh();
		}
		// this.saveCommissionExclusionEvent.emit('save');
	}

	public getTiersByItemDetails(element) {
		return element.data.tiers || [];
	}

	public getCustomerAgeTiersByItemDetails(element) {
		return element.data || [];
	}

	public setCellValueTierBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueCommissionBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueUomColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueRateColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public doCustomizeTextTierMinMax(cellInfo: any, parentData: any, which_tier: string) {
		if (which_tier === 'maxtier') {
			if (!cellInfo) {
				return cellInfo;
			} else if (cellInfo === '-') {
				return cellInfo;
			} else {
				if (parentData.data.tier_basis === 'volume') {
					return cellInfo || '0';
				} else if (parentData.data.tier_basis === 'revenue') {
					return this._CommissionService.convertCurrency((+cellInfo || 0), this.customCurrencyOption);
				} else {
					return cellInfo;
				}
			}
		}
		if (which_tier === 'mintier') {
			if (parentData.data.tier_basis === 'volume') {
				return cellInfo || 0;
			} else if (parentData.data.tier_basis === 'revenue') {
				return this._CommissionService.convertCurrency((+cellInfo || 0), this.customCurrencyOption)
			} else {
				return cellInfo;
			}
		}
	}

	public addExclusionAgreementTabDetails() {
		this.selectedProductData = [];
		if (this.itemDataGrid) {
			this.selectedProductData = [];
			this.selectedProductData = this.itemDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerDataGrid) {
			this.selectedCustomerData = [];
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = [];
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}
		//Territory drop-down-list
		if (this.childTerritoryComponent && this.childTerritoryComponent.territoryDataGrid) {
			this.selectedTerritoryData = this.childTerritoryComponent.territoryDataGrid.instance.getSelectedRowsData();
		}
		if (this.territoryModel.chkAllTerritory) {
			this.allTerritoryChecked();
		}
		//End Territory drop-down-list
		if (this.classesDataSource) {
			this.selectedClassesData = [];
			this.selectedClassesData = this.classesDataGrid.instance.getSelectedRowsData();
		}
		// const isExistItemIndex = this.exclusionTabList.findIndex(itemEle => itemEle.clientcode === this.exclusionTabModel.clientcode && itemEle.itemcode === this.exclusionTabModel.clientcode);
		if (this.chkAllClasses) {
			this.allClassesChecked();
		}
		if (this.chkAllItem) {
			this.allProductChecked();
		}
		if (this.chkAllCustomer || this.chkMyCustomers) {
			this.allCustomerChecked();
		}
		if (this.chkAssignedCustomer || this.chkByTransaction) {
			this.allAssignOrByTransactionCustomerChk();
		}
		if (this.chkSalesTeamTransaction) {
			this.allSalesTeamTransactionCustomerChk();
		}
		if (this.chkAllCustomrGroup) {
			this.allCustomerGroupChecked();
		}

		this.doAddExclusionToListTab();
	}

	public allCustomerGroupChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['group_name'] = 'All Groups';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = 'All Groups';
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomergroups = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerGroupData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allTerritoryChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();

		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['territory_name'] = "All Territories";
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = "All Territories";
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allterritories = this.territoryModel.chkAllTerritory;
		this.selectedTerritoryData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allCustomerChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textCustomerGroup = this.chkAllCustomer ? 'All Customers' : 'My Customers';
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textCustomerGroup;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.erp_primary_key = textCustomerGroup;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allClassesChecked() {
		let itemname = 'All Classes';

		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		// exelusionTierbaseModel['code'] = '';
		// exelusionTierbaseModel['name'] = '';
		// exelusionTierbaseModel['guid'] = '';
		// exelusionTierbaseModel.classguid = 'allclasses';
		// exelusionTierbaseModel.classid = itemname;
		// exelusionTierbaseModel.classname = itemname;
		// exelusionTierbaseModel.allclasses = true;

		exelusionTierbaseModel.guid = 'allclasses';
		exelusionTierbaseModel.erp_primary_key = 'allclasses';
		exelusionTierbaseModel.id = 'itemname';
		exelusionTierbaseModel.name = itemname;
		exelusionTierbaseModel.allclasses = true;

		this.selectedClassesData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allAssignOrByTransactionCustomerChk() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textVal = this.chkAssignedCustomer ? 'Assigned Customer(s)' : 'By Transactions';
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textVal;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = textVal;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		exelusionTierbaseModel.assignedcustomers = this.chkAssignedCustomer;
		exelusionTierbaseModel.bytransactions = this.chkByTransaction;
		exelusionTierbaseModel.sales_team_transactions = false;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allSalesTeamTransactionCustomerChk() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textVal = 'Sales Team Transactions';
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textVal;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = textVal;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		exelusionTierbaseModel.assignedcustomers = false;
		exelusionTierbaseModel.bytransactions = false;
		exelusionTierbaseModel.sales_team_transactions = this.chkSalesTeamTransaction;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allProductChecked() {
		let itemname = '';
		if (this.exclusionsGroupRadioButton === 'addproductexclusion') {
			itemname = 'All Items';
		}
		if (this.exclusionsGroupRadioButton === 'addgroupexclusion') {
			itemname = 'All Groups';
		}
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = '';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = itemname;
		exelusionTierbaseModel.itemname = itemname;
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allproducts = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;

		this.selectedProductData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public checkExclusionListDuplicateRecords(item: any) {
		let indexExcusion = -1;
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			if (this.commissionDetails.tiers_type === 'multiple_items') {
				let countOfEachItem = 0;
				if (this.erp_type === ErpTypeValEnum.NETSUITE && (this.enable_invoice_custom_fields_feature || this.enable_profit_on_document_header)) {
					const isduplicated = this.checkValidateCustomFutureField(item);
					if (isduplicated) {
						countOfEachItem = 1;
					} else {
						countOfEachItem = 0;
					}
				} else {
					if (item.assignedcustomers || item.bytransactions) {
						const getExclusionIndex = this.exclusionTabList.findIndex(i => i.guid !== item.guid && (i.assignedcustomers === true || i.bytransactions === true) && i.sales_team_transactions == item.sales_team_transactions && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis);
						if (getExclusionIndex !== -1) {
							this.exclusionTabList[getExclusionIndex].items.forEach(itemEle => {
								const existingItemIndex = item.items.findIndex(eachItem => eachItem.erp_primary_key === itemEle.erp_primary_key);
								if (existingItemIndex !== -1) {
									countOfEachItem++;
								}
							});
						}
					} else if (item.sales_team_transactions) {
						const getExclusionIndex = this.exclusionTabList.findIndex(i => i.guid !== item.guid && i.sales_team_transactions == true && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis);
						if (getExclusionIndex !== -1) {
							this.exclusionTabList[getExclusionIndex].items.forEach(itemEle => {
								const existingItemIndex = item.items.findIndex(eachItem => eachItem.erp_primary_key === itemEle.erp_primary_key);
								if (existingItemIndex !== -1) {
									countOfEachItem++;
								}
							});
						}
					} else {
						if (this.enable_classification_for_commision) {
							const getExclusionIndex = this.exclusionTabList.findIndex(i => i.guid !== item.guid && i.clientname === item.clientname && i.classname === item.classname && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis);
							if (getExclusionIndex !== -1) {
								this.exclusionTabList[getExclusionIndex].items.forEach(itemEle => {
									const existingItemIndex = item.items.findIndex(eachItem => eachItem.erp_primary_key === itemEle.erp_primary_key);
									if (existingItemIndex !== -1) {
										countOfEachItem++;
									}
								});
							}
						} else if (this.is_use_territory_for_commission_contract) {
							const getExclusionIndex = this.exclusionTabList.findIndex(i => i.guid !== item.guid && i.territoryname === item.territoryname && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis && i.selectedUom === item.selectedUom);
							if (getExclusionIndex !== -1) {
								this.exclusionTabList[getExclusionIndex].items.forEach(itemEle => {
									const existingItemIndex = item.items.findIndex(eachItem => eachItem.erp_primary_key === itemEle.erp_primary_key);
									if (existingItemIndex !== -1) {
										countOfEachItem++;
									}
								});
							}
						} else {
							const getExclusionIndex = this.exclusionTabList.findIndex(i => i.guid !== item.guid && i.clientname === item.clientname && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis && i.selectedUom === item.selectedUom);
							if (getExclusionIndex !== -1) {
								this.exclusionTabList[getExclusionIndex].items.forEach(itemEle => {
									const existingItemIndex = item.items.findIndex(eachItem => eachItem.erp_primary_key === itemEle.erp_primary_key);
									if (existingItemIndex !== -1) {
										countOfEachItem++;
									}
								});
							}

						}
					}
				}
				if (countOfEachItem > 0) {
					return true;
				} else {
					return false;
				}
			} else {
				if (this.erp_type === ErpTypeValEnum.NETSUITE && (this.enable_invoice_custom_fields_feature || this.enable_profit_on_document_header)) {
					const isduplicated = this.checkValidateCustomFutureField(item);
					if (isduplicated) {
						indexExcusion = -1;
					} else {
						indexExcusion = 0;
					}
				} else {
					if (item.assignedcustomers || item.bytransactions) {
						indexExcusion = this.exclusionTabList.findIndex(i => (i.assignedcustomers === true || i.bytransactions === true) && i.sales_team_transactions == item.sales_team_transactions && i.itemname === item.itemname && i.guid !== item.guid);
					} else if (item.sales_team_transactions) {
						indexExcusion = this.exclusionTabList.findIndex(i => i.sales_team_transactions == true && i.itemname === item.itemname && i.guid !== item.guid);
					} else {
						if (this.enable_classification_for_commision) {
							indexExcusion = this.exclusionTabList.findIndex(i => i.clientname === item.clientname && i.classname === item.classname && i.guid !== item.guid && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis);
						} else if (this.is_use_territory_for_commission_contract) {
							indexExcusion = this.exclusionTabList.findIndex(i => i.territoryname === item.territoryname && i.itemname === item.itemname && i.guid !== item.guid && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis && i.selectedUom === item.selectedUom);
						} else {
							indexExcusion = this.exclusionTabList.findIndex(i => i.clientname === item.clientname && i.itemname === item.itemname && i.guid !== item.guid && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis && i.selectedUom === item.selectedUom);
						}
					}
				}
				if (indexExcusion !== -1) {
					return true;
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	}

	public checkValidateCustomFutureField(item: any): boolean {
		if (this.listOfFeatureCustomFields.length > 0) {
			let returnVal: boolean = false;
			const custLineItem1: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: 'custom_lineitem_1' });
			const custLineItem2: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: "custom_lineitem_2" });
			const custHeader1: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: "custom_header_1" });
			const custHeader2: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: "custom_header_2" });
			const custLineItem3: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: "custom_lineitem_3" });
			//const fiteredCustomFieldData = _.where(this.listOfFeatureCustomFields, { display: true });
			// let filterCon: any = '';
			// for (let i = 0; i < fiteredCustomFieldData.length; i++) {
			// 	const element = fiteredCustomFieldData[i];
			// 	filterCon = filterCon + 'j.' + element.ix_custom_field + ' === item.' + element.ix_custom_field;
			// 	if (i < fiteredCustomFieldData.length - 1) {
			// 		filterCon = filterCon + ' && ';
			// 	}
			// 	let indexExcusion = -1;
			// 	if (item.assignedcustomers || item.bytransactions) {
			// 		indexExcusion = this.exclusionTabList.findIndex(j => (j.assignedcustomers === true || j.bytransactions == true) && j.itemname === item.itemname && j[element.ix_custom_field] === item[element.ix_custom_field]);
			// 	} else {
			// 		indexExcusion = this.exclusionTabList.findIndex(j => j.clientname === item.clientname && j.classname === item.classname && j[element.ix_custom_field] === item[element.ix_custom_field]);
			// 	}
			// 	if (indexExcusion === -1) {
			// 		returnVal = true;
			// 	}
			// }


			const itemIndex = this.exclusionTabList.findIndex(j => j.guid === item.guid);
			let newindexExcusion;

			if (this.commissionDetails.tiers_type === 'multiple_items') {
				let getExclusionIndex: number;
				let countOfEachItem: number = 0;
				if (this.enable_classification_for_commision) {
					getExclusionIndex = this.exclusionTabList.findIndex(j => j.clientname === item.clientname &&
						j.classname === item.classname &&
						(custLineItem1.length && custLineItem1[0].display ? (item.custom_lineitem_1 === undefined ? (j.custom_lineitem_1 === undefined || j.custom_lineitem_1 === null || j.custom_lineitem_1 === '' || j.custom_lineitem_1 === false) : j.custom_lineitem_1 === item.custom_lineitem_1) : true) &&
						(custLineItem2.length && custLineItem2[0].display ? (item.custom_lineitem_2 === undefined ? (j.custom_lineitem_2 === undefined || j.custom_lineitem_2 === null || j.custom_lineitem_2 === '' || j.custom_lineitem_2 === false) : j.custom_lineitem_2 === item.custom_lineitem_2) : true) &&
						(custHeader1.length && custHeader1[0].display ? (item.custom_header_1 === undefined ? (j.custom_header_1 === undefined || j.custom_header_1 === null || j.custom_header_1 === '' || j.custom_header_1 === false) : j.custom_header_1 === item.custom_header_1) : true) &&
						(custHeader2.length && custHeader2[0].display ? (item.custom_header_2 === undefined ? (j.custom_header_2 === undefined || j.custom_header_2 === null || j.custom_header_2 === '' || j.custom_header_2 === false) : j.custom_header_2 === item.custom_header_2) : true) && (custLineItem3.length && custLineItem3[0].display ? (item.custom_lineitem_3 === undefined ? (j.custom_lineitem_3 === undefined || j.custom_lineitem_3 === null || j.custom_lineitem_3 === '' || j.custom_lineitem_3 === false) : j.custom_lineitem_3 === item.custom_lineitem_3) : true));
					if (getExclusionIndex !== -1) {
						this.exclusionTabList[getExclusionIndex].items.forEach(itemEle => {
							const existingItemIndex = item.items.findIndex(eachItem => eachItem.erp_primary_key === itemEle.erp_primary_key);
							if (existingItemIndex !== -1) {
								countOfEachItem++;
							}
						});
					}
				} else {
					getExclusionIndex = this.exclusionTabList.findIndex(j => j.clientname === item.clientname &&
						(custLineItem1.length && custLineItem1[0].display ? (item.custom_lineitem_1 === undefined ? (j.custom_lineitem_1 === undefined || j.custom_lineitem_1 === null || j.custom_lineitem_1 === '' || j.custom_lineitem_1 === false) : j.custom_lineitem_1 === item.custom_lineitem_1) : true) &&
						(custLineItem2.length && custLineItem2[0].display ? (item.custom_lineitem_2 === undefined ? (j.custom_lineitem_2 === undefined || j.custom_lineitem_2 === null || j.custom_lineitem_2 === '' || j.custom_lineitem_2 === false) : j.custom_lineitem_2 === item.custom_lineitem_2) : true) &&
						(custHeader1.length && custHeader1[0].display ? (item.custom_header_1 === undefined ? (j.custom_header_1 === undefined || j.custom_header_1 === null || j.custom_header_1 === '' || j.custom_header_1 === false) : j.custom_header_1 === item.custom_header_1) : true) &&
						(custHeader2.length && custHeader2[0].display ? (item.custom_header_2 === undefined ? (j.custom_header_2 === undefined || j.custom_header_2 === null || j.custom_header_2 === '' || j.custom_header_2 === false) : j.custom_header_2 === item.custom_header_2) : true) && (custLineItem3.length && custLineItem3[0].display ? (item.custom_lineitem_3 === undefined ? (j.custom_lineitem_3 === undefined || j.custom_lineitem_3 === null || j.custom_lineitem_3 === '' || j.custom_lineitem_3 === false) : j.custom_lineitem_3 === item.custom_lineitem_3) : true));

					if (getExclusionIndex !== -1) {
						this.exclusionTabList[getExclusionIndex].items.forEach(itemEle => {
							const existingItemIndex = item.items.findIndex(eachItem => eachItem.erp_primary_key === itemEle.erp_primary_key);
							if (existingItemIndex !== -1) {
								countOfEachItem++;
							}
						});
					}
				}
				if (countOfEachItem > 0) {
					returnVal = true;
				} else {
					returnVal = false;
				}
				return returnVal;
			} else {
				if (this.enable_classification_for_commision) {
					newindexExcusion = this.exclusionTabList.findIndex(j => j.clientname === item.clientname &&
						j.classname === item.classname && j.tier_basis === item.tier_basis && j.commission_basis === item.commission_basis &&
						(custLineItem1.length && custLineItem1[0].display ? (item.custom_lineitem_1 === undefined ? (j.custom_lineitem_1 === undefined || j.custom_lineitem_1 === null || j.custom_lineitem_1 === '' || j.custom_lineitem_1 === false) : j.custom_lineitem_1 === item.custom_lineitem_1) : true) &&
						(custLineItem2.length && custLineItem2[0].display ? (item.custom_lineitem_2 === undefined ? (j.custom_lineitem_2 === undefined || j.custom_lineitem_2 === null || j.custom_lineitem_2 === '' || j.custom_lineitem_2 === false) : j.custom_lineitem_2 === item.custom_lineitem_2) : true) &&
						(custHeader1.length && custHeader1[0].display ? (item.custom_header_1 === undefined ? (j.custom_header_1 === undefined || j.custom_header_1 === null || j.custom_header_1 === '' || j.custom_header_1 === false) : j.custom_header_1 === item.custom_header_1) : true) &&
						(custHeader2.length && custHeader2[0].display ? (item.custom_header_2 === undefined ? (j.custom_header_2 === undefined || j.custom_header_2 === null || j.custom_header_2 === '' || j.custom_header_2 === false) : j.custom_header_2 === item.custom_header_2) : true) && (custLineItem3.length && custLineItem3[0].display ? (item.custom_lineitem_3 === undefined ? (j.custom_lineitem_3 === undefined || j.custom_lineitem_3 === null || j.custom_lineitem_3 === '' || j.custom_lineitem_3 === false) : j.custom_lineitem_3 === item.custom_lineitem_3) : true));
				} else {
					newindexExcusion = this.exclusionTabList.findIndex(j => j.clientname === item.clientname &&
						j.itemname === item.itemname &&
						(custLineItem1.length && custLineItem1[0].display ? (item.custom_lineitem_1 === undefined ? (j.custom_lineitem_1 === undefined || j.custom_lineitem_1 === null || j.custom_lineitem_1 === '' || j.custom_lineitem_1 === false) : j.custom_lineitem_1 === item.custom_lineitem_1) : true) &&
						(custLineItem2.length && custLineItem2[0].display ? (item.custom_lineitem_2 === undefined ? (j.custom_lineitem_2 === undefined || j.custom_lineitem_2 === null || j.custom_lineitem_2 === '' || j.custom_lineitem_2 === false) : j.custom_lineitem_2 === item.custom_lineitem_2) : true) &&
						(custHeader1.length && custHeader1[0].display ? (item.custom_header_1 === undefined ? (j.custom_header_1 === undefined || j.custom_header_1 === null || j.custom_header_1 === '' || j.custom_header_1 === false) : j.custom_header_1 === item.custom_header_1) : true) &&
						(custHeader2.length && custHeader2[0].display ? (item.custom_header_2 === undefined ? (j.custom_header_2 === undefined || j.custom_header_2 === null || j.custom_header_2 === '' || j.custom_header_2 === false) : j.custom_header_2 === item.custom_header_2) : true) && (custLineItem3.length && custLineItem3[0].display ? (item.custom_lineitem_3 === undefined ? (j.custom_lineitem_3 === undefined || j.custom_lineitem_3 === null || j.custom_lineitem_3 === '' || j.custom_lineitem_3 === false) : j.custom_lineitem_3 === item.custom_lineitem_3) : true));
				}
				if (newindexExcusion === -1 || newindexExcusion === itemIndex) {
					returnVal = true;
				}
				return returnVal;
			}
		} else {
			return true;
		}
	}

	public doAddExclusionToListTab() {
		let isSaveCommission: boolean = false;
		// Territory drop-down-list
		if (this.selectedTerritoryData && this.selectedTerritoryData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
			if (this.commissionDetails.tiers_type === 'multiple_items') {
				this.selectedTerritoryData.forEach(territory => {
					const element = this.selectedProductData[0];
					let listOfSpecificUoms = [];
					if (!element.allproducts) {
						const priceListNum = territory.priceListNum || '';
						listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
					}
					const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
					const defaultTierBasis = this.listOfTierBasis[0].code;
					const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
					//exelusionTierbaseModel.guid = territory.guid + ' - ' + element.erp_primary_key || '';
					exelusionTierbaseModel.guid = this.guid.newGuid();
					exelusionTierbaseModel.territoryguid = territory.guid;
					exelusionTierbaseModel.territoryname = territory.territory_name;
					exelusionTierbaseModel.allterritories = territory.allterritories;
					exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
					exelusionTierbaseModel.itemname = element.itemname;
					exelusionTierbaseModel.itemsku = null;
					exelusionTierbaseModel.itemType = null;
					exelusionTierbaseModel.itemOrGroup = null;
					exelusionTierbaseModel.allproducts = null;
					exelusionTierbaseModel.allcustomergroups = null;
					exelusionTierbaseModel.mycustomers = null;
					exelusionTierbaseModel.description = null;
					exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
					exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
					exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
					exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
					exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
					exelusionTierbaseModel.rate = null;
					exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
					if (this.isCustomerAgeTiers) {
						exelusionTierbaseModel.customer_age_tiers = [];
					} else {
						exelusionTierbaseModel.tiers = [];
					}
					exelusionTierbaseModel.items = this.selectedProductData.map(itemEle => {
						let listOfSpecificUoms = [];
						let priceListNum = '';
						if (!itemEle.allproducts) {
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemEle, priceListNum);
						}
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						return {
							mycustomers: itemEle.mycustomers,
							allcustomergroups: itemEle.allcustomergroups,
							allproducts: itemEle.allproducts,
							erp_primary_key: itemEle.erp_primary_key,
							itemname: itemEle.itemname,
							itemsku: itemEle.itemsku,
							description: itemEle.description,
							itemType: itemEle.type,
							itemOrGroup: itemOrGroup,
							uomgroupentry: itemEle.uomgroupentry,
						};
					});
					//check duplicate validation for exlusion tab list
					if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
						return false;
					}
					this.exclusionTabList.unshift(exelusionTierbaseModel);
					// Latest exlusions save on edit commission contract
				});
			} else {
				this.selectedTerritoryData.forEach(territory => {
					this.selectedProductData.forEach(element => {
						let listOfSpecificUoms = [];
						if (!element.allproducts) {
							const priceListNum = territory.priceListNum || '';
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						//exelusionTierbaseModel.guid = territory.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.territoryguid = territory.guid;
						exelusionTierbaseModel.territoryname = territory.territory_name;
						exelusionTierbaseModel.allterritories = territory.allterritories;
						exelusionTierbaseModel.itemsku = element.itemsku;
						exelusionTierbaseModel.itemType = element.type;
						exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.allproducts = element.allproducts;
						exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
						exelusionTierbaseModel.mycustomers = element.mycustomers;
						exelusionTierbaseModel.description = element.description;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							return false;
						}
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				});
			}
		}
		// End Territory drop-down-list

		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedClassesData && this.selectedClassesData.length > 0) {
				if (this.commissionDetails.tiers_type === 'multiple_items') {
					this.selectedTerritoryData.forEach(territory => {
						const element = this.selectedProductData[0];
						let listOfSpecificUoms = [];
						if (!element.allproducts) {
							const priceListNum = territory.priceListNum || '';
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						//exelusionTierbaseModel.guid = territory.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.territoryguid = territory.guid;
						exelusionTierbaseModel.territoryname = territory.territory_name;
						exelusionTierbaseModel.allterritories = territory.allterritories;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.itemsku = null;
						exelusionTierbaseModel.itemType = null;
						exelusionTierbaseModel.itemOrGroup = null;
						exelusionTierbaseModel.allproducts = null;
						exelusionTierbaseModel.allcustomergroups = null;
						exelusionTierbaseModel.mycustomers = null;
						exelusionTierbaseModel.description = null;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						exelusionTierbaseModel.items = this.selectedProductData.map(itemEle => {
							let listOfSpecificUoms = [];
							let priceListNum = '';
							if (!itemEle.allproducts) {
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemEle, priceListNum);
							}
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
							return {
								mycustomers: itemEle.mycustomers,
								allcustomergroups: itemEle.allcustomergroups,
								allproducts: itemEle.allproducts,
								erp_primary_key: itemEle.erp_primary_key,
								itemname: itemEle.itemname,
								itemsku: itemEle.itemsku,
								description: itemEle.description,
								itemType: itemEle.type,
								itemOrGroup: itemOrGroup,
								uomgroupentry: itemEle.uomgroupentry,
							};
						});
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							return false;
						}
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				} else {
					this.selectedCustomerData.forEach(customer => {
						this.selectedClassesData.forEach(element => {
							// let listOfSpecificUoms = [];
							// if (!element.allproducts) {
							// 	const priceListNum = customer.priceListNum || '';
							// 	listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
							// }
							const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
							const defaultTierBasis = this.listOfTierBasis[0].code;
							const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							// if (listOfSpecificUoms.length > 0) {
							// 	defaultSelectedUom = listOfSpecificUoms[0].code || '';
							// 	defaultListPrice = listOfSpecificUoms[0].price || 0;
							// }
							const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
							exelusionTierbaseModel.guid = this.guid.newGuid();;
							//exelusionTierbaseModel.guid = customer.guid + ' - ' + (element['classguid'] ? element['classguid'] : element['guid'] || '');
							exelusionTierbaseModel.clientguid = customer.guid;
							exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
							exelusionTierbaseModel.clientname = customer.name;
							exelusionTierbaseModel.allcustomers = customer.allcustomers;
							// exelusionTierbaseModel.itemsku = element.itemsku;
							// exelusionTierbaseModel.itemOrGroup = itemOrGroup;
							// exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
							// exelusionTierbaseModel.itemname = element.itemname;
							// exelusionTierbaseModel.allproducts = element.allproducts;
							// exelusionTierbaseModel.description = element.description;
							exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
							exelusionTierbaseModel.mycustomers = element.mycustomers;
							exelusionTierbaseModel.bytransactions = customer.bytransactions;
							exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
							exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
							exelusionTierbaseModel.allclasses = element.allclasses;
							exelusionTierbaseModel.classguid = element['classguid'] ? element['classguid'] : element['guid'];
							exelusionTierbaseModel.classid = element['classid'] ? element['classid'] : element['id'];
							exelusionTierbaseModel.classname = element['classname'] ? element['classname'] : element['name'];

							exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
							exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
							// exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
							// exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
							exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
							exelusionTierbaseModel.rate = null;
							exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
							//exelusionTierbaseModel.tiers = [];

							if (this.isCustomerAgeTiers) {
								exelusionTierbaseModel.customer_age_tiers = [];
							} else {
								exelusionTierbaseModel.tiers = [];
							}

							const customFieldDataList = this._CommissionService.getCustomFieldForInvoice(this.listOfFeatureCustomFields, 0);
							// if (this.exclusionTabDataGridRef && this.exclusionTabDataGridRef['instance']) {
							if (customFieldDataList && customFieldDataList.length > 0) {
								customFieldDataList.forEach(element => {
									if (!exelusionTierbaseModel.hasOwnProperty(element.dataField)) {
										exelusionTierbaseModel[element.dataField] = null;
									}
								});
							}
							//check duplicate validation for exlusion tab list
							if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
								this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
								return false;
							}
							isSaveCommission = true;
							this.exclusionTabList.unshift(exelusionTierbaseModel);
							// Latest exlusions save on edit commission contract
						});
					});
				}
			}

			if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
				if (this.commissionDetails.tiers_type === 'multiple_items') {
					this.selectedCustomerData.forEach(customer => {
						const element = this.selectedProductData[0];

						let listOfSpecificUoms = [];
						if (!this.enable_classification_for_commision) {
							if (!element.allproducts) {
								const priceListNum = customer.priceListNum || '';
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
							}
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (!this.enable_classification_for_commision) {
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						//exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = customer.guid;
						exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
						exelusionTierbaseModel.clientname = customer.name;
						exelusionTierbaseModel.allcustomers = customer.allcustomers;
						exelusionTierbaseModel.bytransactions = customer.bytransactions;
						exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
						exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.itemsku = null;
						exelusionTierbaseModel.itemType = null;
						exelusionTierbaseModel.itemOrGroup = null;
						exelusionTierbaseModel.allproducts = null;
						exelusionTierbaseModel.allcustomergroups = null;
						exelusionTierbaseModel.mycustomers = null;
						exelusionTierbaseModel.description = null;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						if (!this.enable_classification_for_commision) {
							exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
							exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						}
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						//exelusionTierbaseModel.tiers = [];
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						exelusionTierbaseModel.items = this.selectedProductData.map(itemEle => {
							let listOfSpecificUoms = [];
							let priceListNum = customer.priceListNum || '';
							if (!itemEle.allproducts) {
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemEle, priceListNum);
							}
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
							return {
								// mycustomers: itemEle.mycustomers,
								// allcustomergroups: itemEle.allcustomergroups,
								allproducts: itemEle.allproducts,
								erp_primary_key: itemEle.erp_primary_key,
								itemname: itemEle.itemname,
								itemsku: itemEle.itemsku,
								description: itemEle.description,
								itemType: itemEle.type,
								itemOrGroup: itemOrGroup,
								uomgroupentry: itemEle.uomgroupentry,
							};
						});
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						isSaveCommission = true;
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract

					});
				} else {
					this.selectedCustomerData.forEach(customer => {
						this.selectedProductData.forEach(element => {
							let listOfSpecificUoms = [];
							if (!this.enable_classification_for_commision) {
								if (!element.allproducts) {
									const priceListNum = customer.priceListNum || '';
									listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
								}
							}
							const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
							const defaultTierBasis = this.listOfTierBasis[0].code;
							const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (!this.enable_classification_for_commision) {
								if (listOfSpecificUoms.length > 0) {
									defaultSelectedUom = listOfSpecificUoms[0].code || '';
									defaultListPrice = listOfSpecificUoms[0].price || 0;
								}
							}
							const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
							//exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
							exelusionTierbaseModel.guid = this.guid.newGuid();
							exelusionTierbaseModel.clientguid = customer.guid;
							exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
							exelusionTierbaseModel.clientname = customer.name;
							exelusionTierbaseModel.allcustomers = customer.allcustomers;
							exelusionTierbaseModel.itemsku = element.itemsku;
							exelusionTierbaseModel.itemType = element.type;
							exelusionTierbaseModel.itemOrGroup = itemOrGroup;
							exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
							exelusionTierbaseModel.itemname = element.itemname;
							exelusionTierbaseModel.allproducts = element.allproducts;
							exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
							exelusionTierbaseModel.mycustomers = element.mycustomers;
							exelusionTierbaseModel.bytransactions = customer.bytransactions;
							exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
							exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
							exelusionTierbaseModel.description = element.description;
							exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
							exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
							if (!this.enable_classification_for_commision) {
								exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
								exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
							}
							exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
							exelusionTierbaseModel.rate = null;
							exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
							//exelusionTierbaseModel.tiers = [];
							if (this.isCustomerAgeTiers) {
								exelusionTierbaseModel.customer_age_tiers = [];
							} else {
								exelusionTierbaseModel.tiers = [];
							}
							//check duplicate validation for exlusion tab list
							if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
								this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
								return false;
							}
							isSaveCommission = true;
							this.exclusionTabList.unshift(exelusionTierbaseModel);
							// Latest exlusions save on edit commission contract
						});
					});
				}
			}
		} else {
			if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
				if (this.commissionDetails.tiers_type === 'multiple_items') {
					this.selectedCustomerData.forEach(customer => {
						const element = this.selectedProductData[0];

						let listOfSpecificUoms = [];
						if (!element.allproducts) {
							const priceListNum = customer.priceListNum || '';
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						//exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = customer.guid;
						exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
						exelusionTierbaseModel.clientname = customer.name;
						exelusionTierbaseModel.allcustomers = customer.allcustomers;
						exelusionTierbaseModel.bytransactions = customer.bytransactions;
						exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
						exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
						exelusionTierbaseModel.allproducts = element.allproducts;
						exelusionTierbaseModel.allcustomergroups = null;
						exelusionTierbaseModel.mycustomers = null;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.itemOrGroup = null;
						exelusionTierbaseModel.itemType = null;
						exelusionTierbaseModel.itemsku = null;
						exelusionTierbaseModel.description = null;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						// exelusionTierbaseModel.tiers = [];
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						exelusionTierbaseModel.items = this.selectedProductData.map(itemEle => {
							let listOfSpecificUoms = [];
							let priceListNum = customer.priceListNum || '';
							if (!itemEle.allproducts) {
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemEle, priceListNum);
							}
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
							return {
								// mycustomers: itemEle.mycustomers,
								// allcustomergroups: itemEle.allcustomergroups,
								allproducts: itemEle.allproducts,
								erp_primary_key: itemEle.erp_primary_key,
								itemname: itemEle.itemname,
								itemsku: itemEle.itemsku,
								description: itemEle.description,
								itemType: itemEle.type,
								itemOrGroup: itemOrGroup,
								uomgroupentry: itemEle.uomgroupentry,
							};
						});
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						isSaveCommission = true;
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract

					});
				} else {
					this.selectedCustomerData.forEach(customer => {
						this.selectedProductData.forEach(element => {
							let listOfSpecificUoms = [];
							if (!element.allproducts) {
								const priceListNum = customer.priceListNum || '';
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
							}
							const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
							const defaultTierBasis = this.listOfTierBasis[2].code;
							const defaultCommissionBasis = this.listOfCommissionBasis[1].code;
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
							const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
							// exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
							exelusionTierbaseModel.guid = this.guid.newGuid();
							exelusionTierbaseModel.clientguid = customer.guid;
							exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
							exelusionTierbaseModel.clientname = customer.name;
							exelusionTierbaseModel.allcustomers = customer.allcustomers;
							exelusionTierbaseModel.itemsku = element.itemsku;
							exelusionTierbaseModel.itemType = element.type;
							exelusionTierbaseModel.itemOrGroup = itemOrGroup;
							exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
							exelusionTierbaseModel.itemname = element.itemname;
							exelusionTierbaseModel.allproducts = element.allproducts;
							exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
							exelusionTierbaseModel.mycustomers = element.mycustomers;
							exelusionTierbaseModel.bytransactions = customer.bytransactions;
							exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
							exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
							exelusionTierbaseModel.description = element.description;
							exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
							exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
							exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
							exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
							exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
							exelusionTierbaseModel.rate = null;
							exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
							if (this.isCustomerAgeTiers) {
								exelusionTierbaseModel.customer_age_tiers = [];
							} else {
								exelusionTierbaseModel.tiers = [];
							}
							//check duplicate validation for exlusion tab list
							if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
								this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
								return false;
							}
							this.exclusionTabList.unshift(exelusionTierbaseModel);
							// Latest exlusions save on edit commission contract
						});
					});
				}
			}

			if (this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0 && this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0) {
				this.selectedCustomerGroupData.forEach(group => {
					this.selectedProductData.forEach(element => {
						let listOfSpecificUoms = [];
						if (!element.allproducts) {
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element);
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						// exelusionTierbaseModel.guid = group.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = group.guid;
						exelusionTierbaseModel.clientcode = '';
						exelusionTierbaseModel.clientname = group.group_name;
						exelusionTierbaseModel.allcustomergroups = group.allcustomergroups;
						exelusionTierbaseModel.allcustomers = element.allcustomers;
						exelusionTierbaseModel.itemsku = element.itemsku;
						exelusionTierbaseModel.itemType = element.type;
						exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.allproducts = element.allproducts;
						exelusionTierbaseModel.mycustomers = element.mycustomers;
						exelusionTierbaseModel.bytransactions = element.bytransactions;
						exelusionTierbaseModel.sales_team_transactions = element.sales_team_transactions;
						exelusionTierbaseModel.description = element.description;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				})
			}
		}
		this.exclusionTabList.forEach((i, index) => {
			i.rownum = index + 1;
		});

		setTimeout(() => {
			this.dataSourceExclusionTab = this.exclusionTabList;
		}, 50);
		if (isSaveCommission) {
			this.calSaveCommissionContract();
		}
		if (this.editCommissionGuid) {
			// this.saveCommissionContract(true);
		}
		this.clearExclusionAgreementTabDetails();
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: "cleariconix",
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.exclusionTabDataGridRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.showText = "always";
			columnChooserButton.visible = false;
			columnChooserButton.icon = 'columnChooser';
		}
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		if (this.exclusionTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Commission_Customers_Items_Groups.xlsx';
		worksheet = workbook.addWorksheet('Customers-Items-Groups');

		exportDataGrid({
			worksheet: worksheet,
			component: this.exclusionTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'rate') {
						excelCell.value = '';
						if (gridCell.value) {
							if (gridCell.data && gridCell.data.commission_basis) {
								if (gridCell.data.commission_basis === 'dollar') {
									excelCell.value = this._CommissionService.convertCurrency(gridCell.value || 0, this.customCurrencyOption);
								}
								if (gridCell.data.commission_basis === 'percentage') {
									excelCell.value = +(this._CustomNumberNoRoundingPipe.transform(gridCell.value || 0)) + '%'
								}
							}
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		// e.cancel = true;
	}

	// Method used to Display Commission basis Name in uppercase
	public getDisplayExprCommissionbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Tier basis Name in uppercase
	public getDisplayExprTierbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}
	// #endregion

	private doConvertDecimalPointByConfiguration() {
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			for (let i = 0; i < this.exclusionTabList.length; i++) {
				const exList = this.exclusionTabList[i];
				if (exList.commission_basis === 'percentage') {
					if (exList.tiers && exList.tiers.length > 0) {
						for (let j = 0; j < exList.tiers.length; j++) {
							const t = exList.tiers[j];
							if (t.rate !== null) {
								const rate = +(this._CustomNumberNoRoundingPipe.transform(t.rate));
								t.rate = rate;
							}
						}
					}
					if (exList.hasOwnProperty('rate')) {
						if (exList.rate !== null) {
							exList.rate = +(this._CustomNumberNoRoundingPipe.transform(exList.rate))
						}
					}
				}
			}
		}
	}

	public doOpenFilterBuilder() {
		this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Fetch client list
	public getCustomerData() {
		this._AppCommonSrvc.fetchCustomerData(NameFromServerKeyEnum.CLIENT).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						const customerData = response.data || [];
						const objcustomer = customerData[0];
						if (objcustomer && objcustomer.human_readable_account_code) {
							this.customerGridBoxColumns.push({ dataField: 'human_readable_account_code', caption: 'Code', visible: true })
							this.customerGridBoxColumns.forEach(element => {
								if (element.dataField == 'code') {
									element.visible = false;
								}
							});
							this.array_move(this.customerGridBoxColumns, 2, 0);
						}
					}
				}
			}, error: (error) => { }
		});
	}
	private array_move(arr, old_index, new_index) {
		if (new_index >= arr.length) {
			var k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr; // for testing
	}


	// #region for Feature Custom Fields
	private doGetFeatureCustomFields() {
		const formData = new FormData();
		let searchParams: any[] = [['is_contract_field_use_for_creation', '=', true], 'and', ['contract_type', '=', 'commission']];
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		if (this.enable_profit_on_document_header) {
			formData.append('filter', JSON.stringify(searchParams));
		}
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
		this.getFeatureCustomFieldsLstSbn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfFeatureCustomFields = response.data;
						this.isViewExlusionTabGrid = true;
					} else {
						this.isViewExlusionTabGrid = true;
					}
				} else {
					this.isViewExlusionTabGrid = true;
				}
			}, error: (error) => {
				this.isViewExlusionTabGrid = true;
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// #endregion

	public saveCommissionCustomerTiersData(event: any) {
		this.calSaveCommissionContract();
	}

	// #region for Items Datagrid under Tiers Datagrid
	public getItemsByTiersDetails(element) {
		return element.data.items || [];
	}

	public doEditorPreparingItemTierItmTab(event, subParentData: any, parentData: any) {
		if (event && event['row']) {
			// if (event.dataField === 'rate') {
			// 	if (event.row.isEditing) {
			// 		const exclusionTabListCloneArr: any = [];
			// 		if (event.component.getVisibleRows().length > 0) {
			// 			event.component.getVisibleRows().forEach(element => {
			// 				exclusionTabListCloneArr.push(element.data);
			// 			});
			// 		}
			// 		this.exclusionTabListClone = JSON.parse(JSON.stringify(exclusionTabListCloneArr));
			// 	}
			// }
			const itemObject = event.row.data;
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (parentData.data.commission_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = [];
						const itemIndex = parentData.data.items.findIndex(item => item.erp_primary_key === itemObject.erp_primary_key);
						if (itemIndex !== -1) {
							const selectedItemObject = parentData.data.items[itemIndex];
							uomDataSource = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, selectedItemObject);
						}
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			// if (event.dataField === 'tier_basis') {
			// 	let listOfTierBasis = this.listOfTierBasis;
			// 	event.editorOptions.dataSource = listOfTierBasis;
			// }

			if (event.dataField === 'commission_basis') {
				let listOfCommissionBasis = this.listOfCommissionBasis;
				if (itemObject.allproducts) {
					listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}

				if (itemObject['itemType']) {
					if (itemObject['itemType'] === 'OtherCharge' || itemObject['itemType'] === 'Service') {
						listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
					}
				}
				event.editorOptions.dataSource = listOfCommissionBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.visible = true;
				if (itemObject.allproducts) {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				} else {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				}
			}
		}
	}

	public doRowRemovedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.calSaveCommissionContract();
	}

	public doRowUpdatedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.calSaveCommissionContract();
	}

	public doOnSavedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.doConvertDecimalPointByConfiguration();
		if (this.tiersItemDetailssDataGridRef) {
			this.tiersItemDetailssDataGridRef.instance.refresh();
		}
		this.calSaveCommissionContract();
	}

	public doOpenedDropdownItemTierItmTab(e) {
		this.itemDataGrid.instance.clearFilter();
	}

	public doItemSelectionChanged(event: any, cellInfo: any, dropDownBoxComponent) {
		const selectedRowKeys = event.selectedRowKeys;
		cellInfo.setValue(selectedRowKeys[0]);
		if (selectedRowKeys.length > 0) {
			// this.rowIndexForItemDatagrid = cellInfo.rowIndex;
			// this.selectedItemForDatagrid = event.selectedRowsData[0];
			// this.setCellValueForItems(event.selectedRowsData[0]);
			dropDownBoxComponent.close();
		}
	}

	// #endregion

	// #region for Datagrid State Storing functionality

	public doLoadCommExclusionsAgreeState() {
		this.getFilterText();
		return this.loadDatagridState(this.stateStorageKey, this.mainSectionStorageKey);
	}

	private loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public doSaveCommExclusionsAgreeState(e) {
		const commissionListingConfig = this.saveDatagridState(e, this.stateStorageKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(commissionListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = commissionListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, commissionListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, commissionListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let commissionListingConfig: any = {};
		try {
			commissionListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			commissionListingConfig = {};
		}

		if (!commissionListingConfig.configs) {
			commissionListingConfig.configs = [];
		}

		const configIndex = commissionListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			commissionListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			commissionListingConfig.configs.push(newConfigObj);
		}
		return commissionListingConfig;
	}

	// #endregion
	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.exclusionTabDataGridRef.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}
	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'COLUMN_CHOOSER':
					this.exclusionTabDataGridRef.instance.showColumnChooser();
					break;
				case 'EXPORT':
					this.doExportToExcel();
					break;

			}
		}
	}

	public doActionsBtnClick(event: any) {
		// const addIndex = this.listOfActions.findIndex(i => i.btn_code
		// 	=== 'ADD');
		// if (addIndex !== -1) {
		// 	this.listOfActions[addIndex].disabled = !this.isShowAction;
		// }
	}
	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
}

