<dx-popup #dxPopupRef [width]="550" [height]="340" [showTitle]="popupUI.showTitle" [title]="popupUI.title"
	[dragEnabled]="true" [hideOnOutsideClick]="false" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<div class="dx-dialog-message">
			<div *ngIf="popupUI.popupIconsHtml" [innerHtml]="popupUI.popupIconsHtml"> </div>
			<div *ngIf="popupUI.popupIcon" [ngClass]="popupUI.popupIcon">
				<div class="infoIcon mrgT0"></div>
			</div>
			<h2 class="infoTitle" [innerHtml]="popupUI.subtitle"></h2>
			<p class="infoDescription" [innerHtml]="popupUI.message"></p>
		</div>

		<div *ngIf="popupUI.isShowDocumentDate" class="row">
			<div class="cols3">&nbsp;</div>
			<div class="cols6 pad0">
				<div class="dx-field dateiconset mrgT20 mrgB20">
					<div class="dx-field-label">Document Date</div>
					<div class="dx-field-value">
						<dx-date-box [(value)]="document_date" type="date" displayFormat="yyyy-MM-dd"
							[showClearButton]="true"> </dx-date-box>
					</div>
				</div>
			</div>
		</div>
	</div>

	<dxi-toolbar-item toolbar="bottom" location="center" cssClass="mrgT10">
		<div *dxTemplate>
			<dx-button (onClick)="doCancelBtn()" text="Cancel" class="button secondaryBtn mrgR20"></dx-button>
			<dx-button (onClick)="doOkBtn()" text="Ok" class="button primaryBtn"></dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>