import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { PopupHeightWidth } from '@app/constants-enums/enums';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { DatePipe } from '@angular/common';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { PageOptions } from '@app/models/common.model';
import { ViewCreditMemoDetailPopUpModel, ViewEndusersInvoiceDetailPopUpModel, ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
@Component({
	selector: 'app-mmeucc-view-claim-qty-detail-popup',
	templateUrl: './mmeucc-view-claim-qty-detail-popup.component.html',
	styleUrls: ['./mmeucc-view-claim-qty-detail-popup.component.css']
})
export class MmeuccViewClaimQtyDetailPopupComponent implements OnInit, OnDestroy {
	@Input() otherPurchaseProps: any;
	@ViewChild("quantityDataGridContainer", {}) quantityDataGridContainer: DxDataGridComponent;
	public isShowPopup: boolean = true;
	public pageOptions: PageOptions = new PageOptions();
	public quantityHeaderText: string = 'Other Purchase Quantity';
	public addEditPopupWidth: number;
	public addEditPopupHeight: number;
	public _PopupHeightWidth = PopupHeightWidth;
	public listOfAvailableQuantity: any;
	private updateUserSettingSbsn: Subscription;
	public mainSectionStorageKey: string;
	public stateStorageTabKey: string;
	private guid = new Guid();
	public filterValue: any[] = [];
	private instanceClearBtn: any = null;
	private filterValueChangeSbsn: Observable<any>;
	public filterTextFor: string = '';
	private isDisabledClearForBtn: boolean = true;
	public isShowIsActiveColumn: boolean = true;
	public listOfActions: any[] = [
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'EXPORT',
			btn_name: 'Export',
			btn_image: 'xlsxfile',
			icon: IMG_ICON_XLSXFILEPOPUPIX,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}
	];
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'enduserinvoice', text: 'End-User Invoice' }, { value: 'creditmemo', text: 'Credit Memo' }, { value: 'order', text: 'Order' }];
	public decimalPointForCurrency: number;
	public decimalPercentageFormat: string;
	public dxCustomerName: string;
	public dxItemName: string;
	public dxItemDescription: string;
	public dxDataField: string;
	public isShowInvoiceDetailPopUp: boolean = false;
	public isShowEndusersInvoiceDetailPopUp: boolean = false;
	public isShowCreditMemoDetailPopUp: boolean = false;
	public viewInvoiceDetailProps: ViewInvoiceDetailPopUpModel = new ViewInvoiceDetailPopUpModel();
	public viewEndusersInvoiceDetailProps: ViewEndusersInvoiceDetailPopUpModel = new ViewEndusersInvoiceDetailPopUpModel();
	public viewCreditMemoDetailProps: ViewCreditMemoDetailPopUpModel = new ViewCreditMemoDetailPopUpModel();
	constructor(
		public _LoginService?: LoginService,
		public _RestApiService?: RestApiService,
		public _AppCommonSrvc?: AppCommonSrvc,
		public _ToastrService?: ToastrService,
	) {
		this.doLoadItemTab = this.doLoadItemTab.bind(this);
		this.doSaveItemTab = this.doSaveItemTab.bind(this);
	}

	ngOnInit(): void {
		this.dxDataField = this.otherPurchaseProps.dxDataField || '';
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.listOfActions = this._AppCommonSrvc.getListOfActionsForClaimPreviewDatagrid() || [];
		this.addEditPopupHeight = heightWidthRatio.innerHeight;
		this.addEditPopupWidth = heightWidthRatio.innerWidth;
		this.listOfAvailableQuantity = this.otherPurchaseProps.listOfAvailableQuantity || [];
		this.dxCustomerName = this.otherPurchaseProps.dxCustomerName || '';
		this.dxItemDescription = this.otherPurchaseProps.dxItemDescription || '';
		this.dxItemName = this.otherPurchaseProps.dxItemName || '';
		this.mainSectionStorageKey = ServerSections.MIN_MAX_CHARGEBACK_CONTRACT;
		this.stateStorageTabKey = ServerSections.MIN_MAX_CHARGEBACK_OTHER_PURCHASE_LST;
		this.decimalPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		setTimeout(() => {
			if (this.quantityDataGridContainer) {
				this.filterValueChangeSbsn = this.quantityDataGridContainer.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterTextTab();
				});
			}
		})
	}
	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	public doHiddenPopop(e) {
		this.isShowPopup = false;
	}

	private getFilterTextTab() {
		this.filterTextFor = '';
		this.isDisabledClearForBtn = true;
		if (this.quantityDataGridContainer) {
			this.quantityDataGridContainer.filterPanel.customizeText = (e: any) => {
				this.filterTextFor = e.text;
				if (this.filterTextFor) {
					this.isDisabledClearForBtn = false;
				} else {
					this.isDisabledClearForBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtnFor();
		}, 600);
	}
	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtnFor() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearForBtn });
			}, 10);
		}
	}


	public doActionsBtnClickFor(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].disabled = !this.isShowIsActiveColumn;
		}
	}
	public doActionsBtnItemClickFor(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshItemDataGrid();
					break;
				case 'EXPORT':
					this.doExportToExcelForTab();
					break;
				case 'COLUMN_CHOOSER':
					this.quantityDataGridContainer.instance.showColumnChooser();
					break;
			}
		}
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doItemsToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearForBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.quantityDataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				template: 'templateActionBtns',
				visible: true,
			},
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}
	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.quantityDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	public doRefreshItemDataGrid() {
		// this.claimPreviewDataGridContainer.instance.refresh();
	}
	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcelForTab() {
		const context = this;
		if (context.quantityDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let claimedItemWorksheet;
		let fileName = 'Other_Purchase.xlsx';
		claimedItemWorksheet = workbook.addWorksheet('Other Purchase');

		exportDataGrid({
			worksheet: claimedItemWorksheet,
			component: context.quantityDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			topLeftCell: { row: 5, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = gridCell.value.toString().toUpperCase();
						}
					}
					if (gridCell.column.dataField === 'is_active') {
						excelCell.value = '';
						let colorName = '';
						excelCell.value = gridCell.value ? 'Active' : 'Inactive';
						colorName = gridCell.value ? '05c1a0' : 'f99300';
						// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
						excelCell.font = { color: { argb: colorName } };
					}
				}
			}
		}).then((cellRange) => {
			// header
			this.doSetHeaderBlockOnExportExcel(claimedItemWorksheet, context);
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});

	}

	private doSetHeaderBlockOnExportExcel(worksheet, context) {
		const customerNameRow = worksheet.getRow(1);
		customerNameRow.height = 30;
		customerNameRow.getCell(1).value = 'Customer';
		customerNameRow.getCell(1).alignment = { horizontal: 'left' };
		customerNameRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		customerNameRow.getCell(2).value = this.dxCustomerName;
		customerNameRow.getCell(2).alignment = { horizontal: 'left' };
		customerNameRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		const docRefNumRow = worksheet.getRow(2);
		docRefNumRow.height = 30;
		docRefNumRow.getCell(1).value = 'Item';
		docRefNumRow.getCell(1).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		docRefNumRow.getCell(2).value = this.dxItemName;
		docRefNumRow.getCell(2).alignment = { horizontal: 'left' };
		docRefNumRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
		const selectCustomerRow = worksheet.getRow(3);
		selectCustomerRow.height = 30;
		selectCustomerRow.getCell(1).value = 'Description';
		selectCustomerRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		selectCustomerRow.getCell(2).value = this.dxItemDescription;
		selectCustomerRow.getCell(2).alignment = { horizontal: 'left' };
		selectCustomerRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };
	}

	// #region for  Claim Tab Datagrid State Storing functionality

	public doLoadItemTab() {
		if (this.quantityDataGridContainer) {
			this.filterValueChangeSbsn = this.quantityDataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterTextTab();
			});
		}
		return this.loadDatagridState(this.stateStorageTabKey, this.mainSectionStorageKey);
	}
	public doSaveItemTab(e) {
		const salesListingConfig = this.saveDatagridState(e, this.stateStorageTabKey, this.mainSectionStorageKey);

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.mainSectionStorageKey);
		formData.append('user_settings', JSON.stringify(salesListingConfig));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
			}
		});
	}

	public loadDatagridState(configCode: string, parentLevelKey: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (e) {
			newSection = null;
		}
		const newOrExistingConfigClone = newSection;
		if (!newOrExistingConfigClone.configs) {
			newOrExistingConfigClone.configs = [];
		}
		const configIndex = newOrExistingConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			if (newOrExistingConfigClone.configs[configIndex].state_persistent) {
				return newOrExistingConfigClone.configs[configIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	private saveDatagridState(statePersistent: any, configCode: string, parentLevelKey: string) {
		let salesListingConfig: any = {};
		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(parentLevelKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}

		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		return salesListingConfig;
	}
	// #endregion

	doOnInvoiceItemInvoiceCellClick(element) {
		if (element.row && element.row.rowType === 'data') {
			if (element.data.hasOwnProperty(element.column.dataField) && element.data[element.column.dataField] !== 0 && (element.column.dataField === 'invoiceCode' || element.column.dataField === 'docnum')) {
				const docType = element.data['type'] || '';
				if (docType && docType == 'invoice') {
					const viewInvoiceDetail = new ViewInvoiceDetailPopUpModel();
					viewInvoiceDetail.docNo = element.data[element.column.dataField];
					viewInvoiceDetail.docType = docType;
					this.doOpenInvoiceDetailPopup(viewInvoiceDetail);
				}
				if (docType && docType == 'arcreditmemo') {
					const viewCreditMemoDetail = new ViewCreditMemoDetailPopUpModel();
					viewCreditMemoDetail.docNo = element.data[element.column.dataField];
					viewCreditMemoDetail.docType = docType;
					this.doOpenCreditMemoDetailPopup(viewCreditMemoDetail);
				}
				if (docType && docType == 'enduserinvoice') {
					const viewEndusersInvoiceDetail = new ViewEndusersInvoiceDetailPopUpModel();
					viewEndusersInvoiceDetail.docNo = element.data[element.column.dataField];
					viewEndusersInvoiceDetail.docType = docType;
					this.doOpenEndusersInvoiceDetailPopup(viewEndusersInvoiceDetail);
				}
			}
		}
	}

	// Show End-users Invoice Deatil PopUp
	private doOpenEndusersInvoiceDetailPopup(modelData: ViewEndusersInvoiceDetailPopUpModel) {
		this.viewEndusersInvoiceDetailProps.docNo = modelData.docNo;
		this.viewEndusersInvoiceDetailProps.docType = modelData.docType;
		this.isShowEndusersInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowEndusersInvoiceDetailPopUp = true;
		}, 300);
	}

	// Show Invoice Deatil PopUp
	private doOpenInvoiceDetailPopup(modelData: ViewInvoiceDetailPopUpModel) {
		this.viewInvoiceDetailProps.docNo = modelData.docNo;
		this.viewInvoiceDetailProps.docType = modelData.docType;
		this.isShowInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowInvoiceDetailPopUp = true;
		}, 300);
	}

	// Show CreditMemo PopUp
	private doOpenCreditMemoDetailPopup(modelData: ViewCreditMemoDetailPopUpModel) {
		this.viewCreditMemoDetailProps.docNo = modelData.docNo;
		this.viewCreditMemoDetailProps.docType = modelData.docType;
		this.isShowCreditMemoDetailPopUp = false;
		setTimeout(() => {
			this.isShowCreditMemoDetailPopUp = true;
		}, 300);
	}

}

