<dx-data-grid #dataGridContainer [dataSource]="dxDataSource" [showBorders]="true"
   (onToolbarPreparing)="doToolbarPreparing($event)" [remoteOperations]="true" [filterSyncEnabled]="true"
   [filterValue]="filterValue" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
   [allowColumnReordering]="true" [columnAutoWidth]="true" [width]="'100%'" [height]="'calc(100vh - 430px)'"
   class="hideFilterPanelInx">
   <dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
   <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
   </dxo-pager>
   <dxo-sorting mode="single"></dxo-sorting>
   <dxo-filter-row [visible]="true"></dxo-filter-row>
   <dxo-header-filter [visible]="true"></dxo-header-filter>
   <dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
   <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

   <div *dxTemplate="let data of 'templateFilterText'">
      <div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
         <span>{{filterText}}</span>
      </div>
   </div>

   <div *dxTemplate="let data of 'templateActionBtns'">
      <dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
         text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
         [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
         (onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
         [dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
   </div>
   <dxi-column dataField="pricelistnum" caption="Price List No" dataType="string" [visible]="false"
      [showInColumnChooser]="false"></dxi-column>
   <dxi-column dataField="code" caption="Code" dataType="string" cellTemplate="cellTemplateEndUserCode"
      [allowHeaderFiltering]="false">
      <div *dxTemplate="let element of 'cellTemplateEndUserCode'">
         <span class="proIcon center">
            <span class="icon-default-allcompany"></span>
         </span>
         <span class="mrgL10">
            {{element.data.code || ''}}
         </span>
      </div>
   </dxi-column>

   <dxi-column dataField="companyname" caption="Company" dataType="string" sortOrder="asc"
      [allowHeaderFiltering]="false">
      <!-- <div *dxTemplate="let element of 'cellTemplateEndUserCompany'">
         <span class="highlight mrgL20 pointerMark">
            {{element.data.companyname}}
         </span>
      </div> -->
   </dxi-column>

   <dxi-column dataField="phone" caption="Contacts" dataType="string" cellTemplate="cellTemplatephone"
      [allowHeaderFiltering]="false" [visible]="false" [showInColumnChooser]="false">
      <div *dxTemplate="let element of 'cellTemplatephone'">
         <span class="tlNm" *ngIf="element.data.phone">
            <span class="icon-calldxtable icon-set1"></span>
            <span [innerHTML]="element.data.phone"></span>
         </span>
      </div>
   </dxi-column>
   <dxi-column dataField="email" caption="Email" dataType="string" cellTemplate="cellTemplateemail"
      [allowHeaderFiltering]="false" [visible]="false" [showInColumnChooser]="false">
      <div *dxTemplate="let element of 'cellTemplateemail'">
         <span class="tlNm" *ngIf="element.data.email">
            <span class="icon-emaildxtable icon-set1"></span>
            <span [innerHTML]="element.data.email"></span>
         </span>
      </div>
   </dxi-column>
</dx-data-grid>