import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RebateStatusValEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxCircularGaugeComponent, DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { ToastrService } from 'ngx-toastr';
import { environment } from "@environments/environment";
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { Subscription } from 'rxjs';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { PageOptions } from '@app/models/common.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Guid } from '@app/models/guid';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-sales-goal-chart',
	templateUrl: './sales-goal-chart.component.html',
	styleUrls: ['./sales-goal-chart.component.css'],
	providers: [CustomCurrencyPipe]
})
export class SalesGoalChartComponent implements OnInit {
	@ViewChild('saleGoalChart') saleGoalChart: DxCircularGaugeComponent;
	public globalCurrency: string;
	@ViewChild('listOfContractDataGrid') listOfContractDataGrid: DxDataGridComponent;
	public isVisibleChartLoader: boolean = false;
	public value: any = {
		name: '',
		mean: null,
		min: null,
		max: null
	};
	public dataSource: any[] = [{
		name: 'Summer',
		mean: 3500000,
		min: 2800000,
		max: 3800000,
	}, {
		name: 'Autumn',
		mean: 2400000,
		min: 2000000,
		max: 3200000,
	}, {
		name: 'Winter',
		mean: 1800000,
		min: 1600000,
		max: 2300000,
	}, {
		name: 'Spring',
		mean: 2700000,
		min: 1800000,
		max: 3100000,
	}];
	public isShowChart = false;
	private monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	public currentMonthName: string;

	constructor(private _LoginService: LoginService,
		private _GlobalStateService: GlobalStateService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _AppCommonSrvc: AppCommonSrvc) {
		this.doCustomizeTooltip = this.doCustomizeTooltip.bind(this);
	}

	ngOnInit() {
		const serverDate = new Date(this._LoginService.loginUser.server_date);
		this.currentMonthName = this.monthNames[serverDate.getMonth()];
		this.isShowChart = true;
		this._GlobalStateService.subscribe('REFRESH_SALES_GOAL_CHART_EVENT', response => {
			this.doRefreshSalesGoalChart();
		});
		this.value = this.dataSource[0];
	}

	ngOnDestroy() {
		this._GlobalStateService.unsubscribe('REFRESH_SALES_GOAL_CHART_EVENT');
	}

	customizeText(arg) {
		return '$' + arg.valueText;
	}

	doCustomizeTooltip(arg) {
		return {
			// text: '$' + (arg.value / 1000000).toFixed(2) + 'M'
			text: this._CustomCurrencyPipe.transform(arg.value || 0)
			// text: this._CurrencyPipe.transform(arg.value, this.globalCurrency)
		};
	}

	doCustomizeTextIndicator(arg) {
		return {
			text: '$' + (arg.value / 1000000).toFixed(2) + 'M'
			// text: formatCurrency(arg.value, 'en', '$')
			// text: this._CurrencyPipe.transform(arg.value, this.globalCurrency)
		};
	}

	private doRefreshSalesGoalChart() {
		this.isShowChart = false;
		setTimeout(() => {
			this.isShowChart = true;
		}, 50);
		// this.saleGoalChart.instance.render();
	}
}