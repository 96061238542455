<div class="row equalHight">
	<div class="cols12">
		<div class="borderBox chartAreaBox quarterlyReportBox cf">
			<div class="chartArea contractDetail">
				<div class="row">
					<div class="cols4" [ngClass]="{'cols8':!isCheckAllContracts,'cols4':isCheckAllContracts}">
						<div class="dx-field mrgB10">
							<h2 class="chartTitle">
								<span *ngIf="!contractName">Revenue Forecast</span>
								<span *ngIf="contractName">{{contractName || ''}}</span>
							</h2>
						</div>
					</div>
					<div *ngIf="isCheckAllContracts" class="cols2 mrgT10">
						<div class="dx-field mrgB10">
							<div class="dx-field-label">Period From</div>
							<div class="dx-field-value">
								<dx-date-box [value]="startDate" (onValueChanged)="doStartDateChanged($event)"
									valueChangeEvent="change" type="date" displayFormat="monthAndYear"
									[calendarOptions]="{maxZoomLevel:'year',minZoomLevel: 'decade'}" [min]="minDateValue"
									[disabled]="false">
								</dx-date-box>
							</div>
						</div>
					</div>
					<div *ngIf="isCheckAllContracts" class="cols2 mrgT10">
						<div class="dx-field mrgB10">
							<div class="dx-field-label">Period To</div>
							<div class="dx-field-value">
								<dx-date-box [value]="endDate" (onValueChanged)="doEndDateChanged($event)"
									valueChangeEvent="change" type="date" displayFormat="monthAndYear"
									[calendarOptions]="{maxZoomLevel:'year',minZoomLevel: 'decade'}" [min]="minDateValue"
									[disabled]="false">
								</dx-date-box>
							</div>
						</div>
					</div>
					<div class="cols4 rebateChart">
						<div class="selectContract">
							<div class="dx-field">
								<div class="dx-field-label">Select Contract Name</div>
								<div class="dx-field-value">
									<dx-drop-down-box #refContractsLstDrpDwn [dataSource]="listOfContractsDataSource"
										[value]="sContractsLbl" [(opened)]="isOpenContractNameDropDownBox"
										valueExpr="contractName" [deferRendering]="true" placeholder="Select Contract Name..."
										[showClearButton]="true" (onOptionChanged)="doContractNameOptionChanged($event)"
										(onValueChanged)="doChangeContractValue($event)"
										(onOpened)="doOpenedDropdownContract($event)">
										<div *dxTemplate="let data of 'content'">
											<dx-check-box text="All Contracts" (onValueChanged)="doChangeAllContracts($event)"
												[(value)]="isCheckAllContracts" class="mrgB10">
											</dx-check-box>
											<dx-data-grid #listOfContractDataGrid [dataSource]="listOfContractsDataSource"
												[(selectedRowKeys)]="selectedContractKeys" [remoteOperations]="true"
												[hoverStateEnabled]="true" [filterSyncEnabled]="true"
												[(filterValue)]="contractFilterValue" [wordWrapEnabled]="true" [showBorders]="true"
												(onSelectionChanged)="doSelectedChangedContract($event)" height="100%">

												<dxo-filter-row [visible]="true"></dxo-filter-row>
												<!-- <dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter> -->
												<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
												<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
												<dxo-selection mode="single" [allowSelectAll]="false"></dxo-selection>

												<dxi-column dataField="contractName" caption="Contract Name" dataType="string"
													[allowHeaderFiltering]="false">
												</dxi-column>

												<dxi-column dataField="settlementPeriodStart" alignment="center"
													caption="Settlement Period" dataType="date" format="yyyy-MM-dd"
													cellTemplate="cellTemplateStartDate" [allowFiltering]="false"
													[allowHeaderFiltering]="false">
													<div *dxTemplate="let element of 'cellTemplateStartDate'">
														{{ (element.data.settlementPeriodStart !== null &&
														element.data.settlementPeriodStart !== '' &&
														element.data.settlementPeriodStart !==
														undefined) ? (element.data.settlementPeriodStart | date: 'yyyy-MM-dd') : "" }}
														to
														{{ (element.data.settlementPeriodEnd !== null &&
														element.data.settlementPeriodEnd
														!== '' && element.data.settlementPeriodEnd !== undefined) ?
														(element.data.settlementPeriodEnd | date: 'yyyy-MM-dd') : "" }}
													</div>
												</dxi-column>

												<dxi-column dataField="status" [visible]="false" [allowFiltering]=" false"
													[allowHeaderFiltering]="true" alignment="center" caption="Status"
													dataType="string" cellTemplate="cellTemplateStatus">
													<dxo-header-filter [dataSource]="listOfStatusHeaderFilter">
													</dxo-header-filter>
													<div *dxTemplate="let element of 'cellTemplateStatus'">
														<!-- <span class="selectTip"
														[style.background]="element.data.status || '' | filterFromList:listOfContractStatuses : 'value' : 'bgcolor'"> -->
														{{element.data.status || 'draft' |
														filterFromList:listOfContractStatuses : 'value' :
														'label'}}
														<!-- </span> -->
													</div>
												</dxi-column>
											</dx-data-grid>
										</div>
									</dx-drop-down-box>
								</div>
							</div>
						</div>
					</div>
					<div class="cols12 mapaverage mrgB10">
						<span *ngIf="settlementStartDate && settlementStartDate !== 'Invalid date'">
							<label class="settleLabel">Period:</label> {{ (settlementStartDate !== null &&
							settlementStartDate !== '' && settlementStartDate !==
							undefined) ? (settlementStartDate | date: 'yyyy-MM-dd') : "" }}
							To
							{{ (settlementEndDate !== null && settlementEndDate !== '' && settlementEndDate !==
							undefined) ? (settlementEndDate | date: 'yyyy-MM-dd') : "" }}
						</span>
						<span>
							&nbsp;|&nbsp; <label class="settleLabel padL0">Revenue Accrued To Date:</label>
							{{revenueAccruedTotal || 0}}
							&nbsp;|&nbsp; <label class="settleLabel padL0">Estimated Total:</label>
							{{contractsEstimatedTotal || 0}}
						</span>
						<span class="chartAveragerefresh">
							<dx-button class="secondaryBtn" text="Refresh" icon="refresh"
								(onClick)="doRefreshChart()"></dx-button>
						</span>
						<span class="chartAverage">
							<span class="colorIndicator"></span> Average:
							<span class="value">{{contractsAverageCounter || 0 | customCurrencyPipe}}</span>
						</span>
					</div>
				</div>
				<div class="cf"></div>
				<div class="posRelative mrgT10">
					<dx-chart [dataSource]="contractsChart" [customizeLabel]="doCustomizeLabel">
						<dxi-series [color]="'#21b3c6'" [barWidth]="100" argumentField="month" valueField="value" type="bar">
						</dxi-series>
						<dxo-label [visible]="true"></dxo-label>
						<dxo-legend [visible]="false"></dxo-legend>
						<dxi-value-axis [showZero]="false">
							<dxo-label [customizeText]="doCustomizeTextInDollarYAxis"></dxo-label>
							<dxi-constant-line [width]="2" [value]="contractsAverageCounter" color="#666666" dashStyle="solid"
								class="averageText">
								<dxo-label [text]="constantLineText" [font]="{color:'#222',weight:800}"></dxo-label>
							</dxi-constant-line>
						</dxi-value-axis>
						<!-- <dxo-tooltip title="" [enabled]="true" [shared]="true" [customizeTooltip]="doCustomizeTooltipInDollar">
					</dxo-tooltip> -->
					</dx-chart>
					<app-ix-load-panel *ngIf="isVisibleChartLoader"></app-ix-load-panel>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="cf"></div>